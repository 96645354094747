<template>
  <v-container>
    <v-form ref="distributionVendorForm" lazy-validation>
      <v-container v-for="(row, index) in rows" :key="row.id" class="form-container">
        <v-row class="mt-1">
          <v-col :sm="3">
            <vendors-autocomplete
                :ref="'vendorsAutocomplete' + row.id"
                :vendors="availableVendors(index)"
                :value="selectedVendor"
                label="Distribution Vendor"
                :disabled="!isAddForm || !isModalActive"
                :placeholder="'Select Vendor'"
                @inputSelected="onVendorSelected(index, row.id)"
            />
          </v-col>
          <v-col sm="1">
            <v-divider class="mt-3"></v-divider>
          </v-col>
          <v-col :sm="isAddForm ? 7 : 8">
            <branches-autocomplete
                :ref="'branchesAutocomplete' + row.id"
                :value="selectedBranches"
                :label="'* Branches'"
                :multiple="true"
                :required="true"
                :clearable="true"
                :disabled="!isModalActive"
                :selectAllEnabled="true"
                :defaultBranches="availableBranches(index)"
                :getBranchesOnComponentCreation="false"
                @inputSelected="onBranchSelected(index, row.id)"
            />
          </v-col>
          <br/>
          <v-col v-if="isAddForm" sm="1" style="padding: 7px;">
            <div>
              <v-btn
                  :disabled="rows.length === 1"
                  dark
                  depressed
                  small
                  fab
                  color="primary"
                  @click="deleteRow(index, row)"
              >
                <v-icon dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </v-col>

        </v-row>
      </v-container>
      <div v-if="isAddForm && isModalActive" class="text-center ma-16">
        <v-btn
            dark
            fab
            color="primary"
            @click="addExtraBranchClicked"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </div>
      <div v-if="!isModalActive" class="message">
        <span>There is no vendor to select. Please, set up at least one vendor in Vendor management section.</span>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import BranchesAutocomplete from "../searchFields/BranchesAutocomplete.vue";
import VendorsAutocomplete from "../searchFields/VendorsAutocomplete.vue";

const formDefaults = {};

export default {
  components: {
    VendorsAutocomplete,
    BranchesAutocomplete
  },

  props: {
    isAddForm: {
      type: Boolean,
      required: true,
      default: true
    },
    selectedVendor: {
      type: Object,
      required: false,
    },
    selectedBranches: {
      type: Array,
      required: true,
      default: () => []
    },
    defaultActiveVendors: {
      type: Array,
      required: true,
    },
    defaultBranches: {
      type: Array,
      required: true,
    },
    selectedBranchesFromGrid: {
      type: Array,
      required: false
    },
    selectedDistributionVendorsFromGrid: {
      type: Array,
      required: false
    },
    vendors: {
      type: Array,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
  },

  computed: {
    availableBranches() {
      return (index) => {
        let vendors = this.isAddForm
            ? this.selectedDistributionVendorsFromGrid
            : this.selectedDistributionVendorsFromGrid.filter(item => item.vendor.id !== this.selectedVendor.id);

        // all other branches from Grid, except selected
        const selectedBranchesReferenceIdsFromGrid = vendors.flatMap(vendor => vendor.branches).map(branch => branch.referenceId);

        return this.defaultBranches.map(branch => {
          for (let i = 0; i < this.rows.length; i++) {
            if (i !== index && this.rows[i].branches.includes(branch.referenceId)
                || selectedBranchesReferenceIdsFromGrid.some(selectedBranchFromGrid => selectedBranchFromGrid === branch.referenceId)) {
              return {...branch, disabled: true};
            }
          }
          return branch;
        });
      };
    },

    availableVendors() {
      return (index) => {
        const selectedVendors = this.rows
            .filter((row, i) => i !== index && row.vendor)
            .map((row) => row.vendor.id);
        return this.vendors.map((vendor) => {
          if (selectedVendors.some((id) => id === vendor.id)
              || this.selectedDistributionVendorsFromGrid.some((item) => item.vendor.id === vendor.id)) {
            return {...vendor, disabled: true};
          } else {
            return vendor;
          }
        });
      };
    },

    selectedVendors() {
      return this.rows.map(row => row.vendor);
    }
  },

  data() {
    return Object.assign(
        {
          rows: [{id: 0}]
        },
        formDefaults
    );
  },

  methods: {
    reset() {
      if (this.$refs.distributionVendorForm) {
        this.resetValidation();
      }
    },
    resetValidation() {
      return this.$refs.distributionVendorForm.resetValidation();
    },
    validateForm() {
      return this.$refs.distributionVendorForm.validate();
    },
    getFormData() {
      return this.rows;
    },
    onBranchSelected(index, rowId) {
      const branches = this.$refs['branchesAutocomplete' + rowId][0].selected;
      this.rows[index].branches = [];
      if (branches.length > 0) {
        this.rows[index].branches = branches;
      }
      this.$forceUpdate();
    },
    onVendorSelected(index, rowId) {
      this.rows[index].vendor = this.$refs['vendorsAutocomplete' + rowId][0].selected;
    },
    getSelectedVendorsFromRows() {
      return this.selectedVendors;
    },
    addExtraBranchClicked() {
      let validRows = this.validateForm();
      let id = this.rows[this.rows.length - 1].id + 1;        // get id of the last row and increment it
      if (validRows) {
        this.rows.push({id: id, branches: []});
      }
    },
    filterOutAvailableVendors(toBeRestored, isDisabled) {
      const vendorsToDeselect = new Set(this.selectedVendors);
      this.vendors.filter((vendor) => {
        if (vendorsToDeselect.has(vendor)) {
          vendor.disabled = isDisabled;
        } else if (toBeRestored && vendor === toBeRestored) {
          vendor.disabled = isDisabled;
        }
      });
    },
    resetRowsToDefault() {
      this.rows = [{id: 0}];
      if (this.isAddForm) {
        this.clearRefs();
      }
    },

    deleteRow(index, rowToDelete) {
      this.rows = this.rows.filter((row) => row.id !== rowToDelete.id);
    },
    clearRefs() {
      Object.keys(this.$refs).forEach(refKey => {
        if (refKey.startsWith('vendorsAutocomplete') || refKey.startsWith('branchesAutocomplete')) {
          this.$refs[refKey][0].reset();
        }
      });
    }
  }
};
</script>

<style scoped>
.mt-1 {
  margin-top: 1rem;
}

.form-container {
  padding: 5px;
}

.message {
  margin-top: 3rem;
  font-size: larger;
}
</style>
