<template>
  <system-message-dialog
      :visible="systemMessageVisible"
      :title="title"
      :message="message"
      :icon="icon"
      :created-at="broadcastMessageCreatedAt"
      @acknowledged="onSystemMessageAck"
  />
</template>

<script>
import SystemMessageDialog from "@/views/system/SystemMessageDialog.vue";
import WebSockets from "@/websocket/webSockets";

export default {
  components: {
    SystemMessageDialog
  },

  async created() {
    this.connectWebSocket();
  },

  data() {
    return {
      systemMessageVisible: false,
      title: null,
      message: null,
      broadcastMessageCreatedAt: null,
      icon: null,
      stompJsClient: null
    };
  },

  computed: {
    user() {
      return this.$store.state.loggedInUser;
    }
  },

  watch: {
    user(newVal, oldVal) {
      if (!newVal) {
        this.closeWSBroadcastConnection();
      }
      if (!oldVal && newVal) {
        this.connectWebSocket();
      }
    }
  },

  methods: {
    connectWebSocket() {
      if (this.$store.state.loggedInUser) {
        this.stompJsClient = WebSockets.createStompJsClient(this.onConnectFunction);
      }
    },
    isUserRoleTargeted: function (broadcast, user) {
      return broadcast.roleTarget === "All" ||
          (broadcast.roleTarget === "Administrators" && (user.roleId === 1 || user.roleId === 5)) ||
          (broadcast.roleTarget === "SalesRepresentatives" && user.roleId === 2) ||
          (broadcast.roleTarget === "RegularUsers" && user.roleId === 3) ||
          (broadcast.roleTarget === "BranchUsers" && user.roleId === 4);
    },
    onConnectFunction() {
      const broadcastMessageTopic = WebSockets.getWebSocketSystemTopic() + "/broadcast";
      this.stompJsClient.subscribe(broadcastMessageTopic, response => {
        let broadcast = JSON.parse(response.body);
        let user = this.$store.state.loggedInUser;
        if (broadcast && user) {
          this.title = broadcast.title;
          this.message = broadcast.message;
          this.broadcastMessageCreatedAt = broadcast.createdAt;
          this.icon = broadcast.severity === "Critical" ? "warning" : "info";
          if (this.isUserRoleTargeted(broadcast, user)) {
            this.systemMessageVisible = true;
          }
        }
      });
    },
    onSystemMessageAck() {
      this.systemMessageVisible = false;
    },
    closeWSBroadcastConnection() {
      this.stompJsClient.deactivate();
    },
  }
};
</script>