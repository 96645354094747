<template>
  <div>
    <v-text-field
        v-if="readonly || disabled"
        data-id="promo-code-text-value"
        label="Promo Code"
        :value="textValue"
        :readonly="readonly"
        :disabled="disabled"
        placeholder=" "
        persistent-placeholder
    />
    <v-autocomplete
        v-if="!readonly && !disabled"
        customClass="mutliple-columns"
        data-id="promo-code-autocomplete"
        item-text="key"
        item-value="id"
        label="* Promo Code"
        :placeholder="placeholder"
        persistent-placeholder
        v-model="selected"
        :rules="[rules.required]"
        :clearable="clearable"
        :items="promoCodes"
        :loading="loading"
        hide-no-data
        hide-selected
        dense
        attach
    />
  </div>
</template>

<script>
import PromoCodes from "../../rest/promoCodes";

export default {
  data() {
    return {
      selected: null,
      promoCodes: [],
      loading: false,
      rules: {
        required: value => {
          if (this.requiredValue) {
            return ((value !== null && value !== undefined) || "Promo code is required");
          }
          return true;
        }
      }
    };
  },

  props: {
    value: {
      type: Number,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search promo codes'
    },
    requiredValue: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    marketId: {
      type: Number,
      required: false,
    },
    filterPromoCodes: {
      type: Boolean,
      required: false,
    },
    clearable: {
      type: Boolean,
      required: false,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(updated) {
        if (updated !== null && updated !== undefined) {
          const selectedPromoCodeActive = this.promoCodes.find(item => item.id === updated);
          if (selectedPromoCodeActive) {
            this.selected = updated;
          }
        }
      }
    },
    selected(value) {
      this.$emit('input', value);
    },
    marketId(value) {
      this.populatePromoCodeSelect(value);
    }
  },

  computed: {
    textValue() {
      const items = this.promoCodes;
      const item = items.find(item => item.id === this.selected);
      let value = null;
      if (item && item.key) {
        value = item.key;
      }
      this.$emit('onPromoCodeFound', value);
      return value;
    }
  },

  created() {
    this.populatePromoCodeSelect(this.marketId);
  },

  methods: {
    populatePromoCodeSelect(marketId) {
      if (this.marketId) {
        this.getMarketPromoCodes(marketId);
      }
    },
    async getMarketPromoCodes(marketId) {
      try {
        const response = await PromoCodes.getRestApi().getAllActiveForMarketPromoCodes(marketId);
        this.promoCodes = response.data;
      } catch (error) {
        this.emitError(error);
      }
    }
  }
};
</script>
