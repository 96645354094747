import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {Lead} from "./leads";
import {SalesRepresentative} from "./salesRepresentatives";

/**
 * REST interface provided for Notes.
 */

export class Note {
    id: number;
    note: string;
    creatorId: number;
    userName: String;
    origin: string;
    createdAt: string;
    updatedAt: string;

    constructor(
        id: number,
        note: string,
        creatorId: number,
        userName: string,
        origin: string,
        createdAt: string,
        updatedAt: string,
    ) {
        this.id = id;
        this.note = note;
        this.creatorId = creatorId;
        this.userName = userName;
        this.origin = origin;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}

export interface NotesRestApi {
    getJobAndLeadAndTicketNotes(id: number): AxiosPromise<Note>;
    getAllRelatedNotesByLeadReferenceId(leadReferenceId: string): AxiosPromise<Note>
    createJobNote(id: number, note: object): AxiosPromise<Note>;
    createLeadNote(leadReferenceId: string, note: object): AxiosPromise<Lead>;
    getCustomerNotes(refId: string): AxiosPromise<Note[]>;
    createCustomerNote(refId: string, note: Note): AxiosPromise<Note>;
    createSalesRepNote(refId: string, note: object): AxiosPromise<SalesRepresentative>;
    getJobNotes(id: number): AxiosPromise<Note>;   //Unused. Reason: currently used combined response that contains Lead + Job + Ticket notes
}

/**
 * REST implementation provided for Notes.
 */
class Implementation implements NotesRestApi {
    getJobAndLeadAndTicketNotes(id: number): AxiosPromise<Note> {
        return Axios.axiosInstance().get<Note>(`/notes/${id}/all`);
    }
    createJobNote(id: number, note: object): AxiosPromise<Note> {
        return Axios.axiosInstance().post<Note>(`/jobs/${id}/notes`, note);
    }
    getAllRelatedNotesByLeadReferenceId(leadReferenceId: string): AxiosPromise<Note> {
        return Axios.axiosInstance().get<Note>(`notes/lead/${leadReferenceId}/all`);
    }
    createLeadNote(leadReferenceId: string, note: object): AxiosPromise<Lead> {
        return Axios.axiosInstance().post<Lead>(`/leads/${leadReferenceId}/notes`, note);
    }
    getCustomerNotes(refId: string): AxiosPromise<Note[]> {
        return Axios.axiosInstance().get<Note[]>(`/customers/${refId}/notes`);
    }
    createCustomerNote(refId: string, note: Note): AxiosPromise<Note> {
        return Axios.axiosInstance().post<Note>(`/customers/${refId}/notes`, note);
    }
    createSalesRepNote(refId: string, note: object): AxiosPromise<SalesRepresentative> {
        return Axios.axiosInstance().post<SalesRepresentative>(`/notes/sales-rep/${refId}`, note);
    }

    //Unused. Reason: currently used combined response that contains Lead + Job + Ticket notes
    getJobNotes(id: number): AxiosPromise<Note> {
        return Axios.axiosInstance().get<Note>(`/jobs/${id}/notes`);
    }
}

const Notes = {
    getRestApi(): NotesRestApi {
        return new Implementation();
    },
};

export default Notes;
