<template>
  <inline-grid
      :data-source="jobs"
      :show-borders="false"
      :hoverStateEnabled="true"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="createdAt"
        data-type="date"
        caption="Created"
        format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
        data-field="nextInstallationDate"
        data-type="date"
        caption="Installation Date"
    />
    <dx-column
        data-field="installationTime"
        caption="Installation Time"
    />
  </inline-grid>
</template>

<script>
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import Customers from "@/rest/customers";

export default {
  components: {
    InlineGrid,
    DxColumn
  },

  props: ["customerReferenceId"],

  data() {
    return {jobs: null};
  },

  created() {
    this.refresh();
  },

  methods: {
    refresh() {
      let component = this;
      Customers.getRestApi()
          .getCustomerJobs(this.customerReferenceId)
          .then((response) => {
            component.jobs = response.data;
          })
          .catch(() => {
            component.snackbar = true;
          });
    },
    onRowSelected(data) {
      this.$router.push(`/jobs/details/${data.referenceId}`);
    }
  }
};
</script>
