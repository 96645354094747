<template>
  <div>
    <v-text-field
        v-if="disabled"
        :readonly="readonly"
        :disabled="disabled"
        placeholder=" "
        persistent-placeholder
        :value="textValue"
        label="Sales Representative"
        data-id="sales-rep-text-value"
    />
    <v-autocomplete
        v-if="!disabled"
        v-model="selected"
        :readonly="readonly"
        :item-value="itemValue"
        :search-input.sync="searchInput"
        @input="searchInput=null"
        :hide-selected="!multipleSelection"
        item-text="fullName"
        :multiple="multipleSelection"
        :label="autocompleteLabel"
        :items="salesReps"
        :loading="loading"
        :placeholder="placeholder || 'Search sales representatives'"
        :rules="[rules.required]"
        :clearable="clearable"
        hide-no-data
        persistent-placeholder
        dense
        attach
        validate-on-blur
    />
  </div>
</template>

<script>
import SalesRepresentatives from "../../rest/salesRepresentatives";
import SalesRepTimeBlock from "../../rest/salesRepTimeBlocks";

export default {
  data() {
    return {
      market: this.marketId,
      selected: null,
      salesReps: [],
      loading: false,
      rules: {
        required: value => {
          if (this.isRequired) {
            if (this.multipleSelection) {
              return !!value.length || "Sales representative is required";
            } else {
              return !!value || "Sales representative is required";
            }
          }
          return true;
        }
      },
      searchInput: null,
    };
  },

  props: {
    disabled: Boolean,
    readonly: Boolean,
    placeholder: String,
    isRequired: {
      type: Boolean,
      default: true
    },
    marketId: Number,
    date: null,
    multipleSelection: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "referenceId"
    },
    isRefreshByMarket: {
      type: Boolean,
      default: true
    },
    selectedSalesReps: {
      type: [String, Array],
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    showTier: {
      type: Boolean,
      default: false
    },
    unmodifiable: {
      type: Boolean,
      default: false
    },
    timeBlockId: Number
  },

  watch: {
    selectedSalesReps: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      this.$emit('change', value);

      if (this.selected && this.selected.length > 0 && this.selected.length === this.salesReps.length) {
        this.$emit('showAllSelected');
      } else {
        this.$emit('showNotAllSelected');
      }
    },
    marketId(value) {
      this.market = value;
    },
    market() {
      if (this.isRefreshByMarket) {
        this.refresh();
      }
    },
    date() {
      if (this.isRefreshByMarket) {
        this.refresh();
      }
    }
  },

  created() {
    if (this.isRefreshByMarket) {
      this.refresh();
    } else if (this.timeBlockId) {
      this.refreshByTimeBlock(this.timeBlockId, this.selected);
    }
  },

  computed: {
    textValue() {
      const items = this.salesReps;
      const item = items.find(item => item.referenceId === this.selected);
      if (item) {
        return item.fullName;
      } else {
        return "";
      }
    },
    autocompleteLabel() {
      let label = "Sales Representative";
      if (this.isRequired) label = `* ${label}`;
      return label;
    }
  },

  methods: {
    async refresh() {
      try {
        if (this.market) {
          const data = await this.getSalesRepsByMarketData();
          if (data && Array.isArray(data)) {
            this.salesReps = data;
            if (this.selectedSalesReps) {
              this.selected = this.salesReps.filter(sr => this.selectedSalesReps.includes(sr.id));
            }
            this.$emit('optionsCount', this.salesReps.length);
          }
        } else {
          this.salesReps = [];
        }
      } catch (error) {
        throw error;
      }
    },
    async refreshByTimeBlock(timeBlockId, salesRepRefId) {
      if (timeBlockId) {
        const data = await this.getSalesRepsByTimeBlock(timeBlockId, salesRepRefId);
        if (data && Array.isArray(data)) {
          if (this.showTier) {
            data.forEach(sr => {
              sr.fullName = `${sr.fullName} (tier ${sr.tier})`;
            });
          }
          this.salesReps = data;
        }
      } else {
        this.salesReps = [];
      }
    },
    async getSalesRepsByTimeBlock(timeBlockId, salesRepRefId) {
      try {
        let salesRepToFind = this.selected ? this.selected : salesRepRefId;
        const response = await SalesRepTimeBlock.getRestApi().getActiveSalesRepsByTimeBlockIdAndSelectedSalesRep(
            timeBlockId,
            salesRepToFind
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getSalesRepsByMarketData() {
      try {
        const response = await SalesRepresentatives.getRestApi().getMarketSalesReps(this.market);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    selectAll(value) {
      if (this.multipleSelection) {
        if (value.value) {
          this.selected = this.salesReps.slice().map(item => item.id);
        } else {
          this.selected = [];
        }
      }
    },
    resetMarket() {
      this.market = null;
    },
    cleanList() {
      this.selected = null;
      this.salesReps = [];
    },
    isSalesRepAvailable(salesRepReferenceId) {
      let index = this.salesReps.findIndex(
          salesRep => salesRep.referenceId === salesRepReferenceId && !salesRep.disabled
      );
      return index > -1;
    }
  }
};
</script>
