<template>
  <v-simple-table v-if="hasMessages" fixed-header name="header" class="notification-table body-1 pt-4 pb-2">
    <template v-slot:default>
      <thead class="mb-1 mt-5" v-show="showHeaders">
      <tr>
        <th class="text-center">
          State
        </th>
        <th class="text-center">
          Event
        </th>
        <th class="text-center">
          Start
        </th>
        <th class="text-center">
          End
        </th>
        <th class="text-center" width="50%">
          Message
        </th>
        <th class="text-center">
          Sender
        </th>
        <th class="text-center">
          Mark As Read
        </th>
      </tr>
      </thead>

      <tbody style="min-height: 100px">
      <tr
          v-for="(message, index) in userNotifications"
          :key="index"
          :class="defineClass(message)"
          style="justify-content: flex-end;"
      >
        <td>
          <div class="mt-2">
            <v-icon color="black" v-if="message.eventState === Constants.eventStateTypes.SCHEDULED">
              mdi-wrench-clock
            </v-icon>

            <v-icon color="green" v-else-if="message.eventState === Constants.eventStateTypes.FINISHED">
              mdi-wrench-check
            </v-icon>

            <v-progress-circular
                v-else
                :width="3"
                size="20"
                color="#6699bb"
                indeterminate
            >
              <v-icon color="#6699bb">
                mdi-progress-wrench
              </v-icon>
            </v-progress-circular>
          </div>

          <span class="text-center" style="font-size: 9px"> {{ message.eventState.toLowerCase() }} </span>
        </td>
        <td>
          {{ message.eventName }}
        </td>
        <td>
          {{ message.startTime }}
        </td>
        <td>
          {{ message.endTime }}
        </td>
        <td class="td-message-text-field mt-2">
          {{ message.message }}
        </td>
        <td>
          {{ message.sender }}
        </td>
        <td>
          <v-btn
              v-if="!message.read"
              class="text-none"
              stacked
              light
              icon
              @click="markAsRead(index)"
          >
            <v-icon class="pt-1" color="#69b"> mdi-checkbox-marked-outline </v-icon>
          </v-btn>
        </td>
      </tr>
      <v-divider class="detailsDivider"/>
      </tbody>
    </template>
  </v-simple-table>

  <div v-else class="no-data">
    <v-icon disabled x-large class="mt-4"> mdi-bell-outline</v-icon>
    <div class="no-data-block pa-6">
      <p class="title"> No Notifications Yet</p>
      <p class="no-data-text"> When you get notifications, they will show up here</p>
    </div>
  </div>

</template>

<script>

import Constants from "../../../assets/constants";

export default {

  computed: {
    Constants() {
      return Constants
    },
    hasMessages() {
      return this.userNotifications.length > 0;
    },
  },

  props: {
    showHeaders: {
      type: Boolean,
      default: true,
    },
    userNotifications: {
      type: Array | null
    },
  },

  data() {
    return {
      messageStack: [],
    }
  },

  methods: {
    markAsRead(index) {
      this.$emit('markAsRead', index)
    },
    defineClass(message) {
      return message?.read ? "read-row" : "flex-row align-baseline";
    }
  },
}
</script>

<style scoped>
.read-row {
  color: rgba(0, 0, 0, 0.26);
}
.no-data {
  background-color: white;
  min-height: 200px;
}
.no-data-block {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.53);
}

.td-message-text-field{
  width: 50%;
}

</style>
