<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-row class="mt-2">
        <v-col md="12">
          <v-textarea
            v-model="note"
            label="Note"
            placeholder=" "
            :disabled="true"
            rows="3"
          />
        </v-col>
        <v-col md="12">
          <v-divider class="mb-3" />
        </v-col>
          <v-col md="12">
          <v-select
            label="* Select Vendor"
            placeholder=" "
            persistent-placeholder
            v-model="vendorId"
            :items="vendors"
            item-text="name"
            attach
            return-object
            @change="onVendorSelected"
          />
          </v-col>
        <v-col md="8">
          <v-select
            label="Select Product Items"
            placeholder=" "
            persistent-placeholder
            v-model="productItem"
            :items="vendorItems"
            item-text="displayName"
            attach
            multiple
            small-chips
            return-object
            clearable
            @change="onProductItemsChanged"
          >
            <template v-slot:prepend-item class="select-all" v-if="!emptyProductItemsSelection">
              <select-all
                  :items="vendorItems"
                  :selected="productItem"
                  @select="selectAllProductItems"
                  @deselect="deselectAllProductItems"
              >
              </select-all>
            </template>
          </v-select>
        </v-col>
        <v-col md="4">
          <v-select
            label="Select Labor Items"
            placeholder=" "
            persistent-placeholder
            v-model="laborItem"
            :items="laborItems"
            item-text="displayName"
            attach
            multiple
            small-chips
            return-object
            clearable
            @change="onLaborItemsChanged"
          >
            <template v-slot:prepend-item class="select-all" v-if="!emptyLaborSelection">
              <select-all
                  :items="laborItems"
                  :selected="laborItem"
                  @select="selectAllLaborItems"
                  @deselect="deselectAllLaborItems"
              >
              </select-all>
            </template>
          </v-select>
        </v-col>
        <v-col md="12">
          <v-divider class="mb-3" />
        </v-col>
          <v-col md="3">
          <v-text-field
            v-model="materialCost"
            label="Material Cost"
            placeholder="0.00"
            persistent-placeholder
            prefix="$"
            :rules="[rules.isCurrencyFormat]"
          />
          </v-col>
        <v-col md="3">
          <v-text-field
            v-model="laborCost"
            label="Labor Cost"
            placeholder="0.00"
            persistent-placeholder
            prefix="$"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="customerAmount"
            label="Customer Discount"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model="fiftyFloorAmount"
            label="50Floor Responsibility"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-col>
        <v-col md="12">
          <v-divider class="mb-3" />
        </v-col>
        <v-col md="4">
          <v-text-field
            v-model="totalChargeback"
            label="Total Chargeback"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col md="8"></v-col>
        <v-col md="12">
          <v-spacer class="mb-2" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Jobs from "@/rest/jobs";
import SelectAll from "@/components/searchFields/selectAllForDropdowns/SelectAllDropdownTemplate.vue";
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";
import Rules from "@/assets/rules";
import ItemsNamingHelper from "../../../assets/ItemsNamingHelper";

const formDefaults = {};

export default {
  components: {
    SelectAll
  },

  props: {
    job: Object,
    productLineItems: Array
  },

  data() {
    return Object.assign(
      {},
      {
        error: null,
        productItem: [],
        vendorsPerProduct: [],
        vendors: [],
        vendorItems: [],
        laborItems: [],
        laborItem: [],
        note: null,
        materialCost: 0,
        laborCost: 0,
        customerAmount: 0,
        fiftyFloorAmount: 0,
        totalChargeback: 0,
        vendorId: null,
        vendor: null,
        vendorLabel: null,
        rules: {
          required: Rules.required,
          isCurrencyFormat: Rules.isCurrencyFormat
        },
      },
      formDefaults
    );
  },

  computed: {
    jobReferenceId() {
      return this.job.referenceId;
    },
    emptyProductItemsSelection() {
      return this.vendorItems.length === 0;
    },
    emptyLaborSelection() {
      return this.laborItems.length === 0;
    }
  },

  watch: {
    materialValue() {
      this.updateChargebackTotal();
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    load(payload) {
      this.productItem = [];
      this.loadProductItems();
      this.laborItems = [];
      this.laborItem = [];
      this.loadLaborItems();
      this.vendorId = null;
      this.vendor = null;
      this.vendorLabel = null;
      this.note = payload.note;
      this.materialCost = CurrencyFormatter.zeroCurrency();
      this.laborCost = CurrencyFormatter.zeroCurrency();
      this.customerAmount = CurrencyFormatter.asCurrency(payload.customerAmount);
      this.fiftyFloorAmount = CurrencyFormatter.asCurrency(payload.fiftyFloorAmount);
      this.updateChargebackTotal();
    },
    updateChargebackTotal() {
      this.totalChargeback = CurrencyFormatter.asCurrency(
          this.materialCost
              .add(this.laborCost)
              .add(this.customerAmount)
              .subtract(this.fiftyFloorAmount));
    },
    getPayload() {
      const payload = {};
      payload.materialCost = this.materialCost;
      payload.laborCost = this.laborCost;
      payload.vendorChargeAmount = this.totalChargeback;
      payload.vendorId = this.vendor.id;
      payload.vendorLabel = this.vendor.name;
      return payload;
    },
    onProductItemsChanged(items) {
      let total = CurrencyFormatter.zeroCurrency();
      this.productItem = items;
      items.forEach((item) => {
        if (item.supplierCost) {
          let itemTotal = CurrencyFormatter.asCurrency(item.supplierCost.cost).multiply(item.quantity);
          total = total.add(itemTotal);
        }
      });
      this.materialCost = total;
      this.updateChargebackTotal();
    },
    onLaborItemsChanged(items) {
      let total = CurrencyFormatter.zeroCurrency();
      this.laborItem = items;
      items.forEach((item) => {
        let itemTotal = CurrencyFormatter.asCurrency(item.price);
        total = total.add(itemTotal);
      });
      this.laborCost = total;
      this.updateChargebackTotal();
    },
    onVendorSelected(vendor) {
      this.productItem = [];
      this.onProductItemsChanged([]);
      this.vendorItems = this.vendorsPerProduct.filter(item => item.vendors.some(v => v.id === vendor.id))
          .map(item => {
            const productLineItem = item.jobProductLineItem;
            this.setItemDisplayName(productLineItem)
            return productLineItem;
          });
      this.vendor = vendor;
      this.vendorLabel = vendor.name;
    },
    async loadProductItems() {
      try {
        const response = await Jobs.getRestApi().getJobProductLineItemsVendors(this.jobReferenceId);
        this.vendorsPerProduct = response.data;
        const allVendors = this.vendorsPerProduct.flatMap(item => item.vendors);
        this.vendors = [...new Set(allVendors)];
      } catch (error) {
        throw error;
      }
    },
    async loadLaborItems() {
      try {
        const response = await Jobs.getRestApi().getJobLaborLineItems(this.jobReferenceId);
        let items = response.data;
        items.forEach((item) => {
          item.displayName = item.referenceId;
        });
        this.laborItems = items;
      } catch (error) {
        throw error;
      }
    },
    reset: function () {
    },
    setItemDisplayName(item) {
      return ItemsNamingHelper.buildItemDisplayName(item);
    },
    selectAllProductItems() {
      this.productItem = this.vendorItems;
      this.onProductItemsChanged(this.productItem)
    },
    deselectAllProductItems() {
      this.productItem = [];
      this.onProductItemsChanged([]);
    },
    selectAllLaborItems() {
      this.laborItem = this.laborItems;
      this.onLaborItemsChanged(this.laborItems);
    },
    deselectAllLaborItems() {
      this.laborItem = [];
      this.onLaborItemsChanged([]);
    }
  },
};
</script>
