const RouterHelper = {
    leadOrJobDetailsPages: [
        'jobs/details',
        'jobs/subjob',
        'leads/details'
    ],

    isLeadOrJobDetailsPage(route) {
        return this.leadOrJobDetailsPages.some(path => route.fullPath.includes(path));
    },
    includesPart(route, urlPart) {
        return route.fullPath.includes(urlPart);
    },
    isDetailsPage(route) {
        return route.fullPath.includes('/details');
    },
    //TODO: make it more Vue-like
    createClickableLink(container, options, router, path) {
        const link = document.createElement('a');
        link.href = 'javascript:void(0)';
        link.innerText = options.data.transferNumber;
        link.style.cursor = 'pointer';
        link.addEventListener('click', () => {
            router.push(`${path}/${options.data.transferNumber}`);
        });
        container.appendChild(link);
    },
    extractReferenceId(params) {
        return params.shipmentNumber || params.transferRequestNumber || null;
    }
};

export default RouterHelper;
