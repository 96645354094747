import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";

/**
 * Reason code.
 */
export interface RoleDTO {
  id: number;
  name: string;
  isInternal: boolean;
}

/**
 * REST interface provided for Reason codes.
 */
export interface RolesRestApi {
  getRoles(): AxiosPromise<RoleDTO[]>;
}

/**
 * REST implementation provided for Reason codes.
 */
class Implementation implements RolesRestApi {
  getRoles(): AxiosPromise<RoleDTO[]> {
    return Axios.axiosInstance().get<RoleDTO[]>("/roles/all");
  }

}

const Roles = {
  getRestApi(): RolesRestApi {
    const api = new Implementation();
    return api;
  }
};

export default Roles;
