<template>
  <modal-dialog
      width="600"
      submitEnabled="true"
      title="Session Termination Warning"
      submitLabel="Continue"
      :hideCancel="true"
      :visible="visible"
      @submitClicked="onContinueSessionClicked"
  >
    <v-container>
          <span>
      You have been inactive for {{ timeoutInHours }} hours.
    </span>
      <p>
        For security reasons, you will be logged out if no activity is detected.
        Or click "Continue" to extend your Oasis session.
      </p>
      <div class="time">
        Time remaining: {{ minutes }} minutes {{ seconds }} seconds
      </div>
    </v-container>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import Constants from "@/assets/constants";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    ModalDialog
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      minutes: "5",
      seconds: "00",
      duration: 300000,
      timer: null
    };
  },

  computed: {
    timeoutInHours() {
      return Constants.SESSION_INACTIVITY_TIMEOUT / (60 * 60 * 1000);
    }
  },

  watch: {
    visible(value) {
      if (value) {
        this.startTimer();
      } else {
        this.resetTimeRemaining();
      }
    }
  },

  methods: {
    onContinueSessionClicked() {
      this.$emit('onContinueSessionClicked');
      this.resetTimer();
    },
    startTimer() {
      const interval = 1000;
      let duration = DateUtils.getDuration(this.duration);

      this.timer = setInterval(() => {
        duration = duration.minus({milliseconds: interval});
        if (duration.as('seconds') <= 0) {
          this.$emit('onSessionTimeoutReached', Constants.logoutTypes.INACTIVE);
        } else {
          this.updateTimeRemaining(duration);
        }
      }, interval);
    },
    resetTimer() {
      clearInterval(this.timer);
    },
    resetTimeRemaining() {
      this.minutes = "5";
      this.seconds = "00";
    },
    updateTimeRemaining(duration) {
      this.minutes = Math.floor(duration.as('minutes'));
      this.seconds = this.getTimeString(duration.as('seconds') % 60);
    },
    getTimeString(value) {
      return value < 10 ? `0${Math.floor(value)}` : Math.floor(value).toString();
    }
  }
};
</script>

<style scoped>
.time {
  margin-top: 20px;
  font-weight: bold;
}
</style>
