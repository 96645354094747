<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0">
    <v-row class="pb-0">
      <v-col md="2">
        <v-text-field
            :value="catalogItem.internalSku"
            label="Internal SKU"
            placeholder=" "
            readonly
            id="catalog-item-read-only-internal-sku"
            persistent-placeholder
        />
      </v-col>
      <v-col/>
      <v-col md="2">
        <status-dropdown :value="status" :readonly="true"/>
      </v-col>
      <v-col md="2">
        <v-text-field
            :value="catalogItem.isAvailableInSalesApp ? 'Yes' : 'No'"
            label="Available In Sales App"
            placeholder=" "
            readonly
            id="availableInSalesApp"
        />
      </v-col>
    </v-row>
    <v-divider class="detailsDivider"/>

    <v-row>
      <v-col md="1">
        <v-text-field
            :value="bulkItemValue"
            label="Bulk Item"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-bulk-inventory"
        />
      </v-col>
      <v-col md="1">
        <v-text-field
            :value="catalogItem.conversionMultiplier"
            label="Conversion Multiplier"
            persistent-placeholder
            placeholder=" "
            readonly
            id="catalog-item-read-only-conversion-multiplier"
        />
      </v-col>
      <v-col md="1">
        <v-text-field
            :value="boxQuantityValue"
            label="Box Quantity"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-box-quantity"
        />
      </v-col>
      <v-spacer/>
      <v-col md="2">
        <v-text-field
            :value="catalogItem.productType.name"
            label="Product Type"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-product-type"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            :value="productSubtype.name"
            label="Product SubType"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-product-subtype"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <v-text-field
            :value="catalogItem.manufacturer"
            label="Manufacturer"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-manufacturer"
        />
      </v-col>
      <v-spacer/>

      <v-col md="2">
        <v-text-field
            :value="catalogItem.styleName"
            label="Manufacturer Style"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-style"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            :value="catalogItem.color"
            label="Manufacturer Color"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-color"
        />
      </v-col>
    </v-row>
    <v-row v-if="catalogItem.hasPrivateLabel">
      <v-spacer/>
      <v-col md="2">
        <v-text-field
            :value="catalogItem.privateLabelStyleName"
            label="Private Label Style"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-private-label-style"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            :value="catalogItem.privateLabelColor"
            label="Private Label Color"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-private-label-color"
        />
      </v-col>
    </v-row>

    <v-row v-if="catalogItem.productClass">
      <v-spacer/>
      <v-col md="4">
        <v-text-field
            :value="catalogItem.productClass.name"
            label="Product Class"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-product-class"
        />
      </v-col>
    </v-row>

    <v-row>

      <v-spacer/>
      <v-col md="4">
        <v-text-field
            :value="catalogItem.unitOfMeasure"
            label="Unit of Measure"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-unit-of-measure"
        />
      </v-col>
    </v-row>

    <v-spacer/>

    <v-row>
      <v-spacer/>
      <v-col md="4">
        <v-text-field
            :value="catalogItem.description"
            :title="catalogItem.description"
            label="Description"
            placeholder=" "
            persistent-placeholder
            readonly
            id="catalog-item-read-only-description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StatusDropdown from "../../components/common/form/StatusDropdown.vue";
import FormattingHelper from "../../assets/formattingHelper";

export default {
  components: {StatusDropdown},

  props: {
    catalogItem: Object,
    productSubtype: Object,
  },

  data() {
    return {
      boxQuantityValue: null,
      bulkItemValue: null,
      status: null,
      conversionMultiplier: null
    };
  },

  watch: {
    catalogItem: {
      immediate: true,
      handler(newVal) {
        this.status = newVal.status === 1;
        this.bulkItemValue = newVal.bulkInventoryItem ? "Yes" : "No";
        this.boxQuantityValue = FormattingHelper.quantityFormat(newVal);
      },
    },
  },
};
</script>
