<template>
  <div>
    <v-text-field
        v-if="readonly || disabled"
        :readonly="readonly"
        :disabled="disabled"
        placeholder=" "
        :value="textValue"
        label="Reason Code"
        data-id="reason-code-text-value"
    />
    <v-autocomplete
        v-if="!readonly && !disabled"
        v-model="selected"
        :items="reasonCodes"
        :loading="loading"
        hide-no-data
        hide-selected
        item-text="name"
        item-value="id"
        label="* Reason Code"
        :placeholder="placeholder || 'Search Reason codes'"
        persistent-placeholder
        :rules="[rules.required]"
        customClass="mutliple-columns"
        dense
        attach
        data-id="reason-code-autocomplete"
        :autofocus="true"
    />
  </div>
</template>

<script>
import ReasonCodes from "@/rest/reasonCodes";

export default {
  data() {
    return {
      selected: null,
      reasonCodes: [],
      loading: false,
      rules: {
        required: value => {
          if (!value && this.branchSelected) {
            return "Reason code is required";
          } else return true;
        }
      }
    };
  },

  props: [
    "value",
    "readonly",
    "placeholder",
    "requiredValue",
    "disabled",
    "filterReasonCodes",
    "branchSelected"
  ],

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("input", value);
    }
  },

  computed: {
    textValue() {
      const items = this.reasonCodes;
      const item = items.find(item => item.id === this.selected);
      let value = (item && item.key) || "";
      this.$emit("onReasonCodeFound", value);
      return value;
    }
  },

  created() {
    this.populateReasonCodeSelect();
  },

  methods: {
    populateReasonCodeSelect() {
      this.getAllActiveCheckOutReasonCodes();
    },
    getAllActiveCheckOutReasonCodes() {
      let component = this;
      ReasonCodes.getRestApi()
          .getAllActiveCheckOutReasonCodes()
          .then((response) => {
            component.reasonCodes = response.data;
          })
          .catch((error) => {
            throw error;
          });
    }
  }
};
</script>
