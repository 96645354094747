import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * REST interface provided for Special User Password Validation
 */

interface SpecialUserPasswordValidationRestApi {
    validateSpecialUserPassword(specialEditPOPassword: string): AxiosPromise<boolean>;
}

/**
 * REST implementation provided for Special User Password Validation
 */
class Implementation implements SpecialUserPasswordValidationRestApi {

    validateSpecialUserPassword(specialEditPOPassword: string): AxiosPromise<boolean> {
        return Axios.axiosInstance().post<boolean>(`/special-user-password-validation`, specialEditPOPassword);
    }

}

const SpecialUserPasswordValidation = {
    getRestApi(): SpecialUserPasswordValidationRestApi {
        return new Implementation();
    },
};

export default SpecialUserPasswordValidation;
