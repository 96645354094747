<template>
  <grid-view
      ref="nav"
      page-title="List Branches"
      nav-section="admin"
      :action-button-label="isActionButtonDisplayed ? 'Create Branch' : null"
      :data-source="dataSource"
      :allowFiltering="false"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
      :branchUserRestricted="true"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        :cell-template="StatusRenderer"
    />
    <dx-column
        data-field="Name"
        :minWidth="175"
        caption="Name"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        data-type="string"
    />
    <dx-column
        data-field="State"
        caption="State"
        data-type="string"
    />
    <dx-column
        data-field="ZipCode"
        caption="Zip Code"
        data-type="string"
    />
    <dx-column
        data-field="Phone"
        caption="Phone Number"
        :format="formatPhoneNumber"
        data-type="string"
    />
    <dx-column
        data-field="Fax"
        caption="Fax Number"
        :format="formatPhoneNumber"
        data-type="string"
    />
    <dx-column
        data-field="Email"
        caption="Email"
        data-type="string"
    />
    <dx-column
        data-field="Markets"
        :minWidth="200"
        caption="Associated Markets"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        data-type="string"
    />

    <template v-slot:drawerContent>
      <branch-create-drawer
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "../common/GridView.vue";
import BranchCreateDrawer from "../../components/branches/BranchCreateDrawer.vue";
import RoleRestrictor from "../../rest/odata";
import ODataContext from "../../rest/odataContext";
import Format from "../../components/common/Format.js";
import Roles from "../../assets/roles";
import StatusRenderer from "../../components/common/grid/StatusRenderer";
import {DxColumn} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    GridView,
    BranchCreateDrawer,
    DxColumn
  },
  data() {
    const config = {
      dataSource: {
        store: ODataContext['Branchs'],
        select: [
          "ReferenceId",
          "Name",
          "Address",
          "City",
          "State",
          "ZipCode",
          "Phone",
          "Fax",
          "Email",
          "Markets",
          "Active",
          "Status"
        ]
      }
    };
    return RoleRestrictor.updateConfig(this.$store.state.loggedInUser, config);
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/branches/details/${data.ReferenceId}`);
    },
    onSubmitSuccess() {
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
      this.$refs.nav.showSuccess("Created new branch successfully!");
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    StatusRenderer,
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    }
  }
};
</script>

<style></style>
