import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Request Lead Room Types
 */
export class LeadRoomType {
    createdAt: string = "";
    id: number = 0;
    name: string = "";
    updatedAt: string = "";

    constructor(createdAt: string, id: number, name: string, updatedAt: string) {
        this.createdAt = createdAt;
        this.id = id;
        this.name = name;
        this.updatedAt = updatedAt;
    }
}

/**
 * REST interface provided for Lead Room Types.
 */
export interface LeadRoomTypesRestApi {

    getLeadRooms(referenceId: string): AxiosPromise<LeadRoomType>;

    getAllLeadRoomTypes(): AxiosPromise<LeadRoomType>;
}

/**
 * REST implementation provided for Lead Room Types.
 */
class Implementation implements LeadRoomTypesRestApi {

    getLeadRooms(referenceId: string): AxiosPromise<LeadRoomType> {
        return Axios.axiosInstance().get(`/leads/${referenceId}/rooms`);
    }

    getAllLeadRoomTypes(): AxiosPromise<LeadRoomType> {
        return Axios.axiosInstance().get("/room_types");
    }

}

const LeadRoomTypes = {
    getRestApi(): LeadRoomTypesRestApi {
        return new Implementation();
    }
};

export default LeadRoomTypes;
