<template>
  <modal-dialog
      width="60%"
      :visible="visible"
      :submitEnabled="false"
      title="Activity logs"
      hide-submit="true"
      @cancelClicked="onCancelClicked"
  >
    <v-progress-circular
        class="mt-4 mb-2"
        v-if="loading"
        indeterminate
        color="primary"
    />
    <activity-logs-grid
        v-else
        ref="activityLogs"
        :activityLogs="activityLogs"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import ActivityLogsGrid from "@/components/common/inlineGrids/ActivityLogsGrid.vue";
import SalesRepsTimeBlock from "@/rest/salesRepsTimeBlock";

export default {
  components: {
    ModalDialog,
    ActivityLogsGrid
  },

  data() {
    return {
      visible: false,
      activityLogs: [],
      loading: false
    };
  },

  methods: {
    showDialog(timeblock) {
      if (timeblock) {
        this.visible = true;
        this.getActivityLogs(timeblock)
      }
    },
    hideDialog() {
      this.activityLogs = [];
      this.visible = false;
    },
    showError(error) {
      this.$emit('showError', error);
    },
    onCancelClicked() {
      this.hideDialog();
    },
    async getActivityLogs(timeblock) {
      try {
        if (timeblock) {
          this.loading = true;
          const response = await SalesRepsTimeBlock.getRestApi().getSalesRepresentativeTimeBlocksLogs(timeblock.id);
          this.activityLogs = response.data;
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
