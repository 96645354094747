import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * Request for updating a lead's address.
 */
export class UpdateLeadAddressRequest {
  address: string = "";
  city: string = "";
  state: string = "";
  zipCode: string = "";

  constructor(address: string, city: string, state: string, zipCode: string) {
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
  }
}

/**
 * Leads.
 */
export class Lead {
  id: number;
  referenceId: string;
  customerId: number;
  status: number;
  appointmentTime: string;
  appointmentDate: string;
  branchId: number;
  comment: string;
  quote: string;
  createdAt: string;
  updatedAt: string;
  timeBlockId: number;
  timeBlockName: string;

  constructor(
      id: number,
      referenceId: string,
      customerId: number,
      status: number,
      appointmentTime: string,
      appointmentDate: string,
      branchId: number,
      comment: string,
      quote: string,
      createdAt: string,
      updatedAt: string,
      timeBlockId: number,
      timeBlockName: string
  ) {
    this.id = id;
    this.referenceId = referenceId;
    this.customerId = customerId;
    this.status = status;
    this.appointmentTime = appointmentTime;
    this.appointmentDate = appointmentDate;
    this.branchId = branchId;
    this.comment = comment;
    this.quote = quote;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.timeBlockId = timeBlockId;
    this.timeBlockName = timeBlockName;
  }
}

/**
 * REST interface provided for leads.
 */
export interface LeadRestApi {
  createLead(lead: Lead): AxiosPromise<Lead>;
  getLead(refId: string): AxiosPromise<Lead>;
  updateLead(refId: string, updated: Lead): AxiosPromise<Lead>;
  updateLeadAddress(refId: string, request: UpdateLeadAddressRequest): AxiosPromise<Lead>;
  getLeadProductTypes(id: number): AxiosPromise;
  getLeadLogs(id: string): AxiosPromise<ActivityLog[]>;
  getLeadMigration(): AxiosPromise<Lead[]>;
}

/**
 * REST implementation provided for leads.
 */
class Implementation implements LeadRestApi {
  createLead(lead: Lead): AxiosPromise<Lead> {
    return Axios.axiosInstance().post<Lead>(`/leads`, lead);
  }
  getLead(refId: string): AxiosPromise<Lead> {
    return Axios.axiosInstance().get<Lead>(`/leads/${refId}`);
  }
  getLeadProductTypes(id: number): AxiosPromise {
    return Axios.axiosInstance().get(`/leads/${id}/product_types`);
  }
  getLeadLogs(id: string): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/leads/${id}/logs`);
  }
  updateLead(refId: string, updated: Lead): AxiosPromise<Lead> {
    return Axios.axiosInstance().put<Lead>(`/leads/${refId}`, updated);
  }
  updateLeadAddress(refId: string, request: UpdateLeadAddressRequest): AxiosPromise<Lead> {
    return Axios.axiosInstance().patch<Lead>(`/leads/${refId}/address`, request);
  }
  getLeadMigration(): AxiosPromise<Lead[]> {
    return Axios.axiosInstance().get<Lead[]>(`/leads/pending`);
  }
}

const Leads = {
  getRestApi(): LeadRestApi {
    return new Implementation();
  }
};

export default Leads;
