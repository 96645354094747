<template>
  <div v-if="isLoaded && (isPdf || isImage)">
    <div class="navbar">
      <div class="text file-name"
           @click="download">
        {{ fileName }}
      </div>
      <v-btn
          dark
          color="primary"
          class="section-button"
          @click="download"
      >
        Download File
      </v-btn>
    </div>
    <div class="viewer">
      <iframe
          :src="objectUrl"
          type="application/pdf"
          class="pdf"
          v-if="isPdf"
      />
      <img
          :src="objectUrl"
          class="image"
          v-if="isImage"
      />
    </div>
  </div>
  <div v-else-if="!isLoaded && !errorStatus" class="message-wrapper">
    <div class="loader"/>
    <p class="text loader-text">File is loading</p>
  </div>
  <div v-else-if="errorStatus" class="message-wrapper">
    <p class="text error-text">File Not Found</p>
  </div>
</template>

<script>
import Axios from "@/rest/axiosInstance";

export default {
  data() {
    return {
      fileId: null,
      fileName: null,
      fileType: null,
      objectUrl: null,
      isPdf: false,
      isImage: false,
      isLoaded: false,
      errorStatus: null
    }
  },

  created() {
    const axios = Axios.axiosInstance();
    this.fileId = this.$route.params.fileId;
    let metadataUrl = `/files/${this.fileId}/metadata`;
    let self = this;
    axios.get(metadataUrl).then(response => {
      this.fileName = response.data.fileName;
      this.fileType = response.data.fileType;
      if (this.fileType === "application/pdf") {
        this.isPdf = true;
        this.createObjectUrl();
      } else if (this.fileType === "image/jpeg" || this.fileType === "image/gif" || this.fileType === "image/png") {
        this.isImage = true;
        this.createObjectUrl();
      } else {
        this.createObjectUrl(() => {
          this.download(() => {
            setTimeout(() => {
              window.close()
            }, 1000);
          });
        });
      }
    }).catch((error) => {
      if (error.response.status) {
        self.errorStatus = error.response.status;
      }
    });

  },

  destroyed() {
    window.URL.revokeObjectURL(this.objectUrl);
  },

  methods: {
    download(callback) {
      let anchor = document.createElement("a");
      anchor.href = this.objectUrl;
      anchor.download = this.fileName;
      anchor.click();
      if (callback) callback();
    },
    createObjectUrl(callback) {
      const axios = Axios.axiosInstance();
      let url = `/files/${this.$data.fileId}`;
      axios.get(url, {responseType: "arraybuffer"}).then(response => {
        const blob = new Blob([response.data], {type: this.fileType});
        this.objectUrl = URL.createObjectURL(blob);
        this.isLoaded = true;
        if (callback) callback();
      });
    }
  }
}
</script>

<style scoped>
.navbar {
  width: 100%;
  height: 50px;
  position: fixed;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.file-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.viewer {
  margin-top: 50px;
}

.pdf {
  width: 100%;
  height: calc(100vh - 50px);;
}

.image {
  max-width: 100%;
  margin-top: 7px;
}

.message-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #6699bb;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-bottom: 5px;
}

.text {
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #2a5885;
}

.loader-text {
  font-size: 16px;
}

.error-text {
  font-size: 35px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
