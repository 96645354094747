<template>
  <modal-dialog
    ref="dialog"
    title="Job Has Unsaved Changes"
    width="500"
    :visible="dialogVisible"
    :submitEnabled="true"
    submitLabel="Yes"
    cancelLabel="No"
    @submitClicked="onLeaveClicked"
    @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <slot>
          <div>
            This job has unsaved changes which will be lost if not saved. 
            Are you sure you want to exit this job?
          </div>
        </slot>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";

export default {
  data: () => ({
    dialogVisible: false,
    selected: null,
    next: null,
    error: null
  }),

  components: {
    ModalDialog
  },

  methods: {
    reset() {
      this.selected = null;
    },
    openDialog(next) {
      this.reset();
      this.next = next;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onLeaveClicked() {
      this.dialogVisible = false;
      const component = this;
      component.$emit('leaveJob', this.next);
    },
    onCancelClicked() {
      this.dialogVisible = false;
      const component = this;
      component.$emit('continueJob');
    }
  }
};
</script>
