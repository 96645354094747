import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * IntacctGLAccountBalance
 */
export class IntacctGLAccountBalance {
  glAccountNumber: string = "";
  startBalance: number = 0;
  periodBalance: number = 0;
  endBalance: number = 0;
  departmentId: string = "";
  departmentName: string = "";
  locationId: string = "";
  locationName: string = "";
  projectId: string = "";
  projectName: string = "";
  customerId: string = "";
  customerName: string = "";
  vendorId: string = "";
  vendorName: string = "";
  employeeId: string = "";
  employeeName: string = "";
  itemId: string = "";
  itemName: string = "";
  productLineId: string = "";
  classId: string = "";
  contractId: string = "";
  warehouseId: string = "";
  constructor(
    glAccountNumber: string,
    startBalance: number,
    periodBalance: number,
    endBalance: number,
    departmentId: string,
    departmentName: string,
    locationId: string,
    locationName: string,
    projectId: string,
    projectName: string,
    customerId: string,
    customerName: string,
    vendorId: string,
    vendorName: string,
    employeeId: string,
    employeeName: string,
    itemId: string,
    itemName: string,
    productLineId: string,
    classId: string,
    contractId: string,
    warehouseId: string
  ) {
    this.glAccountNumber = glAccountNumber;
    this.startBalance = startBalance;
    this.periodBalance = periodBalance;
    this.endBalance = endBalance;
    this.departmentId = departmentId;
    this.departmentName = departmentName;
    this.locationId = locationId;
    this.locationName = locationName;
    this.projectId = projectId;
    this.projectName = projectName;
    this.customerId = customerId;
    this.customerName = customerName;
    this.vendorId = vendorId;
    this.vendorName = vendorName;
    this.employeeId = employeeId;
    this.employeeName = employeeName;
    this.itemId = itemId;
    this.itemName = itemName;
    this.productLineId = productLineId;
    this.classId = classId;
    this.contractId = contractId;
    this.warehouseId = warehouseId;
  }
}

/**
 * Installer.
 */
export class Installer {
  id: number = 0;
  referenceId: string = "";
  name: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  zipCode: string = "";
  phoneNumber: string = "";
  alternatePhone: string = "";
  fax: string = "";
  email: string = "";
  taxId: string = "";
  active: boolean = false;
  retainageAmount: number = 0;
  retainagePercentage: number = 0;
  retainageBalance: number = 0;
  lastPaycheckDate: string = "";

  constructor(
    id: number,
    referenceId: string,
    name: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
    phoneNumber: string,
    alternatePhone: string,
    fax: string,
    email: string,
    taxId: string,
    active: boolean,
    retainageAmount: number,
    retainagePercentage: number,
    retainageBalance: number,
    lastPaycheckDate: string
  ) {
    this.id = id;
    this.referenceId = referenceId;
    this.name = name;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.phoneNumber = phoneNumber;
    this.alternatePhone = alternatePhone;
    this.fax = fax;
    this.email = email;
    this.taxId = taxId;
    this.active = active;
    this.retainageAmount = retainageAmount;
    this.retainagePercentage = retainagePercentage;
    this.retainageBalance = retainageBalance;
    this.lastPaycheckDate = lastPaycheckDate;
  }
}

/**
 * REST interface provided for Installers.
 */
export interface InstallersRestApi {
  getAllInstallers(): AxiosPromise<Installer[]>;
  getInstallersForJobBranch(jobId: number): AxiosPromise<Installer[]>;
  getInstallersForJobBranchIncludeInactive(jobId: number): AxiosPromise<Installer[]>;
  getInstallersForJob(jobId: number): AxiosPromise<Installer[]>;
  getInstallersForJobAndParentJob(jobId: number): AxiosPromise<Installer[]>;
  getInstallersForBranchIdAndCapabilityId(
    branchId: number,
    capabilityId: number
  ): AxiosPromise<Installer[]>;
  getInstaller(refId: string): AxiosPromise<Installer>;
  createInstaller(payload: Installer): AxiosPromise<Installer>;
  updateInstaller(refId: string, payload: Installer): AxiosPromise<Installer>;
  getRetainageBalance(refId: string): AxiosPromise<IntacctGLAccountBalance>;
}

/**
 * REST implementation provided for Installers.
 */
class Implementation implements InstallersRestApi {
  getInstaller(refId: string): AxiosPromise<Installer> {
    return Axios.axiosInstance().get<Installer>(`/installers/${refId}`);
  }
  getAllInstallers(): AxiosPromise<Installer[]> {
    return Axios.axiosInstance().get<Installer[]>("/installers/search/all");
  }
  getInstallersForJobBranch(jobId: number): AxiosPromise<Installer[]> {
    return Axios.axiosInstance().get<Installer[]>(
      `/installers/job/${jobId}/branch`
    );
  }
  getInstallersForJobBranchIncludeInactive(jobId: number): AxiosPromise<Installer[]> {
    return Axios.axiosInstance().get<Installer[]>(
      `/installers/job/${jobId}/branch/all`
    );
  }
  getInstallersForJob(jobId: number): AxiosPromise<Installer[]> {
    return Axios.axiosInstance().get<Installer[]>(`/installers/job/${jobId}`);
  }
  getInstallersForJobAndParentJob(jobId: number): AxiosPromise<Installer[]> {
    return Axios.axiosInstance().get<Installer[]>(`/installers/job/${jobId}/subjob`);
  }
  getInstallersForBranchIdAndCapabilityId(
    branchId: number,
    capabilityId: number
  ): AxiosPromise<Installer[]> {
    return Axios.axiosInstance().get<Installer[]>(
      `/installers/branch/${branchId}/installationCapability/${capabilityId}`
    );
  }
  createInstaller(payload: Installer): AxiosPromise<Installer> {
    return Axios.axiosInstance().post<Installer>("/installers", payload);
  }
  updateInstaller(refId: string, updated: Installer): AxiosPromise<Installer> {
    return Axios.axiosInstance().put<Installer>(
      `/installers/${refId}`,
      updated
    );
  }
  getInstallerActivityLogs(refId: string): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(
      `/installers/${refId}/logs`
    );
  }
  getInstallerNotes(refId: string): AxiosPromise<Installer> {
    return Axios.axiosInstance().get<Installer>(`/installers/${refId}/notes`);
  }
  createInstallerNote(refId: string, note: object): AxiosPromise<Installer> {
    return Axios.axiosInstance().post<Installer>(
      `/installers/${refId}/notes`,
      note
    );
  }
  getRetainageBalance(refId: string): AxiosPromise<IntacctGLAccountBalance> {
    return Axios.axiosInstance().get<IntacctGLAccountBalance>(
      `/installers/${refId}/retainage`
    );
  }
}

const Installers = {
  getRestApi(): InstallersRestApi {
    return new Implementation();
  }
};

export default Installers;
