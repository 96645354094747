import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {Vendor} from "./vendors";

/**
 * Distribution Vendor.
 */
export class DistributionVendor {
    id: number;
    catalogItemId: number;
    branches: [];
    vendor: Vendor;

    constructor(
        id: number,
        catalogItemId: number,
        branches: [],
        vendor: Vendor
    ) {
        this.id = id;
        this.catalogItemId = catalogItemId;
        this.branches = branches;
        this.vendor = vendor;
    }
}

/**
 * REST interface.
 */
export interface VendorsRestApi {
    getDistributionVendorsByCatalogItemInternalSku(internalSku: string): AxiosPromise<DistributionVendor[]>;
    createDistributionVendor(internalSku: string, payload: DistributionVendor): AxiosPromise<DistributionVendor>;
    updateDistributionVendor(internalSku: string, distributionVendorId: number, payload: DistributionVendor): AxiosPromise<DistributionVendor>;
    deleteDistributionVendor(internalSku: string, vendorId: number): AxiosPromise<DistributionVendor>;
}

/**
 * REST implementation.
 */
class Implementation implements VendorsRestApi {
    getDistributionVendorsByCatalogItemInternalSku(internalSku: string): AxiosPromise<DistributionVendor[]> {
        return Axios.axiosInstance().get<DistributionVendor[]>(`/distribution-vendors/${internalSku}`);
    }

    createDistributionVendor(internalSku: string, payload: DistributionVendor): AxiosPromise<DistributionVendor> {
        return Axios.axiosInstance().post<DistributionVendor>(`/distribution-vendors/${internalSku}`, payload);
    }

    updateDistributionVendor(internalSku: string, distributionVendorId: number, updated: DistributionVendor): AxiosPromise<DistributionVendor> {
        return Axios.axiosInstance().put<DistributionVendor>(`/distribution-vendors/${internalSku}/${distributionVendorId}`, updated);
    }

    deleteDistributionVendor(internalSku: string, vendorId: number): AxiosPromise<DistributionVendor> {
        return Axios.axiosInstance().delete<DistributionVendor>(`/distribution-vendors/${internalSku}/${vendorId}`);
    }
}

const DistributionVendors = {
    getRestApi(): VendorsRestApi {
        return new Implementation();
    }
};

export default DistributionVendors;
