<template>
  <modal-dialog
      ref="dialog"
      title="Are you sure you want to delete the days off?"
      width="500"
      :visible="dialogVisible"
      submitLabel="Delete"
      cancelLabel="Cancel"
      @submitClicked="onDeleteClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
      :loading="loading"
  >
    <v-container>
      <div>Delete the days off from
        {{this.startDate}} to {{this.endDate}} for the installer crew <b>{{ this.cellData.key.installerCrewName }}</b>?</div>
    </v-container>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import moment from "moment";
import InstallerCrewDayOff from "@/rest/installer/installerCrewDayOff";

export default {
  components: {
    ModalDialog
  },

  data() {
    return {
      cellData: null,
      loading: false,
      dialogVisible: false
    }
  },

  computed: {
    startDate() {
      return moment(this.cellData.key.startDate).format('MM/DD/YYYY');
    },
    endDate() {
      return moment(this.cellData.key.endDate).format('MM/DD/YYYY');
    }
  },

  methods: {
    openDialog(cellData) {
      this.cellData = cellData;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.loading = false;
      this.dialogVisible = false
    },
    onCancelClicked() {
      this.closeDialog();
    },
    onDeleteClicked() {
      this.loading = true;
      InstallerCrewDayOff.getRestApi().deleteDayOff(this.cellData.data.id)
          .then(() => {
            this.closeDialog();
            this.$emit('deleteConfirmed');
          })
          .catch((error) => {
            this.loading = false;
            this.closeDialog();
            this.emitError(error.response.data.message);
          });

    },

    emitError(error) {
      this.$emit('emitError', error);
    },

  }
};
</script>