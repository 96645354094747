import DateUtils from "@/assets/utils/dateUtils";
import {EdmLiteral} from "devextreme/data/odata/utils"

const DateFormatter = {
    formatDate(date) {
        return date.split("T")[0].replaceAll('-', '/');
    },
    formatDateInMMDDYYYYFormat(date) {
        return DateUtils.buildDateTime(date)?.toFormat("MM/dd/y");
    },
    formatDateInUtcAndMMDDYYYYFormat(date) {
        return DateUtils.buildDateTime(date)
            ?.setZone('utc')
            .toFormat('MM/dd/y');
    },
    formatDateInD(date) {
        return DateUtils.buildDateTime(date)?.toFormat('d');
    },
    formatDateInDMMM(date) {
        return DateUtils.buildDateTime(date)?.toFormat("d MMM");
    },
    formatDateInYYYYMMDDFormat(date) {
        return DateUtils.buildDateTime(date)?.toFormat("y-MM-dd");
    },
    formatDateInUtcAndYYYYMMDDFormat(date) {
        return DateUtils.buildDateTime(date)
            ?.setZone('utc')
            .toFormat("y-MM-dd");
    },
    formatDateToUsersTimezoneInMMDDYYYYFormat(date) {
        const dateTime = DateUtils.buildDateTime(date);
        return DateUtils.buildDateTime(date)
            .plus({minutes: dateTime.offset})
            .toFormat("MM/dd/y");
    },
    getStartOfTheDayFromDate(date) {
        //Only for Js Date Class
        let startOfTheDay = new Date(date);
        startOfTheDay.setHours(0);
        startOfTheDay.setMinutes(0);
        startOfTheDay.setSeconds(0);
        startOfTheDay.setMilliseconds(0);
        return startOfTheDay;
    },
    getDayOfWeek(date) {
        const dateTime = DateUtils.buildDateTime(date);
        return dateTime.weekdayLong;
    },
    getYear(date) {
        const dateTime = DateUtils.buildDateTime(date);
        return dateTime.year;
    },
    formatDateWithFormat(isoString, format) {
        const date = new Date(isoString);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');

        return format
            .replace('YYYY', year)
            .replace('MM', month)
            .replace('DD', day);
    },
    getDateAtDayStart(filterValue) {
        return DateUtils.buildDateTime(filterValue).startOf('day').toUTC().toISO();
    },
    getDateAtDayEnd(filterValue) {
        return DateUtils.buildDateTime(filterValue).endOf('day').toUTC().toISO();
    },
    calculateLocalDateFilterExpression(dataField, filterValue, selectedFilterOperation, target) {
        const formatODataDate = this.formatToLocalDate(filterValue);
        const edmLiteral = new EdmLiteral(formatODataDate);

        // for not array filters like eq, le, ge, lt, gt
        if (!Array.isArray(filterValue)) {
            if (selectedFilterOperation === "=") {
                return [[dataField, ">=", edmLiteral], "and", [dataField, "<=", edmLiteral]];
            } else {
                return [dataField, selectedFilterOperation, edmLiteral];
            }
        } else if (selectedFilterOperation === "between" && Array.isArray(filterValue)) {
            return [
                [dataField, ">=", new EdmLiteral(this.formatToLocalDate(filterValue[0]))],
                "and",
                [dataField, "<=", new EdmLiteral(this.formatToLocalDate(filterValue[1]))]
            ];
        }
    },
    formatToLocalDate(value) {
        if (value instanceof Date) {
            const year = value.getFullYear();
            const month = String(value.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(value.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        return null;
    }
}
export default DateFormatter;
