<template>
  <drawer-form
      ref="form"
      title="Create Market"
      submitLabel="Save Market"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <market-edit-form ref="marketEditForm"/>
  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm.vue";
import MarketEditForm from "./MarketEditForm.vue";
import Markets from "@/rest/markets";

export default {
  components: {
    DrawerForm,
    MarketEditForm
  },

  data() {
    return {inProgress: false};
  },

  methods: {
    onCancel() {
      this.$refs.form.resetValidation();
      this.$refs.marketEditForm.loadForm();
      this.$emit("cancelClicked");
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.createMarket();
      }
    },
    refreshForm() {
      this.$refs.marketEditForm.resetFormDefaults();
      this.$refs.form.resetValidation();
    },
    async createMarket() {
      try {
        this.inProgress = true;
        const payload = this.$refs.marketEditForm.buildPayload();
        const response = await Markets.getRestApi().createMarket(payload);
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
