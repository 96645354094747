<template>
  <div>
    <div style="border-top: 1px solid #ddd;"></div>
    <dx-data-grid
        ref="grid"
        :data-source="dataSource"
        :show-borders="showBorders"
        :height="height"
        :load-panel="loadPanelConfig"
        :hover-state-enabled="hoverStateEnabled"
        :columnMinWidth="columnMinWidth"
        :columnAutoWidth="columnAutoWidth"
        :noDataText="noDataTextValue"
        :editing="editing"
        @selectionChanged="onSelectionChanged"
        @rowRemoving="onDeleteClicked"
        @rowUpdated="onValueChanged($event)"
        @editing-start="onEditRowClicked"
        @row-prepared="onRowPrepared"
        @contentReady="onContentReady"
    >

      <dx-selection
          v-if="showSelectionMode"
          :mode="selectionMode"
          :select-all-mode="selectionConfig.selectAllMode"
          :show-check-boxes-mode="selectionConfig.showCheckBoxesMode"
      />

      <dx-paging :page-size="pageSize"/>
      <dx-pager
          v-if="showPager"
          :show-page-size-selector="true"
          :show-info="true"
          infoText="Page {0} of {1} - {2} items"
      />
      <slot/>

      <template #delete="cellData">
        <div>
          <delete-row-cell-template
              v-if="!hideDelete"
              :cell-data="cellData"
              @deleteClicked="onDeleteClicked"
          />
        </div>
      </template>

      <template #link="cellData">
        <div>
          <link-row-cell-template
              :cell-data="cellData"
              file-id-property="id"
              file-name-property="fileName"
              @linkClicked="onLinkClicked"
          />
        </div>
      </template>

      <template #editCommissionPercentage="cellData">
        <div>
          <edit-commission-percentage-cell-template
              :cell-data="cellData"
              @editValue="onEditValue"
          />
        </div>
      </template>

      <template #editPoItemQuantityCellTemplate="cellData">
        <div>
          <edit-po-item-quantity-cell-template
              :cell-data="cellData"
              :hasReceivedItems="hasReceivedItems"
              @editValue="onEditValue"
          />
        </div>
      </template>

      <template #closePoItemButtonCellTemplate="cellData">
        <div>
          <close-po-item-button-cell-template
              :cell-data="cellData"
              @onClickClosePoItem="closePoItemClicked"
          />
        </div>
      </template>

      <template #closeTRItemButtonCellTemplate="cellData">
        <div>
          <close-transfer-request-item-button-cell-template
              :cell-data="cellData"
              :transfer-request-status="transferRequestStatus"
              @onClickCloseTRItem="closeTRItemClicked"
          />
        </div>
      </template>

      <template #changeEtaButtonCellTemplate="cellData">
        <div class="pt-5">
          <po-eta-cell-template
              :cell-data="cellData"
              @etaChanged="etaChanged"
          />
        </div>
      </template>

      <template #hasNoteTemplate="cellData">
        <div class="pt-5">
          <has-note-attached
              :cell-data="cellData"
          />
        </div>
      </template>

      <template #poCheckIn="cellData">
        <div>
          <purchase-order-checkin
              :cell-data="cellData"
              @checkIn="onCheckIn"
          />
        </div>
      </template>

      <template #transferRequestItemStatusIcon="cellData">
        <status-icon-cell-template
            :statusName="cellData.text"
            :templateName="'transferRequestItemStatusIcon'"
        />
      </template>

      <template #checkInIconStatus="cellData">
        <transfer-request-item-check-in-status-cell-template
            :cell-data="cellData"
        />
      </template>

      <template #checkOutIconStatus="cellData">
        <transfer-request-item-check-out-status-cell-template
            :cell-data="cellData"
        />
      </template>

      <template v-slot:enterQuantityTemplate="cellData">
        <enter-quantity-cell-template
            ref="quantityEntered"
            :cellData="cellData"
            :disabled="inputDisabled(cellData)"
            @valueEntered="onValueEntered"
        />
      </template>

      <template #booleanStatusCellTemplate="cellData">
        <boolean-status-cell-template
            :cell-data="cellData"
        />
      </template>

      <dx-editing
          :allow-updating="editingAllowUpdating && this.allowModifying"
          :allow-deleting="editingAllowDeleting && this.allowModifying"
          mode="row">
        <dx-texts
            :confirmDeleteMessage="deleteConfirmMessage"
        />
      </dx-editing>
    </dx-data-grid>
    <slot name="footer"/>
    <slot name="selectedRowsCount"/>
  </div>
</template>

<script>
import DeleteRowCellTemplate from "../../../components/common/grid/cellTemplates/DeleteRowCellTemplate.vue";
import LinkRowCellTemplate from "./cellTemplates/LinkRowCellTemplate";
import EditCommissionPercentageCellTemplate from "./cellTemplates/EditCommissionPercentageCellTemplate";
import HasNoteAttached from "./cellTemplates/HasNoteAttached";
import PurchaseOrderCheckin from "../../../components/common/grid/PurchaseOrderCheckin";
import FilterInitializer from "../../../components/common/grid/FilterInitializer";
import EditPoItemQuantityCellTemplate from "./cellTemplates/EditPoItemQuantityCellTemplate";
import ClosePoItemButtonCellTemplate from "./cellTemplates/ClosePoItemButtonCellTemplate";
import CloseTransferRequestItemButtonCellTemplate from "./cellTemplates/CloseTransferRequestItemButtonCellTemplate.vue";
import PoEtaCellTemplate from "./cellTemplates/PoEtaCellTemplate";
import {DxColumn, DxDataGrid, DxEditing, DxPager, DxPaging, DxSelection, DxTexts} from "devextreme-vue/ui/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import StatusIconCellTemplate from "./cellTemplates/StatusIconCellTemplate.vue";
import TransferRequestItemCheckInStatusCellTemplate
  from "./cellTemplates/branchCheckInCheckOut/TransferRequestItemCheckInStatusCellTemplate.vue";
import TransferRequestItemCheckOutStatusCellTemplate
  from "./cellTemplates/branchCheckInCheckOut/TransferRequestItemCheckOutStatusCellTemplate.vue";
import EnterQuantityCellTemplate from "./cellTemplates/TextFieldWithInputCellTemplate.vue";
import BoxQuantity from "@/assets/boxQuantity";
import Rules from "@/assets/rules";
import {InventoryItemCheckedStatus, ShipmentStatuses} from "@/assets/statusIcons/statusIcons";
import BooleanStatusCellTemplate from "@/components/common/grid/cellTemplates/BooleanStatusCellTemplate.vue";

export default {
  components: {
    BooleanStatusCellTemplate,
    EnterQuantityCellTemplate,
    TransferRequestItemCheckInStatusCellTemplate,
    TransferRequestItemCheckOutStatusCellTemplate,
    StatusIconCellTemplate,
    PoEtaCellTemplate,
    CloseTransferRequestItemButtonCellTemplate,
    ClosePoItemButtonCellTemplate,
    EditPoItemQuantityCellTemplate,
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxPager,
    DxPaging,
    DxEditing,
    DxTexts,
    DeleteRowCellTemplate,
    LinkRowCellTemplate,
    EditCommissionPercentageCellTemplate,
    HasNoteAttached,
    PurchaseOrderCheckin
  },

  props: {
    dataSource: {},
    showBorders: Boolean,
    height: {},
    hoverStateEnabled: {},
    editableCell: {},
    columnMinWidth: Number,
    columnAutoWidth: Boolean,
    noDataText: String,
    confirmDeleteMessage: String,
    showPager: Boolean,
    hideDelete: Boolean,
    pageSize: Number,
    editingAllowUpdating: {
      type: Boolean,
      default: false
    },
    editingAllowPerRow: {
      type: Function,
      default: function () {
        return true;
      }
    },
    editingAllowDeleting: {
      type: Boolean,
      default: false
    },
    deleteConfirmMessage: {
      type: String,
      default: ''
    },
    hasReceivedItems: Boolean,
    transferRequestStatus: String,
    selectionMode: {
      type: String,
      default: "single",
      required: false
    },
    showSelectionMode: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  watch: {
    noDataText: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.noDataTextValue = newVal;
        }
      }
    },
    confirmDeleteMessage: {
      immediate: true,
      handler(newVal) {
        this.editing.texts.confirmDeleteMessage = newVal !== null ? newVal : null;
      }
    }
  },

  mounted() {
    FilterInitializer.initializeCustomFilterOperations(this.getGridInstance());
  },

  data() {
    return {
      noDataTextValue: "No data",
      editing: {
        texts: {
          confirmDeleteMessage: null
        }
      },
      loadPanelConfig: {
        enabled: false
      },
      selectionConfig: {
        mode: "single",
        selectAllMode: "page",
        showCheckBoxesMode: "always",
      },
    };
  },

  methods: {
    hideSelection(){
      this.selectionConfig.showCheckBoxesMode = "none"

      this.$refs.grid.instance.getVisibleRows().forEach(row => {
          this.makeRowDisabledAndHideRowSelectionCheckBox(row);
      });
    },
    showSelection(){
      this.selectionConfig.showCheckBoxesMode = "always"
    },
    onSelectionChanged(event) {
      let data = event.selectedRowsData[0];
      this.$emit('rowSelected', data);
      event.preventDefault();
      // clear selected row (by default) to all grids except: distributionVendorGrid
      if (this.getGridRefName() !== 'distributionVendorGrid') {
        this.clearSelection();
      }
    },
    onDeleteClicked(cellData) {
      if (this.editingAllowDeleting) {
        cellData.cancel = true;
      }
      this.$emit('deleteClicked', cellData);
    },
    onValueChanged(cellData) {
      this.$emit('valueChanged', cellData);
    },
    onLinkClicked(cellData) {
      this.$emit('linkClicked', cellData);
    },
    onCheckIn(cellData) {
      this.$emit('checkIn', cellData);
    },
    onEditValue(cellData) {
      this.$emit('editValue', cellData);
    },
    deleteRow(rowIndex) {
      this.$refs.grid.instance.deleteRow(rowIndex);
    },
    clearSelection() {
      this.$refs.grid.instance.clearSelection();
    },
    filter(data) {
      this.$refs.grid.instance.filter(data);
    },
    async refresh() {
      this.$refs.grid.instance.refresh();
    },
    closePoItemClicked(cellData) {
      this.$emit('onClickClosePoItem', cellData);
    },
    closeTRItemClicked(cellData) {
      this.$emit('onClickCloseTRItem', cellData);
    },
    etaChanged(cellData, etaDate) {
      this.$emit('onEtaChanged', cellData, etaDate);
    },
    clearSortingAndFiltering() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.clearSorting();
    },
    getGridInstance() {
      return this.$refs.grid.instance;
    },
    allowModifying(event) {
      return this.editingAllowPerRow(event.row.data);
    },
    onEditRowClicked(cellData) {
      this.$emit('editingStarted', cellData)
    },
    getGridRefName() {
      return this.$parent.$vnode.data.ref;
    },
    getGridUrl(element) {
      // might be a bit ugly, but this func & getGridRefName() should be revisited in vue 3+
      return element.component.getDataSource()._store._url;
    },
    onRowPrepared(e) {
      if (this.getGridUrl(e).includes('InventoryItemsForBranchTransferCheckOuts')) {
        if (e.data.CheckedOut || e.data.Status === InventoryItemCheckedStatus.CHECKED_OUT.name
            || e.data.Status === InventoryItemCheckedStatus.IS_CLOSED.name || e.data.IsClosed) {
          this.makeRowDisabledAndHideRowSelectionCheckBox(e);
        }
      } else {
        if (this.showSelectionMode === false && e.columns.length === 14) { // TODO: check for the better approach
          e.rowElement.firstChild.style.visibility = "hidden";
        }
        if ((e.data.ShipmentStatus !== ShipmentStatuses.IN_PROGRESS.name && e.data.Status === InventoryItemCheckedStatus.CHECKED_OUT.name) ||
            e.data.Status === InventoryItemCheckedStatus.IS_FULLY_CHECKED_IN.name ||
            e.data.Status === InventoryItemCheckedStatus.OVERRECEIVED.name ||
            e.data.Status === InventoryItemCheckedStatus.CLOSED_AS_SHORT_SHIPPED.name ||
            e.data.Status === InventoryItemCheckedStatus.NOT_CHECKED_OUT.name ||
            e.data.Status === InventoryItemCheckedStatus.IS_CLOSED.name ||
            (e.data.Status === InventoryItemCheckedStatus.PARTIALLY_CHECKED_IN.name && e.data.IsClosed)) {
          this.makeRowDisabledAndHideRowSelectionCheckBox(e);
        }
      }
    },
    makeRowDisabledAndHideRowSelectionCheckBox(e) {
      e.rowElement.classList.add('disable-selection');
      if (this.showSelectionMode) {
        e.rowElement.firstChild.style.visibility = "hidden";
      }
    },
    onValueEntered(value, cellData) {
      let result = Rules.isPositiveOrZero(value);
      if (value < 0 || !result) {
        this.$emit('invalidInput', value, cellData);
      } else {
        this.updateCell("Rounded", cellData, value);
        this.$emit('validInput', value, cellData);
      }
    },
    inputDisabled(cellData) {
      return this.showSelectionMode || (cellData.data.CheckedIn || cellData.data.ReceivedQuantity >= cellData.data.ExpectedQuantity
          || cellData.column.dataField === "Rounded");
    },
    updateCell(dataFiledName, cellData, value) {
      const gridInstance = this.getGridInstance();

      let formatted = BoxQuantity.roundValueToBoxQuantity(value, cellData.data.BoxQuantity);
      cellData.data.Entered = value;

      if (Number(formatted) === 0) {
        gridInstance.cellValue(cellData.rowIndex, "Rounded", null);
      } else {
        gridInstance.cellValue(cellData.rowIndex, "Rounded", formatted);
      }
      gridInstance.cellValue(cellData.rowIndex, "Entered", value);
    },
    onContentReady() {
      this.$emit('contentReady');
    }
  }
};
</script>


<style>
.disable-selection {
  pointer-events: none;
  opacity: 0.6;
}

#no-selection-allowed {
  pointer-events: none;
}

</style>
