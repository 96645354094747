import { render, staticRenderFns } from "./PurchaseOrderHeaders.vue?vue&type=template&id=4a3e4ebe&scoped=true"
import script from "./PurchaseOrderHeaders.vue?vue&type=script&lang=js"
export * from "./PurchaseOrderHeaders.vue?vue&type=script&lang=js"
import style0 from "./PurchaseOrderHeaders.vue?vue&type=style&index=0&id=4a3e4ebe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3e4ebe",
  null
  
)

export default component.exports