<template>
  <v-autocomplete
      v-model="selected"
      :items="options"
      :loading="loading"
      :readonly="readonly"
      no-data-text="No active installers in branch"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      item-disabled="inactive"
      :multiple="false"
      placeholder="Search Installers"
      id="installer-search-field"
      :rules="required ? [rules.required] : []"
      attach
      dense
  />
</template>

<script>
import Rules from "@/assets/rules";

export default {
  components: {},

  data() {
    return {
      selected: null,
      installers: [],
      loading: false,
      rules: {
        required: (value) => Rules.required(value)
      },
    };
  },

  props: {
    value: {
      type: Number,
      required: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search Installers'
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      required: false,
      default: "name"
    },
    itemValue: {
      type: String,
      required: false,
      default: "id"
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$data.selected = newVal;
        }
      },
    },
    selected(value) {
      if (value) {
        this.$emit("input", value);
      } else {
        this.$emit("cleared");
      }
    }
  },

  methods: {
    getSelected() {
      return this.selected;
    },
    clearSelected() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
.v-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.v-list > div {
  width: 50%;
}
</style>
