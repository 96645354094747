import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";

/**
 * Payment Types.
 */
export class PaymentType {
  id: number = 0;
  name: string = "";
  description: string = "";
  sageIntegration: boolean = true;
  hold: boolean = false;
  planCodeRequired: boolean = false;
  requiredNonEmptyPaymentId: boolean = false;

  constructor() {}
}

export interface PaymentTypesRestApi {
  getPaymentTypeById(id: number): AxiosPromise<PaymentType>;
  getAllPaymentTypes(): AxiosPromise<PaymentType[]>;
  getEnabledPaymentTypes(): AxiosPromise<PaymentType[]>;
}

class Implementation implements PaymentTypesRestApi {
  getPaymentTypeById(id: number): AxiosPromise<PaymentType> {
    return Axios.axiosInstance().get<PaymentType>(`/paymentTypes/${id}`);
  }
  getAllPaymentTypes(): AxiosPromise<PaymentType[]> {
    return Axios.axiosInstance().get<PaymentType[]>("/paymentTypes/search/all");
  }
  getEnabledPaymentTypes(): AxiosPromise<PaymentType[]> {
    return Axios.axiosInstance().get<PaymentType[]>(
      "/paymentTypes/search/all/enabled"
    );
  }
}

const PaymentTypes = {
  getRestApi(): PaymentTypesRestApi {
    const api = new Implementation();
    return api;
  }
};

export default PaymentTypes;
