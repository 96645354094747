<template>
  <v-row v-show="showGoBackButton" class="mt-4">
    <v-col cols="12">
      <v-btn
          :id="id"
          class="full-width white--text"
          color="#59b"
          large
          :disabled="disabled"
          append-icon="search"
          @click="onGoBackClicked"
      >
        <v-icon>arrow_back</v-icon>
        <span class="go-back-text">{{ label }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'go-back-full-width-button'
    },
    showGoBackButton: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Go Back'
    }
  },
  methods: {
    onGoBackClicked() {
      this.$emit('goBackClicked');
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.go-back-text {
  width: 100%;
  padding-right: 24px;
}
</style>
