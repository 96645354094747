<template>
  <dx-data-grid
      ref="itemsGridByBranch"
      :data-source="dataSource"
      :show-borders="true"
      :hover-state-enabled="true"
      :load-panel="{ enabled: false }"
      @selectionChanged="onRowSelected"
      :columnMinWidth="150"
  >
    <dx-filter-row :visible="true"/>
    <dx-header-filter :visible="true"/>
    <dx-column-fixing :enabled="true"/>
    <dx-paging :page-size="5"/>
    <dx-pager
        :show-page-size-selector="false"
        :allowed-page-sizes="[5]"
        :show-info="true"
    />
    <dx-selection mode="single"/>
    <dx-column
        data-field="InternalSku"
        caption="Internal SKU"
        data-type="string"
    />
    <dx-column
        data-field="ProductType"
        caption="Product Type"
        data-type="string"
    />
    <dx-column
        data-field="StyleName"
        caption="Style"
        data-type="string"
    />
    <dx-column
        data-field="Color"
        caption="Color"
        data-type="string"
    />
    <dx-column
        data-field="QuantityAvailable"
        caption="Qty Available"
        data-type="number"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Description"
        caption="Description"
        data-type="string"
    />
  </dx-data-grid>
</template>

<script>
import "devextreme/data/odata/store";
import {
  DxColumn,
  DxColumnFixing,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxSelection
} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSelection
  },

  props: {
    branchId: {
      type: Number,
      required: false
    },
    dataSource: {
      type: Object | null,
      required: true
    }
  },

  data() {
    return {
      currencyFormat: {
        type: "currency",
        precision: 2
      },
    }
  },

  methods: {
    onRowSelected(event) {
      this.$emit('rowSelected', event);
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.clearSorting();
      gridInstance.columnOption('InternalSku', {
        sortOrder: 'asc',
        sortIndex: 0
      });
      gridInstance.filter(["BranchId", "=", this.branchId]);
    },
    getGridInstance() {
      return this.$refs.itemsGridByBranch.instance;
    },
    deselectItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    getSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return [...selectedRows];
    },
  }
};
</script>
