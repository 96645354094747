<template>
  <transition name="slide-fade">
    <span :class="className()" v-if="ifShow">{{ tipText }}</span>
  </transition>
</template>
<script>
export default {

  props: {
    ifShow: {
      type: Boolean,
      required: true,
      default: false
    },
    tipText: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: false,
      default: "bottom-left"
    }
  },

  methods: {
    className() {
      return `tooltip ${this.location}`;
    }
  }
};
</script>

<style scoped>
.tooltip {
  position: absolute;
  background-color: #696969;
  opacity: 0.9;
  color: white;
  border-radius: 2px;
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 500;
  z-index: 1000;
}

.tooltip.bottom-left {
  margin-top: 40px;
  margin-left: -55px;
}

.tooltip.bottom {
  margin-top: 40px;
  margin-left: -55px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
