<template>
  <v-container grid-list-lg text-xs-center>
    <v-row>
      <v-col>
        <v-text-field
            :rules="[rules.max255]"
            id="vendor-reference-number"
            v-model="vendorReferenceNumber"
            label="Vendor Reference Number"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Rules from "@/assets/rules";

const formDefaults = {
  vendorReferenceNumber: null
};

export default {
  components: {},

  data() {
    return {
      ...formDefaults,
      rules: {
        max255: value => Rules.maxValue(value, 255)
      }
    };
  },

  methods: {
    onCancel() {
      this.$emit("cancelClicked");
    },
    buildPayload() {
      const payload = {};
      payload.vendorReferenceNumber = this.vendorReferenceNumber;
      return payload;
    },
    loadForm(payload = Object.assign(formDefaults)) {
      this.vendorReferenceNumber = payload.vendorReferenceNumber;
    }
  }
};
</script>
