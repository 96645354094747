<template>
  <v-container class="pa-4">
    <v-row class="ma-1 mb-0">
      <v-col cols="12" class="text-center full-width">

        <v-alert
            v-if="showALANotification"
            border="left"
            colored-border
            type="warning"
            elevation="2"
            prominent
        >
          <v-row class="pa-1">
            Automatic lead assignment is disabled for branch {{ selectedBranchName }}<br>
            Leads will not be assigned automatically until ALA is active on a Branch level
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="pt-0">
        <v-checkbox
            v-model="enableAutomaticLeadAssignment"
            color="primary"
            label="Enable ALA"
        />
      </v-col>
      <v-spacer/>
      <v-col cols="4">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-text-field
            :rules="[rules.required]"
            label="* Company Name"
            v-model="companyName"
            placeholder=" "
            id="sales-rep-form-company-name"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-text-field
            id="sales-rep-form-first-name"
            :rules="[rules.required, rules.max40]"
            maxlength="40"
            v-model="firstName"
            label="* First Name"
            placeholder=" "
            autocomplete="new-sales-rep-first-name"
            counter
            persistent-placeholder
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-text-field
            id="sales-rep-form-last-name"
            :rules="[rules.required, rules.max40]"
            maxlength="40"
            v-model="lastName"
            label="* Last Name"
            placeholder=" "
            autocomplete="new-sales-rep-last-name"
            counter
            persistent-placeholder
        />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-text-field
            id="sales-rep-form-email"
            :rules="[rules.required, rules.email]"
            v-model="email"
            label="* Email"
            placeholder=" "
            autocomplete="new-sales-rep-email"
            validate-on-blur
            persistent-placeholder
        />
      </v-col>
      <v-col cols="12" class="pt-0">
        <address-form-with-autocomplete
            ref="addressForm"
            :usa-only="true"
            :search-only="true"
            :coordinatesRequired="true"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="pt-0">
        <v-text-field
            id="sales-rep-form-phone"
            :rules="[rules.required, rules.phoneNumber]"
            v-model="phone"
            label="* Phone"
            placeholder=" "
            v-facade="phoneNumberMask"
            autocomplete="new-sales-rep-phone"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-text-field
            id="sales-rep-form-fax"
            :rules="[rules.phoneNumber]"
            v-model="fax"
            label="Fax"
            placeholder=" "
            v-facade="phoneNumberMask"
            autocomplete="new-sales-rep-fax"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="12" class="pt-0">
        <market-search-field
            ref="marketSearchField"
            :getItemsOnComponentCreation="true"
            :marketIds="marketIds"
            :selectAllEnabled="selectAllEnabled"
            @input="onMarketSelect"
        />
      </v-col>
      <v-col cols="12" class="pt-0">
        <branches-dropdown-autocomplete
            id="sales-rep-branch"
            label="* Branch"
            :value="branchReferenceId"
            :branches="branches"
            :required="true"
            :readonly="branchesReadonly"
            :placeholder="branchesReadonly ? 'Please select Market first' : 'Select Branch'"
            :selectAllEnabled="false"
            :multiple="false"
            @inputSelected="updateBranchReferenceId"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="pt-0">
        <v-text-field
            label="State License Number"
            placeholder=" "
            validationName="State License Number"
            :value="stateLicenseNumber"
            @input="onStateLicenseNumberAdded"
            id="sales-rep-state-license-number"
            type="string"
            maxlength="20"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <date-picker-with-type-in-text-field
            label="Expiration Date"
            validationName="Expiration Date"
            :value="licenseExpirationDate"
            @input="onLicenseExpirationDateChanged"
            id="sales-rep-expiration-date"
            type="date"
            :optional="true"
            :allowedDates="allowedDates"
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-text-field
            label="* Max Leads per day"
            :rules="[rules.required, rules.isPositiveAndGreaterThanZero]"
            placeholder=" "
            validationName="Max Leads per day"
            :value="maxLeadPerDay"
            @input="onMaxLeadPerDayChanged"
            id="sales-rep-max-lead-per-day"
            type="text"
            v-facade="'#'"
            maxlength="20"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-text-field
            label="GPL Start Date"
            placeholder=""
            validationName="GPL Start Date"
            :value="gplStartDate"
            id="sales-rep-gpl-start-date"
            type="text"
            maxlength="20"
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>
    <v-row v-if="showTierAndGPLEnabled">
      <v-col cols="6" class="pt-0">
        <v-text-field
            label="Tier"
            placeholder=""
            validationName="Tier"
            :value="tierName"
            id="sales-rep-tier"
            type="text"
            maxlength="20"
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col cols="6" class="pt-0">
        <v-text-field
            label="GPL"
            placeholder=""
            validationName="GPL"
            :value="gpl"
            id="sales-rep-gpl"
            type="number"
            maxlength="20"
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>

    <div v-if="isEditForm">
      <alert-message
          :showAlert="showMfaAlert"
          :value="active"
          :alertMessage="alertMessage"
          @onClearError="clearAlertMessageError"
      />
      <user-mfa-control-tools
          :disabled="isMfaSwitchDisabled"
          :mfaEnabled="mfaEnabled"
          :mfaSetUp="salesRepFormData.mfaSetUp"
          @resetMfaClicked="resetMfaClicked"
          @mfaEnabledValueChanged="mfaEnabledValueChanged"
      />
    </div>
    <v-row v-else class="ma-0 pa-0">
      <slot name="mfa-control-tools"/>
    </v-row>

    <v-row>
      <password-helper-container
          ref="passwordHelper"
          :changePasswordEnabled="changePasswordEnabled"
          :initialPasswordEnabled="initialPasswordEnabled"
          :email="email"
      />
    </v-row>

  </v-container>
</template>
<script>
import MarketSearchField from "@/components/searchFields/MarketsAutocomplete.vue";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import PasswordHelperContainer from "@/components/passwordManagement/PasswordHelperContainer.vue";
import AddressFormWithAutocomplete from "@/components/common/form/AddressFormWithAutocomplete.vue";
import Rules from "@/assets/rules";
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField";
import moment from "moment";
import Constants from "@/assets/constants";
import PhoneMask from "../../assets/phoneMask";
import BranchesDropdownAutocomplete from "@/components/searchFields/BranchesDropdownAutocomplete.vue";
import branches from "@/rest/branches";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";
import Roles from "@/assets/roles";
import AlertMessage from "@/components/common/AlertMessage.vue";

const formDefaults = {
  id: null,
  firstName: null,
  lastName: null,
  companyName: null,
  phone: null,
  fax: null,
  email: null,
  taxId: null,
  marketIds: [],
  branchReferenceId: null,
  selectedBranchName: null,
  branches: [],
  branchesReadonly: true,
  active: true,
  enableAutomaticLeadAssignment: false,
  stateLicenseNumber: null,
  licenseExpirationDate: null,
  maxLeadPerDay: null,
  tierName: null,
  gplStartDate: null,
  tier: null,
  gpl: null,
  selectAllEnabled: false,
  mfaEnabled: false,
  showMfaAlert: false,
  alertMessage: ''
};

export default {
  components: {
    AlertMessage,
    UserMfaControlTools,
    BranchesDropdownAutocomplete,
    MarketSearchField,
    StatusDropdown,
    PasswordHelperContainer,
    AddressFormWithAutocomplete,
    DatePickerWithTypeInTextField
  },

  props: {
    changePasswordEnabled: {
      type: Boolean,
      default: false,
    },
    initialPasswordEnabled: {
      type: Boolean,
      default: false,
    },
    showTierAndGPLEnabled: {
      type: Boolean,
      default: false,
    },
    salesRepFormData: {
      type: [Object, null]
    },
    isEditForm: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showALANotification() {
      let result = false;
      this.branches.forEach(branch => {
        if (branch.referenceId === this.branchReferenceId && !branch.isAutomaticLeadAssignmentEnabled && this.enableAutomaticLeadAssignment) {
          result = true;
        }
      });
      return result;
    },
    isMfaSwitchDisabled() {
      return !this.active || !Roles.isSuperAdminUser();
    }
  },

  data() {
    return Object.assign(
        {
          displayTaxId: false,
          rules: {
            required: Rules.required,
            phoneNumber: Rules.phoneNumberWithDelimiter,
            email: Rules.email,
            max40: value => Rules.maxValue(value, 40),
            max255: value => Rules.maxValue(value, 255),
            password: Rules.password,
            isPositiveAndGreaterThanZero: value => Rules.isPositiveAndGreaterThanZero(value)

          },
          phoneNumberMask: Constants.phoneNumberMask
        },
        formDefaults
    );
  },

  watch: {
    salesRepFormData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.loadForm(val);
      }
    }
  },

  methods: {
    updateBranchReferenceId(value) {
      this.branchReferenceId = value;
      this.branches.forEach(branch => {
        if (branch.referenceId === this.branchReferenceId) {
          this.selectedBranchName = branch.name;
        }
      });
    },
    onMarketSelect(value) {
      this.marketIds = value;
      if (value) {
        this.selectAllEnabled = true;
      }

      if (value && value.length !== 0) {
        branches.getRestApi().getBranchesByMarkets(value).then(response => {
          this.branches = response.data;
          this.branchesReadonly = false;
        });
      } else {
        this.branches = [];
        this.branchReferenceId = null;
        this.branchesReadonly = true;
      }
    },
    changeStatus(status) {
      if (this.active !== status) {
        this.active = status;
        this.mfaEnabled = !!status;
        this.showAlertNotification(status);
      }
    },
    buildPayload() {
      let payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      payload.id = this.id;
      payload.firstName = this.firstName;
      payload.lastName = this.lastName;
      payload.companyName = this.companyName;
      payload.phone = PhoneMask.unMaskPhone(this.phone);
      payload.fax = this.fax;
      payload.email = this.email;
      payload.selectedMarketIds = this.marketIds;
      payload.sendTemporaryPassword = this.$refs.passwordHelper.sendTemporaryPassword;
      payload.newPassword = this.$refs.passwordHelper.newPassword;
      payload.active = this.active;
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      payload.latitude = addressData.latitude;
      payload.longitude = addressData.longitude;
      payload.enableAutomaticLeadAssignment = this.enableAutomaticLeadAssignment;
      payload.stateLicenseNumber = this.stateLicenseNumber;
      payload.licenseExpirationDate = this.licenseExpirationDate;
      payload.maxLeadPerDay = this.maxLeadPerDay;
      payload.automaticLeadAssignmentBranchReferenceId = this.branchReferenceId;
      payload.mfaEnabled = this.mfaEnabled;
      return payload;
    },
    loadForm(payload) {
      if (!payload) {
        payload = formDefaults;
      }
      this.id = payload.id;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.companyName = payload.companyName;
      this.phone = payload.phone;
      this.fax = payload.fax;
      this.email = payload.email;
      this.active = payload.active;
      this.marketIds = payload.marketIds;
      this.enableAutomaticLeadAssignment = payload.enableAutomaticLeadAssignment;
      this.$refs.addressForm.setAddressData(payload);
      this.stateLicenseNumber = payload.stateLicenseNumber;
      this.licenseExpirationDate = payload.licenseExpirationDate;
      this.maxLeadPerDay = payload.maxLeadPerDay;
      if (payload.tier) {
        this.tierName = payload.tier.name;
        this.tier = payload.tier;
      }
      this.gpl = payload.gpl;
      this.gplStartDate = payload.gplStartDate;
      this.branchReferenceId = payload.automaticLeadAssignmentBranchReferenceId;
      this.branches.forEach(branch => {
        if (branch.referenceId === this.branchReferenceId) {
          this.selectedBranchName = branch.name;
        }
      });
      this.mfaEnabled = payload.mfaEnabled;
    },
    resetFormToDefaults() {
      this.$refs.addressForm.clearAutocomplete();
      this.loadForm();
    },
    onStateLicenseNumberAdded(value) {
      this.stateLicenseNumber = value;
    },
    onLicenseExpirationDateChanged(value) {
      this.licenseExpirationDate = value;
    },
    onMaxLeadPerDayChanged(value) {
      this.maxLeadPerDay = value;
    },
    allowedDates(date) {
      const currentDate = moment();
      return moment(date).isSameOrAfter(currentDate, "day");
    },
    mfaEnabledValueChanged(value) {
      this.mfaEnabled = value;
      this.clearAlertMessageError();
    },
    resetMfaClicked() {
      this.$emit('resetMfaClicked');
    },
    showAlertNotification(status) {
      this.showMfaAlert = true;
      this.alertMessage = status ? Constants.mfaEnabledMessage : Constants.mfaDisabledMessage;
    },
    clearAlertMessageError() {
      this.showMfaAlert = false;
    }
  }
};
</script>
<style lang="scss" scoped>

.v-input--selection-controls {
  margin-top: 9px;
}

</style>
