<template>
  <div>
    <overbill-entries-grid
        :isMigratedItem="isMigratedItem"
        :overbills="overbills"
        :catalogItemId="catalogItemId"
        :isAddButtonDisplayed="isAddButtonDisplayed"
        @deleteClicked="onDeleteOverbill"
        @overbillAdded="handleOverbillAdded"
    />
  </div>
</template>

<script>
import moment from "moment";
import OverbillEntriesGrid from "../../components/catalogItems/OverbillEntriesGrid.vue";
import ProductCatalogItems from "../../rest/catalogItems";

export default {
  components: {
    OverbillEntriesGrid,
  },

  props: {
    catalogItemId: {},
    overbill: {},
    isAddButtonDisplayed: {
      type: Boolean,
      default: true
    },
    isMigratedItem: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      overbills: [],
      activeOverbill: null,
    };
  },

  watch: {
    catalogItemId: {
      immediate: true,
      handler: function () {
        this.refresh();
      },
    },
  },

  methods: {
    refresh() {
      let component = this;
      ProductCatalogItems.getRestApi()
        .getCatalogItemOverbills(this.catalogItemId)
        .then(function (response) {
          let overbills = response.data;
          let current = moment().valueOf();
          const sorted = overbills.sort(
            (a, b) =>
              moment(b.effectiveOn).utc().valueOf() -
              moment(a.effectiveOn).utc().valueOf()
          );

          // Find active cost and set flag in its data.
          component.activeOverbill = null;
          sorted.forEach((overbill) => {
            if (!component.activeOverbill) {
              let effective = moment(overbill.effectiveOn).utc().valueOf();
              let active = effective <= current;
              if (active) {
                component.activeOverbill = overbill;
              }
            }
          });
          overbills.forEach((overbill) => {
            if (component.activeOverbill === overbill) {
              overbill.effective = true;
            } else {
              overbill.effective = false;
            }
          });
          component.overbills = overbills;
        })
        .catch(function (error) {
          component.emitError(error);
        });
    },
    getActiveOverbill() {
      return this.activeOverbill;
    },
    saveOverbill(data) {
      let component = this;
      const params = {overbill: data};
      ProductCatalogItems.getRestApi()
          .updateCatalogItemOverbill(this.catalogItemId, params)
          .then(() => {
            component.$emit("overbillUpdatedSuccess", data);
          })
          .catch((error) => {
            component.emitError(error);
          });
    },
    onDeleteOverbill(cellData) {
      let component = this;
      ProductCatalogItems.getRestApi()
          .removeCatalogItemOverbill(this.catalogItemId, cellData.data.id)
          .then(() => {
            component.refresh();
            component.emitOverbillChanged();
          })
          .catch((error) => {
            component.emitError(error);
          });
    },
    handleOverbillAdded() {
      this.refresh();
      this.emitOverbillChanged();
    },
    emitError(error) {
      this.$emit("catalogVendorCostsError", error);
    },
    emitOverbillChanged() {
      this.$emit("catalogOverbillChanged");
    },
  },
};
</script>
