<template>
  <modal-dialog
      ref="dialog"
      title="Add Vendor Cost For a Selected Vendor"
      :width="defaultWidth"
      :height="defaultHeight"
      :visible="dialogVisible"
      :loading="loading"
      :submitEnabled="true"
      submitLabel="Add Vendor Cost"
      cancelLabel="Cancel"
      @submitClicked="onSaveVendorCostClicked"
      @cancelClicked="onCancelClicked"
  >
    <add-new-vendor-cost-form
        ref="addVendorCostForSelectedVendorForm"
        :selectedVendor="selectedVendor"
        :activeVendors="activeVendors"
        :isAddForm="false"
        :filteredDatesBySelectedVendor="filteredDatesBySelectedVendor"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../ModalDialog.vue";
import AddNewVendorCostForm from "./AddNewVendorCostForm.vue";

const formDefaults = {
  dialogVisible: false,
  loading: false,
  defaultHeight: '380px',
  defaultWidth: '700px',
  error: null
};
export default {
  components: {
    AddNewVendorCostForm,
    ModalDialog
  },
  props: {
    selectedVendor: {
      type: Object | null,
      required: true
    },
    activeVendors: {
      type: Array,
      required: true
    },
    filteredDatesBySelectedVendor: {
      type: Array | null,
      required: false,
      default: () => []
    }
  },

  data() {
    return Object.assign(
      {},
      formDefaults
    );
  },

  // TODO: consider combine it with {AddNewVendorCostDialog}
  methods: {
    resetToDefaults() {
      this.$refs.addVendorCostForSelectedVendorForm.resetValidation();
      Object.assign(this.$data, formDefaults);
      this.$refs.addVendorCostForSelectedVendorForm.resetForm();
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.resetToDefaults();
    },
    showError(error) {
      this.error = error;
    },
    setLoadingFalse() {
      this.loading = false;
    },
    onSaveVendorCostClicked() {
      const valid = this.$refs.addVendorCostForSelectedVendorForm.validateForm();
      if (valid) {
        this.loading = true;
        let formData = this.$refs.addVendorCostForSelectedVendorForm.getFormData();
        this.$emit('onAddNewVendorCost', formData);
      }
    },
    onCancelClicked() {
      this.resetToDefaults();
      this.dialogVisible = false;
    },
  }
};
</script>
