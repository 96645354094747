<template>
  <div class="add-slot-dialog-content">
    <div>A new slot will be added to all the capabilities of entire crew:</div>
    <v-form ref="form" class="add-slot-form">
      <v-container>
        <v-row class="mt-4">
          <v-select
              label="Installer Crew"
              placeholder="Select Installer Crew"
              v-model="selectedCrew"
              :items="crews"
              persistent-placeholder
              item-value="id"
              :rules="[rules.required]"
          >
            <template #item='{item}'>
              <crew-dropdown-item :item="item" :crewsAmount="crews.length"/>
            </template>
            <template #selection='{item}'>
              <crew-dropdown-item :item="item" selected/>
            </template>
          </v-select>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import Rules from "../../../assets/rules";
import CrewDropdownItem from "./CrewDropdownItem.vue";

export default {
  components: {
    CrewDropdownItem,
    ModalDialog
  },

  data() {
    return {
      selectedCrew: null,
      rules: {
        required: Rules.required
      }
    };
  },

  watch: {
    selectedCrew(newVal) {
      this.$emit('crew-selected', newVal);
    }
  },

  props: {
    crews: {
      type: Array,
      required: true
    }
  },

  methods: {
    reset() {
      this.selectedCrew = null;
      this.$refs.form.resetValidation();
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-slot-dialog-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .add-slot-form {
    width: 100%;
  }
}
</style>
