<template>
  <modal-dialog
      ref="dialog"
      width="500"
      cancelLabel="Cancel"
      title="Installation"
      :visible="dialogVisible"
      :hideSubmit="true"
      @cancelClicked="closeDialog"
  >
    <read-only-form ref="readOnlyForm"/>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import ReadOnlyForm from "@/components/installations/ReadOnlyForm.vue";

export default {

  components: {
    ReadOnlyForm,
    ModalDialog
  },

  data() {
    return {
      dialogVisible: false
    };
  },

  methods: {
    openDialog(data) {
      this.$refs.readOnlyForm.loadForm(data);
      this.dialogVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
    }
  }
};
</script>
