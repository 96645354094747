import Vue from "vue";
import Router, {RouteConfig} from "vue-router";
import decode from "jwt-decode";
import store from "./store";
import Login from "./views/users/UserLogin.vue";
import AppUpdatedAck from "./views/users/AppUpdatedAck.vue";
import ForgotPassword from "./views/users/ForgotPassword.vue";
import RequestPassword from "./views/users/RequestPassword.vue";

import BinsGrid from "./views/bins/BinsGrid.vue";
import BinDetail from "./views/bins/BinDetails.vue";
import BranchesGrid from "./views/branches/BranchesGrid.vue";
import BranchDetail from "./views/branches/BranchDetails.vue";
import CatalogItemDetail from "./views/catalogItems/ProductCatalogItemDetails.vue";
import CatalogItemsGrid from "./views/catalogItems/ProductCatalogItemsGrid.vue";
import CustomerDetail from "./views/customers/CustomerDetails.vue";
import CustomersGrid from "./views/customers/CustomersGrid.vue";
import InventoryItemDetail from "./views/inventoryItems/InventoryItemDetails.vue";
import InventoryItemGrid from "./views/inventoryItems/InventoryItemsGrid.vue";
import InventoryGeneralCheckIn from "./views/inventoryManagement/InventoryGeneralCheckIn.vue";
import InventoryPurchaseOrderCheckIn from "./views/inventoryManagement/InventoryPurchaseOrderCheckIn.vue";
import InventoryGeneralCheckOut from "./views/inventoryManagement/InventoryGeneralCheckOut.vue";
import InventoryJobCheckOut from "./views/inventoryManagement/InventoryJobCheckOut.vue";
import InstallerDetail from "./views/installers/InstallerDetails.vue";
import InstallersGrid from "./views/installers/InstallersGrid.vue";
import JobDetail from "./views/jobs/JobDetails.vue";
import Subjob from "./views/jobs/SubJob.vue";
import WorkOrder from "./views/jobs/WorkOrder.vue";
import JobsGrid from "./views/jobs/JobsGrid.vue";
import LeadDetail from "./views/leads/LeadDetails.vue";
import LeadsGrid from "./views/leads/LeadsGrid.vue";
import MarketDetail from "./views/markets/MarketDetails.vue";
import MarketsGrid from "./views/markets/MarketsGrid.vue";
import PrintLeadAppointment from "./components/salesRepresentativesHome/lead/PrintLeadAppointment.vue";
import PrintJobInstallation from "./components/salesRepresentativesHome/job/PrintJobInstallation.vue";
import PromoCodeDetail from "./views/promoCodes/PromoCodeDetails.vue";
import PromoCodeGrid from "./views/promoCodes/PromoCodesGrid.vue";
import ProductTypesGrid from "./views/productTypes/ProductTypesGrid.vue";
import ProductTypeDetail from "./views/productTypes/ProductTypeDetails.vue";
import PurchaseOrderDetail from "./views/purchaseOrders/PurchaseOrderDetails.vue";
import PurchaseOrderGrid from "./views/purchaseOrders/PurchaseOrdersGrid.vue";
import SalesRepsGrid from "./views/salesRepresentatives/SalesRepGrid.vue";
import SalesRepDetail from "./views/salesRepresentatives/SalesRepDetails.vue";
import SalesRepLeadsGrid from "./views/salesRepresentativesHome/lead/LeadsGrid.vue";  // deprecated
import SalesRepLeadDetail from "./views/salesRepresentativesHome/lead/LeadDetail.vue"; // deprecated
import SalesCommissionsPage from "./views/salesRepresentativesHome/commission/SalesCommissionsPage.vue";
import SalesRepJobs from "./views/salesRepresentativesHome/job/JobsGrid.vue";  // deprecated
import SalesRepJobDetails from "./views/salesRepresentativesHome/job/JobDetails.vue";  // deprecated
import SalesRepHome from "./views/salesRepresentativesHome/home/HomeContainer.vue";  // deprecated
import TicketsGrid from "./views/tickets/TicketsGrid.vue";
import TicketDetails from "./views/tickets/TicketDetails.vue";
import UserDetails from "./views/users/UserDetails.vue";
import UsersGrid from "./views/users/UsersGrid.vue";
import VendorsGrid from "./views/vendors/VendorsGrid.vue";
import VendorDetail from "./views/vendors/VendorDetails.vue";
import TimeBlocksCalendar from "./views/timeBlocksSalesRep/SalesRepsCalendar.vue";
import LeadsMigration from "./views/LeadsMigration.vue";
import PrintLead from "./components/salesRepresentativesHome/lead/PrintLead.vue";
import BroadcastMessage from "./views/system/BroadcastMessage.vue";
import DaysOff from "./views/daysOff/DaysOff.vue";
import FileView from "./views/files/FileView.vue";
import Auth from "./rest/auth";
import TransferRequestsGrid from "./views/transferRequests/TransferRequestsGrid.vue";
import TransferRequestDetails from "./views/transferRequests/TransferRequestDetails.vue";
import ShipmentsGrid from "./views/shipments/ShipmentsGrid.vue";
import ShipmentDetails from "./views/shipments/ShipmentDetails.vue";
import BranchTransferCheckIn from "./views/branches/branchManagement/BranchTransferCheckIn.vue";
import BranchTransferCheckOut from "./views/branches/branchManagement/BranchTransferCheckOut.vue";
import InventoryInTransfer from "./views/branches/branchManagement/InventoryInTransfer.vue";
import InventoryInTransferDetails from "./views/invnetoryInTransfer/InventoryInTransferDetails.vue";
import InstallerSlotsCalendar from "./views/installers/calendar/InstallerSlotsCalendar.vue";
import InstallationsGrid from "./views/installers/installations/InstallationsGrid.vue";
import Roles from "./assets/roles";
import System from "./rest/system";
import RouterHelper from "./assets/routerHelper";
import SuperAdminTools from "./views/system/SuperAdminTools.vue";
import {AuthHelper} from "./assets/authHelper";

Vue.use(Router);
const allAdminRoles = ['admin', 'branch_admin', 'super_admin', 'general_manager'];
const allRolesExceptRehash = ['admin', 'branch_admin', 'super_admin', 'general_manager', 'branch', 'default'];

const routes = <Array<RouteConfig>>[
  {
    path: "/",
    name: "root",
    redirect() {
      return store.state.loggedInUser ? "/customers" : "/login";
    },
    meta: {
      public: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      public: true
    }
  },
  {
    path: "/refresh",
    component: AppUpdatedAck,
    meta: {
      public: true
    }
  },
  {
    path: "/forgot",
    component: ForgotPassword,
    meta: {
      public: true
    }
  },
  {
    path: "/setPassword",
    component: ForgotPassword,
    meta: {
      public: true
    }
  },
  {
    path: "/request",
    component: RequestPassword,
    meta: {
      public: true
    }
  },
  {
    path: "/bins/details/:binId",
    component: BinDetail,
    meta: {
      navBackButton: "/inventory/bins"
    }
  },
  {
    path: "/promos",
    component: PromoCodeGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/promos/details/:promoCodeId",
    component: PromoCodeDetail,
    meta: {
      rolesRequired: allAdminRoles,
      navBackButton: "/promos"
    }
  },
  {
    path: "/product/types",
    component: ProductTypesGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/product/types/details/:productTypeId",
    component: ProductTypeDetail,
    meta: {
      navBackButton: "/product/types"
    }
  },
  {
    path: "/leads",
    component: LeadsGrid,
    name: "leads"
  },
  {
    path: "/leads/details/:leadReferenceId",
    component: LeadDetail,
    name: "leadDetail",
    meta: {
      navBackButton: "/leads"
    }
  },
  {
    path: "/jobs",
    component: JobsGrid,
    name: "jobs"
  },
  {
    path: "/jobs/details/:jobReferenceId",
    name: "jobDetail",
    component: JobDetail,
    meta: {
      navBackButton: "/jobs"
    }
  },
  {
    path: "/jobs/subjob/:jobReferenceId",
    name: "jobSubjob",
    component: Subjob,
    meta: {
      navBackButton: "/jobs"
    }
  },
  {
    path: "/jobs/work-order/:jobRefId/:lineItemRefId",
    name: "workOrder",
    component: WorkOrder
  },
  {
    path: "/markets",
    component: MarketsGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/markets/details/:marketReferenceId",
    component: MarketDetail,
    meta: {
      rolesRequired: allAdminRoles,
      navBackButton: "/markets"
    }
  },
  {
    path: "/customers",
    component: CustomersGrid
  },
  {
    path: "/customers/details/:customerReferenceId",
    component: CustomerDetail,
    meta: {
      navBackButton: "/customers"
    }
  },
  {
    path: "/inventory/items",
    component: InventoryItemGrid
  },
  {
    path: "/inventory/transfer-requests",
    component: TransferRequestsGrid
  },
  {
    path: "/inventory/shipments",
    component: ShipmentsGrid
  },
  {
    path: "/inventory/in-transfer",
    component: InventoryInTransfer
  },
  {
    path: "/inventory/check-in/branch-transfer",
    component: BranchTransferCheckIn
  },
  {
    path: "/inventory/check-out/branch-transfer",
    component: BranchTransferCheckOut
  },
  {
    path: "/inventory/bins",
    component: BinsGrid
  },
  {
    path: "/inventory/details/:inventoryItemId",
    component: InventoryItemDetail,
    meta: {
      navBackButton: "/inventory/items"
    }
  },
  {
    path: "/inventory/transfer-requests/:transferRequestNumber",
    component: TransferRequestDetails,
    meta: {
      navBackButton: "/inventory/transfer-requests"
    },
    beforeEnter: async (to, from, next) => {
      await navigateUsingGuard(to, from, next);
    }
  },
  {
    path: "/inventory/shipment/:shipmentNumber",
    component: ShipmentDetails,
    meta: {
      navBackButton: "/inventory/shipments"
    },
    beforeEnter: async (to, from, next) => {
      await navigateUsingGuard(to, from, next);
    }
  },
  {
    path: "/inventory/in-transfer/details/:transferRequestItemId",
    component: InventoryInTransferDetails,
    meta: {
      navBackButton: "/inventory/in-transfer"
    }
  },
  {
    path: "/inventory/management/checkin/general",
    component: InventoryGeneralCheckIn
  },
  {
    path: "/inventory/management/checkin/purchase_order",
    component: InventoryPurchaseOrderCheckIn
  },
  {
    path: "/inventory/management/checkout/general",
    component: InventoryGeneralCheckOut
  },
  {
    path: "/inventory/management/checkout/job",
    component: InventoryJobCheckOut
  },
  {
    path: "/installers",
    component: InstallersGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/installers/details/:installerReferenceId",
    component: InstallerDetail,
    meta: {
      navBackButton: "/installers"
    }
  },
  {
    path: "/reporting-metabase",
    beforeEnter() {
      window.open("https://reporting.50floor.net", "_blank");
    }
  },
  {
    path: "/vendors",
    component: VendorsGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/vendors/details/:vendorReferenceId",
    component: VendorDetail,
    meta: {
      rolesRequired: allAdminRoles,
      navBackButton: "/vendors"
    }
  },
  {
    path: "/orders/catalog",
    component: CatalogItemsGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/orders/catalog/:catalogItemId",
    component: CatalogItemDetail,
    meta: {
      rolesRequired: allAdminRoles,
      navBackButton: "/orders/catalog"
    }
  },
  // open to other paths for purchase orders
  {
    path: "/orders/purchases",
    component: PurchaseOrderGrid
  },
  {
    path: "/orders/purchases/details/:purchaseOrderNumber",
    component: PurchaseOrderDetail,
    meta: {
      navBackButton: "/orders/purchases"
    }
  },
  {
    path: "/branches",
    component: BranchesGrid
  },
  {
    path: "/branches/details/:branchReferenceId",
    component: BranchDetail,
    meta: {
      navBackButton: "/branches"
    }
  },
  {
    path: "/sales-representatives",
    component: SalesRepsGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/sales-representatives/details/:salesRepReferenceId",
    component: SalesRepDetail,
    meta: {
      rolesRequired: allAdminRoles,
      navBackButton: "/sales-representatives"
    }
  },
  {
    path: "/sales/home",
    name: "salesRepHome",
    component: SalesRepHome  // deprecated
  },
  {
    path: "/sales/jobs",
    name: "salesRepJobs",
    component: SalesRepJobs  // deprecated
  },
  {
    path: "/sales/jobs/details/:jobReferenceId",
    name: "salesRepJobDetails",
    component: SalesRepJobDetails  // deprecated
  },
  {
    path: "/sales/jobs/print",
    name: "printSalesRepJobInstallation",
    component: PrintJobInstallation  // deprecated
  },
  {
    path: "/sales/leads",
    name: "salesRepLeadsGrid",
    component: SalesRepLeadsGrid  // deprecated
  },
  {
    path: "/sales/leads/details/:leadReferenceId",
    name: "salesRepLeadDetail",
    component: SalesRepLeadDetail // deprecated
  },
  {
    path: "/sales/leads/details/:leadReferenceId/print",
    name: "printSalesRepLead",
    component: PrintLead  // deprecated
  },
  {
    path: "/sales/leads/details/print",
    name: "printSalesRepAppointment",
    component: PrintLeadAppointment  // deprecated
  },
  {
    path: "/sales/commissions",
    name: "salesRepCommissions",
    component: SalesCommissionsPage  // deprecated
  },
  {
    path: "/tickets",
    component: TicketsGrid
  },
  {
    path: "/tickets/details/:ticketReferenceId",
    component: TicketDetails,
    meta: {
      navBackButton: "/tickets"
    }
  },
  {
    path: "/users",
    component: UsersGrid,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/users/details/:userId",
    component: UserDetails,
    meta: {
      rolesRequired: allAdminRoles,
      navBackButton: "/users"
    }
  },
  {
    path: "/time-blocks-sales-rep",
    component: TimeBlocksCalendar,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "*",
    redirect() {
      return store.state.loggedInUser ? "/leads" : "/";
    }
  },
  {
    path: "/installers/calendar",
    component: InstallerSlotsCalendar,
    meta: {
      rolesRequired: allRolesExceptRehash
    }
  },
  {
    path: "/installers/installations",
    component: InstallationsGrid,
    meta: {
      rolesRequired: allRolesExceptRehash
    }
  },
    //Do we use it??
  {
    path: "/leadsMigration",
    name: "leadsMigration",
    component: LeadsMigration
  },
  {
    path: "/broadcast",
    name: "broadcast",
    component: BroadcastMessage,
    meta: {
      rolesRequired: ['admin', 'super_admin']
    }
  },
  {
    path: "/days-off",
    name: "daysOff",
    component: DaysOff,
    meta: {
      rolesRequired: allAdminRoles
    }
  },
  {
    path: "/super-admin-tools",
    name: "superAdminTools",
    component: SuperAdminTools,
    meta: {
      rolesRequired: ['super_admin']
    }
  },
  {
    path: "/files/:fileId",
    name: "fileView",
    component: FileView
  }
];

const router = new Router({
  mode: "history",
  routes: routes
});

/*
Check route if it requires authentication
  if yes
     if authenticated
         check to see if the role is restricted to certain routes
             if yes
                 check to see if the current route matches the allowed route
                     if yes
                         next()
                     if no 
                         redirect to allowed route
             if no
                 next()
     if not authenticated
         redirect to login screen
  if no
     next()
*/
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(route => !route.meta.public)) {
    const user = store.state.loggedInUser;

    if (AuthHelper.isSafariBrowser()) {
      //TODO: SAFARI keeps old token in cookies storage, so all new requests will be with old token (causes 401 - jwt expired)
      // and Expires value is 'session' in Safari for some reason
      const userKey = `user_${user?.email}`;
      const accessToken = sessionStorage.getItem(`${userKey}_accessToken`);
      if (!accessToken || !user) {
        store.commit("logout");
        next({path: `/`});
      } else if (!Auth.validateToken(decode(accessToken))) {
        await Auth.updateTokens(user)
            .catch(() => {
              store.commit("logout", "tokenExpireLogout");
              next({path: `/`});
            });
      }
    }

    if (user === null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath }
      });
    } else {
      // check if current route requires admin privileges
      if (to.matched.some(route => typeof route.meta.rolesRequired !== 'undefined')) {
        if (to.meta && to.meta.rolesRequired.includes(user.role.name)) {
          next();
        } else {
          next({ name: "leads" }); // redirect to some default route for the current role?
        }
      } else {
        const roleRestrictionRules = roleRestrictions.find(roleRestriction => {
          return user.role && roleRestriction.role === user.role.name;
        });

        if (roleRestrictionRules) {
          const isRouteAllowed = roleRestrictionRules.routes.find(route => {
            return route.test(to.path);
          });
          if (isRouteAllowed) {
            next();
          } else {
            //redirect to salesRep Home  // deprecated
            next({ path: `/sales/home` });
          }
        } else {
          next();
        }
      }
    }
  } else {
    next();
  }
});

 async function hasPermissions(referenceId: string) {
  const isAllowed = await System.getRestApi().isContentHasBranchesThatUserOperatesIn(referenceId);
  return isAllowed.data;
}
/**
   Secure navigation to a particular details page based on branches that are on a details page
   and branches where BranchUser & BranchAdmin are operating in.
 **/
async function navigateUsingGuard(to: any, from: any, next: any) {
  const referenceId = RouterHelper.extractReferenceId(to.params);
  const accessAllowed = await hasPermissions(referenceId);
  if (!Roles.isSuperAdminOrAdminUser() && !accessAllowed) {
    if (from.path) {
      // Stay on the current page OR
      // if link was copied and navigation is performed from any place of the app - redirect to the default route
      next(from.path);
    } else {
      // Redirect to a default '/customers' route if no associated branch found
      next('/customers');
    }
  } else {
    // Allow access if the user has an associated branch or not-branch associated user
    next();
  }
}

// deprecated
const roleRestrictions = [
  {
    role: "sales",
    routes: [/\/sales/i, /\/files/i]
  }
];

export default router;
