<template>
  <v-flex xs12 mt-2 mb-0>
    <v-select
        @change="onSalesRepSelect"
        placeholder="Filter by Sales Representative"
        v-model="value"
        :items="salesReps"
        item-text="fullName"
        clearable
        :clear-icon-cb="onClearClicked"
        attach
    >
    </v-select>
  </v-flex>
</template>

<script>
export default {

  props: {
    value: null,
    salesReps: null,
    salesRepNamesList: null
  },

  methods: {
    onSalesRepSelect(value) {
      this.$emit('onSalesRepSelected', value);
    },
    onClearClicked() {
      this.$emit('selectionCleared');
    }
  }
};
</script>
