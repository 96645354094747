<template>
  <v-btn
      dark
      color="primary"
      class="section-button"
      :loading="loading"
      @click="buttonClicked"
  >
    {{ submitLabel }}
  </v-btn>
</template>


<script>
import Constants from "../../assets/constants";

export default {
  components: {},

  props: {
    submitLabel: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {};
  },

  methods: {
    buttonClicked() {
      if (this.submitLabel === Constants.jobButtonLabels.FULFILMENT) {
        this.$emit('onStartFulfillment');
      } else if (this.submitLabel === Constants.jobButtonLabels.INVOICE) {
        this.$emit('onInvoiceJob');
      }else {
        this.$emit('onSaveLineItems');
      }
    },
  }

}

</script>
