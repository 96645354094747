import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";

/**
 * Job misc item type.
 */
export class JobMiscItemType {
    id: number;
    name: string = "";

    constructor(id: number) {
        this.id = id;
    }
}

/**
 * REST interface provided for job misc item types.
 */
export interface JobMiscItemTypeRestApi {
    getAll(): AxiosPromise<JobMiscItemType[]>;
}

/**
 * REST implementation provided for job misc item types.
 */
class Implementation implements JobMiscItemTypeRestApi {
    getAll(): AxiosPromise<JobMiscItemType[]> {
        return Axios.axiosInstance().get<JobMiscItemType[]>("/job_misc_item_types");
    }
}

const JobMiscItemTypes = {
    getRestApi(): JobMiscItemTypeRestApi {
        const api = new Implementation();
        return api;
    }
};

export default JobMiscItemTypes;
