<template>
  <modal-dialog
      title="Reset Grid"
      width="350"
      :visible="visible"
      cancelLabel="No"
      submitLabel="Yes"
      :submitEnabled="true"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    Are you sure you want to reset the grid?
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

export default {
  components: {
    ModalDialog
  },

  props: ["visible"],

  methods: {
    onSubmitClicked() {
      this.$emit("onSubmitClicked");
    },
    onCancelClicked() {
      this.$emit("onCancelClicked");
    }
  }
};
</script>
