<template>
  <modal-dialog
      width="75%"
      title="Time Blocks Calendar"
      :visible="visible"
      :submitEnabled="false"
      :hide-submit="true"
      :hideButtons="true"
  >
    <sales-rep-time-block-calendar
        ref="timeBlockCalendar"
        :filterBySalesRep="false"
        :filterBySalesMarket="false"
        :show-done-button="true"
        :market="marketId"
        :formType="formType"
        @timeblockClicked="handleTimeBlockClick"
        @cancelClicked="onCancelClicked"
    />
  </modal-dialog>
</template>

<script>
import SalesRepTimeBlockCalendar from "@/components/salesRepTimeblock/SalesRepTimeBlockCalendar.vue";
import ModalDialog from "@/components/common/ModalDialog.vue";

export default {
  components: {
    SalesRepTimeBlockCalendar,
    ModalDialog
  },
  props: {
    formType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      marketId: null
    };
  },
  methods: {
    showDialog(market) {
      if (market) {
        this.visible = true;
        this.marketId = market;
      }
    },
    hideDialog() {
      this.marketId = null;
      this.visible = false;
    },
    showError(error) {
      this.$emit('showError', error);
    },
    onCancelClicked() {
      this.hideDialog();
    },
    handleTimeBlockClick(timeBlockClicked) {
      if (timeBlockClicked.maxSlots - timeBlockClicked.usedSlots > 0) {
        this.$emit('timeblockClicked', timeBlockClicked);
        this.hideDialog();
      }
    }
  }
};
</script>
