import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Broadcast message.
 */
export interface BroadCastMessage {
  title: string;
  message: string;
  roles: number;
  severity: string;
}

/**
 * REST interface provided for version.
 */
export interface SystemRestApi {
  sendBroadcastMessage(message: BroadCastMessage): AxiosPromise<void>;
  isProductionStage(): AxiosPromise<boolean>;
  isContentHasBranchesThatUserOperatesIn(referenceId: string): AxiosPromise<boolean>;
}

/**
 * REST implementation provided for version.
 */
class Implementation implements SystemRestApi {
  sendBroadcastMessage(message: BroadCastMessage): AxiosPromise<void> {
    return Axios.axiosInstance().post<void>("/system/broadcast", message);
  }
  isProductionStage():AxiosPromise<boolean>{
    return Axios.axiosInstance().get<boolean>("/system/is-production");
  }
  isContentHasBranchesThatUserOperatesIn(referenceId: string): AxiosPromise<boolean> {
    return Axios.axiosInstance().get<boolean>(`/system/navigation/is-access-allowed/${referenceId}`);
  }
}

const System = {
  getRestApi(): SystemRestApi {
    return new Implementation();
  }
};

export default System;
