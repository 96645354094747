<template>
  <v-card class="pl-3 pr-3">
    <strong>{{ inventoryItem.allocated }}</strong> items allocated in
    <strong>{{ inventoryItem.branchName }}</strong> bin
    <strong>{{
      inventoryItem.inventoryBin
        ? inventoryItem.inventoryBin.binNumber
        : "No Bin"
    }}</strong
    >.
  </v-card>
</template>

<script>
export default {
  components: {},

  props: {
    inventoryItem: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  }
};
</script>
