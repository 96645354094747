<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="notes">Notes</v-tab>
          <v-tab-item key="notes">
            <notes-grid
                ref="notesGrid"
                :notes="notes"
                @saveNote="onSaveNote"
                @getNotes="onGetNotes"
            />
          </v-tab-item>

          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                parent-type="lead"
                :lead-reference-id="leadReferenceId"
                :documentTypesCategory=Constants.documentTypesCategories.LEAD
                @filesGridError="emitError"
            />
          </v-tab-item>

          <v-tab key="tickets">Tickets</v-tab>
          <v-tab-item key="tickets">
            <category-specific-tickets-grid
                ref="leadTickets"
                type="lead"
                :leadReferenceId="leadReferenceId"
                :customer="customer"
                @showError="emitError"
                @onRefresh="refreshActivityLog"
            />
          </v-tab-item>

          <v-tab key="activityLog">Activity Log</v-tab>
          <v-tab-item key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Leads from "../../rest/leads.ts";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import CategorySpecificTicketsGrid from "../../components/tickets/CategorySpecificTicketsGrid.vue";
import FilesGrid from "../../components/common/inlineGrids/files/FilesGrid.vue";
import NotesGrid from "../../components/common/inlineGrids/NotesGrid.vue";
import Notes from "../../rest/notes";
import Constants from "../../assets/constants";

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    FilesGrid,
    ActivityLogsGrid,
    CategorySpecificTicketsGrid,
    NotesGrid
  },

  props: {
    leadReferenceId: {
      type: String,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activityLogs: [],
      notes: []
    };
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    emitSuccess(message) {
      this.$emit('showSuccess', message);
    },
    async getLeadNotesData() {
      try {
        const response = await Notes.getRestApi().getAllRelatedNotesByLeadReferenceId(this.leadReferenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async saveLeadNote(note) {
      try {
        await Notes.getRestApi().createLeadNote(this.leadReferenceId, note);
      } catch (error) {
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.emitSuccess("Lead note saved successfully!");
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    async onSaveNote(note) {
      try {
        await this.saveLeadNote(note);
        this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async onGetNotes() {
      try {
        this.notes = await this.getLeadNotesData();
      } catch (error) {
        this.emitError(error);
      }
    },
    async getActivityLogs() {
      try {
        const response = await Leads.getRestApi().getLeadLogs(this.leadReferenceId);
        this.activityLogs = response.data;
      } catch (error) {
        this.emitError(error);
      }
    }
  }
};
</script>
