<template>
  <grid-view
      ref="nav"
      page-title="Product Types"
      nav-section="admin"
      :action-button-label="isActionButtonDisplayed ? 'Create Product Type' : null"
      :data-source="dataSource"
      :columnMinWidth="175"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      :allowFiltering="false"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="Status"
        width="15%"
        caption="Status"
        :cell-template="StatusRenderer"
        data-type="string"
    />
    <dx-column
        data-field="Name"
        width="20%"
        caption="Product Type"
        data-type="string"
    />
    <dx-column
        data-field="Description"
        caption="Description"
        data-type="string"
    />
    <template v-slot:drawerContent>
      <product-type-form
          ref="productTypeForm"
          title="Create Product Type"
          submitLabel="Save Product Type"
          @cancelClicked="onCancel"
          @submitClicked="onCreateProductType"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import ProductTypes from "@/rest/productTypes.ts";
import ProductTypeForm from "@/components/productTypes/ProductTypeForm.vue";
import StatusRenderer from "@/components/common/grid/StatusRenderer.js";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "@/rest/odataContext";
import Roles from "@/assets/roles";

export default {
  components: {
    GridView,
    ProductTypeForm,
    DxColumn
  },
  data() {
    return {
      dataSource: {
        store: ODataContext['ProductTypes'],
        select: ["Id", "Active", "Status", "Name", "Description"]
      }
    };
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/product/types/details/${data.Id}`);
    },
    onSubmitSuccess() {
      this.$refs.nav.showSuccess("Successfully created product type!");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    async onCreateProductType(payload) {
      try {
        await ProductTypes.getRestApi().createProductType(payload);
        this.onSubmitSuccess();
      } catch (error) {
        this.onSubmitFailed(error);
      }
    },
    StatusRenderer
  }
};
</script>
