<template>
  <v-stepper-header>
    <v-stepper-step :complete="step > 1" step="1">Enter Install Issue</v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :complete="step > 2" step="2">Enter Details</v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :complete="step > 3" step="3">Financial Responsibility</v-stepper-step>
  </v-stepper-header>
</template>

<script>
export default {
  props: { step: Number },
};
</script>
