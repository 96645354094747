<template>
  <div id="app">
    <div :class="stageHeaderClass">
      <div> Testing environment</div>
    </div>
    <v-app :style="showStageHeader ? 'padding-top: 22px;' : ''" id="inspire">
      <router-view/>
      <broadcast-messages/>
    </v-app>
  </div>
</template>

<script>
import System from "./rest/system";
import BroadcastMessages from "@/components/broadcast/BroadcastMessages.vue";

export default {
  components: {
    BroadcastMessages
  },

  async created() {
    let response = await System.getRestApi().isProductionStage();
    if (response) {
      this.showStageHeader = !response.data;
    }
  },

  data() {
    return {
      shiftHeader: false,
      showStageHeader: false,
      stageHeaderClass: 'stage-header'
    };
  },

  computed: {
    user () {
      return this.$store.state.loggedInUser;
    }
  },

  watch: {
    showStageHeader(newVal) {
      if (newVal) {
        this.stageHeaderClass = 'stage-header visible';
      }
    }
  }
};
</script>

<style>
/** Get rid of material design all caps */
* {
  text-transform: none !important;
}
.v-app-bar__extension {
  padding: 0;
  border-top: 1px solid #dde;
}
/** Color for expansion panel header */
.v-expansion-panel__header {
  background-color: rgba(102, 153, 187, 0.89);
  font-size: 18px;
}
/** Turn off shake animation on validation */
.input-group.input-group--error label {
  animation: none !important;
}
</style>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.landing-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 40px 20px 40px;
}
// remove blinking cursor from readonly inputs/textareas
.v-text-field__slot input[readonly="readonly"]:focus,
.v-text-field__slot textarea[readonly="readonly"]:focus {
  caret-color: transparent;
}
// change star rating icon padding
i.v-icon.v-icon--link.material-icons.theme--light.primary--text,
i.v-icon.v-icon--link.material-icons.theme--light.accent--text {
  padding: 0 2px;
}
.detailsDivider {
  padding-bottom: 30px;
}

// general styling of labels
.theme--light.v-text-field > .v-input__control > .v-input__slot label {
  color: #0e4e6c;
  font-size: 16px;
  font-weight: 500;
  top: 0;
}
// general styling of input boxes
.theme--light.v-text-field > .v-input__control > .v-input__slot {
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  transition: all 0.3s ease;
  min-height: 34px;
}
// adds padding as lined up to left edge and looks odd in boxes
.v-select__slot,
.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  padding-left: 8px;
  padding-right: 6px;
  color: #4a4a4a;
}

// removes underline from all fields (text, select, autocomplete)
.theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.theme--light.v-text-field > .v-input__control > .v-input__slot:after {
  border: none;
}

// changes border to red for errored fields
.theme--light.v-text-field.error--text > .v-input__control > .v-input__slot {
  transition: all 0.3s ease;
  border: 1px solid #b71c1c;
}

// focused fields
.theme--light.v-text-field.v-input--is-focused
  > .v-input__control
  > .v-input__slot {
  transition: all 0.3s ease;
  border: 1px solid #6699bb;
}

// readonly fields
.theme--light.v-text-field.v-input--is-readonly
  > .v-input__control
  > .v-input__slot
  label {
  color: #0e4e6c !important;
}
.theme--light.v-text-field.v-input--is-readonly
  > .v-input__control
  > .v-input__slot {
  background-color: #fafafa !important;
  border: 1px solid #dce2e7 !important;
}

// disabled fields
.theme--light.v-text-field.v-input--is-disabled
  > .v-input__control
  > .v-input__slot {
  background-color: #f3f6f7 !important;
}
.theme--light.v-text-field.v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  label,
.theme--light.v-input--is-disabled input {
  color: #7a7a7a;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.v-expansion-panel > .v-expansion-panel-header {
  border-radius: 0;
  color: white;
}
.v-expansion-panel-content__wrap{
  padding: 0 0 16px;
}
//remove v-simple-table scrollbar
.v-data-table__wrapper {
  overflow-x: hidden;
  overflow-y:hidden;
  padding-bottom: 4px;
}
.mdi-chevron-down::before {
  color: white;
}
.v-list-item__title {
  font-weight: inherit !important;
}
.v-list-item.v-list-item--highlighted::before {
  background-color: unset;
}
.v-slide-group__next, .v-slide-group__prev {
  background-color: #69b;
  color: white;
}
.v-application--is-ltr .v-data-footer__pagination{
  margin: 0 14px 0 14px;
}
.v-btn:not(.v-btn--round).v-size--large, .v-btn:not(.v-btn--round).v-size--default {
  border-radius: 2px;
  min-width: 100px;
  min-height: 40px;
}
// Vuetify 2
.v-btn, .v-tab, td {
  letter-spacing: 0;
}
.stage-header {
  background-color: #539d7e;
  position: fixed;
  z-index: -9999;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  color: #FFFFFFFF;
  top: 0;
  font-size: 16px;
}

.stage-header.visible {
  z-index: 9999;
}

</style>
