<template>
  <div class="selectButton">
    <div id="grid-toolbar" slot="toolbar">
      <grid-toolbar
          :displayColumnChooser="displayColumnChooser"
          @onToggleResetGridDialog="clearGridState"
      />
    </div>
    <!-- used for job builder with total available quantity -->
    <branch-search-catalog-items-grid
        v-if="branchId"
        ref="itemsGrid"
        :branchId="branchId"
        :dataSource="dataSource"
        @rowSelected="onAddSelected"
    />
    <!-- used for create PO (selectedVendor is from PO stepper & dataSource is from add associated items dialog-->
    <search-catalog-items-grid
        v-if="selectedVendor || dataSource.type"
        :dataSource="dataSource"
        :selectedVendor="selectedVendor"
        :filter="filter"
        ref="itemsGrid"
        @rowSelected="onAddSelected"
    />
    <div class="chipContainer">
      <template>
        <v-chip
            outlined
            close
            text-color="#333"
            v-for="item in selected"
            :key="item.internalSku"
            @click:close="onRemoveSelected(item)"
        >
          <strong>{{ item.InternalSku }}</strong>&nbsp;
          <span>{{ buildInventoryItemName(item) }}</span>
        </v-chip>
      </template>
    </div>
  </div>
</template>

<script>
import SearchCatalogItemsGrid from "../../components/catalogItems/SearchCatalogItemsGrid.vue";
import GridToolbar from "../common/grid/GridToolbar";
import BranchSearchCatalogItemsGrid from "./BranchSearchCatalogItemsGrid";
import FilterInitializer from "../common/grid/FilterInitializer";
import ItemsNamingHelper from "../../assets/ItemsNamingHelper";

export default {

  components: {
    BranchSearchCatalogItemsGrid,
    GridToolbar,
    SearchCatalogItemsGrid
  },

  props: {
    displayColumnChooser: {
      type: Boolean,
      default: false
    },
    branchId: Number,
    dataSource: {
      type: Object | null,
      required: false
    },
    selectedVendor: {
      type: Object,
      required: false,
      default: null
    },
    filter: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data() {
    return {
      selected: [],
      error: null
    };
  },

  watch: {
    dataSource(value) {
      if (value.type) {
        this.setCustomFilterOperations();
      } else if (value) {
        this.setBranchFilter(this.branchId);
        this.setCustomFilterOperations();
      }
    }
  },

  methods: {
    reset() {
      this.selected = [];
      this.removeSelection();
    },
    showError(error) {
      this.error = error;
    },
    onAddSelected(event) {
      if (event.selectedRowsData.length) {
        const newSelection = {...event.selectedRowsData[0]};
        newSelection.quantityOrdered = null;
        newSelection.quantity = null;
        newSelection.productCatalogItemId = newSelection.PciId;
        let itemIsAlreadySelected = this.checkIfDuplicateExists(newSelection)
        if (itemIsAlreadySelected) {
          this.$emit("showError", 'This item: ' + newSelection.InternalSku + ' - ' + newSelection.Color
            + ' - ' + newSelection.StyleName + ' is already selected');
          return;
        }
        this.selected.push(newSelection);
      }
    },
    checkIfDuplicateExists(newSelection) {
      return this.selected.some(item => item.InternalSku === newSelection.InternalSku);
    },
    onRemoveSelected(item) {
      let selectedRows = this.$refs.itemsGrid.getSelectedRows();
      selectedRows.forEach(itemToRemove => {
        if (itemToRemove.PciId && itemToRemove.PciId === item.PciId || itemToRemove.Id === item.Id) {
          this.removeSelection();
        }
      });
      this.selected = item.PciId
          ? this.selected.filter(currentItemFromPO => currentItemFromPO.PciId !== item.PciId)
          : this.selected.filter(currentItemFromJobBuilder => currentItemFromJobBuilder.Id !== item.Id);
    },
    getSelectedItems() {
      return this.selected;
    },
    clearGridState() {
      this.$refs.itemsGrid.clearGrid();
    },
    removeSelection() {
      this.$refs.itemsGrid.deselectItems();
    },
    setBranchFilter(branchId) {
      if (branchId) {
        this.dataSource.filter = ["BranchId", "=", branchId];
      }
    },
    setCustomFilterOperations() {
      if (this.$refs.itemsGrid) {
        FilterInitializer.initializeCustomFilterOperations(this.$refs.itemsGrid.getGridInstance());
      }
    },
    buildInventoryItemName(item) {
      return ItemsNamingHelper.concatenateVendorAndStyleNames(item);
    }
  }
};
</script>

<style scoped>
.chipContainer {
    min-height: 75px;
    padding-top: 20px;
    padding-bottom: 10px;
}
.selectButton {
    padding-bottom: 20px;
}
.toolbar {
    margin-top: 10px;
    margin-left: 1px;
    margin-right: 1px;
}
</style>
