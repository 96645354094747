<template>
  <v-stepper-header>
    <v-stepper-step :complete="step > 1" step="1">
      Choose Vendor And Branch
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :complete="step > 2" step="2">
      Choose Item(s)
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :complete="step > 3" step="3">
      Enter Quantity
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :complete="step > 4" step="4">
      Overview
    </v-stepper-step>
  </v-stepper-header>
</template>

<script>
export default {
  props: {step: Number}
};
</script>
