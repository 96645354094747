<template>
  <v-form ref="searchPONumber" class="searchForm" :onsubmit="false">
    <v-row>
      <v-col md="3">
        <purchase-order-number-autocomplete
            v-model="poNumber"
            :excludeClosed="excludeClosed"
            @enterKeyPress="handleSearchPurhcaseOrderNumber"
        />
      </v-col>
      <v-btn
          class="mt-2 pa-5"
          id="search-purchase-order-number-button"
          color="primary"
          :disabled="!poNumber"
          @click="handleSearchPurhcaseOrderNumber"
      >
        Search
      </v-btn>
      <v-spacer/>
      <v-col md="3" v-if="ifShowJobReferenceId">
        <read-only-text-field-with-hover-and-link-cell-template
            :value="jobReferenceId"
            :link="navLink"
            :show="ifShowJobReferenceId"
            label="Job Reference ID"
        />
      </v-col>
    </v-row>
    <span class="searchError">{{ searchError }}</span>
  </v-form>
</template>

<script>
import PurchaseOrderNumberAutocomplete from "../../searchFields/PurchaseOrderNumberAutocomplete.vue";
import ReadOnlyTextFieldWithHoverAndLinkCellTemplate
  from "../../common/grid/cellTemplates/ReadOnlyTextFieldWithHoverAndLinkCellTemplate.vue";

export default {
  components: {
    ReadOnlyTextFieldWithHoverAndLinkCellTemplate,
    PurchaseOrderNumberAutocomplete
  },

  props: {
    value: String,
    searchError: String,
    jobReferenceId: String,
    ifShowJobReferenceId: Boolean,
    excludeClosed: Boolean,
  },

  data() {
    return {
      poNumber: null,
    };
  },

  computed: {
    navLink() {
      return `/jobs/details/${this.jobReferenceId}`;
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(updated) {
        this.poNumber = updated;
      },
    },
  },

  methods: {
    validate() {
      return this.$refs.searchPONumber.validate();
    },
    handleSearchPurhcaseOrderNumber() {
      this.$emit('searchPurchaseOrderNumber', this.poNumber);
    }
  },
};
</script>

<style scoped>
.searchForm {
  padding-top: 60px;
}

.searchError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
