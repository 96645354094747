<template>
  <drawer-form
      ref="form"
      title="Edit Inventory Bin"
      submit-label="Save Bin"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <bin-form ref="binForm" mode="update"/>
  </drawer-form>
</template>

<script>
import BinForm from "@/components/bins/BinForm.vue";
import Bins from "@/rest/bins";
import DrawerForm from "../common/form/DrawerForm";

export default {
  components: {
    DrawerForm,
    BinForm
  },

  props: {binId: Number},

  data() {
    return {inProgress: false};
  },

  watch: {
    bin(newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },

  methods: {
    loadForm(binData) {
      this.$refs.binForm.loadForm(binData);
    },
    onCancel() {
      this.$emit("cancelClicked");
    },
    async onSubmit() {
      try {
        this.inProgress = true;
        const isValid = this.$refs.form.validate();
        if (isValid) {
          const payload = this.$refs.binForm.buildPayload();
          const response = await Bins.getRestApi().updateBin(this.binId, payload);
          this.$emit("submitSuccess", response.data);
        }
      } catch (error) {
        this.$emit("submitFailed", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
