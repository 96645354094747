<template>
  <v-card light width="'1500px'">
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Schedule Shipment</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <stepper-headers
          :step="step"
          step-one-name="Choose Branches"
          step-two-name="Choose Dates"
          step-three-name="Choose Transfer Request(s)"
          step-four-name="Overview"
          :max-step-number=4
          :editable="false"
      />
      <v-stepper-items>
        <v-stepper-content step="1">
          <choose-branches-step
              ref="branchesChooser"
              :activeBranches="activeBranches"
              @branchSelectionNeeded="onBranchSelectionNeeded"
              @branchesSelected="onAllBranchesSelected"
          />
          <button-container-with-cancel
              backName=Cancel
              nextName=Next
              :hidePreviousStep="true"
              :disableNextStep="firstNextStepDisabled"
              @cancel="cancelForm"
              @getNextStep="stepOneContinue"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <choose-dates-step
              ref="datesChooser"
              v-if="step !== 1"
              @showError="showStepTwoError"
              @datesSelected="allDatesSelected"
              @partiallySelected="onPartiallySelected"
              @estimatedDeliveryIsEarlierThanPickUpDate="onEstimatedDeliveryIsEarlierThanPickUpDate"
          />
          <p class="submitError" v-show="stepTwoError">{{ stepTwoError }}</p>
          <button-container-with-cancel
              backName=Back
              nextName=Next
              :disableNextStep="secondNextStepDisabled"
              @cancel="cancelForm"
              @getPreviousStep="getBackToStepOne"
              @getNextStep="stepTwoContinue"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <select-transfer-request-container
              ref="selectTransferRequestContainer"
              v-if="step>=3"
              :fromBranchName="selectedShipFromBranch.name"
              :toBranchName="selectedShipToBranch.name"
              @selected="onTransferRequestSelected"
              @showError="onStepThreeError"
              @clearError="onClearError"
          />
          <p class="submitError" v-show="stepThreeError">{{ stepThreeError }}</p>
          <button-container-with-cancel
              backName=Back
              nextName=Next
              :disableNextStep="thirdNextStepDisabled"
              @cancel="cancelForm"
              @getPreviousStep="getBackToStep(2)"
              @getNextStep="stepThreeContinue"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <shipment-overview
              v-if="overview"
              ref="shipmentOverview"
              :overview="overview"
          />
          <button-container-with-cancel
              :nextLoading="inProgress"
              backName=Back
              nextName=Submit
              @cancel="cancelForm"
              @getPreviousStep="getBackToStep(3)"
              @getNextStep="submitForm"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import StepperHeaders from "../common/stepper/headers/ConfigurableStepperHeaders.vue";
import ShipmentStepperHeaders from "../common/stepper/headers/ConfigurableStepperHeaders.vue";
import ButtonContainerWithCancel from "../common/wizard/ButtonContainerWithCancel.vue";
import ButtonContainer from "../common/wizard/ButtonContainer.vue";
import ChooseBranchesStep from "../shipment/steps/ChooseBranchesStep.vue";
import ChooseDatesStep from "./steps/ChooseDatesStep.vue";
import TransferRequestsGrid from "../../views/transferRequests/TransferRequestsGrid.vue";
import TransferRequestsChooserInlineGrid from "../../views/transferRequests/TransferRequestsChooserInlineGrid.vue";
import SelectTransferRequestContainer from "../../views/shipments/SelectTransferRequestContainer.vue";
import Shipments from "../../rest/shipments";
import ShipmentOverview from "./steps/ShipmentOverview.vue";

export default {
  data: () => ({
    selectedTransferRequests: [],
    selectedShipToBranch: null,
    selectedShipFromBranch: null,
    selectedPickupDate: null,
    selectedEstimatedDeliveryDate: null,
    overview: null,
    stepTwoError: null,
    stepThreeError: null,
    step: 1,
    inProgress: false,
    firstNextStepDisabled: true,
    secondNextStepDisabled: true,
    thirdNextStepDisabled: true,
    transferRequestPlan: null
  }),

  components: {
    ShipmentOverview,
    SelectTransferRequestContainer,
    TransferRequestsChooserInlineGrid,
    TransferRequestsGrid,
    ChooseDatesStep,
    ChooseBranchesStep,
    ShipmentStepperHeaders,
    ButtonContainer,
    ButtonContainerWithCancel,
    StepperHeaders,
  },

  props: {
    activeBranches: {
      type: Array,
      required: true
    }
  },

  methods: {
    async submitForm() {
      const payload = this.createScheduleShipmentPayload();
      try {
        this.inProgress = true;
        let response = await this.submitScheduleShipmentRequest(payload);
        if (response) {
          this.handleSubmitScheduleShipmentSuccess(response.data.shipmentNumber);
        }
      } catch (error) {
        this.$emit('showError', error);
      }
    },
    stepOneContinue() {
      let valid = this.$refs.branchesChooser.$refs.form.validate();
      if (valid) {
        this.selectedShipFromBranch = this.$refs.branchesChooser.$refs.fromBranch.selected;
        this.selectedShipToBranch = this.$refs.branchesChooser.$refs.toBranch.selected;
        this.goToNextStep(2);
      }
    },
    stepTwoContinue() {
      let validated = !this.$refs.datesChooser.hasDateError();
      if (validated) {
        this.selectedPickupDate = this.$refs.datesChooser.pickupDate;
        this.selectedEstimatedDeliveryDate = this.$refs.datesChooser.estimatedDeliveryDate;
        this.stepTwoError = null;
        this.thirdNextStepDisabled = false;
        this.goToNextStep(3);
      }
    },
    goToNextStep(step) {
      this.step = step;
    },
    getBackToStepOne() {
      this.step = 1;
      this.stepTwoError = null;
      this.secondNextStepDisabled = true;
      this.selectedTransferRequests = [];
      // this.$refs.selectInventoryItems.clearGridState();
    },
    async stepThreeContinue() {
      this.stepThreeError = null;
      this.overview = this.createOverview();
      this.selectedTransferRequests = this.$refs.selectTransferRequestContainer.selected.map(tr => tr.Id);
      this.goToNextStep(4);
    },
    onAllBranchesSelected() {
      this.firstNextStepDisabled = false;
    },
    onBranchSelectionNeeded() {
      this.firstNextStepDisabled = true;
    },
    async submitScheduleShipmentRequest(payload) {
      try {
        return await Shipments.getRestApi().scheduleShipment(payload);
      } catch (error) {
        this.handleSubmitScheduleShipmentError(error);
      } finally {
        this.inProgress = false;
      }
    },
    createScheduleShipmentPayload() {
      return {
        shipFromId: this.selectedShipFromBranch.id,
        shipToId: this.selectedShipToBranch.id,
        pickUpDate: this.selectedPickupDate,
        estimatedDeliveryDate: this.selectedEstimatedDeliveryDate,
        transferRequestIds: this.selectedTransferRequests
      }
    },
    createOverview() {
      return {
        shipFrom: this.selectedShipFromBranch.name,
        shipTo: this.selectedShipToBranch.name,
        pickUpDate: this.selectedPickupDate,
        estimatedDeliveryDate: this.selectedEstimatedDeliveryDate,
        transferRequests: this.$refs.selectTransferRequestContainer.selected
      }
    },
    getBackToStep(step) {
      this.stepTwoError = null;
      this.stepThreeError = null;
      if (step === 2) {
        this.thirdNextStepDisabled = true;
      }
      this.step = step;
    },
    cancelForm() {
      this.resetWizard();
      this.$emit('cancel');
    },
    handleSubmitScheduleShipmentError(error) {
      this.inProgress = false;
      this.$emit('showError', error);
    },
    handleSubmitScheduleShipmentSuccess(shipmentNumber) {
      this.resetWizard();
      this.$emit('success', `Shipment ${shipmentNumber} has been successfully scheduled!`);
    },
    showStepTwoError(error) {
      this.stepTwoError = error;
      this.secondNextStepDisabled = true;
    },
    resetWizard() {
      this.step = 1;
      this.inProgress = false;
      this.stepTwoError = null;
      this.stepThreeError = null;
      this.selectedTransferRequests = [];
      this.selectedShipToBranch = null;
      this.selectedShipFromBranch = null;
      this.selectedPickupDate = null;
      this.selectedEstimatedDeliveryDate = null;
      if (this.$refs.branchesChooser) {
        this.$refs.branchesChooser.resetSelected();
      }
      if (this.$refs.datesChooser) {
        this.$refs.datesChooser.resetForm();
      }
      if (this.$refs.selectTransferRequestContainer) {
        this.$refs.selectTransferRequestContainer.clearGridState();
      }
    },
    onStepThreeError(error) {
      this.stepThreeError = error;
    },
    onClearError() {
      this.stepThreeError = null;
    },
    allDatesSelected() {
      this.stepTwoError = null;
      this.secondNextStepDisabled = false;
    },
    onPartiallySelected() {
      this.secondNextStepDisabled = true;
    },
    onTransferRequestSelected() {
      this.thirdNextStepDisabled = false;
    },
    onEstimatedDeliveryIsEarlierThanPickUpDate() {
      this.secondNextStepDisabled = true;
      this.stepTwoError = "Estimated delivery date must not be earlier than the pickup date.";
    }
  }
};
</script>

<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
