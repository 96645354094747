<template>
  <v-autocomplete
      v-model="selected"
      :search-input.sync="searchInput"
      @input="searchInput=null"
      item-text="text"
      :item-value="value.value"
      :placeholder="placeholder"
      :rules="required ? [rules.requiredArray] : []"
      :items="daysInWeek"
      :label="label"
      :readonly="readonly"
      :multiple="multiple"
      :required="required"
      :disabled="disabled"
      :clearable="clearable"
      data-id="working-days-autocomplete"
      light
      dense
      attach
      hide-no-data
      validate-on-blur
      persistent-placeholder
  >
    <template v-slot:prepend-item v-if="selectAllEnabled">
      <select-all-autocomplete
          class="working-days-autocomplete"
          ref="selectAllAutocomplete"
          :items="daysInWeek"
          :selected="selected"
          @select="selectAll"
          @deselect="deselectAll"
      >
      </select-all-autocomplete>
    </template>
  </v-autocomplete>
</template>

<script>
import SelectAllAutocomplete
  from "../../components/searchFields/selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";
import Rules from "../../assets/rules";
import Constants from "../../assets/constants";

export default {
  components: {
    SelectAllAutocomplete
  },
  data() {
    return {
      selected: null,
      rules: {
        requiredArray: Rules.requiredArray,
      },
      searchInput: null,
      daysInWeek: Constants.daysInWeek
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String | Array,
      required: false,
      default: () => Constants.daysInWeek
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: '* Working Days'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    selectAllEnabled: {
      type: Boolean,
      default: false,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select working days of the crew'
    },
    selectedWorkingDays: {
      type: Array,
      required: false
    }
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selectedWorkingDays: {
      immediate: true,
      handler(val) {
        if (val && val.length > 0) {
          this.setSelectedWorkingDays(val);
        } else {
          this.clearSelectedWorkingDays();
        }
      },
    },
  },

  methods: {
    clearSelectedWorkingDays() {
      this.selected = [];
    },
    setSelectedWorkingDays(selected) {
      this.selected = selected;
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    selectAll() {
      this.selected = this.daysInWeek.map(item => item.value);
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>

.v-input--selection-controls.v-input {
  margin-top: 0 !important;
}
</style>
