<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-row class="mt-0 pt-0" v-show="hasLaborItems">
        <v-col sm="12">
          <job-labor-line-items-search-field
              ref="jobLaborLineItems"
              label="* Line Item"
              v-model="jobLineItemId"
              :job-id="jobId"
              :noExistingInstallation="true"
              @hasLaborLineItems="onLaborItemsUpdated"
              @input="onLaborLineItemSelected"
          />
        </v-col>
        <v-col sm="6">
          <date-picker-with-type-in-text-field
              ref="installationDatePicker"
              v-model="installationDate"
              label="* Installation Date"
              validationName="Installation date"
              data-id="installation-install-date"
              placeholder=" "
              :allowedDates="allowedDates"
              @input="onDateChange"
          />
        </v-col>
        <v-col sm="6">
          <date-picker-with-type-in-text-field
              v-model="etcDate"
              ref="etcDatePicker"
              label="* ETC Date"
              validationName="ETC date"
              data-id="installation-etc-date"
              placeholder=" "
              :allowedDates="allowedETCDates"
          />
        </v-col>
        <v-col sm="12">
          <installer-crew-search-field
              ref="installerCrewSearchField"
              id="installer-crew-dropdown"
              :placeholder="'Search Installer Crews'"
              :label="'* Installer Crew'"
              :value="installerCrewSlotId"
              :required="true"
              :availableInstallerCrews="installerCrews"
              @input="onInstallerCrewUpdated"
              :itemTextField="'installerCrewName'"
              :itemValueField="'id'"
              :no-data-text="'No crews available for this date'"
          />
        </v-col>
        <v-col sm="12">
          <v-textarea
              v-model="comment"
              placeholder=" "
              persistent-placeholder
              label="Comment"
              data-id="installation-comment"
              :rules="[rules.max255]"
          />
        </v-col>
      </v-row>
      <v-card flat v-if="!hasLaborItems">
        <v-card-text class="subheading">
          All labor items have already been associated with installations.
        </v-card-text>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/assets/rules.js";
import JobLaborLineItemsSearchField from "@/components/searchFields/JobLaborLineItems.vue";
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField.vue";
import InstallerCrewSearchField from "@/components/searchFields/InstallerCrews.vue";
import InstallerSlots from "@/rest/installer/installerSlots";
import DateUtils from "@/assets/utils/dateUtils";

const formDefaults = {
  jobLineItemId: null,
  installerCrewSlotId: null,
  installationDate: null,
  etcDate: null,
  comment: null
};

export default {
  components: {
    InstallerCrewSearchField,
    JobLaborLineItemsSearchField,
    DatePickerWithTypeInTextField
  },

  props: {
    jobId: Number,
    branchId: Number,
    title: String,
    submitLabel: String
  },

  data() {
    return Object.assign({}, formDefaults, {
      hasLaborItems: false,
      rules: {
        time: Rules.time,
        max255: value => Rules.maxValue(value, 255)
      },
      installerCrewSlots: [],
      availableDates: []
    });
  },

  watch: {
    installationDate() {
      this.getInstallerCrews();
    },
    jobLineItemId() {
      this.getAvailableDates();
    }
  },

  computed: {
    capabilityId() {
      if (this.jobLineItemId) {
        const selectedLineItem = this.$refs.jobLaborLineItems.getSelectedLineItem();
        return selectedLineItem.installationCapabilityId;
      }
    },
    installerCrews() {
      // Keep only unique installer crews (remove extra slots for the same crew
      return [...new Map(this.installerCrewSlots.map(item => [item['installerCrewId'], item])).values()];
    }
  },

  methods: {
    allowedDates: function (value) {
      return this.availableDates.includes(value);
    },
    onDateChange: function () {
      this.$refs.etcDatePicker.clearSelected();
      this.etcDate = null;
    },
    allowedETCDates: function (value) {
      return this.installationDate ? DateUtils.isSameOrAfter(value, this.installationDate) : false;
    },
    reset() {
      Object.assign(this.$data, formDefaults);
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    onLaborItemsUpdated(hasLaborItems) {
      this.hasLaborItems = hasLaborItems;
      this.$emit("hasLaborLineItems", hasLaborItems);
    },
    refreshLaborLineItems() {
      try {
        this.$refs.jobLaborLineItems.refresh();
      } catch (err) {
        console.log(err);
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    buildPayload() {
      let payload = {};
      payload.jobId = this.jobId;
      payload.jobLineItemId = this.jobLineItemId;
      payload.installationDate = this.installationDate;
      payload.installerCrewSlotId = this.installerCrewSlotId;
      payload.etcDate = this.etcDate;

      payload.comment = this.comment;
      return payload;
    },
    onInstallerCrewUpdated(value) {
      this.installerCrewSlotId = value;
    },

    getInstallerCrews() {
      if (this.installationDate && this.branchId && this.capabilityId) {
        InstallerSlots.getRestApi().getSlotsByDateAndCapabilityId(this.branchId, this.installationDate, this.capabilityId)
            .then((response) => {
              this.installerCrewSlots = response.data.filter((slot) => {
                return slot.status === "AVAILABLE";
              })
            })
            .catch((error) => {
              this.$emit("showError", error);
            });
      }
    },

    getAvailableDates() {
      if (this.branchId && this.capabilityId) {
        InstallerSlots.getRestApi().getAvailableDatesByCapabilityId(this.branchId, this.capabilityId)
            .then((response) => {
              this.availableDates = response.data;
            })
            .catch((error) => {
              this.$emit("showError", error);
            });
      }
    },

    onLaborLineItemSelected() {
      this.$refs.installationDatePicker.clearSelected();
      this.$refs.etcDatePicker.clearSelected();
      this.$refs.installerCrewSearchField.clearSelectedCrew();
      this.$refs.form.resetValidation();
    }
  }
};
</script>
