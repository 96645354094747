const CrewStopsHelper = {
  groupsHaveSameJobAndCrewAndInstallationDate(array) {
    const seenValues = new Set();

    for (let i = 0; i < array.length; i++) {
      const {crewId, installationDate, jobId} = array[i];
      const key = `${jobId}-${crewId}-${installationDate}`;

      if (seenValues.has(key)) {
        return true;
      }

      seenValues.add(key);
    }

    return false;
  },
  groupsHaveSameCrewAndInstallationDateButDifferentJobs(array) {
    if (array.length) {
      const crewId = array[0].crewId;
      const installationDate = array[0].installationDate;
      return array.every(obj => obj.crewId === crewId && obj.installationDate === installationDate)
        && array.some(obj => obj.jobId !== array[0].jobId);
    } else return false;
  },
}

export default CrewStopsHelper;
