<template>
  <v-autocomplete
      v-model="selected"
      hide-no-data
      hide-selected
      :label="label || 'Line Item'"
      item-text="description"
      item-value="id"
      :items="lineItems"
      :loading="loading"
      placeholder="Search Labor Line Items"
      persistent-placeholder
      id="labor-line-items-search-field"
      :rules="[rules.required]"
      required
      attach
      dense
      data-id="job-labor-line-items-autocomplete"
  />
</template>

<script>
import Jobs from "@/rest/jobs";

export default {
  components: {},

  data() {
    return {
      rules: {
        required: value => !!value || "Labor is required"
      },
      selected: null,
      lineItems: [],
      loading: false
    };
  },

  props: ["jobId", "placeholder", "noExistingInstallation", "label"],

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("input", value);
    }
  },

  created() {
    this.refresh();
  },

  methods: {
    async refresh() {
      try {
        const data = await this.getLaborLineItems();
        this.setData(data);
      } catch (error) {
        this.$emit("error", error);
      }
    },
    async getLaborLineItems() {
      try {
        const response = await Jobs.getRestApi().getJobLaborLineItems(this.jobId);
        let result = response.data;
        if (this.noExistingInstallation) {
          result = result.filter((item) => {
            return item.installationId == null;
          });
        }
        return result;
      } catch (error) {
        throw error;
      }
    },
    setData(data) {
      this.lineItems = data;
      this.$emit("hasLaborLineItems", data.length ? true : false);
    },
    getSelected() {
      return this.selected;
    },
    getSelectedLineItem() {
      return this.lineItems.find((item) => {
        return item.id === this.selected;
      });
    }
  }
};
</script>
