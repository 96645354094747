<template>
  <div>
    <v-card flat>
      <section-divider title="BRANCH SPECIFIC ITEMS"/>
      <headers/>
      <panel
          :job="job"
          :line-item="item"
          v-for="item in bcaItems"
          :key="item.referenceId"
      />
    </v-card>
  </div>
</template>

<script>
import Headers from "../../../components/jobs/bcaLineItems/BcaHeaders.vue";
import Panel from "../../../components/jobs/bcaLineItems/BcaPanel.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    initialItems: {
      type: Array,
      required: true
    }
  },

  data() {
    return {bcaItems: []};
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.bcaItems = value;
      }
    }
  },

  methods: {}
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
