<template>
  <v-form ref="EditPOItemForm" v-model="valid" lazy-validation>
    <v-card flat>
      <v-container class="pa-4 mt-8 mb-8 pb-4">
        <v-row>
          <v-col md="4" class=" mt-2 pt-2 pr-6 pl-6">
            <v-text-field
                :rules="[rules.required, rules.isPositiveAndGreaterThanZero]"
                name="input-po-edit-quantity"
                label="Quantity"
                persistent-placeholder
                v-model="quantity"
                class="input-group--focused"
                type="number"
                autofocus
            />
          </v-col>

          <v-col md="4" class=" mt-2 pt-2 pr-6 pl-6">
            <v-text-field
                name="input-po-edit-order"
                label="Order"
                persistent-placeholder
                v-model="order"
                readonly
            />
          </v-col>

          <v-col md="4" class=" mt-2 pt-2 pr-6 pl-6">
            <v-text-field
                name="input-po-edit-total-vendor-cost"
                label="Total Vendor Cost"
                persistent-placeholder
                v-model="totalVendorCost"
                readonly
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>

import Rules from "../../../assets/rules";
import BoxQuantity from "../../../assets/boxQuantity";
import SpecialUserPasswordValidation from "../../../rest/passwordValidator";

const formDefaults = {};

export default {
  components: {},

  data() {
    return {
      valid: false,
      password: null,
      show: false,
      selectedLineItem: null,
      quantity: 0,
      order: 0,
      totalVendorCost: 0,
      rules: {
        required: Rules.required,
        isPositiveAndGreaterThanZero: Rules.isPositiveAndGreaterThanZero,
      }
    }
  },

  props: {
    selectedItem: Object
  },

  watch: {
    selectedItem: {
      immediate: true,
      handler(val) {
        this.selectedLineItem = val.data;
        this.quantity = val.data.quantityOrdered;
        this.order = val.data.quantityOrdered;
        this.totalVendorCost = val.data.totalVendorCostRounded;
      }
    },
    quantity(val) {
      this.recalculateForm(val);
    }
  },

  methods: {
    async validatePasswordForm() {
      if (this.password) {
        return await this.validatePassword(this.password);
      } else return false;

    },
    async validatePassword(password) {
      try {
        const response = await SpecialUserPasswordValidation.getRestApi().validateSpecialUserPassword(password);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    loadDefaults() {
      this.password = null;
      this.show = false;
      this.quantity = 0;
      this.order = 0;
      this.totalVendorCost = 0;
      this.selectedLineItem = null;
    },
    recalculateForm(quantity) {
      this.quantity = quantity;
      this.order = BoxQuantity.roundValueToBoxQuantity(quantity, this.selectedLineItem.productCatalogItem.boxQuantity);
      this.totalVendorCost = (this.order * this.selectedLineItem.vendorCost).toFixed(2);
    },
    getOrder() {
      return this.order;
    },
    getVendorCost() {
      return this.totalVendorCost;
    }
  }
};
</script>
