// Google Places - utility functions

const getSuggestions = async (searchText, usaOnly) => {
    let result;
    try {
        const rawResult = await searchLocation(searchText, usaOnly);
        result = rawResult.map(res => {
            return {
                id: res.place_id,
                value: res.description
            };
        });
    } catch (err) {
        result = null;
    }
    return result;
};

const searchLocation = async (val, usaOnly) => {
    return await new Promise((resolve, reject) => {
        const displaySuggestions = (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                reject(status);
            }
            resolve(predictions);
        };
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(
            {
                input: val,
                types: ["address"],
                componentRestrictions: usaOnly ? {country: "us"} : {}
            },
            displaySuggestions
        );
    }).catch((err) => {
        throw err;
    });
};

const getPlacesService = () =>
    new google.maps.places.PlacesService(document.createElement("div"));

const getPlacesOkStatus = () => google.maps.places.PlacesServiceStatus.OK;

const getPlaceAddress = place => {
    const getAddressValue = (address, addressType) => {
        const addressComponent = address.find(el => el.types.includes(addressType));
        return addressComponent ? addressComponent.short_name : "";
    };
    const addressComponents = place.address_components;
    /*eslint-disable */
    return {
        streetNumber: `${getAddressValue(addressComponents, "street_number")}`,
        street: `${getAddressValue(addressComponents, "route")}`,
        city: `${getAddressValue(addressComponents, "locality")}`,
        neighborhood: `${getAddressValue(addressComponents, "neighborhood")}`,
        county: `${getAddressValue(addressComponents, "administrative_area_level_2")}`,
        state: `${getAddressValue(addressComponents, "administrative_area_level_1")}`,
        country: `${getAddressValue(addressComponents, "country")}`,
        zipCode: `${getAddressValue(addressComponents, "postal_code")}`,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
    };
    /*eslint-enable */
};

export {getSuggestions, getPlacesService, getPlacesOkStatus, getPlaceAddress, searchLocation};
