<template>
  <v-container class="full-width">
    <h5 class="headline"> Set up Multi-Factor Authentication</h5>
    <h5>(scan QR-code with Microsoft Authenticator app)</h5>
    <div class="ma-auto position-relative">
      <div class="qr-code">
        <img :src="base64Prefix + authResponse?.userMfaSettings?.secretImageUri" alt="qr-code" width="30%">
      </div>
      <otp-form
          id="verify-otp-button-auth-app-set-up"
          header="Enter 6-digit Code from your App"
          :showGoBackButton="false"
          :userMfaSettings="authResponse.userMfaSettings"
          @backToLogin="onBackToLoginClicked"
          @otpEntered="onOtpAppSetupFinished"
      />
    </div>
  </v-container>
</template>
<script>

import OtpForm from "@/views/users/OtpForm.vue";

export default {
  components: {
    OtpForm
  },

  props: {
    authResponse: {
      type: Object,
      default: null,
      required: false
    }
  },

  data() {
    return {
      base64Prefix: "data:image/png;base64,"
    };
  },

  methods: {
    onBackToLoginClicked() {
      this.$emit('backToLoginFromSetUpForm');
    },
    onOtpAppSetupFinished(verificationObject) {
      this.$emit('otpAppSetupFinished', verificationObject);
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}

.position-relative {
  position: relative;
}
</style>
