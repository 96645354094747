<template>
  <v-row>
    <v-col md="12" v-show="Roles.isAnyAdminRoleUser">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="crews">Crews</v-tab>
          <v-tab-item key="crews">
            <crews-grid
                ref="crewsGrid"
                :crews="crews"
                :capabilities="capabilities"
                :branch="branch"
                :installer="installer"
                :branches="branches"
                @saveCrew="onSaveCrew"
                @updateCrew="onUpdateCrew"
                @getCrews="onGetCrews"
                @emitError="emitError"
                @emitPasswordOverriddenSuccess="emitPasswordOverriddenSuccess"
                @resetMfaSuccess="onResetMfaSuccess"
            />
          </v-tab-item>
          <v-tab key="daysOff">Days off</v-tab>
          <v-tab-item v-if="Roles.isAnyAdminRoleUser" key="daysOff">
            <days-off-grid
                ref="daysOffGrid"
                :crews="crews"
                :installerRefId="installerReferenceId"
            />
          </v-tab-item>
          <v-tab key="secondaryBranchTime">Time In The Secondary Branch</v-tab>
          <v-tab-item key="secondaryBranchTimeGrid">
            <crew-time-in-secondary-branch-grid
                ref="secondaryBranchTimeGrid"
                :crews="crews"
                :installerRefId="installerReferenceId"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Notes
          </v-expansion-panel-header>
          <v-expansion-panel-content key="notes">
            <notes-grid
                ref="notesGrid"
                :notes="notes"
                :isAddNoteButtonDisplayed="isActionButtonDisplayed"
                @saveNote="onSaveNote"
                @getNotes="onGetNotes"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                :isActionButtonDisplayed="isActionButtonDisplayed"
                ref="files"
                :installerReferenceId="installerReferenceId"
                parent-type="installer"
                :documentTypesCategory=Constants.documentTypesCategories.INSTALLER
            />
          </v-tab-item>
          <v-tab key="activityLogs">Activity Logs</v-tab>
          <v-tab-item key="activityLogs">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Roles from "../../assets/roles";
import Constants from "../../assets/constants";
import DaysOffGrid from "./crews/dayoff/DaysOffGrid.vue";
import CrewsGrid from "./crews/CrewsGrid.vue";
import InstallerCrews from "../../rest/installersCrew";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import NotesGrid from "../../components/common/inlineGrids/NotesGrid.vue";
import FilesGrid from "../../components/common/inlineGrids/files/FilesGrid.vue";
import Installers from "../../rest/installers.ts";
import CrewTimeInSecondaryBranchGrid
  from "@/views/installers/crews/secondaryBranchTime/CrewTimeInSecondaryBranchGrid.vue";

export default {
  components: {
    CrewTimeInSecondaryBranchGrid,
    ActivityLogsGrid,
    NotesGrid,
    FilesGrid,
    CrewsGrid,
    DaysOffGrid
  },
  props: {
    installerReferenceId: {
      type: String,
      required: true
    },
    capabilities: {
      type: Array,
      required: true
    },
    branch: {
      type: Object,
      required: true
    },
    installer: {
      type: Object,
      required: true
    },
    branches: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      notes: [],
      activityLogs: [],
      expanded: Constants.expandedByDefault,
      crews: [],
      mapDays: Constants.weekDaysAbbreviation
    };
  },

  computed: {
    Roles() {
      return Roles
    },
    Constants() {
      return Constants
    },
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    emitSuccess(message) {
      this.$emit("showSuccess", message);
    },
    emitPasswordOverriddenSuccess() {
      this.$emit('showSuccess', 'Installer Crew password has been changed successfully!');
    },
    async getNotesData() {
      try {
        const response = await Installers.getRestApi().getInstallerNotes(this.installerReferenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async saveNote(note) {
      try {
        await Installers.getRestApi().createInstallerNote(this.installerReferenceId, note);
      } catch (error) {
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.emitSuccess('Installer note saved successfully!');
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    async onSaveNote(note) {
      try {
        await this.saveNote(note);
        this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async onGetNotes() {
      try {
        this.notes = await this.getNotesData();
      } catch (error) {
        this.emitError(error);
      }
    },
    async getActivityLogs() {
      try {
        const response = await Installers.getRestApi().getInstallerActivityLogs(this.installerReferenceId);
        this.activityLogs = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async onSaveCrew(crew) {
      try {
        await InstallerCrews.getRestApi().createCrew(crew);
        this.onSaveCrewSuccess();
      } catch (error) {
        this.emitError(error);
        this.$refs.crewsGrid.onCreateError();
      }
      this.inProgress = false;
    },
    async onSaveCrewSuccess() {
      try {
        await this.onGetCrews();
        this.emitSuccess('Crew saved successfully!');
        this.$refs.crewsGrid.onSuccessSave();
        if (this.$refs.activityLog) {
          this.$refs.activityLog.emitGetLogs();
        }
      } catch (error) {
        throw error;
      }
    },
    async onGetCrews() {
      try {
        const data = await this.getCrews();

        data.forEach(crew => {
          let workingDaysParse = []
          crew.workingDays.forEach(dayValue => {
            workingDaysParse.push(this.mapDays[dayValue]);
          })
          crew.workingDaysToShow = workingDaysParse.join(', ');
        });
        this.crews = data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async getCrews() {
      try {
        const response = await InstallerCrews.getRestApi().getInstallerCrews(this.$props.installer.id);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async onUpdateCrew(crew) {
      try {
        const response = await InstallerCrews.getRestApi().updateCrew(crew);
        if (response) {
          this.onUpdateCrewSuccess();
        }
      } catch (error) {
        this.emitError(error);
        this.$refs.crewsGrid.onUpdateError();
      }
      this.inProgress = false;
    },
    async onUpdateCrewSuccess() {
      try {
        await this.onGetCrews();
        this.emitSuccess('Crew updated successfully!');
        // await this.onGetDaysOff();
        this.$refs.crewsGrid.onUpdateSave();
        if (this.$refs.activityLog) {
          this.$nextTick(() => this.$refs.activityLog.emitGetLogs());
        }
      } catch (error) {
        throw error;
      }
    },
    onResetMfaSuccess() {
      this.$emit('resetMfaSuccess');
    },
  }
};
</script>
