<template>
  <modal-dialog
      ref="dialog"
      :title="modalTitle"
      width="400"
      :visible="dialogVisible"
      :submitLabel="submitLabel"
      cancelLabel="Cancel"
      @submitClicked="onAddClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="submitEnabled"
  >
    <v-form ref="form">
      <v-container>
        <v-row class="mt-4">
          <v-col md="12">
            <date-picker-with-type-in-text-field
                v-if="costs"
                v-model="effectiveOn"
                label="Cost Start Date"
                validationName="Start date"
                required
                :allowedDates="allowedDates"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
                v-model="cost"
                :label="fieldLabel"
                placeholder=" "
                persistent-placeholder
                prefix="$"
                type="number"
                :rules="[rules.required, rules.isCurrencyFormat]"
                required
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import Rules from "../../assets/rules";
import DateFormatter from "../../assets/dateFormatter";
import ProductCatalogItems from "../../rest/catalogItems";
import ModalDialog from "../../components/common/ModalDialog.vue";
import DatePickerWithTypeInTextField from "../../components/common/form/DatePickerWithTypeInTextField.vue";
import SalesRepPrices from "../../rest/salesRepPrices";
import DateUtils from "@/assets/utils/dateUtils";

const formDefaults = {
  effectiveOn: null,
  cost: null,
  submitEnabled: true
};

export default {
  data() {
    return Object.assign(
      {
        dialogVisible: false,
        error: null,
        reservedDates: []
      },
      formDefaults,
      {
        rules: {
          required: Rules.required,
          isCurrencyFormat: Rules.isCurrencyFormat
        }
      }
    );
  },

  components: {
    ModalDialog,
    DatePickerWithTypeInTextField
  },

  props: {
    catalogItemId: {
      type: Number,
      required: true
    },
    internalSku: {
      type: String,
      required: true,
    },
    costs: {
      type: Array,
      required: true,
      default: () => []
    },
    isMigratedItem: {
      type: Boolean,
      required: true
    },
    submitLabel: {
      type: String,
      required: false,
    },
    fieldLabel: {
      type: String,
      required: false,
      default: 'Cost'
    },
    modalTitle: {
      type: String,
      required: false
    }
  },

  watch: {
    costs: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.length && newVal !== oldVal) {
          this.createArrayOfReservedCostDates();
        }
      }
    }
  },

  methods: {
    reset() {
      Object.assign(this.$data, formDefaults);
      this.$refs.form.reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    openDialog() {
      this.reset();
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    createArrayOfReservedCostDates() {
      const {costs} = this.$props;
      let reservedDates = [];
      for (let i = 0; i < costs.length; i++) {
        let effectiveOn = DateFormatter.formatDateInYYYYMMDDFormat(costs[i].effectiveOn);
        reservedDates.push(effectiveOn);
      }
      this.reservedDates = reservedDates;
    },
    // Find which dates can be selected
    allowedDates(value) {
      if (value && !this.isMigratedItem) {
        const {reservedDates} = this.$data;
        return !reservedDates.includes(value);
      } else {
        return DateUtils.allowedTodayAndFutureDates(value);
      }
    },
    validateAddPriceForm() {
      return this.$refs.form.validate(true);
    },
    onAddClicked() {
      let isValid = this.validateAddPriceForm();
      if (isValid) {
        let cost = {
            id: null,
            cost: this.cost,
            effectiveOn: this.effectiveOn,
            active: true,
            createdAt: null,
            updatedAt: null,
        };
        this.performAddItemCostRequest(cost);
      }
    },
    performAddItemCostRequest(payload) {
      this.submitEnabled = false;

      if (this.isMigratedItem) {
        // request for migrated items
      SalesRepPrices.getRestApi().addSalesRepPriceForCatalogItem(this.internalSku, payload)
        .then((response) => {
          this.closeDialog();
          this.submitEnabled = true;
          this.$emit('costAdded', response.data);
        })
        .catch((error) => {
          this.submitEnabled = true;
          this.$refs.dialog.showError(error.response.data.message);
        });
      } else {
        // request for non-migrated items
        ProductCatalogItems.getRestApi()
          .addCatalogItemCost(this.catalogItemId, payload)
          .then((response) => {
            this.closeDialog();
            this.submitEnabled = true;
            this.$emit('costAdded', response.data);
          })
          .catch((error) => {
            this.submitEnabled = true;
            this.$refs.dialog.showError(error.response.data.message);
          });
      }
    },
    onCancelClicked() {
      this.dialogVisible = false;
    }
  }
};
</script>
