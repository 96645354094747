<template>
  <dx-data-grid
      ref="groupedInventoryItemsByBranch"
      :data-source="dataSource"
      :show-borders="true"
      :hover-state-enabled="true"
      :load-panel="{ enabled: false }"
      :columnMinWidth="150"
      @selectionChanged="onRowSelected"
  >
    <dx-filter-row :visible="true"/>
    <dx-header-filter :visible="true"/>
    <dx-column-fixing :enabled="true"/>
    <dx-paging :page-size="5"/>
    <dx-pager
        :show-page-size-selector="false"
        :allowed-page-sizes="[5]"
        :show-info="true"
    />
    <dx-selection mode="single"/>
    <dx-column
        data-field="InternalSku"
        caption="Internal SKU"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ProductType"
        caption="Product Type"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ProductStyle"
        caption="Style"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ProductColor"
        caption="Color"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="BoxQuantity"
        caption="Box Quantity"
        data-type="number"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="RollNumber"
        caption="Roll Number"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="DyeLot"
        caption="Dye Lot"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="QuantityAvailable"
        caption="Qty Available"
        data-type="number"
        :allowHeaderFiltering="false"
    />
  </dx-data-grid>
</template>

<script>
import "devextreme/data/odata/store";
import {
  DxColumn,
  DxColumnFixing,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxSelection
} from "devextreme-vue/ui/data-grid";
import ODataContext from "../../rest/odataContext";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSelection
  },

  props: {
    selectedFromBranch: {
      type: Object,
      required: true
    },
  },


  created() {
    if (this.selectedFromBranch) {
      this.setBranchFilter(this.selectedFromBranch.referenceId);
    }
  },

  data() {
    return {
      currencyFormat: {
        type: "currency",
        precision: 2
      },
      dataSource: {
        store: ODataContext['GroupedInventoryItems'],
        select: [
          "Id",
          "InternalSku",
          "ProductType",
          "ProductStyle",
          "ProductColor",
          "BoxQuantity",
          "RollNumber",
          "UnitOfMeasure",
          "DyeLot",
          "QuantityAvailable",
          "BranchReferenceId",
        ],
      }
    }
  },

  methods: {
    onRowSelected(event) {
      this.$emit('rowSelected', event);
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.clearSorting();
      gridInstance.filter(["BranchReferenceId", "=", this.selectedFromBranch.referenceId]);
    },
    getGridInstance() {
      return this.$refs.groupedInventoryItemsByBranch.instance;
    },
    deselectItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    getSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return [...selectedRows];
    },
    setBranchFilter(branchReferenceId) {
      if (branchReferenceId) {
        this.dataSource.filter = ["BranchReferenceId", "=", branchReferenceId];
      }
    },
  }
};
</script>
