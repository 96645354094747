import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * Product type.
 */
export class ProductType {
  id: number;
  name: string;
  active: boolean;
  description: string;
  isRolled: boolean;
  hasDyeLot: boolean;

  constructor(id: number, name: string, description: string, active: boolean, isRolled: boolean, hasDyeLot: boolean) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.active = active;
    this.isRolled = isRolled;
    this.hasDyeLot = hasDyeLot;
  }
}

/**
 * REST interface provided for product types.
 */
export interface ProductTypeRestApi {
  getProductTypes(): AxiosPromise<ProductType[]>;
  getActiveProductTypes(): AxiosPromise<ProductType[]>;
  getProductTypeById(id: number): AxiosPromise<ProductType>;
  getProductTypeLogs(id: number): AxiosPromise<ActivityLog[]>;
  createProductType(payload: ProductType): AxiosPromise<ProductType>;
  updateProductType(id: number, updated: ProductType): AxiosPromise<ProductType>;
}

/**
 * REST implementation provided for product types.
 */
class Implementation implements ProductTypeRestApi {
  getActiveProductTypes(): AxiosPromise<ProductType[]> {
    return Axios.axiosInstance().get<ProductType[]>("product_types/active");
  }
  getProductTypes(): AxiosPromise<ProductType[]> {
    return Axios.axiosInstance().get<ProductType[]>("product_types");
  }
  getProductTypeById(id: number): AxiosPromise<ProductType> {
    return Axios.axiosInstance().get<ProductType>(`/product_types/${id}`);
  }
  getProductTypeLogs(id: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/product_types/${id}/logs`);
  }
  createProductType(payload: ProductType): AxiosPromise<ProductType> {
    return Axios.axiosInstance().post<ProductType>("/product_types", payload);
  }
  updateProductType(id: number, updated: ProductType): AxiosPromise<ProductType> {
    return Axios.axiosInstance().put<ProductType>(`/product_types/${id}`, updated);
  }
}

const ProductTypes = {
  getRestApi(): ProductTypeRestApi {
    return new Implementation();
  }
};

export default ProductTypes;
