<template>
  <v-card light>
    <v-divider/>
    <v-spacer/>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot/>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: [],

  data() {
    return {};
  }
};
</script>
