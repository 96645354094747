<template>
  <div class="email-template" ref="template" @dblclick="select">
    <v-layout column>
      <table v-if="purchaseOrder" class="table">
        <tbody>
          <tr>
            <td class="td">Purchase Order #:</td>
            <td class="td">{{ purchaseOrder.purchaseOrderNumber }}</td>
          </tr>
          <tr>
            <td class="td">Order Date:</td>
            <td class="td">{{ purchaseOrder.dateIssued }}</td>
          </tr>
          <tr v-if="customer">
            <td class="td">Customer:</td>
            <td class="td">{{ customer.firstName }} {{ customer.lastName }}</td>
          </tr>
          <tr class="line-break"></tr>
          <tr>
            <td class="td">Ship To:</td>
            <td class="td">
              {{ purchaseOrder.branch.shipmentAddress }}
              {{ purchaseOrder.branch.shipmentCity }}, {{ purchaseOrder.branch.shipmentState }}
              {{ purchaseOrder.branch.shipmentZipCode }}
            </td>
          </tr>
          <tr class="line-break"></tr>
          <tr>
            <td class="td">Vendor Cost Total:</td>
            <td class="td">$ {{ totalVendorCostRounded }}</td>
          </tr>
          <tr class="line-break"></tr>
          <tr>
            <td class="td">Line Items:</td>
            <td class="td"></td>
          </tr>
          <tr class="line-break"></tr>
          <tr class="line-break"></tr>
        </tbody>
      </table>
      <table v-if="items.length > 0" class="table">
        <thead>
          <tr>
            <td class="td td-head">Type</td>
            <td class="td td-head">Style</td>
            <td class="td td-head">Color</td>
            <td class="td td-head">SKU</td>
            <td class="td td-head">Unit Of Measure</td>
            <td class="td td-head">Quantity</td>
            <td class="td td-head">Vendor Cost</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td class="td">{{ item.productCatalogItem.productType.name }}</td>
            <td class="td">{{ item.productCatalogItem.styleName }}</td>
            <td class="td">{{ item.productCatalogItem.color }}</td>
            <td class="td">{{ item.productCatalogItem.vendorSku }}</td>

            <td class="td">{{ item.productCatalogItem.unitOfMeasure }}</td>
            <td class="td">{{ item.quantityOrdered }}</td>
            <td class="td">
              $
              {{
                item.totalVendorCostRounded
                  ? item.totalVendorCostRounded.toFixed(2)
                  : null
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-layout>
  </div>
</template>
<script>

export default {
  components: {
  },

  data() {
    return {
      job: null
    };
  },

  props: {
    items: Array,
    purchaseOrder: Object,
    customer: Object,
    totalVendorCostRounded: String
  },

  methods: {
    copy: function() {
      this.select();
      document.execCommand("Copy");
      this.deselect();
    },
    select: function() {
      const element = document.querySelector(".email-template");
      if (document.selection) {
        let range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        let range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
    deselect: function() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }
  }
};
</script>
<style scoped>
.email-template {
  box-shadow: inset 0px 0px 2px grey;
  padding: 10px 5px;
  overflow: scroll;
  max-height: 500px;
  font-family: "Courier New";
}
.table {
  border-collapse: collapse;
}
.td {
  padding: 0px 5px;
  text-align: left;
}
.td-head {
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}
.line-break {
  height: 10px;
}
</style>
