<template>
  <div class="status-dropdown">
    <v-select
        v-if="!readonly && !disabled"
        label="Status"
        placeholder=" "
        v-model="selected"
        :items="statusChoices"
        persistent-placeholder
        attach
    />
    <v-text-field
        v-else
        readonly
        label="Status"
        :value="value ? 'Active' : 'Inactive'"
        placeholder=" "
        persistent-placeholder
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      statusChoices: [
        {text: "Inactive", value: false},
        {text: "Active", value: true}
      ]
    };
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: Boolean,
    disabled: Boolean
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      this.$emit("onStatusChange", value);
    }
  }
};
</script>
