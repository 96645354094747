<template>
  <div class="mt-1 mb=1">
    <p class="text-left mb-1">
      <b class="special-bold-color"> Only Transfer Requests with the Status = 'New' are listed here</b>
    </p>
    <div class="text-left mb-1">
      <v-icon color="primary">mdi-information-outline</v-icon>
      <span class="info-transfer-request"> It is not necessary to select the transfer requests now.
        If you are scheduling a shipment for the future, you will be able to add transfer requests to it later
      </span>
    </div>
    <dx-data-grid
        ref="transferRequests"
        :data-source="dataSource"
        :showColumnLines="true"
        :showRowLines="false"
        :show-borders="true"
        :hover-state-enabled="true"
        :columnMinWidth="150"
        :branchUserRestricted="true"
        no-data-text="Availalble Transfer Requests not found"
        @selectionChanged="onSelectionChanged"
        @contentReady="onGridContentReady"
    >
      <dx-paging :page-size="5"/>
      <dx-pager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
      />
      <dx-selection
          :mode="selectionMode"
          :select-all-mode="selectionConfig.selectAllMode"
          :show-check-boxes-mode="selectionConfig.showCheckBoxesMode"
      />
      <dx-column
          data-field="CreatedAt"
          caption="Creation Date"
          data-type="date"
          format="MM/dd/yyyy"
          :allowHeaderFiltering="false"
          sort-order="desc"
      />
      <dx-column
          data-field="TransferFromBranch"
          caption="Transfer From"
          :allowHeaderFiltering="false"
          data-type="string"
      />
      <dx-column
          data-field="TransferToBranch"
          caption="Transfer To"
          :allowHeaderFiltering="false"
          data-type="string"
      />
      <dx-column
          data-field="TransferRequestNumber"
          caption="Transfer Request Number"
          :allowHeaderFiltering="false"
          data-type="string"
      />
    </dx-data-grid>
  </div>
</template>

<script>
import GridView from "../common/GridView.vue";
import {DxColumn, DxDataGrid, DxPager, DxPaging, DxSelection} from "devextreme-vue/ui/data-grid";
import ODataContext from "../../rest/odataContext";
import TransferRequestWizard from "../../components/transferRequest/TransferRequestWizard.vue";

export default {
  components: {
    TransferRequestWizard,
    GridView,
    DxPager,
    DxSelection,
    DxPaging,
    DxColumn,
    DxDataGrid
  },

  props: {
    fromBranch: String,
    toBranch: String,
    status: String
  },

  data() {
    return {
      pageSizes: [5, 10, 15],
      dataSource: {
        store: ODataContext['WebTransferRequests'],
        select: [
          "Id",
          "CreatedAt",
          "TransferFromBranch",
          "TransferToBranch",
          "TransferRequestNumber",
          "Status",
        ],
        sort: [{selector: "CreatedAt", desc: true}],
        filter: this.applyFilter()
      },
      selectionMode: "single",
      selectionConfig: {
        mode: "single",
        selectAllMode: "page",
        showCheckBoxesMode: null,
      },
    };
  },

  methods: {
    onSelectionChanged(e) {
      let selectedRows = e.selectedRowKeys;
      this.$emit('selectionChanged', selectedRows)
    },
    getSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return [...selectedRows];
    },
    getGridInstance() {
      return this.$refs.transferRequests.instance;
    },
    deselectItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
      gridInstance.deselectAll();
    },
    selectAllItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.selectAll(); // select all items even on the next page (that is not visible)
    },
    getTotalRowsCount() {
      const gridInstance = this.getGridInstance();
      return gridInstance.totalCount();
    },
    showError(error) {
      this.$refs.transferRequests.showError(error);
    },
    onSubmitFailed(error) {
      this.$refs.transferRequests.showError(error);
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      this.dataSource.filter = this.applyFilter();
      gridInstance.clearSorting();
      gridInstance.columnOption('CreatedAt', {
        sortOrder: 'desc',
        sortIndex: 0
      });
    },
    applyFilter() {
      return [
        ["tolower(TransferFromBranch)", "contains", `${this.fromBranch}`],
        ["tolower(TransferToBranch)", "contains", `${this.toBranch}`],
        ["tolower(Status)", "contains", `${this.status}`]
      ]
    },
    onGridContentReady() {
      let rowsCount = this.getTotalRowsCount();
      this.$emit('gridLoaded', rowsCount);
    },
  },
};
</script>

<style scoped>
.special-bold-color {
  color: #224E69;
}

.info-transfer-request {
  font-size: 15px;
}
</style>
