<template>
  <v-container>
    <grid-toolbar
        @onToggleResetGridDialog="clearGrid"
    />
    <dx-data-grid
        ref="dataGrid"
        :data-source="dataSource"
        :show-borders="true"
        :hover-state-enabled="true"
        :load-panel="{ enabled: false }"
        noDataText="No available items found"
        @selectionChanged="onSelectionChanged"
        @contentReady="onContentReady"
    >
      <dx-filter-row :visible="true"></dx-filter-row>
      <dx-column-fixing :enabled="true"></dx-column-fixing>
      <dx-paging :page-size="5"/>
      <dx-pager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          infoText="Page {0} of {1} - {2} items"
      />
      <dx-selection mode="single"></dx-selection>
      <dx-column
          data-field="JobReferenceId"
          caption="Job ID"
          datatype="String"
      />
      <dx-column
          data-field="LineItemReferenceId"
          caption="Line Item ID"
          datatype="String"
      />
      <dx-column
          data-field="ReservationAmount"
          caption="Reserved Count"
          alignment="left"
          datatype="Number"
      />
    </dx-data-grid>
  </v-container>
</template>

<script>
import "devextreme/data/odata/store";
import {
  DxColumn,
  DxColumnFixing,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSelection
} from "devextreme-vue/ui/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import ODataContext from "../../rest/odataContext";
import GridToolbar from "../../components/common/grid/GridToolbar.vue";

export default {
  data() {
    return {
      selected: null,
      pageSizes: [5, 10, 15],
      dataSource: {},
    };
  },

  components: {
    GridToolbar,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxSelection
  },

  props: {
    inventoryItemId: {
      type: Number | String,
      required: true
    }
  },

  created() {
    this.initDataSource();
  },

  methods: {
    initDataSource() {
      this.dataSource = {
        store: ODataContext['InventoryReservations'],
        select: [
          "Id",
          "InventoryItemId",
          "JobReferenceId",
          "LineItemReferenceId",
          "CheckedOut",
          "ReservationAmount",
        ],
        filter: [
          ["CheckedOut", "=", false],
          ["InventoryItemId", "=", Number(this.inventoryItemId)],
          ["JobReferenceId", "<>", null]
        ]
      }
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      this.dataSource.filter = [[
        ["CheckedOut", "=", false],
        ["InventoryItemId", "=", Number(this.inventoryItemId)],
        ["JobReferenceId", "<>", null]
      ]];
      gridInstance.clearSorting();
      gridInstance.columnOption('Id', {
        sortOrder: 'asc',
        sortIndex: 0
      });
    },
    getGridInstance() {
      return this.$refs.dataGrid.instance;
    },
    clearSelection() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    resetGridToDefaults() {
      this.clearSelection();
      this.clearGrid();
    },
    onSelectionChanged(event) {
      let data = event.selectedRowsData[0];
      if (data) {
        this.selected = data;
        this.$emit('productSelected', data);
      }
    },
    onContentReady() {
      const inventoryReservations = this.getGridInstance().getDataSource().items();
      const hasJobReservationsToMove = inventoryReservations
          ? inventoryReservations.some(inventoryReservation => inventoryReservation.JobReferenceId !== null && !inventoryReservation.CheckedOut)
          : false;

      if (hasJobReservationsToMove) {
        this.$emit('hasJobReservationsToMove');
      }
    }
  }
};
</script>

<style scoped>
.toolbar {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

::v-deep .dx-datagrid-rowsview tr.dx-row.dx-data-row.dx-row-lines td {
  padding: 9px;
}

::v-deep tr.dx-row.dx-header-row td {
  padding: 10px !important;
}
</style>
