<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="leads">Leads</v-tab>
          <v-tab-item key="leads">
            <customer-leads
                ref="customerLeadsGrid"
                :customer-reference-id="customerReferenceId"
            />
          </v-tab-item>

          <v-tab key="jobs">Jobs</v-tab>
          <v-tab-item key="jobs">
            <customer-jobs :customer-reference-id="customerReferenceId"/>
          </v-tab-item>

          <v-tab key="notes">Notes</v-tab>
          <v-tab-item key="notes">
            <notes-grid
                ref="notesGrid"
                :notes="notes"
                @saveNote="onSaveNote"
                @getNotes="onGetNotes"
            />
          </v-tab-item>
          <v-tab key="activityLogs">Activity Logs</v-tab>
          <v-tab-item key="activityLogs">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Customers from "@/rest/customers.ts";
import ActivityLogsGrid from "@/components/common/inlineGrids/ActivityLogsGrid.vue";
import CustomerLeads from "@/components/customers/CustomerLeads";
import CustomerJobs from "@/components/customers/CustomerJobs";
import NotesGrid from "@/components/common/inlineGrids/NotesGrid.vue";
import Notes from "../../rest/notes";

export default {
  components: {
    CustomerLeads,
    ActivityLogsGrid,
    CustomerJobs,
    NotesGrid
  },
  props: {
    customerReferenceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      activityLogs: [],
      notes: []
    };
  },

  methods: {
    emitError(error) {
      this.$emit("showError", error);
    },
    async onSaveNote(data) {
      try {
        const params = {...data};
        params.customerReferenceId = this.customerReferenceId;
        await this.saveNote(params);
        await this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async saveNote(params) {
      try {
        await Notes.getRestApi().createCustomerNote(this.customerReferenceId, params);
      } catch (error) {
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    async onGetNotes() {
      try {
        const response = await this.getCustomerNotes();
        this.setCustomerNotes(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getCustomerNotes() {
      try {
        return await Notes.getRestApi().getCustomerNotes(this.customerReferenceId);
      } catch (error) {
        this.emitError(error);
      }
    },
    setCustomerNotes(notes) {
      this.notes = notes;
    },
    setActivityLogs(logs) {
      this.activityLogs = logs;
    },
    async refreshActivityLog() {
      try {
        if (this.$refs.activityLog) {
          const data = await this.getActivityLogs();
          this.setActivityLogs(data);
        }
      } catch (error) {
        this.emitError(error);
      }
    },
    async getActivityLogs() {
      try {
        const response = await Customers.getRestApi().getCustomerActivityLogs(this.customerReferenceId);
        this.activityLogs = response.data;
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
