import { render, staticRenderFns } from "./OngoingNotificationsTable.vue?vue&type=template&id=349873be&scoped=true"
import script from "./OngoingNotificationsTable.vue?vue&type=script&lang=js"
export * from "./OngoingNotificationsTable.vue?vue&type=script&lang=js"
import style0 from "./OngoingNotificationsTable.vue?vue&type=style&index=0&id=349873be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349873be",
  null
  
)

export default component.exports