<template>
  <v-btn
      icon
      small
      v-if="canDeleteItems"
  >
    <v-tooltip v-if="showToolTip" left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            :color="colorSchema"
            v-bind="attrs"
            v-on="on"
        >
          delete
        </v-icon>
      </template>
      <span>{{ toolTipMessage }}</span>
    </v-tooltip>
    <v-icon
        v-else
        :color="colorSchema"
        @click="deleteProductClicked"
    >
      delete
    </v-icon>
  </v-btn>
</template>

<script>

import ProductLineItemStatuses from "../../../assets/jobBuilder/productLineItemStatuses";

export default {
  components: {},

  props: {
    lineItem: {
      type: Object,
      required: true,
    },
    canDeleteItems: {
      type: Boolean,
      required: true,
      default: true
    },
    toolTipMessage: {
      type: String,
      required: false,
      default: ""
    },
  },

  computed: {
    colorSchema() {
      return this.lineItem.jobLineItem.status !== ProductLineItemStatuses.CHECKED_OUT.status ? "primary" : "grey";
    },
    showToolTip() {
      return this.lineItem.jobLineItem.status === ProductLineItemStatuses.CHECKED_OUT.status;
    }
  },

  data() {
    return {};
  },

  methods: {
    deleteProductClicked() {
      this.$emit("deleteProductClicked", this.lineItem.jobLineItem);
    }
  }

}
</script>
<style scoped>

</style>
