<template>
  <v-select
      class="misc-select"
      dense
      data-id="job-misc-item-type"
      label=" "
      :value="value"
      :items="items"
      :disabled="disabled"
      :readonly="readonly"
      item-value="id"
      item-text="name"
      @change="onItemSelected"
      placeholder=" "
      attach
  />
</template>

<script>
import Constants from "@/assets/constants";

export default {
  props: {
    value: Number,
    readonly: Boolean,
    disabled: Boolean,
    miscItemTypes: {
      type: Array,
      required: true
    }
  },

  data() {
    return {};
  },

  computed: {
    items() {
      return this.miscItemTypes.map(item => {
        return {
          id: item.id,
          name: item.name,
          disabled: item.id === Constants.miscItemNeedsReviewId,
        };
      });
    }
  },

  methods: {
    onItemSelected(value) {
      this.$emit("input", value);
    }
  },
};
</script>

<style scoped>
.misc-select >>> div.v-select__selection {
  font-size: 14px;
}
</style>
