<template>
  <grid-view
      ref="nav"
      page-title="List Transfer Requests"
      nav-section="warehouse"
      :data-source="dataSource"
      :columnMinWidth="150"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      :branchUserRestricted="false"
      :action-button-label="actionButtonLabel"
      action-button-action="createTransferRequestWizard"
      :customWizardWidth="1300"
      no-data-text="Transfer Requests not found"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="CreatedAt"
        caption="Creation Date"
        data-type="date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        sort-order="desc"
    />
    <dx-column
        data-field="TransferFromBranch"
        caption="Transfer From"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="TransferToBranch"
        caption="Transfer To"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="TransferRequestNumber"
        caption="Transfer Request Number"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        width="auto"
        data-field="Status"
        caption="Status"
        cell-template="statusWithIcon"
        :header-filter="{ dataSource: statusesFilter }"
        :allowHeaderFiltering="true"
        :allowSorting="false"
    />
    <dx-column
        data-field="ShipmentNumber"
        caption="Shipment Number"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="PickupDate"
        caption="Pickup Date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        data-type="date"
    />
    <dx-column
        data-field="EstimatedDeliveryDate"
        caption="Estimated Delivery Date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        data-type="date"
    />
    <dx-column
        data-field="ClosedDate"
        caption="Closed Date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        data-type="date"
    />

    <template v-slot:wizardContent>
      <transfer-request-wizard
          ref="transferRequestWizard"
          :activeBranches="activeBranches"
          @success="onSubmitSuccess"
          @error="onSubmitFailed"
          @cancelClicked="onCancelClicked"
          @showError="showError"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "../common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "../../rest/odataContext";
import TransferRequestWizard from "../../components/transferRequest/TransferRequestWizard.vue";
import Branches from "../../rest/branches";
import Roles from "../../assets/roles";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter";
import Constants from "@/assets/constants";

export default {
  components: {
    TransferRequestWizard,
    GridView,
    DxColumn,
  },
  data() {
    return {
      activeBranches: [],
      statusesFilter: [],
      dataSource: {
        store: ODataContext['WebTransferRequests'],
        select: [
          "Id",
          "CreatedAt",
          "TransferFromBranch",
          "TransferToBranch",
          "TransferRequestNumber",
          "Status",
          "ShipmentNumber",
          "PickupDate",
          "EstimatedDeliveryDate",
          "ClosedDate",
        ],
        sort: [{selector: "CreatedAt", desc: true}]
      }
    };
  },

  created() {
    if (Roles.isSuperAdminOrAdminUser()) {
      this.getAllActiveBranches();
    }
    this.statusesFilter = CustomizeFilter.mapArrayForHeaderFiltering(Constants.transferRequestStatuses);
  },

  computed: {
    actionButtonLabel() {
      return Roles.isSuperAdminOrAdminUser() ? 'Create Transfer Request' : null;
    }
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/inventory/transfer-requests/${data.TransferRequestNumber}`);
    },
    async getAllActiveBranches() {
      try {
        const response = await Branches.getRestApi().getAllActiveBranches();
        this.activeBranches = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    onCancelClicked() {
      this.hideWizard();
    },
    hideWizard() {
      this.$refs.nav.hideWizard();
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    onSubmitSuccess(message) {
      this.hideWizard();
      this.$refs.nav.refreshGrid();
      this.$refs.nav.showSuccess(message);
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
  },
};
</script>
