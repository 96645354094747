<template>
  <v-row class="mt-2">
    <v-col sm="12" v-if="create">
      <v-text-field
          disabled
          data-id="ticket-form-user"
          label="User"
          v-model="userName"
          placeholder=" "
      />
    </v-col>
    <v-col sm="12" v-if="create">
      <v-text-field
          disabled
          data-id="ticket-form-customer"
          label="Associated Customer"
          v-model="customerName"
          placeholder=" "
      />
    </v-col>
    <v-col sm="12" v-if="leadReferenceId && create">
      <v-text-field
          disabled
          data-id="ticket-form-lead"
          label="Associated Lead"
          v-model="leadReferenceId"
          placeholder=" "
      />
    </v-col>
    <v-col sm="12" v-if="jobReferenceId && create">
      <v-text-field
          disabled
          data-id="ticket-form-job"
          label="Associated Job"
          v-model="jobReferenceId"
          placeholder=" "
      />
    </v-col>
    <v-col cols="12" sm="6">
      <users-search-field
          ref="usersDropdown"
          :value="assigneeId"
          :includeInactive="includeInactive"
          inputSelected="onUserSelect"
      />
    </v-col>
    <v-col cols="12" sm="6">
      <ticket-status-search-field
          :value="statusId"
          @onStatusSelect="onStatusSelect"
      />
    </v-col>
    <v-col>
      <v-textarea
          no-resize
          data-id="ticket-form-description"
          label="* Description"
          v-model="description"
          placeholder=" "
          persistent-placeholder
          counter="1024"
          :rules="[rules.required, rules.max1024]"
      />
    </v-col>
  </v-row>
</template>

<script>
import TicketStatusSearchField from "../../components/searchFields/TicketStatus.vue";
import UsersSearchField from "../../components/searchFields/UsersSearchField.vue";
import Rules from "../../assets/rules";
import RouterHelper from "../../assets/routerHelper";

const formDefaults = {
  id: null,
  referenceId: null,
  description: null,
  customerName: null,
  userName: null,
  statusId: 1,
  jobReferenceId: null,
  leadReferenceId: null,
  customerReferenceId: null,
  assigneeId: null
};

export default {
  components: {
    TicketStatusSearchField,
    UsersSearchField
  },

  props: {
    create: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    includeInactive() {
      return !RouterHelper.isLeadOrJobDetailsPage(this.$route);
    }
  },

  data() {
    return Object.assign(
        {
          displayTaxId: false,
          rules: {
            required: value => {
              if (typeof value === "number") {
                return value >= 0 || "Required";
              }
              return !!value || "Required";
            },
            max1024: value => {
              return Rules.maxValue(value, 1024);
            }
          }
        },
        formDefaults
    );
  },

  methods: {
    changeStatus(value) {
      this.active = value;
    },
    setState(value) {
      this.state = value;
    },
    onStatusSelect(value) {
      this.statusId = value;
    },
    getSelectedUserId() {
      return this.$refs.usersDropdown.selected;
    },
    buildPayload() {
      let payload = {};
      if (!this.create) {
        payload.id = Number(this.id);
      }
      payload.jobReferenceId = this.jobReferenceId;
      payload.leadReferenceId = this.leadReferenceId;
      payload.userId = Number(this.userId);
      payload.statusId = Number(this.statusId);
      payload.customerReferenceId = this.customerReferenceId;
      payload.description = this.description;
      payload.assigneeId = this.getSelectedUserId();
      return payload;
    },
    loadTicketFrom(payload) {
      if (!payload) {
        payload = formDefaults;
      }
      this.assigneeId = payload.assigneeId;
      this.referenceId = payload.referenceId;
      this.userId = payload.userId;
      this.userName = payload.userName;
      this.jobReferenceId = payload.jobReferenceId;
      this.jobId = payload.jobId;
      this.leadReferenceId = payload.leadReferenceId;
      this.statusId = payload.statusId;
      this.customerReferenceId = payload.customerReferenceId;
      this.customerId = payload.customerId;
      this.customerName = payload.customerName;
      this.description = payload.description;
    }
  }
};
</script>
