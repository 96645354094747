<template>
  <navigation-view ref="nav" page-title="Product Type" nav-section="admin">
    <template v-slot:content>
      <v-container v-if="productType" grid-list-lg fluid>
        <v-row class="mt-2">
          <v-col md="5">
            <v-row>
              <v-col md="6">
                <v-text-field
                    v-model="productType.name"
                    label="Product Type"
                    placeholder=" "
                    readonly
                    persistent-placeholder
                />
              </v-col>
              <v-col md="6">
                <status-dropdown
                    :value="productType.active"
                    @onStatusChange="v => (productType.active = v)"
                    readonly
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-row class="mt-0 mr-2">
            <v-col md="12">
              <v-text-field
                  v-model="productType.description"
                  label="Description"
                  placeholder=" "
                  persistent-placeholder
                  readonly
              />
            </v-col>
          </v-row>
        </v-row>
        <product-subtypes-inline-grid
            ref="productTypesInlineGrid"
            :productType="productType"
            @needsRefreshPage="refresh"
            @onSubmitSubtypeFailed="onSubmitFailed"
        />
        <inline-grids
            ref="inlineGrids"
            :productTypeId="productTypeId"
            @refreshActivityLogs="refreshActivityLogs"
        />
      </v-container>
    </template>
    <template v-slot:drawer>
      <product-type-update-form
          ref="update"
          :productType="productType"
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>
    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Product Type"
          @click="showDrawer"
      />
    </template>

  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import ProductTypes from "@/rest/productTypes";
import ProductTypeUpdateForm from "@/components/productTypes/ProductTypeUpdateForm.vue";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import InlineGrids from "@/views/productTypes/ProductTypesInlineGrids.vue";
import ProductSubtypesInlineGrid from "../productSubtypes/ProductSubtypesInlineGrid";
import Roles from "../../assets/roles";

export default {
  components: {
    ProductSubtypesInlineGrid,
    NavigationView,
    NavigationActionButton,
    ProductTypeUpdateForm,
    StatusDropdown,
    InlineGrids
  },
  data() {
    return {
      productTypeId: null,
      productType: null
    };
  },

  // Called on initial create.
  created() {
    this.display(this.$route.params.productTypeId);
  },

  // Called when component is reused.
  beforeRouteUpdate(to, from, next) {
    this.display(to.params.productTypeId);
    next();
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    display(productTypeId) {
      this.productTypeId = Number(productTypeId);
      this.getProductTypeById();
    },
    async getProductTypeById() {
      try {
        const response = await ProductTypes.getRestApi().getProductTypeById(this.productTypeId);
        this.setProductType(response.data);
      } catch (error) {
        this.onSubmitFailed(error);
      }
    },
    showDrawer() {
      this.$refs.update.refresh(this.$data.productType);
      this.$refs.nav.showDrawer();
    },
    onSubmitSuccess(data) {
      this.$refs.nav.hideDrawer();
      this.$refs.nav.showSuccess("Updated product type details successfully!");
      this.$refs.inlineGrids.refreshActivityLog();
      this.setProductType(data);
    },
    setProductType(data) {
      this.productType = data;
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    refresh() {
      this.refreshDetailsPage();
      this.refreshActivityLogs();
      this.$refs.nav.showSuccess("Product SubType has been added successfully!");
    },
    refreshDetailsPage() {
      this.getProductTypeById();
    },
    refreshActivityLogs() {
      this.$refs.inlineGrids.refreshActivityLog();
    }
  }
};
</script>
