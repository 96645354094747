import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Purchase Order Reason code.
 */
export class PurchaseOrderReasonCode {
    id: number = 0;
    name: string = "";
    active: boolean;
    isJobRelated: boolean;

    constructor(id: number, name: string, active: boolean, isJobRelated: boolean) {
        this.id = id;
        this.name = name;
        this.active = active;
        this.isJobRelated = isJobRelated;
    }
}

export interface PurchaseOrderReasonCodesRestApi {
    getPurchaseOrderReasonCodes(): AxiosPromise<PurchaseOrderReasonCode[]>;
}

class Implementation implements PurchaseOrderReasonCodesRestApi {
    getPurchaseOrderReasonCodes(): AxiosPromise<PurchaseOrderReasonCode[]> {
        return Axios.axiosInstance().get<PurchaseOrderReasonCode[]>("close-purchase-order-reason-codes/all");
    }
}

const PurchaseOrderReasonCodes = {
    getRestApi(): PurchaseOrderReasonCodesRestApi {
        return new Implementation();
    }
};

export default PurchaseOrderReasonCodes;
