<template>
  <navigation-view
      ref="nav"
      page-title="Transfer Request Details"
      nav-section="warehouse"
  >
    <template v-slot:content>
      <v-skeleton-loader
          :loading="isLoading"
          type="subtitle, article, list-item-two-line, subtitle, list-item-three-line, divider,
          table-heading, table-thead, table-tbody, table-row-divider, table-row, table-tfoot"
      >
        <v-container grid-list-lg fluid id="transfer-request-details">
          <v-row class="mt-1" no-gutters>
            <transfer-request-details-read-only-form
                ref="transferDetails"
                :transferRequest="transferRequest"
            />
          </v-row>

          <transfer-request-inline-grids
              v-if="transferRequest"
              ref="transferRequestInlineGrids"
              :transferNumber="transferNumber"
              :transferRequestStatus="transferRequest.status"
              @itemClose="itemClose"
          />
        </v-container>
      </v-skeleton-loader>
    </template>

    <template v-slot:nav-action>
      <v-skeleton-loader
          :loading="isLoading"
      >
        <v-row id="buttons-row" class="d-flex justify-end ma-0 pb-0 pt-0">
          <v-col class="pb-0 bt-0 pr-1 pl-0 pt-0">
            <navigation-action-button
                :showActionButtons="!!showCloseTransferRequestButton"
                label="Close Transfer Request"
                @click="showCloseTransferRequestModal"
            />
          </v-col>
        </v-row>
        <close-transfer-request-wizard
            ref="closeTransferRequestWizard"
            :transferRequest="transferRequest"
            @submitCloseTransferRequestSuccess="onCloseTransferRequestSuccess"
            @submitCloseTransferRequestFailed="onSubmitFailed"
        />
      </v-skeleton-loader>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import TransferRequestDetailsReadOnlyForm from "./TransferRequestDetailsReadOnlyForm.vue";
import TransferRequests from "../../rest/transferRequests";
import NavigationActionButton from "../navigation/NavigationActionButton.vue";
import TransferRequestInlineGrids from "./TransferRequestInlineGrids.vue";
import CloseTransferRequestWizard
  from "../../components/transferRequest/closeTransferRequestItem/CloseTransferRequestWizard.vue";
import {TransferRequestStatuses} from "@/assets/statusIcons/statusIcons";
import Roles from "@/assets/roles";

export default {
  components: {
    NavigationActionButton,
    NavigationView,
    TransferRequestDetailsReadOnlyForm,
    TransferRequestInlineGrids,
    CloseTransferRequestWizard,
  },

  data() {
    return {
      transferRequest: null,
      transferNumber: null,
      isLoading: true,
    };
  },

  created() {
    this.display(this.$route.params.transferRequestNumber);
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },

  computed: {
    showCloseTransferRequestButton() {
      return Roles.isSuperAdminOrAdminUser() && (this.transferRequest && !this.transferRequest.closedDate && this.isTransferRequestOpen);
    },
    isTransferRequestOpen() {
      const openStatuses = [
        TransferRequestStatuses.NEW.name,
        TransferRequestStatuses.NEW.fullName,
        TransferRequestStatuses.DELIVERY_SCHEDULED.name,
        TransferRequestStatuses.DELIVERY_SCHEDULED.fullName
      ];
      return openStatuses.includes(this.transferRequest.status);
    }
  },

  methods: {
    async display(transferNumber) {
      this.transferNumber = transferNumber;
      await this.refresh();
    },
    setTransferRequestData(data) {
      this.transferRequest = data;
    },
    async refresh() {
      try {
        const data = await this.getAllTransferRequestData();
        this.setTransferRequestData(data);
      } catch (error) {
        this.showError(error);
      }
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    async getAllTransferRequestData() {
      try {
        const response = await TransferRequests.getRestApi().getTransferRequestByTransferNumber(this.transferNumber);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    showCloseTransferRequestModal(selectedItem) {
      this.$refs.closeTransferRequestWizard.openCloseTransferRequestDialog(selectedItem);
    },
    onSubmitFailed(error) {
      this.showError(error);
    },
    itemClose(selectedItem) {
      this.showCloseTransferRequestModal(selectedItem[0]);
    },
    onCloseTransferRequestSuccess(isItemClosed) {
      let message = isItemClosed ? "Transfer Request Item has been closed successfully!" : "Transfer Request has been closed successfully!";
      this.$refs.closeTransferRequestWizard.resetWizard();
      this.$refs.nav.showSuccess(message);
      this.refresh();
      this.$refs.transferRequestInlineGrids.refreshActivityLog();
      this.$refs.transferRequestInlineGrids.refreshInventoryToTransferItemsGrid();
    },
  }
};
</script>
