<template>
  <v-form ref="reasonCodeForm" v-model="valid">
    <v-card flat>
      <v-container class="pa-4 pb-4">
        <v-row>
          <v-col cols="12">
            <close-p-o-reason-code-dropdown
                ref="reasonCodeDropDown"
                :isRelatedToJob="isRelatedToJob"
                @select="optionSelected"
            />
          </v-col>
          <v-col cols="12" class=" mt-0 pt-0 pr-6 pl-6">
            <v-textarea
                :auto-grow="true"
                v-model="comment"
                label="Comment"
                placeholder=""
                persistent-placeholder
                :rules="[rules.max950]"
                counter="950"
                :maxlength="950"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import ClosePOReasonCodeDropdown from "./ClosePOReasonCodeDropdown";
import Rules from "../../../assets/rules";

export default {
  components: {
    ClosePOReasonCodeDropdown
  },

  props: {
    isRelatedToJob: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      valid: true,
      comment: '',
      rules: {
        max950: value => {
          return Rules.maxValue(value, 950);
        }
      }
    }
  },

  methods: {
    validate() {
      return this.$refs.reasonCodeDropDown.validate();
    },
    loadDefaults() {
      this.$refs.reasonCodeDropDown.resetClosePOReasonCodeForm();
      this.comment = '';
    },
    optionSelected(val) {
      this.$emit('select', val);
    },
    getComment() {
      return this.comment;
    }
  }
};
</script>
