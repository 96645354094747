<template>
  <v-layout v-if="installer" row wrap>
    <!-- top section for status -->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs2>
          <v-text-field
            v-model="installer.referenceId"
            label="Reference Id"
            placeholder=" "
            readonly
            id="installer-details-reference-id"
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs5 />
        <v-flex xs2>
          <star-rating-field :value="installer.rating" :readonly="true" />
        </v-flex>
        <v-flex />
        <v-flex xs2>
          <v-text-field
            :value="installer.active ? 'Active' : 'Inactive'"
            label="Status"
            placeholder=" "
            readonly
            id="installer-details-status"
            persistent-placeholder
          />
        </v-flex>
      </v-layout>
      <v-divider class="detailsDivider" />
    </v-flex>
    <!-- column 1 -->
    <v-flex xs5>
      <v-layout row wrap>
        <v-flex xs6>
          <v-text-field
            readonly
            id="installer-details-company-name"
            label="Company Name"
            v-model="installer.companyName"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            readonly
            id="installer-details-contact-name"
            label="Primary Contact Name"
            v-model="installer.name"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            readonly
            id="installer-details-address"
            label="Address"
            v-model="installer.address"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            readonly
            id="installer-details-city"
            label="City"
            v-model="installer.city"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            readonly
            id="installer-details-state"
            label="State"
            v-model="installer.state"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            id="installer-details-zip"
            label="Zip Code"
            v-model="installer.zipCode"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            readonly
            id="installer-details-branches"
            label="Branches"
            v-model="installer.branchName"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-spacer></v-spacer>
    <!-- column 2 -->
    <v-flex xs5>
      <v-layout row wrap>
        <v-flex xs6>
          <v-text-field
            readonly
            id="installer-details-phone-number"
            label="Phone Number"
            v-model="installer.phoneNumber"
            v-facade="phoneNumberMask"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            readonly
            id="installer-details-fax-number"
            label="Fax Number"
            v-model="installer.fax"
            v-facade="phoneNumberMask"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            readonly
            id="installer-details-email"
            label="Email"
            v-model="installer.email"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            id="installer-details-retainage-amount"
            label="Retainage Amount"
            v-model="retainageAmount"
            placeholder="$0.00"
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            id="installer-details-retainage-precent"
            label="Retainage %"
            v-model="retainagePercentage"
            placeholder="0"
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            id="installer-details-retainage-balance"
            label="Retainage Balance"
            v-model="retainageBalance"
            placeholder="$0.00"
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            id="installer-details-last-paycheck-date"
            label="Last Paycheck Date"
            v-model="lastPaycheckDate"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            placeholder=" "
            label="WC Exp Date"
            v-model="wcExpDate"
            id="installer-details-wc-expire-date"
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            readonly
            placeholder=" "
            label="General Liability Exp Date"
            v-model="generalLiabilityExpDate"
            id="installer-details-general-liability-exp-date"
            persistent-placeholder
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import numeral from "numeral";
import moment from "moment";
import StarRatingField from "@/components/common/form/StarRatingField.vue";
import Installers from "@/rest/installers";
import Constants from "@/assets/constants";
import DateFormatter from "@/assets/dateFormatter";

export default {
  components: {
    StarRatingField,
  },

  props: { installer: Object },

  watch: {
    installer: {
      immediate: true,
      handler(newVal) {
        this.formatData(newVal);
      }
    }
  },

  data() {
    return {
      displayTaxId: false,
      retainageAmount: null,
      retainageBalance: null,
      retainagePercentage: null,
      lastPaycheckDate: null,
      wcExpDate: null,
      generalLiabilityExpDate: null,
      phoneNumberMask: Constants.phoneNumberMask
    };
  },

  methods: {
    formatData: async function(installer) {
      if(!installer.referenceId) {
        return;
      }
      if (installer) {
        if (installer.retainageAmount) {
          this.$data.retainageAmount = numeral(installer.retainageAmount).format("$0,0.00");
        }
        this.$data.retainageBalance = await this.getInstallerRetainageBalance(installer.referenceId);
        if (installer.retainagePercentage) {
          this.$data.retainagePercentage = installer.retainagePercentage + "%";
        }
        if (installer.lastPaycheckDate) {
          this.$data.lastPaycheckDate = DateFormatter.formatDateWithFormat(installer.lastPaycheckDate, "MM/DD/YYYY");
        }
        if (installer.wcExpDate) {
          this.$data.wcExpDate = DateFormatter.formatDateWithFormat(installer.wcExpDate, "MM/DD/YYYY");
        }
        if (installer.generalLiabilityExpDate) {
          this.$data.generalLiabilityExpDate = DateFormatter.formatDateWithFormat(installer.generalLiabilityExpDate, "MM/DD/YYYY");;
        }
      }
    },
    getInstallerRetainageBalance: async function(installerId) {
      try {
        const response = await Installers.getRestApi().getRetainageBalance(installerId);
        return numeral(Math.abs(response.data.endBalance)).format("$0,0.00");
      } catch (error) {
        return numeral(0).format("$0,0.00");
      }
    }
  }
};
</script>

<style scoped>
.divider {
  padding-bottom: 20px;
}
</style>
