import ODataContext from "devextreme/data/odata/context";
import AxiosInstance from "@/rest/axiosInstance";
import Constants from "@/assets/constants";

const odataContext = new ODataContext({
    url: (config.VUE_APP_ROOT_ODATA || process.env.VUE_APP_ROOT_ODATA) + "/",
    version: 4,
    beforeSend: (request) => {
        request.timeout = Constants.ODATA_REQUEST_TIMEOUT;
        request.headers = AxiosInstance.setDefaultHeaders();
    },
    entities: {
        "Branchs": {},
        "Customers": {},
        "Jobs": {},
        "Installers": {},
        "InventoryBins": {},
        "InventoryItems": {},
        "InventoryReservations": {},
        "Leads": {},
        "Markets": {},
        "ProductCatalogItems": {},
        "ProductCatalogSearchs": {},
        "ProductTypes": {},
        "PromoCodes": {},
        "PurchaseOrders": {},
        "SalesRepresentativeJobs": {},
        "SalesRepresentatives": {},
        "Tickets": {},
        "Users": {},
        "Vendors": {},
        "WebLeads": {},
        "WebJobs": {},
        "BranchProductCatalogSearchs": {},
        "WebTransferRequests": {},
        "GroupedInventoryItems": {},
        "WebShipments": {},
        "WebInventoryInTransfers": {},
        "InventoryItemsForBranchTransferCheckOuts": {},
        "InventoryItemsForBranchTransferCheckIns": {},
        "InstallerCrewSlotActivityLogs": {},
        "Installations": {},
        "WebSalesRepresentativeCommissions": {},
    },
    withCredentials: true
});

export default odataContext;
