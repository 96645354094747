<template>
  <div class="day-header-content-wrapper">
    <div class="day-header-info">
      <div class="date-wrapper">
        <div class="date">{{ formattedDate }}</div>
        <div class="day-of-week">{{ dayOfWeek }}</div>
      </div>
      <div class="capabilities-available">{{ this.slotsAmount }} <span>Slots available</span></div>
    </div>
    <div class="add-slot-button-wrapper">
      <v-btn
          class="add-slot-button"
          outlined
          color="#224E69"
          @click="emitAddSlotClicked"
          :disabled="isPastDate"
      >
        Add Slot
      </v-btn>
    </div>
    <div class="column-names">
      <div class="slots-column">Slots</div>
      <div class="capability-column">Capability</div>
    </div>
  </div>

</template>

<script>
import DateFormatter from "@/assets/dateFormatter";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {},

  props: {
    date: {
      type: Date,
      required: true
    },
    slotsSummary: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      currentWeek: null
    };
  },

  computed: {
    formattedDate() {
      return DateFormatter.formatDateInD(this.date);
    },
    dayOfWeek() {
      return DateFormatter.getDayOfWeek(this.date);
    },
    isPastDate() {
      return DateUtils.isPastDate(this.date);
    },
    slotsAmount() {
      const summary = this.slotsSummary
          .find(summary => DateUtils.isSameDate(summary.slotDate, DateFormatter.formatDateInYYYYMMDDFormat(this.date)));
      if (summary) {
        return `${summary.totalSlots - summary.scheduledSlots}/${summary.totalSlots}`
      } else {
        return '0/0';
      }
    }
  },

  methods: {
    emitAddSlotClicked() {
      this.$emit('addSlotClicked', this.date);
    }
  }
};
</script>

<style lang="scss" scoped>
.day-header-content-wrapper {
  display: flex;
  flex-direction: column;

  .day-header-info {
    padding: 12px 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    color: #666666;

    .date-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;

      .date {
        font-size: 16px;
      }

      .day-of-week {
        font-size: 13px;
        font-weight: bold;
      }
    }

    .capabilities-available {
      padding-left: 7px;
      border-left: 1px solid #D9D9D9;
      font-size: 14px;

      @media (max-width: 1100px) {
        span {
          display: none;
        }
      }
    }
  }

  .add-slot-button-wrapper {
    display: flex;
    justify-content: center;
    padding: 12px 0;
    border-bottom: 1px solid #D9D9D9;

    .add-slot-button {
      min-height: 30px;
      height: 30px;
    }
  }

  .column-names {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #212529;

    .slots-column {
      min-width: 38px;
      margin: 6px 10px;
    }

    .capability-column {
      padding-left: 7px;
      border-left: 1px solid #D9D9D9;
    }
  }
}
</style>
