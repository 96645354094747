<template>
  <inline-grid
      :data-source="activityLogs"
      :show-borders="false"
  >
    <DxPaging :page-size="initialPageSize"/>
    <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
    />
    <dx-column
        data-field="createdAt"
        width="20%"
        data-type="datetime"
        caption="Date"
        sortOrder="desc"
        format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
        data-field="changeDescription"
        width="50%"
        caption="Description"
    />
    <dx-column
        data-field="createdUser"
        width="30%"
        caption="Created User"
    />
  </inline-grid>
</template>

<script>
import InlineGrid from "../../../components/common/grid/InlineGrid.vue";
import {DxColumn, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    InlineGrid,
    DxColumn,
    DxPager,
    DxPaging
  },

  props: {
    activityLogs: {
      type: Array,
      required: true
    },
    customPagingSize: {
      type: Array,
      required: false,
    }
  },

  data() {
    return {
      logsArray: [],
      pageSizes: [10, 25, 50],
      initialPageSize: 10
    };
  },

  watch: {
    logs: {
      immediate: true,
      handler(newVal) {
        this.logsArray = newVal;
      }
    },
    customPagingSize: {
      immediate: true,
      handler(newVal) {
        this.pageSizes = newVal;
        this.initialPageSize = this.pageSizes[0];
      }
    }
  },

  created() {
    this.emitGetLogs();
  },

  methods: {
    emitGetLogs() {
      this.$emit('onGetLogs');
    }
  }
};
</script>
