<template>
  <div>
    <v-text-field
        v-if="readonly"
        :readonly="readonly"
        placeholder=" "
        persistent-placeholder
        :label="autocompleteLabel"
        :value="textValue"
        data-id="installation-capabilities-text-value"
    />
    <v-autocomplete
        v-if="!readonly"
        v-model="selected"
        :placeholder="autocompletePlaceholder"
        :search-input.sync="searchInput"
        @input="searchInput=null"
        data-id="capabilities-autocomplete"
        :item-text="itemText || 'name'"
        item-value="id"
        :items="availableCapabilities"
        :rules="[rules.requiredBasedOnPropsValue]"
        :required="required"
        :multiple="multiple"
        :clearable="multiple"
        :disabled="disabled"
        :label="label"
        light
        dense
        attach
        :validate-on="validateOnType"
        hide-no-data
        persistent-placeholder
    >
      <template v-slot:prepend-item class="select-all" v-if="selectAllEnabled">
        <select-all-autocomplete
            :items="availableCapabilities"
            :selected="selected"
            @select="selectAll"
            @deselect="deselectAll"
        >
        </select-all-autocomplete>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import SelectAllAutocomplete
  from "../../components/searchFields/selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";
import Rules from "../../assets/rules";
import FormattingHelper from "../../assets/formattingHelper";

export default {
  components: {
    SelectAllAutocomplete
  },

  data() {
    return {
      selected: [],
      rules: {
        requiredBasedOnPropsValue: (value) => Rules.multipleOptionsRequiredBasedOnPropsValue(value, this.required)
      },
      searchInput: null,
    };
  },

  props: {
    value: String | Array,
    readonly: Boolean,
    required: Boolean,
    label: String,
    itemText: String,
    multiple: Boolean,
    disabled: Boolean,
    placeholder: String,
    availableCapabilities: Array,
    selectAllEnabled: {
      type: Boolean,
      default: false
    },
    validateOnType: {
      type: String,
      required: false,
      default: 'input'
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      this.$emit("inputSelected", value);
    }
  },

  computed: {
    autocompleteLabel() {
      return this.$props.required ? "* Product Types" : "Product Types";
    },
    textValue() {
      return FormattingHelper.asCommaDelimitedString(this.selected);
    },
    autocompletePlaceholder() {
      return this.placeholder ? this.placeholder : "Select Product Types";
    }
  },

  methods: {
    clearSelectedCapability() {
      this.selected = null;
    },
    emitError(error) {
      this.$emit('emitError', error);
    },
    selectAll() {
      this.selected = this.availableCapabilities.map(item => item.id);
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>
