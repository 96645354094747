<template>
  <modal-dialog
      ref="createSubtypesDialog"
      width="500"
      cancelLabel="Cancel"
      :submitLabel="submitLabel || 'Add'"
      :title="title"
      :visible="dialogVisible"
      :submitEnabled="true"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <create-subtype-form
        ref="createSubtypeForm"
        :productType="productType"
        :rowData="rowData"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import CreateSubtypeForm from "@/views/productSubtypes/CreateSubtypeForm";
import ProductSubTypes from "@/rest/productSubTypes";

export default {
  props: {
    productType: {
      type: Object,
      required: true
    }
  },

  components: {
    CreateSubtypeForm,
    ModalDialog
  },

  data() {
    return {
      dialogVisible: false,
      rowData: null,
      title: null,
      submitLabel: null,
      subtypeIdToChange: null
    };
  },

  methods: {
    openSubtypesDialog(rowData) {
      if (rowData) {
        this.rowData = rowData.data
        this.subtypeIdToChange = rowData.data.id;
        this.title = 'Edit Product Subtype';
        this.submitLabel = 'Update';
        this.$refs.createSubtypeForm.passDataToChange(rowData.data);
      } else {
        this.title = 'Add Product Subtype';
        this.submitLabel = 'Add';
      }
      this.dialogVisible = true;
    },
    closeDialog() {
      this.$refs.createSubtypeForm.reset();
      this.dialogVisible = false
      this.title = null;
      this.submitLabel = null;
      this.subtypeIdToChange = null;
    },
    onSubmitClicked() {
      let payload = this.$refs.createSubtypeForm.buildSubtypeRequestPayload();
      if (payload && this.submitLabel === 'Add') {
        this.createProductSubtype(payload);
      } else if (payload && this.submitLabel === 'Update') {
        this.updateProductSubtype(payload);
      }
    },
    onCancelClicked() {
      this.closeDialog();
      this.$emit('cancelClicked');
    },
    async createProductSubtype(payload) {
      try {
        const response = await ProductSubTypes.getRestApi().createProductSubType(payload);
        if (response) {
          this.closeDialog();
          this.$emit("subtypeAdded");
        }
      } catch (error) {
        this.$emit('updateErrorOccurred', error)
      }
    },
    async updateProductSubtype(payload) {
      try {
        let id = this.subtypeIdToChange;
        const response = await ProductSubTypes.getRestApi().updateProductSubType(id, payload);
        if (response) {
          this.closeDialog();
          this.$emit("subtypeAdded");
        }
      } catch (error) {
        this.$emit('updateErrorOccurred', error)
      }
    }
  }
};
</script>
