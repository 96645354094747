import { render, staticRenderFns } from "./BcaContainer.vue?vue&type=template&id=06c7594d&scoped=true"
import script from "./BcaContainer.vue?vue&type=script&lang=js"
export * from "./BcaContainer.vue?vue&type=script&lang=js"
import style0 from "./BcaContainer.vue?vue&type=style&index=0&id=06c7594d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c7594d",
  null
  
)

export default component.exports