<template>
  <div class="week-switcher-wrapper">
    <div class="week-switcher-label">
      Week
    </div>
    <div class="week-switcher">
      <v-btn icon @click="handlePreviousWeek">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="selected-week">
        {{ currentWeek }}
      </div>
      <v-btn icon @click="handleNextWeek">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
          small
          outlined
          color="primary"
          @click="todayClicked"
      >
        Today
      </v-btn>
    </div>
  </div>
</template>

<script>
import DateFormatter from "@/assets/dateFormatter";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  data() {
    return {};
  },

  props: {
    currentDate: {
      type: Date,
      required: true
    }
  },

  computed: {
    currentWeek() {
      const startOfWeek = DateFormatter.formatDateInDMMM(DateUtils.getStartOfWeek(this.currentDate));
      const endOfWeek = DateFormatter.formatDateInDMMM(DateUtils.getEndOfWeek(this.currentDate));
      const year = DateFormatter.getYear(this.currentDate);
      return `${startOfWeek} – ${endOfWeek} / ${year}`;
    }
  },

  methods: {
    handlePreviousWeek() {
      this.$emit('previousWeekClicked');
    },
    handleNextWeek() {
      this.$emit('nextWeekClicked');
    },
    todayClicked() {
      this.$emit('todayClicked');
    },
  }
};
</script>

<style lang="scss" scoped>
.week-switcher-wrapper {
  display: flex;
  flex-direction: column;

  .week-switcher-label {
    color: #0e4e6c;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }

  .week-switcher {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .selected-week {
      width: 170px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
</style>