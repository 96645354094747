<template>
  <drawer-form
      ref="form"
      title="Create Sales Representative"
      submitLabel="Save Sales Representative"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container>
      <sales-representative-edit-form
          ref="salesRepEditForm"
          :initialPasswordEnabled="true"
          :isEditForm="false"
      >
        <template v-slot:mfa-control-tools>
          <user-mfa-control-tools
              :disabled="true"
              :mfaEnabled="true"
              :mfaSetUp="false"
          />
        </template>
      </sales-representative-edit-form>
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm.vue";
import SalesRepresentatives from "@/rest/salesRepresentatives";
import SalesRepresentativeEditForm from "@/components/salesRepresentatives/SalesRepresentativeEditForm.vue";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";

export default {
  components: {
    UserMfaControlTools,
    DrawerForm,
    SalesRepresentativeEditForm
  },

  data() {
    return {
      inProgress: false
    };
  },

  methods: {
    onCancel() {
      this.resetFormToDefaults();
      this.$emit('cancelClicked');
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.createSalesRep();
      }
    },
    async createSalesRep() {
      try {
        this.inProgress = true;
        const payload = this.$refs.salesRepEditForm.buildPayload();
        const response = await SalesRepresentatives.getRestApi().createSalesRepresentative(payload);
        this.resetFormToDefaults();
        this.$emit('onCreateSuccess', response.data);
      } catch (error) {
        this.$emit('onCreateFail', error);
      }
      this.inProgress = false;
    },
    resetFormToDefaults() {
      this.$refs.form.resetValidation();
      this.$refs.salesRepEditForm.resetFormToDefaults();
    }
  }
};
</script>
