import Constants from "../constants";

const EventNotificationHelper = {
    formatNotification(newNotification) {
        return {
            id: newNotification.id,
            eventName: newNotification.name || newNotification.eventName,
            message: newNotification.message,
            sender: newNotification.sender,
            severity: newNotification.eventSeverity || newNotification.severity,
            startTime: newNotification.startTime,
            endTime: newNotification.endTime,
            eventState: newNotification.eventState,
            read: false,
            icon: this.defineIconBasedOnSeverityLevel(newNotification.eventSeverity || newNotification.severity)
        };
    },
    defineIconBasedOnSeverityLevel(severity) {
        if (severity === Constants.eventSeverityTypes.INFO) {
            return 'mdi-information-outline';
        } else if (severity === Constants.eventSeverityTypes.CRITICAL) {
            return "mdi-alert-outline";
        } else if (severity === Constants.eventSeverityTypes.MAINTENANCE) {
            return "mdi-hammer-wrench";
        } else {
            return "mdi-alarm-light-outline";
        }
    },
    getAllNotifications(store) {
        return store.getters.getSavedNotifications;
    },
    getUnreadNotificationCounter(store) {
        return this.getAllNotifications(store).filter(n => !n.read).length;
    },
    saveMessageToStore(store, newUserNotification) {
        let saved = Array.from(this.getAllNotifications(store));
        saved.unshift(newUserNotification);
        store.commit('setSavedNotifications', saved);
    },
    getUnreadMessageCounterFromStore(store) {
        return store.getters.getUnreadMessageCounter;
    },
    saveCounterToStore(store, counter) {
        store.commit('setUnreadMessageCounter', counter);
    },
    updateMessageCounter(store, isIncrease) {
        let messageCounter = isIncrease
            ? this.getUnreadMessageCounterFromStore() + 1
            : this.getUnreadMessageCounterFromStore() - 1;
        if (messageCounter < 0) {
            messageCounter = 0;
        }
        this.saveCounterToStore(store, messageCounter);
    },
    getOngoingNotificationsFromStore(store) {
        return store.getters.getOngoingNotifications;
    },
    updateExistingFields(existing, parsed) {
        existing.name = parsed.name;
        existing.message = parsed.message;
        existing.eventState = parsed.eventState;
        existing.severity = parsed.eventSeverity;
        existing.sender = parsed.sender;
        existing.read = false;
        return existing;
    },
    markAsRead(notificationId, store) {
        const result = this.getAllNotifications(store)
            .map(n => {
                if (n.id === notificationId) {
                    n.read = true;
                }
                return n;
            });
        store.commit('setSavedNotifications', result);
        this.updateMessageCounter(store, false);
    }
}

export default EventNotificationHelper;
