import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * Bin.
 */
export class Bin {
    id: number = 0;
    active: string = "";
    state: string = "";
    binNumber: string = "";
    description: string = "";
    onHand: number = 0;
    available: number = 50;
    cycleCount: number = 25;
    lastCycleCount: string = "";
    branchId: number = 0;
    branchName: string = "";

    constructor() {
    }
}

/**
 * REST interface provided for bins.
 */
export interface BinsRestApi {
  createBin(item: Bin): AxiosPromise<Bin>;
  updateBin(id: number, updated: Bin): AxiosPromise<Bin>;
  getBinById(id: number): AxiosPromise<Bin>;
  getBinsByBranchReferenceId(referenceId: string): AxiosPromise<Bin[]>;
  getBinActivityLogs(id: number): AxiosPromise<ActivityLog[]>;
  getAllActiveBinsByBranch(branchReferenceId: string): AxiosPromise<any[]>;
}

/**
 * REST implementation provided for bins.
 */
class Implementation implements BinsRestApi {
    createBin(item: Bin): AxiosPromise<Bin> {
        return Axios.axiosInstance().post<Bin>("/inventory/bins", item);
    }
    updateBin(id: number, updated: Bin): AxiosPromise<Bin> {
        return Axios.axiosInstance().put<Bin>(`/inventory/bins/${id}`, updated);
    }
    getBinById(id: number): AxiosPromise<Bin> {
        return Axios.axiosInstance().get<Bin>(`/inventory/bins/${id}`);
    }
    getBinsByBranchReferenceId(referenceId: string): AxiosPromise<Bin[]> {
        return Axios.axiosInstance().get<Bin[]>(`/inventory/bins/branch/${referenceId}`);
    }
    getBinActivityLogs(id: number): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>(`/inventory/bins/${id}/logs`);
    }
    getAllActiveBinsByBranch(branchReferenceId: string): AxiosPromise<any[]> {
        return Axios.axiosInstance().get<any[]>(`/inventory/bins/active/${branchReferenceId}/all`);
    }
}

const Bins = {
    getRestApi(): BinsRestApi {
        return new Implementation();
    }
};

export default Bins;
