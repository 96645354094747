<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col md="12">
          <v-card flat>
            <p class="subheading mb-4">
              Refund amounts based on a remaining balance of ${{ remainingBalance }}.
            </p>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-text-field
              :value="refundAmountFormatted"
              label="Refund Amount"
              placeholder="0.00"
              prefix="$"
              :disabled="remainingBalance < 0"
              :rules="[rules.isPositiveOrZeroAmount]"
              validate-on-blur
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              :value="balanceTakenAmount"
              label="Balance Taken Amount"
              placeholder="0.00"
              prefix="$"
              :disabled="true"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "../../../assets/rules";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";

export default {
  components: {},

  props: {
    remainingBalance: Number,
  },

  data() {
    return {
      refundAmount: 0,
      balanceTakenAmount: 0,
      rules: {
        isPositiveOrZeroAmount: value => Rules.isPositiveOrZero(Number(value.replace(/[^0-9\.-]+/g, "")))
      },
    };
  },

  watch: {
    refundAmount: {
      immediate: true,
      handler() {
        this.updateFields();
      },
    },
  },

  computed: {
    refundAmountFormatted() {
      return CurrencyFormatter.formatCurrency(Math.abs(this.remainingBalance));
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    loadRefundForm() {
      let balance = this.remainingBalance;
      this.refundAmount = balance >= 0
          ? CurrencyFormatter.zeroCurrency()
          : CurrencyFormatter.formatCurrency(-1 * balance);
      this.balanceTakenAmount = balance >= 0
          ? CurrencyFormatter.zeroCurrency()
          : CurrencyFormatter.formatCurrency(balance);
      this.updateFields();
    },
    updateFields() {
      let balance = this.remainingBalance;
      this.balanceTakenAmount = (balance <= 0 || balance < this.refundAmount)
          ? CurrencyFormatter.formatCurrency(balance)
          : CurrencyFormatter.formatCurrency(this.refundAmount);
    },
    getPayload() {
      const payload = {};
      payload.refundAmount = CurrencyFormatter.formatCurrency(this.refundAmountFormatted);
      payload.balanceTakenAmount = CurrencyFormatter.formatCurrency(this.balanceTakenAmount);
      return payload;
    }
  },
};
</script>
