import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Reassign Sales Reason code
 */
export class ReassignSalesReasonCode {
    id: number = 0;
    name: string = "";
    active: boolean;

    constructor(id: number, name: string, active: boolean) {
        this.id = id;
        this.name = name;
        this.active = active;
    }
}

export interface ReassignSalesReasonCodeRestApi {
    getReassignSalesReasonCode(): AxiosPromise<ReassignSalesReasonCode[]>;
}

class Implementation implements ReassignSalesReasonCodeRestApi {
    getReassignSalesReasonCode(): AxiosPromise<ReassignSalesReasonCode[]> {
        return Axios.axiosInstance().get<ReassignSalesReasonCode[]>("reason-codes/reassign-sales");
    }
}

const ReassignSalesReasonCodes = {
    getRestApi(): ReassignSalesReasonCodeRestApi {
        return new Implementation();
    }
};

export default ReassignSalesReasonCodes;
