<template>
  <div>
    <v-text-field
        :value="value"
        :label="label"
        :placeholder="placeholder"
        persistent-placeholder
        :readonly="readonly"
        append-icon="mdi-content-copy"
        @click:append="copyToClipboard"
    />
    <v-snackbar v-model="snackbar" :timeout="3000">
      <v-row justify="center">
        <v-col class="text-center">
          Copied To Clipboard
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: " "
    }
  },

  data() {
    return {
      snackbar: false
    };
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.value).then(() => {
        this.snackbar = true;
        this.$emit('copied', this.value);
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }
};
</script>

<style scoped>
</style>
