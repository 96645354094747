<template>
  <modal-dialog
      ref="dialog"
      title="Products Not Fully Available in Job's Branch"
      width="1200"
      submitLabel="Continue"
      submit-enabled
      cancelLabel="Close"
      :visible="dialogVisible"
      :loading="loading"
      @submitClicked="onContinueClicked"
      @cancelClicked="closeDialog"
  >
    <v-card>
        <transfer-options-grid
            ref="transferOptionsGrid"
            :transfer-options="transferOptions" />
      <v-card-text>
        <p>
          For products available in other branches, transfer requests must be handled manually.
          For products unavailable in any branch, the system will automatically create a purchase order.
        </p>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import TransferOptionsGrid from "@/components/jobs/planFulfillment/TransferOptionsGrid.vue";

export default {
  components: {
    TransferOptionsGrid,
    ModalDialog
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      transferOptions: [],
    };
  },
  methods: {
    openDialog(options) {
      this.transferOptions = options.map((item) => this.convertToGridTransferOptions(item));
      this.dialogVisible = true;
    },
    convertToGridTransferOptions: function (item) {
      return {
        ...item,
        isTransferAction: false,
        isCreatePOAction: item.branchesNames.length === 0
      };
    },
    closeDialog() {
      this.dialogVisible = false;
      this.resetValidation();
    },
    onContinueClicked() {
      if (this.isValid()) {
        const selectedForTransfer = this.transferOptions
            .filter((item) => item.isTransferAction);
        this.$emit("transferOptionsSelected", selectedForTransfer);
        this.closeDialog();
      }
    },
    isValid() {
      return this.$refs.transferOptionsGrid.isValid();
    },
    resetValidation() {
      this.$refs.transferOptionsGrid.resetValidation();
    }
  },
};
</script>