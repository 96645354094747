import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Summary information for a Vendor Management (catalog item's vendor + vendor cost associations)
 */
export class VendorManagement {
    id?: number;
    catalogItemId: number;
    vendorId: number;
    vendorCost: number;

    constructor(
        id: number,
        catalogItemId: number,
        vendorId: number,
        vendorCost: number
    ) {
        this.id = id;
        this.catalogItemId = catalogItemId;
        this.vendorId = vendorId;
        this.vendorCost = vendorCost;
    }
}

/**
 * REST interface.
 */
export interface VendorManagementRestApi {
    getVendorManagementDataForCatalogItemId(itemId: number): AxiosPromise<VendorManagement>;
    saveVendorManagementData(itemId: number, data: object): AxiosPromise<VendorManagement>;
    deleteVendorManagementData(itemId: number, vendorManagementId: number): AxiosPromise<VendorManagement>;
    addVendorCostForSpecificVendor(itemId: number, payload: VendorManagement): AxiosPromise<VendorManagement>;
    updateVendorManagementSku(itemId: number, vendorId: number, updatedSku: string): AxiosPromise<VendorManagement>;
}

/**
 * REST implementation.
 */
class Implementation implements VendorManagementRestApi {
    getVendorManagementDataForCatalogItemId(itemId: number): AxiosPromise<VendorManagement> {
        return Axios.axiosInstance().get<VendorManagement>(`/vendor-management/catalog-item/${itemId}`);
    }
    saveVendorManagementData(itemId: number, data: VendorManagement): AxiosPromise<VendorManagement> {
        return Axios.axiosInstance().post<VendorManagement>(`/vendor-management/catalog-item/${itemId}`, data);
    }
    deleteVendorManagementData(itemId: number, vendorManagementId: number): AxiosPromise<VendorManagement> {
        return Axios.axiosInstance().delete<VendorManagement>(`/vendor-management/catalog-item/${itemId}/${vendorManagementId}`);
    }
    addVendorCostForSpecificVendor(itemId: number, payload: VendorManagement): AxiosPromise<VendorManagement> {
        return Axios.axiosInstance().post<VendorManagement>(`/vendor-management/add-vendor-cost/catalog-item/${itemId}`, payload);
    }
    updateVendorManagementSku(itemId: number, vendorId: number, updatedSku: string): AxiosPromise<VendorManagement> {
        return Axios.axiosInstance().put<VendorManagement>(`/vendor-management/catalog-item/${itemId}/update-sku/vendor/${vendorId}`, updatedSku);
    }
}

const VendorManagements = {
    getRestApi(): VendorManagementRestApi {
        return new Implementation();
    }
};

export default VendorManagements;
