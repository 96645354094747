<template>
  <v-container>
    <v-form ref="form" class="mt-4">
      <v-row>
        <v-col md="9">
          <v-text-field
              required
              validate-on-blur
              :rules="[rules.zipCode]"
              v-model="zipCode"
              label="Zip Code"
              v-facade="'#####'"
              placeholder="Search Zip Codes"
              persistent-placeholder
              autofocus
              clearable
          />
        </v-col>
        <v-col md="3">
          <v-btn
              color="primary"
              :disabled="saveButtonDisabled"
              @click="onSaveClick"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="zip-codes">
        <v-col md="12">
          <v-data-table
              :items-per-page="5"
              :headers="headers"
              :items="zipCodes"
              class="elevation-1"
              :search="zipCode"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.name">
                <td class="zip-code">{{ item.zipCode }}</td>
                <td class="buttons" v-if="item.zipCode !== selectedZipCode">
                  <v-btn
                      text
                      class="cell-button"
                      depressed
                      title="Remove Zip Code"
                      @click="() => onDeleteClick(item)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
                <td class="buttons" v-if="item.zipCode === selectedZipCode">
                  <v-btn
                      :disabled="confirmButtonsDisabled"
                      text
                      color="success"
                      class="cell-button"
                      title="Confirm"
                      @click="confirm"
                      @mouseenter="ifSaveChange = true"
                      @mouseleave="ifSaveChange = false"
                  >
                    <v-icon>check_circle_outline</v-icon>
                  </v-btn>
                  <v-btn
                      :disabled="confirmButtonsDisabled"
                      text
                      color="error"
                      class="cell-button"
                      title="Cancel"
                      @click="cancel"
                      @mouseenter="ifCancelChange = true"
                      @mouseleave="ifCancelChange = false"
                  >
                    <v-icon>clear</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              There are not any zip codes associated to the market. Add a zip code above.
            </template>
            <template v-slot:no-results>
              Zip code not associated to market.
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Rules from "../../assets/rules";
import ZipCodes from "../../rest/zipCodes";

export default {
  components: {},
  props: {
    zipCodes: {
      default() {
        return [];
      }
    },
    marketReferenceId: {
      required: true
    }
  },

  watch: {
    zipCode(newVal) {
      this.checkZipCode(newVal);
    },
    zipCodes() {
      this.checkZipCode();
    }
  },

  data() {
    return {
      zipCode: "",
      saveButtonDisabled: true,
      selectedZipCode: null,
      confirmButtonsDisabled: false,
      ifSaveChange: false,
      ifCancelChange: false,
      headers: [
        {
          text: "Zip Codes",
          sortable: true,
          value: "zipCode",
          "must-sort": true
        },
        {
          text: " ",
          align: "center",
          sortable: false
        }
      ],
      rules: {
        zipCode: Rules.zipCode
      }
    };
  },

  methods: {
    async onSaveClick() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.associateZipCodes();
      }
    },
    refreshZipCodes() {
      this.$emit('refreshZipCodes');
    },
    showSuccess() {
      const message = "Successfully updated Market zip code!";
      this.$emit('showSuccess', message);
    },
    async associateZipCodes() {
      try {
        await ZipCodes.getRestApi().associateZipCodes(this.marketReferenceId, [this.zipCode]);
        this.showSuccess();
        this.refreshZipCodes();
      } catch (error) {
        this.showError(error);
      }
    },
    async dissociateZipCodes(zipCode) {
      try {
        await ZipCodes.getRestApi().dissociateZipCodes(this.marketReferenceId, [zipCode]);
        this.showSuccess();
        this.refreshZipCodes();
      } catch (error) {
        this.showError(error);
      }
    },
    checkZipCode() {
      this.cancel();
      const valid = this.$refs.form.validate();
      if (valid && this.zipCode) {
        const present = this.findZipCode(this.zipCode);
        this.saveButtonDisabled = present === undefined ? false : true;
      } else {
        this.saveButtonDisabled = true;
      }
    },
    findZipCode() {
      if (this.zipCode) {
        let index;
        this.zipCodes.map((zipCode, i) => {
          if (zipCode.zipCode === this.zipCode) index = i;
        });
        return index;
      }
    },
    showError(error) {
      this.$emit('showError', error);
    },
    onDeleteClick(item) {
      this.selectedZipCode = item.zipCode;
    },
    confirm() {
      this.confirmButtonsDisabled = true;
      this.dissociateZipCodes(this.selectedZipCode);
    },
    cancel() {
      this.selectedZipCode = null;
      this.confirmButtonsDisabled = false;
    },
    reset() {
      this.cancel();
      this.zipCode = "";
      this.saveButtonDisabled = true;
    }
  }
};
</script>

<style scoped>
.buttons {
  width: 200px;
}
.zip-codes {
  margin-top: 10px;
}
.zip-code {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell-button {
  min-width: 40px;
  padding: 0;
  margin: 0;
}
</style>
