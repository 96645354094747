export const TransferRequestStatuses = {
    NEW: {
        name: "NEW",
        fullName: 'New',
        icon: 'mdi-clipboard-plus-outline',
        color: '#6699BB'
    },
    DELIVERY_SCHEDULED: {
        name: "DELIVERY_SCHEDULED",
        fullName: 'Delivery Scheduled',
        icon: 'mdi-calendar-clock',
        color: '#FCB641'
    },
    CHECKED_OUT: {
        name: "CHECKED_OUT",
        fullName: 'Checked Out',
        icon: 'mdi-check-decagram-outline',
        color: '#4887F6'
    },
    IN_TRANSIT: {
        name: "IN_TRANSIT",
        fullName: 'In Transit',
        icon: 'mdi-transfer-right',
        color: '#224E69'
    },
    PARTIALLY_DELIVERED: {
        name: "PARTIALLY_DELIVERED",
        fullName: 'Partially Delivered',
        icon: 'mdi-transfer',
        color: '#009688'
    },
    CLOSED: {
        name: "CLOSED",
        fullName: 'Closed',
        icon: 'mdi-package-variant-closed-check',
        color: '#539049'
    }
};

export const TransferRequestItemStatuses = {
    OPEN: {
        name: "OPEN",
        fullName: 'Open',
        icon: 'mdi-lock-open-variant-outline',
        color: '#6167F0'
    },
    CLOSED: {
        name: "CLOSED",
        fullName: 'Closed',
        icon: 'mdi-lock-check-outline',
        color: '#539049'
    },
}

export const ShipmentStatuses = {
    SCHEDULED: {
        name: "SCHEDULED",
        fullName: 'Scheduled',
        icon: 'mdi-book-clock-outline',
        color: '#FCB641'
    },
    PICKUP: {
        name: "PICKUP",
        fullName: 'Pickup',
        icon: 'mdi-forklift',
        color: '#4887F6'
    },
    IN_PROGRESS: {
        name: "IN_PROGRESS",
        fullName: 'In Progress',
        icon: 'mdi-cog-play-outline',
        color: '#6167F0'
    },
    COMPLETED: {
        name: "COMPLETED",
        fullName: 'Completed',
        icon: 'mdi-package-variant-closed-check',
        color: '#539049'
    },
    CANCELLED: {
        name: "CANCELLED",
        fullName: 'Cancelled',
        icon: 'mdi-package-variant-closed-remove',
        color: '#b03c52'
    },
};

export const InventoryItemCheckedStatus = {
    NOT_CHECKED_OUT: {
        name: "NOT_CHECKED_OUT",
        icon: null,
        color: 'primary'
    },
    PARTIALLY_CHECKED_IN: {
        name: "PARTIALLY_CHECKED_IN",
        icon: 'mdi-transfer',
        color: '#4887F6'
    },
    IS_FULLY_CHECKED_IN: {
        name: "FULLY_CHECKED_IN",
        icon: 'check_circle_outline',
        color: '#539049'
    },
    CLOSED_AS_SHORT_SHIPPED: {
        name: "CLOSED_AS_SHORT_SHIPPED",
        icon: 'check_circle_outline',
        color: '#539049'
    },
    OVERRECEIVED: {
        name: "OVERRECEIVED",
        icon: 'mdi-stack-overflow',
        color: '#6167F0'
    },
    IS_CLOSED: {
        name: "CLOSED",
        icon: 'remove_circle_outline',
        color: 'error'
    },
    CHECKED_OUT: {
        name: "CHECKED_OUT",
        fullName: 'Checked Out',
        icon: null
    },
}

export const BranchDetails = {
    AUTOMATIC: {
        name: "Automatic",
        fullName: 'Automatic lead assignment : ON',
        icon: 'mdi-cog-play-outline',
        color: '#539049'
    },

    MANUAL: {
        name: "Manual",
        fullName: 'Automatic lead assignment : OFF',
        icon: 'mdi-cog-pause-outline',
        color: '#b03c52'
    },
};

export const MfaIcons = {
    MFA_ENABLED: {
        propertyName: "mfaEnabled",
        icon: 'mdi-cellphone-check',
        color: '#539049'
    },
    MFA_DISABLED: {
        propertyName: "mfaDisabled",
        icon: 'mdi-cellphone-remove',
        color: '#b03c52'
    },
    MFA_SET_UP: {
        propertyName: "mfaSetUp",
        icon: 'mdi-qrcode',
        color: '#539049'
    },
    MFA_NOT_SET_UP: {
        propertyName: "mfaSetUp",
        icon: 'mdi-qrcode',
        color: '#b03c52'
    }
};
