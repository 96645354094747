<template>
  <v-data-table
    :headers="headers"
    :items="selectedItems"
    item-key="index"
    light
    hide-default-footer
    disable-pagination
  >
    <template v-slot:item="{ item, index }">
      <tr :key="item.name">
      <td class="text-lg-left pa-4 productDescription">
        {{ item.productType || item.productCatalogItem.productType.name }}
      </td>
      <td class="text-lg-left pa-4 productDescription">
        {{ item.styleName || item.productCatalogItem.styleName }}
      </td>
      <td class="text-lg-left pa-4 productDescription">
        {{ item.color || item.productCatalogItem.color }}
      </td>
      <td class="text-xs-center" v-if="showOrderedQuantity">{{ item.quantityOrdered }}</td>
      <td class="text-xs-center">
        <div class="text-input-container">
          <v-text-field
            slot="input"
            v-model="item.rollNumber"
            placeholder=" "
            :disabled="isRollNumberDisabled(item.productTypeId)"
          />
        </div>
      </td>
      <td class="text-xs-center">
        <div class="text-input-container">
          <v-text-field
            slot="input"
            v-model="item.dyeLot"
            placeholder=" "
            :disabled="isDyeLotDisabled(item.productTypeId)"
          />
        </div>
      </td>
      <td class="text-xs-left">
        <div class="binInputContainer">
          <v-autocomplete
            class="binInput"
            v-model="item.binId"
            item-text="binNumber"
            item-value="id"
            placeholder=" "
            :items="bins"
          />
        </div>
      </td>
      <td class="text-xs-center pa-1">
        <div class="text-input-container">
          <v-text-field
            slot="input"
            v-model="item.quantity"
            type="number"
            placeholder="0"
            autofocus
            @input="boxQuantityEntered($event, item, index)"
          />
        </div>
      </td>
        <td class="text-lg-center pa-1">
          <div class="text-input-container">
            <v-text-field
                :value="item.roundedBoxQuantity"
                type="number"
                placeholder="0"
                autofocus
                disabled
            />
          </div>
        </td>
      <td class="text-xs-center buttonContainer">
        <v-btn
          class="button"
          text
          icon
          small
          @click="onIncreaseQuantityClicked(item, index)"
        >
          <v-icon color="primary">add_circle_outline</v-icon>
        </v-btn>
        <v-btn
          class="button"
          text
          icon
          small
          @click="onReduceQuantityClicked(item, index)"
        >
          <v-icon color="primary">remove_circle_outline</v-icon>
        </v-btn>
        <v-btn class="button" text icon small @click="onDeleteClicked(index)">
          <v-icon color="primary">delete</v-icon>
        </v-btn>
      </td>
      </tr>
    </template>
    <template v-slot:no-data>
      Add items from above to manage inventory
    </template>
  </v-data-table>
</template>

<script>
import BoxQuantity from "../../assets/boxQuantity";


export default {
  props: {
    selectedItems: Array,
    bins: Array,
    showOrderedQuantity: Boolean,
    productTypes: Array
  },

  data() {
    const headers = this.getHeaders();
    return {
      headers
    };
  },

  methods: {
    getHeaders: function() {
      const headers = [
        { text: "Product Type", value: "productType", sortable: false },
        { text: "Style", value: "style", sortable: false },
        { text: "Color", value: "productColor", sortable: false },
        { text: "Roll Number", value: "rollNumber", sortable: false },
        { text: "Dye Lot", value: "dyeLot", sortable: false },
        { text: "Bin", value: "binId", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        {text: "Qty Rounded to box Qty ", value: "roundedBoxQty", sortable: false, align: "center"},
        { text: "Edit", value: "edit", sortable: false, align: "center" }
      ];
      if (this.$props.showOrderedQuantity) {
        const orderColumn = {
          text: "Ordered",
          value: "quantityOrdered",
          sortable: false
        };
        headers.splice(3, 0, orderColumn);
      }
      return headers;
    },
    onDeleteClicked: function(item, index) {
      this.$emit("deleteClicked", item, index);
    },
    onReduceQuantityClicked: function(item, index) {
      this.$emit("reduceQuantityClicked", item, index);
    },
    onIncreaseQuantityClicked(item, index) {
      this.$emit("increaseQuantityClicked", item, index);
    },
    isDyeLotDisabled: function(productTypeId) {
      let productType = this.$props.productTypes.find(pt => pt.id === productTypeId);
      return !productType.hasDyeLot;
    },
    isRollNumberDisabled: function(productTypeId) {
      let productType = this.$props.productTypes.find(pt => pt.id === productTypeId);
      return !productType.isRolled;
    },
    boxQuantityEntered(value, item, index) {
      let boxQuantity = item.BoxQuantity || item.quantity;
      let roundedBoxQuantity = BoxQuantity.roundValueToBoxQuantity(value, boxQuantity);
      if (roundedBoxQuantity){
        let updatedItem = {...item, roundedBoxQuantity};
        this.$emit('selectedItemChange', updatedItem, index);
      }
    }
  }
};
</script>

<style>
.icon-container {
  display: flex;
}
.editableField {
  border-bottom: 1px solid #69b;
  min-width: 60px;
  text-align: left;
  height: 20px;
}
.binInputContainer {
  margin-top: 6px;
}
.binInput {
  width: 120px;
  margin-top: 10px;
}
.binInput > .v-input__control > .v-input__slot {
  margin-bottom: 0;
  font-size: 13px;
}
.button {
  margin-left: 2px;
  margin-right: 2px;
}
.buttonContainer {
  min-width: 150px;
}
.productDescription {
  max-width: 175px;
  min-width: 125px;
}
.text-input-container {
  margin-top: 15px;
}
.text-input-container > .v-input__control > .v-input__slot {
  margin-bottom: 0;
  font-size: 13px;
}
</style>
