<template>
  <div>
    <inline-grid
        :data-source="orders"
        :show-borders="false"
        :hoverStateEnabled="true"
        @rowSelected="onRowSelected"
        @checkIn="onCheckIn"
    >
      <dx-column
          width="25%"
          data-field="dateIssued"
          caption="Date Issued"
          format="MM/dd/yyyy"
          data-type="datetime"
      />
      <dx-column
          width="25%"
          data-field="purchaseOrderNumber"
          caption="Purchase Order Number"
      />
      <dx-column
          width="20%"
          data-field="vendorName"
          caption="Vendor"
      />
      <dx-column
          width="20%"
          data-field="vendorReferenceNumber"
          caption="Reference Id"
      />
      <dx-column
          width="10%"
          data-field="purchaseOrderNumber"
          caption="Check In Status"
          cellTemplate="poCheckIn"
      />
    </inline-grid>
  </div>
</template>

<script>
import Jobs from "../../../rest/jobs";
import InlineGrid from "../../../components/common/grid/InlineGrid.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    InlineGrid,
    DxColumn
  },

  props: {
    jobId: Number
  },

  data() {
    return {
      orders: null
    };
  },

  watch: {
    jobId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      let component = this;
      Jobs.getRestApi()
          .getJobPurchaseOrders(this.jobId)
          .then((response) => {
            component.orders = response.data;
          })
          .catch(() => {
            component.snackbar = true;
          });
    },
    onRowSelected(data) {
      this.$router.push(`/orders/purchases/details/${data.purchaseOrderNumber}`);
    },
    onCheckIn(data) {
      this.$router.push(`/inventory/management/checkin/purchase_order?referenceId=${data.purchaseOrderNumber}`
      );
    }
  }
};
</script>
