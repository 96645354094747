<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td width="15%" class="text-truncate">
          <strong>{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <td width="75%">
          <v-text-field
            :disabled="true"
            v-model="comment"
            class="short-input left-aligned"
            placeholder=" "
          />
        </td>
        <td width="10%" class="pt-0">
          <v-text-field
            :disabled="true"
            v-model="price"
            class="short-input"
            prefix="$"
          />
        </td>
        <td width="30px;"></td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

export default {
  components: { },

  props: {
    job: {
      type: Object,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    }
  },

  data() {
    return { referenceId: null, comment: null, price: null };
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.referenceId = updated.jobLineItem.referenceId;
        this.comment = updated.jobLineItem.comment;
        this.price = CurrencyFormatter.formatCurrency(updated.jobLineItem.price);
      }
    }
  },

  methods: {}
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}
.item-row td.top-aligned {
  vertical-align: top;
}
</style>
