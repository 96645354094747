<template>
  <div ref="printable" id="printable" class="printable">
    <div class="printable-container">
      <div class="printable-header" id="print-title">
        <div class="printable-header-title">50 Floor - Appointment</div>
        <div v-if="date" class="printable-header-date">{{ date }}</div>
      </div>
      <div class="printable-grid">
        <div class="printable-field" id="print-appointment-status">
          <div class="printable-label">Lead Status:</div>
          <div class="printable-value">{{ leadStatus }}</div>
        </div>
        <div class="printable-field" id="print-appointment-name">
          <div class="printable-label">Customer Name:</div>
          <div class="printable-value">{{ fullName }}</div>
        </div>
        <div class="printable-field" id="print-appointment-date">
          <div class="printable-label">Date:</div>
          <div class="printable-value">{{ appointmentDate }}</div>
        </div>
        <div class="printable-field" id="print-appointment-time">
          <div class="printable-label">Time:</div>
          <div class="printable-value">{{ appointmentTime }}</div>
        </div>
        <div class="printable-field" id="print-appointment-address">
          <div class="printable-label">Address:</div>
          <div class="printable-value">{{ address }}, {{ city }}, {{ state }}, {{ zipCode }}</div>
        </div>
        <div class="printable-field" id="print-promo-code">
          <div class="printable-label">Promo Code:</div>
          <div class="printable-value">{{ promoCode }}</div>
        </div>
      </div>
      <div class="printable-label-notes">PRODUCT TYPES</div>
      <div class="printable-header"/>
      <table style="border-spacing: 0 3mm; width: 100%">
        <tr>
          <th>{{ productTypes }}</th>
        </tr>
      </table>

      <div class="printable-label-notes">ROOMS</div>
      <div class="printable-header"/>
      <table style="border-spacing: 0 3mm; width: 100%">
        <tr>
          <th>{{ rooms }}</th>
        </tr>
      </table>

      <div class="printable-label-notes">NOTES</div>
      <div class="printable-header"/>
      <v-simple-table style="border-spacing: 0 3mm; width: 100%">
        <tr id="notes-header">
          <th style="width: 22mm">Date</th>
          <th style="width: 17mm">User</th>
          <th>Note</th>
        </tr>
        <tr v-for="note in notes" v-bind:key="note.id">
          <th>{{ formatDate(note.createdAt) }}</th>
          <th>{{ note.userName }}</th>
          <th>{{ note.note }}</th>
        </tr>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import DateFormatter from "../../../assets/dateFormatter";
import Format from "../../common/Format";

export default {
  data() {
    return {
      qaDebug: false,
      date: null,
      fullName: null,
      leadStatus: null,
      appointmentDate: null,
      appointmentTime: null,
      address: null,
      city: null,
      state: null,
      zipCode: null,
      notes: [],
      rooms: null,
      productTypes: null,
      promoCode: null
    };
  },

  mounted() {
    this.initView();
  },

  methods: {
    initView() {
      let printData = this.$store.getters.getPrintData;
      if (printData) {
        this.date = this.formatDate(new Date());
        this.fullName = printData.customer.firstName || printData.customer.lastName
            ? printData.customer.firstName + " " + printData.customer.lastName
            : "";
        this.leadStatus = printData.lead.leadStatusName
            ? printData.lead.leadStatusName
            : "";
        this.appointmentDate = printData.lead.appointmentDate
            ? this.formatDate(printData.lead.appointmentDate)
            : "";
        this.appointmentTime = printData.lead.appointmentTime
            ? printData.lead.appointmentTime
            : "";
        this.address = printData.customer.address ? printData.customer.address : "";
        this.city = printData.customer.city ? printData.customer.city : "";
        this.state = printData.customer.state ? printData.customer.state : "";
        this.zipCode = printData.customer.zipCode ? printData.customer.zipCode : "";
        this.notes = this.sortDescendingNotes(printData.notes)
            ? printData.notes
            : "";
        this.productTypes = printData.productTypes;
        this.rooms = printData.rooms;
        this.promoCode = printData.lead.promoCodeName;
        this.qaDebug = "qaDebug" in this.$route.query ? true : false;
        this.onAfterPrint();
        this.print();
      } else {
        this.$router.push("/sales/leads");
      }
    },
    formatDate(date) {
      return DateFormatter.formatDateInUtcAndMMDDYYYYFormat(date);
    },
    print() {
      if (!this.qaDebug) {
        window.print();
      }
    },
    onAfterPrint() {
      window.onafterprint = this.close;
    },
    close() {
      if (!this.qaDebug) {
        setTimeout(window.close, 500);
      }
    },
    sortDescendingNotes(data) {
      return data.sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
      );
    }
  }
};
</script>
<style>
th {
  padding-right: 15px;
  font-size: 12px;
  text-align: justify;
  font-weight: normal;
  vertical-align: top;
  page-break-inside: avoid;
}
#notes-header th {
  font-size: 12px;
  font-weight: bold;
  vertical-align: top;
}
.printable-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  width: 100%;
}
.printable-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1mm;
  padding-bottom: 1mm;
  border-bottom: 1px solid black;
}
.printable-label-notes {
  font-weight: bold;
  font-size: 14px;
  margin-top: 10mm;
}
.printable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10mm 10mm 10mm 10mm;
}
</style>
