<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Ticket Notes
          </v-expansion-panel-header>
          <v-expansion-panel-content key="notes">
            <notes-grid
                ref="notesGrid"
                :notes="notes"
                @saveNote="onSaveNote"
                @getNotes="onGetNotes"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Activity Logs
          </v-expansion-panel-header>
          <v-expansion-panel-content key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import Tickets from "@/rest/tickets.ts";
import ActivityLogsGrid from "@/components/common/inlineGrids/ActivityLogsGrid.vue";
import NotesGrid from "@/components/common/inlineGrids/NotesGrid.vue";
import Constants from "@/assets/constants";

export default {
  components: {
    ActivityLogsGrid,
    NotesGrid
  },

  props: {
    referenceId: {
      type: String,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      activityLogs: [],
      notes: [],
      expanded: Constants.expandedByDefault
    };
  },

  watch: {
    referenceId: {
      immediate: true,
      handler() {
        this.refreshActivityLog();
      }
    }
  },

  methods: {
    emitError(error) {
      this.$emit("showError", error);
    },
    async refreshActivityLog() {
      try {
        this.activityLogs = await this.getActivityLogs();
      } catch (error) {
        this.emitError(error);
      }
    },
    async getActivityLogs() {
      try {
        const response = await Tickets.getRestApi().getTicketActivityLogs(this.referenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async onSaveNote(data) {
      try {
        const params = {...data};
        params.ticketId = this.$props.ticketId;
        params.userId = params.creatorId;
        params.ticketReferenceId = this.referenceId;
        await this.saveNote(params);
        await this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async saveNote(params) {
      try {
        await Tickets.getRestApi().createTicketNote(params);
      } catch (error) {
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    async onGetNotes() {
      try {
        const data = await this.getNotesData();
        this.setNotes(data);
      } catch (error) {
        this.emitError(error);
      }
    },
    async getNotesData() {
      try {
        const response = await Tickets.getRestApi().getTicketNotes(this.referenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    setNotes(notes) {
      this.notes = notes;
    }
  }
};
</script>
