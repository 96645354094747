<template>
  <v-card flat>
    <headers />
    <item
      :purchase-order-entry="poEntry"
      v-for="poEntry in purchaseOrderPlan.purchaseOrderEntries"
      :key="poEntry.lineItem.referenceId"
    />
  </v-card>
</template>

<script>
import Headers from "@/components/jobs/planFulfillment/PurchaseOrderHeaders.vue";
import Item from "@/components/jobs/planFulfillment/PurchaseOrderItem.vue";

export default {
  components: { Headers, Item },

  props: {
    purchaseOrderPlan: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  }
};
</script>
