<template>
  <v-autocomplete
      dense
      data-id="job-misc-item-type"
      v-model="selected"
      :items="productSubTypes"
      :disabled="disabled"
      item-value="id"
      item-text="name"
      label="Product SubTypes"
      no-data-text="No Subtypes"
      placeholder="Search subtype"
      persistent-placeholder
      clearable
      attach
  />
</template>

<script>
import ProductSubTypes from "@/rest/productSubTypes";

export default {
  components: {},

  data() {
    return {selected: null, productSubTypes: [], originalSubTypes: []};
  },

  props: {
    productTypeId: {type: Number, required: false},
    productSubTypeId: {type: Number, required: false},
    disabled: {type: Boolean, required: false}
  },

  created: function () {
    this.proceedProductSubtypes();
  },

  watch: {
    selected(value) {
        this.$emit("subtypeSelected", value);
    },
    productTypeId(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.originalSubTypes.length === 0) {
          this.originalSubTypes = this.getAllProductSubTypes();
        }
        this.filterProductSubtypesByParentTypeId(this.originalSubTypes, newValue);
      }
    },
    productSubTypeId(val) {
      this.selected = val;
    }
  },

  methods: {
    async proceedProductSubtypes() {
      const fetchedSubtypes = await this.getAllProductSubTypes();
      if (fetchedSubtypes) {
        this.fulfillSubTypes(fetchedSubtypes);
      }
    },
    getAllProductSubTypes: async function () {
      try {
        const response = await ProductSubTypes.getRestApi().getAllProductSubTypes();
        return response.data;
      } catch (error) {
        console.log(error)
      }
    },
    fulfillSubTypes(response) {
      if (this.$props.productTypeId && this.$props.productSubTypeId) {
        this.filterProductSubtypesByParentTypeId(response, this.$props.productTypeId);
        this.originalSubTypes = response;
        this.selected = this.$props.productSubTypeId;
      } else {
        this.originalSubTypes = response;
        this.$data.productSubTypes = response;
      }
      if (!this.productSubTypeId && !this.productTypeId) {
        this.$emit('noSubTypes');
      }
    },
    filterProductSubtypesByParentTypeId(subtypes, parentId) {
      let filteredSubtypes = [];
      subtypes.forEach(item => {
        if (item.parentId === parentId) {
          filteredSubtypes.push(item);
        }
      });
      this.$data.productSubTypes = filteredSubtypes;
      if (filteredSubtypes.length === 0) {
        this.$emit('noSubTypes');
      }
    }
  }
};
</script>
