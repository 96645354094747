<template>
  <v-alert
      v-if="formError"
      v-model="alert"
      class="multi-line"
      close-text="Close Alert"
      dismissible
      type="error"
      icon="warning"
      outlined
      @click="clearError"
  >
    {{ formError }}
  </v-alert>
</template>

<script>

export default {

  props: {
    formError: String | null,
  },

  data() {
    return {
      alert: true,
    }
  },

  watch: {
    formError(val) {
      this.alert = !!val;
    }
  },

  methods: {
    clearError() {
      this.$emit('clearError');
    }
  }
}
</script>

<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>
