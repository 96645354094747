<template>
  <navigation-view
      ref="nav"
      page-title="Broadcast System Message"
      nav-section="admin"
  >
    <template v-slot:content>
      <v-card
          style="width: 40%; border: 1px solid #eee;"
          flat
          class="ml-3 mt-3 pa-4"
      >
        <v-card-text>
          <broadcast-message-form ref="form"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              class="mr-2"
              color="primary"
              @click="onSendMessage"
          >
            Send Broadcast Message
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import BroadcastMessageForm from "./BroadcastMessageForm.vue";
import System from "@/rest/system";

export default {
  components: {
    NavigationView,
    BroadcastMessageForm
  },

  data() {
    return {};
  },

  methods: {
    async onSendMessage() {
      let content = this.$refs.form.getFormContent();
      try {
        await System.getRestApi().sendBroadcastMessage(content);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
