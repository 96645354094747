import CreateReferenceIds from "../referenceIds";
import Constants from "../constants";
import CurrencyFormatter from "./currencyFormatter";

const SPECIFIC_LINE_ITEM_FIELDS = ["materialCost", "overbill", "laborCost", "customerPayment", "thirdPartyPayment",
  "fiftyFloorAmount", "balanceTakenAmount", "materialsAmount", "laborAmount"];
const CURRENCY_FIELDS = ["customerPaidAmount", "salesRepWarrantyCost", "fiftyFloorWarrantyCost"];

const LineItemMapper = {
  formatLineItemsForWebsocket(all) {
    let formatted = [];
    all.forEach((item) => {
      if (item) {
        const jobLineItem = item.jobLineItem;
        let formattedItem = {
          type: jobLineItem.type,
          price: jobLineItem.price ? jobLineItem.price.value : 0
        };
        this.setFieldsIfPresent(jobLineItem, formattedItem, ...SPECIFIC_LINE_ITEM_FIELDS);
        this.setCurrencyFieldsIfPresent(jobLineItem, formattedItem, ...CURRENCY_FIELDS);
        formatted.push(formattedItem);
      }
    });
    return formatted;
  },

  combineAllLineItemsFromJob(component) {
    let all = [];
    all.push(component.contractItem);
    all.push(...component.productItems);
    all.push(...component.laborItems);
    all.push(...component.financeItems);
    all.push(...component.paymentItems);
    all.push(...component.chargebackItems);
    all.push(...component.installationIssueItems);
    all.push(...component.refundItems);
    all.push(...component.miscItems);
    all.push(component.warrantyLineItem);
    return all;
  },

  combineAllLineItemsFromJobForCalculation(component) {
    let all = [];
    all.push(...this.combineAllLineItemsFromJob(component));
    all.push(...component.bcaItems);
    return all;
  },

  setFieldsIfPresent(item, formattedItem, ...fields) {
    fields.forEach(field => {
      if (typeof item[field] !== 'undefined' && typeof item[field] !== 'object') {
        formattedItem[field] = item[field];
      }
    });
  },

  setCurrencyFieldsIfPresent(item, formattedItem, ...fields) {
    fields.forEach(field => {
      if (typeof item[field] !== 'undefined' && typeof item[field].value !== 'object') {
        if (item[field].value) {
          formattedItem[field] = item[field].value;
        } else if (typeof item[field] === 'number') {
          formattedItem[field] = item[field];
        }
      }
    });
  },

  assureContractLineItem() {
    let item = {};
    item.jobLineItem = {
      referenceId: CreateReferenceIds.referenceId("CTO"),
      type: Constants.jobType.contract,
      quantity: 1,
      price: CurrencyFormatter.zeroCurrency(),
      contractPriceWithoutWarranty: CurrencyFormatter.zeroCurrency(),
      status: null,
      comment: null,
    };
    return item;
  }
};
export default LineItemMapper;
