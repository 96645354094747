<template>
  <navigation-view
      ref="nav"
      page-title="Inventory in Transfer Details"
      nav-section="warehouse"
  >
    <template v-slot:content>
      <v-container grid-list-lg fluid id="inventory-in-transfer-details">
        <inventory-in-transfer-full-details-form-readonly
            :transferRequestItemId="transferRequestItemId"
            @showError="onShowError"
            @inventoryItemIdReceived="onInventoryItemIdReceived"
        />

        <v-expansion-panels multiple accordion :value="expanded">
          <v-expansion-panel>
            <v-expansion-panel-header color="#69b">
              Inventory Reservations
            </v-expansion-panel-header>
            <v-expansion-panel-content key="reservations">
              <inventory-reservations-grid
                  v-if="inventoryItemId"
                  ref="reservations"
                  :inventoryItemId="inventoryItemId"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-container>
    </template>

  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import InventoryInTransferFullDetailsFormReadonly from "./InventoryInTransferFullDetailsFormReadOnly.vue";
import InventoryReservationsGrid from "../../components/inventoryItems/reservations/InventoryReservationsGrid.vue";
import GridToolbar from "../../components/common/grid/GridToolbar.vue";
import Constants from "../../assets/constants";


export default {
  components: {
    GridToolbar,
    InventoryReservationsGrid,
    InventoryInTransferFullDetailsFormReadonly,
    NavigationView,
  },

  data() {
    return {
      transferRequestItemId: null,
      inventoryItemId: null,
      expanded: Constants.expandedByDefault
    };
  },

  created() {
    this.display(this.$route.params.transferRequestItemId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.transferRequestItemId);
    next();
  },

  computed: {},

  methods: {
    display(transferRequestItemId) {
      this.transferRequestItemId = transferRequestItemId;
    },
    onInventoryItemIdReceived(inventoryItemId) {
      this.inventoryItemId = inventoryItemId;
    },
    onShowError(error) {
      this.$refs.nav.showError(error);
    }
  }
};
</script>
