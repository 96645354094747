<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Items Ordered
          </v-expansion-panel-header>
          <v-expansion-panel-content key="items">
            <purchase-order-items-inline-grid
                ref="itemsOrdered"
                :purchaseOrderLines="lineItems"
                :purchaseOrder="purchaseOrder"
                :jobReferenceId="jobReferenceId"
                :totalVendorCostRounded="totalVendorCostRounded"
                :customer="customer"
                @onPurchaseOrderItemClose="purchaseOrderItemClose"
                @onQuantityEdited="refreshData"
                @onEtaUpdated="etaUpdated"
                @onUpdateError="onUpdateEtaError"
                @onUpdateQuantityError="emitError"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-card class="tab-container">
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                v-if="purchaseOrder.job"
                :job-id="purchaseOrder.jobId"
                :jobReferenceId="purchaseOrder.job.referenceId"
                parent-type="job"
                :documentTypesCategory=Constants.documentTypesCategories.JOB_PURCHASE_ORDER
            />
            <files-grid
                v-else
                ref="files"
                :purchase-order-number="purchaseOrder.purchaseOrderNumber"
                parent-type="purchaseorder"
                :documentTypesCategory=Constants.documentTypesCategories.STOCK_PURCHASE_ORDER
            />
          </v-tab-item>
          <v-tab key="activityLog">Activity Log</v-tab>
          <v-tab-item key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PurchaseOrderItemsInlineGrid from "../../components/purchaseOrders/PurchaseOrderItemsInlineGrid";
import FilesGrid from "../../components/common/inlineGrids/files/FilesGrid.vue";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import Constants from "../../assets/constants";
import PurchaseOrders from "../../rest/purchaseOrders";

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    PurchaseOrderItemsInlineGrid,
    ActivityLogsGrid,
    FilesGrid
  },
  props: {
    purchaseOrderNumber: String,
    purchaseOrder: Object,
    lineItems: Array,
    jobReferenceId: String,
    customer: Object,
    totalVendorCostRounded: String
  },
  data() {
    return {
      activityLogs: [],
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    async getActivityLogs() {
      try {
        const response = await PurchaseOrders.getRestApi().getActivityLogs(this.purchaseOrderNumber);
        this.activityLogs = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    purchaseOrderItemClose(selectedItem) {
      this.$emit('itemClose', selectedItem)
    },
    refreshData(summary) {
      this.lineItems = summary.lineItems;
      this.$emit('onLineItemsUpdated', summary);
      this.getActivityLogs();
    },
    etaUpdated() {
      this.$emit('onEtaUpdated');
      this.getActivityLogs();
    },
    onUpdateEtaError(error) {
      this.$emit('showError', error);
    }
  }
};
</script>
