<template>
  <v-row>
    <v-col md="12">
      <v-card class="tab-container">
        <v-tabs background-color="#69b" color="white" slider-color="white" dark>
          <v-tab key="lead" ripple>Lead Information</v-tab>
          <v-tab-item key="lead">
            <lead-panel
                v-if="lead"
                :leadDetails="lead"
                @leadInfoPanelError="emitError"
            />
          </v-tab-item>

          <v-tab key="customer" ripple>Customer Information</v-tab>
          <v-tab-item key="customer">
            <customer-panel
                :customerReferenceId="customer.referenceId"
                @CustomerPanelError="emitError"
            />
          </v-tab-item>

          <v-tab key="orders" ripple>Purchase Orders</v-tab>
          <v-tab-item key="orders">
            <purchase-orders
                ref="orders"
                :job-id="job.id"
            />
          </v-tab-item>

          <v-tab v-if="!job.parentJobId" key="subjobs" ripple>Subjobs</v-tab>
          <v-tab-item v-if="!job.parentJobId" key="subjobs">
            <sub-jobs-panel
                :job="job"
                @open="onOpenSubjob"
            />
          </v-tab-item>

          <v-tab key="issues">Job Issues</v-tab>
          <v-tab-item key="issues">
            <job-issues-container
                :job="job"
                :productItems="productItems"
                :laborItems="laborItems"
                :remainingBalance="remainingBalance"
                :wsSessionId="wsSessionId"
                @submitDiscount="onSubmitDiscount"
                @refresh="emitRefreshPage"
                @submitChargeback="onSubmitChargeback"
                @submitInstallationIssue="onSubmitInstallationIssue"
                @submitRefund="onSubmitRefund"
                @emitError="emitError"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>

    <v-col md="12" id="tabs-container">
      <v-card class="tab-container">
        <v-tabs background-color="#69b" color="white" dark slider-color="white" v-model="tabIndex">
          <v-tab key="notes">Notes</v-tab>
          <v-tab-item key="notes">
            <notes-grid
                ref="notesGrid"
                :notes="notes"
                @saveNote="onSaveNote"
            />
          </v-tab-item>

          <v-tab key="nextActions">Next Actions</v-tab>
          <v-tab-item key="nextActions">
            <next-actions-grid
                ref="nextActionsGrid"
                :nextActions="nextActions"
                :nextActionStatuses="nextActionStatuses"
                @saveNextAction="onSaveNextAction"
                @updateNextAction="actionStatusChanged"
                @getJobNextActions="getJobNextActions"
                @getNextActionStatuses="getNextActionStatuses"
            />
          </v-tab-item>

          <v-tab key="installations">Installations</v-tab>
          <v-tab-item key="installations">
            <job-installations
                ref="jobInstallations"
                :readOnly="installationsReadOnly"
                :job="job"
                :jobReferenceId="job.referenceId"
                :wsSessionId="wsSessionId"
                @jobInstallationError="emitError"
                @added="emitInstallationAdded"
                @deleted="emitInstallationDeleted"
            />
          </v-tab-item>

          <v-tab key="tickets">Job Tickets</v-tab>
          <v-tab-item key="tickets">
            <category-specific-tickets-grid
                ref="jobTickets"
                type="job"
                :jobId="job.id"
                :jobReferenceId="job.referenceId"
                :customer="customer"
                @showError="emitError"
                @onRefresh="refreshActivityLog"
            />
          </v-tab-item>

          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                :job-id="job.id"
                :jobReferenceId="job.referenceId"
                parent-type="job"
                :documentTypesCategory=Constants.documentTypesCategories.JOB
                :availableLabors="availableLabors"
                :wsSessionId="wsSessionId"
                @refreshPageRequired="emitRefreshPage"
            />
          </v-tab-item>

          <v-tab key="activityLog">Activity Log</v-tab>
          <v-tab-item key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <confirm-update-status-dialog
        :statusChange="statusChange"
        :chosenStatus="chosenStatus"
        @actionStatusUpdateConfirmed="actionStatusUpdateConfirmed"
        @actionStatusUpdateCancelled="actionStatusUpdateCancelled"
    />
  </v-row>
</template>

<script>
import DateFormatter from "../../assets/dateFormatter";
import CustomerPanel from "../../components/jobs/details/CustomerPanel.vue";
import LeadPanel from "../../components/jobs/details/LeadPanel.vue";
import SubJobsPanel from "../../components/jobs/subjobs/SubjobsPanel.vue";
import ConfirmUpdateStatusDialog from "../../components/common/dialogContainers/nextActions/ConfirmUpdateStatusDialog";
import JobIssuesContainer from "../../components/jobs/beginJobIssues/JobIssuesContainer.vue";
import JobInstallations from "../../components/installations/PanelContainer.vue";
import PurchaseOrders from "../../components/jobs/details/PurchaseOrders.vue";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import CategorySpecificTicketsGrid from "../../components/tickets/CategorySpecificTicketsGrid.vue";
import FilesGrid from "../../components/common/inlineGrids/files/FilesGrid.vue";
import NotesGrid from "../../components/common/inlineGrids/NotesGrid.vue";
import NextActionsGrid from "../../components/common/inlineGrids/NextActionsGrid";
import NextActions from "../../rest/nextActions.ts";
import Jobs from "../../rest/jobs.ts";
import Notes from "../../rest/notes";
import Constants from "../../assets/constants";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    ConfirmUpdateStatusDialog,
    NextActionsGrid,
    CustomerPanel,
    LeadPanel,
    SubJobsPanel,
    JobIssuesContainer,
    JobInstallations,
    CategorySpecificTicketsGrid,
    FilesGrid,
    PurchaseOrders,
    ActivityLogsGrid,
    NotesGrid,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    productItems: Array,
    laborItems: Array,
    customer: {
      type: Object | null,
      required: true
    },
    remainingBalance: Number,
    installationsReadOnly: {
      type: Boolean,
      required: true,
    },
    wsSessionId: {
      type: String,
      required: true
    },
    lead: Object
  },

  data() {
    return {
      activityLogs: [],
      notes: [],
      nextActions: [],
      nextActionStatuses: [],
      statusChange: null,
      chosenStatus: null,
      tabIndex: 0
    };
  },

  computed: {
    Constants() {
      return Constants
    },
    leadReferenceId() {
      return this.job.leadReferenceId;
    },
    availableLabors() {
      return this.laborItems.map(li => {
        if (li.installation
            && (DateUtils.isPastDate(li.installation.installationDate)
                || DateUtils.isSameDate(li.installation.installationDate, new Date())
            )
            && li.installation.installerId !== Constants.TBDInstallerId) {
          return li.jobLineItem;
        }
      });
    }
  },

  created() {
    this.getJobNextActions();
  },

  methods: {
    emitRefreshPage() {
      this.$emit('refreshPage');
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    emitInstallationAdded() {
      this.$emit('installationAdded');
    },
    emitInstallationDeleted() {
      this.$emit('installationDeleted');
    },
    async onSaveNote(data) {
      try {
        const params = {...data};
        params.jobId = this.job.id;
        await this.saveNote(params);
        await this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async saveNote(params) {
      try {
        await Notes.getRestApi().createJobNote(this.job.id, params);
      } catch (error) {
        this.$refs.notesGrid.resetCreateNoteForm();
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    async onGetNotes() {
      try {
        const response = await this.getJobNotes();
        this.setJobNotes(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getJobNotes() {
      try {
        return await Notes.getRestApi().getJobAndLeadAndTicketNotes(this.job.id);
      } catch (error) {
        this.emitError(error);
      }
    },
    setJobNotes(notes) {
      this.notes = notes;
    },
    setActivityLogs(logs) {
      this.activityLogs = logs;
    },
    refreshInlineGrids() {
      this.onGetNotes();
      this.refreshFormLaborLineItems();
      this.refreshActivityLog();
      this.refreshJobInstallations();
    },
    refreshFormLaborLineItems() {
      if (this.$refs.jobInstallations) {
        this.$refs.jobInstallations.refreshFormLaborLineItems();
      }
    },
    async refreshActivityLog() {
      try {
        if (this.$refs.activityLog) {
          await this.getActivityLogs();
        }
      } catch (error) {
        this.emitError(error);
      }
    },
    refreshJobInstallations() {
      if (this.$refs.jobInstallations) {
        this.$refs.jobInstallations.refresh();
      }
    },
    async getActivityLogs() {
      try {
        const response = await Jobs.getRestApi().getJobActivityLogs(this.job.id);
        this.setActivityLogs(response.data);
      } catch (error) {
        throw error;
      }
    },
    onJobFulfilled() {
      setTimeout(() => {
        this.refreshOrdersGrid();
      }, 2000);
      this.refreshActivityLog();
    },
    onJobInvoiced() {
      setTimeout(() => {
        this.refreshOrdersGrid();
      }, 2000);
      this.refreshActivityLog();
    },
    onSubmitDiscount() {
      this.$emit('submitDiscount');
    },
    onSubmitChargeback(payload) {
      this.$emit('submitChargeback', payload);
    },
    onSubmitInstallationIssue(payload) {
      this.$emit('submitInstallationIssue', payload);
    },
    onSubmitRefund(payload) {
      this.$emit('submitRefund', payload);
    },
    onOpenSubjob(referenceId) {
      this.$emit('openSubjob', referenceId);
    },
    async getJobNextActions() {
      try {
        const response = await NextActions.getRestApi().getNextActionsByJobId(this.job.id);
        let formattedDates = response.data;
        formattedDates.forEach((item) => {
          item.dueDate = DateFormatter.formatDate(item.dueDate);
        });
        this.nextActions = formattedDates;
        this.$emit('nextActionToShow', this.nextActions);
      } catch (error) {
        this.emitError(error);
      }
    },
    async getNextActionStatuses() {
      if (this.nextActions.length > 0) {
        await this.getAllJobNextActionStatuses();
      }
    },
    async onSaveNextAction(data) {
      await this.createJobNextAction(data);
    },
    async createJobNextAction(data) {
      try {
        const response = await NextActions.getRestApi().createJobNextAction(this.job.id, data);
        if (response) {
          this.$refs.nextActionsGrid.onSuccessSave();
          await this.getAllJobNextActionStatuses();
          this.refreshNextActionsGrid();
          await this.refreshNotesGrid();
          this.$emit('refreshContractPanel');
        }
      } catch (error) {
        this.emitError(error);
        this.$refs.nextActionsGrid.setDialogLoadingFalse();
      }
    },
    async getAllJobNextActionStatuses() {
      const response = await NextActions.getRestApi().getAllNextActionStatuses();
      let initialId = 0;
      this.nextActionStatuses = response.data.map(item => {
        let mapped = {
          id: initialId,
          name: item
        }
        initialId++;
        return mapped;
      });
    },
    refreshNextActionsGrid() {
      try {
        this.getJobNextActions();
        this.statusChange = null;
        this.refreshNotesGrid();
      } catch (error) {
        this.emitError(error);
      }
    },
    async refreshNotesGrid() {
      await this.onGetNotes();
    },
    actionStatusChanged(status, nextActionId) {
      let newStatus = this.nextActionStatuses.find(item => item.name === status);
      this.statusChange = {
        nextActionId: nextActionId,
        newStatusId: newStatus.id
      };
      this.chosenStatus = newStatus.name;
    },
    async performUpdateNextAction(statusChange) {
      try {
        await NextActions.getRestApi().updateJobNextAction(statusChange.nextActionId, statusChange.newStatusId);
        this.$refs.nextActionsGrid.showSnackbarSuccess("Next Action Status Changed Successfully!");
        this.refreshNextActionsGrid();
      } catch (error) {
        this.$emit("showError", error);
        this.refreshNextActionsGrid();
      }
    },
    actionStatusUpdateConfirmed(statusChange) {
      this.performUpdateNextAction(statusChange);
    },
    actionStatusUpdateCancelled() {
      this.refreshNextActionsGrid();
    },
    nextActionsTabSelected() {
      this.tabIndex = 1;
      this.scrollToTabBottom();
    },
    scrollToTabBottom() {
      let element = document.getElementById("tabs-container");
      element.scrollIntoView({behavior: "smooth", block: "end"});
    },
    refreshOrdersGrid() {
      if (this.$refs.orders) {
        this.$refs.orders.refresh();
      }
    },
  },
};
</script>

<style scoped>
.tab-container {
  min-height: 170px;
}
.v-slide-group__content.v-tabs-bar__content {
  background-color: aquamarine;
}
</style>
