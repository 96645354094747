<template>
  <v-card>
    <v-card-text class="ml-2">
      <v-row class="mb-4">
        <v-col md="8">
          <v-card
              flat
              class="title left-aligned mb-4 mt-3 blue-grey--text text--lighten-1"
          >
            Job Detail
          </v-card>
          <v-card>
            <inline-grid
                :data-source="sortedItems"
                :show-borders="false"
                noDataText="Job has no line items"
                :hoverStateEnabled="false"
                :hideDelete="true"
            >
              <dx-column
                  data-field="jobLineItem.referenceId"
                  width="15%"
                  caption="Reference Id"
              />
              <dx-column
                  data-field="typeName"
                  width="20%"
                  caption="Line Item Type"
              />
              <dx-column
                  data-field="description"
                  width="50%"
                  caption="Item Description"
              />
              <dx-column
                  data-field="formattedPrice"
                  width="15%"
                  alignment="right"
                  caption="Amount"
              />
            </inline-grid>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-card class="ml-4" flat>
            <v-card
                class="title left-aligned mb-4 mt-3 blue-grey--text text--lighten-1"
                flat
            >
              Commission Computation
            </v-card>
            <v-card
                flat
                class="subheading left-aligned blue-grey--text text--lighten-1"
            >
              <div class="left-column">
                <strong>Contract Value:</strong>
              </div>
              <div class="right-column">{{ asCurrency(contractPrice) }}</div>
            </v-card>
            <v-card
                flat
                class="subheading left-aligned blue-grey--text text--lighten-1"
            >
              <div class="left-column">
                <strong>Total Job Cost:</strong>
              </div>
              <div class="right-column">{{ asCurrency(jobCost) }}</div>
            </v-card>
            <v-card
                flat
                class="subheading left-aligned blue-grey--text text--lighten-1"
            >
              <div class="left-column">
                <strong>Net Profit:</strong>
              </div>
              <div class="right-column">{{ asCurrency(margin) }}</div>
            </v-card>
            <v-card
                flat
                class="subheading left-aligned blue-grey--text text--lighten-1"
            >
              <div class="left-column">
                <strong>Profit Margin:</strong>
              </div>
              <div class="right-column">{{ marginPercent }}%</div>
            </v-card>
            <v-card
                flat
                class="subheading left-aligned blue-grey--text text--lighten-1"
            >
              <div class="left-column">
                <strong>Commission Rate:</strong>
              </div>
              <div class="right-column">{{ commissionPercent }}</div>
            </v-card>
            <v-card
                flat
                class="subheading left-aligned blue-grey--text text--lighten-1"
            >
              <div class="left-column">
                <strong>Commission Amount:</strong>
              </div>
              <div class="right-column">{{ commissionAmount }}</div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Jobs from "@/rest/jobs";
import SalesCommissions from "@/rest/salesCommissions";
import Constants from "@/assets/constants";
import Currency from "currency.js";
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";

export default {
  components: {DxColumn, InlineGrid},

  props: ["jobReferenceId"],

  data() {
    return {
      summary: null,
      salesCommission: null,
      sortedItems: [],
      snackbar: false
    };
  },

  watch: {
    jobReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  computed: {
    contractPrice() {
      return this.summary ? this.summary.job.contractPrice : 0;
    },
    jobCost() {
      return this.summary ? this.summary.job.cost : 0;
    },
    margin() {
      return this.summary
          ? this.summary.job.contractPrice - this.summary.job.cost
          : 0;
    },
    marginPercent() {
      return this.contractPrice
          ? Currency((100 * this.margin) / this.contractPrice)
          : Currency(0);
    },
    commissionPercent: function () {
      return this.salesCommission
          ? this.salesCommission.commissionPercentage + "%"
          : "N/A";
    },
    commissionAmount() {
      return this.salesCommission
          ? this.asCurrency(this.salesCommission.commission)
          : "N/A";
    }
  },

  methods: {
    async refresh() {
      try {
        let response = await Jobs.getRestApi().getJobSummaryById(this.jobReferenceId);
        this.summary = response.data;
        if (response.data) {
          let allItems = response.data.lineItems;
          allItems.sort((i1, i2) => i1.jobLineItem.type - i2.jobLineItem.type);
          allItems = allItems.filter(
              item =>
                  item.jobLineItem.type != Constants.jobType.contract &&
                  item.jobLineItem.type != Constants.jobType.payment
          );
          this.embellish(allItems);
          this.sortedItems = allItems;
          this.loadCommission(response.data.job);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async loadCommission(job) {
      let response = await SalesCommissions.getRestApi().getSalesCommissionByJobReferenceId(job.referenceId);
      if (response.data) {
        this.salesCommission = response.data;
      }
    },

    embellish(items) {
      items.forEach(item => {
        let type = item.jobLineItem.type;
        item.formattedPrice = this.asCurrency(item.jobLineItem.price);
        switch (type) {
          case Constants.jobType.product: {
            let productType = item.jobLineItem.productCatalogItem.productType.name;
            let styleName = item.jobLineItem.productCatalogItem.styleName;
            let color = item.jobLineItem.productCatalogItem.color;
            let quantity = item.jobLineItem.quantity;
            let cost = "(no cost found)";
            let overbill = 0;
            let totalCost = 0;
            if(item.productCatalogItem.isMigrated) {
              let repPrice = item.repPrice.cost;
              totalCost = this.asCurrency(repPrice);
            } else if (item.supplierCost && item.overbill) {
              cost = item.supplierCost.cost;
              overbill = item.overbill.overbill;
              totalCost = this.asCurrency(cost + overbill);
            }
            item.description = `${productType} (${styleName}/${color}) ${quantity} at ${totalCost}`;
            item.typeName = "Product";
            break;
          }
          case Constants.jobType.labor: {
            item.typeName = "Labor";
            let capability = item.jobLineItem.installationCapability
                ? item.jobLineItem.installationCapability.name
                : "Unknown";
            item.description = `${capability}`;
            break;
          }
          case Constants.jobType.finance: {
            item.typeName = "Finance Charge";
            break;
          }
          case Constants.jobType.installIssue: {
            item.typeName = "Installation Issue";
            break;
          }
          case Constants.jobType.chargeback: {
            item.typeName = "Chargeback";
            break;
          }
          case Constants.jobType.bca: {
            item.typeName = "Branch Computed Adjustment";
            let bca = item.jobLineItem.comment;
            item.description = `${bca}`;
            break;
          }
        }
      });
    },

    asCurrency(value) {
      return Currency(value, {formatWithSymbol: true}).format();
    }
  }
};
</script>

<style scoped>
.left-column {
  width: 200px;
  display: inline-block;
  text-align: left;
}
.right-column {
  width: 200px;
  display: inline-block;
  text-align: right;
}
.left-aligned {
  text-align: left;
}
</style>
