<template>
  <drawer-form
      ref="form"
      :title="title"
      :submit-label="submitLabel"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container>
      <v-row>
        <v-col md="6">
          <v-text-field
              id="product-type-name"
              v-model="name"
              label="* Product Type"
              :rules="[() => !!name || 'Product Type is required']"
              required
              placeholder=" "
              persistent-placeholder
          >
          </v-text-field>
        </v-col>
        <v-col md="6">
          <status-dropdown
              id="product-type-status"
              :value="active"
              @onStatusChange="v => (active = v)"
          ></status-dropdown>
        </v-col>
        <v-col md="12">
          <v-text-field
              id="product-type-description"
              v-model="description"
              label="Description"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";

const formDefaults = {
  name: "",
  active: true,
  description: ""
};

export default {
  components: {
    DrawerForm,
    StatusDropdown
  },

  data() {
    return Object.assign({}, formDefaults);
  },

  props: ["title", "submitLabel"],

  methods: {
    onCancel() {
      this.$emit("cancelClicked");
      this.$refs.form.reset();
      this.loadForm();
    },
    onSubmit() {
      let valid = this.$refs.form.validate();
      if (valid) {
        const payload = this.buildPayload();
        this.$emit("submitClicked", payload);
        this.$refs.form.reset();
      }
    },
    buildPayload() {
      const payload = {};
      payload.name = this.name;
      payload.description = this.description;
      payload.active = this.active;
      return payload;
    },
    loadForm(payload = Object.assign(formDefaults)) {
      this.name = payload.name;
      this.description = payload.description;
      this.active = payload.active;
    }
  }
};
</script>
