<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0">
    <div v-if="!showReferenceIdBlock">
      <v-row class="pb-0">
        <v-col cols="3">
          <v-text-field
              :value="customer.firstName"
              label="First Name"
              readonly
              id="customer-details-read-only-first-name"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
              :value="customer.lastName"
              label="Last Name"
              readonly
              id="customer-details-read-only-last-name"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2">
          <v-text-field
              :value="customer.primaryPhone"
              :label="primaryPhoneLabel"
              v-facade="phoneNumberMask"
              readonly
              id="customer-details-read-only-primary-phone"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
              :value="customer.alternatePhone"
              label="Alt Phone"
              v-facade="phoneNumberMask"
              readonly
              id="customer-details-read-only-alt-phone"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" v-if="showAddress">
          <v-text-field
              :value="customer.address"
              label="Customer Mailing Address"
              readonly
              id="customer-details-read-only-address"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="4">
          <v-text-field
              :value="customer.email"
              label="Email"
              readonly
              id="customer-details-read-only-email"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3" v-if="showAddress">
          <v-text-field
              :value="customer.city"
              label="City"
              readonly
              id="customer-details-read-only-city"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="1" v-if="showAddress">
          <v-text-field
              :value="customer.state"
              label="State"
              readonly
              id="customer-details-read-only-state"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2" v-if="showAddress">
          <v-text-field
              :value="customer.zipCode"
              label="Zip Code"
              readonly
              id="customer-details-read-only-zip-code"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="showReferenceIdBlock">
      <v-row class="pb-0">
        <v-col cols="3">
          <v-text-field
              :value="customer.referenceId"
              label="Reference Id"
              readonly
              id="customer-details-read-only-reference-id"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="5"></v-col>
        <v-col cols="2">
          <v-text-field
              :value="customer.primaryPhone"
              :label="primaryPhoneLabel"
              v-facade="phoneNumberMask"
              readonly
              id="customer-details-read-only-primary-phone"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
              :value="customer.alternatePhone"
              label="Alt Phone"
              v-facade="phoneNumberMask"
              readonly
              id="customer-details-read-only-alt-phone"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <v-text-field
              :value="customer.firstName"
              label="First Name"
              readonly
              id="customer-details-read-only-first-name"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
              :value="customer.lastName"
              label="Last Name"
              readonly
              id="customer-details-read-only-last-name"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="4">
          <v-text-field
              :value="customer.email"
              label="Email"
              readonly
              id="customer-details-read-only-email"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" v-if="showAddress">
          <v-text-field
              :value="customer.address"
              label="Customer Mailing Address"
              readonly
              id="customer-details-read-only-address"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-if="showAddress">
          <v-text-field
              :value="customer.city"
              label="City"
              readonly
              id="customer-details-read-only-city"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="1" v-if="showAddress">
          <v-text-field
              :value="customer.state"
              label="State"
              readonly
              id="customer-details-read-only-state"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="2" v-if="showAddress">
          <v-text-field
              :value="customer.zipCode"
              label="Zip Code"
              readonly
              id="customer-details-read-only-zip-code"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>

import Constants from "@/assets/constants";

export default {
  components: {},

  props: {
    customer: {
      type: [Object, null],
    },
    showAddress: {
      type: Boolean,
      required: false,
      default: true
    },
    showReferenceIdBlock: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    primaryPhoneLabel() {
      let primaryPhoneOwnership = this.customer?.isPrimaryPhoneOwner ? "Owner" : "Not Owner";
      return `Cell Phone (${primaryPhoneOwnership})`;
    }
  },
  data() {
    return {
      phoneNumberMask: Constants.phoneNumberMask
    };
  }
};
</script>
