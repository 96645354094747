import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";

/**
 * Sales rep event.
 */
export class SalesRepEvent {
  id: number;
  dateStart: Date;
  dateEnd: Date;
  description?: string;
  salesRepId: number;

  constructor(
    id: number,
    dateStart: Date,
    dateEnd: Date,
    description: string,
    salesRepId: number
  ) {
    this.id = id;
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.description = description;
    this.salesRepId = salesRepId;
  }
}

/**
 * Schedule event.
 */
export class ScheduleEvent {
  id: number;
  eventDateStart: Date;
  eventDateEnd: Date;
  primaryPhone?: string;
  alternatePhone?: string;
  email?: string;
  leadRef: string;
  fullName?: string;
  address?: string;
  leadStatus?: string;
  description?: string;

  constructor(
    id: number,
    eventDateStart: Date,
    eventDateEnd: Date,
    primaryPhone: string,
    alternatePhone: string,
    email: string,
    fullName: string,
    leadRef: string,
    address: string,
    leadStatus: string,
    description: string
  ) {
    this.id = id;
    this.eventDateStart = eventDateStart;
    this.eventDateEnd = eventDateEnd;
    this.primaryPhone = primaryPhone;
    this.alternatePhone = alternatePhone;
    this.email = email;
    this.leadRef = leadRef;
    this.fullName = fullName;
    this.address = address;
    this.leadStatus = leadStatus;
    this.description = description;
  }
}

/**
 * Job Installation
 */

export class Job {
  id: number;
  installationDate: Date;
  jobReferenceId: string;
  jobStatus: string;
  customerName: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: string;
  roomTypes: string;
  productTypes: string;

  constructor(
    id: number,
    installationDate: Date,
    jobReferenceId: string,
    jobStatus: string,
    customerName: string,
    customerFirstName: string,
    customerLastName: string,
    customerAddress: string,
    roomTypes: string,
    productTypes: string
  ) {
    this.id = id;
    this.installationDate = installationDate;
    this.jobReferenceId = jobReferenceId;
    this.jobStatus = jobStatus;
    this.customerName = customerName;
    this.customerFirstName = customerFirstName;
    this.customerLastName = customerLastName;
    this.customerAddress = customerAddress;
    this.roomTypes = roomTypes;
    this.productTypes = productTypes;
  }
}

/**
 * REST interface provided for schedule events.
 * @deprecated
 */
export interface SalesHomeRestApi {
  getSalesRepSchedule(refId: string): AxiosPromise<ScheduleEvent[]>;
  getSalesRepEvents(refId: string): AxiosPromise<SalesRepEvent[]>;
  postSalesRepEvent(event: SalesRepEvent): AxiosPromise<SalesRepEvent>;
  deleteSalesRepEvent(salesRepId: number, id: number): AxiosPromise<SalesRepEvent>;
}

/**
 * REST implementation provided for product types.
 * @deprecated
 */
class Implementation implements SalesHomeRestApi {
  getSalesRepSchedule(refId: string): AxiosPromise<ScheduleEvent[]> {
    return Axios.axiosInstance().get<ScheduleEvent[]>(`/sales_reps/${refId}/schedule`);
  }

  getSalesRepEvents(refId: string): AxiosPromise<SalesRepEvent[]> {
    return Axios.axiosInstance().get<SalesRepEvent[]>(`/sales_reps/${refId}/events`);
  }

  deleteSalesRepEvent(eventId: number): AxiosPromise<SalesRepEvent> {
    return Axios.axiosInstance().delete(`/sales_reps/events/${eventId}`);
  }

  postSalesRepEvent(event: SalesRepEvent): AxiosPromise<SalesRepEvent> {
    return Axios.axiosInstance().post<SalesRepEvent>(`/sales_reps/events`, event);
  }

  getSalesRepJobs(refId: string): AxiosPromise<Job[]> {
    return Axios.axiosInstance().get<Job[]>(`/sales_reps/${refId}/job_installations`);
  }
}
const SalesHome = {
  getRestApi(): SalesHomeRestApi {
    const api = new Implementation();
    return api;
  }
};

export default SalesHome;
