<template>
  <drawer-form
      ref="form"
      title="Create Branch"
      submitLabel="Save Branch"
      :ifUpdate="false"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <branch-form
        ref="branchFormBody"
        :isCreateForm="isCreateForm"
    />
  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm.vue";
import BranchForm from "./BranchForm.vue";
import Branches from "../../rest/branches";

export default {
  components: {
    BranchForm,
    DrawerForm
  },

  data() {
    return {
      inProgress: false,
      isCreateForm: false
    };
  },

  mounted() {
    this.isCreateForm = true;
  },

  methods: {
    onCancelClicked() {
      this.$refs.form.resetValidation();
      this.$refs.branchFormBody.resetFormDefaults();
      this.$emit('cancelClicked');
    },
    async onSubmitClicked() {
      try {
        this.inProgress = true;
        let valid = this.validateForm();
        if (valid) {
          await this.createBranch();
          this.$refs.form.resetValidation();
          this.$refs.branchFormBody.resetFormDefaults();
          this.$emit('submitSuccess');
        }
      } catch (error) {
        this.$emit('submitFailed', error);
      }
      this.inProgress = false;
    },
    async createBranch() {
      try {
        const payload = this.$refs.branchFormBody.buildPayload();
        await Branches.getRestApi().createBranch(payload);
      } catch (error) {
        throw error;
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
  }
};
</script>
