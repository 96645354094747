<template>
  <div>
    <v-card flat>
      <section-divider title="INSTALLATION ISSUES"/>
      <headers :showScgPayableColumn="showScgPayableColumn"/>
      <panel
          :job="job"
          :showScgPayableColumn="showScgPayableColumn"
          :line-item="item"
          v-for="item in installationIssueItems"
          :key="item.referenceId"
          @itemDeleted="onItemDeleted"
      />
    </v-card>
  </div>
</template>

<script>
import Constants from "../../../assets/constants.js";
import Currency from "currency.js";
import Headers from "../../../components/jobs/installationIssueLineItems/InstallationIssuesHeaders.vue";
import Panel from "../../../components/jobs/installationIssueLineItems/InstallationIssuesPanel.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";
import CreateReferenceIds from "../../../assets/referenceIds";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    initialItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      installationIssueItems: []
    };
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.installationIssueItems = value;
      },
    },
  },

  computed: {
    showScgPayableColumn() {
      return this.installationIssueItems.some(i => i.jobLineItem.scgPayable);
    }
  },

  methods: {
    // Add new installation issue line item.
    onAddItem(payload) {
      const installationIssue = {
        jobLineItem: {
          referenceId: CreateReferenceIds.getTempReferenceId(),
          type: Constants.jobType.installIssue,
          status: null,
          comment: payload.note,
          quantity: null,
          laborAmount: payload.laborAmount,
          materialsAmount: payload.materialsAmount,
          fulfillmentDate: null,
          installationCapabilityId: payload.installation.jobLineItem.installationCapabilityId,
          installerReimbursement: payload.installerReimbursement,
          installationId: payload.installation.id,
          installation: payload.installation,
          installerId: payload.installation.installerId,
          laborAssociated: payload.laborAssociated,
          materialsAssociated: payload.materialsAssociated,
          customerSatisfaction: payload.customerSatisfaction,
          customerResponsibilityAmount: payload.customerSatisfactionAmount,
          price: Currency(payload.contractAdjustment).value,
          newLineItem: true,
        }
      };

      if (payload.installerPayable) {
        installationIssue.jobLineItem.installerPayable = payload.installerPayable;
        if (payload.scgPayable) {
          installationIssue.jobLineItem.scgPayable = payload.scgPayable;
          this.showScgPayableColumn = true;
        }
        this.$emit('onInstallerPayableAdded',
            payload.scgPayable ? payload.scgPayable : payload.installerPayable, installationIssue.jobLineItem.installation.jobLineItemId, true);
      }

      this.installationIssueItems.push(installationIssue);
      this.$emit('itemsUpdated', this.installationIssueItems);
      this.$emit('amountsUpdated');
      return installationIssue;
    },
    onItemDeleted(itemToRemove) {
      this.installationIssueItems = this.installationIssueItems.filter(current => current !== itemToRemove);
      this.$emit('installationItemDeleted', itemToRemove);
      this.$emit('itemsUpdated', this.installationIssueItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    markDirty() {
      this.$emit('dataUpdated');
    },
  },
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
