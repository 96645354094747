<template>
  <v-autocomplete
      v-model="selected"
      :items="availableSlots"
      hide-selected
      :item-text="fullTimeBlockName"
      item-value="name"
      :label=label
      :readonly="readonly"
      :placeholder="placeholder"
      persistent-placeholder
      :rules="[() => !!selected || 'Required']"
      :disabled="disabled"
      required
      dense
      attach
      return-object
  />
</template>

<script>

import DateUtils from "@/assets/utils/dateUtils";

export default {

  components: {},

  data() {
    return {
      selected: null,
      loading: false,
    };
  },

  props: {
    availableSlots: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: '* Vendor'
    },
    selectedValue: {
      type: Object,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select time slot'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    fullTimeBlockName() {
      return item => DateUtils.formatTimeBlockName(item.startTime, item.endTime);
    }
  },

  watch: {
    selectedValue: {
      immediate: true,
      handler(value) {
        if (value) {
          this.selected = this.findSlotByTime(value.startTime, value.endTime);
        }
      }
    },
    selected(newValue) {
      this.$emit('inputSelected', newValue);
    },
  },

  methods: {
    reset() {
      this.selected = null;
    },
    findSlotByTime(startTime, endTime) {
      if (startTime === null || endTime === null) {
        return null;
      }
      return this.availableSlots.find(slot => slot.startTime === startTime && slot.endTime === endTime);
    },
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
  width: 100%;
  display: block;
}
</style>
