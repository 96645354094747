<template>
  <v-container>
    <v-form ref="form" class="pt-6">
      <v-row>
        <v-col cols="12">
          <v-autocomplete
              v-model="selected"
              :readonly="isEditForm"
              :items="crews"
              item-text="name"
              item-value="id"
              :label="isEditForm ? 'Installer Crew' : '* Installer Crew'"
              :rules="[rules.required]"
              placeholder="Start typing to search"
              persistent-placeholder
              no-data-text="No active crews found"
          />
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col cols="6">
          <date-picker-with-type-in-text-field
              :disabled="!isStartDateEditable"
              ref="startDate"
              v-model="startDate"
              :optional="false"
              :label="isStartDateEditable ? '* Start Date' : 'Start Date'"
              :validation-name="'Start Date'"
              id="crew-dayoff-start-date"
              :allowed-dates="allowedStartDates"
              @input="onStartDateChanged"
          />
        </v-col>
        <v-col cols="6">
          <date-picker-with-type-in-text-field
              :disabled="isEditForm && !isEditableDayOff"
              ref="endDate"
              v-model="endDate"
              :optional="false"
              label="* End Date"
              :validation-name="'End Date'"
              id="crew-dayoff-end-date"
              :allowed-dates="allowedEndDates"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
              :disabled="isEditForm && !isEditableDayOff"
              auto-grow
              :rows="3"
              name="description"
              label="Comments"
              v-model="comment"
              placeholder=" "
              persistent-placeholder
              :rules="[rules.max255]"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import Rules from "@/assets/rules";
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField.vue";
import DateUtils from "@/assets/utils/dateUtils";

const defaultForm = {
  id: null,
  selected: null,
  startDate: null,
  endDate: null,
  comment: null,
};
export default {
  components: {
    DatePickerWithTypeInTextField,
    ModalDialog
  },

  data() {
    return {
      ...defaultForm,
      rules: {
        required: value => {
          return Rules.requiredWithFieldName(value, 'Installer Crew');
        },
        max255: value => Rules.maxValue(value, 255)
      }
    };
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    isEditForm: {
      type: Boolean,
      default: false
    },
    dayOff: {
      type: Object,
      default: null
    }
  },

  watch: {
    dayOff: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.id = newVal.id;
          this.selected = newVal.installerCrewId;
          this.startDate = newVal.startDate;
          this.endDate = newVal.endDate;
          this.comment = newVal.comment;
        }
      }
    }
  },

  computed: {
    isStartDateEditable() {
      return !this.isEditForm || (this.isEditForm && !DateUtils.isPastDate(this.startDate));
    },
    // Is editable if the end date is in the future
    isEditableDayOff() {
      return this.isEditForm && !DateUtils.isPastDate(this.endDate);
    }
  },

  methods: {
    resetForm() {
      Object.assign(this.$data, defaultForm);
      this.$refs.form.resetValidation();
      if (!this.isEditForm) {
        this.$refs.startDate.clearSelected();
        this.$refs.endDate.clearSelected();
      }
    },
    allowedStartDates(value) {
      return !DateUtils.isPastDate(value);
    },
    allowedEndDates(value) {
      return this.startDate ? DateUtils.isSameOrAfter(value, this.startDate) && !DateUtils.isPastDate(value) : false;
    },
    validate() {
      return this.$refs.form.validate();
    },
    buildPayload() {
      return {
        id: this.id,
        installerCrewId: this.selected,
        startDate: this.startDate,
        endDate: this.endDate,
        comment: this.comment
      };
    },
    onStartDateChanged(value) {
      if (value && !DateUtils.isSameOrAfter(this.endDate, value)) {
        this.endDate = null;
        this.$refs.endDate.clearSelected();
      }
    }
  }
};
</script>
