<template>
  <modal-dialog
      ref="removeDefaultDialog"
      :title="title"
      width="500"
      :visible="dialogVisible"
      :submitEnabled="true"
      :loading="loading"
      submitLabel="Delete"
      cancelLabel="Cancel"
      @submitClicked="onDeleteClicked"
      @cancelClicked="onCancelClicked"
  >
    <v-form ref="deleterForm">
      <v-container>
        <div> Do you really want to delete <b>{{ itemName }}</b>?
        </div>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";
import FormattingHelper from "../../assets/formattingHelper";

export default {
  components: {
    ModalDialog
  },

  data() {
    return {
      dialogVisible: false,
      itemName: null,
      cellData: null,
      loading: false,
    }
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Delete Item'
    },
  },

  methods: {
    openDialog(cellData, type) {
      this.cellData = cellData;
      this.itemName = this.createItemName(cellData, type);
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    clearCellData() {
      this.cellData = null;
    },
    onCancelClicked() {
      this.closeDialog();
      this.clearCellData();
    },
    onDeleteClicked() {
      this.deleteConfirmed(this.cellData);
    },
    deleteConfirmed(cellData) {
      this.loading = true;
      this.$emit('onDeleteItemConfirmed', cellData);
      this.clearCellData();
    },
    createItemName(cellData, type) {
      return FormattingHelper.objectAsHyphenDelimitedString(cellData.data, type);
    },
    setLoading(value) {
      this.loading = value;
    }
  }
};
</script>
