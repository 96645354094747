<template>
  <modal-dialog
    ref="dialog"
    title="Add Branch Integration"
    width="400"
    :visible="dialogVisible"
    submitLabel="Add Integration"
    cancelLabel="Cancel"
    @submitClicked="onAddClicked"
    @cancelClicked="onCancelClicked"
    :submitEnabled="true"
  >
    <v-form ref="form">
      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs12>
            <v-select
              placeholder="Select"
              v-model="selected"
              item-text="name"
              item-value="id"
              :items="remainingIntgrations"
              attach
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import Branches from "@/rest/branches";
import ModalDialog from "@/components/common/ModalDialog.vue";

const formDefaults = {};

export default {
  data() {
    return Object.assign(
      {
        dialogVisible: false,
        error: null,
        selected: null,
        remainingIntgrations: [],
        branchIntegrations: []
      },
      formDefaults
    );
  },

  components: {
    ModalDialog
  },

  props: { branchReferenceId: String },

  methods: {
    openDialog: async function() {
      this.selected = null;
      this.dialogVisible = true;
      await this.getAllBranchIntegrations();
      await this.getBranchIntegrations();
      this.removeExistingIntegrations();
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onAddClicked(){
      this.$emit("integrationAdded", this.selected);
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.dialogVisible = false;
    },
    async getAllBranchIntegrations() {
      try {
        const response = await Branches.getRestApi().getAllBranchIntegrations();
        this.remainingIntgrations = response.data;
      } catch (error) {
        this.showError(error);
      }
    },
    async getBranchIntegrations() {
      try {
        const response = await Branches.getRestApi().getBranchIntegrations(this.$props.branchReferenceId);
        this.branchIntegrations = response.data;
      } catch (error) {
        this.showError(error);
      }
    },
    hasExistingIntegration(item) {
      let hadIntegration = false;
      this.branchIntegrations.forEach(integration => {
        if (item.id == integration.id) {
          hadIntegration = true;
        }
      });
      return hadIntegration;
    },
    async removeExistingIntegrations() {
      let remaining = [];
      this.remainingIntgrations.forEach(integration => {
        if (!this.hasExistingIntegration(integration)) {
          remaining.push(integration);
        }
      });
      this.remainingIntgrations = remaining;
    }
  }
};
</script>
