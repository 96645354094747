import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";
import { ActivityLog } from "./interfaces/activityLogs";

/**
 * Vendor.
 */
export class Vendor {
  id: number;
  referenceId: string;
  name: string;
  address: string;
  city: string;
  phoneNumber: string;
  contactEmail: string;
  taxId: string;
  zipCode: string;

  constructor(
    id: number,
    referenceId: string,
    name: string,
    address: string,
    city: string,
    phoneNumber: string,
    contactEmail: string,
    taxId: string,
    zipCode: string
  ) {
    this.id = id;
    this.referenceId = referenceId;
    this.name = name;
    this.address = address;
    this.city = city;
    this.phoneNumber = phoneNumber;
    this.contactEmail = contactEmail;
    this.taxId = taxId;
    this.zipCode = zipCode;
  }
}

export interface ThirdPartyVendor {
  vendorId: string;
  name: string;
}

/**
 * REST interface.
 */
export interface VendorsRestApi {
  getVendor(refID: string): AxiosPromise<Vendor>;
  getAllVendors(): AxiosPromise<Vendor[]>;
  getActiveVendors(): AxiosPromise<Vendor[]>;
  getThirdPartyVendors(): AxiosPromise<ThirdPartyVendor[]>;
  getVendorsForJob(jobId: number): AxiosPromise<Vendor[]>;
  createVendor(payload: Vendor): AxiosPromise<Vendor>;
  updateVendor(refId: string, payload: Vendor): AxiosPromise<Vendor>;
  getVendorLogs(refId: string): AxiosPromise<ActivityLog[]>;
}

/**
 * REST implementation.
 */
class Implementation implements VendorsRestApi {
  getVendor(refId: string): AxiosPromise<Vendor> {
    return Axios.axiosInstance().get<Vendor>(`/vendors/${refId}`);
  }
  updateVendor(refId: string, updated: Vendor): AxiosPromise<Vendor> {
    return Axios.axiosInstance().put<Vendor>(`/vendors/${refId}`, updated);
  }
  createVendor(newVendor: Vendor): AxiosPromise<Vendor> {
    return Axios.axiosInstance().post<Vendor>("/vendors", newVendor);
  }
  getAllVendors(): AxiosPromise<Vendor[]> {
    return Axios.axiosInstance().get<Vendor[]>("/vendors/search/all");
  }
  getActiveVendors(): AxiosPromise<Vendor[]> {
    return Axios.axiosInstance().get<Vendor[]>("/vendors/search/active");
  }
  getThirdPartyVendors(): AxiosPromise<ThirdPartyVendor[]> {
    return Axios.axiosInstance().get<ThirdPartyVendor[]>("/vendors/search/thirdparty");
  }
  getVendorsForJob(jobId: number): AxiosPromise<Vendor[]> {
    return Axios.axiosInstance().get<Vendor[]>(`/vendors/job/${jobId}`);
  }
  getVendorLogs(refId: string): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/vendors/${refId}/logs`);
  }
}

const Vendors = {
  getRestApi(): VendorsRestApi {
    return new Implementation();
  }
};

export default Vendors;
