<template>
  <v-btn text :href="url" target="_blank" color="primary" :ripple="false">
    {{ fileName }}
  </v-btn>
</template>

<script>
export default {
  props: ["cellData", "fileIdProperty", "fileNameProperty"],

  data() {
    return {
      url: `/files/${
        this.cellData.data[this.fileIdProperty || "id"]
      }`,
      fileName: this.cellData.data[this.fileNameProperty || "fileName"]
    };
  }
};
</script>
