<template>
  <modal-dialog
      ref="dialog"
      title="Choose Catalog Items for Job"
      width="1200"
      :visible="dialogVisible"
      :submitEnabled="true"
      submitLabel="Done"
      cancelLabel="Cancel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <select-catalog-items-container
        ref="selectCatalogItems"
        :dataSource="dataSource"
        :branchId="branchId"
        @selectedItem="clearError"
        @showError="showError"
    />
    <p class="error-message">{{ error }}</p>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import SelectCatalogItemsContainer from "../../../components/catalogItems/SelectCatalogItemsContainer.vue";
import ODataContext from "../../../rest/odataContext";

const defaultCatalogItemsByBranchDataSource = {
  store: ODataContext['BranchProductCatalogSearchs'],
  select: [
    "Id",
    "InternalSku",
    "StyleName",
    "Color",
    "Description",
    "ProductType",
    "QuantityAvailable",
    "BranchId"
  ],
  sort: [{selector: "InternalSku", desc: false}],
  filter: null
};

export default {
  components: {
    ModalDialog,
    SelectCatalogItemsContainer,
  },

  props: {
    productItems: {
      type: Array,
      required: true
    },
    branchId: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      dialogVisible: false,
      alreadySelectedItems: [],
      selected: [],
      error: null,
      dataSource: null
    };
  },

  created() {
    this.setAlreadySelectedItems();
  },

  watch: {
    productItems() {
      this.setAlreadySelectedItems();
    }
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
      this.clearError();
      // get odata from here
      this.setDataSource();
    },
    setDataSource() {
      this.dataSource = Object.assign(defaultCatalogItemsByBranchDataSource);
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onSubmitClicked() {
      const selectedItems = this.$refs.selectCatalogItems.getSelectedItems();
      let isDuplicatedSelection = this.findDuplicatesIfJobItemsContainNewlySelectedItems(selectedItems);
      if (isDuplicatedSelection.length) {
        this.showError("This product(s): [ " + isDuplicatedSelection.join(", ") + " ] is already added to the job and is not fulfilled yet. Please change its quantity if you need more");
        return;
      }

      if (selectedItems.length) {
        this.$emit("itemsSelected", selectedItems);
        this.closeDialog();
        this.$refs.selectCatalogItems.reset();
      } else {
        this.error = "There are no items selected. Please select from above to add products to the job.";
      }
    },
    clearError() {
      this.$data.error = null;
    },
    onCancelClicked() {
      this.dialogVisible = false;
      this.$refs.selectCatalogItems.reset();
    },
    findDuplicatesIfJobItemsContainNewlySelectedItems(selectedItems) {
      let isDuplicatedSelection = [];
      selectedItems.forEach(justSelected => {
        this.alreadySelectedItems.forEach(alreadySelectedItem => {
          if (alreadySelectedItem.internalSku === justSelected.InternalSku && alreadySelectedItem.jobLineItemStatus === 'New') {
            isDuplicatedSelection.push(justSelected.InternalSku + ' - ' + justSelected.StyleName);
          }
        })
      });
      return isDuplicatedSelection;
    },
    setAlreadySelectedItems() {
      this.alreadySelectedItems = this.$props.productItems.map(value => {
        return {
          jobLineItemStatus: value.jobLineItem.status,
          internalSku: value.productCatalogItem.internalSku
        };
      });
    },
  }
};
</script>
