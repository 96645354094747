<template>
  <div>
    <v-text-field
      v-if="readonly || disabled"
      :readonly="readonly"
      :disabled="disabled"
      placeholder=" "
      persistent-placeholder
      :value="value"
      label="State"
      data-id="state-text-value"
    />
    <v-autocomplete
      v-if="!readonly && !disabled"
      v-model="selected"
      :items="states"
      :filter="filter"
      label="* State"
      :rules="[() => !!selected || 'Required']"
      :maxLength="2"
      placeholder=" "
      data-id="state-dropdown-menu"
      persistent-placeholder
      validate-on-blur
    />
  </div>
</template>

<script>
import UnitedStatesList from "../../../assets/unitedStatesList";

export default {
  components: {
    UnitedStatesList
  },

  data() {
    return { selected: null, states: [] };
  },

  props: {
    value: String,
    readonly: Boolean,
    disabled: Boolean
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("input", value);
    }
  },

  created() {
    this.selected = this.value;
    this.states = UnitedStatesList;
  },

  methods: {
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1);
    }
  }
};
</script>
