<template>
  <navigation-view
      ref="nav"
      page-title="Inventory Management"
      nav-section="orders"
  >
    <template v-slot:content>
      <v-container grid-list-lg column>
        <div class="headline">Check Out - Job Inventory</div>
        <v-divider/>
        <v-form
            ref="searchJobReferenceId"
            class="searchForm"
            onSubmit="return false;"
        >
          <v-row>
            <v-col md="3">
              <job-reference-id-search-field
                  @enterKeyPress="handleSearchJobReferenceId"
                  @input="handleInput"
              />
            </v-col>
            <v-btn
                class="mt-2 pa-5"
                color="primary"
                :disabled="!jobReferenceId"
                @keyup.enter.native="handleSearchJobReferenceId"
                @click="handleSearchJobReferenceId"
                id="search-job-reference-id-button"
            >Search
            </v-btn
            >
          </v-row>
          <span class="searchError">{{ searchError }}</span>
        </v-form>

        <v-row v-if="!loaded" column class="searchContainer">
          <v-col md="12">
            <v-icon color="primary" class="searchIcon">search</v-icon>
            <p class="subheading">
              Get started by searching for a job reference number
            </p>
          </v-col>
        </v-row>

        <v-row v-if="loaded">
          <v-col md="3">
            <v-text-field
                v-model="branchName"
                label="Branch Name"
                placeholder=" "
                readonly
            />
          </v-col>
          <v-col/>
          <v-col md="3">
            <v-text-field
                v-model="customerName"
                label="Customer"
                placeholder=" "
                readonly
            />
          </v-col>
          <v-col md="3">
            <v-text-field
                v-model="customerAddress"
                label="Customer Address"
                placeholder=" "
                readonly
            />
          </v-col>
        </v-row>

        <v-row v-if="loaded">
          <v-col md="12">
            <v-card raised light color="white" class="mt-3 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Job Items</v-app-bar-title>
              </v-app-bar>
              <inventory-check-out-job-items
                  :jobItems="jobItems"
                  @jobItemClicked="handleJobItemClicked"
              />
            </v-card>
          </v-col>

          <v-col md="12">
            <v-card raised light color="white" class="mt-3 mb-2 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Check Out Inventory</v-app-bar-title>
              </v-app-bar>
              <inventory-check-out-selected-items
                  :selectedItems="selectedItems"
                  @deleteItem="onDeleteItem"
              />
            </v-card>
          </v-col>

          <v-col class="d-flex justify-end">
            <span class="submitError">{{ submitError }}</span>
            <v-btn
                large
                color="primary"
                class="checkOutButton"
                :disabled="!selectedItems.length"
                :loading="inProgress"
                @click="handleCheckOutInventory"
            >
              Check Out
            </v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import InventoryItems from "../../rest/inventoryItems.ts";
import Jobs from "../../rest/jobs.ts";
import InventoryCheckOutJobItems from "../../components/inventoryManagement/InventoryCheckOutJobItems.vue";
import InventoryCheckOutSelectedItems from "../../components/inventoryManagement/InventoryCheckOutSelectedItems.vue";
import JobReferenceIdSearchField from "../../components/searchFields/JobReferenceId.vue";
import Rules from "../../assets/rules";

export default {
  components: {
    NavigationView,
    InventoryCheckOutJobItems,
    InventoryCheckOutSelectedItems,
    JobReferenceIdSearchField
  },

  data() {
    return {
      jobReferenceId: null,
      job: null,
      customerName: null,
      customerAddress: null,
      installerName: null,
      branchName: null,
      jobItems: null,
      branchReferenceId: null,
      selectedItems: [],
      searchError: null,
      submitError: null,
      loaded: false,
      inProgress: false,
      rules: {
        required: Rules.required,
      }
    };
  },

  mounted() {
    if (this.$route.query.referenceId) {
      this.jobReferenceId = this.$route.query.referenceId;
      this.proceedAutoFill();
    }
  },

  methods: {
    async handleSearchJobReferenceId() {
      try {
        this.clearSearchError();
        const data = await this.getJobDataWithLineItems();
        this.setResponseData(data);
      } catch (error) {
        this.showSearchError();
      }
    },
    showSearchError() {
      this.searchError = `Job Reference ID ${this.jobReferenceId} cannot be found. Please check the ID and try again.`;
      this.job = null;
    },
    clearSearchError() {
      this.searchError = null;
    },
    async getJobDataWithLineItems() {
      if (!this.jobReferenceId) {
        this.clearJobItemsTableData();
      }
      try {
        const valid = this.$refs.searchJobReferenceId.validate();
        if (valid && this.jobReferenceId) {
          const response = await Jobs.getRestApi().getJobProductLineItemsInventory(this.jobReferenceId);
          this.jobItems = response.data.productSummaries;
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    },
    setResponseData(data) {
      if (data) {
        this.job = data.job;
        this.customerName = data.customerName;
        this.customerAddress = data.customerAddress;
        this.branchName = data.branchName;
        this.loaded = true;
      }
    },
    handleJobItemClicked(selectedRow) {
      this.clearSubmitError();
      if (!this.ifRowAlreadySelected(selectedRow)) {
        const {selectedItems} = this.$data;
        const row = {...selectedRow};
        row.quantity = row.onHand;
        selectedItems.push(row);
      } else {
        const {styleName} = selectedRow.productCatalogItem;
        this.$data.submitError = `Inventory item ${styleName} has already been selected to check out.`;
      }
    },
    ifRowAlreadySelected(selectedRow) {
      const {selectedItems} = this.$data;
      for (let i = 0; i < selectedItems.length; i += 1) {
        if (selectedItems[i].jobProductLineItem.referenceId === selectedRow.jobProductLineItem.referenceId) {
          return true;
        }
      }
      return false;
    },
    async proceedAutoFill() {
      try {
        const response = await Jobs.getRestApi().getJobProductLineItemsInventory(this.jobReferenceId);
        this.jobItems = response.data.productSummaries;
        this.setResponseData(response.data);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    onDeleteItem(lineItemReferenceId) {
      this.clearSubmitError();
      this.selectedItems = this.selectedItems.filter(item => {
        return item.jobProductLineItem.referenceId !== lineItemReferenceId;
      });
    },
    clearSubmitError() {
      this.submitError = null;
    },
    async handleCheckOutInventory() {
      this.inProgress = true;
      try {
        this.clearSubmitError();
        let lineItems = [];
        this.selectedItems.forEach(item => {
          lineItems.push({
            jobProductLineItemId: item.jobProductLineItem.id
          });
        });
        await this.processCheckout(lineItems);
        await this.handleSuccessCheckOutInventory();
      } catch (error) {
        this.handleFailCheckInInventory(error);
      }
      this.inProgress = false;
    },
    async processCheckout(lineItems) {
      try {
        await InventoryItems.getRestApi().inventoryItemsCheckOut(lineItems);
      } catch (error) {
        throw error;
      }
    },
    handleFailCheckInInventory(error) {
      this.inProgress = false;
      this.$refs.nav.showError(error);
    },
    async handleSuccessCheckOutInventory() {
      try {
        this.$refs.nav.showSuccess("Checked Out Inventory Successfully!");
        this.selectedItems = [];
        // refreshes just the inventory table so on hand reflects the saved, correct amount
        await this.getJobDataWithLineItems();
      } catch (error) {
        throw error;
      }
    },
    handleInput(jobReferenceId) {
      this.jobReferenceId = jobReferenceId;
      if (this.job) {
        this.selectedItems = [];
        this.handleSearchJobReferenceId();
      }
    },
    clearJobItemsTableData() {
      this.job = null;
      this.customerName = null;
      this.customerAddress = null;
      this.branchName = null;
      this.loaded = false;
      this.clearSearchError();
    }
  }
};
</script>

<style scoped>
.searchForm {
  padding-top: 60px;
}
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}
.checkOutButton {
  width: 100px;
}
.searchIcon {
  font-size: 150px;
}
.searchContainer {
  padding-top: 10px;
  padding-bottom: 60px;
}
.searchError,
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
