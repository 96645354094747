<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-row class="mt-1">
        <v-col md="5">
          <v-text-field
              v-model="totalIssueAmount"
              label="Total Issue Amount"
              placeholder="0.00"
              prefix="$"
              readonly
              hide-details
          />
        </v-col>
        <v-col
            v-if="showInstallerPayableColumn"
            :md="isMultiplierRequired ? 3 : 6"
            :offset="1"
        >
          <v-text-field
              v-model="installerPayable"
              :label="'* Installer Payable'"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              type="number"
              :rules="[rules.installerPayableAmountRule, rules.isCurrencyFormat, rules.required]"
          />
        </v-col>
        <v-col md="3" v-if="showScgPayableColumn">
          <v-text-field
              v-model="installerPayableMultiplied"
              label="SCG payable"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              type="number"
              readonly
              hint="SCG payable will be added to the labor line item’s payable"
              persistent-hint
          />
        </v-col>
      </v-row>

      <v-row class="pt-1 pb-1">
        <v-col md="6">
          <radio-buttons
              class="radio-buttons"
              :selectOptions="selectOptions"
              :label="'Is part or all of the issue amount a Customer Sat?'"
              @onValueChanged="customerSatisfactionValueChanged"
          />
          <v-text-field
              v-show="customerSatisfaction"
              v-model="customerSatisfactionAmount"
              :label="customerSatisfaction ? '* Customer Sat Amount' : 'Customer Sat Amount'"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              :rules="[customerSatisfactionRequired, rules.isCurrencyFormat]"
              hide-details
              type="number"
          />
        </v-col>
      </v-row>

      <v-row class="pt-4 pb-4">
        <v-col md="5">
          <v-text-field
              v-model="amountAddedContractPrice"
              label="Amount added to Customer Contract"
              placeholder="0.00"
              prefix="$"
              readonly
              hide-details
          />
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>

<script>

import Rules from "../../../assets/rules";
import RadioButtons from "../../common/form/RadioButtons.vue";
import Constants from "../../../assets/constants";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";

const formDefaults = {
  customerSatisfaction: false,
  customerSatisfactionAmount: null
};

export default {
  components: {RadioButtons},

  props: {
    totalIssueAmount: {
      type: Number,
      required: true
    },
    laborAssociatedAmount: {
      type: Number | null,
      required: true
    },
    isMultiplierRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return Object.assign(
        {},
        {
          installerPayable: null,
          scgPayable: null,
          error: null,
          selectOptions: Constants.defaultSelectOptionsWithKey,
          rules: {
            isCurrencyFormat: Rules.isCurrencyFormat,
            required: Rules.required,
            installerPayableAmountRule: () => {
              return Rules.installerPayableAmountRule(Number(this.installerPayable), 0, this.laborAssociatedAmount, "The installer payable");
            }
          },
        },
        formDefaults
    );
  },

  computed: {
    customerSatisfactionRequired() {
      if (this.customerSatisfaction) {
        return !!this.customerSatisfactionAmount || "Required";
      }
      return true;
    },
    amountAddedContractPrice() {
      let totalIssueAmount = this.totalIssueAmount || 0.0;
      let customerSatisfactionAmount = this.customerSatisfactionAmount || 0.0;
      return (totalIssueAmount - customerSatisfactionAmount).toFixed(2);
    },
    installerPayableMultiplied() {
      return this.adjustScgPayableAmount(this.installerPayable);
    },
    showInstallerPayableColumn() {
      return this.laborAssociatedAmount != null;
    },
    showScgPayableColumn() {
      return this.isMultiplierRequired && this.laborAssociatedAmount != null;
    }
  },

  watch: {
    customerSatisfaction(newSat) {
      if (newSat === false) {
        this.customerSatisfactionAmount = null;
      }
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.loadForm(formDefaults);
      this.$refs.form.resetValidation();
    },
    getPayload() {
      const payload = {};

      if (this.customerSatisfaction) {
        payload.customerSatisfaction = true;
        payload.customerSatisfactionAmount = this.customerSatisfactionAmount;
      } else {
        payload.customerSatisfaction = false;
        payload.customerSatisfactionAmount = null;
      }
      payload.contractAdjustment = this.amountAddedContractPrice;
      payload.installerPayable = Number(this.installerPayable);
      payload.scgPayable = this.isMultiplierRequired
          ? this.adjustScgPayableAmount(this.installerPayable)
          : Number(CurrencyFormatter.zeroCurrency());
      return payload;
    },
    adjustScgPayableAmount(value) {
      return Number(value * 1.5).toFixed(2);
    },
    customerSatisfactionValueChanged(value) {
      this.customerSatisfaction = value;
    }
  },
};
</script>

<style scoped>
.checkbox-container.v-input--selection-controls {
  padding: 0;
  margin: 0;
}
.contract-prices {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 5px;
}
.radio-buttons {
  margin-top: 0;
}
</style>
