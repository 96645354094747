<template>
  <v-dialog :scrollable="scrollable" v-model="visible" persistent :width="width" eager
            :content-class="contentClass ? contentClass : ''">
    <v-card light>
      <v-app-bar dense flat dark class="mb-0" color="primary">
        <v-app-bar-title class="ml-5">
          <v-icon v-if="icon" class="mr-1">{{ icon }}</v-icon>
          {{ title }}
        </v-app-bar-title>
      </v-app-bar>
      <v-snackbar :timeout="5000" error v-model="errorDisplayed">
        {{ error }}
        <v-btn dark text @click="errorDisplayed = false">
          Close
        </v-btn>
      </v-snackbar>
      <v-card-text class="mt-2" :style="modalBodyHeight">
        <slot>
          <div>
            {{ message || "Your content goes here!" }}
          </div>
        </slot>
      </v-card-text>
      <v-card-actions v-if="!hideButtons" class="pb-5 mr-5" :class="{'mt-5': scrollable}">
        <v-spacer></v-spacer>
        <v-btn
            v-show="!hideCancel && !loading"
            primary
            outlined
            color="primary"
            @click="onCancelClicked"
        >
          {{ cancelLabel || "Cancel" }}
        </v-btn>
        <slot name="buttons"/>
        <v-btn
            v-if="extraLabel"
            primary
            color="primary"
            @click="onExtraButtonClicked"
        >
          {{ extraLabel }}
        </v-btn>
        <v-btn
            data-cy="buttonErrorSubmit"
            v-show="!hideSubmit"
            color="primary"
            :disabled="!submitEnabled"
            :loading="loading"
            @click="onSubmitClicked"
        >
          {{ submitLabel || "Submit" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {},

  data() {
    return {
      errorDisplayed: false,
      error: null
    };
  },

  props: {
    icon: {},
    title: {},
    width: {},
    minHeight: {},
    visible: Boolean,
    cancelLabel: {},
    submitLabel: {},
    extraLabel: String,
    hideButtons: [Boolean],
    submitEnabled: [Boolean, String],
    hideCancel: Boolean,
    hideSubmit:  [Boolean, String],
    loading: Boolean,
    message: {},
    contentClass: {},
    scrollable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modalBodyHeight() {
      return "min-height: " + this.minHeight || 'auto';
    }
  },

  methods: {
    onCancelClicked(e) {
      this.$emit('cancelClicked', e);
    },
    onExtraButtonClicked(e) {
      this.$emit('onExtraButtonClicked', e);
    },
    onSubmitClicked(e) {
      this.$emit('submitClicked', e);
    },
    showError(error) {
      this.error = error;
      this.errorDisplayed = true;
    },
  },
};
</script>
