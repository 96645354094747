<template>
  <v-layout class="star-rating" row wrap>
    <label class="star-rating-label">Rating</label>
    <div class="star-rating-field">
      <v-rating v-model="rating" :readonly="readonly"></v-rating>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: ["readonly", "value"],

  data() {
    return {
      rating: 5
    };
  },

  watch: {
    value: function(newVal) {
      this.rating = newVal;
    }
  }
};
</script>

<style scoped>
.star-rating {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.star-rating-label {
  color: rgba(0, 0, 0, 0.54);
  align-self: flex-start;
  font-size: 12px;
  margin-left: 8px;
  margin-top: -8px;
}
.v-rating {
  min-width: 190px;
}
</style>
