<template>
  <div>
    <job-issues-buttons
      :job="job"
      :remainingBalance="remainingBalance"
      @customerBadDebt="handleOpen('customerBadDebtDialog')"
      @discountJob="handleOpen('discountJobWizard')"
      @installercb="handleOpen('installerChargebackWizard')"
      @vendorcb="handleOpen('vendorChargebackWizard')"
      @installIssue="handleOpen('installIssueWizard')"
      @refund="handleOpen('refundWizard')"
      @emitError="emitError"
    />
    <customer-bad-debt-dialog
      ref="customerBadDebtDialog"
      :customerRemainingTotal="customerRemainingTotal"
    />
    <discount-job-wizard
      ref="discountJobWizard"
      :contractPrice="contractPrice"
      :jobReferenceId="job.referenceId"
      :wsSessionId="wsSessionId"
      @emitError="emitError"
      @submitDiscount="onSubmitDiscount"
    />
    <install-issue-wizard
      ref="installIssueWizard"
      :contractPrice="contractPrice"
      :job="job"
      @submitSuccess="onSubmitInstallationIssue"
    />
    <installer-chargeback-wizard
      ref="installerChargebackWizard"
      :job="job"
      :productItems="productItems"
      :laborItems="laborItems"
      @submitChargeback="onSubmitChargeback"
    />
    <vendor-chargeback-wizard
      ref="vendorChargebackWizard"
      :job="job"
      :productItems="productItems"
      :laborItems="laborItems"
      @submitChargeback="onSubmitChargeback"
    />
    <refund-wizard
      ref="refundWizard"
      :job="job"
      :remainingBalance="remainingBalance"
      @submitRefund="onSubmitRefund"
    />
  </div>
</template>

<script>
import Currency from "currency.js";
import CustomerBadDebtDialog from "../../../components/customers/badDebtDialog/BadDebtContainer.vue";
import InstallerChargebackWizard from "../../../components/jobs/installerChargebackWizard/WizardContainer.vue";
import VendorChargebackWizard from "../../../components/jobs/vendorChargebackWizard/WizardContainer.vue";
import DiscountJobWizard from "../../../components/jobs/discountJobWizard/WizardContainer.vue";
import InstallIssueWizard from "../../../components/jobs/installationIssueWizard/WizardContainer.vue";
import RefundWizard from "../../../components/jobs/refundWizard/RefundWizardContainer.vue";
import JobIssuesButtons from "./JobIssuesButtons";

export default {
  components: {
    JobIssuesButtons,
    CustomerBadDebtDialog,
    DiscountJobWizard,
    InstallerChargebackWizard,
    VendorChargebackWizard,
    InstallIssueWizard,
    RefundWizard,
  },

  props: {
    job: Object,
    remainingBalance: Number,
    productItems: Array,
    laborItems: Array,
    wsSessionId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      contractPrice: null,
      customerRemainingTotal: null,
    };
  },

  watch: {
    job: {
      immediate: true,
      handler(newVal) {
        this.contractPrice = Currency(newVal.contractPrice);
      },
    },
    remainingBalance: {
      immediate: true,
      handler(newVal) {
        this.customerRemainingTotal = Currency(newVal);
      },
    },
  },

  methods: {
    handleOpen(reference) {
      this.$refs[reference].openDialog();
    },
    emitError(message) {
      this.$emit("emitError", message);
    },
    onSubmitDiscount() {
      this.$emit('submitDiscount');
    },
    onSubmitChargeback(payload) {
      this.$emit("submitChargeback", payload);
    },
    onSubmitInstallationIssue(payload) {
      this.$emit("submitInstallationIssue", payload);
    },
    onSubmitRefund(payload) {
      this.$emit("submitRefund", payload);
    },
  },
};
</script>
