<template>
  <v-container class="mt-0 pt-0 pb-0 pt-0">
    <v-radio-group
        class="mt-1"
        v-model="selected"
        column
        @change="handleSelectionChange"
    >
      <v-radio
          class="mt-1 mb-1"
          v-for="option in options"
          :disabled="disabledOption(option)"
          :key="option.value"
          :label="option.label"
          :value="option.value"
      />
    </v-radio-group>
  </v-container>
</template>

<script>

import Constants from "../../assets/constants";

export default {
  props: {
    onHand: Number,
    available: Number,
    hasJobReservationsToMove: Boolean
  },

  data() {
    return {
      selected: null,
      options: Constants.moveItemToAnotherBinOptions
    };
  },

  methods: {
    backToDefaults() {
      this.selected = null;
    },
    handleSelectionChange() {
      this.$emit('checkboxChanged', this.selected);
    },
    disabledOption(option) {
      switch (option.value) {
        case 2:
          if (this.available === 0 && this.onHand > this.available) {
            return true;
          }
          break;
        case 3:
          if ((this.onHand !== 0 && this.available !== 0 && this.onHand === this.available) || !this.hasJobReservationsToMove) {
            return true;
          }
          break;
        default:
          return false;
      }
    },

  }
};
</script>

<style>
.v-radio {
  margin-left: 0 !important;
}
</style>
