<template>
  <modal-dialog
      ref="dialog"
      :title="title"
      :width="defaultWidth"
      :visible="dialogVisible"
      :minHeight="minHeight"
      :submitLabel="submitLabel"
      cancelLabel="Cancel"
      @submitClicked="onSaveDistributionVendorClick"
      @cancelClicked="onCancelClicked"
      :submitEnabled="isModalActive"
      :loading="loading"
  >
    <distribution-vendor-form
        ref="distributionVendorForm"
        :isAddForm="isAddForm"
        :isModalActive="isModalActive"
        :defaultActiveVendors="defaultActiveVendors"
        :selectedBranchesFromGrid="selectedBranchesFromGrid"
        :selectedDistributionVendorsFromGrid="selectedVendorsFromGrid"
        :defaultBranches="defaultBranches"
        :selectedBranches="selectedBranches"
        :selectedVendor="vendorSelected"
        :vendors="vendorManagementVendors"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../ModalDialog.vue";
import DistributionVendorForm from "../../../branches/DistributionVendorForm.vue";

const formDefaults = {
  dialogVisible: false,
  loading: false,
  error: null,
  defaultWidth: '1200px',
  minHeight: '500px',
  isAddForm: false
};

export default {
  components: {
    DistributionVendorForm,
    ModalDialog,
  },

  props: {
    vendorSelected: {
      type: Object,
      required: false,
    },
    defaultActiveVendors: {
      type: Array,
      required: true
    },
    defaultBranches: {
      type: Array,
      required: true
    },
    selectedBranches: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedBranchesFromGrid: {
      type: Array,
      required: false
    },
    selectedVendorsFromGrid: {
      type: Array,
      required: false
    },
    vendorManagementVendors: {
      type: Array,
      required: true
    }
  },

  data() {
    return Object.assign(
        {},
        formDefaults
    );
  },

  computed: {
    title() {
      return !this.isAddForm && this.vendorSelected ? `Edit Distribution Vendor: ${this.vendorSelected.name}` : 'Add Distribution Vendor for specific Branches';
    },
    submitLabel() {
      return !this.isAddForm && this.vendorSelected ? 'Save' : 'Save Distribution Vendor(s)';
    },
    isModalActive() {
      return this.vendorManagementVendors.length > 0
    }
  },

  methods: {
    restoreDefaults() {
      Object.assign(this.$data, formDefaults);
    },
    resetForm() {
      if (this.$refs) {
        this.$refs.distributionVendorForm.resetValidation();
      }
      this.loading = false;
    },
    openDialog(selectedVendor, selectedBranches, isAddForm) {
      this.dialogVisible = true;
      this.isAddForm = isAddForm;
      this.selectedVendor = selectedVendor;
      this.selectedBranches = selectedBranches;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$emit('onDialogClosed');
    },
    showError(error) {
      this.error = error;
    },
    setLoading(value) {
      this.loading = value;
    },
    onSaveDistributionVendorClick() {
      const valid = this.$refs.distributionVendorForm.validateForm();
      if (valid) {
        let data = this.$refs.distributionVendorForm.getFormData();
        this.loading = true;
        if (data && this.isAddForm) {
          this.$emit('onSaveDistributionVendor', data);
        } else if (data && !this.isAddForm) {
          let firstData = data[0];
          // OAS-1895 for some reason after cancelling editing distribution vendor, re-open and save,
          // it fails because vendor in form is not set (vendor-autocomplete watcher is not triggered
          // second time for some reason), TODO fix it better
          if (!firstData.vendor) {
            firstData.vendor = this.selectedVendor;
          }
          this.$emit('onUpdateDistributionVendor', firstData);
        }
      }
    },
    onCancelClicked() {
      let selectedVendors = this.$refs.distributionVendorForm.getSelectedVendorsFromRows();
      if (this.isAddForm) {
        this.$refs.distributionVendorForm.filterOutAvailableVendors(selectedVendors, false);
        this.$emit('onDialogClosed');
      }
      this.restoreDefaults();
      this.resetDistributionVendorForm();
      this.$emit('onEditFormClosed')
    },
    resetDistributionVendorForm() {
      this.$refs.distributionVendorForm.resetRowsToDefault();
    }
  }
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  margin-top: 50px;
}
</style>
