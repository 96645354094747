<template>
  <v-row>
    <v-container fluid id="transfer-request-details">
      <v-row>
        <v-col cols="2">
          <text-field-with-copy-to-clip-board
              :value="transferRequest.transferNumber"
              label="Transfer Request Number"
              placeholder=" "
              persistent-placeholder
              :readonly="true"
              id="transfer-request-details-transfer-request-number"
          />
        </v-col>
        <v-col cols="3">
          <status-icon-cell-template
              :statusName="transferRequest.status"
          />
        </v-col>
        <v-col/>
        <v-col v-if="transferRequest.closedDate" cols="2">
          <v-text-field
              :value="formatDate(transferRequest.closedDate)"
              label="Request Closed Date"
              placeholder=" "
              persistent-placeholder
              readonly
              id="transfer-request-details-transfer-request-clsoed-date"
          />
        </v-col>
      </v-row>
      <v-divider class="detailsDivider"/>

    </v-container>

    <v-col cols="4">
      <v-text-field
          :value="formatDate(transferRequest.creationDate)"
          label="Creation Date"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-created-at"
      />
    </v-col>
    <v-col cols="4">
      <read-only-text-field-with-hover-and-link-cell-template
          :show="showTextField"
          :value="transferRequest.shipmentNumber"
          :link="navLink"
          label="Shipment Number"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
          v-if="transferRequest.jobReferenceId"
          :value="transferRequest.jobReferenceId"
          label="Job Reference Id"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-job-ref-id"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
          :value="transferRequest.transferFrom"
          label="Transfer From (Branch)"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-from-branch"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
          v-if="transferRequest.shipmentNumber"
          :value="formatDate(transferRequest.pickUpDate)"
          label="Pickup Date"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-closest-pickup-date"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
          v-if="transferRequest.jobReferenceId"
          :value="formatDate(transferRequest.closestInstallationDate)"
          label="Closest Installation Date"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-closest-installation-date"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
          :value="transferRequest.transferTo"
          label="Transfer To (Branch)"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-transfer-to-branch"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
          v-if="transferRequest.shipmentNumber"
          :value="formatDate(transferRequest.estimatedDeliveryDate)"
          label="Estimated Delivery Date"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-estimated-delivery-date"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
          v-if="transferRequest.jobReferenceId"
          :value="transferRequest.customerName"
          label="Customer Name"
          placeholder=" "
          persistent-placeholder
          readonly
          id="transfer-request-details-customer-name"
      />
    </v-col>

  </v-row>
</template>

<script>
import StatusIconCellTemplate from "../../components/common/grid/cellTemplates/StatusIconCellTemplate.vue";
import ReadOnlyTextFieldWithHoverAndLinkCellTemplate
  from "../../components/common/grid/cellTemplates/ReadOnlyTextFieldWithHoverAndLinkCellTemplate.vue";
import DateFormatter from "@/assets/dateFormatter";
import TextFieldWithCopyToClipBoard from "@/components/common/templates/TextFieldWithCopyToClipBoard.vue";

export default {
  components: {
    TextFieldWithCopyToClipBoard,
    ReadOnlyTextFieldWithHoverAndLinkCellTemplate,
    StatusIconCellTemplate
  },

  props: {
    transferRequest: {}
  },

  data() {
    return {};
  },

  computed: {
    showTextField() {
      return !!(this.transferRequest.shipmentNumber);
    },
    navLink() {
      return `/inventory/shipment/${this.transferRequest.shipmentNumber}`;
    }
  },

  methods: {
    formatDate(date) {
      return DateFormatter.formatDateInMMDDYYYYFormat(date);
    }
  }

};
</script>
