<template>
  <inline-grid
      ref="inlineGrid"
      :data-source="productType.productSubtypes"
      :show-borders="false"
      :hoverStateEnabled="true"
      @rowSelected="onRowSelected"
  >
    <DxPaging :page-size="10"/>
    <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        :hoverStateEnabled="true"
    />
    <dx-column
        data-field="createdAt"
        width="20%"
        data-type="datetime"
        caption="Date"
        sortOrder="desc"
        format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
        data-field="name"
        width="50%"
        caption="Name"
    />
    <dx-column
        data-field="description"
        width="50%"
        caption="Description"
    />
    <template v-slot:footer>
      <inline-grid-footer>
        <inline-grid-button
            v-if="showButton"
            label="Add Subtype"
            @click="onAddSubtype"
        />
      </inline-grid-footer>
    </template>

    <create-subtype-dialog
        ref="addSubtypeDialog"
        :productType="productType"
        @cancelClicked="onCancelClicked"
        @subtypeAdded="onSubtypeAdded"
        @updateErrorOccurred="onUpdateErrorOccurred"
    >
    </create-subtype-dialog>
  </inline-grid>
</template>

<script>
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import {DxColumn, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";
import CreateSubtypeDialog from "@/views/productSubtypes/CreateSubtypeDialog";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter";
import InlineGridButton from "@/components/common/grid/InlineGridButton";
import Roles from "@/assets/roles";


export default {
  components: {
    InlineGrid,
    DxColumn,
    DxPager,
    DxPaging,
    InlineGridFooter,
    InlineGridButton,
    CreateSubtypeDialog
  },

  props: {
    productType: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      pageSizes: [5, 10, 20]
    };
  },

  computed: {
    showButton() {
      return this.isAllowed();
    }
  },

  methods: {
    onRowSelected(data) {
      if (this.isAllowed()) {
        let changeSubtype = {data};
        this.$refs.addSubtypeDialog.openSubtypesDialog(changeSubtype);
      }
    },
    onAddSubtype() {
      this.$refs.addSubtypeDialog.openSubtypesDialog();
    },
    onSubtypeAdded() {
      this.$emit('needsRefresh');
    },
    onUpdateErrorOccurred(error) {
      this.$emit('onSubmitFailed', error);
    },
    isAllowed() {
      return !Roles.isBranchAdmin();
    },
    onCancelClicked() {
      this.$refs.inlineGrid.clearSelection();
    }
  }
};
</script>
