<template>
  <v-row>
    <v-col md="12" v-if="isMigratedItem">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Vendor Management
          </v-expansion-panel-header>
          <v-expansion-panel-content key="vendor">
            <vendor-management
                :catalog-item-id="catalogItemId"
                :isAddButtonDisplayed="isAddButtonDisplayed"
                :vendorManagementItems="vendorManagementItems"
                :activeVendors="activeVendors"
                @onVendorCostError="showError"
                @onVendorCostAdded="handleVendorCostAdded"
                @onVendorCostUpdated="handleVendorCostUpdated"
                @onVendorCostDeleted="handleVendorCostDeletion"
                @onVendorSkuUpdated="handleVendorSkuUpdated"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12" v-if="isMigratedItem">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Distribution Vendors
          </v-expansion-panel-header>
          <v-expansion-panel-content key="vendor">
            <distribution-vendor-grid
                ref="distributionVendorsGrid"
                :catalogItemId="catalogItemId"
                :internalSku="internalSku"
                :defaultActiveVendors="activeVendors"
                :distributionVendors="distributionVendors"
                :isAddDistributionVendorButtonVisible="isAddDistributionVendorButtonVisible"
                :vendorManagementVendors="vendorManagementVendors"
                @vendorError="showError"
                @onDistributionVendorAdded="handleDistributionVendorAdded"
                @onDistributionVendorUpdated="handleDistributionVendorUpdated"
                @onDistributionVendorDeleted="handleDistributionVendorDeleted"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            {{ costsTabName }}
          </v-expansion-panel-header>
          <v-expansion-panel-content key="costs">
            <catalog-vendor-costs
                ref="catalogVendorCostsSection"
                :catalog-item-id="catalogItemId"
                :internalSku="internalSku"
                :overbill="overbill"
                :isAddButtonDisplayed="isAddButtonDisplayed"
                :isMigratedItem="isMigratedItem"
                @catalogVendorCostsError="showError"
                @catalogVendorCostChanged="handleVendorCostChanged"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12" v-if="!isMigratedItem">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Overbill
          </v-expansion-panel-header>
          <v-expansion-panel-content key="overbill">
            <catalog-overbill-entries
                ref="catalogOverbillEntriesSection"
                :catalog-item-id="catalogItemId"
                :overbill="overbill"
                :isAddButtonDisplayed="isAddButtonDisplayed"
                :isMigratedItem="isMigratedItem"
                @catalogVendorCostsError="showError"
                @catalogOverbillChanged="handleOverbillChanged"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Associated Products
          </v-expansion-panel-header>
          <v-expansion-panel-content key="associated">
            <catalog-item-associated-items
                :catalog-item-id="catalogItemId"
                :internalSku="internalSku"
                :isMigratedItem="isMigratedItem"
                :isAddButtonDisplayed="isAddButtonDisplayed"
                @associatedItemsError="showError"
                @associatedItemsChanged="refreshActivityLog"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Activity Logs
          </v-expansion-panel-header>
          <v-expansion-panel-content key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import CatalogItemAssociatedItems from "../../components/catalogItems/CatalogItemAssociatedItems";
import CatalogVendorCosts from "../../components/catalogItems/CatalogVendorCostsContainer";
import CatalogItems from "../../rest/catalogItems.ts";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import Constants from "../../assets/constants";
import Roles from "../../assets/roles";
import VendorManagement from "../../components/catalogItems/VendorManagement.vue";
import DistributionVendors from "../../rest/distributionVendors";
import CatalogOverbillEntries from "../../components/catalogItems/CatalogOverbillEntriesContainer";
import Vendors from "../../rest/vendors";
import DistributionVendorGrid from "../../components/common/inlineGrids/DistributionVendorGrid.vue";

export default {
  components: {
    VendorManagement,
    DistributionVendorGrid,
    ActivityLogsGrid,
    CatalogItemAssociatedItems,
    CatalogVendorCosts,
    CatalogOverbillEntries
  },

  props: {
    catalogItemId: {  // adjust logic use internalSku prop instead
      type: Number,
      required: true,
    },
    internalSku: {
      type: String,
      required: true,
    },
    overbill: {
      type: Number,
      required: false,
    },
    vendorManagementItems: {
      type: Array,
      required: false,
    },
    isMigratedItem: {
      type: Boolean,
      required: true
    }
  },

  created() {
    this.getActiveVendors();
  },

  data() {
    return {
      activityLogs: [],
      expanded: Constants.expandedByDefault,
      distributionVendorAddedSuccessMessage: 'New Distribution Vendor has been successfully added',
      distributionVendorUpdatedSuccessMessage: 'Existing Distribution Vendor has been successfully updated',
      distributionVendorDeletedSuccessMessage: 'Distribution Vendor has been successfully deleted',
      distributionVendors: [],
      activeVendors: [],
      isAddDistributionVendorButtonVisible: false
    };
  },

  watch: {
    internalSku: {
      immediate: true,
      handler() {
        this.getDistributionVendors();
      }
    }
  },

  computed: {
    isAddButtonDisplayed() {
      return !Roles.isBranchAdmin();
    },
    costsTabName() {
      return this.isMigratedItem ? 'Rep Price' : 'Vendor Cost'
    },
    vendorManagementVendors() {
      return this.vendorManagementItems
          .filter(item => item.vendor)
          .map(item => item.vendor);
    },
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    refreshDistributionVendorsGrid() {
      if (this.$refs.distributionVendorsGrid) {
        this.getDistributionVendors();
      }
    },
    showError(error) {
      this.$emit('showError', error);
    },
    async getActivityLogs() {
      try {
        const response = await CatalogItems.getRestApi().getCatalogItemLogs(this.catalogItemId);
        this.activityLogs = response.data;
      } catch (error) {
        this.showError(error);
      }
    },
    async getDistributionVendors() {
      try {
        const response = await DistributionVendors.getRestApi().getDistributionVendorsByCatalogItemInternalSku(this.internalSku);
        this.distributionVendors = response.data;
        this.isAddDistributionVendorButtonVisible = true;
      } catch (error) {
        this.showError(error);
      }
    },
    async getActiveVendors() {
      await Vendors.getRestApi()
          .getActiveVendors()
          .then((response) => {
            this.activeVendors = response.data;
          }).catch((error) => {
            this.emitError(error);
          });
    },
    handleVendorCostAdded(message) {
      this.$emit('onVendorAdded', message);
      this.refreshActivityLog();
    },
    handleVendorCostUpdated(message) {
      this.$emit('onVendorUpdated', message);
      this.refreshActivityLog();
    },
    async handleVendorCostDeletion(message) {
      this.$emit('onVendorDeleted', message);
      this.refreshActivityLog();
    },
    handleVendorSkuUpdated(message) {
      this.$emit('onVendorSkuUpdated', message);
      this.refreshActivityLog();
    },
    handleVendorCostChanged() {
      this.refreshActivityLog();
      this.$emit('pricingUpdated');
    },
    handleDistributionVendorAdded() {
      this.$emit('onVendorAdded', this.distributionVendorAddedSuccessMessage);
      this.refreshDistributionVendorsGrid();
      this.refreshActivityLog();
    },
    handleDistributionVendorUpdated() {
      this.$emit('onVendorUpdated', this.distributionVendorUpdatedSuccessMessage);
      this.refreshDistributionVendorsGrid();
      this.refreshActivityLog();
    },
    handleDistributionVendorDeleted() {
      this.$emit('onVendorDeleted', this.distributionVendorDeletedSuccessMessage);
      this.refreshDistributionVendorsGrid();
      this.refreshActivityLog();
    },
    // for old logic
    handleOverbillChanged() {
      this.refreshActivityLog();
      this.$emit("pricingUpdated");
    },
  },
};
</script>
