<template>
  <v-dialog v-model="wizardVisible" :width="800">
    <v-app-bar dense flat dark color="primary">
      <v-app-bar-title>Sales Representative Change</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">

        <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Enter Fee
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Select Lead
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Summary
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pl-2 pr-2 pb-2">
            <form-body
              ref="formBody"
              :ifSummary="false"
              :selectedLead="selectedLead"
              :salesRepName="salesRepName"
            />
            <button-container
              backName="Cancel"
              nextName="Next"
              @getPreviousStep="resetWizard"
              @getNextStep="goToStepTwo"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2" v-if="wizardVisible">
          <v-card class="pl-2 pr-2 pb-2">
            <associated-lead-grid-container
              :salesRepName="salesRepName"
              :selectedLead="selectedLead"
              @leadSelected="onLeadSelected"
              @removeSelected="onRemoveSelected"
            />

            <button-container
              backName="Back"
              nextName="Next"
              :disableNextStep="!selectedLead"
              @getPreviousStep="goToStep(1)"
              @getNextStep="goToStepThree"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="pl-2 pr-2 pb-2">
            <form-body
              ref="summaryFormBody"
              :ifSummary="true"
              :selectedLead="selectedLead"
              :salesRepName="salesRepName"
            />
            <button-container
              backName="Back"
              nextName="Submit"
              @getPreviousStep="goToStep(2)"
              @getNextStep="onSubmitClicked"
            />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "@/components/common/wizard/ButtonContainer.vue";
import AssociatedLeadGridContainer from "@/components/salesRepresentatives/fineWizard/AssociatedLeadGridContainer.vue";
import FormBody from "@/components/salesRepresentatives/fineWizard/FormBody.vue";
import StepperHeader from "@/components/salesRepresentatives/fineWizard/StepperHeader.vue";

export default {
  components: {
    ButtonContainer,
    AssociatedLeadGridContainer,
    FormBody,
    StepperHeader
  },

  props: {
    salesRepReferenceId: String,
    salesRepName: String
  },

  data() {
    return Object.assign(
      {},
      {
        wizardVisible: false,
        error: null,
        step: 1,
        selectedLead: null,
        gridLoaded: false
      }
    );
  },

  methods: {
    openDialog() {
      this.wizardVisible = true;
    },

    closeDialog() {
      this.wizardVisible = false;
    },

    showError(error) {
      this.$emit("showError", error);
    },

    showSuccess(message) {
      this.$emit("showSuccess", message);
    },

    resetWizard() {
      this.step = 1;
      this.closeDialog();
      this.onRemoveSelected();
      this.$refs.formBody.reset();
      this.$refs.summaryFormBody.reset();
    },

    onRemoveSelected() {
      this.selectedLead = null;
    },

    onLeadSelected(data) {
      this.selectedLead = data;
    },

    goToStepTwo() {
      const valid = this.$refs.formBody.validate();
      if (valid) {
        this.goToStep(2);
      }
    },

    goToStep(step) {
      this.step = step;
    },

    goToStepThree() {
      const payload = this.$refs.formBody.getPayload();
      const { selectedLead } = this.$data;
      payload.leadReferenceId = selectedLead.ReferenceId;
      payload.customerFullName = `${selectedLead.CustomerFirstName} ${
        selectedLead.CustomerLastName
      }`;
      this.$refs.summaryFormBody.loadForm(payload);
      this.goToStep(3);
    },

    onSubmitClicked() {
      const valid = this.$refs.summaryFormBody.validate();
      if (valid) {
        const payload = this.$refs.summaryFormBody.getPayload();
        this.$emit("submitClicked", payload);
      }
    }
  }
};
</script>
