<template>
  <div>
    <v-text-field
        v-if="readonly"
        :readonly="readonly"
        placeholder=" "
        :value="selected.code"
        label="Plan Codes"
        data-id="plan-codes-text-value"
    />
    <v-autocomplete
        light
        dense
        attach
        validate-on-blur
        hide-no-data
        v-if="!readonly"
        v-model="selected"
        placeholder="Select Plan Code"
        data-id="plan-codes-autocomplete"
        :item-text="'code'"
        item-value="id"
        :items="planCodes"
        :rules="[rules.requiredBasedOnPropsValue]"
        :label="''"
        :required="required"
        :multiple="false"
        :clearable="false"
        :disabled="disabled"
    />
  </div>
</template>

<script>

import Rules from "../../assets/rules";

export default {
  components: {},

  data() {
    return {
      selected: null,
      rules: {
        requiredBasedOnPropsValue: (value) => Rules.requiredBasedOnPropsValue(value, this.required)
      }
    };
  },

  props: {
    value: Number,
    readonly: Boolean,
    required: Boolean,
    label: String,
    disabled: Boolean,
    planCodes: Array
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      const planCode = this.planCodes.find(x => x.id === value);
      this.$emit("inputSelected", value, planCode);
    }
  },

  methods: {
    emitError(error) {
      this.$emit("emitError", error);
    },
    getSelected() {
      return this.selected;
    },
    clearSelected() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
  width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
