<template>
  <v-form ref="updateCrewForm">
    <v-container class="pa-4 mt-4">
      <v-row>
        <v-col cols="6">
          <v-text-field
              id="full-name-create"
              :rules="[rules.required]"
              label="* Name"
              v-model="name"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="6">
          <status-dropdown
              label="* Status"
              :value="active"
              :readonly="isInstallerInactive()"
              @onStatusChange="changeStatus"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
              :rules="[rules.required, rules.phoneNumberWithDelimiter]"
              id="phone-number-create"
              label="* Phone Number"
              v-model="phoneNumber"
              placeholder=" "
              persistent-placeholder
              v-facade="phoneNumberMask"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
              :rules="[rules.required, rules.email]"
              id="email-create"
              label=" * Email"
              v-model="email"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
              id="crew-primary-branch"
              label=" * Primary Branch"
              placeholder="'Select primary branch'"
              persistent-placeholder
              :value="primaryBranch.name"
              readonly
          />
        </v-col>
        <v-col cols="6">
          <branches-dropdown-autocomplete
              ref="branchSearchFieldSecondary"
              id="update-crew-secondary-branch"
              label="Secondary Branch"
              persistent-placeholder
              placeholder="Select additional branch"
              :required="false"
              :selectAllEnabled="false"
              :clearable="true"
              :value="secondaryBranchRefId"
              :branches="branches"
              return-object
              @inputSelected="secondaryBranchInputChanged"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <working-days-autocomplete
              ref="workingDaysAutocomplete"
              id="working-days-update"
              :value="workingDays"
              :selectedWorkingDays="selectedWorkingDays"
              :select-all-enabled=true
              :disabled="false"
              :multiple="true"
              :required="true"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <installation-capabilities-search-field
              ref="installerCapabilitiesSearchField"
              :availableCapabilities="capabilities"
              itemText="name"
              label="* Capabilities"
              placeholder="Select capabilities of the crew"
              :required="true"
              :multiple="true"
              :selectAllEnabled="true"
              :value="selectedCapabilityIds"
              :validate-on-type="'input'"
              @emitError="emitError"
              @inputSelected="capabilitySelected"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <alert-message
              :showAlert="showMfaAlert"
              :value="active"
              :alertMessage="alertMessage"
              @onClearError="clearAlertMessageError"
          />
          <user-mfa-control-tools
              :disabled="disableMfaSwitch"
              :mfaEnabled="mfaEnabled"
              :mfaSetUp="mfaSetUp"
              @resetMfaClicked="resetMfaClicked"
              @mfaEnabledValueChanged="mfaEnabledValueChanged"
          />
        </v-col>
      </v-row>

      <v-row class="pt-3">
        <v-col cols="6" class="ma-0 pa-0">
          <password-helper-container
              ref="passwordHelper"
              :changePasswordEnabled="true"
              :email="email"
              @passwordOverriddenSuccessfully="showSuccess"
          />
        </v-col>
      </v-row>
    </v-container>

    <generic-warning-dialog
        ref="warningDialog"
        title="Warning"
        :message="warningMessage"
        :showDialog="showResetMfaDialog"
        :multiLineError="true"
        confirmationLabel="Confirm"
        cancelLabel="Cancel"
        @confirmed="onConfirmAction"
        @cancelled="onCancelResettingMfa"
    />
  </v-form>
</template>

<script>
import InstallationCapabilitiesSearchField from "../../../components/searchFields/InstallationCapabilities.vue";
import Constants from "../../../assets/constants";
import StatusDropdown from "../../../components/common/form/StatusDropdown.vue";
import Rules from "../../../assets/rules";
import PhoneMask from "../../../assets/phoneMask";
import BranchesDropdownAutocomplete from "../../../components/searchFields/BranchesDropdownAutocomplete.vue";
import PasswordHelperContainer from "../../../components/passwordManagement/PasswordHelperContainer.vue";
import WorkingDaysAutocomplete from "../../../components/searchFields/WorkingDaysAutocomplete.vue";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";
import Users from "@/rest/users";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import Roles from "@/assets/roles";
import AlertMessage from "@/components/common/AlertMessage.vue";

const formDefaults = {
  id: null,
  name: null,
  phoneNumber: null,
  email: null,
  active: true,
  workingDays: [],
  selectedWorkingDays: [],
  daysInWeek: Constants.daysInWeek,
  selectedCapabilityIds: [],
  phoneNumberMask: Constants.phoneNumberMask,
  primaryBranch: null,
  secondaryBranchRefId: null,
  secondaryBranchName: null,
  sendTemporaryPassword: false,
  installerAppPassword: null,
  newPasswordVisible: false,
  referenceId: null,
  mfaEnabled: null,
  mfaSetUp: null,
  warningMessage: null,
  showResetMfaDialog: false,
  showMfaAlert: false,
  alertMessage: ''
};

export default {
  components: {
    AlertMessage,
    GenericWarningDialog,
    UserMfaControlTools,
    WorkingDaysAutocomplete,
    BranchesDropdownAutocomplete,
    StatusDropdown,
    InstallationCapabilitiesSearchField,
    PasswordHelperContainer
  },

  props: {
    capabilities: {
      type: Array,
      required: true
    },
    branches: {
      type: Array,
      required: true
    },
    installer: Object
  },

  data() {
    return Object.assign(
        {
          rules: {
            required: value => {
              if (typeof value === "number") {
                const result = value >= 0 || "Required";
                return result;
              } else if (typeof value === "string") {
                value = value.trim();
              }
              return !!value || "Required";
            },
            phoneNumberWithDelimiter: Rules.phoneNumberWithDelimiter,
            email: Rules.email
          }
        },
        formDefaults
    );
  },

  computed: {
    disableMfaSwitch() {
      return !this.active || (!Roles.isSuperAdminOrAdminUser() && !Roles.isGeneralManager() && !Roles.isBranchAdmin());
    }
  },

  methods: {
    changeStatus(status) {
      if (this.active !== status) {
        this.active = status;
        this.mfaEnabled = !!status;
        this.showAlertNotification(status);
      }
    },
    emitError(error) {
      this.$emit('emitError', error);
    },
    loadUpdateCrewForm(payload) {
      if (!payload) {
        payload = formDefaults;
      }
      this.id = payload.id;
      this.name = payload.name;
      if (this.installer && !this.installer.active) {
        this.active = false;
      } else {
        this.active = payload.active;
      }
      this.phoneNumber = PhoneMask.maskPhone(payload.phoneNumber);
      this.email = payload.email;
      this.primaryBranch = payload.branch;
      this.secondaryBranchName = payload.secondaryBranch === "" ? null : payload.secondaryBranch;
      this.secondaryBranchRefId = this.getSecondaryBranchReferenceId(payload.secondaryBranch);
      this.$nextTick(() => this.$refs.branchSearchFieldSecondary.setSelectedBranch(this.secondaryBranchRefId));
      this.selectedCapabilityIds = payload.capabilityIds;
      this.sendTemporaryPassword = payload.sendTemporaryPassword;
      this.referenceId = payload.referenceId;
      this.installerId = payload.installerId;
      this.selectedWorkingDays = payload.workingDays;
      this.mfaEnabled = payload.mfaEnabled;
      this.mfaSetUp = payload.mfaSetUp;
    },
    validate() {
      return this.$refs.updateCrewForm.validate();
    },
    buildUpdateCrewPayload() {
      let payload = {};
      payload.id = this.id; // crew id
      payload.referenceId = this.referenceId; //crew ref id
      payload.name = this.name;
      payload.active = this.active;
      payload.phoneNumber = PhoneMask.unMaskPhone(this.phoneNumber);
      payload.email = this.email;
      payload.secondaryBranch = this.secondaryBranchRefId;
      payload.workingDays = this.$refs.workingDaysAutocomplete.selected;
      payload.capabilityIds = this.selectedCapabilityIds;
      payload.sendTemporaryPassword = this.sendTemporaryPassword;
      payload.newPassword = this.installerAppPassword;
      payload.installerId = this.installerId;
      payload.mfaEnabled = this.mfaEnabled;
      payload.mfaSetUp = this.mfaSetUp;
      return payload;
    },

    resetForm() {
      this.name = null;
      this.phoneNumber = null;
      this.email = null;
      this.workingDays = [];
      this.selectedWorkingDays = [];
      this.selectedCapabilityIds = [];
      this.alertMessage = '';
      this.showMfaAlert = false;
      this.resetSelectedBranch();
      this.resetCrewFormValidation();
    },
    resetSelectedBranch() {
      if (this.$refs.branchSearchFieldSecondary) {
        this.$refs.branchSearchFieldSecondary.clearSelectedBranch();
      }
    },
    resetCrewFormValidation() {
      if (this.$refs.updateCrewForm) {
        this.$refs.updateCrewForm.resetValidation();
      }
    },
    secondaryBranchInputChanged(value) {
      this.secondaryBranchRefId = value;
    },
    capabilitySelected(value) {
      this.selectedCapabilityIds = value;
    },
    getSecondaryBranchReferenceId(secondaryBranchName) {
      let secondaryBranchRefId;
      this.branches.find(branch => {
        if (branch.name === secondaryBranchName) {
          secondaryBranchRefId = branch.referenceId;
        }
      });
      return secondaryBranchRefId;
    },
    showSuccess() {
      this.$emit('passwordOverridden');
    },
    isInstallerInactive() {
      return !this.installer.active;
    },
    mfaEnabledValueChanged(value) {
      this.mfaEnabled = value;
      this.clearAlertMessageError();
    },
    resetMfaClicked() {
      this.showResetMfaDialog = true;
      this.warningMessage = Constants.mfaResetDialogMessage;
    },
    onUpdateFail(error) {
      this.showNavError(error);
    },
    async onConfirmAction() {
      try {
        const response = await Users.getRestApi().resetUserMFA(this.id);
        this.onMfaResetSuccess(response.data);
      } catch (error) {
        this.onUpdateFail(error);
      }
    },
    onMfaResetSuccess(updatedUser) {
      this.$emit('resetMfaSuccess', updatedUser);
    },
    onCancelResettingMfa() {
      this.warningMessage = null;
      this.showResetMfaDialog = false;
    },
    showAlertNotification(status) {
      this.showMfaAlert = true;
      this.alertMessage = status ? Constants.mfaEnabledMessage : Constants.mfaDisabledMessage;
    },
    clearAlertMessageError() {
      this.showMfaAlert = false;
    }
  }
};
</script>
