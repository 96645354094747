<template>
  <modal-dialog
      ref="dialog"
      title="Add Related Products"
      width="800"
      :visible="dialogVisible"
      submitLabel="Add Items"
      cancelLabel="Cancel"
      @submitClicked="onAddClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <div>
      <dx-data-grid
          ref="grid"
          :height="600"
          :data-source="associated"
          :load-panel="loadPanelConfig"
          :show-borders="true"
          :hover-state-enabled="true"
          :columnMinWidth="100"
          :columnAutoWidth="true"
          @selectionChanged="onSelectionChanged"
      >
        <dx-selection
            mode="multiple"
            :showCheckBoxesMode="selection.showCheckBoxesMode"
            :selectAllMode="selection.selectAllMode"
        ></dx-selection>
        <dx-column data-field="itemDesc" caption="Item"/>
        <dx-column data-field="refsDesc" caption="Related to Items"/>
      </dx-data-grid>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import {DxColumn, DxDataGrid, DxSelection} from "devextreme-vue/ui/data-grid";
import CatalogItems from "@/rest/catalogItems.ts";

export default {
  data() {
    return {
      selected: [],
      associated: [],
      active: null,
      dialogVisible: false,
      loadPanelConfig: {
        enabled: false
      },
      selection: {
        selectAllMode: "page",
        showCheckBoxesMode: "always"
      }
    };
  },

  components: {
    ModalDialog,
    DxDataGrid,
    DxColumn,
    DxSelection
  },

  props: ["items"],

  methods: {
    openDialog() {
      this.dialogVisible = true;
      this.clearSelection();
      let itemIds = [];
      this.items.forEach(item => itemIds.push(item.productCatalogItem.id));
      this.loadCommonAssociatedItems(itemIds);
    },
    closeDialog() {
      this.dialogVisible = false
    },
    getGridInstance() {
      return this.$refs.grid.instance;
    },
    clearSelection() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    showError(error) {
      this.error = error;
    },
    loadCommonAssociatedItems(itemIds) {
      const component = this;
      CatalogItems.getRestApi()
          .getCatalogAssociatedItemsInCommon(itemIds)
          .then((response) => {
            let raw = response.data;
            raw.forEach(rawItem => {
              let assoc = rawItem.associatedItem;
              let refs = rawItem.references;
              let itemDesc = assoc.internalSku + " - " + assoc.styleName + "/" + assoc.color ;
              let respDescs = [];
              refs.forEach(reference => {
                let refDesc = reference.internalSku + " / " + reference.styleName + " / " + reference.color;
                respDescs.push(refDesc);
              });
              rawItem.itemDesc = itemDesc;
              rawItem.refsDesc = respDescs.join(", ");
            });
            component.$data.associated = response.data;
          })
          .catch((error) => {
            component.$refs.dialog.showError(error);
          });
    },
    onSelectionChanged(event) {
      this.selected = event.selectedRowsData;
    },
    onAddClicked() {
      this.dialogVisible = false
      this.$emit("itemsSelected", this.selected);
    },
    onCancelClicked() {
      this.dialogVisible = false
    }
  }
};
</script>
