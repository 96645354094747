<template>
  <v-data-table
      :headers="headers"
      :items="poItems"
      light
      hide-default-footer
      disable-pagination
  >
    <template v-slot:item="{ item }">
      <tr :key="item.name"
          @click="addToCheckInList(item)"
          :class="[isSelectableRow(item)]"
      >
        <td class="text-lg-left pa-4">
          {{ item.productCatalogItem.productType.name }}
        </td>
        <td class="text-lg-left pa-4">
          {{ item.productCatalogItem.styleName }}
        </td>
        <td class="text-lg-left pa-4">
          {{ item.productCatalogItem.color }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.quantityOrdered }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.quantityReceived || 0 }}
        </td>
        <td class="text-xs-center pa-4">
          <v-icon color="primary">{{ showCheckedInStatus(item) }}</v-icon>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      No items associated with this PO
    </template>
  </v-data-table>
</template>

<script>
export default {
  components: {},
  props: {
    poItems: Array
  },
  data() {
    return {
      headers: [
        { text: "Product Type", value: "productType", sortable: false },
        { text: "Style", value: "style", sortable: false },
        { text: "Color", value: "color", sortable: false },
        {
          text: "Quantity Ordered",
          value: "quantityOrdered",
          sortable: false,
          align: "center"
        },
        {
          text: "Quantity Received",
          value: "quantityReceived",
          sortable: false,
          align: "center"
        },
        {
          text: "Check In Status",
          value: "quantityReceived",
          sortable: false,
          align: "center"
        }
      ]
    };
  },

  methods: {
    addToCheckInList(item) {
      if (!item.isCheckedIn) {
        this.$emit("poItemClicked", item);
      }
    },
    isSelectableRow(item) {
      return item.isCheckedIn ? 'disabledRow' : 'selectableRow';
    },
    showCheckedInStatus(item) {
      return item.isCheckedIn ? 'check_circle_outline' : '';
    }
  }
};
</script>

<style>
.disabledRow {
  font-size: 13px;
  background-color: #e6f2fc;
}
.selectableRow{
  font-size: 13px;
  margin: 10px;
}
.disabledRow:hover {
  background-color: #e6f2fc !important;
}
.selectableRow:hover {
  cursor: pointer;
  background: #7A7A7A1F;
}
</style>
