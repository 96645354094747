<template>
  <v-list>
    <v-list-item
        class="selection-section"
        ripple
        @mousedown.prevent
        @click="handleSelectDeselectClick"
        color="primary"
    >
      <v-checkbox
          class="checkbox-select-all"
          color="primary"
          v-model="checkBoxSelected"
      />
      Select All
    </v-list-item>
    <div class="divider-full-length">
      <v-divider class="mt-2"></v-divider>
    </div>
  </v-list>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    items: {type: Array, required: true},
    selected: {type: Array | null, required: true}
  },

  computed: {
    checkBoxSelected() {
      return (this.selected ? this.selected.length : 0) === this.items.length;
    }
  },

  methods: {
    handleSelectDeselectClick() {
      if ((this.selected ? this.selected.length : 0) === this.items.length) {
        this.deselectAll();
      } else this.selectAll();
    },
    selectAll() {
      this.$emit('select');
    },
    deselectAll() {
      this.$emit('deselect');
    }
  }
};
</script>

<style scoped>

.selection-section {
  display: flex;
}
.v-list {
  padding: 0;
}
.checkbox-select-all {
  margin-right: 24px;
  margin-bottom: -20px;
  margin-top: 0;
  padding-top: 0;
}
</style>
