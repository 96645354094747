<template>
  <inline-grid
      :data-source="auditLogs"
      :show-borders="false"
      :pageSize="5"
      :showPager="true">
    <dx-column
        data-field="createdAt"
        data-type="datetime"
        caption="Date"
        format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
        data-field="user.fullName"
        caption="User"
    />
    <dx-column
        data-field="operation"
        caption="Operation"
    />
    <dx-column
        data-field="quantity"
        caption="Quantity"
    />
    <dx-column
        data-field="reasonCode.name"
        caption="Reason Code"
    />
    <dx-column
        data-field="referenceNumber"
        caption="Reference Number"
    />
  </inline-grid>
</template>

<script>
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    InlineGrid,
    DxColumn
  },

  props: {
    auditLogs: {
      type: Array,
      required: true
    }
  },

  created() {
    this.emitGetLogs();
  },

  methods: {
    emitGetLogs() {
      this.$emit("onGetLogs");
    }
  }
};
</script>
