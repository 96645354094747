<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0">
    <v-row v-if="ticket" class="pb-0">
      <v-col md="2">
        <v-text-field
            v-model="ticket.referenceId"
            label="Reference Id"
            placeholder=" "
            persistent-placeholder
            readonly
            id="ticket-reference-id"
        />
      </v-col>
      <v-col/>
      <v-col md="2">
        <v-text-field
            v-model="ticket.statusName"
            label="Status"
            placeholder=" "
            persistent-placeholder
            readonly
            id="ticket-status-name"
        />
      </v-col>
    </v-row>

    <v-divider class="detailsDivider"/>

    <v-row>
      <v-col md="6" id="left-block">
        <v-row>
          <v-col md="5">
            <v-text-field
                readonly
                id="ticket-creator"
                label="Creator"
                v-model="ticket.userName"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col md="5">
            <v-text-field
                readonly
                id="ticket-assignee"
                label="Assigned User"
                v-model="ticket.assigneeName"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col md="5">
            <read-only-text-field-with-hover-and-link-cell-template
                id="ticket-customer"
                :value="ticket.customerName"
                :link="navLinkToCustomer"
                :show="true"
                label="Associated Customer"
            />
          </v-col>
          <v-col md="5" v-if="ticket.leadReferenceId">
            <read-only-text-field-with-hover-and-link-cell-template
                id="ticket-lead"
                :value="ticket.leadReferenceId"
                :link="navLinkToLead"
                :show="true"
                label="Associated Lead"
            />
          </v-col>
          <v-col md="5" v-if="ticket.jobReferenceId">
            <read-only-text-field-with-hover-and-link-cell-template
                id="ticket-job"
                :value="ticket.jobReferenceId"
                :link="navLinkToJob"
                :show="ticket.jobReferenceId"
                label="Associated Job"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col md="6">
        <v-textarea
            readonly
            id="ticket-description"
            no-resize
            label="Description"
            v-model="ticket.description"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ReadOnlyTextFieldWithHoverAndLinkCellTemplate
  from "../common/grid/cellTemplates/ReadOnlyTextFieldWithHoverAndLinkCellTemplate.vue";

export default {
  components: {ReadOnlyTextFieldWithHoverAndLinkCellTemplate},

  props: {
    ticket: Object
  },

  computed: {
    navLinkToCustomer() {
      return `/customers/details/${this.ticket.customerReferenceId}`;
    },
    navLinkToJob() {
      return `/jobs/details/${this.ticket.jobReferenceId}`;
    },
    navLinkToLead() {
      return `/leads/details/${this.ticket.leadReferenceId}`;
    },
  },

  methods: {}
};
</script>
