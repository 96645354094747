<template>
  <div ref="printable" id="printable" class="printable">
    <div class="printable-container">
      <div class="printable-header" id="print-title">
        <div class="printable-header-title">50 Floor - Appointment</div>
        <div v-if="date" class="printable-header-date">{{ date }}</div>
      </div>
      <div class="printable-grid">
        <div class="printable-field" id="print-appointment-status">
          <div class="printable-label">Lead Status:</div>
          <div class="printable-value">{{ leadStatus }}</div>
        </div>
        <div class="printable-field" id="print-appointment-name">
          <div class="printable-label">Customer Name:</div>
          <div class="printable-value">{{ fullName }}</div>
        </div>
        <div class="printable-field" id="print-appointment-date">
          <div class="printable-label">Date:</div>
          <div class="printable-value">{{ appointmentDate }}</div>
        </div>
        <div class="printable-field" id="print-appointment-time">
          <div class="printable-label">Time:</div>
          <div class="printable-value">{{ appointmentTime }}</div>
        </div>
        <div class="printable-field" id="print-appointment-address">
          <div class="printable-label">Address:</div>
          <div class="printable-value">{{ address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      qaDebug: false,
      date: null,
      fullName: null,
      leadStatus: null,
      appointmentDate: null,
      appointmentTime: null,
      address: null
    };
  },

  mounted() {
    this.initView();
  },

  methods: {
    initView: function() {
      let data = this.$store.getters.getPrintData;
      if (data) {
        this.$data.date = this.formatDate(new Date());
        this.$data.fullName = data.fullName ? data.fullName : "";
        this.$data.leadStatus = data.leadStatus ? data.leadStatus : "";
        this.$data.appointmentDate = data.appointmentDate
          ? this.formatDate(data.appointmentDate)
          : "";
        this.$data.appointmentTime = data.appointmentTime
          ? data.appointmentTime
          : "";
        this.$data.address = data.address ? data.address : "";
        this.$data.qaDebug = "qaDebug" in this.$route.query ? true : false;
        this.onAfterPrint();
        this.print();
      } else {
        this.$router.push("/sales/home");
      }
    },
    formatDate: function(date) {
      return moment.utc(date).format("MM/DD/YYYY");
    },
    print: function() {
      if (!this.$data.qaDebug) {
        window.print();
      }
    },
    onAfterPrint: function() {
      window.onafterprint = this.close;
    },
    close: function() {
      if (!this.$data.qaDebug) {
        setTimeout(window.close, 500);
      }
    }
  }
};
</script>
