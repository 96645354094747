<template>
  <modal-dialog
      ref="dialog"
      title="Add Files"
      :width="showInstallTypes ? '70vw' : '700px'"
      :visible="dialogVisible"
      :submitEnabled="true"
      :loading="loading"
      submitLabel="Save File(s)"
      cancelLabel="Cancel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <v-form ref="form">
      <v-container>
        <file-upload
            ref="fileUploader"
            :uploadingFiles="uploadingFiles"
            :uploadPercentage="uploadPercentage"
            :documentTypesCategory="documentTypesCategory"
            :documentTypes="documentTypes"
            :showInstallTypes="showInstallTypes"
            :installTypesPerDocumentType="installTypesPerDocumentType"
            :availableLabors="availableLabors"
        />
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../ModalDialog.vue";
import FileUpload from "./FileUpload.vue";
import Files from "../../../../rest/files";
import Constants from "@/assets/constants";

const formDefaults = {
  note: null,
  dialogVisible: false,
  uploadPercentage: 0,
  uploadingFiles: false,
  loading: false
};
export default {
  components: {
    ModalDialog,
    FileUpload
  },
  props: [
    "leadReferenceId",
    "jobId",
    "installerReferenceId",
    "purchaseOrderNumber",
    "parentType",
    "documentTypesCategory",
    "documentTypes",
    "showInstallTypes",
    "installTypesPerDocumentType",
    "transferRequestNumber",
    "shipmentNumber",
    "salesRepReferenceId",
    "availableLabors",
    "wsSessionId"
  ],

  data() {
    return Object.assign(
        {
          error: null,
          rules: {
            required: value => !!value || "Required"
          }
        },
        formDefaults
    );
  },

  methods: {
    reset() {
      this.uploadPercentage = 0;
      this.$refs.form.reset();
      this.$refs.fileUploader.reset();
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.reset();
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.$refs.form.reset();
      this.closeDialog();
    },
    getFormData(files) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append("file", file);
      }
      return formData;
    },
    async onSubmitClicked() {
      const {files} = this.$refs.fileUploader;
      const {selectedDocumentTypes, selectedInstallTypes, selectedLabors} = this.$refs.fileUploader;
      let validatedForm = this.isValidatedForm();
      if (files.length && validatedForm) {
        this.loading = true;
        this.uploadingFiles = true;
        await this.performFilesUpload(files, selectedDocumentTypes, selectedInstallTypes, selectedLabors);
      }
    },
    async performFilesUpload(files, selectedDocumentTypes, selectedInstallTypes, selectedLabors) {
      let hasCOCorVDAFileTypes = selectedDocumentTypes.some(fileType => fileType === Constants.COC_DOCUMENT_TYPE_ID
          || fileType === Constants.VDA_DOCUMENT_TYPE_ID);
      try {
        let results = await this.uploadFileBasedOnType(files, selectedDocumentTypes, selectedInstallTypes, selectedLabors);
        let hadErrors = false;
        results.data.forEach(result => {
          if (result.error) {
            hadErrors = true;
            let error = new Error(`Unable to upload "${result.fileName}" (${result.error})`);
            error.title = "Upload Failed";
            this.loading = false;
            this.emitError(error);
          }
        });
        if (!hadErrors) {
          this.successFileUpload(hasCOCorVDAFileTypes);
          this.loading = false;
        }
      } catch (error) {
        this.emitError(error);
      }
    },
    async uploadFileBasedOnType(files, selectedDocumentTypes, selectedInstallTypes, selectedLabors) {
      const formData = this.getFormData(files);
      try {
        if (this.parentType === "lead") {
          return this.uploadLeadFile(formData, selectedDocumentTypes, selectedInstallTypes);
        } else if (this.parentType === "job") {
          return this.uploadJobFile(formData, selectedDocumentTypes, selectedInstallTypes, selectedLabors);
        } else if (this.parentType === "installer") {
          return this.uploadInstallerFile(formData, selectedDocumentTypes);
        } else if (this.parentType === "purchaseorder") {
          return this.uploadPurchaseOrderFile(formData, selectedDocumentTypes, selectedInstallTypes);
        } else if (this.parentType === "transferRequest") {
          return this.uploadTransferRequestFile(formData, selectedDocumentTypes);
        } else if (this.parentType === "shipment") {
          return this.uploadShipmentFile(formData, selectedDocumentTypes);
        } else if (this.parentType === "salesRep") {
          return this.uploadSalesRepFile(formData, selectedDocumentTypes);
        }
      } catch (error) {
        throw error;
      }
    },
    async uploadLeadFile(formData, selectedDocumentTypes, selectedInstallTypes) {
      try {
        return await Files.getRestApi().uploadFileForLead(this.leadReferenceId, formData, selectedDocumentTypes,
            selectedInstallTypes);
      } catch (error) {
        throw error;
      }
    },
    async uploadJobFile(formData, documentTypes, selectedInstallTypes, selectedLabors) {
      try {
        return await Files.getRestApi().uploadFileForJob(this.jobId, formData, documentTypes, selectedInstallTypes, selectedLabors, this.wsSessionId);
      } catch (error) {
        throw error;
      }
    },
    async uploadInstallerFile(formData, selectedDocumentTypes) {
      try {
        return await Files.getRestApi().uploadFileForInstaller(this.installerReferenceId, formData,
            selectedDocumentTypes);
      } catch (error) {
        throw error;
      }
    },
    async uploadPurchaseOrderFile(formData, selectedDocumentTypes, selectedInstallTypes) {
      try {
        return await Files.getRestApi().uploadFileForPurchaseOrder(this.purchaseOrderNumber, formData,
            selectedDocumentTypes, selectedInstallTypes);
      } catch (error) {
        throw error;
      }
    },
    async uploadTransferRequestFile(formData, selectedDocumentTypes) {
      try {
        return await Files.getRestApi().uploadFilesForTransferRequest(this.transferRequestNumber, formData, selectedDocumentTypes);
      } catch (error) {
        throw error;
      }
    },
    async uploadShipmentFile(formData, selectedDocumentTypes) {
      try {
        return await Files.getRestApi().uploadShipmentFiles(this.shipmentNumber, formData, selectedDocumentTypes);
      } catch (error) {
        throw error;
      }
    },
    async uploadSalesRepFile(formData, selectedDocumentTypes) {
      try {
        return await Files.getRestApi().uploadSalesRepresentativeFiles(this.salesRepReferenceId, formData, selectedDocumentTypes);
      } catch (error) {
        throw error;
      }
    },
    successFileUpload(hasCOCorVDAFileTypes) {
      this.uploadingFiles = false;
      this.loading = false;
      this.closeDialog();
      this.$emit('uploadComplete', hasCOCorVDAFileTypes);
    },
    emitError(error) {
      this.uploadingFiles = false;
      this.loading = false;
      this.$emit('addFilesGridError', error);
    },
    isValidatedForm() {
      return this.$refs.fileUploader.isValidatedForm();
    },
  }
};
</script>
