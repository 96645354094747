import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {Lead} from "./leads";
import {Job} from "./jobs";
import {ActivityLog} from "@/rest/interfaces/activityLogs";

/**
 * Customers.
 */
export class Customer {
  id: number;
  referenceId: string;
  firstName: string = "";
  lastName: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  email: string = "";
  primaryPhone: string = "";
  alternatePhone: string = "";
  zipCode: string = "";
  updatedAt: string = "";
  createdAt: string = "";

  constructor(
    id: number,
    referenceId: string,
    firstName: string,
    lastName: string,
    address: string,
    city: string,
    state: string,
    email: string,
    primaryPhone: string,
    alternatePhone: string,
    zipCode: string,
    updatedAt: string,
    createdAt: string
  ) {
    this.id = id;
    this.referenceId = referenceId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.address = address;
    this.city = city;
    this.state = state;
    this.email = email;
    this.primaryPhone = primaryPhone;
    this.alternatePhone = alternatePhone;
    this.zipCode = zipCode;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }
}


/**
 * Update Customer Request (customer's email).
 */
export class UpdateCustomerRequest {
  email: string = "";
  constructor(email: string) {
    this.email = email;
  }
}

/**
 * REST interface provided for customers.
 */
export interface CustomersRestApi {
  getCustomer(refId: string): AxiosPromise<Customer>;
  getCustomersByPrimaryOrAlternatePhone(phone: number): AxiosPromise<Customer[]>;
  createCustomer(customer: Customer): AxiosPromise<Customer>;
  updateCustomer(customerRefId: string, updated: Customer): AxiosPromise<Customer>;
  getCustomerLeads(customerRefId: string): AxiosPromise<Lead[]>;
  getCustomerJobs(customerRefId: string): AxiosPromise<Job[]>;
  getCustomerActivityLogs(refId: string): AxiosPromise<ActivityLog[]>;
  updateCustomerEmail(customerReferenceId: string, updateCustomerEmail: UpdateCustomerRequest): AxiosPromise<Customer>;
  checkIfCustomerPhoneNumberIsPresent(primaryPhone: string): AxiosPromise<Boolean>;
  findCustomersByPrimaryPhoneNumber(primaryPhone: string, includeAlternatePhone: boolean): AxiosPromise<Customer[]>;
}

/**
 * REST implementation provided for customers.
 */
class Implementation implements CustomersRestApi {
  getCustomer(refId: string): AxiosPromise<Customer> {
    return Axios.axiosInstance().get<Customer>(`/customers/${refId}`);
  }
  getCustomersByPrimaryOrAlternatePhone(phone: number): AxiosPromise<Customer[]> {
    return Axios.axiosInstance().get<Customer[]>(`/customers/phone/${phone}`);
  }
  createCustomer(customer: Customer): AxiosPromise<Customer> {
    return Axios.axiosInstance().post<Customer>(`/customers/`, customer);
  }
  updateCustomer(customerRefId: string, updated: Customer): AxiosPromise<Customer> {
    return Axios.axiosInstance().put<Customer>(`/customers/${customerRefId}`, updated);
  }
  getCustomerLeads(customerRefId: string): AxiosPromise<Lead[]> {
    return Axios.axiosInstance().get<Lead[]>(`/customers/${customerRefId}/leads`);
  }
  getCustomerJobs(customerRefId: string): AxiosPromise<Job[]> {
    return Axios.axiosInstance().get<Job[]>(`/customers/${customerRefId}/jobs`);
  }
  getCustomerActivityLogs(refId: string): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/customers/${refId}/logs`);
  }
  updateCustomerEmail(customerReferenceId: string, updateCustomerEmail: UpdateCustomerRequest): AxiosPromise<Customer> {
    return Axios.axiosInstance().patch<Customer>(`/customers/${customerReferenceId}/update-email`, updateCustomerEmail);
  }
  checkIfCustomerPhoneNumberIsPresent(primaryPhone: string): AxiosPromise<Boolean> {
    return Axios.axiosInstance().get<Boolean>(`/customers/check/primary-phone/${primaryPhone}`);
  }
  findCustomersByPrimaryPhoneNumber(primaryPhone: string, includeAlternatePhone: boolean): AxiosPromise<Customer[]> {
    return Axios.axiosInstance().get<Customer[]>(`/customers/all/phone/${primaryPhone}`, {params:{
      "includeAlternatePhone": includeAlternatePhone
    }});
  }
}

const Customers = {
  getRestApi(): CustomersRestApi {
    return new Implementation();
  }
};

export default Customers;
