<template>
  <drawer-form
      ref="form"
      title="Edit Installer"
      submitLabel="Save Installer"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container>
      <installer-edit-form
          ref="installerEditForm"
          :availableCapabilities="availableCapabilities"
          :branches="branches"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import Installers from "@/rest/installers";
import InstallerEditForm from "./InstallerEditForm.vue";
import DrawerForm from "../common/form/DrawerForm.vue";

export default {
  components: {
    DrawerForm,
    InstallerEditForm
  },

  props: {
    installer: Object,
    capabilities: {
      type: Array,
      required: true
    },
    branches: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      installerData: null,
      inProgress: false,
      availableCapabilities: []
    };
  },

  watch: {
    installer(newVal) {
      if (newVal) {
        this.refresh();
      }
    },
    capabilities() {
      this.availableCapabilities = this.capabilities;
    }
  },

  methods: {
    refresh() {
      this.installerData = Object.assign(this.installer);
      this.$refs.installerEditForm.loadForm(this.installerData);
    },
    onCancel() {
      this.refresh();
      this.$emit("cancelClicked");
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.updateInstaller();
      }
    },
    async updateInstaller() {
      try {
        this.inProgress = true;
        const payload = this.$refs.installerEditForm.buildPayload();
        const response = await Installers.getRestApi().updateInstaller(this.installer.referenceId, payload);
        this.$emit("onUpdateSuccess", response.data);
      } catch (error) {
        this.$emit("onUpdateFail", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
