<template>
  <modal-dialog
      data-cy="popupError"
      width="400"
      class="error-modal"
      :hideSubmit="true"
      :title="title || 'Error'"
      cancelLabel="Close"
      :visible="visible"
      @cancelClicked="closeModal"
  >
    <div v-if="multiLineError">
      <div v-for="line in errorLines" :key="line">
        {{ line }}
      </div>
    </div>
    <div v-else>
      {{ error }}
    </div>

  </modal-dialog>
</template>
<script>
import ModalDialog from "../common/ModalDialog.vue";

export default {
  components: {
    ModalDialog
  },

  data() {
    return {
      errorLines: [],
    };
  },

  props: {
    visible: Boolean,
    error: String,
    title: String,
    multiLineError: Boolean
  },

  watch: {
    multiLineError(value) {
      if (value) {
        this.errorLines = this.error.split('\n');
      }
    }
  },

  methods: {
    closeModal() {
      this.$emit('closeErrorModal');
    }
  }
};
</script>

<style scoped>
.error-modal {
  z-index: 1000000;
}
</style>
