<template>
  <v-container >
    <v-form ref="form" lazy-validation>
      <v-card class="stepper-card">
        <v-row class="content-row mt-1">
          <v-col sm="6">
            <vendors-autocomplete
                ref="vendorsAutocomplete"
                label="* Vendor"
                :vendors="vendorOptions"
                :disabled="false"
                placeholder="Search Vendor or start typing it's name"
                @inputSelected="onVendorSelected"
            />
          </v-col>
          <v-col sm="6">
            <branches-autocomplete
                ref="branchesAutocomplete"
                label="* Branch"
                placeholder="Search Branch or start typing it's name"
                :multiple="false"
                :required="true"
                :clearable="false"
                :selectAllEnabled="false"
                :getBranchesOnComponentCreation="true"
                :isReturnObject="true"
                @inputSelected="onBranchSelected"
            />
          </v-col>
        </v-row>
        <v-divider/>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import BranchesAutocomplete from "../../searchFields/BranchesAutocomplete.vue";
import VendorsAutocomplete from "../../searchFields/VendorsAutocomplete.vue";
import Vendors from "../../../rest/vendors";

export default {
  components: {
    BranchesAutocomplete,
    VendorsAutocomplete
  },

  data() {
    return {
      vendorOptions: null,
      selectedVendor: null,
      selectedBranch: null
    };
  },

  created() {
    Vendors.getRestApi()
      .getActiveVendors()
      .then((response) => {
        this.vendorOptions = response.data;
      });
  },

  methods: {
    resetSelected() {
      this.$refs.branchesAutocomplete.reset();
      this.$refs.vendorsAutocomplete.reset();
      this.$refs.form.resetValidation();
    },
    onBranchSelected(value) {
      this.selectedBranch = value;
    },
    onVendorSelected(value, isVendorChanged) {
      this.selectedVendor = value;
      if (isVendorChanged) {
        this.$emit('onVendorChanged');
      }
    }
  }
};
</script>

<style scoped>
.content-row {
    min-height: 290px;
}

.stepper-card {
    webkit-box-shadow: none !important;
    box-shadow: none !important;
}
</style>
