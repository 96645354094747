<template>
  <inline-grid
      ref="inlineGrid"
      :data-source="dataSource"
      :transfer-request-status="transferRequestStatus"
      :show-borders="false"
      :showPager="true"
      @onClickCloseTRItem="onCloseClicked"
  >
    <dx-column
        data-field="internalSKU"
        width="8%"
        caption="Internal SKU"
        data-type="string"
    />
    <dx-column
        data-field="vendorName"
        caption="Vendor"
        data-type="string"
    />
    <dx-column
        data-field="productType"
        caption="Product Type"
        data-type="string"
    />
    <dx-column
        data-field="style"
        caption="Style"
        data-type="string"
    />
    <dx-column
        data-field="color"
        caption="Color"
        data-type="string"
    />
    <dx-column
        data-field="rollNumber"
        caption="Roll Number"
        data-type="string"
        width="7%"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="dyeLot"
        caption="Dye Lot"
        data-type="string"
        width="7%"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="bin"
        caption="Bin"
        data-type="string"
        width="5%"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="quantity"
        caption="Qty to Transfer"
        data-type="string"
        width="7%"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="transferredQuantity"
        caption="Transferred Qty"
        data-type="string"
        width="7%"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="vendorCost"
        caption="Vendor Cost"
        width="6%"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="totalValue"
        caption="Total Value"
        width="6%"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="status"
        caption="Status"
        data-type="string"
        cellTemplate="transferRequestItemStatusIcon"
        :allowHeaderFiltering="false"
    />
    <dx-column
        cellTemplate="closeTRItemButtonCellTemplate"
        :transferRequestStatus="transferRequestStatus"
    />
  </inline-grid>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGrid from "../../common/grid/InlineGrid.vue";

export default {
  components: {
    DxColumn,
    InlineGrid
  },

  props: {
    dataSource: {
      type: Array,
      required: true
    },
    transferRequestStatus: String
  },
  data() {
    return {};
  },

  methods: {
    onCloseClicked(rowData) {
      this.transferRequestItemId = rowData.row.key.id;
      let selectedItem = this.dataSource.filter(item => item.id === this.transferRequestItemId);
      this.$emit('onTransferRequestItemClose', selectedItem);
    },
  }
};
</script>
