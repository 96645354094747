import Branches from "@/rest/branches.ts";
import Constants from "@/assets/constants.js";
import InstallationCapabilities from "@/rest/installationCapabilities.ts";
import JobStatuses from "@/rest/jobStatuses.ts";
import LeadStatuses from "@/rest/leadStatuses.ts";
import Markets from "@/rest/markets.ts";
import ProductTypes from "@/rest/productTypes.ts";
import Vendors from "@/rest/vendors";
import Users from "@/rest/users";

export default {
  async getMarketFilters() {
    try {
      //TODO: should it also be filtered by active market?
      const response = await Markets.getRestApi().getAllMarkets();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getMarketFiltersIfNotBranchUser(user) {
    try {
      // returns market filters if a user is NOT a branch user
      let marketsFilter;
      if (user.roleId != Constants.userRoles.branchUser || user.branchReferenceIds.length > 1) {
        marketsFilter = await this.getMarketFilters();
      }
      return marketsFilter;
    } catch (error) {
      throw error;
    }
  },
  async getLeadStatusFilters() {
    try {
      const response = await LeadStatuses.getRestApi().getAllLeadStatuses();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getUserBranchFilters() {
    try {
      const response = await Branches.getRestApi().getAllUserBranches();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getAllActiveBranchesFilter() {
    try {
      const response = await Branches.getRestApi().getAllActiveBranches();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getBranchFiltersIfNotBranchUser(user) {
    try {
      // returns market filters if a user is NOT a branch user
      let branchesFilters;
      if (user.roleId != Constants.userRoles.branchUser || user.branchReferenceIds.length > 1) {
        branchesFilters = await this.getUserBranchFilters();
      }
      return branchesFilters;
    } catch (error) {
      throw error;
    }
  },
  async getInstallationCapabilitiesFilters() {
    try {
      const response = await InstallationCapabilities.getRestApi().getAllInstallationCapabilities();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getProductTypeFilters() {
    try {
      const response = await ProductTypes.getRestApi().getProductTypes();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getJobStatusCategoryFilters() {
    try {
      const response = await JobStatuses.getRestApi().getAllJobStatuses();
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getVendorsFilter() {
    try {
      const response = await Vendors.getRestApi().getAllVendors();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  async getBranchesForParticularUser() {
    try {
      const response = await Users.getRestApi().getSelfBranches();
      return this.mapDataForHeaderFiltering(response);
    } catch (error) {
      throw error;
    }
  },
  mapDataForHeaderFiltering(response) {
    return response.data.map(item => {
      return {
        text: item.name,
        value: item.name,
        id: item.id
      };
    });
  },
  mapArrayForHeaderFiltering(data) {
    return data.map(item => {
      return {
        text: item.name,
        value: item.value,
        id: item.id
      };
    });
  }
};
