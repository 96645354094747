<template>
  <v-container grid-list-lg text-xs-center>
    <v-row class="pa-2">
      <v-col md="4">
        <v-text-field
            v-model="referenceId"
            :rules="[rules.required, rules.isReferenceId]"
            label="* Reference ID"
            placeholder=" "
            id="branch-update-form-display-ref-id"
            hint="EX: MA-B20AZDQQ"
            persistent-hint
            maxLength="11"
            persistent-placeholder
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="4">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
      <v-col md="12" class="pt-0">
        <v-text-field
            :rules="[rules.required]"
            v-model="name"
            label="* Name"
            placeholder=" "
            id="market-edit-form-name"
            persistent-placeholder
        />
      </v-col>
      <v-col md="12" class="pt-0">
        <branches-autocomplete
            id="market-edit-form-branch"
            label="* Branch"
            :required="true"
            :multiple="false"
            :selectAllEnabled="false"
            :value="branchReferenceId"
            @inputSelected="onBranchSelect"
        />
      </v-col>
      <v-col md="12" class="pt-0">
        <address-form-with-autocomplete ref="addressForm"/>
      </v-col>
    </v-row>
    <v-row class="pr-2 pl-2">
      <v-col md="6" class="pt-0">
        <time-zones-dropdown
            :value="timezoneName"
            @onTimeZoneChange="changeTimeZone"
        />
      </v-col>
      <v-col md="6" class="pt-0">
        <v-text-field
            v-model="minCommissionPercentage"
            label="Minimum Commission at"
            placeholder=" "
            id="market-min-commission-percentage"
            prefix="%"
            :maxlength="2"
            hint="default minimum commission at 21% margin"
            :rules="[rules.allowedRange]"
            validate-on-blur
            persistent-placeholder
            v-facade="'##'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rules from "../../assets/rules";
import BranchesAutocomplete from "../searchFields/BranchesAutocomplete.vue";
import AddressFormWithAutocomplete from "../common/form/AddressFormWithAutocomplete.vue";
import StatusDropdown from "../common/form/StatusDropdown.vue";
import TimeZonesDropdown from "../common/form/TimeZonesDropdown.vue";

const formDefaults = {
  id: null,
  name: null,
  addressZipCode: null,
  branchName: null,
  branchReferenceId: null,
  active: true,
  referenceId: null,
  timezoneName: null,
  minCommissionPercentage: 0
};

export default {
  components: {
    BranchesAutocomplete,
    AddressFormWithAutocomplete,
    StatusDropdown,
    TimeZonesDropdown
  },

  data() {
    return this.setFormDefaults();
  },

  methods: {
    setFormDefaults() {
      return Object.assign(
          {},
          {
            rules: {
              required: Rules.required,
              isReferenceId: Rules.isMarketReferenceId,
              allowedRange: value => Rules.numberBetweenTwoValues(value, 0, 32, "Min Commission")
            }
          },
          formDefaults
      );
    },
    resetFormDefaults() {
      this.$refs.addressForm.clearAutocomplete();
      this.loadForm(this.setFormDefaults());
    },
    onBranchSelect(branchReferenceId) {
      this.branchReferenceId = branchReferenceId;
    },
    changeStatus(value) {
      this.active = value;
    },
    changeTimeZone(value) {
      this.timezoneName = value;
    },
    buildPayload() {
      let payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      payload.referenceId = this.referenceId;
      payload.name = this.name;
      payload.zipCode = this.addressZipCode;
      payload.branchReferenceId = this.branchReferenceId;
      payload.active = this.active;
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      payload.timezoneName = this.timezoneName;
      payload.minCommissionPercentage = this.minCommissionPercentage;
      return payload;
    },
    loadForm(payload) {
      if (!payload) {
        payload = Object.assign(formDefaults);
      }
      this.referenceId = payload.referenceId;
      this.name = payload.name;
      this.branchName = payload.branchName;
      this.branchReferenceId = payload.branchReferenceId;
      this.active = payload.active;
      this.timezoneName = payload.timezoneName;
      this.minCommissionPercentage = payload.minCommissionPercentage;
      this.$refs.addressForm.setAddressData(payload);
    }
  }
};
</script>
