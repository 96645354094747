<template>
  <div class="days-off-wrapper pa-2">
    <v-col cols="12">
      <v-row>
        <v-col cols="2" ml-4>
          <sales-reps-days-off-dropdown
              :salesReps="salesRepNamesList"
              :value="selectedSalesRep"
              @onSalesRepSelected="filterTableRowsBySalesRep"
              @selectionCleared="refreshList"
          />
        </v-col>
      </v-row>
      <v-divider class="days-off-divider"/>
    </v-col>
    <headers/>
    <v-card
        flat
        class="pa-2 mb-1"
        v-for="day in filteredAllDaysOff"
        :key="day.id"
        :class="rowLayout(day.status)"
    >
      <table class="line-items body-2 mt-0 mb-4">
        <tr class="item-row">
          <td width="6%">
            <v-text-field
                v-model="day.createdAt"
                class="short-input left-aligned"
                placeholder=" "
                disabled
            />
          </td>
          <td width="6%">
            <v-text-field
                v-model="day.startDate"
                class="short-input left-aligned"
            />
          </td>
          <td width="6%">
            <v-text-field
                v-model="day.endDate"
                class="short-input left-aligned"
            />
          </td>
          <td width="4%" class="pt-0 pl-3">
            <v-icon
                class="repeatable-icon"
                v-if="day.repeat"
            >
              check_circle_outline
            </v-icon>
          </td>
          <td width="28%" class="pt-0">
            <v-text-field
                v-model="day.description"
                class="short-input left-aligned"
            />
          </td>
          <td width="12%" class="pt-0">
            <v-text-field
                v-model="day.fullName"
                class="short-input left-aligned"
            />
          </td>
          <td width="10%" class="pt-0">
            <v-text-field
                v-model="day.markets"
                class="short-input left-aligned"
            />
          </td>
          <td width="11%" class="pt-0">
            <v-btn
                :color="customButtonLayout(day.status)"
                class="statusClass"
                depressed
            >
              {{ day.status }}
            </v-btn>
          </td>
          <td width="70px"></td>
          <td v-if="showActionControls(day.status)">
            <day-off-dropdown
                style="height: 30px"
                :dayOff="day"
                @onStatusChange="changeDayOffStatus"
            />
          </td>
          <v-btn
              v-if="showActionControls(day.status)"
              color="primary"
              class="short-input left-aligned mt-2"
              :disabled="!activateConfirmButton(day.id)"
              :loading="loading"
              @click="approveStatus"
          >
            Confirm
          </v-btn>
        </tr>
      </table>
      <!--  days off total days-->
      <v-row :key="day.id">
        <v-col d-flex md="1" ml-3 mb-2>
          Total days: {{ countDaysInRange(day.status, day.startDate, day.endDate, day.workingDays) }}
        </v-col>
        <v-col d-flex md="3" ml-3 mb-2 v-if="day.workingDays.length">
          Repeat every: {{ showRepeatableDays(day) }}
        </v-col>
      </v-row>
      <!--  end days off total days-->
    </v-card>
    <error-modal
        ref="errorModal"
        title="Error"
        :error="error"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </div>
</template>

<script>
import Headers from "@/components/daysOff/DaysOffHeaders.vue";
import DaysOff, {DayOffStatus} from "../../rest/daysOff";
import DayOffDropdown from "@/components/common/form/DayOffDropdown.vue";
import moment from "moment";
import SalesRepsDaysOffDropdown from "../../components/searchFields/SalesRepsDaysOffDropdown";
import ErrorModal from "@/components/navigation/ErrorModal.vue";

export default {
  components: {
    SalesRepsDaysOffDropdown,
    Headers,
    DayOffDropdown,
    ErrorModal
  },

  props: {
    dayOff: null,
  },

  data() {
    return {
      allDaysOff: {type: Array, required: true},
      filteredAllDaysOff: {type: Array, required: true},
      salesRepDayOffId: null,
      actionValue: null,
      salesRepNamesList: {type: Array, required: true},
      isFiltered: false,
      selectedSalesRep: null,
      isErrorModalOpen: false,
      error: null,
      loading: false
    };
  },

  created() {
    this.getAllDaysOffData();
  },

  methods: {
    async getAllDaysOffData() {
      try {
        const response = await DaysOff.getRestApi().getAllDaysOff();
        let revived = [];
        let unRevived = [];
        response.data.forEach((element) => {
          element.createdAt = moment(String(element.createdAt)).format('YYYY-MM-DD');
          element.status === DayOffStatus.WAITING_FOR_DELETION ? element.status = 'WAITING FOR DELETION' : element.status;
          (element.status === DayOffStatus.PENDING || element.status === 'WAITING FOR DELETION')
              ? revived.push(element) : unRevived.push(element);
        });
        this.salesRepNamesList = this.fulfillSalesRepsList(revived.concat(unRevived));
        this.filteredAllDaysOff = revived.concat(unRevived);
        return this.allDaysOff = revived.concat(unRevived);
      } catch (error) {
        throw error;
      }
    },
    approveStatus() {
      this.changeRequestStatus(this.actionValue, this.salesRepDayOffId)
    },
    changeDayOffStatus(value, salesRepDayOffId) {
      this.salesRepDayOffId = salesRepDayOffId;
      this.actionValue = value;
    },
    async changeRequestStatus(value, salesRepDayOffId) {
      if (value !== null && salesRepDayOffId !== null) {
        this.loading = true;
        const payload = {
          id: salesRepDayOffId,
          status: (value === 'confirm') ? DayOffStatus.APPROVED : DayOffStatus.DECLINED
        };
        let response;
        try {
          response = await this.patchDayOff(payload);
        } catch (error) {
          this.loading = false;
        }
        if (response) {
          await this.getAllDaysOffData();
          this.refreshList();
        }
      }
    },
    async patchDayOff(payload) {
      try {
        return await DaysOff.getRestApi().patchDayOff(payload.id, payload);
      } catch (error) {
        this.showErrorModal(error);
      }
    },
    refreshList() {
      this.filteredAllDaysOff = this.allDaysOff;
      this.isFiltered = false;
      this.selectedSalesRep = null;
      this.loading = false;
    },
    showActionControls(status) {
      return status === DayOffStatus.PENDING || status === 'WAITING FOR DELETION';
    },
    rowLayout(status) {
      return !this.showActionControls(status) ? 'grey lighten-4' : "";
    },
    customButtonLayout(status) {
      if (status === DayOffStatus.APPROVED) {
        return 'green lighten-4';
      } else if (status === DayOffStatus.PENDING) {
        return 'amber lighten-4';
      } else if (status === DayOffStatus.DECLINED) {
        return 'deep-orange lighten-4';
      }
    },
    filterTableRowsBySalesRep(value) {
      this.selectedSalesRep = value;
      this.filteredAllDaysOff = this.allDaysOff.filter((salesRep) => {
        return salesRep.fullName === value;
      });
      this.isFiltered = true;
    },

    activateConfirmButton(id) {
      return !!(this.salesRepDayOffId === id && this.actionValue)
    },
    countDaysInRange(status, startDate, endDate, workingDays) {
      if (status === DayOffStatus.DELETED || status === DayOffStatus.DECLINED) {
        return 0
      } else if (startDate === null || endDate === null) {
        return 1;
      } else {
        let start = moment(startDate).toDate();
        let end = moment(endDate).toDate();
        return (workingDays.length > 0)
            ? this.getCountOfRepeatableDays(start, end, workingDays)
            : this.getTotalDaysInRange(start, end);
      }
    },
    fulfillSalesRepsList(salesRepNamesList) {
      let salesReps = [];
      salesRepNamesList.forEach((salesRep) => {
        salesReps.push(salesRep.fullName);
      });
      return salesReps;
    },
    showRepeatableDays(day) {
      return Object.values(day.workingDays).map(value => {
        return " " + value.substring(0, 3)
      }).toString();
    },
    showErrorModal(error) {
      this.error = error.response ? error.response.data.message : error;
      this.isErrorModalOpen = true;
      throw new Error(error);
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    getTotalDaysInRange(startDate, endDate) {
      return Math.round(((endDate - startDate)) / (1000 * 60 * 60 * 24) + 1);
    },
    getCountOfRepeatableDays(start, end, workingDays) {
      let count = 0;
      let loop = start;
      while (loop <= end) {
        workingDays.forEach((element) => {
          if (element.toLowerCase() === moment(loop).format('dddd').toLowerCase()) {
            count++;
          }
        });
        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      return count;
    }
  }
};
</script>

<style>
.days-off-divider {
  padding-bottom: 20px;
}
.repeatable-icon{
  color: #6699bb !important; caret-color: #6699bb !important;
}
.statusClass {
  pointer-events: none;
  top: 3px;
  height: 33px;
  min-width: 187px;
}
</style>
