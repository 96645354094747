<template>
  <v-card light>
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Lead Wizard</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">Enter Phone Number</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">Customer</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">Address</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4">Scheduling</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <stepper-transition v-show="loading"/>
          <v-card class="pl-4 pr-4 pb-4" height="auto">
            <v-form ref="customerPhoneForm" @submit.prevent="onSubmitFindCustomerPhone">
              <v-container v-show="!loading">
                <v-row class="mt-0">
                  <v-col cols="12" class="pa-0">
                    <h3 class="text-md-left text-lg-left">Search for Customer</h3>
                  </v-col>

                  <v-col cols="12" class="pa-0">
                    <p class="mb-8 text-md-left text-lg-left">
                      Enter the customer's cell phone number in the field below to
                      being searching.
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                        label="* Cell Phone"
                        id="searchPhoneValue"
                        ref="searchPhoneValue"
                        placeholder="555-555-1212"
                        persistent-placeholder
                        v-model="searchPhoneValue"
                        v-facade="phoneNumberMask"
                        :rules="[rules.required, rules.phoneNumber]"
                        :append-icon="defineIcon"
                        autofocus
                        @input="validateIsNewPhone(searchPhoneValue)"
                        @paste="onPaste"
                    />
                  </v-col>
                </v-row>

                <div>
                  <v-row>
                    <v-col cols="12">
                      <v-toolbar-title
                          class="text-center mt-1 mb-1"
                          v-if="customersWithTheSamePrimaryPhone"
                      >
                        Select Customer:
                      </v-toolbar-title>
                    </v-col>

                    <v-col cols="12">
                      <customers-table
                          ref="customersWithTheSamePhonesTable"
                          v-if="customersWithTheSamePrimaryPhone"
                          :customers="customersWithTheSamePrimaryPhone"
                          @customerSelected="onCustomerSelected"
                      />
                    </v-col>
                  </v-row>
                </div>

                <v-row class="mt-2 pr-0">
                  <v-col cols="12" align-content-end>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          large
                          dark
                          color="primary"
                          outlined
                          @click="emitCancel"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          :disabled="isCheckForCustomerButtonDisabled"
                          type="submit"
                          large
                          dark
                          color="primary"
                      >
                        {{ checkCustomerButtonText }}
                      </v-btn>
                      <v-btn
                          v-show="showCreateNewCustomerButton"
                          large
                          dark
                          color="primary"
                          @click="createNewCustomer"
                      >
                        {{ createNewCustomerButtonText }}
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <stepper-transition v-show="loading"/>
          <v-card class="pl-4 pr-4 pb-4">
            <alert-form-error
                :formError="formErrorStep2"
                @clearError="clearStepTwoError"
            />
            <v-form ref="customerDetailsForm" @submit.prevent="onSubmitCustomer" lazy-validation>
              <v-container grid-list-lg>
                <v-row class="pa-2">
                  <h3 class="pl-1">
                    {{ step === 2 && !!customer ? "Verify Customer" : "New Customer" }}
                  </h3>
                </v-row>
                <div class="mt-2 mb-2">
                  <v-row v-if="!!customer" class="pa-3">
                    <p class="text-left pr-1 pt-1 pb-1">
                      Review the information below with the customer to verify
                      their identify.
                    </p>
                  </v-row>
                  <v-row v-else>
                    <p class="text-left pa-3">
                      Create a new customer by entering their information and clicking 'Save'.
                      Or press 'Cancel' to return to the previous screen and select existing customer.
                    </p>
                  </v-row>
                </div>

                <div v-show="!loading">
                  <customer-details
                      ref="customerDetails"
                      :isPhoneOwnerBlockHidden="isPhoneOwnerBlockHidden"
                      :disabled="!!customer"
                      :validEmailPresent="validEmailPresent"
                      :emailRequired="emailNotFoundItsNewCustomer"
                      @cancelClicked="emitCancel"
                  />
                </div>

                <v-row class="mt-2 pr-0">
                  <v-col cols="12" align-content-end>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="button"
                          large
                          dark
                          color="primary"
                          outlined
                          @click="cancelSecondStep"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          class="button"
                          type="submit"
                          ref="customerContinueButton"
                          large
                          dark
                          color="primary"
                      >
                        {{ customerDetailsFormSubmitLabel }}
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <stepper-transition v-show="loading"/>
          <v-card class="pl-4 pr-4 pb-4">
            <v-form v-show="!loading" ref="leadAddressForm" @submit.prevent="onSubmitAddress">
              <v-container grid-list-lg>
                <v-row>
                  <lead-address
                      ref="leadAddress"
                      submit-label="Create Lead"
                      formType="create"
                      :customer="customer"
                      :promo-code-editable="!leadServiceable"
                      :leadServiceable="leadServiceable"
                      :editing="false"
                      @onError="onError"
                      @zipCodeChanged="onZipCodeChanged"
                  />
                  <v-col cols="12" align-content-end v-show="!isMarketAccessed" style="color: #900; background: #fdf2f5">
                    You have no access to create Leads in this market.
                  </v-col>
                  <v-col cols="12" align-content-end>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          large
                          dark
                          color="primary"
                          outlined
                          @click="onCancelAddress"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          type="submit"
                          large
                          dark
                          color="primary"
                      >
                        Continue
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <stepper-transition v-show="loading"/>
          <alert-form-error
              :formError="formErrorStep4"
              @clearError="clearStepFourError"
          />
          <v-card class="pl-4 pr-4 pb-4">
            <v-form ref="leadScheduleForm" @submit.prevent="onSubmitSchedule">
              <v-container grid-list-lg>
                <v-layout row wrap>
                  <v-flex v-show="!isLeadServiceable">
                    <non-serviceable-info/>
                  </v-flex>
                  <lead-schedule-form-editable
                      v-show="isLeadServiceable"
                      ref="leadSchedule"
                      submit-label="Create Lead"
                      formType="create"
                      :customer="customer"
                      :marketId="marketId"
                      :marketRefId="marketRefId"
                      :promo-code-editable="false"
                      :leadServiceable="true"
                      :editing="true"
                      :manualAssignmentAllowed="manualAssignmentAllowed"
                      :isSameDayAllowed="true"
                      @onError="onError"
                  />
                  <v-flex xs12 align-content-end>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn large dark color="primary" outlined @click="onCancelSchedule">Cancel</v-btn>
                      <v-btn type="submit" large dark color="primary" :loading="inProgress">Save</v-btn>
                    </v-card-actions>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import Customers from "../../rest/customers";
import Markets from "../../rest/markets";
import Leads from "../../rest/leads";
import CustomerDetails from "../../components/customers/CustomerDetailsForm";
import LeadAddress from "../../components/leads/LeadAddressFormEditable";
import LeadScheduleFormEditable from "../../components/leads/LeadScheduleFormEditable";
import NonServiceableInfo from "../../components/leads/NonServiceableInfo.vue";
import Constants from "../../assets/constants";
import Rules from "../../assets/rules";
import PhoneMask from "../../assets/phoneMask";
import AlertFormError from "../common/AlertFormError.vue";
import DatePickerHelper from "../../assets/datePickerHelper";
import FormattingHelper from "../../assets/formattingHelper";
import CustomersTable from "@/components/customers/CustomersTable.vue";
import ValidationHelper from "@/assets/validationHelper";
import StepperTransition from "../common/loaders/StepperTransition.vue";

export default {
  data: () => ({
    inProgress: false,
    customer: null,
    address: null,
    marketId: null,
    marketRefId: null,
    step: 1,
    formErrorStep2: null,
    formErrorStep4: null,
    steps: 4,
    searchPhoneValue: "",
    leadServiceable: false,
    marketAccessed: true,
    emailHasBeenUpdated: false,
    rules: {
      required: Rules.required,
      phoneNumber: Rules.phoneNumberWithDelimiter
    },
    phoneNumberMask: Constants.phoneNumberMask,
    originalCustomerEmail: null,
    customersWithTheSamePrimaryPhone: null,
    selectedCustomer: null,
    selectedCustomerWithDuplicatedPhone: false,
    initialCustomersToSelect: null,
    loading: true,
    manualAssignmentAllowed: false
  }),

  components: {
    CustomersTable,
    AlertFormError,
    CustomerDetails,
    LeadAddress,
    LeadScheduleFormEditable,
    StepperTransition,
    NonServiceableInfo
  },

  props: {
    visible: Boolean,
    initialPhoneVal: String
  },

  mounted() {
    if (this.initialPhoneVal) {
      this.setCustomersToSelect(this.initialPhoneVal, true);
    }
  },

  watch: {
    initialPhoneVal: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.searchPhoneValue = newVal;
          this.validateIsNewPhone(newVal);
        }
      }
    },
    step: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 300);
        }
      }
    }
  },

  computed: {
    customerDetailsFormSubmitLabel() {
      return this.customer ? "Continue" : "Save";
    },
    isLeadServiceable() {
      return this.leadServiceable;
    },
    isMarketAccessed() {
      return this.marketAccessed;
    },
    validEmailPresent() {
      return Rules.validEmail(this.customer?.email);
    },
    emailNotFoundItsNewCustomer() {
      return !this.originalCustomerEmail;
    },
    isCheckForCustomerButtonDisabled() {
      return !this.selectedCustomerWithDuplicatedPhone && !!this.customersWithTheSamePrimaryPhone;
    },
    defineIcon() {
      if (!this.searchPhoneValue) {
        return '';
      } else {
        return !this.customersWithTheSamePrimaryPhone || this.searchPhoneValue.length !== Constants.validPhoneLengthWithoutHyphens
            ? 'mdi-cellphone-check'
            : 'mdi-text-account';
      }
    },
    isPhoneOwnerBlockHidden() {
      return this.step === 2 && !!this.customer;
    },
    checkCustomerButtonText() {
      return this.selectedCustomer ? 'Use Selected Customer' : 'Create New Customer';
    },
    showCreateNewCustomerButton() {
      return this.customersWithTheSamePrimaryPhone != null;
    },
    createNewCustomerButtonText() {
      return 'Create New Customer';
    },
  },

  methods: {
    openStep(step) {
      this.step = step;
    },
    async resetWizard(resetPhone = true) {
      this.step = 1;
      this.customer = null;
      this.formErrorStep2 = null;
      this.formErrorStep4 = null;
      if (resetPhone) {
        this.searchPhoneValue = "";
      }
      this.leadServiceable = false;
      this.marketAccessed = true;

      // Override blank default.
      if (this.initialPhoneVal && !this.selectedCustomer) {
        this.searchPhoneValue = this.initialPhoneVal;
      }

      // Reset forms
      if (resetPhone) {
        this.$refs.customerPhoneForm.reset();
      }
      this.$refs.customerDetailsForm.reset();
      this.$refs.leadAddressForm.reset();
      this.$refs.leadScheduleForm.reset();

      // Reset components
      this.$nextTick(() => {
        this.$refs.searchPhoneValue.focus();
      });
      this.$refs.leadAddress.reset();
      if (this.$refs.leadSchedule) {
        this.$refs.leadSchedule.reset();
      }
    },
    cancel() {
      this.emailHasBeenUpdated = false;
      this.resetWizard(true);
    },
    cancelSecondStep() {
      this.resetWizard(false);
    },
    showError(error, step) {
      if (step === 2) {
        this.formErrorStep2 = error;
      } else if (step === 4) {
        this.formErrorStep4 = FormattingHelper.addLineBreakAfterFirstPeriod(error.response.data.message);
      }
    },
    async onSubmitFindCustomerPhone() {
      let phoneToValidate = PhoneMask.unMaskPhone(this.searchPhoneValue);
      let valid = this.$refs.customerPhoneForm.validate();
      try {
        if (valid) {
          if (this.selectedCustomer) {
            this.customer = this.selectedCustomer;
          } else {
            const response = await Customers.getRestApi().getCustomersByPrimaryOrAlternatePhone(phoneToValidate);
            this.customer = response.data[0];
          }
          this.createNewCustomerOnSecondStep(false);
        }
      } catch (error) {
        this.step = 2;
        this.customer = null;
        this.$refs.customerDetailsForm.reset();
        this.$refs.customerDetails.setPhone(this.searchPhoneValue);
      }
    },
    createNewCustomerOnSecondStep(isNew) {
      let customerData = isNew ? {primaryPhone: this.searchPhoneValue} : this.customer;
      //TODO: Totally refactor LEAD WIZARD during VUE 3 migration, setting undefined for 'ownershipText' computed property
      customerData.isPrimaryPhoneOwner = isNew ? null : undefined;
      this.originalCustomerEmail = this.customer?.email;
      this.$refs.customerDetails.loadForm(customerData);
      this.step = 2;
      this.$nextTick(this.$refs.customerDetails.$refs.customerEmail.focus);
    },
    async onSubmitCustomer() {
      let customerEmail = this.$refs.customerDetails.email;
      let isCustomerEmailValid = this.$refs.customerDetails.email;
      let isEmailRequired = this.$refs.customerDetails.emailRequired;
      if ((!customerEmail || !isCustomerEmailValid) && isEmailRequired) {
        this.$refs.customerDetailsForm.validate();
        return;
      }

      if (this.customer) {
        this.$refs.leadScheduleForm.reset();
        if (this.originalCustomerEmail !== customerEmail) {
          const response = await this.updateCustomersEmail(this.customer.referenceId, customerEmail);
          if (!response) {
            this.showError('Customer email update failed. Please enter a valid email address', 2);
            return;
          }
          this.emailHasBeenUpdated = true;
          this.customer.email = response.email;
          this.$emit('customerEmailUpdatedSuccess', response.email);
        }
        this.step = 3;
        return; // skip customer create if already created.
      }

      const formValid = this.$refs.customerDetailsForm.validate();

      try {
        if (formValid) {
          this.inProgress = true;
          const payload = this.$refs.customerDetails.buildPayload();
          const payloadWithMarket = await this.addMarketIdToPayload(payload);
          const response = await Customers.getRestApi().createCustomer(payloadWithMarket);
          this.customer = response.data;
          this.$refs.leadScheduleForm.reset();
          this.step = 3;
          this.$emit('successCustomer');
        }
      } catch (error) {
        this.showError(error, 2);
      }
      this.inProgress = false;
    },
    async addMarketIdToPayload(payload) {
      try {
        const newPayload = {...payload};
        const marketData = await this.getMarketByZipCode(payload.zipCode);
        newPayload.marketId = marketData ? marketData.marketId : null;
        return newPayload;
      } catch (error) {
        throw error;
      }
    },
    async getMarketByZipCode(zipCode) {
      try {
        const marketZipCode = await Markets.getRestApi().getMarketByZipCode(zipCode);
        return marketZipCode.data;
      } catch (error) {
        if (error.response.status !== 404) {
          throw error;
        }
      }
    },
    async onSubmitAddress() {
      let valid = this.$refs.leadAddressForm.validate();
      if (valid) {
        const payload = this.$refs.leadAddress.buildPayload();
        let result = await Markets.getRestApi().checkAccessToMarketByZipCode(payload.zipCode);
        this.marketAccessed = result.data;
        if (this.marketAccessed) {
          await this.validateZipCodeMarket(payload.zipCode);
          this.address = payload;
          this.$refs.leadScheduleForm.reset();
          this.$refs.leadSchedule.reset();
          this.step = 4;
        }
      }
    },
    async onZipCodeChanged() {
      this.$refs.leadSchedule.reset();
    },
    onCancelAddress() {
      this.openStep(2);
    },
    async validateZipCodeMarket(zipCode) {
      if (zipCode !== undefined && zipCode !== null) {
        try {
          const marketZipCode = await Markets.getRestApi().getMarketByZipCode(zipCode);
          if (marketZipCode.data.marketId) {
            this.marketId = marketZipCode.data.marketId;
            this.marketRefId = marketZipCode.data.marketReferenceId;
            this.manualAssignmentAllowed = !marketZipCode.data.automaticLeadAssignmentEnabled;
            this.onSetServiceable();
          } else {
            this.marketId = null;
            this.onSetNonServiceable();
          }
          // set lead status to new
        } catch (error) {
          this.marketId = null;
          this.onSetNonServiceable();
        }
      }
    },
    async onSubmitSchedule() {
      if (this.isLeadServiceable) {
        await this.handleLeadServiceableSubmit();
      } else {
        await this.handleLeadNonServiceableSubmit();
      }
      this.customersWithTheSamePrimaryPhone = null;
      this.restoreOriginalPhoneValue();
    },
    async handleLeadServiceableSubmit() {
      let valid = this.$refs.leadScheduleForm.validate();
      try {
        if (valid) {
          this.inProgress = true;
          const leadPayload = this.buildServiceablePayload();
          await Leads.getRestApi().createLead(leadPayload);
          this.$emit('successLead');
          this.$refs.leadScheduleForm.reset();
          this.inProgress = false;
          if (this.emailHasBeenUpdated) {
            this.$emit('refreshDetailsAfterCustomerEmailUpdated');
          }
        }
      } catch (error) {
        if (error.status && error.status === 403) {
          this.marketAccessed = false;
        }
        // made for notes more than 2048 symbols
        if (error.response.status === 400) {
          this.$emit('onError', error);

        } else if (error.response.status === 409) {
          this.showError(error, 4);
        } else {
          //investigate error throwing. Looks like we do not show any kind of errors from BE
          this.showError(error, 4);
        }
        this.inProgress = false;
      }
    },
    async handleLeadNonServiceableSubmit() {
      try {
        this.inProgress = true;
        const leadPayload = this.buildNonServiceablePayload();
        await Leads.getRestApi().createLead(leadPayload);
        this.$emit('successLead');
        this.inProgress = false;
      } catch (error) {
        if (error.status && error.status === 403) {
          this.marketAccessed = false;
        }
        this.showError(error, 4);
        this.inProgress = false;
      }
    },
    buildNonServiceablePayload() {
      let payload = {};

      payload.customerReferenceId = this.customer.referenceId;
      payload.leadStatusId = this.leadStatusId;

      // Address payload
      payload.address = this.address.address;
      payload.city = this.address.city;
      payload.state = this.address.state;
      payload.zipCode = this.address.zipCode;

      payload.id = this.id;

      return payload;
    },
    buildServiceablePayload() {
      let payload = {};
      payload.customerReferenceId = this.customer.referenceId;
      payload.leadStatusId = this.leadStatusId;

      // Address payload
      payload.address = this.address.address;
      payload.city = this.address.city;
      payload.state = this.address.state;
      payload.zipCode = this.address.zipCode;
      payload.latitude = this.address.latitude;
      payload.longitude = this.address.longitude;

      // Use market id from previous step
      payload.marketId = this.marketId;

      // Fetch Schedule from component
      const schedule = this.$refs.leadSchedule.buildPayload();

      const sanitizedAppointmentDate = DatePickerHelper.addUtcOffset12Hours(schedule.appointmentDate);

      payload.appointmentTime = schedule.appointmentTime;
      payload.appointmentDate = sanitizedAppointmentDate;
      payload.promoCodeId = schedule.promoCodeId;
      payload.roomTypes = schedule.roomTypes;
      payload.productTypesIds = schedule.productTypesIds;
      payload.timeBlockId = schedule.timeBlockId;
      payload.salesRepReferenceId = schedule.salesRepReferenceId;
      payload.note = schedule.note;
      payload.isHomeOwner = schedule.isHomeOwner;
      payload.isRehash = schedule.isRehash;

      payload.id = this.id;

      return payload;
    },
    async updateCustomersEmail(customerReferenceId, customerEmail) {
      try {
        const response = await Customers.getRestApi().updateCustomerEmail(customerReferenceId, {email: customerEmail});
        return response.data;
      } catch (error) {
        this.showError(error, 2);
      }
    },
    onCancelSchedule() {
      this.step = 3;
      this.marketAccessed = true;
    },
    async onSubmitLead() { //unused????
      let valid = this.$refs.leadDetailsForm.validate();
      try {
        if (valid) {
          this.inProgress = true;
          const leadPayload = this.$refs.leadDetails.buildPayload();
          await Leads.getRestApi().createLead(leadPayload);
          this.$refs.leadAddress.setFormBackToDefault();
          if (this.$refs.leadSchedule) {
            this.$refs.leadSchedule.setFormBackToDefault();
          }
          this.$emit('successLead');
        }
      } catch (error) {
        this.showError(error, 4);
      }
      this.$refs.leadDetails.reset();
      this.inProgress = false;
    },
    onSetNonServiceable() {
      this.leadServiceable = false;
      this.leadStatusId = 9;
    },
    onSetServiceable() {
      this.leadServiceable = true;
      this.leadStatusId = 1;
    },
    emitCancel() {
      if (this.emailHasBeenUpdated) {
        this.$emit('refreshDetailsAfterCustomerEmailUpdated');
      }
      !this.initialPhoneVal ? this.$refs.customerPhoneForm.reset() : this.searchPhoneValue = this.initialPhoneVal;
      this.$emit('cancel');
      this.clearCustomersData();
    },
    onError(error) {
      this.$emit('onError', error);
    },
    clearStepTwoError() {
      this.formErrorStep2 = null;
    },
    clearStepFourError() {
      this.formErrorStep4 = null;
    },
    async validateIsNewPhone(value) {
      let digitsOnly = value
          ? FormattingHelper.formatCellPhoneWithoutHyphens(value)
          : FormattingHelper.formatCellPhoneWithoutHyphens(this.searchPhoneValue);

      if (this.initialPhoneVal && FormattingHelper.formatCellPhoneWithoutHyphens(this.initialPhoneVal) === digitsOnly) {
        await this.setCustomersToSelect(digitsOnly, false);
        return;
      }

      if (ValidationHelper.isValidPhoneNumber(digitsOnly)) {
        await this.setCustomersToSelect(digitsOnly, false);
        this.searchPhoneValue = value;
      } else {
        this.clearCustomersData();
      }
    },
    async setCustomersToSelect(digitsOnlyPhoneNumber, isFirstCall) {
      const foundCustomers = await this.getAllCustomersByPrimaryPhone(digitsOnlyPhoneNumber);
      if (Array.isArray(foundCustomers) && foundCustomers.length) {
        // show customers for selection
        this.customersWithTheSamePrimaryPhone = foundCustomers;
        if (isFirstCall) {
          this.initialCustomersToSelect = foundCustomers;
        }
      } else {
        this.customersWithTheSamePrimaryPhone = null;
      }
    },
    onPaste(event) {
      let clipped = event.clipboardData.getData('text').split("\n");
      clipped.forEach(item => {
        let copyPastedDigitsOnly = FormattingHelper.formatCellPhoneWithoutHyphens(item);
        let isValidPhone = ValidationHelper.isValidPhoneNumber(copyPastedDigitsOnly);
        if (isValidPhone) {
          this.validateIsNewPhone(copyPastedDigitsOnly);
        }
      })
    },
    clearCustomersData() {
      if (this.$refs.customersWithTheSamePhonesTable) {
        this.$refs.customersWithTheSamePhonesTable.clearSelected();
      }

      // set / clear initial customers to show in lead wizard from customer details page
      if (this.initialPhoneVal && this.initialCustomersToSelect !== null) {
        this.customersWithTheSamePrimaryPhone = this.initialCustomersToSelect;
      } else {
        this.initialCustomersToSelect = null;
      }

      //  clear if nothing entered
      if (!this.searchPhoneValue || !this.initialPhoneVal) {
        this.customersWithTheSamePrimaryPhone = null;
      }

      if (this.initialPhoneVal && (this.searchPhoneValue && !ValidationHelper.isValidPhoneNumber(this.searchPhoneValue))) {
        this.customersWithTheSamePrimaryPhone = null;
      } else {
        this.selectedCustomer = null;
        this.selectedCustomerWithDuplicatedPhone = false;
      }
    },
    async getAllCustomersByPrimaryPhone(phone) {
      try {
        const response = await Customers.getRestApi().findCustomersByPrimaryPhoneNumber(phone, true);
        return response.data;
      } catch (error) {
        throw new error;
      }
    },
    async onCustomerSelected(selected) {
      if (selected) {
        this.selectedCustomerWithDuplicatedPhone = true;
        this.selectedCustomer = selected;
      }
    },
    createNewCustomer() {
      this.createNewCustomerOnSecondStep(true);
    },
    restoreOriginalPhoneValue() {
      if (this.initialPhoneVal && !this.searchPhoneValue) {
        this.searchPhoneValue = this.initialPhoneVal;
      }
    }
  }
};
</script>
