<template>
    <modal-dialog
            ref="deleteAssociatedItem"
            title="Delete Associated Item"
            width="500"
            :visible="dialogVisible"
            :submitEnabled="true"
            submitLabel="Delete"
            cancelLabel="Cancel"
            @submitClicked="onDeleteClicked"
            @cancelClicked="onCancelClicked"
    >
        <v-form ref="form">
            <v-container>
                <div id="delete-file-message"> Do you really want to delete associated item <b>{{ itemFullName }}</b>?
                </div>
            </v-container>
        </v-form>
    </modal-dialog>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";
import CatalogItems from "../../rest/catalogItems";

export default {
    components: {
        ModalDialog
    },

    props: {
        catalogItemId: {
            type: Number,
            required: true
        },
        /// change: catalogItemId requests to internalSku
        internalSku: {
            type: String,
            required: true,
        },
        isMigratedItem: {
            type: Boolean,
            required: true
        },

    },

    computed: {
        itemFullName() {
            return this.cellData.key.internalSku.concat(' - ', this.cellData.key.styleName ? this.cellData.key.styleName : ''
                , ' - ', this.cellData.key.color ? this.cellData.key.color : '');
        },
    },

    data() {
        return {
            cellData: null,
            dialogVisible: false
        }
    },

    methods: {
        openDialog(cellData) {
            this.cellData = cellData;
            this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
        },
        onCancelClicked() {
            this.closeDialog();
        },
        async onDeleteClicked() {
            await this.deleteConfirmed(this.catalogItemId, this.cellData)
        },
        async deleteConfirmed(catalogItemId, cellData) {
            if (!this.isMigratedItem) {
                this.$emit('removeAssociatedItemError', new Error("Associated Product deletion is restricted for non-migrated items"));
            } else {
                try {
                    await CatalogItems.getRestApi().removeCatalogItemAssociatedItem(this.catalogItemId, cellData.data.id);
                    this.$emit('deleteConfirmed');
                    this.closeDialog();
                } catch (error) {
                    this.$emit('removeAssociatedItemError', error);
                }
            }

        }
    }
};
</script>
