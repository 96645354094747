<template>
  <modal-dialog
      ref="dialog"
      title="Edit Sales Commission Percentage"
      width="400"
      :visible="dialogVisible"
      :submitEnabled="true"
      submitLabel="Save Changes"
      cancelLabel="Cancel"
      @submitClicked="onSaveClick"
      @cancelClicked="onCancelClicked"
  >
    <v-form ref="form" v-on:submit.prevent="onSaveClick">
      <v-container>
        <v-row class="mt-3">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  v-model="salesCommissionRow.CommissionPercentage"
                  label="Commission Percentage"
                  placeholder="0.00"
                  persistent-placeholder
                  prefix="%"
                  type="number"
                  :rules="[rules.required, rules.isPercentage]"
                  required
              />
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import Rules from "../../assets/rules";

export default {
  components: {
    ModalDialog
  },

  data() {
    return {
      dialogVisible: false,
      error: null,
      salesCommissionRow: {},
      initialCommission: {},
      rules: {
        required: Rules.required,
        isPercentage: Rules.isPercentage
      }
    };
  },

  methods: {
    reset() {
      this.$refs.form.reset();
    },
    resetFormToDefaults() {
      this.salesCommissionRow = {...this.commission};
    },
    openDialog(row) {
      this.initialCommission = {...row};
      this.salesCommissionRow = {...row};
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.resetFormToDefaults();
    },
    onSaveClick(e) {
      e.preventDefault();
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit('saveCommissionClicked', this.salesCommissionRow);
      }
    },
    onCancelClicked() {
      this.salesCommissionRow.CommissionPercentage = this.initialCommission.CommissionPercentage;
      this.closeDialog();
    }
  }
};
</script>
