<template>
  <modal-dialog
      ref="dialog"
      title="Add Crew"
      width="600"
      submitLabel="Save"
      cancelLabel="Cancel"
      :submitEnabled="true"
      :visible="dialogVisible"
      :loading="loading"
      @submitClicked="onSaveCrewButtonClick"
      @cancelClicked="onCancelClicked"
  >
    <crews-form
        ref="form"
        :capabilities="capabilities"
        :branch="branch"
        :branches="branches"
        :installer="installer"
        @emitError="emitError"
    >
      <template v-slot:mfa-control-tools>
        <user-mfa-control-tools
            :disabled="true"
            :mfaEnabled="true"
            :mfaSetUp="false"
        />
      </template>
    </crews-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import CrewsForm from "./CrewsForm.vue";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";

const formDefaults = {
  dialogVisible: false
};

export default {
  components: {
    UserMfaControlTools,
    ModalDialog,
    CrewsForm
  },

  props: {
    userName: String,
    capabilities: Array,
    branch: Object,
    branches: {
      type: Array,
      required: true
    },
    installer: Object
  },

  data() {
    return Object.assign(
        {
          error: null,
          loading: false
        },
        formDefaults
    );
  },

  methods: {
    reset() {
      this.$refs.form.resetForm();
    },
    openDialog() {
      this.reset();
      this.dialogVisible = true;
      this.stopLoading();
    },
    closeDialog() {
      this.dialogVisible = false;
      this.stopLoading();
      this.reset();
    },
    emitError(error) {
      this.$emit('emitError', error)
    },
    onSaveCrewButtonClick() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        let payloadCrew = this.$refs.form.buildCrewPayload();
        this.$emit('saveCrewButtonClicked', payloadCrew);
      }
    },
    onCancelClicked() {
      this.closeDialog();
    },
    stopLoading() {
      this.loading = false;
    }
  }
};
</script>
