<template>
  <v-text-field
      v-if="show"
      :value="value"
      :label="label"
      placeholder=" "
      persistent-placeholder
      readonly
      append-icon="mdi-cursor-default-click-outline"
      id="custom-text-field-with-hover-and-link"
      class="custom-clickable-field"
      @click="goToShipmentPage"
  />
</template>

<script>
export default {

  props: {
    value: {type: String},
    label: {type: String},
    show: {type: Boolean},
    link: {type: String}
  },

  data() {
    return {}
  },

  methods: {
    goToShipmentPage() {
      this.$router.push(this.link);
    }
  }
};
</script>

<style scoped>
.custom-clickable-field /deep/ .v-input__slot input {
  cursor: pointer !important;
}

.custom-clickable-field /deep/ .v-input__slot input:hover {
  background-color: rgb(242, 242, 252);
}
</style>
