<template>
  <v-container fluid class="mb-2">
    <v-row>
      <v-col cols="1" offset="8">
        <v-text-field
            :value="isMfaEnabled"
            label="MFA Enabled"
            placeholder=" "
            persistent-placeholder
            readonly
            id="is-mfa-enabled"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
            :value="isMfaSetUp"
            label="MFA Set Up"
            placeholder=" "
            persistent-placeholder
            readonly
            id="is-mfa-set-up"
        />
      </v-col>
      <v-col cols="2">
        <status-dropdown :value="user.active" :readonly="true"/>
      </v-col>
    </v-row>

    <v-divider class="detailsDivider"/>

    <v-row>
      <v-col cols="5">
        <v-row>
          <v-col cols="6">
            <v-text-field
                readonly
                label="First Name"
                :value="user.firstName"
                placeholder=" "
                persistent-placeholder
                id="user-read-only-first-name"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                readonly
                label="Last Name"
                :value="user.lastName"
                placeholder=" "
                persistent-placeholder
                id="user-read-only-last-name"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                readonly
                label="Email"
                :value="user.email"
                placeholder=" "
                persistent-placeholder
                id="user-read-only-email"
            />
          </v-col>
          <v-col cols="12" v-if="isBranchUser">
            <v-textarea
                readonly
                auto-grow
                :rows="1"
                label="Branches"
                :value="user.branchNames"
                placeholder=" "
                persistent-placeholder
                id="user-read-only-branches"
            />
          </v-col>
          <v-col cols="6" v-if="isRehashUser">
            <v-text-field
                id="user-phone"
                readonly
                :value="user.phone"
                label="Phone"
                placeholder=" "
                v-facade="phoneNumberMask"
                persistent-placeholder
            />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="5">
        <v-row>
          <v-col cols="12" v-if="user.role">
            <v-text-field
                readonly
                label="Role"
                :value="user.role.description"
                placeholder=" "
                persistent-placeholder
                id="user-read-only-role"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
                readonly
                auto-grow
                :rows="1"
                name="description"
                label="Description / Comments"
                :value="user.description"
                placeholder=" "
                persistent-placeholder
                id="user-read-only-description"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import Roles from "../../assets/roles";
import Constants from "@/assets/constants";

export default {
  components: {StatusDropdown},

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      phoneNumberMask: Constants.phoneNumberMask
    };
  },


  computed: {
    isBranchUser() {
      return this.user?.role ? Roles.isRoleBranchRole(this.user.role.id) : false;
    },
    isRehashUser() {
      return Roles.isRoleRehashRep(this.user?.role?.id);
    },
    isMfaEnabled() {
      return this.user.mfaEnabled ? 'Yes' : 'No';
    },
    isMfaSetUp() {
      return this.user.mfaSetUp ? 'Yes' : 'No';
    }
  }
};
</script>
