<template>
  <v-btn
      text
      color="primary"
      :ripple="false"
      :disabled="editDisabled"
      @click="onClick"
  >
    {{ value }}
  </v-btn>
</template>

<script>
export default {
  props: {
    cellData: Object,
    hasReceivedItems: Boolean
  },

  data() {
    return {
      value: parseFloat(this.cellData.data.quantityOrdered).toFixed(2)
    };
  },

  computed: {
    editDisabled() {
      return this.hasReceivedItems;
    }
  },

  methods: {
    onClick() {
      this.$emit('editValue', this.cellData);
    }
  }
};
</script>

<style scoped>
.short {
  height: 10px;
}
</style>
