<template>
  <div>
    <v-card flat>
      <section-divider title="WARRANTY LINE ITEMS"/>
      <headers/>
      <panel
          :job="job"
          :line-item="warrantyLineItem"
          :key="warrantyLineItem.referenceId"
          @itemDeleted="onItemDeleted"
      />
    </v-card>
  </div>
</template>

<script>
import Headers from "./WarrantyLineItemsHeaders.vue";
import Panel from "./WarrantyLineItemsPanel.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";
import JobWarrantyConfigs from "@/rest/job/jobWarrantyConfig";
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    initialItem: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      warrantyLineItem: null,
      warrantyConfig: null
    };
  },

  created() {
    JobWarrantyConfigs.getRestApi().getJobWarrantyConfig()
        .then(response => {
          this.warrantyConfig = response.data;
        });
  },

  watch: {
    initialItem: {
      immediate: true,
      deep: true,
      handler(value) {
        this.warrantyLineItem = value;
      }
    }
  },

  methods: {
    recalculateWarranty(contractPrice) {
      if (this.warrantyLineItem !== null) {
        this.warrantyLineItem = JSON.parse(JSON.stringify(this.warrantyLineItem)); // create deep copy to trigger watcher ContractLineItem.warrantyLineItem
        this.warrantyLineItem.jobLineItem.price = CurrencyFormatter.asCurrency(contractPrice * this.warrantyConfig.warrantyPercentage);
        this.warrantyLineItem.jobLineItem.salesRepWarrantyCost = CurrencyFormatter.asCurrency(contractPrice * this.warrantyConfig.salesRepWarrantyCostPercentage);
        this.warrantyLineItem.jobLineItem.fiftyFloorWarrantyCost = CurrencyFormatter.asCurrency(contractPrice * this.warrantyConfig.fiftyFloorWarrantyCostPercentage);
        this.$emit("itemUpdated", this.warrantyLineItem);
      }
    },
    onItemDeleted() {
      this.$emit("itemUpdated", null);
    }
  }
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
