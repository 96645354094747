<template>
  <navigation-view ref="nav" page-title="Promo Codes" nav-section="admin">
    <template v-slot:content>
      <v-container v-if="promoCode" grid-list-lg fluid>
        <details-read-only :promoCode="promoCode"/>

        <inline-grids ref="inlineGrids" :promoCodeId="promoCodeId"/>
      </v-container>
    </template>

    <template v-slot:drawer>
      <update-drawer
          ref="update"
          :promoCode="promoCode"
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Promo Code"
          @click="showDrawer"
      />
    </template>

  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import PromoCodes from "@/rest/promoCodes";
import UpdateDrawer from "@/components/promoCodes/UpdateDrawer.vue";
import DetailsReadOnly from "@/components/promoCodes/DetailsReadOnly.vue";
import InlineGrids from "@/views/promoCodes/PromoCodesInlineGrids.vue";
import Roles from "@/assets/roles";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    UpdateDrawer,
    DetailsReadOnly,
    InlineGrids
  },
  data() {
    return {
      promoCodeId: null,
      promoCode: null
    };
  },

  created() {
    this.display(this.$route.params.promoCodeId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.promoCodeId);
    next();
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    display(promoCodeId) {
      this.promoCodeId = Number(promoCodeId);
      this.refresh();
    },

    async refresh() {
      try {
        this.promoCode = await this.getPromoCodeData();
      } catch (error) {
        this.showError(error);
      }
    },
    async getPromoCodeData() {
      try {
        const response = await PromoCodes.getRestApi().getPromoCodeById(this.promoCodeId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    onSubmitSuccess() {
      this.refreshActivityLog();
      this.$refs.nav.hideDrawer();
      this.$refs.nav.showSuccess("Updated promo code details successfully!");
      this.refresh();
    },
    onSubmitFailed(error) {
      this.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    showError(error) {
      this.$refs.nav.showError(error);
    }
  }
};
</script>
