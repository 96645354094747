<template>
  <modal-dialog
      ref="dialog"
      title="Confirm Status Change"
      width="500"
      :visible="statusChange"
      :message="`Do you really want to mark this action as ` + chosenStatus + `? You will not be able to change it back to OPEN`"
      submitLabel="Yes"
      cancelLabel="No"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

export default {
  components: {
    ModalDialog
  },
  props: {
    statusChange: {type: Object | null, required: true},
    chosenStatus: {type: String | null, required: true}
  },

  data() {
    return Object.assign({});
  },

  methods: {
    onCancelClicked() {
      this.$emit('actionStatusUpdateCancelled', this.$props.statusChange);
    },
    onSubmitClicked() {
      this.$emit('actionStatusUpdateConfirmed', this.$props.statusChange);
    },
    emitError(error) {
      this.$emit("changeNextActionStatusError", error);
    }
  }
};
</script>
