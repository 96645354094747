<template>
  <v-form ref="createCrewForm">
    <v-container class="pa-4 mt-4">
      <v-row>
        <v-col cols="6">
          <v-text-field
              id="full-name-create"
              :rules="[rules.required]"
              label="* Name"
              v-model="name"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
        <v-col cols="6">
          <status-dropdown
              label="* Status"
              :value="active"
              :readonly="isInstallerInactive()"
              @onStatusChange="changeStatus"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
              :rules="[rules.required, rules.phoneNumber]"
              id="phone-number-create"
              label="* Phone Number"
              v-model="phoneNumber"
              placeholder=" "
              persistent-placeholder
              v-facade="phoneNumberMask"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
              :rules="[rules.required, rules.email]"
              id="email-create"
              label=" * Email"
              v-model="email"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
              id="crew-primary-branch"
              label=" * Primary Branch"
              placeholder="Select primary branch"
              persistent-placeholder
              :value="branch.name"
              readonly
          />
        </v-col>
        <v-col cols="6">
          <branches-dropdown-autocomplete
              ref="branchSearchFieldSecondary"
              id="crew-secondary-branch"
              label="Secondary Branch"
              persistent-placeholder
              placeholder="Select additional branch"
              :required="false"
              :selectAllEnabled="false"
              :clearable="true"
              :branches="branches"
              return-object
              @inputSelected="secondaryBranchInputChanged"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <working-days-autocomplete
              id="working-days-create"
              ref="workingDaysAutocomplete"
              :select-all-enabled=true
              :required="true"
              :disabled="false"
              :multiple="true"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <installation-capabilities-search-field
              ref="installerCapabilitiesSearchField"
              :availableCapabilities="capabilities"
              itemText="name"
              label="* Capabilities"
              placeholder="Select capabilities of the crew"
              :required="true"
              :multiple="true"
              :selectAllEnabled="true"
              @emitError="emitError"
              @inputSelected="capabilitySelected"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <alert-message
              :showAlert="showMfaAlert"
              :value="active"
              :alertMessage="alertMessage"
              @onClearError="clearAlertMessageError"
          />
          <user-mfa-control-tools
              :disabled="isMfaSwitchDisabled"
              :mfaEnabled="mfaEnabled"
              :mfaSetUp="false"
              @mfaEnabledValueChanged="mfaEnabledValueChanged"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-switch
              color="primary"
              label="Email temporary password to user"
              v-model="sendTemporaryPassword"
              @change="switchChanged"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
              v-show="!sendTemporaryPassword"
              id="installer-app-password"
              v-model="installerAppPassword"
              @click:append="newPasswordVisible = !newPasswordVisible"
              :append-icon="newPasswordVisible ? 'visibility_off' : 'visibility'"
              :type="newPasswordVisible ? 'text' : 'password'"
              :rules="[rules.password, rules.requiredField]"
              label=" * Installer app password"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import InstallationCapabilitiesSearchField from "../../../components/searchFields/InstallationCapabilities.vue";
import Constants from "../../../assets/constants";
import StatusDropdown from "../../../components/common/form/StatusDropdown.vue";
import Rules from "../../../assets/rules";
import PhoneMask from "../../../assets/phoneMask";
import BranchesDropdownAutocomplete from "../../../components/searchFields/BranchesDropdownAutocomplete.vue";
import WorkingDaysAutocomplete from "../../../components/searchFields/WorkingDaysAutocomplete.vue";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";
import Roles from "@/assets/roles";
import AlertMessage from "@/components/common/AlertMessage.vue";

const formDefaults = {
  id: null,
  name: null,
  phoneNumber: null,
  email: null,
  active: true,
  selectedCapabilityIds: null,
  phoneNumberMask: Constants.phoneNumberMask,
  primaryBranch: null,
  secondaryBranchRefId: null,
  sendTemporaryPassword: false,
  installerAppPassword: null,
  newPasswordVisible: false,
  mfaEnabled: false,
  showMfaAlert: false,
  alertMessage: ''
};

export default {

  components: {
    AlertMessage,
    UserMfaControlTools,
    StatusDropdown,
    InstallationCapabilitiesSearchField,
    WorkingDaysAutocomplete,
    BranchesDropdownAutocomplete
  },

  props: {
    capabilities: Array,
    branch: Object,
    installer: Object,
    branches: {
      type: Array,
      required: true
    }
  },

  data() {
    return Object.assign(
        {
          rules: {
            required: value => {
              if (typeof value === "number") {
                const result = value >= 0 || "Required";
                return result;
              } else if (typeof value === "string") {
                value = value.trim();
              }
              return !!value || "Required";
            },
            requiredField: (value) => {
              if (this.sendTemporaryPassword) {
                return true;
              } else if (!this.sendTemporaryPassword && !value) {
                return 'Required';
              } else {
                return true;
              }
            },
            password: value => Rules.password(value),
            phoneNumber: Rules.phoneNumberWithDelimiter,
            email: Rules.email
          },
        },
        formDefaults
    );
  },

  computed: {
    isMfaSwitchDisabled() {
      return !this.active || (!Roles.isSuperAdminOrAdminUser() && !Roles.isGeneralManager() && !Roles.isBranchAdmin());
    },
  },

  methods: {
    changeStatus(status) {
      if (this.active !== status) {
        this.active = status;
        this.mfaEnabled = !!status;
        this.showAlertNotification(status);
      }
    },
    emitError(error) {
      this.$emit("emitError", error);
    },
    validate() {
      return this.$refs.createCrewForm.validate();
    },
    buildCrewPayload() {
      let payload = {};
      payload.name = this.name;
      payload.active = this.active;
      payload.phoneNumber = PhoneMask.unMaskPhone(this.phoneNumber);
      payload.email = this.email;
      payload.primaryBranchId = this.branch.id;
      payload.secondaryBranch = this.secondaryBranchRefId;
      payload.workingDays = this.$refs.workingDaysAutocomplete.selected;
      payload.capabilityIds = this.selectedCapabilityIds;
      payload.sendTemporaryPassword = this.sendTemporaryPassword;
      payload.newPassword = this.installerAppPassword;
      payload.installerId = this.installer.id;
      payload.mfaEnabled = this.mfaEnabled;
      return payload;
    },
    resetForm() {
      Object.assign(this.$data, formDefaults);
      if (this.installer && !this.installer.active) {
        this.active = false;
      }
      this.$refs.workingDaysAutocomplete.clearSelectedWorkingDays();
      this.$refs.branchSearchFieldSecondary.clearSelectedBranch();
      this.$refs.installerCapabilitiesSearchField.clearSelectedCapability();
      this.$refs.createCrewForm.resetValidation();
    },
    secondaryBranchInputChanged(value) {
      this.secondaryBranchRefId = value;
    },
    capabilitySelected(value) {
      this.selectedCapabilityIds = value;
    },
    switchChanged(value) {
      this.sendTemporaryPassword = value;
    },
    isInstallerInactive() {
      return !this.installer.active;
    },
    mfaEnabledValueChanged(value) {
      this.mfaEnabled = value;
      this.clearAlertMessageError();
    },
    showAlertNotification(status) {
      this.showMfaAlert = true;
      this.alertMessage = status ? Constants.mfaEnabledMessage : Constants.mfaDisabledMessage;
    },
    clearAlertMessageError() {
      this.showMfaAlert = false;
    }
  }
};
</script>

<style scoped>
.v-text-field >>> .v-input__append-inner {
  margin-right: 10px;
}
</style>
