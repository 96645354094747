<template>
  <v-card>
    <v-card-text>
      Pull
      <strong>{{ itemQuantity }} {{ unitOfMeasure }}</strong> of
      <strong>{{ itemDescription }}</strong> from bin
      <strong>{{ binNumber }}</strong>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: ["reservation"],
  data() {
    return {};
  },

  computed: {
    itemQuantity: function() {
      return this.reservation.inventoryReservation.amount;
    },
    unitOfMeasure: function() {
      return this.reservation.inventoryItem.productCatalogItem.unitOfMeasure
        ? this.reservation.inventoryItem.productCatalogItem.unitOfMeasure
        : "";
    },
    itemDescription: function() {
      return (
        this.reservation.inventoryItem.productCatalogItem.styleName +
        "\\" +
        this.reservation.inventoryItem.productCatalogItem.color
      );
    },
    binNumber: function() {
      return this.reservation.inventoryItem.inventoryBin.binNumber;
    }
  },

  methods: {}
};
</script>
