<template>
  <v-card flat>
    <table class="headers">
      <tr>
        <td width="20%">
          Reference Id
        </td>
        <td width="50%">
          Style
        </td>
        <td width="20%">
          Color
        </td>
        <td width="10%">
          Quantity
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {};
  }
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
</style>
