<template>
  <modal-dialog
      :title="title || 'Confirm Action'"
      :width="width"
      :visible="visible"
      :cancelLabel="cancelLabel"
      :submitLabel="submitLabel"
      :submitEnabled="true"
      :loading="loading"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-row class="ma-2">
      <v-col>
        {{ confirmationText }}
      </v-col>
    </v-row>
  </modal-dialog>
</template>

<script>
import ModalDialog from "./ModalDialog.vue";

export default {
  components: {
    ModalDialog
  },

  props: {
    width: {
      type: Number,
      default: 400
    },
    visible: {
      type: Boolean,
      required: true
    },
    confirmationText: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    submitLabel: {
      type: String,
      required: false,
      default: "Save"
    },
    cancelLabel: {
      type: String,
      required: false,
      default: "Cancel"
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    onSubmitClicked() {
      this.$emit('onSubmitClicked');
    },
    onCancelClicked() {
      this.$emit('onCancelClicked');
    }
  }
};
</script>
