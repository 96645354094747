<template>
  <drawer-form
      ref="form"
      title="Create Installer"
      submitLabel="Save Installer"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container grid-list-lg text-xs-center>
      <installer-edit-form
          ref="installerEditForm"
          :availableCapabilities="availableCapabilities"
          :branches="branches"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "../../components/common/form/DrawerForm.vue";
import InstallerEditForm from "../../components/installers/InstallerEditForm.vue";
import Branches from "../../rest/branches";
import Installers from "../../rest/installers";
import InstallationCapabilities from "../../rest/installationCapabilities";

export default {
  components: {
    DrawerForm,
    InstallerEditForm
  },

  created() {
    this.getInstallationCapabilities();
    this.getBranches();
  },

  data() {
    return {
      inProgress: false,
      availableCapabilities: [],
      branches: []
    };
  },

  methods: {
    onCancel() {
      this.$refs.form.resetValidation();
      this.$refs.installerEditForm.clearAutocomplete();
      this.$refs.installerEditForm.loadForm();
      this.$refs.installerEditForm.clearPickers();
      this.$emit('cancelClicked');
    },
    async onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        let response = await this.createInstaller();
        if (response) {
          this.onCancel();
        }
      }
    },
    async createInstaller() {
      let response;
      try {
        this.inProgress = true;
        const payload = this.$refs.installerEditForm.buildPayload();
        response = await Installers.getRestApi().createInstaller(payload);
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        if (error.message === "Request failed with status code 409") {
          this.$refs.installerEditForm.onNonUniqueNameConflict(this.$refs.installerEditForm.companyName + " already exists");
          this.$refs.installerEditForm.companyName = null;
        }
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
      return response;
    },
    async getInstallationCapabilities() {
      try {
        const response = await InstallationCapabilities.getRestApi().getAllInstallationCapabilities();
        this.availableCapabilities = response.data;
      } catch (error) {
        throw error;
      }
    },
    async getBranches() {
      try {
        const response = await Branches.getRestApi().getAllActiveBranches();
        this.branches = response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
