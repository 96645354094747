<template>
  <modal-dialog
      ref="dialog"
      title="Update Day Off"
      width="500"
      :visible="dialogVisible"
      submitLabel="Update"
      cancelLabel="Cancel"
      @submitClicked="onUpdateDayOffButtonClick"
      @cancelClicked="onCancelClicked"
      :submitEnabled="isSubmitEnabled"
      :loading="loading"
  >
    <crew-day-off-form
        ref="form"
        :crews="crews"
        :dayOff="dayOff"
        :isEditForm="true"/>

    <error-modal
        ref="errorModal"
        :error="errorMessage"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="isErrorModalOpen = false"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

import DaysOffForm from "../../../daysOff/DaysOffForm.vue";
import CrewDayOffForm from "@/views/installers/crews/dayoff/CrewDayOffForm.vue";
import InstallerCrewDayOff from "@/rest/installer/installerCrewDayOff";
import ErrorModal from "@/components/navigation/ErrorModal.vue";
import DateUtils from "@/assets/utils/dateUtils";

const formDefaults = {
  errorMessage: null,
  loading: false,
  isErrorModalOpen: false,
  dialogVisible: false,
  dayOff: null
};
export default {
  components: {
    ErrorModal,
    CrewDayOffForm,
    ModalDialog,
    DaysOffForm
  },

  data() {
    return Object.assign(
        {},
        formDefaults
    );
  },

  props: {
    crews: {
      type: Array,
      required: true
    }
  },

  computed: {
    isSubmitEnabled() {
      return this.dayOff != null && !DateUtils.isPastDate(this.dayOff.endDate);
    }
  },

  methods: {
    reset() {
      Object.assign(this.$data, formDefaults);
      this.$refs.form.resetForm();
    },
    openDialog() {
      this.dialogVisible = true;
      this.stopLoading();
    },
    closeDialog() {
      this.stopLoading();
      this.dialogVisible = false;
      this.reset();
    },
    showError(error) {
      this.errorMessage = error.response.data.message;
      this.isErrorModalOpen = true;
    },
    loadForm(data) {
      this.dayOff = data;
    },
    async onUpdateDayOffButtonClick() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        let payload = this.$refs.form.buildPayload();
        await InstallerCrewDayOff.getRestApi().updateDayOff(payload)
            .then(() => {
              this.$emit('updateDayOffButtonClicked', payload);
              this.closeDialog();
            }).catch((error) => {
              this.stopLoading();
              this.showError(error);
            });
        this.$emit('updateDayOffButtonClicked', payload);
      }
    },
    onCancelClicked() {
      this.$refs.form.resetForm();
      this.closeDialog();
      this.$emit('cancelClicked');
    },
    stopLoading() {
      this.loading = false;
    }
  }
};
</script>
