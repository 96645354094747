<template>
  <navigation-view ref="nav" page-title="Installer Details" nav-section="admin">
    <template v-slot:content>
      <v-container grid-list-lg fluid>
        <installer-read-only :installer="installer"/>
        <installers-inline-grids
            v-if="isInstallerLoaded"
            ref="inlineGrids"
            :installerReferenceId="installerReferenceId"
            :capabilities="capabilities"
            :branch="installerPrimaryBranch"
            :installer="installer"
            :branches="branchesAvailable"
            @showSuccess="showNavSuccess"
            @showError="showNavError"
            @resetMfaSuccess="onResetMfaSuccess"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <installer-update-drawer
          :installer="installer"
          :capabilities="capabilities"
          :branches="branches"
          @cancelClicked="onCancel"
          @onUpdateFail="onUpdateFail"
          @onUpdateSuccess="onUpdateSuccess"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Installer"
          @click="showDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>

import InstallationCapabilities from "../../rest/installationCapabilities";
import InstallerReadOnly from "../../components/installers/InstallerReadOnly.vue";
import InstallerUpdateDrawer from "../../components/installers/InstallerUpdateDrawer.vue";
import NavigationActionButton from "../navigation/NavigationActionButton.vue";
import NavigationView from "../navigation/NavigationView.vue";
import InstallersInlineGrids from "../../views/installers/InstallersInlineGrids.vue";
import Roles from "../../assets/roles";
import Branches from "../../rest/branches";
import Installers from "../../rest/installers";
import Constants from "@/assets/constants";

export default {
  components: {
    InstallerReadOnly,
    InstallerUpdateDrawer,
    NavigationActionButton,
    NavigationView,
    InstallersInlineGrids
  },

  data() {
    return {
      installerReferenceId: "",
      installer: {},
      isInstallerLoaded: false,
      capabilities: [],
      installerPrimaryBranch: null,
      branches: []
    };
  },

  created() {
    this.display(this.$route.params.installerReferenceId);
    this.getBranches();
    this.getInstallationCapabilities();
  },

  // Called when component is reused.
  beforeRouteUpdate(to, from, next) {
    this.display(to.params.installerReferenceId);
    next();
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    },
    branchesAvailable() {
      if (this.installer?.branch) {
        let primaryBranch = this.installer.branch.id;
        return this.branches
            .map(branch => {
              if (branch.id === primaryBranch) {
                return {...branch, disabled: true};
              } else {
                return branch;
              }
            });
      } else {
        return this.branches;
      }
    }
  },

  methods: {
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    display(installerReferenceId) {
      this.installerReferenceId = installerReferenceId;
      this.getInstaller();
    },
    async getInstaller() {
      try {
        const response = await Installers.getRestApi().getInstaller(this.installerReferenceId);
        this.setInstaller(response.data);
      } catch (error) {
        this.showNavError(error);
      }
    },
    setInstaller(response) {
      this.installer = response;
      this.isInstallerLoaded = true;
      this.installerPrimaryBranch = this.installer.branch;
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    async onUpdateSuccess(data) {
      let originalStatus = this.installer.active;
      let updatedStatus = data.active;
      try {
        await this.getInstaller(data);
        this.refreshActivityLog();
        if (originalStatus !== updatedStatus) {
          this.$refs.inlineGrids.onUpdateCrewSuccess();
        }
        this.$refs.nav.hideDrawer();
        this.showNavSuccess("Updated installer details successfully!");
      } catch (error) {
        this.showNavError(error);
      }
    },
    onUpdateFail(error) {
      this.showNavError(error);
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    showNavSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    async getInstallationCapabilities() {
      try {
        const response = await InstallationCapabilities.getRestApi().getAllInstallationCapabilities();
        this.capabilities = response.data;
      } catch (error) {
        throw error;
      }
    },
    async getBranches() {
      try {
        const response = await Branches.getRestApi().getAllActiveBranches();
        this.branches = response.data;
      } catch (error) {
        throw error;
      }
    },
    onResetMfaSuccess() {
      this.showNavSuccess(Constants.mfaResetSuccessMessage);
    },
  }
};
</script>
