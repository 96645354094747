<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card class="stepper-card" height="250">
        <v-row>
          <v-col sm="6">
            <branches-autocomplete
                ref="fromBranch"
                label="* Transfer From"
                placeholder="Search Branch or start typing it's name"
                :defaultBranches="activeBranches"
                :multiple="false"
                :required="true"
                :clearable="false"
                :selectAllEnabled="false"
                :getBranchesOnComponentCreation="false"
                :isReturnObject="true"
                @inputSelected="onFromBranchSelected"
            />
          </v-col>
          <v-col sm="6">
            <branches-autocomplete
                ref="toBranch"
                label="* Transfer To"
                placeholder="Search Branch or start typing it's name"
                :defaultBranches="filteredUserBranches"
                :multiple="false"
                :required="true"
                :clearable="false"
                :selectAllEnabled="false"
                :getBranchesOnComponentCreation="false"
                :isReturnObject="true"
                :readonly="disableDropdown"
                @inputSelected="onToBranchSelected"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-divider/>
    </v-form>
  </v-container>
</template>

<script>
import BranchesAutocomplete from "../../searchFields/BranchesAutocomplete.vue";

export default {
  components: {
    BranchesAutocomplete
  },

  props: {
    activeBranches: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedToBranch: null,
      selectedFromBranch: null,
      disableDropdown: true,
      filteredUserBranches: []
    };
  },

  watch: {
    selectedToBranch: 'branchesWatcher',
    selectedFromBranch: 'branchesWatcher',
    activeBranches: {
      immediate: true,
      handler(value) {
        this.filteredUserBranches = value;
      },
    }
  },

  methods: {
    resetSelected() {
      this.disableDropdown = true;
      this.$refs.fromBranch.reset();
      this.$refs.toBranch.reset();
      this.$refs.form.resetValidation();
    },
    onFromBranchSelected(value) {
      this.selectedFromBranch = value;
    },
    onToBranchSelected(value) {
      this.selectedToBranch = value;
    },
    branchesWatcher() {
      if (this.selectedToBranch?.id === this.selectedFromBranch?.id) {
        this.selectedToBranch = null;
        return;
      }
      if (this.selectedToBranch && this.selectedFromBranch) {
        this.$emit('branchesSelected');
      } else {
        this.$emit('branchSelectionNeeded');
        if (this.selectedFromBranch) {
          this.disableDropdown = false;
          this.filteredUserBranches = this.activeBranches.filter(b => b.id !== this.selectedFromBranch.id);
        }
      }
    },
  }
};
</script>

<style scoped>
.content-row {
  min-height: 290px;
}

.stepper-card {
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}

</style>
