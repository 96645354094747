<template>
  <navigation-view
      ref="nav"
      :page-title="pageTitle"
      :nav-section="navSection"
      :customWizardWidth="customWizardWidth"
  >
    <div id="grid-toolbar" slot="toolbar" v-show="toolsEnabled">
      <grid-toolbar
          :displayColumnChooser="displayColumnChooser"
          @onToggleResetGridDialog="clearGridState"
          @onShowColumnChooser="showColumnChooser"
      />
    </div>

    <template v-slot:content>
      <v-card class="ma-3 mt-5">
        <dx-data-grid
            ref="dataGrid"
            :showColumnLines="true"
            :showRowLines="false"
            :show-borders="true"
            :data-source="dataSource"
            :hover-state-enabled="true"
            :load-panel="loadPanelConfig"
            :columnMinWidth="columnMinWidth"
            :columnAutoWidth="columnAutoWidth"
            :allowColumnResizing="allowColumnResizing"
            :columnResizingMode="columnResizingMode"
            :allowColumnReordering="true"
            :onContentReady="onGridReady"
            :columnChooser="gridColumnChooser"
            :onToolbarPreparing="hideNativeGridToolbar"
            :dateSerializationFormat="null"
            @selectionChanged="onSelectionChanged"
        >
          <dx-filter-row :visible="true"/>
          <dx-header-filter :visible="allowFiltering"/>
          <dx-column-fixing :enabled="true"/>
          <dx-state-storing
              :enabled="gridStore.enabled"
              :type="gridStore.type"
              :savingTimeout="gridStore.savingTimeout"
              :customLoad="onGridLoad"
              :customSave="onGridSave"
          />
          <dx-scrolling :useNative="true" showScrollbarMode="Always"/>
          <dx-paging :page-size="10"/>
          <dx-pager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="true"
              infoText="Page {0} of {1} - {2} items"
          />
          <dx-selection mode="single"/>
          <slot/>

          <template #link="cellData">
            <link-row-cell-template
                :cell-data="cellData"
                file-id-property="LeadQuoteId"
                file-name-property="LeadQuoteFileName"
                @linkClicked="onLinkClicked"
            />
          </template>

          <template #hasNoteTemplate="cellData">
            <has-note-attached
                :cell-data="cellData"
            />
          </template>

          <template #statusWithIcon="cellData">
            <status-icon-cell-template
                :statusName="cellData.text"
                :templateName="templateName"
            />
          </template>

        </dx-data-grid>
      </v-card>
    </template>
    <!-- show toolbar action button -->
    <div :class="[ifShowActionButton]" slot="nav-action">
      <v-btn
          dark
          icon
          small
          class="col-sel-btn ma-0 pa-0"
          color="primary"
          id="toolbar-menu-button"
          @click="toggleToolsEnabled"
          @mouseenter="ifToolbarTool = true"
          @mouseleave="ifToolbarTool = false"
      >
        <v-icon v-if="toolsEnabled">close</v-icon>
        <v-icon v-else>more_vert</v-icon>
        <tool-tip v-if="toolsEnabled" :ifShow="ifToolbarTool" tipText="Close Tools Bar"/>
        <tool-tip v-else :ifShow="ifToolbarTool" tipText="Open Tools Bar"/>
      </v-btn>

      <!-- show create/edit buttons -->
      <navigation-action-button
          class="nav-button"
          :showActionButtons="!!actionButtonLabel"
          :label="actionButtonLabel"
          :secondaryLabel="secondaryActionButtonLabel"
          @click="doPrimaryAction"
          @secondaryButtonClick="doSecondaryAction"
      />
    </div>
    <template v-slot:drawer="{ isDrawerVisible }">
      <slot name="drawerContent" :isDrawerVisible="isDrawerVisible"/>
    </template>

    <div slot="wizard">
      <slot name="wizardContent"/>
    </div>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import {mapGetters} from "vuex";
import {
  DxColumn,
  DxColumnFixing,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSelection,
  DxStateStoring,
} from "devextreme-vue/ui/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import HasNoteAttached from "../../components/common/grid/cellTemplates/HasNoteAttached";
import LinkRowCellTemplate from "../../components/common/grid/cellTemplates/LinkRowCellTemplate";
import StatusIconCellTemplate from "@/components/common/grid/cellTemplates/StatusIconCellTemplate.vue";
import ToolTip from "@/components/common/dialogContainers/ToolTip.vue";
import GridToolbar from "@/components/common/grid/GridToolbar.vue";
import FilterInitializer from "@/components/common/grid/FilterInitializer";
import RoleRestrictor from "@/rest/odata";

export default {
  components: {
    StatusIconCellTemplate,
    NavigationView,
    NavigationActionButton,
    HasNoteAttached,
    LinkRowCellTemplate,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxPager,
    DxPaging,
    DxSelection,
    DxStateStoring,
    ToolTip,
    GridToolbar,
  },

  props: {
    pageTitle: String,
    navSection: String,
    actionButtonLabel: [String, null],
    actionButtonAction: String,
    dataSource: Object,
    columnMinWidth: Number,
    columnAutoWidth: Boolean,
    navAction: Boolean,
    secondaryActionButtonLabel: String,
    allowColumnResizing: Boolean,
    columnResizingMode: String,
    branchUserRestricted: Boolean,
    totalCountColumn: String,
    allowFiltering: {
      type: Boolean,
      default: true,
    },
    displayColumnChooser: {
      type: Boolean,
      default: true
    },
    templateName: String,
    customWizardWidth: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return Object.assign(
        {},
        {
          bulkAssign: false,
          pageSizes: [10, 20, 50],
          loadPanelConfig: {
            enabled: true,
            message: "Loading...",
            showPane: false,
          },
          gridStore: {
            enabled: true,
            type: "custom",
            savingTimeout: 500,
          },
          gridColumnChooser: {
            enabled: true,
            mode: "select",
            height: 500,
          },
          visibleColumns: [],
          columnsWithFilters: [],
          ifRevertTool: false,
          ifColumnTool: false,
          ifToolbarTool: false,
          columnsWithDefaultSortingByDateDesc: ["CreatedAt", "DateIssued"]
        }
    );
  },

  watch: {
    visibleColumns: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.handleFilteredColumns(newVal);
        }
      },
    },
  },

  computed: {
    ifShowActionButton() {
      return this.actionButtonLabel ? "nav-action" : "nav-no-action";
    },
    ...mapGetters(["toolsEnabled"]),
  },

  mounted() {
    FilterInitializer.initializeCustomFilterOperations(this.getGridInstance());
  },

  methods: {
    refreshGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.refresh();
    },
    watchGridComponents() {
      const grid = this.getGridInstance();
      const visibleColumns = grid.getVisibleColumns();
      this.visibleColumns = [...visibleColumns];
    },
    onGridLoad() {
      if (this.pageTitle) {
        const gridStore = this.$store.getters.gridStore;
        if (gridStore && this.pageTitle in gridStore) {
          return gridStore[this.pageTitle];
        }
      }
    },
    onGridSave(state) {
      if (this.pageTitle) {
        this.$store.commit("setGridStore", {
          title: this.pageTitle,
          store: state,
        });
      }
    },
    onGridReady() {
      this.watchGridComponents();
    },
    toggleToolsEnabled() {
      this.ifToolbarTool = !this.ifToolbarTool;
      this.$store.commit('toggleToolbar');
    },
    getGridInstance() {
      return this.$refs.dataGrid.instance;
    },
    onSelectionChanged(event) {
      let data = event.selectedRowsData[0];
      this.$emit('rowSelected', data);
    },
    onLinkClicked(cellData) {
      this.$emit('linkClicked', cellData);
    },
    doPrimaryAction() {
      if (this.actionButtonAction === "leadWizard" || this.actionButtonAction === "bulkPOWizard"
          || this.actionButtonAction === "createTransferRequestWizard" || this.actionButtonAction === "scheduleShipmentWizard") {
        this.$refs.nav.showWizard();
      } else {
        this.$refs.nav.showDrawer();
      }
    },
    doSecondaryAction() {
      this.$refs.nav.showWizard();
    },
    hideWizard() {
      this.$refs.nav.hideWizard();
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    hideDrawer() {
      this.$refs.nav.hideDrawer();
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    showSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    showColumnChooser() {
      const gridInstance = this.getGridInstance();
      gridInstance.showColumnChooser();
    },
    clearGridState() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      RoleRestrictor.applyRoleRestrictions(
          this.$store.state.loggedInUser,
          gridInstance,
          this.branchUserRestricted
      );
      gridInstance.clearSorting();
      this.setDefaultSortingCreatedAtDescByDefault(gridInstance);
      this.resetSelectedFilterOperations();
    },
    setDefaultSortingCreatedAtDescByDefault(gridInstance) {
      let columnCount = gridInstance.columnCount();
      for (let i = 0; i < columnCount; i++) {
        if (this.columnsWithDefaultSortingByDateDesc.includes(gridInstance.columnOption(i).dataField)) {
          gridInstance.columnOption(gridInstance.columnOption(i).dataField, {
            sortOrder: "desc",
            sortIndex: 0
          });
        }
      }
    },
    hideNativeGridToolbar(e) {
      // hide the native grid toolbar element
      // adding the column choser and export button automatically display the native grid toolbar
      // hidden columns do not persist when the column chooser's "enabled" attribute is set to "false"
      e.toolbarOptions.visible = false;
    },
    resetSelectedFilterOperations() {
      const gridInstance = this.getGridInstance();
      let columnCount = gridInstance.columnCount();
      for (var i = 0; i < columnCount; i++) {
        gridInstance.columnOption(i, "selectedFilterOperation", null);
      }
    },
    handleFilteredColumns(newVal) {
      const newColumnsWithFilters = this.getFilteredColumns(newVal);
      this.clearPastFilteredColumnBackgrounds();
      this.setFilteredColumnElementsBackground(newColumnsWithFilters);
      this.columnsWithFilters = newColumnsWithFilters;
    },
    setFilteredColumnElementsBackground(columns) {
      for (let i = 0; columns.length > i; i += 1) {
        const elements = document.querySelectorAll(
            `[aria-colindex="${columns[i]}"]`
        );
        elements.forEach((value, index) => {
          if (value.className.includes("dx-datagrid-action")) {
            elements[index].style.backgroundColor = "#DEEFF5";
          }
        });
      }
    },
    getFilteredColumns(columns) {
      let filteredColumns = [];
      for (var key in columns) {
        for (var columnKey in columns[key]) {
          if (columnKey === "filterValue" || columnKey === "filterValues") {
            if (columns[key].filterValue || columns[key].filterValues) {
              let column = Number(key) + 1;
              filteredColumns.push(column);
            }
          }
        }
      }
      return filteredColumns;
    },
    clearPastFilteredColumnBackgrounds() {
      let columns = this.columnsWithFilters;
      if (columns.length) {
        for (let i = 0; columns.length > i; i += 1) {
          const elements = document.querySelectorAll(
              `[aria-colindex="${columns[i]}"]`
          );

          elements.forEach((value, index) => {
            elements[index].style.backgroundColor = "";
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.nav-action {
  display: flex;
  height: 100%;
}

.nav-no-action {
  background-color: rgb(245, 248, 250);
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.nav-button {
  font-size: 18px;
  min-width: 0;
  flex-grow: 7;
}

.col-sel-btn {
  flex-grow: 1;
  max-width: 30px;
  height: 100%;
  border-radius: 0;
  border-right: 1px solid white !important;
}

.dx-datagrid-header-panel {
  position: absolute !important;
  visibility: hidden !important;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

/* .toolbar-spacing {
  display: flex;
  margin: 0px 10px;
}
.toolbar-button-text {
  display: flex;
  align-items: center;
} */
</style>

<style>
/* stylize toolbar icons */
.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  color: rgba(0, 0, 0, 0.54);
}

/* style checkbox column */
.dx-command-select {
  padding: 0 !important;
}

.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
  background-color: #6699bb;
}

</style>
