<template>
  <v-main class="background">
    <div>
      <v-card flat>
        <main-header-panel
            ref="mainHeaderPanel"
            :pageTitle="pageTitle"
            :loggedInUser="loggedInUser"
            :userNotification="newUserNotification"
            :pagePrefix="pagePrefix"
            @logoutClicked="logout"
            @changePasswordClicked="toggleChangePasswordModal"
            @notificationBarClosed="onNotificationBarClosed"
        />

        <v-row class="mb-4" no-gutters>
          <menu-options
              :nav-section="navSection"
              @goBackButtonClicked="onGoBackButtonClicked"
          />
          <v-col cols="2" class="nav-action">
            <slot name="nav-action"/>
          </v-col>
        </v-row>
      </v-card>

      <div>
        <transition name="fade">
          <slot name="toolbar"/>
        </transition>
        <slot name="content" class="content-slot"/>
      </div>

      <v-dialog
          persistent
          v-model="wizardVisible"
          :width="wizardWidth"
      >
        <slot name="wizard"/>
      </v-dialog>

      <!-- 'disable-resize-watcher' disables the drawer from appearing upon maximizing the browser -->
      <transition name="fade">
        <v-navigation-drawer
            v-model="drawer"
            :width="drawerWidth"
            class="drawer"
            right
            absolute
            disable-resize-watcher
            v-show="drawer"
        >
          <slot name="drawer" :isDrawerVisible="drawer"/>
        </v-navigation-drawer>
      </transition>
      <transition name="fade">
        <div class="drawer-background" v-if="drawer"/>
      </transition>
    </div>
    <snackbar ref="snackbar" error-color="white" default-timeout="2000"/>

    <session-timeout-modal
        :visible="sessionTimeout"
        @onSessionTimeoutReached="sessionTimeoutReached"
        @onContinueSessionClicked="closeSessionModal"
    />

    <change-password-modal
        :visible="changePasswordModal"
        @submitClick="toggleChangePasswordModal"
        @cancelClick="toggleChangePasswordModal"
    />

    <error-modal
        ref="errorModal"
        :error="error"
        :title="errorTitle"
        :visible="isErrorModalOpen"
        :multiLineError="showLineBreaks"
        @closeErrorModal="closeErrorModal"
    />

    <v-footer
        v-if="hasOngoingMessagesToDisplay"
        class="sticky-event-message-footer"
        absolute
    >
      <ongoing-notifications-table
          :ongoingNotifications="ongoingNotifications"
          :showHeaders="false"
      />
    </v-footer>

  </v-main>
</template>
<script>
import ChangePasswordModal from "../../components/passwordManagement/ChangePasswordModal.vue";
import MainHeaderPanel from "../../components/navigation/MainHeaderPanel.vue";
import MenuOptions from "../../components/navigation/MenuOptions.vue";
import ErrorModal from "../../components/navigation/ErrorModal.vue";
import SessionTimeoutModal from "../../components/users/SessionTimeoutModal.vue";
import Snackbar from "../../components/common/Snackbar.vue";
import Users from "../../rest/users";
import DxExport from "devextreme-vue/ui/data-grid";
import OngoingNotificationsTable from "../../components/common/notifications/OngoingNotificationsTable.vue"
import EventNotificationHelper from "../../assets/notifications/notificationsHelper";
import NotificationsHelper from "../../assets/notifications/notificationsHelper";
import Constants from "../../assets/constants";
import Websockets from "../../websocket/webSockets";
import {addSubscription, isSubscribed} from "../../websocket/subscriptionManager";
import {AuthHelper} from "@/assets/authHelper";
import ActivityDetector from "@/assets/activityDetector/ActivityDetector";

export default {
  components: {
    ChangePasswordModal,
    DxExport,
    Snackbar,
    SessionTimeoutModal,
    ErrorModal,
    MainHeaderPanel,
    MenuOptions,
    OngoingNotificationsTable
  },
  data() {
    return {
      error: null,
      isErrorModalOpen: false,
      changePasswordModal: false,
      successMessage: null,
      drawer: false,
      snackbar: false,
      drawerWidth: 600,
      sessionTimeout: false,
      errorMessage: null,
      wizardVisible: false,
      errorTitle: null,
      defaultWizardWidth: 700,
      defaultWizardHeight: 700,
      wideWizardWidth: 1100,
      extendedWizardWidth: 1000,
      showLineBreaks: false,
      newUserNotification: null,
      ongoingNotifications: [],
      subscribedTopics: [],
      activityDetector: null,
    };
  },

  props: {
    pageTitle: String,
    pagePrefix: {
      type: String,
      default: "Oasis"
    },
    navSection: String,
    customWizardWidth: {
      type: [Number, String],
      default: null
    }
  },

  mounted() {
    this.initActivityDetector();
  },

  async created() {
    this.subscribeToWebSocketEventTopic();
    const component = this;
    this.$root.$on("mainNavShowError", (error) => {
      component.showError(error);
    });
    this.setOngoingNotifications(NotificationsHelper.getOngoingNotificationsFromStore(this.$store));
  },

  computed: {
    loggedInUser() {
      let user = this.$store.state.loggedInUser;
      if (user) {
        return user.firstName + " " + user.lastName;
      } else {
        return "Not Logged In";
      }
    },
    wizardWidth() {
      if (this.customWizardWidth) {
        return this.customWizardWidth;
      } else if (this.navSection === 'orders' || this.navSection === 'customer' || this.navSection === 'warehouse') {
        return this.extendedWizardWidth;
      } else {
        return this.defaultWizardWidth;
      }
    },
    hasOngoingMessagesToDisplay() {
      return this.getOngoingNotifications().length > 0;
    }
  },

  methods: {
    connectWebSocket() {
      if (this.$store.state.loggedInUser) {
        this.stompJsClient = Websockets.createStompJsClient(this.onConnectFunction);
      }
    },
    subscribeToWebSocketEventTopic() {
      let topic = Websockets.getWebSocketEventNotificationTopic();
      if (!isSubscribed(topic)) {
        this.connectWebSocket();
        addSubscription(topic);
      }
    },
    onConnectFunction() {
      const customEventNotification = Websockets.getWebSocketEventNotificationTopic();
      this.stompJsClient.subscribe(customEventNotification, (response) => {
        let parsed = JSON.parse(response.body);
        this.processIncomingMessage(parsed);
      });
    },
    async logout(type) {
      const user = this.$store.state.loggedInUser;
      await Users.getRestApi().logout();
      const storedType = typeof type === "string" ? type : null;
      if (AuthHelper.isSafariBrowser()) {
        AuthHelper.clearSessionStorage(user);
        AuthHelper.clearCookies();
      }
      const logoutType = this.defineLogoutType(type);
      this.$store.commit(logoutType, storedType);
      this.$router.push("/");
    },
    showWizard() {
      this.wizardVisible = true;
    },
    hideWizard() {
      this.wizardVisible = false;
    },
    showDrawer() {
      this.drawer = true;
    },
    hideDrawer() {
      this.drawer = false;
    },
    showError(error, showWithLineBreaks) {
      this.errorTitle = error.title ? error.title : "Error";
      if (error.message && !error.response) {
        this.error = error.message;
      } else {
        this.error = error.response ? error.response.data.message : error;
        if (typeof this.error === "object") {
          this.error = `Something unexpected has happened.
          Be assured we have recorded the error and are striving to create the best experience for you!`;
        }
      }
      this.showLineBreaks = !(showWithLineBreaks);
      this.isErrorModalOpen = true;
      throw new Error(error);
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
      this.errorTitle = null;
      this.showLineBreaks = false;
    },
    showSuccess(message, duration) {
      this.$refs.snackbar.addMessage(message, false, duration);
    },
    initActivityDetector() {
      const duration = Constants.SESSION_INACTIVITY_TIMEOUT;
      this.activityDetector = new ActivityDetector(duration, this.openSessionModal);
    },
    openSessionModal() {
      this.sessionTimeout = true;
    },
    closeSessionModal() {
      this.sessionTimeout = false;
    },
    toggleChangePasswordModal() {
      this.changePasswordModal = !this.changePasswordModal;
    },
    processIncomingMessage(parsed) {
      let existingEvent = [...this.ongoingNotifications, ...Array.from(EventNotificationHelper.getAllNotifications(this.$store))]
          .find(notification => notification.id === parsed.id);

      if (existingEvent) {
        this.updateEventState(existingEvent, parsed);
      } else {
        let newUserNotification = EventNotificationHelper.formatNotification(parsed);
        this.newUserNotification = newUserNotification;
        EventNotificationHelper.saveMessageToStore(this.$store, newUserNotification);
        let counter = NotificationsHelper.getUnreadNotificationCounter(this.$store);
        this.$store.commit('setUnreadMessageCounter', counter);
      }
    },
    onNotificationBarClosed() {
      let ongoingEvents = this.getOngoingNotifications();
      this.setOngoingNotifications(ongoingEvents);
    },
    getOngoingNotifications() {
      return NotificationsHelper.getOngoingNotificationsFromStore(this.$store);
    },
    setOngoingNotifications(ongoingEvents) {
      this.ongoingNotifications = ongoingEvents.length > 0 ? ongoingEvents : [];
    },
    updateEventState(existing, parsed) {
      if (existing.eventState === Constants.eventStateTypes.SCHEDULED) {
        let updatedEvent = NotificationsHelper.updateExistingFields(existing, parsed);
        this.startScheduledEvent(updatedEvent);
      } else if (existing.eventState ===  Constants.eventStateTypes.RUNNING) {
        let updatedEvent = NotificationsHelper.updateExistingFields(existing, parsed);
        this.markEventAsFinished(updatedEvent);
      }
      // finished event won't be restarted
    },
    markEventAsFinished(event) {
      this.ongoingNotifications = this.ongoingNotifications.filter(notification => notification.id !== event.id);
      this.setOngoingNotifications(this.ongoingNotifications);
      let ongoingNotificationEvents = NotificationsHelper.getAllNotifications(this.$store)
          .filter(message => message.eventState === Constants.eventStateTypes.RUNNING);
      this.$store.commit('setOngoingNotifications', ongoingNotificationEvents);
      let counter = NotificationsHelper.getUnreadNotificationCounter(this.$store);
      this.$store.commit('setUnreadMessageCounter', counter);
    },
    startScheduledEvent(event) {
      let toBeStarted = NotificationsHelper.getAllNotifications(this.$store)
          .find(notification => notification.id === event.id);

      if (toBeStarted) {
        let updatedOngoing = this.getOngoingNotifications();
        updatedOngoing.unshift(toBeStarted);
        this.setOngoingNotifications(updatedOngoing);
        this.$store.commit('setOngoingNotifications', updatedOngoing);
      }
    },
    onGoBackButtonClicked() {
      this.$emit('goBackButtonClicked');
    },
    sessionTimeoutReached(logoutType) {
      this.logout(logoutType);
    },
    defineLogoutType(type) {
      return type === Constants.logoutTypes.INACTIVE
          ? Constants.logoutTypes.INACTIVE
          : Constants.logoutTypes.LOGOUT;
    }
  },
};
</script>

<style scoped>
.background {
  background-color: rgba(238, 244, 246, 0.5);
}
.nav-action {
  background-color: rgba(245, 248, 250, 0.91);
  border-top: 1px solid #d1dbe3;
  border-bottom: 1px solid #d1dbe3;
  margin-right: 10px;
}
.drawer {
  z-index: 201;
}
.drawer-background {
  z-index: 200;
  background-color: rgba(33, 33, 33, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sticky-event-message-footer {
  position: sticky;
  bottom: 0;
  background-color: #F7FAFB;
  max-height: 170px;
  overflow-y: auto;
}
</style>
