<template>
  <drawer-form
      ref="form"
      formId="vendorDrawerForm"
      :title="title"
      :create="create"
      :submitLabel="submitLabel"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-container grid-list-lg text-xs-center>
      <vendor-edit-form
          :create="create"
          ref="vendorDrawerForm"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm";
import VendorEditForm from "@/components/vendors/VendorEditForm.vue";

export default {
  components: {
    DrawerForm,
    VendorEditForm
  },

  props: {
    title: String,
    submitLabel: String,
    create: Boolean
  },

  data() {
    return {
      inProgress: false
    };
  },

  methods: {
    onCancelClicked() {
      this.$emit("cancelClicked");
      this.reset();
      this.$emit('refreshVendorDrawerForm');
    },
    reset(submit) {
      this.$refs.vendorDrawerForm.resetForm(submit);
      this.$refs.form.resetValidation();
      this.setInProgress(false);
    },
    onSubmitClicked() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.setInProgress(true);
        const payload = this.$refs.vendorDrawerForm.buildPayload();
        this.$emit("submitClicked", payload);
        this.reset(false);
      }
    },
    loadDrawerForm(data) {
      this.$refs.vendorDrawerForm.loadForm(data);
    },
    setInProgress(value) {
      this.inProgress = value;
    }
  }
};
</script>
