<template>
  <v-main class="background">
    <v-container grid-list-lg fluid class="wrapper">
      <v-row justify-center class="wrapper-layout ma-1">
        <v-col cols="10" offset-sm="1">
          <v-card class="main-card elevation-12">
            <v-row justify-space-between>
              <v-col cols="6">
                <div class="primary-title">Oasis</div>
                <div class="slogan">Welcome to Oasis by 50Floor</div>
                <slot></slot>
                <div class="version">
                  Oasis – Copyright © 2018-2025, 50Floor Inc. All rights reserved. v{{appVersion}}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="login-image" src/>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {version} from '../../package.json'

export default {
  data() {
    return {
      appVersion: version
    }
  }

};
</script>

<style scoped>
.background {
  background-color: #ddd;
}
.wrapper-layout {
  height: calc(100vh - 48px);
}
.main-card {
  border-radius: 5px;
  height: calc(100vh - 125px);
  overflow: hidden;
}
.primary-title {
  margin-top: 40px;
  font-size: 60px;
  font-weight: bold;
}
.slogan {
  font-size: 15px;
  margin-bottom: 5px;
}
.login-image {
  background-image: url("../assets/login/loginPhoto.jpg");
  background-color: #ccc;
  height: calc(100vh - 100px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.version {
  color: #b0bec5;
  font-size: 12px;
  padding: 0 40px;
  position: absolute;
  z-index: 0;
  width: 50%;
  bottom: 0;
  margin-bottom: 10px;
}
</style>
