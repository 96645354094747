import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Lead status.
 */
export class LeadStatus {
    id: number;
    name: string = "";

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}

/**
 * REST interface provided for lead statuses.
 */
export interface LeadStatusRestApi {
    getAllLeadStatuses(): AxiosPromise<LeadStatus[]>;

    getAllLeadStatusesFromStatus(sourceState: number): AxiosPromise<LeadStatus[]>;

    getAllLeadStatusesFromStatusForSalesRepresentatives(sourceState: number): AxiosPromise<LeadStatus[]>;
}

/**
 * REST implementation provided for lead statuses.
 */
class Implementation implements LeadStatusRestApi {
    getAllLeadStatuses(): AxiosPromise<LeadStatus[]> {
        return Axios.axiosInstance().get<LeadStatus[]>("/lead_statuses");
    }

    getAllLeadStatusesFromStatus(sourceState: number): AxiosPromise<LeadStatus[]> {
        return Axios.axiosInstance().get<LeadStatus[]>(`/lead_statuses/nextStates/${sourceState}`);
    }

    getAllLeadStatusesFromStatusForSalesRepresentatives(sourceState: number): AxiosPromise<LeadStatus[]> {
        return Axios.axiosInstance().get<LeadStatus[]>(`/lead_statuses/nextStates/${sourceState}/salesRep`);
    }
}

const LeadStatuses = {
    getRestApi(): LeadStatusRestApi {
        return new Implementation();
    }
};

export default LeadStatuses;
