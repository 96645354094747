<template>
  <grid-view
      ref="nav"
      nav-section="admin"
      page-title="List Users"
      :action-button-label="isActionButtonDisplayed ? 'Create User' : null"
      :data-source="dataSource"
      @rowSelected="onRowSelected"
      :columnMinWidth="150"
      :allowColumnResizing="true"
      columnResizingMode="widget"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        :cell-template="StatusRenderer"
        data-type="string"
    />
    <dx-column
        data-field="Email"
        :minWidth="200"
        caption="Email"
        data-type="string"
    />
    <dx-column
        data-field="FirstName"
        caption="First Name"
        data-type="string"
    />
    <dx-column
        data-field="LastName"
        caption="Last Name"
        data-type="string"
    />
    <dx-column
        data-field="Role"
        caption="Role"
        data-type="string"
    />
    <dx-column
        data-field="Branch"
        caption="Branch"
        data-type="string"
    />
    <dx-column
        data-field="Description"
        caption="Description / Comments"
        data-type="string"
    />
    <template v-slot:drawerContent="{isDrawerVisible}">
      <user-create-drawer
          :isVisible="isDrawerVisible"
          @cancelClicked="onCancel"
          @onCreateFail="onCreateFail"
          @onCreateSuccess="onCreateSuccess"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import UserCreateDrawer from "@/components/users/UserCreateDrawer.vue";
import ODataContext from "@/rest/odataContext";
import Roles from "../../assets/roles";
import StatusRenderer from "../../components/common/grid/StatusRenderer";

export default {
  components: {
    GridView,
    DxColumn,
    UserCreateDrawer
  },

  computed: {
    isActionButtonDisplayed() {
      return Roles.isSuperAdminUser();
    }
  },

  data() {
    return {
      dataSource: {
        store: ODataContext['Users'],
        select: [
          "Active",
          "Status",
          "Id",
          "Email",
          "FirstName",
          "LastName",
          "Role",
          "Description",
          "Branch"
        ]
      }
    };
  },
  methods: {
    onCreateSuccess() {
      this.$refs.nav.showSuccess("Create new user successfully!");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onCreateFail(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    onRowSelected(data) {
      this.$router.push(`/users/details/${data.Id}`);
    },
    StatusRenderer
  }
};
</script>
