<template>
  <v-form ref="form" :id="formId" enctype="multipart/form-data">
    <v-container>
      <v-row class="pa-2">
        <form-header :title="title"/>
        <slot/>
        <div class="required-key">
          <h6>* Required Fields</h6>
        </div>
        <v-skeleton-loader
            v-if="isLoading"
            :loading="isLoading"
            type="form, actions"
        />
        <form-buttons
            v-show="!isLoading"
            data-cy="submitButtons"
            ref="formButtons"
            :submit-label="submitLabel || 'Create'"
            :hide-cancel="hideCancel"
            :hideSubmit="hideSubmit"
            :showCheckbox="showCheckbox"
            :showDelete="showDelete"
            :inProgress="inProgress"
            :deleting="deleting"
            @deleteClicked="onDelete"
            @cancelClicked="onCancel"
            @submitClicked="onSubmit"
        />
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormButtons from "../../../components/common/form/FormButtons";
import FormHeader from "../../../components/common/form/FormHeader";

export default {
  components: {
    FormButtons,
    FormHeader
  },

  props: {
    title: String,
    submitLabel: String,
    formId: String,
    hideSubmit: Boolean,
    hideCancel: Boolean,
    showCheckbox: Boolean,
    showDelete: Boolean,
    inProgress: Boolean,
    deleting: Boolean,
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {};
  },

  methods: {
    onDelete() {
      this.$emit('deleteClicked');
    },
    onCancel() {
      this.$emit('cancelClicked');
    },
    onSubmit(event) {
      this.$emit('submitClicked', event);
    },
    validate() {
      return this.$refs.form.validate(true);
    },
    reset() {
      return this.$refs.form.reset();
    },
    resetValidation() {
      return this.$refs.form.resetValidation();
    },
    getCheckboxValue() {
      return this.$refs.formButtons.getCheckboxValue();
    },
    resetCheckboxValue() {
      this.$refs.formButtons.resetCheckboxValue();
    }
  }
};
</script>

<style scoped>
.required-key {
  width: 100%;
  text-align: right;
  padding-right: 20px;
}
</style>
