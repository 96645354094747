const Constants = {
    jobType: {
        product: 0,
        labor: 1,
        payment: 2,
        finance: 3,
        contract: 4,
        installIssue: 5,
        chargeback: 6,
        bca: 7,
        refund: 8,
        misc: 9,
        warranty: 10,
        0: "product",
        1: "labor",
        2: "payment",
        3: "finance",
        4: "contract",
        5: "installIssue",
        6: "chargeback",
        7: "bca",
        8: "refund",
        9: "misc",
        10: "warranty"
    },
    installerIssueType: {
        labor: 1,
        materials: 2,
        1: "labor",
        2: "materials",
    },
    productType: {
        carpet: 1,
        lvt: 2,
        miscSupplies: 3,
        carpetPad: 4,
        adhesives: 6,
        wallMolding: 7,
        laminate: 8,
        tile: 9,
        sheetVinyl: 10,
        solidHardwood: 11,
        engineeredHardwood: 12,
        wpc: 13,
        hardwoodTrim: 14,
        laminateTrim: 15,
        lvtTrim: 16,
        underlayment: 17
    },
    userRoles: {
        admin: 1,
        salesRep: 2,
        regular: 3,
        branchUser: 4,
        branchAdminUser: 5,
        installerCrew: 6,
        rehashRepUser: 7,
        superAdmin: 9,
        marketingDataUploaderUser: 8,
        scgApiUser: 10,
        generalManager: 12,
        salesLeadOptimizerApiUser: 13
    },
    roles: [
        {id: 1, name: "admin", description: "Administrator"},
        {id: 2, name: "sales", description: "Sales Representative"},
        {id: 3, name: "default", description: "Regular User"},
        {id: 4, name: "branch", description: "Branch User",},
        {id: 5, name: "branch_admin", description: "Branch Administrator"},
        {id: 7, name: "rehash_rep", description: "Rehash Representative"},
        {id: 8, name: "marketing_data_uploader", description: "Marketing Data Uploader"},
        {id: 9, name: "super_admin", description: "Super Admin"},
        {id: 10, name: "scg_api", description: "SCG API integration user"},
        {id: 12, name: "general_manager", description: "General Manager"},
        {id: 13, name: "sales_lead_optimizer_integration", description: "Sales Lead Optimizer Integration"},
    ],
    nonServiceableLeadStatus: 'Non-serviceable',
    TBDInstallerId: 99999,
    LeadStatuses: {
        "New": 1,
        "Assigned": 2,
        "Layaway": 3,
        "Rehash": 4,
        "Canceled": 5,
        "Dead": 6,
        "Sold": 7,
        "Sizing": 8,
        "Non-serviceable": 9
    },
    leadFormType: {
        "CREATE": "create",
        "UPDATE": "edit"
    },
    laborLineItemSource: {
        SALES_REP_APP: 'SALES_REP_APP',
        WEB: 'WEB'
    },
    miscItemNeedsReviewId: 4,
    nextActionStatusOpen: "OPEN",
    expandedByDefault: [0, 1],
    daysInWeek: [
        {
            text: "Monday",
            value: "MONDAY"
        },
        {
            text: "Tuesday",
            value: "TUESDAY"
        },
        {
            text: "Wednesday",
            value: "WEDNESDAY"
        },
        {
            text: "Thursday",
            value: "THURSDAY"
        },
        {
            text: "Friday",
            value: "FRIDAY"
        },
        {
            text: "Saturday",
            value: "SATURDAY"
        },
        {
            text: "Sunday",
            value: "SUNDAY"
        }
    ],
    weekDaysAbbreviation: {
        'SUNDAY': 'Sun',
        'MONDAY': 'Mon',
        'TUESDAY': 'Tue',
        'WEDNESDAY': 'Wed',
        'THURSDAY': 'Thu',
        'FRIDAY': 'Fri',
        'SATURDAY': 'Sat'
    },

    phoneNumberMask: "###-###-####",
    closedStatus: 'Closed',
    closedTRStatus: 'Closed',
    documentCategoriesWithInstallTypes: ["LEAD", "JOB", "JOB_PURCHASE_ORDER"],
    yesNoDropdownOptions: [
        {
            text: "Yes",
            value: true,
        },
        {
            text: "No",
            value: false,
        },
    ],
    activeInactiveOptionsWithValue: [
        {text: "Inactive", value: 0},
        {text: "Active", value: 1},
    ],
    defaultSelectOptionsWithKey: [
        {key: "no ", label: "No", value: false},
        {key: "yes ", label: "Yes", value: true}
    ],
    phoneOwnerOptions: [
        {key: "yes ", label: "Owner", value: true},
        {key: "no ", label: "Not Owner", value: false}
    ],
    unitOfMeasureChoices: [
        "Square Feet",
        "Square Yards",
        "Linear Feet",
        "Each",
    ],
    returnFromJobDropdownOptions: [
        {text: 'Full Product Line Item Return', value: 'FULL_RETURN'},
        {text: 'Partial Product Line Item Return', value: 'PARTIAL_RETURN'},
        {text: 'Full Product Line Item Reservation', value: 'FULL_RESERVATION'},
        {text: 'Partial Product Line Item Reservation', value: 'PARTIAL_RESERVATION'},
    ],
    ticketTypes: {
        'LEAD': 'lead',
        'JOB': 'job',
    },
    documentTypesCategories: {
        'LEAD': 'LEAD',
        'JOB': 'JOB',
        'INSTALLER': 'INSTALLER',
        'JOB_PURCHASE_ORDER': 'JOB_PURCHASE_ORDER',
        'STOCK_PURCHASE_ORDER': 'STOCK_PURCHASE_ORDER',
        'TRANSFER_REQUEST_AND_SHIPMENT': 'TRANSFER_REQUEST_AND_SHIPMENT',
        'SALES_REPRESENTATIVE': 'SALES_REPRESENTATIVE',
    },
    jobButtonLabels: {
        SAVE_UPDATES: 'Save Updates',
        FULFILMENT: 'Begin Fulfillment',
        INVOICE: 'Invoice'
    },
    moveItemToAnotherBinOptions: [
        {label: 'Move full quantity on hand', value: 1},
        {label: 'Move available quantity (fully or partially)', value: 2},
        {label: 'Move reservation', value: 3},
    ],
    FILE_UPLOAD_TIMEOUT: 90000,
    ODATA_REQUEST_TIMEOUT: 300000,
    AXIOS_REQUEST_TIMEOUT: 65000,
    SESSION_INACTIVITY_TIMEOUT: 7200000,
    COC_DOCUMENT_TYPE_ID: 9,
    installerSlotStatuses: {
        AVAILABLE: 'AVAILABLE',
        SCHEDULED: 'SCHEDULED'
    },
    VDA_DOCUMENT_TYPE_ID: 8,
    eventStateTypes: {
        "SCHEDULED": 'SCHEDULED',
        "RUNNING": 'RUNNING',
        "FINISHED": 'FINISHED',
    },
    eventSeverityTypes: {
        "INFO": 'INFO',
        "MAINTENANCE": 'MAINTENANCE',
        "CRITICAL": 'CRITICAL',
    },
    validPhoneLengthWithoutHyphens: 12,
    validPhoneLengthDigitsOnly: 10,
    fileSources: {
        SALES_APP: 'SALES_APP',
        INSTALLER_APP: 'INSTALLER_APP',
        WEB: 'WEB'
    },
    transferRequestStatuses: [
        {"id": 1, "name": "New", "value": "NEW"},
        {"id": 2, "name": "Delivery Scheduled", "value": "DELIVERY_SCHEDULED"},
        {"id": 3, "name": "Checked Out", "value": "CHECKED_OUT"},
        {"id": 4, "name": "In Transit", "value": "IN_TRANSIT"},
        {"id": 5, "name": "Partially Delivered", "value": "PARTIALLY_DELIVERED"},
        {"id": 6, "name": "Closed", "value": "CLOSED"},
    ],

    shipmentStatuses: [
        {"id": 1, "name": "Scheduled", "value": "SCHEDULED"},
        {"id": 2, "name": "Pickup", "value": "PICKUP"},
        {"id": 3, "name": "In Progress", "value": "IN_PROGRESS"},
        {"id": 4, "name": "Completed", "value": "COMPLETED"},
        {"id": 5, "name": "Cancelled", "value": "CANCELLED"}
    ],

    timeBlockAssignmentStatuses: {
        PAST: 'PAST',
        CAN_BE_REASSIGNED: 'CAN_BE_REASSIGNED',
        FUTURE: 'FUTURE'
    },

    mfaResetDialogMessage:  'Are you sure you want to reset MFA?',
    mfaResetSuccessMessage:  'MFA reset successfully!',
    mfaEnabledMessage:  'MFA has been enabled due to the status changed to active',
    mfaDisabledMessage:  'MFA has been disabled due to the status changed to inactive',

    logoutTypes: {
        INACTIVE: 'inactiveLogout',
        LOGOUT: 'logout',
    }
};

export default Constants;
