<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td class="text-truncate" style="width: 15%;">
          <strong>{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <td style="width: 45%;">
          <v-text-field
            :disabled="true"
            v-model="comment"
            class="short-input left-aligned"
            placeholder=" "
          />
        </td>
        <td class="pt-0" style="width: 15%;">
          <v-text-field
            :disabled="true"
            v-model="warrantyPrice"
            class="short-input"
            prefix="$"
          />
        </td>
        <td class="pt-0" style="width: 15%;">
          <v-text-field
            :disabled="true"
            v-model="salesRepWarrantyCost"
            class="short-input"
            prefix="$"
          />
        </td>
        <td class="pt-0" style="width: 15%;">
          <v-text-field
            :disabled="true"
            v-model="fiftyFloorWarrantyCost"
            class="short-input"
            prefix="$"
          />
        </td>
        <td style="width: 30px">
          <v-btn
              small
              dark
              icon
              class="short ma-0 pa-0"
              v-if="!job.invoiced"
              @click="onDeleteItem(lineItem.jobLineItem.referenceId)"
          >
            <v-icon class="short" color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

export default {
  components: { },

  props: {
    job: {
      type: Object,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      referenceId: null,
      comment: null,
      warrantyPrice: null,
      salesRepWarrantyCost: null,
      fiftyFloorWarrantyCost: null
    };
  },

  watch: {
    lineItem: {
      immediate: true,
      deep: true,
      handler(updated) {
        this.referenceId = updated.jobLineItem.referenceId;
        this.comment = updated.jobLineItem.comment;
        this.warrantyPrice = CurrencyFormatter.formatCurrency(updated.jobLineItem.price);
        this.salesRepWarrantyCost = CurrencyFormatter.formatCurrency(updated.jobLineItem.salesRepWarrantyCost);
        this.fiftyFloorWarrantyCost = CurrencyFormatter.formatCurrency(updated.jobLineItem.fiftyFloorWarrantyCost);
      }
    }
  },

  methods: {
    onDeleteItem(referenceId) {
      this.$emit('itemDeleted', referenceId);
    }
  }
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}
.item-row td.top-aligned {
  vertical-align: top;
}
</style>
