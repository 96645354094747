<template>
  <v-layout class="nav-bar">
    <v-app-bar color="#F5F8FA" dense flat>
      <v-col>
        <navigation-menu-item
          label="Schedule"
          section="schedule"
          :currentSection="navSection"
          :primaryUrl="'/sales/home'"
        />
      </v-col>
      <v-col>
        <navigation-menu-item
          label="Leads"
          section="leads"
          :primaryUrl="'/sales/leads'"
          :currentSection="navSection"
        />
      </v-col>
      <v-col>
        <navigation-menu-item
          label="Jobs"
          section="jobs"
          :primaryUrl="'/sales/jobs'"
          :currentSection="navSection"
        />
      </v-col>
      <v-col>
        <navigation-menu-item
          label="Commissions"
          section="commissions"
          :primaryUrl="'/sales/commissions'"
          :currentSection="navSection"
        />
      </v-col>
    </v-app-bar>
  </v-layout>
</template>

<script>
import NavigationMenuItem from "@/components/navigation/NavigationMenuItem.vue";

export default {
  components: { NavigationMenuItem },
  props: {
    navSection: String
  }
};
</script>
