<template>
  <div>
    <inline-grid
        :data-source="items"
        :show-borders="false"
        :hoverStateEnabled="true"
        @rowSelected="onRowSelected"
        @deleteClicked="onDeleteItem"
    >
      <dx-column
          data-field="internalSku"
          width="20%"
          caption="Internal SKU"
      />
      <dx-column
          data-field="styleName"
          width="25%"
          caption="Style"
      />
      <dx-column
          data-field="color"
          width="25%"
          caption="Color"
      />
      <dx-column
          data-field="id"
          width="5%"
          caption
          cellTemplate="delete"
          :allowSorting="false"
      />

      <template v-slot:footer>
        <inline-grid-footer>
          <inline-grid-button
              v-if="isAddButtonDisplayed"
              label="Add Associated Product"
              @click="onAddItem"
          />
        </inline-grid-footer>
      </template>

    </inline-grid>
    <add-associated-item-dialog
        ref="dialog"
        :catalog-item-id="catalogItemId"
        @itemsAssociated="refresh"
    />
    <remove-catalog-item-dialog
        ref="removeAssociatedItemDialog"
        :catalog-item-id="catalogItemId"
        :internalSku="internalSku"
        :isMigratedItem="isMigratedItem"
        @deleteConfirmed="refresh"
        @removeAssociatedItemError="emitError"
    />
  </div>
</template>

<script>
import InlineGrid from "../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../components/common/grid/InlineGridButton.vue";
import AddAssociatedItemDialog from "../../components/catalogItems/AddAssociatedItemDialog.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ProductCatalogItems from "../../rest/catalogItems";
import RemoveCatalogItemDialog from "./RemoveCatalogItemDialog";

export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    AddAssociatedItemDialog,
    DxColumn,
    RemoveCatalogItemDialog
  },

  props: {
    catalogItemId: {},
    isMigratedItem: {
      type: Boolean,
      required: true
    },
    internalSku: {
      type: String,
      required: true,
    },
    isAddButtonDisplayed: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {items: null};
  },

  watch: {
    catalogItemId: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  methods: {
    async refresh() {
      try {
        const response = await ProductCatalogItems.getRestApi().getCatalogItemAssociatedItemsByCatalogItemId(this.catalogItemId);
        let initialLength = !this.items ? 0 : this.items.length;
        this.items = response.data;
        if (initialLength !== this.items.length) {
          this.$emit('associatedItemsChanged');
        }
      } catch (error) {
        this.emitError(error);
      }
    },
    onRowSelected(data) {
      if (data && data.id) {
        this.$router.push(`/orders/catalog/${data.id}`);
      }
    },
    onAddItem() {
      this.$refs.dialog.openDialog();
    },
    onDeleteItem(cellData) {
      this.$refs.removeAssociatedItemDialog.openDialog(cellData);
    },
    emitError(error) {
      this.$emit('associatedItemsError', error);
    },
  },
};
</script>
