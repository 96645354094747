<template>
  <modal-dialog
      width="700"
      title="Email Template"
      cancelLabel="Close"
      :submitLabel="submitLabel"
      :visible="visible"
      :submitEnabled="submitEnabled"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <purchase-order-email-template
        ref="email"
        :purchaseOrder="purchaseOrder"
        :items="items"
        :customer="customer"
        :totalVendorCostRounded="totalVendorCostRounded"
    />
  </modal-dialog>
</template>
<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import PurchaseOrderEmailTemplate from "@/components/purchaseOrders/PurchaseOrderEmailTemplate.vue";

export default {
  components: {
    ModalDialog,
    PurchaseOrderEmailTemplate
  },

  props: {
    visible: Boolean,
    items: Array,
    purchaseOrder: Object,
    customer: Object,
    totalVendorCostRounded: String
  },

  data() {
    return {
      submitLabel: "Copy",
      submitEnabled: true,
      copied: false
    };
  },

  methods: {
    onCancelClicked() {
      this.$emit("onCancelClicked");
      this.toggleModalState(false);
    },
    onSubmitClicked() {
      this.$refs.email.copy();
      this.toggleModalState(true);
    },
    toggleModalState(newState) {
      this.copied = newState;
      this.submitLabel = this.copied ? "Copied" : "Copy";
      this.submitEnabled = this.copied ? false : true;
    }
  }
};
</script>
