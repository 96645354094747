const ProductLineItemStatuses = {

    CHECKED_OUT: {
        status: "CheckedOut",
        name: "Checked Out"
    },
    INVENTORY_RESERVED: {
        status: "InventoryReserved",
        name: "Inventory Reserved"
    },
    PARTIALLY_RECEIVED: {
        status: "PartiallyReceived",
        name: "Partially Received"
    },
    RESERVED: "Reserved",
    PENDING: "Pending",
    NEW: "New",

    defineJobLineItemStatus(status) {
        if (status) {
            if (this.CHECKED_OUT.status === status) {
                return this.CHECKED_OUT.name;
            } else if (this.INVENTORY_RESERVED.status === status) {
                return this.RESERVED;
            } else if (this.PARTIALLY_RECEIVED.status === status) {
                return this.PARTIALLY_RECEIVED.name;
            } else if (this.PENDING === status) {
                return this.PENDING;
            }
            return status;
        }
        return this.NEW;
    },

};


export default ProductLineItemStatuses;
