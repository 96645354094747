<template>
  <drawer-form
      ref="form"
      :title="title"
      :submitLabel="submitLabel"
      :inProgress="inProgress"
      formId="customerDrawerForm"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-container>
      <customer-details-form
          ref="customerDetailsDrawerForm"
          :readonly="false"
          :isCreate="isCreateForm"
          :customer="customer"
          :emailRequired="emailRequired"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import CustomerDetailsForm from "./CustomerDetailsForm.vue";
import DrawerForm from "../common/form/DrawerForm";

export default {
  components: {
    DrawerForm,
    CustomerDetailsForm
  },

  props: {
    title: String,
    submitLabel: String,
    inProgress: Boolean,
    customer: {
      type: [Object, null],
    },
    isCreateForm: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    emailRequired() {
      if (this.customer) {
        return !(this.customer.email) && this.customer.emailMandatory || this.customer.email && this.customer.emailMandatory;
      }
    }
  },

  methods: {
    onCancelClicked() {
      this.$emit('cancelClicked');
      if (this.isCreateForm) {
        this.$refs.customerDetailsDrawerForm.setFormBackToDefault();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    onSubmitClicked() {
      let valid = this.$refs.form.validate();
      if (valid) {
        const payload = this.$refs.customerDetailsDrawerForm.buildPayload();
        this.$emit('submitClicked', payload);
      }
    }
  }
};
</script>
