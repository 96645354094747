<template>
  <treeselect
      v-model="selected"
      :searchable="false"
      :multiple="false"
      :options="items"
      :disable-branch-nodes="true"
      :show-count="true"
      placeholder="Select Job Status"
      :clearable="false"
  >
    <template v-slot:value-label="{ node }">
      <div>
        {{ node.raw.customLabel }}
      </div>
    </template>

  </treeselect>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import JobStatuses from "@/rest/jobStatuses";

const cancelParentId = 45;
const saveSubStatusId = 67;

export default {
  components: {
    Treeselect
  },

  data() {
    return {
      selected: null,
      jobStatuses: [],
      loading: false,
      items: []
    };
  },

  props: {
    value: {
      type: Number
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      this.$emit("statusSelected", value);
    }
  },

  created() {
    this.createDropdownData();
  },

  methods: {
    async createDropdownData() {
      try {
        const data = await this.getAllData();
        this.items = this.formatData(data);
      } catch (error) {
        this.$emit("jobStatusSearchFieldError", error);
      }
    },
    async getAllData() {
      try {
        const response = await JobStatuses.getRestApi().getAllJobStatuses();
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    formatData(data) {
      let formattedData = [];
      data.forEach(item => {
        if (item.parentId === null) {
          let parent = {...item}
          parent.label = item.name;
          parent.children = [];
          data.forEach(childItem => {
            if (childItem.parentId === item.id) {
              let child = {...childItem};
              child.label = child.name;
              child.customLabel = `${item.name}: ${child.name}`;
              child.isDisabled = !childItem.active;
              parent.children.push(child);
            }
          })
          formattedData.push(parent);
        }
      });
      return this.sortSubStatusesWithCancelledStatus(formattedData);
    },
    sortSubStatusesWithCancelledStatus(formattedData) {
      formattedData.forEach(parent => {
        if (parent.id === cancelParentId) {
          let firstItem = parent.children.find(i => i.id === saveSubStatusId);
          parent.children.splice(parent.children.findIndex(item => item.id === saveSubStatusId), 1);
          return parent.children.unshift(firstItem);
        }
      });
      return formattedData;
    }
  }
};
</script>
