<template>
  <grid-view
      ref="nav"
      page-title="List Markets"
      nav-section="admin"
      :action-button-label="isActionButtonDisplayed ? 'Create Market' : null"
      :data-source="dataSource"
      :allowFiltering="false"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        :cell-template="StatusRenderer"
        data-type="string"
    />
    <dx-column
        data-field="Name"
        caption="Name"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        data-type="string"
    />
    <dx-column
        data-field="State"
        caption="State"
        width="15%"
        data-type="string"
    />
    <dx-column
        data-field="BranchName"
        caption="Branch"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        data-type="string"
    />

    <template v-slot:drawerContent>
      <market-create-drawer
          ref="createDrawer"
          @cancelClicked="onCancel"
          @onCreateFail="onCreateFail"
          @onCreateSuccess="onCreateSuccess"
      />
    </template>
  </grid-view>
</template>

<script>
import MarketCreateDrawer from "../../components/markets/MarketCreateDrawer.vue";
import GridView from "../common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "@/rest/odataContext";
import Roles from "../../assets/roles";
import StatusRenderer from "../../components/common/grid/StatusRenderer";

export default {
  components: {
    MarketCreateDrawer,
    GridView,
    DxColumn
  },
  data() {
    return {
      dataSource: {
        store: ODataContext['Markets'],
        select: [
          "ReferenceId",
          "Active",
          "Status",
          "Name",
          "Address",
          "City",
          "State",
          "BranchName"
        ]
      }
    };
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onRowSelected(data) {
      const refId = data["ReferenceId"];
      this.$router.push(`/markets/details/${refId}`);
    },
    onCreateSuccess() {
      this.$refs.createDrawer.refreshForm();
      this.$refs.nav.refreshGrid();
      this.$refs.nav.showSuccess("Created new market successfully!");
      this.$refs.nav.hideDrawer();
    },
    onCreateFail(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.createDrawer.refreshForm();
      this.$refs.nav.hideDrawer();
    },
    StatusRenderer
  }
};
</script>
