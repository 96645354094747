<template>
  <v-container grid-list-lg>
    <v-row class="ma-1">
      <v-col md="6">
        <v-text-field
            :value="appointmentDate"
            label="Appointment Date"
            ref="appointmentDate"
            placeholder=" "
            persistent-placeholder
            readonly
            id="lead-details-form-apt-date-read"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="timeBlockName"
            label="Appointment Time"
            placeholder=" "
            persistent-placeholder
            readonly
            id="lead-details-form-apt-time-read"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="marketName"
            label="Market Name"
            placeholder=" "
            persistent-placeholder
            readonly
            id="lead-details-form-market-name-read"
        />
      </v-col>
      <v-col md="12">
        <lead-rooms-search-field
            ref="leadRooms"
            :value="leadRooms"
            :readonly="true"
            :selectAllEnabled="false"
            placeholder=" "
        />
      </v-col>
      <v-col md="12">
        <installation-capabilities-search-field
            ref="leadInstallationCapabilities"
            :value="installationCapabilities"
            :readonly="true"
            :placeholder="' '"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LeadRoomsSearchField from "../../../components/searchFields/LeadRooms.vue";
import InstallationCapabilitiesSearchField from "../../../components/searchFields/InstallationCapabilities.vue";
import DateFormatter from "../../../assets/dateFormatter";

const formDefaults = {
  appointmentDate: null,
  appointmentTime: null,
  timeBlockName: null,
  comment: null,
  customerReferenceId: null,
  leadStatusName: null,
  salesRepName: null,
  leadRooms: [],
  installationCapabilities: [],
  marketName: null
};
export default {
  components: {
    LeadRoomsSearchField,
    InstallationCapabilitiesSearchField
  },

  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    loadForm(payload, leadRooms, installationCapabilities) {
      this.appointmentDate = payload.appointmentDate
          ? DateFormatter.formatDateInUtcAndMMDDYYYYFormat(payload.appointmentDate)
          : null;
      this.comment = payload.comment;
      this.customerId = payload.customerId;
      this.customerReferenceId = payload.customerReferenceId || null;
      this.salesRepName = payload.salesRepName;
      this.leadStatusName = payload.leadStatusName;
      this.appointmentTime = payload.appointmentTime;
      this.timeBlockName = payload.timeBlockName;
      this.installationCapabilities = installationCapabilities;
      this.leadRooms = leadRooms;
      this.marketName = payload.marketName;
    }
  }
};
</script>

<style scoped>
.divider {
  border-bottom: 20px;
}
</style>
