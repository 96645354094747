<template>
  <div>
    <inline-grid
        :data-source="overbills"
        :show-borders="false"
        @deleteClicked="onDeleteClicked"
    >
      <dx-column
          data-field="effectiveOn"
          caption="Status"
          :cell-template="renderActiveInactive"
      />
      <dx-column
          data-field="effectiveOn"
          :cell-template="renderEffectiveDate"
          caption="Price Effective Date"
      />
      <dx-column
          data-field="overbill"
          caption="Overbill"
          :format="format"
      />
      <dx-column
          v-if="isMigratedItem"
          data-field="id"
          width="5%"
          caption
          cellTemplate="delete"
      />
      <template v-slot:footer>
        <inline-grid-footer v-if="isMigratedItem">
          <inline-grid-button
              v-if="isAddButtonDisplayed"
              label="Add Overbill"
              @click="onAddOverbillEntry"
          />
        </inline-grid-footer>
      </template>
      <template v-slot:customRenderer>
        <v-card color="red"></v-card>
      </template>
    </inline-grid>

    <add-overbill-entry-dialog
        ref="dialog"
        :catalog-item-id="catalogItemId"
        @overbillAdded="overbillAdded"
        :overbills="overbills"
    />
    <error-modal
        ref="errorModal"
        :error="error"
        :title="errorTitle"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </div>
</template>

<script>
import moment from "moment";
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import AddOverbillEntryDialog from "@/components/catalogItems/AddOverbillEntryDialog.vue";
import ErrorModal from "@/components/navigation/ErrorModal.vue";

export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    AddOverbillEntryDialog,
    ErrorModal
  },

  props: {
    catalogItemId: Number,
    overbills: Array,
    isAddButtonDisplayed: {
      type: Boolean,
      default: true
    },
    isMigratedItem: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      format: {
        type: "currency",
        precision: 2,
      },
      isErrorModalOpen: false,
      error: null
    };
  },

  methods: {
    renderEffectiveDate(element, column) {
      let date = moment(column.data.effectiveOn).utc().format("MM/DD/YYYY");
      element.insertAdjacentHTML("beforeend", date);
    },
    renderActiveInactive(element, column) {
      let active = column.data.effective;
      let content = active
          ? "<span style='color: #090;'>Active</span>"
          : "<span style='color: #900;'>Inactive</span>";
      element.insertAdjacentHTML("beforeend", content);
    },
    onDeleteClicked(cellData) {
      if (cellData.data.effective || moment(cellData.data.effectiveOn).isBefore(moment())) {
        this.showErrorModal(new Error());
      }
      this.$emit("deleteClicked", cellData);
    },
    addVendorCostButtonClicked() {
      this.$emit("addVendorCost");
    },
    onAddOverbillEntry() {
      this.$refs.dialog.openDialog();
    },
    overbillAdded() {
      this.$emit("overbillAdded");
    },
    showErrorModal(error) {
      error = "Unable to delete overbill with dates in the past. Overbill can be updated only with future dates";
      this.error = error;
      this.isErrorModalOpen = true;
      throw new Error(error);
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    }
  },
};
</script>
