<template>
  <navigation-view
      ref="nav"
      page-title="Inventory Item Details"
      nav-section="warehouse"
      @goBackButtonClicked="onGoBackButtonClicked"
  >
    <template v-slot:content>
      <v-container fluid id="inventory-details">
        <v-row class="mt-1" no-gutters>
          <v-col cols="5">
            <inventory-item-details-form
                ref="inventoryItemDetailsForm"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="5">
            <inventory-item-catalog-information
                ref="inventoryItemCatalogInformation"
            />
          </v-col>
        </v-row>

        <inventory-items-inline-grids
            ref="inlineGrids"
            :inventoryItemId="inventoryItemId"
        />

        <move-item-to-another-bin-dialog
            ref="moveItemDialog"
            title="Move Item To Another Bin"
            :width="800"
            submit-label="Move Item"
            cancel-label="Cancel"
            :dialogVisible="isMoveDialogVisible"
            :onHand="onHand"
            :loading="isMoveItemLoading"
            :available="available"
            :branchReferenceId="branchReferenceId"
            :boxQuantity="boxQty"
            :itemBinNumber="itemBinNumber"
            :inventoryItemId="inventoryItemId"
            @destinationBinSelected="onDestinationBinSelected"
            @submitClicked="onSubmitMovementClicked"
            @cancelMovementClicked="onCancelMovementClicked"
        />

        <confirmation-dialog
            :width="600"
            :visible="isConfirmationDialogVisible"
            :loading="false"
            :confirmationText="confirmationText"
            submitLabel="Confirm"
            @onCancelClicked="onCancelMovementConfirmation"
            @onSubmitClicked="onSubmitMovementConfirmation"
        />

      </v-container>
    </template>

    <template v-slot:drawer>
      <inventory-item-drawer-form
          ref="inventoryItemDrawerForm"
          title="Edit Inventory Item"
          submitLabel="Save Inventory Item"
          :isRolled="isRolled"
          :hasDyeLot="hasDyeLot"
          @cancelClicked="onCancelInventoryItemUpdate"
          @submitClicked="onSubmitInventoryItemUpdate"
      />
    </template>

    <template v-slot:nav-action>
      <v-row id="buttons-row" class="d-flex justify-end ma-0 pb-0 pt-0">
        <v-col cols="6" class="pb-0 bt-0 pr-1 pl-0 pt-0" v-show="showEditInventoryButton">
          <navigation-action-button
              :showActionButtons="!!showEditInventoryButton"
              label="Edit Item"
              @click="showDrawer"
          />
        </v-col>
        <v-col cols="6" class="pb-0 bt-0 pr-0 pl-0 pt-0" v-show="showMoveButton">
          <navigation-action-button
              :showActionButtons="!!showMoveButton"
              label="Move Item"
              @click="showMoveItemDialog"
          />
        </v-col>
      </v-row>
    </template>

  </navigation-view>
</template>

<script>
import InventoryItemDrawerForm from "../../components/inventoryItems/InventoryItemDrawerForm.vue";
import InventoryItemCatalogInformation from "../../components/inventoryItems/InventoryItemCatalogInformation.vue";
import InventoryItemDetailsForm from "../../components/inventoryItems/InventoryItemDetailsForm.vue";
import NavigationView from "../../views/navigation/NavigationView.vue";
import NavigationActionButton from "../../views/navigation/NavigationActionButton.vue";
import InventoryItems from "../../rest/inventoryItems.ts";
import MoveItemToAnotherBinDialog from "@/components/common/dialogContainers/MoveItemToAnotherBinDialog.vue";
import InventoryItemsInlineGrids from "@/views/inventoryItems/InventoryItemsInlineGrids.vue";
import ConfirmationDialog from "../../components/common/ConfirmationDialog.vue";
import RouterHelper from "@/assets/routerHelper";
import UserHelper from "../../assets/userHelper";

export default {
  components: {
    ConfirmationDialog,
    InventoryItemsInlineGrids,
    MoveItemToAnotherBinDialog,
    NavigationView,
    NavigationActionButton,
    InventoryItemDrawerForm,
    InventoryItemCatalogInformation,
    InventoryItemDetailsForm,
  },

  data() {
    return {
      inventoryItemId: null,
      onHand: null,
      available: null,
      inventoryItem: null,
      branch: null,
      productCatalogInformation: null,
      branchReferenceId: null,
      confirmationText: '',
      isConfirmationDialogVisible: false,
      isMoveDialogVisible: false,
      isMoveItemLoading: false,
      unconfirmedPayload: null,
      destinationBin: null
    };
  },

  created() {
    this.display(this.$route.params.inventoryItemId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.inventoryItemId);
    next();
  },

  computed: {
    showEditInventoryButton() {
      return UserHelper.hasAccessToBranch(this.branchReferenceId) &&
          this.inventoryItem &&
          (this.isItemRolled() || this.hasItemDyeLot()) && this.inventoryItem.onHand > 0;
    },
    showMoveButton() {
      return UserHelper.hasAccessToBranch(this.branchReferenceId) &&
          ((this.inventoryItem && this.inventoryItem.onHand > 0)
              || (this.onHand && this.available && this.onHand === 0 && this.available === 0));
    },
    boxQty() {
      return this.inventoryItem && this.inventoryItem.productCatalogItem.boxQuantity;
    },
    itemBinNumber() {
      return this.inventoryItem && this.inventoryItem?.inventoryBin.binNumber;
    },
    isRolled() {
      return this.isItemRolled();
    },
    hasDyeLot() {
      return this.hasItemDyeLot();
    },
  },

  methods: {
    isItemRolled() {
      return this.inventoryItem && this.inventoryItem.productCatalogItem.productType.isRolled;
    },
    hasItemDyeLot() {
      return this.inventoryItem && this.inventoryItem.productCatalogItem.productType.hasDyeLot;
    },
    display(inventoryItemId) {
      this.inventoryItemId = inventoryItemId;
      this.refresh();
    },
    async refresh() {
      try {
        this.clearMoveItemDialog();
        this.isConfirmationDialogVisible = false;
        this.isMoveItemLoading = false;
        this.isMoveDialogVisible = false;
        const inventoryItem = await this.getAllInventoryItemData();
        this.setInventoryData(inventoryItem);
        this.loadAllForms();
      } catch (error) {
        this.showError(error);
      }
    },
    async getAllInventoryItemData() {
      try {
        const response = await InventoryItems.getRestApi().getInventoryItemById(this.inventoryItemId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    setInventoryData(inventoryItem) {
      this.inventoryItem = inventoryItem;
      this.onHand = inventoryItem.onHand;
      this.available = inventoryItem.available;
      this.branchReferenceId = inventoryItem?.inventoryBin?.branchReferenceId;
      this.productCatalogInformation = inventoryItem.productCatalogItem;
    },
    loadAllForms() {
      this.loadInventoryItemDetailsForm();
      this.loadInventoryItemDrawerForm();
      this.loadInventoryItemCatalogInformation();
    },
    async loadInventoryItemDetailsForm() {
      await this.$refs.inventoryItemDetailsForm.loadForm(this.inventoryItem);
    },
    loadInventoryItemDrawerForm() {
      this.$refs.inventoryItemDrawerForm.loadDrawerForm(this.inventoryItem);
    },
    loadInventoryItemCatalogInformation() {
      this.$refs.inventoryItemCatalogInformation.loadForm(this.productCatalogInformation, this.inventoryItem);
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    onSubmitSuccess() {
      this.$refs.nav.hideDrawer();
      this.refresh();
    },
    onCancelInventoryItemUpdate() {
      this.loadInventoryItemDrawerForm();
      this.$refs.nav.hideDrawer();
    },
    async updateInventoryItem(params) {
      try {
        await InventoryItems.getRestApi().updateInventoryItem(this.inventoryItemId, params);
      } catch (error) {
        throw error;
      }
    },
    async onSubmitInventoryItemUpdate(params) {
      try {
        await this.updateInventoryItem(params);
        const data = await this.getAllInventoryItemData();
        this.handleUpdateSuccess(data);
      } catch (error) {
        this.showError(error);
      }
    },
    handleUpdateSuccess(data) {
      this.$refs.inventoryItemDetailsForm.loadForm(data);
      this.$refs.nav.showSuccess('Updated inventory item details successfully!');
      this.$refs.nav.hideDrawer();
      this.refreshActivityLog();
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    refreshAllInlineGrids() {
      this.refreshInventoryReservations();
      this.refreshActivityLog();
      this.refreshAuditLog();
    },
    refreshActivityLog() {
      if (this.$refs.inlineGrids) {
        this.$refs.inlineGrids.refreshActivityLog();
      }
    },
    refreshAuditLog() {
      if (this.$refs.inlineGrids) {
        this.$refs.inlineGrids.refreshAuditLog();
      }
    },
    refreshInventoryReservations() {
      if (this.$refs.inlineGrids) {
        this.$refs.inlineGrids.refreshInventoryReservationsGrid();
      }
    },
    showMoveItemDialog(value) {
      if (value != null || value !== undefined) {
        this.isMoveDialogVisible = value;
      } else {
        this.isMoveDialogVisible = true;
      }
    },
    async onSubmitMovementClicked() {
      let payload = this.buildPayload();
      this.confirmationText = this.getConformationText(payload.quantity);
      this.isConfirmationDialogVisible = true;
      this.unconfirmedPayload = payload;
    },
    buildPayload() {
      let payload = this.getMoveItemFormData();
      let movePartSelected = this.getSelectedType() === 2;
      if (movePartSelected) {
        payload.inventoryItemId = Number(this.inventoryItemId);
      }
      return payload;
    },
    getMoveItemFormData() {
      return this.$refs.moveItemDialog.getFormData();
    },
    async performInventoryMovement(payload) {
      const newInventoryItemId = await InventoryItems.getRestApi().moveInventoryItems(payload)
          .then((result) => {
            return result.data;
          }).catch((error) => {
            this.showError(error);
          }).finally(() => {
            this.isMoveItemLoading = false;
          });
      let selectedType = this.getSelectedType();
      if (newInventoryItemId && (selectedType === 2 || selectedType === 3)) {
        const isSameRoute = RouterHelper.includesPart(this.$route, newInventoryItemId);
        if (!isSameRoute) {
          this.$router.push(`/inventory/details/${newInventoryItemId}`);
        }
      }
      if (newInventoryItemId && selectedType !== 1) {
        this.refresh();
      }
      this.$refs.nav.showSuccess(this.getSuccessMessage());
      this.refreshAllInlineGrids();
    },
    async performBinUpdate(payload) {
      try {
        await InventoryItems.getRestApi().updateBin(this.inventoryItemId, payload.binId);
        this.$refs.nav.showSuccess(this.getSuccessMessage());
        this.fullRefresh();
      } catch (error) {
        this.showError(error);
      } finally {
        this.isMoveItemLoading = false;
      }
    },
    fullRefresh() {
      this.refresh();
      this.refreshAllInlineGrids();
    },
    getSuccessMessage() {
      let selectedType = this.getSelectedType();
      if (selectedType === 3) {
        return 'Reservation successfully moved';
      } else if (selectedType === 2) {
        return 'Available quantity successfully moved';
      } else {
        return 'Item successfully moved to another bin';
      }
    },
    getSelectedType() {
      return this.$refs.moveItemDialog.$refs.radioTypes.selected;
    },
    theSameAmountEnteredAsOnHandAndAvailable(quantityEntered, available, onHand) {
      return quantityEntered === available && available === onHand;
    },
    getConformationText(quantity) {
      return this.buildConfirmationText(this.getSelectedType(), quantity);
    },
    async onSubmitMovementConfirmation() {
      this.isConfirmationDialogVisible = false;
      this.isMoveItemLoading = true;
      if (this.getSelectedType() === 1
          || this.theSameAmountEnteredAsOnHandAndAvailable(this.unconfirmedPayload.quantity, this.available, this.onHand)) {
        await this.performBinUpdate(this.unconfirmedPayload);
      } else {
        await this.performInventoryMovement(this.unconfirmedPayload);
      }
    },
    onCancelMovementClicked() {
      this.clearMoveItemDialog();
      this.showMoveItemDialog(false);
    },
    clearMoveItemDialog() {
      if (this.$refs.moveItemDialog) {
        this.$refs.moveItemDialog.fullResetAndRestoreDefaults();
      }
    },
    onCancelMovementConfirmation() {
      this.resetConfirmationData();
    },
    resetConfirmationData() {
      this.confirmationText = '';
      this.isConfirmationDialogVisible = false;
      this.unconfirmedPayload = null;
    },
    onDestinationBinSelected(value) {
      this.destinationBin = value;
    },
    buildConfirmationText(option, quantity) {
      switch (option) {
        case 2:
          return `Are you sure you want to move the quantity of ${quantity} from ${this.itemBinNumber} to ${this.destinationBin.binNumber}?
           Please confirm the quantity.`;
        case 3:
          return `Are you sure you want to move selected item(s) reservation from ${this.itemBinNumber} to ${this.destinationBin.binNumber}?`;
        default:
          return `Are you sure you want to move item from ${this.itemBinNumber} to ${this.destinationBin.binNumber} bin?`;
      }
    },
    onGoBackButtonClicked() {
      setTimeout(() => {
        if (RouterHelper.isDetailsPage(this.$route) && this.inventoryItemId) {
          this.refreshAllInlineGrids();
        }
      }, 1000);
    },
  }
};
</script>
