<template>
  <v-card light>
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Create Transfer Request</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <stepper-headers
          :step="step"
          step-one-name="Choose Branches"
          step-two-name="Choose Item(s)"
          step-three-name="Enter Quantity"
          step-four-name="Overview"
          :max-step-number=4
          :editable="false"
      />
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pa-8">
            <choose-branches-step
                ref="branchesChooser"

                :activeBranches="activeBranches"
                @branchSelectionNeeded="onBranchSelectionNeeded"
                @branchesSelected="onAllBranchesSelected"
            />
            <button-container-with-cancel
                backName=Cancel
                nextName=Next
                :hidePreviousStep="true"
                :disableNextStep="firstNextStepDisabled"
                @cancel="cancelClicked"
                @getNextStep="stepOneContinue"
            />
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <select-grouped-inventory-items-container
              ref="selectInventoryItems"
              v-if="step !== 1"
              :selectedFromBranch="selectedFromBranch"
              @showError="showStepTwoError"
              @nothingSelected="onInventoryItemSelectionNeeded"
              @atLeastOneItemSelected="onOneOrMoreItemsSelected"
          />
          <p class="submitError" v-show="stepTwoError">{{ stepTwoError }}</p>
          <button-container-with-cancel
              backName=Back
              nextName=Next
              :disableNextStep="secondNextStepDisabled"
              @cancel="cancelClicked"
              @getPreviousStep="getBackToStepOne"
              @getNextStep="stepTwoContinue"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <enter-quantity-step
              ref="enterQuantity"
              :selectedItems="selectedItems"
              @computeQuantity="onAdjustedSelectedItemsQuantity"
              @enteredQtyIsMoreThanAvailable="onEnteredQtyIsMoreThanAvailable"
              @enteredValueIsFloatForNonBoxQtyItem="onEnteredValueIsFloatForNonBoxQtyItem"
              @quantityCleared="onQuantityCleared"
          />
          <button-container-with-cancel
              backName=Back
              nextName=Next
              :disableNextStep="thirdNextStepDisabled"
              @cancel="cancelClicked"
              @getPreviousStep="getBackToStep(2)"
              @getNextStep="stepThreeContinue"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <transfer-request-overview
              v-if="transferRequestPlan"
              ref="transferOverview"
              :error="stepThreeError"
              :overviewList="transferRequestPlan.overviewList"
              :selectedToBranch="selectedToBranch"
              :selectedFromBranch="selectedFromBranch"
          />
          <button-container-with-cancel
              :nextLoading="inProgress"
              backName=Back
              nextName=Submit
              @cancel="cancelClicked"
              @getPreviousStep="getBackToStep(3)"
              @getNextStep="submitForm"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import StepperHeaders from "../common/stepper/headers/ConfigurableStepperHeaders.vue";
import ButtonContainerWithCancel from "../common/wizard/ButtonContainerWithCancel.vue";
import ButtonContainer from "../common/wizard/ButtonContainer.vue";
import ChooseBranchesStep from "./steps/ChooseBranchesStep.vue";
import SelectGroupedInventoryItemsContainer from "./steps/SelectGroupedInventoryItemsContainer.vue";
import EnterQuantityStep from "./steps/EnterQuantityStep.vue";
import TransferRequestOverview from "./steps/TransferRequestOverview.vue";
import TransferRequests from "../../rest/transferRequests";
import FormattingHelper from "../../assets/formattingHelper";

export default {
  data: () => ({
    selectedItems: [],
    selectedToBranch: null,
    selectedFromBranch: null,
    stepTwoError: null,
    stepThreeError: null,
    step: 1,
    inProgress: false,
    firstNextStepDisabled: true,
    secondNextStepDisabled: true,
    thirdNextStepDisabled: true,
    transferRequestPlan: null
  }),

  components: {
    TransferRequestOverview,
    SelectGroupedInventoryItemsContainer,
    ChooseBranchesStep,
    EnterQuantityStep,
    ButtonContainer,
    ButtonContainerWithCancel,
    StepperHeaders,
  },

  props: {
    activeBranches: {
      type: Array,
      required: true
    }
  },

  methods: {
    async submitForm() {
      try {
        this.inProgress = true;
        let response = await this.submitTransferRequestCreation();
        if (response) {
          this.handleSubmitCreateTransferRequestSuccess();
        }
      } catch (error) {
        this.$emit('showError', error);
      }
    },
    stepOneContinue() {
      let valid = this.$refs.branchesChooser.$refs.form.validate();
      if (valid) {
        this.selectedFromBranch = this.$refs.branchesChooser.$refs.fromBranch.selected;
        this.selectedToBranch = this.$refs.branchesChooser.$refs.toBranch.selected;
        this.goToNextStep(2);
      }
    },
    stepTwoContinue() {
      let selectedItems = this.$refs.selectInventoryItems.getSelectedItems();
      this.selectedItems = [...selectedItems];
      this.goToNextStep(3);
      this.stepTwoError = null;
    },
    goToNextStep(step) {
      this.step = step;
    },
    getBackToStepOne() {
      this.step = 1;
      this.stepTwoError = null;
      this.secondNextStepDisabled = true;
      this.selectedItems = [];
      this.$refs.selectInventoryItems.clearGridState();
    },
    async stepThreeContinue() {
      this.stepThreeError = null;
      let itemsToTransfer = this.$refs.enterQuantity.getItemsFormData();
      let payload = {
        fromBranch: this.selectedFromBranch.referenceId,
        plannedInventoryItems: itemsToTransfer,
      }
      if (!this.stepThreeError) {
        let response = await this.performBranchFulfillmentRequest(payload);
        if (response) {
          this.goToNextStep(4);
        }
      }
    },
    async performBranchFulfillmentRequest(payload) {
      try {
        const response = await TransferRequests.getRestApi().createTransferRequestFulfillmentPlan(payload);
        let transferPlan = response.data;
        transferPlan.overviewList = FormattingHelper.formatPlanItemsToOverviewItems(transferPlan);
        this.transferRequestPlan = transferPlan;
        return response.data;
      } catch (error) {
        if (error.response.status === 400) { // && error.message starts with ... to catch only errors with qty
          this.goBackToTheSecondStepIfQuantityErrorOccurred();
        }
        this.$emit('showError', error);
      } finally {
        this.inProgress = false;
      }
    },
    onAllBranchesSelected() {
      this.firstNextStepDisabled = false;
    },
    onBranchSelectionNeeded() {
      this.firstNextStepDisabled = true;
    },
    async submitTransferRequestCreation() {
      try {
        const payload = this.createManualTransferRequestPayload();
        return await TransferRequests.getRestApi().createManualTransferRequest(payload);
      } catch (error) {
        this.handleSubmitTransferRequestError(error);
      } finally {
        this.inProgress = false;
      }
    },
    createManualTransferRequestPayload() {
      return {
        transferFrom: this.selectedFromBranch.id,
        transferTo: this.selectedToBranch.id,
        plan: this.transferRequestPlan.plan
      }
    },
    onAdjustedSelectedItemsQuantity() {
      let haveValidQuantityEntered = this.validateAllItemsQuantity();
      if (haveValidQuantityEntered) {
        this.stepThreeError = null;
        this.selectedItems = [...this.selectedItems];
        this.thirdNextStepDisabled = false;
      }
    },
    validateAllItemsQuantity() {
      return this.$refs.enterQuantity.getItemsFormData().every(
          item => item.quantity > 0 && item.quantity !== null && item.quantity <= item.quantityAvailable
      );
    },
    getBackToStep(step) {
      this.stepTwoError = null;
      this.stepThreeError = null;
      this.step = step;
      if (this.$refs.enterQuantity && step === 2) {
        this.$refs.enterQuantity.clearRefs();
      }
    },
    cancelClicked() {
      this.resetWizard();
      this.$emit('cancelClicked');
    },
    handleSubmitTransferRequestError(error) {
      this.inProgress = false;
      this.$emit('showError', error);
    },
    handleSubmitCreateTransferRequestSuccess() {
      this.resetWizard();
      this.$emit('success', 'Transfer Request has been successfully created!');
    },
    showStepTwoError(error) {
      this.stepTwoError = error;
      this.secondNextStepDisabled = true;
    },
    resetWizard() {
      this.step = 1;
      this.inProgress = false;
      this.stepTwoError = null;
      this.stepThreeError = null;
      this.secondNextStepDisabled = true;
      this.thirdNextStepDisabled = true;
      this.selectedItems = [];
      if (this.$refs.branchesChooser) {
        this.$refs.branchesChooser.resetSelected();
      }
      if (this.$refs.selectInventoryItems) {
        this.$refs.selectInventoryItems.$refs.groupedInventoryChooser.clearGrid();
      }
    },
    onInventoryItemSelectionNeeded() {
      this.secondNextStepDisabled = true;
    },
    onOneOrMoreItemsSelected() {
      this.stepTwoError = null;
      this.secondNextStepDisabled = false;
    },
    onEnteredQtyIsMoreThanAvailable(value, quantityAvailable) {
      if (value) {
        this.stepThreeError = value <= 0
            ? 'Quantity to transfer must be greater than 0'
            : `Quantity to transfer cannot exceed available quantity ${quantityAvailable}`;
        this.thirdNextStepDisabled = true;
      }
    },
    onEnteredValueIsFloatForNonBoxQtyItem(value) {
      if (value) {
        this.stepThreeError = `Entered Quantity to Transfer ${value} must be an integer`;
        this.thirdNextStepDisabled = true;
      }
    },
    onQuantityCleared() {
      this.thirdNextStepDisabled = true;
    },
    goBackToTheSecondStepIfQuantityErrorOccurred() {
      this.selectedItems = [];
      this.$refs.selectInventoryItems.clearGridState();
      this.$refs.selectInventoryItems.reset();
      this.getBackToStep(2);
    }
  }
};
</script>

<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
