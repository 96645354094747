<template>
  <div>
    <div class="text-truncate">
      <strong>{{ lineItem.productCatalogItem.styleName }}</strong>
    </div>
    <div class="text-truncate">{{ lineItem.productCatalogItem.color }}</div>
    <div class="text-truncate">
      Box Quantity: {{ lineItem.productCatalogItem.boxQuantity }}
    </div>
    <div class="text-truncate">
      Unit of Measure: {{ lineItem.productCatalogItem.unitOfMeasure }}
    </div>
    <div class="text-truncate">
      Internal SKU: {{ lineItem.productCatalogItem.internalSku }}
    </div>
    <div v-if="displayReferenceId" class="text-truncate">
      Reference Id: {{ lineItem.jobLineItem.referenceId }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lineItem: Object,
    displayReferenceId: {
      type: Boolean,
      default: false
    }
  }
};
</script>
