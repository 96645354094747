import Constants from "@/assets/constants";

const createWarningMessage = (field, fieldCountIndicator) =>
    `There are other products in the catalog with the same Product Type and Style, but with different ${field}.` +
    `\nIf you save the current item, the value from its ${field} ${fieldCountIndicator} will also be set for all those matching products`;

const ErrorMessages = {
    updateProductClassAllOtherProductTypesAndStylesWarning: createWarningMessage("Product Class", "field"),
    updatePrivateStyleAllOtherProductTypesAndStylesWarning: createWarningMessage("Private Label Style", "field"),
    updateAllOtherProductTypesAndStylesWarning: createWarningMessage("Private Label Style and Product Class", "fields"),
};

export const LoginErrorMessages = {
    defaultError: "An unexpected error occurred. Please try again later.",
    unsuccessfulLogin: "Invalid credentials or password expired.",
    webAccessForbidden: "Web Access Is Forbidden.",
    otpVerificationFailed: "6-digit Code Verification Failed.",
    inactiveLogout: `There has not been any activity for ${Constants.SESSION_INACTIVITY_TIMEOUT / (60 * 60 * 1000)} hours. For security reasons, your session has been terminated.`,
    tokenExpired: "Your session has expired.  Please login again.",
    versionLogout: "Oasis has been updated. We have cleared your session for a better experience.",
}

export default ErrorMessages;
