<template>
  <v-icon color="primary">{{ iconValue }}</v-icon>
</template>

<script>
export default {
  props: {
    cellData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      iconValue: null
    };
  },

  watch: {
    cellData: {
      immediate: true,
      handler: function(newVal) {
        if (newVal.data.HasNote === "Yes") {
          this.$data.iconValue = "description";
        }
      }
    }
  }
};
</script>
