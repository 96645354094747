import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Product type.
 */
export class LeadProductType {
  id: number;
  name: string;
  active: boolean;
  description: string;

  constructor(id: number, name: string, description: string, active: boolean) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.active = active;
  }
}

/**
 * REST interface provided for LEAD product types.
 */
export interface LeadProductTypeRestApi {
  getActiveLeadProductTypes(): AxiosPromise<LeadProductType[]>;
}

/**
 * REST implementation provided for LEAD product types.
 */
class Implementation implements LeadProductTypeRestApi {
  getActiveLeadProductTypes(): AxiosPromise<LeadProductType[]> {
    return Axios.axiosInstance().get<LeadProductType[]>("leads/product-types/active");
  }
}

const ProductTypes = {
  getRestApi(): LeadProductTypeRestApi {
    return new Implementation();
  }
};

export default ProductTypes;
