<template>
  <v-col md="12">
    <v-card-actions>
      <v-checkbox
          v-if="showCheckbox"
          v-model="checkboxValue"
          color="primary"
          label="Save and Create Another Color"
      />
      <v-btn
          large
          dark
          ref="cancelButton"
          color="red"
          v-if="showDelete"
          :loading="deleting"
          @click="onDelete"
      >
        Delete
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
          large
          dark
          outlined
          ref="cancelButton"
          color="primary"
          v-if="!isContinueMode && !hideCancel"
          @click="onCancel"
      >
        Cancel
      </v-btn>
      <v-btn
          large
          dark
          ref="submitButton"
          color="primary"
          v-if="!hideSubmit"
          :loading="inProgress"
          @click="onSubmit"
      >
        {{ mutableSubmitLabel }}
      </v-btn>
    </v-card-actions>
  </v-col>
</template>

<script>
export default {
  props: {
    submitLabel: String,
    hideSubmit: Boolean,
    hideCancel: Boolean,
    showCheckbox: Boolean,
    showDelete: Boolean,
    inProgress: Boolean,
    deleting: Boolean,
    isLoading: Boolean,
  },

  data() {
    return {
      checkboxValue: false,
      eventName: "submitClicked",
      isContinueMode: false,
      mutableSubmitLabel: this.$props.submitLabel
    };
  },

  watch: {
    checkboxValue(val) {
      this.$emit("checkboxValueChange", val);
    }
  },

  methods: {
    reset() {
      this.eventName = "submitClicked";
      this.mutableSubmitLabel = this.submitLabel;
      this.isContinueMode = false;
    },
    onDelete() {
      this.$emit("deleteClicked");
      this.reset();
    },
    onCancel() {
      this.$emit("cancelClicked");
      this.reset();
    },
    onSubmit(event) {
      event.preventDefault();
      this.$emit(this.eventName, event);
      this.reset();
    },
    getCheckboxValue() {
      return this.checkboxValue;
    },
    resetCheckboxValue() {
      this.checkboxValue = false;
    }
  }
};
</script>

<style></style>
