<template>
  <navigation-view ref="nav" page-title="User Details" nav-section="admin">
    <template v-slot:content>
      <v-container fluid v-if="isUserLoaded">
        <user-read-only :user="user"/>

        <inline-grids
            v-if="isUserLoaded"
            ref="inlineGrids"
            :userId="userId"
            @showSuccess="showNavSuccess"
            @showError="showNavError"
        />
      </v-container>
    </template>
    <template v-slot:drawer="{ isDrawerVisible }">
      <user-update-drawer
          :user="user"
          :isVisible="isDrawerVisible"
          @cancelClicked="onCancel"
          @onUpdateFail="onUpdateFail"
          @onUpdateSuccess="onUpdateSuccess"
          @onEmailUpdateSuccess="onEmailUpdateSuccess"
          @resetMfaSuccess="onResetMfaSuccess"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isActionButtonDisplayed"
          label="Edit User"
          @click="showDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>
import Users from "@/rest/users.ts";
import UserReadOnly from "@/components/users/UserDetailsReadOnly.vue";
import UserUpdateDrawer from "@/components/users/UserUpdateDrawer.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import NavigationView from "@/views/navigation/NavigationView.vue";
import InlineGrids from "@/views/users/InlineGrids.vue";
import Roles from "../../assets/roles";
import Constants from "@/assets/constants";

export default {
  components: {
    UserReadOnly,
    UserUpdateDrawer,
    NavigationActionButton,
    NavigationView,
    InlineGrids
  },

  data() {
    return {
      userId: 0,
      user: {},
      isUserLoaded: false
    };
  },

  computed: {
    isActionButtonDisplayed() {
      return Roles.isSuperAdminUser();
    }
  },

  created() {
    this.display(this.$route.params.userId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.userId);
    next();
  },

  methods: {
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    display(userId) {
      this.userId = Number(userId);
      this.getUserById();
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    async getUserById() {
      try {
        const response = await Users.getRestApi().getUserById(this.userId);
        this.setUser(response.data);
      } catch (error) {
        this.showNavError(error);
      }
    },
    setUser(data) {
      this.user = data;
      this.isUserLoaded = true;
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    onUpdateSuccess() {
      this.getUserById();
      this.refreshActivityLog();
      this.showNavSuccess("Updated user details successfully!");
      this.$refs.nav.hideDrawer();
    },
    onEmailUpdateSuccess() {
      this.showNavSuccess("Your email has been changed, performing force logout");
      this.$refs.nav.hideDrawer();
      this.$store.commit("logout");
    },
    onUpdateFail(error) {
      this.showNavError(error);
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    showNavSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    onResetMfaSuccess(updatedUser) {
      this.setUser(updatedUser);
      this.refreshActivityLog();
      this.showNavSuccess(Constants.mfaResetSuccessMessage);
      this.$refs.nav.hideDrawer();
    },
  }
};
</script>
