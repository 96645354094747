<template>
  <navigation-view ref="nav" page-title="Inventory Management" nav-section="orders">
    <template v-slot:content>
      <v-container grid-list-lg column>
        <div class="headline">Check-In Inventory</div>
        <v-divider/>
        <search-p-o-number-form
            ref="searchPONumber"
            v-model="poNumber"
            :excludeClosed="true"
            :searchError="searchError"
            :jobReferenceId="jobReferenceId"
            :ifShowJobReferenceId="ifShowJobReferenceId"
            @searchPurchaseOrderNumber="handleSearchPurchaseOrderNumber"
        />

        <!-- if no purchase order selected, show temp search screen -->
        <no-search-completed v-if="!purchaseOrder"/>

        <v-row v-show="purchaseOrder">
          <v-col md="3">
            <v-text-field
                v-model="branchName"
                label="Branch"
                readonly
            />
          </v-col>
          <v-col md="3">
            <v-text-field
                v-model="vendorName"
                label="Vendor Name"
                readonly
            />
          </v-col>
          <v-col md="3">
            <v-text-field
                v-if="customerName"
                v-model="customerName"
                label="Customer"
                readonly
            />
          </v-col>
          <v-col md="3">
            <v-text-field
                v-if="customerAddress"
                v-model="customerAddress"
                label="Customer Address"
                readonly
            />
          </v-col>
        </v-row>

        <v-row v-if="purchaseOrder">
          <v-col md="12">
            <v-card raised light color="white" class="mt-3 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Purchase Order Items</v-app-bar-title>
              </v-app-bar>
              <inventory-check-in-p-o-items
                  :poItems="poItems"
                  @poItemClicked="handlePOItemClicked"
              />
            </v-card>
          </v-col>

          <v-col md="12">
            <v-card raised light color="white" class="mt-3 mb-2 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Check-In Inventory</v-app-bar-title>
              </v-app-bar>
              <inventory-check-in-selected-items-table
                  :bins="bins"
                  :selectedItems="selectedItems"
                  :showOrderedQuantity="true"
                  :productTypes="productTypes"
                  @deleteClicked="onDeleteRow"
                  @reduceQuantityClicked="onReduceQuantity"
                  @increaseQuantityClicked="onIncreaseQuantity"
                  @selectedItemChange="selectedItemChange"
              />
            </v-card>
          </v-col>

          <v-col class="d-flex justify-end">
            <span class="submitError">{{ submitError }}</span>
            <v-btn
                large
                color="primary"
                class="checkInButton"
                :disabled="!selectedItems.length"
                :loading="inProgress"
                @click="handleCheckInInventory"
            >
              Check In
            </v-btn>
          </v-col>
        </v-row>

        <check-in-p-o-item-overreceiving
            ref="overreceivingModal"
            :productsOverreceived="productsOverreceived"
            @onOverreceivingApproved="overreceivingApproved"
        />

      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import InventoryCheckInPOItems from "../../components/inventoryManagement/InventoryCheckInPOItems.vue";
import InventoryCheckInSelectedItemsTable
  from "../../components/inventoryManagement/InventoryCheckInSelectedItemsTable.vue";
import SearchPONumberForm from "../../components/inventoryManagement/checkInPOItems/SearchPONumberForm.vue";
import NoSearchCompleted from "../../components/inventoryManagement/checkInPOItems/NoSearchCompleted.vue";
import CheckInPOItemOverreceiving from "../../components/inventoryManagement/checkInPOItems/CheckInPOItemOverreceiving";
import Rules from "../../assets/rules";
import BoxQuantity from "../../assets/boxQuantity";
import ProductTypes from "../../rest/productTypes";
import Bins from "../../rest/bins";
import PurchaseOrders from "../../rest/purchaseOrders.ts";
import InventoryItems from "../../rest/inventoryItems.ts";

export default {
  components: {
    NavigationView,
    InventoryCheckInPOItems,
    InventoryCheckInSelectedItemsTable,
    SearchPONumberForm,
    NoSearchCompleted,
    CheckInPOItemOverreceiving,
  },

  data() {
    return {
      poNumber: this.$route.query.referenceId,
      purchaseOrder: null,
      jobReferenceId: null,
      poItems: null,
      branchId: null,
      branchReferenceId: null,
      branchName: null,
      vendorName: null,
      customerName: null,
      customerAddress: null,
      selectedItems: [],
      bins: [],
      submitError: null,
      searchError: null,
      inProgress: false,
      productFullName: null,
      productsOverreceived: '',
      rules: {
        required: Rules.required,
      },
      productTypes: []
    };
  },

  created() {
    let component = this;
    ProductTypes.getRestApi()
        .getActiveProductTypes()
        .then((response) => {
          component.productTypes = response.data;
        })
  },

  mounted() {
    if (this.poNumber) {
      this.handleSearchPurchaseOrderNumber();
    }
  },

  computed: {
    ifShowJobReferenceId() {
      return this.purchaseOrder != null && this.purchaseOrder.job != null;
    }
  },

  methods: {
    async handleSearchPurchaseOrderNumber() {
      if (this.selectedItems.length > 0) {
        this.selectedItems = [];
      }
      try {
        const data = await this.getPurchaseOrderData();
        await this.getBinsByBranch(data.purchaseOrder.branch.referenceId);
        this.setDataFromResponse(data);
      } catch (error) {
        this.searchError = `Purchase Order Number ${this.poNumber} cannot be found. Please check the order number and try again.`;
        this.purchaseOrder = null;
      }
    },
    async getBinsByBranch(branchReferenceId) {
      try {
        const response = await Bins.getRestApi().getBinsByBranchReferenceId(branchReferenceId);
        this.bins = response.data;
      } catch (error) {
        throw error;
      }
    },
    async getPurchaseOrderData() {
      try {
        this.searchError = null;
        const valid = this.$refs.searchPONumber.validate();
        if (valid) {
          const {poNumber} = this.$refs.searchPONumber;
          const response = await PurchaseOrders.getRestApi().getPurchaseOrderByPurchaseOrderNumber(poNumber);
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    },
    setDataFromResponse(data) {
      const poItems = [...data.lineItems];
      this.jobReferenceId = data.purchaseOrder.job
          ? data.purchaseOrder.job.referenceId
          : null;
      this.branchId = data.purchaseOrder.branchId;
      this.branchReferenceId = data.purchaseOrder.branch.referenceId;
      this.branchName = data.purchaseOrder.branch.name;
      this.purchaseOrder = data.purchaseOrder;
      this.vendorName = data.purchaseOrder.vendor.name;
      this.poItems = this.adjustPOItemsViewForGrid(poItems);
      this.customerName = data.customer ? data.customer.fullName : null;
      this.customerAddress = data.customer ? data.customer.address : null;
    },
    adjustPOItemsViewForGrid(items) {
      const sortedItems = this.sortPOItemsByIfCheckedIn(items);
      return this.flagCheckedInItems(sortedItems);
    },
    flagCheckedInItems(items) {
      return items.map((item) => {
        let flaggedItem = {...item};
        flaggedItem.isCheckedIn = this.isCheckedIn(item);
        return flaggedItem;
      });
    },
    sortPOItemsByIfCheckedIn(items) {
      return items.sort(this.compareItemQuantitiesReceived);
    },
    compareItemQuantitiesReceived(a, b) {
      // sorts the array of item objects by comparing the quantity received
      // so checked in items, meaning they have a quantity received, will be at the bottom
      const quantityReceivedA = a.quantityReceived;
      const quantityReceivedB = b.quantityReceived;

      let comparison = 0;
      if (quantityReceivedA > quantityReceivedB) {
        comparison = 1;
      } else if (quantityReceivedA < quantityReceivedB) {
        comparison = -1;
      }
      return comparison;
    },
    isCheckedIn(item) {
      return !(item.quantityReceived < item.quantityOrdered || !item.quantityReceived) || item.status === 'Closed';
    },
    createRowData(selectedRow) {
      let itemTotal = 0;
      this.selectedItems.forEach((item) => {
        if (item.id === selectedRow.id) {
          itemTotal += Number(item.quantity);
        }
      });
      const row = {...selectedRow};
      const {name} = selectedRow.productCatalogItem.productType;
      row.productFullName = this.buildProductName(name, selectedRow.productCatalogItem.styleName,
          selectedRow.productCatalogItem.color);
      row.productType = name;
      row.quantity =
          Math.round(
              Math.max(
                  selectedRow.quantityOrdered -
                  selectedRow.quantityReceived -
                  itemTotal,
                  0
              ) * 10000
          ) / 10000;
      row.rollNumber = null;
      row.dyeLot = null;
      row.binId = null;
      row.binName = null;
      row.roundedBoxQuantity = selectedRow.productCatalogItem.boxQuantity
          ? BoxQuantity.roundValueToBoxQuantity(row.quantity, selectedRow.productCatalogItem.boxQuantity)
          : null;
      return row;
    },
    handlePOItemClicked(selectedRow) {
      this.clearError();
      const row = this.createRowData(selectedRow);
      this.selectedItems.push(row);
    },
    onDeleteRow(index) {
      this.clearError();
      this.selectedItems.splice(index, 1);
    },
    onReduceQuantity(item, index) {
      this.clearError();
      const newItem = {...item};
      let quantity = parseInt(newItem.quantity || 0);
      if (quantity > 0) {
        quantity -= 1;
      }
      this.selectedItems[index].quantity = Number(quantity);
      this.selectedItems[index].roundedBoxQuantity = this.setBoxQuantity(this.selectedItems[index], index);
    },
    onIncreaseQuantity(item, index) {
      this.clearError();
      const newItem = {...item};
      let quantity = parseInt(newItem.quantity || 0);
      this.selectedItems[index].quantity = Number((quantity += 1));
      this.selectedItems[index].roundedBoxQuantity = this.setBoxQuantity(this.selectedItems[index], index);
    },
    setBoxQuantity(selectedItem, index) {
      return selectedItem.roundedBoxQuantity = this.selectedItems[index].productCatalogItem.boxQuantity
          ? BoxQuantity.roundValueToBoxQuantity(this.selectedItems[index].quantity, this.selectedItems[index].productCatalogItem.boxQuantity)
          : null;
    },
    clearError() {
      this.submitError = null;
    },
    validateIfHasBinAssigned(items) {
      let pass = true;
      items.forEach(item => {
        if (!item.binId) {
          const {productFullName} = item;
          this.submitError = `Must assign a bin for product ${productFullName}`;
          pass = false;
        }
      });
      return pass;
    },
    handleValidateLineItems() {
      this.clearError();
      let pass = true;
      const items = [...this.selectedItems];
      if (!this.hasQuantityEntered(items)) {
        pass = false;
      } else if (!this.hasRollNumAndDyeLot(items)) {
        pass = false;
      } else {
        const totals = this.getTotalsForAllItems(items);
        if (!this.validateIfHasBinAssigned(items)) {
          pass = false;
        } else if (!this.validateQuantityTotals(totals)) {
          pass = false;
        }
      }
      return pass;
    },
    createLineItemParams() {
      const items = [...this.selectedItems];
      const lineItems = [];
      for (let i = 0; i < items.length; i += 1) {
        let lineItem = {
          binId: items[i].binId,
          purchaseOrderItemId: items[i].id,
          quantity: items[i].roundedBoxQuantity
              ? items[i].roundedBoxQuantity
              : items[i].quantity,
          dyeLot: items[i].dyeLot,
          rollNumber: items[i].rollNumber,
        };
        lineItems.push(lineItem);
      }
      return lineItems;
    },
    validateQuantityTotals(totals) {
      let pass = true;
      let isOverreceived = false;
      let overreceivedItems = '';
      for (let key in totals) {
        pass = true;
        let {
          quantityOrdered,
          quantityReceived,
          productFullName,
          total,
          totalRounded,
        } = totals[key];


        let difference = Math.round((quantityOrdered - quantityReceived) * 10000) / 10000;
        // let difference = totalRounded ? Math.round((quantityOrdered - totalRounded) * 10000) / 10000: Math.round((quantityOrdered - quantityReceived) * 10000) / 10000;

        if(totalRounded){
          if (totalRounded > difference) {
            pass = false;
          }
        } else {
          if (total > difference) {
            pass = false;
          }
        }


        if (!pass) {
          isOverreceived = true
          let selected = this.selectedItems.find(item => item.id == key);
          let diff = totalRounded ?
              this.getDifferenceForLineItem(totalRounded, quantityOrdered, quantityReceived, selected.roundedBoxQuantity) :
              this.getDifferenceForLineItem(total, quantityOrdered, quantityReceived, selected.roundedBoxQuantity);
          overreceivedItems = overreceivedItems.concat(productFullName + ' - '
              + Math.abs(diff).toFixed(4) + ' overreceived' + "\n");
        }
      }
      if (isOverreceived) {
        this.$refs.overreceivingModal.openDialog();
        this.productsOverreceived = overreceivedItems;
        pass = false;
      }
      return pass;
    },
    getDifferenceForLineItem(total, quantityOrdered, quantityReceived, boxQuantity) {
      let diff = 0;
      if (!boxQuantity && !quantityReceived) {
        diff = total - quantityOrdered;
      } else if (!boxQuantity && quantityReceived) {
        diff = total - (quantityOrdered - quantityReceived);
      } else if (boxQuantity && !quantityReceived) {
        diff = total - quantityOrdered;
      } else if (boxQuantity && quantityReceived) {
        diff = boxQuantity - (quantityOrdered - quantityReceived);
      }
      return diff;
    },
    // for items that are checked into multiple bins
    getTotalsForAllItems(itemsArray) {
      const totals = {};
      itemsArray.forEach((item) => {
        let id = item.id;
        if (!totals[id]) {
          totals[id] = {};
          totals[id].total = 0;
          totals[id].totalRounded = 0;
          totals[id].quantityOrdered = item.quantityOrdered;
          totals[id].quantityReceived = item.quantityReceived;
          totals[id].roundedBoxQuantity = item.roundedBoxQuantity;
          totals[id].productFullName = item.productFullName;
        }
        totals[id].total = Number(totals[id].total) + Number(item.quantity);
        totals[id].totalRounded = Number(totals[id].totalRounded) + Number(item.roundedBoxQuantity);
      });
      return totals;
    },
    hasQuantityEntered(items) {
      let pass = true;
      items.forEach(item => {
        if (!this.isNumericQuantityValue(item.quantity)) {
          this.submitError = `Cannot have 0 quantity for product ${item.productFullName}`;
          pass = false;
        }
        if (item.quantity < 0) {
          this.submitError = `Quantity can't be negative for ${item.productFullName}.`;
          pass = false;
        }
      });
      return pass;
    },
    hasRollNumAndDyeLot(items) {
      let pass = true;
      items.forEach(item => {
        let productType = this.productTypes.find(productType => productType.id === item.productTypeId);
        if (productType.isRolled && !item.rollNumber) {
          this.submitError = `Must include roll number for product ${item.productFullName}`;
          pass = false;
        }

        if (productType.hasDyeLot && !item.dyeLot) {
          this.submitError = `Must include dye lot for product ${item.productFullName}`;
          pass = false;
        }
      });
      return pass;
    },
    isNumericQuantityValue(quantity) {
      return !(!quantity || Number(quantity) === 0);
    },
    async handleCheckInInventory() {
      this.inProgress = true;
      this.clearError();
      const isValidated = this.handleValidateLineItems();
      if (isValidated) {
        await this.proceedCheckInRequest();
      }
      this.inProgress = false;
    },
    async adjustInventory(lineItems) {
      try {
        await InventoryItems.getRestApi().inventoryItemsCheckIn(lineItems);
      } catch (error) {
        throw error;
      } finally {
        this.inProgress = false;
      }
    },
    async proceedCheckInRequest() {
      try {
        const lineItems = this.createLineItemParams();
        await this.adjustInventory(lineItems);
        await this.handleSuccessCheckInInventory();
      } catch (error) {
        this.handleFailCheckInInventory(error);
      }
    },
    handleFailCheckInInventory(error) {
      this.$refs.nav.showError(error);
    },
    async handleSuccessCheckInInventory() {
      try {
        this.selectedItems = [];
        this.$refs.nav.showSuccess("Checked In Inventory Successfully!");
        // refreshes just the inventory table so on hand reflects the saved, correct amount
        await this.handleSearchPurchaseOrderNumber();
      } catch (error) {
        throw error;
      }
    },
    buildProductName(productType, styleName, color) {
      return productType + (styleName ? ` ${styleName} ` : "") + (color ? ` ${color}` : "");
    },
    selectedItemChange(updatedItem, index) {
      this.selectedItems[index].quantity = updatedItem.quantity;
      this.selectedItems[index].roundedBoxQuantity = this.setBoxQuantity(this.selectedItems[index], index);
    },
    overreceivingApproved() {
      if (!this.submitError) {
        this.proceedCheckInRequest();
      }
    }
  },
};
</script>

<style scoped>
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}
.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
}
.checkInButton {
  width: 100px;
}
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
