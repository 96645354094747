import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export interface FiftyFloorState {
  loggedInUser: any;
  loginError: string | null;
  toolsEnabled?: boolean;
  gridStore: any;
  printData?: any;
  isJobSaving? : boolean;
  refreshJobEventType? : string | null;
  unreadMessageCounter?: number;
  savedNotifications?: [] | null;
  ongoingNotifications?: [] | null;
  deviceId?: string | null;
}

const store: StoreOptions<FiftyFloorState> = {
  state: {
    gridStore: {},
    toolsEnabled: false,
    loggedInUser: null,
    loginError: null,
    isJobSaving: false,
    refreshJobEventType: null,
    unreadMessageCounter: 0,
    savedNotifications: [],
    ongoingNotifications: [],
    deviceId: null,
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedInUser = user;
      state.loginError = null;
    },
    logout(state, type) {
      state.loggedInUser = null;
      state.loginError = type;
    },
    inactiveLogout(state, type) {
      console.log(state, type);
      state.loggedInUser = null;
      state.loginError = type;
    },
    toggleToolbar(state) {
      state.toolsEnabled = !state.toolsEnabled;
    },
    setGridStore(state, grid) {
      state.gridStore[grid.title] = grid.store;
    },
    setPrintData(state, data) {
      state.printData = data;
    },
    setDefaultPageSize(state, gridProperty) {
      Object.entries(gridProperty).forEach(property => {
        if (state.gridStore[property[0]].allowedPageSizes[0]) {
          state.gridStore[property[0]].pageSize = state.gridStore[property[0]].allowedPageSizes[0];
        } else
          state.gridStore[property[0]].pageSize = 10;
      })
    },
    setIsJobSaving(state, isJobSaving){
      state.isJobSaving = isJobSaving;
    },
    setRefreshJobEventType(state, refreshJobEventType) {
      state.refreshJobEventType = refreshJobEventType;
    },
    setUnreadMessageCounter(state, unreadMessageCounter) {
      state.unreadMessageCounter = unreadMessageCounter;
    },
    setSavedNotifications(state, savedNotifications) {
      state.savedNotifications = savedNotifications;
    },
    setOngoingNotifications(state, ongoingNotifications) {
      state.ongoingNotifications = ongoingNotifications;
    },
    setDeviceId(state, deviceId) {
      state.deviceId = deviceId;
    },
  },
  actions: {},
  plugins: [vuexLocal.plugin],
  getters: {
    toolsEnabled: state => {
      return state.toolsEnabled;
    },
    gridStore: state => {
      return state.gridStore;
    },
    loggedInUser: state => {
      return state.loggedInUser;
    },
    getPrintData: state => {
      return state.printData;
    },
    getIsJobSaving: state => {
      return state.isJobSaving;
    },
    getRefreshJobEventType: state => {
      return state.refreshJobEventType;
    },
    getUnreadMessageCounter: state => {
      return state.unreadMessageCounter;
    },
    getSavedNotifications: state => {
      return state.savedNotifications;
    },
    getOngoingNotifications: state => {
      return state.ongoingNotifications;
    },
    getDeviceId: state => {
      return state.deviceId;
    },
  }
};

export default new Vuex.Store<FiftyFloorState>(store);
