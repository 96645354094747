<template>
  <v-row>
    <v-col md="12">
      <v-card flat>
        <v-card-text
            style="text-align: center;"
            class="blue-grey--text text--lighten-1 title mt-0 pt-0 mb-4"
        >
          Send System Broadcast Message
        </v-card-text
        >
      </v-card>
    </v-col>
    <v-col md="12">
      <v-text-field
          :rules="[rules.required]"
          label="* Message Title"
          v-model="title"
          placeholder=" "
          id="broadcast-msg-title"
          class="mb-3"
      />
    </v-col>
    <v-col md="12">
      <v-textarea
          :rows="3"
          auto-grow
          name="message"
          placeholder=" "
          persistent-placeholder
          label="* Message Content"
          v-model="message"
          id="broadcast-msg-message"
          class="mb-3"
      />
    </v-col>
    <v-col md="12">
      <v-select
          v-model="roles"
          :items="roleChoices"
          item-text="name"
          item-value="value"
          label="* Target Roles"
          class="mb-3"
          attach
      />
    </v-col>
    <v-col md="12">
      <v-select
          v-model="severity"
          :items="severityChoices"
          item-text="name"
          item-value="value"
          label="* Severity"
          attach
      />
    </v-col>
  </v-row>
</template>

<script>
const formDefaults = {
  title: "Urgent Notification",
  message: "",
  roles: -1,
  severity: "info"
};

export default {
  components: {},

  props: {},

  data() {
    return Object.assign(
        {
          rules: {
            required: value => {
              if (typeof value === "number") {
                return value >= 0 || "Required";
              }
              return !!value || "Required";
            }
          },
          roleChoices: [
            {name: "All Users", value: -1},
            {name: "Administrators", value: 1},
            {name: "Sales Representatives", value: 2},
            {name: "Regular Users", value: 3},
            {name: "Branch Users", value: 4}
          ],
          severityChoices: [
            {name: "Informational", value: "info"},
            {name: "Critical", value: "Critical"}
          ]
        },
        formDefaults
    );
  },

  methods: {
    getFormContent() {
      return {
        title: this.title,
        message: this.message,
        roles: this.roles,
        severity: this.severity
      };
    }
  }
};
</script>
