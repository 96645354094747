<template>
  <table class="headers">
    <tr>
      <td width="105">Reference Id</td>
      <td width="270">Style</td>
      <td width="110">Color</td>
      <td width="70">Quantity</td>
    </tr>
  </table>
</template>

<script>
export default {
  components: {},

  props: {},

  data() {
    return {};
  }
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
</style>
