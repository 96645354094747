<template>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Inventory To Transfer
          </v-expansion-panel-header>
          <v-expansion-panel-content key="transfer">
            <inventory-to-transfer-grid
                ref="transfer"
                :transferRequestStatus="transferRequestStatus"
                :data-source="transferRequestItems"
                @onTransferRequestItemClose="transferRequestItemClose"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                parentType="transferRequest"
                :transferRequestNumber="transferNumber"
                :documentTypesCategory=Constants.documentTypesCategories.TRANSFER_REQUEST_AND_SHIPMENT
                @filesGridError="showError"
            />
          </v-tab-item>

          <v-tab key="notes">Activity Logs</v-tab>
          <v-tab-item key="activityLogs">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InventoryToTransferGrid from "../../components/inventoryItems/transferRequest/InventoryToTransferGrid.vue";
import TransferRequests from "../../rest/transferRequests";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import Constants from "../../assets/constants";
import FilesGrid from "../../components/common/inlineGrids/files/FilesGrid.vue";

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    FilesGrid,
    ActivityLogsGrid,
    InventoryToTransferGrid,
  },

  props: {
    transferNumber: {
      type: String,
      required: true
    },
    transferRequestStatus: {
      type: String,
      required: true
    }
  },

  created() {
    this.display();
  },

  data() {
    return {
      activityLogs: [],
      transferRequestItems: [],
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    display() {
      try {
        this.getActivityLogs();
        this.getInventoryToTransferItems();
      } catch (error) {
        this.showError(error);
      }
    },
    async refreshActivityLog() {
      if (this.$refs.activityLog) {
        await this.getActivityLogs();
      }
    },
    refreshInventoryToTransferItemsGrid() {
      if (this.$refs.transfer) {
        this.getInventoryToTransferItems();
      }
    },
    showError(error) {
      this.$emit('showError', error);
    },
    async getActivityLogs() {
      try {
        const response = await TransferRequests.getRestApi().getTransferRequestsActivityLogs(this.transferNumber);
        this.activityLogs = response.data;
      } catch (error) {
        throw error;
      }
    },
    transferRequestItemClose(selectedItem) {
      this.$emit('itemClose', selectedItem);
    },
    async getInventoryToTransferItems() {
      try {
        const response = await TransferRequests.getRestApi().getInventoryToTransferItems(this.transferNumber);
        this.transferRequestItems = response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>

<style scoped>
</style>
