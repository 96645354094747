<template>
  <navigation-view-sales
    ref="nav"
    :page-title="pageTitle"
    :nav-section="navSection"
  >
    <div
      id="grid-toolbar"
      slot="toolbar"
      v-show="toolsEnabled"
    >
      <grid-toolbar
        @onToggleResetGridDialog="toggleResetGridDialog"
        @onShowColumnChooser="showColumnChooser"
      />
    </div>

    <v-card slot="content" class="ma-3">
      <dx-data-grid
        ref="dataGrid"
        :showColumnLines="true"
        :showRowLines="false"
        :show-borders="true"
        :data-source="dataSource"
        :hover-state-enabled="true"
        :load-panel="loadPanelConfig"
        :columnMinWidth="columnMinWidth"
        :columnAutoWidth="columnAutoWidth"
        :allowColumnResizing="allowColumnResizing"
        :columnResizingMode="columnResizingMode"
        :allowColumnReordering="true"
        :onContentReady="onGridReady"
        :onCellClick="onCellClick"
        :columnChooser="gridColumnChooser"
        :onToolbarPreparing="hideNativeGridToolbar"
      >
        <reset-grid-dialog
          :visible="resetGridDialog"
          @onCancelClicked="toggleResetGridDialog"
          @onSubmitClicked="clearGridState"
        />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="allowFiltering" />
        <dx-column-fixing :enabled="true" />
        <dx-state-storing
          :enabled="gridStore.enabled"
          :type="gridStore.type"
          :savingTimeout="gridStore.savingTimeout"
          :customLoad="onGridLoad"
          :customSave="onGridSave"
        />
        <dx-scrolling :useNative="true" showScrollbarMode="Always" />
        <dx-paging :page-size="10" />
        <dx-pager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          infoText="Page {0} of {1} - {2} items"
        />

        <slot />
        <div slot="hasNoteTemplate" slot-scope="cellData">
          <has-note-attached :cell-data="cellData" />
        </div>
      </dx-data-grid>
    </v-card>
    <!-- show toolbar action button -->
    <div class="nav-no-action" slot="nav-action">
      <v-btn
        dark
        icon
        small
        class="col-sel-btn ma-0 pa-0"
        color="primary"
        id="toolbar-menu-button"
        @click="toggleToolsEnabled"
        @mouseenter="ifToolbarTool = true"
        @mouseleave="ifToolbarTool = false"
        v-show="toolsEnabled && !overrideToolsEnabled"
      >
        <v-icon v-if="toolsEnabled">close</v-icon>
        <v-icon v-else>more_vert</v-icon>
        <tool-tip
          v-if="toolsEnabled"
          :ifShow="ifToolbarTool"
          tipText="Close Tools Bar"
        />
        <tool-tip v-else :ifShow="ifToolbarTool" tipText="Open Tools Bar" />
      </v-btn>
    </div>
  </navigation-view-sales>
</template>

<script>
import { mapGetters } from "vuex";
import {
  DxDataGrid,
  DxColumn,
  DxColumnFixing,
  DxFilterRow,
  DxHeaderFilter,
  DxScrolling,
  DxPager,
  DxPaging,
  DxSelection,
  DxStateStoring
} from "devextreme-vue/ui/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import NavigationViewSales from "@/views/navigation/NavigationViewSales.vue";
import HasNoteAttached from "../../../components/common/grid/cellTemplates/HasNoteAttached";
import ResetGridDialog from "@/components/common/grid/ResetGridDialog.vue";
import RoleRestrictor from "@/rest/odata";
import ToolTip from "@/components/common/dialogContainers/ToolTip.vue";
import GridToolbar from "@/components/common/grid/GridToolbar.vue";
import FilterInitializer from "@/components/common/grid/FilterInitializer";

export default {
  components: {
    NavigationViewSales,
    HasNoteAttached,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    DxScrolling,
    DxPager,
    DxPaging,
    DxSelection,
    DxStateStoring,
    ResetGridDialog,
    ToolTip,
    GridToolbar
  },

  props: {
    pageTitle: String,
    navSection: String,
    dataSource: Object,
    columnMinWidth: Number,
    columnAutoWidth: Boolean,
    navAction: Boolean,
    allowColumnResizing: Boolean,
    columnResizingMode: String,
    branchUserRestricted: Boolean,
    totalCountColumn: String,
    allowFiltering: {
      type: Boolean,
      default: true
    },
    overrideToolsEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return Object.assign(
      {},
      {
        bulkAssign: false,
        pageSizes: [10, 20, 50],
        resetGridDialog: false,
        loadPanelConfig: {
          enabled: true,
          message: "Loading...",
          showPane: false
        },
        gridStore: {
          enabled: true,
          type: "custom",
          savingTimeout: 500
        },
        gridColumnChooser: {
          enabled: true,
          mode: "select",
          height: 500
        },
        selection: {
          selectAllMode: "page",
          showCheckBoxesMode: "always"
        },
        visibleColumns: [],
        columnsWithFilters: [],
        ifRevertTool: false,
        ifColumnTool: false,
        ifToolbarTool: false,
      }
    );
  },

  mounted() {
    FilterInitializer.initializeCustomFilterOperations(this.getGridInstance());
  },

  watch: {
    visibleColumns: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.handleFilteredColumns(newVal);
        }
      }
    }
  },

  computed: {
    ...mapGetters(["toolsEnabled"])
  },

  methods: {
    refreshGrid: function() {
      const gridInstance = this.getGridInstance();
      gridInstance.refresh();
    },
    watchGridComponents: function() {
      const grid = this.getGridInstance();
      const visibleColumns = grid.getVisibleColumns();
      this.$data.visibleColumns = [...visibleColumns];
    },
    onGridLoad: function() {
      if (this.pageTitle) {
        const gridStore = this.$store.getters.gridStore;
        if (gridStore && this.pageTitle in gridStore) {
          return gridStore[this.pageTitle];
        }
      }
    },
    onGridSave: function(state) {
      if (this.pageTitle) {
        this.$store.commit("setGridStore", {
          title: this.pageTitle,
          store: state
        });
      }
    },
    onGridReady: function() {
      this.watchGridComponents();
      this.clearSelection();
    },
    toggleToolsEnabled: function() {
      this.$data.ifToolbarTool = !this.$data.ifToolbarTool;
      this.$store.commit("toggleToolbar");
    },
    getGridInstance() {
      return this.$refs.dataGrid.instance;
    },
    onCellClick: function(e) {
      if (e.data) {
        this.$emit("rowSelected", e.data);
      }
    },
    onLinkClicked: function(cellData) {
      this.$emit("linkClicked", cellData);
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    showSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    showColumnChooser: function() {
      const gridInstance = this.getGridInstance();
      gridInstance.showColumnChooser();
    },
    clearGridState: function() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      RoleRestrictor.applyRoleRestrictions(
        this.$store.state.loggedInUser,
        gridInstance,
        this.branchUserRestricted
      );
      gridInstance.clearSorting();
      this.clearSelection();
      this.resetSelectedFilterOperations();
      if (this.$data.resetGridDialog) {
        this.toggleResetGridDialog();
      }
    },
    clearSelection: function() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    toggleResetGridDialog: function() {
      this.$data.resetGridDialog = !this.$data.resetGridDialog;
    },
    hideNativeGridToolbar: function(e) {
      // hide the native grid toolbar element
      // adding the column choser and export button automatically display the native grid toolbar
      // hidden columns do not persist when the column chooser's "enabled" attribute is set to "false"
      e.toolbarOptions.visible = false;
    },
    resetSelectedFilterOperations: function() {
      const gridInstance = this.getGridInstance();
      let columnCount = gridInstance.columnCount();
      for (var i = 0; i < columnCount; i++) {
        gridInstance.columnOption(i, "selectedFilterOperation", null);
      }
    },
    handleFilteredColumns: function(newVal) {
      const newColumnsWithFilters = this.getFilteredColumns(newVal);
      this.clearPastFilteredColumnBackgrounds();
      this.setFilteredColumnElementsBackground(newColumnsWithFilters);
      this.$data.columnsWithFilters = newColumnsWithFilters;
    },
    setFilteredColumnElementsBackground: function(columns) {
      for (let i = 0; columns.length > i; i += 1) {
        const elements = document.querySelectorAll(
          `[aria-colindex="${columns[i]}"]`
        );
        elements.forEach(function(value, index) {
          if (value.className.includes("dx-datagrid-action")) {
            elements[index].style.backgroundColor = "#DEEFF5";
          }
        });
      }
    },
    getFilteredColumns: function(columns) {
      let filteredColumns = [];
      for (var key in columns) {
        for (var columnKey in columns[key]) {
          if (columnKey === "filterValue" || columnKey === "filterValues") {
            if (columns[key].filterValue || columns[key].filterValues) {
              let column = Number(key) + 1;
              filteredColumns.push(column);
            }
          }
        }
      }
      return filteredColumns;
    },
    clearPastFilteredColumnBackgrounds: function() {
      let columns = this.$data.columnsWithFilters;
      if (columns.length) {
        for (let i = 0; columns.length > i; i += 1) {
          const elements = document.querySelectorAll(
            `[aria-colindex="${columns[i]}"]`
          );

          elements.forEach(function(value, index) {
            elements[index].style.backgroundColor = "";
          });
        }
      }
    }
  }
};
</script>

<style scoped>
.nav-action {
  display: flex;
  height: 100%;
}
.nav-no-action {
  background-color: rgb(245, 248, 250);
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
.nav-button {
  font-size: 18px;
  min-width: 0;
  flex-grow: 7;
}
.col-sel-btn {
  flex-grow: 1;
  max-width: 30px;
  height: 100%;
  border-radius: 0;
  border-right: 1px solid white !important;
}
.dx-datagrid-header-panel {
  position: absolute !important;
  visibility: hidden !important;
}
.toolbar {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>

<style>
/* stylize toolbar icons */
.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  color: rgba(0, 0, 0, 0.54);
}
/* style checkbox column */
.dx-command-select {
  padding: 0 !important;
}
.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
  background-color: #6699bb;
}
button#toolbar-menu-button {
  background-color: #6699bb;
  /*right: 12px;*/
  color: #ffffff !important;
}
</style>
