<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="notes">Notes</v-tab>
          <v-tab-item key="notes">
            <notes-grid
                ref="notesGrid"
                :lead-reference-id="leadReferenceId"
                :notes="notes"
                @saveNote="onSaveNote"
                @getNotes="onGetNotes"
            />
          </v-tab-item>

          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                :lead-reference-id="leadReferenceId"
                parent-type="lead"
                @filesGridError="emitError"
                :documentTypesCategory=Constants.documentTypesCategories.LEAD
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FilesGrid from "../../../components/common/inlineGrids/files/FilesGrid.vue";
import NotesGrid from "../../../components/common/inlineGrids/NotesGrid.vue";
import Notes from "../../../rest/notes";
import Constants from "../../../assets/constants";

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    FilesGrid,
    NotesGrid
  },

  props: {
    leadReferenceId: {
      type: String,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    customerReferenceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      notes: []
    };
  },

  methods: {
    emitError(error) {
      this.$emit('showError', error);
    },
    emitSuccess(message) {
      this.$emit('showSuccess', message);
    },
    async getLeadNotesData() {
      try {
        const response = await Notes.getRestApi().getAllRelatedNotesByLeadReferenceId(this.leadReferenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async saveLeadNote(note) {
      try {
        await Notes.getRestApi().createLeadNote(this.leadReferenceId, note);
      } catch (error) {
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.emitSuccess("Lead note saved successfully!");
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    async onSaveNote(note) {
      try {
        await this.saveLeadNote(note);
        await this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async onGetNotes() {
      try {
        this.notes = await this.getLeadNotesData();
      } catch (error) {
        this.emitError(error);
      }
    }
  }
};
</script>
