<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="4" md="12">
          <v-card flat class="text-sm-left subheading">
            <span>Are we paying a third party (e.g. Cleaner, Plumber, etc.)?</span>
          </v-card>
          <v-radio-group v-model="payingThirdParty">
            <v-radio
                v-for="option in selectOptions"
                :key="option.key"
                :label="option.label"
                :value="option.value"
                color="primary"
            />
          </v-radio-group>
          <v-select
            style="width: 45%;"
            class="mt-3 mb-3"
            v-show="payingThirdParty"
            :items="thirdPartyVendors"
            item-text="name"
            item-value="vendorId"
            v-model="thirdPartyVendorId"
            label="* Third Party Vendor"
            placeholder=" "
            persistent-placeholder
            :rules="[rules.required]"
          />
          <v-text-field
            style="width: 25%;"
            class="mt-3 mb-3"
            v-show="payingThirdParty"
            v-model="thirdPartyAmount"
            label="* Amount for Third Party Vendor"
            placeholder="0.00"
            prefix="$"
            :rules="[rules.isPositiveAndGreaterThanZero]"
            type="number"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vendors from "@/rest/vendors";
import Rules from "@/assets/rules";
import Constants from "@/assets/constants";

export default {
  components: {},

  props: {
    job: Object,
  },

  data() {
    return Object.assign(
      {},
      {
        error: null,
        payingThirdParty: false,
        thirdPartyVendorId: null,
        thirdPartyVendor: "",
        thirdPartyAmount: 0,
        thirdPartyVendors: [],
        selectOptions: Constants.defaultSelectOptionsWithKey,
        rules: {
          required: (value) => {
            if (!value && this.payingThirdParty) {
              return "Required"
            } else return true;
          },
          isPositiveAndGreaterThanZero: value => {
            if (this.payingThirdParty) {
              return Rules.isPositiveAndGreaterThanZero(value);
            } else return true;
          }
        },
      }
    );
  },

  watch: {
    payingThirdParty(newVal) {
      if (!newVal) {
        this.thirdPartyVendorId = null;
        this.thirdPartyAmount = 0;
      }
    },
    thirdPartyVendorId(newVal) {
      if (newVal) {
        this.thirdPartyVendors.forEach((vendor) => {
          if (newVal === vendor.vendorId) {
            this.thirdPartyVendor = vendor.name;
          }
        });
      }
    },
  },

  created() {
    this.loadThirdPartyVendors();
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {
      this.loadThirdPartyVendors();
      this.payingThirdParty = false;
      this.thirdPartyVendorId = null;
      this.thirdPartyVendor = "";
      this.thirdPartyAmount = 0;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    async loadThirdPartyVendors() {
      try {
        let response = await Vendors.getRestApi().getThirdPartyVendors();
        this.thirdPartyVendors = response.data;
      } catch (err) {
        console.log(err);
      }
    },

    getPayload() {
      const payload = {};
      payload.payingThirdParty = this.payingThirdParty;
      payload.thirdPartyVendorId = this.thirdPartyVendorId;
      payload.thirdPartyVendor = this.thirdPartyVendor;
      payload.thirdPartyAmount = this.thirdPartyAmount;
      return payload;
    },

    loadForm(payload) {},
  },
};
</script>
