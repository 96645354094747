<template>
  <grid-view
      ref="nav"
      page-title="Jobs"
      nav-section="jobs"
      :data-source="dataSource"
      :allowFiltering="false"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      :overrideToolsEnabled="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        caption="Job Reference ID"
        data-field="JobReferenceId"
        data-type="string"
    />
    <dx-column
        caption="Job Status"
        data-field="StatusWithCategory"
        data-type="string"
    />
    <dx-column
        caption="Customer Name"
        data-field="CustomerName"
        data-type="string"
    />
    <dx-column
        caption="Customer Address"
        data-field="CustomerAddress"
        data-type="string"
    />
    <dx-column
        caption="Rooms"
        data-field="RoomTypes"
        data-type="string"
    />
    <dx-column
        caption="Installations"
        data-field="InstallationCapabilities"
        data-type="string"
    />
  </grid-view>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import RoleRestrictor from "@/rest/odata";
import GridView from "@/views/salesRepresentativesHome/common/MobileAppGridView.vue";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    DxColumn,
    GridView
  },

  data() {
    const config = {
      jobDialogVisible: false,
      dataSource: {
        store: ODataContext['SalesRepresentativeJobs'],
        select: [
          "JobReferenceId",
          "StatusWithCategory",
          "CustomerName",
          "CustomerAddress",
          "RoomTypes",
          "InstallationCapabilities",
          "SalesRepReferenceId",
          "SalesRepId",
          "JobStatusId",
          "JobParentStatusId"
        ]
      }
    };
    return RoleRestrictor.updateSalesRepConfig(this.$store.state.loggedInUser, config);
  },

  methods: {
    showError(error) {
      this.$refs.nav.showError(error);
    },
    onRowSelected(data) {
      this.$router.push(`/sales/jobs/details/${data.JobReferenceId}`);
    }
  }
};
</script>
