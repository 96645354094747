<template>
  <div>
    <v-card flat>
      <section-divider title="REFUNDS" />
      <headers />
      <panel
        :job="job"
        :line-item="item"
        v-for="item in refundItems"
        :key="item.referenceId"
        @itemDeleted="onItemDeleted"
      />
    </v-card>
  </div>
</template>

<script>
import Currency from "currency.js";
import Constants from "@/assets/constants.js";
import Headers from "./RefundLineItemsHeaders.vue";
import Panel from "./RefundLineItemsPanel.vue";
import SectionDivider from "@/components/jobs/SectionDivider.vue";
import CreateReferenceIds from "@/assets/referenceIds";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    initialItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return { refundItems: [] };
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.$data.refundItems = value;
      },
    },
  },

  methods: {
    // Add new refund line item.
    onAddItem: function (payload) {
      let item = {};
      let refid = CreateReferenceIds.getTempReferenceId();
      let jobLineItem = {
        referenceId: refid,
        type: Constants.jobType.refund,
        status: null,
        comment: "",
        quantity: 1,
        refundAmount: Currency(payload.refundAmount).value,
        balanceTakenAmount: Currency(payload.balanceTakenAmount).value,
        price: Currency(payload.refundAmount).value,
        newLineItem: true,
      };
      item.jobLineItem = jobLineItem;
      this.$data.refundItems.push(item);
      this.$emit("itemsUpdated", this.$data.refundItems);
      this.$emit("dataUpdated");
      this.$emit("amountsUpdated");
      return item;
    },
    onItemDeleted: function (referenceId) {
      this.$data.refundItems = this.$data.refundItems.filter(
        (current) => current.jobLineItem.referenceId !== referenceId
      );
      this.$emit("itemsUpdated", this.$data.refundItems);
      this.$emit("dataUpdated");
      this.$emit("amountsUpdated");
    },
  },
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
