<template>
  <navigation-view
      ref="nav"
      page-prefix="Oasis Global Configuration"
      page-title="Super Admin Tools"
      nav-section="admin"
  >
    <template v-slot:content>
      <v-app class="app-style">
        <left-menu-persistent-drawer
            :selectedGroup="selectedGroup"
            :groupedItems="groupedItems"
            :expandPanel="expandPanel"
            :getIcon="getIcon"
        />

        <v-container fluid class="mb-12">
          <v-row no-gutters class="ma-8">
            <v-col cols="12">
              <configuration-panels
                  :expanded.sync="expanded"
                  :groupedItems="groupedItems"
                  :isActionConfirmed="isActionConfirmed"
                  @showError="onSubmitFailed"
                  @showSuccess="onShowSnackbarSuccess"
                  @saveTiersClicked="onSaveTiersClicked"
                  @noModificationDetected="onNoModificationDetected"
                  @updateConfigurations="onUpdateConfigurations"
              />
            </v-col>
          </v-row>

          <generic-warning-dialog
              ref="warningDialog"
              title="Sales Rep Tiers Update Confirmation"
              :showDialog="showDialog"
              :message="confirmationMessage"
              :multiLineError="true"
              confirmationLabel="Confirm"
              cancelLabel="Cancel"
              @confirmed="onConfirmAction"
              @cancelled="onCancelAction"
          />
        </v-container>
      </v-app>

      <v-container fluid class="pa-0">
        <v-row no-gutters class="sticky-bottom">
          <v-col cols="12">
            <v-expansion-panels multiple accordion>
              <v-expansion-panel>
                <v-expansion-panel-header color="#69b">
                  Activity Logs
                </v-expansion-panel-header>
                <v-expansion-panel-content key="activityLog">
                  <v-progress-circular
                      class="mt-4 mb-2"
                      v-show="loading"
                      indeterminate
                      color="primary"
                  />
                  <activity-logs-grid-pageable
                      v-show="!loading"
                      ref="activityLog"
                      :customPagingSize="[5,10]"
                      :fetchLogsFunction="GlobalConfigurations.getRestApi().getActivityLogs"
                      @onGetLogsFinished="getLogsFinished"
                      @onGetLogsFailed="errorOccurred"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import ConfigurationPanels from "@/views/system/globalConfiguration/ConfigurationPanels.vue";
import LeftMenuPersistentDrawer from "@/views/system/globalConfiguration/LeftMenuPersistentDrawer.vue";
import GlobalConfigurations from "@/rest/globalConfigurations";
import ActivityLogsGridPageable from "@/components/common/inlineGrids/ActivityLogsGridPageable.vue";

const SALES_REPS_TIERS_NAME = 'Sales Representative Tiers (1)'; // do not forget that (1) should math the real number of items

export default {
  components: {
    ActivityLogsGridPageable,
    GenericWarningDialog,
    NavigationView,
    ConfigurationPanels,
    LeftMenuPersistentDrawer
  },

  data() {
    return {
      showDialog: false,
      isActionConfirmed: false,
      confirmationMessage: null,
      showForm: true,
      expanded: [],
      globalConfigurations: [],
      selectedGroup: '',
      iconMapping: {
        'default': 'mdi-folder-outline',
        'Sales Representative Tiers (1)': 'mdi-home-percent-outline',
        'Session Management': 'mdi-timer-outline',
        'Security Settings': 'mdi-security',
        'Application Login': 'mdi-login'
      },
      loading: true
    };
  },

  created() {
    this.getAllGlobalConfigurations();
  },

  computed: {
    GlobalConfigurations() {
      return GlobalConfigurations
    },
    groupedItems() {
      return this.globalConfigurations.reduce((groups, item) => {
        const group = groups[item.groupName] || [];
        group.push(item);
        groups[item.groupName] = group;
        return groups;
      }, {});
    }
  },

  watch: {
    expanded(newVal) {
      if (newVal.length === 0) {
        this.selectedGroup = '';
      } else {
        this.selectedGroup = newVal.map(panelIndex => {
          if (panelIndex === 0) {
            return SALES_REPS_TIERS_NAME;
          } else {
            const groupNames = Object.keys(this.groupedItems);
            return groupNames[panelIndex - 1];
          }
        });
      }
    }
  },

  methods: {
    async onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onShowSnackbarSuccess(message) {
      this.$refs.nav.showSuccess(message);
      this.loadDefaults();
      this.$refs.activityLog.refreshGrid();
    },
    onSaveTiersClicked(payload) {
      this.showDialog = true;
      this.confirmationMessage = `Are you sure you want to change the % splitting between tiers?\n`;
      payload.forEach(tier => {
        this.confirmationMessage += `${tier.name}: ${tier.tierPercentage}% \n`;
      });
    },
    onConfirmAction() {
      this.isActionConfirmed = true;
    },
    onCancelAction() {
      this.showDialog = false;
      this.confirmationMessage = null;
    },
    loadDefaults() {
      this.showDialog = false;
      this.confirmationMessage = null;
      this.isActionConfirmed = false;
    },
    async getAllGlobalConfigurations() {
      try {
        let initialLength = this.globalConfigurations.length;
        const response = await GlobalConfigurations.getRestApi().getAllConfigurations();
        this.globalConfigurations = response.data;
        if (initialLength === 0 && this.globalConfigurations.length > 0) {
          this.setInitialExpandedPanel();
        }
      } catch (error) {
        this.onSubmitFailed(error);
      }
    },
    setInitialExpandedPanel() {
      if (this.globalConfigurations.length > 0) {
        this.expanded = [0];
        this.selectedGroup = SALES_REPS_TIERS_NAME;
      }
    },
    expandPanel(panelName) {
      const panelIndex = this.getPanelIndex(panelName);
      if (panelIndex !== -1) {
        if (this.expanded.includes(panelIndex)) {
          this.expanded = this.expanded.filter(index => index !== panelIndex);
        } else {
          this.expanded.push(panelIndex);
        }
      }
    },
    getIcon(groupName) {
      return this.iconMapping[groupName] || this.iconMapping.default;
    },
    onNoModificationDetected(message) {
      this.$refs.nav.showSuccess(message);
    },
    async onUpdateConfigurations(payload) {
      await this.updateGlobalConfigurations(payload);
      if (this.isActivityLogPanelExpanded()) {
        this.loading = true;
        this.$refs.activityLog.refreshGrid();
      }
    },
    async updateGlobalConfigurations(payload) {
      try {
        const requestPayload = this.buildRequest(payload);
        await GlobalConfigurations.getRestApi().updateGlobalConfigurations(requestPayload);
        this.$refs.nav.showSuccess('Global configurations updated successfully');
        this.getAllGlobalConfigurations();
      } catch (error) {
        this.onSubmitFailed(error);
      }
    },
    buildRequest(configurations) {
      return configurations.map(config => ({
        id: config.id,
        value: config.value
      }));
    },
    getPanelIndex(panelName) {
      return panelName === SALES_REPS_TIERS_NAME ? 0 : Object.keys(this.groupedItems).indexOf(panelName) + 1;
    },
    isActivityLogPanelExpanded() {
      return this.expanded.includes(1);
    },
    async getLogsFinished() {
      this.loading = false;
    },
    errorOccurred(error) {
      this.$refs.nav.showError(error);
    }
  }
};
</script>

<style scoped>
.app-style {
  background-color: #F6F9FA;
  padding-bottom: 200px;
}
.sticky-bottom {
  position: fixed;
  bottom: 0;
  z-index: 999;
}
</style>
