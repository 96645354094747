<template>
  <div style="padding-left: 10px">
    <v-row>
      <v-col md="12" class="ma-1">
        <v-divider/>
        <transfer-request-enter-quantity-item-headers/>
        <v-divider/>
      </v-col>

      <v-row class="line-items-wrapper ma-2 mb-6">
        <inventory-items
            class="ma-1"
            v-for="(item, index) in selectedItems"
            :ref="'ii'"
            :key="index"
            :index="index"
            :inventoryItem="item"
            :readonly="false"
            :step="3"
            @computeQuantity="onComputeQuantity"
            @enteredQtyIsMoreThanAvailable="onEnteredQtyIsMoreThanAvailable"
            @enteredValueIsFloatForNonBoxQtyItem="onEnteredValueIsFloatForNonBoxQtyItem"
            @valueCleared="onValueCleared"
        />
      </v-row>
    </v-row>
  </div>
</template>

<script>
import InventoryItems from "../InventoryItems.vue";
import FormattingHelper from "../../../assets/formattingHelper";
import TransferRequestOverviewItemHeaders from "../headers/TransferRequestOverviewItemHeaders.vue";
import TransferRequestEnterQuantityItemHeaders from "../headers/TransferRequestEnterQuantityItemHeaders.vue";

export default {
  components: {
    TransferRequestEnterQuantityItemHeaders,
    TransferRequestOverviewItemHeaders,
    InventoryItems
  },

  props: {
    selectedItems: {
      type: Array,
      required: true
    }
  },

  methods: {
    onComputeQuantity(computedQuantity, index) {
      this.$emit('computeQuantity', computedQuantity, index);
    },
    getItemsFormData() {
      let formData = []
      for (const refKey in this.$refs.ii) {
        const inventoryItem = this.$refs.ii[refKey];
        const quantity = inventoryItem.quantity;
        let combined = {...inventoryItem.inventoryItem, quantity}
        formData.push(FormattingHelper.convertFirstLetterToLowerCase(combined));
      }
      return formData;
    },
    onEnteredQtyIsMoreThanAvailable(value, quantityAvailable) {
      this.$emit('enteredQtyIsMoreThanAvailable', value, quantityAvailable);
    },
    onEnteredValueIsFloatForNonBoxQtyItem(value) {
      this.$emit('enteredValueIsFloatForNonBoxQtyItem', value);
    },
    clearRefs() {
      Object.keys(this.$refs).forEach((refKey, index) => {
        this.$refs[refKey][index].clearPreviouslyEnteredQuantity();
      });
    },
    onValueCleared() {
      this.$emit('quantityCleared');
    }
  }
};
</script>

<style scoped>

</style>
