<template>
  <div :title="active ? 'Delete' : disabledReason">
    <v-btn
        class="short ma-0 pa-0"
        :class="!active ? 'disabled' : ''"
        :disabled="!active"
        small
        icon
        @click.stop="onButtonClick"
    >
      <v-icon class="short" :color="active ? 'primary' : 'grey'">delete</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    cellData: Object,
    active: {
      type: Boolean,
      default: true
    },
    disabledReason: {
      type: String,
      default: "Cannot delete"
    }
  },

  data() {
    return {};
  },
  methods: {
    onButtonClick() {
      if (this.active) {
        this.$emit("deleteClicked", this.cellData);
      }
    }
  }
};
</script>

<style scoped>
.short {
  height: 10px;
}
.disabled {
  cursor: default;
}
</style>
