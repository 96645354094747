<!-- TODO remove this component after migration to Vue 3. Duplication of JobDetails.vue -->
<template>
  <navigation-view ref="nav" page-title="Subjob Details" nav-section="sales">
    <template v-slot:content>
      <v-container grid-list-lg fluid v-if="isJobLoaded">
        <job-builder-panel
            ref="jobBuilderPanel"
            :job-id="jobId"
            :nextAction="nextAction"
            :hasOpenNextActions="hasOpenNextActions"
            :wsSessionId="wsSessionId"
            @setCustomer="setCustomer"
            @setLead="onSetLead"
            @remainingBalance="onRemainingBalance"
            @changesDetected="onChangesDetected"
            @itemsSaved="onLineItemsSaved"
            @itemsDirty="onLineItemsDirty"
            @jobFulfilled="onJobFulfilled"
            @jobInvoiced="onJobInvoiced"
            @refreshInlineGrid="onRefreshInlineGrid"
            @jobBuilderPanelError="showSnackbarError"
            @showError="showSnackbarError"
            @open="onOpenJob"
            @productItemsUpdated="onProductItemsUpdated"
            @laborItemsUpdated="onLaborItemsUpdated"
            @openNextActionTab="openNextActionTab"
            @onReturnFromJobSuccess="showSnackbarSuccess"
            @sendMessageThroughWebSocket="onSendMessageThroughWebSocket"
        />

        <inline-grids
            ref="inlineGrids"
            :jobId="jobId"
            :job="job"
            :productItems="productItems"
            :laborItems="laborItems"
            :lead="lead"
            :customerId="customerId"
            :jobReferenceId="jobReferenceId"
            :remainingBalance="remainingBalance"
            :installationsReadOnly="installationsReadOnly"
            :wsSessionId="wsSessionId"
            @refreshPage="refreshAll"
            @showError="showSnackbarError"
            @installationAdded="onInstallationAdded"
            @installationDeleted="onInstallationDeleted"
            @submitDiscount="onSubmitDiscount"
            @submitChargeback="onSubmitChargeback"
            @submitInstallationIssue="onSubmitInstallationIssue"
            @submitRefund="onSubmitRefund"
            @nextActionToShow="nextActionToShow"
            @refreshContractPanel="refreshContractPanelOnActionStatusUpdate"
            :customer="customer"
            :customerReferenceId="customerReferenceId"
        />
        <job-dirty-dialog
            ref="dirtyDialog"
            :jobId="jobReferenceId"
            @leaveJob="onLeaveJob"
            @continueJob="onContinueJob"
        />
        <job-update-conflict-dialog ref="conflictDialog" @refresh="refreshAll" />

      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import Jobs from "../../rest/jobs.ts";
import NavigationView from "../../views/navigation/NavigationView.vue";
import JobBuilderPanel from "../../components/jobs/JobBuilderPanel.vue";
import InlineGrids from "../../views/jobs/JobsInlineGrids.vue";
import JobDirtyDialog from "../../components/jobs/JobDirtyDialog.vue";
import NextActions from "../../rest/nextActions";
import DateFormatter from "../../assets/dateFormatter";
import Constants from "../../assets/constants";
import JobUpdateConflictDialog from "../../components/jobs/JobUpdateConflictDialog.vue";
import WebSockets from "../../websocket/webSockets";

export default {
  components: {
    NavigationView,
    JobBuilderPanel,
    InlineGrids,
    JobDirtyDialog,
    JobUpdateConflictDialog,
  },

  data() {
    return {
      jobReferenceId: null,
      jobId: 0,
      job: {},
      productItems: [],
      laborItems: [],
      customer: null,
      customerId: null,
      customerReferenceId: null,
      customerName: null,
      lead: null,
      leadId: null,
      branchId: null,
      isJobLoaded: false,
      remainingBalance: null,
      installationsReadOnly: false,
      isDirty: false,
      nextActions: [],
      stompJsClient: null,
      isConflictModalEnabled: null,
      nextAction: null,
      wsSessionId: null
    };
  },

  created() {
    this.jobReferenceId = this.$route.params.jobReferenceId;
    this.wsSessionId = WebSockets.generateWebSocketSessionId();
    this.isConflictModalEnabled = true;
  },

  beforeRouteUpdate(to, from, next) {
    this.jobReferenceId = to.params.jobReferenceId;
    next();
  },

  computed: {
    hasOpenNextActions() {
      return !!this.nextAction;
    }
  },

  watch: {
    jobReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.isJobDirty()) {
      this.$refs.dirtyDialog.openDialog(next);
    } else {
      next();
    }
  },

  methods: {
    refreshInlineGrids() {
      this.$refs.inlineGrids.refreshInlineGrids();
      this.getSubjobNextActions();
    },
    async refresh() {
      if (this.jobReferenceId) {
        try {
          const data = await this.getJobData();
          this.setData(data);
          this.isJobLoaded = true;
          if (!this.stompJsClient) {
            this.connectWebSocket();
          }
          window.scrollTo(0, 0);
        } catch (error) {
          this.showSnackbarError(error);
        }
      }
    },
    getJobsUpdateTopicName() {
      return WebSockets.getWebSocketJobsTopic() + "/" + this.job.referenceId + "/updated";
    },
    getJobsCalculationTopicName() {
      return WebSockets.getWebSocketJobsTopic() + "/" + this.job.referenceId + "/calculate/" + this.wsSessionId;
    },
    async passiveRefresh() {
      if (this.jobReferenceId) {
        try {
          const data = await this.getJobData();
          this.setData(data);
        } catch (error) {
          console.log(error);
        }
      }
    },
    onSendMessageThroughWebSocket(message) {
      let publishParams = {
        destination: `/app/jobs/cost/calculate/${this.job.referenceId}/${this.wsSessionId}`,
        body: JSON.stringify(message),
      }
      this.stompJsClient.publish(publishParams);
    },
    connectWebSocket() {
      this.stompJsClient = WebSockets.createStompJsClient(this.onConnectFunction);
    },
    closeWebSocket() {
      this.stompJsClient.deactivate();
    },
    onConnectFunction() {
      const topic = this.getJobsUpdateTopicName();
      const calculationTopic = this.getJobsCalculationTopicName();

      this.stompJsClient.subscribe(topic, (response) => {
        const message = JSON.parse(response.body);
        const eventType = message.eventType;
        // If true, then the update is made by the other user, so we need to show the conflict modal
        const isUpdateFromDifferentSession = message.originalWsSessionId !== this.wsSessionId;

        if (isUpdateFromDifferentSession && this.$refs.conflictDialog) {
          this.$refs.conflictDialog.openDialog();
        }
        if (eventType) {
          this.$store.commit("setRefreshJobEventType", eventType);
        }
        this.$store.commit("setIsJobSaving", false);
      });
      this.stompJsClient.subscribe(calculationTopic, (response) => {
        let recalculatedJobCostAndRemainingBalance = JSON.parse(response.body);
        this.$refs.jobBuilderPanel.showUpdatedJobCostAndRemainingBalance(recalculatedJobCostAndRemainingBalance);
      });
    },
    onRemainingBalance(price) {
      this.remainingBalance = price;
    },
    async getJobData() {
      const response = await Jobs.getRestApi().getJobById(this.jobReferenceId);
      return response.data;
    },
    setData(data) {
      this.job = data;
      this.jobId = data.id;
      this.leadId = data.leadId;
      this.customerId = data.customerId;
    },
    onInstallationAdded() {
      this.$refs.jobBuilderPanel.refresh();
    },
    onInstallationDeleted() {
      this.$refs.jobBuilderPanel.refresh();
    },
    onLineItemsSaved() {
      this.showSnackbarSuccess("Job Updated");
      this.refreshInlineGrids();
      this.isDirty = false;
    },
    onLineItemsDirty() {
      this.isDirty = true;
    },
    onChangesDetected(value) {
      this.installationsReadOnly = value;
    },
    showSnackbarError(error) {
      this.$refs.nav.showError(error);
    },
    showSnackbarSuccess(message) {
      this.$refs.nav.showSuccess(message);
      this.refreshPage();
      this.$refs.jobBuilderPanel.refresh();
    },
    onJobFulfilled() {
      this.isDirty = false;
      this.$refs.inlineGrids.onJobFulfilled();
      this.passiveRefresh();
    },
    onJobInvoiced() {
      this.isDirty = false;
      this.$refs.inlineGrids.onJobInvoiced();
      this.passiveRefresh();
    },
    onRefreshInlineGrid() {
      this.refreshInlineGrids();
    },
    refreshPage() {
      this.refresh();
      this.refreshInlineGrids();
    },
    refreshAll() {
      this.refresh();
      this.refreshInlineGrids();
      this.$refs.jobBuilderPanel.refresh();
    },
    onSubmitDiscount() {
      this.showSnackbarSuccess("Discount added");
      this.refreshPage();
      this.$refs.jobBuilderPanel.refresh();
    },
    onSubmitChargeback(payload) {
      this.$refs.jobBuilderPanel.addChargeback(payload);
    },
    onSubmitInstallationIssue(payload) {
      this.$refs.jobBuilderPanel.addInstallationIssue(payload);
    },
    onSubmitRefund(payload) {
      this.$refs.jobBuilderPanel.addRefund(payload);
    },
    onLeaveJob(next) {
      next();
    },
    onContinueJob() {
    },
    isJobDirty() {
      return this.isDirty;
    },
    onOpenJob(referenceId) {
      this.$router.push(`/jobs/details/${referenceId}`);
    },
    onProductItemsUpdated(items) {
      this.productItems = items;
    },
    onLaborItemsUpdated(items) {
      this.laborItems = items;
    },
    getSubjobNextActions() {
      this.getJobNextActions();
    },
    async getJobNextActions() {
      try {
        const response = await NextActions.getRestApi().getNextActionsByJobId(this.job.id);
        this.nextAction = this.findNearestNextActionDateSubjob(response.data);
      } catch (error) {
        this.emitError(error);
      }
    },
    findNearestNextActionDateSubjob(actions) {
      let nextDates = actions.filter(action => {
        if (action.status === Constants.nextActionStatusOpen) return true;
      }).sort((a, b) => a.dueDate.localeCompare(b.dueDate));
      let nextDate = this.getNearestFutureNextActionDate(nextDates);
      if (nextDate == null) {
        nextDate = nextDates.shift();
      }
      return nextDate ? DateFormatter.formatDate(nextDate.dueDate) : null;
    },
    nextActionToShow(allActions) {
      if (allActions.length > 0) {
        this.nextAction = this.findNearestNextActionDateSubjob(allActions);
      }
    },
    getNearestFutureNextActionDate(nextDates) {
      let today = new Date().setHours(0, 0, 0, 0);
      return nextDates.find(action => Date.parse(action.dueDate) >= today);
    },
    refreshContractPanelOnActionStatusUpdate() {
      this.nextActionToShow(this.nextActions);
    },
    setCustomer(customer) {
      this.customer = customer;
    },
    openNextActionTab() {
      this.$refs.inlineGrids.nextActionsTabSelected();
    },
    onSetLead(lead) {
      this.lead = lead;
    }
  }
};
</script>
