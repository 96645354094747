<template>
  <modal-dialog
      ref="dialog"
      title="Are you sure you want to delete the slot?"
      width="500"
      :visible="dialogVisible"
      submitLabel="Delete"
      cancelLabel="Cancel"
      @submitClicked="onDeleteClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
      :loading="loading"
  >
    <v-container>
      <div>Slot will be deleted for all the crew capabilities of the crew <b>{{ this.cellData.key.installerCrewName }}</b>.</div>
    </v-container>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import InstallerSlots from "../../../rest/installer/installerSlots";

export default {
  components: {
    ModalDialog
  },

  data() {
    return {
      cellData: null,
      loading: false,
      dialogVisible: false
    }
  },

  methods: {
    openDialog(cellData) {
      this.cellData = cellData;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.loading = false;
      this.dialogVisible = false
    },
    onCancelClicked() {
      this.closeDialog();
    },
    async onDeleteClicked() {
      this.loading = true;
      InstallerSlots.getRestApi().deleteSlot(this.cellData.data.id)
          .then(() => {
            this.$emit('deleteConfirmed');
            this.closeDialog();
          })
          .catch(error => {
            this.loading = false;
            this.emitError(error.response.data.message);
            this.closeDialog();
          });
    },

    emitError(error) {
      this.$emit('emitError', error);
    },

  }
};
</script>
<style>
</style>
