<template>
  <v-container>
    <h4 class="ma-4"> Please check your email and enter the received code to proceed with the MFA setup. </h4>
    <v-row class="ma-auto position-relative">
      <v-col cols="9" class="mt-1">
        <v-text-field
            v-model="token"
            datatype="string"
            placeholder="Enter Security Token"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="3" class="ma-0 mt-0">
        <v-btn
            id="verify-token-button"
            class="full-width white--text"
            color="#59b"
            :loading="loading"
            :disabled="!token"
            @click="verifyTokenClicked(token)"
        >
          <span class="verify-text">Verify</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="ma-3"/>

    <go-back-button
        :showGoBackButton="showGoBackButton"
        :disabled="isGoBackButtonDisabled"
        @goBackClicked="onGoBackClicked"
    />

  </v-container>
</template>

<script>
import Authentication from "@/rest/authentication";
import GoBackButton from "@/components/common/buttons/GoBackFullWidthButton.vue";

export default {
  components: {GoBackButton},

  props: {
    hasOTPVerificationError: {
      type: Boolean,
      default: false
    },
    showGoBackButton: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      required: false
    }
  },

  computed:{
    isGoBackButtonDisabled() {
      return !!this.token;
    }
  },

  data() {
    return {
      loading: false,
      token: null,
    };
  },

  methods: {
    verifyTokenClicked(token) {
      if (!token) {
        return;
      }
      this.verifyToken(token);
    },
    async verifyToken(token) {
      this.loading = true;
      try {
        const securityTokenRequest = this.createTokenRequestObject(token, this.email);
        const response = await Authentication.getRestApi().verifySecurityToken(securityTokenRequest);
        if (response.data.userMfaSettings?.securityTokenVerified) {
          this.$emit('isTokenVerified', response.data);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        this.$emit('tokenVerificationError', error.response?.data?.message);
      } finally {
        this.loading = false;
      }
    },
    createTokenRequestObject(token, email) {
      return {
        email,
        token
      }
    },
    onGoBackClicked() {
      this.token = null;
      this.loading = false;
      this.$emit('backToCredentialsForm');
    },
    clear() {
      this.token = null;
    },
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.position-relative {
  position: relative;
}
</style>
