<template>
    <modal-dialog
            ref="dialog"
            title="Add Vendor & Branch Relation"
            width="600"
            :visible="dialogVisible"
            :submitEnabled="true"
            submitLabel="Add Relation"
            cancelLabel="Cancel"
            @submitClicked="onSubmitClicked"
            @cancelClicked="onCancelClicked"
    >
        <v-container style="height: 430px"> <!--check styling here-->
            <add-vendor-relation-form
                    ref="vendorBranchRelationForm"
            />
        </v-container>
        <p class="error-message">
            {{ error }}
        </p>
    </modal-dialog>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";
import AddVendorRelationForm from "../../views/vendors/AddVendorCostRelationForm.vue"
import VendorManagements from "../../rest/vendorManagement";

export default {
    data() {
        return {
            dialogVisible: false,
            error: null,
        }
    },

    components: {
        AddVendorRelationForm,
        ModalDialog,
    },

    props: {
        catalogItemId: {
            type: Number,
            required: true
        }
    },

    methods: {
        openDialog() {
            this.dialogVisible = true;
            this.clearError();
        },
        closeDialog() {
            this.dialogVisible = false;
        },
        showError(error) {
            this.error = error;
        },
        onSubmitClicked() {
            let valid = this.$refs.vendorBranchRelationForm.validate();
            if (valid) {
                const formData = this.$refs.vendorBranchRelationForm.getFormData();
                VendorManagements.getRestApi()
                    .saveVendorManagementData(this.catalogItemId, formData)
                    .then((response) => {
                        this.$emit('entryAdded', response.data);
                        this.$refs.vendorBranchRelationForm.resetFormData();
                        this.closeDialog();
                    })
                    .catch((error) => {
                        this.$refs.dialog.showError(error);
                    });
            }
        },
        clearError() {
            this.error = null;
        },
        onCancelClicked() {
            this.dialogVisible = false;
            this.clearError();
            this.$refs.vendorBranchRelationForm.resetFormData();
            this.$refs.vendorBranchRelationForm.resetValidation();
        },
    },
};
</script>
