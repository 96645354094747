<template>
  <v-container class="ma-2 pt-4">
    <v-row dense>
      <v-col md="12">
        <v-text-field
            v-show="!isCreateDrawer"
            v-model="internalSku"
            label="Internal SKU"
            disabled
            persistent-placeholder
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col md="6">
        <v-select
            v-model="isAvailableInSalesApp"
            :items="availableInSalesRepAppOptions"
            label="* Available In Sales App"
            placeholder=" "
            persistent-placeholder
            :disabled="isAvailableInSalesRepApp"
            attach
        />
      </v-col>
      <v-col md="6">
        <v-select
            v-model="status"
            :items="statusChoices"
            label="Item Status"
            placeholder=" "
            :disabled="multipleCreate"
            attach
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="12">
        <v-text-field
            v-model="manufacturer"
            label="Manufacturer"
            placeholder=" "
            persistent-placeholder
            :disabled="multipleCreate"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="6">
        <v-select
            v-model="bulkInventoryItem"
            label="Bulk Item"
            placeholder=" "
            persistent-placeholder
            :items="bulkItemOptions"
            :disabled="multipleCreate"
            attach
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="boxQuantity"
            label="Box Quantity"
            placeholder=" "
            persistent-placeholder
            type="number"
            :disabled="multipleCreate"
        />
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col md="6">
        <product-types-search-field
            v-model="productTypeId"
            :disabled="multipleCreate"
            @inputChanged="inputChanged"
        />
      </v-col>
      <v-col md="6">
        <product-sub-types-search-field
            ref="subtypesDropdown"
            :productTypeId="productTypeId"
            :productSubTypeId="productSubtypeId"
            :disabled="disabled"
            @subtypeSelected="onSelectSubtype"
            @noSubTypes="disableSubTypesInput"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="6">
        <styles-search-field
            ref="stylesDropdown"
            v-model="styleName"
            :disabled="multipleCreate"
            label-name="* Manufacturer Style"
            :is-private-label="false"
            :required="true"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            ref="colorField"
            v-model="color"
            label="* Manufacturer Color"
            placeholder=" "
            persistent-placeholder
            maxlength="25"
            counter
            :rules="[rules.requiredField]"
        />
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col md="12">
        <v-switch
            color="primary"
            label="Private label style and color are the same as manufacturer's"
            v-model="privateLabelSameAsDefault"
        />
      </v-col>
    </v-row>
    <v-row class="mt-1" v-if="!privateLabelSameAsDefault">
      <v-col md="6">
        <styles-search-field
            ref="stylesDropdown"
            v-model="privateLabelStyleName"
            :disabled="multipleCreate"
            label-name="* Private Label Style"
            :is-private-label="true"
            :required="true"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            ref="colorField"
            v-model="privateLabelColor"
            label="* Private Label Color"
            placeholder=" "
            persistent-placeholder
            maxlength="25"
            counter
            :rules="[rules.requiredField]"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="6">
        <general-purpose-dropdown
            v-model="unitOfMeasure"
            :select-options="unitOfMeasureChoices"
            label="* Unit of Measure"
            placeholder=" "
            :disabled="multipleCreate"
            :required="true"
            @onOptionChange="onUnitOfMeasureChange"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            label="Conversion Multiplier"
            v-model="conversionMultiplier"
            placeholder=" "
            persistent-placeholder
            type="number"
            :rules="[rules.isNullOrPositiveAndGreaterThanZero]"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="6" v-show="isCreateDrawer">
        <v-text-field
            v-model="repPrice"
            label="* Rep Price"
            placeholder=" "
            persistent-placeholder
            prefix="$"
            :rules="[rules.required, rules.isCurrencyFormat]"
            :disabled="multipleCreate"
            type="number"
        />
      </v-col>
      <v-spacer/>
      <v-col md="6">
        <general-purpose-dropdown
            label="Product Class"
            placeholder=""
            :value="productClass"
            :required="false"
            :isClearable="true"
            :select-options="productClassOptions"
            item-text="name"
            item-value="id"
            @onOptionChange="changeProductClass"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="12" class="pt-2 bp-0">
        <v-textarea
            :rows="2"
            v-model="description"
            auto-grow
            label="Description"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Rules from "../../assets/rules.js";
import Constants from "../../assets/constants";
import ProductTypesSearchField from "../../components/searchFields/ProductTypes.vue";
import StylesSearchField from "../../components/catalogItems/formHelpers/StylesSearchField.vue";
import ProductSubTypesSearchField from "../../components/catalogItems/formHelpers/ProductSubTypesSearchField.vue";
import GeneralPurposeDropdown from "../common/form/GeneralPurposeDropdown.vue";
import BoxQuantity from "@/assets/boxQuantity";
import ProductCatalogItems from "@/rest/catalogItems";
import ErrorMessages from "@/assets/errorMessages";

const formDefaults = {
  boxQuantity: 0,
  color: null,
  description: null,
  internalSku: null,
  manufacturer: null,
  productTypeId: null,
  styleName: null,
  status: 1,
  unitOfMeasure: null,
  vendorSku: null,
  bulkInventoryItem: false,
  commercial: false,
  isAvailableInSalesApp: true,
  conversionMultiplier: null,
  productSubtypeId: null,
  repPrice: 0,
  privateLabelColor: null,
  privateLabelStyleName: null,
  privateLabelSameAsDefault: false,
  productClass: null
};

export default {
  components: {
    StylesSearchField,
    ProductTypesSearchField,
    ProductSubTypesSearchField,
    GeneralPurposeDropdown
  },

  props: {
    multipleCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: true,
    },
    subtype: {
      type: String | null,
      required: false,
      default: null
    },
    productClassOptions: Array
  },

  data() {
    return this.resetToDefaults();
  },

  computed: {
    isCreateDrawer() {
      return this.type === 'create';
    }
  },

  watch: {
    status(value) {
      if (value === 0) {
        this.isAvailableInSalesRepApp = true;
        this.isAvailableInSalesApp = false;
      } else this.isAvailableInSalesRepApp = false;
    },
    productTypeId(newValue) {
      if (!newValue) {
        this.disabled = true;
      }
      this.productTypeId = newValue;
    },
    subtype(value) {
      if (value) {
        this.productSubtypeId = value.id;
        this.disabled = false;
      }
    }
  },

  methods: {
    resetToDefaults() {
      return Object.assign(
        {},
        formDefaults,
        {
          rules: {
            isCurrencyFormat: Rules.isCurrencyFormat,
            required: this.type === 'create' ? Rules.required : true,
            requiredField: Rules.required,
            isNullOrPositiveAndGreaterThanZero: Rules.isNullOrPositiveAndGreaterThanZero
          },
        },
        {
          statusChoices: Constants.activeInactiveOptionsWithValue,
          availableInSalesRepAppOptions: Constants.yesNoDropdownOptions,
          isAvailableInSalesRepApp: false,
          unitOfMeasureChoices: Constants.unitOfMeasureChoices
        },
        {
          internalSku: null,
        },
        {
          bulkItemOptions: Constants.yesNoDropdownOptions,
          commercialProductDropdown: Constants.yesNoDropdownOptions,
          disabled: false
        }
      );
    },
    focusOnColorField() {
      if (this.$refs.colorField) {
        this.$refs.colorField.$el.focus();
      }
    },
    getVendorId() {
      return this.vendorId;
    },
    getVendorSku() {
      return this.vendorSku;
    },
    buildPayload() {
      let payload = {};
      const {boxQuantity} = this.$data;
      payload.repPrice = this.repPrice;
      payload.boxQuantity = BoxQuantity.formatBoxQuantity(boxQuantity);
      payload.color = this.color;
      payload.description = this.description;
      payload.internalSku = this.internalSku;
      payload.manufacturer = this.manufacturer;
      payload.productTypeId = this.productTypeId;
      payload.status = this.status;
      payload.styleName = this.styleName;
      payload.unitOfMeasure = this.unitOfMeasure;
      payload.bulkInventoryItem = this.bulkInventoryItem;
      payload.commercial = this.commercial;
      payload.isAvailableInSalesApp = this.isAvailableInSalesApp;
      payload.conversionMultiplier = this.conversionMultiplier;
      payload.productSubtypeId = this.productSubtypeId;
      payload.hasPrivateLabel = !this.privateLabelSameAsDefault;
      if (payload.hasPrivateLabel) {
        payload.privateLabelStyleName = this.privateLabelStyleName;
        payload.privateLabelColor = this.privateLabelColor;
      } else {
        payload.privateLabelStyleName = null;
        payload.privateLabelColor = null;
      }
      payload.productClass = this.productClass;
      return payload;
    },
    loadForm(payload) {
      this.repPrice = payload.repPrice;
      this.boxQuantity = payload.boxQuantity;
      this.color = payload.color;
      this.description = payload.description;
      this.internalSku = payload.internalSku;
      this.manufacturer = payload.manufacturer;
      this.productTypeId = payload.productTypeId;
      this.status = payload.status;
      this.styleName = payload.styleName;
      this.unitOfMeasure = payload.unitOfMeasure;
      this.vendorSku = payload.vendorSku;
      this.bulkInventoryItem = payload.bulkInventoryItem;
      this.commercial = payload.commercial;
      this.isAvailableInSalesApp = payload.isAvailableInSalesApp;
      this.conversionMultiplier = payload.conversionMultiplier;
      this.productSubtypeId = payload.productSubtypeId;
      this.privateLabelStyleName = payload.privateLabelStyleName;
      this.privateLabelColor = payload.privateLabelColor;
      if (payload.hasPrivateLabel !== undefined) {
        this.privateLabelSameAsDefault = !payload.hasPrivateLabel;
      } else {
        this.privateLabelSameAsDefault = payload.privateLabelSameAsDefault;
      }
      this.productClass = payload.productClass;
    },
    onSelectBranch(value) {
      this.branchIds = value;
    },
    onSelectSubtype(value) {
      this.productSubtypeId = value;
    },
    inputChanged(value) {
      this.productTypeId = value;
      this.disabled = false;
    },
    disableSubTypesInput() {
      this.productSubtypeId = null;
      this.disabled = true;
    },
    reloadStyles() {
      if (this.$refs.stylesDropdown) {
        this.$refs.stylesDropdown.getCatalogItemStyles();
      }
    },
    changeProductClass(value) {
      this.productClass = value;
    },
    onUnitOfMeasureChange(value) {
      this.unitOfMeasure = value;
    },
    async validateUniqueness(params) {
      let result = false;
      try {
        let response = await this.getUniquenessValidationResults(params);
        if (response.anotherProductClassItemsCount > 0 && response.anotherPrivateLabelStyleCount > 0) {
          this.$emit('emitWarning', ErrorMessages.updateAllOtherProductTypesAndStylesWarning);
        } else if (response.anotherProductClassItemsCount > 0) {
          this.$emit('emitWarning', ErrorMessages.updateProductClassAllOtherProductTypesAndStylesWarning);
        } else if (response.anotherPrivateLabelStyleCount > 0) {
          this.$emit('emitWarning', ErrorMessages.updatePrivateStyleAllOtherProductTypesAndStylesWarning);
        } else {
          result = true;
        }
      } catch (error) {
        this.$emit('emitFail', error);
      }
      return result;
    },
    async getUniquenessValidationResults(params) {
      try {
        let matches = await ProductCatalogItems.getRestApi().validateProductUniqueness(params);
        return matches.data;
      } catch (error) {
        throw error;
      }
    }
  },
};
</script>
