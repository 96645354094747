import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";
import {DefaultPageableParams} from "./interfaces/defaultPageableParams";

/**
 * Global Configuration.
 */
export interface GlobalConfigurationDTO {
    id: number;
    name: string;
    value: string;
    type: string;
    description: string;
    groupName: string;
    isModifiable: boolean;
}

export interface UpdateGlobalConfigurationsRequest {
    updates: { id: number, value: string }[];
}

/**
 * REST interface provided for Global Configuration
 */
export interface GlobalConfigurationsRestApi {
    getAllConfigurations(): AxiosPromise<GlobalConfigurationDTO[]>;

    updateGlobalConfigurations(request: UpdateGlobalConfigurationsRequest): AxiosPromise<void>;

    getActivityLogs(params: DefaultPageableParams): AxiosPromise<ActivityLog[]>;
}

/**
 * REST implementation provided for Global Configuration.
 */
class Implementation implements GlobalConfigurationsRestApi {
    getAllConfigurations(): AxiosPromise<GlobalConfigurationDTO[]> {
        return Axios.axiosInstance().get<GlobalConfigurationDTO[]>("/global-configuration");
    }

    updateGlobalConfigurations(request: UpdateGlobalConfigurationsRequest): AxiosPromise<void> {
        return Axios.axiosInstance().put<void>("/global-configuration/batch", request);
    }

    getActivityLogs(params: DefaultPageableParams): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>("/global-configuration/activity-logs/all", {params});
    }
}

const GlobalConfigurations = {
    getRestApi(): GlobalConfigurationsRestApi {
        return new Implementation();
    }
};

export default GlobalConfigurations;
