<template>
  <div>
    <v-text-field
        v-if="readonly"
        :readonly="readonly"
        placeholder=" "
        :value="textValue"
        label="Payment Types"
        data-id="payment-types-text-value"
    />
    <v-autocomplete
        light
        dense
        attach
        validate-on-blur
        hide-no-data
        v-if="!readonly"
        v-model="selected"
        placeholder="Select Payment Types"
        data-id="payment-types-autocomplete"
        :item-text="itemText || 'name'"
        item-value="id"
        :items="paymentTypes"
        :rules="[rules.requiredBasedOnPropsValue]"
        :label="label || 'Payment Types'"
        :required="required"
        :multiple="multiple"
        :clearable="multiple"
        :disabled="disabled"
    />
  </div>
</template>

<script>

import Rules from "../../assets/rules";

export default {
  components: {},

  data() {
    return {
      selected: [],
      rules: {
        requiredBasedOnPropsValue: (value) => Rules.requiredBasedOnPropsValue(value, this.required)
      }
    };
  },

  props: {
    value: String | Array,
    readonly: Boolean,
    required: Boolean,
    label: String,
    itemText: String,
    multiple: Boolean,
    disabled: Boolean,
    paymentTypes: Array
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      const paymentType = this.paymentTypes.find(x => x.id === value);
      this.$emit("inputSelected", value, paymentType);
    }
  },

  computed: {
    textValue() {
      return this.selected.map(item => item.name).join(", ");
    }
  },

  methods: {
    emitError(error) {
      this.$emit("emitError", error);
    },
    getSelected() {
      return this.selected;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
  width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
