const ItemsNamingHelper = {
    concatenateInventoryItemProperties(item) {
        const result = [];
        if (item.ProductColor) {
            result.push(item.ProductColor.substring(0, 15));
        }
        if (item.ProductStyle) {
            result.push(": " + item.ProductStyle.substring(0, 15));
        }
        if (item.RollNumber) {
            result.push(": " + item.RollNumber.substring(0, 15));
        }
        if (item.DyeLot) {
            result.push(": " + item.DyeLot.substring(0, 15));
        }
        return result.join(" ");
    },
    concatenateVendorAndStyleNames(item) {
        const result = [];
        if (item.VendorName) {
            result.push(item.VendorName.substring(0, 15));
        }
        if (item.StyleName) {
            result.push(": " + item.StyleName.substring(0, 15));
        }
        return result.join(" ");
    },
    buildItemDisplayName(item) {
        item.displayName = item.referenceId + " - " + item.productCatalogItem.styleName +
            " " + (item.productCatalogItem.color
                ? "(" + item.productCatalogItem.color + ")"
                : "");
        return item;
    },
    buildTransferRequestDisplayName(selected) {
        return " : " + selected.TransferFromBranch + " - " + selected.TransferToBranch + " "
            + (selected.JobReferenceId
                ? "(" + selected.JobReferenceId + ")"
                : "")
            + (selected.ClosestInstallationDate
                ? "(" + selected.ClosestInstallationDate + ")"
                : "");
    }
}

export default ItemsNamingHelper;
