import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {CatalogItem} from "@/rest/catalogItems";
import {SupplierCost} from "@/rest/supplierCosts";
import * as AxiosUtils from "./util/axiosUtils";
import {JobContent} from "./jobs";

/**
 * Job line item.
 */
export class JobLineItem {
  id: number;
  jobId: number;
  referenceId: string;
  type: string;
  product_catalog_item_id: number;
  installation_id: number;
  quantity: number;
  price: number;
  status: string;
  comment: string;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: number,
    jobId: number,
    referenceId: string,
    type: string,
    product_catalog_item_id: number,
    installation_id: number,
    quantity: number,
    price: number,
    status: string,
    comment: string,
    createdAt: string,
    updatedAt: string
  ) {
    this.id = id;
    this.jobId = jobId;
    this.referenceId = referenceId;
    this.type = type;
    this.product_catalog_item_id = product_catalog_item_id;
    this.installation_id = installation_id;
    this.quantity = quantity;
    this.price = price;
    this.status = status;
    this.comment = comment;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

/**
 * Job Product Line Item
 */
export class JobProductLineItem extends JobLineItem { }

/**
 * Job line item summary information.
 */
export class JobLineItemSummary {
  lineItem: JobLineItem;
  productCatalogItem: CatalogItem;
  supplierCost: SupplierCost;

  constructor(
    lineItem: JobLineItem,
    productCatalogItem: CatalogItem,
    supplierCost: SupplierCost
  ) {
    this.lineItem = lineItem;
    this.productCatalogItem = productCatalogItem;
    this.supplierCost = supplierCost;
  }
}

export class PaymentLineItem {
  referenceId: string;
  type: string;
  quantity: number;
  price: number;
  comment: string;
  paymentTypeId: string;
  customerPaidAmount: number;

  constructor(
      referenceId: string,
      type: string,
      quantity: number,
      price: number,
      comment: string,
      paymentTypeId: string,
      customerPaidAmount: number
  ) {
    this.referenceId = referenceId;
    this.type = type;
    this.quantity = quantity;
    this.price = price;
    this.comment = comment;
    this.paymentTypeId = paymentTypeId;
    this.customerPaidAmount = customerPaidAmount;
  }
}

export interface ReturnProductItemDTO {
  internalSku: string;
  returnType: ReturnType;
  quantity: number;
}

export interface ReturnFromJobDTO {
  returnProductItems: ReturnProductItemDTO[];
}

export enum ReturnType {
  FULL_RETURN = "FULL_RETURN",
  FULL_RESERVATION = "FULL_RESERVATION",
  PARTIAL_RETURN = "PARTIAL_RETURN",
  PARTIAL_RESERVATION = "PARTIAL_RESERVATION",
}

/**
 * REST interface provided for job line items.
 */
export interface JobLineItemRestApi {
  setJobLineItems(id: number, content: JobContent, wsSessionId: string): AxiosPromise<JobContent>;
  markHoldPaymentAsReceived(jobReferenceId: string, paymentReferenceId: string, wsSessionId: string): AxiosPromise<PaymentLineItem>;
  returnProductLineItems(jobReferenceId: string, returnProductItems: ReturnFromJobDTO, wsSessionId: string): void;
}

/**
 * REST implementation provided for job line items.
 */
class Implementation implements JobLineItemRestApi {
  setJobLineItems(id: number, content: JobContent, wsSessionId: string): AxiosPromise<JobContent> {
    const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
    return Axios.axiosInstance().post<JobContent>(`/jobs/${id}/lineitems`, content, config);
  }

  markHoldPaymentAsReceived(jobReferenceId: string, paymentReferenceId: string, wsSessionId: string): AxiosPromise<PaymentLineItem> {
    const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
    return Axios.axiosInstance().patch<PaymentLineItem>(`/jobs/${jobReferenceId}/payments/${paymentReferenceId}/received`, {}, config);
  }

  returnProductLineItems(jobReferenceId: string, returnProductItems: ReturnFromJobDTO, wsSessionId: string): AxiosPromise<void> {
    const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
    return Axios.axiosInstance().post<void>(`/jobitems/${jobReferenceId}/products/return`, returnProductItems, config);
  }
}

const JobLineItems = {
  getRestApi(): JobLineItemRestApi {
    return new Implementation();
  }
};

export default JobLineItems;
