<template>
  <v-autocomplete
      v-model="selected"
      :items="vendors"
      :loading="loading"
      hide-no-data
      hide-selected
      item-text="name"
      item-value="id"
      :label=label
      :readonly="readonly"
      :placeholder="placeholder"
      persistent-placeholder
      :rules="[() => !!selected || 'Required']"
      :disabled="disabled"
      required
      dense
      attach
      return-object
  />
</template>

<script>

export default {

  components: {},

  data() {
    return {
      selected: null,
      loading: false
    };
  },

  props: {
    vendors: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: '* Vendor'
    },
    value: {
      type: String | null,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search vendors'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(newValue, oldValue) {
      let isVendorChanged = oldValue && newValue !== oldValue;
      this.$emit('inputSelected', newValue, isVendorChanged);
    },
    vendors(value) {
    }
  },

  created() {
    // let component = this;
    // Vendors.getRestApi()
    //   .getActiveVendors()
    //   .then((response) => {
    //     component.vendors = response.data;
    //   });
  },

  methods: {
    reset() {
      this.selected = null;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
    width: 50%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
