<template>
  <v-container grid-list-lg fluid id="inventory-in-transfer-details">
    <v-row id="first-row">
      <v-col cols="4">
        <v-text-field
            :value="details.transferFromBranch"
            label="Transfer From (Branch)"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-from-branch"
        />
      </v-col>
      <v-col cols="2">
        <text-field-with-copy-to-clip-board
            :value="details.internalSku"
            :readonly="true"
            label="Internal SKU"
            id="inventory-in-transfer-details-internal-sku"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.vendorSku"
            label="Vendor SKU"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-vendor-sku"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.productType"
            label="Product Type"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-product-type"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.style"
            label="Product Style"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-product-style"
        />
      </v-col>
    </v-row>

    <v-row id="second-row">
      <v-col cols="4">
        <v-text-field
            :value="details.transferToBranch"
            label="Transfer To (Branch)"
            placeholder=" "
            persistent-placeholder
            readonly
            id="transfer-request-details-transfer-to-branch"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.vendorCost"
            label="Vendor Cost"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-vendor-cost"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.totalValue"
            label="Total Value"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-total-value"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.rollNumber"
            label="Roll Number"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-roll-number"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.dyeLot"
            label="Dye Lot"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-dye-lot"
        />
      </v-col>
    </v-row>

    <v-row id="third-row">
      <v-col cols="4">
        <v-text-field
            :value="formatDate(details.estimatedDeliveryDate)"
            label="Estimated Delivery Date"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-estimated-delivery-date"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.quantityInTransfer"
            label="Quantity In Transfer"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-quantity-in-transfer"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.vendorName"
            label="Vendor Name"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-vendor-name"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.unitOfMeasure"
            label="Unit of Measure"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-unit-of-measure"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            :value="details.boxQuantity"
            label="Box Quantity"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-box-quantity"
        />
      </v-col>
    </v-row>

    <v-row id="fourth-row">
      <v-col cols="2">
        <read-only-text-field-with-hover-and-link-cell-template
            id="inventory-in-transfer-details-transfer-number"
            label="Transfer Request Number"
            readonly
            placeholder=" "
            persistent-placeholder
            :show="true"
            :value="details.transferRequestNumber"
            :link="transferRequestLink"
        />
      </v-col>
      <v-col cols="2">
        <read-only-text-field-with-hover-and-link-cell-template
            id="inventory-in-transfer-details-shipment-number"
            label="Shipment Number"
            readonly
            placeholder=" "
            persistent-placeholder
            :show="true"
            :value="details.shipmentNumber"
            :link="shipmentLink"
        />
      </v-col>

      <v-col/>
      <v-col cols="2">
        <v-text-field
            :value="formatDate(this.details.agingStartDateTime)"
            label="Aging Start Time"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-aging"
        />
      </v-col>

      <v-col cols="2">
        <v-text-field
            :value="details.color"
            label="Color"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-in-transfer-details-color"
        />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

import InventoryItemsInTransfer from "../../rest/inventoryItemsInTransfer";
import DateFormatter from "@/assets/dateFormatter";
import ReadOnlyTextFieldWithHoverAndLinkCellTemplate
  from "@/components/common/grid/cellTemplates/ReadOnlyTextFieldWithHoverAndLinkCellTemplate.vue";
import TextFieldWithCopyToClipBoard from "@/components/common/templates/TextFieldWithCopyToClipBoard.vue";

export default {
  components: {
    ReadOnlyTextFieldWithHoverAndLinkCellTemplate,
    TextFieldWithCopyToClipBoard
  },

  props: {
    transferRequestItemId: [String, Number]
  },

  created() {
    this.getDetails();
  },

  computed: {
    transferRequestLink() {
      return `/inventory/transfer-requests/${this.details.transferRequestNumber}`;
    },
    shipmentLink() {
      return `/inventory/shipment/${this.details.shipmentNumber}`;
    }
  },

  data() {
    return {
      details: null
    }
  },

  methods: {
    getDetails() {
      this.getInventoryInTransferDetails(this.transferRequestItemId);
    },
    async getInventoryInTransferDetails(inventoryInTransferId) {
      try {
        const response = await InventoryItemsInTransfer.getRestApi().getDetails(inventoryInTransferId);
        if (response.data.inventoryItemId) {
          this.$emit('inventoryItemIdReceived', response.data.inventoryItemId);
        }
        this.details = Object.assign({}, response.data);
      } catch (error) {
        this.showError(error);
      }
    },
    showError(error) {
      this.$emit('showError', error);
    },
    formatDate(date) {
      return DateFormatter.formatDateInMMDDYYYYFormat(date);
    }
  },
};
</script>
