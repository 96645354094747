<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td width="25%" class="text-truncate">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <td width="25%" class="pt-0"></td>
        <td width="25%" class="pt-0">
          <v-text-field :disabled="true" v-model="refundAmount" class="short-input" prefix="$" />
        </td>
        <td width="25%" class="pt-0">
          <v-text-field
            :disabled="true"
            v-model="balanceTakenAmount"
            class="short-input"
            prefix="$"
          />
        </td>
        <td width="30px;">
          <v-btn
            small
            dark
            icon
            class="short ma-0 pa-0"
            v-show="!lineItem.jobLineItem.posted"
            @click="onDeleteItem(lineItem.jobLineItem.referenceId)"
          >
            <v-icon class="short" color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import {isGenerated} from "@/assets/referenceIds";
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

export default {
  components: {},

  props: {
    job: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      referenceId: null,
      refundAmount: 0,
      balanceTakenAmount: 0
    };
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.referenceId = updated.jobLineItem.referenceId;
        this.refundAmount = CurrencyFormatter.formatCurrency(updated.jobLineItem.refundAmount);
        this.balanceTakenAmount = CurrencyFormatter.formatCurrency(updated.jobLineItem.refundAmount);
      },
    },
  },

  methods: {
    onDeleteItem: function (referenceId) {
      this.$emit("itemDeleted", referenceId);
    },
    isGenerated
  },
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}
.item-row td.top-aligned {
  vertical-align: top;
}
</style>
