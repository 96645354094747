<template>
  <modal-dialog
      title="Next Action"
      width="40%"
      :hideSubmit="true"
      :visible="dialogVisible"
      @cancelClicked="onCancelClicked"
  >
    <v-container grid-list-lg>
      <v-row class="mt-1">
        <v-col md="6">
          <v-text-field v-model="createdAt" label="Created At" readonly/>
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="creator"
              label="User"
              placeholder=" "
              readonly
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="formattedNextActionDate"
              label="Next Action Date"
              placeholder=" "
              readonly
              data-type="date"
              format="MM/dd/yyyy"
          />
        </v-col>
        <v-col md="6">
          <v-text-field v-model="actionStatus" rows="1" label="Status" readonly/>
        </v-col>
        <v-col md="12">
          <v-textarea v-model="nextActionNote" rows="1" auto-grow label="Note" readonly/>
        </v-col>
      </v-row>
    </v-container>
  </modal-dialog>
</template>

<script>
import moment from "moment";
import ModalDialog from "@/components/common/ModalDialog.vue";

const formDefaults = {
  nextActionNote: null,
  nextActionDate: null,
  createdAt: null,
  creator: null,
  dialogVisible: false,
  actionStatus: null
};
export default {
  components: {ModalDialog},

  data() {
    return Object.assign({}, formDefaults);
  },

  computed: {
    formattedNextActionDate() {
      return this.nextActionDate ? moment(this.nextActionDate).format('MM/DD/YYYY') : ' ';
    }
  },
  methods: {
    openDialog(data) {
      this.dialogVisible = true;
      this.nextActionNote = data.selectedRowsData[0].nextActionNote;
      this.nextActionDate = data.selectedRowsData[0].dueDate;
      this.createdAt = moment(data.selectedRowsData[0].createdAt).format("MM/DD/YYYY");
      this.creator = data.selectedRowsData[0].creator;
      this.actionStatus = data.selectedRowsData[0].status;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.closeDialog();
    }
  }
};
</script>
