<template>
  <v-select
      v-model="selected"
      :items="stopOptions"
      :readonly="readonly"
      :multiple="false"
      id="installer-crew-stop-select-field"
      item-text="stopText"
      item-value="stopValue"
      required
      dense
  />
</template>
<script>

export default {
  components: {},

  data() {
    return {
      selected: null,
    };
  },

  props: {
    value: {
      type: Number,
      required: false,
      nullable: true
    },
    readonly: {
      type: Boolean,
      required: false
    },
    stopOptions: {
      type: Array,
      required: false
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.setSelected(val);
      }
    },
    selected(value) {
      this.$emit("input", value);
    }
  },

  methods: {
    getSelected() {
      return this.selected;
    },
    setSelected(value) {
      this.selected = value;
    },
    clearSelected() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>

</style>
