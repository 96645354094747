<template>
    <v-row>
      <v-col md="6">
        <v-text-field
            v-model="internalSku"
            label="Internal Sku"
            readonly
            id="inventory-item-details-form-internal-sku"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="vendorName"
            label="Vendor Name"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="vendorSku"
            label="Vendor SKU"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-item-details-form-vendor-sku"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="boxQuantity"
            label="Box Quantity"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-item-details-form-box-quantity"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="productStyle"
            label="Product Style"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-item-details-form-product-fode"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="productType"
            label="Product Type"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-item-details-form-product-type"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="color"
            label="Color"
            readonly
            placeholder=" "
            persistent-placeholder
            id="inventory-item-details-form-color"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="unitOfMeasure"
            label="Unit Of Measure"
            placeholder=" "
            persistent-placeholder
            readonly
            id="inventory-item-details-form-unit-of-measure"
        />
      </v-col>
    </v-row>
</template>

<script>
import FormattingHelper from "../../assets/formattingHelper";

const formDefaults = {
  vendorName: "",
  vendorSku: "",
  productStyle: "",
  productType: "",
  internalSku: "",
  unitOfMeasure: "",
  status: "",
  color: "",
  boxQuantity: "",
  byBox: "",
  branch: "",
  branchItems: []
};

export default {
  components: {},

  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    loadForm(payload, inventoryItem) {
      this.vendorName = (payload.isMigrated && inventoryItem.vendorManagement) ?
          inventoryItem.vendorManagement?.vendor.name
          : inventoryItem.productCatalogItem.vendor.name;
      this.vendorSku = (payload.isMigrated && inventoryItem.vendorManagement)
          ? inventoryItem.vendorManagement?.vendorSku
          : payload.vendorSku;
      this.productType = payload.productType.name;
      this.productStyle = payload.styleName;
      this.internalSku = payload.internalSku;
      this.color = payload.color;
      this.boxQuantity = FormattingHelper.quantityFormat(payload);
      this.unitOfMeasure = payload.unitOfMeasure || " ";
    }
  }
};
</script>
