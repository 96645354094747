import {
    BranchDetails, MfaIcons,
    ShipmentStatuses,
    TransferRequestItemStatuses,
    TransferRequestStatuses
} from "./statusIcons/statusIcons";

const propertyNames = {
    MFA_ENABLED: 'mfaEnabled',
    MFA_SET_UP: 'mfaSetUp'
}

const IconDefineHelper = {
    defineIconByStatusName(templateName, statusName) {
        if (templateName === 'shipmentStatusIcon') {
            return this.defineShipmentIconOption(statusName);
        } else if (templateName === 'transferRequestItemStatusIcon') {
            return this.defineTransferItemIconOption(statusName);
        }
        if (templateName === 'branchDetails') {
            return this.defineBranchDetailsIconOption(statusName);
        } else {
            return this.defineTransferRequestStatusOption(statusName);
        }
    },
    defineTransferRequestStatusOption(status) {
        switch (status) {
            case TransferRequestStatuses.NEW.name:
            case TransferRequestStatuses.NEW.fullName:
                return TransferRequestStatuses.NEW;
            case TransferRequestStatuses.DELIVERY_SCHEDULED.name:
            case TransferRequestStatuses.DELIVERY_SCHEDULED.fullName:
                return TransferRequestStatuses.DELIVERY_SCHEDULED;
            case TransferRequestStatuses.CHECKED_OUT.name:
            case TransferRequestStatuses.CHECKED_OUT.fullName:
                return TransferRequestStatuses.CHECKED_OUT;
            case TransferRequestStatuses.IN_TRANSIT.name:
            case TransferRequestStatuses.IN_TRANSIT.fullName:
                return TransferRequestStatuses.IN_TRANSIT;
            case TransferRequestStatuses.PARTIALLY_DELIVERED.name:
            case TransferRequestStatuses.PARTIALLY_DELIVERED.fullName:
                return TransferRequestStatuses.PARTIALLY_DELIVERED;
            case TransferRequestStatuses.CLOSED.name:
            case TransferRequestStatuses.CLOSED.fullName:
                return TransferRequestStatuses.CLOSED;
            default:
                return null;
        }
    },
    defineTransferItemIconOption(status) {
        switch (status) {
            case TransferRequestItemStatuses.CLOSED.name:
            case TransferRequestItemStatuses.CLOSED.fullName:
                return TransferRequestItemStatuses.CLOSED;
            case TransferRequestItemStatuses.OPEN.name:
            case TransferRequestItemStatuses.OPEN.fullName:
                return TransferRequestItemStatuses.OPEN;
            default:
                return null;
        }
    },
    defineBranchDetailsIconOption(status) {
        switch (status) {
            case BranchDetails.AUTOMATIC.name:
                return BranchDetails.AUTOMATIC;
            case BranchDetails.MANUAL.name:
                return BranchDetails.MANUAL;
            default:
                return null;
        }
    },
    defineShipmentIconOption(status) {
        switch (status) {
            case ShipmentStatuses.SCHEDULED.name:
            case ShipmentStatuses.SCHEDULED.fullName:
                return ShipmentStatuses.SCHEDULED;
            case ShipmentStatuses.PICKUP.name:
            case ShipmentStatuses.PICKUP.fullName:
                return ShipmentStatuses.PICKUP;
            case ShipmentStatuses.IN_PROGRESS.name:
            case ShipmentStatuses.IN_PROGRESS.fullName:
                return ShipmentStatuses.IN_PROGRESS;
            case ShipmentStatuses.COMPLETED.name:
            case ShipmentStatuses.COMPLETED.fullName:
                return ShipmentStatuses.COMPLETED;
            case ShipmentStatuses.CANCELLED.name:
            case ShipmentStatuses.CANCELLED.fullName:
                return ShipmentStatuses.CANCELLED;
            default:
                return null;
        }
    },

    defineMfaOption(cellData) {
        const propertyName = cellData?.column?.dataField;
        const booleanValue = this.getValueBasedOnPropertyName(propertyName, cellData);
        switch (propertyName) {
            case propertyNames.MFA_ENABLED:
                return booleanValue ? MfaIcons.MFA_ENABLED : MfaIcons.MFA_DISABLED;
            case propertyNames.MFA_SET_UP:
                return booleanValue ? MfaIcons.MFA_SET_UP : MfaIcons.MFA_NOT_SET_UP;
            default:
                return null;
        }
    },

    getValueBasedOnPropertyName(propertyName, cellData) {
        if (propertyName === propertyNames.MFA_ENABLED) {
            return cellData.data.mfaEnabled;
        } else if (propertyName === propertyNames.MFA_SET_UP) {
            return cellData.data.mfaSetUp;
        }
    }
};

export default IconDefineHelper;
