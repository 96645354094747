<template>
  <modal-dialog
      ref="dialog"
      title="Update time in the secondary branch"
      width="500"
      :visible="dialogVisible"
      submitLabel="Update"
      cancelLabel="Cancel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="isEditable"
      :loading="loading"
  >
    <crew-time-in-secondary-branch-form
        ref="form"
        :crews="crews"
        :secondaryBranchTime="secondaryBranchTime"
        :isEditForm="true"
        :installerRefId="installerRefId"
    />

    <error-modal
        ref="errorModal"
        :error="errorMessage"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="isErrorModalOpen = false"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import ErrorModal from "@/components/navigation/ErrorModal.vue";
import InstallerCrewSecondaryBranchTimes from "@/rest/installer/installerCrewSecondaryBranchTimes";
import CrewTimeInSecondaryBranchForm
  from "@/views/installers/crews/secondaryBranchTime/CrewTimeInSecondaryBranchForm.vue";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import DateUtils from "@/assets/utils/dateUtils";

const formDefaults = {
  errorMessage: null,
  loading: false,
  isErrorModalOpen: false,
  dialogVisible: false,
  secondaryBranchTime: null
};

export default {
  components: {
    GenericWarningDialog,
    CrewTimeInSecondaryBranchForm,
    ErrorModal,
    ModalDialog
  },

  data() {
    return Object.assign({}, formDefaults);
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    installerRefId: {
      type: String,
      required: true
    }
  },

  computed: {
    isEditable() {
      return this.secondaryBranchTime && !DateUtils.isPastDate(this.secondaryBranchTime.toDate);
    }
  },

  methods: {
    reset() {
      Object.assign(this.$data, formDefaults);
      this.$refs.form.resetForm();
    },
    openDialog(data) {
      if (this.$refs?.form) {
        this.$refs.form.resetForm();
      }
      this.secondaryBranchTime = data;
      this.dialogVisible = true;
      this.stopLoading();
    },
    closeDialog() {
      this.stopLoading();
      this.dialogVisible = false;
      this.reset();
    },
    showError(error) {
      this.errorMessage = error.response.data.message;
      this.isErrorModalOpen = true;
    },
    async onSubmitClicked() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        let payload = this.$refs.form.buildPayload();
        return InstallerCrewSecondaryBranchTimes.getRestApi().updateTimeInSecondaryBranch(payload)
            .then(() => {
              this.$emit('updateButtonClicked', payload);
              this.closeDialog();
            }).catch((error) => {
              this.stopLoading();
              this.showError(error);
            });
      }
    },
    onCancelClicked() {
      this.closeDialog();
      this.$emit('cancelClicked');
    },
    stopLoading() {
      this.loading = false;
    }
  }
};
</script>
