<template>
  <div style="border-top: 1px solid #ddd;">
    <inline-grid
        ref="poItemsGrid"
        :data-source="items"
        :selection="{ mode: 'single' }"
        :show-borders="true"
        :hover-state-enabled="true"
        :hasReceivedItems="hasReceivedItems"
        editable-cell="quantityOrdered"
        @editValue="onCellClick"
        @onClickClosePoItem="onCloseClicked"
        @onEtaChanged="etaUpdated"
    >
      <dx-editing
          mode="cell"
      />
      <dx-column
          data-field="productCatalogItem.productType.name"
          caption="Type"
      />
      <dx-column
          data-field="productCatalogItem.styleName"
          caption="Style"
      />
      <dx-column
          data-field="productCatalogItem.color"
          caption="Color"
      />
      <dx-column
          data-field="productCatalogItem.unitOfMeasure"
          caption="Unit Of Measure"
      />
      <dx-column
          data-field="quantityOrdered"
          caption="Qty Ordered"
          width="100"
          data-type="number"
          :format="currencyFormat"
          cellTemplate="editPoItemQuantityCellTemplate"
      />
      <dx-column
          data-field="quantityReceived"
          caption="Qty Received"
          width="100"
      />
      <dx-column
          data-field="totalVendorCostRounded"
          caption="Total Vendor Cost"
          width="125"
          :format="currencyFormat"
      />
      <dx-column
          data-field="status"
          caption="Status"
      />
      <dx-column
          data-field="etaDate"
          data-type="date"
          caption="ETA Date"
          cellTemplate="changeEtaButtonCellTemplate"
          width="10%"
      />
      <dx-column
          v-if="showCloseButtonColumn()"
          cellTemplate="closePoItemButtonCellTemplate"
      >
        Close
      </dx-column>
    </inline-grid>

    <v-row>
      <v-card-actions class="ml-2 mt-3">
        <inline-grid-button
            label="View Email Template"
            @click="showEmailTemplate"
        />
      </v-card-actions>
    </v-row>

    <purchase-order-email-template-dialog
        :items="items"
        :visible="emailTemplate"
        :customer="customer"
        :purchaseOrder="purchaseOrder"
        :totalVendorCostRounded="totalVendorCostRounded"
        @onCancelClicked="showEmailTemplate"
    />

    <close-p-o-wizard
        ref="closePurchaseOrderWizard"
        :purchaseOrderItemId="purchaseOrderItemId"
    />

    <edit-p-o-item-quantity-ordered-wizard
        ref="editPOQuantityWizard"
        :passwordError="passwordError"
        @onPasswordError="showPasswordError"
        @onUpdateQuantityError="showUpdateQuantityError"
        @onWizardReset="resetError"
        @onSubmitPOQuantityEditSuccess="quantityEditSuccess"
    />

  </div>
</template>

<script>
import InlineGrid from "../common/grid/InlineGrid.vue";
import InlineGridButton from "../common/grid/InlineGridButton.vue";
import {DxButton, DxColumn, DxDataGrid, DxEditing} from "devextreme-vue/ui/data-grid";
import PurchaseOrderEmailTemplateDialog from "./PurchaseOrderEmailTemplateDialog.vue";
import PurchaseOrders from "@/rest/purchaseOrders";
import ClosePOWizard from "../../components/purchaseOrders/closePurchaseOrder/ClosePOWizard";
import EditPOItemQuantityOrderedWizard from "./editPurchaseOrderItem/EditPOItemQuantityOrderedWizard";
import Constants from "../../assets/constants";
import moment from "moment";
import Roles from "../../assets/roles";


export default {
  components: {
    InlineGrid,
    InlineGridButton,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxButton,
    PurchaseOrderEmailTemplateDialog,
    ClosePOWizard,
    EditPOItemQuantityOrderedWizard,
    Roles
  },

  props: {
    purchaseOrderLines: Array,
    purchaseOrder: Object,
    jobReferenceId: String,
    customer: Object,
    totalVendorCostRounded: String
  },

  data() {
    return {
      currencyFormat: {
        type: "currency",
        precision: 2,
      },
      items: [],
      emailTemplate: false,
      successMessage: null,
      purchaseOrderItemId: null,
      passwordError: null
    };
  },

  watch: {
    purchaseOrderLines: {
      immediate: true,
      handler(value) {
        this.items = value;
      }
    }
  },

  computed: {
    hasReceivedItems() {
      return this.items.some(item => item.status === Constants.closedStatus
          || (item.quantityReceived !== null && item.quantityReceived > 0));
    }
  },

  created() {
    this.items = [...this.purchaseOrderLines];
  },

  methods: {
    showCloseButtonColumn(){
      return (this.$props.jobReferenceId && !Roles.isRegularOrBranchUser()) || !this.$props.jobReferenceId;
    },
    showEmailTemplate() {
      this.emailTemplate = !this.emailTemplate;
    },
    async etaUpdated(event, etaDate) {
      const eta = moment.utc(etaDate).add(-1 * moment().utcOffset() + 720, "m");
      let itemId = event.key.id;
      try {
        await PurchaseOrders.getRestApi().updatePurchaseOrderItemEta(itemId, eta);
        this.$emit('onEtaUpdated');
      } catch (error) {
        this.$emit('onUpdateError', error);
        throw error;
      }
    },
    onCloseClicked(rowData) {
      this.purchaseOrderItemId = rowData.row.key.id;
      let selectedItem = this.items.filter(item => item.id === this.purchaseOrderItemId);
      this.$emit("onPurchaseOrderItemClose", selectedItem);
    },
    onCellClick(rowData) {
      if (rowData.column.dataField === 'quantityOrdered' && rowData.columnIndex === 4 && rowData.rowType === 'data') {
        if (this.canEditQuantity()) {
          this.$refs.editPOQuantityWizard.openEditPOQuantityDialog(rowData);
        }
      }
    },
    canEditQuantity() {
      let isNotJobRelated = this.purchaseOrder.job == null;
      let poIsNotClosed = this.purchaseOrder.purchaseOrderClosedDate == null;
      return isNotJobRelated && poIsNotClosed && !this.hasReceivedItems;
    },
    showPasswordError() {
      this.passwordError = "Password is empty or does not match";
    },
    showUpdateQuantityError(error) {
      this.$emit('onUpdateQuantityError', error)
    },
    resetError() {
      this.passwordError = null;
    },
    quantityEditSuccess(response) {
      this.$emit('onQuantityEdited', response);
    }
  }
};
</script>

<style scoped>
::v-deep .dx-template-wrapper {
  text-align: center;
}
</style>
