<template>
  <v-dialog v-model="wizardVisible" persistent :width="800">
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>{{ title }}</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" editable step="1">
          Password
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Reason Code
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Confirm Vendor Contacting
        </v-stepper-step>
        <v-divider v-if="isRelatedToJob"></v-divider>
        <v-stepper-step :complete="step > 4" step="4" v-if="isRelatedToJob">
          Confirm Job Changes
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <validate-special-user-password-form
              ref="passwordForm"
              @passwordEmpty="passwordChangeHandler"
          />
          <span class="submitError" v-show="passwordError"> {{ passwordError }}</span>
          <button-container
              backName=Cancel
              nextName=Next
              :disableNextStep="isPasswordFieldEmpty"
              @getPreviousStep="resetWizard"
              @getNextStep="goToStepTwo"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <div v-if="itemDescription">
            {{ itemDescription }}
          </div>
          <close-p-o-reason-code-form
              ref="reasonForm"
              :isRelatedToJob="isRelatedToJob"
              @select="reasonCodeSelected"
          />

          <button-container
              backName=Cancel
              nextName=Next
              @getPreviousStep="resetWizard"
              @getNextStep="goToStepThree"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card flat>
            <div class="subheading pa-5">
              <p v-if="selectedPurchaseOrderItem"> {{ closeLineItemMessage }}</p>
              <p v-else> {{ closePOMessage }}</p>
              <p> {{ confirmationMessage }}</p>
            </div>
          </v-card>
          <button-container-with-cancel
              backName=Back
              nextName=Confirm
              cancelName=Cancel
              @cancel="resetWizard"
              @getPreviousStep="goToStep(2)"
              @getNextStep="goToSubmitStep"
          />
        </v-stepper-content>

        <v-stepper-content step="4" v-if="isRelatedToJob">
          <v-card flat>
            <div class="subheading pa-5">
              <p>This PO is related to a job, so its closure can lead to the changes in the job material line items.</p>
              <p>Please confirm that you want to proceed.</p>
            </div>
          </v-card>
          <button-container-with-cancel
              backName=Back
              nextName=Confirm
              cancelName=Cancel
              :nextLoading="confirmLoading"
              @cancel="resetWizard"
              @getPreviousStep="goToStep(3)"
              @getNextStep="submitClosePo"
          />
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "../../common/wizard/ButtonContainer.vue";
import ClosePOReasonCodeForm from "./ClosePOReasonCodeForm";
import PurchaseOrders from "../../../rest/purchaseOrders";
import ButtonContainerWithCancel from "../../common/wizard/ButtonContainerWithCancel";
import ValidateSpecialUserPasswordForm from "../../common/form/ValidateSpecialUserPasswordForm.vue";

export default {
  components: {
    ValidateSpecialUserPasswordForm,
    ButtonContainerWithCancel,
    ClosePOReasonCodeForm,
    ButtonContainer
  },

  props: {
    purchaseOrder: Object,
  },

  data() {
    return {
      wizardVisible: false,
      error: null,
      step: 1,
      isRelatedToJob: false,
      reasonCodeId: null,
      selectedPurchaseOrderItem: null,
      title: 'Close PO',
      itemDescription: null,
      closePOMessage: 'PO closure will not cancel the order if it was already sent to the vendor',
      closeLineItemMessage: 'PO line item closure will not cancel the order if it was already sent to the vendor.',
      confirmationMessage: 'Please confirm that you will contact the vendor to cancel the order.',
      confirmLoading: false,
      passwordError: null,
      isPasswordFieldEmpty: true
    };
  },

  methods: {
    passwordChangeHandler(value) {
      this.isPasswordFieldEmpty = value;
    },
    async submitClosePo() {
      this.confirmLoading = true;
      const payload = {
        reasonCodeId: this.reasonCodeId,
        comment: this.$refs.reasonForm.getComment()
      }
      this.selectedPurchaseOrderItem
          ? this.closePoItemRequest(this.selectedPurchaseOrderItem.id, payload)
          : this.closeWholePurchaseOrderRequest(this.purchaseOrder.purchaseOrderNumber, payload);
      this.loadPasswordFormDefaults();
    },
    async closePoItemRequest(selectedPoItemId, payload) {
      try {
        const response = await PurchaseOrders.getRestApi().closePurchaseOrderItem(selectedPoItemId, payload);
        this.confirmLoading = false;
        this.$emit('submitClosePOSuccess', response.data);
      } catch (error) {
        this.$emit('submitClosePOFailed', error);
      }
    },
    async closeWholePurchaseOrderRequest(purchaseOrderNumber, payload) {
      try {
        const response = await PurchaseOrders.getRestApi().closePurchaseOrder(purchaseOrderNumber, payload);
        this.confirmLoading = false;
        this.$emit('submitClosePOSuccess', response.data);
      } catch (error) {
        this.$emit('submitClosePOFailed', error);
      } finally {
        this.confirmLoading = false;
      }
    },
    openClosePODialog(isPORelatedToJob, selectedItem) {
      this.resetWizard();
      if (isPORelatedToJob) {
        this.isRelatedToJob = true;
      }
      this.wizardVisible = true;
      if (selectedItem) {
        this.selectedPurchaseOrderItem = selectedItem;
        this.title = "Close PO Line Item";
        this.itemDescription = this.createLineItemDescription(selectedItem);
      }
    },
    closeDialog() {
      this.wizardVisible = false;
      this.title = 'Close PO';
    },
    goToStep(step) {
      this.step = step;
    },
    async goToStepTwo() {
      let valid = await this.$refs.passwordForm.validatePasswordForm();
      if (valid) {
        this.passwordError = null;
        this.goToStep(2);
      } else {
        this.passwordError = 'Password is empty or does not match';
      }
    },
    goToStepThree() {
      let valid = this.$refs.reasonForm.validate();
      if (valid) {
        this.goToStep(3);
      }
    },
    goToSubmitStep() {
      if (this.isRelatedToJob) {
        this.goToStep(4)
      } else this.submitClosePo();
    },
    showError(error) {
      this.$emit("errorPOClose", error);
    },
    showSuccess(message) {
      this.$emit("successPOClose", message);
    },
    async resetWizard() {
      this.step = 1;
      this.reasonCodeId = null;
      this.selectedPurchaseOrderItem = null;
      this.itemDescription = null;
      this.isPasswordFieldEmpty = true;
      this.closeDialog();
      this.resetAllForms();
    },
    resetAllForms() {
      this.$nextTick(() => {
        this.$refs.reasonForm.loadDefaults();
      })
      this.loadPasswordFormDefaults();
    },
    reasonCodeSelected(reasonCodeId) {
      this.reasonCodeId = reasonCodeId;
    },
    createLineItemDescription(selectedItem) {
      return "Selected Item: " + selectedItem.productCatalogItem.productType.name + " - "
          + selectedItem.productCatalogItem.styleName + " - "
          + selectedItem.productCatalogItem.color
    },
    loadPasswordFormDefaults() {
      if (this.$refs.passwordForm) {
        this.$refs.passwordForm.loadDefaults();
      }
    }
  }
};
</script>
<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
