<template>
  <v-app-bar class="main-app-nav-bar mb-4" flat light color="white">
    <v-toolbar-title class="blue-grey--text text--lighten-1 pl-3">
      {{pagePrefix}} - {{ pageTitle }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <user-notification-bar
        ref="userNotificationBar"
        :userNotificationIcon="iconToShow"
        @notificationBarClosed="onNotificationBarClosed"
    />

    <div class="nav-user pt-3 pr-3 pl-3">
      <v-menu nudge-bottom="30">
        <template v-slot:activator="{ on }">
          <div
              v-on="on"
              class="user-dropdown"
              @click="onChangePasswordClicked"
          >
            <v-icon class="pt-1 pr-1" right color="#69b">person_pin</v-icon>
            <span class="nav-username">{{ loggedInUser }}</span>
          </div>
        </template>
      </v-menu>
    </div>
    <div class="nav-button mt-4 pr-4">
      <v-btn icon @click="onLogoutClicked">
        <v-icon color="#69b" large>exit_to_app</v-icon>
      </v-btn>
      <div>Logout</div>
    </div>
  </v-app-bar>
</template>

<script>
import UserNotificationBar from "../common/notifications/UserNotificationBar.vue";
import StickyOngoingNotificationsBar from "../common/notifications/OngoingNotificationsTable.vue";

export default {
  components: {
    StickyOngoingNotificationsBar,
    UserNotificationBar
  },

  props: {
    loggedInUser: String,
    pageTitle: String,
    userNotification: Object | null,
    pagePrefix: {
      type: String,
      default: "Oasis"
    }
  },

  computed: {
    iconToShow() {
      return this.userNotification?.icon
    }
  },

  methods: {
    onChangePasswordClicked() {
      this.$emit('changePasswordClicked');
    },
    onLogoutClicked() {
      this.$emit('logoutClicked');
    },
    onNotificationBarClosed() {
      this.$emit('notificationBarClosed');
    },
  }
};
</script>

<style scoped>
.nav-user {
  color: #69b;
  margin-top: 5px;
}
.nav-username {
  font-size: 20px;
  vertical-align: top;
  padding-left: 5px;
  padding-right: 10px;
}
.nav-button {
  border-left: 1px solid #eee;
  font-size: 8px;
  padding-left: 15px;
  padding-right: 10px;
  color: #69b;
}
.user-dropdown {
  margin: 0 10px;
  padding-left: 10px;
}
.user-dropdown:hover {
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
  cursor: pointer;
}

</style>
