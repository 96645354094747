<template>
  <drawer-form
      ref="form"
      title="Create Promo Code"
      submit-label="Save Promo Code"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <form-body ref="formBody" mode="create"/>
  </drawer-form>
</template>

<script>
import FormBody from "@/components/promoCodes/FormBody.vue";
import PromoCodes from "@/rest/promoCodes";
import DrawerForm from "../common/form/DrawerForm";

export default {
  components: {
    FormBody,
    DrawerForm
  },

  data() {
    return {inProgress: false};
  },

  methods: {
    onCancelClicked() {
      this.$emit("cancelClicked");
      this.$refs.form.resetValidation();
      this.$refs.formBody.resetForm();
    },
    async onSubmitClicked() {
      try {
        let valid = this.$refs.form.validate();
        if (valid) {
          this.inProgress = true;
          let payload = this.$refs.formBody.buildPayload();
          await this.creatPromoCode(payload);
          this.$emit("submitSuccess");
          this.$refs.formBody.resetForm();
        }
      } catch (error) {
        this.$emit("submitFailed", error);
      }
      this.inProgress = false;
    },
    async creatPromoCode(payload) {
      try {
        await PromoCodes.getRestApi().createPromoCode(payload);
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
