<template>
  <div>
    <DxDataGrid
        ref="nextActionsGrid"
        :data-source="nextActions"
        :show-borders="false"
        :hoverStateEnabled="true"
        :selection="{ mode: 'single' }"
        key-expr="id"
        @selection-changed="onRowSelected"
        @row-updated="onValueChanged($event)"
        @cell-prepared="onCellPrepared"
    >
      <dx-editing :allow-updating="true" :allow-adding="false" :allow-deleting="false" mode="row"/>
      <DxPaging :page-size="10"/>
      <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
      />
      <dx-column
          :width="180"
          data-field="createdAt"
          data-type="date"
          caption="Date"
          format="MM/dd/yyyy, hh:mm a"
          :allow-editing="false"
      />
      <dx-column
          data-field="creator"
          caption="User"
          :width="150"
          :allow-editing="false"
      />
      <dx-column
          data-field="dueDate"
          data-type="date"
          sortOrder="desc"
          caption="Next Action Date"
          :width="140"
          :allow-editing="false"
      />
      <dx-column
          data-field="nextActionNote"
          caption="Note"
          :allow-editing="false"
      />
      <dx-column
          data-field="status"
          caption="Action Status"
          :width="260"
      >
        <DxLookup
            :data-source="nextActionStatuses"
            display-expr="name"
            value-expr="name"
        />
      </dx-column>
    </DxDataGrid>

    <inline-grid-footer slot="footer">
      <inline-grid-button
          v-if="isAddActionButtonDisplayed"
          label="Add Action"
          @click="onAddActionButtonClick"
      />
    </inline-grid-footer>

    <add-next-action-dialog
        ref="addNextActionDialog"
        :userName="userName"
        @saveNexActionButtonClicked="onEmitSaveNextAction"
    />
    <show-next-action-dialog ref="showNextActionDialog"/>
    <snackbar ref="snackbar" error-color="white" default-timeout="4000"/>
  </div>
</template>

<script>
import {DxColumn, DxDataGrid, DxEditing, DxLookup, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";
import InlineGridFooter from "../../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../../components/common/grid/InlineGridButton.vue";
import Snackbar from "../../../components/common/Snackbar.vue";
import Constants from "../../../assets/constants";
import AddNextActionDialog from "../dialogContainers/nextActions/AddNextActionDialog";
import ShowNextActionDialog from "../dialogContainers/nextActions/ShowNextActionDialog";

export default {
  components: {
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    DxEditing,
    DxLookup,
    AddNextActionDialog,
    ShowNextActionDialog,
    DxPager,
    DxPaging,
    Snackbar,
    DxDataGrid,
  },

  props: {
    nextActions: {
      type: Array,
      required: true
    },
    nextActionStatuses: {
      type: Array,
      required: true
    },
    isAddActionButtonDisplayed: {
      type: Boolean,
      default: true
    }
  },

  mounted() {
    this.getJobNextActions();
    this.getLoggedInUser();
  },

  data() {
    return {
      userName: "",
      creatorId: 0,
      pageSizes: [10, 25, 50],
      snackbar: false,
    };
  },

  methods: {
    onSuccessSave() {
      this.resetCreateNextActionForm();
      this.closeCreateNextActionDialog();
    },
    onRowSelected(data) {
      this.$refs.showNextActionDialog.openDialog(data);
      this.$refs.nextActionsGrid.instance.clearSelection();
    },
    onAddActionButtonClick() {
      this.$refs.addNextActionDialog.openDialog();
    },
    resetCreateNextActionForm() {
      this.$refs.addNextActionDialog.reset();
    },
    setDialogLoadingFalse() {
      this.$refs.addNextActionDialog.setLoadingFalse();
    },
    closeCreateNextActionDialog() {
      this.$refs.addNextActionDialog.closeDialog();
    },
    getLoggedInUser() {
      const user = this.$store.state.loggedInUser;
      this.userName = `${user.firstName} ${user.lastName}`;
      this.creatorId = user.id;
    },
    onEmitSaveNextAction(nextActionNote, nextActionDate) {
      const data = {
        nextActionNote,
        creatorId: this.creatorId,
        dueDate: nextActionDate
      };
      this.$emit('saveNextAction', data);
    },
    async getJobNextActions() {
      this.$emit('getJobNextActions');
      this.$emit('getNextActionStatuses');
    },
    onValueChanged(event) {
      this.$emit('updateNextAction', event.data.status, event.key)
    },
    showSnackbarSuccess(message) {
      this.$refs.snackbar.addMessage(message);
    },
    onCellPrepared(element) {
      this.hideEditButton(element);
    },
    hideEditButton(element) {
      if (element.data && element.data.status !== Constants.nextActionStatusOpen && element.column.command === "edit") {
        element.cellElement.id = 'no-edit-allowed-' + element.rowIndex;
        document.getElementById("no-edit-allowed-" + element.rowIndex).firstChild.style.visibility = "hidden";
      }
    }
  }
};
</script>
