<template>
  <grid-view
      ref="nav"
      page-title="List Sales Representatives"
      nav-section="admin"
      :action-button-label="isActionButtonDisplayed ? 'Create Sales Rep' : null"
      :data-source="dataSource"
      :columnMinWidth="125"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="FirstName"
        caption="First Name"
        :fixed="true"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LastName"
        caption="Last Name"
        :fixed="true"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Tier"
        caption="Tier"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="State"
        caption="State"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ZipCode"
        caption="Zip Code"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Phone"
        caption="Phone"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Fax"
        caption="Fax"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Email"
        :minWidth="250"
        caption="Email"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Markets"
        caption="Markets"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Status"
        caption="Status"
        :cell-template="StatusRenderer"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CompanyName"
        caption="Company Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />

    <template v-slot:drawerContent>
      <sales-rep-create-drawer
          ref="form"
          title="Create Sales Representative"
          submitLabel="Save Sales Representative"
          @cancelClicked="onCancel"
          @onCreateFail="onCreateFail"
          @onCreateSuccess="onCreateSuccess"
      />
    </template>
  </grid-view>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import GridView from "@/views/common/GridView.vue";
import SalesRepCreateDrawer from "@/components/salesRepresentatives/SalesRepresentativeCreateDrawer.vue";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter.js";
import ODataContext from "@/rest/odataContext";
import Roles from "../../assets/roles";
import Constants from "../../assets/constants";
import StatusRenderer from "../../components/common/grid/StatusRenderer";
import Format from "../../components/common/Format";

export default {
  components: {
    GridView,
    DxColumn,
    SalesRepCreateDrawer
  },
  data() {
    return {
      allowMarketFilter: false,
      marketsFilter: [],
      dataSource: {
        store: ODataContext['SalesRepresentatives'],
        select: [
          "ReferenceId",
          "Active",
          "Status",
          "FirstName",
          "LastName",
          "Address",
          "City",
          "ZipCode",
          "State",
          "Phone",
          "Fax",
          "Email",
          "Markets",
          "CompanyName",
          "Tier"
        ]
      }
    };
  },
  created() {
    this.getFilters();
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    async getFilters() {
      try {
        // once Market Ids are returned on user data, we can limit what markets the user sees in this dropdown
        // if user is apart of more than one branch
        const user = this.$store.state.loggedInUser;
        if (user.roleId != Constants.userRoles.branchUser) {
          this.marketsFilter = await CustomizeFilter.getMarketFilters();
          this.allowMarketFilter = true;
        }
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    onRowSelected(data) {
      this.$router.push(`/sales-representatives/details/${data.ReferenceId}`);
    },
    onCreateSuccess() {
      this.$refs.nav.showSuccess("Created new sales representative successfully!");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onCreateFail(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    StatusRenderer,
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    }
  }
};
</script>
