import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

export interface Crew {
    id: number;
    referenceId: string;
    name: string;
    phoneNumber: string;
    email: string;
    capabilities: string;
    workingDays: string[];
    status: string;
}

export interface CrewCapabilityShortDTO {
    id: number;
    name: string;
}
export interface CrewWorkingDay {
    id: number;
    dayOfWeek: string;
}

export interface InstallerCrewCapabilitiesDTO {
    id: number;
    name: string;
    capabilities: CrewCapabilityShortDTO[];
}

/**
 * REST interface provided for Installer  Crews
 */
export interface InstallerCrewRestApi {
    createCrew(payload: Crew): AxiosPromise<Crew>;

    getInstallerCrews(refId: string): AxiosPromise<Crew[]>;

    updateCrew(payload: Crew): AxiosPromise<Crew>;

    getCrewsHavingSlotsByDateAndBranch(date: string, branchId: number): AxiosPromise<InstallerCrewCapabilitiesDTO>;

    getCrewWorkingDays(crewId: number): AxiosPromise<CrewWorkingDay>;
}

/**
 * REST implementation provided for Installer Crews.
 */
class Implementation implements InstallerCrewRestApi {

    createCrew(crew: Crew): AxiosPromise<Crew> {
        return Axios.axiosInstance().post<Crew>(`/installer-crew`, crew);
    }

    getInstallerCrews(installerId: string): AxiosPromise<Crew[]> {
        return Axios.axiosInstance().get<Crew[]>(`/installer-crew/${installerId}/all`);
    }

    updateCrew(updated: Crew): AxiosPromise<Crew> {
        return Axios.axiosInstance().put<Crew>(`/installer-crew`, updated);
    }

    getCrewsHavingSlotsByDateAndBranch(date: string, branchId: number): AxiosPromise<InstallerCrewCapabilitiesDTO> {
        return Axios.axiosInstance().get<InstallerCrewCapabilitiesDTO>(`/installer-crew/having-slots?date=${date}&branchId=${branchId}`);
    }

    getCrewWorkingDays(crewId: number): AxiosPromise<CrewWorkingDay> {
        return Axios.axiosInstance().get<CrewWorkingDay>(`/installer-crew/${crewId}/working-days`);
    }
}

const InstallerCrews = {
    getRestApi(): InstallerCrewRestApi {
        return new Implementation();
    }
};

export default InstallerCrews;
