<template>
  <v-autocomplete
      v-model="selected"
      :items="installers"
      :loading="loading"
      no-data-text="No installers for job"
      :label="label || 'Installer'"
      item-text="companyName"
      item-value="id"
      :multiple="false"
      :placeholder="placeholder || 'Search Installers'"
      persistent-placeholder
      :rules="rules"
      id="installer-search-field-chargeback"
      attach
      dense
      validate-on-blur
  />
</template>

<script>
import Installers from "@/rest/installers";

export default {
  components: {},

  data() {
    return {
      selected: null,
      installers: [],
      loading: false,
    };
  },

  props: ["value", "placeholder", "jobId", "rules", "availableInstallers", "label"],

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("input", value);
      if (value && this.installers) {
        this.installers.forEach((installer) => {
          if (installer.id === value) {
            this.$emit("installer", installer);
          }
        });
      }
    },
    availableInstallers(value) {
      this.installers = value;
    },
    jobId(value) {
      this.refresh(value);
    },
  },

  async created() {
    await this.refreshWithExistingJobId();
  },

  methods: {
    async refreshWithExistingJobId() {
      if (this.jobId) {
        this.refresh(this.jobId);
      }
    },
    async refresh(jobId) {
      try {
        // will need to change function to accept branchId once Installers are associated to markets on backend
        const response = await Installers.getRestApi().getInstallersForJobAndParentJob(jobId);
        this.installers = response.data;
      } catch (error) {
        throw error;
      }
    },
    getSelected() {
      return this.selected;
    },
    clearSelected() {
      this.selected = null;
    }
  },
};
</script>

<style scoped>
.v-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.v-list > div {
  width: 50%;
}
</style>
