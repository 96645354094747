<template>
  <div :class="{
    'transfer-action-radio-button' : true,
    'transfer-action-cell-not-valid': showError
  }">
    <radio-buttons
        ref="actionRadioButtons"
        :disabled="cellData.data.branchesNames.length === 0"
        :selectOptions="actions"
        :value="preSelectedAction"
        :required="true"
        @onValueChanged="onActionChanged"
    />
    <div v-show="showError" class="transfer-action-cell-validation-message">
      <span>* Required</span>
    </div>
  </div>
</template>

<script>
import RadioButtons from "@/components/common/form/RadioButtons.vue";

export default {
  components: {
    RadioButtons
  },
  props: {
    cellData: {
      type: Object,
      required: true
    },
    showValidationResult: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actions: [
        {key: "Transfer", label: "I will manually request a transfer from another branch", value: "transfer"},
        {key: "CreatePO", label: "Create PO", value: "createPO"}
      ]
    };
  },
  methods: {
    onActionChanged(value) {
      this.cellData.data.isTransferAction = value === "transfer";
      this.cellData.data.isCreatePOAction = value === "createPO";
    }
  },
  computed: {
    showError() {
      return this.showValidationResult && !this.isValid;
    },
    isValid() {
      return this.cellData.data.isTransferAction || this.cellData.data.isCreatePOAction;
    },
    preSelectedAction() {
      if (this.cellData.data.isTransferAction) {
        return "transfer";
      } else if (this.cellData.data.isCreatePOAction) {
        return "createPO";
      }
    }
  }
};
</script>

<style scoped>
::v-deep label {
  font-size: 14px;
}

.transfer-action-cell-not-valid {
  border: 1px solid #b71c1c;
  border-radius: 2px;
}

.transfer-action-cell-validation-message {
  text-align: left;
  color: #b71c1c;
  margin: 10px 5px;
}
</style>