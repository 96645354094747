<template>
  <v-container>
    <v-row v-if="customer">
      <v-col cols="12">
        <read-only-text-field-with-hover-and-link-cell-template
            :value="customer.fullName"
            :link="navLink"
            :show="true"
            label="Customer Name"
        />
      </v-col>
    </v-row>

    <v-row v-if="showOnSalesRepApp && !hidePhoneAndEmail">
      <v-col cols="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            :value="customer.primaryPhone"
            label="Customer Primary Phone"
            v-facade="phoneNumberMask"
            readonly
            data-id="lead-details-customer-primary-phone"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            :value="customer.alternatePhone"
            label="Customer Alternate Phone"
            v-facade="phoneNumberMask"
            readonly
            data-id="lead-details-customer-alternate-phone"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            :value="lead.address"
            label="Job Property Address"
            readonly
            data-id="lead-details-customer-address"
        />
      </v-col>
    </v-row>

    <v-row v-if="!hidePhoneAndEmail">
      <v-col cols="12">
        <text-field-with-copy-to-clip-board
            :value="customer.email"
            readonly
            label="Email"
            id="lead-details-customer-contact-email"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            :value="lead.city"
            label="City"
            readonly
            data-id="lead-details-customer-city"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            :value="lead.state"
            label="State"
            readonly
            data-id="lead-details-customer-state"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            :value="lead.zipCode"
            label="ZipCode"
            readonly
            data-id="lead-details-customer-zipcode"
        />
      </v-col>
    </v-row>

    <v-row v-if="!showOnSalesRepApp">
      <v-col cols="12">
        <v-text-field
            :value="lead.marketName"
            placeholder=" "
            persistent-placeholder
            label="Market"
            readonly
            data-id="lead-details-market-name"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Constants from "../../assets/constants";
import ReadOnlyTextFieldWithHoverAndLinkCellTemplate
  from "../common/grid/cellTemplates/ReadOnlyTextFieldWithHoverAndLinkCellTemplate.vue";
import TextFieldWithCopyToClipBoard from "@/components/common/templates/TextFieldWithCopyToClipBoard.vue";

export default {
  components: {
    TextFieldWithCopyToClipBoard,
    ReadOnlyTextFieldWithHoverAndLinkCellTemplate
  },

  props: {
    customer: {
      type: Object,
      default() {
        return {};
      }
    },
    lead: {
      type: Object,
      required: true
    },
    createdAt: String,
    canGoToCustomer: Boolean,
    hidePhoneAndEmail:{
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      phoneNumberMask: Constants.phoneNumberMask
    };
  },

  computed: {
    showOnSalesRepApp() {
      return this.$route.fullPath.includes('/sales/');
    },
    navLink() {
      if (this.canGoToCustomer && this.customer) {
        return `/customers/details/${this.customer.referenceId}`;
      } else return null;
    },
  },

  methods: {}
};
</script>
