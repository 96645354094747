import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {JobLineItem, JobLineItemSummary} from "@/rest/jobLineItems";
import {InventoryItem} from "@/rest/inventoryItems";
import {Vendor} from "@/rest/vendors";
import {PurchaseOrder} from "@/rest/purchaseOrders";
import {Installation} from "@/rest/installations";
import {ActivityLog} from "@/rest/interfaces/activityLogs";
import * as AxiosUtils from "./util/axiosUtils";

/**
 * Jobs.
 */
export class Job {
    id: number;
    customerId: number;
    status: number;
    installationTime: string;
    installationDate: string;
    contract: string;
    createdAt: string;
    updatedAt: string;
    referenceId: string;

    constructor(
        id: number,
        customerId: number,
        status: number,
        installationTime: string,
        installationDate: string,
        contract: string,
        createdAt: string,
        updatedAt: string,
        referenceId: string
    ) {
        this.id = id;
        this.customerId = customerId;
        this.status = status;
        this.installationTime = installationTime;
        this.installationDate = installationDate;
        this.contract = contract;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.referenceId = referenceId;
    }
}

/**
 * Job summary information.
 */
export class JobSummary {
    job: Job;
    lineItems: JobLineItemSummary[];

    constructor(job: Job, lineItems: JobLineItemSummary[]) {
        this.job = job;
        this.lineItems = lineItems;
    }
}

/**
 * Job content for save/update.
 */
export class JobContent {
    job: Job;
    lineItems: JobLineItem[];

    constructor(job: Job, lineItems: JobLineItem[]) {
        this.job = job;
        this.lineItems = lineItems;
    }
}

/**
 * Inventory checkout plan.
 */
export class InventoryCheckoutPlan {
    lineItemSummary: JobLineItemSummary;
    inventoryItems: InventoryItem[];

    constructor(
        lineItemSummary: JobLineItemSummary,
        inventoryItems: InventoryItem[]
    ) {
        this.lineItemSummary = lineItemSummary;
        this.inventoryItems = inventoryItems;
    }
}

/**
 * Purchase order plan.
 */
export class PurchaseOrderPlan {
    vendor: Vendor;
    lineItemSummaries: JobLineItemSummary[];

    constructor(vendor: Vendor, lineItemSummaries: JobLineItemSummary[]) {
        this.vendor = vendor;
        this.lineItemSummaries = lineItemSummaries;
    }
}

/**
 * Job fulfillment plan.
 */
export class JobFulfillmentPlan {
    jobId: Number;
    inventoryCheckoutPlans: InventoryCheckoutPlan[];
    purchaseOrderPlans: PurchaseOrderPlan[];

    constructor(
        jobId: Number,
        inventoryCheckoutPlans: InventoryCheckoutPlan[],
        purchaseOrderPlans: PurchaseOrderPlan[]
    ) {
        this.jobId = jobId;
        this.inventoryCheckoutPlans = inventoryCheckoutPlans;
        this.purchaseOrderPlans = purchaseOrderPlans;
    }
}

/**
 * Job fulfillment results.
 */
export class JobFulfillmentResults {
}

/**
 * REST interface provided for Jobs.
 */
export interface JobRestApi {
    getSearchedJobAsViewForAutocomplete(searchString: string): AxiosPromise<Job[]>;
    getJobById(id: number): AxiosPromise<Job>;
    getJobSummaryById(id: number): AxiosPromise<JobSummary>;
    getJobLineItems(id: number): AxiosPromise<JobLineItem[]>;
    updateOriginatingJob(id: number, originatingJobId: number): AxiosPromise<Job>;
    getJobProductLineItemsInventory(id: number): any;
    getJobProductLineItems(id: number): AxiosPromise<JobLineItem[]>;
    getJobProductLineItemsVendors(referenceId: string): AxiosPromise<Vendor[]>;
    getJobLaborLineItems(id: number): AxiosPromise<JobLineItem[]>;
    getJobActivityLogs(id: number): AxiosPromise<ActivityLog[]>;
    getJobInstallations(id: string, assignedOnly: boolean, hideEarlyPayed: boolean, onlyCurrentInstallations: boolean,
                        onlyScheduledInstallations: boolean): AxiosPromise<Installation[]>;
    getJobInstallationsWithCrews(id: number): AxiosPromise<Installation[]>;
    getStopsNumberByInstallationIdAndDate(id: number, date: string): any;
    getAllStopGroupsForLaborItems(payload: []): any;
    getJobPurchaseOrders(id: number): AxiosPromise<PurchaseOrder[]>;
    setJobLineItems(id: number, content: JobContent): AxiosPromise<JobContent>;
    createJobFulfillmentPlan(id: number): AxiosPromise<JobFulfillmentPlan>;
    processJobFulfillmentPlan(plan: JobFulfillmentPlan, wsSessionId: string): AxiosPromise<JobFulfillmentResults>;
    invoiceJob(id: number,  wsSessionId: string): AxiosPromise<Job>;
    checkInvoiceJob(id: number): AxiosPromise<boolean>;
    cancelJob(id: number, wsSessionId: string): AxiosPromise<Job>;
    createSubjob(parentRefId: string): AxiosPromise<Job>;
    getSubjobs(parentRefId: string): AxiosPromise<Job[]>;
    logSalesRepresentativePrint(refId: string): AxiosPromise<ActivityLog>;   //Unused. Reason unknown
}

/**
 * REST implementation provided for Jobs.
 */
class Implementation implements JobRestApi {
    getJobById(id: number): AxiosPromise<Job> {
        return Axios.axiosInstance().get<Job>(`/jobs/${id}`);
    }
    getJobSummaryById(id: number): AxiosPromise<JobSummary> {
        return Axios.axiosInstance().get<JobSummary>(`/jobs/${id}/summary`);
    }
    updateOriginatingJob(id: number, originatingJobId: number): AxiosPromise<Job> {
        return Axios.axiosInstance().put<Job>(`/jobs/${id}/originating/${originatingJobId}`);
    }
    getJobProductLineItemsInventory(id: number): any {
        return Axios.axiosInstance().get<JobLineItem[]>(`/jobs/${id}/lineitems/product/inventory`);
    }
    getJobPurchaseOrders(id: number): AxiosPromise<PurchaseOrder[]> {
        return Axios.axiosInstance().get<PurchaseOrder[]>(`/jobs/${id}/orders`);
    }
    getStopsNumberByInstallationIdAndDate(id: number, date: string): any {
        return Axios.axiosInstance().get(`/stops/installation/${id}/${date}`);
    }
    getAllStopGroupsForLaborItems(payload: []): any {
        return Axios.axiosInstance().post('/stops/groups', payload);
    }
    getJobProductLineItems(id: number): AxiosPromise<JobLineItem[]> {
        return Axios.axiosInstance().get<JobLineItem[]>(`/jobs/${id}/lineitems/product`);
    }
    getJobProductLineItemsVendors(referenceId: string): AxiosPromise<Vendor[]> {
        return Axios.axiosInstance().get<Vendor[]>(`/jobs/${referenceId}/lineitems/product/vendors`);
    }
    getJobLaborLineItems(id: number): AxiosPromise<JobLineItem[]> {
        return Axios.axiosInstance().get<JobLineItem[]>(`/jobs/${id}/lineitems/labor`);
    }
    getJobActivityLogs(id: number): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>(`/jobs/${id}/logs`);
    }
    getJobLineItems(id: number): AxiosPromise<JobLineItem[]> {
        return Axios.axiosInstance().get<JobLineItem[]>(`/jobs/${id}/lineitems`);
    }
    getJobInstallations(id: string, assignedOnly: boolean, hideEarlyPayed: boolean, onlyCurrentInstallations: boolean,
                        onlyScheduledInstallations: boolean): AxiosPromise<Installation[]> {
        return Axios.axiosInstance().get<Installation[]>(`/jobs/${id}/installations`, {
            params: {
                "assignedOnly": assignedOnly,
                "hideEarlyPayed": hideEarlyPayed,
                "onlyCurrentInstallations": onlyCurrentInstallations,
                "onlyScheduledInstallations": onlyScheduledInstallations
            }
        });
    }
    getJobInstallationsWithCrews(id: number): AxiosPromise<Installation[]> {
        return Axios.axiosInstance().get<Installation[]>(`/jobs/${id}/installations/summary`);
    }
    setJobLineItems(id: number, content: JobContent): AxiosPromise<JobContent> {
        return Axios.axiosInstance().post<JobContent>(`/jobs/${id}/lineitems`, content);
    }
    createJobFulfillmentPlan(id: number): AxiosPromise<JobFulfillmentPlan>{
        return Axios.axiosInstance().get<JobFulfillmentPlan>(`/jobs/${id}/fulfill`);
    }
    processJobFulfillmentPlan(plan: JobFulfillmentPlan, wsSessionId: string): AxiosPromise<JobFulfillmentResults> {
        const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
        return Axios.axiosInstance().post<JobFulfillmentResults>(`/jobs/${plan.jobId}/fulfill`, plan, config);
    }
    invoiceJob(id: number, wsSessionId: string): AxiosPromise<Job> {
        const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
        return Axios.axiosInstance().put<Job>(`/jobs/${id}/invoice`, {}, config);
    }
    checkInvoiceJob(id: number): AxiosPromise<boolean> {
        return Axios.axiosInstance().post<boolean>(`/jobs/${id}/invoice`);
    }
    cancelJob(id: number, wsSessionId: string): AxiosPromise<Job> {
        const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
        return Axios.axiosInstance().put<Job>(`/jobs/${id}/cancel`, {}, config);
    }
    createSubjob(parentRefId: string): AxiosPromise<Job> {
        return Axios.axiosInstance().post<Job>(`/jobs/${parentRefId}/subjob`, null);
    }
    getSubjobs(parentRefId: string): AxiosPromise<Job[]> {
        return Axios.axiosInstance().get<Job[]>(`/jobs/${parentRefId}/subjobs`);
    }
    getSearchedJobAsViewForAutocomplete(searchString: string): AxiosPromise<Job[]> {
        return Axios.axiosInstance().get<Job[]>("/jobs/active/view/search/autocomplete", {
            params: {"referenceIdContains": searchString}
        });
    }
    //Unused. Reason: deprecated web access for Sales Reps
    logSalesRepresentativePrint(refId: string): AxiosPromise<ActivityLog> {
        return Axios.axiosInstance().post(`/jobs/${refId}/logSalesRepJobInstallationPrint`);
    }
}

const Jobs = {
    getRestApi(): JobRestApi {
        return new Implementation();
    },
};

export default Jobs;
