<template>
  <div>
    <tree-list
        ref="vendorManagementGrid"
        :vendorManagementItems="vendorManagementItems"
        :activeVendors="activeVendors"
        :catalogItemId="catalogItemId"
        @onAddNewVendorCost="saveVendorCost"
        @vendorRowSelected="showAddCostButton"
        @onVendorSkuUpdated="updateVendorSku"
    />
    <inline-grid-footer>
      <inline-grid-button
          :loading="!activeVendors"
          label="Add Vendor"
          @click="addVendor"
      />
      <inline-grid-button
          v-if="selectedVendor"
          :label="'Add Cost for ' +  selectedVendor.name || selectedVendor.vendor.name"
          :width="'auto'"
          @click="addVendorCost"
      />

    </inline-grid-footer>

    <add-new-vendor-cost-dialog
        ref="addNewVendorCostDialog"
        :activeVendors="availableVendors"
        @onAddNewVendorCost="handleAddVendorCost"
    />
  </div>
</template>
<script>

import InlineGrid from "../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../components/common/grid/InlineGridButton.vue";
import AddVendorRelationDialog from "./AddSpecificVendorCostDialog.vue";
import TreeList from "../common/grid/TreeList.vue";
import AddBranchVendorRelationDialog from "../common/dialogContainers/catalogItemManagement/DistributionVendorDialog.vue";
import AddNewVendorCostDialog from "../common/dialogContainers/catalogItemManagement/AddNewVendorCostDialog.vue";
import VendorManagements from "../../rest/vendorManagement";

export default {
  components: {
    AddNewVendorCostDialog,
    AddBranchVendorRelationDialog,
    TreeList,
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    AddVendorRelationDialog,
  },

  props: {
    catalogItemId: {
      type: Number,
      required: true
    },
    vendorManagementItems: {
      type: Array,
      required: false,
    },
    activeVendors: {
      type: Array,
      required: true,
    }
  },

  computed: {
    availableVendors() {
      return this.activeVendors.map(vendor => {
        const vendorManagementItem = this.vendorManagementItems.find(
            vendorManagementItem => vendorManagementItem.vendor?.id === vendor.id
        );
        if (vendorManagementItem) {
          return { ...vendor, disabled: true };
        } else {
          return vendor;
        }
      });
    }
  },

  data() {
    return {
      vendorCostAddedSuccessMessage: 'New Vendor Cost has been successfully added',
      vendorSkuUpdatedSuccessMessage: 'Vendor SKU has been successfully updated',
      selectedVendor: null
    };
  },

  methods: {
    addVendor() {
      this.$refs.addNewVendorCostDialog.openDialog();
    },
    saveVendorCost(formData) {
      this.performAddNewVendorCostForSpecificVendorRequest(formData);
      this.$refs.vendorManagementGrid.resetVendorCostForm();
    },
    showAddCostButton(vendorSelected) {
      this.selectedVendor = vendorSelected.vendor ? vendorSelected.vendor : vendorSelected;
    },
    addVendorCost() {
      this.$refs.vendorManagementGrid.addVendorCost();
    },
    handleAddVendorCost(formData) {
      this.performSaveVendorCostRequest(formData);
    },
    updateVendorSku(event) {
      this.performUpdateVendorSkuRequest(this.catalogItemId, event.data.vendor.id, event.data.vendorSku);
    },
    async performUpdateVendorSkuRequest(catalogItemId, vendorId, updatedVendorSku) {
      await VendorManagements.getRestApi().updateVendorManagementSku(catalogItemId, vendorId, updatedVendorSku)
        .then((response) => {
          this.$emit('onVendorSkuUpdated', this.vendorSkuUpdatedSuccessMessage);
          this.$refs.addNewVendorCostDialog.closeDialog();
        })
        .catch((error) => {
          this.emitError(error);
        }).finally(() => {
          this.$refs.addNewVendorCostDialog.setLoadingFalse();
        });
    },
    async performSaveVendorCostRequest(payload) {
      await VendorManagements.getRestApi().saveVendorManagementData(this.catalogItemId, payload)
        .then((response) => {
          this.$emit('onVendorCostAdded', this.vendorCostAddedSuccessMessage);
          this.$refs.addNewVendorCostDialog.closeDialog();
        })
        .catch((error) => {
          this.emitError(error);
        }).finally(() => {
          this.$refs.addNewVendorCostDialog.setLoadingFalse();
        });
    },
    emitError(error) {
      this.$emit('onVendorCostError', error);
    },
    async performAddNewVendorCostForSpecificVendorRequest(payload) {
      try {
        await VendorManagements.getRestApi().addVendorCostForSpecificVendor(this.catalogItemId, payload);
        this.$refs.addNewVendorCostDialog.onCancelClicked();
        this.$emit('onVendorCostUpdated', this.vendorCostAddedSuccessMessage);
      } catch (error) {
        this.emitError(error);
      } finally {
        this.$refs.addNewVendorCostDialog.setLoadingFalse();
      }
    }
  }
};
</script>
