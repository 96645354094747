<template>
  <navigation-view ref="nav" page-title="Inventory Management" nav-section="orders">
    <template v-slot:content>
      <v-container grid-list-lg column>
        <div class="headline">Check In - Inventory Adjustments</div>
        <v-divider class="detailsDivider"/>

        <v-row>
          <v-col md="4">
            <branches-search-field
                :required="true"
                :selectAllEnabled="false"
                @inputSelected="handleGetBinsByBranch"
            />
          </v-col>
          <v-col md="4">
            <reason-codes-search-field
                v-if="branchReferenceId"
                :branchSelected="branchReferenceId"
                @input="onReasonCodeChanged"/>
          </v-col>
        </v-row>

        <!-- if no purchase order selected, show temp search screen -->
        <v-row v-if="!branchReferenceId" column class="tempSearchContainer">
          <v-col md="12">
            <v-icon color="primary" class="searchIcon">search</v-icon>
            <p class="subheading">Get started by selecting a Branch</p>
          </v-col>
        </v-row>

        <v-row v-show="branchReferenceId">
          <v-col md="12">
            <v-card raised light color="white" class="mt-3 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Search Product Catalog</v-app-bar-title>
              </v-app-bar>
              <grid-toolbar
                  @onToggleResetGridDialog="clearGridState"
              />
              <search-catalog-items-grid
                  ref="selectCatalogItemsGrid"
                  @rowSelected="addToSelectedItems"
              />
            </v-card>
          </v-col>

          <v-col md="12">
            <v-card raised light color="white" class="mt-3 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Check In Items</v-app-bar-title>
              </v-app-bar>
              <inventory-general-check-in-selected-items-table
                  :bins="bins"
                  :selectedItems="selectedItems"
                  :showQuantityOrdered="false"
                  :productTypes="productTypes"
                  @deleteClicked="onDeleteRow"
                  @reduceQuantityClicked="onReduceQuantity"
                  @increaseQuantityClicked="onIncreaseQuantity"
                  @selectedItemChange="selectedItemChange"
              />
            </v-card>
          </v-col>
          <confirmation-dialog
              :loading="inProgress"
              :visible="confirmModalOpened"
              :cancel-label="`No`"
              :submit-label="`Yes`"
              :confirmationText="`You are about to check in $${totalCost} worth of inventory. Are you sure?`"
              @onCancelClicked="confirmModalOpened = false"
              @onSubmitClicked="handleCheckInConfirmed"
          />

          <v-col class="d-flex justify-end">
            <span class="submitError">{{ submitError }}</span>
            <v-btn
                large
                color="primary"
                class="checkInButton"
                :disabled="!(selectedItems.length && reasonCodeId)"
                @click="handleCheckInClicked"
            >
              Check In
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import SearchCatalogItemsGrid from "../../components/catalogItems/SearchCatalogItemsGrid.vue";
import InventoryGeneralCheckInSelectedItemsTable
  from "../../components/inventoryManagement/InventoryGeneralCheckInSelectedItemsTable.vue";
import BranchesSearchField from "../../components/searchFields/BranchesAutocomplete.vue";
import ReasonCodesSearchField from "../../components/searchFields/ReasonCodesCheckIn.vue";
import GridToolbar from "../../components/common/grid/GridToolbar.vue";
import InventoryItems from "../../rest/inventoryItems.ts";
import ProductTypes from "../../rest/productTypes";
import BoxQuantity from "../../assets/boxQuantity";
import Bins from "../../rest/bins";
import ConfirmationDialog from "../../components/common/ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
    NavigationView,
    SearchCatalogItemsGrid,
    InventoryGeneralCheckInSelectedItemsTable,
    BranchesSearchField,
    ReasonCodesSearchField,
    GridToolbar
  },

  data() {
    return {
      selectedItems: [],
      bins: null,
      submitError: null,
      branchReferenceId: null,
      promoCodeId: null,
      inProgress: false,
      reasonCodeId: null,
      productTypes: [],
      confirmModalOpened: false
    };
  },

  created() {
    let component = this;
    ProductTypes.getRestApi()
        .getActiveProductTypes()
        .then((response) => {
          component.productTypes = response.data;
        })
  },

  computed: {
    totalCost() {
      return this.selectedItems.reduce((acc, item) => {
        let quantityForMessage = item.roundedBoxQuantity? item.roundedBoxQuantity : item.quantity;
        return acc + (Number(quantityForMessage) * item.ActiveVendorCost);
      }, 0).toFixed(2);
    }
  },

  methods: {
    clearError() {
      this.submitError = null;
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    async handleGetBinsByBranch(data) {
      try {
        this.branchReferenceId = data;
        this.bins = await this.getBinsByBranch();
      } catch (error) {
        this.showNavError(error);
      }
    },
    async getBinsByBranch() {
      try {
        const response = await Bins.getRestApi().getBinsByBranchReferenceId(this.branchReferenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    addToSelectedItems(event) {
      let data = event.selectedRowsData[0];
      if (data) {
        const rowData = this.createRowData(data);
        this.selectedItems.push(rowData);
      }
    },
    createRowData(selectedRow) {
      const row = {...selectedRow};
      // assigns none if is null for reusable selected items table
      row.styleName = selectedRow.StyleName || "none";
      row.color = selectedRow.Color || "none";
      row.productType = selectedRow.ProductType || "none";
      row.productTypeId = selectedRow.ProductTypeId || null;
      row.ordered = null;
      row.quantity = null;
      row.rollNumber = null;
      row.dyeLot = null;
      row.binId = null;
      row.binName = null;
      row.roundedBoxQuantity = selectedRow.BoxQuantity
          ? BoxQuantity.roundValueToBoxQuantity(row.quantity, selectedRow.BoxQuantity)
          : null;
      return row;
    },
    onDeleteRow(index) {
      this.clearError();
      this.selectedItems.splice(index, 1);
      this.$refs.selectCatalogItemsGrid.deselectItems();
    },
    onReduceQuantity(item, index) {
      this.clearError();
      const newItem = {...item};
      let quantity = parseInt(newItem.quantity || 0);
      if (quantity > 0) {
        quantity -= 1;
      }
      this.selectedItems[index].quantity = Number(quantity);
      this.selectedItems[index].roundedBoxQuantity = this.setBoxQuantity(this.selectedItems[index], index);

    },
    onIncreaseQuantity(item, index) {
      this.clearError();
      const newItem = {...item};
      let quantity = parseInt(newItem.quantity || 0);
      this.selectedItems[index].quantity = Number((quantity += 1));
      this.selectedItems[index].roundedBoxQuantity = this.setBoxQuantity(this.selectedItems[index], index);
    },
    setBoxQuantity(selectedItem, index) {
      return selectedItem.roundedBoxQuantity = this.selectedItems[index].BoxQuantity
        ? BoxQuantity.roundValueToBoxQuantity(this.selectedItems[index].quantity, this.selectedItems[index].BoxQuantity)
        : BoxQuantity.roundValueToBoxQuantity(this.selectedItems[index].quantity, this.selectedItems[index].quantity);
    },
    validateIfHasBinAssigned(items) {
      let pass = true;
      items.forEach(item => {
        if (!item.binId) {
          this.submitError = `Must assign a bin for product ${item.styleName} ${item.productType} ${item.color}`;
          pass = false;
        }
      });
      return pass;
    },
    async handleCheckInConfirmed() {
      this.inProgress = true;
      try {
        const lineItems = this.createLineItemParams();
        await this.checkInInventory(lineItems);
        this.handleSuccessCheckInInventory();
      } catch (error) {
        this.handleFailCheckInInventory(error);
      }
      this.inProgress = false;
      this.confirmModalOpened = false;
    },
    handleCheckInClicked() {
      this.clearError();
      const isValidated = this.handleValidateLineItems();
      if (isValidated) {
        this.confirmModalOpened = true;
      }
    },
    handleValidateLineItems() {
      this.clearError();
      let pass = true;
      const items = [...this.selectedItems];
      if (!this.hasQuantityEntered(items)) {
        pass = false;
      } else if (!this.validateIfHasBinAssigned(items)) {
        pass = false;
      } else if (!this.validateIfRollNumberAndDyeLotEntered(items)) {
        pass = false;
      }
      return pass;
    },
    validateIfRollNumberAndDyeLotEntered(items) {
      let pass = true;

      items.forEach(item => {
        let productType = this.productTypes.find(productType => productType.id === item.productTypeId);
        if (productType.isRolled && !item.rollNumber) {
          this.submitError = `Must specify a roll number for product
          ${item.styleName} ${item.productType} ${item.color}`;
          pass = false;
        }

        if (productType.hasDyeLot && !item.dyeLot) {
          this.submitError = `Must specify a dye lot for product
          ${item.styleName} ${item.productType} ${item.color}`;
          pass = false;
        }
      });
      return pass;
    },
    hasQuantityEntered(items) {
      let pass = true;
      items.forEach(item => {
        if (!this.isNumericQuantityValue(item.quantity)) {
          this.submitError = `Cannot have 0 quantity for product
          ${item.styleName} ${item.productType} ${item.color}`;
          pass = false;
        }
        if ((item.quantity) < 0) {
          this.submitError = `Quantity can't be negative for
          ${item.styleName} ${item.productType} ${item.color}.`;
          pass = false;
        }
      });
      return pass;
    },
    isNumericQuantityValue(quantity) {
      return !(!quantity || Number(quantity) === 0);
    },
    createLineItemParams() {
      const items = [...this.selectedItems];
      const lineItems = [];
      items.forEach(item => {
        let lineItem = {
          binId: item.binId,
          supplierCostId: item.SupplierCostId,
          quantity: item.roundedBoxQuantity
              ? item.roundedBoxQuantity
              : item.quantity,
          productCatalogItemId: item.ProductCatalogItemId,
          dyeLot: item.dyeLot,
          rollNumber: item.rollNumber,
          vendorId: item.VendorId
        };
        lineItems.push(lineItem);
      });
      return lineItems;
    },
    async checkInInventory(items) {
      try {
        let request = {
          reasonCodeId: this.reasonCodeId,
          branchReferenceId: this.branchReferenceId,
          checkIns: items
        };
        await InventoryItems.getRestApi().inventoryItemsCheckInGeneric(request);
      } catch (error) {
        throw error;
      }
    },
    handleFailCheckInInventory(error) {
      this.$refs.nav.showError(error);
    },
    handleSuccessCheckInInventory() {
      this.selectedItems = [];
      this.$refs.selectCatalogItemsGrid.deselectItems();
      this.$refs.nav.showSuccess("Checked In Inventory Successfully!");
    },
    onReasonCodeChanged(data) {
      if (data !== undefined) {
        this.reasonCodeId = data;
      } else {
        this.reasonCodeId = null;
      }
    },
    clearGridState() {
      this.$refs.selectCatalogItemsGrid.clearGrid();
    },
    selectedItemChange(updatedItem, index) {
      this.selectedItems[index].quantity = updatedItem.quantity;
      this.selectedItems[index].roundedBoxQuantity = updatedItem.roundedBoxQuantity;
    }
  }
};
</script>

<style scoped>
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}
.tempSearchContainer {
  padding-top: 10px;
  padding-bottom: 60px;
}
.searchIcon {
  font-size: 150px;
}
.checkInButton {
  width: 100px;
}
.searchError,
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
.toolbar {
  margin-top: 0;
  margin-left: 1px;
  margin-right: 1px;
}
</style>