<template>
  <modal-dialog
      ref="dialog"
      title="Create New Note"
      width="500"
      :visible="dialogVisible"
      :submitEnabled="true"
      :loading="loading"
      submitLabel="Add Note"
      cancelLabel="Cancel"
      @submitClicked="onSaveNoteButtonClick"
      @cancelClicked="onCancelClicked"
  >
    <v-form ref="form">
      <v-container>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-text-field
                disabled
                label="User"
                v-model="userName"
                id="add-note-user-name"
            />
          </v-col>
          <br/>
          <v-col cols="12">
            <v-textarea
                ref="note"
                v-model="note"
                rows="3"
                label="Note"
                placeholder=" "
                persistent-placeholder
                counter="2048"
                :rules="[rules.required, rules.max2048]"
                id="add-note-modal"
                autofocus
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import Rules from "@/assets/rules.js";

const formDefaults = {
  note: null,
  dialogVisible: false,
  loading: false
};
export default {
  components: {
    ModalDialog
  },
  props: {
    userName: String
  },

  data() {
    return Object.assign(
        {
          error: null,
          rules: {
            required: value => !!value || "Required",
            max2048: value => {
              return Rules.maxValue(value, 2048);
            }
          }
        },
        formDefaults
    );
  },

  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(this.$refs.note.focus);
      }
    }
  },

  methods: {
    reset() {
      this.$refs.form.resetValidation();
      this.note = null;
      this.setLoading(false);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onSaveNoteButtonClick() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.setLoading(true);
        this.$emit('saveNoteButtonClicked', this.note);
      }
    },
    onCancelClicked() {
      this.reset();
      this.dialogVisible = false;
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    }
  }
};
</script>
