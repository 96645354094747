<template>
  <div class="toolbar">
    <div v-if="showGridStateChange" class="center-left">
      <toggle-button-with-two-states
          :checkInModeEnabled="checkInModeEnabled"
          @selectionChanged="onGridStateChange"
      />
    </div>
    <v-divider vertical class="toolbar-spacing"/>
    <!-- clear grid -->
    <div
        id="clear-grid-filters"
        class="toolbar-spacing"
        title="Reset Grid Filters"
        @click="toggleResetGridDialog"
        @mouseenter="ifRevertTool = true"
        @mouseleave="ifRevertTool = false"
    >
      <dx-button icon="revert" class="toolbar-button-icon"/>
      <tool-tip :ifShow="ifRevertTool" tipText="Clear Grid Search"/>
    </div>
    <!-- choose columns -->
    <div
        v-if="displayColumnChooser"
        id="column-chooser"
        class="toolbar-spacing"
        title="Show/Hide Columns"
        @click="showColumnChooser"
        @mouseenter="ifColumnTool = true"
        @mouseleave="ifColumnTool = false"
    >
      <dx-button icon="column-chooser" class="toolbar-button-icon"/>
      <tool-tip :ifShow="ifColumnTool" tipText="Choose Columns"/>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/common/dialogContainers/ToolTip.vue";
import {DxButton} from "devextreme-vue/ui/button";
import ToggleButtonWithTwoStates from "@/components/common/templates/ToggleButtonWithTwoStates.vue";

export default {
  components: {
    ToggleButtonWithTwoStates,
    ToolTip,
    DxButton
  },

  props: {
    displayColumnChooser: Boolean,
    showGridStateChange: {
      type: Boolean,
      required: false,
      default: false
    },
    checkInModeEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      ifRevertTool: false,
      ifColumnTool: false,
      ifToolbarTool: false,
    };
  },

  methods: {
    toggleResetGridDialog() {
      this.$emit('onToggleResetGridDialog');
    },
    showColumnChooser() {
      this.$emit('onShowColumnChooser');
    },
    onGridStateChange() {
      this.$emit('gridStateChange');
    },
  },
};
</script>

<style scoped>
.toolbar {
  display: flex;
  justify-content: flex-end;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.toolbar-spacing {
  display: flex;
  margin: 0 10px;
}

.center-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
