<template>
  <div>
    <v-card flat v-if="chargebackItems && chargebackItems.length > 0">
      <section-divider title="CHARGEBACKS" />
      <headers />
      <panel
        :job="job"
        :line-item="item"
        v-for="item in chargebackItems"
        :key="item.referenceId"
        @itemDeleted="onItemDeleted"
      />
    </v-card>
  </div>
</template>

<script>
import Constants from "@/assets/constants.js";
import Currency from "currency.js";
import Headers from "@/components/jobs/chargebackLineItems/ChargebackLineItemsHeaders.vue";
import Panel from "@/components/jobs/chargebackLineItems/ChargebackLineItemsPanel.vue";
import SectionDivider from "@/components/jobs/SectionDivider.vue";
import CreateReferenceIds from "@/assets/referenceIds";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    initialItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return { chargebackItems: [] };
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.$data.chargebackItems = value;
      },
    },
  },

  methods: {
    // Add new chargeback line item.
    onAddChargebackItem: function (payload) {
      let item = {};
      let component = this;
      let refid = CreateReferenceIds.getTempReferenceId();
      let jobLineItem = {
        reason: payload.reason,
        referenceId: refid,
        type: Constants.jobType.chargeback,
        quantity: 1,
        price: Currency(0.0).value,
        materialCost: Currency(payload.materialCost).value,
        laborCost: Currency(payload.laborCost).value,
        customerPayment: Currency(payload.customerPayment).value,
        thirdPartyVendorId: payload.thirdPartyVendorId,
        thirdPartyVendor: payload.thirdPartyVendor,
        thirdPartyPayment: Currency(payload.thirdPartyPayment).value,
        installerId: payload.installerId,
        installerLabel: payload.installerLabel,
        installerChargeAmount: Currency(payload.installerChargeAmount).value,
        vendorId: payload.vendorId,
        vendorLabel: payload.vendorLabel,
        vendorChargeAmount: Currency(payload.vendorChargeAmount).value,
        fiftyFloorAmount: Currency(payload.fiftyFloorAmount).value,
        status: null,
        comment: null,
        newLineItem: true,
        overbill: Currency(payload.chargebackProductsDelta).value
      };
      item.jobLineItem = jobLineItem;
      component.chargebackItems.push(item);
      this.$emit("itemsUpdated", this.$data.chargebackItems);
      this.$emit("amountsUpdated");
    },
    onItemDeleted: function (referenceId) {
      this.$data.chargebackItems = this.$data.chargebackItems.filter(
        (current) => current.jobLineItem.referenceId !== referenceId
      );
      this.$emit("itemsUpdated", this.$data.chargebackItems);
      this.$emit("amountsUpdated");
      this.markDirty();
    },
    onCustomerPaymentUpdated: function () {
      this.$emit("amountsUpdated");
      this.markDirty();
    },
    onThirdPartyPaymentUpdated: function () {
      this.$emit("amountsUpdated");
      this.markDirty();
    },
    onInstallerChargeAmountUpdated: function () {
      this.$emit("amountsUpdated");
      this.markDirty();
    },
    onVendorChargeAmountUpdated: function () {
      this.$emit("amountsUpdated");
      this.markDirty();
    },
    markDirty: function () {
      this.$emit("dataUpdated");
    },
    requestRefresh: function () {
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
