<template>
  <dx-data-grid
      ref="transferOptionsGrid"
      :data-source="transferOptions"
      :show-borders="true"
      :hover-state-enabled="true"
      :load-panel="{ enabled: false }"
      :column-min-width="140"
  >
    <dx-filter-row :visible="true"/>
    <dx-column
        data-field="productLineItemRefId"
        caption="Product Ref ID"
        data-type="string"
    />
    <dx-column
        data-field="style"
        caption="Style"
        data-type="string"
    />
    <dx-column
        data-field="color"
        caption="Color"
        data-type="string"
    />
    <dx-column
        data-field="internalSku"
        caption="SKU"
        data-type="string"
    />
    <dx-column
        caption="Branches"
        alignment="left"
        :cell-template="branchesCellTemplate"
    />
    <dx-column
        caption="Action"
        alignment="center"
        cellTemplate="actionTemplate"
        width="420"
    />
    <template v-slot:actionTemplate="cellData">
      <transfer-action-cell-template :cell-data="cellData" :show-validation-result="showValidationResult"/>
    </template>
  </dx-data-grid>
</template>

<script>
import {
  DxColumn,
  DxColumnFixing,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging
} from "devextreme-vue/data-grid";
import ModalDialog from "@/components/common/ModalDialog.vue";
import TransferActionCellTemplate from "@/components/common/grid/cellTemplates/TransferActionCellTemplate.vue";

export default {
  components: {
    TransferActionCellTemplate,
    ModalDialog,
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxHeaderFilter,
    DxColumnFixing,
    DxPaging,
    DxPager
  },
  props: {
    transferOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showValidationResult: false
    };
  },
  methods: {
    branchesCellTemplate(cellElement, cellInfo) {
      const content = cellInfo.data.branchesNames.length ? cellInfo.data.branchesNames.join(", ") : "Not Available in Any Branch";
      cellElement.innerHTML = `<div class="transfer-options-branches-cell">${content}</div>`;
    },
    isValid() {
      this.showValidationResult = true;
      return this.$refs.transferOptionsGrid.instance.getVisibleRows().every(row => row.data.isTransferAction || row.data.isCreatePOAction);
    },
    resetValidation() {
      this.showValidationResult = false;
    }
  },
};
</script>

<style>
.transfer-options-branches-cell {
  white-space: normal;
  word-wrap: break-word;
}
</style>