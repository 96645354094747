import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import Router from "../router";
import {captureMessage} from "@sentry/browser";

/**
 * Backend version.
 */
export class Version {
  versionIdentifier: string;
  buildTimestamp: string;
  gitRevision: string;
  gitRevisionAbbrev: string;
  dockerImageTag: string;
  version: string;

  constructor(data?: any) {
    data = data || {};
    this.versionIdentifier = data.versionIdentifier;
    this.buildTimestamp = data.buildTimestamp;
    this.gitRevision = data.gitRevision;
    this.gitRevisionAbbrev = data.gitRevisionAbbrev;
    this.dockerImageTag = data.dockerImageTag;
    this.version = data.version;
  }
}

/**
 * REST interface provided for version.
 */
export interface VersionRestApi {
  getBackendVersion(): AxiosPromise<Version>;
}

/**
 * REST implementation provided for version.
 */
class Implementation implements VersionRestApi {
  getBackendVersion(): AxiosPromise<Version> {
    return Axios.nonJwtAxiosInstance().get<Version>("/version");
  }
}

const Versions = {
  getRestApi(): VersionRestApi {
    return new Implementation();
  }
};

export function checkVersion() {
  Versions.getRestApi()
      .getBackendVersion()
      .then((response) => {
        const frontendVersion = getFrontendVersion();
        const backendVersion = response.data.version;
        captureMessage(`Logged in user. Frontend version: ${frontendVersion}. Backend version: ${backendVersion}`);
        if (frontendVersion != backendVersion) {
          console.log(`Frontend version: ${frontendVersion}. Backend version: ${backendVersion}`);
          Router.push("/refresh");
          return;
        }
      })
}

export function getLocalServerHash(): string {
  const localServerHash = window.localStorage.getItem("fifty-floor-server-hash");
  return localServerHash != null ? localServerHash : "";
}

export function getFrontendVersion(): string {
  return JSON.stringify(require('./../../package.json').version).replaceAll('"', '');
}

export default Versions;
