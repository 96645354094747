<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td width="60%" class="text-truncate">
          <div class="text-truncate">
            <strong>{{ lineItem.StyleName }}</strong>
          </div>
          <div class="text-truncate">Internal SKU: {{ lineItem.InternalSku }}</div>
          <div class="text-truncate">Color: {{ lineItem.Color }}</div>
          <div class="text-truncate">Vendor: {{ lineItem.VendorName }}</div>
          <div class="text-truncate" v-if="lineItem.BoxQuantity">Box Quantity: {{ lineItem.BoxQuantity }}</div>
          <div class="text-truncate">Unit of Measure: {{ lineItem.UnitOfMeasure }}</div>
          <div class="text-truncate">Vendor SKU: {{ lineItem.VendorSku }}</div>
          <div class="text-truncate">
            Vendor Cost: ${{
              lineItem.ActiveVendorCost
                  ? lineItem.ActiveVendorCost.toFixed(2)
                  : null
            }}
          </div>
        </td>

        <td width="20%" class="pt-0">
          <v-text-field
              v-if="!readonly"
              type="number"
              v-model="needed"
              placeholder="0"
              class="short-input"
              :readonly="readonly"
          />
        </td>
        <td width="20%" class="pt-0">
          <v-text-field
              type="number"
              v-model="quantity"
              placeholder="0"
              class="short-input"
              disabled
          />
        </td>
        <td width="25%" class="pt-0">
          <v-text-field
              v-model="totalPrice"
              placeholder="0.00"
              prefix="$"
              class="short-input"
              disabled
          />
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";

export default {
  components: {},

  props: {
    lineItem: {
      type: Object,
      required: true
    },
    readonly: Boolean,
    index: Number,
    step: Number
  },

  data() {
    return {
      needed: null,
      quantity: null,
      totalPrice: null
    };
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.quantity = updated.quantity;
        this.totalPrice = this.computeTotalPrice(updated);
        if (this.quantity) {
          this.$emit('validQuantityEntered');
        }
      },
      deep: true
    },
    needed(value) {
      if (value) {
        let computedQuantity = this.computeQuantity(value);
        this.quantity = computedQuantity;
        this.totalPrice = this.lineItem.ActiveVendorCost
          ? CurrencyFormatter.formatCurrency(this.lineItem.ActiveVendorCost * computedQuantity)
          : CurrencyFormatter.formatCurrency(this.lineItem.ActiveCostCost * computedQuantity);
        this.$emit('onComputeQuantity', computedQuantity, this.index);
      }
    }
  },

  methods: {
    computeQuantity(needed) {
      let boxQuantity = this.lineItem.BoxQuantity;
      if (boxQuantity) {
        let mod = (needed % boxQuantity).toFixed(4);
        if (mod > 0) {
          let mult = (needed - mod) / boxQuantity;
          let next = boxQuantity * (mult + 1);
          let fixedNum = next.toFixed(4);
          return fixedNum;
        }
        return needed;
      } else {
        return needed;
      }
    },
    computeTotalPrice(updated) {
      return CurrencyFormatter.formatCurrency(updated.totalPrice)
        || CurrencyFormatter.formatCurrency(updated.BoxQuantity * updated.quantity);
    }
  }
};
</script>

<style scoped>
.line-items {
    text-align: left;
    color: #666;
    width: 100%;
    table-layout: fixed;
    padding-bottom: 10px;
}
</style>
