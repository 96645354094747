import store from "@/store";
import Roles from "@/assets/roles";

const UserHelper = {
    hasAccessToBranch(branchReferenceId) {
        const user = store.state.loggedInUser;

        if (user === undefined || user === null) {
            return false;
        }

        if (branchReferenceId && Roles.isRoleBranchRole(user.roleId)) {
            return user.branchReferenceIds && user.branchReferenceIds.includes(branchReferenceId);
        }

        return Roles.isSuperAdminOrAdminUser();
    },
}

export default UserHelper;
