<template>
  <div class="text-center fill-height">
    <v-bottom-sheet
        v-model="sheet"
        persistent
    >
      <template v-slot:activator="{ on }">
        <div class="user-notifications mt-3 pr-6">
          <v-btn
              :class="disabledNotificationIconClass"
              v-bind="on"
              stacked
              light
              icon
              @click="show"
          >
            <v-badge :content="messageCounter" :color="badgeColor">
              <v-icon lass="pt-1" color="#69b">mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </div>
      </template>

      <div class="full-block">
        <v-container fluid class="container-header">
          <v-row>
            <v-col md="1" class="text-left ma-0">
              <v-icon color="white" light medium>
                {{ userNotificationIcon }}
              </v-icon>
            </v-col>
            <v-col class="text-right pa-0 mt-1 mr-1">
              <v-btn
                  text
                  dark
                  color="white"
                  @click="closeBottomBarClicked"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <notifications-table
          :showHeaders="true"
          :userNotifications="messageStack"
          @markAsRead="markAsRead"
      />

    </v-bottom-sheet>
  </div>
</template>

<script>

import NotificationsTable from "./NotificationsTable.vue";
import NotificationsHelper from "../../../assets/notifications/notificationsHelper";

export default {
  components: {
    NotificationsTable
  },

  props: {
    userNotificationIcon: Object | null,
  },

  data() {
    return {
      sheet: false,
      messageStack: [],
    }
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setSavedNotifications') {
        this.displayBar(state.savedNotifications);
      } else if (mutation.type === 'setOngoingNotifications') {
        this.show();
      }
    });
  },

  computed: {
    messageCounter() {
      return NotificationsHelper.getUnreadMessageCounterFromStore(this.$store);
    },
    disabledNotificationIconClass() {
      return this.messageStack.length <= 0 ? 'text-none' : 'no-message-custom-class';
    },
    badgeColor() {
      let hasUnreadMessages = [...this.messageStack, ...this.getAllNotifications()].some(n => n.read === false);
      return hasUnreadMessages ? 'rgba(102,153,187,0.66)' : 'rgba(255,255,255,0.25)';
    },
  },

  methods: {
    displayBar(savedNotifications) {
      this.messageStack = [];
      savedNotifications.forEach(un => this.addToNotificationStack(un));
      this.sheet = true;
    },
    show() {
      let allNotifications = this.getAllNotifications();
      if (allNotifications) {
        this.messageStack = [];
        allNotifications.forEach(un => this.addToNotificationStack(un));
      }
      this.sheet = true;
    },
    closeBottomBarClicked() {
      this.sheet = !this.sheet;
      this.$emit('notificationBarClosed');
    },
    addToNotificationStack(notification) {
      this.messageStack.push(notification); // LIFO
    },
    markAsRead(index) {
      this.messageStack[index].read = true;
      this.$store.commit('setSavedNotifications', this.messageStack);
    },
    getAllNotifications() {
      return Array.from(this.$store.getters.getSavedNotifications);
    },
  }
};
</script>

<style scoped>
.full-block {
  border-top: 4px solid #b0cdde;
  border-right: 4px solid #b0cdde;
  border-left: 4px solid #b0cdde;
  border-bottom: 2px solid #b0cdde;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.container-header {
  height: 50px;
  background-color: #6699bb;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom: 2px solid #6699bb;
}
.user-notifications {
  border-right: 1px solid #eee;
}
</style>
