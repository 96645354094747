<template>
  <v-form ref="reasonCodeForm" v-model="valid">
    <v-card flat>
      <v-container class="pa-4 pb-4">
        <v-row>
          <v-col md="12">
            <close-transfer-request-reason-code-dropdown
                ref="reasonCodeDropDown"
                :isCloseTransferRequestForm="isCloseTransferRequestForm"
                @select="optionSelected"
            />
          </v-col>
          <v-col md="12" class=" mt-0 pt-0 pr-6 pl-6">
            <v-textarea
                :auto-grow="true"
                v-model="comment"
                label="Comment"
                placeholder=""
                persistent-placeholder
                :rules="[rules.max950]"
                counter="950"
                :maxlength="950"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import CloseTransferRequestReasonCodeDropdown from "./CloseTransferRequestReasonCodeDropdown.vue";
import Rules from "../../../assets/rules";

const formDefaults = {};

export default {
  components: {
    CloseTransferRequestReasonCodeDropdown
  },

  props: {
    isCloseTransferRequestForm: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  watch: {
    comment: 'formWatcher',
    selectedReasonCode: 'formWatcher',
  },

  data() {
    return {
      valid: true,
      comment: null,
      selectedReasonCode: null,
      rules: {
        max950: value => {
          return Rules.maxValue(value, 950);
        }
      }
    }
  },

  methods: {
    formWatcher() {
      let reasonPickedUp = this.selectedReasonCode;
      this.$emit('changeSubmitState', !reasonPickedUp);
    },
    validate() {
      let validDropDown = this.$refs.reasonCodeDropDown.validate();
      let validComment = this.$refs.reasonCodeForm.validate();
      return !(!validDropDown || !validComment);
    },
    loadDefaults() {
      this.$refs.reasonCodeDropDown.resetCloseTransferRequestReasonCodeForm();
      this.comment = null;
      this.selectedReasonCode = null;
    },
    optionSelected(val) {
      this.selectedReasonCode = val;
      this.$emit('select', val);
    },
    getComment() {
      return this.comment;
    }
  }
};
</script>
