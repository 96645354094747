<template>
  <navigation-view
      ref="nav"
      page-title="Sales Representatives Days Off"
      nav-section="admin"
  >
    <template v-slot:content>
      <days-off-form/>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import DaysOffForm from "./DaysOffForm";

export default {
  components: {
    DaysOffForm,
    NavigationView,
  }

};
</script>
