import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import Constants from "../assets/constants";
import * as AxiosUtils from "./util/axiosUtils";

/**
 * Holds status information for file uploads.
 */
export interface FileUploadStatus {
  name: string;
  url: string;
  error: string;
}

/**
 * Files.
 */
export class File {
  id: number;
  fileName: string;
  mimeType: string;
  url: string;
  createdAt: string;
  documentTypeId: number;
  userName: string;

  constructor(
    id: number,
    fileName: string,
    mimeType: string,
    url: string,
    createdAt: string,
    documentTypeId: number,
    userName: string
  ) {
    this.id = id;
    this.fileName = fileName;
    this.mimeType = mimeType;
    this.url = url;
    this.createdAt = createdAt;
    this.documentTypeId = documentTypeId;
    this.userName = userName;
  }
}

export interface DocumentType {
  name: string;
  id: number;
  isHidden: boolean;
  isDeletable: boolean;
  isImmutableWhenIosSource: boolean;
}

/**
 * REST interface provided for interactions with Files.
 */
export interface FileRestApi {
  deleteFile(id: number): AxiosPromise<File>;
  uploadFileForLead(
    leadReferenceId: string,
    files: File[],
    documentTypes: number[],
    installTypes: number[],
    onUploadProgress?: any
  ): AxiosPromise<FileUploadStatus[]>;
  uploadFileForJob(
    jobId: number,
    files: File[],
    documentTypes: number[],
    installTypes: number[],
    selectedLabors: number[],
    wsSessionId?: string,
    onUploadProgress?: any
  ): AxiosPromise<FileUploadStatus[]>;
  uploadFileForInstaller(
    installerReferenceId: string,
    files: File[],
    documentTypes: number[],
    onUploadProgress?: any
  ): AxiosPromise<FileUploadStatus[]>;
  uploadFileForPurchaseOrder(
    purchaseOrderNumber: string,
    files: File[],
    documentTypes: number[],
    installTypes: number[],
    onUploadProgress?: any
  ): AxiosPromise<FileUploadStatus[]>;
  getAllDocumentTypes(category: string): AxiosPromise<DocumentType []>;
  updateFileDocumentType(fileId: number,
                         documentTypeId: number,
                         installTypeId: number,
                         wsSessionId: string
  ): AxiosPromise<void>;getAllSpecificFilesByProvidedReferenceId(referenceIdBasedOnParentType: string): AxiosPromise<File>;
  uploadFilesForTransferRequest(
      transferRequestNumber: string,
      files: File[],
      documentTypes: number[],
      onUploadProgress?: any
  ): AxiosPromise<FileUploadStatus[]>;
  uploadShipmentFiles(shipmentNumber: string, files: File[], documentTypes: number[]): AxiosPromise<FileUploadStatus[]>;
  uploadSalesRepresentativeFiles(shipmentNumber: string, files: File[], documentTypes: number[]): AxiosPromise<FileUploadStatus[]>;
}

/**
 * REST implementation provided for interactions with Files.
 */
class Implementation implements FileRestApi {
  deleteFile(fileId: number): AxiosPromise<File> {
    return Axios.axiosInstance().delete(`/files/${fileId}`);
  }
  uploadFileForLead(
      leadReferenceId: string,
      files: File[],
      documentTypes: number[],
      installTypes: number[]
  ): AxiosPromise<FileUploadStatus[]> {
    return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>
    (`/files/lead/${leadReferenceId}`, files, {
      headers: {
        "Document-Types": `${documentTypes}`,
        "Install-Types": `${installTypes}`
      }
    });
  }
  uploadFileForJob(
      jobId: number,
      files: File[],
      documentTypes: number[],
      installTypes: number[],
      selectedLabors: number[],
      wsSessionId: string
  ): AxiosPromise<FileUploadStatus[]> {

    const headers = Object.assign(
        {
          "Content-type": "multipart/form-data",
          "Document-Types": `${documentTypes}`,
          "Install-Types": `${installTypes}`,
          "Selected-Labors": `${selectedLabors}`
        },
        AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId).headers
    );

    return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>(`/files/job/${jobId}`, files, {
      headers: headers
    });
  }
  uploadFileForInstaller(
    installerReferenceId: string,
    files: File[],
    documentTypes: number[]
  ): AxiosPromise<FileUploadStatus[]> {
    return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>
    (`/files/installer/${installerReferenceId}`, files, {
      headers: {
        "Content-type": "multipart/form-data",
        "Document-Types": `${documentTypes}`,
        "Source": "web"
      }
    });
  }
  uploadFileForPurchaseOrder(
    purchaseOrderNumber: string,
    files: File[],
    documentTypes: number[],
    installTypes: number[],
  ): AxiosPromise<FileUploadStatus[]> {
    return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>
    (`/files/purchaseorder/${purchaseOrderNumber}`, files, {
      headers: {
        "Content-type": "multipart/form-data",
        "Document-Types": `${documentTypes}`,
        "Install-Types": `${installTypes}`,
        "Source": "web"
      }
    });
  }
  updateFileDocumentType(
    fileId: number,
    documentTypeId: number,
    installTypeId: number,
    wsSessionId: string
  ): AxiosPromise<void> {
    const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);

    return Axios.axiosInstance().patch<void>(`/files/${fileId}/type`, {
      documentTypeId: documentTypeId,
      installTypeId: installTypeId
    }, config);
  }
  getAllDocumentTypes(category: string): AxiosPromise<DocumentType []> {
    return Axios.axiosInstance().get<DocumentType []>(`/files/types`,
        {
          params: { "category": category }
        });
  }
  getAllSpecificFilesByProvidedReferenceId(referenceIdBasedOnParentType: string): AxiosPromise<File> {
    return Axios.axiosInstance().get<File>(`/files/specific/${referenceIdBasedOnParentType}`);
  }
  uploadFilesForTransferRequest(transferRequestNumber: string, files: File[], documentTypes: number[]): AxiosPromise<FileUploadStatus[]> {
    return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>
    (`/files/transfer-request/${transferRequestNumber}`, files, {
      headers: {
        "Content-type": "multipart/form-data",
        "Document-Types": `${documentTypes}`,
      }
    });
  }
  //TODO: make 1 uploader func and 1 method
  uploadShipmentFiles(shipmentNumber: string, files: File[], documentTypes: number[]): AxiosPromise<FileUploadStatus[]> {
    return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>
    (`/files/shipment/${shipmentNumber}`, files, {
      headers: {
        "Content-type": "multipart/form-data",
        "Document-Types": `${documentTypes}`,
      }
    });
  }
   uploadSalesRepresentativeFiles(salesRepReferenceId: string, files: File[], documentTypes: number[]): AxiosPromise<FileUploadStatus[]> {
        return Axios.axiosInstance(Constants.FILE_UPLOAD_TIMEOUT).post<FileUploadStatus[]>
        (`/files/sales-representative/${salesRepReferenceId}`, files, {
        headers: {
            "Content-type": "multipart/form-data",
            "Document-Types": `${documentTypes}`,
        }
        });
    }
}

const Files = {
  getRestApi(): FileRestApi {
    return new Implementation();
  }
};

export default Files;
