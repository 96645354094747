<template>
  <modal-dialog
      title="User Profile"
      :width="900"
      :hideButtons="true"
      :visible="visible"
      @cancelClicked="cancelClick"
      @submitClicked="submitClick"
  >
    <div v-if="salesRep" class="pt-12 pr-12 pl-12">
      <sales-rep-read-only
          :salesRep="salesRep"
          :referenceId="salesRep.referenceId"
      />
    </div>
    <div class="textTitle">
      Change Password
    </div>
    <div id="change-password-modal" class="ma-2">
      <change-password-form
          ref="form"
          :cancelButton="true"
          :changePassword="!this.overridePassword"
          :resetPassword="false"
          :overridePassword="this.overridePassword"
          :email="this.email"
          @submitClick="submitClick"
          @cancelClick="cancelClick"
      />
    </div>
  </modal-dialog>
</template>

<script>
import ChangePasswordForm from "../passwordManagement/ChangePasswordForm";
import ModalDialog from "../common/ModalDialog.vue";
import Users from "@/rest/users";
import SalesRepReadOnly from "@/components/salesRepresentatives/SalesRepresentativeReadOnly.vue";
import SalesRepresentative from "@/rest/salesRepresentatives";

export default {
  components: {
    ChangePasswordForm,
    ModalDialog,
    SalesRepReadOnly
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: null
    },
    overridePassword: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      salesRep: {},
      referenceId: null
    };
  },

  watch: {
    visible(value) {
      if (value) {
        this.getSalesRepByUserId();
      }
    }
  },

  methods: {
    async getSalesRepByUserId() {
      try {
        const response = await SalesRepresentative.getRestApi().getSalesRepresentativeByUserId(
            this.$store.state.loggedInUser.id
        );
        this.setSalesRep(response.data);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setSalesRep(data) {
      this.$data.salesRep = data;
      this.$data.referenceId = data.referenceId;
    },
    async submitUserPasswordChange(payload) {
      // self password change by user
      try {
        const user = {
          email: payload.email,
          currentPassword: payload.currentPassword,
          newPassword: payload.newPassword
        };
        await Users.getRestApi().changePassword(user);
        this.$emit("submitClick");
      } catch (error) {
        this.$root.$emit("mainNavShowError", error);
      }
    },
    async submitAdminPasswordOverride(component, payload) {
      // admin password change on behalf of user
      try {
        await Users.getRestApi().overridePassword(
            payload.email,
            payload.newPassword
        );
        this.$root.$emit("closeUserEditDrawer");
        this.$emit("submitClick");
      } catch (error) {
        this.$root.$emit("mainNavShowError", error);
      }
    },
    submitClick() {
      const component = this;
      const payload = this.$refs.form.buildPayload();
      if (this.overridePassword) {
        this.submitAdminPasswordOverride(component, payload);
      } else this.submitUserPasswordChange(payload);
    },
    cancelClick() {
      this.$root.$emit("closeUserEditDrawer");
      this.$emit("cancelClick");
    }
  }
};
</script>

<style scoped>
.textTitle {
  width: fit-content;
  font-size: 22px;
  align-content: start;
  justify-content: start;
  color: #69b;
  margin: 20px;
}
</style>
