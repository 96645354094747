<template>
  <div>
    <inline-grid
      :data-source="dataSource"
      :show-borders="true"
      :hoverStateEnabled="true"
      :columnAutoWidth="true"
      :showPager="true"
      :pageSize="5"
      ref="inlineGrid"
      @rowSelected="onRowSelected"
    >
      <dx-filter-row :visible="true" />
      <dx-header-filter :visible="true" />
      <dx-column-fixing :enabled="true" />
      <dx-scrolling :useNative="true" showScrollbarMode="Always" />
      <dx-column
        data-field="InternalSku"
        :fixed="true"
        caption="Internal SKU"
        :allowHeaderFiltering="false"
      />
      <dx-column data-field="VendorName" :fixed="true" caption="Vendor" data-type="string"/>
      <dx-column
        data-field="ProductType"
        caption="Product Type"
        data-type="string"
      />
      <dx-column data-field="ProductStyle" caption="Product Style" data-type="string"/>
      <dx-column
        data-field="ProductColor"
        caption="Color"
        :allowHeaderFiltering="false"
        data-type="string"
      />
      <dx-column
        data-field="VendorSku"
        caption="Vendor SKU"
        :allowHeaderFiltering="false"
        data-type="string"
      />
      <dx-column
        data-field="Available"
        caption="Available"
        :allowHeaderFiltering="false"
      />
      <dx-column
        data-field="RollNumber"
        caption="Roll Number"
        :allowHeaderFiltering="false"
      />
      <dx-column
        data-field="DyeLot"
        caption="Dye Lot"
        :allowHeaderFiltering="false"
        data-type="number"
      />
      <dx-column
        data-field="UnitOfMeasure"
        caption="Unit of Measure"
        :allowHeaderFiltering="false"
        data-type="string"
      />
      <dx-column data-field="BinNumber" caption="Bin Number" data-type="string"/>
      <dx-column
        data-field="OnHand"
        caption="On Hand"
        :allowHeaderFiltering="false"
        data-type="number"
      />
      <dx-column
        data-field="BoxQuantity"
        caption="Box Quantity"
        :allowHeaderFiltering="false"
        data-type="number"
      />
      <dx-column
        data-field="SupplierCost"
        caption="Vendor Cost"
        :format="format"
        :allowHeaderFiltering="false"
      />
      <dx-column
        data-field="ItemValue"
        caption="Total Value"
        :format="format"
        :allowHeaderFiltering="false"
      />
      <dx-column
        data-field="CustomerName"
        :minWidth="200"
        caption="Customer Sidemark"
        :allowHeaderFiltering="false"
        data-type="string"
      />
    </inline-grid>
  </div>
</template>

<script>
import {DxColumn, DxColumnFixing, DxFilterRow, DxHeaderFilter, DxScrolling} from "devextreme-vue/ui/data-grid";
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter.js";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    DxScrolling,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    InlineGrid,
    DxColumn
  },

  props: ["branchId"],

  watch: {
    branchId: function(value) {
      this.$refs.inlineGrid.filter([
        ["Available", ">", 0],
        "and",
        ["OnHand", ">", 0],
        "and",
        ["BranchReferenceId", "=", value]
      ]);
    }
  },

  data() {
    return {
      allowBranchFilter: false,
      branchesFilter: [],
      pageSizes: [10, 20, 50],
      format: {
        type: "currency",
        precision: 2
      },
      dataSource: {
        store: ODataContext['InventoryItems'],
        select: [
          "Id",
          "VendorSku",
          "VendorName",
          "InternalSku",
          "ProductType",
          "ProductStyle",
          "ProductColor",
          "OnHand",
          "Available",
          "BranchName",
          "RollNumber",
          "DyeLot",
          "UnitOfMeasure",
          "BinNumber",
          "BoxQuantity",
          "BranchReferenceId",
          "SupplierCost",
          "ItemValue"
        ]
      }
    };
  },

  created() {
    this.getFilters();
  },

  methods: {
    async getFilters() {
      try {
        const user = this.$store.state.loggedInUser;
        const branchesFilter = await CustomizeFilter.getBranchFiltersIfNotBranchUser(user);
        this.setFilters(branchesFilter);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setFilters(branchesFilter) {
      if (branchesFilter) {
        this.branchesFilter = branchesFilter;
        this.allowBranchFilter = true;
      }
    },
    onRowSelected(data) {
      if (data) {
        this.$emit("itemChosen", data);
      }
    },
    refreshInventoryItemsGrid() {
      this.$refs.inlineGrid.refresh();
    },
    clearFilterAndSort() {
      this.$refs.inlineGrid.clearSortingAndFiltering();
    },
  }
};
</script>
