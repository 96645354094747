<template>
  <modal-dialog
    ref="dialog"
    title="Confirm Invoice"
    width="550"
    :visible="dialogVisible"
    :submitEnabled="true"
    submitLabel="Yes"
    cancelLabel="No"
    @submitClicked="onYesClicked"
    @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <slot>
          <div>
            Do you really want to invoice? This cannot be undone.
          </div>
        </slot>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

export default {
  data: () => ({
    dialogVisible: false,
    error: null
  }),

  components: {
    ModalDialog
  },

  methods: {
    reset() {
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onYesClicked() {
      this.dialogVisible = false;
      this.$emit("invoiceConfirmed");
    },
    onCancelClicked() {
      this.dialogVisible = false;
    }
  }
};
</script>
