<template>
  <drawer-form
      ref="form"
      title="Update Branch"
      submitLabel="Save Branch"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <branch-form
        ref="branchFormBody"
        :isCreateForm="false"
    />
  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm.vue";
import BranchForm from "./BranchForm.vue";
import Branches from "../../rest/branches";

export default {
  components: {
    BranchForm,
    DrawerForm
  },

  props: ["branch"],

  data() {
    return {
      branchData: null,
      inProgress: false
    };
  },

  watch: {
    branch(newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      this.branchData = Object.assign(this.branch);
      this.$refs.branchFormBody.resetFormDefaults(this.branchData);
    },
    onCancelClicked() {
      this.$refs.form.resetValidation();
      this.$refs.branchFormBody.loadForm(this.branch);
      this.$emit('cancelClicked');
    },
    async onSubmitClicked() {
      try {
        let valid = this.$refs.form.validate();
        if (valid) {
          this.inProgress = true;
          const payload = this.$refs.branchFormBody.buildPayload();
          const data = await this.updateBranch(payload);
          this.$refs.form.resetValidation();
          this.$emit('updateSuccess', data);
        }
      } catch (error) {
        this.$emit('updateFailed', error);
      }
      this.inProgress = false;
    },
    async updateBranch(payload) {
      try {
        const response = await Branches.getRestApi().updateBranch(this.branchData.referenceId, payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
