const ValidationHelper = {
    ifEnteredQuantityIsPositiveNumber(items) {
        let pass = true;
        items.forEach(item => {
            if (item.quantity <= 0) {
                pass = false;
            }
        });
        return pass;
    },
    isValidPhoneNumber(phoneNumber) {
        if (phoneNumber.length !== 10) {
            return false;
        }
        return /^\d{10}$/.test(phoneNumber);
    },
}


export default ValidationHelper;
