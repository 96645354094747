<template>
  <modal-dialog
      ref="dialog"
      title="Update Crew"
      width="600"
      submitLabel="Save"
      cancelLabel="Cancel"
      :visible="dialogVisible"
      :submitEnabled="true"
      :loading="loading"
      @submitClicked="onSaveCrewButtonClick"
      @cancelClicked="onCancelClicked"
  >
    <update-crews-form
        ref="updateCrewForm"
        :capabilities="capabilities"
        :branches="branches"
        :installer="installer"
        @emitError="emitError"
        @passwordOverridden="passwordOverridden"
        @resetMfaSuccess="onResetMfaSuccess"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import UpdateCrewsForm from "./UpdateCrewsForm.vue";

const formDefaults = {
  dialogVisible: false
};
export default {
  components: {
    ModalDialog,
    UpdateCrewsForm
  },
  props: {
    userName: String,
    capabilities: {
      type: Array,
      required: true
    },
    branches: {
      type: Array,
      required: true
    },
    installer: Object
  },

  data() {
    return Object.assign(
        {
          error: null,
          loading: false
        },
        formDefaults
    );
  },

  methods: {
    reset() {
      this.$refs.updateCrewForm.resetForm();
    },
    openUpdateCrewDialog() {
      this.dialogVisible = true;
      this.stopLoading();
    },
    closeUpdateCrewDialog() {
      this.dialogVisible = false;
      this.stopLoading();
    },
    emitError(error) {
      this.$emit('emitError', error);
    },
    passwordOverridden() {
      this.$emit('passwordOverriddenSuccessfully');
    },
    onSaveCrewButtonClick() {
      const valid = this.$refs.updateCrewForm.validate();
      if (valid) {
        this.loading = true;
        let payloadCrew = this.$refs.updateCrewForm.buildUpdateCrewPayload();
        this.$emit('updateCrewButtonClicked', payloadCrew);
      }
    },
    onCancelClicked() {
      this.reset();
      this.dialogVisible = false;
      this.stopLoading();
      this.$emit('cancelUpdateCrewFormClicked');
    },
    loadForm(payload) {
      this.$refs.updateCrewForm.loadUpdateCrewForm(payload);
    },
    stopLoading() {
      this.loading = false;
    },
    onResetMfaSuccess() {
      this.$emit('resetMfaSuccess');
    }
  }
};
</script>
