<template>
  <grid-view
      ref="nav"
      page-title="Leads"
      nav-section="leads"
      :data-source="dataSource"
      :allowFiltering="false"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      :overrideToolsEnabled="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="LeadStatus"
        caption="Status" data-type="string"
    />
    <dx-column
        data-field="CustomerFirstName"
        caption="First Name"
        data-type="string"
    />
    <dx-column
        data-field="CustomerLastName"
        caption="Last Name"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        data-type="string"
    />
    <dx-column
        data-field="AppointmentDate"
        caption="Appointment Date"
        :customize-text="customizeDate"
    />
    <dx-column
        data-field="AppointmentTime"
        caption="Appointment Time"
        format="mm:hh a"
        data-type="string"
    />
    <dx-column
        data-field="LeadInstallationCapabilities"
        caption="Installations"
        data-type="string"
    />
    <dx-column
        data-field="MarketName"
        caption="Market"
        data-type="string"
    />
    <dx-column
        data-field="LastNote"
        caption="Last Note"
        alignment="left"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        data-type="string"
    />
    <dx-column
        data-field="PromoCode"
        caption="Promo Code"
        data-type="string"
    />
  </grid-view>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import RoleRestrictor from "@/rest/odata";
import GridView from "@/views/salesRepresentativesHome/common/MobileAppGridView.vue";
import Format from "@/components/common/Format.js";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    DxColumn,
    GridView
  },
  data() {
    const config = {
      dataSource: {
        store: ODataContext['Leads'],
        select: [
          "Id",
          "CustomerFirstName",
          "CustomerLastName",
          "Address",
          "City",
          "AppointmentDate",
          "AppointmentTime",
          "LeadInstallationCapabilities",
          "LeadStatus",
          "MarketName",
          "MarketId",
          "MarketReferenceId",
          "ReferenceId",
          "LastNote",
          "LeadStatusId",
          "PromoCode"
        ]
      }
    };
    return RoleRestrictor.updateSalesRepConfig(this.$store.state.loggedInUser, config);
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/sales/leads/details/${data.ReferenceId}`);
    },
    displayError(error) {
      this.$refs.nav.showError(error);
    },
    customizeDate(cellInfo) {
      return cellInfo.valueText;
    }
  }
};
</script>
