<template>
  <div>
    <v-card flat v-for="item in selectedItems" :key="item.referenceId">
      <line-item
          :itemSummary="item"
          @deleteItem="onDeleteItem"
      >
        <po-inventory
            v-if="item.purchaseOrder"
            :poSummary="item.purchaseOrder"
        />
        <reservation-inventory
            v-else
            :resSummary="item.reservations"
        />
        <v-divider/>
      </line-item>
    </v-card>
  </div>
</template>

<script>
import LineItem from "../../components/inventoryManagement/JobInventoryCheckOutLineItem.vue";
import PoInventory from "../../components/inventoryManagement/JobInventoryCheckOutPO.vue";
import ReservationInventory from "../../components/inventoryManagement/JobInventoryCheckOutReservations.vue";

export default {
  components: {
    LineItem,
    PoInventory,
    ReservationInventory
  },

  props: {
    selectedItems: Array
  },

  methods: {
    onDeleteItem(lineItemReferenceId) {
      this.$emit('deleteItem', lineItemReferenceId);
    }
  }
};
</script>
