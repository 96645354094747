<template>
  <v-card flat>
    <div v-for="reservation in resSummary" :key="reservation.inventoryItem.id">
      <reservation-entry :reservation="reservation" />
    </div>
  </v-card>
</template>

<script>
import ReservationEntry from "@/components/inventoryManagement/JobInventoryCheckOutReservation.vue";

export default {
  components: { ReservationEntry },
  props: ["resSummary"],
  data() {
    return {};
  },

  methods: {}
};
</script>
