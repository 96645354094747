import Currency from "currency.js";

const CurrencyFormatter = {

    asCurrency(price) {
        return Currency(price);
    },

    zeroCurrency() {
        return Currency(0.0);
    },

    formatCurrency(price) {
        return Currency(price, {separator: ",", symbol: ''}).format();
    },

    formatCurrencyWithDollarPrefix(price) {
        return Currency(price, {separator: ",", symbol: '$'}).format();
    },

    sanitizePrice(price) {
        let parsed = Number.parseFloat(price);
        return (!Number.isNaN(parsed) && parsed < 0) ? Currency(0.0) : this.formatCurrency(price);
    }

};
export default CurrencyFormatter;
