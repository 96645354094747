<template>
  <modal-dialog
      :contentClass="'add-slot-dialog'"
      ref="dialog"
      :title="'Add Slot ' + formattedDate"
      width="600"
      submitLabel="Save"
      cancelLabel="Cancel"
      :visible="dialogVisible"
      :submitEnabled="true"
      :loading="loading"
      @submitClicked="onSubmit"
      @cancelClicked="closeDialog"
  >
    <add-slot-form
        ref="form"
        :crews="crews"
        @crew-selected="onCrewSelected"
    />
  </modal-dialog>

</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import CrewDropdownItem from "./CrewDropdownItem.vue";
import InstallerCrews from "../../../rest/installersCrew";
import InstallerSlots from "../../../rest/installer/installerSlots";
import DateFormatter from "../../../assets/dateFormatter";
import AddSlotForm from "./AddSlotForm.vue";

export default {
  components: {
    AddSlotForm,
    CrewDropdownItem,
    ModalDialog
  },

  data() {
    return {
      dialogVisible: false,
      loading: false,
      date: null,
      crews: [],
      selectedCrew: null
    };
  },

  props: {
    branchId: {
      type: Number,
      required: true
    },
    wsSessionId: {
      type: String,
      required: true
    }
  },

  computed: {
    formattedDate() {
      return DateFormatter.formatDateInMMDDYYYYFormat(this.date);
    }
  },

  methods: {
    getAvailableCrews() {
      const slotDate = DateFormatter.formatDateInYYYYMMDDFormat(this.date);
      InstallerCrews.getRestApi().getCrewsHavingSlotsByDateAndBranch(slotDate, this.branchId)
          .then(response => {
            this.crews = response.data;
          })
          .catch(error => {
            this.emitError(error.response.data.message);
          });
    },

    reset() {
      this.$refs.form.reset();
      this.date = null;
    },

    openDialog(date) {
      this.reset();
      this.date = date;
      this.getAvailableCrews();
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.stopLoading();
      this.reset();
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        const payload = {
          branchId: this.branchId,
          slotDate: DateFormatter.formatDateInYYYYMMDDFormat(this.date),
          installerCrewId: this.selectedCrew
        };
        InstallerSlots.getRestApi().createExtraSlot(payload, this.wsSessionId)
            .then(() => {
              this.closeDialog();
              this.$emit("onSubmit");
            })
            .catch(error => {
              this.emitError(error.response.data.message);
            });
      }
    },
    stopLoading() {
      this.loading = false;
    },
    emitError(error) {
      this.$emit('emitError', error);
    },
    onCrewSelected(crewId) {
      this.selectedCrew = crewId;
    }
  }
};
</script>

<style>
.add-slot-dialog {
  position: absolute;
  top: 120px;
}
</style>
