<template>
  <div>
    <custom-icon
        :icon="icon"
        :showFullName="false"
    />
  </div>
</template>

<script>
import CustomIcon from "../../../icons/CustomIcon.vue";
import {InventoryItemCheckedStatus} from "@/assets/statusIcons/statusIcons";

export default {
  components: {CustomIcon},

  props: {
    cellData: Object,
  },

  data() {
    return {}
  },

  computed: {
    icon() {
      if (this.cellData.data.CheckedOut) {
        return InventoryItemCheckedStatus.IS_FULLY_CHECKED_IN;
      } else {
        return null;
      }
    }
  },

};
</script>
