<template>
  <drawer-form
      ref="form"
      title="Edit Purchase Order"
      submit-label="Save Purchase Order"
      :showDelete="false"
      :deleting="false"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmit"
  >
    <purchase-order-form ref="formBody"/>
  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm";
import PurchaseOrderForm from "./PurchaseOrderForm.vue";
import PurchaseOrders from "@/rest/purchaseOrders";

export default {
  components: {
    PurchaseOrderForm,
    DrawerForm,
  },

  props: {
    purchaseOrder: {type: Object, required: false},
  },

  data() {
    return {
      purchaseOrderData: null,
    };
  },

  watch: {
    purchaseOrder(newVal) {
      if (newVal) {
        this.refresh();
      }
    },
  },

  methods: {
    async refresh() {
      this.purchaseOrderData = Object.assign(
          {},
          this.$data,
          this.purchaseOrder
      );
      this.$refs.formBody.loadForm(this.purchaseOrderData);
    },
    async onSubmit() {
      try {
        let valid = this.$refs.form.validate();
        if (valid) {
          const payload = this.$refs.formBody.buildPayload();
          const data = await this.onUpdatePurchaseOrder(payload);
          this.$emit("submitSuccess", data);
        }
      } catch (error) {
        this.$emit("submitFailed", error);
      }
    },
    onCancelClicked() {
      this.$emit('cancelClicked');
      this.$refs.formBody.loadForm(this.purchaseOrderData);
    },
    async onUpdatePurchaseOrder(payload) {
      try {
        const response = await PurchaseOrders.getRestApi().updatePurchaseOrder(
            this.purchaseOrderData.purchaseOrderNumber,
            payload
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
