<template>
  <v-card flat>
    <v-expansion-panels multiple v-model="localExpanded" class="expansion-panels">
      <v-expansion-panel>
        <v-expansion-panel-header color="#69b">
          Sales Representative Tiers (1)
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text>
            <sales-rep-tiers-form
                ref="form"
                :isActionConfirmed="isActionConfirmed"
                @showError="onSubmitFailed"
                @showSuccess="onShowSnackbarSuccess"
                @saveTiersClicked="onSaveTiersClicked"
            />
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-for="(group, groupName, index) in groupedItems" :key="index">
        <v-expansion-panel-header color="#69b">
          {{ groupName }} ({{ group.filter(item => item.isModifiable).length }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text>
            <global-configuration-form
                ref="form"
                :isActionConfirmed="isActionConfirmed"
                :items="group"
                @showError="onSubmitFailed"
                @showSuccess="onShowSnackbarSuccess"
                @saveTiersClicked="onSaveTiersClicked"
                @noModificationDetected="onNoModificationDetected"
                @updateConfigurations="handleUpdateConfigurations"
            />
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import SalesRepTiersForm from "@/views/system/globalConfiguration/SalesRepTiersForm.vue";
import GlobalConfigurationForm from "@/views/system/globalConfiguration/GlobalConfigurationForm.vue";

export default {
  components: {
    GlobalConfigurationForm,
    SalesRepTiersForm
  },

  props: {
    expanded: Array,
    groupedItems: Object,
    isActionConfirmed: Boolean
  },

  data() {
    return {
      localExpanded: this.expanded
    };
  },

  watch: {
    expanded(newVal) {
      this.localExpanded = newVal;
    },
    localExpanded(newVal) {
      this.$emit('update:expanded', newVal);
    }
  },

  methods: {
    onSubmitFailed(error) {
      this.$emit('showError', error);
    },
    onShowSnackbarSuccess(message) {
      this.$emit('showSuccess', message);
    },
    onSaveTiersClicked(payload) {
      this.$emit('saveTiersClicked', payload);
    },
    onNoModificationDetected(message) {
      this.$emit('noModificationDetected', message);
    },
    handleUpdateConfigurations(payload) {
      this.$emit('updateConfigurations', payload);
    }
  }
};
</script>

<style scoped>
.expansion-panels {
  background-color: #F6F9FA;
}
</style>
