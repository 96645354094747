<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-row class="mt-1">
        <v-col md="6" v-if="ifSummary">
          <v-text-field
            v-model="leadReferenceId"
            label="Lead Reference ID"
            placeholder=" "
            persistent-placeholder
            disabled
          />
        </v-col>
        <v-col md="6" v-if="ifSummary">
          <v-text-field
            v-model="customerFullName"
            label="Customer Name"
            placeholder=" "
            persistent-placeholder
            disabled
          />
        </v-col>
        <v-col md="6">
          <v-select
            v-model="type"
            label="Type"
            placeholder=" "
            persistent-placeholder
            :items="amountTypes"
            :rules="[rules.required]"
            :disabled="ifSummary"
            attach
          />
        </v-col>
        <v-col md="6">
          <v-text-field
            v-model="amount"
            label="Amount"
            placeholder="0.00"
            persistent-placeholder
            prefix="$"
            type="number"
            :prepend-icon="amountIcon"
            :class="amountClassName"
            :rules="[rules.required, rules.isCurrencyFormat, rules.isPositiveAndGreaterThanZero]"
            :disabled="ifSummary"
          />
        </v-col>
        <v-col md="12">
          <v-textarea
            auto-grow
            rows="2"
            v-model="note"
            label="Note"
            placeholder=" "
            persistent-placeholder
            :rules="[rules.required, rules.noteSize]"
            :disabled="ifSummary"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/assets/rules";

const formDefaults = {
  note: null,
  amount: null,
  customerFullName: null,
  leadReferenceId: null,
  type: null,
  amountClassName: null,
  amountIcon: " "
};
export default {
  props: {
    salesRepReferenceId: String,
    selectedLead: Object,
    salesRepName: String,
    ifSummary: Boolean
  },

  data() {
    return Object.assign(
      {},
      {
        dialogVisible: false,
        error: null,
        rules: {
          required: value => Rules.required(value),
          isCurrencyFormat: value => Rules.isCurrencyFormat(value),
          noteSize: value => Rules.noteSize(value, 1000),
          isPositiveAndGreaterThanZero: value => Rules.isPositiveAndGreaterThanZero(value)
        },
        amountTypes: [
          {
            text: "Fee",
            value: 2
          },
          {
            text: "Adjustment",
            value: 1
          }
        ]
      },
      formDefaults
    );
  },

  watch: {
    type(newVal) {
      //fine = subtract amount (newVal=2) | adjustment = add amount (newVal=1)
      let className = "positive";
      let prefix = "add";
      if (newVal === 2) {
        className = "negative";
        prefix = "remove";
      }
      this.amountClassName = className;
      this.amountIcon = prefix;
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.loadForm(formDefaults);
      this.$refs.form.resetValidation();
    },
    getPayload() {
      const payload = {};
      payload.type = this.type;
      payload.note = this.note;
      payload.amount = this.amount;
      payload.salesRepReferenceId = this.$props.salesRepReferenceId;
      payload.leadReferenceId = this.leadReferenceId;
      return payload;
    },
    loadForm(payload) {
      this.type = payload.type;
      this.note = payload.note;
      this.amount = payload.amount;
      this.leadReferenceId = payload.leadReferenceId;
      this.customerFullName = payload.customerFullName;
    }
  }
};
</script>

<style>
.positive > .v-input__control > .v-input__slot > .v-text-field__slot > input,
.positive
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-text-field__prefix,
.positive > .v-input__prepend-outer > .v-input__icon > .v-icon {
  color: green !important;
}
.negative > .v-input__control > .v-input__slot > .v-text-field__slot > input,
.negative
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > .v-text-field__prefix,
.negative > .v-input__prepend-outer > .v-input__icon > .v-icon {
  color: red !important;
}
</style>
