<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0">
    <v-row class="pb-0">
      <v-col md="2">
        <v-text-field
            v-model="purchaseOrder.purchaseOrderNumber"
            label="PO Number"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-order-number"
        />
      </v-col>
      <v-spacer/>
      <v-col md="2">
        <v-text-field
            v-if="purchaseOrder.purchaseOrderClosedDate"
            :value="formattedDate"
            label="PO Closed Date"
            data-type="date"
            format="MM/dd/yyyy"
            placeholder=" "
            persistent-placeholder
            readonly
            id="po-closed-date"
        />
        <v-text-field
            v-else
            v-model="status"
            label="PO Status"
            readonly
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider class="detailsDivider"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="2">
        <v-text-field
            v-model="purchaseOrder.branch.name"
            label="Branch"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-branch-name"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            v-model="dateIssued"
            label="Date Issued"
            data-type="date"
            format="MM/dd/yyyy"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-date-issued"
        />
      </v-col>
      <v-spacer/>
      <v-col md="5">
        <v-text-field
            v-model="purchaseOrder.vendor.displayName"
            label="Vendor Name"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-vendor-name"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="2">
        <v-text-field
            v-if="purchaseOrder.job"
            v-model="purchaseOrder.job.referenceId"
            label="Job Reference ID"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-reference-id"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            v-if="sidemark"
            v-model="sidemark"
            label="Sidemark"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-sidemark"
        />
      </v-col>

      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
            v-model="purchaseOrder.vendorReferenceNumber"
            label="Vendor Reference Number"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-vendor-reference-number"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            v-model="totalVendorCostRounded"
            label="Total Vendor Cost"
            placeholder=" "
            persistent-placeholder
            readonly
            id="purchase-order-details-total-vendor-cost"
            prefix="$"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DateFormatter from "../../assets/dateFormatter";

export default {
  data: () => ({
    status: "Open"
  }),

  props: {
    purchaseOrder: Object,
    dateIssued: String,
    sidemark: String,
    totalVendorCostRounded: String
  },

  computed: {
    formattedDate() {
      const formattedClosedDate = DateFormatter.formatDateInMMDDYYYYFormat(this.purchaseOrder.purchaseOrderClosedDate);
      return formattedClosedDate ? formattedClosedDate : null;
    }
  }

};
</script>
