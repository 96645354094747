<template>
  <v-dialog v-model="wizardVisible" persistent :width="900">
    <v-app-bar dense flat dark color="primary">
      <v-app-bar-title>Installer Chargeback</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">

      <v-stepper-header :step="step">
        <v-stepper-step :complete="step > 1" step="1">
          Enter Note
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Customers
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          50Floor
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4">
          Third Party
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 5" step="5">
          Chargeback
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pl-2 pr-2 pb-2">
            <v-textarea
                ref="customerNote"
                class="pt-4"
                :auto-grow="true"
                v-model="reason"
                label="* Describe Customer Concerns"
                placeholder=" "
                persistent-placeholder
                :rules="[rules.required, rules.max999, rules.isNotWhitespace]"
                :counter="999"
            />
            <button-container
                backName="Cancel"
                nextName="Next"
                :hidePreviousStep="true"
                @cancel="closeDialog"
                @getPreviousStep="resetWizard"
                @getNextStep="goToStepTwo"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="pl-2 pr-2 pb-2">
            <customer-discount-form ref="customerDiscountForm" :job="job"/>
            <button-container
                backName="Back"
                nextName="Next"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(1)"
                @getNextStep="goToStepThree"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <fifty-floor-responsibility-form
              ref="fiftyFloorResponsibilityForm"
              :job="job"
          />
          <v-card class="pl-2 pr-2 pb-2">
            <button-container
                backName="Back"
                nextName="Next"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(2)"
                @getNextStep="goToStepFour"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <third-party-form ref="thirdPartyForm" :job="job"/>
          <v-card class="pl-2 pr-2 pb-2">
            <button-container
                backName="Back"
                nextName="Next"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(3)"
                @getNextStep="goToStepFive"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card class="pl-2 pr-2 pb-2">
            <confirmation-form
                ref="confirmationForm"
                :job="job"
                :laborItems="availableItemsWithExistingRefIdsAndInstallation"
            />
            <button-container
                backName="Back"
                nextName="Submit"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(4)"
                @getNextStep="onSubmitClicked"
            />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "@/components/common/wizard/ButtonContainerWithCancel.vue";
import StepperHeader from "@/components/jobs/installerChargebackWizard/StepperHeader.vue";
import CustomerDiscountForm from "@/components/jobs/installerChargebackWizard/CustomerDiscountForm.vue";
import FiftyFloorResponsibilityForm from "@/components/jobs/installerChargebackWizard/FiftyFloorResponsibilityForm.vue";
import ThirdPartyForm from "@/components/jobs/installerChargebackWizard/ThirdPartyForm.vue";
import ConfirmationForm from "@/components/jobs/installerChargebackWizard/ConfirmationForm.vue";
import Constants from "@/assets/constants";
import Rules from "@/assets/rules";
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

export default {
  components: {
    StepperHeader,
    ButtonContainer,
    CustomerDiscountForm,
    FiftyFloorResponsibilityForm,
    ThirdPartyForm,
    ConfirmationForm,
  },

  props: {
    job: Object,
    contractPrice: Object,
    productItems: Array,
    laborItems: Array,
  },

  watch: {
    wizardVisible(value) {
      if (value) {
        this.$nextTick(this.$refs.customerNote.focus);
      }
    }
  },

  data() {
    return Object.assign(
        {},
        {
          reason: null,
          materialCost: 0,
          laborCost: 0,
          customerAmount: 0,
          fiftyFloorAmount: 0,
          wizardVisible: false,
          payingThirdParty: false,
          thirdPartyVendorId: null,
          thirdPartyVendor: null,
          thirdPartyAmount: 0,
          error: null,
          step: 1,
          rules: {
            required: (value) => !!value || "Required",
            max999: value => {
              return Rules.maxValue(value, 999);
            },
            isNotWhitespace: value => !Rules.isWhitespaceOnly(value)
          },
        }
    );
  },

  computed: {
    availableItemsWithExistingRefIdsAndInstallation() {
      return this.$props.laborItems
          .filter(item => !item.jobLineItem.newLineItem && item.jobLineItem.installerId
              && item.installation.installerId !== Constants.TBDInstallerId)
          .map(item => {
            item.displayName = item.jobLineItem.referenceId + " - " + item.installation.installer.companyName;
            return item;
          });
    }
  },

  methods: {
    openDialog() {
      this.resetWizard();
      this.wizardVisible = true;
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    showError(error) {
      this.$emit("showError", error);
    },
    showSuccess(message) {
      this.$emit("showSuccess", message);
    },
    resetWizard() {
      this.step = 1;
      this.closeDialog();
      this.resetAllForms();
    },
    goToStepTwo() {
      if (this.$refs.customerNote.validate()) {
        this.goToStep(2);
      }
    },
    goToStepThree() {
      let valid = this.$refs.customerDiscountForm.validate();
      if (valid) {
        const payload = this.$refs.customerDiscountForm.getPayload();
        this.customerAmount = parseFloat(payload.customerAmount);
        this.goToStep(3);
      }
    },
    goToStepFour() {
      let valid = this.$refs.fiftyFloorResponsibilityForm.validate();
      if (valid) {
        const payload = this.$refs.fiftyFloorResponsibilityForm.getPayload();
        this.fiftyFloorAmount = parseFloat(payload.fiftyFloorAmount);
        this.goToStep(4);
      }
    },
    goToStepFive() {
      let valid = this.$refs.thirdPartyForm.validate();
      if (valid) {
        const payload = this.$refs.thirdPartyForm.getPayload();
        this.payingThirdParty = payload.payingThirdParty;
        this.thirdPartyVendorId = payload.thirdPartyVendorId;
        this.thirdPartyVendor = payload.thirdPartyVendor;
        this.thirdPartyAmount = parseFloat(payload.thirdPartyAmount);
        const input = {
          note: this.reason,
          customerAmount: this.customerAmount,
          fiftyFloorAmount: this.fiftyFloorAmount,
          thirdPartyAmount: this.thirdPartyAmount,
        };
        this.$refs.confirmationForm.load(input);
        this.goToStep(5);
      }
    },
    goToStep(step) {
      this.step = step;
      this.$refs.confirmationForm.clearSelectedInstaller();
    },
    async onSubmitClicked() {
      let valid = this.$refs.confirmationForm.validate() && this.validateChargebackInvalidCase();
      if (valid) {
        const payload = this.$refs.confirmationForm.getPayload();
        let request = {
          reason: this.reason,
          materialCost: payload.materialCost,
          laborCost: payload.laborCost,
          customerPayment: this.customerAmount,
          thirdPartyVendorId: this.thirdPartyVendorId,
          thirdPartyVendor: this.thirdPartyVendor,
          thirdPartyPayment: this.thirdPartyAmount,
          fiftyFloorAmount: this.fiftyFloorAmount,
          installerChargeAmount: payload.installerChargeAmount,
          installerId: payload.installerId,
          installerLabel: payload.installerLabel,
          vendorChargeAmount: 0,
          vendorId: null,
          vendorLabel: null,
          chargebackProductsDelta: this.calculateChargebackOverbillDifference(CurrencyFormatter.asCurrency(payload.materialCost).value)
        };
        this.$emit("submitChargeback", request);
        this.resetWizard();
      }
    },
    calculateChargebackOverbillDifference(materialCostTotal) {
      let sumSelectedCosts = 0;
      this.$refs.confirmationForm.productItem.forEach(productItem => {
            if (materialCostTotal > 0) {
              sumSelectedCosts = sumSelectedCosts + productItem.itemCost;
            }
          }
      );
      return (sumSelectedCosts > 0) ? (sumSelectedCosts - materialCostTotal) : 0;
    },
    validateChargebackInvalidCase() {
      return !(this.$refs.confirmationForm.customerAmount == 0 && this.$refs.confirmationForm.fiftyFloorAmount == 0
          && this.$refs.confirmationForm.totalChargeback == 0 && this.$refs.confirmationForm.thirdPartyAmount == 0
          && this.$refs.confirmationForm.materialCost == 0 && this.$refs.confirmationForm.laborCost == 0
          && this.$refs.confirmationForm.installerId);
    },
    resetAllForms() {
      //works only with $nextTick in Vuetify 2
      this.$nextTick(() => {
        this.$refs.customerNote.reset();
        this.$refs.customerDiscountForm.reset();
        this.$refs.fiftyFloorResponsibilityForm.reset();
        this.$refs.thirdPartyForm.reset();
        this.$refs.confirmationForm.reset();
      })
    },
  },
};
</script>
