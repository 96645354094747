<template>
  <div>
    <table class="line-item-headers caption">
      <tr>
        <td width="50%" class="text-sm-left pb-2">Item Information</td>
        <td width="15%">Quantity</td>
        <td width="15%">Ordered</td>
        <td width="25%">Price</td>
        <td width="10px"></td>
      </tr>
    </table>

    <v-divider />

    <v-layout row wrap justify-space-between>
      <purchase-order-line-items
        class="ma-1"
        v-for="(item, index) in selectedItems"
        :key="index"
        :index="index"
        :line-item="item"
        :readonly="false"
        :step="2"
        @onComputeQuantity="emitComputedQuantity"
      />

      <v-flex xs12 align-content-end>
        <v-divider />
        <p class="stepError">{{ error }}</p>
        <v-card-actions class="align-content-end ma-2">
          <v-spacer></v-spacer>
          <div>
            <v-btn class="ma-1" large dark outlined color="primary" @click="cancelClicked"> Cancel </v-btn>
            <v-btn class="ma-1" large dark type="submit" color="primary" @click="nextClicked"> Next </v-btn>
          </div>
        </v-card-actions>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import PurchaseOrderLineItems from "@/components/purchaseOrders/bulkPurchaseOrder/LineItems.vue";

export default {
  components: {
    PurchaseOrderLineItems
  },

  props: {
    error: String,
    selectedItems: Array
  },

  methods: {
    cancelClicked: function() {
      this.$emit("cancelClicked");
    },
    nextClicked: function() {
      this.$emit("nextClicked");
    },
    emitComputedQuantity: function(computedQuantity, index) {
      this.$emit("onComputeQuantity", computedQuantity, index);
    }
  }
};
</script>

<style scoped>
.stepError {
  color: red;
  text-align: right;
  height: 20px;
  padding-top: 5px;
}
</style>
