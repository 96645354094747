<template>
  <v-row>
    <v-container fluid id="transfer-request-details">
      <v-row>
        <v-col cols="2">
          <text-field-with-copy-to-clip-board
              :value="shipment.shipmentNumber"
              label="Shipment Number"
              placeholder=" "
              persistent-placeholder
              :readonly="true"
              id="transfer-request-details-shipment-number"
          />
        </v-col>
        <v-col cols="3">
          <status-icon-cell-template
              :statusName="shipment.status"
              template-name="shipmentStatusIcon"
          />
        </v-col>
        <v-col/>
      </v-row>
      <v-divider class="detailsDivider"/>
    </v-container>

    <v-container grid-list-lg fluid id="transfer-request-details">
      <v-row>
        <v-col cols="4">
          <v-text-field
              :value="shipment.shipFrom"
              label="Ship From (Branch)"
              placeholder=" "
              persistent-placeholder
              readonly
              id="shipment-details-from-branch"
          />
        </v-col>
        <v-col/>
        <v-col cols="4">
          <v-text-field
              :value="formatDate(shipment.pickUpDate)"
              label="Pickup Date"
              placeholder=" "
              persistent-placeholder
              readonly
              id="shipment-details-closest-pickup-date"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
              :value="shipment.shipTo"
              label="Ship To (Branch)"
              placeholder=" "
              persistent-placeholder
              readonly
              id="shipment-details-transfer-to-branch"
          />
        </v-col>
        <v-col/>
        <v-col cols="4">
          <v-text-field
              v-if="shipment.shipmentNumber"
              :value="formatDate(shipment.estimatedDeliveryDate)"
              label="Estimated Delivery Date"
              placeholder=" "
              persistent-placeholder
              readonly
              id="shipment-details-estimated-delivery-date"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import StatusIconCellTemplate from "../../components/common/grid/cellTemplates/StatusIconCellTemplate.vue";
import DateFormatter from "@/assets/dateFormatter";
import TextFieldWithCopyToClipBoard from "@/components/common/templates/TextFieldWithCopyToClipBoard.vue";

export default {
  components: {
    TextFieldWithCopyToClipBoard,
    StatusIconCellTemplate
  },

  computed: {},

  props: {
    shipment: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  methods: {
    formatDate(date) {
      return DateFormatter.formatDateInMMDDYYYYFormat(date);
    }
  },
};
</script>
