import Axios from "./axiosInstance";
import {ActivityLog} from "./interfaces/activityLogs";
import {AxiosPromise} from "axios";
import {Branch} from "@/rest/branches";

/**
 * User role.
 */
export class Role {
  createdAt: string;
  updatedAt: string;
  description: string;
  id: number;
  name: string;

  constructor(data?: any) {
    data = data || {};
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.name = data.name;
    this.description = data.description;
  }
}

/**
 *  System user.
 */
export class User {
  id: number;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  roleId: number;
  branchIds: string[];
  branchReferenceIds: string[];
  salesRepId: number;
  referenceId: string;

  constructor(data?: any) {
    data = data || {};
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.role = this.parseRole(data.role);
    this.roleId = data.roleId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.branchIds = data.branchIds;
    this.branchReferenceIds = data.branchReferenceIds;
    this.salesRepId = data.salesRepId;
    this.referenceId = data.referenceId;
  }

  parseRole(data: any) {
    const role = new Role();
    role.id = data.id;
    role.name = data.name;
    role.description = data.description;
    role.createdAt = data.createdAt;
    role.updatedAt = data.updatedAt;

    return role;
  }
}

/**
 * Request for Creating a Regular User
 */
export class CreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  active: boolean;
  sendTemporaryPassword: boolean;
  newPassword: string;

  constructor(data?: any) {
    data = data || {};
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.description = data.description;
    this.active = data.active;
    this.sendTemporaryPassword = data.sendTemporaryPassword;
    this.newPassword = data.newPassword;
  }
}
/**
 * Request for Creating a Rehash Rep User
 */
export class CreateRehashUserRequest extends CreateUserRequest{
  phone: string;

  constructor(data?: any) {
    data = data || {};
    super(data);
    this.phone = data.phone;
  }
}

/**
 * Request for Creating an Admin User
 */
export class CreateAdminUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  active: boolean;
  sendTemporaryPassword: boolean;
  newPassword: string;

  constructor(data?: any) {
    data = data || {};
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.description = data.description;
    this.active = data.active;
    this.sendTemporaryPassword = data.sendTemporaryPassword;
    this.newPassword = data.newPassword;
  }
}
export class CreateSuperAdminUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  active: boolean;
  sendTemporaryPassword: boolean;
  newPassword: string;

  constructor(data?: any) {
    data = data || {};
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.description = data.description;
    this.active = data.active;
    this.sendTemporaryPassword = data.sendTemporaryPassword;
    this.newPassword = data.newPassword;
  }
}

/**
 * Request for Creating a Branch User
 */
export class CreateBranchUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  active: boolean;
  sendTemporaryPassword: boolean;
  newPassword: string;
  branchReferenceIds: string[];

  constructor(data?: any) {
    data = data || {};
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.description = data.description;
    this.active = data.active;
    this.sendTemporaryPassword = data.sendTemporaryPassword;
    this.newPassword = data.newPassword;
    this.branchReferenceIds = data.branchReferenceIds;
  }
}

/**
 * Request for Creating a Branch Admin User
 */
export class CreateBranchAdminUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  description: string;
  active: boolean;
  sendTemporaryPassword: boolean;
  newPassword: string;
  branchReferenceIds: string[];

  constructor(data?: any) {
    data = data || {};
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.description = data.description;
    this.active = data.active;
    this.sendTemporaryPassword = data.sendTemporaryPassword;
    this.newPassword = data.newPassword;
    this.branchReferenceIds = data.branchReferenceIds;
  }
}

export class AuthenticationResponse {
  accessToken: string;
  expiresIn: number; // unused
  refreshToken: string;
  refreshTokenExpiresIn: number; // unused
  deviceId: string;
  user: User;

  constructor(
      accessToken: string,
      expiresIn: number,
      refreshToken: string,
      refreshTokenExpiresIn: number,
      deviceId: string,
      user: User
  ) {
    this.accessToken = accessToken;
    this.expiresIn = expiresIn;
    this.refreshToken = refreshToken;
    this.refreshTokenExpiresIn = refreshTokenExpiresIn;
    this.deviceId = deviceId;
    this.user = user;
  }
}

/**
 * REST interface provided for users.
 */
export interface UsersRestApi {
  getAllUsers(): AxiosPromise<User>;
  getAllActiveUsers(): AxiosPromise<User>;
  getUserById(id: number): AxiosPromise<User>;
  getUserByEmail(email: string): AxiosPromise<User>;
  authenticate(email: string, password: string): AxiosPromise<AuthenticationResponse>;
  refreshToken(): AxiosPromise<AuthenticationResponse>;
  createUser(payload: CreateUserRequest): AxiosPromise<User>;
  createRehashUser(payload: CreateRehashUserRequest): AxiosPromise<User>;
  createBranchUser(payload: CreateBranchUserRequest): AxiosPromise<User>;
  createBranchAdminUser(payload: CreateBranchAdminUserRequest): AxiosPromise<User>;
  createGeneralManager(payload: CreateBranchAdminUserRequest): AxiosPromise<User>;
  createAdminUser(payload: CreateAdminUserRequest): AxiosPromise<User>;
  createMarketingDataUploaderUser(payload: CreateAdminUserRequest): AxiosPromise<User>;
  createScgApiUser(payload: CreateAdminUserRequest): AxiosPromise<User>;
  createSalesLeadOptimizerApiUser(payload: CreateUserRequest): AxiosPromise<User>;
  createSuperAdminUser(payload: CreateSuperAdminUserRequest): AxiosPromise<User>;
  updateUser(id: number, payload: User): AxiosPromise<User>;
  forgotPassword(email: string): AxiosPromise<User>;
  resetPassword(email: string, temporaryPassword: string, newPassword: string): AxiosPromise<User>;
  changePassword(changePassword: object): AxiosPromise<User>;
  overridePassword(email: string, newPassword: string): AxiosPromise<User>;
  getActivityLogs(id: number): AxiosPromise<ActivityLog[]>;
  getSelfBranches(): AxiosPromise<Branch[]>;
  logout() : AxiosPromise<void>;
  resetUserMFA(userId: number): AxiosPromise<User>;
}

/**
 * REST implementation provided for users.
 */
class Implementation implements UsersRestApi {
  getAllUsers(): AxiosPromise<User> {
    return Axios.axiosInstance().get<User>("/users/search/all");
  }
  getAllActiveUsers(): AxiosPromise<User> {
    return Axios.axiosInstance().get<User>("/users/search/all/active");
  }
  getUserById(id: number): AxiosPromise<User> {
    return Axios.axiosInstance().get<User>(`/users/id/${id}`);
  }
  getUserByEmail(email: string): AxiosPromise<User> {
    return Axios.axiosInstance().get<User>(`/users/${email}`);
  }
  createUser(payload: CreateUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users", payload);
  }

  createMarketingDataUploaderUser(payload: CreateUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/marketing-uploader", payload);
  }

  createScgApiUser(payload: CreateUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/scg-api", payload);
  }

  createSalesLeadOptimizerApiUser(payload: CreateUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/sales-lead-optimizer-integration", payload);
  }
  createAdminUser(payload: CreateAdminUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/admin", payload);
  }
  createSuperAdminUser(payload: CreateSuperAdminUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/super-admin", payload);
  }
  createRehashUser(payload: CreateRehashUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/rehash", payload);
  }
  createBranchUser(payload: CreateBranchUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/branch", payload);
  }
  createBranchAdminUser(payload: CreateBranchAdminUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/branch_admin", payload);
  }
  createGeneralManager(payload: CreateBranchAdminUserRequest): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>("/users/general-manager", payload);
  }
  updateUser(id: number, payload: User): AxiosPromise<User> {
    return Axios.axiosInstance().put<User>(`/users/${id}`, payload);
  }
  changePassword(changePassword: object): AxiosPromise<User> {
    return Axios.axiosInstance().post<User>(
      `/users/password/change`,
      changePassword
    );
  }
  overridePassword(email: string, newPassword: string): AxiosPromise<User> {
    const user = {
      email: email,
      newPassword: newPassword,
    };
    return Axios.axiosInstance().post<User>(`/users/password/override`, user);
  }
  resetPassword(
    email: string,
    temporaryPassword: string,
    newPassword: string
  ): AxiosPromise<User> {
    const user = {
      email: email,
      temporaryPassword: temporaryPassword,
      newPassword: newPassword,
    };
    const config = {
      headers: {
        Authorization: "",
      },
    };
    return Axios.axiosInstance().post<User>(
      `/users/password/reset`,
      user,
      config
    );
  }
  forgotPassword(email: string): AxiosPromise<User> {
    const user = {
      email: email,
    };
    const config = {
      headers: {
        Authorization: "",
      },
    };
    return Axios.axiosInstance().post<User>(
      `/users/password/forgot`,
      user,
      config
    );
  }
  authenticate(
    email: string,
    password: string
  ): AxiosPromise<AuthenticationResponse> {
    const user = {
      email: email,
      encryptedPassword: password
    };
    return Axios.axiosInstance().post<AuthenticationResponse>("users/authenticate", user
    );
  }
  refreshToken(): AxiosPromise<AuthenticationResponse> {
    return Axios.axiosInstance().post<AuthenticationResponse>("users/refreshtoken", null);
  }
  getActivityLogs(id: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/users/${id}/logs`);
  }
  getSelfBranches(): AxiosPromise<Branch[]> {
    return Axios.axiosInstance().get<Branch[]>(`/users/me/branches`);
  }
  logout(): AxiosPromise<void> {
    return Axios.axiosInstance().post<void>(`/users/logout`);
  }
  resetUserMFA(userId: number): AxiosPromise<User> {
    return Axios.axiosInstance().patch<User>(`/users/${userId}/mfa/reset`);
  }
}

const Users = {
  getRestApi(): UsersRestApi {
    return new Implementation();
  }
};

export default Users;
