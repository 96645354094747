<template>
  <v-menu
      v-if="submenus && submenus.length"
      open-on-hover
      offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          dark
          text
          block
          :to="primaryUrl"
          :class="menuClass"
          v-bind="attrs"
          v-on="on"
      >
        {{ label }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          :key="submenu.label"
          :to="submenu.to"
          v-for="submenu in submenus"
      >
        <v-list-item-title class="text-left">{{ submenu.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-btn
      v-else
      text
      dark
      block
      :to="primaryUrl"
      :class="menuClass"
  >
    {{ label }}
  </v-btn>
</template>
<script>
export default {
  components: {},

  props: {
    label: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
      default: ''
    },
    submenus: {
      type: Array,
      required: false,
      default: () => []
    },
    currentSection: {
      type: String,
      required: true,
    },
    primaryUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    menuClass() {
      return this.currentSection === this.section ? 'nav-menu nav-menu-selected' : 'nav-menu';
    },
  }
};
</script>

<style scoped>
.nav-menu {
  color: #999;
  font-size: 18px;
  border-bottom: 3px solid transparent;
}

.nav-menu-selected {
  color: rgba(102, 153, 187, 1);
  border-bottom: 3px solid #69b;
  border-radius: 0;
}

.v-btn--active:before {
  background-color: unset;
}

.v-list-item--active {
  color: rgba(102, 153, 187, 1);
}

.v-list-item--active:before {
  background-color: unset;
}
</style>
