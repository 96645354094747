<template>
  <v-data-table
      :headers="headers"
      :items="selectedItems"
      item-key="index"
      light
      hide-default-footer
      disable-pagination
  >
    <template v-slot:item="{ item, index }">
      <tr>
        <td class="text-lg-left pa-4">{{ item.ProductStyle }}</td>
        <td class="text-lg-left pa-4">{{ item.ProductColor }}</td>
        <td class="text-lg-left pa-4">{{ item.RollNumber }}</td>
        <td class="text-lg-left pa-4">{{ item.DyeLot }}</td>
        <td class="text-lg-left pa-4">{{ item.BinNumber }}</td>
        <td class="text-lg-left pa-4">{{ item.Available }}</td>
        <td class="text-lg-center" width="150px">
          <div class="text-input-container">
            <v-text-field
                slot="input"
                v-model="item.Quantity"
                label="Qty"
                type="number"
                placeholder="0"
                single-line
                autofocus
            />
          </div>
        </td>
        <td class="text-lg-center buttonContainer">
          <v-btn
              class="button"
              text
              icon
              small
              @click="onIncreaseQuantityClicked(item, index)"
          >
            <v-icon color="primary">add_circle_outline</v-icon>
          </v-btn>
          <v-btn
              class="button"
              text
              icon
              small
              @click="onReduceQuantityClicked(item,index)"
          >
            <v-icon color="primary">remove_circle_outline</v-icon>
          </v-btn>
          <v-btn
              class="button"
              absolute
              icon
              small
              @click="onDeleteClicked(index)"
          >
            <v-icon color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      Add items from above to check out inventory
    </template>
  </v-data-table>
</template>

<script>
export default {
  components: {},
  props: ["selectedItems"],
  data() {
    return {
      headers: [
        { text: "Style", value: "productType", sortable: false },
        { text: "Color", value: "productColor", sortable: false },
        { text: "Roll Number", value: "rollNumber", sortable: false },
        { text: "Dye Lot", value: "dyeLot", sortable: false },
        { text: "Bin", value: "binId", sortable: false },
        { text: "Available", value: "available", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Edit", value: "edit", sortable: false, align: "center" }
      ]
    };
  },
  methods: {
    onDeleteClicked: function(index) {
      this.$emit("deleteClicked", index);
    },
    onReduceQuantityClicked: function(item, index) {
      this.$emit("reduceQuantityClicked", item, index);
    },
    onIncreaseQuantityClicked: function(item, index) {
      this.$emit("increaseQuantityClicked", item, index);
    }
  }
};
</script>

<style>
.quantity {
  border-bottom: 1px solid #69b;
  min-width: 50px;
  text-align: left;
  padding-left: 4px;
  height: 20px;
}
.button {
  margin-left: 2px;
  margin-right: 2px;
}
.buttonContainer {
  min-width: 150px;
}
.text-input-container {
  margin-top: 15px;
}
</style>
