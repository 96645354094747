<template>
  <div class="timezone-dropdown">
    <v-select
        label="* Timezone"
        placeholder=" "
        v-model="selected"
        :items="timezones"
        :rules="[rules.required]"
        attach
        persistent-placeholder
    />
  </div>
</template>

<script>
import Rules from "../../../assets/rules";

export default {
  data() {
    return {
      selected: null,
      timezones: ['US/Eastern', 'US/Central'],
      rules: {
        required: Rules.required
      }
    };
  },
  props: {
    value: String,
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.$data.selected = value;
      }
    },
    selected(value) {
      this.$emit('onTimeZoneChange', value);
    }
  }
};
</script>
