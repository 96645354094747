<template>
  <navigation-view-sales ref="nav" page-title="Jobs" nav-section="jobs">
    <template v-slot:nav-action>
      <v-row id="single-btn-row" class="justify-end ma-0 pa-0">
        <navigation-action-button
            :showActionButtons="true"
            id="go-to-button"
            label="Go to Lead"
            @click="goToLeadPage"
        />
      </v-row>
    </template>

    <template v-slot:content>
      <v-container grid-list-lg fluid id="job-details">
        <v-row class="ma-1">
          <job-form-read-only ref="jobFormReadOnly"/>
          <job-inline-grids v-if="job" :job="job" :lead="lead"/>
        </v-row>
      </v-container>
    </template>
  </navigation-view-sales>
</template>
<script>
import Jobs from "@/rest/jobs.ts";
import NavigationViewSales from "@/views/navigation/NavigationViewSales.vue";
import JobFormReadOnly from "@/components/salesRepresentativesHome/job/JobFormReadOnly.vue";
import JobInlineGrids from "@/views/salesRepresentativesHome/job/JobInlineGrids.vue";
import NavigationActionButton from "../../navigation/NavigationActionButton";

export default {
  components: {
    NavigationViewSales,
    JobFormReadOnly,
    JobInlineGrids,
    NavigationActionButton
  },

  data() {
    return {
      job: null,
      jobId: null,
      customer: null,
      jobReferenceId: null,
      lead: null
    };
  },

  created() {
    this.display(this.$route.params.jobReferenceId);
  },

  methods: {
    display(jobReferenceId) {
      this.jobReferenceId = jobReferenceId;
      this.refresh();
    },
    async refresh() {
      try {
        const jobData = await this.getJobData();
        this.setData(jobData);
        this.$refs.jobFormReadOnly.loadForm(jobData);
      } catch (error) {
        this.showSnackbarError(error);
      }
    },
    async getJobData() {
      try {
        const response = await Jobs.getRestApi().getJobSummaryById(this.jobReferenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    setData(data) {
      this.job = data.job;
      this.jobId = data.job.id;
      this.customer = data.customer;
      this.lead = data.lead;
    },
    showSnackbarError(error) {
      this.$refs.nav.showError(error);
    },
    showSnackbarSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    goToLeadPage() {
      this.$router.push(`/sales/leads/details/${this.lead.referenceId}`);
    }
  }
};
</script>

<style scoped>
#single-btn-row {
  overflow-x: hidden;
  background-color: #f5f8fa;
  border-top: 1px solid #d1dbe3;
  border-bottom: 1px solid #d1dbe3;
}
</style>
