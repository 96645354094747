<template>
  <div class="section-divider mr-3">{{ title }}</div>
</template>

<script>
export default {
  components: {},

  props: ["title"],

  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.section-divider {
  color: #fff;
  background-color: #69b;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 2px;
  border-radius: 5px;
}
</style>
