<template class="pt-1">
  <date-picker-with-type-in-text-field
      v-model="value"
      id="purchase-order-item-edit-eta-date"
      :optional="true"
      :hideLabel="true"
  />
</template>

<script>
import DatePickerWithTypeInTextField from "../../form/DatePickerWithTypeInTextField.vue";

export default {
  components: {
    DatePickerWithTypeInTextField
  },

  props: {
    cellData: Object
  },

  data() {
    return {
      value: this.cellData.data.etaDate
    };
  },

  watch: {
    value(newVal) {
      this.$emit('etaChanged', this.cellData, newVal);
    }
  },

  methods: {}
};
</script>

