<template>
  <div>
    <inline-grid
        :data-source="tickets"
        :show-borders="false"
        :hoverStateEnabled="true"
        @rowSelected="onRowSelected"
    >
      <DxPaging :page-size="10"/>
      <dx-column
          data-field="createdAt"
          width="15%"
          data-type="date"
          caption="Date"
          sortOrder="desc"
          format="MM/dd/yyyy, hh:mm a"
      />
      <dx-column
          data-field="statusName"
          width="20%"
          caption="Status"
      />
      <dx-column
          data-field="referenceId"
          width="20%"
          caption="Ticket Reference ID"
      />
      <dx-column
          data-field="description"
          caption="Description"
      />

      <template v-slot:footer>
        <inline-grid-footer>
          <inline-grid-button
              label="Add Ticket"
              @click="onAddTicketClick"
          />
        </inline-grid-footer>
      </template>
    </inline-grid>

    <create-ticket-dialog
        ref="createTicketDialog"
        @onCreateTicket="createTicket"
    />
  </div>
</template>

<script>
import {DxColumn, DxPaging} from "devextreme-vue/ui/data-grid";
import InlineGrid from "../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../components/common/grid/InlineGridButton.vue";
import CreateTicketDialog from "../../components/tickets/CreateTicketDialog.vue";
import Tickets from "../../rest/tickets";
import Constants from "../../assets/constants";

export default {
  components: {
    DxPaging,
    CreateTicketDialog,
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn
  },

  props: {
    jobId: Number,
    leadReferenceId: String,
    jobReferenceId: String,
    customer: {
      type: Object,
      required: true
    },
    type: String,
  },

  data() {
    return {
      tickets: [],
    };
  },

  created() {
    this.refresh();
  },

  methods: {
    async refresh() {
      let response;
      try {
        if (this.type === Constants.ticketTypes.LEAD) {
          response = await this.getTicketsByLead();
        } else if (this.type === Constants.ticketTypes.JOB) {
          response = await this.getTicketsByJobReferenceId();
        }
        this.tickets = response.data;
        this.emitRefresh();
      } catch (error) {
        this.emitError(error);
      }
    },
    emitRefresh() {
      this.$emit('onRefresh');
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    async getTicketsByLead() {
      try {
        return await Tickets.getRestApi().getTicketsByLead(this.leadReferenceId);
      } catch (error) {
        throw error;
      }
    },
    async getTicketsByJobReferenceId() {
      try {
        return await Tickets.getRestApi().getTicketsByJobReferenceId(this.jobReferenceId);
      } catch (error) {
        throw error;
      }
    },
    getUserName() {
      const firstName = this.$store.getters.loggedInUser.firstName;
      const lastName = this.$store.getters.loggedInUser.lastName;
      return `${firstName} ${lastName}`;
    },
    onAddTicketClick() {
      this.populateForm();
      this.$refs.createTicketDialog.openDialog();
    },
    async createTicket(payload) {
      try {
        await Tickets.getRestApi().createTicket(payload);
        await this.refresh();
      } catch (error) {
        this.emitError(error);
      }
    },
    populateForm() {
      const ticket = {
        id: null,
        description: "",
        userName: this.getUserName(),
        userId: this.$store.getters.loggedInUser.id,
        statusId: 1,
        jobId: this.jobId,
        jobReferenceId: this.jobReferenceId,
        leadReferenceId: this.leadReferenceId,
        customerName: this.customer.fullName,
        customerReferenceId: this.customer.referenceId,
        customerId: this.customer.id
      };
      this.$refs.createTicketDialog.loadFrom(ticket);
    },
    onRowSelected(ticket) {
      const refId = ticket.referenceId;
      this.$router.push(`/tickets/details/${refId}`);
    }
  }
};
</script>
