<template>
  <div class="separator pa-2">
    <table class="line-item-headers caption">
      <tr>
        <td width="12%">Reference Id</td>
        <td width="15%">Item Information</td>
        <td width="8%" class="text-sm-center">Item Price</td>
        <td width="12%" style="padding-left: 15px">Status</td>
        <td width="12%" style="padding-left: 15px">ETA</td>
        <td width="13%" style="padding-left: 15px">Comment</td>
        <td width="10%" class="text-sm-right" style="padding-right: 3px">
          Quantity
        </td>
        <td width="10%" class="text-sm-right" style="padding-right: 3px">
          Order
        </td>
        <td width="10%" class="text-sm-right" style="padding-right: 3px">
          Cost
        </td>
        <td width="85px" class="text-sm-center">In Fulfillment</td>
        <td width="35px"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
td {
  margin-right: 10px;
  margin-left: 15px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
</style>
