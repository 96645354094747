<template>
  <grid-view
      ref="nav"
      page-title="List Jobs"
      nav-section="sales"
      :data-source="dataSource"
      :columnMinWidth="150"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
      :branchUserRestricted="true"
  >
    <!-- branch user role restriction -->
    <dx-column
        data-field="BranchName"
        caption="Branch"
        :allowHeaderFiltering="allowBranchesFilter"
        :header-filter="{ dataSource: branchesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="CreatedAt"
        caption="Create Date"
        data-type="date"
        format="MM/dd/yyyy"
        :minWidth="160"
        :allowHeaderFiltering="false"
        sort-order="desc"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ParentStatus"
        caption="Status"
        alignment="left"
        :header-filter="{ dataSource: parentStatusFilters }"
        data-type="string"
    />
    <dx-column
        data-field="Status"
        caption="Substatus"
        alignment="left"
        :header-filter="{ dataSource: subStatusFilters }"
        data-type="string"
    />
    <dx-column
        data-field="JobPriority"
        caption="Job Priority"
        data-type="number"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ContractPrice"
        caption="Contract Price"
        data-type="number"
        :format="format"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Cost"
        caption="Job Cost"
        data-type="number"
        :format="format"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="SalesRepName"
        caption="Sales Representative"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Margin"
        caption="Margin"
        data-type="number"
        :format="format"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="MarginPercentage"
        data-type="number"
        caption="Margin %"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Eta"
        caption="ETA"
        data-type="date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="InstallationDate"
        caption="Installation Date"
        data-type="date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="MaxEtcDate"
        caption="ETC Date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        data-type="date"
    />
    <dx-column
        data-field="CustomerFirstName"
        caption="Customer First Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CustomerLastName"
        caption="Customer Last Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CustomerPrimaryPhone"
        caption="Cell Phone"
        data-type="string"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CustomerEmail"
        :minWidth="225"
        caption="Customer Email"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LeadAddress"
        caption="Lead Address"
        :minWidth="250"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LeadCity"
        caption="Lead City"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LeadZipCode"
        caption="Lead Zip Code"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="LastNote"
        caption="Last Note"
        alignment="left"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="InvoicedDate"
        caption="Invoiced Date"
        data-type="date"
        format="MM/dd/yyyy"
        :minWidth="160"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="NextActionDate"
        caption="Next Action Date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        data-type="date"
    />
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "@/rest/odataContext";
import Format from "../../components/common/Format";
import CustomizeFilter from "../../components/common/grid/CustomizeFilter";

export default {
  components: {
    GridView,
    DxColumn,
  },
  data() {
    return {
      allowBranchesFilter: false,
      parentStatusFilters: [],
      subStatusFilters: [],
      branchesFilter: [],
      format: {
        type: "currency",
        precision: 2,
      },
      dataSource: {
        store: ODataContext['WebJobs'],
        select: [
          "Id",
          "CreatedAt",
          "ReferenceId",
          "ParentStatus",
          "Status",
          "InstallationDate",
          "CustomerFirstName",
          "CustomerLastName",
          "CustomerPrimaryPhone",
          "CustomerEmail",
          "LeadAddress",
          "LeadCity",
          "LeadZipCode",
          "ContractPrice",
          "Cost",
          "Margin",
          "Eta",
          "JobPriority",
          "LastNote",
          "BranchName",
          "BranchReferenceId", // for branch user role restriction
          "InvoicedDate",
          "NextActionDate",
          "MarginPercentage",
          "SalesRepName",
          "MaxEtcDate"
        ],
        sort: [{selector: "CreatedAt", desc: true}]
      }
    };
  },

  created() {
    this.getFilters();
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/jobs/details/${data.ReferenceId}`);
    },
    async getFilters() {
      try {
        const user = this.$store.state.loggedInUser;
        const marketsFilter = await CustomizeFilter.getMarketFiltersIfNotBranchUser(user);
        this.setMarketFilters(marketsFilter);
        const branchesFilter = await CustomizeFilter.getBranchFiltersIfNotBranchUser(user);
        this.setBranchesFilters(branchesFilter);

        let jobStatuses = await CustomizeFilter.getJobStatusCategoryFilters();
        this.populateFilteringParentChildJobStatutes(jobStatuses);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setMarketFilters(marketsFilter) {
      if (marketsFilter) {
        this.marketsFilter = marketsFilter;
        this.allowMarketFilter = true;
      }
    },
    setBranchesFilters(branchesFilter) {
      if (branchesFilter) {
        this.branchesFilter = branchesFilter;
        this.allowBranchesFilter = true;
      }
    },
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    },
    populateFilteringParentChildJobStatutes(jobStatuses) {
      let parentStatuses = [];
      let subStatuses = [];
      jobStatuses.forEach(status => {
        status.value = status.name
        status.text = status.name
        if (status.parentId === null) {
          parentStatuses.push(status);
        } else subStatuses.push(status);
      });

      this.parentStatusFilters = parentStatuses.sort((a, b) => a.name.localeCompare(b.name));
      this.subStatusFilters = subStatuses
          .filter((v, i, a) => a.findIndex(t => t.id === v.id || t.name === v.name) === i)
          .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
};
</script>
