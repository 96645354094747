<template>
  <v-row class="mr-0 ml-0">
    <v-col md="12" v-if="!disabled && !readonly">
      <place
          ref="placeAutocomplete"
          :loading="loading"
          :usaOnly="usaOnly"
          @onPlaceChange="changePlace"
      />
    </v-col>

    <v-col md="12" class="mt-0 pt-0">
      <v-text-field
          placeholder=" "
          persistent-placeholder
          v-model="addressValue"
          :rules="[rules.required, rules.max255]"
          :label="showAddressLabel"
          data-id="autocomplete-form-address"
          :disabled="disabled"
          :readonly="isFieldsReadOnly(addressFound)"
          autocomplete="create-edit-address"
          @change="onAddressChange"
      />
    </v-col>
    <v-col md="6" class="mt-0 pt-0">
      <v-text-field
          v-model="cityValue"
          :rules="[rules.required, rules.max255, rules.cityName]"
          :label="readonly || disabled ? 'City' : '* City'"
          data-id="autocomplete-form-city"
          :disabled="disabled"
          :readonly="isFieldsReadOnly(cityFound)"
          autocomplete="create-edit-city"
          placeholder=" "
          persistent-placeholder
          @change="onCityChange"
      />
    </v-col>
    <v-col md="2" class="mt-0 pt-0">
      <state-search-field
          autocomplete="create-edit-state"
          :disabled="disabled"
          :readonly="isFieldsReadOnly(stateFound)"
          :value="stateValue"
          @input="updateState"
          @change="onStateChange"
      />
    </v-col>
    <v-col md="4" class="mt-0 pt-0">
      <v-text-field
          v-model="zipCodeValue"
          :rules="[rules.required, rules.zipCode]"
          :label="readonly || disabled ? 'Zip' : '* Zip'"
          data-id="autocomplete-form-zip"
          type="text"
          v-facade="'#####'"
          placeholder=" "
          persistent-placeholder
          :disabled="disabled"
          :readonly="isFieldsReadOnly(zipCodeFound)"
          autocomplete="create-edit-zip-code"
          @change="onZipCodeChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import StateSearchField from "./StateSearchField.vue";
import Place from "../location/PlaceAutocomplete.vue";
import {
  getPlaceAddress,
  getPlacesOkStatus,
  getPlacesService,
  searchLocation
} from "@/components/common/location/PlaceUtils";
import Rules from "../../../assets/rules";

const formDefaults = {
  addressValue: null,
  addressFound: false,
  cityValue: null,
  cityFound: false,
  stateValue: null,
  stateFound: false,
  zipCodeValue: null,
  zipCodeFound: false,
  latitudeValue: null,
  longitudeValue: null,
  loading: false,
  googleFound: false
};

export default {
  components: {
    StateSearchField,
    Place
  },

  props: {
    addressData: {
      type: Object,
      required: false
    },
    readonly: Boolean,
    address: String,
    city: String,
    state: String,
    zipCode: String,
    disabled: Boolean,
    addressLabel: String,
    searchOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    usaOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    coordinatesRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showAddressLabel() {
      return this.addressLabel || (this.readonly || this.disabled ? 'Address' : '* Address');
    }
  },
  watch: {
    addressData: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.setAddressData(newValue);
        }
      }
    },
    address(newVal) {
      this.addressValue = newVal;
    },
    city(newVal) {
      this.cityValue = newVal;
    },
    state(newVal) {
      this.stateValue = newVal;
    },
    zipCode(newVal) {
      this.zipCodeValue = newVal;
    }
  },

  data() {
    return Object.assign(
        {},
        {
          rules: {
            required: Rules.required,
            zipCode: Rules.zipCode,
            max255: value => Rules.maxValue(value, 255),
            cityName: value => Rules.cityName(value)
          }
        },
        formDefaults
    );
  },

  methods: {
    isFieldsReadOnly(data) {
      return this.readonly || (this.searchOnly && (!this.googleFound || data));
    },
    changePlace(placeId) {
      // shows loading bar under place autocomplete to show progress
      // if response takes a while to get back
      this.loading = true;
      const service = getPlacesService();
      service.getDetails(
          {
            placeId: placeId,
            fields: ['address_components', 'geometry']
          },
          (place, status) => {
            if (status === getPlacesOkStatus()) {
              const addressData = getPlaceAddress(place);
              addressData.address = `${addressData.streetNumber} ${addressData.street}`;
              this.setAddressData(addressData, place.geometry);
              this.googleFound = true;
              this.loading = false;
            }
          }
      );
    },
    updateState(data) {
      this.stateValue = data;
    },
    async setAddressData(data, geometry) {
      this.addressValue = data.address;
      this.addressFound = data.address !== "";
      this.cityValue = data.city;
      this.cityFound = data.city !== "";
      this.stateValue = data.state;
      this.stateFound = data.stateValue !== "";
      this.zipCodeValue = data.zipCode;
      this.zipCodeFound = data.zipCode !== "";
      if (geometry) {
        await this.setCoordinates(data, geometry);
      } else {
        this.latitudeValue = data.latitudeValue;
        this.longitudeValue = data.longitudeValue;
      }
      this.$emit('zipCodeChanged', this.zipCodeValue);
    },
    setShipmentAddressData(data) {
      this.addressValue = data.shipmentAddress;
      this.cityValue = data.shipmentCity;
      this.stateValue = data.shipmentState;
      this.zipCodeValue = data.shipmentZipCode;
      this.$emit('zipCodeChanged', this.shipmentZipCodeValue); //?? shipmentZipCodeValue
    },
    async setCoordinates(data, geometry) {
      if (this.coordinatesRequired) {
        if (geometry) {
          const component = this;
          component.latitudeValue = geometry.location.lat();
          component.longitudeValue = geometry.location.lng();
        } else if ((!geometry && data.address) && this.hasMissingCoordinates(data)) {
          await this.updateAddressData(data);
        }
      }
    },
    hasMissingCoordinates(data) {
      return !data.latitude || !data.longitude;
    },
    async updateAddressData(data) {
      if (data.address) {
        let locationSearchResult = await searchLocation(`${data.address} ${data.city} ${data.state} ${data.zipCode}`);
        if (locationSearchResult[0]) {
          const component = this;
          let service = getPlacesService();
          service.getDetails({
                placeId: locationSearchResult[0].place_id,
                fields: ["geometry"]
              },
              (place, status) => {
                if (status === getPlacesOkStatus()) {
                  component.latitudeValue = place.geometry.location.lat();
                  component.longitudeValue = place.geometry.location.lng();
                }
              }
          );
        }
      }
    },
    clearAutocomplete() {
      this.$refs.placeAutocomplete.clearAutocomplete();
    },
    getAddressData() {
      return {
        address: this.addressValue,
        city: this.cityValue,
        state: this.stateValue,
        zipCode: this.zipCodeValue,
        latitude: this.latitudeValue,
        longitude: this.longitudeValue
      };
    },
    onAddressChange() {
      this.$emit('addressChanged', this.addressValue);
      this.$emit('someAddressChanged', this.addressValue);
    },
    onCityChange() {
      this.$emit('cityChanged', this.cityValue);
      this.$emit('someAddressChanged', this.addressValue);
    },
    onStateChange() {
      this.$emit('stateChanged', this.stateValue);
      this.$emit('someAddressChanged', this.addressValue);
    },
    onZipCodeChange() {
      this.$emit('zipCodeChanged', this.zipCodeValue);
      this.$emit('someAddressChanged', this.addressValue);
    }
  }
};
</script>
