<template>
  <div>
    <v-card>
      <associated-lead-grid
        v-show="gridLoaded"
        :salesRepName="salesRepName"
        @leadSelected="onLeadSelected"
        @gridContentReady="onGridContentReady"
      />
      <!-- using custom loader as data grid's loader isn't playing nice while being inside a modal -->
      <div v-show="!gridLoaded" class="customLoaderContainer">
        <v-progress-circular :size="40" color="primary" indeterminate />
      </div>
    </v-card>
    <div class="chipContainer">
      <v-chip
        outlined
        close
        text-color="#333"
        v-if="selectedLead"
        @click:close="onRemoveSelected"
      >
        <strong>{{ selectedLead.ReferenceId }}:</strong>&nbsp;
        <span
          >{{ selectedLead.CustomerFirstName }}
          {{ selectedLead.CustomerLastName }}</span
        >
      </v-chip>
    </div>
  </div>
</template>

<script>
import AssociatedLeadGrid from "@/components/salesRepresentatives/fineWizard/AssociatedLeadGrid.vue";

export default {
  components: { AssociatedLeadGrid },

  props: { salesRepName: String, selectedLead: Object },

  data() {
    return {
      gridLoaded: false
    };
  },

  methods: {
    onGridContentReady() {
      this.$data.gridLoaded = true;
    },
    onLeadSelected(data) {
      this.$emit("leadSelected", data);
    },
    onRemoveSelected() {
      this.$emit("removeSelected");
    }
  }
};
</script>

<style scoped>
.chipContainer {
  min-height: 75px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.customLoaderContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
