<template>
  <v-container>
    <v-row>
      <v-col>
        <header class="text-left"><b>Job Property Address</b></header>
      </v-col>
    </v-row>

    <v-row class="mt-1 pl-2 mb-4">
      <check-box
          v-if="formType === Constants.leadFormType.CREATE"
          :selected="sameCustomerAddress"
          :label="'Same as customer mailing address'"
          @onCheckBoxChanged="checkboxChanged"
      />
    </v-row>

    <v-row class="ma-1 mb-0">
      <v-col cols="12" class="text-center full-width">

        <v-alert
            border="left"
            colored-border
            type="info"
            elevation="2"
            prominent
        >
          <v-row class="pa-1">
            You must select the Lead Address from the location dropdown to ensure proper routing.
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <address-form-with-autocomplete
          ref="addressForm"
          :coordinatesRequired="true"
          :disabled="disabled"
          @zipCodeChanged="onZipCodeChanged"
      />
    </v-row>
  </v-container>
</template>

<script>
import AddressFormWithAutocomplete from "../../components/common/form/AddressFormWithAutocomplete.vue";
import Constants from "../../assets/constants";
import CheckBox from "../common/form/CheckBox.vue";
import CompareUtils from "@/assets/utils/compareUtils";

const formDefaults = {
  leadStatusId: 1,
  sameCustomerAddress: false
};

// outside the component:
function initialState() {
  return Object.assign(formDefaults);
}

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    CheckBox,
    AddressFormWithAutocomplete
  },

  props: {
    customer: Object,
    disabled: Boolean,
    formType: String
  },

  data() {
    return initialState();
  },

  watch: {
    customer: {
      immediate: true,
      handler(newCustomer, oldCustomer) {
      }
    }
  },

  methods: {
    buildPayload() {
      let payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      // Address payload
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      payload.latitude = addressData.latitude;
      payload.longitude = addressData.longitude;
      return payload;
    },
    setFormBackToDefault() {
      this.loadForm(formDefaults);
    },
    setFormBackToOriginalData(payload) {
      this.loadForm(payload);
    },
    async loadForm(payload) {
      this.leadStatusId = payload.leadStatusId;
      this.id = payload.id;

      let addressPayload = {
        address: payload.address,
        city: payload.city,
        state: payload.state,
        zipCode: payload.zipCode,
        latitude:  payload.latitude,
        longitude: payload.longitude
      };

      let originalAddress = this.$refs.addressForm.getAddressData();
      let areEqual = CompareUtils.deepEqual(originalAddress, addressPayload);
      if (this.$refs.addressForm && !areEqual) {
        this.$refs.addressForm.setAddressData(addressPayload);
      }
    },
    onUseCustomerAddress() {
      if (this.sameCustomerAddress) {
        let customer = this.customer;
        if (customer) {
          let payload = {
            address: customer.address,
            city: customer.city,
            state: customer.state,
            zipCode: customer.zipCode
          };
          this.$refs.addressForm.setAddressData(payload);
        }
      } else {
        this.$refs.addressForm.setAddressData({});
      }
    },
    onZipCodeChanged() {
      this.$emit('zipCodeChanged', this.$refs.addressForm.getAddressData().zipCode);
    },
    reset() {
      Object.assign(this.$data, initialState());
      if (this.$refs.addressForm)  {
        this.$refs.addressForm.setAddressData({});
      }
    },
    checkboxChanged(value) {
      this.sameCustomerAddress = value;
      this.onUseCustomerAddress();
    }
  }
};
</script>
