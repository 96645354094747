<template>
  <modal-dialog
    ref="dialog"
    title="Potential SKU Conflict"
    width="500"
    :visible="dialogVisible"
    submitLabel="Yes"
    cancelLabel="No"
    @submitClicked="onSubmitClicked"
    @cancelClicked="onCancelClicked"
    :submitEnabled="true"
  >
    <v-card flat>
      <v-card-text
        class="subheading"
      >The SKU entered already exists for this vendor. Do you wish to Save?</v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

const formDefaults = {
  dialogVisible: false,
};
export default {
  components: {
    ModalDialog,
  },
  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.closeDialog();
    },
    async onSubmitClicked() {
      this.closeDialog();
      this.$emit("confirmed");
    },
  },
};
</script>
