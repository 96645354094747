import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * Shipments.
 */
export class Shipment {
    shipmentNumber: string;
    status: string;
    closestInstallationDate: Date;
    pickUpDate: Date;
    estimatedDeliveryDate: Date;
    shipFrom: string;
    shipFromBranchId: number;
    shipTo: string;
    shipToBranchId: number;
    transferRequests: [];

    constructor(shipmentNumber: string, status: string, closestInstallationDate: Date, pickUpDate: Date,
                estimatedDeliveryDate: Date, shipFrom: string, shipFromBranchId: number, shipTo: string,
                shipToBranchId: number, transferRequests: []
    ) {
        this.shipmentNumber = shipmentNumber;
        this.status = status;
        this.closestInstallationDate = closestInstallationDate;
        this.pickUpDate = pickUpDate;
        this.estimatedDeliveryDate = estimatedDeliveryDate;
        this.shipFrom = shipFrom;
        this.shipFromBranchId = shipFromBranchId;
        this.shipTo = shipTo;
        this.shipToBranchId = shipToBranchId;
        this.transferRequests = transferRequests;
    }
}

/**
 * REST interface provided for Shipment interactions
 */
export interface ShipmentRestApi {
    getShipmentByShipmentNumber(shipmentNumber: string): AxiosPromise<Shipment>;
    cancelShipment(shipmentNumber: string): AxiosPromise<Shipment>;
    getActivityLogs(shipmentNumber: string): AxiosPromise<ActivityLog>;
    scheduleShipment(payload: object): AxiosPromise<Shipment>;
    updateShipmentDates(shipmentNumber: string, payload: object): AxiosPromise<Shipment>;
    addTransferRequestsToShipment(shipmentNumber: string, payload: object): AxiosPromise<Shipment>;
}

/**
 * REST implementation.
 */
class Implementation implements ShipmentRestApi {
    //
    getShipmentByShipmentNumber(shipmentNumber: string): AxiosPromise<Shipment>{
        return Axios.axiosInstance().get<Shipment>(`/shipment/${shipmentNumber}/details`);
    }
    cancelShipment(shipmentNumber: string): AxiosPromise<Shipment> {
        return Axios.axiosInstance().post<Shipment>(`/shipment/close/${shipmentNumber}`);
    }
    getActivityLogs(shipmentNumber: string): AxiosPromise<ActivityLog> {
        return Axios.axiosInstance().get<ActivityLog>(`/shipment/${shipmentNumber}/logs`);
    }
    scheduleShipment(payload: object): AxiosPromise<Shipment> {
        return Axios.axiosInstance().post<Shipment>(`/shipment/schedule`, payload);
    }
    updateShipmentDates(shipmentNumber: string, payload: object): AxiosPromise<Shipment> {
        return Axios.axiosInstance().patch<Shipment>(`/shipment/dates/update/${shipmentNumber}`, payload);
    }
    addTransferRequestsToShipment(shipmentNumber: string, payload: object): AxiosPromise<Shipment> {
        return Axios.axiosInstance().put<Shipment>(`/shipment/${shipmentNumber}/add-transfer-requests`, payload);
    }
}

const Shipments = {
    getRestApi(): ShipmentRestApi {
        return new Implementation();
    }
};

export default Shipments;
