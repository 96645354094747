import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "@/rest/interfaces/activityLogs";
import {Lead} from "@/rest/leads";

export interface SalesRepresentativeBase {
    id: number;
    referenceId: string;
    firstName: string;
    lastName: string;
    companyName: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    fax: string;
    email: string;
    employee: boolean;
    stateLicenseNumber: string;
    licenseExpirationDate: string;
    latitude: number;
    longitude: number;
    automaticLeadAssignmentBranchReferenceId: string;
    enableAutomaticLeadAssignment: boolean;
}

/**
 * Sales representative.
 */
export interface SalesRepresentative extends SalesRepresentativeBase {
    marketIds: number[];
    marketNames: string;
    gpl: number;
    tier: SalesRepTierShortDTO;
    maxLeadPerDay: number;
    gplStartDate: string;
    automaticLeadAssignmentBranchName: string;
}

export interface SalesRepresentativeUpdateRequest extends SalesRepresentativeBase {
    marketIds: number[];
    selectedMarketIds: number[];
    newPassword: string;
    sendTemporaryPassword: boolean;
}

export interface SalesRepTierShortDTO {
    id: number;
    name: string;
}

export class SalesCommission {
    id: number;
    saleDate: Date;
    fullname: string;
    leadId: number;
    jobId: number;
    commissionableJobAmt: number;
    commission: number;

    constructor(
        id: number,
        saleDate: Date,
        fullname: string,
        leadId: number,
        jobId: number,
        commissionableJobAmt: number,
        commission: number
    ) {
        this.id = id;
        this.saleDate = saleDate;
        this.fullname = fullname;
        this.leadId = leadId;
        this.jobId = jobId;
        this.commissionableJobAmt = commissionableJobAmt;
        this.commission = commission;
    }
}

/** Used to create an adjustment record */
export class AdjustmentCreateRequest {
    memo?: string;
    amount?: number;
    leadReferenceId?: string;
}

/** Adjustment record */
export class Adjustment {
    accountNumber?: number;
    accountTitle?: string;
    entryDate?: string;
    amount?: number;
    vendorId?: string;
    vendorName?: string;
}

/**
 * REST interface provided for sales representatives.
 */
export interface SalesRepresentativeRestApi {

    updateSalesCommission(refId: string, commissionId: number, payload: SalesCommission): AxiosPromise<SalesCommission>;

    getMarketSalesReps(marketId: number): AxiosPromise<SalesRepresentative[]>;

    getSalesRepresentative(refId: string): AxiosPromise<SalesRepresentative>;

    getSalesRepresentativeByUserId(id: number): AxiosPromise<SalesRepresentative>;

    createSalesRepresentative(payload: SalesRepresentative): AxiosPromise<SalesRepresentative>;

    updateSalesRepresentative(refId: string, payload: SalesRepresentative): AxiosPromise<SalesRepresentative>;

    getSalesRepresentativeLogs(refId: string): AxiosPromise<ActivityLog[]>;

    createSalesRepAdjustment(refId: string, request: AdjustmentCreateRequest): AxiosPromise<any>;

    getSalesRepAdjustments(refId: string): AxiosPromise<Adjustment[]>;

    associateLeadsToSalesRepresentative(id: number, payload: any): AxiosPromise<Lead[]>;

    resetGPLStartDate(refId: string): AxiosPromise<any>;
}

/**
 * REST implementation provided for product types.
 */
class Implementation implements SalesRepresentativeRestApi {

    updateSalesCommission(refId: string, commissionId: number, payload: SalesCommission): AxiosPromise<SalesCommission> {
        return Axios.axiosInstance().put<SalesCommission>(`/sales_reps/${refId}/commissions/${commissionId}`, payload);
    }

    getMarketSalesReps(marketId: number): AxiosPromise<SalesRepresentative[]> {
        return Axios.axiosInstance().get<SalesRepresentative[]>(`/sales_reps/market/${marketId}`);
    }

    getSalesRepresentative(refId: string): AxiosPromise<SalesRepresentative> {
        return Axios.axiosInstance().get<SalesRepresentative>(`/sales_reps/${refId}`);
    }

    getSalesRepresentativeByUserId(id: number): AxiosPromise<SalesRepresentative> {
        return Axios.axiosInstance().get<SalesRepresentative>(`/sales_reps/user/${id}`);
    }

    createSalesRepresentative(payload: SalesRepresentative): AxiosPromise<SalesRepresentative> {
        return Axios.axiosInstance().post<SalesRepresentative>("/sales_reps", payload);
    }

    updateSalesRepresentative(refId: string, payload: SalesRepresentative): AxiosPromise<SalesRepresentative> {
        return Axios.axiosInstance().put<SalesRepresentative>(`/sales_reps/${refId}`, payload);
    }

    getSalesRepresentativeLogs(refId: string): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>(`/sales_reps/${refId}/logs`);
    }

    associateLeadsToSalesRepresentative(id: number, payload: any): AxiosPromise {
        return Axios.axiosInstance().post(`/sales_reps/${id}/leads`, payload);
    }

    getNotes(refId: string): AxiosPromise<SalesRepresentative> {
        return Axios.axiosInstance().get<SalesRepresentative>(`/sales_reps/${refId}/notes`);
    }

    createSalesRepAdjustment(refId: string, request: AdjustmentCreateRequest): AxiosPromise<any> {
        return Axios.axiosInstance().post<any>(`/sales_reps/${refId}/adjustment`, request);
    }

    getSalesRepAdjustments(refId: string): AxiosPromise<Adjustment[]> {
        return Axios.axiosInstance().get<Adjustment[]>(`/sales_reps/${refId}/adjustments`);
    }

    resetGPLStartDate(refId: string): AxiosPromise<any> {
        return Axios.axiosInstance().post<any>(`/sales_reps/${refId}/reset-gpl-start-date`);
    }
}

const SalesRepresentatives = {
    getRestApi(): SalesRepresentativeRestApi {
        return new Implementation();
    },
};

export default SalesRepresentatives;
