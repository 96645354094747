<template>
  <navigation-view ref="nav" page-title="Lead Details" nav-section="sales">
    <template v-slot:content>
      <v-container v-if="lead" grid-list-lg fluid id="lead-details">
        <v-row class="ma-1">
          <!-- ref id and status top section -->
          <lead-details-status
              :referenceId="lead.referenceId"
              :leadStatusName="lead.leadStatusName"
          />
        </v-row>
        <v-row class="mr-2 mt-2">
          <!-- left side details section -->
          <v-col cols="5">
            <lead-details-form-read-only
                ref="LeadDetailsFormReadOnly"
                :lead="lead"
            />
          </v-col>

          <v-spacer/>
          <!-- right side details brought in from associated ids (market, customer, create date) -->
          <v-col cols="5">
            <lead-additional-details
                :lead="lead"
                :customer="customer"
                :canGoToCustomer="true"
            />
          </v-col>
        </v-row>

        <v-row class="ma-1">
          <inline-grids
              ref="leadInlineGrid"
              :leadReferenceId="leadReferenceId"
              :customer="customer"
              @showSuccess="showSnackbarSuccess"
              @showError="showSnackbarError"
          />
        </v-row>
      </v-container>
    </template>

    <template v-slot:drawer>
      <lead-address-drawer-form
          v-show="drawerSelected === 'editAddress'"
          ref="leadAddressDrawerForm"
          title="Edit Address"
          submitLabel="Save Lead Address"
          formType="edit"
          :inProgress="updateInProgress"
          :customer="customer"
          @cancelClicked="onUpdateLeadAddressCancelClicked"
          @submitClicked="onUpdateLeadAddressSubmitClicked"
          @onError="onError"
      />

      <lead-schedule-drawer-form
          v-show="drawerSelected === 'editSchedule'"
          ref="leadScheduleDrawerForm"
          title="Edit Schedule"
          submitLabel="Save Lead Schedule"
          formType="edit"
          :inProgress="updateInProgress"
          :customer="customer"
          :nonServiceable="nonServiceable"
          :marketId="marketId"
          :marketRefId="marketRefId"
          :salesRepName="lead.salesRepName"
          :assignedSalesRep="assignedSalesRep"
          :isSameDayAllowed="true"
          @cancelClicked="onUpdateLeadScheduleCancelClicked"
          @submitClicked="onUpdateLeadScheduleSubmitClicked"
          @onError="onError"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="true"
          label="Edit Address"
          secondaryLabel="Edit Schedule"
          @click="showAddressDrawer"
          @secondaryButtonClick="showScheduleDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>
import Constants from "../../assets/constants";
import NavigationView from "../../views/navigation/NavigationView.vue";
import NavigationActionButton from "../../views/navigation/NavigationActionButton.vue";
import LeadAddressDrawerForm from "../../components/leads/LeadAddressDrawerForm.vue";
import LeadScheduleDrawerForm from "../../components/leads/LeadScheduleDrawerForm.vue";
import LeadDetailsFormReadOnly from "../../components/leads/LeadDetailsFormReadOnly.vue";
import LeadAdditionalDetails from "../../components/leads/LeadAdditionalReadOnlyDetails.vue";
import LeadDetailsStatus from "../../components/leads/LeadDetailsStatus.vue";
import InlineGrids from "../../views/leads/LeadsInlineGrids.vue";
import Leads from "../../rest/leads.ts";
import Markets from "../../rest/markets";
import Customers from "../../rest/customers.ts";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    LeadAddressDrawerForm,
    LeadScheduleDrawerForm,
    LeadDetailsFormReadOnly,
    LeadAdditionalDetails,
    LeadDetailsStatus,
    InlineGrids,
  },

  data() {
    return {
      drawerSelected: "editSchedule",
      loading: true,
      lead: null,
      leadReferenceId: null,
      customer: {},
      customerReferenceId: null,
      leadRooms: null,
      installationCapabilities: null,
      nonServiceable: true,
      marketId: null,
      marketRefId: null,
      activityLogs: [],
      notes: [],
      updateInProgress: false,
      promoCodeName: null,
      assignedSalesRep: null
    };
  },

  async created() {
    this.display(this.$route.params.leadReferenceId);
  },

  methods: {
    display(leadReferenceId) {
      this.leadReferenceId = leadReferenceId;
      this.refresh();
    },
    async refresh() {
      try {
        const data = await this.getAllLeadData();
        this.setAllLeadData(data);
        await this.getCustomerData();
        await this.checkLeadServiceable();
        this.loadDetailsForm();
        this.loading = false;
      } catch (error) {
        this.showSnackbarError(error);
      }
    },
    refreshActivityLog() {
      this.$refs.leadInlineGrid.refreshActivityLog();
    },
    async checkLeadServiceable() {
      this.nonServiceable = await this.isLeadNonServiceable(this.lead);
    },
    loadDetailsForm() {
      if (this.$refs.LeadDetailsFormReadOnly) {
        this.$refs.LeadDetailsFormReadOnly.loadForm(this.lead, this.leadRooms, this.installationCapabilities);
      }
    },
    async getAllLeadData() {
      try {
        const leadResponse = await Leads.getRestApi().getLead(this.leadReferenceId);
        return leadResponse.data;
      } catch (error) {
        throw error;
      }
    },
    setAllLeadData(lead) {
      this.lead = lead;
      this.marketId = lead.marketId;
      this.marketRefId = lead.marketReferenceId;
      this.customerReferenceId = lead.customerReferenceId;
      this.refId = lead.refId;
      this.leadRooms = lead.leadRooms;
      this.installationCapabilities = lead.productTypes;
      this.assignedSalesRep = {
        salesRepId: lead.salesRepId,
        salesRepName: lead.salesRepName,
        salesRepReferenceId: lead.salesRepReferenceId,
        previousSalesRepName: lead.previousSalesRepName
      }
    },
    async getCustomerData() {
      try {
        const customerResponse = await Customers.getRestApi().getCustomer(this.customerReferenceId);
        this.customer = customerResponse.data;
      } catch (error) {
        throw error;
      }
    },
    showAddressDrawer() {
      this.drawerSelected = "editAddress";
      this.$refs.leadAddressDrawerForm.loadDrawerForm(this.lead);
      this.$refs.nav.showDrawer();
    },
    onUpdateLeadAddressCancelClicked() {
      this.$refs.leadAddressDrawerForm.setFormBackToOriginalData(this.lead);
      this.$refs.nav.hideDrawer();
    },
    async onUpdateLeadAddressSubmitClicked(payload) {
      const isZipCodeServiceable = await this.checkZipCodeServiceable(payload.zipCode);

      if (isZipCodeServiceable) {
        // Try to update lead's address.
        try {
          await this.updateLeadAddress(payload);
          await this.onUpdateLeadSuccess();
        } catch (error) {
          this.onUpdateLeadFailed(error);
        }
      } else {
        // Zip Code is non-serviceable. Hide drawer and show message
        this.$refs.leadAddressDrawerForm.resetForm();
        this.onUpdateLeadScheduleCancelClicked();
        this.onUpdateLeadFailed("The zip code entered in non-serviceable.");
      }
    },
    showScheduleDrawer() {
      this.drawerSelected = "editSchedule";
      this.$refs.leadScheduleDrawerForm.loadDrawerForm(this.lead, this.leadRooms, this.installationCapabilities);
      this.$refs.nav.showDrawer();
    },
    onUpdateLeadScheduleCancelClicked() {
      this.$refs.leadScheduleDrawerForm.setFormBackToOriginalData(this.lead, this.leadRooms, this.installationCapabilities);
      this.$refs.nav.hideDrawer();
    },
    async onUpdateLeadScheduleSubmitClicked(payload) {
      if (this.nonServiceable) {
        this.onUpdateLeadScheduleCancelClicked();
      } else {
        try {
          let updateSchedulePayload = {};
          Object.assign(updateSchedulePayload, this.lead);
          Object.assign(updateSchedulePayload, payload);

          if (updateSchedulePayload.salesRepReferenceId === null
              || updateSchedulePayload.salesRepReferenceId !== this.lead.salesRepReferenceId) {
            updateSchedulePayload.salesRepId = null;
            updateSchedulePayload.salesRepName = null;
          }

          await this.updateLead(updateSchedulePayload);
          await this.onUpdateLeadSuccess();
        } catch (error) {
          this.onUpdateLeadFailed(error);
        }
      }
    },
    async updateLead(payload) {
      try {
        this.updateInProgress = true;
        await Leads.getRestApi().updateLead(this.leadReferenceId, payload);
      } catch (error) {
        throw error;
      } finally {
        this.updateInProgress = false;
      }
    },
    async updateLeadAddress(payload) {
      try {
        this.updateInProgress = true;
        await Leads.getRestApi().updateLeadAddress(this.leadReferenceId, payload);
      } catch (error) {
        throw error;
      } finally {
        this.updateInProgress = false;
      }
    },
    async onUpdateLeadSuccess() {
      try {
        this.$refs.nav.hideDrawer();
        this.showSnackbarSuccess("Updated lead details successfully.");
        const data = await this.getAllLeadData();
        this.setAllLeadData(data);
        this.loadDetailsForm();
        this.refreshActivityLog();
      } catch (error) {
        throw error;
      }
    },
    onUpdateLeadFailed(error) {
      this.showSnackbarError(error);
    },
    showSnackbarError(error) {
      this.$refs.nav.showError(error);
    },
    showSnackbarSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    onError(error) {
      this.showSnackbarError(error);
    },
    async checkZipCodeServiceable(zipCode) {
      if (zipCode !== undefined && zipCode !== null) {
        try {
          const marketZipCode = await Markets.getRestApi().getMarketByZipCode(zipCode);
          return !!marketZipCode.data.marketId;
        } catch (error) {
          return false;
        }
      }
    },
    async isLeadNonServiceable(lead) {
      return lead?.leadStatusName === Constants.nonServiceableLeadStatus;
    }
  }
};
</script>
