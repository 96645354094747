import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";


/**
 * Market ZipCode
 */
export class MarketZipCode {
    id: number;
    zipCode: string;
    active: boolean;

    constructor(id: number, zipCode: string, active: boolean) {
        this.id = id;
        this.zipCode = zipCode;
        this.active = active;
    }
}

/**
 * Zip Codes REST interface.
 */
export interface ZipCodesRestApi {
    associateZipCodes(marketReferenceId: string, payload: string[]): AxiosPromise<string[]>;

    dissociateZipCodes(marketReferenceId: string, payload: string[]): AxiosPromise<string[]>;
}

/**
 * Zip Codes REST implementation.
 */
class Implementation implements ZipCodesRestApi {

    associateZipCodes(marketReferenceId: string, payload: string[]): AxiosPromise<string[]> {
        return Axios.axiosInstance().post<string[]>(`/markets/zip-codes/${marketReferenceId}`, payload);
    }

    dissociateZipCodes(marketReferenceId: string, payload: string[]): AxiosPromise<string[]> {
        return Axios.axiosInstance().delete(`/markets/zip-codes/${marketReferenceId}`, {data: payload});
    }
}

const ZipCodes = {
    getRestApi(): ZipCodesRestApi {
        return new Implementation();
    }
};

export default ZipCodes;
