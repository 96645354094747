<template>
  <navigation-view-sales ref="nav" page-title="Leads" nav-section="leads">
    <template v-slot:content>
      <v-container v-if="lead" grid-list-lg fluid id="lead-details">
        <v-row class="ma-1">
          <!-- ref id and status top section -->
          <lead-details-status
              :referenceId="lead.referenceId"
              :leadStatusName="lead.leadStatusName"
          />
        </v-row>
        <v-row class="mr-2 mt-2">
          <sales-rep-lead-details-form ref="LeadDetailsForm"/>
          <v-spacer/>
          <!-- right side details brought in from associated ids (market, customer, create date) -->
          <lead-additional-details
              :lead="lead"
              :customer="customer"
              :createdAt="createdAt"
              :canGoToCustomer="false"
              :hidePhoneAndEmail="true"
          />
        </v-row>
        <sales-rep-lead-inline-grids
            ref="salesRepLeadInlineGrid"
            :leadReferenceId="leadReferenceId"
            :customer="customer"
            :customerReferenceId="customerReferenceId"
            @showSuccess="showSnackbarSuccess"
            @showError="showSnackbarError"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <sales-rep-lead-drawer-container
          ref="leadDrawerForm"
          title="Edit Lead"
          submitLabel="Save Lead"
          :serviceable="serviceable"
          @cancelClicked="onUpdateLeadCancelClicked"
          @submitClicked="onUpdateLeadSubmitClicked"
      />
    </template>

    <template v-slot:nav-action>
      <v-row id="buttons-row" class="d-flex justify-end ma-0 pb-0 pt-0">
        <v-col md="2" class="pb-0 bt-0 pr-1 pl-0 pt-0">
          <navigation-action-button
              :showActionButtons="true"
              label="Print"
              @click="printLead"
          />
        </v-col>
        <v-col md="2" v-if="lead.jobReferenceId" class="pb-0 bt-0 pr-0 pl-1 pt-0 text-right">
          <navigation-action-button
              :showActionButtons="true"
              id="go-to-button"
              label="Go to Job"
              @click="goToJobPage"
          />
        </v-col>
      </v-row>
    </template>
  </navigation-view-sales>
</template>
<script>
import NavigationViewSales from "../../../views/navigation/NavigationViewSales.vue";
import NavigationActionButton from "../../../views/navigation/NavigationActionButton.vue";
import LeadAdditionalDetails from "../../../components/leads/LeadAdditionalReadOnlyDetails.vue";
import LeadDetailsStatus from "../../../components/leads/LeadDetailsStatus.vue";
import SalesRepLeadDetailsForm from "../../../components/salesRepresentativesHome/lead/LeadDetailsForm.vue";
import SalesRepLeadInlineGrids from "../../..//views/salesRepresentativesHome/lead/LeadInlineGrids.vue";
import SalesRepLeadDrawerContainer from "../../../components/salesRepresentativesHome/lead/LeadDrawerContainer.vue";
import Leads from "../../../rest/leads.ts";
import Customers from "../../../rest/customers.ts";
import DateFormatter from "../../../assets/dateFormatter";
import LeadRoomTypes from "../../../rest/leadRoomTypes";

export default {
  components: {
    NavigationViewSales,
    NavigationActionButton,
    LeadDetailsStatus,
    SalesRepLeadDetailsForm,
    SalesRepLeadInlineGrids,
    LeadAdditionalDetails,
    SalesRepLeadDrawerContainer
  },

  data() {
    return {
      loading: true,
      lead: null,
      leadReferenceId: null,
      createdAt: null,
      customer: {},
      customerReferenceId: null,
      leadRooms: null,
      serviceable: false,
      activityLogs: [],
      notes: []
    };
  },

  created() {
    this.display(this.$route.params.leadReferenceId);
  },

  methods: {
    display(leadReferenceId) {
      this.leadReferenceId = leadReferenceId;
      this.refresh();
    },
    async refresh() {
      try {
        const data = await this.getAllLeadData();
        this.setAllLeadData(data);
        await this.getCustomerData();
        this.isCustomerInMarket();
        this.loadForms();
        this.loading = false;
      } catch (error) {
        this.showSnackbarError(error);
      }
    },
    isCustomerInMarket() {
      if (this.lead.marketId) {
        this.serviceable = true;
      }
    },
    loadForms() {
      this.$refs.LeadDetailsForm.loadForm(this.lead, this.leadRooms, this.leadInstallationCapabilities);
      this.$refs.leadDrawerForm.loadDrawerForm(this.lead, this.leadRooms, this.leadInstallationCapabilities);
    },
    async getAllLeadData() {
      try {
        const leadResponse = await Leads.getRestApi().getLead(this.leadReferenceId);
        const installationCapabilitiesResponse = await Leads.getRestApi().getLeadProductTypes(this.leadReferenceId);
        const roomsResponse = await LeadRoomTypes.getRestApi().getLeadRooms(this.leadReferenceId);
        return {
          lead: leadResponse.data,
          installationCapabilities: installationCapabilitiesResponse.data,
          rooms: roomsResponse.data
        };
      } catch (error) {
        throw error;
      }
    },
    setAllLeadData(data) {
      const {rooms, installationCapabilities, lead} = data;
      this.lead = lead;
      this.createdAt = DateFormatter.formatDateInMMDDYYYYFormat(lead.createdAt);
      this.customerReferenceId = lead.customerReferenceId;
      this.refId = lead.refId;
      this.leadRooms = rooms;
      this.leadInstallationCapabilities = installationCapabilities;
    },
    async getCustomerData() {
      try {
        const customerResponse = await Customers.getRestApi().getCustomer(this.lead.customerReferenceId);
        this.customer = customerResponse.data;
      } catch (error) {
        throw error;
      }
    },
    showSnackbarError(error) {
      this.$refs.nav.showError(error);
    },
    showSnackbarSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    onUpdateLeadCancelClicked() {
      this.$refs.leadDrawerForm.setFormBackToOriginalData(this.lead, this.leadRooms, this.leadInstallationCapabilities);
      this.$refs.nav.hideDrawer();
    },
    async onUpdateLeadSubmitClicked(payload) {
      try {
        await this.updateLead(payload);
        this.onUpdateLeadSuccess();
      } catch (error) {
        this.onUpdateLeadFailed(error);
      }
    },
    async updateLead(payload) {
      try {
        await Leads.getRestApi().updateLead(this.leadReferenceId, payload);
      } catch (error) {
        throw error;
      }
    },
    refreshActivityLog() {
      this.$refs.salesRepLeadInlineGrid.refreshActivityLog();
    },
    async onUpdateLeadSuccess() {
      try {
        this.$refs.nav.hideDrawer();
        this.$refs.nav.showSuccess("Updated lead details successfully!");
        const data = await this.getAllLeadData();
        this.setAllLeadData(data);
        this.loadForms();
        this.refreshActivityLog();
      } catch (error) {
        throw error;
      }
    },
    onUpdateLeadFailed(error) {
      this.showSnackbarError(error);
    },
    printLead() {
      let customer = this.customer;
      let lead = this.lead;
      const leadToPrint = {
        lead: lead,
        customer: customer,
        notes: this.$refs.salesRepLeadInlineGrid.$data.notes,
        rooms: this.leadRooms.map(item => item.name).join(", "),
        productTypes: this.leadInstallationCapabilities.map(item => item.name).join(", ")
      };
      this.$store.commit("setPrintData", leadToPrint);
      window.open(
          "/sales/leads/details/" + this.lead.referenceId + "/print",
          "_blank"
      );
    },
    goToJobPage() {
      this.$router.push(`/sales/jobs/details/${this.lead.jobReferenceId}`);
    }
  }
};
</script>

<style scoped>
.v-application .mb-6 {
  margin: 0 !important;
}

#buttons-row {
  overflow-x: hidden;
  background-color: #f5f8fa;
  border-top: 1px solid #d1dbe3;
  border-bottom: 1px solid #d1dbe3;
}
</style>
