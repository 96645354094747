<template>
  <div>
    <inventory :inventoryItem="item" v-for="item in poSummary.inventoryItems" :key="item.id" />
  </div>
</template>

<script>
import Inventory from "@/components/inventoryManagement/JobInventoryCheckOutInventory.vue";

export default {
  components: { Inventory },
  props: ["poSummary"],
  data() {
    return {};
  },

  methods: {}
};
</script>

<style></style>
