<template>
  <div>
    <v-col cols="4" v-if="changePasswordEnabled">
      <v-btn
          color="normal"
          :disabled="!changePasswordEnabled"
          @click="toggleChangePasswordModal"
      >
        Change Password
      </v-btn>
    </v-col>
    <v-col cols="8" v-if="changePasswordEnabled"/>
    <v-col cols="12" v-if="initialPasswordEnabled">
      <v-switch
          color="primary"
          :label="'Email temporary password to user'"
          v-model="sendTemporaryPassword"
      />
    </v-col>
    <v-col cols="12" v-if="initialPasswordEnabled && !sendTemporaryPassword">
      <v-text-field
          autocomplete="new-user-password"
          v-model="newPassword"
          @click:append="newPasswordVisible = !newPasswordVisible"
          :append-icon="newPasswordVisible ? 'visibility_off' : 'visibility'"
          :type="newPasswordVisible ? 'text' : 'password'"
          :rules="[rules.password, rules.required]"
          placeholder=" "
          label="* User Password"
          persistent-placeholder
      />
    </v-col>
    <change-password-modal
        :visible="changePasswordModal"
        @submitClick="toggleChangePasswordModal"
        @cancelClick="toggleChangePasswordModal"
        :email="email"
        :overridePassword="true"
    />
  </div>
</template>

<script>
import ChangePasswordModal from "./ChangePasswordModal.vue";
import Rules from "../../assets/rules";

const formDefaults = {
  sendTemporaryPassword: false,
  changePasswordModal: false,
  newPasswordVisible: false,
  newPassword: ""
};
export default {
  components: {
    ChangePasswordModal
  },

  props: {
    changePasswordEnabled: Boolean,
    initialPasswordEnabled: Boolean,
    email: String
  },

  data() {
    return Object.assign(
        {},
        {
          rules: {
            required: Rules.required,
            password: value => Rules.password(value)
          }
        },
        formDefaults
    );
  },

  methods: {
    toggleChangePasswordModal(response) {
      this.sendSnackbarNotification(response);
      this.changePasswordModal = !this.changePasswordModal;
    },
    resetPasswordToDefault() {
      this.newPassword = '';
    },
    sendSnackbarNotification(response) {
      if (response && response.status === 200) {
        this.$emit('passwordOverriddenSuccessfully');
      }
    }
  }
};
</script>
