<template>
  <navigation-view
      ref="nav"
      page-title="Catalog Item Details"
      nav-section="admin"
  >
    <template v-slot:content>
      <v-container v-if="catalogItem" grid-list-lg fluid>
        <catalog-item-read-only-details
            :catalogItem="catalogItem"
            :productSubtype="productSubtype"
        />
        <catalog-item-inline-grids
            ref="catalogItemsInlineGrids"
            :catalogItemId="catalogItemId"
            :internalSku="catalogItemSku"
            :vendorManagementItems="vendorManagementItems"
            :isMigratedItem="isMigratedItem"
            @showError="showError"
            @pricingUpdated="refresh"
            @onVendorAdded="vendorManagementAdded"
            @onVendorUpdated="vendorManagementUpdated"
            @onVendorDeleted="deleteVendor"
            @onVendorSkuUpdated="showSuccess"
        />

        <generic-warning-dialog
            ref="warningDialog"
            title="Warning"
            :message="warningMessage"
            :multiLineError="true"
            confirmationLabel="Confirm"
            cancelLabel="Cancel"
            @confirmed="onConfirmAction"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <update-catalog-item-drawer
          ref="updateForm"
          :catalogItemId="catalogItemId"
          :productSubtype="productSubtype"
          :productClassOptions="productClassOptions"
          @cancelClicked="onCancelUpdate"
          @submitClicked="onSubmitUpdate"
          @submitFailed="onSubmitFailed"
          @showWarning="showWarningDialog"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Catalog Item"
          @click="showDrawer"
      />
    </template>

  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import NavigationActionButton from "../../views/navigation/NavigationActionButton.vue";
import CatalogItems from "../../rest/catalogItems";
import CatalogItemReadOnlyDetails from "../../components/catalogItems/CatalogItemReadOnlyDetails.vue";
import CatalogItemInlineGrids from "../../views/catalogItems/ProductCatalogItemsInlineGrids.vue";
import Roles from "../../assets/roles";
import UpdateCatalogItemDrawer from "../../components/catalogItems/drawers/UpdateDrawer.vue"
import VendorManagements from "../../rest/vendorManagement";
import GenericWarningDialog from "../../components/common/dialogContainers/GenericWarningDialog.vue";

export default {
  components: {
    GenericWarningDialog,
    NavigationView,
    NavigationActionButton,
    UpdateCatalogItemDrawer,
    CatalogItemReadOnlyDetails,
    CatalogItemInlineGrids,
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  data() {
    return {
      catalogItemId: null,
      catalogItem: null,
      supplierCost: null,
      overbill: null,
      productSubtype: null,
      vendorManagementItems: [],
      isMigratedItem: false,
      catalogItemSku: null,
      productClassOptions: null,
      warningMessage: null
    };
  },

  created() {
    this.display(this.$route.params.catalogItemId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.catalogItemId);
    next();
  },

  methods: {
    display(catalogItemId) {
      this.catalogItemId = Number(catalogItemId);
      this.refresh();
    },
    async refresh() {
      try {
        this.checkIfPageWasScrolled();
        this.getVendorManagementData();
        const data = await this.getCatalogItemData();
        if (!this.productClassOptions) {
          this.productClassOptions = await this.getProductClassOptions();
        }
        this.catalogItem = data.productCatalogItem;
        this.catalogItemSku = data.productCatalogItem.internalSku;
        this.supplierCost = data.supplierCost;
        this.productSubtype = data.productSubtype || {};
        this.overbill = data.overbill;
        this.$refs.updateForm.loadDrawerForm(this.catalogItem);
        this.overbill = data.overbill;
      } catch (error) {
        this.showError(error);
      }
    },
    async getCatalogItemData() {
      try {
        const response = await CatalogItems.getRestApi().getCatalogItemSummary(this.catalogItemId);
        this.isMigratedItem = response.data.productCatalogItem.isMigrated;
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async getVendorManagementData() {
      await VendorManagements.getRestApi()
        .getVendorManagementDataForCatalogItemId(this.catalogItemId)
        .then((response) => {
          this.vendorManagementItems = response.data;
        }).catch((error) => {
          this.emitError(error);
        });
    },
    showDrawer() {
      if (!this.isMigratedItem) {
        this.$refs.nav.showError(new Error("Edit Drawer is restricted is for non-migrated items"));
      } else {
        this.$refs.nav.showDrawer();
      }
    },
    async onSubmitUpdate(data) {
      try {
        this.onSubmitSuccess(data);
      } catch (error) {
        this.onSubmitFailed(error);
      }
    },
    onSubmitSuccess(data) {
      if (data) {
        this.$refs.nav.hideDrawer();
        this.showSuccess("Updated catalog item details successfully!");
        this.$refs.catalogItemsInlineGrids.refreshActivityLog();
        this.refresh();
      }
    },
    onSubmitFailed(error) {
      this.showError(error.response.data.message);
    },
    onCancelUpdate() {
      this.$refs.nav.hideDrawer();
      this.$refs.updateForm.resetForm(this.catalogItem);
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    showSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    deleteVendor(message) {
      this.showSuccess(message);
    },
    vendorManagementAdded(message) {
      this.showSuccess(message);
      this.getVendorManagementData();
    },
    vendorManagementUpdated(message) {
      this.showSuccess(message);
      this.getVendorManagementData();
    },
    checkIfPageWasScrolled() {
      if (window.pageYOffset > 0) {
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    async getProductClassOptions() {
      try {
        const response = await CatalogItems.getRestApi().getCatalogItemsProductClasses();
        return response.data;
      } catch (error) {
        this.emitFail(error);
      }
    },
    showWarningDialog(message) {
      this.warningMessage = message;
      this.$refs.warningDialog.openDialog();
    },
    onConfirmAction() {
      this.$refs.updateForm.performUpdate();
    }
  },
};
</script>
