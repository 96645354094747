<template>
  <div class="general-purpose-dropdown">
    <v-select
        v-if="!disabled"
        :readonly="readonly"
        v-model="selected"
        :label="label"
        :placeholder="placeholder"
        :items="selectOptions"
        :rules="[rules.requiredBasedOnPropsValue]"
        :clearable="isClearable"
        persistent-placeholder
        :item-text="itemText"
        :item-value="itemValue"
        attach
        return-object
    />
  </div>
</template>

<script>
import Rules from "../../../assets/rules";

export default {

  data() {
    return {
      selected: null,
      rules: {
        requiredBasedOnPropsValue: (value) => Rules.requiredBasedOnPropsValue(value, this.required)
      },
    };
  },

  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object | String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    selectOptions: Array,
    label: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    isClearable: {
      type: Boolean,
      required: false,
      default: false
    },
    itemText: {
      type: String,
      required: false,
      default: null
    },
    itemValue: {
      type: String,
      required: false,
      default: null
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    },
    selected(value) {
      this.$emit('onOptionChange', value);
    },
  }
};
</script>
