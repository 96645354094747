<template>
  <div class="separator pa-2">
    <table class="line-item-headers caption">
      <tr>
        <td width="14%">Reference Id</td>
        <td width="28%">Type</td>
        <td width="36%">Description</td>
        <td width="20%">Amount</td>
        <td width="30px"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
.help-icon {
  cursor: pointer;
}
</style>
