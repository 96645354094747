<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <!-- reference id -->
        <td width="12%" class="text-truncate top-aligned">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <!-- item information -->
        <td width="15%" class="text-truncate">
          <line-item-description :lineItem="lineItem"/>
        </td>

        <!-- item price -->
        <td width="8%" class="text-truncate text-sm-center">
          <div class="product-item">
            {{ unitPrice ? "$" + unitPrice.toFixed(2) : "N/A" }}
          </div>
        </td>
        <!-- status -->
        <td width="12%">
          <v-btn
              color="primary"
              class="item-status-button"
              v-if="lineItem.purchaseOrder"
              @click="onOpenPurchaseOrder(lineItem.purchaseOrder)"
          >
            {{ itemStatus }}
          </v-btn>
          <v-menu
              offset-y
              style="width: 100%"
              v-else-if="lineItem.inventoryReservations && lineItem.inventoryReservations.length">
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  class="item-status-button"
                  color="primary"
                  dark
              >
                {{ itemStatus }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="reservation in filteredInventoryReservations"
                  :key="reservation.inventoryItemId"
                  @click="onOpenReservation(reservation)"
              >
                <v-list-item-title>
                  Inventory reservation for
                  {{ reservation.amount }} units
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn class="item-status-button" v-else>
            {{ itemStatus }}
          </v-btn>
        </td>
        <!-- ETA -->
        <td width="12%" class="pt-0" style="font-weight: normal">
          <date-picker-with-type-in-text-field
              ref="etaDatePicker"
              v-model="sanitizeETA"
              label=" "
              validationName="ETA"
              :optional="true"
              :disabled="false"
              data-id="eta"
              placeholder=" "
              @input="onETAChange"
              :allowedDates="allowedDates"
          />
        </td>
        <!-- comment -->
        <td width="13%">
          <v-text-field
              :disabled="disableEditingComment"
              v-model="comment"
              class="short-input left-aligned product-item"
          />
        </td>
        <!-- quantity -->
        <td width="10%" class="pt-0">
          <v-text-field
              :disabled="disableEditingQuantity"
              type="number"
              min="0"
              v-model="needed"
              class="short-input product-item"
              placeholder="0"
              @blur="sanitizeQuantity"
          />
        </td>
        <!-- order -->
        <td width="10%" class="pt-0">
          <v-text-field
              v-model="quantity"
              class="short-input product-item"
              disabled
          />
        </td>
        <!-- cost -->
        <td width="10%" class="pt-0">
          <v-text-field
              :disabled="true"
              min="0"
              v-model="price"
              class="short-input product-item"
              @blur="setSanitizedPrice"
              prefix="$"
              placeholder="0.00"
          />
        </td>

        <!-- in fulfillment -->
        <td width="75px" class="fullfillment-icon-container">
          <v-icon
              v-if="isFulfilled"
              color="primary"
              class="product-item"
          >
            check_circle_outline
          </v-icon>
        </td>

        <!-- delete button -->
        <td width="35px" class="ma-0 pb-6">
          <delete-button-with-tooltip
              :canDeleteItems="canDeleteItems"
              :line-item="lineItem"
              :toolTipMessage="toolTipMessage"
              @deleteProductClicked="onDeleteItem"
          />
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import LineItemDescription from ".//../../../components/jobs/productLineItems/LineItemDescription.vue";
import DatePickerWithTypeInTextField from ".//../../../components/common/form/DatePickerWithTypeInTextField.vue";
import DeleteButtonWithTooltip from "../../common/templates/DeleteIconButtonWithTooltip.vue";
import ProductLineItemStatuses from ".//../../../assets/jobBuilder/productLineItemStatuses";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import BoxQuantity from "../../../assets/boxQuantity";
import {isGenerated} from "../../../assets/referenceIds";
import moment from "moment";

export default {
  components: {
    DeleteButtonWithTooltip,
    LineItemDescription,
    DatePickerWithTypeInTextField,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      referenceId: null,
      unitPrice: null,
      needed: null,
      quantity: null,
      price: null,
      status: null,
      comment: null,
      eta: new Date(),
      purchaseOrderItem: null,
      isFulfilled: null,
      toolTipMessage: "You can not delete checked-out products. Please use the Return from Job button and proceed with the return"
    };
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.referenceId = updated.jobLineItem.referenceId;
        this.unitPrice = this.computeUnitPrice(updated);
        this.status = updated.jobLineItem.status;
        this.comment = updated.jobLineItem.comment;
        this.eta = updated.jobLineItem.eta;
        this.needed = updated.jobLineItem.quantity;
        this.quantity = this.computeQuantity(updated.jobLineItem.quantity);
        this.price = CurrencyFormatter.formatCurrency(updated.jobLineItem.price);
        this.isFulfilled = updated.jobLineItem.fulfillmentDate
            ? true
            : false;
        this.purchaseOrderItem = updated.jobLineItem.purchaseOrderItem;
      },
    },
    status(value) {
      this.lineItem.jobLineItem.status = value;
    },
    comment(value) {
      this.lineItem.jobLineItem.comment = value;
      this.$emit('commentUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    eta(value) {
      if (value != null && !moment(value).isSame(moment(this.lineItem.jobLineItem.eta), 'day')) {

        this.lineItem.jobLineItem.eta = value;
        this.$emit('etaUpdated', {
          item: this.lineItem,
          updated: value,
        });
      } else if (value == null) {
        this.$refs.etaDatePicker.clearSelected();
        this.lineItem.jobLineItem.eta = value;
      }
    },
    needed(value) {
      this.quantity = this.computeQuantity(value);
    },
    quantity(value) {
      if (Number(value).toFixed(2) === Number(this.lineItem.jobLineItem.quantity).toFixed(2)) {
        return;
      }
      this.lineItem.jobLineItem.quantity = value;

      if (this.unitPrice) {
        let updatedPrice = CurrencyFormatter.asCurrency(value).multiply(this.unitPrice);
        this.price = CurrencyFormatter.formatCurrency(updatedPrice);
      }
      this.$emit('quantityUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    price(value) {
      if (CurrencyFormatter.asCurrency(value).value === this.lineItem.jobLineItem.price.value) {
        return;
      }
      this.lineItem.jobLineItem.price = CurrencyFormatter.asCurrency(value);
      this.$emit('priceUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
  },

  computed: {
    canDeleteItems() {
      return !this.job.invoiced;
    },
    disableEditingComment() {
      return this.job.invoiced;
    },
    disableEditingQuantity() {
      return this.job.invoiced || this.isFulfilled;
    },
    sanitizeETA: {
      get() {
        return this.eta ? this.eta.split("T")[0] : "";
      },
      set(newName) {
        return newName;
      },
    },
    itemStatus() {
      return ProductLineItemStatuses.defineJobLineItemStatus(this.status);
    },
    filteredInventoryReservations() {
      if (!this.lineItem.inventoryReservations) {
        return [];
      }
      if (this.status === ProductLineItemStatuses.INVENTORY_RESERVED.status) {
        return this.lineItem.inventoryReservations.filter(reservation => reservation.checkedOut === false);
      }
      // If the status is CheckedOut, take the most recent reservations
      else if (this.status === ProductLineItemStatuses.CHECKED_OUT.status) {
        return this.lineItem.inventoryReservations.filter(reservation => reservation.jobProductLineItemId === this.lineItem.jobLineItem.id);
      }
    }
  },

  methods: {
    onDeleteItem(jobLineItem) {
      this.$emit("itemDeleted", jobLineItem.referenceId);
    },
    computeUnitPrice(summary) {
      let fulfillmentDate = summary.jobLineItem.fulfillmentDate;
      let overbillEntry = summary.overbill;
      let supplier = summary.supplierCost;
      let repPrice = summary.repPrice;

      // If already fulfilled, return original unit cost.
      if (fulfillmentDate) {
        return summary.jobLineItem.unitCost;
      } else if (repPrice) {
        return repPrice.cost;
      }
      // Otherwise, return cost from supplier cost + overbill.
      else {
        if (supplier && overbillEntry) {
          return supplier.cost + overbillEntry.overbill;
        }
      }
      return null;
    },
    computeQuantity(needed) {
      return BoxQuantity.roundValueToBoxQuantity(needed, this.lineItem.productCatalogItem.boxQuantity);
    },
    sanitizeQuantity() {
      let parsed = Number.parseFloat(this.quantity);
      if (!Number.isNaN(parsed) && parsed < 0) {
        this.quantity = 0;
      }
    },
    setSanitizedPrice() {
      this.price = CurrencyFormatter.sanitizePrice(this.price);
    },
    onETAChange(value) {
      this.eta = value;
    },
    allowedDates(value) {
      const currentDate = moment();
      return moment(value).isSameOrAfter(currentDate, "day");
    },
    onOpenPurchaseOrder(po) {
      this.$router.push(`/orders/purchases/details/${po.purchaseOrderNumber}`);
    },
    onOpenReservation(reservation) {
      this.$router.push(`/inventory/details/${reservation.inventoryItemId}`);
    },
    isGenerated
  },
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}

.item-row td.top-aligned {
  vertical-align: top;
}

.item-status {
  line-height: 4.4;
  font-weight: 700;
  font-size: 14px;
}

.item-status-button {
  margin-bottom: 20px;
  width: 80%;
}

.fullfillment-icon-container {
  text-align: center;
}

.product-item {
  margin-bottom: 24px;
}
</style>
