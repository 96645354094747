import Users, {User} from "./users";
import {AuthHelper} from "../assets/authHelper";

const Auth = {
    async updateTokens(user: User | null) {
        return Users.getRestApi().refreshToken()
            .then(function (response) {
                const authResponse = response.data;
                if (authResponse.accessToken) {
                    if (AuthHelper.isSafariBrowser()) {
                        const userKey = user?.email ? `user_${user.email}` : `user_${authResponse?.user?.email}`;
                        const accessToken = authResponse.accessToken;
                        const refreshToken = authResponse.refreshToken;
                        sessionStorage.setItem(`${userKey}_accessToken`, accessToken);
                        sessionStorage.setItem(`${userKey}_refreshToken`, refreshToken);
                    }
                }
                return;
            });
    },

    validateToken(token: any) {
        const now = new Date().getTime() / 1000;
        return now < token.exp;
    }
};

export default Auth;
