<template>
  <grid-view
      ref="nav"
      nav-section="installer"
      page-title="List Installations"
      :data-source="dataSource"
      :columnMinWidth="150"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="Status"
        :minWidth="200"
        caption="Status"
        data-type="string"
        :header-filter="{ dataSource: statusFilter }"
    />
    <dx-column
        data-field="BranchName"
        :minWidth="200"
        caption="Branch Name"
        data-type="string"
        :header-filter="{ dataSource: branchesFilter }"
    />
    <dx-column
        data-field="CreatedAt"
        :minWidth="200"
        caption="Created Date"
        data-type="date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="false"
        sort-order="desc"
    />
    <dx-column
        data-field="InstallationRefId"
        :minWidth="200"
        caption="Installation Reference Id"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="SalesRepName"
        :minWidth="200"
        caption="Sales Representative"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="InstallationDate"
        :minWidth="200"
        caption="Installation Date"
        format="MM/dd/yyyy"
        data-type="date"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="EtcDate"
        :minWidth="200"
        caption="ETC Date"
        data-type="date"
        format="MM/dd/yyyy"
        :allowHeaderFiltering="true"
    />
    <dx-column
        data-field="InstallerCrewName"
        :minWidth="200"
        caption="Crew"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CustomerFirstName"
        :minWidth="200"
        caption="Customer First Name"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CustomerLastName"
        :minWidth="200"
        caption="Customer Last Name"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CustomerPrimaryPhone"
        :minWidth="200"
        caption="Cell phone"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CustomerEmail"
        :minWidth="200"
        caption="Customer Email"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="LeadCity"
        :minWidth="200"
        caption="Lead City"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="LeadZipCode"
        :minWidth="200"
        caption="Lead Zip Code"
        data-type="string"
        :allowHeaderFiltering="false"
    />

    <dx-column
        data-field="LatestNote"
        :minWidth="200"
        caption="Latest Note"
        data-type="string"
        :allowHeaderFiltering="false"
    />
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import UserCreateDrawer from "@/components/users/UserCreateDrawer.vue";
import ODataContext from "@/rest/odataContext";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter";

export default {
  components: {
    GridView,
    DxColumn,
    UserCreateDrawer
  },

  data() {
    return {
      dataSource: {
        store: ODataContext['Installations'],
        select: [
          "Id",
          "Status",
          "CreatedAt",
          "BranchName",
          "InstallationRefId",
          "SalesRepName",
          "InstallationDate",
          "EtcDate",
          "InstallerCrewName",
          "CustomerFirstName",
          "CustomerLastName",
          "CustomerPrimaryPhone",
          "CustomerEmail",
          "LeadCity",
          "LeadZipCode",
          "JobRefId",
          "LatestNote",
        ],
        sort: [{selector: "CreatedAt", desc: true}]
      },
      branchesFilter: [],
      statusFilter: [
        {text: "Scheduled", value: "SCHEDULED"},
        {text: "New", value: "NEW"},
      ],
      allowBranchesFilter: false
    };
  },

  created() {
    this.getFilters();
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/jobs/details/${data.JobRefId}`);
    },
    async getFilters() {
      try {
        const user = this.$store.state.loggedInUser;
        const branchesFilter = await CustomizeFilter.getBranchFiltersIfNotBranchUser(user);
        this.setBranchesFilters(branchesFilter);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setBranchesFilters(branchesFilter) {
      if (branchesFilter) {
        this.branchesFilter = branchesFilter;
        this.allowBranchesFilter = true;
      }
    },
  }
};
</script>
