<template>
  <v-snackbar
      v-model="snackbar"
      :top="true"
      :timeout="message.timeout"
  >
    <span :style="{ color: message.color }">{{ message.text }}</span>
    <template v-slot:action>
      <v-btn color="blue" text @click="snackbar = false"> Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: [
    "errorColor",
    "successColor",
    "defaultTimeout"
  ],

  data() {
    return {
      message: {
        text: "",
        color: this.successColor,
        timeout: parseInt(this.defaultTimeout, 10)
      },
      snackbarQueue: [],
      snackbar: false
    };
  },

  computed: {
    hasSnackbarsPending() {
      return this.snackbarQueue.length > 0;
    }
  },

  watch: {
    snackbar() {
      if (!this.snackbar && this.hasSnackbarsPending) {
        this.message = this.snackbarQueue.shift();
        this.$nextTick(() => (this.snackbar = true));
      }
    }
  },

  methods: {
    addMessage(text, error, timeout) {
      this.snackbarQueue.push({
        text,
        color: error ? this.errorColor : this.successColor,
        timeout: timeout ? timeout : parseInt(this.defaultTimeout, 10)
      });
      if (!this.snackbar) {
        this.message = this.snackbarQueue.shift();
        this.snackbar = true;
      }
    }
  }
};
</script>

<style scoped>
.snackbar {
  z-index: 100000;
}
</style>
