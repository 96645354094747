<template>
  <navigation-view ref="nav" page-title="Branch Details" nav-section="admin">
    <template v-slot:content>
      <v-container grid-list-lg fluid>
        <branch-details-form
            :branch="branch"
        />
        <branches-inline-grids
            ref="inlineGrids"
            :branchReferenceId="branchReferenceId"
            :branch="branch"
            @showError="showError"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <branch-update-drawer
          :branch="branch"
          @cancelClicked="onCancel"
          @updateSuccess="onUpdateSuccess"
          @updateFailed="onSubmitFailed"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Branch"
          @click="showDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import NavigationActionButton from "../../views/navigation/NavigationActionButton.vue";
import Branches from "../../rest/branches";
import BranchUpdateDrawer from "../../components/branches/BranchUpdateDrawer.vue";
import BranchesInlineGrids from "../../views/branches/BranchesInlineGrids.vue";
import BranchDetailsForm from "../../components/branches/BranchDetailsForm";
import Roles from "../../assets/roles";
import Constants from "../../assets/constants";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    BranchDetailsForm,
    BranchUpdateDrawer,
    BranchesInlineGrids
  },

  data() {
    return {
      branchReferenceId: null,
      branch: {}
    };
  },

  created() {
    this.display(this.$route.params.branchReferenceId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.branchReferenceId);
    next();
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    display(branchReferenceId) {
      this.$data.branchReferenceId = branchReferenceId;
      this.getBranch();
    },
    async getBranch() {
      try {
        const response = await Branches.getRestApi().getBranch(this.branchReferenceId);
        this.branch = response.data;
      } catch (error) {
        this.showError(error);
      }
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    onUpdateSuccess(data) {
      const message = "Updated branch details successfully!";
      this.showSuccess(message);
      this.$refs.nav.hideDrawer();
      // changes URL route to new reference id param if user updates reference Id
      if (data.referenceId != this.branchReferenceId) {
        this.$router.push(`/branches/details/${data.referenceId}`);
      } else {
        this.getBranch();
        this.refreshActivityLog();
      }
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    onSubmitFailed(error) {
      this.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    showError(error, showWithLineBreaks) {
      this.$refs.nav.showError(error, showWithLineBreaks);
    },
    showSuccess(message) {
      this.$refs.nav.showSuccess(message);
    }
  }
};
</script>
