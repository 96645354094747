<template>
  <div class="separator pa-2">
    <table class="line-item-totals subheading" v-if="totalJobCost" >
      <tr>
        <td class="total-style-job-cost">
          <span class="description"> Total Job Cost: </span>
          {{ formatCurrency(totalJobCost) }}
        </td>
      </tr>
    </table>
    <table class="line-item-totals subheading" v-else>
      <tr>
        <td class="total-style-remaining-balance">
          <span class="description"> Remaining Balance: </span>
          {{ formatCurrency(remainingBalance) }}
        </td>
      </tr>
      <tr>
        <td class="total-style-remaining-balance">
          <span class="description"> Customer balance due: </span>
          {{ formatCurrency(customerBalanceDue) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

export default {
  props: {
    totalJobCost: Number,
    remainingBalance: Number,
    customerBalanceDue: Number,
    currencyPrefix: String
  },

  methods: {
    formatCurrency(curr) {
      return CurrencyFormatter.formatCurrencyWithDollarPrefix(curr);
    },
  }
};
</script>

<style scoped>
td {
  text-align: right;
}

.separator {
  width: 100%;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #999;
}

.line-item-totals {
  width: 100%;
  font-weight: bold;
  text-align: left;
  color: #666;
}

.previous-total-job-cost, .previous-remaining-balance {
  color: #b9afaf;
}

.total-style-job-cost, .total-style-remaining-balance {
  width: 17%;
  font-size: 16px;
}
.description{
  font-size: 16px;
  padding-right: 8%;
}
</style>
