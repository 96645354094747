<template>
  <navigation-view ref="nav" page-title="Branch Management" nav-section="warehouse">
    <template v-slot:content>
      <v-container grid-list-md>
        <div class="headline">Check Out - Branch Transfer</div>
        <v-divider class="detailsDivider"/>
        <v-row>
          <v-col md="3">
            <branches-autocomplete
                ref="shipFrom"
                label="Ship from (Branch)"
                :required="true"
                :selectAllEnabled="false"
                :getId="true"
                :getBranchesOnComponentCreation="false"
                :defaultBranches="userBranches"
                @inputSelected="shipFromBranchSelected"
            />
          </v-col>
          <v-col md="3">
            <branches-autocomplete
                ref="shipTo"
                label="Ship to (Branch)"
                :required="true"
                :selectAllEnabled="false"
                :getId="true"
                :getBranchesOnComponentCreation="false"
                :defaultBranches="filteredUserBranches"
                @inputSelected="shipToBranchSelected"
            />
          </v-col>
          <v-col sm="3">
            <date-picker-with-type-in-text-field
                ref="pickupDatePicker"
                v-model="selectedPickupDate"
                label="Pickup Date"
                id="pickup-date-picker"
                validation-name="Pickup Date"
                :optional="false"
                :readonly="false"
                @input="onPickupDateSelected"
            />
          </v-col>
          <v-col sm="3">
            <v-btn
                color="primary"
                class="searchButton"
                :disabled="false"
                @click="handleBranchTransferSearch"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container md fluid>
        <v-row v-show="showInventoryItemsGrid">
          <v-col md="12">
            <v-card raised light color="white" class="mt-3 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Inventory Items included in the Shipments</v-app-bar-title>
              </v-app-bar>
              <grid-toolbar
                  @onToggleResetGridDialog="clearGridState"
              />
              <inventory-items-from-shipments-check-out-grid
                  :filterConfig="filterConfig"
                  ref="inventoryInShipments"
                  @rowSelected="setCheckOutButtonState"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row v-show="showInventoryItemsGrid">
          <v-col class="d-flex justify-end">
            <v-btn
                large
                color="primary"
                class="checkOutButton"
                :disabled="checkoutDisabled"
                :loading="inProgress"
                @click="onCheckOutClicked"
            >
              Check Out
            </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../navigation/NavigationView.vue";
import BranchesAutocomplete from "../../../components/searchFields/BranchesAutocomplete.vue";
import DatePickerWithTypeInTextField from "../../../components/common/form/DatePickerWithTypeInTextField.vue";
import GridToolbar from "../../../components/common/grid/GridToolbar.vue";
import InventoryItemsFromShipmentsCheckOutGrid
  from "../../../components/inventoryItems/branchTransfer/InventoryItemsFromShipmentsCheckOutGrid.vue";
import DateFormatter from "../../../assets/dateFormatter";
import BranchTransfers from "../../../rest/inventoryItemsBranchTransfer";
import Roles from "@/assets/roles";
import Branches from "@/rest/branches";

export default {
  components: {
    InventoryItemsFromShipmentsCheckOutGrid,
    DatePickerWithTypeInTextField,
    NavigationView,
    BranchesAutocomplete,
    GridToolbar
  },

  data() {
    return {
      filterConfig: null,
      inProgress: false,
      showInventoryItemsGrid: false,
      selectedPickupDate: new Date().toISOString(),
      selectedFromBranchId: null,
      selectedToBranchId: null,
      selectedItems: [],
      checkoutDisabled: true,
      filteredUserBranches: [],
      userBranches: [],
      allBranches: [],
    };
  },
  created() {
    this.getSpecificUserBranches();
    this.getAlActiveBranches();
  },
  watch: {
    selectedFromBranchId: 'branchesWatcher',
    selectedToBranchId: 'branchesWatcher',
  },

  methods: {
    async getSpecificUserBranches() {
      try {
        const response = await Branches.getRestApi().getAllUserBranches();
        this.userBranches = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async getAlActiveBranches() {
      try {
        const response = await Branches.getRestApi().getAllActiveBranches();
        this.filteredUserBranches = response.data;
        this.allBranches = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async handleBranchTransferSearch() {
      if (this.selectedFromBranchId && this.selectedToBranchId && this.selectedPickupDate) {
        this.showInventoryItemsGrid = true;
        this.buildFilterConfig(this.selectedFromBranchId, this.selectedToBranchId, this.selectedPickupDate);
      }
    },
    clearGridState() {
      this.$refs.inventoryInShipments.clearGrid();
    },
    onPickupDateSelected(date) {
      this.selectedPickupDate = date;
    },
    branchesWatcher() {
      if ((this.selectedFromBranchId && this.selectedToBranchId) && (this.selectedFromBranchId !== this.selectedToBranchId)) {
        this.showInventoryItemsGrid = false;
      }

      if (this.selectedToBranchId === this.selectedFromBranchId) {
        this.selectedToBranchId = null;
        return;
      }
      if (this.selectedFromBranchId) {
        this.filteredUserBranches = this.allBranches.filter(b => b.id !== this.selectedFromBranchId);
      }
    },
    async shipFromBranchSelected(branchId) {
      this.selectedFromBranchId = branchId;
    },
    async shipToBranchSelected(branchId) {
      this.selectedToBranchId = branchId;
    },
    buildFilterConfig(fromBranchId, toBranchId, pickupDate) {
      this.filterConfig = [
        ["PickupDate", "=", DateFormatter.formatDateInYYYYMMDDFormat(pickupDate)],
        ["ShipFromBranchId", "=", fromBranchId],
        ["ShipToBranchId", "=", toBranchId]
      ];
    },
    setCheckOutButtonState() {
      let totalSelected = this.$refs.inventoryInShipments.getSelectedRowsCount(true, true);
      this.checkoutDisabled = totalSelected === 0;
    },
    onCheckOutClicked() {
      let selected = this.$refs.inventoryInShipments.getOnlyAvailableForCheckOutSelectedRows();
      let payload = this.buildPayload(selected);
      if (payload.length > 0) {
        this.performCheckOut(payload);
      }
    },
    buildPayload(selectedRows) {
      return selectedRows.map(i => {
        return {
          transferRequestItemId: i.Id,
          inventoryItemId: i.InventoryItemId,
          transferNumber: i.TransferNumber,
          quantity: i.QuantityReserved
        }
      });
    },
    async performCheckOut(payload) {
      try {
        this.inProgress = true;
        const response = await BranchTransfers.getRestApi().checkOutBranchTransferInventoryItems(payload);
        this.onSubmitSuccess();
      } catch (error) {
        this.showNavError(error);
      } finally {
        this.inProgress = false;
      }
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    onSubmitSuccess() {
      this.$refs.nav.showSuccess('Selected inventory items checked out successfully!');
      this.$refs.inventoryInShipments.deselectItems();
      this.clearGridState();
    }
  }
};
</script>

<style scoped>
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}
</style>
