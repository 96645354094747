export default {
    initializeCustomFilterOperations: function (gridInstance) {
        let columnCount = gridInstance.columnCount();
        for (let i = 0; i < columnCount; i++) {
            let filterOperations = gridInstance.columnOption(i).filterOperations;
            if (filterOperations && filterOperations.includes("notcontains")) {
                let notContainsIndex = filterOperations.indexOf("notcontains");
                if (notContainsIndex > -1) {
                    filterOperations.splice(notContainsIndex, 1);
                }
            }
        }
    }
}
