import Roles from "@/assets/roles.js";

const RoleRestrictor = {
  updateConfig: function(user, config) {
    const isBranchUser = Roles.isRoleBranchRole(user.roleId);
    if (
      isBranchUser &&
      config.dataSource.select.includes("BranchReferenceId")
    ) {
      config.dataSource.filter = this.getBranchUserRoleExpression(
        user.branchReferenceIds
      );
      return config;
    } else {
      return config;
    }
  },
  updateSalesRepConfig: function(user, config) {
    config.dataSource.filter = this.getSalesRepRoleExpression(user.id);
    return config;
  },
  getBranchUserRoleExpression: function(branchReferenceIds) {
    if (!branchReferenceIds || branchReferenceIds.length == 0)
      return [["BranchReferenceId", "=", ""]]; // no data
    const conditions = branchReferenceIds.map((branchReferenceId) => [
      "BranchReferenceId",
      "=",
      branchReferenceId,
    ]);
    const expression = [];
    conditions.forEach((condition, i) => {
      expression.push(condition);
      if (i < conditions.length - 1) expression.push("or");
    });
    return expression;
  },
  getSalesRepRoleExpression: function(salesRepId) {
    if (!salesRepId) return [["SalesRepId", "=", -1]];
    // no data
    else return ["SalesRepId", "=", salesRepId];
  },
  applyRoleRestrictions: function(user, gridInstance, isBranchRestricted) {
    const isBranchUser = user.roleId == 4;
    if (isBranchUser && isBranchRestricted) {
      let branchIds = "";
      if (user.branchIds !== undefined && user.branchIds !== null) {
        branchIds = user.branchIds;
      } else if (
        user.branchReferenceIds !== null &&
        user.branchReferenceIds !== null
      ) {
        branchIds = user.branchReferenceIds;
      }
      gridInstance.filter(this.getBranchUserRoleExpression(branchIds)); // applied server-side
    }
  },
  getVendorFilterConfig(vendorId) {
    if (!vendorId) return [["VendorId", "=", -1]];
    // no data
    else return ["VendorId", "=", vendorId];
  },
};

export default RoleRestrictor;
