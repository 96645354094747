<template>
  <v-card-actions>
    <v-row>
      <v-col class="pa-0 ma-0">
        <v-card-actions class="pa-0 ma-0">
          <v-col md="6" align="left">
            <v-spacer></v-spacer>
            <v-btn
                v-show="!hidePreviousStep"
                large
                color="primary"
                outlined
                @click="onGetPreviousStep"
            >
              {{ backName }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="6" align="right" class="pr-2 pl-2">
            <v-btn
                v-if="!hideCancel"
                class="mr-1"
                large
                color="primary"
                outlined
                @click="onCancel"
            >
              {{ cancelName || "Cancel" }}
            </v-btn>
            <v-btn
                class="ml-1"
                v-if="!hideNextStep"
                large
                :loading="nextLoading"
                color="primary"
                @click="onGetNextStep"
                :disabled="disableNextStep"
            >
              {{ nextName }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script>
export default {
  props: {
    nextName: String,
    nextLoading: {
      type: Boolean,
      default: false
    },
    backName: String,
    cancelName: String,
    disableNextStep: {
      type: Boolean,
      default: false,
    },
    hidePreviousStep: {
      type: Boolean,
      default: false,
    },
    hideNextStep: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onGetPreviousStep() {
      this.$emit('getPreviousStep');
    },
    onGetNextStep() {
      this.$emit('getNextStep');
    },
  },
};
</script>
