<template>
  <grid-view
      ref="nav"
      page-title="List Vendors"
      nav-section="admin"
      :actionButtonLabel="isActionButtonDisplayed ? 'Create Vendor' : null"
      :data-source="dataSource"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        :cell-template="StatusRenderer"
        data-type="string"
    />
    <dx-column
        data-field="Name"
        caption="Name"
        :minWidth="200"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        caption="Address"
        :minWidth="250"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ZipCode"
        caption="Zip Code"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="PhoneNumber"
        caption="Phone"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        :allowHeaderFiltering="false"
        data-type="string"
    />

    <template v-slot:drawerContent>
      <vendor-drawer-form
          :create="true"
          ref="vendorCreateForm"
          title="Create Vendor"
          submit-label="Save Vendor"
          @cancelClicked="onCreateVendorCancel"
          @submitClicked="onCreateVendorSubmit"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import VendorDrawerForm from "@/components/vendors/VendorDrawerForm.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import Vendors from "@/rest/vendors";

import ODataContext from "@/rest/odataContext";
import Roles from "../../assets/roles";
import StatusRenderer from "../../components/common/grid/StatusRenderer";
import Format from "../../components/common/Format";


export default {
  components: {
    GridView,
    VendorDrawerForm,
    DxColumn
  },
  data() {
    return {
      dataSource: {
        store: ODataContext['Vendors'],
        select: [
          "ReferenceId",
          "Name",
          "Address",
          "City",
          "ZipCode",
          "PhoneNumber",
          "Status",
          "Active"
        ]
      }
    };
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onCreateVendorCancel() {
      this.$refs.vendorCreateForm.reset();
      this.$refs.nav.hideDrawer();
    },
    onRowSelected(data) {
      this.$router.push(`/vendors/details/${data.ReferenceId}`);
    },
    async onCreateVendorSubmit(payload) {
      try {
        await this.createVendor(payload);
        this.onCreateVendorSuccess();
      } catch (error) {
        this.onCreateVendorFailed(error);
      }
    },
    async createVendor(params) {
      try {
        return await Vendors.getRestApi().createVendor(params);
      } catch (error) {
        throw error;
      }
    },
    onCreateVendorSuccess() {
      this.$refs.vendorCreateForm.reset();
      this.$refs.nav.showSuccess("Create new vendor successfully!");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onCreateVendorFailed(error) {
      this.$refs.nav.showError(error);
      this.$refs.vendorCreateForm.setInProgress(false);
    },
    StatusRenderer,
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    }
  }
};
</script>
