import Constants from "./constants";
import store from "@/store";

const Roles = {
    getLoggedInUser() {
        return store.state.loggedInUser;
    },

    isRoleBranchRole(roleId) {
        return roleId &&
            (roleId === Constants.userRoles.branchUser
                || roleId === Constants.userRoles.branchAdminUser
                || roleId === Constants.userRoles.generalManager);
    },

    isRoleRehashRep(roleId) {
        return (roleId === Constants.userRoles.rehashRepUser);
    },

    isAdminUser() {
        const user = this.getLoggedInUser();
        if (user === undefined || user === null) {
            return false;
        } else {
            return user.roleId === Constants.userRoles.admin;
        }
    },
    isSuperAdminOrAdminUser() {
        return this.isSuperAdminUser() || this.isAdminUser();
    },
    isSuperAdminUser() {
        const user = this.getLoggedInUser();
        if (user === undefined || user === null) {
            return false;
        } else {
            return user.roleId === Constants.userRoles.superAdmin;
        }
    },
    isRehashUser() {
        const user = this.getLoggedInUser();
        if (user === undefined || user === null) {
            return false;
        } else {
            return user.roleId === Constants.userRoles.rehashRepUser;
        }
    },
    isAnyAdminUser() {
        return this.isAdminUser() || this.isBranchAdmin() || this.isSuperAdminUser();
    },

    isBranchAdmin() {
        const user = this.getLoggedInUser();
        if (user === undefined || user === null) {
            return false;
        } else {
            return user.roleId === Constants.userRoles.branchAdminUser
                || user.roleId === Constants.userRoles.generalManager;
        }
    },

    isAnyAdminRoleUser() {
        const user = this.getLoggedInUser();
        if (!user) {
            return false;
        } else {
            return user.roleId === Constants.userRoles.admin
                || user.roleId === Constants.userRoles.branchAdminUser
                || user.roleId === Constants.userRoles.generalManager;
        }
    },

    isRegularOrBranchUser() {
        const user = this.getLoggedInUser();
        return user ? user.roleId === Constants.userRoles.branchUser || user.roleId === Constants.userRoles.regular : false;
    },

    isGeneralManager() {
        const user = this.getLoggedInUser();
        return user ? user.roleId === Constants.userRoles.generalManager : false;
    },

};
export default Roles;
