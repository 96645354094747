<template>
  <v-form ref="form">
    <v-container class="mt-4">
      <v-row>
        <v-col md="12">
          <v-text-field
              label="Line Item"
              v-model="jobLineItemDescription"
              readonly
          />
        </v-col>
        <v-col md="12">
          <v-text-field
              v-model="installerCompanyName"
              label="Installer"
              readonly
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="installationDate"
              label="Installation Date"
              readonly
          />
        </v-col>
        <v-col md="3">
          <v-text-field
              readonly
              v-model="installationTimeOfDay"
              placeholder=" "
          />
        </v-col>
        <v-col md="12">
          <v-textarea
              v-model="comment"
              placeholder=" "
              persistent-placeholder
              label="Comment"
              data-id="installation-comment"
              readonly
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      jobLineItemDescription: null,
      installerCompanyName: null,
      installationDate: null,
      installationTimeOfDay: null,
      comment: null
    };
  },

  methods: {
    loadForm(data) {
      this.jobLineItemDescription = data.jobLineItem.description;
      this.installerCompanyName = data.installer.companyName;
      this.installationDate = moment(data.installationDate).format("MM/DD/YYYY");
      this.installationTimeOfDay = data.installationTime;
      this.comment = data.comment;
    }
  }
};
</script>
