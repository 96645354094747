<template>
  <grid-view
      ref="nav"
      page-title="List Customers"
      nav-section="customer"
      secondaryActionButtonLabel="New Lead"
      action-button-label="Create Customer"
      :data-source="dataSource"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="FirstName"
        :fixed="true"
        caption="First Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LastName"
        :fixed="true"
        caption="Last Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ZipCode"
        caption="Zip Code"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="PrimaryPhone"
        caption="Phone"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="AlternatePhone"
        caption="Alt Phone"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Email"
        :minWidth="225"
        caption="Email"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <template v-slot:drawerContent>
      <customer-drawer-form
          ref="customerDrawerForm"
          title="Create Customer"
          :isCreateForm="true"
          submit-label="Save Customer"
          :inProgress="inProgress"
          @cancelClicked="onDrawerCancel"
          @submitClicked="onCreateCustomer"
      />
    </template>

    <template v-slot:wizardContent>
      <lead-wizard
          ref="leadWizard"
          @successCustomer="onSubmitWizardCustomerSuccess"
          @successLead="onSubmitWizardLeadSuccess"
          @error="onSubmitWizardFail"
          @cancel="onWizardCancel"
          @onError="onSubmitWizardFail"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "../common/GridView.vue";
import CustomerDrawerForm from "../../components/customers/CustomerDrawerForm.vue";
import LeadWizard from "../../components/leads/LeadWizard.vue";
import Format from "../../components/common/Format";
import Customers from "../../rest/customers";
import ODataContext from "@/rest/odataContext";
import {DxColumn} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    GridView,
    CustomerDrawerForm,
    DxColumn,
    LeadWizard
  },
  data() {
    return {
      inProgress: false,
      dataSource: {
        store: ODataContext['Customers'],
        select: [
          "ReferenceId",
          "FirstName",
          "LastName",
          "Address",
          "City",
          "ZipCode",
          "PrimaryPhone",
          "AlternatePhone",
          "Email"
        ]
      }
    };
  },

  methods: {
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    },
    onDrawerCancel() {
      this.$refs.customerDrawerForm.$refs.form.reset();
      this.$refs.nav.hideDrawer();
    },
    onRowSelected(data) {
      this.$router.push(`/customers/details/${data.ReferenceId}`);
    },
    async createCustomer(payload) {
      try {
        this.inProgress = true;
        const response = await Customers.getRestApi().createCustomer(payload);
        this.inProgress = false;
        return response.data;
      } catch (error) {
        this.inProgress = false;
        throw error;
      }
    },
    async onCreateCustomer(payload) {
      try {
        await this.createCustomer(payload);
        this.onCreateCustomerSuccess();
      } catch (error) {
        this.onCreateCustomerFailed(error);
      }
    },
    async onCreateCustomerSuccess() {
      this.$refs.customerDrawerForm.reset();
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
      this.$refs.nav.showSuccess("Create new customer successfully!");
    },
    onCreateCustomerFailed(error) {
      this.$refs.nav.showError(error);
    },
    onSubmitWizardCustomerSuccess() {
      this.$refs.nav.showSuccess("Customer created successfully");
      this.$refs.nav.refreshGrid();
    },
    onSubmitWizardLeadSuccess() {
      this.$refs.nav.showSuccess("Lead created successfully.");
      this.$refs.nav.refreshGrid();
      this.hideWizard();
      this.$refs.leadWizard.resetWizard();
    },
    onSubmitWizardFail(error) {
      this.$refs.nav.showError(error);
    },
    onWizardCancel() {
      this.hideWizard();
    },
    hideWizard() {
      this.$refs.nav.hideWizard();
    }
  }
};
</script>
