<template>
  <div style="padding-left: 10px">
    <v-row>
      <v-col md="12" class="ma-1">
        <table class="line-item-headers caption">
          <tr>
            <td width="45%" class="text-sm-left">Item Information</td>
            <td width="20%">Quantity</td>
            <td width="15%">Ordered</td>
            <td width="25%">Price</td>
            <td width="10px"></td>
          </tr>
        </table>

        <v-divider/>
      </v-col>

      <v-row class="line-items-wrapper ma-2 mb-6">
        <purchase-order-line-items
            class="ma-1"
            v-for="(item, index) in selectedItems"
            :key="index"
            :index="index"
            :line-item="item"
            :readonly="false"
            :step="3"
            @onComputeQuantity="onComputeQuantity"
            @validQuantityEntered="validQuantityEntered"
        />
      </v-row>
    </v-row>
  </div>
</template>

<script>
import PurchaseOrderLineItems from "../../../components/purchaseOrders/bulkPurchaseOrder/LineItems.vue";

export default {
  components: {
    PurchaseOrderLineItems
  },

  props: {
    selectedItems: {
      type: Array,
      required: true
    }
  },

  methods: {
    onComputeQuantity(computedQuantity, index) {
      this.$emit('onComputeQuantity', computedQuantity, index)
    },
    validQuantityEntered() {
      this.$emit('validQuantityEntered');
    }
  }
};
</script>

<style scoped>

.textFields {
    padding-top: 16px;
}

.line-items-wrapper {
  flex-direction: column;
}
</style>
