<template>
  <modal-dialog
      ref="dialog"
      title="Create New Action"
      width="500"
      :visible="dialogVisible"
      submitLabel="Add Action"
      cancelLabel="Cancel"
      @submitClicked="onSaveActionButtonClick"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
      :loading="loading"
  >
    <v-form ref="nextActionDialogForm">
      <v-container>
        <v-row class="mt-4">
          <v-col sm="12">
            <v-text-field
                disabled
                label="User"
                v-model="userName"
                id="add-note-user-name"
            />
          </v-col>
          <br/>
          <v-col sm="12">
            <date-picker-with-type-in-text-field
                v-model="nextActionDate"
                label="* Next Action Date"
                id="add-next-action-dialog-next-action-date"
                ref="nextActionDatePicker"
                :optional="false"
                :validation-name="'Next Action Date'"
                :readonly="false"
                :allowed-dates="allowedDates"
            />
          </v-col>
          <br/>
          <v-col sm="12">
            <v-textarea
                ref="note"
                v-model="nextAction"
                rows="4"
                label="Note"
                placeholder=" "
                persistent-placeholder
                counter="2020"
                :rules="[rules.required, rules.max2020]"
                id="add-note-modal"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField.vue";
import Rules from "@/assets/rules.js";
import moment from "moment";

const formDefaults = {
  nextAction: null,
  dialogVisible: false,
  loading: false,
  nextActionDate: null
};
export default {
  components: {
    ModalDialog,
    DatePickerWithTypeInTextField
  },
  props: {
    userName: String
  },

  data() {
    return Object.assign(
        {
          error: null,
          rules: {
            required: value => !!value || "Required",
            max2020: value => {
              return Rules.maxValue(value, 2020);
            }
          }
        },
        formDefaults
    );
  },

  watch: {
    dialogVisible(val) {
      if (val) {
        this.$nextTick(this.$refs.note.focus);
      }
    }
  },

  methods: {
    reset() {
      this.$refs.nextActionDialogForm.resetValidation();
      this.$refs.nextActionDatePicker.clearSelected();
      this.nextAction = null;
      this.nextActionDate = null;
      this.loading = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    setLoadingFalse() {
      this.loading = false;
    },
    onSaveActionButtonClick() {
      const valid = this.$refs.nextActionDialogForm.validate();
      if (valid) {
        this.loading = true;
        this.$emit('saveNexActionButtonClicked', this.nextAction, this.nextActionDate);
      }
    },
    onCancelClicked() {
      this.reset();
      this.dialogVisible = false;
    },
    allowedDates(value) {
      const currentDate = moment();
      return moment(value).isSameOrAfter(currentDate, "day");
    },
  }
};
</script>
