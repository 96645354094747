<template>
  <drawer-form
      ref="createCatalogItemForm"
      title="Create Product Catalog Item"
      submit-label="Save Item"
      :showCheckbox="true"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <catalog-item-form-body
        ref="formBody"
        type="create"
        :multipleCreate="multipleCreateItem"
        :productClassOptions="productClassOptions"
        @emitWarning="emitWarning"
        @emitFail="emitFail"
    />
    <duplicate-vendor-and-sku-dialog
        ref="duplicate"
        @confirmed="completeCreate"
    />
  </drawer-form>
</template>

<script>
import CatalogItemFormBody from "../CatalogItemFormBody.vue";
import DuplicateVendorAndSkuDialog from "../DuplicateVendorAndSkuDialog.vue";
import ProductCatalogItems from "../../../rest/catalogItems";
import DrawerForm from "../../common/form/DrawerForm.vue";
import ErrorMessages from "../../../assets/errorMessages";
import BoxQuantity from "@/assets/boxQuantity";

export default {
  components: {
    CatalogItemFormBody,
    DuplicateVendorAndSkuDialog,
    DrawerForm,
  },

  data() {
    return {
      multipleCreateItem: false,
      inProgress: false,
    };
  },

  props: {
    productClassOptions: Array
  },

  methods: {
    onCancel() {
      this.inProgress = false;
      this.resetForm();
      this.$emit('cancelClicked');
    },
    onSubmit() {
      // const vid = this.$refs.formBody.getVendorId();
      // const vsku = this.$refs.formBody.getVendorSku();
      // this.handleCreate(vid, vsku);
      this.completeCreate();
    },
    async getMatchesForSku(vendorSku) {
      try {
        let matches = await ProductCatalogItems.getRestApi().getCatalogItemsByVendorSku(vendorSku);
        return matches.data;
      } catch (error) {
        this.emitFail(error);
        return [];
      }
    },
    /** Checks for duplicate items with vendor id/sku identical */
    async handleCreate(vendorId, vendorSku) {
      try {
        let matches = await this.getMatchesForSku(vendorSku);
        let hadDuplicate = false;
        matches.forEach((match) => {
          if (match.vendorId === vendorId) {
            hadDuplicate = true;
          }
        });
        if (hadDuplicate) {
          this.$refs.duplicate.openDialog();
        } else {
          this.completeCreate();
        }
      } catch (error) {
        this.emitFail(error);
        return true;
      }
    },
    async completeCreate() {
        if (this.isValidatedForm()) {
          this.inProgress = true;
          let params = this.buildParams();
          const validOnUniqueness = await this.$refs.formBody.validateUniqueness(params);
          if (validOnUniqueness) {
            try {
              await this.performUpdate();
            } catch (error) {
              this.emitFail(error);
            }
          }
          this.inProgress = false;
        }
    },
    async performUpdate() {
      const payload = this.getPayload();
      await this.createCatalogItem(payload);
      this.handleFormAfterSubmitSuccess();
    },
    handleFormAfterSubmitSuccess() {
      if (this.ifAddAnotherEntry()) {
        this.handleAddAnotherEntry();
      } else {
        this.handleCreateOnlyOneEntry();
      }
    },
    handleCreateOnlyOneEntry() {
      this.emitSuccess();
      this.resetForm();
    },
    ifAddAnotherEntry() {
      return this.$refs.createCatalogItemForm.getCheckboxValue();
    },
    getPayload() {
      return this.$refs.formBody.buildPayload();
    },
    resetForm() {
      this.$refs.createCatalogItemForm.resetValidation();
      this.$refs.createCatalogItemForm.resetCheckboxValue();
      this.$refs.formBody.loadForm(this.$refs.formBody.resetToDefaults());
      this.$refs.formBody.reloadStyles();
      this.multipleCreateItem = false;
    },
    async createCatalogItem(payload) {
      try {
        await ProductCatalogItems.getRestApi().createCatalogItem(payload);
      } catch (error) {
        throw error;
      }
    },
    emitSuccess(data) {
      this.$emit('submitSuccess', data);
    },
    emitFail(error) {
      this.$emit('submitFailed', error);
    },
    emitWarning(warning) {
      this.inProgress = false;
      this.$emit('showWarning', warning);
    },
    isValidatedForm() {
      return this.$refs.createCatalogItemForm.validate();
    },
    createNextItemObject() {
      const nextItem = this.getPayload();
      nextItem.color = null;
      nextItem.internalSku = null;
      nextItem.vendorSku = null;
      return nextItem;
    },
    handleAddAnotherEntry() {
      // sets multipleCreate flag here so form doesn't disable form fields prior to first submit
      this.multipleCreateItem = true;
      this.$emit('showSuccess');
      const nextItem = this.createNextItemObject();
      this.$refs.formBody.loadForm(nextItem);
      this.focusOnColorField();
    },
    focusOnColorField() {
      this.$refs.formBody.focusOnColorField();
      this.$refs.createCatalogItemForm.resetValidation();
    },
    buildParams() {
      return {
        productTypeId: this.$refs.formBody.productTypeId,
        styleName: this.$refs.formBody.styleName,
        color: this.$refs.formBody.color,
        boxQuantity: BoxQuantity.formatBoxQuantity(this.$refs.formBody.boxQuantity),
        productClassId: this.$refs.formBody.productClass ? this.$refs.formBody.productClass.id : null,
        privateLabelStyleName: this.$refs.formBody.privateLabelStyleName,
        productCatalogItemId: null
      }
    }
  },
};
</script>

<style scoped>
.checkboxContainer {
  display: flex;
  justify-content: flex-end;
}

.checkbox {
  padding: 0;
  margin: 0;
}
</style>
