<template>
  <modal-dialog
      ref="dialog"
      :title="title"
      :width="width"
      :visible="dialogVisible"
      :submitLabel="confirmationLabel"
      :cancelLabel="cancelLabel"
      :extraLabel="extraLabel"
      :submitEnabled="submitEnabled"
      @onExtraButtonClicked="onExtraButtonClicked"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <div v-if="multiLineError">
          <div class="ma-1" v-for="line in errorLines" :key="line">
            {{ line }}
          </div>
        </div>
        <div v-else>
          <div>{{ message }}</div>
        </div>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../ModalDialog.vue";

const formDefaults = {
  dialogVisible: false,
  errorLines: []
};
export default {
  components: {
    ModalDialog
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 500
    },
    title: String,
    confirmationLabel: String,
    cancelLabel: String,
    extraLabel: String,
    message: {
      type: [String, null],
      default: ''
    },
    submitEnabled: {
      type: Boolean,
      default: true,
      required: false
    },
    multiLineError: Boolean
  },

  data() {
    return Object.assign({}, formDefaults);
  },

  watch: {
    message: {
      handler(val) {
        this.splitToLines(val);
      },
      immediate: true
    },
    showDialog: {
      handler(val) {
        this.changeModalVisibility(val);
      },
      immediate: true
    }
  },

  methods: {
    changeModalVisibility(value) {
      value ? this.openDialog() : this.closeDialog();
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.closeDialog();
      this.$emit('cancelled');
    },
    async onSubmitClicked() {
      this.closeDialog();
      this.$emit('confirmed');
    },
    onExtraButtonClicked() {
      this.$emit('extraButtonClicked');
    },
    splitToLines(value) {
      if (value) {
        this.errorLines = this.message.split('\n');
      }
    },
  }
};
</script>
