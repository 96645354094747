import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Backend version.
 */
export class SalesRepTimeBlocks {
    id: bigint;
    name: string;
    slotDate: string;
    startTime: string;
    endTime: string;
    color: string;
    marketId: bigint;
    maxSlots: bigint;
    usedSlots: bigint;

    constructor(
        id: bigint,
        name: string,
        slotDate: string,
        startTime: string,
        endTime: string,
        color: string,
        marketId: bigint,
        maxSlots: bigint,
        usedSlots: bigint
    ) {
        this.id = id;
        this.name = name;
        this.slotDate = slotDate;
        this.startTime = startTime;
        this.endTime = endTime;
        this.color = color;
        this.marketId = marketId;
        this.maxSlots = maxSlots;
        this.usedSlots = usedSlots;
    }
}

/**
 * REST interface provided for sales representatives time blocks
 */
export interface SalesRepTimeBlocksRestApi {
    getTimeBlocksByMarketIdAndDate(marketId: String, slotDate: string): AxiosPromise<SalesRepTimeBlocks[]>;
    getActiveSalesRepsByTimeBlockIdAndSelectedSalesRep(timeBlockId: String, selectedSalesRep: String): AxiosPromise<SalesRepTimeBlocks[]>;

    /** @deprecated */
    getSalesRepsBySlotByDate(marketId: String, slotDate: string, slotTime: string): AxiosPromise<SalesRepTimeBlocks[]>;
}

/**
 * REST implementation provided for sales representatives time blocks
 */
class Implementation implements SalesRepTimeBlocksRestApi {
    getActiveSalesRepsByTimeBlockIdAndSelectedSalesRep(timeBlockId: string, selectedSalesRepRefId: string): AxiosPromise<SalesRepTimeBlocks[]> {
        return Axios.axiosInstance().get<SalesRepTimeBlocks[]>(
            `/sales-rep-time-block/sales-representatives-by-timeblock/${timeBlockId}/${selectedSalesRepRefId}`
        );
    }
    getTimeBlocksByMarketIdAndDate(marketId: String, slotDate: string): AxiosPromise<SalesRepTimeBlocks[]> {
        return Axios.axiosInstance().get<SalesRepTimeBlocks[]>(`/sales-rep-time-block/${marketId}/${slotDate}`);
    }
    /** @deprecated */
    getSalesRepsBySlotByDate(marketId: string, slotDate: string, slotTime: string): AxiosPromise<SalesRepTimeBlocks[]> {
        return Axios.axiosInstance().get<SalesRepTimeBlocks[]>(
            `/sales-rep-time-block/salesRepresentatives/${marketId}/${slotDate}/${slotTime}`
        );
    }
}

const SalesRepTimeBlock = {
    getRestApi(): SalesRepTimeBlocksRestApi {
        return new Implementation();
    }
};

export default SalesRepTimeBlock;
