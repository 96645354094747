<template>
  <modal-dialog
      ref="dialog"
      title="Add Schedule Event"
      width="400"
      :visible="dialogVisible"
      submitLabel="Add Event"
      cancelLabel="Cancel"
      @submitClicked="onAddClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <v-form ref="form">
      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs6>
            <date-picker-with-type-in-text-field
                v-model="dateStart"
                label="Event Start Date"
                validationName="Start date"
                :allowed-dates="allowedDates"
                required
            />
          </v-flex>
          <v-flex xs6>
            <time-picker-field
                v-model="timeStart"
                label="Event Start Time"
                optional="true"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs6>
            <date-picker-with-type-in-text-field
                v-model="dateEnd"
                label="Event End Date"
                validationName="End date"
                :allowed-dates="allowedDates"
                required
            />
          </v-flex>
          <v-flex xs6>
            <time-picker-field v-model="timeEnd" label="Event End Time"/>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
                v-model="description"
                label="Event Description"
                optional="true"
                placeholder=" "
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField.vue";
import TimePickerField from "@/components/common/form/TimePickerField.vue";
import SalesHome from "@/rest/salesHome";
import moment from "moment";

const formDefaults = {
  dateStart: null,
  dateEnd: null,
  timeStart: null,
  timeEnd: null,
  description: null
};

/** @deprecated */
export default {
  data() {
    return Object.assign(
        {
          dialogVisible: false,
          error: null
        },
        formDefaults
    );
  },
  components: {
    ModalDialog,
    DatePickerWithTypeInTextField,
    TimePickerField
  },

  props: ["salesRepReferenceId"],

  watch: {
    salesRepReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  methods: {
    getPayload() {
      const payload = {};

      payload.dateStart = this.dateStart;
      payload.dateEnd = this.dateEnd;
      payload.timeStart = this.timeStart;
      payload.timeEnd = this.timeEnd;

      payload.description = this.description;
      payload.salesRepReferenceId = this.$props.salesRepReferenceId;

      return payload;
    },

    allowedDates(val) {
      const testDate = moment(val, "YYYY-MM-DD");
      return testDate >= moment().subtract(1, "day");
    },

    refresh() {
    },
    reset() {
      Object.assign(this.$data, formDefaults);
      this.$refs.form.reset();
    },
    openDialog() {
      this.reset();
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onAddClicked() {
      if (this.$refs.form.validate(true)) {
        const component = this;

        SalesHome.getRestApi()
            .postSalesRepEvent(this.getPayload())
            .then(() => {
              component.$emit("eventSaved");
              component.closeDialog();
            })
            .catch(() => {
              component.snackbar = true;
            });
      }
    },
    onCancelClicked() {
      this.dialogVisible = false;
    }
  }
};
</script>
