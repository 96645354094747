<template>
  <div>
    <vendor-cost-grid
      :costs="costs"
      :catalogItemId="catalogItemId"
      :internalSku="internalSku"
      :isAddButtonDisplayed="isAddButtonDisplayed"
      :isMigratedItem="isMigratedItem"
      @deleteClicked="onDeleteCost"
      @costAdded="handleCostAdded"
    />
    <error-modal
        ref="errorModal"
        :error="error"
        :title="errorModalTitle"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </div>
</template>

<script>
import moment from "moment";
import VendorCostGrid from "../../components/catalogItems/VendorCostGrid.vue";
import ProductCatalogItems from "../../rest/catalogItems";
import ErrorModal from "../../components/navigation/ErrorModal.vue";
import SalesRepPrices from "../../rest/salesRepPrices";

export default {
  components: {
    VendorCostGrid,
    ErrorModal
  },

  props: {
    catalogItemId: {},
    overbill: {},
    isAddButtonDisplayed: {
      type: Boolean,
      default: true
    },
    internalSku: {
      type: String,
      required: true,
    },
    isMigratedItem: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      costs: [],
      activeCost: null,
      isErrorModalOpen: false,
      error: null
    };
  },

  computed: {
    errorModalTitle() {
      let name = this.isMigratedItem ? 'rep price' : 'vendor cost';
      return `Can\'t delete existing ${name}`;
    }
  },

  watch: {
    internalSku: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  methods: {
    refresh() {
      if (this.isMigratedItem) {
        SalesRepPrices.getRestApi().getSalesRepPriceByCatalogItemById(this.internalSku)
          .then((response) => {
            this.costs = response.data;
          })
          .catch((error) => {
            this.emitError(error);
          });
      } else {
        this.performGetCatalogItemCostsRequestFormNotMigratedItem();
      }
    },
    performGetCatalogItemCostsRequestFormNotMigratedItem() {
      let component = this;
      ProductCatalogItems.getRestApi()
        .getCatalogItemCosts(this.catalogItemId)
        .then((response) => {
          let costs = response.data;
          let current = moment().valueOf();
          const sorted = costs.sort(
            (a, b) =>
              moment(b.effectiveOn).utc().valueOf() -
              moment(a.effectiveOn).utc().valueOf()
          );

          // Find active cost and set flag in its data.
          component.activeCost = null;
          sorted.forEach((cost) => {
            if (!component.activeCost) {
              let effective = moment(cost.effectiveOn).utc().valueOf();
              let active = effective <= current;
              if (active) {
                component.activeCost = cost;
              }
            }
          });
          costs.forEach((cost) => {
            cost.effective = component.activeCost === cost;
          });
          component.costs = costs;
        })
        .catch((error) => {
          component.emitError(error);
        });
    },
    saveOverbill(data) {
      const params = {overbill: data};
      ProductCatalogItems.getRestApi()
        .updateCatalogItemOverbill(this.catalogItemId, params)
        .then(() => {
          this.$refs.totalUnitCostInformation.closeEditOverbillDialog();
          this.$emit('overbillUpdatedSuccess', data);
        })
        .catch((error) => {
          this.emitError(error);
        });
    },
    onDeleteCost(cellData) {
      if (cellData.data.effective === true || moment(cellData.data.effectiveOn).isBefore(moment())) {
        this.showErrorModal(new Error());
      }
      this.performDeleteCostRequest(cellData);
    },
    performDeleteCostRequest(cellData) {
      if (this.isMigratedItem) {
        //request for migrated items
        SalesRepPrices.getRestApi().removeRepPrice(this.internalSku, cellData.data.id)
          .then(() => {
            this.refresh();
            this.emitCostChanged();
          })
          .catch((error) => {
            this.emitError(error);
          });
      } else {
        throw new Error("Vendor Cost deletion is restricted for non-migrated items");
        //request for non-migrated items. Clean '.ts' file afterward
        // ProductCatalogItems.getRestApi()
        //   .removeCatalogItemCost(this.catalogItemId, cellData.data.id)
        //   .then(() => {
        //     this.refresh();
        //     this.emitCostChanged();
        //   })
        //   .catch((error) => {
        //     this.emitError(error);
        //   });
      }
    },
    handleCostAdded() {
      this.refresh();
      this.emitCostChanged();
    },
    emitError(error) {
      this.$emit('catalogVendorCostsError', error);
    },
    emitCostChanged() {
      this.$emit('catalogVendorCostChanged');
    },
    showErrorModal(error) {
      let name = this.isMigratedItem ? 'rep price' : 'vendor cost'
      let capitalizedName = this.isMigratedItem ? 'Rep price' : 'Vendor cost'
      error = `Unable to delete ${name} with dates in the past. ${capitalizedName} can be updated only with future dates`;
      this.error = error;
      this.isErrorModalOpen = true;
      throw new Error(error);
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    }
  },
};
</script>

<style scoped></style>
