<template>
  <v-card flat class="pa-2 mb-0 mr-1">
    <v-simple-table name="header" class="line-items body-1">
      <tr class=" flex-row align-baseline" style="justify-content: flex-end;">
        <td width="55%">
          <span class="title">
            <strong>{{ customer.fullName }}</strong>
            -
            {{ lead.address }} {{ lead.city }} {{ lead.state }}
            {{ lead.zipCode }},
           <v-icon class="fc-short">call</v-icon>
            {{ formattedPhoneNumber }}
          </span>
        </td>
        <td>
          <div class="contract-prices-column">
            <div class="contract-prices-container">
              <div class="total-contract-price-container">
                <strong>{{
                    warrantyLineItem !== null ? "Contract Price (without warranty)" : "Contract Price"
                  }}:</strong>
                <v-text-field
                    :disabled="disableEditingContractPrice"
                    v-model="priceWithoutWarranty"
                    class="short-input contract-price-input"
                    prefix="$"
                    @focus="unFormatPrice"
                    @blur="formatPrice"
                />
              </div>
              <div v-if="warrantyLineItem !== null" class="total-contract-price-container">
                <strong>Total Contract Price:</strong>
                <div class="total-contract-price">
                  {{ totalPrice }}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="mt-2">

        </td>
        <td class="float-end">
          <main-job-button
              v-if="jobButtonActive"
              :loading="loading"
              :submitLabel="submitLabel"
              @onSaveLineItems="onSaveLineItems"
              @onStartFulfillment="onStartFulfillment"
              @onInvoiceJob="onInvoiceJob"
          />
        </td>
      </tr>
    </v-simple-table>

    <p class="save-error">{{ saveError }}</p>
  </v-card>
</template>

<script>
import Format from "../common/Format";
import CurrencyFormatter from "../../assets/jobBuilder/currencyFormatter";
import MainJobButton from "./MainJobButton.vue";

export default {
  components: {MainJobButton},

  props: {
    job: {
      type: Object,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true | null,
    },
    warrantyLineItem: {
      type: Object | null,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    submitLabel: String,
    jobButtonActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },

  data() {
    return {
      contractLineItem: null,
      referenceId: null,
      type: null,
      priceWithoutWarranty: null,
      totalPrice: null,
      comment: null,
      saveError: null,
    };
  },

  computed: {
    disableEditingContractPrice() {
      return this.job.invoiced;
    },
    formattedPhoneNumber() {
      return Format.phoneNumber(this.customer.primaryPhone);
    },
    warrantyPrice() {
      const warrantyPrice = this.warrantyLineItem?.jobLineItem?.price ? this.warrantyLineItem.jobLineItem.price : 0;
      return CurrencyFormatter.formatCurrency(warrantyPrice);
    },
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.contractLineItem = updated;
        this.referenceId = updated.jobLineItem.referenceId;
        this.type = updated.jobLineItem.type;
        this.status = null;
        this.comment = null;
        this.quantity = null;
        this.priceWithoutWarranty = CurrencyFormatter.formatCurrency(updated.jobLineItem.contractPriceWithoutWarranty.value);
        this.totalPrice = CurrencyFormatter.formatCurrency(updated.jobLineItem.price.value);
      },
    },
    priceWithoutWarranty(updated) {
      let newPriceWithoutWarranty = CurrencyFormatter.asCurrency(updated);
      if (newPriceWithoutWarranty.value === this.lineItem.jobLineItem.contractPriceWithoutWarranty.value) {
        return;
      }
      this.contractLineItem.jobLineItem.contractPriceWithoutWarranty = newPriceWithoutWarranty;
      if (this.warrantyLineItem === null) {
        this.contractLineItem.jobLineItem.price = newPriceWithoutWarranty;
      }
      this.$emit('priceWithoutWarrantyUpdated', {
        item: this.contractLineItem,
        priceWithoutWarranty: newPriceWithoutWarranty,
      });
    },
    warrantyLineItem: {
      deep: true,
      handler(updated, oldVal) {
        if (!updated) {
          const priceWithoutWarranty = this.contractLineItem.jobLineItem.contractPriceWithoutWarranty;
          this.contractLineItem.jobLineItem.price = priceWithoutWarranty;
          this.emitTotalPriceUpdated(this.contractLineItem, priceWithoutWarranty, priceWithoutWarranty);
        } else if (updated.jobLineItem.price.value !== oldVal.jobLineItem.price.value) {
          const recalculatedTotal = this.contractLineItem.jobLineItem.contractPriceWithoutWarranty.add(updated.jobLineItem.price);
          this.contractLineItem.jobLineItem.price = recalculatedTotal;
          this.totalPrice = CurrencyFormatter.formatCurrency(recalculatedTotal);
          this.emitTotalPriceUpdated(this.contractLineItem, this.contractLineItem.jobLineItem.contractPriceWithoutWarranty,
              recalculatedTotal);
        }
      },
    },
    totalPrice: {
      immediate: true,
      handler(updated) {
        if (this.warrantyLineItem === null && updated !== this.priceWithoutWarranty) {
          this.priceWithoutWarranty = CurrencyFormatter.formatCurrency(updated);
        }
      }
    }
  },

  methods: {
    unFormatPrice() {
      this.priceWithoutWarranty = CurrencyFormatter.asCurrency(this.priceWithoutWarranty);
    },
    formatPrice() {
      this.priceWithoutWarranty = CurrencyFormatter.formatCurrency(this.priceWithoutWarranty);
    },
    showSaveError(value) {
      this.saveError = value;
    },
    onSaveLineItems() {
      this.$emit('onSaveLineItems');
    },
    onStartFulfillment() {
      this.$emit('onStartFulfillment');
    },
    onInvoiceJob() {
      this.$emit('onInvoiceJob');
    },
    emitTotalPriceUpdated(item, priceWithoutWarranty, totalPrice) {
      this.$emit('totalPriceUpdated', {
        item: item,
        priceWithoutWarranty: priceWithoutWarranty,
        totalPrice: totalPrice,
      });
    },
  },
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}

.save-error {
  color: red;
  width: 100%;
  text-align: right;
}

.total-contract-price {
  font-weight: bold;
  font-size: 14px;
  color: #666;
  padding-right: 5px;
}

.total-contract-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contract-prices-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 310px;
}

.contract-price-input {
  max-width: 150px;
}

.contract-prices-column {
  display: flex;
  flex-direction: row-reverse;
}
</style>
