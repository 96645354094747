<template>
  <div>
    <div style="border-top: 1px solid #ddd;"></div>
    <dx-data-grid
        ref="grid"
        slot="content"
        :data-source="dataSource"
        :show-borders="showBorders"
        :height="height"
        :load-panel="loadPanelConfig"
        :hover-state-enabled="hoverStateEnabled"
        :columnMinWidth="columnMinWidth"
        :columnAutoWidth="columnAutoWidth"
        :noDataText="noDataTextValue"
        :editing="editing"
        @selectionChanged="onSelectionChanged"
    >
      <dx-filter-row :visible="true"/>
      <dx-header-filter :visible="true"/>
      <dx-selection mode="single"></dx-selection>
      <dx-paging :page-size="pageSize"/>
      <dx-pager
          v-if="showPager"
          :show-page-size-selector="true"
          :show-info="true"
          infoText="Page {0} of {1} - {2} items"
      />
      <slot/>
      <div slot="delete" slot-scope="cellData">
        <delete-row-cell-template
            v-if="!hideDelete"
            :cell-data="cellData"
            @deleteClicked="onDeleteClicked"
        />
      </div>
      <div slot="link" slot-scope="cellData">
        <link-row-cell-template
            :cell-data="cellData"
            file-id-property="id"
            file-name-property="fileName"
            @linkClicked="onLinkClicked"
        />
      </div>
      <div slot="hasNoteTemplate" slot-scope="cellData">
        <has-note-attached :cell-data="cellData"/>
      </div>
      <div slot="poCheckIn" slot-scope="cellData">
        <purchase-order-checkin :cell-data="cellData" @checkIn="onCheckIn"/>
      </div>
    </dx-data-grid>
    <slot name="footer"/>
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxSelection
} from "devextreme-vue/ui/data-grid";
import DeleteRowCellTemplate from "@/components/common/grid/cellTemplates/DeleteRowCellTemplate.vue";
import LinkRowCellTemplate from "./cellTemplates/LinkRowCellTemplate";
import HasNoteAttached from "./cellTemplates/HasNoteAttached";
import PurchaseOrderCheckin from "@/components/common/grid/PurchaseOrderCheckin";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import FilterInitializer from "@/components/common/grid/FilterInitializer";
import RouterHelper from "@/assets/routerHelper";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DeleteRowCellTemplate,
    LinkRowCellTemplate,
    HasNoteAttached,
    PurchaseOrderCheckin
  },

  props: [
    "dataSource",
    "showBorders",
    "height",
    "hoverStateEnabled",
    "editableCell",
    "columnMinWidth",
    "columnAutoWidth",
    "noDataText",
    "confirmDeleteMessage",
    "showPager",
    "hideDelete",
    "pageSize"
  ],

  mounted() {
    FilterInitializer.initializeCustomFilterOperations(this.getGridInstance());
    this.hideColumns();
  },

  watch: {
    noDataText: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.noDataTextValue = newVal;
        }
      }
    },
    confirmDeleteMessage: {
      immediate: true,
      handler(newVal) {
        this.editing.texts.confirmDeleteMessage = newVal !== null ? newVal : null;
      }
    }
  },

  data() {
    return {
      noDataTextValue: "No data",
      editing: {
        texts: {
          confirmDeleteMessage: null
        }
      },
      loadPanelConfig: {
        enabled: false
      },
      dataFetched: false
    };
  },
  methods: {
    onSelectionChanged(event) {
      let data = event.selectedRowsData[0];
      this.$emit("rowSelected", data);
      this.clearSelection();
    },
    onDeleteClicked(cellData) {
      this.$emit("deleteClicked", cellData);
    },
    onLinkClicked(cellData) {
      this.$emit("linkClicked", cellData);
    },
    onCheckIn(cellData) {
      this.$emit("checkIn", cellData);
    },
    onEditValue(cellData) {
      this.$emit("editValue", cellData);
    },
    deleteRow(rowIndex) {
      this.$refs.grid.instance.deleteRow(rowIndex);
    },
    clearSelection() {
      this.$refs.grid.instance.clearSelection();
    },
    filter(data) {
      this.$refs.grid.instance.filter(data);
    },
    clearSortingAndFiltering(inventoryItemId) {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.clearSorting();
      gridInstance.filter(["InventoryItemId", "=", parseInt(inventoryItemId)]);
    },
    getGridInstance() {
      return this.$refs.grid.instance;
    },
    hideColumns() {
      let isInventoryInTransferDetailsPage = RouterHelper.includesPart(this.$route, 'inventory/in-transfer/details/');
      if (isInventoryInTransferDetailsPage) {
        this.$emit('hideJobRelatedColumns');
      }
    }
  }
};
</script>
