<template>
  <v-slide-y-transition>
    <v-alert
        v-if="showAlert"
        :type="type"
        :style="{ fontSize: fontSize + 'px' }"
        dismissible
        border="left"
        colored-border
        @click="clearError"
    >
      {{ alertMessage }}
    </v-alert>
  </v-slide-y-transition>
</template>

<script>
export default {
  props: {
    alertMessage: {
      type: String,
      required: true,
      default: ''
    },
    showAlert: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'warning'
    },
    fontSize: {
      type: Number,
      required: false,
      default: 14
    }
  },

  methods: {
    clearError() {
      this.$emit('onClearError');
    }
  }
}
</script>
