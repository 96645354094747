<template>
  <div>
    <custom-icon :icon="icon"/>
  </div>
</template>

<script>
import CustomIcon from "../../icons/CustomIcon.vue";
import IconDefineHelper from "../../../../assets/iconDefineHelper";

export default {
  components: {CustomIcon},

  props: {
    statusName: String,
    templateName: String
  },

  data() {
    return {}
  },

  computed: {
    icon() {
      return this.defineIconByStatusName(this.templateName, this.statusName);
    }
  },

  methods: {
    defineIconByStatusName(templateName, statusName) {
      if (this.statusName) {
        return IconDefineHelper.defineIconByStatusName(templateName, statusName);
      }
    },
  }
};
</script>
