<template>
  <dx-data-grid
      ref="itemsGrid"
      :data-source="dataSourceConfig"
      :show-borders="true"
      :hover-state-enabled="true"
      :load-panel="{ enabled: true }"
      @selectionChanged="onRowSelected"
      :columnMinWidth="150"
      :noDataText="selectedVendor ? 'No available items found for the selected vendor' : 'No Data'"
  >
    <dx-filter-row :visible="true"/>
    <dx-header-filter :visible="true"/>
    <dx-column-fixing :enabled="true"/>
    <dx-paging :page-size="5"/>
    <dx-pager
        :show-page-size-selector="false"
        :show-info="true"
    />
    <slot/>
    <dx-selection mode="single"/>
    <dx-column
        data-field="InternalSku"
        caption="Internal SKU"
        data-type="string"
    />
    <dx-column
        data-field="ProductType"
        caption="Product Type"
        data-type="string"
    />
    <dx-column
        data-field="StyleName"
        caption="Style"
        data-type="string"
    />
    <dx-column
        data-field="Color"
        caption="Color"
        data-type="string"
    />
    <dx-column
        data-field="Description"
        caption="Description"
        data-type="string"
    />
    <dx-column
        v-if="!selectedVendor && !dataSource"
        data-field="VendorName"
        caption="Vendor Name"
        data-type="string"
    />
  </dx-data-grid>
</template>

<script>
import "devextreme/data/odata/store";
import {
  DxColumn,
  DxColumnFixing,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxSelection
} from "devextreme-vue/ui/data-grid";
import ODataContext from "../../rest/odataContext";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSelection
  },

  props: {
    selectedVendor: {
      type: Object,
      required: false,
      default: null
    },
    filter: {
      type: Array,
      required: false,
      default: () => []
    },
    dataSource: {
      type: Object | Array,
      required: false,
      default: null
    }
  },

  data() {
    return {
      dataSourceConfig: {
        store: ODataContext['ProductCatalogSearchs'],
        select: [
          "ProductCatalogItemId",
          "InternalSku",
          "StyleName",
          "Color",
          "Description",
          "ProductType",
          "ProductTypeId",
          "VendorId",
          "SupplierCostId",
          "ActiveVendorCost",
          "VendorSku",
          "UnitOfMeasure",
          "BoxQuantity",
          "VendorName",
          "IsMigrated"
        ],
        sort: [{selector: "InternalSku", desc: false}],
        filter: this.getGridFilter()
      }
    };
  },

  created() {
    if (this.dataSource) {
      this.dataSourceConfig = this.dataSource;
    }
  },

  watch: {
    selectedVendor() {
      this.setFilterAndReloadDataSource();
    },
    dataSource(newValue) {
      if (newValue) {
        this.dataSourceConfig = newValue;
      }
    }
  },

  methods: {
    setFilterAndReloadDataSource() {
      const gridInstance = this.getGridInstance();
      const gridFilter = this.getGridFilter();
      this.reloadDataSourceWithFilter(gridInstance, gridFilter);
    },
    getGridFilter() {
      const gridFilter = this.filter ? [...this.filter] : [];
      if (this.selectedVendor) {
        gridFilter.push(["VendorId", "=", this.selectedVendor.id]);
      }
      if (gridFilter.length > 0) {
        return gridFilter;
      }
    },
    reloadDataSourceWithFilter(gridInstance, filter) {
      gridInstance.filter(filter);
      gridInstance.refresh();
    },
    onRowSelected(event) {
      this.$emit('rowSelected', event);
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      this.clearFilterOnlyForBulkPoGrid();
      gridInstance.clearSorting();
      gridInstance.columnOption('InternalSku', {
        sortOrder: 'asc',
        sortIndex: 0
      });
    },
    getGridInstance() {
      return this.$refs.itemsGrid.instance;
    },
    deselectItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    getSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return [...selectedRows];
    },
    clearFilterOnlyForBulkPoGrid() {
      if (this.selectedVendor) {
        this.setFilterAndReloadDataSource(this.selectedVendor);
      }
    }
  }
};
</script>
