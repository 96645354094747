<template>
  <navigation-view
      ref="nav"
      page-title="Customer Details"
      nav-section="customer"
  >
    <template v-slot:content>
      <v-container fluid id="customer-details">
        <!-- top section for ref id -->
        <v-row>
          <v-col md="2">
            <v-text-field
                v-model="customerReferenceId"
                label="Reference Id"
                placeholder=" "
                readonly
            />
          </v-col>
        </v-row>
        <v-divider class="detailsDivider"/>
        <customer-details-form-read-only
            ref="customerReadOnlyDetails"
            :customer="customer"
        />

        <customers-inline-grids
            ref="inlineGrids"
            :customer-reference-id="customerReferenceId"
            @showError="showNavError"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <customer-drawer-form
          v-if="showEditDrawer"
          ref="customerDrawerForm"
          title="Edit Customer"
          submitLabel="Save Customer"
          :customer="customer"
          :inProgress="inProgress"
          :isCreateForm="false"
          @cancelClicked="onCancelUpdateCustomer"
          @submitClicked="onUpdateCustomer"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="true"
          label="Edit Customer"
          secondaryLabel="New Lead"
          @click="handleEditCustomerClick"
          @secondaryButtonClick="handleNewLeadClick"
      />
    </template>

    <template v-slot:wizard>
      <lead-wizard
          ref="leadWizard"
          :initialPhoneVal="primaryPhone"
          @successCustomer="onSubmitWizardCustomerSuccess"
          @successLead="onSubmitWizardLeadSuccess"
          @error="onSubmitWizardFail"
          @cancel="onWizardCancel"
          @onError="onSubmitWizardFail"
          @customerEmailUpdatedSuccess="onCustomerEmailUpdatedSuccess"
          @refreshDetailsAfterCustomerEmailUpdated="refreshDetailsAfterCustomerEmailUpdated"
      />
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../navigation/NavigationView.vue";
import NavigationActionButton from "../navigation/NavigationActionButton.vue";
import Customers from "../../rest/customers";
import CustomerDetailsFormReadOnly from "../../components/customers/CustomerDetailsFormReadOnly.vue";
import CustomersInlineGrids from "./CustomersInlineGrids.vue";
import LeadWizard from "../../components/leads/LeadWizard.vue";
import CustomerDrawerForm from "../../components/customers/CustomerDrawerForm";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    CustomerDetailsFormReadOnly,
    CustomerDrawerForm,
    CustomersInlineGrids,
    LeadWizard
  },

  data() {
    return {
      customerReferenceId: "",
      customer: null,
      customerServiceable: true,
      notes: [],
      activityLogs: [],
      inProgress: false,
      showEditDrawer: false
    };
  },

  created() {
    this.display(this.$route.params.customerReferenceId);
  },

  // Called when component is reused.
  beforeRouteUpdate(to, from, next) {
    this.display(to.params.customerReferenceId);
    next();
  },

  computed: {
    primaryPhone() {
      return this.customer ? this.customer.primaryPhone : "";
    }
  },

  methods: {
    display(customerReferenceId) {
      this.customerReferenceId = customerReferenceId;
      this.refresh();
    },
    async refresh() {
      try {
        const customerData = await this.getCustomerData();
        this.customerReferenceId = customerData.referenceId;
        this.customer = customerData;
        this.setIfCustomerInServiceableMarket();
        await this.$refs.inlineGrids.refreshActivityLog();
      } catch (error) {
        this.showNavError(error);
      }
    },
    async getCustomerData() {
      try {
        const response = await Customers.getRestApi().getCustomer(this.customerReferenceId);
        this.customer = response.data;
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    setIfCustomerInServiceableMarket() {
      let serviceable = true;
      if (!this.customer.marketId) {
        serviceable = false;
        this.$emit('error', "Customer in a non-serviceable zipcode.");
      }
      this.customerServiceable = serviceable;
    },
    handleEditCustomerClick() {
      this.showEditDrawer = true;
      this.showDrawer();
    },
    handleNewLeadClick() {
      this.$refs.nav.showWizard();
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    async updateCustomer(payload) {
      try {
        const response = await Customers.getRestApi().updateCustomer(this.customerReferenceId, payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async onUpdateCustomer(payload) {
      try {
        this.inProgress = true;
        await this.updateCustomer(payload);
        this.onUpdateCustomerSuccess(payload);
      } catch (error) {
        this.onUpdateCustomerFailed(error);
      }
      this.inProgress = false;
    },
    onUpdateCustomerSuccess(payload) {
      this.$refs.nav.showSuccess("Updated customer details successfully!");
      this.$refs.nav.hideDrawer();
      this.refresh();
    },
    onUpdateCustomerFailed(error) {
      this.showNavError(error);
    },
    onCancelUpdateCustomer() {
      this.$refs.nav.hideDrawer();
      this.resetFormToDefaults();
    },
    resetFormToDefaults() {
      this.showEditDrawer = false; // reset edit form to defaults by re-creating it
    },
    onSubmitWizardCustomerSuccess() {
      this.$refs.nav.showSuccess("Customer created successfully");
    },
    onSubmitWizardLeadSuccess() {
      this.$refs.nav.showSuccess("Lead created successfully.");
      this.hideWizard();
      this.$refs.leadWizard.resetWizard();
      this.$refs.inlineGrids.$refs.customerLeadsGrid.refresh();
    },
    onCustomerEmailUpdatedSuccess(email) {
      let message = `Customer Email has been updated successfully to ${email}`;
      this.$refs.nav.showSuccess(message);
    },
    onSubmitWizardFail(error) {
      this.$refs.nav.showError(error);
    },
    onWizardCancel() {
      this.hideWizard();
    },
    hideWizard() {
      this.$refs.nav.hideWizard();
    },
    onError(error) {
      this.showNavError(error);
    },
    refreshDetailsAfterCustomerEmailUpdated() {
      this.refresh();
    }
  }
};
</script>

<style scoped>
.fill-button {
  min-width: 100%;
  height: 100%;
  font-size: 20px;
}
</style>
