<template>
  <drawer-form
      ref="leadDrawerForm"
      :title="title"
      formId="leadDrawerForm"
      :submitLabel="submitLabel"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-container grid-list-lg text-xs-center>
      <sales-rep-lead-form-editable
          ref="leadDetailsForm"
          :customer="customer"
          :serviceable="serviceable"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm";
import SalesRepLeadFormEditable from "@/components/salesRepresentativesHome/lead/LeadFormEditable.vue";

export default {
  components: {
    DrawerForm,
    SalesRepLeadFormEditable
  },

  props: {
    title: String,
    submitLabel: String,
    customer: Object,
    serviceable: Boolean
  },

  data() {
    return {};
  },

  methods: {
    onCancelClicked() {
      this.$emit("cancelClicked");
    },
    onSubmitClicked() {
      let valid = this.$refs.leadDrawerForm.validate();
      if (valid) {
        const payload = this.$refs.leadDetailsForm.buildPayload();
        this.$emit("submitClicked", payload);
      }
    },
    loadDrawerForm(data, leadRooms, installationCapabilities) {
      this.$refs.leadDetailsForm.loadForm(data, leadRooms, installationCapabilities);
    },
    setFormBackToOriginalData(data, leadRooms, installationCapabilities) {
      this.$refs.leadDetailsForm.setFormBackToOriginalData(data, leadRooms, installationCapabilities);
    }
  }
};
</script>
