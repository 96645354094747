<template>
  <v-menu
      ref="menu"
      v-model="menu"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      id="date-picker"
      :close-on-content-click="false"
      :nudge-right="40"
      :disabled="menuDisabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="dateFormatted"
          :readonly="readonly"
          :disabled="disabled"
          :label="showLabel"
          :rules="[rules.required, rules.isDate]"
          :placeholder="placeholder || ' '"
          persistent-placeholder
          append-icon="event"
          hint="MM/DD/YYYY format"
          v-bind="attrs"
          v-on="on"
      />
    </template>
    <v-date-picker
        :allowedDates="allowedDates"
        v-model="date"
        no-title
        @change="onDateChosen(date)"
    />
  </v-menu>
</template>

<script>
import moment from "moment";
import Rules from "../../../assets/rules";
import DateFormatter from "../../../assets/dateFormatter";

export default {
  props: {
    validationName: String,
    value: String,
    optional: Boolean,
    allowedDates: Function,
    disabled: Boolean,
    readonly: Boolean,
    placeholder: String,
    label: String,
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    menuDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      date: null,
      dateFormatted: null,
      rules: {
        required: value => !!value || this.optional || this.validationName + " is required",
        isDate: value => {
          return this.isDate(value);
        }
      }
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.date = newVal;
          this.dateFormatted = this.formatDate(this.date);
        }
      }
    },
    dateFormatted(updated) {
      if (updated && updated.length === 10) {
        let mdate = moment(updated, "MM-DD-YYYY");
        if (mdate.isValid()) {
          let dateStr = mdate.format("YYYY-MM-DD");
          this.$emit('input', dateStr);
        }
      } else {
        this.date = null;
        this.dateFormatted = null;
        this.$emit('cleared');
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    menu(newVal) {
      if (newVal) {
        this.$emit('opened');
      }
    }
  },

  computed: {
    showLabel() {
      return this.hideLabel ? null : (this.label || 'Date');
    },
    hasClearedDate() {
      return this.date === null || this.dateFormatted === null;
    }
  },

  methods: {
    isDate(value) {
      if (value && !this.readonly && !this.disabled) {
        let checkPassed = Rules.isDateMMDDYYYYFormat(value);
        if (!checkPassed) {
          this.date = null;
        }
        return (checkPassed || "Invalid date. MM/DD/YYYY format required");
      }
      return true;
    },
    onDateChosen(date) {
      this.$refs.menu.save(date);
      this.$emit('input', date);
    },
    formatDate(date) {
      if (!date) return null;
      const newDate = moment.utc(date);
      return moment(newDate).format("MM/DD/YYYY");
    },
    getSelected() {
      return this.dateFormatted;
    },
    clearSelected() {
      this.dateFormatted = null;
      this.date = null;
    },
    getSelectedDate() {
      return DateFormatter.formatDateInYYYYMMDDFormat(this.date);
    }
  }
};
</script>

