<template>
  <v-layout row wrap>
    <!-- TODO: rewrite to proper row-col-->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs2>
          <v-text-field
              label="Reference Id"
              readonly
              data-id="vendor-details-ref-id"
              v-model="referenceId"
              placeholder=" "
              persistent-placeholder
          />
        </v-flex>
        <v-flex/>
        <v-flex xs2>
          <status-dropdown
              :value="active"
              :readonly="true"
          />
        </v-flex>
      </v-layout>
      <v-divider class="detailsDivider"/>
    </v-flex>
    <!-- column 1 -->
    <v-flex xs5>
      <v-layout row wrap>
        <v-flex xs6>
          <v-text-field
              v-model="name"
              label="Name"
              readonly
              data-id="vendor-details-name"
              placeholder=" "
              persistent-placeholder
          />
        </v-flex>

        <v-flex xs6>
          <v-text-field
              v-model="phoneNumber"
              label="Phone Number"
              v-facade="phoneNumberMask"
              readonly
              data-id="vendor-details-phone-number"
              placeholder=" "
              persistent-placeholder
          />
        </v-flex>

        <v-flex xs6>
          <v-text-field
              v-model="contactFirstName"
              label="Contact First name"
              placeholder=" "
              persistent-placeholder
              readonly
              data-id="vendor-details-first-name"
          />
        </v-flex>

        <v-flex xs6>
          <v-text-field
              v-model="contactLastName"
              label="Contact Last name"
              placeholder=" "
              persistent-placeholder
              readonly
              data-id="vendor-details-last-name"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
              v-model="contactEmail"
              label="Contact Email"
              placeholder=" "
              persistent-placeholder
              readonly
              data-id="vendor-details-email"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-spacer></v-spacer>
    <!-- column 2 -->
    <v-flex xs5>
      <address-form-with-autocomplete
          ref="addressForm"
          :readonly="true"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import AddressFormWithAutocomplete from "@/components/common/form/AddressFormWithAutocomplete.vue";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import Constants from "../../assets/constants";

const formDefaults = {
  name: null,
  phoneNumber: null,
  contactFirstName: null,
  contactLastName: null,
  contactEmail: null,
  active: null,
  referenceId: null
};

export default {
  components: {
    StatusDropdown,
    AddressFormWithAutocomplete
  },

  data() {
    return Object.assign(
        {
          phoneNumberMask: Constants.phoneNumberMask
        },
        formDefaults);
  },
  methods: {
    onCancel() {
      this.$emit("cancelClicked");
    },
    loadForm(payload) {
      this.name = payload.name;
      this.phoneNumber = payload.phoneNumber;
      this.contactEmail = payload.contactEmail;
      this.contactFirstName = payload.contactFirstName;
      this.contactLastName = payload.contactLastName;
      this.active = payload.active;
      this.referenceId = payload.referenceId;
      this.$refs.addressForm.setAddressData(payload);
    }
  }
};
</script>
