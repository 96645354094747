<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td class="reference-id-td top-aligned">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <td width="10%">
          <v-text-field
              :disabled="true"
              v-model="chargebackType"
              class="short-input left-aligned"
              placeholder=" "
          />
        </td>
        <td width="25%">
          <v-text-field
              :disabled="true"
              v-model="chargebackInfo"
              class="short-input left-aligned"
              placeholder=" "
              prefix="$"
          />
        </td>
        <td width="10%">
          <v-text-field
              :disabled="true"
              v-model="overbill"
              class="short-input left-aligned"
              placeholder=" "
              prefix="$"
          />
        </td>
        <td width="10%">
          <v-text-field
              :disabled="true"
              v-model="customerPayment"
              class="short-input left-aligned"
              placeholder=" "
              prefix="$"
          />
        </td>
        <td width="10%">
          <v-text-field
              :disabled="true"
              v-model="fiftyFloorAmount"
              class="short-input left-aligned"
              placeholder=" "
              prefix="$"
          />
        </td>
        <td width="15%">
          <v-text-field
              v-show="isInstaller"
              :disabled="true"
              v-model="thirdPartyInfo"
              class="short-input left-aligned"
              placeholder=" "
              prefix="$"
          />
        </td>
        <!-- delete icon -->
        <td class="delete-icon">
          <v-btn
              v-if="canDeleteItems"
              small
              dark
              icon
              class="short ma-0 pa-0"
              @click="onDeleteItem(lineItem.jobLineItem.referenceId)"
          >
            <v-icon class="short" color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import {isGenerated} from "../../../assets/referenceIds";

export default {
  components: {},

  props: {
    job: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      referenceId: null,
      type: null,
      customerPayment: 0,
      thirdPartyVendor: null,
      thirdPartyPayment: 0,
      installerLabel: null,
      installerChargeAmount: 0,
      vendorLabel: null,
      vendorChargeAmount: 0,
      fiftyFloorAmount: 0,
      quantity: null,
      price: 0,
      comment: "",
      isFulFilled: true,
      posted: false,
      overbill: 0
    };
  },

  computed: {
    isInstaller() {
      return this.installerId != null;
    },
    canDeleteItems() {
      return !(this.job.invoiced);
    },
    chargebackType() {
      return this.installerId ? "Installer" : "Vendor";
    },
    chargebackInfo() {
      return this.installerId ? this.installerInfo : this.vendorInfo;
    },
    vendorInfo() {
      return (this.vendorChargeAmount + (this.vendorId ? " (" + this.vendorLabel + ")" : ""));
    },
    installerInfo() {
      return (this.installerChargeAmount + (this.installerId ? " (" + this.installerLabel + ")" : ""));
    },
    thirdPartyInfo() {
      return (this.thirdPartyPayment +
          (this.thirdPartyPayment && this.thirdPartyPayment > 0
              ? " (" + this.thirdPartyVendor + ")"
              : "")
      );
    }
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.referenceId = updated.jobLineItem.referenceId;
        this.type = updated.jobLineItem.type;
        this.status = null;
        this.comment = "";
        this.quantity = null;
        this.price = 0;
        this.isFulFilled = true;
        this.posted = updated.jobLineItem.posted;
        this.customerPayment = CurrencyFormatter.formatCurrency(updated.jobLineItem.customerPayment);
        this.thirdPartyPayment = CurrencyFormatter.formatCurrency(updated.jobLineItem.thirdPartyPayment);
        this.thirdPartyVendor = updated.jobLineItem.thirdPartyVendor;
        this.installerId = updated.jobLineItem.installerId;
        this.installerLabel = updated.jobLineItem.installerLabel;
        this.installerChargeAmount = CurrencyFormatter.formatCurrency(updated.jobLineItem.installerChargeAmount);
        this.vendorId = updated.jobLineItem.vendorId;
        this.vendorLabel = updated.jobLineItem.vendorLabel;
        this.vendorChargeAmount = CurrencyFormatter.formatCurrency(updated.jobLineItem.vendorChargeAmount);
        this.fiftyFloorAmount = CurrencyFormatter.formatCurrency(updated.jobLineItem.fiftyFloorAmount);
        this.overbill = CurrencyFormatter.formatCurrency(updated.jobLineItem.overbill);
      },
    },
  },

  methods: {
    onDeleteItem(referenceId) {
      this.$emit("itemDeleted", referenceId);
    },
    emitError(error) {
      this.$emit("error", error);
    },
    isGenerated
  },
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
.reference-id-td {
  width: 10%;
  padding-top: 7px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}
.item-row td.top-aligned {
  vertical-align: top;
}
.delete-icon {
  text-align: right;
  width: 30px
}
</style>
