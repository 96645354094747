<template>
  <div id="wo-container" v-if="workOrder">
    <div id="panel">
      <div id="toggle-wrapper">
        <toggle-button
            v-model="isFieldCopy"
            color="#6699bb"
            height="35"
            width="70"
            font-size="15"
        />
        <div id="toggler-label">
          {{isFieldCopy  ? 'Field Copy' : 'Office Copy'}}
        </div>
      </div>
      <v-btn
          color="primary"
          class="section-button"
          @click="print"
      >Print</v-btn
      >
    </div>
    <div id="header">
      <div class="header-block">
        <div class="header-block-column left-header-block-column fields-names">
          <span>Sold Date:</span>
          <span>Sold to:</span>
          <span class="mt-2">Address:</span>
          <span>City, St, Zip:</span>
          <span class="mt-2">Phone:</span>
          <span v-if="workOrder.jobSummary.alternatePhone">Phone:</span>
        </div>
        <div class="header-block-column left-header-block-column">
          <span>{{ soldDate }}</span>
          <span>{{ soldTo }}</span>
          <span class="mt-2">{{ address }}</span>
          <span>{{ city + ', ' + state + ', ' + zip }}</span>
          <span class="mt-2">{{ this.formatPhoneNumber(primaryPhone) }}</span>
          <span v-if="alternatePhone">{{ this.formatPhoneNumber(alternatePhone) }}</span>
        </div>
      </div>
      <div id="right-header-block-container">
        <div id="right-header-block-title">
          <span>{{ laborTypeName }} Work Order</span>
          <div>
            <span v-if="isFieldCopy">Field Copy</span>
            <span v-else>Office Copy</span>
          </div>
        </div>

        <div class="header-block">
          <div class="header-block-column text-align-right fields-names">
            <span>Sales Representative:</span>
            <span>Phone:</span>
            <span>Installer:</span>
            <span class="balance-due">Balance Due:</span>
          </div>
          <div class="header-block-column">
            <span>{{ salesRepresentative }}</span>
            <span>{{ this.formatPhoneNumber(salesRepresentativePhone) }}</span>
            <span v-if="installerCompanyName">{{ installerCompanyName }}</span>
            <span v-if="!installerCompanyName" class="empty-placeholder">Not assigned yet</span>
            <span class="balance-due">${{ balanceDue }}</span>
          </div>
        </div>
      </div>
    </div>
    <div id="labors-table" class="mt-5">
      <section style="width: 100%">
        <header>
          <div class="col">Labor Description</div>
          <div class="col">Style and Color</div>
          <div class="col text-align-right">Qty</div>
          <div v-if="!isFieldCopy" class="col text-align-right">Rate</div>
          <div v-if="!isFieldCopy" class="col text-align-right">Total</div>
        </header>
        <div v-for="item in workOrderLineItems" v-bind:key="item.id" class="wo-row">
          <div class="col text-align-left">{{ item.laborDescription }}</div>
          <div class="col text-align-left">
            <span v-if="item.privateLabelStyle != null && item.privateLabelStyle !== ''">{{item.privateLabelStyle}}</span>
            <span v-if="item.privateLabelColor != null && item.privateLabelColor !== ''">, {{item.privateLabelColor}}</span>
            <span v-if="item.privateLabelStyle != null && item.privateLabelStyle !== ''"> / </span>
            <span>{{item.style}}</span>
            <span v-if="item.color != null && item.color !== ''">, {{item.color}}</span>

          </div>
          <div class="col text-align-right">{{ item.quantity }}</div>
          <div v-if="!isFieldCopy" class="col text-align-right">${{ item.rate }}</div>
          <div v-if="!isFieldCopy" class="col text-align-right">${{ item.total }}</div>
        </div>
      </section>
    </div>
    <div id="bottom-block">
      <div id="misc-materials">
        <p class="bottom-block-title">Misc Materials Quantities</p>
        <div class="table">
          <div v-for="item in workOrderMiscItems" v-bind:key="item.id" class="wo-row">
            <div class="col">
              <span v-if="item.privateLabelStyle != null && item.privateLabelStyle !== ''">{{item.privateLabelStyle}}</span>
              <span v-if="item.privateLabelColor != null && item.privateLabelColor !== ''">, {{item.privateLabelColor}}</span>
              <span v-if="item.privateLabelStyle != null && item.privateLabelStyle !== ''"> / </span>
              <span>{{item.miscStyle}}</span>
              <span v-if="item.miscColor != null && item.miscColor !== ''">, {{ item.miscColor }}</span>
            </div>
            <div class="col">{{ item.convertedQuantity }}</div>
          </div>
        </div>
      </div>
      <div class="total-pay" v-if="!isFieldCopy">
        <div>
          <span id="total-pay-name">Total Pay:</span>
          <span>${{ totalPay }}</span>
        </div>
      </div>
      <div class="total-pay" v-else>
        <span>99</span>
        <span>{{ instCapCode }}</span>
        <span>{{ totalPay }}</span>
      </div>
    </div>
    <div id="note-block">
      <p class="bottom-block-title">Job Notes:</p>
      <p>{{ installerNote }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import WorkOrders from "@/rest/workorders";
import {ToggleButton} from "vue-js-toggle-button";
import System from "@/rest/system";
import Format from "@/components/common/Format";

const installationCapsCodes = {
  1: "С",
  2: "L",
  3: "GH",
  4: "NH",
  5: "LVT",
  7: "CT",
  8: "SV",
  9: "ST",
  11: "HH",
  12: "UH"
}

export default {
  components: {
    ToggleButton
  },

  data() {
    return {
      isFieldCopy: true,
      instCapCode: null,
      jobRefId: this.$route.params.jobRefId,
      lineItemRefId: this.$route.params.lineItemRefId,
      workOrder: null,
      soldDate: null,
      soldTo: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      primaryPhone: null,
      alternatePhone: null,
      salesRepresentative: null,
      salesRepresentativePhone: null,
      balanceDue: null,
      workOrderLineItems: null,
      totalPay: null,
      workOrderMiscItems: null,
      installerNote: null,
      laborTypeName: null,
      installerCompanyName: null
    };
  },

  mounted() {
    this.initView();
  },

  methods: {
    initView() {
      WorkOrders.getRestApi()
          .getJobWorkOrder(this.jobRefId, this.lineItemRefId)
          .then((response) => {
            this.workOrder = response.data;
            this.soldDate = moment(response.data.jobSummary.soldDate).format("MM/DD/YYYY");
            this.soldTo = response.data.jobSummary.soldTo;
            this.address = response.data.jobSummary.address;
            this.city = response.data.jobSummary.city;
            this.state = response.data.jobSummary.state;
            this.zip = response.data.jobSummary.zip;
            this.primaryPhone = response.data.jobSummary.primaryPhone;
            this.alternatePhone = response.data.jobSummary.alternatePhone;
            this.salesRepresentative = response.data.jobSummary.salesRepresentative;
            this.salesRepresentativePhone = response.data.jobSummary.salesRepresentativePhone;
            this.balanceDue = response.data.jobSummary.balanceDue;
            this.workOrderLineItems = response.data.laborSummary.workOrderLineItems;
            this.totalPay = response.data.laborSummary.totalPay;
            this.installerNote = response.data.laborSummary.installerNote;
            this.workOrderMiscItems = response.data.workOrderMiscItems;
            this.instCapCode = installationCapsCodes[response.data.laborSummary.laborTypeId];
            this.laborTypeName = response.data.laborSummary.laborTypeName;
            this.installerCompanyName = response.data.laborSummary.installerCompanyName;
          })
          .catch(() => {
            this.$router.push("/jobs");
          })
    },
    async print() {
      const response = await System.getRestApi().isProductionStage();
      if (!response.data) {
        this.removeHeaderInPrintForm();
      }
      window.print();
    },
    removeHeaderInPrintForm() {
      document.getElementsByClassName("stage-header")[0].style.visibility = "hidden";
      document.getElementById("inspire").style.padding = 0;
    },
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    }
  }
};
</script>

<style>
#wo-container {
  width: 100vw;
  min-height: 100vh;
  padding: 0 50px;
}

#header {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.header-block {
  display: flex;
  gap: 30px;
}

.header-block-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.left-header-block-column {
  text-align: left;
}

.fields-names {
  font-weight: bold;
}

.balance-due {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.empty-placeholder {
  color: #999999;
  font-style: italic;
}

#right-header-block-container {
  display: flex;
  flex-direction: column;
}

#right-header-block-title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
}

#labors-table {
  font-size: 15px;
  width: 100%;
  display: flex;
  border: 1px solid black;
  border-right: none;
}

#labors-table header, .wo-row {
  display: flex;
}

#labors-table .wo-row {
  border-top: 1px solid black;
}

#labors-table .wo-row:last-child {
  border-right: none;
}

#labors-table header {
  font-weight: bold;
}

#labors-table header .col {
  text-align: center;
}

#labors-table header .col:nth-of-type(1),
#labors-table .col:nth-of-type(1),
#labors-table header .col:nth-of-type(2),
#labors-table .col:nth-of-type(2),
#labors-table header .col:nth-of-type(3),
#labors-table .col:nth-of-type(3) {
  flex-grow: 2;
}

#labors-table .col {
  flex: 1;
  border-right: 1px solid black;
  padding: 1px 7px;
}

#bottom-block {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

#misc-materials {
  width: 50%;
}

#misc-materials .table {
  width: 100%;
}

#misc-materials .col {
  flex: 1;
  text-align: left;
  padding: 1px 7px;
}

.bottom-block-title {
  text-align: left;
  text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
}

.total-pay {
  display: flex;
  gap: 10px;
  font-size: 17px;
  text-align: right;
}

#total-pay-name {
  font-weight: bold;
  margin-right: 10px;
}

#note-block {
  margin-top: 40px;
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

#panel {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 50px;
  align-items: center;
  margin-top: 30px;
}

#toggle-wrapper {
  display: flex;
  align-items: center;
}

#toggler-label {
  margin-left: 15px;
  font-size: 18px;
  width: 100px;
}

@media print {
  #panel {
    display: none !important;
  }
  #wo-container {
    padding: 0;
  }
  #misc-materials {
    width: 90%;
  }
  @page {
    size: portrait;
  }
}
</style>
