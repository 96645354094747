import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Sales Representative Price.
 */
export class SalesRepPrice {
    id?: number;
    cost: number;
    effectiveOn: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;

    constructor(id: number, cost: number, effectiveOn: string, active: boolean, createdAt: string, updatedAt: string) {
        this.id = id;
        this.cost = cost;
        this.effectiveOn = effectiveOn;
        this.active = active;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}

/**
 * REST interface provided for Sales Representative Prices.
 */
export interface SalesRepPricesRestApi {
    getSalesRepPriceByCatalogItemById(internalSku: string): AxiosPromise<SalesRepPrice>;
    addSalesRepPriceForCatalogItem(internalSku: string, payload: SalesRepPrice): AxiosPromise<SalesRepPrice>;
    removeRepPrice(internalSku: string, costId: number): AxiosPromise<SalesRepPrice>;
}

/**
 * REST implementation provided for product catalog items.
 */
class Implementation implements SalesRepPricesRestApi {

    getSalesRepPriceByCatalogItemById(internalSku: string): AxiosPromise<SalesRepPrice> {
        return Axios.axiosInstance().get<SalesRepPrice>(`/rep-price/${internalSku}`);
    }
    addSalesRepPriceForCatalogItem(internalSku: string, payload: SalesRepPrice): AxiosPromise<SalesRepPrice> {
        return Axios.axiosInstance().post<SalesRepPrice>(`/rep-price/${internalSku}`, payload);
    }
    removeRepPrice(internalSku: string, costId: number): AxiosPromise<SalesRepPrice> {
        return Axios.axiosInstance().delete(`/rep-price/${internalSku}/${costId}`);
    }
}

const SalesRepPrices = {
    getRestApi(): SalesRepPricesRestApi {
        return new Implementation();
    }
};

export default SalesRepPrices;
