<template>
  <dx-data-grid
    ref="leadDataGrid"
    :data-source="dataSource"
    :show-borders="true"
    :hover-state-enabled="true"
    :onContentReady="onGridContentReady"
    noDataText="Only Leads associated to this Sales Representative can be selected"
    @selectionChanged="onSelectionChanged"
  >
    <dx-filter-row :visible="true" />
    <dx-header-filter :visible="false" />
    <dx-paging :page-size="5" />
    <dx-pager
      :show-page-size-selector="true"
      :allowed-page-sizes="[5]"
      :show-info="true"
    />
    <dx-selection mode="single" />
    <dx-column data-field="LeadStatus" caption="Status" />
    <dx-column data-field="ReferenceId" caption="Reference Id" />
    <dx-column data-field="CustomerFirstName" caption="Customer First Name" />
    <dx-column data-field="CustomerLastName" caption="Customer Last Name" />
  </dx-data-grid>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import "devextreme/data/odata/store";
import {
  DxDataGrid,
  DxColumn,
  DxColumnFixing,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxSelection
} from "devextreme-vue/ui/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.teal.light.css";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    ModalDialog,
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSelection
  },

  props: { salesRepId: Number, salesRepName: String },

  data() {
    return {
      selected: null,
      dataSource: {
        store: ODataContext['Leads'],
        select: [
          "Id",
          "ReferenceId",
          "LeadStatus",
          "CustomerFirstName",
          "CustomerLastName",
          "SalesRepName",
          "SalesRepId"
        ],
        // filters leads with only selected sales rep associated
        filter: ["SalesRepName", "=", `${this.$props.salesRepName}`]
      }
    };
  },

  methods: {
    onGridContentReady() {
      this.$emit("gridContentReady");
    },

    reset() {
      this.selected = null;
    },

    onSelectionChanged(event) {
      let data = event.selectedRowsData[0];
      if (data) {
        this.selected = data;
        this.$emit("leadSelected", data);
      }
    }
  }
};
</script>
