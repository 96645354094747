<template>
  <v-card light color="white">
    <v-card-text>
      <lead-details :lead=leadDetails class="pa-3" ref="details"/>
    </v-card-text>
  </v-card>
</template>

<script>
import LeadDetails from "../../../components/jobs/details/LeadDetails.vue";

export default {
  components: {LeadDetails},

  props: {
    leadDetails: Object,
  },

  data() {
    return {};
  },


  methods: {}
};
</script>
