<template>
  <div>
    <v-text-field
        v-if="readonly || disabled"
        :readonly="readonly"
        :disabled="disabled"
        placeholder=" "
        :value="textValue"
        label="Product Types"
        data-id="lead-product-types-text-value"
    />
    <v-autocomplete
        v-if="!readonly && !disabled"
        v-model="selected"
        :items="productTypes"
        :search-input.sync="searchInput"
        @input="searchInput=null"
        :loading="loading"
        hide-no-data
        label="* Product Types"
        :rules="[() => !!this.selected.length || 'Product types are required']"
        item-text="name"
        item-value="id"
        :multiple="multiple"
        :clearable="true"
        :placeholder="placeholder || 'Search Product Types'"
        persistent-placeholder
        data-id="lead-product-type-search-field"
        dense
        attach
        :menu-props="{maxHeight: maxDropdownHeight ? maxDropdownHeight : 'auto'}"
    >
    <template v-slot:prepend-item v-if="selectAllEnabled">
      <select-all-autocomplete
          :items="productTypes"
          :selected="selected"
          @select="selectAll"
          @deselect="deselectAll"
      >
      </select-all-autocomplete>
    </template>
    </v-autocomplete>
  </div>
</template>

<script>
import SelectAllAutocomplete from "../../components/searchFields/selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";
import ProductTypes from "./ProductTypes.vue";
import LeadProductTypes from "../../rest/leadProductTypes";

export default {
  components: {
    SelectAllAutocomplete
  },

  data() {
    return {
      selected: [],
      productTypes: [],
      loading: false,
      searchInput: null
    };
  },

  props: {
    value: Array,
    readonly: Boolean,
    disabled: Boolean,
    placeholder: String,
    multiple: Boolean,
    selectAllEnabled: {type: Boolean, default: false, required: true},
    maxDropdownHeight: Number
  },

  watch: {
    value(value) {
      this.$data.selected = value;
    },
    selected(value) {
      this.$emit("input", value);
    }
  },

  computed: {
    textValue() {
      return this.selected.map(item => item.name).join(", ");
    }
  },

  created() {
    this.getProductTypes();
  },

  methods: {
    async getProductTypes() {
      try {
        const response = await LeadProductTypes.getRestApi().getActiveLeadProductTypes();
        this.productTypes = response.data;
      } catch (error) {
        this.$emit('showError', error);
      }
    },
    setSelected(selected) {
      this.selected = selected;
    },
    getSelected() {
      return this.selected;
    },
    getFlatSelected() {
      return (this.selected || []).map(item => {
        if (typeof item === "object") {
          return item.id;
        }
        return item;
      });
    },
    selectAll() {
      this.selected = this.productTypes.map(item => item.id);
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
  width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
