import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";

/**
 * Product .
 */
export class InstallationCapability {
  id: number;
  type: string;
  laborItemDescription: string;

  constructor(id: number, type: string, laborItemDescription: string) {
    this.id = id;
    this.type = type;
    this.laborItemDescription = laborItemDescription;
  }
}

/**
 * REST interface provided for product .
 */
export interface InstallationCapabilityRestApi {
  getAllInstallationCapabilities(): AxiosPromise<InstallationCapability[]>;
  getInstallationCapabilityById(
    id: number
  ): AxiosPromise<InstallationCapability>;
}

/**
 * REST implementation provided for InstallationCapability .
 */
class Implementation implements InstallationCapabilityRestApi {
  getAllInstallationCapabilities(): AxiosPromise<InstallationCapability[]> {
    return Axios.axiosInstance().get<InstallationCapability[]>(
      "/installation/capabilities/search/all"
    );
  }
  getInstallationCapabilityById(
    id: number
  ): AxiosPromise<InstallationCapability> {
    return Axios.axiosInstance()
      .get<InstallationCapability>(`/installation/capabilities/${id}`)
      .then(result => {
        return result;
      });
  }
}

const InstallationCapabilities = {
  getRestApi(): InstallationCapabilityRestApi {
    const api = new Implementation();
    return api;
  }
};

export default InstallationCapabilities;
