<template>
  <v-autocomplete
      v-model="selected"
      :items="productTypes"
      :loading="loading"
      hide-no-data
      item-text="name"
      item-value="id"
      label="* Product Type"
      placeholder="Search product types"
      persistent-placeholder
      :disabled="disabled"
      :rules="[() => !!selected || 'Product type is required']"
      required
      dense
      attach
      data-id="product-types-autocomplete"
  />
</template>

<script>
import ProductTypes from "@/rest/productTypes";

export default {
  components: {},

  data() {
    return {
      selected: null,
      productTypes: [],
      loading: false
    };
  },

  props: ["value", "disabled"],

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("input", value);
      this.$emit("inputChanged", value);
    }
  },

  created() {
    let component = this;
    ProductTypes.getRestApi()
        .getActiveProductTypes()
        .then((response) => {
          component.productTypes = response.data;
        })
  },

  methods: {}
};
</script>
