<template>
  <v-row>
    <v-col v-if="!serviceable">
      <non-serviceable-info/>
    </v-col>
    <!-- column 1 -->
    <v-col md="12">
      <v-col md="6" offset-md="6">
        <lead-statuses-search-field
            :salesRepresentative="true"
            v-model="leadStatusId"
            :sourceState="initialLeadStatusId"
        />
      </v-col>
      <v-row>
        <v-col md="12">
          <lead-appointment-fields
              ref="appointmentFields"
              :disabled="serviceable"
              :marketId="marketId"
          />
        </v-col>
        <v-col md="12">
          <lead-rooms-search-field
              ref="leadRoomsSearchField"
              :selectAllEnabled="false"
              :value="leadRooms"
              :placeholder="'Search Rooms'"
          />
        </v-col>

        <v-col md="12">
          <lead-product-types
              ref="productTypesSearchField"
              :value="leadProductTypes"
              :multiple="true"
              :placeholder="' '"
              :selectAllEnabled="false"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Constants from "../../../assets/constants";
import LeadStatusesSearchField from "../../../components/searchFields/LeadStatuses.vue";
import LeadRoomsSearchField from  "../../../components/searchFields/LeadRooms.vue";
import ProductTypesSearchField from  "../../../components/searchFields/LeadProductTypes.vue";
import LeadProductTypes from "../../searchFields/LeadProductTypes";
import NonServiceableInfo from "../../leads/NonServiceableInfo.vue";
import LeadAppointmentFields from "../../leads/LeadAppointmentFields.vue";

const formDefaults = {
  leadStatusId: 1,
  customerReferenceId: null,
  salesRepReferenceId: null,
  marketId: null,
  leadRooms: [],
  id: null,
  zipCode: null,
  leadProductTypes: [],
  initialLeadStatusId: Constants.LeadStatuses.New
};
export default {
  components: {
    LeadProductTypes,
    LeadStatusesSearchField,
    LeadRoomsSearchField,
    ProductTypesSearchField,
    LeadAppointmentFields,
    NonServiceableInfo
  },

  props: {
    serviceable: Boolean
  },
  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    buildPayload() {
      let payload = {};
      const appointments = this.$refs.appointmentFields.getValues();
      payload.appointmentTime = appointments.name;
      payload.appointmentDate = appointments.date;
      payload.customerReferenceId = this.customerReferenceId;
      payload.productTypesIds = this.$refs.productTypesSearchField.getFlatSelected();
      payload.roomTypes = this.$refs.leadRoomsSearchField.getFlatSelected();
      payload.leadStatusId = this.leadStatusId;
      payload.salesRepReferenceId = Array.isArray(this.salesRepReferenceId) ? null : this.salesRepReferenceId;
      payload.id = this.id;
      payload.zipCode = this.zipCode;
      payload.timeBlockId = appointments.timeBlockId;
      return payload;
    },
    setFormBackToOriginalData(payload, leadRooms, leadProductTypes) {
      this.loadForm(payload, leadRooms, leadProductTypes);
      this.$refs.leadRoomsSearchField.setSelected(leadRooms);
      this.$refs.productTypesSearchField.setSelected(leadProductTypes);
    },
    loadForm(payload, leadRooms, leadProductTypes) {
      this.marketId = payload.marketId;
      this.$refs.appointmentFields.loadForm(payload);
      this.customerReferenceId = payload.customerReferenceId || null;
      this.salesRepReferenceId = payload.salesRepReferenceId;
      this.leadStatusId = payload.leadStatusId;
      this.leadProductTypes = leadProductTypes;
      this.leadRooms = leadRooms || [];
      this.id = payload.id;
      this.initialLeadStatusId = payload.leadStatusId;
    }
  }
};
</script>
