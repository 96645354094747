<template>
  <modal-dialog
      ref="dialog"
      :title="title"
      :width="width"
      :minHeight="minHeight"
      :visible="dialogVisible"
      :loading="loading"
      :submitEnabled="submitEnabled"
      :submitLabel="submitLabel"
      :cancelLabel="cancelLabel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <v-form ref="form">
      <v-container fluid>
        <div class="text-left">
          <p class="mb-2"> Quantity On Hand: <span class="item-value"> <b> {{ onHand }} </b> </span></p>
          <p class="mb-2"> Quantity Available: <span :class="getClass"> <b> {{ available }} </b> </span></p>
        </div>

        <v-row class="pt-0">
          <inventory-item-radio-group
              ref="radioTypes"
              :hasJobReservationsToMove="hasJobReservationsToMove"
              :onHand="onHand"
              :available="available"
              @checkboxChanged="checkboxChanged"
          />
        </v-row>

        <v-row class="pb-4" v-show="showReservationDetails">
          <p class="text-left ml-3 mt-0 pt-0 mb-0 ml-0"><b>Job Reservation Details:</b></p>
          <job-products-available-to-move-grid
              v-if="dialogVisible"
              ref="productsToBeMoved"
              :inventoryItemId="inventoryItemId"
              @productSelected="onProductSelected"
              @hasJobReservationsToMove="onHasJobReservationsToMove"
          />
        </v-row>

        <v-row v-show="showRow">
          <v-col cols="4" v-show="showSelectedJob">
            <v-text-field
                :value="selectedJob"
                placeholder="Selected Job"
                persistent-placeholder
                label="Job ID"
                readonly
            />
          </v-col>

          <v-col cols="4" v-show="showSelectedItem">
            <v-text-field
                :value="selectedProduct"
                placeholder="Selected Item"
                persistent-placeholder
                label="Line item ID"
                readonly
            />
          </v-col>

          <v-col cols="4">
            <v-autocomplete
                label="* Move to Bin"
                v-model="destinationBin"
                item-text="binNumber"
                item-value="id"
                placeholder="Choose Bin"
                persistent-placeholder
                :items="activeBins"
                :menu-props="{ offsetY: true }"
                return-object
                @change="onBinSelected"
            />
          </v-col>
          <v-col cols="4" v-show="showQuantity">
            <v-text-field
                v-model="quantity"
                placeholder="Enter Quantity"
                persistent-placeholder
                label="* Quantity"
                type="number"
                :rules="[rules.validQuantityEntered]"
            />
          </v-col>

          <v-col cols="4" v-show="showQuantity">
            <v-text-field
                :value="quantityRounded"
                placeholder="0.00"
                persistent-placeholder
                label="Rounded to Box Qty"
                readonly
            />
          </v-col>
        </v-row>

      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import InventoryItemRadioGroup from "../../inventoryItems/InventoryItemRadioGroup.vue";
import JobProductsAvailableToMoveGrid from "../../../views/jobs/JobProductsAvailableToMoveGrid.vue";
import Bins from "../../../rest/bins";
import BoxQuantity from "../../../assets/boxQuantity";

const formDefaults = {
  selectedCheckBox: null,
  selectedJob: null,
  reservationId: null,
  selectedProduct: null,
  destinationBin: null,
  quantity: null,
  quantityRounded: 0.00,
  minHeight: '510px'
};

export default {
  components: {
    InventoryItemRadioGroup,
    ModalDialog,
    JobProductsAvailableToMoveGrid
  },

  props: {
    title: String,
    submitLabel: String,
    cancelLabel: String,
    width: {
      type: Number,
      default: 500
    },
    onHand: Number,
    available: Number,
    branchReferenceId: String,
    boxQuantity: [Number, null],
    itemBinNumber: [String, null],
    inventoryItemId: {
      type: [Number, String],
      required: true
    },
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    }
  },

  data() {
    return Object.assign(
        {
          activeBins: [],
          rules: {
            validQuantityEntered: value => {
              if (this.selectedCheckBox === 2 && value && value > this.available) {
                return 'Entered amount exceeds Quantity Available, please correct';
              } else if (this.selectedCheckBox === 2 && value && value <= 0) {
                return 'Quantity must be greater than 0';
              } else if (this.selectedCheckBox === 2 && value && this.quantityRounded > this.available) {
                return 'Rounded quantity exceeds Quantity Available, please correct';
              } else {
                return true;
              }
            }
          },
          hasJobReservationsToMove: false
        },
        formDefaults
    );
  },

  watch: {
    branchReferenceId: {
      handler(val) {
        if (val) {
          this.getActiveBinsForBranch();
        }
      },
      immediate: true
    },
    quantity(value) {
      this.adjustToBoxQuantity(value);
    },
    itemBinNumber: {
      handler(newVal, oldVal) {
        if (newVal && (newVal !== oldVal)) {
          this.getActiveBinsForBranch();
        }
      },
      immediate: true
    }
  },

  computed: {
    showRow() {
      return this.selectedCheckBox;
    },
    showSelectedJob() {
      return this.selectedCheckBox && (this.selectedCheckBox === 3 || !this.selectedCheckBox);
    },
    showSelectedItem() {
      return this.selectedCheckBox && (this.selectedCheckBox === 3 || !this.selectedCheckBox);
    },
    showQuantity() {
      return this.selectedCheckBox === 2;
    },
    showReservationDetails() {
      return this.selectedCheckBox === 3;
    },
    submitEnabled() {
      return (this.selectedCheckBox === 3 && this.destinationBin && this.selectedJob && this.selectedProduct)
          || (this.destinationBin && this.selectedCheckBox === 2 && this.quantity && this.quantity > 0 && this.quantity <= this.available)
          || (this.destinationBin && this.selectedCheckBox === 1);
    },
    getClass() {
      return this.available === 0 ? "item-value-zero" : "item-value";
    }
  },

  methods: {
    reset() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    resetRadioButtons() {
      if (this.$refs.radioTypes) {
        this.$refs.radioTypes.backToDefaults();
      }
    },
    resetGridToDefaults() {
      if (this.$refs.productsToBeMoved) {
        this.$refs.productsToBeMoved.resetGridToDefaults();
      }
    },
    restoreDefaults() {
      Object.assign(this.$data, formDefaults);
    },
    fullResetAndRestoreDefaults() {
      this.reset();
      this.resetRadioButtons();
      this.resetGridToDefaults();
      this.restoreDefaults();
    },
    clearMovingSelection() {
      if (this.$refs.productsToBeMoved) {
        this.$refs.productsToBeMoved.clearSelection();
      }
    },
    onBinSelected(val) {
      this.$emit('destinationBinSelected', val);
    },
    onSubmitClicked() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit('submitClicked', this);
      }
    },
    onCancelClicked() {
      this.reset();
      this.resetRadioButtons();
      this.resetGridToDefaults();
      this.restoreDefaults();
      this.$emit('cancelMovementClicked');
    },
    checkboxChanged(value) {
      this.restoreDefaults();
      this.clearMovingSelection();
      this.selectedCheckBox = value;
    },
    onProductSelected(selected) {
      this.selectedJob = selected.JobReferenceId;
      this.selectedProduct = selected.LineItemReferenceId;
      this.reservationId = selected.Id;
    },
    async getActiveBinsForBranch() {
      if (this.branchReferenceId) {
        const response = await Bins.getRestApi().getAllActiveBinsByBranch(this.branchReferenceId);
        this.activeBins = this.filterExceptItsOwnBin(response.data);
      }
    },
    adjustToBoxQuantity(quantity) {
      let roundedBoxQuantity = BoxQuantity.roundValueToBoxQuantity(quantity, this.boxQuantity);
      let hasDigits = BoxQuantity.hasNonZeroThirdAndFourthDigitsAfterDot(Number(roundedBoxQuantity));
      this.quantityRounded = hasDigits ? roundedBoxQuantity : Number(roundedBoxQuantity).toFixed(2);
    },
    filterExceptItsOwnBin(bins) {
      return bins.filter(b => b.binNumber !== this.itemBinNumber);
    },
    getFormData() {
      return {
        binId: this.destinationBin.id,
        quantity: this.setQuantity(),
        reservationId: this.reservationId,
        inventoryItemId: null
      }
    },
    setQuantity() {
      return (this.quantityRounded && this.quantityRounded > 0)
          ? Number(this.quantityRounded)
          : (typeof this.quantity == 'string' ? Number(this.quantity) : null);
    },
    onHasJobReservationsToMove() {
      this.hasJobReservationsToMove = true;
    }
  }
};
</script>

<style scoped>
.item-value {
  color: #2a5885;
}

.item-value-zero {
  color: #cd1032;
}
</style>
