<template>
  <div class="selectButton">
    <div id="grid-toolbar" slot="toolbar">
      <grid-toolbar
          :displayColumnChooser="displayColumnChooser"
          @onToggleResetGridDialog="clearGridState"
      />
    </div>
    <inventory-items-branch-search-grid
        ref="groupedInventoryChooser"
        :selectedFromBranch="selectedFromBranch"
        @rowSelected="onAddSelected"
    />
    <div class="chipContainer">
      <template>
        <v-chip
            outlined
            close
            text-color="#333"
            v-for="item in selected"
            :key="item.internalSku"
            @click:close="onRemoveSelected(item)"
        >
          <strong>{{ item.ProductType }}  </strong>&nbsp;
          <span>{{ buildInventoryItemName(item) }}</span>
        </v-chip>
      </template>
    </div>
  </div>
</template>

<script>
import GridToolbar from "../../common/grid/GridToolbar.vue";
import InventoryItemsBranchSearchGrid from "../../inventoryItems/InventoryItemsBranchSearchGrid.vue";
import ItemsNamingHelper from "../../../assets/ItemsNamingHelper";

export default {

  components: {
    InventoryItemsBranchSearchGrid,
    GridToolbar,
  },

  props: {
    displayColumnChooser: {
      type: Boolean,
      default: false
    },
    selectedFromBranch: Object,
  },

  data() {
    return {
      selected: [],
      error: null
    };
  },

  methods: {
    reset() {
      this.selected = [];
      this.removeSelection();
    },
    showError(error) {
      this.error = error;
    },
    onAddSelected(event) {
      if (event.selectedRowsData.length) {
        const newlySelectedItem = {...event.selectedRowsData[0]};
        let isItemAlreadySelected = this.checkIfDuplicateExists(newlySelectedItem);
        if (isItemAlreadySelected) {
          return;
        }
        this.$emit('atLeastOneItemSelected');
        this.selected.push(newlySelectedItem);
      }
    },
    checkIfDuplicateExists(newSelection) {
      return this.selected.some(item => item.Id === newSelection.Id);
    },
    onRemoveSelected(item) {
      let selectedRows = this.$refs.groupedInventoryChooser.getSelectedRows();
      selectedRows.forEach(itemToRemove => {
        if (itemToRemove.Id === item.Id) {
          this.removeSelection();
        }
      });
      this.selected = this.selected.filter(current => current.Id !== item.Id);
      if (this.selected.length === 0) {
        this.$emit('nothingSelected');
      }
    },
    getSelectedItems() {
      return this.selected;
    },
    clearGridState() {
      this.$refs.groupedInventoryChooser.clearGrid();
    },
    removeSelection() {
      this.$refs.groupedInventoryChooser.deselectItems();
    },
    buildInventoryItemName(item) {
      return ItemsNamingHelper.concatenateInventoryItemProperties(item);
    },
  }
};
</script>

<style scoped>
.chipContainer {
  min-height: 75px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.selectButton {
  padding-bottom: 20px;
}

.toolbar {
  margin-top: 10px;
  margin-left: 1px;
  margin-right: 1px;
}
</style>
