<template>
  <v-card light>
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Return from Job</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">Choose Item(s)</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2">Enter Type and Quantity</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <choose-items-form
              ref="chooseItemsForm"
              :checkedOutItems="checkedOutItems"
              :hasVendorChargebacks="hasVendorChargebacks"
          />
          <button-container-with-cancel
              nextName=Next
              cancelName=Cancel
              :hidePreviousStep="true"
              :hide-next-step="hasVendorChargebacks"
              @cancel="resetWizard"
              @getNextStep="goToSubmitStep"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <return-submit-form
              ref="returnSubmitForm"
              :selectedItemsFromProductItems="selectedItemsFromProductItems"
              @onErrorOccurred="errorOccurred"
          />
          <button-container-with-cancel
              backName=Back
              nextName=Submit
              cancelName=Cancel
              @cancel="resetWizard"
              @getPreviousStep="goToStep(1)"
              @getNextStep="submitFormClicked"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <job-message-dialog
        ref="messageDialog"
        :message="'Contract must be amended'"
        :hideCancel="true"
        :multiLineError="true"
        @accept="onAcceptMessage"
        @cancel="onCancelMessage"
    />
  </v-card>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import ChooseItemsForm from "./ChooseItemsForm.vue";
import ReturnSubmitForm from "./ReturnFromJobContainer.vue";
import ProductLineItems from "./ProductLineItemsContainer.vue";
import ButtonContainerWithCancel from "../../common/wizard/ButtonContainerWithCancel.vue";
import JobLineItems from "../../../rest/jobLineItems";
import JobMessageDialog from "@/components/jobs/JobMessageDialog.vue";

export default {
  components: {
    JobMessageDialog,
    ButtonContainerWithCancel,
    ProductLineItems,
    ModalDialog,
    ChooseItemsForm,
    ReturnSubmitForm
  },

  props: {
    productItems: {
      type: Array,
      required: true
    },
    hasVendorChargebacks: {
      type: Boolean,
      required: true
    },
    wsSessionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      selected: [],
      error: "There are no items selected. Please select from above to add products to the job.",
      step: 1
    };
  },

  computed: {
    checkedOutItems() {
      return this.productItems
          .filter(value => value.jobLineItem.status === 'CheckedOut')
          .map(value => {
            return {
              ReferenceId: value.jobLineItem.referenceId,
              InternalSku: value.jobLineItem.productCatalogItem.internalSku,
              ProductType: value.jobLineItem.productCatalogItem.productType.name,
              StyleName: value.jobLineItem.productCatalogItem.styleName,
              Color: value.jobLineItem.productCatalogItem.color,
              Description: value.jobLineItem.productCatalogItem.description
            };
          });
    },
    selectedItemsFromProductItems() {
      const referenceIds = this.selected.map(item => item.ReferenceId);
      return this.productItems.filter(item => referenceIds.includes(item.jobLineItem.referenceId));
    }
  },

  methods: {
    openDialog() {
      this.$refs.chooseItemsForm.clearError();
    },
    closeDialog() {
      this.selected = [];
      this.step = 1;
      if (!this.hasVendorChargebacks) {
        this.$refs.chooseItemsForm.reset();
      }
      this.$emit('closeDialog');
    },
    errorOccurred(error) {
      this.$emit('onErrorOccurred', error);
    },
    resetWizard() {
      this.clearForms();
      this.closeDialog();
    },
    goToStep(step) {
      this.step = step;
    },
    goToSubmitStep() {
      const selectedItems = this.$refs.chooseItemsForm.getSelectedItems();
      if (selectedItems.length) {
        this.$refs.chooseItemsForm.clearError();
        this.selected = selectedItems;
        this.step = 2;
      } else {
        this.$refs.chooseItemsForm.showError(this.error);
      }
    },
    submitFormClicked() {
      const payload = this.buildPayload();
      let valid = this.$refs.returnSubmitForm.validateReturnFromJobForms();
      let hasFullOrPartial = this.isFullOrPartialReturn(payload);
      if (valid && hasFullOrPartial) {
        this.$refs.messageDialog.openDialog();
      } else if (valid) {
        this.inProgress = true;
        this.performReturnFromJobRequest(payload);
      }
    },
    clearForms() {
      this.$refs.returnSubmitForm.clearRefs();
    },
    buildPayload() {
      let returnFromJobRows = [];
      this.$refs.returnSubmitForm.$children.forEach(formRow => {
        returnFromJobRows.push(formRow.getFormData());
      });
      return {
        returnProductItems: returnFromJobRows
      }
    },
    async performReturnFromJobRequest(payload) {
      try {
        await JobLineItems.getRestApi().returnProductLineItems(this.$route.params.jobReferenceId, payload, this.wsSessionId);
        this.$emit('submitReturnFromJob');
        this.closeDialog();
      } catch (error) {
        this.$emit('onErrorOccurred', error);
      } finally {
        this.inProgress = false;
      }
    },
    isFullOrPartialReturn(payload) {
      return payload.returnProductItems.some(i => i.returnType === "FULL_RETURN" || i.returnType === "PARTIAL_RETURN");
    },
    onAcceptMessage() {
      this.submitAccepted();
      this.$refs.messageDialog.closeDialog();
    },
    onCancelMessage() {
      this.$refs.messageDialog.closeDialog();
    },
    submitAccepted() {
      const payload = this.buildPayload();
      this.inProgress = true;
      this.performReturnFromJobRequest(payload);
    }
  }
};
</script>
