<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Transfer Requests
          </v-expansion-panel-header>
          <v-expansion-panel-content key="transfer">
            <transfer-requests-group-summaries-grid
                ref="transferRequestsSummariesGrid"
                :transferRequests="shipment.transferRequests"
                :shipment="shipment"
                @transferRequestAdded="onTransferRequestAdded"
                @addTransfersError="onAddTransfersErrorOccurred"
                @removeTransferRequestSuccess="onRemoveTransferRequestSuccess"
                @removeTransferRequestFromShipmentError="onRemoveTransferRequestFromShipmentError"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                parentType="shipment"
                :shipmentNumber="shipment.shipmentNumber"
                :documentTypesCategory=Constants.documentTypesCategories.TRANSFER_REQUEST_AND_SHIPMENT
                @filesGridError="showError"
            />
          </v-tab-item>

          <v-tab key="notes">Activity Logs</v-tab>
          <v-tab-item key="activityLogs">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InventoryToTransferGrid from "../../components/inventoryItems/transferRequest/InventoryToTransferGrid.vue";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import Constants from "../../assets/constants";
import FilesGrid from "../../components/common/inlineGrids/files/FilesGrid.vue";
import TransferRequestsGrid from "../transferRequests/TransferRequestsGrid.vue";
import TransferRequestsGroupSummariesGrid from "../transferRequests/TransferRequestsGroupSummariesGrid.vue";
import Shipments from "../../rest/shipments";

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    TransferRequestsGroupSummariesGrid,
    FilesGrid,
    ActivityLogsGrid,
    InventoryToTransferGrid,
    TransferRequestsGrid,
  },

  props: {
    shipment: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activityLogs: [],
      transferRequestItems: [],
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    display() {
      try {
        this.getActivityLogs();
      } catch (error) {
        this.showError(error);
      }
    },
    async refreshActivityLog() {
      if (this.$refs.activityLog) {
        await this.getActivityLogs();
      }
    },
    showError(error) {
      this.$emit('showError', error);
    },
    async getActivityLogs() {
      try {
        const response = await Shipments.getRestApi().getActivityLogs(this.shipment.shipmentNumber);
        this.activityLogs = response.data;
      } catch (error) {
        throw error;
      }
    },
    onTransferRequestAdded() {
      this.$emit('transferRequestAdded');
    },
    onAddTransfersErrorOccurred(error) {
      this.$emit('addTransfersError', error);
    },
    onRemoveTransferRequestSuccess(transferNumber) {
      this.$emit('removeTransferRequestSuccess', transferNumber);
    },
    onRemoveTransferRequestFromShipmentError(error) {
      this.$emit('removeTransferRequestFromShipmentError', error);
    }
  }
};
</script>

<style scoped>
</style>
