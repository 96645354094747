<template>
  <navigation-view ref="nav" page-title="Slots management" nav-section="installer">
    <template v-slot:content>
      <div class="calendar-page-container">

        <v-row class="mb-0" justify-start>
          <v-col md="3">
            <branches-autocomplete
                label="* Branch"
                :required="true"
                :selectAllEnabled="false"
                :value="branchId"
                :get-id="true"
                @inputSelected="onBranchSelect"
            />
          </v-col>
          <v-col v-if="branchId" md="4" class="week-switcher-container">
            <calendar-week-switcher
                v-if="selectedWeekDate"
                :current-date="selectedWeekDate"
                @previousWeekClicked="previousWeekClicked"
                @nextWeekClicked="nextWeekClicked"
                @todayClicked="todayClicked"
            />
          </v-col>
        </v-row>
        <div v-if="branchId">
          <add-slot-dialog
              ref="addSlotDialog"
              :branch-id="branchId"
              :ws-session-id="wsSessionId"
              @onSubmit="refreshSlotsAndLogs"
              @onError="showError"
          />
          <edit-slot-dialog
              ref="editSlotDialog"
              :branch-id="branchId"
              @slotDeleted="refreshSlotsAndLogs"
          />
          <error-modal
              ref="errorModal"
              :error="error"
              :title="'Error'"
              :visible="isErrorModalOpen"
              @closeErrorModal="closeErrorModal"
          />
          <FullCalendar
              ref="fullCalendar"
              class="installer-calendar"
              :options="calendarOptions">

            <template v-slot:eventContent='arg'>
              <calendar-event-content :event="arg.event"/>
            </template>

            <template v-slot:dayHeaderContent='arg'>
              <calendar-day-header
                  :date="arg.date"
                  :slots-summary="slotsSummary"
                  @addSlotClicked="onAddSlotClicked"
              />
            </template>
          </FullCalendar>

          <v-expansion-panels multiple accordion :value="expanded" class="mt-3">
            <v-expansion-panel>
              <v-expansion-panel-header color="#69b">
                Activity Logs
              </v-expansion-panel-header>
              <v-expansion-panel-content key="activityLog">
                <slots-activity-logs
                    ref="slotsActivityLogs"
                    :branch-id="branchId"
                    @showError="showError"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="select-branch-view" v-else>
          <div>Select the branch to start</div>
          <img src="@/assets/installerTracker/icons/calendar.svg" alt="Calendar icon"/>
        </div>
      </div>
    </template>
  </navigation-view>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import NavigationView from "../../navigation/NavigationView.vue";
import BranchesAutocomplete from "../../../components/searchFields/BranchesAutocomplete.vue";
import CalendarDayHeader from "./CalendarDayHeader.vue";
import AddSlotDialog from "./AddSlotDialog.vue";
import CalendarEventContent from "./CalendarEventContent.vue";
import CalendarWeekSwitcher from "./CalendarWeekSwitcher.vue";
import EditSlotDialog from "./EditSlotDialog.vue";
import InstallerSlots from "../../../rest/installer/installerSlots";
import ErrorModal from "../../../components/navigation/ErrorModal.vue";
import DateFormatter from "../../../assets/dateFormatter";
import DateUtils from "../../../assets/utils/dateUtils";
import WebSockets from "../../../websocket/webSockets";
import SlotsActivityLogs from "./SlotsActivityLogs.vue";
import ActivityLogsGrid from "../../../components/common/inlineGrids/ActivityLogsGrid.vue";
import Constants from "../../../assets/constants";

export default {
  components: {
    ActivityLogsGrid,
    SlotsActivityLogs,
    ErrorModal,
    EditSlotDialog,
    CalendarEventContent,
    AddSlotDialog,
    CalendarDayHeader,
    BranchesAutocomplete,
    NavigationView,
    FullCalendar,
    CalendarWeekSwitcher
  },

  data() {
    return {
      slotsGroups: [],
      slotsSummary: [],
      selectedWeekDate: new Date(),
      branchId: null,
      weekChangedThrottleTimer: null,
      stompJsClient: null,
      wsSessionId: null,
      error: null,
      isErrorModalOpen: false,
      expanded: Constants.expandedByDefault
    };
  },

  watch: {
    selectedWeekDate(newValue) {
      if (this.branchId) {
        if (!this.weekChangedThrottleTimer) {
          this.refreshSlots();
          // Set a throttle timer that resets after 1 second
          this.weekChangedThrottleTimer = setTimeout(() => {
            this.weekChangedThrottleTimer = null;
            // Check if the selectedWeekDate has changed again within the throttle window. If it has changed, call refreshSlots again
            if (newValue !== this.selectedWeekDate) {
              this.refreshSlots();
            }
          }, 1000);  // 1 second throttle delay
        }
      }
    },

    slotsGroups() {
      this.$refs.editSlotDialog.refresh();
    }
  },

  computed: {
    calendarOptions() {
      return {
        initialView: "dayGridWeek",
        weekends: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        aspectRatio: "2.4",
        events: this.formattedSlots,
        eventDisplay: 'block',
        firstDay: 1,
        headerToolbar: false,
        eventClick: this.handleEventClick,
        datesSet: this.handleDalesSet
      };
    },
    formattedSlots() {
      return this.slotsGroups.map((capabilitySlotsGroup) => {
        return {
          title: capabilitySlotsGroup.capabilityName,
          extendedProps: {
            availableSlotsAmount: capabilitySlotsGroup.totalSlots - capabilitySlotsGroup.scheduledSlots,
            totalSlotsAmount: capabilitySlotsGroup.totalSlots,
            capabilityId: capabilitySlotsGroup.capabilityId
          },
          textColor: 'black',
          date: capabilitySlotsGroup.slotDate
        };
      });
    },
    startOfWeek() {
      return DateUtils.getStartOfWeek(this.selectedWeekDate);
    },
    endOfWeek() {
      return DateUtils.getEndOfWeek(this.selectedWeekDate);
    }
  },

  methods: {
    onBranchSelect(branchId) {
      if (this.stompJsClient) {
        this.closeWebSocket();
      }
      this.branchId = branchId;
      if (this.$refs.fullCalendar && branchId) {
        this.refreshSlotsAndLogs();
      }
      this.connectWebSocket();
    },
    async refreshSlotsAndLogs() {
      await this.refreshSlots();
      this.refreshActivityLogs();
    },
    async refreshSlots() {
      const startOfWeek = DateFormatter.formatDateInUtcAndYYYYMMDDFormat(this.startOfWeek);
      const endOfWeek = DateFormatter.formatDateInUtcAndYYYYMMDDFormat(this.endOfWeek);
      InstallerSlots.getRestApi().getSlotsSummary(this.branchId, startOfWeek, endOfWeek)
          .then(response => {
            this.slotsSummary = response.data;
          })
          .catch(error => {
            this.showError(error.response.data.message);
          });
      InstallerSlots.getRestApi().getSlotsByCapability(this.branchId, startOfWeek, endOfWeek)
          .then(response => {
            this.slotsGroups = response.data;
          })
          .catch(error => {
            this.showError(error.response.data.message);
          });
    },
    refreshActivityLogs() {
      this.$refs.slotsActivityLogs.refreshGrid();
    },
    handleDalesSet() {
      this.selectedWeekDate = this.$refs.fullCalendar?.getApi().getDate();
    },
    todayClicked() {
      this.$refs.fullCalendar.getApi().today();
    },
    previousWeekClicked() {
      this.$refs.fullCalendar.getApi().prev();
    },
    nextWeekClicked() {
      this.$refs.fullCalendar.getApi().next();
    },
    onAddSlotClicked(date) {
      this.$refs.addSlotDialog.openDialog(date);
    },
    handleEventClick(arg) {
      this.$refs.editSlotDialog.openDialog(arg.event.start, arg.event.title, arg.event.extendedProps.capabilityId);
    },
    showError(errorMessage) {
      this.error = errorMessage;
      this.isErrorModalOpen = true;
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    // WebSockets
    connectWebSocket() {
      this.wsSessionId = WebSockets.generateWebSocketSessionId();
      this.stompJsClient = WebSockets.createStompJsClient(this.onConnectFunction);
    },
    onConnectFunction() {
      const topic = WebSockets.getWebSocketInstallerCalendarTopic() + "/branch/" + this.branchId;
      this.stompJsClient.subscribe(topic, (response) => {
        const message = JSON.parse(response.body);
        const isUpdateWithinOpenedWeek = DateUtils.isDateBetween(message.updatedDate, this.startOfWeek, this.endOfWeek);

        if (isUpdateWithinOpenedWeek && message.wsOriginSessionId !== this.wsSessionId) {
          this.refreshSlotsAndLogs();
          this.showWsUpdateNotification("Calendar refreshed");
        }
      });
    },
    closeWebSocket() {
      this.stompJsClient.deactivate();
      this.wsSessionId = null;
    },
    showWsUpdateNotification(message) {
      this.$refs.nav.showSuccess(message);
    }
  }
};
</script>

<style lang="scss" scoped>
.installer-calendar {

  // day header
  ::v-deep .fc-col-header-cell-cushion {
    width: 100%;
    padding: 0;
    cursor: default;
  }

  ::v-deep .fc-event {
    background-color: #F0F2F3;
    border: none;
    margin: 7.5px 8px;
    height: 36px;
    display: flex;

    &:hover {
      background-color: #7CA0BE;
    }

    .fc-event-main {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.calendar-page-container {
  padding: 10px 20px 0;
}

::v-deep .add-slot-dialog {
  position: absolute;
  top: 100px;
}

.week-switcher-container {
  padding: 0;
  margin-left: 10px;
}

.select-branch-view {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #666666;

  img {
    width: 360px;
  }
}
</style>
