import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";
import { ActivityLog } from "./interfaces/activityLogs";

/**
 * Reason code.
 */
export class ReasonCode {
  id: number = 0;
  name: string = "";
  description: string = "";
  active: string = "";
  debitAccount: number = 0;
  creditAccount: number = 0;
  title: string = "";

  constructor() {}
}

/**
 * REST interface provided for Reason codes.
 */
export interface ReasonCodesRestApi {
  createReasonCode(item: ReasonCode): AxiosPromise<ReasonCode>;
  updateReasonCode(id: number, updated: ReasonCode): AxiosPromise<ReasonCode>;
  getReasonCodes(): AxiosPromise<ReasonCode[]>;
  getActiveReasonCodes(): AxiosPromise<ReasonCode[]>;
  getAllActiveCheckInReasonCodes(): AxiosPromise<ReasonCode[]>;
  getAllActiveCheckOutReasonCodes(): AxiosPromise<ReasonCode[]>;
  getAllActiveReasonCodes(): AxiosPromise<ReasonCode[]>;
  getReasonCodeById(id: number): AxiosPromise<ReasonCode>;
  getReasonCodeLogs(id: number): AxiosPromise<ActivityLog[]>;
}

/**
 * REST implementation provided for Reason codes.
 */
class Implementation implements ReasonCodesRestApi {
  createReasonCode(item: ReasonCode): AxiosPromise<ReasonCode> {
    return Axios.axiosInstance().post<ReasonCode>("/reason", item);
  }
  updateReasonCode(id: number, updated: ReasonCode): AxiosPromise<ReasonCode> {
    return Axios.axiosInstance().put<ReasonCode>(`/reason/${id}`, updated);
  }
  getReasonCodes(): AxiosPromise<ReasonCode[]> {
    return Axios.axiosInstance().get<ReasonCode[]>("/reason");
  }
  getActiveReasonCodes(): AxiosPromise<ReasonCode[]> {
    return Axios.axiosInstance().get<ReasonCode[]>("/reason/active");
  }
  getAllActiveReasonCodes(): AxiosPromise<ReasonCode[]> {
    return Axios.axiosInstance().get<ReasonCode[]>("/reason/active/all");
  }
  getAllActiveCheckInReasonCodes(): AxiosPromise<ReasonCode[]> {
    return Axios.axiosInstance().get<ReasonCode[]>(
      "/reason/active/check-in/all"
    );
  }
  getAllActiveCheckOutReasonCodes(): AxiosPromise<ReasonCode[]> {
    return Axios.axiosInstance().get<ReasonCode[]>(
      "/reason/active/check-out/all"
    );
  }
  getReasonCodeById(id: number): AxiosPromise<ReasonCode> {
    return Axios.axiosInstance()
      .get<ReasonCode>(`/reason/${id}`)
      .then(result => {
        return result;
      });
  }
  getReasonCodeLogs(id: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/reason/${id}/logs`);
  }
  getAllReasonCodes(): AxiosPromise<ReasonCode> {
    return Axios.axiosInstance().get<ReasonCode>("/reason/search/all");
  }
}

const ReasonCodes = {
  getRestApi(): ReasonCodesRestApi {
    const api = new Implementation();
    return api;
  }
};

export default ReasonCodes;
