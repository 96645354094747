<template>
  <grid-view
      ref="nav"
      page-title="Inventory Bins"
      nav-section="admin"
      action-button-label="Create Bin"
      :data-source="dataSource"
      :columnMinWidth="150"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
      :branchUserRestricted="true"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="15%"
        :cell-template="StatusRenderer"
        data-type="string"
    />
    <dx-column
        data-field="BranchName"
        width="200"
        caption="Branch"
        :allowHeaderFiltering="allowBranchesFilter"
        :header-filter="{ dataSource: branchesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="BinNumber"
        width="15%"
        caption="Bin"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LastCycleCount"
        width="15%"
        caption="Last Cycle Count Date"
        data-type="date"
        format="MM/dd/yyyy"
        :minWidth="165"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Description"
        width="auto"
        caption="Description"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <template v-slot:drawerContent>
      <bin-create-drawer
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import BinCreateDrawer from "@/components/bins/BinCreateDrawer.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import StatusRenderer from "@/components/common/grid/StatusRenderer.js";
import RoleRestrictor from "@/rest/odata";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter.js";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    GridView,
    DxColumn,
    BinCreateDrawer
  },
  data() {
    const config = {
      allowBranchesFilter: false,
      branchesFilter: [],
      dataSource: {
        store: ODataContext['InventoryBins'],
        select: [
          "Id",
          "Active",
          "Status",
          "BranchName",
          "BinNumber",
          "LastCycleCount",
          "Description",
          "BranchReferenceId"
        ]
      }
    };
    return RoleRestrictor.updateConfig(this.$store.state.loggedInUser, config);
  },
  created() {
    this.getFilters();
  },

  methods: {
    async getFilters() {
      try {
        // once Market Ids are returned on user data, we can limit what branches the user sees in this dropdown
        // if user is apart of more than one branch
        const user = this.$store.state.loggedInUser;
        if (user.roleId != 5 || user.branchIds.length > 1) {
          this.branchesFilter = await CustomizeFilter.getUserBranchFilters();
          this.allowBranchesFilter = true;
        }
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    onRowSelected(data) {
      this.$router.push(`/bins/details/${data.Id}`);
    },
    onSubmitSuccess() {
      this.$refs.nav.showSuccess("Created new bin successfully!");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    StatusRenderer
  }
};
</script>
