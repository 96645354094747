<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="commission">Commission</v-tab>
          <v-tab key="installations">Installations</v-tab>
          <v-tab-item key="commission">
            <job-commission-summary :jobReferenceId="job.referenceId" />
          </v-tab-item>

          <v-tab-item key="installations">
            <job-installations
              ref="jobInstallations"
              :job-id="job.id"
              :jobReferenceId="job.referenceId"
              :branchId="job.branchId"
              :isSalesApp="true"
            />
          </v-tab-item>

          <v-tab key="notes">Notes</v-tab>
          <v-tab-item key="notes">
            <notes-grid
              ref="notesGrid"
              :lead="lead"
              :notes="notes"
              @saveNote="onSaveNote"
              @getNotes="onGetNotes"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import JobInstallations from "../../../components/installations/PanelContainer.vue";
import JobCommissionSummary from "../../../views/salesRepresentativesHome/job/JobCommissionSummary.vue";
import NotesGrid from "../../../components/common/inlineGrids/NotesGrid.vue";
import Notes from "../../../rest/notes";

export default {
  components: {
    JobInstallations,
    JobCommissionSummary,
    NotesGrid
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    lead:{
      type: Object,
      required: true
    }
  },

  data() {
    return {notes: []};
  },

  methods: {
    refreshInlineGrids() {
      this.refreshJobInstallations();
    },
    refreshJobInstallations() {
      if (this.$refs.jobInstallations) {
        this.$refs.jobInstallations.refresh();
      }
    },
    async onGetNotes() {
      try {
        const response = await this.getJobAndLeadNotes();
        this.setLeadAndJobNotes(response.data);
      } catch (error) {
        throw error;
      }
    },
    async getJobAndLeadNotes() {
      try {
        return await Notes.getRestApi().getJobAndLeadAndTicketNotes(this.job.id);
      } catch (error) {
        this.emitError(error);
      }
    },
    setLeadAndJobNotes(notes) {
      this.notes = notes;
    },
    async onSaveNote(data) {
      try {
        const params = { ...data };
        params.jobId = this.job.id;
        await this.saveNote(params);
        await this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      }
    },
    async saveNote(params) {
      try {
        await Notes.getRestApi().createJobNote(this.job.id, params);
      } catch (error) {
        throw error;
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
  }
};
</script>

<style scoped>
.tab-container {
  min-height: 170px;
}
</style>
