<template>
  <v-form ref="form">
    <v-container>
      <v-row class="mt-1">
        <v-col md="12" v-if="ifSummary">
          <v-textarea
              :rows="2"
              :auto-grow="true"
              v-model="note"
              label="Customer Concern"
              placeholder=" "
              disabled
          />
        </v-col>
        <v-col md="12">
          <v-text-field
              v-model="discountAmount"
              label="Discount"
              placeholder="0.00"
              persistent-placeholder
              type="number"
              prefix="$"
              :rules="[rules.required, rules.isCurrencyFormat, rules.isPositiveOrZero]"
              :disabled="ifSummary"
          />
        </v-col>
      </v-row>
      <div class="contract-prices">
        <h4 class="body-2">
          Original Total Contract Price: {{ originalContractPrice }}
        </h4>
      </div>
      <v-divider/>

      <div class="contract-prices">
        <h4 class="title">
          New Total Contract Price: {{ computedContractPrice }}
        </h4>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "../../../assets/rules";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";

const formDefaults = {
  note: null,
  discountAmount: null
};

export default {
  props: {
    jobReferenceId: String,
    ifSummary: Boolean,
    contractPrice: Object
  },

  data() {
    return Object.assign(
        {},
        {
          rules: {
            isCurrencyFormat: Rules.isCurrencyFormat,
            required: Rules.required,
            isPositiveOrZero: () => {
              return Rules.valueIsPositiveOrZeroWithName(this.contractPrice.subtract(this.discountAmount), "New Total Contract Price")
                  || false;
            },
          }
        },
        formDefaults
    );
  },

  computed: {
    computedContractPrice() {
      let computedContractPrice = this.calculateNewContractPrice();
      this.$emit('updateContractPrice', computedContractPrice);
      return CurrencyFormatter.formatCurrencyWithDollarPrefix(computedContractPrice);
    },
    originalContractPrice() {
      return CurrencyFormatter.formatCurrencyWithDollarPrefix(this.contractPrice);
    }
  },

  methods: {
    calculateNewContractPrice() {
      let discount = CurrencyFormatter.asCurrency(this.discountAmount);
      let originalPrice = CurrencyFormatter.asCurrency(this.contractPrice);
      return originalPrice.subtract(discount);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.loadForm(formDefaults);
      this.$refs.form.resetValidation();
    },
    getPayload() {
      const payload = {};
      payload.note = this.note;
      payload.discountAmount = this.discountAmount;
      return payload;
    },
    loadForm(payload) {
      this.note = payload.note;
      this.discountAmount = payload.discountAmount;
    }
  }
};
</script>

<style scoped>
.contract-prices {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 5px;
}
</style>
