<template>
  <v-chip outlined border="false">
    <v-icon :color="colorSchema">
      {{ icon.icon }}
    </v-icon>
    <span v-if="showFullName" class="ml-2 mr-2">{{ icon.fullName }}</span>
  </v-chip>
</template>

<script>

export default {
  props: {
    icon: Object,
    showFullName: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  data() {
    return {}
  },

  computed: {
    colorSchema() {
      return this.icon.color || 'primary';
    }
  }
};
</script>

<style scoped>
</style>
