<template>
  <div>
    <select-catalog-items-container ref="selectCatalogItems"/>

    <v-flex xs12 align-content-end>
      <v-divider/>
      <p class="stepError">{{ error }}</p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn class="ma-1" large dark outlined color="primary" @click="cancelClicked"> Cancel</v-btn>
          <v-btn class="ma-1" large dark type="submit" color="primary" @click="nextClicked"> Next</v-btn>
        </div>
      </v-card-actions>
    </v-flex>
  </div>
</template>

<script>
import SelectCatalogItemsContainer from "@/components/catalogItems/SelectCatalogItemsContainer.vue";

export default {
  components: {
    SelectCatalogItemsContainer
  },

  props: ["error"],

  methods: {
    cancelClicked() {
      this.$emit("cancelClicked");
    },
    nextClicked() {
      const selectedItems = this.$refs.selectCatalogItems.getSelectedItems();
      this.$emit("nextClicked", selectedItems);
    },
    resetChosenItems() {
      this.$refs.selectCatalogItems.reset();
    }
  }
};
</script>

<style scoped>
.stepError {
  color: red;
  text-align: right;
  height: 20px;
  padding-top: 5px;
}
</style>
