<template>
  <modal-dialog
      ref="dialog"
      title="Early Installer Payment"
      width="400"
      v-if="dialogVisible"
      :visible="dialogVisible"
      submitLabel="Submit"
      cancelLabel="Cancel"
      :loading="loadingEarlyPayment"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <v-row>
      <v-col class="ma-2">
        <p class="subheading">
          A payment of ${{ installationPrice }} will be released early to
          {{ installerCompanyName }}.
        </p>
      </v-col>
    </v-row>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../common/ModalDialog.vue";

export default {
  components: {
    ModalDialog
  },

  data() {
    return {
      dialogVisible: false,
      installation: null,
      installationPrice: null,
      installerCompanyName: null,
      loadingEarlyPayment: false
    };
  },

  methods: {
    openDialog(installation) {
      this.setData(installation);
      this.loadingEarlyPayment = false;
      this.dialogVisible = true;
    },
    setData(installation) {
      this.installation = installation;
      this.installationPrice = installation.jobLineItem.price;
      this.installerCompanyName = installation.installer.companyName;
    },
    closeDialog() {
      this.$data.loadingEarlyPayment = false;
      this.dialogVisible = false
    },
    showError(error) {
      this.error = error;
    },
    onSubmitClicked() {
      this.loadingEarlyPayment = true;
      let payment = {
        installation: this.installation,
        installationPrice: this.installation.jobLineItem.price
      };
      this.$emit('submitClicked', payment);
    },
    onCancelClicked() {
      this.dialogVisible = false
    }
  }
};
</script>
