<template>
  <v-main class="background">
    <div>
      <v-card flat class="d-flex flex-column mb-6">
        <main-header-panel
            :pageTitle="pageTitle"
            :loggedInUser="loggedInUser"
            @logoutClicked="logout"
            @changePasswordClicked="toggleChangePasswordModal"
        />

        <v-row class="mb=0" no-gutters>
          <v-col>
            <sales-rep-menu-options
                :navSection="navSection"
            />
          </v-col>

          <v-col class="d-flex flex-column justify-end" v-if="showSalesActionButtonsBlock">
            <slot name="nav-action"/>
          </v-col>
        </v-row>
      </v-card>

      <div>
        <transition name="fade">
          <slot name="toolbar"></slot>
        </transition>
        <slot name="content" class="content-slot"></slot>
      </div>

      <v-navigation-drawer
          v-model="drawer"
          :width="drawerWidth"
          right
          absolute
          temporary
          class="drawer"
      >
        <slot name="drawer"/>
      </v-navigation-drawer>
      <div class="drawer-background" v-if="drawer"></div>
    </div>
    <snackbar ref="snackbar" error-color="white" default-timeout="2000"/>

    <session-timeout-modal
        :visible="sessionTimeout"
        @onSessionTimeoutReached="sessionTimeoutReached"
        @onContinueSessionClicked="closeSessionModal"
    />

    <user-info-modal
        :visible="changePasswordModal"
        :sales-rep-id="this.$store.state.loggedInUser.id"
        @submitClick="toggleChangePasswordModal"
        @cancelClick="toggleChangePasswordModal"
    />

    <error-modal
        ref="errorModal"
        :error="error"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </v-main>
</template>
<script>

import SessionTimeoutModal from "@/components/users/SessionTimeoutModal.vue";
import ErrorModal from "@/components/navigation/ErrorModal.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import DxExport from "devextreme-vue/ui/data-grid";
import MainHeaderPanel from "@/components/navigation/MainHeaderPanel.vue";
import SalesRepMenuOptions from "@/components/navigation/SalesRepMenuOptions.vue";
import UserInfoModal from "../../components/userInfo/UserInfoModal";
import RouterHelper from "../../assets/routerHelper";
import ActivityDetector from "@/assets/activityDetector/ActivityDetector";
import Constants from "@/assets/constants";

export default {
  components: {
    UserInfoModal,
    DxExport,
    Snackbar,
    SessionTimeoutModal,
    ErrorModal,
    MainHeaderPanel,
    SalesRepMenuOptions
  },

  data() {
    return {
      error: null,
      isErrorModalOpen: false,
      changePasswordModal: false,
      successMessage: null,
      drawer: false,
      snackbar: false,
      drawerWidth: 600,
      sessionTimeout: false,
      activityDetector: null,
    };
  },

  props: {
    pageTitle: String,
    navSection: String
  },

  mounted() {
    this.initActivityDetector();
  },

  computed: {
    loggedInUser() {
      let user = this.$store.state.loggedInUser;
      if (user) {
        return user.firstName + " " + user.lastName;
      } else {
        return "Not Logged In";
      }
    },
    showSalesActionButtonsBlock() {
      return RouterHelper.isLeadOrJobDetailsPage(this.$route);
    }
  },

  methods: {
    logout(type) {
      const logoutType = this.defineLogoutType(type);
      this.$store.commit(logoutType, type);
      this.$router.push("/");
    },
    showDrawer() {
      this.drawer = true;
    },
    hideDrawer() {
      this.drawer = false;
    },
    showError(error) {
      this.error = error.response ? error.response.data.message : error;
      if (this.$refs.errorModal) {
        this.isErrorModalOpen = true;
      }
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    showSuccess(message) {
      this.$refs.snackbar.addMessage(message);
    },
    initActivityDetector() {
      const duration = Constants.SESSION_INACTIVITY_TIMEOUT;
      this.activityDetector = new ActivityDetector(duration, this.openSessionModal);
    },
    openSessionModal() {
      this.sessionTimeout = true;
    },
    closeSessionModal() {
      this.sessionTimeout = false;
    },
    toggleChangePasswordModal() {
      this.changePasswordModal = !this.changePasswordModal;
    },
    sessionTimeoutReached(logoutType) {
      this.logout(logoutType);
    },
    defineLogoutType(type) {
      return type === Constants.logoutTypes.INACTIVE
          ? Constants.logoutTypes.INACTIVE
          : Constants.logoutTypes.LOGOUT;
    }
  }
};
</script>

<style scoped>
.background {
  background-color: #f5f5f5;
}
.nav-bar {
  overflow-x: hidden;
  background-color: #f5f8fa;
  border-top: 1px solid #d1dbe3;
  border-bottom: 1px solid #d1dbe3;
}
.nav-user {
  color: #69b;
  margin-top: 5px;
}
.nav-username {
  font-size: 20px;
  vertical-align: top;
  padding-left: 5px;
  padding-right: 10px;
}
.nav-button {
  border-left: 1px solid #eee;
  font-size: 8px;
  padding-left: 15px;
  padding-right: 10px;
  color: #69b;
}
.nav-tab {
  font-size: 18px;
}
.user-dropdown {
  margin: 0 10px;
  padding-left: 10px;
}
.user-dropdown:hover {
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
}
.drawer {
  z-index: 99999;
}
.drawer-background {
  z-index: 9999;
  background-color: rgba(33, 33, 33, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
