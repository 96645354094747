<template>
  <modal-dialog
    ref="dialog"
    width="500"
    cancelLabel="Cancel"
    submitLabel="Schedule"
    title="Schedule Installation"
    :visible="dialogVisible"
    :hideSubmit="!hasLaborItems"
    :submitEnabled="true"
    @submitClicked="onSubmitClicked"
    @cancelClicked="onCancelClicked"
    :loading="loading"
  >
    <create-form
      ref="dialogForm"
      :branchId="branchId"
      :jobId="jobId"
      @hasLaborLineItems="onHaveLaborLineItems"
      @showError="showError"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import CreateForm from "@/components/installations/CreateForm.vue";
import Installations from "@/rest/installations";

export default {
  props: {
    branchId: {
      type: Number
    },
    jobId: {
      type: Number
    },
    wsSessionId: {
      type: String,
      required: true
    }
  },

  components: {
    CreateForm,
    ModalDialog
  },

  data() {
    return {
      hasLaborItems: false,
      dialogVisible: false,
      loading: false
    };
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
      this.refreshFormLaborLineItems();
    },
    closeDialog() {
      this.$refs.dialogForm.reset();
      this.dialogVisible = false
      this.loading = false;
    },
    onHaveLaborLineItems(hasLaborItems) {
      this.hasLaborItems = hasLaborItems;
    },
    refreshFormLaborLineItems() {
      this.$refs.dialogForm.refreshLaborLineItems();
    },
    onSubmitClicked() {
      if (this.$refs.dialogForm.validate(true)) {
        this.createInstallation();
        this.$data.loading = true;

      }
    },
    onCancelClicked() {
      this.closeDialog();
    },
    showError() {
      this.$refs.dialog.showError();
      this.loading = false;
    },
    async createInstallation() {
      try {
        const payload = this.$refs.dialogForm.buildPayload();
        this.$store.commit("setIsJobSaving", true);
        const response = await Installations.getRestApi().createInstallation(payload, this.wsSessionId);
        this.$emit("installationAdded", response.data);
        this.$store.commit("setIsJobSaving", false);
        this.closeDialog();
      } catch (error) {
        this.$emit("installationAddFailed", error);
        this.$store.commit("setIsJobSaving", false);
        this.loading = false;
      }
    }
  }
};
</script>
