<template>
  <div class="selectButton">
    <div id="grid-toolbar" slot="toolbar">
      <grid-toolbar
          @onToggleResetGridDialog="clearGridState"
      />
    </div>
    <transfer-requests-chooser-inline-grid
        ref="transferRequestChooser"
        v-if="fromBranchName && toBranchName"
        :from-branch="fromBranchName"
        :to-branch="toBranchName"
        status="New"
        @gridLoaded="setGridRowCount"
        @selectionChanged="onSelectionChanged"
    />
    <div class="chipContainer">
      <template>
        <v-chip
            outlined
            close
            text-color="#333"
            v-for="item in selected"
            :key="item.Id"
            @click:close="onRemoveSelected(item)"
        >
          <div>
            <strong>{{ item.TransferRequestNumber }}:</strong>&nbsp;
            <span>{{ buildSelectedTransferRequestName(item) }}</span>
          </div>
        </v-chip>
      </template>
    </div>
    <div v-show="rowCount > 0">
      <v-btn
          tonal
          ripple
          small
          text
          @click="changeSelection"
      >
        {{ selectButtonName }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import SearchCatalogItemsGrid from "../../components/catalogItems/SearchCatalogItemsGrid.vue";
import GridToolbar from "../../components/common/grid/GridToolbar.vue";
import ItemsNamingHelper from "../../assets/ItemsNamingHelper";
import TransferRequestsChooserInlineGrid from "../transferRequests/TransferRequestsChooserInlineGrid.vue";

export default {

  components: {
    TransferRequestsChooserInlineGrid,
    GridToolbar,
    SearchCatalogItemsGrid
  },

  props: {
    fromBranchName: String,
    toBranchName: String,
  },

  data() {
    return {
      selected: [],
      allRowsSelected: false,
      rowCount: 0
    };
  },

  watch: {
    selected() {
      this.allRowsSelected = this.selected.length === this.$refs.transferRequestChooser.getTotalRowsCount();
      if (this.selected.length > 0) {
        this.$emit('enableSubmit');
      } else {
        this.$emit('disableSubmit');
      }
    }
  },

  computed: {
    selectButtonName() {
      return this.allRowsSelected ? "Deselect All" : "Select All";
    }
  },

  methods: {
    reset() {
      this.selected = [];
      this.rowCount = 0;
    },
    checkIfDuplicateExists(newSelection) {
      return this.selected.some(item => item.TransferRequestNumber === newSelection.TransferRequestNumber);
    },
    onRemoveSelected(selected) {
      let selectedRows = this.$refs.transferRequestChooser.getSelectedRows();
      selectedRows.forEach(itemToRemove => {
        if (itemToRemove.Id === selected.Id) {
          this.removeSelection();
        }
      });
      this.selected = this.selected.filter(current => current && current.Id !== selected.Id);
    },
    getSelectedItems() {
      return this.selected;
    },
    clearGridState() {
      this.$refs.transferRequestChooser.clearGrid();
      this.$emit('clearError');
    },
    removeSelection() {
      this.$refs.transferRequestChooser.deselectItems();
    },
    changeSelection() {
      if (this.allRowsSelected) {
        this.$refs.transferRequestChooser.deselectItems();
        this.allRowsSelected = false;
        this.selected = [];
      } else {
        this.$refs.transferRequestChooser.selectAllItems();
      }
    },
    buildSelectedTransferRequestName(selected) {
      return ItemsNamingHelper.buildTransferRequestDisplayName(selected);
    },
    onSelectionChanged(selectedRows) {
      let totalRows = this.$refs.transferRequestChooser.getTotalRowsCount();
      if (totalRows === this.selected.length) {
        this.allRowsSelected = true;
        return;
      }
      if (selectedRows.length > 1 && this.selected.length === 0) {
        selectedRows.forEach(row => this.selected.push(row));
        this.allRowsSelected = true;
        return;
      } else if (selectedRows.length > 1 && this.selected.length > 0) {
        selectedRows.forEach(row => {
          const notInSelected = this.selected.find(item => item.Id !== row.Id);
          if (notInSelected) {
            this.selected.push(row);
          }
        });
        this.allRowsSelected = true;
        return;
      } else if (selectedRows.length === 1 && this.selected.length < totalRows && this.selected.length > 0) {
        this.selected.push(selectedRows[0]);
        return;
      }
      let oneRowOnly = selectedRows[0];
      let itemIsAlreadySelected = this.checkIfDuplicateExists(oneRowOnly);
      if (itemIsAlreadySelected) {
        let errorName = 'This item: ' + oneRowOnly.TransferRequestNumber + " "
            + this.buildSelectedTransferRequestName(oneRowOnly) + ' is already selected';
        this.$emit('showError', errorName);
        return;
      }
      this.selected.push(oneRowOnly);
    },
    setGridRowCount(rowCount) {
      this.rowCount = rowCount;
    }
  }
};
</script>

<style scoped>
.chipContainer {
  min-height: 75px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.selectButton {
  padding-bottom: 20px;
}

.toolbar {
  margin-top: 10px;
  margin-left: 1px;
  margin-right: 1px;
}
</style>
