<template>
  <v-dialog v-model="wizardVisible" persistent :width="800">
    <v-app-bar dense flat dark color="primary">
      <v-app-bar-title>Installation Issue</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step" v-if="wizardVisible">
      <stepper-header :step="step"/>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pl-2 pr-2 pb-2">
            <v-textarea
                ref="installerIssue"
                class="pt-4"
                :auto-grow="true"
                v-model="note"
                label="* Describe Installer Issue"
                placeholder=" "
                persistent-placeholder
                :counter="255"
                autofocus
                :rules="[rules.required, rules.isNotWhitespace, rules.max255]"
            />
            <button-container
                backName="Cancel"
                nextName="Next"
                :hidePreviousStep="true"
                @cancel="closeDialog"
                @getPreviousStep="resetWizard"
                @getNextStep="goToStepTwo"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="pl-2 pr-2 pb-2">
            <details-form-body
                ref="detailsFormBody"
                :job="job"
                :jobReferenceId="job.referenceId"
                :installations="installations"
                @disableNextButton="disableNextButton"
            />
            <button-container
                backName="Back"
                nextName="Next"
                :disableNextStep="disableNextStep"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(1)"
                @getNextStep="goToStepThree"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="pl-2 pr-2 pb-2">
            <financial-responsibility-form-body
                ref="financialResponsibilityFormBody"
                :totalIssueAmount="totalIssueAmount"
                :laborAssociatedAmount="laborAmount"
                :isMultiplierRequired="isMultiplierRequired"
            />
            <button-container
                backName="Back"
                nextName="Submit"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(2)"
                @getNextStep="onSubmitClicked"
            />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import DetailsFormBody from "../../../components/jobs/installationIssueWizard/DetailsFormBody.vue";
import ButtonContainer from "../../../components/common/wizard/ButtonContainerWithCancel.vue";
import FinancialResponsibilityFormBody from "./FinancialResponsibilityFormBody.vue";
import StepperHeader from "../../../components/jobs/installationIssueWizard/StepperHeader.vue";
import Constants from "../../../assets/constants";
import Rules from "../../../assets/rules";
import Jobs from "../../../rest/jobs";
import Branches from "../../../rest/branches";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    ButtonContainer,
    DetailsFormBody,
    FinancialResponsibilityFormBody,
    StepperHeader,
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    contractPrice: Object,
  },

  data() {
    return Object.assign(
        {},
        {
          type: Constants.jobType.installIssue,
          installations: null,
          isMultiplierRequired: false,
          quantity: 1,
          comment: null,
          wizardVisible: false,
          error: null,
          step: 1,
          selectedLead: null,
          gridLoaded: false,
          note: null,
          installation: null,
          installerReimbursable: null,
          laborAmount: null,
          laborAssociated: null,
          materialsAmount: null,
          materialsAssociated: null,
          customerSatisfaction: false,
          customerSatisfactionAmount: null,
          disableNextStep: true,
          rules: {
            required: Rules.required,
            isNotWhitespace: value => !Rules.isWhitespaceOnly(value),
            max255: value => Rules.maxValue(value, 255)
          },
        }
    );
  },

  computed: {
    totalIssueAmount() {
      let laborAmount = 0.0;
      let materialsAmount = 0.0;
      if (this.laborAmount) {
        laborAmount = Number.parseFloat(this.laborAmount) || 0.0;
      }
      if (this.materialsAmount) {
        materialsAmount = Number.parseFloat(this.materialsAmount) || 0.0;
      }
      return laborAmount + materialsAmount;
    },
  },

  watch: {
    wizardVisible(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.installerIssue.focus();
        });
      }
    }
  },

  methods: {
    async getJobInstallationsItems() {
      try {
        const response = await Jobs.getRestApi().getJobInstallations(this.job.referenceId, true,
            true, false, false);
        this.installations = response.data.filter(installation => !DateUtils.isFutureDate(installation.installationDate));
      } catch (error) {
        this.$emit('error', error);
      }
    },
    async isScgBranch(branchId) {
      try {
        const response = await Branches.getRestApi().isSCGBranch(branchId);
        this.isMultiplierRequired = response.data;
      } catch (error) {
        this.$emit('error', error);
      }
    },
    openDialog() {
      this.getJobInstallationsItems();
      this.isScgBranch(this.job.branchId);
      this.wizardVisible = true;
      this.resetWizard();
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    showError(error) {
      this.$emit('showError', error);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    handleCreateFineSuccess() {
      this.showSuccess('Installation Issue has been saved successfully');
      this.closeDialog();
    },
    resetWizard() {
      this.step = 1;
      this.note = null;
      this.installation = null;
      this.installerReimbursable = null;
      this.laborAmount = null;
      this.laborAssociated = null;
      this.materialsAmount = null;
      this.materialsAssociated = null;
      this.customerSatisfaction = null;
      this.customerSatisfactionAmount = null;
      this.disableNextStep = true;
      this.$refs.installerIssue.reset();
      this.$refs.detailsFormBody.reset();
      this.$refs.financialResponsibilityFormBody.reset();
    },
    onLeadSelected(data) {
      this.selectedLead = data;
    },
    goToStepTwo() {
      if (this.$refs.installerIssue.validate()) {
        this.goToStep(2);
      }
    },
    goToStep(step) {
      this.step = step;
    },
    goToStepThree() {
      let valid = this.$refs.detailsFormBody.validate();
      if (valid) {
        const payload = this.$refs.detailsFormBody.getPayload();
        payload.note = this.note;

        this.installation = payload.installation;
        this.installerReimbursable = payload.installerReimbursable;
        this.laborAmount = payload.laborAmount;
        this.laborAssociated = payload.laborAssociated;
        this.materialsAmount = payload.materialsAmount;
        this.materialsAssociated = payload.materialsAssociated;
        this.goToStep(3);
      }
    },
    async onSubmitClicked() {
      try {
        const valid = this.$refs.financialResponsibilityFormBody.validate();
        if (valid) {
          const request = this.createRequestObject();
          this.closeDialog();
          this.$emit('submitSuccess', request);
        }
      } catch (error) {
        this.$emit('submitError', error);
      }
    },
    createRequestObject() {
      const payload = this.$refs.financialResponsibilityFormBody.getPayload();
      return {
        installation: this.installation,
        type: this.type,
        quantity: this.quantity,
        note: this.note,
        installerReimbursement: this.installerReimbursable,
        laborAssociated: this.laborAssociated,
        laborAmount: this.laborAmount,
        materialsAmount: this.materialsAmount,
        materialsAssociated: this.materialsAssociated,
        customerSatisfaction: payload.customerSatisfaction,
        customerSatisfactionAmount: payload.customerSatisfactionAmount,
        contractAdjustment: payload.contractAdjustment,
        installerPayable: payload.installerPayable,
        scgPayable: payload.scgPayable
      };
    },
    disableNextButton(value) {
      this.disableNextStep = value;
    }
  },
};
</script>
