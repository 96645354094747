import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

export interface PlanCode {
  id: number;
  code: string;
}

export interface PlanCodesRestApi {
  getAllPlanCodes(): AxiosPromise<PlanCode[]>;
}

class Implementation implements PlanCodesRestApi {

  getAllPlanCodes(): AxiosPromise<PlanCode[]> {
    return Axios.axiosInstance().get<PlanCode[]>("/payments/plan-codes/all");
  }
}

const PlanCodes = {
  getRestApi(): PlanCodesRestApi {
    return new Implementation();
  }
};

export default PlanCodes;
