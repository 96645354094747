<template>
  <div>
    <v-card flat>
      <section-divider title="LABOR"/>
      <headers/>
      <panel
          ref="laborLineItemsPanel"
          :job="job"
          v-for="item in laborItems"
          :line-item="item"
          :key="item.jobLineItem.referenceId"
          :availableCapabilities="availableCapabilities"
          @priceUpdated="onPriceUpdated"
          @quantityUpdated="onQuantityUpdated"
          @commentUpdated="onCommentUpdated"
          @installerUpdated="onInstallerUpdated"
          @installationCapabilityUpdated="onInstallationCapabilityUpdated"
          @itemDeleted="onItemDeleted"
          @earlyInstallerPayment="onEarlyInstallerPayment"
          @installerCrewUpdated="onInstallerCrewUpdated"
          @installationDateUpdated="onInstallationDateUpdated"
          @installationStatusUpdated="onInstallationStatusUpdated"
          @etcUpdated="onETCUpdated"
          @stopUpdated="onStopUpdated"
          @checkIfSomeItemHasTheSameCrewAndDate="checkIfSomeItemHasTheSameCrewAndDate"
      />
      <v-card-actions class="pr-3">
        <v-spacer></v-spacer>
        <v-btn
            v-if="canAddLabor"
            color="primary"
            class="section-button"
            @click="onAddLaborItem"
        >
          Add Labor
        </v-btn>
      </v-card-actions>
    </v-card>
    <early-installer-payment-dialog
        ref="earlyInstallerPaymentDialog"
        @submitClicked="onEarlyPaymentSubmit"
    />
  </div>
</template>

<script>
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import CreateReferenceIds from "../../../assets/referenceIds";
import Constants from "../../../assets/constants.js";
import Headers from "../../../components/jobs/laborLineItems/LaborLineItemsHeaders.vue";
import Panel from "../../../components/jobs/laborLineItems/LaborLineItemsPanel.vue";
import EarlyInstallerPaymentDialog from "../../../components/jobs/laborLineItems/EarlyInstallerPaymentDialog.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";
import InstallationCapabilities from "../../../rest/installationCapabilities";
import Installations from "../../../rest/installations";
import DateFormatter from "../../../assets/dateFormatter";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider,
    EarlyInstallerPaymentDialog
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    initialItems: {
      type: Array,
      required: true
    },
    installationIssueUpdated: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data() {
    return {
      laborItems: [],
      availableCapabilities: []
    };
  },

  created() {
    this.getAllAvailableCapabilities();
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.laborItems = value;
      }
    },
    installationIssueUpdated(value) {
      if (value) {
        this.updateLaborInstallerPayableValue(value.updatedJobLineItemId, value.installerPayable, value.increase);
      }
    }
  },

  computed: {
    canAddLabor() {
      return !this.job.invoiced;
    }
  },

  methods: {
    // Add new labor line item.
    onAddLaborItem() {
      let item = {};
      let component = this;
      let refid = CreateReferenceIds.getTempReferenceId();
      item.jobLineItem = {
        referenceId: refid,
        type: Constants.jobType.labor,
        quantity: 1,
        price: CurrencyFormatter.zeroCurrency(),
        status: null,
        comment: null,
        newLineItem: true,
        installationCapabilityId: null
      };
      component.laborItems.push(item);
      this.$emit('itemsUpdated', this.laborItems);
      this.$emit('amountsUpdated');
    },
    onItemDeleted(referenceId) {
      this.laborItems = this.laborItems.filter(
          current => current.jobLineItem.referenceId !== referenceId
      );
      this.$emit('itemsUpdated', this.laborItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    async onEarlyInstallerPayment(installation) {
      this.$refs.earlyInstallerPaymentDialog.openDialog(installation);
    },
    async onEarlyPaymentSubmit(payment) {
      let {installation, installationPrice} = payment;
      const payload = {
        amount: installationPrice,
        earlyPayment: true
      };
      try {
        await Installations.getRestApi().createInstallationEarlyPayment(installation.referenceId, payload);
        this.$refs.earlyInstallerPaymentDialog.closeDialog();
        this.requestRefresh();
      } catch (error) {
        this.$refs.earlyInstallerPaymentDialog.closeDialog();
        this.$emit('emitError', error);
      }
    },
    onInstallationCapabilityUpdated() {
      this.markDirty();
    },
    onInstallerUpdated() {
      this.markDirty();
    },
    onInstallationDateUpdated() {
      this.markDirty();
    },
    onInstallationStatusUpdated() {
      this.markDirty();
    },
    onInstallerCrewUpdated() {
      this.markDirty();
    },
    onETCUpdated() {
      this.markDirty();
    },
    onStopUpdated(updated) {
      this.updateStopsInSimilarLabors(updated);
      this.markDirty();
    },
    onCommentUpdated() {
      this.markDirty();
    },
    onQuantityUpdated() {
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    onPriceUpdated() {
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    markDirty() {
      this.$emit('dataUpdated');
    },
    requestRefresh() {
      this.$emit('refresh');
    },
    async getAllAvailableCapabilities() {
      try {
        const response = await InstallationCapabilities.getRestApi().getAllInstallationCapabilities();
        this.availableCapabilities = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    getSimilarItems(updated) {
      let itemsWithSimilarStops = [];
      this.laborItems.forEach(li => {
        if (this.isSimilar(updated, li)) {
          li.installation.stop = updated.updated;
          itemsWithSimilarStops.push(li);
        }
      });
      return itemsWithSimilarStops;
    },
    isSimilar(updated, laborItem) {
      return laborItem.installation != null && updated.item.installation != null
          && updated.item.jobLineItem.id !== laborItem.jobLineItem.id
          && updated.item.installation.installationDate === laborItem.installation.installationDate
          && updated.item.jobLineItem.installerCrewId === laborItem.jobLineItem.installerCrewId;
    },
    updateStopsInSimilarLabors(updated) {
      // sync stops for labor line items with identical 1) installation date 2) installer 3) crew 4) stop
      let itemsWithSimilarStops = this.getSimilarItems(updated);
      this.updateSimilarItems(itemsWithSimilarStops);
    },
    updateSimilarItems(itemsWithSimilarStops) {
      this.$refs.laborLineItemsPanel.forEach((item, index) => {
        let jobLineItemIdFromPanel = item.getJobLineItemId();
        let itemToBeChanged = itemsWithSimilarStops.find(i => i.jobLineItem.id === jobLineItemIdFromPanel);
        if (itemToBeChanged && jobLineItemIdFromPanel === itemToBeChanged.jobLineItem.id) {
          this.$refs.laborLineItemsPanel[index].updateUpdatedItemStop(itemToBeChanged);
        }
      });
    },
    checkIfSomeItemHasTheSameCrewAndDate(laborReferenceId, laborInstallationDate, selectedCrewId, stopToCopy) {
      this.laborItems.forEach(foundItem => {
        if (this.ifLaborItemsHaveItemWithSameCrewAndInstallationDateButDifferentReferenceId(foundItem, laborReferenceId, laborInstallationDate, selectedCrewId)) {
          this.copyStopValueIntoLaborItem(stopToCopy ? stopToCopy : foundItem.jobLineItem.stop, laborReferenceId);
        }
      });
    },
    ifLaborItemsHaveItemWithSameCrewAndInstallationDateButDifferentReferenceId(foundItem, laborReferenceId, laborInstallationDate, selectedCrewId) {
      return foundItem && foundItem.jobLineItem.referenceId !== laborReferenceId
          && DateFormatter.formatDateInYYYYMMDDFormat(foundItem.installation.installationDate) === laborInstallationDate
          && foundItem.jobLineItem.installerCrewId === selectedCrewId;
    },
    copyStopValueIntoLaborItem(stopToCopy, targetLaborReferenceId) {
      let itemToUpdateStop = this.laborItems.find(i => i.jobLineItem.referenceId === targetLaborReferenceId);
      itemToUpdateStop.jobLineItem.stop = stopToCopy;
      this.$refs.laborLineItemsPanel.forEach((item, index) => {
        let targetJobLineItemIdFromPanel = item.getJobLineItemId();
        if (itemToUpdateStop && targetJobLineItemIdFromPanel === itemToUpdateStop.jobLineItem.id) {
          this.$refs.laborLineItemsPanel[index].updateStop(stopToCopy);
        }
      });
    },
    updateLaborInstallerPayableValue(updatedJobLineItemId, installerPayableAmount, increase) {
      const laborItemIndex = this.laborItems.findIndex(lineItem => lineItem.jobLineItem.id === updatedJobLineItemId);
      const laborItem = this.laborItems.find(lineItem => lineItem.jobLineItem.id === updatedJobLineItemId);

      let updatedPrice = this.updateLaborItemPrice(laborItem.jobLineItem.price, installerPayableAmount, increase);
      laborItem.jobLineItem.price = updatedPrice;

      this.$refs.laborLineItemsPanel[laborItemIndex].updateLineItem(laborItem);
      if (laborItemIndex !== -1) {
        this.laborItems[laborItemIndex].jobLineItem.price = Number(updatedPrice);
      }
      this.$emit('itemsUpdated', this.laborItems);
    },
    updateLaborItemPrice(originalPrice, installerPayableAmount, increase) {
      const sanitizedOriginalPrice = originalPrice || 0;
      const sanitizedInstallerPayableAmount = installerPayableAmount || 0;

      return increase
          ? (parseFloat(sanitizedOriginalPrice) + parseFloat(sanitizedInstallerPayableAmount)).toFixed(2)
          : (parseFloat(sanitizedOriginalPrice) - parseFloat(sanitizedInstallerPayableAmount)).toFixed(2);
    },
    reloadSlots() {
      if(this.$refs.laborLineItemsPanel){
        this.$refs.laborLineItemsPanel.forEach((item, index) => {
          this.$refs.laborLineItemsPanel[index].getInstallersSlots();
        });
      }
    }
  }
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
