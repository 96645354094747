<template>
  <div class="line-items-container ma-4">
    <v-row class="item-row"
           v-for="(selectedItem) in selectedItemsFromProductItems"
           :key="selectedItem.ReferenceId">
      <div>
        <return-from-job-submit-form
            ref="returnFromJobForm"
            :item="selectedItem"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import LineItemDescription from "../../jobs/productLineItems/LineItemDescription";
import GeneralPurposeDropdown from "../../common/form/GeneralPurposeDropdown.vue";
import ReturnFromJobSubmitForm from "./ReturnFromJobSubmitForm.vue";
import Constants from "../../../assets/constants";
import Panel from "../refundLineItems/RefundLineItemsPanel.vue";

export default {
  components: {
    Panel,
    LineItemDescription,
    GeneralPurposeDropdown,
    ReturnFromJobSubmitForm
  },

  data() {
    return {
      dropdownOptions: Constants.returnFromJobDropdownOptions,
      inProgress: false,
    };
  },

  props: {
    selectedItemsFromProductItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  methods: {
    validateReturnFromJobForms() {
      const hasError = this.$refs['returnFromJobForm'].some((item => 'error' in item && item.error !== null));
      let passed = this.$refs['returnFromJobForm'].every(ref => ref.validateReturnFromJobForm() === true);
      return passed && !hasError;
    },
    clearRefs() {
      if (this.$refs.length > 0) {
        Object.keys(this.$refs).forEach((refKey, index) => {
          this.$refs[refKey][index].clearReturnFromJobForm();
        });
      }
    },
    getValues() {
      let formRows = [];
      Object.keys(this.$refs).forEach((refKey, index) => {
        let row = this.$refs[refKey][index].getFormData();
        formRows.push(row);
      });
      return formRows;
    }
  }
}
</script>

<style scoped>
.line-items-container {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  color: #666;
}

.item-row {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.item-row > div {
  flex: 1;
  margin-right: 10px;
}

.item-row > div:last-child {
  margin-right: 0;
}
</style>
