<template>
  <div style="padding-left: 10px">
    <v-row>
      <v-col md="6" class="textFields pt-8">
        <v-text-field
            label="Selected From Branch"
            :value="selectedFromBranch.name"
            readonly
            disabled
        />
      </v-col>

      <v-col md="6" class="textFields  pt-8">
        <v-text-field
            label="Selected To Branch"
            :value="selectedToBranch.name"
            readonly
            disabled
        />
      </v-col>

      <v-col md="12" class="ma-1">
        <v-divider/>
        <transfer-request-overview-item-headers/>
        <v-divider/>
      </v-col>

      <v-row class="line-items-wrapper ma-2 mb-6">
        <inventory-items
            class="ma-1"
            v-for="(item, index) in overviewList"
            :key="index"
            :index="index"
            :inventoryItem="item"
            :readonly="true"
            :step="4"
        />
      </v-row>
    </v-row>
  </div>
</template>

<script>
import InventoryItems from "../InventoryItems.vue";
import TransferRequestOverviewItemHeaders from "../headers/TransferRequestOverviewItemHeaders.vue";

export default {
  components: {
    TransferRequestOverviewItemHeaders,
    InventoryItems
  },

  props: {
    overviewList: {
      type: Array | null,
      required: false,
      default: () => []
    },
    selectedToBranch: {
      type: Object | null,
      required: true
    },
    selectedFromBranch: {
      type: Object | null,
      required: true
    }

  },

  watch: {
    overviewList: {
      handler(value) {
      },
    }
  },

  methods: {}
};
</script>
