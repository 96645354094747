<template>
  <drawer-form
      ref="form"
      formId="inventoryDrawerForm"
      :title="title"
      :submitLabel="submitLabel"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-container grid-list-lg>
      <inventory-item-editable-form
          :isRolled="isRolled"
          :hasDyeLot="hasDyeLot"
          ref="inventoryItemDrawerForm"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm.vue";
import InventoryItemEditableForm from "./InventoryItemEditableForm.vue";

export default {
  components: {
    DrawerForm,
    InventoryItemEditableForm
  },

  props: {
    title: String,
    submitLabel: String,
    isRolled: Boolean,
    hasDyeLot: Boolean
  },

  data() {
    return {};
  },

  methods: {
    onCancelClicked() {
      this.$emit('cancelClicked');
    },
    onSubmitClicked() {
      let valid = this.$refs.form.validate();
      if (valid) {
        const payload = this.$refs.inventoryItemDrawerForm.buildPayload();
        this.$emit('submitClicked', payload);
      }
    },
    loadDrawerForm(data) {
      this.$refs.inventoryItemDrawerForm.loadForm(data);
    }
  }
};
</script>
