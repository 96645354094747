<template>
  <drawer-form
      ref="form"
      title="Edit User"
      submitLabel="Save User"
      :inProgress="inProgress"
      :key="formKey"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container>
      <user-edit-form
          ref="userEditForm"
          :isVisible="isVisible"
          :formData="userData"
          :editing="true"
          :changePasswordEnabled="true"
          @resetMfaClicked="resetMfaClicked"
          @mfaEnabledValueChanged="onMfaEnabledValueChanged"
      />

      <generic-warning-dialog
          ref="warningDialog"
          title="Warning"
          :message="warningMessage"
          :showDialog="showResetMfaDialog"
          :multiLineError="true"
          confirmationLabel="Confirm"
          cancelLabel="Cancel"
          @confirmed="onConfirmAction"
          @cancelled="onCancelResettingMfa"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm.vue";
import Users from "@/rest/users";
import UserEditForm from "@/components/users/UserEditForm.vue";
import Roles from "@/assets/roles";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import Constants from "@/assets/constants";
import _ from "lodash";

export default {
  components: {
    GenericWarningDialog,
    InlineGridButton, InlineGridFooter,
    DrawerForm,
    UserEditForm,
    UserMfaControlTools
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      userData: {...this.user},
      formKey: 0,
      inProgress: false,
      warningMessage: null,
      showResetMfaDialog: false
    };
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.userData = _.cloneDeep(newVal);
        this.formKey += 1;
      }
    }
  },

  methods: {
    refresh() {
      this.userData = _.cloneDeep(this.user);
    },
    onCancel() {
      if (Roles.isRoleBranchRole()) {
        this.$refs.form.validate();
        this.preventSavingEmptyBranches();
      }
      this.resetForm();
      this.$emit('cancelClicked');
    },
    async onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.updateUser();
      }
    },
    async updateUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildPayload(true);
        const response = await Users.getRestApi().updateUser(this.user.id, payload);
        const data = response.data;
        if (this.user.email === this.$store.state.loggedInUser.email && this.user.email !== data.email) {
          this.$emit('onEmailUpdateSuccess');
        } else {
          this.$emit('onUpdateSuccess', data);
        }
      } catch (error) {
        this.$emit('onUpdateFail', error);
      }
      this.inProgress = false;
    },
    preventSavingEmptyBranches() {
      const valid = this.userData.branchReferenceIds.length !== 0;
      if (!valid) {
        this.$emit('onUpdateFail', 'Please, specify branch and save user');
        this.errors.has();
      }
    },
    onMfaResetSuccess(updatedUser) {
      this.$emit('resetMfaSuccess', updatedUser);
      this.showResetMfaDialog = false;
    },
    resetMfaClicked() {
      this.warningMessage = Constants.mfaResetDialogMessage;
      this.showResetMfaDialog = true;
    },
    async onConfirmAction() {
      try {
        const response = await Users.getRestApi().resetUserMFA(this.userData.id);
        this.onMfaResetSuccess(response.data);
      } catch (error) {
        this.$emit('onUpdateFail', error);
      }
    },
    onCancelResettingMfa() {
      this.warningMessage = null;
      this.showResetMfaDialog = false;
    },
    onMfaEnabledValueChanged(value) {
      this.userData.mfaEnabled = value;
    },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.refresh();
      this.formKey += 1;
    }
  }
};
</script>
