<template>
  <v-container grid-list-lg text-xs-center>
    <v-row class="d-flex flex-row-reverse pa-2">
      <v-col md="4">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
    </v-row>
    <v-row class="pr-2 pl-2">
      <v-col md="6">
        <branches-search-field
            :value="branchReferenceId"
            :required="true"
            label="* Branch"
            @inputSelected="selectBranch"
            :selectAllEnabled="false"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="binNumber"
            label="* Bin Number"
            placeholder=" "
            :rules="[rules.required]"
            required
            persistent-placeholder
        />
      </v-col>
      <v-col md="6">
        <date-picker-with-type-in-text-field
            v-model="lastCycleCount"
            label="Last Cycle Count Date"
            id="last-cycle-count-date"
            :optional="true"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="cycleCount"
            label="Cycle Count"
            placeholder=" "
            persistent-placeholder
            type="number"
            :rules="[rules.validQuantity]"
        />
      </v-col>
      <v-col md="12">
        <v-textarea
            rows="2"
            :auto-grow="true"
            v-model="description"
            label="Description"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField.vue";
import BranchesSearchField from "@/components/searchFields/BranchesAutocomplete.vue";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import Rules from "@/assets/rules.js";

const formDefaults = {
  active: true,
  binNumber: null,
  description: null,
  cycleCount: 0,
  lastCycleCount: null,
  branchReferenceId: null
};

export default {
  components: {
    DatePickerWithTypeInTextField,
    BranchesSearchField,
    StatusDropdown
  },

  data() {
    return Object.assign(
        {},
        {
          rules: {
            required: Rules.required,
            validQuantity: Rules.validQuantity
          }
        },
        formDefaults
    );
  },

  props: {
    title: String,
    submitLabel: String,
    mode: String
  },

  methods: {
    changeStatus(data) {
      this.active = data;
    },
    selectBranch(value) {
      this.branchReferenceId = value;
    },
    resetToDefaults() {
      this.loadForm(formDefaults);
    },
    onCancel() {
      if (this.$props && this.$props.mode && this.$props.mode === "create") {
        Object.assign(this.$data, formDefaults);
        this.$refs.form.reset();
      }
      this.$emit("cancelClicked");
    },
    buildPayload() {
      const payload = {};
      payload.active = this.active;
      payload.binNumber = this.binNumber;
      payload.description = this.description;
      payload.cycleCount = this.cycleCount;
      payload.lastCycleCount = this.lastCycleCount;
      payload.branchReferenceId = this.branchReferenceId;
      return payload;
    },
    loadForm(payload) {
      this.active = payload.active;
      this.binNumber = payload.binNumber;
      this.description = payload.description;
      this.cycleCount = payload.cycleCount;
      this.lastCycleCount = payload.lastCycleCount;
      this.branchReferenceId = payload.branchReferenceId;
    }
  }
};
</script>
