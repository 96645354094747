<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Activity Logs
          </v-expansion-panel-header>
          <v-expansion-panel-content key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import Users from "@/rest/users.ts";
import ActivityLogsGrid from "@/components/common/inlineGrids/ActivityLogsGrid.vue";
import Constants from "@/assets/constants";

export default {
  components: {
    ActivityLogsGrid
  },

  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      notes: [],
      activityLogs: [],
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    emitError(error) {
      this.$emit("showError", error);
    },
    async getActivityLogs() {
      try {
        const response = await Users.getRestApi().getActivityLogs(this.userId);
        this.activityLogs = response.data;
      } catch (error) {
        this.emitError(error);
      }
    }
  }
};
</script>
