<template>
  <drawer-form
      ref="form"
      title="Edit Sales Representative"
      submitLabel="Save Sales Representative"
      :inProgress="inProgress"
      :key="formKey"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container>
      <sales-representative-edit-form
          ref="salesRepEditForm"
          :salesRepFormData="salesRepData"
          :changePasswordEnabled=true
          :showTierAndGPLEnabled=true
          :isEditForm="true"
          @resetMfaClicked="resetMfaClicked"
          @mfaEnabledValueChanged="mfaEnabledValueChanged"
      >
        <template v-slot:mfa-control-tools>
          <user-mfa-control-tools
              :disabled="false"
              :mfaEnabled="salesRep?.mfaEnabled"
              :mfaSetUp="salesRep?.mfaSetUp"
          />
        </template>
      </sales-representative-edit-form>

      <generic-warning-dialog
          ref="warningDialog"
          title="Warning"
          :message="warningMessage"
          :showDialog="showResetMfaDialog"
          :multiLineError="true"
          confirmationLabel="Confirm"
          cancelLabel="Cancel"
          @confirmed="onConfirmAction"
          @cancelled="onCancelResettingMfa"
      />

    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm.vue";
import SalesRepresentatives from "@/rest/salesRepresentatives";
import SalesRepresentativeEditForm from "@/components/salesRepresentatives/SalesRepresentativeEditForm.vue";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import Users from "@/rest/users";
import Constants from "@/assets/constants";

export default {
  components: {
    GenericWarningDialog,
    UserMfaControlTools,
    DrawerForm,
    SalesRepresentativeEditForm
  },

  props: {
    salesRep: Object,
    salesRepReferenceId: String
  },

  data() {
    return {
      salesRepData: {...this.salesRep},
      formKey: 0,
      inProgress: false,
      warningMessage: null,
      showResetMfaDialog: false
    };
  },

  watch: {
    salesRep(newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      this.salesRepData = {...this.salesRep};
      this.$refs.salesRepEditForm.loadForm(this.salesRepData);
      this.$refs.form.resetValidation();
    },
    onCancel() {
      this.refresh();
      this.$emit('cancelClicked');
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.updateSalesRep();
      }
    },
    async updateSalesRep() {
      try {
        this.inProgress = true;
        const payload = this.$refs.salesRepEditForm.buildPayload();
        await SalesRepresentatives.getRestApi().updateSalesRepresentative(this.salesRepReferenceId, payload);
        this.$emit('onUpdateSuccess');
      } catch (error) {
        this.$emit('onUpdateFail', error);
      }
      this.inProgress = false;
    },
    onMfaResetSuccess(updatedUser) {
      this.$emit('resetMfaSuccess', updatedUser);
    },
    mfaEnabledValueChanged(value) {
      this.salesRepData.mfaEnabled = value;
    },
    resetMfaClicked() {
      this.warningMessage = Constants.mfaResetDialogMessage;
      this.showResetMfaDialog = true;
    },
    async onConfirmAction() {
      try {
        const response = await Users.getRestApi().resetUserMFA(this.salesRepData.id);
        this.onMfaResetSuccess(response.data);
      } catch (error) {
        this.$emit('onUpdateFail', error);
      }
    },
    onCancelResettingMfa() {
      this.warningMessage = null;
      this.showResetMfaDialog = false;
    },
  }
};
</script>
