<template>
  <modal-dialog
      ref="dialog"
      title="Create Ticket"
      width="500"
      :visible="dialogVisible"
      submitLabel="Save Ticket"
      cancelLabel="Cancel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <v-form ref="form" lazy-validation>
      <v-container>
        <ticket-edit-form
            :create="true"
            ref="ticketEditForm"
        />
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import TicketEditForm from "@/components/tickets/TicketEditForm.vue";

export default {
  components: {
    ModalDialog,
    TicketEditForm
  },

  data() {
    return {
      dialogVisible: false,
      newTicketData: null,
      customerName: null
    };
  },

  methods: {
    reset() {
      this.loadFrom(this.newTicketData);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.reset();
    },
    showError(error) {
      this.error = error;
    },
    onCancelClicked() {
      this.closeDialog();
    },
    onSubmitClicked() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.createTicket();
        this.closeDialog();
      }
    },
    setTicketData(data) {
      if (this.dialogVisible) {
        this.newTicketData = Object.assign({}, data);
      }
    },
    createTicket() {
      const payload = this.$refs.ticketEditForm.buildPayload();
      this.$emit("onCreateTicket", payload);
    },
    loadFrom(data) {
      this.setTicketData(data);
      this.$refs.ticketEditForm.loadTicketFrom(Object.assign({}, data));
      this.$refs.form.resetValidation();
    }
  }
};
</script>
