<template>
  <v-container>
    <v-row>
      <v-col cols="4" offset-md="8">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            :rules="[rules.required]"
            label="* First Name"
            v-model="firstName"
            placeholder=" "
            persistent-placeholder
            id="user-edit-form-first-name"
            autocomplete="new-user-first-name"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            :rules="[rules.required]"
            label="* Last Name"
            v-model="lastName"
            placeholder=" "
            persistent-placeholder
            id="user-edit-form-last-name"
            autocomplete="new-user-last-name"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
            :rules="[rules.required, rules.email]"
            label="* Email"
            v-model="email"
            placeholder=" "
            persistent-placeholder
            id="user-edit-form-email"
            autocomplete="new-user-first-email"
        />
      </v-col>
      <v-col cols="6" v-if="editing">
        <v-text-field
            label="Current Role"
            v-model="role.description"
            placeholder=" "
            persistent-placeholder
            id="user-current-role"
            readonly
        />
      </v-col>
      <v-col cols="6" v-if="editing && !isRoleEditDisabled()">
        <v-autocomplete
            item-text="description"
            item-value="id"
            :items="availableRoles"
            v-model="selectedRoleId"
            placeholder=" "
            persistent-placeholder
            label="New Role"
            attach=""
        />
      </v-col>
      <v-col v-if="!editing">
        <user-role-search-field
            ref="userRoleSearchField"
            :selected="selectedRoleId"
            :editing="editing"
            :items="roles"
            @onRoleSelect="onRoleSelect"
        />
      </v-col>

      <v-col cols="6" v-if="isRehashUser">
        <v-text-field
            id="user-edit-form-phone"
            :rules="[rules.required, rules.phoneNumber]"
            v-model="phone"
            label="* Phone"
            placeholder=" "
            v-facade="phoneNumberMask"
            autocomplete="user-edit-phone"
            persistent-placeholder
        />
      </v-col>

      <v-col cols="12" v-if="isBranchUser">
        <branches-search-field
            data-id="user-edit-form-branches"
            label="* Branch"
            :required="true"
            :multiple="true"
            :value="branchReferenceIds"
            :clearable="true"
            :selectAllEnabled="true"
            @inputSelected="onBranchSelect"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
            :rows="2"
            auto-grow
            name="description"
            placeholder=" "
            persistent-placeholder
            label="Description / Comments"
            v-model="description"
            id="user-edit-form-description"
        />
      </v-col>
    </v-row>

    <div v-if="editing">
      <alert-message
          :showAlert="showMfaAlert"
          :value="active"
          :alertMessage="alertMessage"
          @onClearError="clearAlertMessageError"
      />
      <user-mfa-control-tools
          :disabled="disableMfaSwitch"
          :mfaEnabled="mfaEnabled"
          :mfaSetUp="formData.mfaSetUp"
          @resetMfaClicked="resetMfaClicked"
          @mfaEnabledValueChanged="mfaEnabledValueChanged"
      />
    </div>
    <v-row v-else class="ma-0 pa-0">
      <slot name="mfa-control-tools"/>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col cols="8" class="d-flex justify-start ma-0 pa-0">
        <password-helper-container
            ref="passwordHelper"
            :changePasswordEnabled="changePasswordEnabled"
            :initialPasswordEnabled="initialPasswordEnabled"
            :email="email"
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Constants from "../../assets/constants.js";
import Roles from "../../assets/roles.js";
import Rules from "../../assets/rules";
import PhoneMask from "../../assets/phoneMask";
import BranchesSearchField from "../../components/searchFields/BranchesAutocomplete.vue";
import UserRoleSearchField from "../../components/users/UserRoleSearchField.vue";
import PasswordHelperContainer from "../../components/passwordManagement/PasswordHelperContainer.vue";
import StatusDropdown from "../../components/common/form/StatusDropdown.vue";
import RolesApi from "../../rest/roles";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";
import AlertMessage from "@/components/common/AlertMessage.vue";

const formDefaults = {
  id: null,
  firstName: null,
  lastName: null,
  fullName: null,
  email: null,
  description: null,
  phone: null,
  roleId: Constants.userRoles.branchUser,
  defaultRoleId: Constants.userRoles.branchUser,
  role: Constants.roles[0],
  selectedRoleId: null,
  isBranchUser: true,
  isRehashUser: false,
  active: true,
  branchReferenceIds: [],
  mfaEnabled: false,
  showMfaAlert: false,
  alertMessage: ''
};

export default {
  components: {
    AlertMessage,
    BranchesSearchField,
    UserRoleSearchField,
    PasswordHelperContainer,
    StatusDropdown,
    UserMfaControlTools
  },

  props: {
    changePasswordEnabled: Boolean,
    initialPasswordEnabled: Boolean,
    editing: Boolean,
    isVisible: {
      type: Boolean,
      required: true
    },
    formData: {
      type: [Object, null]
    }
  },

  data() {
    return Object.assign(
        {
          rules: {
            required: Rules.required,
            email: Rules.email,
            password: Rules.password,
            phoneNumber: Rules.phoneNumber(),
          },
          changePasswordModal: false,
          statusChoices: [
            {text: "Active", value: true},
            {text: "Inactive", value: false}
          ],
          phoneNumberMask: Constants.phoneNumberMask,
          roles: []
        },
        formDefaults
    );
  },

  computed: {
    availableRoles() {
      return (this.role === undefined || this.role === null) ? [] : this.roles.filter(item => this.role.id !== item.id
          && !item.isInternal);
    },
    disableMfaSwitch() {
      return !this.active;
    }
  },

  watch: {
    selectedRoleId(newVal) {
      this.isBranchUser = Roles.isRoleBranchRole(newVal);
      this.isRehashUser = Roles.isRoleRehashRep(newVal);
      this.defaultRoleId = null;
    },
    async isVisible(value) {
      if (value && this.roles.length === 0) {
        RolesApi.getRestApi().getRoles().then((response) => {
          this.roles = response.data.filter(role => role.id !== Constants.userRoles.salesRep && role.id !== Constants.userRoles.installerCrew);
        });
      }
    },
    formData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.loadForm(val);
        }
      }
    },
  },

  methods: {
    changeStatus(status) {
      if (this.active !== status) {
        this.active = status;
        this.mfaEnabled = !!status;
        this.showAlertNotification(status);
      }
    },
    onBranchSelect(value) {
      this.branchReferenceIds = value;
    },
    getSelectedBranches() {
      return this.isBranchUser ? this.branchReferenceIds : [];
    },
    onRoleSelect(value) {
      this.selectedRoleId = value;
    },
    resetDefaultRole() {
      this.$refs.userRoleSearchField.setRole();
    },
    resetPassword() {
      this.$refs.passwordHelper.resetPasswordToDefault();
    },
    buildPayload(edit) {
      let payload = {};
      payload.firstName = this.firstName;
      payload.lastName = this.lastName;
      payload.email = this.email;
      payload.description = this.description;
      payload.active = this.active;
      payload.sendTemporaryPassword = this.$refs.passwordHelper.sendTemporaryPassword;
      payload.newPassword = this.$refs.passwordHelper.newPassword;
      payload.branchReferenceIds = this.getSelectedBranches();
      if (edit) {
        payload.roleId = this.selectedRoleId;
      } else payload.roleId = this.selectedRoleId != null ? this.selectedRoleId : this.defaultRoleId;
      payload.phone = PhoneMask.unMaskPhone(this.phone);
      payload.mfaEnabled = this.mfaEnabled;
      return payload;
    },
    buildAdminUserPayload() {
      return this.buildPayload();
    },
    buildSuperAdminUserPayload() {
      return this.buildPayload();
    },
    buildBranchUserPayload() {
      return this.buildPayload();
    },
    userRole() {
      return this.selectedRoleId || this.defaultRoleId;
    },
    loadForm(payload) {
      if (!payload) {
        payload = formDefaults;
      }
      this.id = payload.id;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.email = payload.email;
      this.description = payload.description;
      this.role = payload.role;
      this.roleId = payload.roleId;
      this.isBranchUser = Roles.isRoleBranchRole(payload.roleId);
      this.isRehashUser = Roles.isRoleRehashRep(payload.roleId);
      this.active = payload.active;
      this.branchReferenceIds = payload.branchReferenceIds;
      this.phone = payload.phone;
      this.mfaEnabled = payload.mfaEnabled;
    },
    isRoleEditDisabled() {
      let selectedRole = this.roles.find(role => role.id === this.roleId);
      return selectedRole && selectedRole.isInternal;
    },
    mfaEnabledValueChanged(value) {
      this.$emit('mfaEnabledValueChanged', value);
      this.clearAlertMessageError();
    },
    resetMfaClicked() {
      this.$emit('resetMfaClicked');
    },
    showAlertNotification(status) {
      this.showMfaAlert = true;
      this.alertMessage = status ? Constants.mfaEnabledMessage : Constants.mfaDisabledMessage;
    },
    clearAlertMessageError() {
      this.showMfaAlert = false;
    }
  }
};
</script>
