import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/browser';
import * as Version from './rest/version';
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
declare const config: any; //So TS compiler won't complain about this JS defined var used below

Sentry.init({
  debug: true,
  dsn: config.VUE_APP_SENTRY_DSN || process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.Integrations.Vue({
      Vue,
      attachProps: true
    })
  ],
  beforeSend(event) {
    /* eslint-disable */
    let sendEvent = true;
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case "prod":
        // no console log wanted and send event is default true
        break;
      case "qa":
      case "dev":
        console.log(event);
        break;
      case "local":
        sendEvent = false;
        break;
      default:
        console.log(event);
        break;
    }
    if (sendEvent) {
      event.tags = {
        "fe.version": Version.getFrontendVersion(),
        "user.email": store.state.loggedInUser ? store.state.loggedInUser.email : "not-logged-in-user"
      };
      return event;
    }
    return null;
    /* eslint-enable */
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
