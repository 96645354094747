import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import InputFacade from 'vue-input-facade'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);
Vue.use(InputFacade);

const opts = {
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#69b',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#b71c1c',
          info: '#2196F3',
        }
      }
    }
  }),
}

export default new Vuetify(opts)
