<template>
  <v-container>
<!--    <v-form ref="branchFormBody" validate-on="submit">-->
    <v-row>
      <v-col md="4">
        <v-text-field
            v-model="referenceId"
            :rules="[rules.required, rules.isReferenceId]"
            label="* Reference ID"
            placeholder=" "
            id="branch-update-form-display-ref-id"
            hint="EX: B01-B20"
            persistent-hint
            maxlength="3"
            persistent-placeholder
        />
      </v-col>
      <v-col/>
      <v-col md="4">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" class="mt-1">
        <v-text-field
            v-model="name"
            :rules="[rules.required, rules.max50]"
            maxlength="50"
            counter
            label="* Name"
            placeholder=" "
            id="branch-update-form-display-name"
            persistent-placeholder
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="12" v-if="!isCreateForm">
        <market-search-field
            :getItemsOnComponentCreation="true"
            :required="false"
            :marketIds="marketIds"
            :selectAllEnabled="true"
            @input="onMarketSelect"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="12">
        <h4 style="text-align: left">Branch Office Address</h4>
        <br/>
        <address-form-with-autocomplete ref="addressForm"/>
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="12">
        <h4 style="text-align: left">Shipping Address</h4>
        <v-checkbox
            v-model="sameAsBOAddress"
            color="primary"
            label="Same as Branch Office Address"
            @change="onSameAsBOAddress"
        />
        <address-form-with-autocomplete
            ref="shipmentAddressForm"
            @someAddressChanged="onAddressChange"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="6">
        <v-text-field
            v-model="phone"
            label="* Phone Number"
            v-facade="phoneNumberMask"
            :rules="[rules.phoneNumber, rules.required]"
            id="branch-update-form-phone"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            v-model="fax"
            label="Fax Number"
            v-facade="phoneNumberMask"
            :rules="[rules.phoneNumber]"
            id="branch-update-form-fax"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col md="12" class="mt-0 pt-0">
        <v-text-field
            v-model="email"
            label="* Email"
            :rules="[rules.email, rules.required]"
            validate-on-blur
            id="branch-update-form-email"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col md="12" v-if="isCreateForm">
        <branches-autocomplete
            v-model="catalogItemsBranchIds"
            label="Import Catalog items from Branch"
            :multiple="true"
            :get-id="true"
            :clearable="true"
            :selectAllEnabled="true"
            :isBranchesMappedById="true"
            @inputSelected="onSelectBranch"
        />
      </v-col>
    </v-row>

    <v-row class="mt-1" v-if="isSuperAdmin">
      <v-col md="12">
        <h4 style="text-align: left">Super Admin configuration</h4>
        <v-checkbox
            v-model="isAutomaticLeadAssignmentEnabled"
            color="primary"
            label="Automatic Lead Assignment"
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import AddressFormWithAutocomplete from "../common/form/AddressFormWithAutocomplete.vue";
import MarketSearchField from "../searchFields/MarketsAutocomplete.vue";
import BranchesAutocomplete from "../searchFields/BranchesAutocomplete.vue";
import StatusDropdown from "../common/form/StatusDropdown.vue";
import Rules from "../../assets/rules";
import Constants from "../../assets/constants";
import PhoneMask from "../../assets/phoneMask";
import Roles from "../../assets/roles";

const formDefaults = {
  name: null,
  active: true,
  phone: null,
  fax: null,
  email: null,
  markets: null,
  marketIds: [],
  referenceId: null,
  isAutomaticLeadAssignmentEnabled: false,
  catalogItemsBranchIds: [],
  sameAsBOAddress: false,
};

export default {
  components: {
    MarketSearchField,
    StatusDropdown,
    AddressFormWithAutocomplete,
    BranchesAutocomplete
  },

  props: {
    isCreateForm: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  created() {
  },

  computed: {
    Constants() {
      return Constants
    },
    isDisplayed() {
      return Roles.isSuperAdminUser() || (Roles.isAnyAdminUser() && this.isCreateForm);
    },
    isSuperAdmin() {
      return Roles.isSuperAdminUser();
    }
  },

  data() {
    return Object.assign(
        {
          rules: {
            required: Rules.required,
            isReferenceId: Rules.isBranchReferenceId,
            phoneNumber: Rules.phoneNumberWithDelimiter,
            email: Rules.email,
            max50: value => Rules.maxValue(value, 50)
          },
          phoneNumberMask: Constants.phoneNumberMask
        },
        formDefaults
    );
  },

  methods: {
    isAddressEquals(addressData, shipmentAddressData) {
      return addressData.address === shipmentAddressData.address && addressData.city === shipmentAddressData.city &&
          addressData.state === shipmentAddressData.state && addressData.zipCode === shipmentAddressData.zipCode
    },
    onAddressChange() {
      const addressData = this.$refs.addressForm.getAddressData();
      const shipmentAddressData = this.$refs.shipmentAddressForm.getAddressData();

      this.sameAsBOAddress = this.isAddressEquals(addressData, shipmentAddressData);
    },
    changeStatus(data) {
      this.active = data;
    },
    onMarketSelect(value) {
      this.marketIds = value;
    },
    resetFormDefaults(payload) {
      this.$refs.addressForm.clearAutocomplete();
      this.$refs.shipmentAddressForm.clearAutocomplete();
      this.loadForm(payload);
    },
    buildPayload() {
      const payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      const shipmentAddressData = this.$refs.shipmentAddressForm.getAddressData();
      payload.name = this.name;
      payload.fax = this.fax;
      payload.email = this.email;
      payload.phone = PhoneMask.unMaskPhone(this.phone);
      payload.active = this.active;
      payload.selectedMarketIds = this.marketIds;
      payload.referenceId = this.referenceId;
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      payload.shipmentAddress = shipmentAddressData.address;
      payload.shipmentCity = shipmentAddressData.city;
      payload.shipmentState = shipmentAddressData.state;
      payload.shipmentZipCode = shipmentAddressData.zipCode;
      payload.isAutomaticLeadAssignmentEnabled = this.isAutomaticLeadAssignmentEnabled;

      let catalogItemsBranchIds = this.catalogItemsBranchIds;
      if (catalogItemsBranchIds != null) {
        payload.catalogItemsBranchIds = catalogItemsBranchIds;
      }
      return payload;
    },
    loadForm(payload) {
      if (!payload) {
        payload = Object.assign(formDefaults);
      }
      this.referenceId = payload.referenceId;
      this.name = payload.name;
      this.phone = payload.phone;
      this.active = payload.active;
      this.fax = payload.fax;
      this.email = payload.email;
      this.marketIds = payload.marketIds;
      this.isAutomaticLeadAssignmentEnabled = payload.isAutomaticLeadAssignmentEnabled;
      this.$refs.addressForm.setAddressData(payload);
      this.$refs.shipmentAddressForm.setShipmentAddressData(payload);

      const addressData = this.$refs.addressForm.getAddressData();
      const shipmentAddressData = this.$refs.shipmentAddressForm.getAddressData();

      if (this.isAddressEquals(addressData, shipmentAddressData)) {
        this.sameAsBOAddress = true;
      }
    },
    onSelectBranch(value) {
      this.catalogItemsBranchIds = value;
    },
    onSameAsBOAddress() {
      if (this.sameAsBOAddress) {
        const addressData = this.$refs.addressForm.getAddressData();
        let payload = {
          address: addressData.address,
          city: addressData.city,
          state: addressData.state,
          zipCode: addressData.zipCode
        };
        this.$refs.shipmentAddressForm.setAddressData(payload);
      } else {
        this.$refs.shipmentAddressForm.setAddressData({});
      }
    }
  }
};
</script>
