<template>
  <v-autocomplete
      hide-no-data
      hide-selected
      v-model="selected"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      item-text="referenceId"
      item-value="referenceId"
      @keyup.enter.native="handleEnterKeyPress"
      hide-details
      label="Search Job Reference Id"
      :placeholder="'Start typing to search'"
      persistent-placeholder
      :rules="[rules.required]"
      maxlength="11"
      clearable
      attach
  />
</template>

<script>
import Jobs from "@/rest/jobs";

export default {
  components: {},

  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selected: null,
      minCharactersEntered: 2,
      rules: {
        required: value => !!value || "Required",
      }
    };
  },

  mounted() {
    this.autofillJobReferenceIdFromQueryParam();
  },

  watch: {
    search(value) {
      value && value !== this.selected && this.searchJobRefId(value.toUpperCase());
    },
    selected(val) {
      this.$emit("input", val);
    }
  },

  methods: {
    handleEnterKeyPress(data) {
      this.$emit("enterKeyPress", data);
    },
    async searchJobRefId(newVal) {
      if (!newVal || newVal.length <= this.minCharactersEntered) return;
      try {
        const response = await Jobs.getRestApi().getSearchedJobAsViewForAutocomplete(newVal);
        this.items = response.data;
      } catch (error) {
        throw error;
      }
    },
    autofillJobReferenceIdFromQueryParam() {
      if (this.$route.query.referenceId) {
        this.search = this.$route.query.referenceId;
        this.selected = this.$route.query.referenceId;
        this.searchJobRefId(this.selected);
        this.$emit("input", this.selected);
      }
    }
  },
};
</script>
