<template>
  <landing-page>
    <v-divider class="mt-5 mb-5"/>
    <v-card flat class="mt-5">
      <v-card-text class="title">
        A newer version of Oasis is available!
      </v-card-text>
    </v-card>
    <v-card flat class="mt-2">
      <v-card-text>
        <v-btn
            large
            color="primary"
            dark
            @click="onForceReload"
        >
          Upgrade
          <v-icon class="white--text ml-2">
            cloud_download
          </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </landing-page>
</template>

<script>
import LandingPage from "../LandingPage.vue";

export default {
  components: {
    LandingPage
  },

  data: () => ({}),

  methods: {
    onForceReload() {
      this.$router.push("/");
      this.$store.commit("logout");
      window.location.reload(true);
    }
  }
};
</script>

<style scoped></style>
