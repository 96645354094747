<template>
  <grid-view
      ref="nav"
      nav-section="customer"
      page-title="List Tickets"
      :data-source="dataSource"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
      :branchUserRestricted="true"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        data-type="string"
    />
    <dx-column
        data-field="CreatedAt"
        caption="Created Date"
        width="10%"
        data-type="date"
        format="MM/dd/yyyy"
        sort-order="desc"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Ticket"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="BranchName"
        caption="Branch Name"
        :allowHeaderFiltering="allowBranchFilter"
        :header-filter="{ dataSource: branchesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="AssigneeName"
        caption="Assigned To"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CustomerName"
        caption="Associated Customer"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LeadReferenceId"
        caption="Associated Lead"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="JobReferenceId"
        caption="Associated Job"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Description"
        caption="Description"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="UserName"
        caption="Creator"
        :allowHeaderFiltering="false"
        data-type="string"
    />
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import CustomizeFilter from "../../components/common/grid/CustomizeFilter";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    GridView,
    DxColumn
  },
  data() {
    return {
      allowBranchFilter: false,
      branchesFilter: [],
      dataSource: {
        store: ODataContext['Tickets'],
        select: [
          "ReferenceId",
          "UserName",
          "AssigneeName",
          "Description",
          "Status",
          "CustomerName",
          "LeadReferenceId",
          "JobReferenceId",
          "BranchName",
          "BranchReferenceId", // for branch user role restriction
          "CreatedAt"
        ],
        sort: [{selector: "CreatedAt", desc: true}]
      }
    };
  },

  created() {
    this.getFilters();
  },

  methods: {
    async getFilters() {
      try {
        const user = this.$store.state.loggedInUser;
        const branchesFilter = await CustomizeFilter.getBranchFiltersIfNotBranchUser(user);
        this.setFilters(branchesFilter);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setFilters(branchesFilter) {
      if (branchesFilter) {
        this.branchesFilter = branchesFilter;
        this.allowBranchFilter = true;
      }
    },
    onRowSelected(data) {
      this.$router.push(`/tickets/details/${data.ReferenceId}`);
    }
  }
};
</script>
