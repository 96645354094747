<template>
  <modal-dialog
      ref="dialog"
      title="Customer Bad Debt"
      width="400"
      :visible="dialogVisible"
      submitLabel="Write Off"
      cancelLabel="Cancel"
      @submitClicked="onSaveButtonClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <v-form ref="form">
      <v-container>
        <p class="headline">
          Are you sure you want to write off ${{ customerRemainingTotal }}?
        </p>
        <p class="caption">
          This customer's debt will be written off and cannot be undone in Oasis.
        </p>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

const formDefaults = {
  dialogVisible: false
};

export default {
  components: {ModalDialog},

  props: {
    customerRemainingTotal: Object
  },

  data() {
    return {
      dialogVisible: false
    };
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onSaveButtonClicked() {
      // to do: dialog will be closed after submit success. Closing now for UI flow only
      this.closeDialog();
      this.$emit("saveButtonClicked");
    },
    onCancelClicked() {
      this.dialogVisible = false;
    }
  }
};
</script>
