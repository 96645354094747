<template>
  <v-dialog v-model="wizardVisible" persistent :width="800">
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>{{ title }}</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <stepper-headers
          :step="step"
          step-one-name="Password"
          step-two-name=" Reason Code"
          :maxStepNumber=2
          :editable="false"
      />

      <v-stepper-items>
        <v-stepper-content step="1">
          <validate-special-user-password-form
              ref="passwordForm"
              @passwordEmpty="passwordChangeHandler"
          />
          <span class="submitError" v-show="passwordError"> {{ passwordError }}</span>
          <button-container
              backName=Cancel
              nextName=Next
              :disableNextStep="isPasswordFieldEmpty"
              @getPreviousStep="resetWizard"
              @getNextStep="goToStepTwo"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <close-transfer-request-reason-code-form
              ref="reasonForm"
              :isCloseTransferRequestForm="!(selectedTransferRequestItem)"
              @select="reasonCodeSelected"
              @changeSubmitState="onChangeSubmitState"
          />

          <button-container-with-cancel
              backName=Back
              nextName=Confirm
              cancelName=Cancel
              :nextLoading="confirmLoading"
              :disableNextStep="submitDisabled"
              @cancel="resetWizard"
              @getPreviousStep="goToStep(1)"
              @getNextStep="submitCloseTransferRequest"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "../../common/wizard/ButtonContainer.vue";
import CloseTransferRequestReasonCodeForm from "./CloseTransferRequestReasonCodeForm.vue";
import ButtonContainerWithCancel from "../../common/wizard/ButtonContainerWithCancel";
import TransferRequest from "../../../rest/transferRequests";
import ValidateSpecialUserPasswordForm from "../../common/form/ValidateSpecialUserPasswordForm.vue";
import StepperHeaders from "../../common/stepper/headers/ConfigurableStepperHeaders.vue";

export default {
  components: {
    StepperHeaders,
    ValidateSpecialUserPasswordForm,
    ButtonContainerWithCancel,
    CloseTransferRequestReasonCodeForm,
    ButtonContainer
  },

  props: {
    transferRequest: Object,
  },

  data() {
    return {
      wizardVisible: false,
      step: 1,
      reasonCodeId: null,
      selectedTransferRequestItem: null,
      title: 'Close Transfer Request',
      confirmLoading: false,
      passwordError: null,
      submitDisabled: true,
      isPasswordFieldEmpty: true
    };
  },

  methods: {
    async submitCloseTransferRequest() {
      this.confirmLoading = true;
      const payload = {
        reasonCodeId: this.reasonCodeId,
        comment: this.$refs.reasonForm.getComment()
      }
      this.selectedTransferRequestItem
          ? await this.closeTransferRequestItemRequest(this.selectedTransferRequestItem.id, payload)
          : await this.closeWholeTransferRequest(this.transferRequest.transferNumber, payload);
      this.loadPasswordFormDefaults();
    },
    async closeTransferRequestItemRequest(selectedTRItemId, payload) {
      try {
        await TransferRequest.getRestApi().closeTransferRequestItem(selectedTRItemId, payload);
        this.$emit('submitCloseTransferRequestSuccess', true);
      } catch (error) {
        this.$emit('submitCloseTransferRequestFailed', error);
      } finally {
        this.confirmLoading = false;
      }
    },
    async closeWholeTransferRequest(transferRequestNumber, payload) {
      try {
        await TransferRequest.getRestApi().closeTransferRequest(transferRequestNumber, payload);
        this.confirmLoading = false;
        this.$emit('submitCloseTransferRequestSuccess', false);
      } catch (error) {
        this.$emit('submitCloseTransferRequestFailed', error);
      } finally {
        this.confirmLoading = false;
      }
    },
    openCloseTransferRequestDialog(selectedItem) {
      this.resetWizard();
      this.wizardVisible = true;
      if (selectedItem) {
        this.selectedTransferRequestItem = selectedItem;
        this.title = "Close Transfer Request Line Item";
      }
    },
    closeDialog() {
      this.wizardVisible = false;
      this.title = "Close Transfer Request";
    },
    goToStep(step) {
      this.step = step;
    },
    async goToStepTwo() {
      let valid = await this.$refs.passwordForm.validatePasswordForm();
      if (valid) {
        this.passwordError = null;
        this.goToStep(2);
      } else {
        this.passwordError = 'Password is empty or does not match';
      }
    },
    passwordChangeHandler(value) {
      this.isPasswordFieldEmpty = value;
    },
    async resetWizard() {
      this.step = 1;
      this.reasonCodeId = null;
      this.selectedTransferRequestItem = null;
      this.submitDisabled = true;
      this.isPasswordFieldEmpty = true;
      this.loadPasswordFormDefaults();
      this.closeDialog();
      this.resetAllForms();
    },
    resetAllForms() {
      this.$nextTick(() => {
        this.$refs.reasonForm.loadDefaults();
      })
    },
    reasonCodeSelected(reasonCodeId) {
      this.reasonCodeId = reasonCodeId;
    },
    onChangeSubmitState(newButtonState) {
      this.submitDisabled = newButtonState;
    },
    loadPasswordFormDefaults() {
      if (this.$refs.passwordForm) {
        this.$refs.passwordForm.loadDefaults();
      }
    }
  }
};
</script>
<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
