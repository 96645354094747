<template>
  <navigation-view-sales ref="nav" page-title="Schedule" nav-section="schedule">
    <template v-slot:content>
      <v-card class="ma-3" v-if="salesRep">
        <schedule
            ref="salesRepSchedule"
            :sales-rep-reference-id="salesRep.referenceId"
            :selectable="true"
            :sales-rep-urls="true"
        />
      </v-card>
      <sales-representative-appointment-dialog
          ref="appointmentDialog"
          :visible="appointmentDialog"
          @cancelClicked="toggleAppointmentDialog"
      />
    </template>
  </navigation-view-sales>
</template>

<script>
import SalesRepresentative from "@/rest/salesRepresentatives";
import NavigationViewSales from "@/views/navigation/NavigationViewSales.vue";
import Schedule from "@/components/salesRepresentatives/inlineContainers/SalesRepSchedule.vue";
import SalesRepresentativeAppointmentDialog from "@/components/salesRepresentatives/SalesRepresentativeAppointmentDialog.vue";

export default {
  components: {
    NavigationViewSales,
    Schedule,
    SalesRepresentativeAppointmentDialog
  },

  data() {
    return {
      salesRep: {},
      salesRepId: null,
      referenceId: null,
      appointmentDialog: null
    };
  },

  created() {
    this.display(this.$store.state.loggedInUser.id);
  },

  methods: {
    display(salesRepId) {
      this.$data.salesRepId = salesRepId;
      this.getSalesRepByUserId();
    },
    async getSalesRepByUserId() {
      try {
        const response = await SalesRepresentative.getRestApi().getSalesRepresentativeByUserId(this.salesRepId);
        this.setSalesRep(response.data);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setSalesRep(data) {
      this.salesRep = data;
      this.referenceId = data.referenceId;
    }
  }
};
</script>
