<template>
  <grid-view
      ref="nav"
      page-title="List Inventory Items"
      nav-section="warehouse"
      :data-source="dataSource"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
      :branchUserRestricted="false"
  >
    <dx-column
        data-field="BranchName"
        :fixed="true"
        caption="Branch"
        :allowHeaderFiltering="allowBranchFilter"
        :header-filter="{ dataSource: branchesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="InternalSku"
        :fixed="true"
        caption="Internal SKU"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="VendorName"
        caption="Vendor"
        :fixed="true"
        :header-filter="{ dataSource: vendorsFilter }"
        data-type="string"
    />
    <dx-column
        data-field="ProductType"
        caption="Product Type"
        :header-filter="{ dataSource: productTypesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="ProductStyle"
        caption="Product Style"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ProductColor"
        caption="Color"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="VendorSku"
        caption="Vendor SKU"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="RollNumber"
        caption="Roll Number"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="DyeLot"
        caption="Dye Lot"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="UnitOfMeasure"
        caption="Unit of Measure"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="BinNumber"
        caption="Bin Number"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="OnHand"
        caption="On Hand"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Available"
        caption="Available"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="BoxQuantity"
        caption="Box Quantity"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="SupplierCost"
        caption="Vendor Cost"
        :format="format"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ItemValue"
        caption="Total Value"
        :format="format"
        :allowHeaderFiltering="false"
    />
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter.js";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    GridView,
    DxColumn,
  },
  data() {
    const config = {
      allowBranchFilter: false,
      branchesFilter: [],
      productTypesFilter: [],
      vendorsFilter: [],
      format: {
        type: "currency",
        precision: 2,
      },
      dataSource: {
        store: ODataContext['InventoryItems'],
        select: [
          "Id",
          "VendorSku",
          "VendorName",
          "InternalSku",
          "ProductType",
          "ProductStyle",
          "ProductColor",
          "OnHand",
          "Available",
          "BranchName",
          "RollNumber",
          "DyeLot",
          "UnitOfMeasure",
          "BinNumber",
          "BoxQuantity",
          "BranchReferenceId",
          "SupplierCost",
          "ItemValue",
        ],
      },
    };
    return config;
  },
  created() {
    this.getFilters();
  },
  methods: {
    async getFilters() {
      let component = this;
      try {
        const branchesFilter = await CustomizeFilter.getAllActiveBranchesFilter();
        const vendorFilter = await CustomizeFilter.getVendorsFilter();
        this.setVendorFilters(vendorFilter);
        this.setBranchFilters(branchesFilter);
        this.productTypesFilter = await CustomizeFilter.getProductTypeFilters();
      } catch (error) {
        component.$refs.nav.showError(error);
      }
    },
    setBranchFilters(branchesFilter) {
      if (branchesFilter) {
        this.branchesFilter = branchesFilter;
        this.allowBranchFilter = true;
      }
    },
    setVendorFilters(vendorFilter) {
      if (vendorFilter) {
        this.vendorsFilter = vendorFilter;
      }
    },
    onRowSelected(data) {
      this.$router.push(`/inventory/details/${data.Id}`);
    },
  },
};
</script>
