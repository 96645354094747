<template>
  <div>
    <inline-grid
        ref="daysOffGrid"
        :data-source="daysOff"
        :pageSize="5"
        :showPager="true"
        :show-borders="false"
        :hoverStateEnabled="true"
        :editingAllowDeleting="true"
        :editingAllowPerRow="(row) => isDayOffDeletable(row)"
        @rowSelected="onRowSelected"
        @deleteClicked="onDeleteClicked"
    >
      <dx-column data-field="installerCrewName" width="20%" caption="Crew"/>
      <dx-column data-field="startDate" width="20%" data-type="date" caption="Start Date" sort-order="desc"/>
      <dx-column data-field="endDate" width="20%" data-type="date" caption="End Date"/>
      <dx-column data-field="comment" caption="Comments"/>
      <template v-slot:footer>
        <inline-grid-footer>
          <inline-grid-button label="Add Days Off" @click="onAddDaysOffClick"/>
        </inline-grid-footer>
      </template>
    </inline-grid>
    <remove-day-off-dialog
        ref="removeDayOffDialog"
        @deleteConfirmed="getDaysOff"
        @emitError="showError"
    />
    <add-day-off-dialog
        ref="addDayOffDialog"
        :crews="activeCrews"
        @saveDayOffButtonClicked="onDayOffSubmit"
    />
    <update-day-off-dialog
        ref="updateDayOffDialog"
        :crews="crews"
        @updateDayOffButtonClicked="onDayOffSubmit"
        @cancelClicked="onUpdateCancel"
    />
    <error-modal
        ref="errorModal"
        :error="error"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </div>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";

import UpdateDayOffDialog from "./UpdateDayOffDialog.vue";
import AddDayOffDialog from "./AddDayOffDialog.vue";
import InstallerCrewDayOff from "@/rest/installer/installerCrewDayOff";
import RemoveTimeInSecondaryBranchDialog
  from "@/views/installers/crews/secondaryBranchTime/RemoveTimeInSecondaryBranchDialog.vue";
import RemoveDayOffDialog from "@/views/installers/crews/dayoff/RemoveDayOffDialog.vue";
import ErrorModal from "@/components/navigation/ErrorModal.vue";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    ErrorModal,
    RemoveDayOffDialog,
    RemoveTimeInSecondaryBranchDialog,
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    AddDayOffDialog,
    UpdateDayOffDialog
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    installerRefId: String
  },

  created() {
    this.getDaysOff();
  },

  data() {
    return {
      daysOff: [],
      isErrorModalOpen: false,
      error: null
    };
  },

  computed: {
    activeCrews() {
      return this.crews.filter(crew => crew.active);
    }
  },

  methods: {
    onRowSelected(data) {
      if (data) {
        this.$refs.updateDayOffDialog.loadForm(data);
        this.$refs.updateDayOffDialog.openDialog();
      }
    },
    async onDeleteClicked(row) {
      this.$refs.removeDayOffDialog.openDialog(row);
    },
    onAddDaysOffClick() {
      this.$refs.addDayOffDialog.openDialog();
    },
    onDayOffSubmit() {
      this.getDaysOff();
      this.$refs.daysOffGrid.clearSelection();
    },
    getDaysOff() {
      InstallerCrewDayOff.getRestApi().getCrewsDaysOff(this.installerRefId)
          .then(response => {
            this.daysOff = response.data
          })
    },
    showError(error) {
      this.isErrorModalOpen = true;
      this.error = error;
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    isDayOffDeletable(row) {
      return !DateUtils.isPastDate(row.endDate);
    },
    onUpdateCancel() {
      this.$refs.daysOffGrid.clearSelection();
    }
  }
};
</script>
