<template>
  <modal-dialog
      ref="dialog"
      title="Warning"
      width="550"
      :visible="dialogVisible"
      :submitEnabled="true"
      :submitLabel="submitLabel"
      :cancelLabel="cancelLabel"
      :hideCancel="hideCancel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <div v-if="multiLineError">
          <div class="ma-1" v-for="line in errorLines" :key="line">
            {{ line }}
          </div>
        </div>
        <div v-else>
          <div>{{ message }}</div>
        </div>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../common/ModalDialog.vue";

export default {
  data: () => ({
    dialogVisible: false,
    errorLines: []
  }),

  components: {
    ModalDialog
  },

  props: {
    message: String,
    submitLabel: {
      type: String,
      default: 'Continue'
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    multiLineError: Boolean
  },

  watch: {
    message: {
      handler(val) {
        this.splitToLines(val);
      },
      immediate: true
    }
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.message = error;
    },
    onSubmitClicked() {
      this.dialogVisible = false;
      this.$emit('accept');
    },
    onCancelClicked() {
      this.dialogVisible = false;
      this.$emit('cancel');
    },
    splitToLines(value) {
      if (value) {
        this.errorLines = this.message.split('\n');
      }
    }
  }
};
</script>
