export class AuthHelper {
    static updateSessionStorage(user, accessToken, refreshToken) {
        const userKey = `user_${user.email}`;
        sessionStorage.setItem(`${userKey}_accessToken`, accessToken);
        sessionStorage.setItem(`${userKey}_refreshToken`, refreshToken);
    }

    static clearSessionStorage(user) {
        const userKey = `user_${user.email}`;
        sessionStorage.removeItem(`${userKey}_accessToken`);
        sessionStorage.removeItem(`${userKey}_refreshToken`);
    }

    static clearCookies() {
        document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    static isSafariBrowser() {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.includes('safari') && !userAgent.includes('chrome');
    }

}
