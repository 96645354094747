<template>
  <navigation-view-sales
      ref="nav"
      page-title="Commissions"
      nav-section="commissions"
  >
    <template v-slot:content>
      <v-card class="ma-3">
        <sales-rep-commissions-grid
            ref="salesRepCommissions"
            :salesRepresentativeId="salesRep.id"
            :salesRepReferenceId="salesRep.referenceId"
            :customPagingSize="[10, 20, 50]"
            @onRowSelected="onRowSelected"
        />
      </v-card>
    </template>
  </navigation-view-sales>
</template>
<script>
import NavigationViewSales from "@/views/navigation/NavigationViewSales.vue";
import SalesRepCommissionsGrid from "@/components/salesRepresentatives/SalesRepCommissionsGrid.vue";

export default {
  components: {
    SalesRepCommissionsGrid,
    NavigationViewSales
  },

  data() {
    return {
      salesRep: null,
    };
  },

  created() {
    this.salesRep = this.$store.state.loggedInUser;
  },

  methods: {
    onRowSelected(data) {
      if (data) {
        this.navigateToSelectedJob(data.JobReferenceId);
      }
    },
    navigateToSelectedJob(referenceId) {
      this.$router.push(`/sales/jobs/details/${referenceId}`);
    }
  }
};
</script>
