<template>
  <v-navigation-drawer
      app
      permanent
      rail
      class="drawer"
  >
    <v-list class="mt-12 mb-6">
      <v-list-item class="configuration-group-header-item">
        <v-list-item-content>
          <v-list-item-title class="mb-2 font-weight-medium subtitle-1">
            {{ configHeaderName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider/>

      <v-list-item
          class="text-left"
          :class="{ 'selected-group': selectedGroup.includes(salesRepTires) }"
          @click="expandPanel(salesRepTires)"
      >
        <v-list-item-icon>
          <v-icon>{{ getIcon(salesRepTires) }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Sales Reps Tiers</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          v-for="(group, groupName) in groupedItems"
          :key="groupName"
          class="text-left"
          :class="{ 'selected-group': selectedGroup.includes(groupName) }"
          @click="expandPanel(groupName)"
      >
        <v-list-item-icon>
          <v-icon>{{ getIcon(groupName) }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ groupName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

export default {
  props: {
    selectedGroup: {
      type: [Array, String]
    },
    groupedItems: Object,
    expandPanel: Function,
    getIcon: Function
  },

  data() {
    return {
      configHeaderName: 'Configuration Groups',
      salesRepTires: 'Sales Representative Tiers (1)'
    };
  }
};
</script>

<style scoped>
.selected-group {
  background-color: #e0e0e0;
}
.configuration-group-header-item {
  padding: 22px;
}
</style>
