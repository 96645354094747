<template>
  <v-layout row wrap>
    <!-- top section for status -->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs2 offset-xs10>
          <status-dropdown v-if="!bin.isSystem" readonly :value="bin.active" />
          <v-text-field
              v-else
              readonly
              label="Type"
              :value="'System Bin'"
          />
        </v-flex>
      </v-layout>
      <v-divider class="detailsDivider" />
    </v-flex>

    <v-flex xs5>
      <v-layout row wrap>
        <v-flex xs6>
          <v-text-field
            readonly
            v-model="bin.branchName"
            label="Branch"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            readonly
            v-model="bin.binNumber"
            label="Bin"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            readonly
            label="Last Cycle Count Date"
            v-model="lastCycleCountValue"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            readonly
            label="Cycle Count"
            v-model="cycleCountValue"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-spacer></v-spacer>
    <v-flex xs5>
      <v-layout row wrap>
        <v-flex xs12>
          <v-textarea
            readonly
            rows="4"
            :auto-grow="true"
            label="Description"
            v-model="bin.description"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import numeral from "numeral";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import DateFormatter from "@/assets/dateFormatter";

export default {
  components: {
    StatusDropdown
  },

  props: { bin: Object },

  watch: {
    bin: {
      immediate: true,
      handler(newVal) {
        this.cycleCountValue = numeral(newVal.cycleCount).format("0,0");
        this.lastCycleCountValue = newVal.lastCycleCount ? DateFormatter.formatDateWithFormat(newVal.lastCycleCount,'MM/DD/YYYY') : null;
      }
    }
  },

  data() {
    return {
      cycleCountValue: null,
      lastCycleCountValue: null
    };
  }
};
</script>
