<template>
  <modal-dialog
      :visible="visible"
      title="Change Password"
      width="500"
      :hideButtons="true"
      @cancelClicked="cancelClick"
      @submitClicked="submitClick"
  >
    <div id="change-password-modal">
      <change-password-form
          ref="form"
          :cancelButton="true"
          :changePassword="!this.overridePassword"
          :resetPassword="false"
          :overridePassword="this.overridePassword"
          :email="this.email"
          @submitClick="submitClick"
          @cancelClick="cancelClick"
      />
    </div>
  </modal-dialog>
</template>

<script>
import ChangePasswordForm from "./ChangePasswordForm.vue";
import ModalDialog from "../common/ModalDialog.vue";
import Users from "@/rest/users";

export default {
  components: {
    ChangePasswordForm,
    ModalDialog
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: null
    },
    overridePassword: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async submitUserPasswordChange(payload) {
      // self password change by user
      try {
        const user = {
          email: payload.email,
          currentPassword: payload.currentPassword,
          newPassword: payload.newPassword
        };
        await Users.getRestApi().changePassword(user);
        this.$emit("submitClick");
      } catch (error) {
        this.$root.$emit("mainNavShowError", error);
      }
    },
    async submitAdminPasswordOverride(component, payload) {
      // admin password change on behalf of user
      try {
        const response = await Users.getRestApi().overridePassword(payload.email, payload.newPassword);
        this.$root.$emit("closeUserEditDrawer");
        this.$emit("submitClick", response);
      } catch (error) {
        this.$root.$emit("mainNavShowError", error);
      }
    },
    submitClick() {
      const component = this;
      const payload = this.$refs.form.buildPayload();
      if (this.overridePassword) {
        this.submitAdminPasswordOverride(component, payload);
      } else this.submitUserPasswordChange(payload);
    },
    cancelClick() {
      this.$root.$emit("closeUserEditDrawer");
      this.$emit("cancelClick");
    }
  }
};
</script>
