<template>
  <grid-view
      ref="nav"
      nav-section="admin"
      page-title="List Installers"
      :action-button-label="isActionButtonDisplayed ? 'Create Installer' : null"
      :data-source="dataSource"
      :columnMinWidth="125"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="CompanyName"
        width="auto"
        caption="Company Name"
        :minWidth="200"
        :fixed="true"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Name"
        width="auto"
        caption="Contact Name"
        :minWidth="150"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="State"
        caption="State"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ZipCode"
        caption="Zip Code"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="PhoneNumber"
        caption="Phone"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Fax"
        caption="Fax"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Email"
        :minWidth="200"
        caption="Email"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="RetainageAmount"
        :minWidth="150"
        caption="Retainage Amount"
        :format="formatCurrency"
    />
    <dx-column
        data-field="RetainagePercentage"
        caption="Retainage %"
    />
    <dx-column
        data-field="LastPaycheckDate"
        caption="Last Paycheck Date"
        data-type="date"
        format="MM/dd/yyyy"
        :minWidth="165"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Branches"
        caption="Branches"
        :allowHeaderFiltering="false"
        :minWidth="130"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        :cell-template="StatusRenderer"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <template v-slot:drawerContent>
      <installer-create-drawer
          @cancelClicked="onCancel"
          @onCreateFail="onCreateFail"
          @onCreateSuccess="onCreateSuccess"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InstallerCreateDrawer from "@/components/installers/InstallerCreateDrawer.vue";
import ODataContext from "@/rest/odataContext";
import Roles from "../../assets/roles";
import StatusRenderer from "../../components/common/grid/StatusRenderer";
import Format from "../../components/common/Format";

export default {
  components: {
    GridView,
    DxColumn,
    InstallerCreateDrawer
  },
  data() {
    return {
      formatCurrency: {
        type: "currency",
        precision: 2
      },
      dataSource: {
        store: ODataContext['Installers'],
        select: [
          "ReferenceId",
          "CompanyName",
          "Name",
          "Address",
          "City",
          "State",
          "ZipCode",
          "PhoneNumber",
          "Fax",
          "Email",
          "Active",
          "Status",
          "RetainageAmount",
          "RetainagePercentage",
          "RetainageBalance",
          "LastPaycheckDate",
          "Branches"
        ]
      }
    };
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onCreateSuccess() {
      this.$refs.nav.showSuccess("Created new installer successfully!");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onCreateFail(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    onRowSelected(data) {
      this.$router.push(`/installers/details/${data.ReferenceId}`);
    },
    StatusRenderer,
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    }
  }
};
</script>
