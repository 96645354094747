<template>
  <v-form ref="vendorCostForm">
    <v-container>
      <v-row class="mt-4">
        <v-col sm="12">
          <vendors-autocomplete
              ref="vendorsAutocomplete"
              label="* Vendor"
              :vendors="activeVendors"
              :value="selectedVendor"
              :disabled="!isAddForm"
              :placeholder="'Search Vendors'"
              @inputSelected="onVendorSelected"
          />
        </v-col>
        <br/>
        <v-col :sm="!isAddForm ? 6: 3">
          <date-picker-with-type-in-text-field
              ref="datePicker"
              v-model="costEffectiveDate"
              placeholder="Choose a Date"
              validation-name="Future Cost Effective Date"
              label="* Cost Effective Date"
              :allowed-dates="showAllowedDates"
          />
        </v-col>
        <br/>
        <v-col :sm="!isAddForm ? 6: 3">
          <v-text-field
              v-model="vendorCost"
              label="* Vendor Cost"
              placeholder="0.00"
              persistent-placeholder
              :rules="[rules.required, rules.isCurrency]"
              :validation-name="'Vendor Cost'"
              type="number"
              prefix="$"
          />
        </v-col>
        <br/>
        <v-col sm="6">
          <v-text-field
              ref="vendorSku"
              v-if="isAddForm"
              v-model="vendorSku"
              placeholder=""
              persistent-placeholder
              label="Vendor SKU"
              maxLength="80"
              hint="Max 80 characters"
              :persistent-hint="isFocused"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DatePickerWithTypeInTextField from "../../form/DatePickerWithTypeInTextField.vue";
import Rules from "../../../../assets/rules";
import VendorsAutocomplete from "../../../searchFields/VendorsAutocomplete.vue";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    VendorsAutocomplete,
    DatePickerWithTypeInTextField,
  },

  props: {
    isAddForm: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedVendor: {
      type: Object | null,
      required: true
    },
    activeVendors: {
      type: Array,
      required: true
    },
    filteredDatesBySelectedVendor: {
      type: Array | null,
      required: false,
    }
  },

  watch: {
    selectedVendor(value) {
      if (value) {
        this.vendor = value.vendor || value;
      }
    },
    isFocused() {
      return this.$refs.vendorSku.$refs.input.focus();
    },
    activeVendors(val) {
    }
  },

  data() {
    return {
      vendorCost: null,
      vendor: null,
      vendorSku: null,
      costEffectiveDate: null,
      isFocused: false,
      rules: {
        required: Rules.required,
        isCurrency: Rules.isCurrencyFormat
      }
    };
  },

  methods: {
    validateForm() {
      return this.$refs.vendorCostForm.validate();
    },
    resetValidation() {
      this.$refs.vendorCostForm.resetValidation();
    },
    resetForm() {
      this.vendorCost = null;
      this.costEffectiveDate = null;
      this.vendor = null;
      this.vendorSku = null;
      if (this.isAddForm) {
        this.$refs.vendorsAutocomplete.reset();
      }
      this.$refs.datePicker.clearSelected();
    },
    showAllowedDates(value) {
      return this.selectedVendor
          ? DateUtils.allowedTodayAndFutureDatesAndExcludeAlreadySelected(value, this.filteredDatesBySelectedVendor)
          : DateUtils.allowedTodayAndFutureDates(value);
    },
    getSelectedDate() {
      return this.$refs.datePicker.getSelectedDate();
    },
    getFormData() {
      return {
        vendorId: this.vendor ? this.vendor.id : this.selectedVendor.id,
        vendorCost: this.vendorCost,
        costEffectiveDate: this.getSelectedDate(),
        vendorSku: this.vendorSku
      }
    },
    onVendorSelected(value) {
      this.vendor = value;
    }
  }
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  margin-top: 50px;
}
</style>
