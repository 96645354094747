<template>
  <div v-if="dataSourceConfig">
    <inline-grid
        ref="inventoryItemsInShipmentsCheckInGrid"
        :data-source="dataSourceConfig"
        :show-borders="true"
        :hover-state-enabled="true"
        :showSelectionMode="showSelectionMode"
        :columnAutoWidth="true"
        selectionMode="multiple"
        noDataText="No available items found"
        @invalidInput="onInvalidQuantityEntered"
        @validInput="onValidQuantityEntered"
        @rowSelected="onRowSelected"
        @contentReady="onContentReady"
    >
      <dx-paging :page-size="defaultInitialPageSize"/>
      <dx-pager
          :show-page-size-selector="true"
          :show-info="true"
      />
      <dx-column
          data-field="TransferNumber"
          caption="Transfer Number"
          data-type="string"
          sort-order="asc"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="InternalSku"
          caption="Internal SKU"
          :allowHeaderFiltering="false"
          data-type="string"
      />
      <dx-column
          data-field="ProductType"
          caption="Product Type"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="Style"
          caption="Style"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="Color"
          caption="Color"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="RollNumber"
          caption="Roll Number"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="DyeLot"
          caption="Dye Lot"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="DefaultBin"
          caption="Bin"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="ExpectedQuantity"
          caption="Expected Qty"
          data-type="number"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="ReceivedQuantity"
          caption="Recieved Qty"
          data-type="number"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="Entered"
          caption="Check-In Qty"
          data-type="number"
          cell-template="enterQuantityTemplate"
          :calculate-cell-value="calculateEnteredValue"
          :allowHeaderFiltering="false"
          :allow-sorting="false"
      />
      <dx-column
          data-field="Rounded"
          caption="Rounded to Box Q-ty"
          data-type="string"
          :allowHeaderFiltering="false"
          :allow-sorting="false"
      />
      <dx-column
          data-field="Status"
          caption="Checked In"
          data-type="boolean"
          cell-template="checkInIconStatus"
          :allowHeaderFiltering="false"
      />
      <template v-slot:selectedRowsCount>
        <div class="text-center pt-5 pb-5" v-show="showSelectionMode">
          Items Selected: {{
            rowsCount
          }}
        </div>
      </template>
    </inline-grid>
  </div>

</template>

<script>
import "devextreme/data/odata/store";
import {DxColumn, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";
import OdataContext from "../../../rest/odataContext";
import InlineGrid from "../../common/grid/InlineGrid.vue";
import InlineGridButton from "../../common/grid/InlineGridButton.vue";
import InlineGridFooter from "../../common/grid/InlineGridFooter.vue";
import {InventoryItemCheckedStatus, ShipmentStatuses} from "@/assets/statusIcons/statusIcons";

export default {
  components: {
    InlineGridFooter,
    InlineGridButton,
    InlineGrid,
    DxColumn,
    DxPager,
    DxPaging
  },

  props: {
    filterConfig: Array,
    showSelectionMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      defaultInitialPageSize: 10,
      rowsCount: 0,
      dataSourceConfig: null
    };
  },

  watch: {
    filterConfig(value) {
      this.setDataSourceAndFilterAndReloadDataSource(value);
    },
    showSelectionMode(value) {
      if (value) {
        this.$refs.inventoryItemsInShipmentsCheckInGrid.showSelection();
      } else {
        this.$refs.inventoryItemsInShipmentsCheckInGrid.hideSelection();
      }
      this.deselectItems()
      this.refreshGrid();
    }
  },

  methods: {
    setDataSourceAndFilterAndReloadDataSource(filter) {
      this.dataSourceConfig = {
        store: OdataContext['InventoryItemsForBranchTransferCheckIns'],
        select: [
          "Id",
          "TransferNumber",
          "InternalSku",
          "ProductType",
          "Style",
          "Color",
          "RollNumber",
          "DyeLot",
          "ExpectedQuantity",
          "ReceivedQuantity",
          "PickupDate",
          "ShipToBranchName",
          "ShipFromBranchName",
          "BoxQuantity",
          "DefaultBin",
          "IsClosed",
          "Status",
          "TriId",
          "ShipmentNumber",
          "ShipmentStatus",
          "ShowOnlyAvailable"
        ],
        filter: filter
      };
      const gridInstance = this.getGridInstance();
      this.reloadDataSourceWithFilter(gridInstance, filter);
    },
    async reloadDataSourceWithFilter(gridInstance, filter) {
      gridInstance.filter(filter);
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.filter(this.filterConfig);
      gridInstance.clearSorting();
    },
    getGridInstance() {
      return this.$refs.inventoryItemsInShipmentsCheckInGrid.getGridInstance();
    },
    refreshGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.refresh();
    },
    deselectItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    getSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return [...selectedRows];
    },
    calculateEnteredValue(cellData) {
      return cellData.data?.Entered || 0;
    },
    onInvalidQuantityEntered() {
      this.$emit('invalidQuantityEntered');
    },
    onValidQuantityEntered() {
      this.$emit('validQuantityEntered');
    },
    onRowSelected() {
      this.$emit('rowSelected', this.getSelectedRowsCount());
      this.updateSelectedRowCount();
    },
    updateSelectedRowCount() {
      this.rowsCount = this.getSelectedRowsCount();
    },
    getSelectedRowsCount() {
      return this.getOnlyAvailableForCheckInSelectedRows(true).length;
    },
    getOnlyAvailableForCheckInSelectedRows(isPartialCheckIn) {
      const selectedRows = this.getSelectedRows();
      return isPartialCheckIn ?
          this.getRowsAvailableForPartialCheckIn(selectedRows)
          : this.getRowsAvailableForCheckIn(selectedRows);
    },
    getRowsAvailableForCheckIn(rows) {
      return rows.filter(r => {
        let rowData = r.data ? r.data : r;
        return rowData.Status === InventoryItemCheckedStatus.CHECKED_OUT.name && !rowData.IsClosed;
      });
    },
    getRowsAvailableForPartialCheckIn(rows) {
      return rows.filter(r => {
        let rowData = r.data ? r.data : r;
        return !rowData.IsClosed && (rowData.Status === InventoryItemCheckedStatus.PARTIALLY_CHECKED_IN.name
            || (rowData.Status === InventoryItemCheckedStatus.CHECKED_OUT.name && rowData.ShipmentStatus === ShipmentStatuses.IN_PROGRESS.name));
      });
    },

    allRowsClosed(rows) {
      return rows.every(r => {
        let rowData = r.data ? r.data : r;
        return rowData.IsClosed
      });
    },
    onContentReady() {
      this.$emit('gridRefreshed');
    }
  }
};
</script>
