<template>
  <table class="line-items">
    <tr>
      <td width="20%" class="text-truncate">{{ lineItem.referenceId }}</td>
      <td width="50%" class="text-truncate">
        {{ lineItem.productCatalogItem.styleName }}
      </td>
      <td width="20%" class="text-truncate">
        {{ lineItem.productCatalogItem.color }}
      </td>
      <td width="10%" class="text-truncate">{{ itemQuantity }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  components: {},

  props: {
    lineItem: {
      type: Object,
      required: true
    },
    inventoryItems: {
      type: Array,
      required: false
    }
  },

  computed: {
    itemQuantity: function() {
      let q = 0;
      if (this.$props.lineItem.productCatalogItem.bulkInventoryItem) {
        q = this.$props.lineItem.quantity;
      } else {
        if (
          this.$props.inventoryItems !== undefined &&
          this.$props.inventoryItems !== null
        ) {
          this.$props.inventoryItems.forEach(item => {
            q += item.allocated;
          });
        }
      }
      return q;
    }
  },

  data() {
    return {};
  }
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  font-size: 14px;
}
</style>
