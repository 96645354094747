import Constants from "@/assets/constants";
import {isGenerated} from "@/assets/referenceIds";
import Rules from "@/assets/rules";

const CHECK_AND_CHECK_HOLD_PAYMENT_ID_MAX_LENGTH = 6;
const SQUARE_PAYMENT_ID_MAX_LENGTH = 4;
const SQUARE_ID = 10;
const CHECK_ID = 6;
const CHECK_HOLD_ID = 18;

const LineItemValidator = {

    validateLineItems(itemsToVerify) {
        let passed = true;
        let error;

        itemsToVerify.forEach(
            item => {
                let lineItem = item.jobLineItem;
                let typeName = this.getTypeName(lineItem.type);
                if (!Rules.isNumericQuantityValue(lineItem.quantity)) {
                    passed = false;
                    if (!isGenerated(lineItem.referenceId)) {
                        error = `Quantity must be entered for ${typeName} ${lineItem.comment}: ${lineItem.referenceId}`;
                    } else {
                        error = `Quantity must be entered for new job ${typeName} items.`;
                    }
                } else if (lineItem.type === Constants.jobType.labor && !Rules.isPositiveNumericOrZero(lineItem.price)) {
                    passed = false;
                    if (!isGenerated(lineItem.referenceId)) {
                        error = `Installer payable must be positive or zero for ${typeName} ${lineItem.comment}: ${lineItem.referenceId}`;
                    } else {
                        error = `Installer payable must be positive or zero for new job ${typeName} items.`;
                    }
                } else if (lineItem.type === Constants.jobType.labor && Rules.installerShouldBeAdded(lineItem)) {
                    passed = false;
                    error = `Installer must be selected for ${typeName} items.`;
                } else if (lineItem.type === Constants.jobType.labor && Rules.installerCrewShouldBeAdded(lineItem)) {
                    passed = false;
                    error = `Installer Crew must be selected for ${typeName} items.`;
                } else if (lineItem.type === Constants.jobType.labor && Rules.etcDateShouldBeSelected(lineItem)) {
                    passed = false;
                    error = `ETC Date must be selected for ${typeName} items.`;
                } else if (lineItem.type !== Constants.jobType.misc && !Rules.isNumericQuantityValue(lineItem.price)) {
                    passed = false;
                    if (!isGenerated(lineItem.referenceId)) {
                        error = `Price must be entered for ${typeName} ${lineItem.comment}: ${lineItem.referenceId}`;
                    } else {
                        error = `Price must be entered for new job ${typeName} items.`;
                    }
                } else if (lineItem.type === Constants.jobType.labor && !lineItem.installationCapabilityId) {
                    passed = false;
                    if (!isGenerated(lineItem.referenceId)) {
                        error = `No product type chosen for labor item: ${lineItem.referenceId}`;
                    } else {
                        error = `No product type chosen for new labor items.`;
                    }
                } else if (lineItem.type === Constants.jobType.misc && !lineItem.itemTypeId) {
                    passed = false;
                    if (!isGenerated(lineItem.referenceId)) {
                        error = `No item type chosen for misc item: ${lineItem.referenceId}`;
                    } else {
                        error = `No item type chosen for new misc items.`;
                    }
                }
            });

        return {
            verified: passed,
            error: error
        };
    },

    verifyPaymentLineItems(itemsToVerify, paymentTypes) {
        let passed = true;
        let error;
        itemsToVerify.forEach(
            lineItem => {
                let paymentLineItem = lineItem.jobLineItem;
                let paymentType = paymentTypes.find((x) => x.id === paymentLineItem.paymentTypeId);
                if (paymentType === undefined || paymentType === null) {
                    passed = false;
                    if (!isGenerated(paymentLineItem.referenceId)) {
                        error = `No payment type chosen for payment item: ${paymentLineItem.referenceId}`;
                    } else {
                        error = `No payment type chosen for new payment items.`;
                    }
                } else if (paymentType && paymentType.sageIntegration === true) {
                    if (!Rules.isNumericQuantityValue(paymentLineItem.paymentTypeId)) {
                        passed = false;
                        if (!isGenerated(paymentLineItem.referenceId)) {
                            error = `No payment type chosen for payment item: ${paymentLineItem.referenceId}`;
                        } else {
                            error = `No payment type chosen for new payment items.`;
                        }
                    } else if (!Rules.isPositiveNumericQuantityValue(paymentLineItem.price)) {
                        passed = false;
                        if (!isGenerated(paymentLineItem.referenceId)) {
                            error = `Non-zero value must be entered for payment item: ${paymentLineItem.referenceId}`;
                        } else {
                            error = `Non-zero value must be entered for new payment items.`;
                        }
                    }
                } else if (Rules.isPositiveNumericQuantityValue(paymentLineItem.price)) {
                    passed = false;
                    if (!isGenerated(paymentLineItem.referenceId)) {
                        error = `Zero value must be entered for payment item: ${paymentLineItem.referenceId}`;
                    } else {
                        error = `Zero value must be entered for new payment items.`;
                    }
                }
                if (!paymentLineItem.posted && paymentType && paymentType.planCodeRequired
                    && (!paymentLineItem.planCodeId || paymentLineItem.planCodeId.length === 0)) {
                    passed = false;
                    error = `No Plan Code set for new payment items.`;
                } else if (!paymentLineItem.posted && paymentType?.requiredNonEmptyPaymentId
                    && (!paymentLineItem.paymentId || paymentLineItem.paymentId.length === 0)) {
                    passed = false;
                    error = `No Payment ID set for new payment items.`;
                }
                if (isGenerated(paymentLineItem.referenceId) && this.hasPaymentIdExtraValidation(paymentLineItem)) {
                    if (!paymentLineItem.paymentId) {
                        error = `Payment ID is required for this payment type`;
                        passed = false;
                    } else {
                        if (this.isCheckOrCheckHoldPayment(paymentLineItem) && (paymentLineItem.paymentId
                            && paymentLineItem.paymentId.length > CHECK_AND_CHECK_HOLD_PAYMENT_ID_MAX_LENGTH)) {
                            passed = false;
                            error = `Check / Check Hold should have populated Payment ID up to ${CHECK_AND_CHECK_HOLD_PAYMENT_ID_MAX_LENGTH} characters`;
                        } else if (paymentLineItem.paymentTypeId === SQUARE_ID && paymentLineItem.paymentId.length > SQUARE_PAYMENT_ID_MAX_LENGTH) {
                            passed = false;
                            error = `Square should have populated Payment ID up to ${SQUARE_PAYMENT_ID_MAX_LENGTH} characters`;
                        }
                    }
                }
            });

        return {
            verified: passed,
            error: error
        }
    },

    getTypeName(typeId) {
        return Constants.jobType[typeId];
    },

    hasPaymentIdExtraValidation(paymentLineItem) {
        return paymentLineItem.paymentTypeId === SQUARE_ID
            || paymentLineItem.paymentTypeId === CHECK_ID
            || paymentLineItem.paymentTypeId === CHECK_HOLD_ID;
    },

    isCheckOrCheckHoldPayment(paymentLineItem) {
        return paymentLineItem.paymentTypeId === CHECK_ID || paymentLineItem.paymentTypeId === CHECK_HOLD_ID;
    },

};
export default LineItemValidator;
