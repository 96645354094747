<template>
  <div class="ma-1">
    <v-row>
      <v-col md="3" class="textFields pt-8">
        <v-text-field
            label="Ship From"
            persistent-placeholder
            :value="overview.shipFrom"
            readonly
            disabled
        />
      </v-col>

      <v-col md="3" class="textFields pt-8">
        <v-text-field
            label="Ship To"
            persistent-placeholder
            :value="overview.shipTo"
            readonly
            disabled
        />
      </v-col>

      <v-col md="3" class="textFields pt-8">
        <v-text-field
            label="Pickup Date"
            :value="formatDate(overview.pickUpDate)"
            persistent-placeholder
            readonly
            disabled
        />
      </v-col>

      <v-col md="3" class="textFields pt-8">
        <v-text-field
            label="Estimated Delivery Date"
            :value="formatDate(overview.estimatedDeliveryDate)"
            persistent-placeholder
            readonly
            disabled
        />
      </v-col>
      <v-row class="selected-transfer-request wrapper ma-2 mb-6">
        <v-data-table
            class="full-width"
            :headers="headers"
            :items="overview.transferRequests"
            hide-default-footer
            disable-pagination
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ formatDate(item.CreatedAt) }}</td>
              <td>{{ item.TransferFromBranch }}</td>
              <td>{{ item.TransferToBranch }}</td>
              <td>{{ item.TransferRequestNumber }}</td>
<!--              <td>{{ item.JobReferenceId }}</td>-->
<!--              <td>{{ item.ClosestInstallationDate }}</td>-->
            </tr>
          </template>
        </v-data-table>
      </v-row>
    </v-row>
  </div>
</template>

<script>

import DateFormatter from "../../../assets/dateFormatter";

export default {
  components: {},

  props: {
    overview: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      headers: [
        {text: "Created Date", value: "CreatedAt", sortable: true, align: 'center'},
        {text: "Transfer From Branch", value: "TransferFromBranch", sortable: true, align: 'center'},
        {text: "Transfer To Branch", value: "TransferToBranch", sortable: true, align: 'center'},
        {text: "Transfer Request Number", value: "TransferRequestNumber", sortable: true, align: 'center'},
        // {text: "Job Reference Id", value: "JobReferenceId", sortable: true, align: 'center'},
        // {text: "Closest Installation Date", value: "ClosestInstallationDate", sortable: true, align: 'center'},
      ],
    }
  },

  methods: {
    formatDate(date) {
      return DateFormatter.formatDateInMMDDYYYYFormat(date);
    },
  }
};
</script>

<style scoped>
.full-width {
  width: 100%
}

</style>
