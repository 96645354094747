<template>
  <div>
    <v-text-field
        v-model="quantity"
        :class="textFieldClass"
        :type="inputType"
        :disabled="disabled"
        placeholder="Enter Quantity"
        hide-details
        :rules="[rules.isValid]"
        @change="onChange"
        @blur="onBlur"
        @focus="onFocus"
    />
  </div>
</template>

<script>

import Rules from "@/assets/rules";

export default {

  props: {
    cellData: Object,
    inputType: {
      type: String,
      default: 'number',
      required: false
    },
    disabled: Boolean
  },

  watch: {
    cellData: {
      immediate: true,
      handler(newVal) {
        this.quantity = newVal.data?.Entered || 0;
      }
    },
  },

  data() {
    return {
      quantity: 0,
      defaultQuantity: 0,
      rules: {
        isValid: Rules.validQuantity
      }
    }
  },

  computed: {
    textFieldClass() {
      return 'short-input';
    }
  },

  methods: {
    onChange(value) {
      if (this.quantity && this.quantity >= 0) {
        this.$emit('valueEntered', Number(value), this.cellData);
      } else {
        this.quantity = this.defaultQuantity
        this.$emit('valueEntered', this.defaultQuantity, this.cellData);
      }

    },
    onBlur(value) {
      if (this.quantity === undefined || this.quantity < 0) {
        this.quantity = this.defaultQuantity
        this.$emit('valueEntered', this.quantity, this.cellData);
      }
    },
    onFocus(value) {
      if (Number(this.quantity) === 0) {
        this.quantity = ''
      }
    },
  }

};
</script>

<style scoped>
.no-styles {
  border: none !important;
  pointer-events: none;
  text-align: center;
}
</style>

