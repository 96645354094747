<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat class="text-sm-left subheading">
            <span color="primary">Is 50Floor responsible for part of the chargeback?</span>
          </v-card>
          <v-radio-group v-model="fiftyFloorResponsible">
            <v-radio color="primary" key="no" label="No" :value="false" />
            <v-radio color="primary" key="yes" label="Yes" :value="true" />
          </v-radio-group>
          <v-text-field
            style="width: 25%;"
            class="mt-3 mb-3"
            v-show="fiftyFloorResponsible"
            v-model="fiftyFloorAmount"
            label="* 50Floor Amount"
            placeholder="0.00"
            persistent-placeholder
            prefix="$"
            :rules="[rules.isPositiveAndGreaterThanZero]"
            type="number"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/assets/rules";

export default {
  components: {},

  props: {
    job: Object,
  },

  data() {
    return Object.assign(
      {},
      {
        error: null,
        fiftyFloorResponsible: false,
        fiftyFloorAmount: 0,
        rules: {
          isPositiveAndGreaterThanZero: value => {
            if (this.fiftyFloorResponsible) {
              return Rules.isPositiveAndGreaterThanZero(value);
            } else return true;
          }
        }
      }
    );
  },

  watch: {
    fiftyFloorResponsible(newVal) {
      if (!newVal) {
        this.fiftyFloorAmount = 0;
      }
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {
      this.fiftyFloorResponsible = false;
      this.fiftyFloorAmount = 0;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    getPayload() {
      const payload = {};
      payload.fiftyFloorAmount = this.fiftyFloorAmount;
      return payload;
    },

    // formatCurrency(curr) {
    //   return Currency(curr).format();
    // },
  },
};
</script>
