<template>
  <drawer-form
      ref="form"
      title="Create Inventory Bin"
      submit-label="Save Bin"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <bin-form ref="binForm" mode="create"/>
  </drawer-form>
</template>

<script>
import BinForm from "@/components/bins/BinForm.vue";
import Bins from "@/rest/bins";
import DrawerForm from "../common/form/DrawerForm";

export default {
  components: {
    DrawerForm,
    BinForm
  },

  data() {
    return {
      inProgress: false
    };
  },

  methods: {
    onCancel() {
      this.$refs.binForm.resetToDefaults();
      this.$emit("cancelClicked");
    },
    onSubmit: async function () {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.inProgress = true;
          let payload = this.$refs.binForm.buildPayload();
          const data = await this.createBin(payload);
          this.$refs.binForm.resetToDefaults();
          this.$emit("submitSuccess", data);
          this.$refs.form.resetValidation();
        }
      } catch (error) {
        this.$emit("submitFailed", error);
      }
      this.inProgress = false;
    },
    async createBin(payload) {
      try {
        const response = await Bins.getRestApi().createBin(payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
