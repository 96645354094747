<template>
  <navigation-view
      ref="nav"
      page-title="Purchase Order Details"
      nav-section="orders"
  >
    <template v-slot:content>
      <v-container v-if="purchaseOrder" grid-list-lg fluid>
        <purchase-order-details-read-only
            ref="purchaseOrderDetailsReadOnly"
            :purchaseOrder="purchaseOrder"
            :dateIssued="dateIssued"
            :sidemark="sidemark"
            :totalVendorCostRounded="totalVendorCostRounded"
        />

        <purchase-order-inline-grids
            ref="inlineGrids"
            :purchaseOrderNumber="purchaseOrderNumber"
            :purchaseOrder="purchaseOrder"
            :jobReferenceId="jobReferenceId"
            :customer="customer"
            :lineItems="lineItems"
            :totalVendorCostRounded="totalVendorCostRounded"
            @showError="showError"
            @itemClose="itemClose"
            @onLineItemsUpdated="lineItemsUpdated"
            @onEtaUpdated="etaUpdateShowSuccess"
        />

        <close-p-o-wizard
            ref="closePurchaseOrderWizard"
            :purchaseOrder="purchaseOrder"
            @submitClosePOSuccess="onClosePOSuccess"
            @submitClosePOFailed="onSubmitFailed"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <purchase-order-update-drawer
          ref="update"
          :purchaseOrder="purchaseOrder"
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>

    <template v-slot:nav-action v-if="purchaseOrder">
      <v-row id="buttons-row" class="d-flex justify-end ma-0 pb-0 pt-0">
        <v-col md="6" class="pb-0 bt-0 pr-1 pl-0 pt-0">
          <navigation-action-button
              :showActionButtons="true"
              label="Edit PO"
              @click="showDrawer"
          />
        </v-col>
        <v-col md="6" class="pb-0 bt-0 pr-0 pl-0 pt-0" v-if="showClosePOButton">
          <navigation-action-button
              :showActionButtons="showClosePOButton"
              label="Close PO"
              @click="showClosePoWizard"
          />
        </v-col>
      </v-row>
    </template>

  </navigation-view>
</template>

<script>
import PurchaseOrders from "@/rest/purchaseOrders";
import PurchaseOrderInlineGrids from "./PurchaseOrdersInlineGrids.vue";
import NavigationView from "../navigation/NavigationView.vue";
import NavigationActionButton from "../navigation/NavigationActionButton.vue";
import PurchaseOrderUpdateDrawer from "../../components/purchaseOrders/PurchaseOrderUpdateDrawer.vue";
import PurchaseOrderDetailsReadOnly from "../../components/purchaseOrders/PurchaseOrderDetailsReadOnly.vue";
import DateFormatter from "../../assets/dateFormatter";
import ClosePOWizard from "../../components/purchaseOrders/closePurchaseOrder/ClosePOWizard";
import Roles from "../../assets/roles";

export default {
  components: {
    ClosePOWizard,
    NavigationView,
    NavigationActionButton,
    PurchaseOrderUpdateDrawer,
    PurchaseOrderDetailsReadOnly,
    PurchaseOrderInlineGrids
  },
  data() {
    return {
      purchaseOrderNumber: null,
      purchaseOrder: null,
      lineItems: [],
      displayedAt: null,
      dateIssued: null,
      sidemark: null,
      isPurchaseOrderLoaded: false,
      jobReferenceId: null,
      customer: null,
      totalVendorCostRounded: null,
    };
  },

  created() {
    this.display(this.$route.params.purchaseOrderNumber);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.purchaseOrderNumber);
    next();
  },

  computed: {
    showClosePOButton() {
      let poIsOpen = !this.purchaseOrder.purchaseOrderClosedDate
      let poHasJob = this.purchaseOrder.job;

      return poIsOpen && (!poHasJob || (poHasJob && Roles.isAnyAdminUser()));
    }
  },

  methods: {
    display(purchaseOrderNumber) {
      this.purchaseOrderNumber = purchaseOrderNumber;
      this.getPurchaseOrderSummary();
    },
    async getPurchaseOrderSummary() {
      try {
        const summaryData = await this.getPurchaseOrderSummaryData();
        this.setData(summaryData);
      } catch (error) {
        this.showError(error);
      }
    },
    setData(data) {
      this.totalVendorCostRounded = data.totalVendorCostRounded.toFixed(2);
      this.purchaseOrder = data.purchaseOrder;
      this.lineItems = data.lineItems;
      if (data.purchaseOrder.job) {
        this.jobReferenceId = data.purchaseOrder.job.referenceId;
      }
      this.customer = data.customer;
      this.isPurchaseOrderLoaded = true;
      this.sidemark = data.customer ? data.customer.fullName : null;
      this.dateIssued = DateFormatter.formatDateInMMDDYYYYFormat(data.purchaseOrder.dateIssued);
    },
    async getPurchaseOrderSummaryData() {
      try {
        const response = await PurchaseOrders.getRestApi().getPurchaseOrderSummary(this.purchaseOrderNumber);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    onSubmitSuccess(data) {
      this.$refs.nav.hideDrawer();
      this.purchaseOrder.vendorReferenceNumber = data.vendorReferenceNumber;
      this.$refs.inlineGrids.refreshActivityLog();
      this.showSuccess();
    },
    onSubmitFailed(error) {
      this.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    showSuccess() {
      this.$refs.nav.showSuccess("Updated purchase order details successfully!");
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    showClosePoWizard(selectedItem) {
      this.$refs.closePurchaseOrderWizard.openClosePODialog(!!this.purchaseOrder?.job, selectedItem);
    },
    onClosePOSuccess(response) {
      this.$refs.closePurchaseOrderWizard.resetWizard();
      if (response.purchaseOrderId) {
        this.$refs.nav.showSuccess("PO Item has been closed successfully!");
      } else {
        this.$refs.nav.showSuccess("PO has been closed successfully!");
      }
      this.getPurchaseOrderSummary();
      this.$refs.inlineGrids.refreshActivityLog();
    },
    itemClose(selectedItem) {
      this.showClosePoWizard(selectedItem[0]);
    },
    lineItemsUpdated(updatedSummary) {
      this.setData(updatedSummary);
    },
    etaUpdateShowSuccess() {
      this.$refs.nav.showSuccess("PO Item ETA has been updated successfully!");
    }
  }
};
</script>
