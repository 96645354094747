<template>
  <v-autocomplete
      label="Location"
      v-model="autocompleteLocationModel"
      :items="locationFoundItems"
      :search-input.sync="locationSearchText"
      item-text="value"
      item-value="id"
      hide-no-data
      return-object
      placeholder="Search for location"
      prepend-icon="gps_fixed"
      @change="onChange"
      :loading="loading"
      data-id="place-autocomplete"
      class="editable"
      attach
      persistent-placeholder
  />
</template>

<script>
import {getSuggestions} from "./PlaceUtils";

export default {
  data() {
    return {
      autocompleteLocationModel: null,
      locationSearchText: null,
      locationEntries: []
    };
  },

  computed: {
    locationFoundItems() {
      return this.locationEntries;
    }
  },

  props: {
    address: String,
    loading: Boolean,
    usaOnly: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  watch: {
    selected(value) {
      this.$emit("input", value);
    },
    locationSearchText(newVal) {
      const component = this;
      if (!newVal || newVal.length <= 2) return; // wait until 3 chars typed
      getSuggestions(newVal, this.usaOnly)
          .then((res) => {
            component.locationEntries = res;
          })
    }
  },

  methods: {
    onChange(place) {
      this.$emit("onPlaceChange", place.id);
    },
    clearAutocomplete() {
      this.autocompleteLocationModel = null;
    }
  }
};
</script>
