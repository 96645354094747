<template>
  <v-autocomplete
      v-model="selected"
      :items="availableInstallerCrews"
      :placeholder="placeholder"
      :readonly="readonly"
      :rules="required ? [rules.required] : []"
      :multiple="false"
      :label="label || null"
      id="installer-crew-search-field"
      :item-text="itemTextField"
      :item-value="itemValueField"
      item-disabled="inactive"
      persistent-placeholder
      :no-data-text="noDataText"
      attach
      dense
  />
</template>
<script>

import rules from "@/assets/rules";

export default {
  computed: {
    rules() {
      return rules
    }
  },
  components: {},

  data() {
    return {
      selected: null
    };
  },

  props: {
    value: {type: Number, required: false, nullable: true},
    placeholder: {type: String, required: false},
    readonly: {type: Boolean, required: false},
    required: {type: Boolean, required: true},
    availableInstallerCrews: {type: Array, required: true},
    itemTextField: {type: String, required: false, default: 'name'},
    itemValueField: {type: String, required: false, default: 'id'},
    label: {type: String, required: false},
    noDataText: {type: String, required: false, default: 'No crews added for this installer'},
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selected = newVal;
        }
      },
    },
    selected(value) {
      if (value) {
        this.$emit('input', value);
      } else {
        this.$emit('cleared');
      }
    }
  },

  methods: {
    getSelected() {
      return this.selected;
    },
    clearSelectedCrew() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>

</style>
