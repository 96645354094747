<template>
  <v-menu
    :disabled="disabled"
    ref="menu"
    :close-on-content-click="false"
    v-model="menu"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    id="time-picker"
    min-width="290px"
  >
    <v-text-field
      :disabled="disabled"
      :readonly="readonly"
      v-model="timeFormatted"
      hint="HH:mm am/pm format"
      :placeholder="placeholder || ' '"
      :rules="[rules.required, rules.isTime]"
      :label="label || 'Pick a time'"
      append-icon="access_time"
    />
    <v-time-picker
      v-model="time"
      v-if="menu"
      ref="timePicker"
      :allowed-hours="allowedHours"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="onTimeChosen(time)">OK</v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: {
    label: String,
    validationName: String,
    optional: Boolean,
    allowedHours: Function,
    readonly: Boolean,
    placeholder: String,
    disabled: Boolean,
    value: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      menu: false,
      time: null,
      timeFormatted: null,
      rules: {
        required: value =>
          !!value ||
          this.$props.optional ||
          this.$props.validationName + " is required",
        isTime: value => {
          if (value && !this.$props.readonly && !this.$props.disabled) {
            const pattern = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;
            return pattern.test(value) || "Invalid time. HH:mm format required";
          }
          return true;
        }
      }
    };
  },

  watch: {
    value: {
      immediate: true,
      handler: function(value) {
        this.time = value;
      }
    },
    time(value) {
      if (value != null) {
        this.time = value;
        this.timeFormatted = moment(value, "HH:mm").format("h:mm a");
      } else {
        this.timeFormatted = this.$props.placeholder;
      }
    }
  },

  methods: {
    onTimeChosen: function(time) {
      this.$refs.menu.save(time);
      this.$emit("input", time);
    }
  }
};
</script>

<style></style>
