<template>
  <modal-dialog
      width="450"
      submitEnabled="true"
      :title="title"
      cancelLabel="Acknowledge"
      :visible="visible"
      :hideSubmit="true"
      :icon="icon"
      @cancelClicked="onAckClicked"
  >
    <div class="subheading mt-8">{{ message }}</div>
    <div class="timestamp mt-2" v-if="formattedTime">
      <small><strong>Sent at:</strong> {{ formattedTime }}</small>
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    ModalDialog
  },

  props: ["visible", "title", "message", "icon", "createdAt"],

  data() {
    return {};
  },

  computed: {
    formattedTime() {
      return DateUtils.convertToLocalDateTime(this.createdAt)
          .toFormat("yyyy-MM-dd HH:mm:ss");
    }
  },

  methods: {
    onAckClicked() {
      this.$emit("acknowledged");
    }
  }
};
</script>

<style scoped>
.timestamp {
  color: #666;
}
</style>
