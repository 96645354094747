<template>
  <grid-view
      ref="nav"
      page-title="Purchase Orders"
      nav-section="orders"
      :data-source="dataSource"
      action-button-label="Create Purchase Order"
      action-button-action="bulkPOWizard"
      @rowSelected="onRowSelected"
      :columnMinWidth="175"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      :branchUserRestricted="true"
  >
    <dx-column
        data-field="BranchName"
        caption="Branch"
        :allowHeaderFiltering="allowBranchesFilter"
        :header-filter="{ dataSource: branchesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="PurchaseOrderClosedDate"
        caption="PO Closed Date"
        data-type="date"
        format="MM/dd/yyyy"
        :minWidth="165"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="VendorName"
        :minWidth="200"
        caption="Vendor Name"
        data-type="string"
    />
    <dx-column
        data-field="PurchaseOrderNumber"
        caption="Purchase Order Number"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="VendorReferenceNumber"
        caption="Vendor Reference Number"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="DateIssued"
        caption="Date Issued"
        data-type="date"
        format="MM/dd/yyyy"
        :minWidth="165"
        :allowHeaderFiltering="false"
        sort-order="desc"
    />
    <dx-column
        data-field="JobReferenceId"
        caption="Job Reference ID"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CustomerName"
        :minWidth="200"
        caption="Customer Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />

    <template v-slot:wizardContent>
      <bulk-purchase-order-wizard
          ref="bulkPurchaseOrderWizard"
          @success="onSubmitSuccess"
          @error="onSubmitFailed"
          @cancel="onCancel"
          @showError="showError"
      />
    </template>
  </grid-view>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import RoleRestrictor from "../../rest/odata";
import GridView from "../../views/common/GridView.vue";
import BulkPurchaseOrderWizard from "../../components/purchaseOrders/bulkPurchaseOrder/PurchaseOrderWizard.vue";
import ODataContext from "../../rest/odataContext";
import CustomizeFilter from "../../components/common/grid/CustomizeFilter";

export default {
  components: {
    GridView,
    DxColumn,
    BulkPurchaseOrderWizard
  },
  data() {
    const config = {
      allowBranchesFilter: false,
      branchesFilter: [],
      dataSource: {
        store: ODataContext['PurchaseOrders'],
        select: [
          "Id",
          "VendorReferenceNumber",
          "DateIssued",
          "PurchaseOrderClosedDate",
          "PurchaseOrderNumber",
          "BranchName",
          "VendorName",
          "JobReferenceId",
          "CustomerName",
          "BranchReferenceId"
        ],
        sort: [{selector: "DateIssued", desc: true}]
      }
    };
    return RoleRestrictor.updateConfig(this.$store.state.loggedInUser, config);
  },
  created() {
    this.getFilters();
  },
  methods: {
    async getFilters() {
      try {
        const user = this.$store.state.loggedInUser;
        const marketsFilter = await CustomizeFilter.getMarketFiltersIfNotBranchUser(user);
        // TODO: do we need this request for Purchase Orders ?
        const branchesFilter = await CustomizeFilter.getBranchesForParticularUser();
        this.setFilters(marketsFilter, branchesFilter);
      } catch (error) {
        this.$refs.nav.showError(error);
      }
    },
    setFilters(marketsFilter, branchesFilter) {
      if (marketsFilter) {
        this.marketsFilter = marketsFilter;
        this.allowMarketFilter = true;
      }
      if (branchesFilter) {
        this.branchesFilter = branchesFilter;
        this.allowBranchesFilter = true;
      }
    },
    onRowSelected(data) {
      this.$router.push(`/orders/purchases/details/${data.PurchaseOrderNumber}`);
    },
    onSubmitSuccess(message) {
      this.$refs.bulkPurchaseOrderWizard.resetWizard();
      this.hideWizard();
      this.$refs.nav.refreshGrid();
      this.$refs.nav.showSuccess(message);
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.hideWizard();
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    hideWizard() {
      this.$refs.nav.hideWizard();
    }
  }
};
</script>
