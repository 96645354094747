<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td class="reference-id-td top-aligned" style="width: 10%">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <td style="width: 20%">
          <payment-types-search-field
              :disabled="disableEditing"
              :required="true"
              :multiple="false"
              :value="paymentTypeId"
              :paymentTypes="paymentTypes"
              label=" "
              placeholder=" "
              class="short-input-payments left-aligned pb-1"
              itemText="name"
              @emitError="emitError"
              @inputSelected="updatePaymentTypeId"
              ref="paymentTypeSearchField"
          />
        </td>
        <td style="width: 10%"
            class="top-aligned">
          <v-btn
              small
              dark
              color="primary"
              v-if="displayReceivedButton"
              style="width: 90%"
              :loading="processing"
              @click="onReceivedClick"
          >
            Received
          </v-btn>
        </td>
        <td style="width: 20%">
          <v-text-field
              :disabled="disableEditing"
              :required="false"
              v-model="comment"
              class="short-input-payments left-aligned"
          />
        </td>
        <td style="width: 10%"
            class="top-aligned">
          <v-text-field
              v-if="showPaymentId"
              v-model="paymentId"
              :disabled="disableEditing"
              :rules="[rules.requiredNotBlank]"
              class="short-input-payments left-aligned"
          />
        </td>
        <td style="width: 10%"
            class="top-aligned">
          <plan-code-search-field
              v-if="planCodeRequired"
              ref="planCodeSearchField"
              :disabled="disableEditing"
              :required="true"
              :multiple="false"
              :value="planCodeId"
              :planCodes="planCodes"
              class="short-input-payments left-aligned pb-1"
              @emitError="emitError"
              @inputSelected="updatePlanCodeId"
          />
        </td>
        <td style="width: 15%" class="pt-0">
          <v-text-field
              :disabled="disableEditing || !isHold"
              v-model="customerPaidAmount"
              :rules="[rules.isPositiveOrZeroAmount]"
              class="short-input-payments"
              prefix="$"
              @blur="setSanitizedCustomerPaidAmount"
          />
        </td>
        <td style="width: 15%" class="pt-0">
          <v-text-field
              :disabled="disableEditing || isHold"
              v-model="price"
              :rules="[rules.isPositiveOrZeroAmount]"
              class="short-input-payments"
              prefix="$"
              @blur="setSanitizedPrice"
          />
        </td>
        <td style="width: 30px" class="top-aligned">
          <v-btn
              small
              dark
              icon
              class="short ma-0 pa-0"
              v-if="canDeleteItems"
              @click="onDeleteItem(lineItem.jobLineItem.referenceId)"
          >
            <v-icon class="short" color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
    <received-payment-dialog
        ref="receivePaymentDialog"
        @receivedConfirmed="onReceivedConfirmed"
    >
    </received-payment-dialog>
  </v-card>
</template>

<script>
import PaymentTypesSearchField from "../../../components/searchFields/PaymentTypes.vue";
import PlanCodeSearchField from "../../../components/searchFields/PlanCodes.vue";
import ReceivedPaymentDialog from "../../../components/jobs/paymentLineItems/ReceivedPaymentDialog";
import JobLineItems from "../../../rest/jobLineItems";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import {isGenerated} from "../../../assets/referenceIds";
import Rules from "../../../assets/rules";
import FormattingHelper from "../../../assets/formattingHelper";

export default {
  components: {
    ReceivedPaymentDialog,
    PaymentTypesSearchField,
    PlanCodeSearchField
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true,
    },
    paymentTypes: {
      type: Array,
      required: true
    },
    planCodes: {
      type: Array,
      required: true
    },
    wsSessionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      referenceId: null,
      type: null,
      customerPaidAmount: null,
      price: null,
      comment: null,
      planCodeId: null,
      paymentId: null,
      paymentTypeId: null,
      paymentType: null,
      processing: false,
      rules: {
        isPositiveOrZeroAmount: value => {
          return Rules.isPositiveOrZero(FormattingHelper.removeNonNumericCharacters(value));
        },
        requiredNotBlank: Rules.requiredNotBlank,
      },
    };
  },

  computed: {
    canDeleteItems() {
      return !(this.job.invoiced && this.lineItem.jobLineItem.posted);
    },
    disableEditing() {
      return this.lineItem.jobLineItem.posted;
    },
    isHold() {
      const paymentType = this.getPaymentType();
      return paymentType == null ? false : paymentType.hold;
    },
    displayReceivedButton() {
      return !this.isGenerated(this.lineItem.jobLineItem.referenceId) && this.isHold
          && this.lineItem.jobLineItem.customerPaidAmount > 0;
    },
    planCodeRequired() {
      const paymentType = this.getPaymentType();
      return paymentType == null ? false : paymentType.planCodeRequired;
    },
    showPaymentId() {
      return !!this.lineItem.jobLineItem.paymentId || this.showPaymentIdField();
    }
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.referenceId = updated.jobLineItem.referenceId;
        this.type = updated.jobLineItem.type;
        this.status = null;
        this.comment = updated.jobLineItem.comment;
        this.quantity = null;
        this.paymentType = updated.jobLineItem.paymentType;
        this.paymentTypeId = updated.jobLineItem.paymentTypeId;
        this.planCodeId = updated.jobLineItem.planCodeId;
        this.paymentId = updated.jobLineItem.paymentId;
        this.price = CurrencyFormatter.formatCurrency(updated.jobLineItem.price);
        this.customerPaidAmount = CurrencyFormatter.formatCurrency(updated.jobLineItem.customerPaidAmount);
      },
    },
    comment(value) {
      this.lineItem.jobLineItem.comment = value;
      this.$emit('commentUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    planCodeId(value) {
      this.lineItem.jobLineItem.planCodeId = value;
      this.$emit('planCodeIdUpdated');
    },
    paymentId(value) {
      this.lineItem.jobLineItem.paymentId = value;
      this.$emit('paymentIdUpdated');
    },
    price(value) {
      if (value === undefined || value === null) return;

      let price = CurrencyFormatter.asCurrency(value);
      if (price.intValue === this.lineItem.jobLineItem.price.intValue) {
        return;
      }
      this.lineItem.jobLineItem.price = price;
      this.customerPaidAmount = price;
      this.lineItem.jobLineItem.customerPaidAmount = price;
      this.$emit('priceUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    customerPaidAmount(value) {
      if (this.isHold) {
        if (value === undefined || value === null) return;

        let customerPaidAmount = CurrencyFormatter.asCurrency(value);
        if (customerPaidAmount.value === CurrencyFormatter.asCurrency(this.lineItem.jobLineItem.customerPaidAmount).value) {
          return;
        }
        this.lineItem.jobLineItem.customerPaidAmount = customerPaidAmount;
        this.$emit('priceUpdated', {
          item: this.lineItem,
          updated: value,
        });
      }
    },
    paymentTypeId(value) {
      this.lineItem.jobLineItem.paymentTypeId = value;
      this.$emit('paymentTypeUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    paymentType(value) {
      this.lineItem.jobLineItem.paymentType = value;
    },
  },

  methods: {
    onDeleteItem(referenceId) {
      this.$emit('itemDeleted', referenceId);
    },
    setSanitizedPrice() {
      this.price = CurrencyFormatter.sanitizePrice(CurrencyFormatter.asCurrency(FormattingHelper.removeNonNumericCharacters(this.price)));
    },
    setSanitizedCustomerPaidAmount() {
      this.customerPaidAmount = CurrencyFormatter.sanitizePrice(CurrencyFormatter.asCurrency((FormattingHelper.removeNonNumericCharacters(this.customerPaidAmount))));
    },
    emitError(error) {
      this.$emit('error', error);
    },
    updatePaymentTypeId(value, paymentType) {
      if (this.paymentTypeId !== value) {
        this.paymentId = null;
      }
      this.paymentTypeId = value;
      this.paymentType = paymentType;
      if (paymentType.hold) {
        this.price = CurrencyFormatter.zeroCurrency();
      }
      if (!paymentType.planCodeRequired) {
        this.planCodeId = null;
        this.$refs.planCodeSearchField.clearSelected();
      }
    },
    updatePlanCodeId(value) {
      this.planCodeId = value;
    },
    onReceivedClick() {
      this.$refs.receivePaymentDialog.openDialog(this.referenceId);
    },
    async onReceivedConfirmed() {
      this.$store.commit('setIsJobSaving', true);
      await this.performMarkHoldPaymentAsReceivedRequest(this.job.referenceId, this.referenceId);
      this.$store.commit('setIsJobSaving', false);
    },
    async performMarkHoldPaymentAsReceivedRequest(jobReferenceId, paymentReferenceId) {
      this.processing = true;
      try {
        await JobLineItems.getRestApi().markHoldPaymentAsReceived(jobReferenceId, paymentReferenceId, this.wsSessionId);
        this.$emit('holdReceived');
      } catch (error) {
        this.$emit('onErrorOccurred', error);
      } finally {
        this.processing = false;
      }
    },
    getPaymentType() {
      return this.paymentTypes.find(item => item.id === this.paymentTypeId);
    },
    showPaymentIdField() {
      const paymentType = this.getPaymentType();
      return paymentType == null ? false : paymentType.hasPaymentId;
    },
    isGenerated
  }
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}

.reference-id-td {
  width: 15%;
  padding-top: 7px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}

.item-row td.top-aligned {
  vertical-align: top;
}

.v-text-field {
  margin-top: 0;
  padding-top: 0;
}
</style>
