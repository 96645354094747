<template>
  <navigation-view ref="nav" page-title="Branch Management" nav-section="warehouse">
    <template v-slot:content>
      <v-container>
        <div class="headline">Check-In - Branch Transfer</div>
        <v-divider class="detailsDivider"/>
        <v-row>
          <v-col cols="3">
            <branches-autocomplete
                ref="shipFrom"
                label="Ship from (Branch)"
                :required="true"
                :selectAllEnabled="false"
                :getId="true"
                @inputSelected="shipFromBranchSelected"
                :getBranchesOnComponentCreation="false"
                :defaultBranches="userBranches"
            />
          </v-col>
          <v-col cols="3">
            <branches-autocomplete
                :defaultBranches="filteredUserBranches"
                ref="shipTo"
                label="Ship to (Branch)"
                :required="true"
                :selectAllEnabled="false"
                :getId="true"
                :getBranchesOnComponentCreation="false"
                @inputSelected="shipToBranchSelected"
            />
          </v-col>
          <v-col cols="2">
            <date-picker-with-type-in-text-field
                ref="pickupDatePicker"
                v-model="selectedPickupDate"
                label="Pickup Date"
                id="pickup-date-picker"
                validation-name="Pickup Date"
                :optional="false"
                :readonly="false"
                @input="onPickupDateSelected"
                @cleared="onPickupDateCleared"
            />
          </v-col>
          <v-col cols="2" class="mt-2 pt-1">
            <simple-check-box-with-label
                :fontSize="'14px'"
                label="Show only available for check-in"
                v-model="showOnlyAvailableForCheckInItems"
            />
          </v-col>

          <v-col cols="2">
            <v-btn
                color="primary"
                class="searchButton"
                :disabled="isDisabled"
                @click="handleBranchTransferSearch"
            >
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container md fluid>
        <v-row v-show="showInventoryItemsGrid">

          <v-col cols="12">
            <v-card raised light color="white" class="mt-3 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Inventory Items included in the Shipments</v-app-bar-title>
              </v-app-bar>
              <grid-toolbar
                  :showGridStateChange="true"
                  :checkInModeEnabled="checkInModeEnabled"
                  @onToggleResetGridDialog="clearGridState"
                  @gridStateChange="onGridStateChange"
              />
              <v-row v-if="showWarningMessage" class="ma-1 mb-0">
                <v-col cols="12" class="text-center full-width">
                  <v-alert
                      border="left"
                      colored-border
                      type="warning"
                      elevation="2"
                      prominent
                  >
                    <v-row class="pa-4">
                      {{ notAllItemsAreCheckedOutInShipmentsWarningMessage }}
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>

              <inventory-items-from-shipments-check-in-grid
                  ref="inventoryItemsInShipmentsCheckInGrid"
                  :filterConfig="filterConfig"
                  :showSelectionMode="!checkInModeEnabled"
                  @gridRefreshed="onGridRefreshed"
                  @invalidQuantityEntered="setCheckInButtonStateDisabled"
                  @validQuantityEntered="setCheckInButtonStateEnabled"
                  @rowSelected="onRowSelected"
              />
            </v-card>
          </v-col>
        </v-row>

        <v-row v-show="showInventoryItemsGrid">
          <v-col class="d-flex justify-end pr-4">
            <v-btn
                v-if="!checkInModeEnabled"
                large
                color="primary"
                class="checkInButton"
                :disabled="!completeCheckInButtonEnabled || !isAtLeastOneRowSelected"
                :loading="inProgress"
                @click="onCompleteItemCheckInClicked"
            >
              Close Without Check-In
            </v-btn>
            <section-divider></section-divider>
            <v-btn
                v-if="checkInModeEnabled"
                large
                color="primary"
                class="checkInButton"
                :disabled="checkInDisabled"
                :loading="inProgress"
                @click="onCheckInClicked"
            >
              Check-In
            </v-btn>
          </v-col>
        </v-row>

        <generic-warning-dialog
            ref="warningDialog"
            title="Close Without Check-In Confirmation"
            width=800
            :showDialog="showDialog"
            :message="shortShipmentMessage"
            :multiLineError="true"
            :confirmationLabel="confirmationLabel"
            :extraLabel="extraLabel"
            :submitEnabled="submitEnabled"
            cancelLabel="Cancel"
            @confirmed="onConfirmClicked"
            @extraButtonClicked="onCheckInAndLeaveItemOpenClicked"
            @cancelled="onCancelPartialCheckIn"
        />

      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../navigation/NavigationView.vue";
import BranchesAutocomplete from "../../../components/searchFields/BranchesAutocomplete.vue";
import DatePickerWithTypeInTextField from "../../../components/common/form/DatePickerWithTypeInTextField.vue";
import GridToolbar from "../../../components/common/grid/GridToolbar.vue";
import DateFormatter from "../../../assets/dateFormatter";
import BranchTransfers from "../../../rest/inventoryItemsBranchTransfer";
import InventoryItemsFromShipmentsCheckInGrid
  from "../../../components/inventoryItems/branchTransfer/InventoryItemsFromShipmentsCheckInGrid.vue";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import FormattingHelper from "@/assets/formattingHelper";
import SectionDivider from "@/components/jobs/SectionDivider.vue";
import BoxQuantity from "@/assets/boxQuantity";
import Branches from "@/rest/branches";
import SimpleCheckBoxWithLabel from "@/components/common/form/SimpleCheckBoxWithLabel.vue";
import {ShipmentStatuses} from "@/assets/statusIcons/statusIcons";
import ToggleButtonWithTwoStates from "@/components/common/templates/ToggleButtonWithTwoStates.vue";

export default {
  components: {
    ToggleButtonWithTwoStates,
    SectionDivider,
    GenericWarningDialog,
    InventoryItemsFromShipmentsCheckInGrid,
    DatePickerWithTypeInTextField,
    NavigationView,
    BranchesAutocomplete,
    GridToolbar,
    SimpleCheckBoxWithLabel
  },

  data() {
    return {
      checkInModeEnabled: true,
      isAtLeastOneRowSelected: false,
      filterConfig: null,
      inProgress: false,
      showInventoryItemsGrid: false,
      selectedPickupDate: new Date().toISOString(),
      selectedFromBranchId: null,
      selectedToBranchId: null,
      checkInDisabled: true,
      shortShipmentMessage: null,
      showDialog: false,
      confirmationLabel: 'Check-In And Close Item',
      extraLabel: 'Check-In And Leave Item Open',
      completeCheckInButtonEnabled: true,
      isCompleteCheckInOperation: false,
      submitEnabled: true,
      filteredUserBranches: [],
      userBranches: [],
      allBranches: [],
      showOnlyAvailableForCheckInItems: true,
      notAllItemsAreCheckedOutInShipmentsWarningMessage: 'Not all items in the shipment(s) have been checked out. ' +
          'To proceed with check-in, check out these items from the origin branch or close them in Transfer Request with the respective reason.',
      showWarningMessage: false
    };
  },

  created() {
    this.getSpecificUserBranches();
    this.getAlActiveBranches();
  },
  watch: {
    selectedFromBranchId: 'branchesWatcher',
    selectedToBranchId: 'branchesWatcher',
  },

  computed: {
    isDisabled() {
      return !this.selectedFromBranchId || !this.selectedToBranchId || !this.selectedPickupDate;
    }
  },

  methods: {
    async getSpecificUserBranches() {
      try {
        const response = await Branches.getRestApi().getAllActiveBranches();
        this.userBranches = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async getAlActiveBranches() {
      try {
        const response = await Branches.getRestApi().getAllUserBranches();
        this.filteredUserBranches = response.data;
        this.allBranches = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async handleBranchTransferSearch() {
      this.showWarningMessage = false;
      if (this.selectedFromBranchId && this.selectedToBranchId && this.selectedPickupDate) {
        this.showInventoryItemsGrid = true;
        this.buildFilterConfig(this.selectedFromBranchId, this.selectedToBranchId, this.selectedPickupDate, this.showOnlyAvailableForCheckInItems);
      }
    },
    clearGridState() {
      this.$refs.inventoryItemsInShipmentsCheckInGrid.clearGrid();
      this.setCheckInButtonStateDisabled();
    },
    onPickupDateSelected(date) {
      this.selectedPickupDate = date;
    },
    onPickupDateCleared() {
      this.selectedPickupDate = null;
    },
    branchesWatcher() {
     this.setGridDefaultMode();

      if ((this.selectedFromBranchId && this.selectedToBranchId) && (this.selectedFromBranchId !== this.selectedToBranchId)) {
        this.showInventoryItemsGrid = false;
      }

      if (this.selectedToBranchId === this.selectedFromBranchId) {
        this.selectedToBranchId = null;
        return;
      }
      if (this.selectedFromBranchId) {
        this.filteredUserBranches = this.allBranches.filter(b => b.id !== this.selectedFromBranchId);
      }
    },
    async shipFromBranchSelected(branchId) {
      this.selectedFromBranchId = branchId;
    },
    async shipToBranchSelected(branchId) {
      this.selectedToBranchId = branchId;
    },
    buildFilterConfig(fromBranchId, toBranchId, pickupDate, showOnlyAvailableForCheckInItems) {
      this.filterConfig = [
        ["PickupDate", "=", DateFormatter.formatDateInYYYYMMDDFormat(pickupDate)],
        ["ShipFromBranchId", "=", fromBranchId],
        ["ShipToBranchId", "=", toBranchId]
      ];
      if (showOnlyAvailableForCheckInItems) {
        this.filterConfig.push(["ShowOnlyAvailable", "=", true]);
      }
    },
    setCheckInButtonStateDisabled() {
      this.checkInDisabled = true;
    },
    onRowSelected(count) {
      this.isAtLeastOneRowSelected = count > 0;
    },
    setCheckInButtonStateEnabled() {
      this.checkInDisabled = this.getModifiedRows().length <= 0;
    },
    onCheckInClicked() {
      //check if there is a short shipment
      let isShortShipment = this.detectShortShipment();
      if (isShortShipment) {
        this.showDialog = true;
      } else {
        let payload = this.buildPayload(this.getModifiedRows());
        this.performCheckIn(payload, true);
      }
    },
    checkInOperationConfirmed() {
      let payload = this.buildPayload(this.getModifiedRows());
      this.performCheckIn(payload, true);
    },
    getModifiedRows() {
      return this.getGridRows().filter(row => {
        return row?.modified && Number(row.data.Rounded) > 0;
      });
    },
    getGridRows() {
      let gridInstance = this.$refs.inventoryItemsInShipmentsCheckInGrid.getGridInstance();
      return gridInstance.getVisibleRows();
    },
    getSelectedRows() {
      return this.$refs.inventoryItemsInShipmentsCheckInGrid.getSelectedRows();
    },
    onGridRefreshed() {
      this.completeCheckInButtonEnabled = this.enableCompleteCheckInButtonState();
      this.hasFullyCheckedOutShipments();
    },
    enableCompleteCheckInButtonState() {
      return !this.$refs.inventoryItemsInShipmentsCheckInGrid.allRowsClosed(this.getGridRows()) && this.$refs.inventoryItemsInShipmentsCheckInGrid.getRowsAvailableForPartialCheckIn(this.getGridRows()).length > 0;
    },
    buildPayload(modifiedRows) {
      return modifiedRows.map(row => {
        return {
          transferRequestItemId: row.data.TriId,
          transferNumber: row.data.TransferNumber,
          quantity: Number.parseFloat(row.data.Rounded)
        }
      });
    },
    convertRowItemToPayload(row) {
      return {
        transferRequestItemId: row.TriId,
        transferNumber: row.TransferNumber,
        quantity: row.Entered ? BoxQuantity.roundValueToBoxQuantity(row.Entered, row.BoxQuantity) : 0
      }
    },
    async performCheckIn(payload, changeStatusToClosed) {
      try {
        this.inProgress = true;
        await BranchTransfers.getRestApi().checkInBranchTransferInventoryItems(payload, changeStatusToClosed);
        this.onSubmitSuccess();
      } catch (error) {
        this.showNavError(error);
      } finally {
        this.inProgress = false;
      }
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    onSubmitSuccess() {
      this.$refs.nav.showSuccess('Selected inventory items checked in successfully!');
      this.$refs.inventoryItemsInShipmentsCheckInGrid.deselectItems();
      this.setCheckInButtonStateDisabled();
      this.clearGridState();
      this.showDialog = false;
      this.shortShipmentMessage = null;
      this.confirmationLabel = 'Check-In And Close Item';
      this.extraLabel = "Check-In And Leave Item Open";
      this.onGridRefreshed();
    },
    onCancelPartialCheckIn() {
      this.showDialog = false;
      setTimeout(() => {
        this.shortShipmentMessage = null;
        this.confirmationLabel = 'Check-In And Close Item';
        this.extraLabel = "Check-In And Leave Item Open";
      }, 500);
    },
    onConfirmClicked() {
      if (this.isCompleteCheckInOperation) {
        this.completeShipmentCheckInConfirmed();
      } else {
        this.checkInOperationConfirmed();
      }
    },
    detectShortShipment() {
      const rows = this.getModifiedRows();
      let count = 0;
      let severalOccurrences = [];
      rows.forEach(row => {
        if (row.data.Rounded < row.data.ExpectedQuantity && this.isExpectedQuantityLessThanAlreadyReceivedAndEnteredValue(row)) {
          count++;
          severalOccurrences.push(row);
        }
      });

      if (count === 1) {
        this.shortShipmentMessage = `The Quantity Received of ${severalOccurrences[0].data.ProductType} - ${severalOccurrences[0].data.Style} - ${severalOccurrences[0].data.Color} - ${severalOccurrences[0].data.RollNumber || ''} - ${severalOccurrences[0].data.DyeLot || ''}
          from ${severalOccurrences[0].data.TransferNumber} is smaller than the Quantity Expected (${severalOccurrences[0].data.Rounded} received, ${severalOccurrences[0].data.ExpectedQuantity} expected).
          If this product was short-shipped, please select 'Check-In And Close Item'.
          (NOTE: you will not be able to check in more of this product afterward).
          If you plan to check in more later, select 'Check-In And Leave Item Open'.`
        return true;
      } else if (count > 1) {
        let fewItemsMessage = `The Quantity Received of several items you just checked in is smaller than the Quantity Expected (see the details below). If these products were short-shipped, please select 'Check-In And Close Item'
        (NOTE: you will not be able to check in more of those products after that). If you plan to check in more, select 'Check-In And Leave Item Open'.\n`
        let items = severalOccurrences.map(item => {
          return `\n ${FormattingHelper.formatItemOnBranchCheckIn(item)} \n`;
        }).join("\n ");
        this.shortShipmentMessage = fewItemsMessage + items;
        return true;
      } else {
        return false;
      }
    },
    isExpectedQuantityLessThanAlreadyReceivedAndEnteredValue(row) {
      return (row.data.ReceivedQuantity + Number(row.data.Rounded)) < row.data.ExpectedQuantity;
    },
    onCheckInAndLeaveItemOpenClicked() {
      this.shortShipmentMessage = null;
      this.confirmationLabel = 'Check-In And Close Item';
      this.showDialog = false;
      let payload = this.buildPayload(this.getModifiedRows());
      this.performCheckIn(payload, false);
    },
    completeShipmentCheckInConfirmed() {
      let payload = this.$refs.inventoryItemsInShipmentsCheckInGrid.getOnlyAvailableForCheckInSelectedRows(true);
      let payloadForEnteredValues = [];
      let payloadForZeroQuantitiesEntered = [];
      payload.forEach(row => {
        let formattedRow = this.convertRowItemToPayload(row);
        if (row.Entered) {
          payloadForEnteredValues.push(formattedRow);
        } else {
          payloadForZeroQuantitiesEntered.push(formattedRow);
        }
      })
      if (payloadForEnteredValues.length > 0) {
        this.performCheckIn(payloadForEnteredValues, true);
      } else if (payloadForZeroQuantitiesEntered.length > 0) {
        this.performCheckIn(payloadForZeroQuantitiesEntered, true);
      }
    },
    onCompleteItemCheckInClicked() {
      this.populateCompleteItemCheckInModal();
    },
    populateCompleteItemCheckInModal() {
      this.extraLabel = null;
      this.confirmationLabel = 'Confirm';
      let hasSelectedRows = this.getSelectedRows().length > 0;
      this.shortShipmentMessage = hasSelectedRows
          ? 'Are you sure you want to close the selected item(s) without checking it in? Once you proceed, no further check-ins will be allowed.'
          : 'To proceed with partial check-in please select at least one row';
      this.submitEnabled = hasSelectedRows;
      this.showDialog = true;
      this.isCompleteCheckInOperation = true;
    },
    hasFullyCheckedOutShipments() {
      // !this.showOnlyAvailableForCheckInItems means that it could have any shipment that is not in progress, otherwise it would be filtered by the View
      if (this.filterConfig && !this.showOnlyAvailableForCheckInItems) {
        this.defineShowWarningState(this.getGridRows());
      }
    },
    defineShowWarningState(visibleRows) {
      let shipmentMap = new Map();

      visibleRows.forEach(row => {
        let shipmentNumber = row.data.ShipmentNumber;
        if (!shipmentMap.has(shipmentNumber)) {
          shipmentMap.set(shipmentNumber, []);
        }
        shipmentMap.get(shipmentNumber).push(row);
      });

      let showWarning = false;
      shipmentMap.forEach((rows) => {
        if (rows.some(row => row.data.ShipmentStatus !== ShipmentStatuses.IN_PROGRESS.name && row.data.ShipmentStatus !== ShipmentStatuses.COMPLETED.name)) {
          showWarning = true;
        }
      });

      this.showWarningMessage = showWarning;
    },
    onGridStateChange() {
      this.checkInModeEnabled = !this.checkInModeEnabled;
    },
    setGridDefaultMode() {
      if (!this.checkInModeEnabled) {
        this.checkInModeEnabled = true;
      }
    }
  }
};
</script>

<style scoped>
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}
</style>
