<template>
  <v-row>
    <v-col md="6">
      <v-text-field
          v-if="readonly || disabled"
          :readonly="readonly"
          :disabled="disabled"
          placeholder=" "
          persistent-placeholder
          :value="textValue"
          label="Rooms"
          data-id="lead-rooms-text-value"
      />
      <v-autocomplete
          v-if="!readonly && !disabled"
          v-model="selected"
          :items="rooms"
          :search-input.sync="searchInput"
          @input="searchInput=null"
          :loading="loading"
          :menu-props="menuProps"
          hide-no-data
          label="* Rooms"
          :rules="[rules.required]"
          item-text="name"
          item-value="id"
          :multiple="true"
          :clearable="true"
          :placeholder="placeholder"
          persistent-placeholder
          data-id="lead-rooms-search-field"
          dense
          attach
      >
        <template v-slot:prepend-item class="select-all" v-if="selectAllEnabled">
          <select-all-autocomplete
              :items="rooms"
              :selected="selected"
              @select="selectAll"
              @deselect="deselectAll"
          >
          </select-all-autocomplete>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col md="6">
      <v-text-field
          v-if="showRoomCount"
          v-model="leadsRoomCount"
          ref="leadRoomsCount"
          label="Rooms count"
          persistent-placeholder
          readonly
      />
    </v-col>
  </v-row>
</template>

<script>
import SelectAllAutocomplete
  from "../../components/searchFields/selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";
import LeadRoomTypes from "../../rest/leadRoomTypes";

export default {

  components: {
    SelectAllAutocomplete
  },

  data() {
    return {
      selected: [],
      leadsRoomCount: 0,
      rooms: [],
      loading: false,
      menuProps: {
        maxHeight: 250
      },
      rules: {
        required: value => {
          if (!value || this.selected.length < 1) {
            return "Rooms are required"
          } else {
            return true
          }
        }
      },
      searchInput: null
    };
  },

  props: {
    value: Array,
    showRoomCount: {
      type: Boolean,
      default: false
    },
    readonly: Boolean,
    placeholder: {
      type: String,
      default: 'Search Rooms'
    },
    disabled: Boolean,
    selectAllEnabled: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    value(value) {
      this.selected = value;
      this.setLeadsRoomCount();
    },
    selected(value) {
      this.setLeadsRoomCount();
      this.$emit('input', value);
    }
  },

  computed: {
    textValue() {
      return (this.selected || []).map(item => item.name).join(", ");
    }
  },

  created() {
    this.getLeadRooms();
  },

  methods: {
    async getLeadRooms() {
      try {
        const response = await LeadRoomTypes.getRestApi().getAllLeadRoomTypes();
        this.rooms = response.data;
      } catch (error) {
        this.$emit('showError', error);
      }
    },
    setSelected(selected) {
      this.selected = selected;
    },
    getSelected() {
      return this.selected;
    },
    setLeadsRoomCount() {
      const flatSelected = this.getFlatSelected();
      let capacity = 0;
      this.rooms.forEach(room => {
        if (flatSelected.includes(room.id)) {
          capacity += room.capacity;
        }
      });
      this.leadsRoomCount = capacity;
    },
    getFlatSelected() {
      return (this.selected || []).map(item => {
        if (typeof item === "object") {
          return item.id;
        }
        return item;
      });
    },
    selectAll() {
      this.selected = this.rooms.map(item => item.id);
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
