/**
 * REST interface provided for Contract Price Changes.
 */
import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {Job} from "./jobs";
import * as AxiosUtils from "./util/axiosUtils";

/**
 * Job Contract Price Create Request
 */
export interface ContractPriceChangeRequest {
    amount?: number;
    note?: string;
}

/**
 * Job Contract Price Change
 */
export class ContractPriceChange {
    id: number;
    createdAt: string;
    updatedAt: string;
    jobId: number;
    userId: number;
    amount: number;
    oldContractPrice: number;
    newContractPrice: number;
    note: string;

    constructor(
        id: number,
        createdAt: string,
        updatedAt: string,
        jobId: number,
        userId: number,
        amount: number,
        oldContractPrice: number,
        newContractPrice: number,
        note: string
    ) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.jobId = jobId;
        this.userId = userId;
        this.amount = amount;
        this.oldContractPrice = oldContractPrice;
        this.newContractPrice = newContractPrice;
        this.note = note;
    }
}

export interface ContractPriceRestApi {
    updateContractPrice(id: string, request: ContractPriceChangeRequest, wsSessionId: string): AxiosPromise<Job>;
}

/**
 * REST implementation provided for ContractPrice.
 */
class Implementation implements ContractPriceRestApi {
    updateContractPrice(id: string, request: ContractPriceChangeRequest, wsSessionId: string): AxiosPromise<Job> {
        const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
        return Axios.axiosInstance().put<Job>(`/contract-price/${id}`, request, config);
    }
}

const ContractPrice = {
    getRestApi(): ContractPriceRestApi {
        return new Implementation();
    },
};

export default ContractPrice;
