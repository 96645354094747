import Constants from "../constants";

const JobBuilderHelper = {
    getWizardTitle(job) {
        let title = "Job Builder";
        if (job.invoiced) {
            title += " [Invoiced]";
        } else if (job.inFulfillment) {
            title += " [In Fulfillment]";
        }
        return title;
    },

    defineButtonState(job, haveAllItemsBeenFulfilled, changesDetected, productItems) {
        let result;
        if (job.inFulfillment && haveAllItemsBeenFulfilled && !(job.invoiced || changesDetected)) {
            result = Constants.jobButtonLabels.INVOICE;
        } else if (!(job.invoiced || changesDetected)
            && ((productItems.length > 0 && !haveAllItemsBeenFulfilled) || (productItems.length === 0 && !job.inFulfillment))) {
            result = Constants.jobButtonLabels.FULFILMENT;
        } else {
            result = Constants.jobButtonLabels.SAVE_UPDATES;
        }
        return result;
    },
    defineButtonActive(job, changesDetected) {
        return !job.invoiced || changesDetected;
    },

    areAllItemsFulfilled(items) {
        return items.every(i => i.jobLineItem.fulfillmentDate);
    }

};
export default JobBuilderHelper;
