<template>
  <modal-dialog
      ref="dialog"
      title="Add time in the secondary branch"
      width="500"
      :visible="dialogVisible"
      submitLabel="Save"
      cancelLabel="Cancel"
      :submitEnabled="true"
      :loading="loading"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
  >
    <crew-time-in-secondary-branch-form
        ref="form"
        :crews="activeCrewsWithSecondaryBranch"
        :installerRefId="installerRefId"
    />
    <error-modal
        ref="errorModal"
        :error="errorMessage"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../../components/common/ModalDialog.vue";
import ErrorModal from "../../../../components/navigation/ErrorModal.vue";
import CrewTimeInSecondaryBranchForm from "./CrewTimeInSecondaryBranchForm.vue";
import InstallerCrewSecondaryBranchTimes from "../../../../rest/installer/installerCrewSecondaryBranchTimes";

export default {
  components: {
    ErrorModal,
    ModalDialog,
    CrewTimeInSecondaryBranchForm
  },

  data() {
    return {
      errorMessage: null,
      loading: false,
      isErrorModalOpen: false,
      dialogVisible: false
    };
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    installerRefId: {
      type: String,
      required: true
    }
  },

  computed: {
    activeCrewsWithSecondaryBranch() {
      return this.crews.filter(crew => crew.active && crew.secondaryBranch);
    }
  },

  methods: {
    reset() {
      this.resetForm();
      this.resetValidation();
    },
    resetValidation() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetForm();
      }
    },
    openDialog() {
      this.dialogVisible = true;
      this.stopLoading();
    },
    closeDialog() {
      this.stopLoading();
      this.dialogVisible = false;
      this.reset();
    },
    showError(error) {
      this.errorMessage = error.response.data.message;
      this.isErrorModalOpen = true;
    },
    async onSubmitClicked() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          const payload = this.$refs.form.buildPayload();
          InstallerCrewSecondaryBranchTimes.getRestApi().createTimeInSecondaryBranch(payload)
              .then(() => {
                this.$emit('saveButtonClicked');
                this.closeDialog();
              }).catch((error) => {
            this.stopLoading();
            this.showError(error);
          });
        }
      } catch (error) {
        this.$emit('submitFailed', error);
      }
    },
    onCancelClicked() {
      this.closeDialog();
    },
    stopLoading() {
      this.loading = false;
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.errorMessage = null;
    },
  }
};
</script>
