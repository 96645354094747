<template>
  <v-tooltip v-if="showToolTip" left>
    <template v-slot:activator="{ on, attrs }">
      <div v-on="isDisabled ? on : ''">
        <v-btn
            v-if="showButton"
            class="ma-2"
            color="primary"
            :disabled="isDisabled"
            v-bind="attrs"
            @click="buttonClicked"
        >
          {{ buttonText }}
        </v-btn>
      </div>
    </template>
    <span>{{ toolTipMessage }}</span>
  </v-tooltip>
</template>

<script>

export default {
  components: {},

  props: {
    isDisabled: {
      type: Boolean,
      required: true,
    },
    showButton: {
      type: Boolean,
      required: true,
    },
    showToolTip: {
      type: Boolean,
      required: false,
      default: true
    },
    toolTipMessage: {
      type: String,
      required: false,
      default: ""
    },
    buttonText: {
      type: String,
      required: true
    }
  },

  data() {
    return {};
  },

  methods: {
    buttonClicked() {
      this.$emit('buttonClicked');
    }
  }

}
</script>
<style scoped>

</style>
