<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-row class="mt-4">
        <v-col md="12">
          <v-text-field
              v-model="subtypeName"
              label="* Name"
              placeholder=" "
              counter="100"
              data-id="subtype-name"
              :rules="[rules.required, rules.max100]"
              persistent-placeholder
          />
        </v-col>
        <v-col md="12">
          <v-textarea
              v-model="subtypeDescription"
              placeholder=" "
              label="Description (optional)"
              data-id="subtype-description"
              counter="100"
              :rules="[rules.max100]"
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "@/assets/rules";

const formDefaults = {
  subtypeName: "",
  subtypeDescription: ""
};

export default {
  components: {},

  props: {
    productType: Object
  },

  data() {
    return Object.assign({}, formDefaults, {
      rules: {
        required: Rules.required,
        max100: value => {
          return Rules.maxValue(value, 100);
        },
      },
      dataToChange: null
    });
  },

  methods: {
    buildSubtypeRequestPayload() {
      let valid = this.validate();
      if (valid) {
        return {
          name: this.subtypeName,
          description: this.subtypeDescription,
          parentId: this.$props.productType.id
        };
      }
    },
    reset() {
      Object.assign(this.$data, formDefaults);
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    passDataToChange(data) {
      if (data) {
        this.subtypeName = data.name;
        this.subtypeDescription = data.description;
      }
    }
  }
};
</script>
