import * as StompJs from "@stomp/stompjs";
import ReferenceIds from "@/assets/referenceIds";

const WebSockets = {
    createStompJsClient(onConnect) {
        let stompJsClient = new StompJs.Client({
            brokerURL: this.getWebSocketBaseUrl(),
            heartbeatIncoming: 15000,
            heartbeatOutgoing: 15000,
            onConnect: onConnect,
            onStompError: () => {
                console.log(new Date().toTimeString(), " Stomp error.");
            }
        });
        let webSocketDebug = config.VUE_APP_WEBSOCKET_DEBUG || process.env.VUE_APP_WEBSOCKET_DEBUG;
        if (webSocketDebug === 'true') {
            stompJsClient.debug = (str) => {
                console.log("Websocket DEBUG: ", new Date().toTimeString(), str);
            };
            stompJsClient.onDisconnect = () => {
                console.log(new Date().toTimeString(), " Websocket disconnected.");
            };
            stompJsClient.onWebSocketClose = () => {
                console.log(new Date().toTimeString(), " Websocket closed.");
            };
        }
        stompJsClient.activate();
        return stompJsClient;
    },

    /** Get base URL for connecting to websocket */
    getWebSocketBaseUrl() {
        let websocketUrl = config.VUE_APP_WEBSOCKET_URL || process.env.VUE_APP_WEBSOCKET_URL;
        if (websocketUrl === undefined) {
            websocketUrl = "wss://" + window.location.host + "/ws";
        }
        return websocketUrl;
    },


    /** Get relative URL for topics on STOMP broker */
    getWebSocketTopicRelativeUrl() {
        return "/topic";
    },

    /** Get relative URL for job model updates */
    getWebSocketJobsTopic() {
        return this.getWebSocketTopicRelativeUrl() + "/model/jobs";
    },

    getWebSocketInstallerCalendarTopic() {
        return this.getWebSocketTopicRelativeUrl() + "/installer-calendar";
    },

    /** Get relative URL for system updates */
    getWebSocketSystemTopic() {
        return this.getWebSocketTopicRelativeUrl() + "/system";
    },

    /** Get relative URL for Sage Intacct topics */
    getWebSocketIntacctUrl() {
        return this.getWebSocketTopicRelativeUrl() + "/intacct";
    },

    /** Get relative URL for Sage Intacct sales rep topics */
    getWebSocketSalesRepTopic() {
        return this.getWebSocketIntacctUrl() + "/salesrep";
    },

    getWebSocketEventNotificationTopic() {
        return this.getWebSocketSystemTopic() + "/custom-event-notification";
    },

    generateWebSocketSessionId() {
        return ReferenceIds.getRandomString(8);
    },
}

export default WebSockets;
