<template>
  <modal-dialog
      ref="dialog"
      :title="modalTitle"
      width="800"
      submitLabel=" "
      cancelLabel="Close"
      :visible="dialogVisible"
      :submitEnabled="true"
      :hideSubmit="true"
      scrollable
      @cancelClicked="closeDialog"
  >
    <remove-slot-dialog
        ref="removeSlotDialog"
        @deleteConfirmed="onDeleteConfirmed"
        @emitError="showError"
    />
    <error-modal
        ref="errorModal"
        :error="error"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
    <div class="add-slot-dialog-content">
      <edit-slot-grid
          ref="edisSlotsGrid"
          :slots="slots"
          :date="date"
          @deleteCLicked="onDeleteClicked"
      />
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import InlineGrid from "../../../components/common/grid/InlineGrid.vue";
import RemoveSlotDialog from "./RemoveSlotDialog.vue";
import InstallerSlots from "../../../rest/installer/installerSlots";
import ErrorModal from "../../../components/navigation/ErrorModal.vue";
import DateFormatter from "../../../assets/dateFormatter";
import EditSlotGrid from "../grids/EditSlotGrid.vue";

export default {
  components: {
    EditSlotGrid,
    ErrorModal,
    RemoveSlotDialog,
    InlineGrid,
    ModalDialog,
  },

  props: {
    branchId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dialogVisible: false,
      date: null,
      capabilityId: null,
      capabilityName: null,
      slots: null,
      isErrorModalOpen: false,
      error: null
    };
  },

  computed: {
    modalTitle() {
      return this.capabilityName + ' ' + DateFormatter.formatDateInMMDDYYYYFormat(this.date);
    },
  },

  methods: {
    async getSlotInfo() {
      const formattedSlotDate = DateFormatter.formatDateInYYYYMMDDFormat(this.date);
      await InstallerSlots.getRestApi().getSlotsByDateAndCapabilityId(this.branchId, formattedSlotDate, this.capabilityId)
          .then((response) => {
            this.slots = response.data;
          })
          .catch((error) => {
            this.showError(error.response.data.message);
          });
    },
    reset() {
      this.date = null;
      this.capabilityId = null;
      this.capabilityName = null;
      this.slots = null;
    },
    onDeleteConfirmed() {
      this.getSlotInfo();
      this.$emit('slotDeleted');
    },
    async openDialog(date, capabilityName, capabilityId) {
      this.reset();
      this.date = date;
      this.capabilityName = capabilityName;
      this.capabilityId = capabilityId;
      await this.getSlotInfo();
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.$refs.edisSlotsGrid.resetGridState();
      this.reset();
    },
    onDeleteClicked(cellData) {
      this.$refs.removeSlotDialog.openDialog(cellData);
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    showError(error) {
      this.isErrorModalOpen = true;
      this.error = error;
    },
    refresh() {
      if (this.dialogVisible) {
        this.getSlotInfo();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.add-slot-dialog-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .add-slot-form {
    width: 100%;
  }
}

</style>
