<template>
  <modal-dialog
      title="Note"
      width="40%"
      :hideSubmit="true"
      :visible="dialogVisible"
      @cancelClicked="onCancelClicked"
  >
    <v-container grid-list-lg>
      <v-row class="mt-4">
        <v-col sm="6">
          <v-text-field v-model="createdAt" label="Created At" readonly/>
        </v-col>
        <v-col sm="6">
          <v-text-field
              v-model="userName"
              label="User"
              placeholder=" "
              readonly
          />
        </v-col>
        <v-col sm="12">
          <v-textarea v-model="note" rows="1" auto-grow label="Note" readonly/>
        </v-col>
        <v-col sm="12">
          <v-text-field v-model="origin" rows="1" label="Origin" readonly/>
        </v-col>
      </v-row>
    </v-container>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import DateFormatter from "@/assets/dateFormatter";

const formDefaults = {
  note: null,
  createdAt: null,
  userName: null,
  dialogVisible: false,
  origin: null
};
export default {
  components: {ModalDialog},

  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    openDialog(data) {
      if (data) {
        this.dialogVisible = true;
        this.note = data.note;
        this.createdAt = DateFormatter.formatDateInMMDDYYYYFormat(data.createdAt);
        this.userName = data.userName;
        this.origin = data.origin ? data.origin : " ";
      }
    },
    closeDialog() {
      this.dialogVisible = false;
      this.note = null;
    },
    onCancelClicked() {
      this.closeDialog();
      this.$emit("onCancelClicked");
    }
  }
};
</script>
