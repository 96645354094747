<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card class="stepper-card pt-1" height="250">
        <v-row>
          <v-col sm="6">
            <date-picker-with-type-in-text-field
                ref="pickupDatePicker"
                v-model="pickupDate"
                label="* Pickup Date"
                id="pickup-date-picker"
                :optional="false"
                validation-name="Pickup Date"
                :readonly="false"
                :allowed-dates="allowedDates"
                @input="onPickupDateSelected"
            />
          </v-col>
          <v-col sm="6">
            <date-picker-with-type-in-text-field
                ref="estimatedDeliveryDatePicker"
                v-model="estimatedDeliveryDate"
                label="* Estimated Delivery Date"
                id="estimated-delivery-date-picker"
                :optional="false"
                validation-name="Estimated Delivery Date"
                :readonly="false"
                :allowed-dates="allowedDates"
                @input="onEstimatedDeliveryDate"
            />
          </v-col>
        </v-row>
      </v-card>
      <v-divider/>
    </v-form>
  </v-container>
</template>

<script>
import BranchesAutocomplete from "../../searchFields/BranchesAutocomplete.vue";
import DatePickerWithTypeInTextField from "../../common/form/DatePickerWithTypeInTextField.vue";
import DatePickerHelper from "../../../assets/datePickerHelper";

export default {
  components: {
    DatePickerWithTypeInTextField,
    BranchesAutocomplete
  },

  props: {
    shipment: {
      type: Object,
      required: false
    },
  },

  data() {
    return {
      pickupDate: null,
      estimatedDeliveryDate: null
    }
  },

  watch: {
    pickupDate: 'datesWatcher',
    estimatedDeliveryDate: 'datesWatcher',
    shipment: {
      immediate: true,
      handler(value) {
        this.pickupDate = value.pickUpDate
        this.estimatedDeliveryDate = value.estimatedDeliveryDate
      }
    },
  },

  methods: {
    resetForm(shipment) {
      this.loadFormDefaults(shipment);
      this.$refs.form.resetValidation();
    },
    onPickupDateSelected(value) {
      this.pickupDate = value;
    },
    onEstimatedDeliveryDate(value) {
      this.estimatedDeliveryDate = value;
    },
    datesWatcher() {
      let allDatesSelected = this.estimatedDeliveryDate !== null && this.pickupDate !== null;
      if (allDatesSelected) {
        if (this.estimatedDeliveryDate < this.pickupDate) {
          this.$emit('estimatedDeliveryIsEarlierThanPickUpDate');
        } else {
          this.$emit('datesSelected');
        }
      } else {
        this.$emit('partiallySelected');
      }
    },
    allowedDates(val) {
      return DatePickerHelper.allowedDates(val);
    },
    loadFormDefaults(shipment) {
      if (shipment) {
        this.estimatedDeliveryDate = shipment.estimatedDeliveryDate;
        this.pickupDate = shipment.pickUpDate;
      }
    },
    hasDateError() {
      return this.$refs.estimatedDeliveryDatePicker.hasClearedDate || this.$refs.pickupDatePicker.hasClearedDate;
    }
  }
};
</script>

<style scoped>
.content-row {
  min-height: 290px;
}

.stepper-card {
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.error-message {
  color: red;
  padding-right: 15px;
  height: 25px;
  font-size: 15px;
}
</style>
