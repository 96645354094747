import axios, {AxiosInstance} from "axios";
import store from "../store";
import Router from "@/router";
import {checkVersion, getLocalServerHash} from "@/rest/version";
import Auth from "@/rest/auth";
import {getFrontendVersion} from "./version";
import {AuthHelper} from "../assets/authHelper";
import Constants from "../assets/constants";

declare const config: any; //So TS compiler won't complain about this JS defined var used below

interface ErrorWithStatus extends Error {
    status?: number;
}

const Instance = {
    axiosInstance(timeout?: number, baseUrl?: string): AxiosInstance {
        const instance = axios.create({
            baseURL: baseUrl || this.getBaseUrl(),
            timeout: timeout || Constants.AXIOS_REQUEST_TIMEOUT,
            withCredentials: true
        });
        instance.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                const originalRequest = error.config;
                if (error.response === undefined) {
                    return Promise.reject(new Error("Request timeout exceeded. IMPORTANT: Request is being processed. " +
                        "You will receive a notice of an update when it’s done. Please don’t refresh your browser window"));
                }

                if (error.response.status === 401 && originalRequest.url.includes('/authenticate')) {
                    return Promise.reject(error);
                }

                if (error.response.status === 401 && originalRequest.url.includes('/refreshtoken')) {
                    store.commit("logout", null);
                    Router.push('/login');
                    return Promise.reject(error);
                }

                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const user = store.state.loggedInUser;
                    return await Auth.updateTokens(user)
                        .then(() => {
                            return axios(originalRequest);
                        });
                }
                if (error.response && error.response.status === 401 && originalRequest._retry) {
                    store.commit("logout", "tokenExpireLogout");
                    Router.push("/");
                }
                if (error.response && error.response.status === 403) {
                    const errorWithStatus = new Error(error) as ErrorWithStatus;
                    errorWithStatus.status = 403;
                    throw errorWithStatus;
                }
                if (error.response && error.response.status === 426) {
                    checkVersion();
                }
                return Promise.reject(error);
            }
        );

        instance.interceptors.request.use(config => {
            if (AuthHelper.isSafariBrowser()) {
                this.setSafariCookies();
            }
            config.headers = Object.assign(config.headers, Instance.setDefaultHeaders());
            return config;
        }, error => {
            return Promise.reject(error);
        });
        return instance;
    },

    nonJwtAxiosInstance(timeout?: number, baseUrl?: string): AxiosInstance {
        const instance = axios.create({
            baseURL: baseUrl || this.getBaseUrl(),
            timeout: timeout || Constants.AXIOS_REQUEST_TIMEOUT,
            headers: {"Content-Type": "application/json"}
        });
        instance.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        return instance;
    },

    setDefaultHeaders() {
        const headers: { [key: string]: string } = {
            "Content-Type": "application/json",
            "Device-Os": "web"
        };
        headers["frontend-version"] = getFrontendVersion();
        const version = getLocalServerHash();
        if (version) {
            headers["fifty-floor-server-hash"] = version;
        }
        return headers;
    },

    /** Base base web app URL */
    getBaseUrl(): string | undefined {
        return config.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API;
    },

    setSafariCookies() {
        const user = store.state.loggedInUser;
        const userKey = `user_${user?.email}`;
        const accessToken = sessionStorage.getItem(`${userKey}_accessToken`);
        const refreshToken = sessionStorage.getItem(`${userKey}_refreshToken`);
        const deviceId = store.getters.getDeviceId;

        if (accessToken) {
            document.cookie = `accessToken=${accessToken}`;
        }
        if (refreshToken) {
            document.cookie = `refreshToken=${refreshToken}`;
        }
        if (deviceId) {
            document.cookie = `Device-Id=${deviceId}`;
        }
    }
};

export default Instance;
