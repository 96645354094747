<template>
  <v-card class="mb-3">
    <v-app-bar dense dark color="primary">
      <v-app-bar-title class="subheading">
        Fulfill from Inventory
      </v-app-bar-title>
    </v-app-bar>
    <inventory-headers class="pl-4 pr-4 pt-1"/>
    <v-expansion-panels multiple :value="expanded">
      <v-virtual-scroll v-if="plan.inventoryCheckOutPlans.length"
                        :items="plan.inventoryCheckOutPlans"
                        :item-height="itemHeight"
                        :height="getTableHeight()"
                        :bench="10">
          <template v-slot:default="{ item }">
            <inventory-item
                :line-item="item.lineItem"
                :inventory-items="item.inventoryItems"
            />
          <v-card class="separated">
            <inventory-matched-items
                class="pl-5 pr-5"
                v-for="inventoryItem in item.inventoryItems"
                :key="inventoryItem.id"
                :inventoryItem="inventoryItem"
            />
          </v-card>
          </template>
      </v-virtual-scroll>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import InventoryItem from "@/components/jobs/planFulfillment/InventoryItem.vue";
import InventoryHeaders from "@/components/jobs/planFulfillment/InventoryHeaders.vue";
import InventoryMatchedItems from "@/components/jobs/planFulfillment/InventoryMatchedItems.vue";
import Constants from "@/assets/constants";

export default {
  components: {
    InventoryItem,
    InventoryHeaders,
    InventoryMatchedItems
  },

  props: {
    plan: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      expanded: Constants.expandedByDefault,
      itemHeight: 70,
      tableHeight: 250
    };
  },

  methods: {
    getTableHeight(){
      const height = this.plan.inventoryCheckOutPlans.length * this.itemHeight;
      return height > this.tableHeight ? this.tableHeight : height;
    }
  }
};
</script>

<style scoped>
.separated {
  border-top: 1px solid #eee;
}
</style>
