import { render, staticRenderFns } from "./CrewDropdownItem.vue?vue&type=template&id=96598cc6&scoped=true"
import script from "./CrewDropdownItem.vue?vue&type=script&lang=js"
export * from "./CrewDropdownItem.vue?vue&type=script&lang=js"
import style0 from "./CrewDropdownItem.vue?vue&type=style&index=0&id=96598cc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96598cc6",
  null
  
)

export default component.exports