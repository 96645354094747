<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td class="text-truncate">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <!-- Misc type -->
        <td class="misc-type">
          <job-misc-item-types-select
            :disabled="disableEditing || !lineItem.jobLineItem.newLineItem"
            v-model="itemTypeId"
            :miscItemTypes="miscItemTypes"
            class="short-input left-aligned"
          />
        </td>
        <!-- Description -->
        <td class="misc-item-description">
          <v-text-field
            :disabled="disableEditing"
            v-model="comment"
            class="short-input left-aligned"
            placeholder=" "
            maxlength="180"
          />
        </td>
        <!-- Amount -->
        <td class="amount">
          <v-text-field
            :disabled="disableEditing"
            v-model="price"
            class="short-input"
            @blur="setSanitizedPrice"
            prefix="$"
          />
        </td>
        <td class="needs-review">
          <v-tooltip v-if="showNeedsReviewIcon" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
              >
                error_outline
              </v-icon>
            </template>
            <span>Item was not found in the catalog when the job was sent from the sales app - please review</span>
          </v-tooltip>
        </td>
        <!-- delete Icon -->
        <td class="delete-icon">
          <v-btn
            small
            dark
            icon
            class="short ma-0 pa-0"
            v-if="canDeleteItems"
            @click="onDeleteItem(lineItem.jobLineItem.referenceId, lineItem.jobLineItem.itemTypeId)"
          >
            <v-icon class="short" color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
    <remove-needs-review-misc-item-dialog
        ref="removeNeedsReviewItemDialog"
        :miscItemReferenceId="miscItemReferenceId"
        @deleteCancelled="miscItemDeletionCancelled"
        @deleteConfirmed="miscItemDeletionConfirmed"
        >
    </remove-needs-review-misc-item-dialog>
  </v-card>
</template>

<script>
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";
import JobMiscItemTypesSelect from "@/components/searchFields/JobMiscItemTypes.vue";
import {isGenerated} from "@/assets/referenceIds";
import RemoveNeedsReviewMiscItemDialog from "@/components/jobs/miscLineItems/RemoveNeedsReviewMiscItemDialog";
import Constants from "@/assets/constants.js";

export default {
  components: {RemoveNeedsReviewMiscItemDialog, JobMiscItemTypesSelect },

  props: {
    job: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true,
    },
    miscItemTypes:{
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      updatedLineItem: null,
      referenceId: null,
      type: null,
      quantity: null,
      price: null,
      comment: null,
      itemTypeId: null,
      miscItemReferenceId: null
    };
  },

  computed: {
    jobId: function () {
      return this.job.id;
    },
    canDeleteItems() {
      return !this.job.invoiced;
    },
    disableEditing() {
      return this.job.invoiced
          || (this.job.source !== null && this.lineItem.jobLineItem.itemTypeId === Constants.miscItemNeedsReviewId);
    },
    showNeedsReviewIcon() {
      return this.job.source !== null && this.lineItem.jobLineItem.itemTypeId === Constants.miscItemNeedsReviewId;
    }
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.updatedLineItem = updated;
        this.referenceId = updated.jobLineItem.referenceId;
        this.type = updated.jobLineItem.type;
        this.status = null;
        this.comment = updated.jobLineItem.comment;
        this.quantity = null;
        this.price = CurrencyFormatter.formatCurrency(updated.jobLineItem.price);
        this.itemTypeId = updated.jobLineItem.itemTypeId;
      },
    },
    price(value) {
      if (value === this.lineItem.jobLineItem.price) {
        return;
      }
      this.lineItem.jobLineItem.price = CurrencyFormatter.asCurrency(value);
      this.$emit("priceUpdated", {
        item: this.lineItem,
        updated: value,
      });
    },
    comment: function (value) {
      if (value === this.lineItem.jobLineItem.comment) {
        return;
      }
      this.lineItem.jobLineItem.comment = value;
      this.$emit("commentUpdated", {
        item: this.lineItem,
        updated: value,
      });
    },
    itemTypeId: function (value) {
      this.lineItem.jobLineItem.itemTypeId = value;
    },
  },

  methods: {
    emitError: function (error) {
      this.$emit("error", error);
    },
    setSanitizedPrice() {
      this.price = CurrencyFormatter.sanitizePrice(this.price);
    },
    onDeleteItem: function (referenceId, typeId) {
      if (typeId !== Constants.miscItemNeedsReviewId) {
        this.$emit("itemDeleted", referenceId);
      } else {
        this.showDeletingConfirmation(referenceId);
      }
    },
    showDeletingConfirmation(referenceId) {
      this.$data.miscItemReferenceId = referenceId;
    },
    miscItemDeletionConfirmed(referenceId) {
      this.$emit("itemDeleted", referenceId);
    },
    miscItemDeletionCancelled() {
      this.$data.miscItemReferenceId = null;
    },
    isGenerated
  },
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
.text-truncate {
  width: 91px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}
.item-row td.top-aligned {
  vertical-align: top;
}
.delete-icon {
  text-align: right;
  width: 30px
}
.misc-item-description {
  width: 250px;
  font-weight: normal;
}
.needs-review {
  width: 30px;
  text-align: right;
}
.amount {
  width: 100px;
  font-weight: normal;
}
.misc-type {
  width: 196px;
}
</style>
