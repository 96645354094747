<template>
  <v-container>
    <v-form ref="form" class="pt-6">
      <v-row>
        <v-col md="6">
          <v-autocomplete
              v-model="selectedCrewId"
              :readonly="isEditForm"
              :items="crews"
              item-text="name"
              item-value="id"
              :label="isEditForm ? 'Installer Crew' : '* Installer Crew'"
              :rules="[rules.required]"
              placeholder="Start typing to search"
              no-data-text="No active crews with secondary branch"
              persistent-placeholder
              @input="clearSelectedDates"
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              readonly
              :value="secondaryBranchName"
              label="Secondary Branch"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col md="6">
          <date-picker-with-type-in-text-field
              ref="startDate"
              id="time-period-start-date"
              v-model="startDate"
              :disabled="!isStartDateEditable"
              :optional="false"
              :label="!startDate || isStartDateEditable ? '* Start Date' : 'Start Date'"
              :validation-name="'Start Date'"
              :allowed-dates="allowedStartDates"
              @input="onStartDateChanged"
          />
        </v-col>
        <v-col md="6">
          <date-picker-with-type-in-text-field
              id="time-period-end-date"
              ref="endDate"
              v-model="endDate"
              :optional="false"
              :label="!endDate || isEditable ? '* End Date' : 'End Date'"
              :validation-name="'End Date'"
              :disabled="!isEndDateEditable"
              :allowed-dates="allowedEndDates"
          />
        </v-col>
      </v-row>
      <v-row v-show="isEditable && hasNonAllowedDatesToSelect">
        <div class="text-center ml-8 mr-2 mb-8">
          <span> Slots will not be created for days off and non-working days </span>
        </div>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-textarea
              :disabled="!isEditable"
              auto-grow
              :rows="3"
              name="description"
              label="Comments"
              v-model="comment"
              placeholder=" "
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ModalDialog from "../../../../components/common/ModalDialog.vue";
import Rules from "../../../../assets/rules";
import DatePickerWithTypeInTextField from "../../../../components/common/form/DatePickerWithTypeInTextField.vue";
import DateUtils from "../../../../assets/utils/dateUtils";
import InstallerCrewDayOff from "../../../../rest/installer/installerCrewDayOff";
import GenericWarningDialog from "@/components/common/dialogContainers/GenericWarningDialog.vue";
import InstallerCrews from "@/rest/installersCrew";
import DateFormatter from "@/assets/dateFormatter";

const defaultForm = {
  id: null,
  selectedCrewId: null,
  startDate: null,
  endDate: null,
  comment: null,
  selectedCrewDaysOffs: null,
  selectedCrewWorkingDays: null,
};

export default {
  components: {
    GenericWarningDialog,
    DatePickerWithTypeInTextField,
    ModalDialog
  },

  data() {
    return {
      ...defaultForm,
      rules: {
        required: value => {
          return Rules.requiredWithFieldName(value, 'Installer Crew');
        }
      }
    };
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    isEditForm: {
      type: Boolean,
      default: false
    },
    secondaryBranchTime: {
      type: Object,
      default: null
    },
    installerRefId: String | null
  },

  watch: {
    secondaryBranchTime: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.id = newVal.id;
          this.selectedCrewId = newVal.installerCrewId;
          this.startDate = newVal.fromDate;
          this.endDate = newVal.toDate;
          this.comment = newVal.comment;
        }
      }
    },
    selectedCrewId: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.isEditable) {
          this.getDaysOff();
          this.getSelectedCrewWorkingDays();
        }
      }
    }
  },

  computed: {
    isStartDateEditable() {
      if (!this.selectedCrewId) {
        return false;
      }
      return !this.isEditForm || this.isEditForm && DateUtils.isSameOrAfter(this.startDate, DateFormatter.getStartOfTheDayFromDate(new Date()));
    },
    isEndDateEditable() {
      if (!this.selectedCrewId) {
        return false;
      }
      return !this.isEditForm || this.isEditForm && !DateUtils.isPastDate(this.endDate);
    },
    // Is editable if the end date is in the future
    isEditable() {
      return !this.isEditForm || this.isEditForm && !DateUtils.isPastDate(this.endDate);
    },
    secondaryBranchName() {
      return this.crews.find(crew => crew.id === this.selectedCrewId)?.secondaryBranch;
    },
    daysOffDates() {
      return this.selectedCrewDaysOffs ? this.selectedCrewDaysOffs.map(dayOff => {
            return {
              startDate: dayOff.startDate,
              endDate: dayOff.endDate
            }
          }).map(range => DateUtils.getDatesBetween(range.startDate, range.endDate)).flat()
          : [];
    },
    hasNonAllowedDatesToSelect() {
      if (this.startDate && this.endDate) {
        return DateUtils.getDatesBetween(this.startDate, this.endDate)
            .some(date => !this.isWorkingDay(date) || this.isDayOffDate(date));
      }
      return false;
    }
  },

  methods: {
    resetForm() {
      Object.assign(this.$data, defaultForm);
      if (this.isEditForm) {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      } else {
        this.$refs.startDate.clearSelected();
        this.$refs.endDate.clearSelected();
      }
    },
    allowedStartDates(value) {
      return !DateUtils.isPastDate(value) && !this.isDayOffDate(value) && this.isWorkingDay(value);
    },
    allowedEndDates(value) {
      const isSameOrAfterStartDate = this.startDate ? DateUtils.isSameOrAfter(value, this.startDate) : false;
      if (!isSameOrAfterStartDate || this.isEditForm && DateUtils.isPastDate(value)) {
        return false;
      }
      return !this.isDayOffDate(value) && this.isWorkingDay(value);
    },
    validate() {
      return this.$refs.form.validate();
    },
    buildPayload() {
      return {
        id: this.id,
        installerCrewId: this.selectedCrewId,
        fromDate: this.startDate,
        toDate: this.endDate,
        comment: this.comment
      };
    },
    onStartDateChanged(value) {
      if (value && !DateUtils.isSameOrAfter(this.endDate, value)) {
        this.endDate = null;
        this.$refs.endDate.clearSelected();
      }
    },
    getDaysOff() {
      InstallerCrewDayOff.getRestApi().getCrewsDaysOff(this.installerRefId)
          .then(response => {
            this.selectedCrewDaysOffs = response.data.filter(dayOff => dayOff.installerCrewId === this.selectedCrewId);
          })
    },
    getSelectedCrewWorkingDays() {
      InstallerCrews.getRestApi().getCrewWorkingDays(this.selectedCrewId)
          .then(response => {
            this.selectedCrewWorkingDays = response.data.map(wd => wd.dayOfWeek);
          });
    },
    clearSelectedDates() {
      this.startDate = null;
      this.$refs.startDate.clearSelected();
      this.endDate = null;
      this.$refs.endDate.clearSelected();
    },
    isDayOffDate(value) {
      return this.daysOffDates ? this.daysOffDates.some(day => day === value) : false;
    },
    isWorkingDay(value) {
      return this.selectedCrewWorkingDays
          ? this.selectedCrewWorkingDays.some(day => day === DateFormatter.getDayOfWeek(value).toUpperCase())
          : true;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>
