<template>
  <v-container class="mt-6">
    <v-skeleton-loader
        v-if="loading"
        type="card"
        class="mt-6"
    />
    <v-form v-else ref="form" v-model="isFormValid">
      <v-row>
        <v-col cols="4">
          <v-text-field
              v-model.number="tier1"
              :rules="[rules.required, rules.positive, rules.minOne, rules.integer]"
              label="* Tier 1 (%)"
              type="number"
              min="0"
              step="1"
              prefix="%"
              placeholder="0"
              persistent-placeholder
              @input="markAsModified"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
              v-model.number="tier2"
              :rules="[rules.required, rules.positive, rules.minOne, rules.integer]"
              label="* Tier 2 (%)"
              type="number"
              min="0"
              step="1"
              prefix="%"
              placeholder="0"
              persistent-placeholder
              @input="markAsModified"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
              v-model.number="tier3"
              :rules="[rules.required, rules.positive, rules.minOne, rules.integer]"
              label="* Tier 3 (%)"
              type="number"
              min="0"
              step="1"
              prefix="%"
              placeholder="0"
              persistent-placeholder
              @input="markAsModified"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-card-text class="total-percentage-check text-center">
              <div class="total-percentage-content">
                Total Percentage: {{ totalPercentage }}%
                <v-icon :color="isTotalValid ? 'green' : 'red'" class="ml-2">
                  {{ isTotalValid ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                </v-icon>
              </div>
            </v-card-text>
            <v-card-text v-if="!isTotalValid && isModified" class="text-center red--text">
              Total percentage must be equal to 100% and at least 1% required for each tier.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="2">
          <v-btn
              color="primary"
              :disabled="!isTotalValid || !isIntegerValues"
              @click="saveTiersClicked"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Rules from "@/assets/rules";
import SalesRepTiers from "@/rest/salesRepTiers";

export default {

  props: {
    isActionConfirmed: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  created() {
    this.getAllTiers();
  },

  watch: {
    isActionConfirmed: {
      handler(val) {
        if (val) {
          this.saveTiers();
        }
      },
      immediate: true
    }
  },

  data() {
    return {
      tier1: 0,
      tier2: 0,
      tier3: 0,
      isFormValid: false,
      isModified: false,
      loading: true,
      rules: {
        required: Rules.required,
        positive: Rules.isPositiveAndGreaterThanZero,
        minOne: value => value > 0 || "At least 1% required",
        integer: value => Number.isInteger(value) || "Only integer values are allowed"
      }
    };
  },

  computed: {
    totalPercentage() {
      return this.tier1 + this.tier2 + this.tier3;
    },
    isTotalValid() {
      return this.totalPercentage === 100 && this.tier1 > 0 && this.tier2 > 0 && this.tier3 > 0;
    },
    isIntegerValues() {
      return Number.isInteger(this.tier1) && Number.isInteger(this.tier2) && Number.isInteger(this.tier3);
    }
  },

  methods: {
    markAsModified() {
      this.isModified = true;
    },
    validateForm() {
      if (!this.isTotalValid) {
        alert("Total percentage must be 100% and at least 1% required for each tier");
        return false;
      }
      return true;
    },
    buildPayload() {
      return [
        {id: 1, name: 'Tier 1', tierPercentage: this.tier1},
        {id: 2, name: 'Tier 2', tierPercentage: this.tier2},
        {id: 3, name: 'Tier 3', tierPercentage: this.tier3}
      ];
    },
    saveTiersClicked() {
      if (this.$refs.form.validate() && this.validateForm()) {
        const payload = this.buildPayload();
        this.$emit('saveTiersClicked', payload);
      }
    },
    async saveTiers() {
      const payload = this.buildPayload();
      try {
        await SalesRepTiers.getRestApi().updateSalesRepTiers(payload);
        this.$emit('showSuccess', 'Sales Representative tiers updated successfully');
      } catch (error) {
        this.onErrorOccurred(error);
      }
    },
    async getAllTiers() {
      try {
        const response = await SalesRepTiers.getRestApi().getSalesRepTiers();
        const tiers = response.data;
        this.tier1 = tiers.find(tier => tier.id === 1)?.tierPercentage || 0;
        this.tier2 = tiers.find(tier => tier.id === 2)?.tierPercentage || 0;
        this.tier3 = tiers.find(tier => tier.id === 3)?.tierPercentage || 0;
        this.loading = false;
      } catch (error) {
        this.onErrorOccurred(error);
      }
    },
    onErrorOccurred(error) {
      this.$emit('showError', error);
    }
  }
};
</script>

<style scoped>
.total-percentage-check {
  font-size: 18px;
  font-weight: 500;
}
.total-percentage-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
