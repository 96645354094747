import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Work Order
 */
export interface WorkOrderJobSummary {
    soldDate: string;
    soldTo: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    primaryPhone: string;
    alternatePhone: string;
    salesRepresentative: string;
    salesRepresentativePhone: string;
    balanceDue: number;
}

export interface WorkOrderLineItem {
    id: number;
    laborDescription: string;
    style: string;
    color: string;
    quantity: number;
    rate: number;
    total: number;
}

export interface WorkOrderLaborSummary {
    workOrderLineItems: WorkOrderLineItem[];
    totalPay: number;
    laborTypeName: string;
    laborTypeId: number;
}

export interface WorkOrderMiscItem {
    miscStyle: string;
    miscColor: string;
    convertedQuantity: string;
}

export interface WorkOrder {
    jobSummary: WorkOrderJobSummary;
    laborSummary: WorkOrderLaborSummary;
    miscItems: WorkOrderMiscItem[];
    installerNote: string;
}

/**
 * REST interface provided for Jobs.
 */
export interface WorkOrderRestApi {
    getJobWorkOrder(jobRefId: string, lineItemRefId: string): AxiosPromise<WorkOrder>;
}

/**
 * REST implementation provided for Jobs.
 */
class Implementation implements WorkOrderRestApi {
    getJobWorkOrder(jobRefId: string, lineItemRefId: string): AxiosPromise<WorkOrder> {
        return Axios.axiosInstance().get<WorkOrder>(
            `/work-orders/${jobRefId}/${lineItemRefId}`
        );
    }
}

const WorkOrders = {
    getRestApi(): WorkOrderRestApi {
        return new Implementation();
    },
};

export default WorkOrders;
