<template>
  <navigation-view
      ref="nav"
      page-title="Inventory Management"
      nav-section="orders"
  >
    <template v-slot:content>
      <v-container grid-list-lg column>
        <div class="headline">Check Out - Inventory Adjustments</div>
        <v-divider class="detailsDivider"/>

        <v-row>
          <v-col md="4">
            <branches-search-field
                :required="true"
                :selectAllEnabled="false"
                @inputSelected="onBranchSelected"
            />
          </v-col>
          <v-col md="4">
            <reason-codes-search-field
                v-if="branchReferenceId"
                :branchSelected="branchReferenceId"
                @input="onReasonCodeChanged"
            />
          </v-col>
        </v-row>

        <v-row v-if="!branchReferenceId" column class="tempSearchContainer">
          <v-col md="12">
            <v-icon color="primary" class="searchIcon">search</v-icon>
            <p class="subheading">Get started by selecting a Branch</p>
          </v-col>
        </v-row>

        <v-row v-show="branchReferenceId">
          <v-col md="12">
            <v-card raised light color="white" class="mt-3 mb-2 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Inventory Items</v-app-bar-title>
              </v-app-bar>
              <inventory-chooser
                  ref="inventoryChooser"
                  :branchId="branchReferenceId"
                  @itemChosen="onInventoryItemChosen"
              />
            </v-card>
          </v-col>
          <v-col md="12">
            <v-card raised light color="white" class="mt-3 mb-2 elevation-5">
              <v-app-bar dense dark flat color="primary">
                <v-app-bar-title>Check Out Inventory</v-app-bar-title>
              </v-app-bar>
              <selected-items
                  :selectedItems="inventoryItems"
                  @reduceQuantityClicked="onReduceQuantity"
                  @increaseQuantityClicked="onIncreaseQuantity"
                  @deleteClicked="onDeleteClicked"
              />
            </v-card>
          </v-col>

          <confirmation-dialog
              :loading="inProgress"
              :visible="confirmModalOpened"
              :cancel-label="`No`"
              :submit-label="`Yes`"
              :confirmationText="`You are about to check out $${totalCost} worth of inventory. Are you sure?`"
              @onCancelClicked="confirmModalOpened = false"
              @onSubmitClicked="handleCheckOutConfirmed"
          />
          <v-col class="d-flex justify-end">
            <span class="submitError">{{ submitError }}</span>
            <v-btn
                large
                color="primary"
                class="checkOutButton"
                :disabled="!(inventoryItems.length && reasonCodeId)"
                @click="handleCheckOutClicked"
            >Check Out
            </v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import InventoryChooser from "../../components/inventoryManagement/inventoryGeneralCheckOut/InventoryChooser.vue";
import SelectedItems from "../../components/inventoryManagement/inventoryGeneralCheckOut/SelectedItems.vue";
import InventoryItems from "../../rest/inventoryItems.ts";
import BranchesSearchField from "../../components/searchFields/BranchesAutocomplete.vue";
import ReasonCodesSearchField from "../../components/searchFields/ReasonCodesCheckOut.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
    NavigationView,
    InventoryChooser,
    SelectedItems,
    BranchesSearchField,
    ReasonCodesSearchField
  },

  data() {
    return {
      inventoryItems: [],
      submitError: null,
      branchReferenceId: null,
      promoCodeId: null,
      inProgress: false,
      reasonCodeId: null,
      confirmModalOpened: false
    };
  },

  computed: {
    totalCost() {
      return this.inventoryItems.reduce((acc, item) => {
        return acc + (Number(item.Quantity) * item.SupplierCost);
      }, 0).toFixed(2);
    }
  },

  methods: {
    onInventoryItemChosen(item) {
      this.clearSubmitError();
      let duplicate = false;
      this.inventoryItems.forEach(existing => {
        if (existing.Id === item.Id) {
          duplicate = true;
        }
      });
      if (!duplicate) {
        item.Quantity = 0;
        this.inventoryItems.push(item);
      }
    },
    onReduceQuantity(item, index) {
      this.clearSubmitError();
      const newItem = {...item};
      let quantity = parseInt(newItem.Quantity || 0);
      if (quantity > 0) {
        quantity -= 1;
      }
      this.inventoryItems[index].Quantity = Number(quantity);
    },
    onIncreaseQuantity(item, index) {
      this.clearSubmitError();
      const newItem = {...item};
      let quantity = parseInt(newItem.Quantity || 0);
      this.inventoryItems[index].Quantity = Number((quantity += 1));
    },
    handleCheckOutClicked() {
      if (this.checkItemsValid()) {
        this.confirmModalOpened = true;
      }
    },
    async handleCheckOutConfirmed() {
      this.inProgress = true;
      let items = this.getCheckoutFormat();

      let request = {
        reasonCodeId: this.reasonCodeId,
        branchReferenceId: this.branchReferenceId,
        checkOuts: items
      };
      try {
        await InventoryItems.getRestApi().inventoryItemsCheckOutGeneric(request);
        this.inventoryItems = [];
        this.$refs.nav.showSuccess("Inventory checked out successfully.");
      } catch (error) {
        throw error;
      } finally {
        this.clearSubmitError();
        this.refreshInventoryItemsInlineGrid();
      }
      this.inProgress = false;
      this.confirmModalOpened = false;
    },
    onDeleteClicked(index) {
      this.clearSubmitError();
      this.inventoryItems.splice(index, 1);
    },
    clearSubmitError() {
      this.submitError = null;
    },
    checkItemsValid() {
      let component = this;
      let passed = true;
      this.inventoryItems.forEach(existing => {
        let item = existing.ProductStyle + " " + existing.ProductColor;
        if (!existing.Quantity) {
          component.submitError = `Input quantity is incorrect for ${item}.`;
          passed = false;
        }
        if (existing.Quantity == 0) {
          component.submitError = `Cannot have 0 quantity for product ${item}.`;
          passed = false;
        }
        if (existing.Quantity < 0) {
          component.submitError = `Quantity can't be negative for ${item}.`;
          passed = false;
        }
        if (existing.Available === 0) {
          component.submitError = `No inventory available for ${item}.`;
          passed = false;
        }
        if (existing.Quantity > existing.Available) {
          component.submitError = `Checkout for ${item} exceeds the available amount.`;
          passed = false;
        }
      });
      return passed;
    },
    getCheckoutFormat() {
      let items = [];
      this.inventoryItems.forEach(existing => {
        items.push({
          inventoryItemId: existing.Id,
          quantity: existing.Quantity
        });
      });
      return items;
    },
    onBranchSelected(data) {
      if (this.inventoryItems.length > 0) {
        this.inventoryItems = []
      }
      this.branchReferenceId = data;
    },
    onReasonCodeChanged(data) {
      if (data !== undefined) {
        this.reasonCodeId = data;
      } else {
        this.reasonCodeId = null;
      }
    },
    refreshInventoryItemsInlineGrid() {
      this.$refs.inventoryChooser.refreshInventoryItemsGrid();
    },
    clearGridState() {
      this.$refs.inventoryChooser.clearFilterAndSort();
    },
  }
};
</script>

<style scoped>
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}
.searchError,
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
.searchIcon {
  font-size: 150px;
}
.toolbar {
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}
</style>
