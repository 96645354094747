<template>
  <v-form ref="closeTRReasonCodeForm">
    <v-container>
      <v-select
          v-model="selectedReasonCodeId"
          :items="closingOptions"
          label="* Reason Code"
          item-text="name"
          item-value="id"
          placeholder=" "
          persistent-placeholder
          :rules="[rules.required]"
      />
    </v-container>
  </v-form>
</template>

<script>
import Rules from "../../../assets/rules";
import TransferRequestReasonCodes from "../../../rest/transferRequestReasonCodes";

export default {
  components: {},

  props: {
    isCloseTransferRequestForm: {
      type: Boolean,
      required: false,
      default: true,
    }
  },

  created() {
    this.getTransferRequestsReasonCodes();
  },

  computed: {
    closingOptions() {
      return this.isCloseTransferRequestForm ? this.closeTransferRequestReasonCodes : this.closeTransferRequestItemReasonCodes;
    }
  },

  data() {
    return Object.assign(
        {},
        {
          error: null,
          closeTransferRequestReasonCodes: [],
          closeTransferRequestItemReasonCodes: [],
          selectedReasonCodeId: null,
          rules: {
            required: Rules.required,
          },
        }
    );
  },

  watch: {
    selectedReasonCodeId(newVal) {
      this.selectedReasonCodeId = newVal;
      this.$emit('select', newVal);
    }
  },
  methods: {
    validate() {
      return this.$refs.closeTRReasonCodeForm.validate();
    },
    resetCloseTransferRequestReasonCodeForm() {
      this.getTransferRequestsReasonCodes();
      this.restoreToDefaults();
      this.$refs.closeTRReasonCodeForm.resetValidation();
    },
    restoreToDefaults() {
      this.selectedReasonCodeId = null;
      this.error = null;
    },
    async getTransferRequestsReasonCodes() {
      if (!this.closeTransferRequestReasonCodes.length || !this.closeTransferRequestItemReasonCodes.length) {
        const response = await TransferRequestReasonCodes.getRestApi().getAllReasonCodesRelatedToTransferRequest();
        this.filterReasonCodesByItsApplicableType(response.data);
      }
    },
    filterReasonCodesByItsApplicableType(allReasonCodes) {
      this.closeTransferRequestReasonCodes = allReasonCodes.filter(rc => rc.isTransferRequestApplied);
      this.closeTransferRequestItemReasonCodes = allReasonCodes.filter(rc => rc.isItemApplied);
    }
  },
}

</script>
