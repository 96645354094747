<template>
    <v-row>
      <v-col md="6">
        <v-text-field
            :value="appointmentDate"
            label="Appointment Date"
            ref="appointmentDate"
            placeholder=" "
            persistent-placeholder
            readonly
            data-id="lead-details-form-read-only-apt-date-read"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="timeBlockName"
            label="Appointment Time"
            :placeholder="appointmentTime"
            persistent-placeholder
            readonly
            data-id="lead-details-form-apt-timeblock-read"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :class="salesRepNameStyle"
            data-id="lead-details-form-sales-rep"
            label="Sales Representative"
            :value="displaySalesRepName"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="promoCodeName"
            label="Promo Code"
            placeholder="Promo Code"
            persistent-placeholder
            :readonly="true"
        />
      </v-col>
      <v-col md="12" v-if="widgetPromoCode">
        <v-text-field
            label="Widget Promo Code"
            :value="widgetPromoCode"
            placeholder=" "
            persistent-placeholder
            readonly
            data-id="lead-details-form-widget-promo-code"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            ref="leadRooms"
            :value="leadRooms"
            :readonly="true"
            placeholder="Rooms"
            persistent-placeholder
            label="Rooms"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            ref="roomsCount"
            :value="roomsCount"
            :readonly="true"
            placeholder="Rooms Count"
            persistent-placeholder
            label="Rooms Count"
        />
      </v-col>
      <v-col md="12">
        <v-text-field
            ref="leadInstallationCapabilities"
            :value="installationCapabilities"
            :readonly="true"
            :placeholder="' '"
            persistent-placeholder
            label="Product Types"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="homeOwnerValue"
            data-id="lead-details-form-is-home-owner"
            label="Home Owner"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="isRehashValue"
            data-id="lead-details-form-is-rehash"
            label="Rehash Go Back"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            :value="isAppointmentConfirmedValue"
            data-id="lead-details-form-is-appointment-confirmed"
            label="Appointment Confirmed"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>
</template>

<script>
import FormattingHelper from "../../assets/formattingHelper";
import DateFormatter from "../../assets/dateFormatter";
import LeadRoomsSearchField from "../../components/searchFields/LeadRooms.vue";
import InstallationCapabilitiesSearchField from "../../components/searchFields/InstallationCapabilities.vue";

const formDefaults = {
  leadStatusName: null,
  appointmentDate: null,
  appointmentTime: null,
  timeBlockName: null,
  promoCodeName: null,
  widgetPromoCode: null,
  salesRepName: null,
  previousSalesRepName: null,
  leadRooms: [],
  installationCapabilities: [],
  roomsCount:null,
  isHomeOwner: null,
  isRehash: null,
  isAppointmentConfirmed: null
};
export default {
  components: {
    LeadRoomsSearchField,
    InstallationCapabilitiesSearchField
  },

  data() {
    return Object.assign({}, formDefaults);
  },

  computed: {
    displaySalesRepName() {
      return this.salesRepName || this.previousSalesRepName || null;
    },
    salesRepNameStyle() {
      return this.previousSalesRepName ? 'gray--text' : '';
    },
    homeOwnerValue() {
      return FormattingHelper.textValueFromBoolean(this.isHomeOwner);
    },
    isRehashValue() {
      return FormattingHelper.textValueFromBoolean(this.isRehash);
    },
    isAppointmentConfirmedValue() {
      return FormattingHelper.textValueFromBoolean(this.isAppointmentConfirmed);
    }
  },

  methods: {
    loadForm(payload, leadRooms, installationCapabilities) {
      this.leadStatusName = payload.leadStatusName;
      this.appointmentDate = DateFormatter.formatDateInUtcAndMMDDYYYYFormat(payload.appointmentDate);
      this.appointmentTime = payload.appointmentTime;
      this.timeBlockName = payload.timeBlockName;
      this.promoCodeName = payload.promoCodeName;
      this.widgetPromoCode = payload.widgetPromoCode;
      this.salesRepName = payload.salesRepName;
      this.previousSalesRepName = payload.previousSalesRepName;
      this.leadRooms = FormattingHelper.asCommaDelimitedString(leadRooms);
      this.roomsCount = payload.roomsCount;
      this.installationCapabilities = FormattingHelper.asCommaDelimitedString(installationCapabilities);
      this.isHomeOwner = payload.isHomeOwner;
      this.isRehash = payload.isRehash;
      this.isAppointmentConfirmed = payload.isAppointmentConfirmed;
    }
  }
};
</script>

<style scoped>
.divider {
  border-bottom: 20px;
}

.gray--text >>> .v-text-field__slot input {
  color: silver;
}
</style>
