<template>
  <landing-page>
    <div class="landing-form">
      <v-form v-model="valid">
        <v-row>
          <v-col md="12">
            <v-text-field
                label="Email"
                v-model="email"
                placeholder=" "
                persistent-placeholder
                requiered
                id="request-email-field"
                :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-col>
          <v-col md="12">
            <div class="request-buttons">
              <v-btn
                  large
                  color="#59b"
                  class="request-button white--text"
                  @click="onCancelClick"
              >
                Cancel
              </v-btn>
              <v-btn
                  large
                  color="#59b"
                  class="request-button white--text"
                  :disabled="!valid"
                  @click="onSubmitClick"
              >
                Submit
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <v-snackbar :timeout="30000" error v-model="snackbar">
      {{ snackbarMessage }}
      <v-btn
          dark
          text
          @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </landing-page>
</template>

<script>
import LandingPage from "../LandingPage.vue";
import Users from "@/rest/users";
import Rules from "../../assets/rules";

export default {
  components: {
    LandingPage
  },

  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarMessage: "",
      email: "",
      rules: {
        required: Rules.required,
        email: Rules.email
      }
    };
  },

  methods: {
    onSubmitClick() {
      const component = this;
      const email = this.email;
      const encodedEmail = encodeURIComponent(this.email);
      Users.getRestApi()
          .forgotPassword(email)
          .then(() => {
            component.$router.push(`/forgot?email=${encodedEmail}`);
          })
          .catch((error) => {
            component.snackbarMessage =
                error.response && error.response.data
                    ? error.response.data.message
                    : "Invalid or mismatched email.";
            component.snackbar = true;
          });
    },
    onCancelClick() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
.request-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.request-button {
  margin: 0 15px;
}
</style>
