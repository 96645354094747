<template>
  <inline-grid
    :data-source="leads"
    :show-borders="false"
    :hoverStateEnabled="true"
    @rowSelected="onRowSelected"
  >
    <dx-column
      data-field="createdAt"
      data-type="date"
      caption="Date"
      id="leads-table-created-at-column"
      format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
      data-field="leadStatusName"
      caption="Status"
      id="leads-table-status-column"
    />
    <dx-column
      data-field="salesRepName"
      caption="Sales Representative"
      id="leads-table-sales-rep-column"
    />
    <dx-column
      data-field="quote"
      caption="Quote"
      id="leads-table-quote-column"
    />
  </inline-grid>
</template>

<script>
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import Customers from "../../rest/customers";

export default {
  components: {
    InlineGrid,
    DxColumn
  },

  props: ["customerReferenceId"],

  data() {
    return {leads: null};
  },

  created() {
    this.refresh();
  },

  methods: {
    refresh() {
      let component = this;
      Customers.getRestApi()
          .getCustomerLeads(this.customerReferenceId)
          .then((response) => {
            component.leads = response.data;
          })
          .catch(() => {
            component.snackbar = true;
          });
    },
    onRowSelected(data) {
      if (data) {
        this.$router.push(`/leads/details/${data.referenceId}`);
      }
    }
  }
};
</script>
