<template>
  <modal-dialog
    ref="dialog"
    title="Verify Fulfillment Details"
    width="800"
    submitLabel="Fulfill Job"
    cancelLabel="Cancel"
    :visible="dialogVisible"
    :loading="fulfilling"
    :hideButtons="hideFulfillmentButtons"
    :submitEnabled="plan.purchaseOrderPlansAlerts == null || plan.purchaseOrderPlansAlerts.length === 0"
    @submitClicked="onProcessClicked"
    @cancelClicked="onCancelClicked"
  >
    <v-card v-if="plan">
      <plan-inventory-fulfillment :plan="plan" class="mb-3" />
      <v-card>
        <div class="notification" v-for="notification in plan.purchaseOrderPlanNotifications" :key="notification">
          <p> {{notification}} </p>
        </div>
      </v-card>
      <v-card>
        <v-app-bar dense dark color="primary">
          <v-app-bar-title class="subheading">
            Fulfill Via Purchase Order
          </v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-tabs v-model="active" background-color="#69b" slider-color="white" dark>
            <v-tab
              ripple
              v-for="orderPlan in plan.purchaseOrderPlans"
              :key="orderPlan.vendor.id"
              >
              {{ orderPlan.vendor.name }}
            </v-tab>
            <v-tab-item
              v-for="orderPlan in plan.purchaseOrderPlans"
              :key="orderPlan.vendor.id"
            >
              <purchase-order-fulfillment :purchaseOrderPlan="orderPlan" />
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>

      <v-card>
        <li class="submitError" v-for="alert in plan.purchaseOrderPlansAlerts" :key="alert">
          {{ formatAlertMessage(alert) }}
          <p> {{ subAlertMessage(alert) }} </p>
        </li>
      </v-card>

    </v-card>
    <transfer-options ref="missingProductsModal"
                      @transferOptionsSelected="onTransferOptionsSelected" />
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";
import PlanInventoryFulfillment from "../../../components/jobs/planFulfillment/InventoryContainer.vue";
import PurchaseOrderFulfillment from "../../../components/jobs/planFulfillment/PurchaseOrderContainer.vue";
import Jobs from "../../../rest/jobs";
import TransferOptions from "@/components/jobs/planFulfillment/TransferOptions.vue";

export default {
  data() {
    return {
      active: null,
      dialogVisible: false,
      hideFulfillmentButtons: false,
      fulfilling: false,
      subAlert: null,
      fulfillmentPlan: null
    };
  },

  components: {
    ModalDialog,
    PlanInventoryFulfillment,
    PurchaseOrderFulfillment,
    TransferOptions
  },

  props: {
    plan: {},
    wsSessionId: {
      type: String,
      required: true
    }
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    hasTransferOptions() {
      return this.plan.transferOptions.length > 0;
    },
    onProcessClicked() {
      if (this.hasTransferOptions()) {
        this.$refs.missingProductsModal.openDialog(this.plan.transferOptions);
        return;
      }
      this.processJobFulfillmentPlan(this.plan);
    },
    processJobFulfillmentPlan(plan) {
      let splitItems = this.isProductGoingToBeSplitAfterFulfillment(plan);
      this.fulfilling = true;
      this.$emit('fulfilling', true);
      this.$emit("disableJobConflictModal");
      this.hideFulfillmentButtons = true;
      Jobs.getRestApi()
          .processJobFulfillmentPlan(plan, this.wsSessionId)
          .then((response) => {
            this.hideFulfillmentButtons = false;
            this.closeDialog();
            this.$emit('planProcessed');
            this.fulfilling = false;
            this.$emit('fulfilling', false);
          })
          .catch((error) => {
            this.hideFulfillmentButtons = false;
            this.fulfilling = false;
            this.$emit('fulfilling', false);
            this.$emit('fulfillmentError', error.response.data.message);
            this.closeDialog();
          });
      if (splitItems) {
        this.$emit('productSplit', splitItems);
      }
    },
    onTransferOptionsSelected(options) {
      let plan = this.plan;
      if (options.length !== 0) {
        plan = this.applyTransferOptionsToPlan(options);
        this.$emit("planUpdated", plan);
      }
      if (this.planIsNotEmpty(plan)) {
        this.processJobFulfillmentPlan(plan);
      }
      this.closeDialog();
    },
    applyTransferOptionsToPlan(options) {
      const plan = {...this.plan};
      plan.selectedTransferOptions = options;
      return plan;
    },
    onCancelClicked() {
      this.dialogVisible = false;
    },
    subAlertMessage(alert) {
      let parts = alert.split("\n");
      return parts[1];
    },
    formatAlertMessage(alert) {
      let parts = alert.split("\n");
      return parts[0]
    },
    isProductGoingToBeSplitAfterFulfillment(plan) {
      let resultItems = [];
      plan.inventoryCheckOutPlans.forEach(checkOutPlan => {
        let totalInventoryQuantity = 0;
        checkOutPlan.inventoryItems.forEach(ii => {
          totalInventoryQuantity += ii.allocated;
        })
        if (totalInventoryQuantity === checkOutPlan.lineItem.quantity) {
          resultItems.push(checkOutPlan.lineItem.referenceId);
        }
      });
      return resultItems;
    },
    planIsNotEmpty(plan) {
      return plan.purchaseOrderPlans.length > 0 || plan.inventoryCheckOutPlans.length > 0;
    }
  }
};
</script>

<style scoped>

.submitError {
  color: red;
  padding-right: 15px;
}
.notification {
  padding: 5px;
  background-color: #D9F4FF;
  color: #000000;
}
</style>
