<template>
  <v-row class="mt-4">
    <v-col md="6">
      <date-picker-with-type-in-text-field
          label="* Appointment Date"
          id="lead-details-form-apt-date"
          v-model="appointmentDate"
          :optional="false"
          :disabled="disabled"
          :menu-disabled="disabled"
          :readonly="false"
          :validation-name="'Appointment date'"
          :allowed-dates="allowedDates"
      />
    </v-col>

    <v-col md="6">
      <v-select
          item-value="id"
          data-id="lead-details-form-apt-time"
          label="* Appointment Time"
          persistent-placeholder
          v-model="timeBlockId"
          :items="itemsTimeBlocks"
          :disabled="disabled"
          :readonly="disabled"
          :rules="[rules.required]"
          attach
      >
        <template v-slot:selection="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item="{ item }">
          {{ item.name }} ({{ item.availableSlots }})
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import Constants from "../../assets/constants";
import DatePickerWithTypeInTextField from "../../components/common/form/DatePickerWithTypeInTextField.vue";
import SalesRepTimeBlock from "../../rest/salesRepTimeBlocks";
import Rules from "@/assets/rules";
import Roles from "@/assets/roles";

export default {
  components: {
    DatePickerWithTypeInTextField,
  },

  props: {
    disabled: Boolean,
    readonly: Boolean,
    marketId: Number,
    required: Boolean,
    isSameDayAllowed: Boolean,
    formType: {
      type: String,
      required: true
    }
  },

  watch: {
    appointmentDate(newVal) {
      if (newVal) {
        this.resetAppointmentTime();
        this.$emit('changeAppointmentDate', this.appointmentDate);
      }
    },
    async timeBlockId() {
      this.timeSlotSelected = await this.findTimeBlockInList();
      if (this.timeSlotSelected) {
        this.$emit('changeAppointmentTime', this.timeSlotSelected.name);
      }
    }
  },

  data() {
    return Object.assign(
        {itemsTimeBlocks: []},
        {timeSlotSelected: {id: null, name: "", startTime: ""}},
        {
          rules: {
            required: (value) => {
              return Rules.requiredBasedOnPropsValue(value, this.required, "Appointment time is required");
            },
          },
        },
        {appointmentDate: "", timeBlockId: ""}
    );
  },

  methods: {
    allowedDates(val) {
      const allowSuperAdminToPickAnyDay = Roles.isSuperAdminUser() && this.formType === Constants.leadFormType.UPDATE;

      if (allowSuperAdminToPickAnyDay) {
        return true;
      }

      return this.isSameDayAllowed
          ? moment(val).isSameOrAfter(moment(), 'day')
          : moment(val).isAfter(moment(), 'day');
    },
    getValues() {
      return {
        date: this.appointmentDate,
        time: this.timeSlotSelected ? this.timeSlotSelected.startTime : "",
        name: this.timeSlotSelected ? this.timeSlotSelected.name : "",
        timeBlockId: this.timeBlockId,
      };
    },
    async loadForm(payload) {
      this.appointmentDate = payload.appointmentDate ? moment.utc(payload.appointmentDate).format("YYYY-MM-DD") : null;
      this.timeBlockId = payload.timeBlockId;
      this.disableScheduleDrawerIfLeadNonServiceable(payload);
      if (this.appointmentDate) {
        await this.setTimeBlock(this.appointmentDate, payload.timeBlockId, payload.marketId);
      }
    },
    async findTimeBlockInList() {
      return this.itemsTimeBlocks.find((itemTimeBlock) =>
          itemTimeBlock.id === this.timeBlockId &&
          itemTimeBlock.availableSlots >= 0
      );
    },
    async setTimeBlock(slotDate, timeBlockIdSelected, marketId) {
      slotDate = moment.utc(slotDate).format("YYYY-MM-DD");

      if (slotDate && marketId) {
        const response = await SalesRepTimeBlock.getRestApi().getTimeBlocksByMarketIdAndDate(marketId, slotDate);
        this.itemsTimeBlocks = response.data;
        this.itemsTimeBlocks.forEach((timeBlock) => this.disableTimeBlocksIfNeeded(slotDate, timeBlock, timeBlockIdSelected));

        if (timeBlockIdSelected != null) {
          const foundTimeBlock = this.itemsTimeBlocks.find((timeBlock) => !timeBlock.disabled && timeBlock?.id === timeBlockIdSelected);
          if (foundTimeBlock) {
            this.timeBlockId = foundTimeBlock.id;
          }
        }
      }
    },
    disableTimeBlocksIfNeeded(slotDate, timeBlock, timeBlockIdSelected) {
      const startTime = moment(slotDate + " " + timeBlock.startTime, "YYYY-MM-DD HH:mm");
      const isPastAppointmentTime = startTime.isBefore(moment());
      const isCreateForm = this.formType === Constants.leadFormType.CREATE;

      if (timeBlock.id !== timeBlockIdSelected && (timeBlock.availableSlots <= 0 || (isPastAppointmentTime && isCreateForm))) {
        timeBlock.disabled = true;
      }
    },
    reset() {
      this.appointmentDate = "";
      this.timeBlockId = "";
      this.itemsTimeBlocks = [];
    },
    resetAppointmentTime() {
      this.timeSlotSelected = {id: null, name: "", startTime: ""};
      this.timeBlockId = "";
    },
    updateTimeSlots(timeBlockIdSelected) {
      this.setTimeBlock(this.appointmentDate, timeBlockIdSelected, this.marketId);
    },
    disableScheduleDrawerIfLeadNonServiceable(payload) {
      this.disabled = payload.leadStatusName === Constants.nonServiceableLeadStatus;
    }
  },
};
</script>
