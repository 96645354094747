const Rules = {
  alphabeticOnly: value => {
    if (value) {
      const pattern = /^([a-zA-Z ' ` ~ ñ á é ü ô č]*)$/;
      return pattern.test(value) || "Must contain only letters";
    }
    return true;
  },
  email: value => {
    if (value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    }
    return true;
  },
  validEmail: value => {
    if (value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value);
    }
    return false;
  },
  password: value => {
    if (!value || (value.length >= 6 && value.match(/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).+\S$/gi))) {
      return true
    } else return "Password must be at least 6 characters and contain at least one letter and one number."
  },
  isCurrencyFormat: value => {
    if (value) {
      const pattern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/;
      return pattern.test(value) || "Invalid currency.";
    }
    return true;
  },
  isPercentage: value => {
    if (!value) {
      return "Percentage is required.";
    } else {
      const pattern = /^\d+(\.\d{1,2})?$/;
      return pattern.test(value) || "Percentage must be a number with up to 2 decimal places.";
    }
  },
  isBranchReferenceId: value => {
    let pattern;
    if (value) {
      pattern = /^B[0-9]{2}$/;
      return pattern.test(value) || "'B' followed by 2 digits";
    }
    return true;
  },
  isMarketReferenceId: value => {
    let pattern;
    if (value) {
      pattern = /^MA-[A-Z0-9]{8}$/;
      return pattern.test(value) || "'MA-' followed by 8 digits";
    }
    return true;
  },
  isPositiveOrZero: value => {
    return value >= 0 || "Value must be positive or zero.";
  },
  valueIsPositiveOrZeroWithName(value, filedName) {
    return value >= 0 || `${filedName} must be positive or zero.`;
  },
  isPositiveAndGreaterThanZero: value => {
    return value > 0 || "Value must be positive and greater than zero.";
  },
  isNullOrPositiveAndGreaterThanZero: value => {
    if (value === '' || value === null) return true
    else return value > 0 || "Value must be positive and greater than zero.";
  },
  phoneNumber: value => {
    if (value) {
      const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/;
      return pattern.test(value) || "Invalid phone number.";
    }
    return true;
  },
  phoneNumberWithDelimiter: value => {
    if (value) {
      const pattern = /^\d{3}-\d{3}-\d{4}$/;
      return pattern.test(value) || "Invalid phone number.";
    }
    return true;
  },
  required: value => !!value || "Required",
  requiredNotBlank: str => (typeof str === "string" && str.trim().length !== 0) || "Required",
  requiredArray: value => Array.isArray(value) && value.length > 0 || "Required",
  requiredWithFieldName: (value, fieldName) => !!value || (fieldName + " is required"),
  requiredWithCustomMessage: (value, message) => !!value || (message),
  requiredBasedOnPropsValue: (value, isRequired, validationMessage = "Required") => {
    if (isRequired) {
      return !!value || validationMessage;
    } else {
      return true;
    }
  },
  multipleOptionsRequiredBasedOnPropsValue: (selected, isRequired) => {
    if (isRequired) {
      if (!selected || selected.length === 0) {
        return "Required";
      } else return true;
    } else {
      return true;
    }
  },
  time: value => {
    if (value) {
      const pattern = /(((0[1-9]))|(1[0-2])):([0-5])([0-9])/;
      return pattern.test(value) || "Invalid time format";
    }
    return true;
  },
  validQuantity: value => {
    return /^\+?(0|[0-9]\d*)$/.test(value) || "Valid quantity required";
  },
  validQuantityCompareToMaxValue: (value, max) => {
    const isPositiveNumber = !isNaN(Number(value)) && Number(value) > 0;
    const isLessOrEqualThanMax = value <= max;
    return isPositiveNumber && isLessOrEqualThanMax;
  },
  isFloat(value) {
    if (typeof value === 'string') {
      value = Number(value);
    }
    if (!isNaN(value) && typeof value === 'number') {
      return !Number.isInteger(value);
    }
    return false;
  },
  validDecimalQuantity: value => {
    return /^(\+)?(\d+|\d*\.\d+)$/.test(value) || "Valid quantity required";
  },
  zipCode: value => {
    // not checking the dash as it's not to be persisted (apparent dash serves only as visual decoration via the mask)
    if (value) {
      const pattern = /(^\d{5}$)/; // only 5  zip
      return pattern.test(value) || "Invalid zip code.";
    }
    return true;
  },
  maxValue: (value, length) => {
    if (value) {
      return ((value.length > 0 && value.length <= length) || "Cannot have more than: " + length + " characters");
    }
    return true;
  },
  noteSize: (value, length) => {
    if (value) {
      return ((value.length > 0 && value.length < length) || "Note should not have more than " + length + " characters.");
    }
  },
  numberBetweenTwoValues: (value, minLength, maxLength, valueName) => {
    if (value) {
      return ((value >= minLength && value <= maxLength) || valueName + " should be greater than or equal " + minLength +
          " and less than " + maxLength);
    } else return true;
  },
  installerPayableAmountRule: (amount, minValue, maxValue, valueName) => {
    if (amount && (amount < minValue || amount > maxValue)) {
      return (valueName + " amount cannot exceed $" + maxValue + " (cost of labor for the customer)");
    } else return true;
  },
  isPositiveNumericOrZero(quantity) {
    if (!quantity || quantity.value < 0.0) {
      return false;
    }
    return true;
  },
  installerShouldBeAdded: (laborLineItem) => {
    if (laborLineItem.installationId && !laborLineItem.installerId) {
      return true;
    }
  },
  installerCrewShouldBeAdded: (laborLineItem) => {
    if (laborLineItem.installationId && isCreatedAfterInstallerTrackerRelease(laborLineItem.installationDate)
        && !laborLineItem.installerCrewId) {
      return true;
    }
  },
  etcDateShouldBeSelected: (laborLineItem) => {
    if (laborLineItem.installerId && laborLineItem.installerCrewId && !laborLineItem.etc) {
      return true;
    }
  },
  isNumericQuantityValue(quantity) {
    if (!quantity || Number(quantity) === 0) {
      return false;
    }
    return true;
  },
  isPositiveNumericQuantityValue(quantity) {
    if (!quantity || quantity.value <= 0.0) {
      return false;
    }
    return true;
  },
  cityName(value) {
    let pattern = /^[A-Za-z0-9'\-\s]+$/;
    return pattern.test(value) || "Invalid city name format.";
  },
  isDateMMDDYYYYFormat(value){
    const pattern = /^(|(0[1-9])|(1[0-2]))\/((0[1-9])|(1\d)|(2\d)|(3[0-1]))\/((\d{4}))$/;
    return pattern.test(value);
  },
  validReturnQuantity(value, selectedOption, jboLineItemQuantity) {
    return value => (
        (selectedOption === 'FULL_RETURN' || selectedOption === 'FULL_RESERVATION')
        || ((selectedOption !== 'FULL_RETURN' && selectedOption !== 'FULL_RESERVATION')
          && value <= jboLineItemQuantity) && value > 0)
      || 'Cannot be larger than the product item quantity or zero';
  },
  isWhitespaceOnly(string) {
    return /^\s*$/.test(string);
  },
  textLengthWithoutWhitespaces(string) {
    const nonWhitespaceStr = string.replace(/\s+/g, '');
    const charCount = nonWhitespaceStr.length;
    if (charCount >= 2) {
      return charCount;
    } else {
      return 'Provided string must have at least 2 non-whitespace characters.';
    }
  }
};

// Needed for backward compatibility. So that the validation doesn't fail for the existing installations
// created before Installer Tracker 2.0 release and not having crew
export const isCreatedAfterInstallerTrackerRelease = function(date) {
  if (process.env.VUE_APP_ENVIRONMENT === "prod") {
    return new Date(date) >= new Date("2024-04-17");
  } else {
    return new Date(date) >= new Date("2024-03-20") // QA Installer Tracker release
  }
}

export default Rules;
