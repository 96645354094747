<template>
  <navigation-view ref="nav" page-title="Job Details" nav-section="sales">
    <template v-slot:content>
      <v-container grid-list-lg fluid v-if="isJobLoaded">
        <job-builder-panel
            ref="jobBuilderPanel"
            :job-id="job.id"
            :nextAction="nextAction"
            :hasOpenNextActions="hasOpenNextActions"
            :wsSessionId="wsSessionId"
            @remainingBalance="onRemainingBalance"
            @changesDetected="onChangesDetected"
            @setCustomer="setCustomer"
            @setLead="onSetLead"
            @itemsSaved="onLineItemsSaved"
            @itemsDirty="onLineItemsDirty"
            @jobFulfilled="onJobFulfilled"
            @jobInvoiced="onJobInvoiced"
            @refreshInlineGrid="onRefreshInlineGrid"
            @jobBuilderPanelError="showSnackbarError"
            @showError="showSnackbarError"
            @open="onOpenJob"
            @productItemsUpdated="onProductItemsUpdated"
            @laborItemsUpdated="onLaborItemsUpdated"
            @openNextActionTab="openNextActionTab"
            @onReturnFromJobSuccess="showSnackbarSuccess"
            @sendMessageThroughWebSocket="onSendMessageThroughWebSocket"
            @updateJobData="onUpdateJobData"
        />

        <jobs-inline-grids
            ref="inlineGrids"
            :job="job"
            :lead="lead"
            :customer="customer"
            :productItems="productItems"
            :laborItems="laborItems"
            :remainingBalance="remainingBalance"
            :installationsReadOnly="installationsReadOnly"
            :wsSessionId="wsSessionId"
            @refreshPage="refreshAll"
            @showError="showSnackbarError"
            @installationAdded="onInstallationAdded"
            @installationDeleted="onInstallationDeleted"
            @submitDiscount="onSubmitDiscount"
            @submitChargeback="onSubmitChargeback"
            @submitInstallationIssue="onSubmitInstallationIssue"
            @submitRefund="onSubmitRefund"
            @openSubjob="onOpenSubjob"
            @nextActionToShow="nextActionToShow"
        />
        <job-dirty-dialog
            ref="dirtyDialog"
            @leaveJob="onLeaveJob"
            @continueJob="onContinueJob"
        />
        <job-update-conflict-dialog
            ref="conflictDialog"
            @refresh="refreshAll"
        />
      </v-container>
    </template>
  </navigation-view>
</template>

<script>
import Jobs from "../../rest/jobs.ts";
import NavigationView from "../../views/navigation/NavigationView.vue";
import JobBuilderPanel from "../../components/jobs/JobBuilderPanel.vue";
import JobsInlineGrids from "../../views/jobs/JobsInlineGrids.vue";
import JobDirtyDialog from "../../components/jobs/JobDirtyDialog.vue";
import JobUpdateConflictDialog from "../../components/jobs/JobUpdateConflictDialog.vue";
import Constants from "../../assets/constants";
import DateFormatter from "../../assets/dateFormatter";
import WebSockets from "../../websocket/webSockets";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    NavigationView,
    JobBuilderPanel,
    JobsInlineGrids,
    JobDirtyDialog,
    JobUpdateConflictDialog,
  },

  data() {
    return {
      jobReferenceId: null, // re-write jobReferenceId usage to job.referenceId
      job: {},
      lead: null,
      productItems: [],
      laborItems: [],
      customer: null,
      isJobLoaded: false,
      remainingBalance: null,
      installationsReadOnly: false,
      isDirty: false,
      nextAction: null,
      hasOpenNextActions: false,
      stompJsClient: null,
      wsSessionId: null
    };
  },

  created() {
    this.jobReferenceId = this.$route.params.jobReferenceId;
    this.wsSessionId = WebSockets.generateWebSocketSessionId();
  },

  beforeRouteUpdate(to, from, next) {
    this.$data.jobReferenceId = to.params.jobReferenceId;
    next();
  },

  watch: {
    jobReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.isJobDirty()) {
      this.$refs.dirtyDialog.openDialog(next);
    } else {
      next();
    }
  },

  methods: {
    refreshInlineGrids() {
      this.$refs.inlineGrids.refreshInlineGrids();
    },
    async refresh() {
      if (this.jobReferenceId) {
        try {
          const data = await this.getJobData();
          this.setData(data);
          if (!this.stompJsClient) {
            this.connectWebSocket();
          }
          this.isJobLoaded = true;
        } catch (error) {
          this.showSnackbarError(error);
        }
      }
    },
    onSendMessageThroughWebSocket(message) {
      let publishParams = {
        destination: `/app/jobs/cost/calculate/${this.job.referenceId}/${this.wsSessionId}`,
        body: JSON.stringify(message),
      }
      this.stompJsClient.publish(publishParams);
    },
    connectWebSocket() {
      this.stompJsClient = WebSockets.createStompJsClient(this.onConnectFunction);
    },
    closeWebSocket() {
      this.stompJsClient.deactivate();
    },
    getJobsUpdateTopicName() {
      return WebSockets.getWebSocketJobsTopic() + "/" + this.job.referenceId + "/updated";
    },
    getJobsCalculationTopicName() {
      return WebSockets.getWebSocketJobsTopic() + "/" + this.job.referenceId + "/calculate/" + this.wsSessionId;
    },
    onConnectFunction() {
      const topic = this.getJobsUpdateTopicName();
      const calculationTopic = this.getJobsCalculationTopicName();

      this.stompJsClient.subscribe(topic, (response) => {
        const message = JSON.parse(response.body);
        const eventType = message.eventType;
        // If true, then the update is made by the other user, so we need to show the conflict modal
        const isUpdateFromDifferentSession = message.originalWsSessionId !== this.wsSessionId;

        if (isUpdateFromDifferentSession && this.$refs.conflictDialog) {
          this.$refs.conflictDialog.openDialog();
        }
        if (eventType) {
          this.$store.commit("setRefreshJobEventType", eventType);
        }
        this.$store.commit("setIsJobSaving", false);
      });
      this.stompJsClient.subscribe(calculationTopic, (response) => {
        let recalculatedJobCostAndRemainingBalance = JSON.parse(response.body);
        this.$refs.jobBuilderPanel.showUpdatedJobCostAndRemainingBalance(recalculatedJobCostAndRemainingBalance);
      });
    },
    async passiveRefresh() {
      if (this.jobReferenceId) {
        try {
          const data = await this.getJobData();
          this.setData(data);
        } catch (error) {
          // console.log(error);
        }
      }
    },
    onRemainingBalance(price) {
      this.remainingBalance = price;
    },
    async getJobData() {
      const response = await Jobs.getRestApi().getJobById(this.jobReferenceId);
      return response.data;
    },
    setData(data) {
      this.job = data;
    },
    onInstallationAdded() {
      this.$refs.jobBuilderPanel.refresh();
    },
    onInstallationDeleted() {
      this.$refs.jobBuilderPanel.refresh();
    },
    onLineItemsSaved() {
      this.showSnackbarSuccess("Job Updated");
      this.isDirty = false;
    },
    onLineItemsDirty() {
      this.isDirty = true;
    },
    onChangesDetected(value) {
      this.installationsReadOnly = value;
    },
    showSnackbarError(error) {
      this.$refs.nav.showError(error);
    },
    showSnackbarSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    onJobFulfilled() {
      this.isDirty = false;
      this.$refs.inlineGrids.onJobFulfilled();
      this.passiveRefresh();
    },
    onJobInvoiced() {
      this.isDirty = false;
      this.$refs.inlineGrids.onJobInvoiced();
      this.passiveRefresh();
    },
    onRefreshInlineGrid() {
      this.refreshInlineGrids();
    },
    refreshAll() {
      this.isDirty = false;
      this.refresh();
      this.refreshInlineGrids();
      this.$refs.jobBuilderPanel.refresh();
      if (this.$refs.jobBuilderPanel) {
        this.$refs.jobBuilderPanel.clearChangesDetectedAfterSpecificFIleTypeUpdated();
      }
    },
    onSubmitDiscount() {
      this.showSnackbarSuccess('Discount added');
      this.refresh();
      this.refreshInlineGrids();
      this.$refs.jobBuilderPanel.refresh();
    },
    onSubmitChargeback(payload) {
      this.$refs.jobBuilderPanel.addChargeback(payload);
    },
    onSubmitInstallationIssue(payload) {
      this.$refs.jobBuilderPanel.addInstallationIssue(payload);
    },
    onSubmitRefund(payload) {
      this.$refs.jobBuilderPanel.addRefund(payload);
    },
    onLeaveJob(next) {
      next();
    },
    onContinueJob() {
    },
    isJobDirty() {
      return this.isDirty;
    },
    onOpenJob(referenceId) {
      this.$router.push(`/jobs/details/${referenceId}`);
    },
    onOpenSubjob(referenceId) {
      this.$router.push(`/jobs/details/${referenceId}`);
    },
    onProductItemsUpdated(items) {
      this.productItems = items;
    },
    onLaborItemsUpdated(items) {
      this.$data.laborItems = items;
    },
    nextActionToShow(allActions) {
      this.hasOpenNextActions = this.ifNextActionDatesHaveOpenStatus(allActions);
      if (allActions.length > 0) {
        this.nextAction = this.findNearestNextActionDate(allActions);
      }
    },
    findNearestNextActionDate(allActions) {
      let nextDates = allActions.filter(action => {
        if (action.status === Constants.nextActionStatusOpen) return true;
      }).sort((a, b) => a.dueDate.localeCompare(b.dueDate));
      let nextDate = this.getNearestFutureNextActionDate(nextDates);
      if (nextDate == null) {
        nextDate = nextDates.shift();
      }
      const dueDateTime = DateUtils.stringToDateTime(nextDate.dueDate, 'y/MM/dd');
      return nextDate ? DateFormatter.formatDateInMMDDYYYYFormat(dueDateTime) : null;
    },
    openNextActionTab() {
      this.$refs.inlineGrids.nextActionsTabSelected();
    },
    getNearestFutureNextActionDate(nextDates) {
      let today = new Date().setHours(0, 0, 0, 0);
      return nextDates.find(action => Date.parse(action.dueDate) >= today);
    },
    ifNextActionDatesHaveOpenStatus(allActions) {
      return allActions.some(action => action.status === Constants.nextActionStatusOpen);
    },
    setCustomer(customer) {
      this.customer = customer;
    },
    onUpdateJobData(updated) {
      this.job = updated;
    },
    onSetLead(lead) {
      this.lead = lead;
    }
  },
};
</script>
