<template>
  <v-form ref="form">
    <v-container>
      <v-row class="mt-2 pa-2">
        <v-col md="12">
          <v-select
              v-model="installation"
              label=" * Installation with Issue"
              placeholder=" "
              persistent-placeholder
              :items="installations"
              item-text="jobLineItem.description"
              item-value="id"
              :rules="[rules.requiredInstallation]"
              return-object
              hide-details
              :validDataPopulated="validDataPopulated"
              attach
          />
        </v-col>
        <v-col md="6">
          <radio-buttons
              :selectOptions="selectOptions"
              :label="'Is there Material associated with this issue?'"
              @onValueChanged="materialsAssociatedValueChanged"
          />
          <v-text-field
              class="mr-1"
              v-model="materialsAmount"
              :label="materialsAssociated ? '* Amount' : 'Amount'"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              :rules="[rules.requiredMaterial, rules.isCurrencyFormat]"
              v-show="materialsAssociated"
              hide-details
              type="number"
          />
        </v-col>
        <v-col md="6">
          <radio-buttons
              :selectOptions="selectOptions"
              :label="'Is there Labor associated with this issue?'"
              @onValueChanged="laborsAssociatedValueChanged"
          />
          <v-text-field
              class="ml-1"
              v-model="laborAmount"
              :label="laborAssociated ? '* Amount' : 'Amount'"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              :rules="[rules.requiredLabor, rules.isCurrencyFormat]"
              v-show="laborAssociated"
              hide-details
              type="number"
          />
        </v-col>

        <v-col md="9">
          <v-checkbox
              v-show="materialsAssociated"
              class="ma-0 pa-1 pt-5"
              color="primary"
              label="Installer Reimbursable"
              v-model="installerReimbursable"
              hide-details
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Rules from "../../../assets/rules";
import Constants from "../../../assets/constants";
import RadioButtons from "../../common/form/RadioButtons.vue";

const formDefaults = {
  materialsAssociated: false,
  materialsAmount: null,
  laborAssociated: false,
  laborAmount: null,
  installerId: null,
  installation: null,
  installerReimbursable: false,
};

export default {
  components: {
    RadioButtons
  },

  props: {
    job: Object,
    jobReferenceId: String,
    installations: Array,
  },

  data() {
    return Object.assign(
        {},
        {
          error: null,
          selectOptions: Constants.defaultSelectOptionsWithKey,
          rules: {
            requiredInstallation: (value) => {
              return !!value || "Installation Required"
            },
            requiredLabor: (value) => {
              if (!value && !this.laborAssociated) {
                return true;
              }
              return !!value || "Labor Required"
            },
            requiredMaterial: (value) => {
              if (!value && !this.materialsAssociated) {
                return true;
              }
              return !!value || "Material Required"
            },
            isCurrencyFormat: Rules.isCurrencyFormat
          },
        },
        formDefaults
    );
  },

  computed: {
    validDataPopulated() {
      const {laborAmount, materialsAmount, installation} = this;
      return {laborAmount, materialsAmount, installation};
    }
  },

  watch: {
    materialsAssociated(newMaterialsAssociated) {
      if (newMaterialsAssociated === false) {
        this.materialsAmount = null;
        this.installerReimbursable = false;
      }
    },
    laborAssociated(newLaborRequired) {
      if (newLaborRequired === false) {
        this.laborAmount = null;
      }
    },
    validDataPopulated: {
      handler(value) {
        if (value.installation && (value.laborAmount || value.materialsAmount)) {
          this.$emit('disableNextButton', false);
        } else if (!value.laborAmount || !value.materialsAmount) {
          this.$emit('disableNextButton', true);
        }
      },
      deep: true
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {
      this.loadForm(formDefaults);
      this.$refs.form.resetValidation();
    },

    getPayload() {
      const payload = {};

      if (this.materialsAssociated) {
        payload.materialsAssociated = this.materialsAssociated;
        payload.materialsAmount = this.materialsAmount;
      } else {
        payload.materialsAssociated = false;
        payload.materialsAmount = null;
      }

      if (this.laborAssociated) {
        payload.laborAssociated = this.laborAssociated;
        payload.laborAmount = this.laborAmount;
      } else {
        payload.laborAssociated = false;
        payload.laborAmount = null;
      }

      payload.installation = this.installation;
      payload.installerReimbursable = this.installerReimbursable;
      return payload;
    },

    loadForm(payload) {
      if (payload.materialsAssociated) {
        this.materialsAssociated = payload.materialsAssociated;
        this.materialsAmount = payload.materialsAmount;
      } else {
        this.materialsAssociated = false;
        this.materialsAmount = null;
      }

      if (payload.laborAssociated) {
        this.laborAssociated = payload.laborAssociated;
        this.laborAmount = payload.laborAmount;
      } else {
        this.laborAssociated = false;
        this.laborAmount = null;
      }

      this.installation = payload.installation;
      this.installerId = payload.installerId;
      this.installerReimbursable = payload.installerReimbursable;
    },
    materialsAssociatedValueChanged(value) {
      this.materialsAssociated = value;
    },
    laborsAssociatedValueChanged(value) {
      this.laborAssociated = value;
    }
  },
};
</script>

<style scoped>
.checkbox-container.v-input--selection-controls {
  padding: 0;
  margin: 0;
}

.contract-prices {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 5px;
}
</style>
