<template>
  <dx-data-grid
      ref="itemsGrid"
      :dataSource="slots"
      :showBorders="true"
      :hoverStateEnabled="true"
      :loadPanel="{ enabled: true }"
      :columnMinWidth="50"
  >
    <template #slot-status="cellData">
      <div class="slot-status-container">
        <div>
          <span :class="getClassForSlotStatus(cellData)">{{ formatStatusName(cellData.data.status) }} </span>
        </div>
        <div class="slot-status-capability-name-group" v-if="isStatusScheduled(cellData)">
          <div class="slot-status-line"></div>
          <img class="slot-status-capability-icon" :src="getCapabilityIcon(cellData.data.capabilityName)" alt=""/>
          <div class="slot-scheduled-color slot-scheduled-capability">
            {{ getCapabilityName(cellData.data.capabilityName) }}
          </div>
        </div>
      </div>
    </template>
    <template #delete="cellData">
      <div>
        <delete-row-cell-template
            :cell-data="cellData"
            :active="isSlotDeletable(cellData)"
            disabledReason="Crew has only one slot for this day"
            @deleteClicked="onDeleteClicked"
        />
      </div>
    </template>

    <template #job-reference-id="cellData">
      <div class="job-reference-id-container">
        <a target="_blank" @click="openNewTab(cellData.data.jobReferenceId)">{{ cellData.data.jobReferenceId }}</a>
      </div>
    </template>
    <dx-filter-row :visible="true"/>
    <dx-column
        data-field="status"
        caption="Status"
        cellTemplate="slot-status"
        width="250"
    />
    <dx-column
        data-field="installerCrewName"
        width="300"
        caption="Crew name"
    />
    <dx-column
        data-field="jobReferenceId"
        width="130"
        cellTemplate="job-reference-id"
        caption="Job Id"
    />
    <dx-column
        v-if="!isPassedDate"
        caption="Delete"
        cellTemplate="delete"
        width="70"
        :allowHeaderFiltering="false"
    />
  </dx-data-grid>
</template>

<script>
import {DxColumn, DxDataGrid, DxFilterRow, DxHeaderFilter} from "devextreme-vue/ui/data-grid";
import PoEtaCellTemplate from "../../../components/common/grid/cellTemplates/PoEtaCellTemplate.vue";
import DeleteRowCellTemplate from "../../../components/common/grid/cellTemplates/DeleteRowCellTemplate.vue";
import DateUtils from "../../../assets/utils/dateUtils";
import Constants from "../../../assets/constants";
import {CapabilityIcons} from "@/assets/installerTracker/capabilityIcons";

export default {
  components: {
    DeleteRowCellTemplate,
    PoEtaCellTemplate,
    DxFilterRow,
    DxHeaderFilter,
    DxDataGrid,
    DxColumn
  },

  props: {
    slots: Array | null,
    date: Object | null
  },

  data() {
    return {};
  },

  computed: {
    Constants() {
      return Constants
    },
    isPassedDate() {
      return this.date && DateUtils.isPastDate(this.date);
    }
  },

  methods: {
    openNewTab(jobReferenceId) {
      const routeData = this.$router.resolve(`/jobs/details/${jobReferenceId}`);
      window.open(routeData.href, '_blank');
    },
    isStatusScheduled(cellData) {
      return cellData.data.status === Constants.installerSlotStatuses.SCHEDULED;
    },
    getCapabilityName(capability) {
      return capability.toUpperCase();
    },
    getCapabilityIcon(capability) {
      return CapabilityIcons[capability];
    },
    isSlotDeletable(cellData) {
      return (this.slots.filter(slot => cellData.data.installerCrewName === slot.installerCrewName).length > 1)
          && cellData.data.status !== Constants.installerSlotStatuses.SCHEDULED;
    },
    formatStatusName(status) {
      return status.charAt(0) + status.slice(1).toLowerCase();
    },
    getClassForSlotStatus(cellData) {
      if (cellData.data.status === Constants.installerSlotStatuses.AVAILABLE) {
        return 'slot-status-name slot-available';
      } else {
        return 'slot-status-name slot-scheduled slot-scheduled-color';
      }
    },
    onDeleteClicked(cellData) {
      this.$emit('deleteCLicked', cellData);
    },
    resetGridState() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.clearSorting();
    },
    getGridInstance() {
      return this.$refs.itemsGrid.instance;
    },
  }
}


</script>


<style scoped>
.slot-available {
  color: #009688;
  border: 1px solid #009688;
}

.slot-scheduled {
  border: 1px solid #FF9F00;
}

.slot-scheduled-capability {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  white-space: wrap;
  max-width: 80px;
  padding-left: 4px;
  line-height: 15px;
  margin: auto
}

.slot-scheduled-color {
  color: #FF9F00;
}

.slot-status-name {
  border-radius: 20px;
  padding: 5px 17px;
  width: 110px;
}

.slot-status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 30px;
}

.slot-status-line {
  border-right: 2px solid;
  padding-right: 3px;
  border-color: #D3D3D3;
}

.slot-status-capability-icon {
  padding-left: 5px;
  width: 35px;
}
.slot-status-capability-name-group{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

</style>
