<template>
  <v-row>
    <v-col cols="12" class="mt-2 pt-1">
      <span class="label"> {{ label }} </span>
      <v-btn-toggle
          class="ml-5"
          :value="checkInModeEnabled"
          color="deep-purple-accent-3"
          mandatory
          @change="selectionChanged"
      >
        <v-btn
            small
            :value="false"
            :color="checkInModeEnabled ? 'default' : 'primary'"
            :class="{ 'white-text': !checkInModeEnabled }"
        >
          Close Item
        </v-btn>
        <v-btn
            small
            :value="true"
            :color="checkInModeEnabled ? 'primary' : 'default'"
            :class="{ 'white-text': checkInModeEnabled }"
        >
          Check-In
        </v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: "Select Check-In Mode:"
    },
    checkInModeEnabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    selectionChanged() {
      this.$emit('selectionChanged');
    }
  }
}
</script>

<style scoped>
.white-text {
  color: white !important;
}

span.label {
  font-size: 14px;
}
</style>
