<template>
  <modal-dialog
    ref="dialog"
    title="Add Overbill Entry"
    width="400"
    :visible="dialogVisible"
    submitLabel="Add Overbill"
    cancelLabel="Cancel"
    @submitClicked="onAddClicked"
    @cancelClicked="onCancelClicked"
    :submitEnabled="true"
  >
    <v-form ref="form">
      <v-container>
        <v-row class="mt-4">
          <v-col md="12">
            <date-picker-with-type-in-text-field
              v-if="overbills"
              v-model="effectiveOn"
              label="Overbill Start Date"
              validationName="Start date"
              required
              :allowedDates="allowedDates"
            />
          </v-col>
          <v-col md="12">
            <v-text-field
              v-model="overbill"
              label="Overbill"
              placeholder=" "
              persistent-placeholder
              prefix="$"
              type="number"
              :rules="[rules.required, rules.isCurrencyFormat]"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import moment from "moment";
import Rules from "@/assets/rules.js";
import ProductCatalogItems from "@/rest/catalogItems";
import ModalDialog from "@/components/common/ModalDialog.vue";
import DatePickerWithTypeInTextField from "@/components/common/form/DatePickerWithTypeInTextField.vue";

const formDefaults = {
  effectiveOn: null,
  overbill: null,
};

export default {
  data() {
    return Object.assign(
      {
        dialogVisible: false,
        error: null,
        reservedDates: [],
      },
      formDefaults,
      {
        rules: {
          required: Rules.required,
          isCurrencyFormat: Rules.isCurrencyFormat,
        },
      }
    );
  },

  components: {
    ModalDialog,
    DatePickerWithTypeInTextField,
  },

  props: { catalogItemId: Number, overbills: Array },

  watch: {
    overbills: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.length && newVal !== oldVal) {
          this.createArrayOfReservedCostDates();
        }
      },
    },
  },

  methods: {
    reset() {
      Object.assign(this.$data, formDefaults);
      this.$refs.form.reset();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    openDialog() {
      this.reset();
      this.dialogVisible = true;
    },
    closeDialog () {
      this.dialogVisible = false;
    },
    showError (error) {
      this.error = error;
    },
    createArrayOfReservedCostDates () {
      const { overbills } = this.$props;
      let reservedDates = [];
      for (let i = 0; i < overbills.length; i++) {
        let effectiveOn = moment(overbills[i].effectiveOn).format("YYYY-MM-DD");
        reservedDates.push(effectiveOn);
      }
      this.$data.reservedDates = reservedDates;
    },
    allowedDates(val) {
      const { reservedDates } = this.$data;
      return reservedDates.includes(val) ? false : true;
    },
    onAddClicked() {
      if (this.$refs.form.validate(true)) {
        const component = this;
        let overbill = {
          overbill: this.overbill,
          effectiveOn: this.effectiveOn,
        };
        ProductCatalogItems.getRestApi()
          .addCatalogItemOverbill(this.catalogItemId, overbill)
          .then(function (response) {
            component.closeDialog();
            component.$emit("overbillAdded", response.data);
          })
          .catch(function (error) {
            component.$refs.dialog.showError(error.response.data.message);
          });
      }
    },
    onCancelClicked() {
      this.dialogVisible = false;
    },
  },
};
</script>
