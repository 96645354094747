<template>
  <v-form ref="itemsToReturnForm" @submit.prevent="onSubmitChooseItems">
    <v-row v-if="hasVendorChargebacks">
      <div class="chargeback-message">
        <div class="text">There is a chargeback added to a job. Please, delete a chargeback and try again</div>
      </div>
    </v-row>
    <v-row v-else class="mt-0" justify-start>
      <return-from-job-items-container
          ref="selectCatalogItems"
          :dataSource="checkedOutItems"
          @selectedItem="clearError"
          @showError="showError"
      />
      <div class="error-message-container">
        <p class="text">{{ error }}</p>
      </div>
    </v-row>
  </v-form>
</template>

<script>

import ReturnFromJobItemsContainer from "./ReturnFromJobItemsContainer.vue";

export default {
  components: {
    ReturnFromJobItemsContainer
  },

  props: {
    checkedOutItems: {
      type: Array,
      required: true,
      default: () => []
    },
    hasVendorChargebacks: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      alreadySelectedItems: [],
      selected: [],
      error: null,
      returnProductItems: []
    };
  },

  computed: {
    isNextButtonValid() {
      return !this.hasVendorChargebacks;
    }
  },

  methods: {
    onSubmitChooseItems() {
      if (this.isNextButtonValid) {
        this.$emit('nextClicked');
      }
    },
    onCancelClicked() {
      this.$emit('cancelClicked');
    },
    clearError() {
      this.$data.error = null;
    },
    showError(error) {
      this.error = error;
    },
    getSelectedItems() {
      return this.$refs.selectCatalogItems.getSelectedItems();
    },
    reset() {
      this.$refs.selectCatalogItems.reset();
      this.clearError();
    }
  }
};
</script>

<style scoped>
.error-message-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.chargeback-message {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  color: red;
  font-size: 15px;
  text-align: center;
}
</style>
