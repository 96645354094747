import numeral from "numeral";
import DateFormatter from "./dateFormatter";

const skipField = ['id'];
const distributionVendor = 'distributionVendor'

const FormattingHelper = {
    asCommaDelimitedString(array) {
        return array.map(item => {
            if (item && item.name) {
                return item.name;
            }
        }).join(", ");
    },
    textValueFromBoolean(value) {
        return value ? 'Yes' : 'No';
    },
    quantityFormat(quantity) {
        return numeral(quantity.boxQuantity).format("0,0.[0000]");
    },
    availableFormat(quantity) {
        return numeral(quantity).format("0,0.[0000]");
    },
    objectAsHyphenDelimitedString(object, type) {
        let fullProps = '';
        if (type === distributionVendor) {
            fullProps += object.vendor.name + " - ";
            fullProps += this.asCommaDelimitedString(object.branches);
        }
        return fullProps;
    },
    removeNonNumericCharacters(str) {
        return Number(str.replace(/[^0-9.]+/g, ""));
    },
    getPromoCodeName(leadExtraDetails) {
        return leadExtraDetails.promoCode ? leadExtraDetails.promoCode.key : " ";
    },
    getFormattedLeadRoomTypes(leadExtraDetails) {
        return (!leadExtraDetails.roomTypes || leadExtraDetails.roomTypes.length === 0)
            ? " "
            : this.asCommaDelimitedString(leadExtraDetails.roomTypes);
    },
    getFormattedLeadProductTypes(leadExtraDetails) {
        return (!leadExtraDetails.productTypes || leadExtraDetails.productTypes.length === 0)
            ? " "
            : this.asCommaDelimitedString(leadExtraDetails.productTypes);
    },
    getFormattedLeadAppointmentDate(lead) {
        return lead.appointmentDate
            ? DateFormatter.formatDateInMMDDYYYYFormat(lead.appointmentDate)
            : null;
    },
    addLineBreakAfterFirstPeriod(string) {
        return string.replace(/\.(?![^[]*\])/, ". \n");
    },
    capitalizeFirstLetterIfUppercase(string) {
        return this.uppercaseRegexp(string)
            ? string[0].toUpperCase() + string.slice(1).toLowerCase()
            : string;
    },
    uppercaseRegexp(string) {
        const pattern = /\p{Lu}/u;
        return pattern.test(string);
    },
    convertFirstLetterToLowerCase(obj) {
        if (obj === null || typeof obj !== 'object') {
            throw new Error('Input must be a non-null object');
        }
        return Object.keys(obj).reduce((result, key) => {
            const lowercaseKey = key.charAt(0).toLowerCase() + key.slice(1);
            result[lowercaseKey] = obj[key];
            return result;
        }, {});
    },
    formatPlanItemsToOverviewItems(transferPlan) {
        let fulfilledItems = transferPlan.overviewList;
        return fulfilledItems.map(ol => {
            return {
                Id: ol.id,
                BoxQuantity: ol.boxQuantity,
                DyeLot: ol.dyeLot,
                ProductColor: ol.color,
                ProductStyle: ol.style,
                ProductType: ol.productType,
                RollNumber: ol.rollNumber,
                QuantityAvailable: ol.quantityAvailable,
                QuantityToTransfer: ol.quantityToTransfer,
                VendorCost: ol.vendorCost,
                TotalValue: ol.totalValue,
                InternalSku: ol.internalSku
            };
        });
    },
    formatCellPhoneWithoutHyphens(phone) {
        return phone.replace(/\D/g, '');
    },
    formatItemOnBranchCheckIn(item) {
        const {TransferNumber, ProductType, Style, Color, RollNumber, DyeLot, ExpectedQuantity, Rounded} = item.data;
        const parts = [
            TransferNumber,
            ProductType,
            Style,
            Color,
            RollNumber || '',
            DyeLot || ''
        ].filter(part => part).join(' - ');
        return `${parts}. Quantity Expected: ${ExpectedQuantity}, Quantity received: ${Rounded}`;
    }
}

export default FormattingHelper;
