<template>
  <v-container>
    <h4 class=""> {{ header }} </h4>
    <v-row class="ma-auto position-relative">
      <v-col cols="9">
        <v-otp-input
            v-model="otp"
            class="mt-3"
            length="6"
            type="number"
            placeholder="-"
            :disabled="loading"
            autofocus
        />
      </v-col>
      <v-col cols="3" class="ma-0 mt-3">
        <v-btn
            id="verify-opt-button"
            class="full-width white--text"
            color="#59b"
            :disabled="!otp || otp.length < 6"
            @click="verifyOtp(otp)"
        >
          <span class="verify-text">Verify</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0 ml-2" v-if="showDontAskMfaSwitch">
      <v-switch
          color="primary"
          v-model="dontAskMfaAgainEnabled"
          :append-icon="computedIcon"
          :label="dontAskMfaLabel"
      />
    </v-row>

    <v-divider class="ma-3"/>

    <go-back-button
        :showGoBackButton="showGoBackButton"
        :disabled="isGoBackButtonDisabled"
        @goBackClicked="onGoBackClicked"
    />

  </v-container>
</template>

<script>
import GoBackButton from "@/components/common/buttons/GoBackFullWidthButton.vue";

export default {
  components: {GoBackButton},

  props: {
    hasOTPVerificationError: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: 'Enter 6-digit code from the app'
    },
    id: {
      type: String,
      default: 'verify-otp-button'
    },
    showGoBackButton: {
      type: Boolean,
      default: true
    },
    userMfaSettings: {
      type: Object,
      required: false
    }
  },

  watch: {
    hasOTPVerificationError: {
      immediate: true,
      handler(val) {
        if (val) {
          this.clear();
        }
      }
    },
    otp: {
      immediate: true,
      handler(val) {
        if (!val || val.length === 0) {
          this.clear();
        }
      }
    }
  },

  computed: {
    dontAskMfaLabel() {
      return `Remember this device for ${this.userMfaSettings.dontAskMfaDays} days to skip 6-digit code during login`;
    },
    showDontAskMfaSwitch() {
      return !this.userMfaSettings?.active;
    },
    computedIcon() {
      return this.showDontAskMfaSwitch && this.dontAskMfaAgainEnabled ? 'mdi-ghost-outline' : 'mdi-ghost-off-outline';
    },
    isGoBackButtonDisabled() {
      return !!this.otp;
    }
  },

  data() {
    return {
      loading: false,
      otp: null,
      dontAskMfaAgainEnabled: false
    };
  },

  methods: {
    verifyOtp(userOTP) {
      this.loading = true;
      this.$emit('otpEntered', this.buildVerificationObject(userOTP));
      this.loading = false;
    },
    onGoBackClicked() {
      this.otp = null;
      this.loading = false;
      this.$emit('backToLogin');
    },
    clear() {
      this.otp = null;
    },
    buildVerificationObject(userOTP) {
      return {
        otp: userOTP,
        dontAskMfaAgainEnabled: this.dontAskMfaAgainEnabled
      }
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
.position-relative {
  position: relative;
}
.go-back-text {
  width: 100%;
  padding-rigth: 24px;
}
</style>
