<template>
  <drawer-form
      ref="leadAddressDrawerForm"
      :title="title"
      formId="leadAddressDrawerForm"
      :submitLabel="submitLabel"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-container grid-list-lg text-xs-center>
      <lead-address-form
          ref="leadAddressForm"
          :formType="formType"
          :readonly="false"
          :includeUploadField="true"
          :customer="customer"
          @onError="onError"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import LeadAddressForm from "@/components/leads/LeadAddressFormEditable.vue";
import DrawerForm from "../common/form/DrawerForm";

export default {
  components: {
    DrawerForm,
    LeadAddressForm
  },

  props: [
    "title",
    "submitLabel",
    "customer",
    "hideCancel",
    "formType",
    "inProgress"
  ],

  data() {
    return {};
  },

  methods: {
    resetForm() {
      this.$refs.leadAddressDrawerForm.resetValidation();
      this.$refs.leadAddressForm.reset();
      this.$refs.leadAddressForm.setFormBackToDefault();
    },
    onCancelClicked() {
      this.$emit("cancelClicked");
    },
    onSubmitClicked() {
      let valid = this.$refs.leadAddressDrawerForm.validate();
      if (valid) {
        const payload = this.$refs.leadAddressForm.buildPayload();
        this.$emit("submitClicked", payload);
      }
    },
    loadDrawerForm(data) {
      this.$refs.leadAddressForm.loadForm(data);
    },
    setFormBackToOriginalData(data) {
      this.$refs.leadAddressForm.setFormBackToOriginalData(data);
    },
    onError(error) {
      this.$emit("onError", error);
    }
  }
};
</script>
