<template>
  <v-card-actions class="pa-1">
    <v-spacer></v-spacer>
    <div>
      <v-btn
          class="mr-0 ml-2"
          large
          color="primary"
          outlined
          @click="onGetPreviousStep"
      >
        {{ backName }}
      </v-btn>
      <v-btn
          class="mr-0 ml-2"
          v-if="!hideNextStep"
          large
          color="primary"
          @click="onGetNextStep"
          :disabled="disableNextStep"
      >
        {{ nextName }}
      </v-btn>
    </div>
  </v-card-actions>
</template>

<script>
export default {
  props: {
    nextName: String,
    backName: String,
    disableNextStep: {
      type: Boolean,
      default: false
    },
    hideNextStep: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onGetPreviousStep() {
      this.$emit("getPreviousStep");
    },
    onGetNextStep() {
      this.$emit("getNextStep");
    }
  }
};
</script>
