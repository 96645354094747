const BoxQuantity = {
    roundValueToBoxQuantity(needed, boxQuantity, fractionDigits = 4) {
        if (boxQuantity) {
            const whole = Math.floor(needed / boxQuantity);
            const diff = Math.floor((needed - whole * boxQuantity) * 10000) / 10000;
            const adjusted = diff > 0 ? (whole + 1) * boxQuantity : whole * boxQuantity;
            return adjusted.toFixed(fractionDigits);
        } else {
            return needed;
        }
    },
    formatBoxQuantity(boxQuantity) {
        return boxQuantity ? Number(boxQuantity).toFixed(4) : null;
    },
    getFractionDigitsAmount(boxQuantity) {
        return boxQuantity?.toString().split('.')[1]?.length || 0;
    },
    hasNonZeroThirdAndFourthDigitsAfterDot(boxQuantity) {
        let valueStr = boxQuantity.toFixed(4);
        let thirdDigit = valueStr.charAt(valueStr.indexOf('.') + 3);
        let fourthDigit = valueStr.charAt(valueStr.indexOf('.') + 4);
        return thirdDigit !== '0' || fourthDigit !== '0';
    }
}
export default BoxQuantity;
