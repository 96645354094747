import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Job Next Actions.
 */
export class JobNextAction {
    dueDate: string = "";
    nextActionNote: string = "";
    creator: string = "";

    constructor() {
    }
}

export class JobNextActionStatus {
    id: number = 0
    name: string = ""
    constructor() {
    }
}

export interface JobNextActionsRestApi {
    getNextActionsByJobId(id: number): AxiosPromise<JobNextAction>;

    createJobNextAction(id: number, request: JobNextAction): AxiosPromise<JobNextAction>;

    updateJobNextAction(nextActionId: number, request: number): AxiosPromise<JobNextAction>;

    getAllNextActionStatuses(): AxiosPromise<JobNextActionStatus []>;
}

class Implementation implements JobNextActionsRestApi {
    getAllNextActionStatuses(): AxiosPromise<JobNextActionStatus []> {
        return Axios.axiosInstance().get<JobNextActionStatus []>(`/jobs/next_actions/statuses`);
    }

    createJobNextAction(id: number, request: JobNextAction): AxiosPromise<JobNextAction> {
        return Axios.axiosInstance().post<JobNextAction>(`/jobs/next_actions/${id}`, request);
    }

    getNextActionsByJobId(id: number): AxiosPromise<JobNextAction> {
        return Axios.axiosInstance().get<JobNextAction>(`/jobs/next_actions/${id}`);
    }

    updateJobNextAction(nextActionId: number, request: number): AxiosPromise<JobNextAction> {
        return Axios.axiosInstance().patch<JobNextAction>(`/jobs/next_actions/${nextActionId}`, request);
    }
}

const JobNextActions = {
    getRestApi(): JobNextActionsRestApi {
        return new Implementation();
    }
};

export default JobNextActions;
