<template>
  <v-form ref="closePOReasonCodeForm">
    <v-container>
      <v-select
          v-model="selectedReasonCodeId"
          :items="closePOReasonCodes"
          label="* Reason Code"
          item-text="name"
          item-value="id"
          placeholder=" "
          persistent-placeholder
          :rules="[rules.required]"
      />
    </v-container>
  </v-form>
</template>

<script>
import Rules from "../../../assets/rules";
import PurchaseOrderReasonCodes from "../../../rest/purchaseOrderReasonCodes";

export default {
  components: {},

  props: {
    isRelatedToJob: {
      type: Boolean,
      required: true
    },
  },

  created() {
    if (!this.closePOReasonCodes) {
      this.getPurchaseOrdersReasonCodes();
    }
  },

  data() {
    return {
      error: null,
      closePOReasonCodes: null,
      selectedReasonCodeId: null,
      rules: {
        required: Rules.required,
      },
    };
  },

  watch: {
    selectedReasonCodeId(newVal) {
      this.selectedReasonCodeId = newVal;
      this.$emit('select', newVal);
    }
  },
  methods: {
    validate() {
      return this.$refs.closePOReasonCodeForm.validate();
    },
    resetClosePOReasonCodeForm() {
      this.getPurchaseOrdersReasonCodes();
      this.restoreToDefaults();
      this.$refs.closePOReasonCodeForm.resetValidation();
    },
    restoreToDefaults() {
      this.selectedReasonCodeId = null;
      this.error = null;
    },
    async getPurchaseOrdersReasonCodes() {
      const response = await PurchaseOrderReasonCodes.getRestApi().getPurchaseOrderReasonCodes();
      this.closePOReasonCodes = response.data.filter(rc => rc.isJobRelated === this.isRelatedToJob);
    }
  },
}

</script>
