<template>
  <div class="slot-content-wrapper">
    <div class="slot-amount" :class="{ 'gray-text': event.extendedProps.availableSlotsAmount === 0 }">
      {{ event.extendedProps.availableSlotsAmount }}/{{ event.extendedProps.totalSlotsAmount }}
    </div>
    <div class="slot-capability" :title="event.title">
      <div class="slot-icon">
        <img :src="getCapabilityIcon(event.title)" alt=""/>
      </div>
      <div class="slot-capability-name">{{ event.title }}</div>
    </div>
  </div>

</template>

<script>
import {CapabilityIcons} from "@/assets/installerTracker/capabilityIcons";

export default {

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  methods: {
    getCapabilityIcon(capability) {
      return CapabilityIcons[capability];
    },
  }
};
</script>

<style lang="scss" scoped>
.slot-content-wrapper {
  display: flex;
  justify-content: start;
  width: 100%;

  .slot-amount {
    min-width: 50px;
    flex-shrink: 0;
    padding: 0 5px;
    border-right: 1px solid #D9D9D9;
    text-align: center;
    align-self: center;
    font-weight: bold;
  }

  .gray-text {
    color: #666666;
  }

  .slot-capability {
    max-width: 70%;
    display: flex;
    align-items: center;
    overflow: hidden;

    .slot-icon {
      display: flex;
      justify-content: center;
      margin: 0 5px;
      width: 30px;
    }

    .slot-capability-name {
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }
}
</style>