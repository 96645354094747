<template>
  <v-layout v-if="market" row wrap>
    <!-- top section for status -->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs2>
          <v-text-field
            label="Reference Id"
            readonly
            v-model="market.referenceId"
            id="market-details-form-ref-id"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex />
        <v-flex xs2>
          <status-dropdown :value="market.active" :readonly="true" />
        </v-flex>
      </v-layout>
      <v-divider class="detailsDivider" />
    </v-flex>

    <v-flex xs5>
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            readonly
            v-model="market.name"
            label="Name"
            id="market-details-form-name"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            readonly
            v-model="market.branchName"
            label="Associated Branch"
            id="market-details-form-associated-branches"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
              v-model="market.minCommissionPercentage"
              label="Minimum Commission Percentage"
              id="market-details-form-min-commission-percentage"
              placeholder=" "
              prefix="%"
              readonly
              persistent-placeholder
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-spacer></v-spacer>
    <v-flex xs5>
      <address-form-with-autocomplete ref="addressForm" :readonly="true" />
    </v-flex>
  </v-layout>
</template>

<script>
import AddressFormWithAutocomplete from "@/components/common/form/AddressFormWithAutocomplete.vue";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";

export default {
  components: {
    StatusDropdown,
    AddressFormWithAutocomplete
  },
  props: {
    market: {
      type: Object,
      required: true
    }
  },

  watch: {
    market: {
      immediate: true,
      handler: function(newVal) {
        if (newVal && newVal.address) {
          this.$refs.addressForm.setAddressData(newVal);
        }
      }
    }
  }
};
</script>
