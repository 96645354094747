import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "@/rest/interfaces/activityLogs";

/**
 *
 */
export class SalesRepsTimeBlock {
  id: number;
  marketId: string;
  name: string;
  startTime: string;
  endTime: string;
  slotDate: string;
  color: string;
  maxSlots: number;
  usedSlots: number;
  salesRepresentativesId: Array<number>;
  referenceId: string;

  constructor(data?: any) {
    data = data || {};
    this.id = data.id;
    this.marketId = data.marketId;
    this.name = data.name;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.slotDate = data.slotDate;
    this.color = data.color;
    this.maxSlots = data.maxSlots;
    this.usedSlots = data.usedSlots;
    this.salesRepresentativesId = data.salesRepresentativesId;
    this.referenceId = data.referenceId;
  }
}

/**
 *
 */
export class SalesRepsTimeBlockTemplate {
  startDate: string;
  endDate: string;
  marketId: string;
  startTime: string;
  endTime: string;
  salesRepresentatives: Array<Object>;
  maxSlots: number;
  name: string;
  defaultDays: Array<string>;
  color: string;

  constructor(data?: any) {
    data = data || {};
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.marketId = data.marketId;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.salesRepresentatives = data.salesRepresentatives;
    this.maxSlots = data.maxSlots;
    this.name = data.name;
    this.defaultDays = data.days;
    this.color = data.color;
  }
}

export class SalesRepsTimeBlockSlot {
  name: string;
  startTime: string;
  endTime: string;
  fullTime: string;

  constructor(data?: any) {
    data = data || {};
    this.name = data.name;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.fullTime = data.fullTime;
  }
}
/**
 * REST interface provided for Sales Representatives Timeblock.
 */
export interface SalesRepsTimeBlockRestApi {
  getByMarketAndDate(marketId: number, startDate: string, endDate: string): AxiosPromise<SalesRepsTimeBlock[]>;
  getByMarketDateAndSalesRep(marketId: number, salesRep: number, startDate: string, endDate: string): AxiosPromise<SalesRepsTimeBlock[]>;
  createTimeBlocks(salesRepsTimeBlockTemplate: SalesRepsTimeBlockTemplate): AxiosPromise<SalesRepsTimeBlock[]>;
  deleteTimeBlock(id: number): AxiosPromise<SalesRepsTimeBlock>;
  deleteTimeBlocksBatch(marketId: string, startTime: string, endTime: string, startDate: Date): AxiosPromise<SalesRepsTimeBlock[]>;
  updateTimeBlock(id: number, timeblock: SalesRepsTimeBlock): AxiosPromise<SalesRepsTimeBlock>;
  updateTimeBlocksBatch(startDate: Date, timeblock: SalesRepsTimeBlock): AxiosPromise<SalesRepsTimeBlock[]>;
  validateTimeBlockNotLeadAssigned(
    maxSlots: string,
    marketId: string,
    startDate: string,
    startTime: string,
    endTime: string,
    listSalesRepsId: number[]
  ): AxiosPromise<string>;
  validateIfDeleteTimeBlock(marketId: string, startDate: string, startTime: string, endTime: string): AxiosPromise<string>;
  getSalesRepresentativeTimeBlocksLogs(timeBlockId: number): AxiosPromise<ActivityLog[]>;
  getSalesRepresentativeTimeBlocksPossibleSlots(): AxiosPromise<SalesRepsTimeBlockSlot[]>;

  getAssignmentStatus(timeBlockId: number): AxiosPromise<boolean>;

}

/**
 * REST implementation provided for users.
 */
class Implementation implements SalesRepsTimeBlockRestApi {
  getByMarketAndDate(marketId: number, startDate: string, endDate: string): AxiosPromise<SalesRepsTimeBlock[]> {
    return Axios.axiosInstance().get<SalesRepsTimeBlock[]>(
        `/sales-rep-time-block/market/${marketId}/start-date/${startDate}/end-date/${endDate}`
    );
  }

  getByMarketDateAndSalesRep(marketId: number, salesRep: number, startDate: string, endDate: string): AxiosPromise<SalesRepsTimeBlock[]> {
    return Axios.axiosInstance().get<SalesRepsTimeBlock[]>(
        `/sales-rep-time-block/market/${marketId}/sales-rep/${salesRep}/start-date/${startDate}/end-date/${endDate}`
    );
  }

  createTimeBlocks(salesRepsTimeBlockTemplate: SalesRepsTimeBlockTemplate): AxiosPromise<SalesRepsTimeBlock[]> {
    return Axios.axiosInstance().post<SalesRepsTimeBlock[]>(
      "/sales-rep-time-block/",
      salesRepsTimeBlockTemplate
    );
  }

  deleteTimeBlock(timeBlockId: number): AxiosPromise<SalesRepsTimeBlock> {
    return Axios.axiosInstance().delete(`/sales-rep-time-block/${timeBlockId}`);
  }

  deleteTimeBlocksBatch(marketId: string, startTime: string, endTime: string, startDate: Date): AxiosPromise<SalesRepsTimeBlock[]> {
    return Axios.axiosInstance().delete(
      `/sales-rep-time-block/batch-delete/${marketId}/startDate/${startDate}/${startTime}/to/${endTime}`
    );
  }

  updateTimeBlock(id: number, timeblock: SalesRepsTimeBlock): AxiosPromise<SalesRepsTimeBlock> {
    return Axios.axiosInstance().put<SalesRepsTimeBlock>(
      `/sales-rep-time-block/${id}`,
      timeblock
    );
  }

  updateTimeBlocksBatch(startDate: Date, timeblock: SalesRepsTimeBlock): AxiosPromise<SalesRepsTimeBlock[]> {
    return Axios.axiosInstance().put<SalesRepsTimeBlock[]>(
      `/sales-rep-time-block/update-batch/startDate/${startDate} `,
      timeblock
    );
  }

  validateTimeBlockNotLeadAssigned(
    maxSlots: string,
    marketId: string,
    startDate: string,
    startTime: string,
    endTime: string,
    listSalesRepsId: number[]
  ): AxiosPromise<string> {
    return Axios.axiosInstance().get<string>(
      `/sales-rep-time-block/validate-not-assigned-lead/${marketId}/slots/${maxSlots}/${startDate}/${startTime}/to/${endTime}/listSalesRepsId/${listSalesRepsId} `
    );
  }

  validateIfDeleteTimeBlock(marketId: string, startDate: string, startTime: string, endTime: string): AxiosPromise<string> {
    return Axios.axiosInstance().get<string>(
      `sales-rep-time-block/validate-if-delete-timeblock/${marketId}/${startDate}/${startTime}/to/${endTime}`
    );
  }

  getSalesRepresentativeTimeBlocksLogs(timeBlockId: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(
      `sales-rep-time-block/${timeBlockId}/logs`
    );
  }

  getSalesRepresentativeTimeBlocksPossibleSlots(): AxiosPromise<SalesRepsTimeBlockSlot[]> {
    return Axios.axiosInstance().get<SalesRepsTimeBlockSlot[]>(
        `sales-rep-time-block/slots`
    );
  }

  getAssignmentStatus(timeBlockId: number): AxiosPromise<boolean> {
    return Axios.axiosInstance().get<boolean>(
        `sales-rep-time-block/${timeBlockId}/assign-status`
    );
  }
}

const SalesRepsTimeBlocks = {
  getRestApi(): SalesRepsTimeBlockRestApi {
    return new Implementation();
  }
};

export default SalesRepsTimeBlocks;
