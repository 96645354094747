import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Selected inventory items that are included in a Shipment and are going to be checked out.
 */

export class BranchTransferCheckOutItem {
    transferNumber: string;
    transferRequestItemId: number;
    inventoryItemId: number;
    quantity: number

    constructor(transferNumber: string, inventoryItemId: number, quantity: number, transferRequestItemId: number) {
        this.transferNumber = transferNumber;
        this.inventoryItemId = inventoryItemId;
        this.quantity = quantity;
        this.transferRequestItemId = transferRequestItemId;
    }
}

/**
 * Selected inventory items that are included in a Shipment and are going to be checked in.
 */

export class BranchTransferCheckInItem {
    transferNumber: string;
    inventoryItemId: number;
    quantity: number

    constructor(transferNumber: string, inventoryItemId: number, quantity: number) {
        this.transferNumber = transferNumber;
        this.inventoryItemId = inventoryItemId;
        this.quantity = quantity;
    }
}

/**
 * REST interface provided for Inventory Items Branch Transfer interactions
 */
export interface BranchTransferRestApi {
    checkOutBranchTransferInventoryItems(payload: BranchTransferCheckOutItem[]): AxiosPromise<BranchTransferCheckOutItem[]>;

    checkInBranchTransferInventoryItems(payload: BranchTransferCheckInItem[], changeStatusToClosed: boolean): AxiosPromise<BranchTransferCheckInItem[]>;
}

/**
 * REST implementation.
 */
class Implementation implements BranchTransferRestApi {
    checkOutBranchTransferInventoryItems(payload: BranchTransferCheckOutItem[]): AxiosPromise<BranchTransferCheckOutItem[]> {
        return Axios.axiosInstance().post<BranchTransferCheckOutItem[]>(`/branch-transfer/inventory-items/check-out`, payload);
    }
    checkInBranchTransferInventoryItems(payload: BranchTransferCheckInItem[], changeStatusToClosed: boolean): AxiosPromise<BranchTransferCheckInItem[]> {
        return Axios.axiosInstance().post<BranchTransferCheckInItem[]>(`/branch-transfer/inventory-items/check-in/${changeStatusToClosed}`, payload);
    }
}

const BranchTransfers = {
    getRestApi(): BranchTransferRestApi {
        return new Implementation();
    }
};

export default BranchTransfers;
