<template>
  <v-btn
      text
      color="primary"
      :ripple="false"
      @click="onClick"
  >
    {{ value }}
  </v-btn>
</template>

<script>
export default {
  props: {
    cellData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      value:
          parseFloat(this.cellData.data["CommissionPercentage"]).toFixed(2) + "%"
    };
  },
  methods: {
    onClick() {
      this.$emit('editValue', this.cellData);
    }
  }
};
</script>

<style scoped>
.short {
  height: 10px;
}
</style>
