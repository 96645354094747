<template>
  <v-dialog v-model="wizardVisible" persistent :width="800">
    <v-app-bar dense flat dark color="primary">
      <v-app-bar-title>Vendor Chargeback</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">

      <v-stepper-header :step="step">
        <v-stepper-step :complete="step > 1" step="1">
          Enter Note
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Customer
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          50Floor
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 4" step="4">
          Chargeback
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pl-2 pr-2 pb-2">
            <v-textarea
                class="pt-4"
                :auto-grow="true"
                v-model="reason"
                label="* Describe Customer Concerns"
                placeholder=" "
                persistent-placeholder
                :rules="[rules.required, rules.isNotWhitespace, rules.max255]"
                :counter="255"
                ref="customerNote"
            />
            <button-container
                backName="Cancel"
                nextName="Next"
                :hidePreviousStep="true"
                @cancel="closeDialog"
                @getPreviousStep="resetWizard"
                @getNextStep="goToStepTwo"
            />
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card class="pl-2 pr-2 pb-2">
            <customer-discount-form ref="customerDiscountForm" :job="job"/>
            <button-container
                backName="Back"
                nextName="Next"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(1)"
                @getNextStep="goToStepThree"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <fifty-floor-responsibility-form
              ref="fiftyFloorResponsibilityForm"
              :job="job"
          />
          <v-card class="pl-2 pr-2 pb-2">
            <button-container
                backName="Back"
                nextName="Next"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(2)"
                @getNextStep="goToStepFour"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="pl-2 pr-2 pb-2">
            <confirmation-form ref="confirmationForm" :job="job" :productLineItems="productItems"/>
            <button-container
                backName="Back"
                nextName="Submit"
                @cancel="closeDialog"
                @getPreviousStep="goToStep(3)"
                @getNextStep="onSubmitClicked"
            />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "@/components/common/wizard/ButtonContainerWithCancel.vue";
import StepperHeader from "@/components/jobs/vendorChargebackWizard/StepperHeader.vue";
import CustomerDiscountForm from "@/components/jobs/vendorChargebackWizard/CustomerDiscountForm.vue";
import FiftyFloorResponsibilityForm from "@/components/jobs/vendorChargebackWizard/FiftyFloorResponsibilityForm.vue";
import ConfirmationForm from "@/components/jobs/vendorChargebackWizard/ConfirmationForm.vue";
import Rules from "@/assets/rules";

export default {
  components: {
    StepperHeader,
    ButtonContainer,
    CustomerDiscountForm,
    FiftyFloorResponsibilityForm,
    ConfirmationForm,
  },

  props: {
    job: Object,
    contractPrice: Object,
    productItems: Array,
    laborItems: Array,
  },

  data() {
    return Object.assign(
        {},
        {
          reason: null,
          customerAmount: 0,
          fiftyFloorAmount: 0,
          wizardVisible: false,
          error: null,
          step: 1,
          rules: {
            required: (value) => !!value || "Required",
            isNotWhitespace: value => !Rules.isWhitespaceOnly(value),
            max255: value => Rules.maxValue(value, 255)
          },
        }
    );
  },
  watch: {
    wizardVisible(value) {
      if (value) {
        this.$nextTick(this.$refs.customerNote.focus);
      }
    }
  },

  methods: {
    openDialog() {
      this.resetWizard();
      this.wizardVisible = true;
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    showError(error) {
      this.$emit("showError", error);
    },
    showSuccess(message) {
      this.$emit("showSuccess", message);
    },
    resetWizard() {
      this.step = 1;
      this.closeDialog();
      this.resetAllForms();
    },
    goToStepTwo() {
      if (this.$refs.customerNote.validate()) {
        this.goToStep(2);
      }
    },
    goToStepThree() {
      let valid = this.$refs.customerDiscountForm.validate();
      if (valid) {
        const payload = this.$refs.customerDiscountForm.getPayload();
        this.customerAmount = parseFloat(payload.customerAmount);
        this.goToStep(3);
      }
    },
    goToStepFour() {
      let valid = this.$refs.fiftyFloorResponsibilityForm.validate();
      if (valid) {
        const payload = this.$refs.fiftyFloorResponsibilityForm.getPayload();
        this.fiftyFloorAmount = parseFloat(payload.fiftyFloorAmount);
        const input = {
          note: this.reason,
          customerAmount: this.customerAmount,
          fiftyFloorAmount: this.fiftyFloorAmount,
        };
        this.$refs.confirmationForm.load(input);
        this.goToStep(4);
      }
    },
    goToStep(step) {
      this.step = step;
    },
    async onSubmitClicked() {
      let valid = this.$refs.confirmationForm.validate();
      if (valid) {
        const payload = this.$refs.confirmationForm.getPayload();
        let request = {
          reason: this.reason,
          materialCost: payload.materialCost,
          laborCost: payload.laborCost,
          customerPayment: this.customerAmount,
          thirdPartyPayment: 0,
          fiftyFloorAmount: this.fiftyFloorAmount,
          vendorId: payload.vendorId,
          vendorLabel: payload.vendorLabel,
          vendorChargeAmount: payload.vendorChargeAmount,
          installerChargeAmount: 0,
          installerId: null,
          installerLabel: null,
        };
        this.$emit('submitChargeback', request);
        this.resetWizard();
      }
    },
    resetAllForms() {
      //works only with $nextTick in Vuetify 2
      this.$nextTick(() => {
        this.$refs.customerNote.reset();
        this.$refs.customerDiscountForm.reset();
        this.$refs.fiftyFloorResponsibilityForm.reset();
        this.$refs.confirmationForm.reset();
      })
    },
  },
};
</script>
