<template>
  <div>
    <DxTreeList
        id="vendorManagement"
        ref="treeList"
        :data-source="vendorManagementItems"
        v-model="selectedRowKeys"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :expanded-row-keys="expandedRowKeys"
        :auto-expand-all="autoExpandAll"
        :hover-state-enabled="true"
        :allowColumnReordering="true"
        key-expr="id"
        parent-id-expr="parentId"
        @selection-changed="onSelectionChanged"
        @cell-prepared="onCellPrepared"
        @row-updated="onValueChanged($event)"
    >
      <DxScrolling
          mode="standard"
      />
      <DxPaging
          :enabled="true"
          :page-size="defaultInitialPageSize"
      />
      <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="allowedPageSizes"
          :show-info="true"
      />
      <dx-editing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="false"
          mode="row"
      />
      <DxSelection
          mode="single"
      />
      <DxColumn
          data-field="vendorName"
          caption="Vendor Name"
          sort-order="asc"
          :allow-editing="false"
      />
      <DxColumn
          data-field="vendorSku"
          caption="Vendor SKU"
          :allow-editing="true"
      />
      <DxColumn
          data-field="effectiveCostDate"
          caption="Effective Cost Date"
          :allow-editing="false"
          :cell-template="effectiveCostDate"
      />
      <DxColumn
          data-field="isActive"
          caption="Vendor Cost Status"
          data-type="string"
          :customize-text="setStatusName"
          :allow-editing="false"
      />
      <DxColumn
          data-field="cost"
          caption="Vendor Cost"
          data-type="number"
          :customize-text="addDollarPrefix"
          :allow-editing="false"
      />
    </DxTreeList>
    <add-vendor-cost-for-selected-vendor-dialog
        ref="addVendorCostForSelectedVendorDialog"
        :selectedVendor="selectedVendor"
        :activeVendors="activeVendors"
        :filteredDatesBySelectedVendor="filteredDatesBySelectedVendor"
        @onAddNewVendorCost="addVendorCostClicked"
    />
  </div>
</template>
<script>

import {DxColumn, DxEditing, DxPager, DxPaging, DxScrolling, DxSelection, DxTreeList} from 'devextreme-vue/tree-list';
import AddVendorCostForSelectedVendorDialog
  from "../dialogContainers/catalogItemManagement/AddVendorCostForSelectedVendorDialog.vue";
import InlineGridButton from "./InlineGridButton.vue";
import AddVendorRelationDialog from "../../catalogItems/AddSpecificVendorCostDialog.vue";
import InlineGridFooter from "./InlineGridFooter.vue";
import DateFormatter from "../../../assets/dateFormatter";
import moment from "moment";

export default {
  components: {
    DxTreeList,
    DxSelection,
    DxColumn,
    DxEditing,
    DxPager,
    DxPaging,
    DxScrolling,
    InlineGridFooter,
    InlineGridButton,
    AddVendorRelationDialog,
    AddVendorCostForSelectedVendorDialog
  },

  data() {
    return {
      autoExpandAll: false,
      defaultInitialPageSize: 10,
      allowedPageSizes: [10, 20, 50],
      expandedRowKeys: [],
      selectedRowKeys: [],
      selectedVendor: null,
      selectionMode: 'all',
      filteredDatesBySelectedVendor: []
    };
  },

  props: {
    vendorManagementItems: {
      type: Array,
      required: true
    },
    catalogItemId: {
      type: Number,
      required: true
    },
    activeVendors: {
      type: Array,
      required: true
    }
  },

  methods: {
    effectiveCostDate(element, column){
      let date = moment(column.data.effectiveCostDate).utc().format("MM/DD/YYYY");
      element.insertAdjacentHTML("beforeend", date);
    },
    onSelectionChanged({component}) {
      const selectedData = component.getSelectedRowsData(this.selectionMode);
      this.selectedVendor = this.getSelectedVendor(selectedData);
      this.$emit('vendorRowSelected', this.selectedVendor);
      this.filteredDatesBySelectedVendor = this.getVendorCostEffectiveDatesFromSelectedRow(selectedData[0]);
    },
    getVendorCostEffectiveDatesFromSelectedRow(rowData) {

      return this.vendorManagementItems.filter(vmi => {
        if ((vmi.id === rowData.id || vmi.id === rowData.parentId || vmi.parentId === rowData.id || (vmi.parentId === rowData.parentId && vmi.parentId !== 0 && rowData.parentId !== 0 ))
          && (new Date(vmi.effectiveCostDate) >= new Date()) ) {
          return vmi;
        }
      }).map(i => DateFormatter.formatDateInYYYYMMDDFormat(i.effectiveCostDate));
    },
    getSelectedVendor(selectedData) {
      return selectedData[0].vendor || this.vendorManagementItems.find(el => (el.id === selectedData[0].parentId)).vendor;
    },
    addVendorCost() {
      this.$refs.addVendorCostForSelectedVendorDialog.openDialog();
    },
    onCellPrepared(element) {
      this.adjustActiveInactiveColor(element);
      this.hideEditButton(element);
    },
    adjustActiveInactiveColor(element) {
      if (element.rowType === 'data' && element.column.dataField === 'isActive') {
        element.cellElement.style.color = element.data.isActive ? 'green' : 'red';
      }
    },
    hideEditButton(element) {
      if (element.data && element.data.parentId !== 0 && element.column.command === 'edit') {
        element.cellElement.id = 'no-edit-allowed-' + element.rowIndex;
        document.getElementById('no-edit-allowed-' + element.rowIndex).firstChild.style.visibility = 'hidden';
      }
    },
    addDollarPrefix(cellInfo) {
      return '$' + cellInfo.value;
    },
    setStatusName(cellInfo) {
      return cellInfo.value ? 'Active' : 'Inactive';
    },
    addVendorCostClicked(formData) {
      this.$emit('onAddNewVendorCost', formData);
    },
    resetVendorCostForm() {
      this.$refs.addVendorCostForSelectedVendorDialog.resetToDefaults();
    },
    onValueChanged(event) {
      this.$emit('onVendorSkuUpdated', event)
    },
  },
};
</script>

<style scoped>
#vendorManagement {
    max-height: 440px;
}
.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}
.caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.selected-data {
    margin-top: 20px;
}
.selected-data .caption {
    margin-right: 4px;
}
.option > span {
    width: 120px;
    display: inline-block;
}
.option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 350px;
}
::v-deep .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed) > td {
    background-color: #e5eef5;
    color: unset;
}

.dx-treelist .dx-treelist-rowsview .dx-treelist-cell:nth-child(3):hover {
    background-color: #ccc;
}
</style>


