<template>
  <div class="inline-checkbox" :title="label">
    <v-simple-checkbox
        color="primary"
        :disabled="disabled"
        v-model="isChecked"
    />
    <span :style="labelStyle" class="text-left label-ellipsis">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {type: String, required: true},
    value: {type: Boolean, required: false},
    fontSize: {type: String, default: '16px'},
    disabled: {type: Boolean, default: false}
  },

  data() {
    return {
      isChecked: this.value,
    };
  },

  computed: {
    labelStyle() {
      return {
        fontSize: this.fontSize
      };
    }
  },

  watch: {
    isChecked: {
      handler(newVal) {
        this.$emit('input', newVal);
      }
    },

    value: {
      immediate: true,
      handler(newVal) {
        this.isChecked = newVal;
      }
    }
  }
}
</script>

<style scoped>
.inline-checkbox {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.inline-checkbox .v-label {
  margin-left: 5px;
}
.primary.v-simple-checkbox {
  background-color: unset !important;
}

.inline-checkbox .label-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
