<template>
  <div v-if="dataSourceConfig">
    <inline-grid
        ref="inventoryItemsInShipmentsGrid"
        :data-source="dataSourceConfig"
        :show-borders="true"
        :hover-state-enabled="true"
        :columnAutoWidth="true"
        selectionMode="multiple"
        noDataText="No available items found"
        @rowSelected="onRowSelected"
    >
      <dx-paging :page-size="defaultInitialPageSize"/>
      <dx-pager
          :show-page-size-selector="true"
          :show-info="true"
      />
      <dx-column
          data-field="TransferNumber"
          caption="Transfer Number"
          data-type="string"
          sort-order="asc"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="InternalSku"
          caption="Internal SKU"
          :allowHeaderFiltering="false"
          data-type="string"
      />
      <dx-column
          data-field="StyleName"
          caption="Style"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="Color"
          caption="Color"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="RollNumber"
          caption="Roll Number"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="DyeLot"
          caption="Dye Lot"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="BinNumber"
          caption="Bin Number"
          data-type="string"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="QuantityReserved"
          caption="Quantity Reserved"
          data-type="number"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="CheckedOut"
          caption="Checked Out"
          data-type="boolean"
          cell-template="checkOutIconStatus"
          :allowHeaderFiltering="false"
      />
      <template v-slot:selectedRowsCount>
        <div class="text-center pt-5 pb-5">
          Items Selected: {{
            rowsCount
          }}
        </div>
      </template>
    </inline-grid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import {DxColumn, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";
import OdataContext from "../../../rest/odataContext";
import InlineGrid from "../../common/grid/InlineGrid.vue";
import InlineGridButton from "../../common/grid/InlineGridButton.vue";
import InlineGridFooter from "../../common/grid/InlineGridFooter.vue";
import {InventoryItemCheckedStatus} from "@/assets/statusIcons/statusIcons";

export default {
  components: {
    InlineGridFooter,
    InlineGridButton,
    InlineGrid,
    DxColumn,
    DxPager,
    DxPaging
  },

  props: {
    filterConfig: Array
  },

  data() {
    return {
      defaultInitialPageSize: 10,
      rowsCount: 0,
      dataSourceConfig: null
    };
  },

  watch: {
    filterConfig: {
      immediate: true,
      handler(value) {
        this.setFilterAndReloadDataSource(value);
      }
    },
  },

  methods: {
    setFilterAndReloadDataSource(filter) {
      this.dataSourceConfig = {
        store: OdataContext['InventoryItemsForBranchTransferCheckOuts'],
        select: [
          "Id",
          "InventoryItemId",
          "TransferNumber",
          "InternalSku",
          "StyleName",
          "Color",
          "BinNumber",
          "RollNumber",
          "DyeLot",
          "QuantityReserved",
          "CheckedOut",
          "PickupDate",
          "ShipFromBranchId",
          "ShipToBranchId",
          "ShipFromBranchName",
          "ShipToBranchName",
          "IsClosed",
          "Status"
        ],
        filter: filter
      }
      const gridInstance = this.getGridInstance();
      this.reloadDataSourceWithFilter(gridInstance, filter);
    },
    reloadDataSourceWithFilter(gridInstance, filter) {
      gridInstance.filter(filter);
      gridInstance.refresh();
    },
    onRowSelected() {
      this.$emit('rowSelected');
      this.updateSelectedRowCount();
    },
    clearGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.filter(this.filterConfig);
      gridInstance.clearSorting();
    },
    getGridInstance() {
      return this.$refs.inventoryItemsInShipmentsGrid.getGridInstance();
    },
    refreshGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.refresh();
    },
    deselectItems() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearSelection();
    },
    getSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return [...selectedRows];
    },
    getOnlyAvailableForCheckOutSelectedRows() {
      const selectedRows = this.getGridInstance().getSelectedRowsData();
      return selectedRows.filter(r => (!r.CheckedOut && !r.IsClosed && r.Status !== InventoryItemCheckedStatus.CHECKED_OUT.name));
    },
    getSelectedRowsCount(excludeCheckedOut, excludeClosed) {
      let selectedRows = this.getGridInstance().getSelectedRowsData();
      if (excludeCheckedOut && excludeClosed) {
        return this.getOnlyAvailableForCheckOutSelectedRows().length;
      } else {
        if (excludeCheckedOut && !excludeClosed) {
          return selectedRows.filter(r => r.CheckedOut !== true).length;
        } else if (!excludeCheckedOut && excludeClosed) {
          return selectedRows.filter(r => r.IsClosed !== true).length;
        }
      }
      return selectedRows.length;
    },
    updateSelectedRowCount() {
      this.rowsCount = this.getSelectedRowsCount(true, true);
    },
  }
};
</script>
