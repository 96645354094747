<template>
  <div>
    <form ref="fileform" :class="fileDropClass" enctype="multipart/form-data">
      <!-- input is set to 0 opacity so can click on "fileform" box -->
      <!-- todo: file-input introduced in vuetify 2+ -->
      <input
          type="file"
          multiple
          class="input-file"
          @change="handleClickUpload"
      />
      <p class="drop-files">Drop the files here to begin</p>
      <p class="drop-files">or click to browse</p>
    </form>

    <v-progress-linear v-if="uploadingFiles" :indeterminate="true"/>
    <v-container grid-list-lg class="pa-0 ma-0">
      <v-row v-if="files.length > 0" class="mt-1 file-listing">
        <v-col offset="1" class="d-flex">File Name</v-col>
        <v-col class="d-flex">* Document Type</v-col>
        <v-col v-if="showInstallTypes" class="d-flex">* Install Type</v-col>
        <v-col v-if="atLeastOneCOCDocumentTypeSelected" class="d-flex">* Related Labor</v-col>
      </v-row>
      <v-form ref="form">
        <div
            v-for="(file, key) in attemptedFiles"
            class="pa-2 file-listing"
            :key="key"
        >
          <v-row align="center" justify="center">
            <v-col cols="1">
              <div v-if="imageSources[key] && imageSources[key].length > 0">
                <v-img
                    v-bind:src="imageSources[key]"
                    aspect-ratio="1"
                    class="white lighten-2 preview"
                />
              </div>
            </v-col>
            <v-col>
              <div class="message-container" :title="file.name">
                <v-row align="center" justify="center">
                  <v-col class="file">{{ file.name }}</v-col>
                  <v-col>
                    <v-select
                        placeholder="Select Document Type"
                        :items="documentTypes"
                        v-model="selectedDocumentTypes[key]"
                        item-text="name"
                        item-value="id"
                        :rules="[() => !!selectedDocumentTypes[key] || 'Required']"
                    />
                  </v-col>
                  <v-col id="install-type-wrapper" v-if="showInstallTypes">
                    <v-select
                        v-if="showInstallTypesForFile(key)"
                        placeholder="Select Install Type"
                        :items="getInstallTypes(selectedDocumentTypes[key])"
                        v-model="selectedInstallTypes[key]"
                        item-text="name"
                        item-value="id"
                        :rules="[() => !!selectedInstallTypes[key] || 'Required']"
                    />
                  </v-col>
                  <v-col v-show="atLeastOneCOCDocumentTypeSelected">
                    <v-select
                        v-if="showRelatedLaborDropdown(key)"
                        placeholder="Select Related Labor"
                        :items="availableLabors"
                        v-model="selectedLabors[key]"
                        item-text="referenceId"
                        item-value="id"
                        :rules="[() => !!selectedLabors[key] || 'Required', rules.nonUniqueSelectedLabor]"
                    />
                  </v-col>
                </v-row>
                <span v-if="file.uploadMessage" class="error-message">
                  {{ file.uploadMessage }}
                </span>
              </div>
            </v-col>
            <v-col cols="1">
              <a v-on:click="removeFile(key, file)">
                <v-icon color="primary">delete</v-icon>
              </a>
            </v-col>
          </v-row>
        </div>
        <div class="warning-message">
         <span v-if="showWarningIfSpecificTypesSelected">
              {{ warningMessage }}
         </span>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Rules from "../../../../assets/rules";
import Constants from "../../../../assets/constants";

export default {
  props: {
    customerReferenceId: String,
    leadReferenceId: String,
    uploadPercentage: Number,
    uploadingFiles: Boolean,
    documentTypesCategory: String,
    documentTypes: Array,
    showInstallTypes: Boolean,
    installTypesPerDocumentType: Object,
    availableLabors: {type: Array}
  },

  data() {
    return {
      dragAndDropCapable: false,
      attemptedFiles: [],
      files: [],
      selectedDocumentTypes: [],
      selectedInstallTypes: [],
      selectedLabors: [],
      fileDropClass: "fileform",
      whitelisted: [
        "pdf",
        "jpg",
        "jpeg",
        "gif",
        "png",
        "doc",
        "docx",
        "txt",
        "xls",
        "xlsx",
        "zip",
        "msg",
        "mp4"
      ],
      maxFileSize: 62914560,    // 60 MB
      imageSources: [],
      genericThumbnail: require("../../../../assets/attach_file-24px.svg"),
      rules: {
        required: Rules.required,
        nonUniqueSelectedLabor: (value) => {
          let nonUnique = this.isRelatedLaborAlreadySelected(value);
          return !nonUnique || 'Selected labor must be unique';
        }
      },
      warningMessage: "Warning: All unsaved changes in job builder will be lost!"
    };
  },

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(evt => {
        this.$refs.fileform.addEventListener(
            evt,
            e => {
              e.preventDefault();
              e.stopPropagation();
            },
            false
        );
      });

      this.$refs.fileform.addEventListener("dragover", () => {
        this.fileDropClass = "fileform active";
      });

      this.$refs.fileform.addEventListener("dragleave", () => {
        this.fileDropClass = "fileform";
      });

      this.$refs.fileform.addEventListener("drop", e => {
        this.fileDropClass = "fileform";
        this.addToAttemptedFiles(e.dataTransfer);
      });
    }
  },

  watch: {
    // Whenever a document type is changed, reset the install type
    updatedDocumentTypes: {
      handler(newValues, oldValues) {
        if (newValues.length === oldValues.length) {
          newValues.forEach((documentType, index) => {
            if (documentType !== oldValues[index]) {
              this.$set(this.selectedInstallTypes, index, null);
            }
          });
        }
      }
    },
    updatedAttemptedFiles: {
      handler(newFiles, oldFiles) {
        const numNewFiles = newFiles.length - oldFiles.length;
        if (numNewFiles > 0) {
          for (let i = 0; i < numNewFiles; i++) {
            this.selectedDocumentTypes.push(null);
            this.selectedInstallTypes.push(null);
          }
        }
      }
    }
  },

  computed: {
    updatedDocumentTypes() {
      return [...this.selectedDocumentTypes];
    },
    updatedAttemptedFiles() {
      return [...this.attemptedFiles];
    },
    atLeastOneCOCDocumentTypeSelected() {
      return this.selectedDocumentTypes.some((i) => i === Constants.COC_DOCUMENT_TYPE_ID);
    },
    showWarningIfSpecificTypesSelected() {
      return this.selectedDocumentTypes.some((i) => i === Constants.COC_DOCUMENT_TYPE_ID || i === Constants.VDA_DOCUMENT_TYPE_ID);
    }
  },

  methods: {
    reset() {
      this.files = [];
      this.attemptedFiles = [];
      this.selectedDocumentTypes = [];
      this.selectedInstallTypes = [];
      this.imageSources = [];
      this.selectedLabors = [];
      this.$refs.fileform.reset();
    },
    determineDragAndDropCapable() {
      const div = document.createElement("div");
      return (
          ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
          "FormData" in window &&
          "FileReader" in window
      );
    },
    handleClickUpload(e) {
      this.addToAttemptedFiles(e.target);
    },
    addToAttemptedFiles(target) {
      if (!target.files.length) return;
      for (let i = 0; i < target.files.length; i++) {
        this.validateFile(target.files[i]);
      }
      this.getImagePreviews();
    },
    // if can get a preview for the uploaded file (is image type)
    // add that thumbnail to image object to display to user
    // else use a generic thumbnail
    getImagePreviews() {
      const component = this;
      for (let i = 0; i < this.attemptedFiles.length; i++) {
        if (/\.(jpe?g|png|gif)$/i.test(this.attemptedFiles[i].name)) {
          const reader = new FileReader();
          reader.addEventListener(
              "load",
              () => {
                component.imageSources[i] = reader.result;
                component.$forceUpdate();
              },
              false
          );

          reader.readAsDataURL(this.attemptedFiles[i]);
        } else {
          this.$nextTick(() => {
            component.imageSources[i] = component.genericThumbnail;
            component.$forceUpdate();
          });
        }
      }
    },
    // removes files in upload modal before submit
    removeFile(key, file) {
      if (!file.uploadMessage) {
        let index = this.files.findIndex(f => f.name === file.name);
        if (index >= 0) {
          this.files.splice(index, 1);
        }
      }

      let index = this.attemptedFiles.findIndex(f => f.name === file.name);
      if (index >= 0) {
        this.attemptedFiles.splice(index, 1);
        this.selectedDocumentTypes.splice(index, 1);
        this.selectedInstallTypes.splice(index, 1);
        this.imageSources.splice(index, 1);
      }
    },
    // ensures file type is acceptable
    validateFile(file) {
      if (file.size > this.maxFileSize) {
        file.uploadMessage = "File size exceeds the maximum limit (60 MB)";
      } else {
        // get file extension
        const match = file.name.match(/\.([0-9a-z]+)$/i);
        file.uploadMessage = "Invalid file extension";
        if (match) {
          const fileType = match[1];
          if (this.validateFileType(fileType)) {
            file.uploadMessage = null;
            this.files.push(file);
          }
        }
      }
      this.attemptedFiles.push(file);
    },
    // uses whitelisted list to see if new file type is within list
    validateFileType(fileType) {
      const index = this.whitelisted.findIndex(
          item => fileType.toLowerCase() === item.toLowerCase()
      );
      return index > -1;
    },
    isValidatedForm() {
      return this.$refs.form.validate();
    },
    getInstallTypes(documentTypeId) {
      return this.documentTypes.find(x => x.id === documentTypeId).installTypes;
    },
    showInstallTypesForFile(index) {
      return this.selectedDocumentTypes.some((i) => i !== Constants.COC_DOCUMENT_TYPE_ID) && !!this.installTypesPerDocumentType[this.updatedDocumentTypes[index]];
    },
    showRelatedLaborDropdown(index) {
      return this.selectedDocumentTypes[index] === Constants.COC_DOCUMENT_TYPE_ID;
    },
    isRelatedLaborAlreadySelected(selectedLaborId) {
      return this.files.length > 1 && this.selectedDocumentTypes.filter(sdt => sdt === Constants.COC_DOCUMENT_TYPE_ID).length > 1
          && this.selectedLabors.filter(l => l === selectedLaborId).length > 1;
    }
  }
}
</script>

<style>
.fileform {
  display: block;
  height: 100px;
  background-color: #d1e0ea;
  margin: auto;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
  border: 1px dashed #6699bb;
  position: relative;
  cursor: pointer;
}
.fileform:active {
  background-color: #b1cde0;
  border-color: #517a95;
  cursor: pointer;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}
.fileform.active {
  background-color: #b1cde0;
  border-color: #517a95;
}
.file-listing {
  width: 95%;
  margin: auto;
  border-bottom: 1px solid #ddd;
}
.file-listing img {
  height: 100px;
}
a.submit-button {
  display: block;
  margin: auto;
  text-align: center;
  width: 200px;
  padding: 10px;
  text-transform: uppercase;
  background-color: #ccc;
  color: white;
  font-weight: bold;
  margin-top: 20px;
}
progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}
.preview {
  border: 1px solid #dddddd;
}
.message-container {
  display: flex;
  flex-direction: column;
}
.error-message {
  color: red;
  font-size: 12px;
}
.file {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  width: 275px;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  text-align: left;
}
.warning-message {
  color: orange;
  margin: 6px;
}
</style>
