<template>
  <navigation-view
      ref="nav"
      page-title="Sales Representative Details"
      nav-section="admin"
  >
    <template v-slot:content>
      <v-container grid-list-lg fluid>
        <sales-rep-read-only
            :salesRep="salesRep"
            :referenceId="salesRepReferenceId"
            @onUpdateFail="onUpdateFail"
            @onUpdateSuccess="onUpdateSuccess"
        />
        <inline-grids
            v-if="isSalesRepLoaded"
            ref="inlineGrids"
            :salesRepReferenceId="salesRepReferenceId"
            :sales-representative-id="salesRep.id"
            :salesRepName="salesRepName"
            @showSuccess="showNavSuccess"
            @showError="showNavError"
        />
      </v-container>
    </template>
    <template v-slot:drawer>
      <sales-rep-update-drawer
          ref="salesRepDrawerForm"
          :salesRep="salesRep"
          :salesRepReferenceId="salesRepReferenceId"
          title="Edit Sales Rep"
          submitLabel="Save Sales Representative"
          @cancelClicked="onCancel"
          @onUpdateFail="onUpdateFail"
          @onUpdateSuccess="onUpdateSuccess"
          @resetMfaSuccess="onResetMfaSuccess"
      />
    </template>
    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Sales Rep"
          @click="showDrawer"
      />
    </template>

  </navigation-view>
</template>
<script>
import SalesRepresentative from "@/rest/salesRepresentatives";
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import SalesRepReadOnly from "@/components/salesRepresentatives/SalesRepresentativeReadOnly.vue";
import SalesRepUpdateDrawer from "@/components/salesRepresentatives/SalesRepresentativeUpdateDrawer.vue";
import InlineGrids from "@/views/salesRepresentatives/SalesRepInlineGrids.vue";
import Roles from "../../assets/roles";
import Constants from "@/assets/constants";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    SalesRepReadOnly,
    SalesRepUpdateDrawer,
    InlineGrids
  },

  data() {
    return {
      salesRep: {},
      salesRepReferenceId: null,
      isSalesRepLoaded: false,
      salesRepName: null
    };
  },

  created() {
    this.display(this.$route.params.salesRepReferenceId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.salesRepReferenceId);
    next();
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    display(salesRepReferenceId) {
      this.salesRepReferenceId = salesRepReferenceId;
      this.getSalesRepByReferenceId();
    },
    async getSalesRepByReferenceId() {
      try {
        const response = await SalesRepresentative.getRestApi().getSalesRepresentative(this.salesRepReferenceId);
        this.setSalesRep(response.data);
      } catch (error) {
        this.showNavError(error);
      }
    },
    setSalesRep(data) {
      this.salesRep = data;
      this.salesRepName = `${data.firstName} ${data.lastName}`;
      this.isSalesRepLoaded = true;
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    onUpdateSuccess() {
      this.getSalesRepByReferenceId();
      this.refreshActivityLog();
      this.showNavSuccess("Updated sales representative details successfully!");
      this.$refs.nav.hideDrawer();
    },
    onUpdateFail(error) {
      this.showNavError(error);
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    showNavSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    onResetMfaSuccess(updatedSalesRep) {
      this.showNavSuccess(Constants.mfaResetSuccessMessage);
      this.salesRep.mfaSetUp = updatedSalesRep.mfaSetUp;
      this.$refs.nav.hideDrawer();
    },
  }
};
</script>
