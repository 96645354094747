<template>
  <v-autocomplete
      v-model="selected"
      :search-input.sync="searchInput"
      @input="searchInput=null"
      item-text="name"
      data-id="branches-autocomplete"
      :item-value="isIdRequired"
      :placeholder="placeholder"
      :rules="[rules.requiredBasedOnPropsValue]"
      :items="branches"
      :loading="loading"
      :label="label"
      :readonly="readonly"
      :multiple="multiple"
      :required="required"
      :disabled="disabled"
      :clearable="clearable || false"
      light
      dense
      attach
      hide-no-data
      validate-on-blur
      persistent-placeholder
      hide-selected
  >
    <template v-slot:prepend-item v-if="selectAllEnabled">
      <select-all-autocomplete
          :items="branches"
          :selected="selected"
          @select="selectAll"
          @deselect="deselectAll"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import SelectAllAutocomplete
  from "../../components/searchFields/selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";
import Rules from "../../assets/rules";

export default {
  components: {
    SelectAllAutocomplete
  },
  data() {
    return {
      selected: null,
      loading: false,
      rules: {
        requiredBasedOnPropsValue: (value) => Rules.requiredBasedOnPropsValue(value, this.required)
      },
      searchInput: null,
    };
  },

  props: {
    value: String | Array,
    readonly: Boolean,
    required: Boolean,
    multiple: Boolean,
    getId: Boolean,
    clearable: Boolean,
    label: {
      type: String,
      required: false,
      default: 'Branch'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectAllEnabled: {
      type: Boolean,
      default: false,
      required: true
    },
    isBranchesMappedById: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select Branch'
    },
    branches: {
      type: Array,
      required: true
    }
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      // this.setSelectedBranch(value);
      this.$emit('inputSelected', value);
    }
  },

  computed: {
    isIdRequired() {
      return this.getId ? "id" : "referenceId";
    }
  },

  methods: {
    setSelectedBranch(branchRefId) {
      this.selected = branchRefId;
    },
    clearSelectedBranch() {
      this.selected = null;
    },
    emitError(error) {
      this.$emit("showError", error);
    },
    selectAll() {
      if (this.isBranchesMappedById) {
        this.selected = this.branches.map(item => item.id);
      } else {
        this.selected = this.branches.map(item => item.referenceId);
      }
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
  width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

</style>
