<template>
  <v-container>
    <v-layout>
      <v-flex xs4>
        <v-text-field
          readonly
          v-model="fullName"
          placeholder=" "
          label="Customer Name"
        />
      </v-flex>
      <v-spacer />
      <v-flex xs4>
        <v-text-field
          readonly
          v-model="leadStatus"
          placeholder=" "
          label="Lead Status"
        />
      </v-flex>
    </v-layout>
    <v-layout class="pt-2">
      <v-flex xs6 class="pr-2">
        <v-text-field
          readonly
          v-model="appointmentDate"
          placeholder=" "
          label="Date"
        />
      </v-flex>
      <v-flex xs6 class="pl-2">
        <v-text-field
          readonly
          v-model="appointmentTime"
          placeholder=" "
          label="Time"
        />
      </v-flex>
    </v-layout>
    <v-layout class="pt-3">
      <v-flex xs6 class="pr-2">
        <v-text-field
          readonly
          v-model="address"
          placeholder=" "
          label="Address"
        />
      </v-flex>
      <v-flex xs6 class="pl-2">
        <v-text-field readonly v-model="email" placeholder=" " label="Email" />
      </v-flex>
    </v-layout>
    <v-layout class="pt-3">
      <v-flex xs6 class="pr-2">
        <v-text-field
          readonly
          v-facade="phoneNumberMask"
          v-model="primaryPhone"
          placeholder=" "
          label="Primary Phone"
        />
      </v-flex>
      <v-flex xs6 class="pl-2 pr-2">
        <v-text-field
          readonly
          v-facade="phoneNumberMask"
          v-model="alternatePhone"
          placeholder=" "
          label="Alternate Phone"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from "moment";
import Constants from "@/assets/constants";

export default {
  data() {
    return {
      address: null,
      appointmentDate: null,
      appointmentTime: null,
      email: null,
      fullName: null,
      leadStatus: null,
      primaryPhone: null,
      alternatePhone: null,
      phoneNumberMask: Constants.phoneNumberMask
    };
  },

  methods: {
    loadFrom: function(appointment) {
      this.$data.address = appointment.address;
      this.$data.appointmentDate = moment(appointment.appointmentDate)
        .utc()
        .format("MM/DD/YYYY");
      this.$data.appointmentTime = appointment.appointmentTime;
      this.$data.email = appointment.email;
      this.$data.fullName = appointment.fullName;
      this.$data.leadStatus = appointment.leadStatus;
      this.$data.primaryPhone = appointment.primaryPhone;
      this.$data.alternatePhone = appointment.alternatePhone;
    }
  }
};
</script>
