<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Zip Codes
          </v-expansion-panel-header>
          <v-expansion-panel-content key="zipCodes">
            <market-zip-code-grid
                :isEditButtonDisplayed="isEditButtonDisplayed"
                :zipCodes="zipCodeSorted"
                @toggleZipCodeDialog="toggleZipCodeDialog"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Activity Logs
          </v-expansion-panel-header>
          <v-expansion-panel-content key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <market-zip-code-dialog
        :marketReferenceId="marketReferenceId"
        :visible="zipCodeDialogOpen"
        :zipCodes="zipCodes"
        @onClose="toggleZipCodeDialog"
        @refreshZipCodes="refreshZipCodes"
        @showSuccess="showZipCodeChangeSuccess"
        @showError="showError"
    />
  </v-row>
</template>

<script>
import Markets from "../../rest/markets.ts";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import MarketZipCodeGrid from "../../components/markets/MarketZipCodeGrid.vue";
import MarketZipCodeDialog from "../../components/markets/MarketZipCodeDialog.vue";
import Roles from "../../assets/roles";
import Constants from "../../assets/constants";

export default {
  components: {
    ActivityLogsGrid,
    MarketZipCodeGrid,
    MarketZipCodeDialog
  },

  props: {
    marketReferenceId: {
      type: String,
      required: true
    },
    zipCodes: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      activityLogs: [],
      zipCodeSorted: [],
      zipCodeDialogOpen: false,
      expanded: Constants.expandedByDefault
    };
  },

  watch: {
    zipCodes: {
      immediate: true,
      handler(data) {
        if (data) {
          this.sortAscending(data);
        }
      }
    },
    marketReferenceId: {
      immediate: true,
      handler() {
        this.refreshActivityLog();
      }
    }
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    sortAscending(data) {
      let sortedItems = data.slice();
      sortedItems.sort((a, b) => {
        return a.zipCode - b.zipCode;
      });
      this.zipCodeSorted = sortedItems;
    },
    showError(error) {
      this.$emit("showError", error);
    },
    showSuccess(message) {
      this.$emit("showSuccess", message);
    },
    async getActivityLogs() {
      try {
        const response = await Markets.getRestApi().getMarketLogs(this.marketReferenceId);
        this.activityLogs = response.data;
      } catch (error) {
        this.showError(error);
      }
    },
    toggleZipCodeDialog() {
      this.zipCodeDialogOpen = !this.zipCodeDialogOpen;
    },
    refreshZipCodes() {
      this.$emit("refreshZipCodes");
    },
    showZipCodeChangeSuccess(message) {
      this.showSuccess(message);
      this.refreshActivityLog();
    }
  }
};
</script>

<style>
.theme--dark.v-expansion-panel .v-expansion-panel__container {
  background-color: white;
  overflow: hidden;
}
</style>
