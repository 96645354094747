<template>
  <drawer-form
      ref="form"
      submit-label="Save Promo Code"
      title="Edit Promo Code"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <form-body ref="formBody" mode="update"/>
  </drawer-form>
</template>

<script>
import FormBody from "@/components/promoCodes/FormBody.vue";
import PromoCodes from "@/rest/promoCodes";
import DrawerForm from "../common/form/DrawerForm";

export default {
  components: {
    FormBody,
    DrawerForm
  },

  props: {promoCode: Object},

  data() {
    return {
      promoCodeData: null,
      inProgress: false
    };
  },

  watch: {
    promoCode(newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      this.promoCodeData = Object.assign({}, this.promoCode);
      this.$refs.formBody.loadForm(this.promoCodeData);
    },
    onCancelClicked() {
      this.$emit("cancelClicked");
      this.$refs.form.resetValidation();
      this.$refs.formBody.resetForm();
    },
    async onSubmitClicked() {
      try {
        let valid = this.$refs.form.validate();
        if (valid) {
          this.inProgress = true;
          const payload = this.$refs.formBody.buildPayload();
          const response = await PromoCodes.getRestApi().updatePromoCode(this.promoCode.id, payload);
          this.$emit("submitSuccess", response.data);
        }
      } catch (error) {
        this.$emit("submitFailed", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
