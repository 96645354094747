<template>
  <div class="buttonContainer">
    <v-btn
        class="ml-2 mr-2"
        color="primary"
        @click="handleClick('customerBadDebt')"
    >
      Customer Bad Debt
    </v-btn>
    <v-btn
        class="ml-2 mr-2"
        color="primary"
        :disabled="jobInvoiced"
        @click="handleClick('discountJob')"
    >
      Cancel Save
    </v-btn>
    <v-btn
        class="ml-2 mr-2"
        color="primary"
        :disabled="jobInvoiced"
        @click="handleClick('installercb')"
    >
      Installer Chargeback
    </v-btn>
    <v-btn
        class="ml-2 mr-2"
        color="primary"
        :disabled="jobInvoiced"
        @click="handleClick('vendorcb')"
    >
      Vendor Chargeback
    </v-btn>
    <v-btn
        class="ml-2 mr-2"
        color="primary"
        :disabled="jobInvoiced"
        @click="handleClick('installIssue')"
    >
      Installation Issue
    </v-btn>
    <v-btn
        class="ml-2 mr-2"
        color="primary"
        :disabled="positiveRemainingBalance"
        @click="handleClick('refund')"
    >
      Refund
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    job: Object,
    remainingBalance: Number
  },

  computed: {
    jobInvoiced() {
      return this.job.invoiced;
    },
    positiveRemainingBalance() {
      return this.remainingBalance >= 0;
    }
  },

  methods: {
    handleClick(type) {
      if (!(type === "customerBadDebt" || type === "refund") && this.job.invoiced) {
        const message = "This function is not available once the job has been invoiced.";
        this.$emit("emitError", message);
      } else {
        this.$emit(type);
      }
    }
  },
};
</script>

<style scoped>
.buttonContainer {
  /* same as min height for inline grids for job section */
  min-height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-btn {
  border-radius: 2px;
}
</style>
