<template>
  <v-card light>
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Create Purchase Order</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <stepper-headers :step="step"/>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pa-4">
            <choose-vendor-and-branch-step
                ref="vendorAndBranchStep"
                @onVendorChanged="clearPreviouslySelectedChips"
            />
            <button-container-with-cancel
                backName=Cancel
                nextName=Next
                :hidePreviousStep="true"
                @cancel="cancelForm"
                @getNextStep="stepOneContinue"
            />
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <select-catalog-items-container
              ref="selectCatalogItems"
              v-if="selectedVendor"
              :selectedVendor="selectedVendor"
              :filter="[['IsMigrated', '=', true],['Status', '=', 'Active']]"
          />
          <p class="submitError" v-show="stepTwoError">{{ stepTwoError }}</p>
          <button-container-with-cancel
              backName=Back
              nextName=Next
              @cancel="cancelForm"
              @getPreviousStep="getBackToStep(1)"
              @getNextStep="stepTwoContinue"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <enter-quantity
              ref="enterQuantity"
              :selectedItems="selectedItems"
              @validQuantityEntered="validQuantityEntered"
              @onComputeQuantity="adjustedSelectedItemsQuantity"
          />
          <p class="submitError" v-show="stepThreeError">{{ stepThreeError }}</p>
          <button-container-with-cancel
              backName=Back
              nextName=Next
              @cancel="cancelForm"
              @getPreviousStep="getBackToStep(2)"
              @getNextStep="stepThreeContinue"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <overview
              ref="overview"
              :error="stepThreeError"
              :selectedItems="selectedItems"
              :selectedVendor="selectedVendor"
              :selectedBranch="selectedBranch"
          />
          <button-container-with-cancel
              :nextLoading="inProgress"
              backName=Back
              nextName=Submit
              @cancel="cancelForm"
              @getPreviousStep="getBackToStep(3)"
              @getNextStep="submitForm"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import PurchaseOrderLineItems from "../../../components/purchaseOrders/bulkPurchaseOrder/LineItems.vue";
import ChooseItems from "../../../components/purchaseOrders/bulkPurchaseOrder/ChooseItems.vue";
import AdjustQuantity from "../../../components/purchaseOrders/bulkPurchaseOrder/AdjustQuantity.vue";
import Overview from "../../../components/purchaseOrders/bulkPurchaseOrder/PurchaseOverview.vue";
import StepperHeaders from "../../../components/purchaseOrders/bulkPurchaseOrder/StepperHeaders.vue";
import PurchaseOrders from "../../../rest/purchaseOrders";
import ChooseVendorAndBranchStep from "./ChooseVendorAndBranchStep.vue";
import EnterQuantity from "./EnterQuantity.vue";
import ButtonContainerWithCancel from "../../common/wizard/ButtonContainerWithCancel.vue";
import ButtonContainer from "../../common/wizard/ButtonContainer.vue";
import SelectCatalogItemsContainer from "../../catalogItems/SelectCatalogItemsContainer.vue";

export default {
  data: () => ({
    selectedItems: [],
    selectedVendor: null,
    selectedBranch: null,
    stepTwoError: null,
    stepThreeError: null,
    step: 1,
    inProgress: false,
  }),

  components: {
    EnterQuantity,
    SelectCatalogItemsContainer,
    ButtonContainer,
    ButtonContainerWithCancel,
    AdjustQuantity,
    ChooseItems,
    Overview,
    PurchaseOrderLineItems,
    StepperHeaders,
    ChooseVendorAndBranchStep
  },

  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    async submitForm() {
      try {
        this.inProgress = true;
        await this.submitBulkPurchaseOrder();
        this.handleSubmitPOSuccess();
      } catch (error) {
        this.handleSubmitPOError(error);
      }
      this.inProgress = false;
    },
    stepOneContinue() {
      let valid = this.$refs.vendorAndBranchStep.$refs.form.validate();
      if (valid) {
        this.selectedVendor = this.$refs.vendorAndBranchStep.$refs.vendorsAutocomplete.selected
        this.selectedBranch = this.$refs.vendorAndBranchStep.$refs.branchesAutocomplete.selected;
        this.goToNextStep(2);
      }
    },
    stepTwoContinue() {
      let selectedItems = this.$refs.selectCatalogItems.getSelectedItems();
      if (this.ifSelectedItemsReadyForNextStep(selectedItems)) {
        this.selectedItems = [...selectedItems];
        this.goToNextStep(3);
        this.stepTwoError = null;
      }
    },
    goToNextStep(step) {
      this.step = step;
    },
    stepThreeContinue() {
      this.hasEnteredQuantity(this.selectedItems);
      this.ifSelectedItemsHaveQuantity();
      this.ifEnteredQuantityIsPositiveNumber();
      if (!this.stepThreeError) {
        this.goToNextStep(4);
      }
    },
    hasEnteredQuantity(selectedItems) {
      selectedItems.some(i => {
        if (!i.quantityOrdered || !i.quantity) {
          this.stepThreeError = "Quantity Must Be Entered for selected items"
        }
      });
    },
    buildBulkPurchaseOrderPayload() {
      return {
        vendorId: this.selectedVendor.id,
        branchReferenceId: this.selectedBranch.referenceId,
        purchaseOrderItems: this.mapSelectedItems()
      }
    },
    mapSelectedItems() {
      return this.selectedItems.map(i => {
        return {
          catalogItemId: i.ProductCatalogItemId,
          quantityOrdered: i.quantityOrdered,
          totalPrice: i.totalPrice,
          supplierCostId: i.SupplierCostId,
        }
      })
    },
    async submitBulkPurchaseOrder() {
      try {
        const payload = this.buildBulkPurchaseOrderPayload();
        await PurchaseOrders.getRestApi().createBulkPurchaseOrder(payload);
      } catch (error) {
        throw error.response.data;
      }
    },
    validQuantityEntered() {
      this.stepThreeError = null;
    },
    adjustedSelectedItemsQuantity(computedQuantity, index) {
      const items = [...this.selectedItems];
      items[index].quantityOrdered = Number(computedQuantity);
      items[index].quantity = Number(computedQuantity);
      items[index].totalPrice = Number(Number(computedQuantity) * Number(items[index].ActiveVendorCost));
      this.selectedItems = items;
    },
    ifSelectedItemsHaveQuantity() {
      let pass = true;
      let error;
      for (const selectedItem of this.selectedItems) {
        if (!selectedItem.quantity || Number(selectedItem.quantity) === 0) {
          pass = false;
          error = "Must enter quantity for all items before creating a Purchase Order";
        }
      }
      this.stepThreeError = error;
      return pass;
    },
    ifEnteredQuantityIsPositiveNumber() {
      let items = this.selectedItems;
      let pass = true;
      let error;
      items.forEach(item => {
        if (item.quantity <= 0) {
          pass = false;
          error = "Entered quantity must be greater than zero";
        }
      });
      this.stepThreeError = error;
      return pass;
    },
    ifSelectedItemsReadyForNextStep(selectedItems) {
      let pass = true;
      let error;
      if (!selectedItems.length) {
        pass = false;
        error = "Must select item(s) to move on to next step";
      }
      if (!this.ifAllItemsSameVendor(selectedItems)) {
        pass = false;
        error = "All items must be from same vendor to create Purchase Order.";
      }
      this.stepTwoError = error;
      return pass;
    },
    // kept if for the potential testing issues
    ifAllItemsSameVendor(selectedItems) {
      let pass = true;
      if (selectedItems.length > 1) {
        let firstItemVendorId = selectedItems[0].VendorId;
        for (let i = 1; i < selectedItems.length; i += 1) {
          if (selectedItems[i].VendorId !== firstItemVendorId) {
            pass = false;
          }
        }
      }
      return pass;
    },
    getBackToStep(step) {
      this.step = step;
    },
    cancelForm() {
      this.resetWizard();
      this.$emit('cancel');
    },
    handleSubmitPOError(error) {
      this.inProgress = false;
      this.$emit('showError', error);
    },
    handleSubmitPOSuccess() {
      this.resetWizard();
      this.$emit('success', 'Created Purchase Order Successfully!');
    },
    resetWizard() {
      this.step = 1;
      this.inProgress = false;
      this.selectedVendor = null;
      this.selectedBranch = null;
      this.stepTwoError = null;
      this.stepThreeError = null;
      this.selectedItems = [];
      if (this.$refs.selectCatalogItems) {
        this.$refs.selectCatalogItems.reset();
      }
      if (this.$refs.vendorAndBranchStep) {
        this.$refs.vendorAndBranchStep.resetSelected();
      }
    },
    clearPreviouslySelectedChips() {
      this.$refs.selectCatalogItems.reset();
    }
  }
};
</script>

<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
