import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Sales commission.
 */
export interface SalesCommission {
    id: number;
    salesRepresentativeId: number;
    customerId: number;
    leadId: number;
    jobId: number;
    commissionableJobAmount: number;
    commissionPercentage: number;
    commission: number;
}

/**
 * SalesCommission REST interface.
 * Deprecated. Since Sales App release. Still sed in JobCommissionSummary.vue as a part of SR Oasis functionality
 */
/** @deprecated */
export interface SalesCommissionApi {
    /** @deprecated */
    getSalesCommissionByJobReferenceId(referenceId: string): AxiosPromise<SalesCommission>;
}

/**
 * SalesCommission REST implementation.
 * Deprecated. Since Sales App release. Still sed in JobCommissionSummary.vue as a part of SR Oasis functionality
 */
class Implementation implements SalesCommissionApi {
    /** @deprecated */
    getSalesCommissionByJobReferenceId(referenceId: string): AxiosPromise<SalesCommission> {
        return Axios.axiosInstance().get<SalesCommission>(`/sales_commissions/job/${referenceId}`);
    }
}

const SalesCommissions = {
    /** @deprecated */
    getRestApi(): SalesCommissionApi {
        return new Implementation();
    }
};

export default SalesCommissions;
