<template>
  <modal-dialog
      title="Add/Remove Zip Codes"
      width="480px"
      :submitEnabled="true"
      cancelLabel="Close"
      @cancelClicked="onClose"
      :hideSubmit="true"
      :visible="visible"
  >
    <market-zip-code-search
        ref="zipCodeSearch"
        :zipCodes="zipCodes"
        :marketReferenceId="marketReferenceId"
        @refreshZipCodes="refreshZipCodes"
        @showError="showError"
        @showSuccess="showSuccess"
    />
  </modal-dialog>
</template>

<script>
import MarketZipCodeSearch from "@/components/markets/MarketZipCodes.vue";
import ModalDialog from "@/components/common/ModalDialog.vue";

export default {
  components: {
    MarketZipCodeSearch,
    ModalDialog
  },

  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      }
    },
    zipCodes: {
      default() {
        return [];
      }
    },
    marketReferenceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {};
  },

  methods: {
    onClose() {
      this.$refs.zipCodeSearch.reset();
      this.$emit("onClose");
    },
    refreshZipCodes() {
      this.$emit("refreshZipCodes");
    },
    showError(error) {
      this.$emit("showError", error);
    },
    showSuccess(message) {
      this.$emit("showSuccess", message);
    }
  }
};
</script>
