<template>
  <v-autocomplete
      :disabled="disabled"
      v-model="selected"
      :search-input.sync="searchInput"
      @input="searchInput=null"
      :items="marketSelections"
      :loading="loading"
      item-text="name"
      item-value="id"
      :label="label"
      placeholder="Start typing to search markets"
      :multiple="multipleSelection"
      :rules="[rules.required]"
      data-id="markets-autocomplete"
      hide-no-data
      validate-on-blur
      required
      dense
      flat
      attach
      clearable
      persistent-placeholder
  >
    <template v-slot:prepend-item class="select-all" v-if="selectAllEnabled">
      <auto-complete-select-all-dropdown-template
          :items="marketSelections"
          :selected="selected"
          @select="selectAll"
          @deselect="deselectAll"
      >
      </auto-complete-select-all-dropdown-template>
    </template>
  </v-autocomplete>
</template>

<script>
import Markets from "../../rest/markets";
import AutoCompleteSelectAllDropdownTemplate from "./selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";

export default {
  components: {
    AutoCompleteSelectAllDropdownTemplate
  },
  data() {
    return {
      selected: [],
      marketSelections: [],
      loading: false,
      rules: {
        required: value => {
          if (this.required) {
            if (this.$props.multipleSelection) {
              return !!value.length || "Market is required";
            } else {
              return !!value || "Market is required";
            }
          }
          return true;
        }
      },
      searchInput: null,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      default: true,
      type: Boolean
    },
    // Selected markets ids
    marketIds: Array | Number,
    selectAllEnabled: {
      type: Boolean,
      default: false
    },
    multipleSelection: {
      type: Boolean,
      default: true
    },
    filteredByBranch: {
      type: Boolean,
      default: false
    },
    // When true, it will fetch all markets on component creation
    getItemsOnComponentCreation: {
      type: Boolean,
      required: true,
    },
    // Markets available to select from
    markets: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  watch: {
    selected(value) {
      this.$emit('input', value);
    },
    markets: {
      immediate: true,
      handler(value) {
        if (value) {
          this.marketSelections = value;
        }
      }
    },
    marketIds: {
      immediate: true,
      handler(value) {
        this.selected = value;
      }
    }
  },

  computed: {
    label() {
      let label = "Market";
      return this.required ? "* " + label : label;
    }
  },

  created() {
    if (this.getItemsOnComponentCreation) {
      this.loadAndSetAllMarkets();
    }
  },

  methods: {
    async loadAndSetAllMarkets() {
      try {
        this.marketSelections = await this.getAll();
      } catch (error) {
        this.$emit('getAllError', error);
      }
    },
    async getAll() {
      let response;
      if (this.filteredByBranch) {
        response = await Markets.getRestApi().getAllMarketsFilteredByBranch();
      } else {
        response = await Markets.getRestApi().getAllActiveMarkets();
      }
      return response.data;
    },
    selectAll() {
      this.selected = this.marketSelections.map(item => item.id);
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>
<style scoped>
::v-deep .theme--light.v-list-item {
  width: 50%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
