<template>
  <v-card raised light color="white" class="mt-3 mb-3 elevation-7" v-if="job">
    <div class="job-header">
      <v-row class="job-builder-row">
        <span style="vertical-align: middle">{{ wizardTitle }} ({{ job.referenceId }})</span>
        <v-btn
            v-if="job.parentJobId"
            color="primary"
            small
            class="ml-4 darken-1"
            @click="onOpenParent"
        >
          <v-icon small class="mr-1">arrow_upward</v-icon>
          Parent Job
        </v-btn>
      </v-row>
      <v-flex xs2 class="job-invoiced-date">
        <span> {{ invoicedDateFormat }} </span>
      </v-flex>
      <v-flex xs2 v-if="nextAction">
        <v-btn
            dark text outlined
            style="font-size: 10pt"
            @click="nextActionsSelected"
        >
          <div>
            <strong>Next Action Date:</strong><br/>
            <span style="padding-left: 10px;">{{ nextAction }}</span>
          </div>
        </v-btn>
      </v-flex>
      <v-flex xs4 class="job-status-label">
        <v-flex xs3>
          <span style="color: white">Job Status</span>
        </v-flex>
        <v-flex xs8>
          <job-statuses-search-field
              v-model="jobStatusId"
              @statusSelected="onStatusSelected"
              @jobStatusSearchFieldError="emitError"
          />
        </v-flex>
      </v-flex>
    </div>

    <v-card-text>
      <contract-line-item-panel
          ref="contractLineItemPanel"
          :job="job"
          :lead="lead"
          :lineItem="contractItem"
          :warrantyLineItem="warrantyLineItem"
          :customer="customer"
          :loading="processing"
          :submitLabel="jobButtonState"
          :jobButtonActive="jobButtonActive"
          @priceWithoutWarrantyUpdated="handlePriceWithoutWarrantyUpdated"
          @totalPriceUpdated="markDirtyAndUpdateTotals"
          @onSaveLineItems="onSaveLineItems"
          @onStartFulfillment="onStartFulfillment"
          @onInvoiceJob="onInvoiceJob"
      />
      <product-line-items
          ref="products"
          :job="job"
          :initialItems="productItems"
          :hasVendorChargebacks="hasVendorChargebacks"
          :wsSessionId="wsSessionId"
          @dataUpdated="markDirtyDueToProductChanges"
          @itemsUpdated="onProductItemsUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
          @refresh="refresh"
          @onReturnFromJobSuccess="showReturnFromJobSuccessMessage"
          @onErrorOccurred="emitError"
      />
      <labor-line-items
          ref="laborLineItems"
          :job="job"
          :initialItems="laborItems"
          :installationIssueUpdated="installationIssueUpdated"
          @refresh="refresh"
          @dataUpdated="markDirtyIfNoSpecificEventOccurred"
          @itemsUpdated="onLaborItemsUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
          @emitError="emitError"
      />
      <installation-issue-line-items
          ref="installationIssues"
          v-show="installationIssueItems && installationIssueItems.length"
          :job="job"
          :initialItems="installationIssueItems"
          @dataUpdated="markDirty"
          @itemsUpdated="onInstallationIssueItemsUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
          @installationItemDeleted="onDeleteInstallationIssue"
          @onInstallerPayableAdded="buildUpdatedObjectWithInstallerPayableAmount"
      />
      <chargeback-line-items
          ref="chargebacks"
          :job="job"
          :initialItems="chargebackItems"
          @itemsUpdated="onChargebackItemUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
          @dataUpdated="markDirtyIfNoSpecificEventOccurred"
      />
      <misc-line-items
          ref="misc"
          :job="job"
          :initialItems="miscItems"
          :miscItemTypes="miscItemTypes"
          @dataUpdated="markDirtyIfNoSpecificEventOccurred"
          @itemsUpdated="onMiscItemsUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
      />
      <warranty-line-items
          ref="warranty"
          v-if="warrantyLineItem"
          :job="job"
          :initialItem="warrantyLineItem"
          @itemUpdated="onWarrantyItemUpdated"
      />
      <bca-line-items
          v-if="bcaItems && bcaItems.length"
          :job="job"
          :initialItems="bcaItems"
      />
      <job-line-item-totals
          ref="lineItemTotals"
          class="mb-4"
          :totalJobCost="totalJobCost"
      />
      <payment-line-items
          :job="job"
          :initialItems="paymentItems"
          :paymentTypes="paymentTypes"
          :wsSessionId="wsSessionId"
          class="mb-4"
          @dataUpdated="markDirtyIfNoSpecificEventOccurred"
          @itemsUpdated="onPaymentItemsUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
          @holdReceived="handleSuccessSaveLineItems"
        @onPaymentErrorOccurred="emitError"
      />
      <refund-line-items
          ref="refunds"
          v-show="refundItems && refundItems.length"
          :job="job"
          :initialItems="refundItems"
          @dataUpdated="markDirtyIfNoSpecificEventOccurred"
          @itemsUpdated="onRefundItemsUpdated"
          @amountsUpdated="jobCostOrRemainingBalanceAffected"
      />
      <job-line-item-totals
          ref="lineItemTotals"
          :remainingBalance="remainingBalance"
          :customerBalanceDue="customerBalanceDue"
      />
    </v-card-text>
    <v-card-actions class="mr-4 pb-3">
      <v-spacer></v-spacer>
      <p class="save-error">{{ saveError }}</p>
      <main-job-button
          v-if="jobButtonActive"
          :loading="processing"
          :submitLabel="jobButtonState"
          @onSaveLineItems="onSaveLineItems"
          @onStartFulfillment="onStartFulfillment"
          @onInvoiceJob="onInvoiceJob"
      />
    </v-card-actions>
    <no-labor-items-warning-dialog
        ref="noLaborItems"
        @confirmed="onNoLaborItemsConfirmed"
    />
    <plan-fulfillment-dialog
        ref="planDialog"
        :plan="jobFulfillmentPlan"
        :wsSessionId="wsSessionId"
        @fulfilling="onFulfillmentStateUpdated"
        @planProcessed="onPlanProcessed"
        @productSplit="onProductSplit"
        @fulfillmentError="fulfillmentError"
        @planUpdated="onPlanUpdated"
    />
    <invoice-confirm-dialog
        ref="invoiceConfirmDialog"
        @invoiceConfirmed="onInvoiceConfirmed"
    />
    <job-message-dialog
        ref="messageDialog"
        :message="jobSaveMessage"
        :hideCancel="hideCancel"
        :multiLineError="true"
        @accept="onAcceptMessage"
        @cancel="onCancelMessage"
    />
    <job-error-message-dialog
        ref="errorDialog"
        :message="jobErrorMessage"
    />
  </v-card>
</template>

<script>
import Constants from "../../assets/constants.js";
import ContractLineItemPanel from "../../components/jobs/ContractLineItemPanel.vue";
import InstallationIssueLineItems
  from "../../components/jobs/installationIssueLineItems/InstallationIssuesContainer.vue";
import JobLineItemTotals from "../../components/jobs/JobLineItemTotals.vue";
import JobStatusesSearchField from "../../components/searchFields/JobStatuses.vue";
import LaborLineItems from "../../components/jobs/laborLineItems/LaborLineItemsContainer.vue";
import PaymentLineItems from "../../components/jobs/paymentLineItems/PaymentLineItemsContainer.vue";
import PlanFulfillmentDialog from "../../components/jobs/planFulfillment/DialogContainer.vue";
import ProductLineItems from "../../components/jobs/productLineItems/ProductLineItemsContainer.vue";
import ChargebackLineItems from "../../components/jobs/chargebackLineItems/ChargebackLineItemsContainer.vue";
import BcaLineItems from "../../components/jobs/bcaLineItems/BcaContainer.vue";
import WarrantyLineItems from "../../components/jobs/warrantyLineItems/WarrantyLineItemsContainer.vue";
import RefundLineItems from "../../components/jobs/refundLineItems/RefundLineItemsContainer.vue";
import MiscLineItems from "../../components/jobs/miscLineItems/MiscLineItemsContainer.vue";
import JobErrorMessageDialog from "../../components/common/ErrorMessageDialog.vue";
import JobMessageDialog from "../../components/jobs/JobMessageDialog.vue";
import NoLaborItemsWarningDialog from "../../components/jobs/NoLaborItemsWarningDialog.vue";
import InvoiceConfirmDialog from "../../components/jobs/InvoiceConfirmDialog.vue";
import MainJobButton from "./MainJobButton.vue";
import PaymentTypes from "../../rest/paymentTypes";
import Jobs from "../../rest/jobs.ts";
import JobMiscItemTypes from "../../rest/jobMiscItemTypes";
import LineItemFormatter from "../../assets/jobBuilder/lineItemFormatter";
import LineItemValidator from "../../assets/jobBuilder/lineItemValidator";
import LineItemMapper from "../../assets/jobBuilder/lineItemMapper";
import DateFormatter from "../../assets/dateFormatter";
import JobBuilderHelper from "../../assets/jobBuilder/jobBuilderHelper";
import CurrencyFormatter from "../../assets/jobBuilder/currencyFormatter";
import JobLineItems from "@/rest/jobLineItems";
import FormattingHelper from "../../assets/formattingHelper";

export default {
  components: {
    MainJobButton,
    ContractLineItemPanel,
    InstallationIssueLineItems,
    JobLineItemTotals,
    JobStatusesSearchField,
    LaborLineItems,
    PaymentLineItems,
    PlanFulfillmentDialog,
    ProductLineItems,
    ChargebackLineItems,
    WarrantyLineItems,
    BcaLineItems,
    RefundLineItems,
    MiscLineItems,
    JobMessageDialog,
    JobErrorMessageDialog,
    NoLaborItemsWarningDialog,
    InvoiceConfirmDialog,
  },

  props: {
    jobId: Number,
    nextAction: {
      type: String | null,
      required: true
    },
    hasOpenNextActions: {
      type: Boolean,
      required: true
    },
    wsSessionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      job: null,
      lead: null,
      customer: null,
      contractItem: null,
      contractTotal: null,
      jobStatusId: null,
      productItems: [],
      productTotal: null,
      laborItems: [],
      laborTotal: null,
      financeItems: [],
      financeTotal: null,
      paymentItems: [],
      paymentTypes: [],
      chargebackItems: [],
      chargebackTotal: null,
      bcaItems: [],
      warrantyLineItem: null,
      refundItems: [],
      miscItems: [],
      miscTotal: null,
      bcaTotal: null,
      totalJobCost: null,
      remainingBalance: null,
      customerBalanceDue: null,
      jobFulfillmentPlan: null,
      changesDetected: false,
      error: null,
      saveError: null,
      haveAllItemsBeenFulfilled: true,
      jobSaveMessage: null,
      jobErrorMessage: null,
      installationIssueItems: [],
      installationIssueTotal: null,
      refundTotal: null,
      allowNoLaborItems: false,
      invoiceConfirmed: false,
      processing: false,
      miscItemTypes: [],
      installationIssueUpdated: null,
      applyingUpdatedData: false,
      hideCancel: false,
      splitItems: []
    };
  },

  watch: {
    jobId: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
    jobStatusId(value) {
      let old = this.job.jobStatus.id;
      if (!old || value !== old) {
        this.markDirty();
      }
    },
    changesDetected(value) {
      this.$emit('changesDetected', value);
    },
    productItems(items) {
      this.$emit('productItemsUpdated', items);
    },
    laborItems(items) {
      this.$emit('laborItemsUpdated', items);
    }
  },

  computed: {
    wizardTitle() {
      return JobBuilderHelper.getWizardTitle(this.job);
    },
    jobButtonState() {
      return this.applyingUpdatedData
          ? " "
          : JobBuilderHelper.defineButtonState(this.job, this.areAllItemsFulfilled(this.productItems), this.changesDetected, this.productItems);
    },
    jobButtonActive(){
      return JobBuilderHelper.defineButtonActive(this.job, this.changesDetected);
    },
    invoicedDateFormat() {
      return this.job.invoiced
          ? ("Invoiced on " + DateFormatter.formatDateInMMDDYYYYFormat(this.job.invoicedDate))
          : "";
    },
    hasVendorChargebacks() {
      return this.chargebackItems.length > 0;
    }
  },

  created() {
    this.getPaymentTypes();
    this.getJobMiscItemTypes();
  },

  destroyed() {
    this.closeWebSocket();
  },

  methods: {
    async getPaymentTypes() {
      try {
        const response = await PaymentTypes.getRestApi().getEnabledPaymentTypes();
        this.paymentTypes = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async refresh() {
      this.processing = true;
      this.applyingUpdatedData = true;
      try {
        const data = await this.getJobSummaryData();
        await this.getCombinedStops(data);
        this.resetSummaryLineItems();
        this.setJobSummaryData(data);
      } catch (error) {
        this.$emit('showError', error);
      }
      this.$emit('refreshInlineGrid');
      this.processing = false;
      this.changesDetected = false;
      this.applyingUpdatedData = false;
      this.$refs.laborLineItems?.reloadSlots();
    },
    showUpdatedJobCostAndRemainingBalance(response) {
      this.totalJobCost = CurrencyFormatter.asCurrency(response.jobCost).value;
      this.remainingBalance = CurrencyFormatter.asCurrency(response.remainingBalance).value;
      this.customerBalanceDue = CurrencyFormatter.asCurrency(response.customerBalanceDue).value;
    },
    async getJobSummaryData() {
      const response = await Jobs.getRestApi().getJobSummaryById(this.jobId);
      return response.data;
    },
    setJobSummaryData(data) {
      let summary = {...data};
      let job = summary.job;
      let lead = this.addExtraPropertiesToLead(summary.lead, summary.leadExtraDetails);
      this.job = job;
      this.lead = lead;
      this.customer = summary.customer;
      this.jobStatusId = job.jobStatus ? job.jobStatus.id : null;
      this.setInitialJobLineItems(summary.lineItems);
      this.totalJobCost = summary.totalJobCost;
      this.remainingBalance = summary.remainingBalance;
      this.customerBalanceDue = summary.customerBalanceDue;
      //       TODO: use only customer object instead of customerReferenceId
      this.$emit('remainingBalance', this.remainingBalance);
      this.$emit('customerReferenceId', this.customer.referenceId);
      this.$emit('setCustomer', this.customer);
      this.$emit('setLead', this.lead);
      summary.job.leadReferenceId = lead.referenceId;
      this.$emit('updateJobData', summary.job);
    },
    resetSummaryLineItems() {
      this.contractItem = null;
      this.productItems = [];
      this.laborItems = [];
      this.paymentItems = [];
      this.financeItems = [];
      this.chargebackItems = [];
      this.installationIssueItems = [];
      this.warrantyLineItem = null;
      this.bcaItems = [];
      this.refundItems = [];
      this.miscItems = [];
    },
    setInitialJobLineItems(lineItems) {
      lineItems.forEach((summary) => {
        summary.jobLineItem.price = CurrencyFormatter.asCurrency(summary.jobLineItem.price);
        summary.jobLineItem.posted = true;
        let {type} = summary.jobLineItem;
        switch (type) {
          case Constants.jobType.contract:
            summary.jobLineItem.contractPriceWithoutWarranty = CurrencyFormatter.asCurrency(summary.jobLineItem.contractPriceWithoutWarranty);
            this.contractItem = summary;
            break;
          case Constants.jobType.finance:
            this.financeItems.push(summary);
            break;
          case Constants.jobType.payment:
            this.paymentItems.push(summary);
            break;
          case Constants.jobType.labor:
            this.laborItems.push(summary);
            break;
          case Constants.jobType.product:
            this.productItems.push(summary);
            break;
          case Constants.jobType.installIssue:
            this.installationIssueItems.push(summary);
            break;
          case Constants.jobType.chargeback:
            this.chargebackItems.push(summary);
            break;
          case Constants.jobType.bca:
            this.bcaItems.push(summary);
            break;
          case Constants.jobType.refund:
            this.refundItems.push(summary);
            break;
          case Constants.jobType.misc:
            this.miscItems.push(summary);
            break;
          case Constants.jobType.warranty:
            summary.jobLineItem.fiftyFloorWarrantyCost = CurrencyFormatter.asCurrency(summary.jobLineItem.fiftyFloorWarrantyCost);
            summary.jobLineItem.salesRepWarrantyCost = CurrencyFormatter.asCurrency(summary.jobLineItem.salesRepWarrantyCost);
            this.warrantyLineItem = summary;
            break;
        }
      });
      this.haveAllItemsBeenFulfilled = this.areAllItemsFulfilled();
      if (!this.contractItem) {
        this.assureContractLineItem();
      }
    },
    areAllItemsFulfilled() {
      return JobBuilderHelper.areAllItemsFulfilled(this.productItems);
    },
    assureContractLineItem() {
      this.contractItem = LineItemMapper.assureContractLineItem();
    },
    onProductItemsUpdated(items) {
      if (items.length !== this.productItems.length) {
        this.changesDetected = true;
      }
      this.productItems = items;
    },
    onLaborItemsUpdated(items) {
      this.laborItems = items;
    },
    onChargebackItemUpdated(item) {
      this.chargebackItems = item;
    },
    onInstallationIssueItemsUpdated(items) {
      this.installationIssueItems = items;
    },
    onPaymentItemsUpdated(items) {
      this.paymentItems = items;
    },
    onRefundItemsUpdated(items) {
      this.refundItems = items;
    },
    onMiscItemsUpdated(items) {
      this.miscItems = items;
    },
    onWarrantyItemUpdated(item) {
      this.warrantyLineItem = item;
    },
    markDirtyDueToProductChanges() {
      if (!this.splitItems.length) {
        this.changesDetected = true;
        this.$emit('itemsDirty');
      }
      this.splitItems = [];
    },
    markDirty() {
      this.changesDetected = true;
      this.$emit('itemsDirty');
    },
    handlePriceWithoutWarrantyUpdated(update) {
      if (this.warrantyLineItem !== null) {
        this.$refs.warranty.recalculateWarranty(update.priceWithoutWarranty);
      } else {
        update.totalPrice = update.priceWithoutWarranty;
        this.markDirtyAndUpdateTotals(update);
      }
    },
    markDirtyAndUpdateTotals(update) {
      this.contractTotal = update.totalPrice;
      this.contractItem = update.item;
      this.markDirtyIfNoSpecificEventOccurred();
      this.jobCostOrRemainingBalanceAffected();
    },
    markDirtyIfNoSpecificEventOccurred() {
      const specificEvent = this.$store.state.refreshJobEventType;
      if (!specificEvent) {
        this.markDirty();
      }
      this.$store.commit("setRefreshJobEventType", null);
    },
    onStatusSelected(status) {
      this.jobStatusId = status;
    },
    onFulfillmentStateUpdated(update) {
      this.processing = update;
    },
    onSaveLineItems() {
      let contractPrice = CurrencyFormatter.asCurrency(this.contractItem.jobLineItem.price);
      if (this.totalJobCost > contractPrice.value) {
        let difference = CurrencyFormatter.formatCurrencyWithDollarPrefix(this.totalJobCost - contractPrice);
        this.jobSaveMessage = "This job currently operates at a loss of " + difference + ". Do you want to proceed?";
        this.$refs.messageDialog.openDialog();
      } else {
        this.performSaveLineItems();
      }
    },
    async performSaveLineItems() {
      this.processing = true;
      this.$store.commit('setIsJobSaving', true);
      try {
        this.saveError = null;
        var verified;
        if (!this.job.invoiced) {
          verified = this.verifyAllLineItems();
        } else {
          const paymentVerify = LineItemValidator.verifyPaymentLineItems(this.$data.paymentItems, this.paymentTypes);
          if (!paymentVerify.verified && paymentVerify.error) {
            this.showLineItemValidationError(paymentVerify.error);
            verified = false;
          } else {
            verified = true;
          }
        }
        if (verified) {
          const saveContent = this.createContentObjectForSave();
          await this.saveJobLineItems(saveContent);
          this.handleSuccessSaveLineItems();
        } else this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$emit('showError', error);
        //TODO check
      }
      this.$store.commit('setIsJobSaving', false);
    },
    onAcceptMessage() {
      this.performSaveLineItems();
      this.$refs.messageDialog.closeDialog();
    },
    onCancelMessage() {
      this.$refs.messageDialog.closeDialog();
    },
    addChargeback(payload) {
      this.$refs.chargebacks.onAddChargebackItem(payload);
      this.markDirty();
    },
    forceContractPriceUpdate() {
      let existing = this.contractItem;
      let updated = {jobLineItem: existing.jobLineItem};
      this.$set(this.$data, "contractItem", updated);
    },
    addInstallationIssue(payload) {
      let added = this.$refs.installationIssues.onAddItem(payload);
      let contractTotal = CurrencyFormatter.asCurrency(this.contractItem.jobLineItem.price);
      let contractPriceAdj = CurrencyFormatter.asCurrency(added.jobLineItem.price);
      this.contractItem.jobLineItem.price = contractTotal.add(contractPriceAdj);
      this.forceContractPriceUpdate();
      this.markDirty();
    },
    addRefund(payload) {
      this.$refs.refunds.onAddItem(payload);
    },
    getInstallerPayableAmount(jobLineItem) {
      const amount = jobLineItem.scgPayable > 0 ? jobLineItem.scgPayable : jobLineItem.installerPayable;
      return amount || 0;
    },
    onDeleteInstallationIssue(payload) {
      const jobLineItem = payload.jobLineItem;
      const contractTotal = CurrencyFormatter.asCurrency(this.contractItem.jobLineItem.price);
      const contractPriceAdj = CurrencyFormatter.asCurrency(jobLineItem.price);

      this.contractItem.jobLineItem.price = contractTotal.subtract(contractPriceAdj);
      this.forceContractPriceUpdate();

      const amount = this.getInstallerPayableAmount(jobLineItem);
      this.buildUpdatedObjectWithInstallerPayableAmount(amount, jobLineItem.installation.jobLineItemId, false);
      this.markDirty();
    },
    jobCostOrRemainingBalanceAffected() {
      let mappedItems = LineItemMapper.formatLineItemsForWebsocket(LineItemMapper.combineAllLineItemsFromJobForCalculation(this));
      this.$emit('sendMessageThroughWebSocket', mappedItems);
    },
    createContentObjectForSave() {
      let content = {};
      content.jobStatusId = this.jobStatusId;
      content.contractLineItem = LineItemFormatter.formatContractLineItemsForSave(this.contractItem.jobLineItem);
      content.productLineItems = LineItemFormatter.formatProductLineItemsForSave(this.productItems);
      content.laborLineItems = LineItemFormatter.formatLaborLineItemsForSave(this.laborItems);
      content.chargebackLineItems = LineItemFormatter.formatChargebackLineItemsForSave(this.chargebackItems);
      content.installationIssueLineItems = LineItemFormatter.formatInstallationIssueLineItemsForSave(this.installationIssueItems);
      content.refundLineItems = LineItemFormatter.formatRefundLineItemsForSave(this.refundItems);
      content.miscLineItems = LineItemFormatter.formatMiscLineItemsForSave(this.miscItems);
      content.paymentLineItems = LineItemFormatter.formatPaymentLineItemsForSave(this.paymentItems);
      content.warrantyLineItem = LineItemFormatter.formatWarrantyLineItemForSave(this.warrantyLineItem);
      return content;
    },
    async saveJobLineItems(content) {
      const response = await JobLineItems.getRestApi().setJobLineItems(this.jobId, content, this.wsSessionId);
      return response.data;
    },
    verifyAllLineItems() {
      const {
        productItems,
        laborItems,
        financeItems,
        paymentItems,
        miscItems,
      } = this.$data;

      const productVerify = LineItemValidator.validateLineItems(productItems);
      if (!productVerify.verified && productVerify.error) {
        this.showLineItemValidationError(productVerify.error);
        return;
      }
      const laborVerify = LineItemValidator.validateLineItems(laborItems);
      if (!laborVerify.verified && laborVerify.error) {
        this.showLineItemValidationError(laborVerify.error);
        return;
      }
      const financeVerify = LineItemValidator.validateLineItems(financeItems);
      if (!financeVerify.verified && financeVerify.error) {
        this.showLineItemValidationError(financeVerify.error);
        return;
      }
      const paymentVerify = LineItemValidator.verifyPaymentLineItems(paymentItems, this.paymentTypes);
      if (!paymentVerify.verified && paymentVerify.error) {
        this.showLineItemValidationError(paymentVerify.error);
        return;
      }
      const miscVerify = LineItemValidator.validateLineItems(miscItems);
      if (!miscVerify.verified && miscVerify.error) {
        this.showLineItemValidationError(miscVerify.error);
        return;
      }

      return true;
    },
    showLineItemValidationError(error) {
      this.saveError = error;
      this.$refs.contractLineItemPanel.showSaveError(error);
    },
    handleSuccessSaveLineItems() {
      this.$emit('itemsSaved');
      this.refresh();
      this.changesDetected = false;
      this.haveAllItemsBeenFulfilled = true;
      this.processing = false;
      this.showLineItemValidationError(null);
      this.splitItems = [];
    },
    onStartFulfillment() {
      this.processing = true;
      this.fulfilling = true;
      Jobs.getRestApi()
          .createJobFulfillmentPlan(this.jobId)
          .then((response) => {
            this.jobFulfillmentPlan = response.data;
            this.processing = false;
            this.$refs.planDialog.openDialog();
          })
          .catch((error) => {
            this.processing = false;
            this.emitError(error);
          });
    },
    onPlanProcessed() {
      this.$emit('jobFulfilled');
      this.refresh();
    },
    onPlanUpdated(plan) {
      this.jobFulfillmentPlan = plan;
    },
    onInvoiceConfirmed() {
      this.invoiceConfirmed = true;
      this.onInvoiceJob();
    },
    onNoLaborItemsConfirmed() {
      this.allowNoLaborItems = true;
      this.onInvoiceJob();
    },
    onInvoiceJob() {
      let validated = this.validateIfJobHasMiscItemsNotFoundDuringJobSync();
      if (!validated) return;
      if (!this.allowNoLaborItems && this.laborItems.length === 0) {
        this.$refs.noLaborItems.openDialog();
        return;
      }
      this.processing = true;
      Jobs.getRestApi()
          .checkInvoiceJob(this.jobId)
          .then(() => {
            this.processing = false;
            if (!this.invoiceConfirmed) {
              this.$refs.invoiceConfirmDialog.openDialog();
              return;
            }
            this.performInvoice();
          })
          .catch((error) => {
            this.processing = false;
            this.emitError(error);
          });
    },
    performInvoice() {
      this.processing = true;
      Jobs.getRestApi().invoiceJob(this.jobId, this.wsSessionId)
          .then((response) => {
            this.processing = false;
            this.$emit('jobInvoiced', response.data);
            this.refresh();
          })
          .catch((error) => {
            this.processing = false;
            this.emitError(error);
          });
    },
    async onOpenParent() {
      try {
        let response = await Jobs.getRestApi().getJobById(this.job.parentJobId);
        let job = response.data;
        this.$emit('open', job.referenceId);
      } catch (error) {
        this.emitError(error);
      }
    },
    emitError(error) {
      this.$emit('jobBuilderPanelError', error);
    },
    async getJobMiscItemTypes() {
      this.miscItemTypes = await this.getAllJobMiscItemTypes();
    },
    async getAllJobMiscItemTypes() {
      const response = await JobMiscItemTypes.getRestApi().getAll();
      return response.data;
    },
    validateIfJobHasMiscItemsNotFoundDuringJobSync() {
      if (this.miscItems.some(miscItem => miscItem.jobLineItem.itemTypeId === Constants.miscItemNeedsReviewId)) {
        let error = new Error('There is an item in MISCELLANEOUS section that requires your attention. ' +
            'You cannot invoice the job unless you deal with it');
        this.emitError(error);
        return false;
      } else return true
    },
    nextActionsSelected() {
      this.$emit('openNextActionTab');
    },
    fulfillmentError(error) {
      this.emitError(error);
    },
    async getCombinedStops(data) {
      let laborItems = data.lineItems.filter(li => li.jobLineItem.laborItem);
      let payload = [];
      laborItems.map(li => {
        if (li.installation && li.installation.installationDate && li.jobLineItem.installerCrewId) {
          let crewAndDate = this.crateCrewPayloadObject(li);
          payload.push(crewAndDate);
        }
      });
      let groups = await this.performGetAllStopGroupsRequest(payload);
      this.setStopsForLaborItems(groups, laborItems);
    },
    async performGetAllStopGroupsRequest(payload) {
      const response = await Jobs.getRestApi().getAllStopGroupsForLaborItems(payload);
      return response.data;
    },
    setStopsForLaborItems(stopsData, laborItems) {
      laborItems.map(li => {
        let crewData = [];
        stopsData.forEach(match => {
          if (li.installation && li.jobLineItem.installerCrewId === match.crewId
              && DateFormatter.formatDateInYYYYMMDDFormat(li.installation.installationDate) === DateFormatter.formatDateInYYYYMMDDFormat(match.installationDate)) {
            crewData.push(match);
          }
        });
        if (crewData) {
          li.crewStopsData = crewData;
          return li;
        }
      });
    },
    crateCrewPayloadObject(li) {
      let crewId = li.jobLineItem.installerCrewId;
      let date = DateFormatter.formatDateInUtcAndYYYYMMDDFormat(li.installation.installationDate);
      return {
        crewId,
        date
      };
    },
    buildUpdatedObjectWithInstallerPayableAmount(installerPayableAmount, updatedJobLineItemId, increaseValue) {
      this.installationIssueUpdated = {
        installerPayable: installerPayableAmount,
        updatedJobLineItemId: updatedJobLineItemId,
        increase: increaseValue
      };
    },
    showReturnFromJobSuccessMessage(message) {
      this.$emit('onReturnFromJobSuccess', message);
    },
    addExtraPropertiesToLead(lead, leadExtraDetails) {
      lead.promoCode = FormattingHelper.getPromoCodeName(leadExtraDetails);
      lead.leadRooms = FormattingHelper.getFormattedLeadRoomTypes(leadExtraDetails);
      lead.productTypes = FormattingHelper.getFormattedLeadProductTypes(leadExtraDetails);
      lead.appointmentDate = FormattingHelper.getFormattedLeadAppointmentDate(lead);
      return lead;
    },
    onProductSplit(referenceIds) {
      this.splitItems = referenceIds;
    },
    clearChangesDetectedAfterSpecificFIleTypeUpdated() {
      this.changesDetected = false;
    }
  },
};
</script>

<style>
.section-button {
  width: 170px;
}
.job-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: #69b;
  color: white;
}
.job-builder-row {
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
}
.job-status-label {
  color: black;
  font-size: 12pt;
  display: flex;
  align-items: center;
}
.short-input div.v-input__control {
  max-height: 30px;
  margin-left: 15px;
}
.short-input-payments {
  max-height: 70px;
  margin-right: 15px;
}
.short-input-labor div.v-input__control {
  max-height: 70px;
  margin-left: 15px;
}
.short-input div.v-input__control div.v-input__slot {
  margin-bottom: 0;
}
.short-input div.v-input__control div.v-input__slot input {
  text-align: right;
  font-size: 14px;
}
.short-input.left-aligned div.v-input__control div.v-input__slot input {
  text-align: left;
}
.save-error {
  padding-right: 20px;
  color: red;
}
.job-invoiced-date {
  color: white;
  font-size: 12pt;
  display: block;
}
</style>
