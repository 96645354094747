<template>
  <v-autocomplete
      data-id="ticket-form-status"
      label="Status"
      :value="value"
      :items="items"
      item-value="id"
      item-text="name"
      @change="onStatusSelect"
      placeholder=" "
      attach
  />
</template>

<script>
import Tickets from "@/rest/tickets";

export default {
  props: ["value"],

  data() {
    return {
      items: []
    };
  },

  created() {
    this.getTicketStatuses();
  },

  methods: {
    onStatusSelect(value) {
      this.$emit("onStatusSelect", value);
    },
    async getTicketStatuses() {
      const data = await this.getAllTicketStatuses();
      this.setTicketStatuses(data);
    },
    setTicketStatuses(statuses) {
      this.items = statuses;
    },
    async getAllTicketStatuses() {
      try {
        const response = await Tickets.getRestApi().getTicketStatuses();
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>
