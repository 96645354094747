<template>
  <v-dialog v-model="wizardVisible" persistent :width="800">
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Edit Quantity Ordered</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" editable step="1">
          Password
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          New Quantity Ordered
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <validate-special-user-password-form
              ref="passwordForm"
              @passwordEmpty="passwordChangeHandler"
          />
          <span class="submitError" v-show="passwordError"> {{ passwordError }}</span>
          <button-container
              backName=Cancel
              nextName=Next
              :disableNextStep="isPasswordFieldEmpty"
              @getPreviousStep="resetWizard"
              @getNextStep="goToStepTwo"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <p-o-item-edited-quantity-confirmation-form
              ref="confirmationForm"
              :selectedItem="selectedPurchaseOrderItem"
          />
          <button-container-with-cancel
              backName=Back
              nextName=Confirm
              cancelName=Cancel
              @cancel="resetWizard"
              @getPreviousStep="goToStep(1)"
              @getNextStep="goToSubmitStep"
          />
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "@/components/common/wizard/ButtonContainer.vue";
import ButtonContainerWithCancel from "../../common/wizard/ButtonContainerWithCancel";
import ValidateSpecialUserPasswordForm from "../../common/form/ValidateSpecialUserPasswordForm.vue";
import POItemEditedQuantityConfirmationForm from "./POItemEditedQuantityConfirmationForm";
import PurchaseOrders from "../../../rest/purchaseOrders";

export default {
  components: {
    POItemEditedQuantityConfirmationForm,
    ValidateSpecialUserPasswordForm,
    ButtonContainerWithCancel,
    ButtonContainer
  },

  props: {
    purchaseOrder: Object,
    passwordError: String
  },

  data() {
    return {
      wizardVisible: false,
      error: null,
      step: 1,
      selectedPurchaseOrderItem: null,
      isPasswordFieldEmpty: true
    };
  },

  methods: {
    passwordChangeHandler(value) {
      this.isPasswordFieldEmpty = value;
    },
    async submitEditPoItemQuantity() {
      let purchaseOrderItemId = this.selectedPurchaseOrderItem.data.id;
      const payload = {
        order: this.$refs.confirmationForm.getOrder(),
        total: this.$refs.confirmationForm.getVendorCost(),
      }
      try {
        const response = await PurchaseOrders.getRestApi().editQuantityOrdered(purchaseOrderItemId, payload);
        this.$emit('onSubmitPOQuantityEditSuccess', response.data);
        if (response) {
          this.resetWizard();
        }
      } catch (error) {
        this.$emit('onUpdateQuantityError', error);
      }
    },
    openEditPOQuantityDialog(selectedItem) {
      this.resetWizard();
      this.wizardVisible = true;
      this.selectedPurchaseOrderItem = selectedItem;
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    goToStep(step) {
      this.step = step;
    },
    async goToStepTwo() {
      let valid = await this.$refs.passwordForm.validatePasswordForm();
      if (valid) {
        this.resetError();
        this.goToStep(2);
      } else {
        this.$emit('onPasswordError');
      }
    },
    goToSubmitStep() {
      this.submitEditPoItemQuantity();
    },
    async resetWizard() {
      this.step = 1;
      this.selectedPurchaseOrderItem = null;
      this.isPasswordFieldEmpty = true;
      this.closeDialog();
      this.resetAllForms();
    },
    resetAllForms() {
      this.$nextTick(() => {
        this.$refs.passwordForm.loadDefaults();
      })
      this.resetError();
    },
    resetError() {
      this.$emit('onWizardReset');
    }
  }
};
</script>
<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
