<template>
  <v-row>
    <v-row class="d-flex flex-row-reverse mr-1 ml-1">
      <v-col md="4" class="mr-2">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
      <v-row class="mr-2 ml-2">
        <v-col md="6">
          <v-text-field
              v-model="name"
              label="* Name"
              placeholder=" "
              id="vendor-details-name"
              :rules="[rules.required, rules.max100]"
              maxlength="100"
              counter
              persistent-placeholder
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="phoneNumber"
              label="* Phone Number"
              placeholder=" "
              v-facade="phoneNumberMask"
              id="vendor-details-phone-number"
              :rules="[rules.required, rules.phoneNumber]"
              persistent-placeholder
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="contactFirstName"
              label="Contact First Name"
              placeholder=" "
              id="vendor-details-first-name"
              :rules="[rules.firstName, rules.max40]"
              maxlength="40"
              counter
              persistent-placeholder
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model="contactLastName"
              label="Contact Last Name"
              placeholder=" "
              id="vendor-details-last-name"
              :rules="[rules.lastName, rules.max40]"
              maxlength="40"
              counter
              persistent-placeholder
          />
        </v-col>
        <v-col md="12">
          <v-text-field
              v-model="contactEmail"
              label="Contact Email"
              placeholder=" "
              id="vendor-details-email"
              :rules="[rules.email]"
              persistent-placeholder
          />
        </v-col>
      </v-row>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col md="12">
        <address-form-with-autocomplete ref="addressForm"/>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import AddressFormWithAutocomplete from "@/components/common/form/AddressFormWithAutocomplete.vue";
import PhoneMask from "../../assets/phoneMask";
import Rules from "../../assets/rules";
import Constants from "../../assets/constants";

const formDefaults = {
  name: null,
  phoneNumber: null,
  contactFirstName: null,
  contactLastName: null,
  contactEmail: null,
  active: true
};

export default {
  components: {AddressFormWithAutocomplete, StatusDropdown},

  props: {
    create: Boolean
  },

  data() {
    return Object.assign(
        {
          defaultState: formDefaults,
          originalState: null,
          rules: {
            required: Rules.required,
            phoneNumber: Rules.phoneNumberWithDelimiter,
            email: Rules.email,
            max40: value => Rules.maxValue(value, 40),
            max100: value => Rules.maxValue(value, 100),
            firstName: value => {
              return (!value && !!this.contactFirstName) ? "Required with First Name" : true;
            },
            lastName: value => {
              return (!value && !!this.contactLastName) ? "Required with Last Name" : true
            },
          },
          phoneNumberMask: Constants.phoneNumberMask
        },
        formDefaults
    );
  },

  methods: {
    changeStatus(value) {
      this.active = value;
    },
    resetForm(submit) {
      if (this.create) {
        this.loadForm(this.defaultState);
      } else {
        if (!submit) {
          this.loadForm(this.originalState);
        }
      }
    },
    setDefaultData(data) {
      if (!data) {
        data = this.defaultState;
      }
      this.setOriginalState(data);
      return data;
    },
    setOriginalState(data) {
      if (this.originalState !== data) {
        this.originalState = Object.assign({}, data);
      }
    },
    buildPayload() {
      const payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      payload.name = this.name;
      payload.phoneNumber = PhoneMask.unMaskPhone(this.phoneNumber);
      payload.statusValue = this.statusValue;
      payload.contactFirstName = this.contactFirstName;
      payload.contactLastName = this.contactLastName;
      payload.contactEmail = this.contactEmail;
      payload.active = this.active;
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      this.setOriginalState(payload);
      return payload;
    },
    loadForm(payload) {
      payload = this.setDefaultData(payload);
      this.name = payload.name;
      this.phoneNumber = payload.phoneNumber;
      this.statusValue = payload.statusValue;
      this.contactFirstName = payload.contactFirstName;
      this.contactLastName = payload.contactLastName;
      this.contactEmail = payload.contactEmail;
      this.active = payload.active;
      this.$refs.addressForm.setAddressData(payload);
    }
  }
};
</script>
