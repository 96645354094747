<template>
  <v-data-table
      :headers="headers"
      :items="customers"
      item-key="name"
      item-id="id"
      light
      :items-per-page="defaultItemsOnPageValue"
      :disable-pagination="isPaginationAndFooterDisabled"
      :hide-default-footer="isPaginationAndFooterDisabled"
      dense
  >
    <template v-slot:item="{ item }">
      <tr
          :class="[isSelected(item) ? 'selected-row' : '']"
          @click="rowSelected(item)"
      >
        <td class="text-lg-left pa-4">
          {{ item.fullName }}
        </td>
        <td class="text-lg-left pa-4">
          {{ item.address }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.city }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.zipCode }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.primaryPhone }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.alternatePhone }}
        </td>
        <td class="text-lg-center pa-4">
          {{ item.email }}
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      Customers with the same primary or alternate phone number are not found.
    </template>
  </v-data-table>
</template>

<script>

export default {

  props: {
    customers: {
      type: Array | null,
      required: true
    }
  },

  data() {
    return {
      selected: null,
      headers: [
        {text: "Full Name", value: "fullName", sortable: false, align: "center"},
        {text: "Address", value: "address", sortable: false, align: "center"},
        {text: "City", value: "city", sortable: false, align: "center"},
        {text: "Zip Code", value: "zipCode", sortable: false, align: "center"},
        {text: "Primary Phone", value: "primaryPhone", sortable: false, align: "center"},
        {text: "Alternate Phone", value: "alternatePhone", sortable: false, align: "center"},
        {text: "Email", value: "email", sortable: false, align: "center"},
      ],
      defaultItemsOnPageValue: 5
    };
  },

  computed: {
    isPaginationAndFooterDisabled() {
      return this.customers.length <= this.defaultItemsOnPageValue;
    }
  },

  methods: {
    rowSelected(selected) {
      this.$emit('customerSelected', selected);
      return this.selected = selected;
    },
    isSelected(item) {
      return this.selected === item;
    },
    clearSelected() {
      this.selected = null;
    }
  }
};
</script>

<style scoped>
.selected-row {
  background-color: #cce5ff;
}

tr {
  cursor: pointer;
}

</style>
