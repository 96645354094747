<template>
  <v-row>
    <v-col md="12">
      <v-text-field
          v-model="branchName"
          label="Branch"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-branch"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          v-model="binName"
          label="Bin Name"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-bin-name"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          v-model="aging"
          label="Aging"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-aging"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          v-model="rollNumber"
          label="Roll Number"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-roll-number"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          v-model="dyeLot"
          label="Dye Lot"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-dye-lot"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          v-model="onHand"
          label="On Hand"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-on-hand"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          v-model="available"
          label="Available"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-available"
      />
    </v-col>
    <v-col md="12">
      <v-text-field
          v-model="customerName"
          label="Customer Name"
          placeholder=" "
          persistent-placeholder
          readonly
          id="inventory-details-customer-name"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import Customers from "../../rest/customers.ts";
import FormattingHelper from "../../assets/formattingHelper";

const formDefaults = {
  rollNumber: null,
  dyeLot: null,
  status: null,
  binName: null,
  onHand: null,
  available: null,
  branchName: null,
  customerName: null,
  aging: null,
};

export default {
  components: {},

  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    getAgingInDays(createdAt) {
      const today = moment();
      const createdDate = moment(createdAt);
      const difference = today.diff(createdDate, "days");
      return `${difference} Days`;
    },
    async loadForm(payload) {
      this.rollNumber = payload.rollNumber;
      this.dyeLot = payload.dyeLot;
      this.binName = payload.inventoryBin.binNumber;
      this.branchName = payload.branchName;
      this.onHand = FormattingHelper.availableFormat(payload.onHand || 0);
      this.available = FormattingHelper.availableFormat(payload.available || 0);
      this.aging = this.getAgingInDays(payload.agingStartDateTime);
      this.customerName = " ";

      if (payload.customerId) {
        try {
          const response = await Customers.getRestApi().getCustomer(payload.customerId);
          this.customerName = response.data.fullName;
        } catch (error) {
          throw error;
        }
      }
    },
  },
};
</script>
