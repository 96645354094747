<template>
  <grid-view
      ref="nav"
      page-title="Promo Codes"
      nav-section="admin"
      :action-button-label="isActionButtonDisplayed ? 'Create Promo Code' : null"
      :data-source="dataSource"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="Status"
        caption="Status"
        width="10%"
        :cell-template="StatusRenderer"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Key"
        caption="Promo Code"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Markets"
        caption="Markets"
        data-type="string"
    />
    <dx-column
        data-field="Description"
        caption="Description"
        :allowHeaderFiltering="false"
        data-type="string"
    />

    <template v-slot:drawerContent>
      <create-drawer
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "@/views/common/GridView.vue";
import CreateDrawer from "@/components/promoCodes/CreateDrawer.vue";
import StatusRenderer from "@/components/common/grid/StatusRenderer.js";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "@/rest/odataContext";
import Roles from "@/assets/roles";

export default {
  components: {
    GridView,
    CreateDrawer,
    DxColumn
  },
  data() {
    return {
      dataSource: {
        store: ODataContext['PromoCodes'],
        select: ["Active", "Status", "Id", "Key", "Description", "Markets"]
      }
    };
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/promos/details/${data.Id}`);
    },
    onSubmitSuccess() {
      this.$refs.nav.showSuccess("Created new promo code successfully");
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    StatusRenderer
  }
};
</script>
