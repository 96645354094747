<template>
  <navigation-view ref="navigationView" page-title="Ticket Details" nav-section="customer">
    <template v-slot:content>
      <v-container grid-list-lg fluid>
        <ticket-read-only :ticket="ticket"/>

        <inline-grids
            ref="ticketsInlineGrids"
            :ticketId="ticketId"
            :referenceId="ticketReferenceId"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <ticket-update-drawer
          :ticket="ticket"
          @cancelClicked="onCancel"
          @onUpdateFail="onUpdateFail"
          @onUpdateSuccess="onUpdateSuccess"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="true"
          label="Edit Ticket"
          @click="showDrawer"
      />
    </template>

  </navigation-view>
</template>

<script>
import Tickets from "../../rest/tickets.ts";
import TicketReadOnly from "../../components/tickets/TicketReadOnly.vue";
import TicketUpdateDrawer from "../../components/tickets/TicketUpdateDrawer.vue";
import NavigationActionButton from "../../views/navigation/NavigationActionButton.vue";
import NavigationView from "../../views/navigation/NavigationView.vue";
import InlineGrids from "../../views/tickets/TicketsInlineGrids.vue";

export default {
  components: {
    TicketReadOnly,
    TicketUpdateDrawer,
    NavigationActionButton,
    NavigationView,
    InlineGrids
  },

  data() {
    return {
      ticketId: 0,
      ticketReferenceId: null,
      ticket: {}
    };
  },

  created() {
    this.display(this.$route.params.ticketReferenceId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.ticketReferenceId);
    next();
  },
  methods: {
    showDrawer() {
      this.$refs.navigationView.showDrawer();
    },
    display(ticketReferenceId) {
      this.ticketReferenceId = ticketReferenceId;
      this.getTicket();
    },
    async getTicket() {
      try {
        const response = await Tickets.getRestApi().getTicket(this.ticketReferenceId);
        this.setTicket(response.data);
      } catch (error) {
        throw error;
      }
    },
    setTicket(data) {
      this.ticket = data;
      this.ticketId = data.id;
    },
    onCancel() {
      this.$refs.navigationView.hideDrawer();
    },
    async onUpdateSuccess() {
      try {
        await this.getTicket();
        await this.$refs.ticketsInlineGrids.refreshActivityLog();
        this.$refs.navigationView.showSuccess("Updated ticket details successfully!");
        this.$refs.navigationView.hideDrawer();
      } catch (error) {
        this.$refs.navigationView.showError(error);
      }
    },
    onUpdateFail(error) {
      this.$refs.navigationView.showError(error);
    }
  }
};
</script>
