<template>
  <modal-dialog
      ref="dialog"
      title="Add Associated Product"
      width="1200"
      :visible="dialogVisible"
      submitLabel="Add Products"
      cancelLabel="Cancel"
      @submitClicked="onSubmitClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <select-catalog-items-container
        :dataSource="dataSource"
        ref="selectCatalogItems"
        @selectedItem="clearError"
    />
    <p class="error-message">{{ error }}</p>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";
import ProductCatalogItems from "../../rest/catalogItems";
import SelectCatalogItemsContainer from "../../components/catalogItems/SelectCatalogItemsContainer.vue";
import ODataContext from "../../rest/odataContext";

const defaultAssociatedItemsDataSource = {
  store: ODataContext['ProductCatalogItems'],
  select: [
    "Id",
    "InternalSku",
    "ProductType",
    "StyleName",
    "Color",
    "Description"
  ],
  sort: [{selector: "InternalSku", desc: false}],
  type: 'associatedProducts'
};

export default {
  data: () => ({
    dialogVisible: false,
    selected: null,
    error: null,
    dataSource: null
  }),

  components: {
    ModalDialog,
    SelectCatalogItemsContainer,
  },

  props: {
    catalogItemId: {
      type: Number,
      required: true
    }
  },

  methods: {
    setDataSource() {
      this.dataSource = Object.assign(defaultAssociatedItemsDataSource);
    },
    openDialog() {
      this.dialogVisible = true;
      this.clearError();
      this.setDataSource();
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onSubmitClicked() {
      const selectedItems = this.$refs.selectCatalogItems.getSelectedItems();
      if (selectedItems.length) {
        const selectedIds = selectedItems.map((item) => item.Id);
        const component = this;
        ProductCatalogItems.getRestApi()
            .addCatalogItemAssociatedItems(this.catalogItemId, selectedIds)
            .then((response) => {
              component.$emit('itemsAssociated', response.data);
            })
            .catch((error) => {
              component.$refs.dialog.showError(error);
            });
        this.closeDialog();
        this.$refs.selectCatalogItems.reset();
      } else {
        this.error = "There are no items selected. Please select from above to add associated products.";
      }
    },
    clearError() {
      this.error = null;
    },
    onCancelClicked() {
      this.dialogVisible = false;
      this.$refs.selectCatalogItems.reset();
    },

  },
};
</script>
