import Axios from "./axiosInstance";
import { ActivityLog } from "./interfaces/activityLogs";
import { AxiosPromise } from "axios";
import {Note} from "./notes";

export class Ticket {
  id: number;
  status: string;
  job: string;
  lead: string;
  customer: string;
  user: string;

  constructor(
    id: number,
    status: string,
    job: string,
    lead: string,
    customer: string,
    user: string
  ) {
    this.id = id;
    this.status = status;
    this.job = job;
    this.lead = lead;
    this.customer = customer;
    this.user = user;
  }
}

export class TicketStatus {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class TicketNote {
  id: number;
  note: string;
  ticketId: number;

  constructor(id: number, note: string, ticketId: number) {
    this.id = id;
    this.note = note;
    this.ticketId = ticketId;
  }
}

/**
 * REST interface provided for users.
 */
export interface TicketsRestApi {
  getTicket(refId: string): AxiosPromise<Ticket>;
  createTicket(payload: Ticket): AxiosPromise<Ticket>;
  updateTicket(referenceId: string, payload: Ticket): AxiosPromise<Ticket>;
  getTicketsByLead(leadReferenceId: string): AxiosPromise<Ticket>;
  getTicketsByJobReferenceId(jobReferenceId: string): AxiosPromise<Ticket>;
  getTicketActivityLogs(id: number): AxiosPromise<ActivityLog[]>;
  getTicketStatuses(): AxiosPromise<TicketStatus[]>;
  getTicketNotes(referenceId: string): AxiosPromise<Note[]>;
  createTicketNote(payload: TicketNote): AxiosPromise<TicketNote>;
}

/**
 * REST implementation provided for users.
 */
class Implementation implements TicketsRestApi {
  getTicket(refId: string): AxiosPromise<Ticket> {
    return Axios.axiosInstance().get<Ticket>(`/tickets/${refId}`);
  }
  createTicket(payload: Ticket): AxiosPromise<Ticket> {
    return Axios.axiosInstance().post<Ticket>("/tickets", payload);
  }
  updateTicket(referenceId: string, updated: Ticket): AxiosPromise<Ticket> {
    return Axios.axiosInstance().put<Ticket>(
      `/tickets/${referenceId}`,
      updated
    );
  }
  getTicketsByLead(leadReferenceId: string): AxiosPromise<Ticket> {
    return Axios.axiosInstance().get<Ticket>(
      `/tickets/lead/${leadReferenceId}`
    );
  }
  getTicketsByJobReferenceId(jobReferenceId: string): AxiosPromise<Ticket> {
    return Axios.axiosInstance().get<Ticket>(`/tickets/job/${jobReferenceId}`);
  }
  getTicketActivityLogs(id: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/tickets/${id}/logs`);
  }
  getTicketStatuses(): AxiosPromise<TicketStatus[]> {
    return Axios.axiosInstance().get<TicketStatus[]>(`ticket_statuses`);
  }
  createTicketNote(payload: TicketNote): AxiosPromise<TicketNote> {
    return Axios.axiosInstance().post<TicketNote>("/ticket_notes", payload);
  }
  getTicketNotes(referenceId: String): AxiosPromise<Note[]> {
    return Axios.axiosInstance().get<Note[]>(
        `/tickets/${referenceId}/notes`
    );
  }
}

const Tickets = {
  getRestApi(): TicketsRestApi {
    const api = new Implementation();
    return api;
  }
};

export default Tickets;
