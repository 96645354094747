<template>
  <v-layout row wrap>
    <!-- top section for ref id -->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex />
        <v-flex xs2 md2>
          <v-text-field
            :value="promoCode.active ? 'Active' : 'Inactive'"
            label="Status"
            placeholder=" "
            readonly
          />
        </v-flex>
      </v-layout>
      <v-divider class="detailsDivider" />
    </v-flex>

      <v-row class="ma-1">
        <v-col md="12">
          <v-text-field
            v-model="promoCode.key"
            label="Promo Code"
            placeholder=" "
            persistent-placeholder
            readonly
            id="promo-code-details-promo-code"
          />
        </v-col>
        <v-col md="12">
          <v-textarea
            :rows="1"
            auto-grow
            v-model="promoCode.marketNames"
            label="Markets"
            placeholder=" "
            persistent-placeholder
            readonly
            id="promo-code-details-markets"
          />
        </v-col>
      </v-row>

    <v-spacer></v-spacer>

      <v-row class="ma-1">
        <v-col md="12">
          <v-textarea
            v-model="promoCode.description"
            label="Description"
            placeholder=" "
            persistent-placeholder
            readonly
            :rows="2"
            id="promo-code-details-description"
          />
        </v-col>
      </v-row>

  </v-layout>
</template>

<script>
export default {
  props: {
    promoCode: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>
