<template>
  <v-checkbox
      :label=label
      v-model="model"
      dense
      @change="onCheckBoxChanged"
  >
  </v-checkbox>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean | null,
      required: true,
      default: () => !this.selected
    }
  },

  watch: {
    selected: {
      immediate: true,
      handler(value) {
        this.model = value;
      }
    }
  },

  data() {
    return {
      model: false
    };
  },

  methods: {
    onCheckBoxChanged(event) {
      this.$emit('onCheckBoxChanged', event);
    }
  }

};
</script>
