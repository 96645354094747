import LVT from './icons/LVT.svg';
import Laminate from './icons/Laminate.svg';
import Carpet from './icons/Carpet.svg';
import GlueDownHardwood from './icons/Glue_Down_Hardwood.svg';
import HDF from './icons/HDF.svg';
import Staircase from './icons/Staircase.svg';
import Tile from './icons/Tile.svg';
import Vinyl from './icons/Vinyl.svg';
import NailDownHardwood from './icons/Nail_Down_Hardwood.svg';
import UnfinishedHardwood from './icons/Unfinished_Hardwood.svg';

export const CapabilityIcons = {
    'LVT': LVT,
    'Laminate': Laminate,
    'Carpet': Carpet,
    'Glue Down Hardwood': GlueDownHardwood,
    'Nail Down Hardwood': NailDownHardwood,
    'HDF Hardwood': HDF,
    'Staircase': Staircase,
    'Tile': Tile,
    'Sheet Vinyl': Vinyl,
    'Unfinished Hardwood': UnfinishedHardwood
};