<template>
  <div>
    <inline-grid
      ref="grid"
      :data-source="schedule"
      :show-borders="false"
      @rowSelected="rowSelected"
      :hoverStateEnabled="selectable"
    >
      <dx-column
        data-field="appointmentDate"
        width="12%"
        data-type="date"
        caption="Date"
        :format="formatDate"
      />
      <dx-column
        data-field="appointmentTime"
        width="12%"
        data-type="string"
        caption="Time"
      />
    </inline-grid>
    <add-schedule-event-dialog ref="dialog" />
  </div>
</template>

<script>
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";
import AddScheduleEventDialog from "@/components/salesRepresentatives/AddScheduleEventDialog.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import SalesHome from "@/rest/salesHome";
import Format from "@/components/common/Format.js";
import * as moment from "moment";

/** @deprecated */
export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    AddScheduleEventDialog,
    DxColumn
  },

  props: ["salesRepReferenceId", "selectable", "salesRepUrls"],

  data() {
    return {
      schedule: []
    };
  },

  watch: {
    salesRepReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      let component = this;
      const salesRepReferenceId = this.$props.salesRepReferenceId;

      if (!salesRepReferenceId) {
        return;
      }

      SalesHome.getRestApi()
          .getSalesRepSchedule(salesRepReferenceId)
          .then((response) => {
            component.schedule = response.data;
          })
          .catch(() => {
            component.snackbar = true;
          });
    },
    rowSelected(data) {
      this.$router.push(`${this.$props.salesRepUrls ? "/sales" : ""}/leads/details/${data.leadRef}`);
    },
    clearSelection() {
      this.$refs.grid.clearSelection();
    },
    formatDate(date) {
      return moment.utc(date).format("MM/DD/YYYY");
    }
  }
};
</script>
