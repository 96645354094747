<template>
  <div>
    <v-layout class="grid" row wrap>
      <div v-for="(item, index) in zipCodes" :key="index" xs1 class="zipCode">
        {{ item.zipCode }}
      </div>
      <div v-if="zipCodes.length === 0" class="zipCodes-Empty">No data</div>
    </v-layout>
    <inline-grid-footer>
      <inline-grid-button
        v-if="isEditButtonDisplayed"
        label="Add/Remove Zip Codes"
        @click="emitToggleZipCodeDialog"
      />
    </inline-grid-footer>
  </div>
</template>

<script>
import { DxColumn } from "devextreme-vue/ui/data-grid";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";

export default {
  components: {
    InlineGridButton,
    InlineGridFooter,
    DxColumn
  },

  props: {
    zipCodes: {
      type: Array,
      required: true
    },
    isEditButtonDisplayed: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    emitToggleZipCodeDialog: function() {
      this.$emit("toggleZipCodeDialog");
    }
  }
};
</script>

<style>
.grid {
  color: black;
  padding: 10px 15px;
  font-size: 16px;
  max-height: 350px;
  min-height: fit-content;
  overflow-y: scroll;
}
.zipCode {
  text-align: center;
  width: 70px;
  padding: 3px;
  margin: 0px 15px;
}
.zipCodes-Empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  text-align: center;
  color: rgba(102, 102, 102, 0.87);
  font-size: 17px;
  font-family: "Roboto", "RobotoFallback", "Helvetica", "Arial", sans-serif;
}
</style>
