<template>
  <grid-view
      ref="nav"
      page-title="List Leads"
      nav-section="sales"
      :data-source="dataSource"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      :branchUserRestricted="true"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="CreatedAt"
        caption="Create Date"
        data-type="date"
        format="MM/dd/yyyy"
        :customize-text="customizeDateToTimezone"
        :calculate-filter-expression="modifyFilterToApplyUTCTimezone"
        :minWidth="160"
        :allowHeaderFiltering="false"
        sort-order="desc"
    />
    <dx-column
        data-field="MarketName"
        caption="Market"
        :minWidth="150"
        :allowHiding="false"
        :header-filter="{ dataSource: marketsFilter }"
        :allowHeaderFiltering="allowMarketFilter"
        data-type="string"
    />
    <dx-column
        data-field="LeadStatus"
        caption="Status"
        :header-filter="{ dataSource: statusesFilter }"
        data-type="string"
    />
    <dx-column
        data-field="ReferenceId"
        caption="Reference ID"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="AppointmentDate"
        caption="Appointment Date"
        :customize-text="customizeDate"
        :minWidth="160"
        :allowHeaderFiltering="false"
        data-type="date"
        :calculate-filter-expression="calculateAppointmentDateFilterExpression"
    />
    <dx-column
        data-field="AppointmentTime"
        caption="Appointment Time"
        :minWidth="135"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CustomerFirstName"
        caption="First Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CustomerLastName"
        caption="Last Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="Address"
        :minWidth="250"
        caption="Address"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="City"
        caption="City"
        :allowHeaderFiltering="false" data-type="string"
    />
    <dx-column
        data-field="ZipCode"
        caption="Zip Code"
        :allowHeaderFiltering="false" data-type="string"
    />
    <dx-column
        data-field="CustomerPrimaryPhone"
        caption="Cell Phone"
        :format="formatPhoneNumber"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="CustomerEmail"
        caption="Customer Email"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LeadInstallationCapabilities"
        caption="Installation Types"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LeadRooms"
        caption="Rooms"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="PromoCode"
        caption="Promotion"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="SalesRepFirstName"
        caption="Sales Rep First Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="SalesRepLastName"
        caption="Sales Rep Last Name"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="LastNote"
        caption="Last Note"
        alignment="left"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <template v-slot:wizardContent>
      <lead-wizard
          ref="leadWizard"
          @successCustomer="onSubmitWizardCustomerSuccess"
          @successLead="onSubmitWizardLeadSuccess"
          @error="onSubmitWizardFail"
          @cancel="onWizardCancel"
          @onError="onError"
      />
    </template>
  </grid-view>
</template>

<script>
import GridView from "../common/GridView.vue";
import {DxButton, DxColumn} from "devextreme-vue/ui/data-grid";
import LeadWizard from "@/components/leads/LeadWizard.vue";
import Format from "@/components/common/Format.js";
import CustomizeFilter from "@/components/common/grid/CustomizeFilter.js";
import ODataContext from "../../rest/odataContext"
import DateFormatter from "@/assets/dateFormatter";
import {EdmLiteral} from "devextreme/data/odata/utils"

export default {
  components: {
    GridView,
    LeadWizard,
    DxColumn,
    DxButton
  },
  data() {
    return {
      modifyFilterToApplyUTCTimezone(filterValue, selectedFilterOperation, target) {
        let dataField = "CreatedAt";
        // for not array filters like eq, le, ge, lt, gt
        if (!Array.isArray(filterValue)) {
          if (selectedFilterOperation === "=") {
            return [
              [dataField, ">=", new EdmLiteral(DateFormatter.getDateAtDayStart(filterValue))],
              "and",
              [dataField, "<=", new EdmLiteral(DateFormatter.getDateAtDayEnd(filterValue))]
            ];
          } else {
            return [[dataField, selectedFilterOperation,
              new EdmLiteral(DateFormatter.getDateAtDayStart(filterValue))]];
          }
          // for between array filters
        } else if (selectedFilterOperation === "between" && Array.isArray(filterValue)) {
          return [
            [dataField, ">=", new EdmLiteral(DateFormatter.getDateAtDayStart(filterValue[0]))],
            "and",
            [dataField, "<=", new EdmLiteral(DateFormatter.getDateAtDayEnd(filterValue[1]))]
          ];
        }
      },
      allowMarketFilter: true,
      marketsFilter: [],
      statusesFilter: [],
      dataSource: {
        store: ODataContext['WebLeads'],
        select: [
          "Id",
          "CreatedAt",
          "CustomerFirstName",
          "CustomerLastName",
          "Address",
          "City",
          "AppointmentDate",
          "AppointmentTime",
          "LeadInstallationCapabilities",
          "LeadRooms",
          "PromoCode",
          "LeadStatus",
          "MarketName",
          "MarketReferenceId",
          "MarketId",
          "ReferenceId",
          "LastNote",
          "CustomerPrimaryPhone",
          "BranchReferenceId",
          "ZipCode",
          "CustomerEmail",
          "SalesRepFirstName",
          "SalesRepLastName",
        ],
        sort: [{selector: "CreatedAt", desc: true}]
      },
    };
  },

  created() {
    this.getFilters();
  },

  methods: {
    async getFilters() {
      try {
        const user = this.$store.state.loggedInUser;
        const marketsFilter = await CustomizeFilter.getMarketFiltersIfNotBranchUser(user);
        const statusesFilter = await CustomizeFilter.getLeadStatusFilters();
        this.setFilters(marketsFilter, statusesFilter);
      } catch (error) {
        this.showNavError(error);
      }
    },
    setFilters(marketsFilter, statusesFilter) {
      if (marketsFilter) {
        this.marketsFilter = marketsFilter;
        this.allowMarketFilter = true;
      }
      this.statusesFilter = statusesFilter;
    },
    onRowSelected(data) {
      this.$router.push(`/leads/details/${data.ReferenceId}`);
    },
    showNavError(error) {
      this.$refs.nav.showError(error);
    },
    onSubmitWizardCustomerSuccess() {
      this.showSuccess("Created new customer successfully!");
      this.$refs.nav.refreshGrid();
    },
    onSubmitWizardLeadSuccess() {
      this.showSuccess("Created new lead successfully!");
      this.$refs.nav.refreshGrid();
      this.hideWizard();
      this.$refs.leadWizard.resetWizard();
    },
    showSuccess(message) {
      this.$refs.nav.showSuccess(message);
    },
    onSubmitWizardFail(error) {
      this.$refs.nav.showError(error);
    },
    onWizardCancel() {
      this.hideWizard();
    },
    hideWizard() {
      this.$refs.nav.hideWizard();
    },
    formatPhoneNumber(phone) {
      return Format.phoneNumber(phone);
    },
    onError(error) {
      this.$refs.nav.showError(error);
    },
    customizeDate(cellInfo) {
      return cellInfo.valueText;
    },
    customizeDateToTimezone(cellInfo) {
      return DateFormatter.formatDateToUsersTimezoneInMMDDYYYYFormat(cellInfo.value);
    },
    calculateAppointmentDateFilterExpression(filterValue, selectedFilterOperation, target) {
      return DateFormatter.calculateLocalDateFilterExpression("AppointmentDate", filterValue, selectedFilterOperation, target);
    },
  },
};
</script>
