<template>
  <v-container class="ma-4">
    <v-row>
      <confirmation-dialog
          :visible="dialog"
          :confirmationText="confirmationText"
          :submitLabel="action"
          :loading="creating || deleting"
          @onCancelClicked="onCancelConfirmation"
          @onSubmitClicked="onSubmitConfirmation"
      />
    </v-row>
    <v-row>
      <confirmation-dialog
          :visible="dialogValidation"
          :confirmationText="confirmationTextValidation"
          :submitLabel="action"
          :loading="creating || deleting"
          @onCancelClicked="onCancelConfirmation"
          @onSubmitClicked="onSubmitConfirmationValidation"
      />
    </v-row>

    <error-modal
        ref="errorModal"
        :error="error"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
    <v-row v-if="timeBlock" justify="end">
      <v-col cols="3">
        <v-btn
            class="delete-time-block-btn"
            medium
            dark
            color="error"
            title="Delete"
            :loading="deleting"
            @click="onDeleteClick"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <date-picker-with-type-in-text-field
            v-model="startDate"
            ref="pickerStartDate"
            label="* Start Date"
            validation-name="Start Date"
            id="new-timeblock-start-date"
            :allowedDates="allowedDates"
            :readonly="isEdit"
            :disabled="isEdit"
            :menuDisabled="isEdit"
            :optional="false"
        />
      </v-col>
      <v-col cols="4">
        <date-picker-with-type-in-text-field
            v-model="endDate"
            ref="pickerEndDate"
            label="* End Date"
            validation-name="End Date"
            id="new-timeblock-end-date"
            :allowedDates="allowedDates"
            :readonly="isEdit"
            :disabled="isEdit"
            :menuDisabled="isEdit"
            :optional="false"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <sales-rep-time-block-time-autocomplete
            label="* Time Block"
            :selectedValue="existingTimeSlot"
            :availableSlots="availableSlots"
            :disabled="isEdit"
            :placeholder="'Select time block'"
            @inputSelected="onSalesRepTimeBlockSelected"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <market-search-field
            ref="marketsField"
            :multiple-selection="false"
            :required="true"
            :getItemsOnComponentCreation="noMarketsProvided"
            :markets="markets"
            :market-ids="marketId"
            :disabled="isEdit"
            :filtered-by-branch="true"
            @input="onMarketSelect"
        />
      </v-col>
      <v-col cols="8">
        <sales-reps-search-field-by-market
            ref="salesRepsSelection"
            :disabled="false"
            :is-required="true"
            :multiple-selection="true"
            :marketId="marketId"
            :date="startDate"
            :item-value="'id'"
            :placeholder="'Search Sale Representatives'"
            :selectedSalesReps="selectedSalesReps"
            :clearable="false"
            :isRefreshByMarket="true"
            @showAllSelected="onAllSalesRepsSelectedOn"
            @showNotAllSelected="onAllSalesRepsSelectedOff"
            @optionsCount="onOptionsCount"
            @change="onSalesRepresentativesChange"
        />
      </v-col>
      <v-col cols="2">
        <toggle-button
            ref="selectAllSwitch"
            v-model="allSelected"
            :labels="{
              checked: 'All ' + '(' + this.optionsCount + ')',
              unchecked: 'All ' + '(' + this.optionsCount + ')'
            }"
            color="#82C7EB"
            :height="35"
            :width="80"
            :value="false"
            :sync="true"
            @change="onSelectAllSalesRep"
        />
      </v-col>

      <v-col cols="2">
        <v-text-field
            v-model="maxSlots"
            label="* Max Slots"
            placeholder="0"
            persistent-placeholder
            type="number"
            :value="salesRepresentatives.length"
            :disabled="false"
            :rules="[maxSlots => !!maxSlots || 'Max slots is required']"
            id="new-timeblock-max-slots"
            hint=""
            persistent-hint
        />
      </v-col>
      <v-col cols="12">
        <v-select
            v-model="defaultDays"
            label="* Days"
            placeholder="Days for the timeblock"
            persistent-placeholder
            v-show="!isEdit"
            :disabled="isEdit"
            :multiple="true"
            :items="daysInWeek"
            :rules="[() => !!this.defaultDays || 'Days are required']"
            id="new-timeblock-days"
            hint=""
            persistent-hint
            clearable
            attach
        >
          <template v-slot:prepend-item class="select-all">
            <select-all
                :items="daysInWeek"
                :selected="defaultDays ? defaultDays : []"
                @select="selectAllDays"
                @deselect="deselectAllDays"
            />
          </template>
        </v-select>
      </v-col>
      <v-col cols="6" v-if="isEdit">
        <v-switch
            color="primary"
            :label="allLabel"
            v-model="allTimeBlocks"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SalesRepsTimeBlock from "@/rest/salesRepsTimeBlock";
import MarketSearchField from "../../components/searchFields/MarketsAutocomplete.vue";
import SalesRepsSearchFieldByMarket from "../searchFields/SalesRepsSearchFieldByMarket";
import DatePickerWithTypeInTextField from "../common/form/DatePickerWithTypeInTextField";
import moment from "moment";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import ErrorModal from "@/components/navigation/ErrorModal.vue";
import {ToggleButton} from "vue-js-toggle-button";
import SelectAll from "@/components/searchFields/selectAllForDropdowns/SelectAllDropdownTemplate.vue";
import Constants from "@/assets/constants";
import Rules from "../../assets/rules";
import SalesRepTimeBlockTimeAutocomplete from "@/components/searchFields/SalesRepTimeBlockTimeAutocomplete.vue";

const deleteAction = "Delete";

const formDefaults = {
  allTimeBlocks: false,
  startDate: null,
  endDate: null,
  marketId: null,
  startTime: null,
  endTime: null,
  salesRepresentatives: [],
  selectedSalesReps: [],
  maxSlots: null,
  defaultDays: [],
  color: "#ffe082",
  error: null,
  isErrorModalOpen: false,
  errorMessage: null,
  allSelected: false,
  deleting: false,
  startTimeMenu: false,
  endTimeMenu: false,
  optionsCount: 0,
};

export default {
  components: {
    SalesRepTimeBlockTimeAutocomplete,
    ToggleButton,
    DatePickerWithTypeInTextField,
    MarketSearchField,
    SalesRepsSearchFieldByMarket,
    ErrorModal,
    ConfirmationDialog,
    SelectAll
  },

  props: {
    calendarDate: String,
    calendarMarket: {
      type: Number,
      required: false
    },
    timeBlock: Object,
    keepDatesOnHide: Boolean,
    creating: Boolean,
    markets: {
      type: Array,
      required: true
    },
    selectedWeekDay: String,
    availableSlots: {
      type: Array,
      required: true
    },
  },

  watch: {
    marketId(value) {
      if (!value) {
        this.$refs.salesRepsSelection.resetMarket();
      }
      this.marketId = value;
    },
    calendarMarket: {
      immediate: true,
      handler(value) {
        this.marketId = value;
      }
    },
    defaultDays(value) {
      if (value.length === 0) {
        this.defaultDays = null;
      }
    },
    calendarDate: {
      immediate: true,
      handler(value) {
        if (value) {
          this.startDate = value;
          this.endDate = value;
          this.$refs.salesRepsSelection.refresh();
          this.defaultDays = [];
          this.pushDefaultDate(value);
        }
      },
    },
    selectedWeekDay: {
      immediate: true,
      handler(value) {
        if (value) {
          this.deselectAllDays();
          let selected = this.daysInWeek.find(day => day.text === value);
          this.defaultDays.push(selected);
        }
      }
    },
    // TODO: rewrite to luxon and move out to a helper classs
    startDate() {
      const startMoment = moment(this.startDate);
      const endMoment = moment(this.endDate);
      if (startMoment.isAfter(endMoment)) {
        this.endDate = this.startDate;
      }
    },
    startTime() {
      const startMoment = moment(this.startTime, "HH:mm a");
      const endMoment = moment(this.endTime, "HH:mm a");
      if (startMoment.isAfter(endMoment)) {
        this.endTime = this.startTime;
      }
    },
    endTime() {
      const startMoment = moment(this.startTime, "HH:mm a");
      const endMoment = moment(this.endTime, "HH:mm a");
      if (startMoment.isAfter(endMoment)) {
        this.startTime = this.endTime;
      }
    },
    timeBlock: {
      immediate: true,
      handler(value) {
        if (value) {
          this.setExistingTimeBlockData(value);
        }
      },
    },
  },

  data() {
    return Object.assign(
        {
          messageErrorValidation: "",
          dialogValidation: false,
          dialog: false,
          action: null,
          rules: {
            required: Rules.required
          },
          daysInWeek: Constants.daysInWeek
        },
        formDefaults
    );
  },

  computed: {
    allLabel() {
      return this.allTimeBlocks
          ? "All time blocks from now on"
          : "Just this time block";
    },
    isEdit() {
      return this.timeBlock !== null;
    },
    confirmationText() {
      let msg = "Are you sure you want to " + this.action + " the timeblock";
      return this.allTimeBlocks ? msg + "s from now on ?" : msg + "?";
    },
    confirmationTextValidation() {
      let msg = "Some time blocks can't be " + this.action +
          "d because they are assigned to a lead. Are you sure you want to " + this.action + " only the others? ";
      return msg + this.messageErrorValidation;
    },
    noMarketsProvided() {
      return !this.markets;
    },
    existingTimeSlot() {
      return {
        startTime: this.startTime,
        endTime: this.endTime
      };
    }
  },

  methods: {
    onSalesRepTimeBlockSelected(value) {
      this.startTime = value.startTime;
      this.endTime = value.endTime;
      this.color = value.color;
    },
    showError(error) {
      if (error.message && !error.response) {
        this.error = error.message;
      } else {
        this.error = error.response ? error.response.data.message : error;
        if (typeof this.error === "object") {
          this.error = `Something unexpected has happened.
          Be assured we have recorded the error and are striving to create the best experience for you!`;
        }
      }
      this.isErrorModalOpen = true;
      this.action = null;
      this.dialog = false;
      throw new Error(error);
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    pushDefaultDate(value) {
      var date = new Date(value);
      this.defaultDays.push(date.toLocaleDateString("en-US", {weekday: 'long'}).toUpperCase());
    },
    allowedDates(date) {
      return moment(date).isSameOrAfter(moment(), "day");
    },
    onMarketSelect(value) {
      this.marketId = value;
      this.salesRepresentatives = [];
      if (!value) {
        this.optionsCount = 0;
      }
    },
    required(value, type) {
      return typeof value === type || "Required";
    },
    resetFormDefaults() {
      this.clearStartDateAndEndDate();
      this.setFormDefaults();
    },
    setFormDefaults() {
      Object.assign(this.$data, formDefaults);
    },
    buildPayload() {
      let payload = {};
      payload.startDate = this.startDate;
      payload.endDate = this.endDate;
      payload.marketId = this.marketId;
      payload.startTime = this.startTime;
      payload.endTime = this.endTime;
      payload.salesRepresentatives = this.getSelectedSaleRepsIds();
      payload.maxSlots = this.maxSlots;
      payload.defaultDays = this.getParserDefaultDays();
      payload.color = this.color;
      payload.timezoneOffset = new Date().getTimezoneOffset();
      return payload;
    },
    getParserDefaultDays() {
      return this.defaultDays.map(day => {
        if (day.text && day.value) {
          return day.value;
        } else return day;
      });
    },
    onDeleteClick() {
      this.openDialog(deleteAction);
    },
    onCancelConfirmation() {
      this.action = null;
      this.dialog = false;
      this.dialogValidation = false;
      this.messageErrorValidation = "";
    },
    async onSubmitConfirmation() {
      if (this.action === deleteAction) {
        this.deleting = true;
        if (this.allTimeBlocks) {
          let messageValidation = await this.validateIfDeleteTimeBlock();
          if (messageValidation !== "") {
            this.messageErrorValidation = messageValidation;
            this.dialogValidation = true;
            this.deleting = false;
          } else {
            await this.deleteTimeBlock();
          }
        } else {
          await this.deleteTimeBlock();
        }
      } else {
        this.$emit('updateStart');
        if (this.allTimeBlocks) {
          let messageValidation = await this.validateTimeBlockNotLeadAssigned();
          if (messageValidation !== "") {
            this.messageErrorValidation = messageValidation;
            this.dialogValidation = true;
          } else {
            await this.updateTimeBlock();
          }
        } else {
          await this.updateTimeBlock();
        }
        this.$emit('updateEnd');
      }
    },
    async onSubmitConfirmationValidation() {
      this.dialogValidation = false;
      if (this.action === deleteAction) {
        await this.deleteTimeBlock();
      } else {
        await this.updateTimeBlock();
      }
      this.messageErrorValidation = "";
    },
    openDialog(action) {
      this.action = action;
      this.dialog = true;
    },
    async updateTimeBlock() {
      try {
        const payload = this.buildPayload();
        this.$emit('updateStart');
        if (this.allTimeBlocks) {
          await SalesRepsTimeBlock.getRestApi().updateTimeBlocksBatch(this.startDate, payload);
        } else {
          await SalesRepsTimeBlock.getRestApi().updateTimeBlock(this.timeBlock.id, payload);
        }
        this.$emit('updateSuccess');
        this.$emit('updateEnd');
        this.action = null;
        this.dialog = false;
      } catch (error) {
        this.$emit('updateEnd');
        this.showError(error);
      }
    },
    async validateTimeBlockNotLeadAssigned() {
      try {
        const response = await SalesRepsTimeBlock.getRestApi().validateTimeBlockNotLeadAssigned(
            this.maxSlots,
            this.timeBlock.marketId,
            this.startDate,
            this.timeBlock.startTime,
            this.timeBlock.endTime,
            this.salesRepresentatives.map(sr => {
                  return sr?.id || sr;
                }
            ));
        return response.data;
      } catch (error) {
        this.$emit('updateEnd');
        this.showError(error);
      }
    },
    async validateIfDeleteTimeBlock() {
      try {
        const response = await SalesRepsTimeBlock.getRestApi().validateIfDeleteTimeBlock(
            this.timeBlock.marketId,
            this.startDate,
            this.timeBlock.startTime,
            this.timeBlock.endTime
        );
        return response.data;
      } catch (error) {
        this.$emit('updateEnd');
        this.deleting = false;
        this.showError(error);
      }
    },
    async deleteTimeBlock() {
      this.deleting = true;
      try {
        if (this.allTimeBlocks) {
          await SalesRepsTimeBlock.getRestApi().deleteTimeBlocksBatch(
              this.timeBlock.marketId,
              this.timeBlock.startTime,
              this.timeBlock.endTime,
              this.startDate
          );
        } else {
          await SalesRepsTimeBlock.getRestApi().deleteTimeBlock(this.timeBlock.id);
        }
        this.$emit('updateSuccess');
        this.action = null;
        this.dialog = false;
        this.deleting = false;
        this.defaultDays = [];
      } catch (error) {
        this.$emit('updateEnd');
        this.deleting = false;
        this.showError(error);
      }
    },
    onSelectAllSalesRep(event) {
      this.$refs.salesRepsSelection.selectAll(event);
    },
    onAllSalesRepsSelectedOn() {
      this.allSelected = true;
    },
    onAllSalesRepsSelectedOff() {
      this.allSelected = false;
    },
    onOptionsCount(count) {
      this.optionsCount = count;
    },
    selectAllDays() {
      this.defaultDays = this.daysInWeek.map(day => day.value);
    },
    deselectAllDays() {
      this.defaultDays = [];
    },
    clearStartDateAndEndDate() {
      this.$refs.pickerStartDate.clearSelected();
      this.$refs.pickerEndDate.clearSelected();
    },
    setExistingTimeBlockData(value) {
      this.startDate = value.slotDate;
      this.endDate = value.slotDate;
      this.startTime = value.startTime;
      this.endTime = value.endTime;
      this.maxSlots = value.maxSlots;
      this.color = value.color;
      this.defaultDays = [];
      this.pushDefaultDate(value.slotDate);
      this.selectedSalesReps = Array.from(value.salesRepresentativesId);
      this.allTimeBlocks = false;
      this.marketId = value.marketId;
    },
    getSelectedSaleRepsIds() {
      if (this.$refs.salesRepsSelection) {
        return this.$refs.salesRepsSelection.selected.map(selected => {
          return selected?.id ? selected.id : selected;
        });
      }
    },
    onSalesRepresentativesChange(selectedSalesRepresentatives) {
      this.salesRepresentatives = selectedSalesRepresentatives;
    }
  }
};

</script>
<style lang="scss" scoped>
.delete-time-block-btn {
  padding: 0 !important;
}

.buttons {
  width: 200px;
}
</style>
