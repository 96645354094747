<template>
  <v-autocomplete
      v-model="selected"
      :items="leadStatuses"
      :loading="loading"
      item-text="name"
      item-disabled="disabled"
      item-value="id"
      label="Lead Status"
      placeholder="Search lead statuses"
      persistent-placeholder
      :disabled="disabled"
      :rules="[() => !!selected || 'Lead status is required']"
      :validate-on-blur="true"
      :readonly="readonly"
      dense
      attach
      data-id="lead-statuses-autocomplete">

    <template v-slot:item="{ item }">
      <v-tooltip v-if="item.disabled" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-title v-bind="attrs" v-on="on" class="lead-status-list-item-disabled" disabled>
            {{ item.name }}
          </v-list-item-title>
        </template>

        <div v-if="item.name === 'Assigned'">
          <div v-if="isTimeBlockInPast">
            <span>The selected time block is in the past. Please choose another appointment date and time.</span>
          </div>
          <div v-else>
            <span>Please, wait until the Automatic Lead assignment process runs (6 PM EST)</span>
          </div>
        </div>

        <div v-else-if="item.name === 'New'">
          <span>The "New" status will automatically change if the automatic assignment process is not run for the selected date.</span>
        </div>

      </v-tooltip>

      <v-tooltip v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-title v-bind="attrs" v-on="on">{{ item.name }}</v-list-item-title>
        </template>
      </v-tooltip>
    </template>

  </v-autocomplete>
</template>

<script>
import LeadStatuses from "../../rest/leadStatuses";

export default {
  data() {
    return {
      selected: null,
      leadStatuses: [],
      loading: false
    };
  },

  props: {
    value: Number,
    sourceState: {
      type: Number | null,
      required: true
    },
    salesRepresentative: {
      type: Boolean,
      required: false
    },
    readonly: Boolean,
    disabled: Boolean,
    manualAssignmentAllowed: Boolean,
    isTimeBlockInPast: {
      type: Boolean,
      required: false,
      default: false
    },
    isNewStatusAllowed: {
      type: Boolean,
      required: false,
      default: true
    },
    filterOutLeadStatusIds: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  watch: {
    manualAssignmentAllowed: 'refreshLeadStatuses',
    value: {
      immediate: true,
      handler(val) {
        this.selected = val;
      }
    },
    sourceState: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getAllLeadStatusesBySourceState(value);
        }
      }
    },
    selected(value) {
      this.$emit("input", value);
    }
  },

  methods: {
    refreshLeadStatuses() {
      this.leadStatuses = this.leadStatuses.filter(leadStatus => !this.filterOutLeadStatusIds.includes(leadStatus.id))
          .map(leadStatus => {
            if (leadStatus.name === 'Assigned') {
              leadStatus.disabled = !this.manualAssignmentAllowed;
            } else if (leadStatus.name === 'New') {
              leadStatus.disabled = !this.isNewStatusAllowed;
            }
            return leadStatus;
          })
    },
    async getAllLeadStatusesBySourceState(source) {
      try {
        this.leadStatuses = this.salesRepresentative
            ? await LeadStatuses.getRestApi().getAllLeadStatusesFromStatusForSalesRepresentatives(source).then(r => r.data)
            : await LeadStatuses.getRestApi().getAllLeadStatusesFromStatus(source).then(r => r.data);

        this.refreshLeadStatuses();
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>

<style scoped>
.lead-status-list-item-disabled {
  cursor: pointer;
  pointer-events: auto;
}
</style>
