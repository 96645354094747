<template>
  <v-btn
      large
      dark
      color="primary"
      class="inline-grid-button"
      :loading="loading"
      :disabled="disabled"
      :width="width"
      @click="onButtonClick"
  >
    {{ label || "Add" }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    width: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {};
  },
  methods: {
    onButtonClick() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.inline-grid-button {
  min-width: 220px;
  width: 220px;
  border-radius: 2px;
}
</style>
