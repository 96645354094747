import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * Promo code.
 */
export class PromoCode {
  id: number = 0;
  key: string = "";
  description: string = "";
  active: string = "";
  markets: String = "";
  marketNames: string = "";
  marketIds: Array<number> = [];

  constructor() {}
}

/**
 * REST interface provided for promo codes.
 */
export interface PromoCodesRestApi {
  createPromoCode(item: PromoCode): AxiosPromise<PromoCode>;
  updatePromoCode(id: number, updated: PromoCode): AxiosPromise<PromoCode>;
  getAllActivePromoCodes(): AxiosPromise<PromoCode[]>;
  getAllActiveForMarketPromoCodes(id: number): AxiosPromise<PromoCode[]>;
  getPromoCodeById(id: number): AxiosPromise<PromoCode>;
  getPromoCodeLogs(id: number): AxiosPromise<ActivityLog[]>;

  /** @deprecated */
  getAllPromoCodes(): AxiosPromise<PromoCode>; // Unused. Reason unknown
  /** @deprecated */
  getPromoCodes(): AxiosPromise<PromoCode[]>;   // Unused. Reason unknown
  /** @deprecated */
  getActivePromoCodes(): AxiosPromise<PromoCode[]>; //  Unused. Reason unknown
}

/**
 * REST implementation provided for promo codes.
 */
class Implementation implements PromoCodesRestApi {
  createPromoCode(item: PromoCode): AxiosPromise<PromoCode> {
    return Axios.axiosInstance().post<PromoCode>("/promos", item);
  }
  updatePromoCode(id: number, updated: PromoCode): AxiosPromise<PromoCode> {
    return Axios.axiosInstance().put<PromoCode>(`/promos/${id}`, updated);
  }
  getAllActivePromoCodes(): AxiosPromise<PromoCode[]> {
    return Axios.axiosInstance().get<PromoCode[]>("/promos/active/all");
  }
  getAllActiveForMarketPromoCodes(id: number): AxiosPromise<PromoCode[]> {
    return Axios.axiosInstance().get<PromoCode[]>(`/promos/market/${id}`);
  }
  getPromoCodeById(id: number): AxiosPromise<PromoCode> {
    return Axios.axiosInstance().get<PromoCode>(`/promos/${id}`);
  }
  getPromoCodeLogs(id: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/promos/${id}/logs`);
  }

  // Unused. Reason unknown
  /** @deprecated */
  getPromoCodes(): AxiosPromise<PromoCode[]> {
    return Axios.axiosInstance().get<PromoCode[]>("/promos");
  }
  // Unused. Reason unknown
  /** @deprecated */
  getActivePromoCodes(): AxiosPromise<PromoCode[]> {
    return Axios.axiosInstance().get<PromoCode[]>("/promos/active");
  }
  /** @deprecated */
// Unused. Reason unknown
  getAllPromoCodes(): AxiosPromise<PromoCode> {
    return Axios.axiosInstance().get<PromoCode>("/promos/search/all");
  }
}

const PromoCodes = {
  getRestApi(): PromoCodesRestApi {
    return new Implementation();
  },
};

export default PromoCodes;
