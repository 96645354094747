<template>
  <inline-grid
      ref="grid"
      :data-source="dataSource"
      :show-borders="false"
  >
    <dx-paging :page-size="10"/>
    <dx-pager
        :show-page-size-selector="true"
        :allowed-page-sizes="[10, 25, 50]"
        :show-info="true"
    />
    <dx-column
        data-field="CreatedAt"
        width="20%"
        data-type="datetime"
        caption="Date"
        sortOrder="desc"
        format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
        data-field="ChangeDescription"
        width="40%"
        caption="Description"
    />
    <dx-column
        data-field="SlotDate"
        width="15%"
        caption="Slot Date"
        data-type="date"
        format="MM/dd/yyyy"
    />
    <dx-column
        data-field="UserName"
        width="25%"
        caption="Created User"
    />
  </inline-grid>

</template>

<script>
import InlineGrid from "../../../components/common/grid/InlineGrid.vue";
import ODataContext from "@/rest/odataContext";
import {DxColumn, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    InlineGrid,
    DxColumn,
    DxPager,
    DxPaging,
  },

  props: {
    branchId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dataSource: {
        store: ODataContext['InstallerCrewSlotActivityLogs'],
        select: [
          "Id",
          "BranchName",
          "ChangeDescription",
          "SlotDate",
          "CreatedAt",
          "UserName"
        ],
        filter: ["BranchId", "=", this.branchId],
        sort: [{selector: "CreatedAt", desc: true}]
      },
    };
  },

  mounted() {
    this.refreshGrid();
  },

  methods: {
    emitError(error) {
      this.$emit('showError', error);
    },
    refreshGrid() {
      this.$refs.grid.getGridInstance().filter(["BranchId", "=", this.branchId]);
      this.$refs.grid.refresh();
    }
  }
};
</script>
