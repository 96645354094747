<template>
  <div class="separator pa-2">
    <table class="line-item-headers caption">
      <tr>
        <td width="18%">Reference Id</td>
        <td width="29%">Comment</td>
        <td width="27%">Installation</td>
        <td width="10%">Price of Labor</td>
        <td width="10%">Installer Payable</td>
        <td width="10%" v-if="showScgPayableColumn">SCG Payable</td>
        <td width="11%">Price of Materials</td>
        <td width="8%">Customer Sat</td>
        <td width="30px"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    showScgPayableColumn: {
      type: Boolean,
      required: false,
      default: false
    },
    deep: true
  }

};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
</style>
