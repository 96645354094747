<template>
  <v-autocomplete
      :eager="true"
      required
      attach
      dense
      hide-no-data
      hide-selected
      v-model="selected"
      item-text="fullName"
      item-value="id"
      label="* Search Users"
      id="users-search-field"
      :items="items"
      :loading="loading"
      :menu-props="menuProps"
      :placeholder="'Start typing to search'"
      persistent-placeholder
      :rules="[rules.required]"
  />
</template>

<script>
import Users from "../../rest/users";
import Rules from "../../assets/rules";

export default {
  data() {
    return {
      rules: {
        required: Rules.required
      },
      menuProps: {
        maxHeight: 225
      },
      selected: null,
      items: [],
      loading: false
    };
  },

  props: {
    value: {
      type: Number | null,
      required: true
    },
    includeInactive: {
      type: Boolean,
      required: true
    }
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit('inputSelected', value);
    }
  },

  created() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      try {
        const response = this.includeInactive
            ? await Users.getRestApi().getAllUsers()
            : await Users.getRestApi().getAllActiveUsers();
        this.items = this.disableInactiveUsers(response.data);
      } catch (error) {
        throw error;
      }
    },
    disableInactiveUsers(users) {
      return users.map(i => {
        if (!i.active) {
          i.disabled = true;
          return i;
        } else return i;
      });
    }
  }
};
</script>
