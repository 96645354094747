<template>
  <v-dialog v-model="wizardVisible" persistent :width="550">
    <v-app-bar dense flat dark color="primary">
      <v-app-bar-title>Cancel Save</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <v-stepper-header :step="step">
        <v-stepper-step :complete="step > 1" step="1">
          Enter Issue
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Enter Discount
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Summary
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pl-2 pr-2 pb-2">
            <v-textarea
                ref="customerNote"
                v-model="note"
                class="pt-6"
                :auto-grow="true"
                label="* Describe Customer Concerns"
                placeholder=" "
                persistent-placeholder
                :rules="[rules.required, rules.noteSize, rules.validText]"
                counter="2048"
            />
            <v-row>
              <v-col align-content-end>
                <button-container-with-cancel
                    backName="Cancel"
                    nextName="Next"
                    :hideCancel="true"
                    @getPreviousStep="resetWizard"
                    @getNextStep="goToStepTwo"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="pl-2 pr-2 pb-2 pt-2">
            <form-body
                ref="enterDiscountFrom"
                :jobReferenceId="jobReferenceId"
                :contractPrice="contractPrice"
                :newContractPrice="newContractPrice"
                @updateContractPrice="onUpdateContractPrice"
            />
            <button-container-with-cancel
                backName="Back"
                nextName="Next"
                :hideCancel="true"
                @getPreviousStep="goToStep(1)"
                @getNextStep="goToStepThree"
            />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="pl-2 pr-2 pb-2 pt-2">
            <form-body
                ref="summaryFormBody"
                :ifSummary="true"
                :jobReferenceId="jobReferenceId"
                :contractPrice="contractPrice"
            />
            <button-container-with-cancel
                backName="Back"
                nextName="Submit"
                :hideCancel="true"
                :next-loading="loading"
                @cancel="resetWizard"
                @getPreviousStep="goToStep(2)"
                @getNextStep="onSubmitClicked"
            />
          </v-card>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import FormBody from "../../../components/jobs/discountJobWizard/FormBody.vue";
import Rules from "../../../assets/rules";
import ButtonContainerWithCancel from "../../common/wizard/ButtonContainerWithCancel.vue";
import ContractPrice from "../../../rest/contractPrice";

export default {
  components: {
    ButtonContainerWithCancel,
    FormBody
  },

  props: {
    jobReferenceId: String,
    contractPrice: Object,
    wsSessionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      newContractPrice: null,
      wizardVisible: false,
      error: null,
      step: 1,
      note: null,
      rules: {
        required: Rules.required,
        noteSize: value => Rules.noteSize(value, 2048),
        validText: value => !Rules.isWhitespaceOnly(value) && Rules.textLengthWithoutWhitespaces(value)
      },
      loading: false
    }
  },

  methods: {
    openDialog() {
      this.resetWizard();
      this.wizardVisible = true;
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    showError(error) {
      this.$emit('emitError', error);
    },
    showSuccess(message) {
      this.$emit('showSuccess', message);
    },
    resetWizard() {
      this.step = 1;
      this.closeDialog();
      this.newContractPrice = null;
      this.note = null;
      this.loading = false;
      if (this.$refs.enterDiscountFrom) {
        this.$refs.enterDiscountFrom.reset();
      }
    },
    onUpdateContractPrice(price) {
      this.newContractPrice = price;
    },
    goToStepTwo() {
      let valid = this.$refs.customerNote.validate();
      if (valid) {
        this.goToStep(2);
      }
    },
    goToStep(step) {
      this.step = step;
    },
    goToStepThree() {
      let valid = this.$refs.enterDiscountFrom.validate();
      if (valid) {
        const payload = this.$refs.enterDiscountFrom.getPayload();
        payload.note = this.note;
        this.$refs.summaryFormBody.loadForm(payload);
        this.goToStep(3);
      }
    },
    async onSubmitClicked() {
      const valid = this.$refs.summaryFormBody.validate();
      if (valid) {
        let payload = this.buildPayload();
        await this.performCancelSaveRequest(payload);
      }
    },
    buildPayload() {
      const payload = this.$refs.summaryFormBody.getPayload();
      return {
        amount: payload.discountAmount * -1,
        note: payload.note
      };
    },
    async performCancelSaveRequest(payload) {
      try {
        this.loading = true;
        const response = await ContractPrice.getRestApi().updateContractPrice(this.jobReferenceId, payload, this.wsSessionId);
        if (response) {
          this.$emit('submitDiscount');
          this.resetWizard();
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
