<template>
  <v-container grid-list-lg fluid>
    <v-row class="pb-0">
      <v-col md="3">
        <v-text-field
            v-model="lead.appointmentDate"
            label="Appointment Date"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col md="3">
        <v-text-field
            v-model="lead.appointmentTime"
            label="Appointment Time"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-spacer/>
      <v-col md="5">
        <v-text-field
            v-model="lead.marketName"
            label="Market"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3">
        <v-text-field
            v-model="lead.leadStatusName"
            label="Lead Status"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-col md="3">
        <v-text-field
            v-model="lead.salesRepName"
            label="Sales Representative"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-spacer/>
      <v-col md="5">
        <v-text-field
            v-model="lead.promoCode"
            label="Promo Code"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <v-text-field
            v-model="lead.address"
            label="Address"
            readonly
            id="lead-details-read-only-address"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-spacer/>
      <v-col md="5">
        <v-text-field
            v-model="lead.leadRooms"
            label="Rooms"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="2">
        <v-text-field
            v-model="lead.city"
            label="City"
            readonly
            id="lead-details-read-only-city"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            v-model="lead.state"
            label="State"
            readonly
            id="lead-details-read-only-state"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-col md="2">
        <v-text-field
            v-model="lead.zipCode"
            label="Zip Code"
            readonly
            id="lead-details-read-only-zip-code"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-spacer/>
      <v-col md="5">
        <v-text-field
            v-model="lead.productTypes"
            label="Product Types"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    lead: Object
  },

  data() {
    return {};
  },

  methods: {}
};
</script>
