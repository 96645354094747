<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Product Subtypes
          </v-expansion-panel-header>
          <v-expansion-panel-content key="activityLog">
            <product-subtypes-grid
                ref="productSubtypesGrids"
                :productType="productType"
                @needsRefresh="needsRefresh"
                @onSubmitFailed="onSubmitFailed"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import ProductSubtypesGrid from "@/views/productSubtypes/ProductSubtypesGrid";
import Constants from "@/assets/constants";

export default {
  components: {
    ProductSubtypesGrid
  },

  props: {
    productType: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    needsRefresh() {
      this.$emit("needsRefreshPage");
    },
    onSubmitFailed(error) {
      this.$emit('onSubmitSubtypeFailed', error);
    },
  }
};
</script>
