<template>
  <v-form ref="returnFromJobForm" class="mt-2 ml-1 mb-6 pt-2 pb-6">
    <v-row no-gutters class="quantity-row">
      <v-col md="4">
        <line-item-description
            :lineItem="item"
            :displayReferenceId="true"
        />
      </v-col>

      <v-col md="4" class="pt-4 mr-2 ml-2">
        <general-purpose-dropdown
            ref="returnTypeDropdown"
            :select-options="dropdownOptions"
            :value="selectedOption"
            :placeholder="'Type of return'"
            :required="true"
            label="Select"
            item-text="text"
            item-value="value"
            @onOptionChange="handleReturnTypeChange"
        />

      </v-col>
      <v-col md="2" class="pt-4 mr-2 ml-2">
        <v-text-field
            ref="quantityField"
            class="quantity-field"
            persistent-placeholder
            label="Quantity"
            v-model="returnQuantity"
            :readonly="readonly"
            type="number"
            min="0"
            :rules="[rules.required, rules.validQuantity,
            rules.validReturnQuantity(returnQuantity, this.selectedOptionValue, item.jobLineItem.quantity)]"
            @change="adjustQuantity"
        />
      </v-col>
      <v-col cols="1" class="stepper-buttons" v-if="showAdjustQuantityButtons">
        <v-btn
            small
            class="stepper-btn"
            @click="changeQuantity(true)"
        >
          +
        </v-btn>
        <v-btn
            small
            class="stepper-btn"
            @click="changeQuantity(false)"
        >
          -
        </v-btn>
      </v-col>
      <div class="error-message-container mt-6" v-if="error">
        <p class="text">{{ error }}</p>
      </div>
    </v-row>
  </v-form>
</template>

<script>
import LineItemDescription from "../../jobs/productLineItems/LineItemDescription";
import Rules from "../../../assets/rules";
import GeneralPurposeDropdown from "../../common/form/GeneralPurposeDropdown.vue";
import Constants from "../../../assets/constants";
import BoxQuantity from "../../../assets/boxQuantity";

export default {
  components: {
    LineItemDescription,
    GeneralPurposeDropdown
  },

  data() {
    return {
      dropdownOptions: Constants.returnFromJobDropdownOptions,
      rules: {
        required: Rules.required,
        validQuantity: Rules.validDecimalQuantity,
        validReturnQuantity: (value, selectedOption, jboLineItemQuantity) => Rules.validReturnQuantity(value, selectedOption, jboLineItemQuantity),
      },
      selectedItem: null,
      selectedOption: null,
      selectedOptionValue: null,
      returnQuantity: 0,
      readonly: false,
      showAdjustQuantityButtons: false,
      error: null
    };
  },

  created() {
    this.setQuantityFromReadonlyIfSelectedOptionIsNotChosen();
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  watch: {
    selectedOption: 'checkOptions',
    returnQuantity: 'checkOptions'
  },

  methods: {
    changeQuantity(isIncrease) {
      if (this.item.productCatalogItem.boxQuantity) {
        this.returnQuantity = isIncrease
            ? Number(this.returnQuantity) + Number(this.item.productCatalogItem.boxQuantity)
            : Number(this.returnQuantity) - Number(this.item.productCatalogItem.boxQuantity);
        // get box quantity decimals amount
        const boxQuantityFractionDigits = BoxQuantity.getFractionDigitsAmount(this.item.productCatalogItem.boxQuantity);
        this.returnQuantity = Number(this.returnQuantity).toFixed(boxQuantityFractionDigits);
      } else {
        this.returnQuantity = isIncrease
            ? (Number(this.returnQuantity) + Number(1))
            : (Number(this.returnQuantity) - Number(1));
      }
      this.adjustQuantity(this.returnQuantity);
    },
    handleReturnTypeChange(selectedReturnType) {
      if (this.isFullReturnOrFullReservation(selectedReturnType.value)) {
        this.readonly = true;
        this.showAdjustQuantityButtons = false;
        this.returnQuantity = this.item.jobLineItem.quantity;
      } else if (this.isPartialReturnOrPartialReservation(selectedReturnType.value)) {
        this.readonly = false;
        this.showAdjustQuantityButtons = true;
        this.returnQuantity = this.item.productCatalogItem.boxQuantity || 0;
      }
      /*** TODO: check why it's no visible 'this.selectedOption.value' instead of 'selectedOptionValue' in
       rules.validReturnQuantity(returnQuantity, this.selectedOptionValue, item.jobLineItem.quantity)
       and get rid of 'selectedOptionValue' option
       **/
      this.selectedOptionValue = selectedReturnType.value;
      this.selectedOption = selectedReturnType;
    },
    clearReturnFromJobForm() {
      this.selectedItem = null;
      this.selectedOption = null;
      this.selectedOptionValue = null;
      this.returnQuantity = 0;
      this.readonly = false;
      this.showAdjustQuantityButtons = false;
    },
    getFormData() {
      return {
        internalSku: this.item.productCatalogItem.internalSku,
        returnType: this.selectedOptionValue,
        quantity: this.returnQuantity,
        referenceId: this.item.jobLineItem.referenceId
      }
    },
    validateReturnFromJobForm() {
      return this.$refs.returnFromJobForm.validate() && this.error === null;
    },
    setReturnQuantityAdjustedToBoxQuantity(returnQuantity, boxQuantity) {
      this.returnQuantity = BoxQuantity.roundValueToBoxQuantity(returnQuantity, boxQuantity)
          / 1; // remove trailing zeroes
    },
    setDefaultQuantity() {
      this.returnQuantity = 0;
    },
    isPartialReturnOrPartialReservation(selectedOptionValue) {
      return selectedOptionValue === 'PARTIAL_RETURN' || selectedOptionValue === 'PARTIAL_RESERVATION';
    },
    isFullReturnOrFullReservation(selectedOptionValue) {
      return selectedOptionValue === 'FULL_RETURN' || selectedOptionValue === 'FULL_RESERVATION';
    },
    adjustFormValues(value) {
      if (Number(value) < 0) {
        this.setDefaultQuantity();
        return;
      }

      if (this.isPartialReturnOrPartialReservation(this.selectedOptionValue) && Number(value) >= this.item.jobLineItem.quantity) {
        return;
      }

      let boxQuantity = this.item.productCatalogItem.boxQuantity;
      if (boxQuantity) {
        this.setReturnQuantityAdjustedToBoxQuantity(Number(value), boxQuantity);
      }
    },
    setQuantityFromReadonlyIfSelectedOptionIsNotChosen() {
      if (!this.selectedOption) {
        this.readonly = true;
      }
    },
    adjustQuantity(value) {
      this.adjustFormValues(Number(value));
    },
    checkOptions() {
      if (this.selectedOptionValue === 'PARTIAL_RETURN' && this.returnQuantity >= this.item.jobLineItem.quantity) {
        this.error = 'Please, use the "Full Product Line Item Return" option if you need to return all the product quantity.'
      } else {
        this.error = null;
      }
    }
  },
}
</script>

<style scoped>
.item-row > div {
  flex: 1;
  margin-right: 10px;
}

.item-row > div:last-child {
  margin-right: 0;
}

.error-message-container {
  display: flex;
  width: 100%;
  justify-content: center;
  color: red;
}
</style>
