<template>
  <modal-dialog
    :visible="visible"
    title="Appointment"
    width="40%"
    cancelLabel="Close"
    submitLabel="Print"
    :submitEnabled="true"
    @cancelClicked="cancelClicked"
    @submitClicked="print"
  >
    <sales-representative-appointment
      ref="appointment"
      :appointment="appointment"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import SalesRepresentativeAppointment from "@/components/salesRepresentatives/SalesRepresentativeAppointment";

export default {
  components: {
    ModalDialog,
    SalesRepresentativeAppointment
  },

  props: ["visible"],

  data() {
    return {
      appointment: null
    };
  },

  watch: {
    appointment: function(newVal) {
      this.$refs.appointment.loadFrom(newVal);
    }
  },

  methods: {
    setAppointment: function(appointment) {
      this.$store.commit("setPrintData", appointment);
      this.$data.appointment = appointment;
    },
    cancelClicked: function() {
      this.$store.commit("setPrintData", null);
      this.$emit("cancelClicked");
    },
    print: function() {
      window.open("/sales/home/print", "_blank");
    }
  }
};
</script>
