import Axios from ".././axiosInstance";
import {AxiosPromise} from "axios";

export interface UpdateInstallerCrewTimeInSecondaryBranch {
    id: number;
    installerCrewId: number;
    fromDate: string;
    toDate: string;
    comment: string;
}


export interface InstallerCrewTimeInSecondaryBranchDTO extends UpdateInstallerCrewTimeInSecondaryBranch{
    installerCrewName: string;
    toBranchName: string;
}


export interface InstallerCrewSecondaryBranchTimeApi {
    createTimeInSecondaryBranch(payload: UpdateInstallerCrewTimeInSecondaryBranch): AxiosPromise<InstallerCrewTimeInSecondaryBranchDTO>;

    updateTimeInSecondaryBranch(payload: UpdateInstallerCrewTimeInSecondaryBranch): AxiosPromise<InstallerCrewTimeInSecondaryBranchDTO>;

    deleteTimeInSecondaryBranch(id: number): AxiosPromise<void>;

    getTimesInSecondaryBranch(installerRefId: String): AxiosPromise<InstallerCrewTimeInSecondaryBranchDTO[]>;
}

class Implementation implements InstallerCrewSecondaryBranchTimeApi {

    createTimeInSecondaryBranch(payload: UpdateInstallerCrewTimeInSecondaryBranch): AxiosPromise<InstallerCrewTimeInSecondaryBranchDTO> {
        return Axios.axiosInstance().post<InstallerCrewTimeInSecondaryBranchDTO>(`/installer-crew/secondary-branch/time`, payload);
    }

    updateTimeInSecondaryBranch(payload: UpdateInstallerCrewTimeInSecondaryBranch): AxiosPromise<InstallerCrewTimeInSecondaryBranchDTO> {
        return Axios.axiosInstance().put<InstallerCrewTimeInSecondaryBranchDTO>(`/installer-crew/secondary-branch/time/${payload.id}`, payload);
    }

    deleteTimeInSecondaryBranch(id: number): AxiosPromise<void> {
        return Axios.axiosInstance().delete<void>(`/installer-crew/secondary-branch/time/${id}`);
    }

    getTimesInSecondaryBranch(installerRefId: String): AxiosPromise<InstallerCrewTimeInSecondaryBranchDTO[]> {
        return Axios.axiosInstance().get<InstallerCrewTimeInSecondaryBranchDTO[]>(`/installer-crew/secondary-branch/time/installer/${installerRefId}/all`);
    }
}

const InstallerCrewSecondaryBranchTimes = {
    getRestApi(): InstallerCrewSecondaryBranchTimeApi {
        return new Implementation();
    }
};

export default InstallerCrewSecondaryBranchTimes;
