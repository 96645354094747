<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0">
    <v-row>
      <v-col md="2">
        <v-text-field
            label="Reference Id"
            :readonly="true"
            v-model="referenceId"
            data-id="lead-details-form-ref-id"
        />
      </v-col>
      <v-col/>
      <v-col md="2">
        <v-text-field
            label="Lead Status"
            v-model="leadStatusName"
            :readonly="true"
            data-id="lead-details-form-status"
        />
      </v-col>
    </v-row>
    <v-divider class="detailsDivider"/>
  </v-container>
</template>

<script>
export default {
  props: {
    leadStatusName: {
      type: String,
      required: true
    },
    referenceId: {
      type: String
    }
  }
};
</script>
