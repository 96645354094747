import {isGenerated} from "@/assets/referenceIds";
import DateFormatter from "../dateFormatter";

const LineItemFormatter = {

    formatProductLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                status: item.jobLineItem.status,
                comment: item.jobLineItem.comment,
                quantity: item.jobLineItem.quantity,
                price: item.jobLineItem.price.value,
                eta: item.jobLineItem.eta ? DateFormatter.formatDateInYYYYMMDDFormat(item.jobLineItem.eta) : null,
                productCatalogItemId: item.productCatalogItem ? item.productCatalogItem.id : null,
            };
        });
    },

    formatLaborLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                comment: item.jobLineItem.comment ? item.jobLineItem.comment : '',
                quantity: item.jobLineItem.quantity,
                price: item.jobLineItem.price ? Number(item.jobLineItem.price) : item.jobLineItem.price.value,
                installerId: item.jobLineItem.installerId,
                installerCrewId: item.jobLineItem.installerCrewId,
                installerSlotId: item.installation ? item.installation.installerSlotId : null,
                etc: item.installation ? item.installation.etc : null,
                stop: this.getStop(item),
                capabilityTypeId: item.jobLineItem.installationCapabilityId ? item.jobLineItem.installationCapabilityId : null,
                installationStatus: item.installation ? item.installation.status : null,
                installationDate: item.installation ? item.installation.installationDate : null
            };
        });
    },

    getStop(item) {
        if (item.installation && item.installation.stop) {
            return item.installation.stop;
        } else if (item.jobLineItem.stop) {
            return item.jobLineItem.stop;
        } else return null;
    },

    formatChargebackLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                comment: "",
                quantity: 1,
                price: 0.0,
                reason: item.jobLineItem.reason,
                materialCost: item.jobLineItem.materialCost,
                laborCost: item.jobLineItem.laborCost,
                customerPayment: item.jobLineItem.customerPayment,
                thirdPartyVendorId: item.jobLineItem.thirdPartyVendorId,
                thirdPartyVendor: item.jobLineItem.thirdPartyVendor,
                thirdPartyPayment: item.jobLineItem.thirdPartyPayment,
                installerId: item.jobLineItem.installerId,
                installerChargeAmount: item.jobLineItem.installerChargeAmount,
                vendorId: item.jobLineItem.vendorId,
                vendorChargeAmount: item.jobLineItem.vendorChargeAmount,
                fiftyFloorAmount: item.jobLineItem.fiftyFloorAmount,
                overbill: item.jobLineItem.overbill
            }
        });
    },

    formatInstallationIssueLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                comment: item.jobLineItem.comment,
                quantity: 1,
                price: item.jobLineItem.price,
                installationId: item.jobLineItem.installationId,
                installerReimbursement: item.jobLineItem.installerReimbursement,
                customerResponsibility: item.jobLineItem.customerSatisfaction,
                customerResponsibilityAmount: item.jobLineItem.customerResponsibilityAmount || 0,
                materialsAmount: item.jobLineItem.materialsAmount || 0,
                laborType: "original",
                installerId: item.jobLineItem.installerId,
                laborAmount: item.jobLineItem.laborAmount || 0,
                customerSatisfactionAmount: item.jobLineItem.customerSatisfactionAmount,
                installerPayable: item.jobLineItem.installerPayable || 0,
                scgPayable: item.jobLineItem.scgPayable || 0
            }
        });
    },

    formatRefundLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                comment: item.jobLineItem.comment ? item.jobLineItem.comment : '',
                quantity: 1,
                price: item.jobLineItem.price.value,
                refundAmount: item.jobLineItem.refundAmount,
                balanceTakenAmount: item.jobLineItem.balanceTakenAmount,
            }
        });
    },

    formatMiscLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                comment: item.jobLineItem.comment ? item.jobLineItem.comment : '',
                quantity: 1,
                price: item.jobLineItem.price.value,
                itemTypeId: item.jobLineItem.itemTypeId,
            }
        });
    },

    formatPaymentLineItemsForSave(items) {
        return items.map(item => {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                quantity: item.jobLineItem.quantity,
                price: item.jobLineItem.price.value,
                customerPaidAmount: item.jobLineItem.customerPaidAmount.value,
                paymentTypeId: item.jobLineItem.paymentTypeId,
                planCodeId: item.jobLineItem.planCodeId,
                paymentId: item.jobLineItem.paymentId,
                comment: item.jobLineItem.comment,
            }
        });
    },

    formatContractLineItemsForSave(item) {
        return {
            referenceId: this.getReferenceId(item),
            type: item.type,
            quantity: item.quantity,
            price: item.price.value,
            contractPriceWithoutWarranty: item.contractPriceWithoutWarranty.value,
        };
    },

    formatWarrantyLineItemForSave(item) {
        if (item) {
            return {
                referenceId: this.getReferenceId(item.jobLineItem),
                type: item.jobLineItem.type,
                comment: item.jobLineItem.comment ? item.jobLineItem.comment : '',
                quantity: 1,
                price: item.jobLineItem.price.value,
                salesRepWarrantyCost: item.jobLineItem.salesRepWarrantyCost.value,
                fiftyFloorWarrantyCost: item.jobLineItem.fiftyFloorWarrantyCost.value
            };
        }
    },

    getReferenceId: function (item) {
        return !isGenerated(item.referenceId) ? item.referenceId : undefined;
    }
};
export default LineItemFormatter;
