import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";
import qs from "qs";

/**
 * Branch.
 */
export class Branch {
    id: number = 0;
    referenceId: string = "";
    name: string = "";
    active: boolean = false;
    address: string = "";
    city: string = "";
    state: string = "";
    zipCode: string = "";
    phone: string = "";
    fax: string = "";
    marketIds: Array<object> = [];

    constructor() {
    }
}

/**
 * Branch integration.
 */
export interface BranchIntegration {
    name: string;
    description: string;
    type: string;
}

/**
 * REST interface provided for branches.
 */
export interface BranchesRestApi {
    createBranch(item: Branch): AxiosPromise<Branch>;

    updateBranch(refId: string, updated: Branch): AxiosPromise<Branch>;

    getBranch(refId: string): AxiosPromise<Branch>;

    getBranchLogs(refId: string): AxiosPromise<ActivityLog[]>;

    getAllUserBranches(): AxiosPromise<Branch[]>;

    getAllActiveBranches(): AxiosPromise<Branch[]>;

    getAllBranchIntegrations(): AxiosPromise<BranchIntegration[]>;

    getBranchIntegrations(refId: string): AxiosPromise<BranchIntegration[]>;

    deleteBranchIntegration(refId: string, integrationId: number): AxiosPromise<BranchIntegration>;

    addBranchIntegration(refId: string, integrationId: number): AxiosPromise<BranchIntegration>;

    isSCGBranch(branchId: number): AxiosPromise<Boolean>;

    getBranchesByMarkets(marketIds: number[]): AxiosPromise<Branch[]>;
}

/**
 * REST implementation provided for branches.
 */
class Implementation implements BranchesRestApi {
    getAllUserBranches(): AxiosPromise<Branch[]> {
        return Axios.axiosInstance().get<Branch[]>("/branches/search/all");
    }

    getAllActiveBranches(): AxiosPromise<Branch[]> {
        return Axios.axiosInstance().get<Branch[]>("/branches/all");
    }

    createBranch(item: Branch): AxiosPromise<Branch> {
        return Axios.axiosInstance().post<Branch>("/branches", item);
    }

    updateBranch(refId: string, updated: Branch): AxiosPromise<Branch> {
        return Axios.axiosInstance().put<Branch>(`/branches/${refId}`, updated);
    }

    getBranch(refId: string): AxiosPromise<Branch> {
        return Axios.axiosInstance().get<Branch>(`/branches/${refId}`);
    }

    getBranchLogs(refId: string): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>(`/branches/${refId}/logs`);
    }

    getAllBranchIntegrations(): AxiosPromise<BranchIntegration[]> {
        return Axios.axiosInstance().get<BranchIntegration[]>(`/branches/integrations`);
    }

    getBranchIntegrations(refId: string): AxiosPromise<BranchIntegration[]> {
        return Axios.axiosInstance().get<BranchIntegration[]>(`/branches/${refId}/integrations`);
    }

    deleteBranchIntegration(refId: string, integrationId: number): AxiosPromise<BranchIntegration> {
        return Axios.axiosInstance().delete(`/branches/${refId}/integrations/${integrationId}`);
    }

    addBranchIntegration(refId: string, integrationId: number): AxiosPromise<BranchIntegration> {
        return Axios.axiosInstance().put(`/branches/${refId}/integrations/${integrationId}`);
    }

    isSCGBranch(branchId: number): AxiosPromise<Boolean> {
        return Axios.axiosInstance().get(`/branches/is-scg-branch/${branchId}`);
    }

    getBranchesByMarkets(marketIds: number[]): AxiosPromise<Branch[]> {
        return Axios.axiosInstance().get<Branch[]>(`/branches/search/by-markets`, {
            params: {
                marketIds: marketIds
            },
            paramsSerializer: function paramsSerializer(params) {
                return qs.stringify({
                    ...params,
                    marketIds: params.marketIds.join(',')
                }, {arrayFormat: 'comma'});
            }
        });
    }
}

const Branches = {
    getRestApi(): BranchesRestApi {
        return new Implementation();
    }
};

export default Branches;
