<template>
  <v-card flat class="mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td class="reference-id-td labor-panel-reference-id-width top-aligned">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <!-- Install type -->
        <td class="labor-panel-install-type-width top-aligned">
          <installation-capabilities-search-field
              :disabled="!canEdit || !lineItem.jobLineItem.newLineItem || isInstallationComplete"
              :required="false"
              :multiple="false"
              :value="installationCapabilityId"
              :availableCapabilities="availableCapabilities"
              label=" "
              placeholder=" "
              class="short-input-labor left-aligned"
              itemText="laborItemDescription"
              @emitError="emitError"
              @inputSelected="updateInstallationCapabilityId"
              ref="InstallationCapabilitySearchField"
          />
        </td>
        <!-- description (Optional) -->
        <td class="labor-panel-description-width top-aligned">
          <v-text-field
              style="font-weight: normal"
              :disabled="!canEdit || isInstallationComplete"
              v-model="comment"
              class="short-input-labor left-aligned"
              placeholder=" "
              maxlength="180"
          />
        </td>
        <!-- installer payable -->
        <td class="installer-payable labor-panel-installer-payable-width top-aligned">
          <v-text-field
              :disabled="!canEdit || ifHadEarlyPayment || isInstallationComplete"
              v-model="price"
              class="short-input-labor"
              @blur="setSanitizedPrice"
              prefix="$"
              :rules="[rules.isPositiveOrZeroAmount]"
          />
        </td>
        <!-- Installation date -->
        <td class="labor-panel-installation-date-width short-input-etc top-aligned">
          <date-picker-with-type-in-text-field
              ref="installationDatePicker"
              v-if="lineItem.installation"
              data-id="installation-date"
              :hide-label="true"
              placeholder=" "
              validationName="Installation Date"
              v-model="installationDate"
              :optional="false"
              :allowedDates="allowedInstallationDates"
              :readonly="isInstallerDateNonEditable || isInstallationComplete"
              :disabled="isInstallerDateNonEditable || isInstallationComplete"
              @input="onInstallationDateUpdated"
              @cleared="onInstallationDateCleared"
              @opened="onInstallationDatePickerOpened"
          />
        </td>
        <!-- ETC date (if scheduled) -->
        <td class="labor-panel-etc-date-width short-input-etc top-aligned">
          <date-picker-with-type-in-text-field
              ref="etcDatePicker"
              v-if="lineItem.installation"
              data-id="etc"
              label=" "
              placeholder=" "
              validationName="ETC"
              v-model="selectedEtc"
              :optional="false"
              :readonly="!canEdit || ifHadEarlyPayment || isInstallationComplete"
              :disabled="!canEdit || ifHadEarlyPayment || isInstallationComplete"
              :allowedDates="allowedETCDates"
              @input="onETCUpdated"
              @cleared="onETCCleared"
          />
        </td>
        <!-- installer name (if scheduled) -->
        <td class="installer-name labor-panel-installer-name-width top-aligned">
          <installer-search-field
              ref="installerSearchField"
              v-if="lineItem.installation"
              :value="installerId"
              :item-text="'installerCompanyName'"
              :item-value="'installerId'"
              :required="true"
              @onOptionChange="onInstallerUpdated"
              :readonly="!canEdit || ifHadEarlyPayment || isInstallationComplete"
              :options="installers"
              class="short-input-labor left-aligned"
              @input="onInstallerUpdated"
              @cleared="onInstallerCleared"
          />
        </td>
        <!-- installer crew name (if scheduled) -->
        <td class="labor-panel-installer-crew-width top-aligned">
          <installer-crew-search-field
              v-if="lineItem.installation"
              ref="installerCrewSearchField"
              class="short-input-crew left-aligned"
              id="installer-crew-tbd-dropdown"
              :itemTextField="'installerCrewName'"
              :itemValueField="'installerCrewId'"
              :readonly="!canEdit || !isInstaller|| ifHadEarlyPayment || isInstallationComplete"
              :placeholder="'Search Installer Crews'"
              :value="installerCrewId"
              :required="true"
              :availableInstallerCrews="installerCrews"
              @input="onInstallerCrewUpdated"
              @cleared="onInstallerCrewCleared"
          />
        </td>
        <!-- Stop -->
        <td class="labor-panel-stop-width short-input-stop top-aligned">
          <installer-crew-stops
              ref="crewStopsDropdown"
              v-if="installerId && installerCrewId && (stopOptions.length > 1)"
              data-id="stop"
              :value=stop
              :stopOptions="stopOptions"
              :readonly="isInstallationComplete"
              :placeholder="'Choose stops'"
              @input="onStopUpdated"
          />
        </td>
        <td class="early-payment-btn labor-panel-early-payment-width top-aligned">
          <v-btn
              v-if="ifCanSubmitPayment && initialInstallationStatus === `SCHEDULED`&& this.lineItem.installation.status !== `NEW`"
              small
              dark
              outlined
              class="ma-0 pa-1 mt-0 fill-height"
              color="primary"
              @click="onEarlyInstallerPayment(lineItem.installation)"
          >
            Early Payment
          </v-btn>
          <div v-else-if="ifHadEarlyPayment" :title="earlyPaymentDate">
            {{ earlyPaymentDate }}
          </div>
          <v-icon
              v-else-if="lineItem.installation && lineItem.installation.paid"
              color="primary"
              class="short"
          >
            check_circle_outline
          </v-icon>
        </td>
        <!-- Work Order -->
        <td class="work-order-button labor-panel-work-order-width top-aligned">
          <v-btn v-if="isItemFromSalesApp(lineItem.jobLineItem) && !isGenerated(lineItem.jobLineItem.referenceId)"
                 small
                 dark
                 outlined
                 class="ma-0 pa-1 fill-height"
                 color="primary"
                 @click="onWorkOrderClick(lineItem.jobLineItem.referenceId)"
          >
            Work Order
          </v-btn>
        </td>
        <!-- Installation Status -->
        <td class="labor-panel-installation-status-width short-input-stop top-aligned">
          <general-purpose-dropdown
              v-if="lineItem.installation"
              :value="installationStatus"
              :item-text="'text'"
              :item-value="'value'"
              :select-options="statusChoices"
              ref="installerStatusDropdown"
              placeholder="Choose status"
              :required="true"
              :readonly="isInstallerStatusNonEditable || isInstallationComplete"
              @onOptionChange="onInstallerStatusUpdated"
          />
        </td>
        <!-- delete Icon -->
        <td class="delete-icon labor-panel-delete-icon-width top-aligned">
          <v-btn
              small
              dark
              icon
              class="short ma-0 pa-0"
              v-if="canDeleteItems"
              @click="onDeleteItem(lineItem.jobLineItem.referenceId)"
          >
            <v-icon class="short" color="primary">
              delete
            </v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import moment from "moment";
import InstallationCapabilitiesSearchField from "../../../components/searchFields/InstallationCapabilities.vue";
import InstallerSearchField from "../../../components/searchFields/Installers.vue";
import {isGenerated} from "../../../assets/referenceIds";
import Constants from "../../../assets/constants";
import Rules, {isCreatedAfterInstallerTrackerRelease} from "../../../assets/rules";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import InstallerCrewStops from "../../searchFields/InstallerCrewStops.vue";
import InstallerCrewSearchField from "../../../components/searchFields/InstallerCrews.vue";
import DatePickerWithTypeInTextField from "../../common/form/DatePickerWithTypeInTextField.vue";
import GeneralPurposeDropdown from "../../common/form/GeneralPurposeDropdown.vue";
import Jobs from "../../../rest/jobs";
import DateFormatter from "../../../assets/dateFormatter";
import CrewStopsHelper from "../../../assets/crewStopsHelper";
import FormattingHelper from "../../../assets/formattingHelper";
import InstallerSlots from "@/rest/installer/installerSlots";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    InstallerCrewStops,
    InstallationCapabilitiesSearchField,
    InstallerSearchField,
    InstallerCrewSearchField,
    DatePickerWithTypeInTextField,
    GeneralPurposeDropdown
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    lineItem: {
      type: Object,
      required: true
    },
    availableCapabilities: Array
  },

  data() {
    return {
      updatedLineItem: null,
      referenceId: null,
      type: null,
      quantity: null,
      price: null,
      comment: null,
      isFulFilled: false,
      installationCapabilityId: null,
      installerCompanyName: null,
      installation: null,
      installationDate: null,
      canSubmitPayment: false,
      availableDates: [],
      installersSlotsSummary: [],
      selectedEtc: null,
      initialInstallationStatus: null,
      stop: null,
      stopOptions: [],
      defaultStopOptionsSize: 0,
      statusChoices: [
        {text: "New", value: 'NEW'},
        {text: "Scheduled", value: 'SCHEDULED'}
      ],
      rules: {
        isPositiveOrZeroAmount: value => {
          return Rules.isPositiveOrZero(FormattingHelper.removeNonNumericCharacters(value));
        }
      },
    };
  },

  created() {
    this.getInstallersSlots();
    if (this.lineItem.installation) {
      this.getAvailableDates();
      this.initialInstallationStatus = this.lineItem.installation.status;
    }
  },

  computed: {
    isInstallerDateNonEditable() {
      return !this.canEdit || this.ifHadEarlyPayment;
    },
    isStopNonEditable() {
      return this.isInstallationComplete || !this.canEdit;
    },
    isInstallerStatusNonEditable() {
      return !this.canEdit || !this.installationDate || this.ifHadEarlyPayment;
    },
    jobId() {
      return this.job.id;
    },
    branchId() {
      return this.job.branchId;
    },
    installerId() {
      let item = this.updatedLineItem;
      return item.jobLineItem.installerId;
    },
    installerCrewId() {
      let item = this.updatedLineItem;
      return item.jobLineItem.installerCrewId;
    },
    installerCrewName() {
      let item = this.updatedLineItem;
      return item.jobLineItem.installerCrewName;
    },
    installationStatus() {
      return this.lineItem.installation
          ? this.statusChoices.find((status) => status.value === this.lineItem.installation.status)
          : null
    },
    isInstaller() {
      return this.updatedLineItem.jobLineItem.installerId && this.updatedLineItem.jobLineItem.installerId !== Constants.TBDInstallerId;
    },
    canDeleteItems() {
      return !(this.job.invoiced || this.ifHadEarlyPayment || this.isInstallationComplete);
    },
    canEdit() {
      return !this.job.invoiced;
    },
    isInstallationComplete() {
      return this.lineItem.installation && this.lineItem.installation.certificateOfCompletionId !== null && this.lineItem.installation.certificateOfCompletionId !== undefined;
    },
    ifCanSubmitPayment() {
      const {installationDate} = this.$data;
      let earlyPaymentDate = this.lineItem.jobLineItem.earlyPaymentDate;
      let canSubmitPayment = false;
      if (installationDate && !earlyPaymentDate) {
        // user can submit payment only if installation date is in the past or today
        canSubmitPayment = !DateUtils.isFutureDate(this.lineItem.jobLineItem.installationDate);
      }
      return canSubmitPayment && !(this.job.invoiced);
    },
    ifHadEarlyPayment() {
      let earlyPaymentDate = this.lineItem.jobLineItem.earlyPaymentDate;
      if (earlyPaymentDate) {
        return true;
      }
      return false;
    },
    earlyPaymentDate() {
      let earlyPaymentDate = this.lineItem.jobLineItem.earlyPaymentDate;
      if (earlyPaymentDate) {
        return moment(earlyPaymentDate).format("MM/DD/YYYY HH:mm");
      }
      return "N/A";
    },
    originalInstallerSlot() {
      if (this.lineItem.installation.jobLineItem.installerSlotId) {
        return {
          id: this.lineItem.installation.jobLineItem.installerSlotId,
          installerCompanyName: this.lineItem.installation.installer.companyName,
          installerId: this.lineItem.installation.jobLineItem.installerId,
          installerCrewId: this.lineItem.installation.jobLineItem.installerCrewId,
          installerCrewName: this.lineItem.installation.jobLineItem.installerCrewName,
          status: Constants.installerSlotStatuses.SCHEDULED
        };
      }
    },
    installers() {
      if (!this.installersSlotsSummary) {
        return [];
      }
      const installers = {};
      this.installersSlotsSummary.forEach(slot => {
        // If the installer is not already in the installers object, add it
        if (!installers[slot.installerId]) {
          installers[slot.installerId] = {
            installerId: slot.installerId,
            installerCompanyName: slot.installerCompanyName,
            inactive: true
          };
        }
        if (installers[slot.installerId].inactive
            && (slot.status === Constants.installerSlotStatuses.AVAILABLE
                ||
                // Keep original installer available in dropdown even if no free crews
                ((slot.installerId === this.lineItem.installation.installerId) && !this.isInstallationDateChanged))) {
          installers[slot.installerId].inactive = false;
        }
      });

      if (this.updatedLineItem.jobLineItem.installerId && !installers[this.installerId]) {
        return [{
          installerId: this.installerId,
          installerCompanyName: this.lineItem.installation.installer.companyName
        }]
      }
      return Object.values(installers);
    },
    installerCrews() {
      if (!this.installersSlotsSummary) {
        return [];
      }
      // Leave only AVAILABLE crews + the crew that is already selected (having SCHEDULED status)
      const crewsFromSlots = this.installersSlotsSummary.filter((crewSlot) => {
        return crewSlot.installerId === this.installerId
            && (crewSlot.status === Constants.installerSlotStatuses.AVAILABLE
                || crewSlot.id === this.originalInstallerSlot?.id);
      });
      if (crewsFromSlots.length === 0 && !!this.installerCrewId) {
        return [{
          installerCrewId: this.installerCrewId,
          installerCrewName: this.installerCrewName
        }];
      } else {
        return crewsFromSlots;
      }
    },
    installerSlotId() {
      if (this.installerCrewId && this.installersSlotsSummary) {
        return this.installersSlotsSummary.find((slot) => {
          return slot.installerCrewId === this.installerCrewId
              && (slot.status === Constants.installerSlotStatuses.AVAILABLE || slot.id === this.originalInstallerSlot?.id)
        })?.id;
      }
    },
    isInstallationDateChanged() {
      return !DateUtils.isSameDate(this.installationDate, this.lineItem.installation.jobLineItem.installationDate);
    }
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        this.updateLineItem(updated);
        this.initialInstallationStatus = this.lineItem.installation.status;
      }
    },
    comment(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.updatedLineItem.jobLineItem.comment = newVal;
        this.$emit('commentUpdated', {
          item: this.lineItem,
          updated: newVal
        });
      }
    },
    installationCapabilityId(value) {
      this.updatedLineItem.jobLineItem.installationCapabilityId = value;
      this.$emit('InstallationCapabilityUpdated', {
        item: this.lineItem,
        updated: value
      });
    },
    price(value) {
      const newValueAsCurrency = CurrencyFormatter.asCurrency(value);
      const oldValueAsCurrency = CurrencyFormatter.asCurrency(this.lineItem.jobLineItem.price.value);
      if (newValueAsCurrency.value === oldValueAsCurrency.value) {
        return;
      }
      this.updatedLineItem.jobLineItem.price = CurrencyFormatter.asCurrency(value);
      this.$emit('priceUpdated', {
        item: this.lineItem,
        updated: value
      });
    },
    installationDate(newVal, oldVal) {
      if (newVal && oldVal === null) {
        this.getAvailableDates();
      }
    },
    installation(newVal, oldVal) {
      if (newVal && oldVal === null) {
        this.getInstallersSlots();
      }
    },
  },

  methods: {
    updateInstallationCapabilityId(value) {
      this.installationCapabilityId = value;
    },
    onEarlyInstallerPayment(referenceId) {
      this.$emit('earlyInstallerPayment', referenceId);
    },
    onInstallerCleared() {
      this.onInstallerUpdated(null);
    },
    async onInstallerUpdated(value) {
      this.$refs.installerCrewSearchField.clearSelectedCrew();

      this.clearOtherFieldsIfInstallerChanged();
      this.updatedLineItem.jobLineItem.installerId = value;
      this.$emit('installerUpdated');
    },
    onInstallerCrewCleared() {
      this.onInstallerCrewUpdated(null);
    },
    onInstallerCrewUpdated(value) {
      this.getInstallationStops(value, this.lineItem.installation.installationDate);
      this.updatedLineItem.jobLineItem.installerCrewId = value;
      this.updatedLineItem.installation.installerSlotId = this.installerSlotId;
      this.clearStopIfCrewSelected();
      this.$emit('installerCrewUpdated');
    },
    onInstallationDateCleared() {
      this.onInstallationDateUpdated(null);
    },
    onInstallationDateUpdated(value) {
      if (DateUtils.isSameDate(new Date(value), new Date(this.lineItem.installation.installationDate))) {
        return;
      }
      this.lineItem.installation.installationDate = value;

      this.updatedLineItem.jobLineItem.installerId = null;
      this.$refs.installerSearchField.clearSelected();
      this.$refs.installerCrewSearchField.clearSelectedCrew();
      this.clearEtcDate();
      this.clearOtherFieldsIfInstallerChanged();
      this.$nextTick(() => {
        this.installersSlotsSummary = null;
      });
      this.getInstallersSlots();
      this.$emit('installationDateUpdated');
    },
    onInstallationDatePickerOpened() {
      this.getAvailableDates();
    },
    onETCCleared() {
      this.selectedEtc = null;
      this.onETCUpdated(null);
    },
    onETCUpdated(value) {
      this.lineItem.installation.etc = value;
      this.lineItem.jobLineItem.etc = value;
      this.$emit('etcUpdated');
    },
    onStopUpdated(value) {
      this.lineItem.installation.stop = value;
      this.$emit('stopUpdated', {
        item: this.lineItem,
        updated: value
      });
    },
    onInstallerStatusUpdated(value) {
      this.lineItem.installation.status = value.value ? value.value : value;
      this.$emit('installationStatusUpdated');
    },
    onDeleteItem(referenceId) {
      this.$emit('itemDeleted', referenceId);
    },
    emitError(error) {
      this.$emit('error', error);
    },
    setSanitizedPrice() {
      this.price = CurrencyFormatter.sanitizePrice(CurrencyFormatter.asCurrency(FormattingHelper.removeNonNumericCharacters(this.price)));
    },
    isGenerated,
    onWorkOrderClick(lineItemRefId) {
      window.open(`/jobs/work-order/${this.job.referenceId}/${lineItemRefId}`, "_blank");
    },
    isItemFromSalesApp(jobLineItem) {
      return Constants.laborLineItemSource.SALES_REP_APP === jobLineItem.laborLineItemOrigin;
    },
    allowedETCDates(value) {
      const installationDate = this.lineItem.installation.installationDate;
      return moment(value).isSameOrAfter(moment(installationDate), "day");
    },
    allowedInstallationDates(value) {
      return this.availableDates.includes(value)
          || value === DateFormatter.formatDateInUtcAndYYYYMMDDFormat(this.lineItem?.jobLineItem?.installationDate);
    },
    updateLineItem(updated) {
      this.updatedLineItem = updated;
      this.referenceId = updated.jobLineItem.referenceId;
      this.type = updated.jobLineItem.type;
      this.status = null;
      this.comment = updated.jobLineItem.comment;
      this.quantity = null;
      this.price = CurrencyFormatter.formatCurrency(updated.jobLineItem.price);
      this.isFulFilled = !!updated.jobLineItem.fulfillmentDate;
      this.installationCapabilityId = updated.jobLineItem.installationCapabilityId;

      if (updated.installation) {
        this.installerCompanyName = updated.installation
            ? updated.installation.installer.companyName
            : null;
        this.installationDate = updated.installation
            ? updated.installation.installationDate
            : null;
        this.selectedEtc = updated.installation.etc;
        this.installation = updated.installation;
        this.installation.installerSlotId = updated.jobLineItem.installerSlotId;

        // this.defaultStopOptionsSize sets 10 max options and dropdown uses it like a selected value. see for loop there
        this.stop = this.getStopForUpdatedItem(updated);
        this.defineStop(updated.crewStopsData, updated.jobLineItem.installerCrewId, updated.installation.installationDate);
      }
    },
    defineStop(laborStop, installerCrewId, installationDate) {
      if (laborStop) {
        this.stopOptions = this.getFormattedStopOptions(laborStop, installerCrewId, installationDate);
        let has = CrewStopsHelper.groupsHaveSameJobAndCrewAndInstallationDate(this.stopOptions);
        if (!this.stop || has) {
          this.checkIfStopShouldBeCopiedFromExistingItem();
        }
      }
    },
    updateStop(stopValue) {
      this.stop = stopValue;
    },
    getStopForUpdatedItem(updatedItem) {
      return updatedItem.installation.stop != null ? updatedItem.installation.stop : this.defaultStopOptionsSize;
    },
    clearStopIfCrewSelected() {
      this.stop = null;
      if (this.lineItem.jobLineItem.stop || this.lineItem.installation.stop) {
        this.lineItem.jobLineItem.stop = null;
        this.lineItem.installation.stop = null;
      }
    },
    updateUpdatedItemStop(updatedItem) {
      this.stop = this.getStopForUpdatedItem(updatedItem);
    },
    getJobLineItemId() {
      return this.updatedLineItem.jobLineItem.id;
    },
    clearOtherFieldsIfInstallerChanged() {
      this.stop = null;
      this.stopOptions = [];
      this.$nextTick(() => {
        if (this.$refs.crewStopsDropdown) {
          this.$refs.crewStopsDropdown.clearSelected();
        }
      });
      this.updatedLineItem.jobLineItem.installerCrewId = null;
    },
    clearEtcDate() {
      this.selectedEtc = null;
      this.onETCUpdated(null);
      this.$nextTick(() => {
        if (this.$refs.etcDatePicker) {
          this.$refs.etcDatePicker.clearSelected();
        }
      });
    },
    isInstallationInGroup(stopGroups, installerCrewId, installationDate) {
      let jobid = this.job.id;
      let formattedInstallationDate = DateFormatter.formatDateInYYYYMMDDFormat(installationDate);
      let isInstallationInGroup = false;

      stopGroups.forEach(e => {
        e.installationDate = DateFormatter.formatDateInYYYYMMDDFormat(e.installationDate);
        if (e.jobId === jobid && e.installationDate === formattedInstallationDate && e.crewId === installerCrewId) {
          isInstallationInGroup = true;
        }
      })
      return isInstallationInGroup;
    },
    async getInstallationStops(installerCrewId, installationDate) {
      if (!installerCrewId || !installationDate) {
        return [];
      }
      try {
        let formattedDate = DateFormatter.formatDateInYYYYMMDDFormat(installationDate);
        const response = await Jobs.getRestApi().getStopsNumberByInstallationIdAndDate(installerCrewId, formattedDate);
        this.stopOptions = this.getFormattedStopOptions(response.data, installerCrewId, installationDate);
        let has = CrewStopsHelper.groupsHaveSameJobAndCrewAndInstallationDate(this.stopOptions);
        if (!this.stop || has) {
          this.checkIfStopShouldBeCopiedFromExistingItem();
        }
      } catch (error) {
        throw error;
      }
    },
    getFormattedStopOptions(stopGroups, installerCrewId, installationDate) {
      let isNewGroup = !this.isInstallationInGroup(stopGroups, installerCrewId, installationDate);
      let value = stopGroups.length;

      if (isNewGroup) {
        value += 1;
      }

      let maxOptions = (value > 0) ? value : this.defaultStopOptionsSize;
      let result = [];
      for (let i = 1; i <= maxOptions; i++) {
        result[i - 1] = {
          stopText: 'Stop ' + i,
          stopValue: i
        }
      }
      return result;
    },
    async getInstallersSlots() {
      if (this.installationDate) {
        InstallerSlots.getRestApi().getSlotsByDateAndCapabilityId(this.branchId,
            DateFormatter.formatDateInYYYYMMDDFormat(this.installationDate), this.installationCapabilityId)
            .then((response) => {
              let result = response.data;
              if (this.originalInstallerSlot && !this.isInstallationDateChanged) {
                result = result.filter(slot => slot.id !== this.originalInstallerSlot.id);
                result.push(this.originalInstallerSlot);
              }
              this.installersSlotsSummary = result;
            })
            .catch((error) => {
              this.$emit("showError", error);
            });
      }
    },
    getAvailableDates() {
      if (this.branchId && this.installationCapabilityId) {
        InstallerSlots.getRestApi().getAvailableDatesByCapabilityId(this.branchId, this.installationCapabilityId)
            .then((response) => {
              this.availableDates = response.data;
            })
            .catch((error) => {
              this.$emit("showError", error);
            });
      }
    },
    checkIfStopShouldBeCopiedFromExistingItem() {
      this.$emit('checkIfSomeItemHasTheSameCrewAndDate', this.referenceId,
          DateFormatter.formatDateInYYYYMMDDFormat(this.installationDate), this.updatedLineItem.jobLineItem.installerCrewId,
          this.stop
      );
    }
  }
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}

.work-order-button {
  text-align: center;
  padding-top: 2px;
}

.reference-id-td {
  padding-top: 7px;
  font-size: 13px;
}

.early-payment-btn {
  padding-top: 2px;
  text-align: center;
}

.installer-payable {
  font-weight: normal;
}

.installer-name {
  font-weight: normal;
}

.short-input-crew {
  max-height: 70px;
  margin-left: 15px;
}

td.short-input-stop.top-aligned .v-input {
  margin-left: 15px;
}

td.short-input-etc.top-aligned {
  padding-left: 15px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}

.item-row td.top-aligned {
  vertical-align: top;
  font-weight: normal;
}

.delete-icon {
  text-align: right;
}

.labor-panel-reference-id-width {
  width: 9%;
}

.labor-panel-install-type-width {
  width: 10%;
}

.labor-panel-description-width {
  width: 11%;
}

.labor-panel-installer-payable-width {
  width: 9%;
}

.labor-panel-installation-date-width {
  width: 12%;
}

.labor-panel-etc-date-width {
  width: 12%;
}

.labor-panel-installer-name-width {
  width: 15%;
}

.labor-panel-installer-crew-width {
  width: 13%;
}

.labor-panel-stop-width {
  width: 10%;
}

.labor-panel-early-payment-width {
  width: 8%;
}

.labor-panel-work-order-width {
  width: 7%;
}

.labor-panel-installation-status-width {
  width: 8%;
}

.labor-panel-delete-icon-width {
  width: 30px;
}
</style>
