<template>
  <landing-page>
    <div class="landing-form">
      <change-password-form
          ref="form"
          outline
          :resetPassword="true"
          @submitClick="submitClick"
      ></change-password-form>
    </div>
    <v-snackbar :timeout="30000" error v-model="snackbar">
      {{ snackbarMessage }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </landing-page>
</template>

<script>
import LandingPage from "../LandingPage.vue";
import ChangePasswordForm from "@/components/passwordManagement/ChangePasswordForm.vue";
import Users from "@/rest/users";

export default {
  components: {
    LandingPage,
    ChangePasswordForm
  },

  data() {
    return {
      snackbar: false,
      snackbarMessage: ""
    };
  },

  methods: {
    submitClick() {
      const component = this;
      const payload = this.$refs.form.buildPayload();
      Users.getRestApi()
          .resetPassword(
              payload.email,
              payload.temporaryPassword,
              payload.newPassword
          )
          .then(() => {
            component.$router.push("/");
          })
          .catch((error) => {
            component.snackbarMessage =
                error.response && error.response.data
                    ? error.response.data.message
                    : "Password reset failed.";
            component.snackbar = true;
          });
    }
  }
};
</script>
