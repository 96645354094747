<template>
  <v-form ref="addVendorBranchRelation" lazy-validation>
    <v-container>
      <v-row class="mt-1">
        <v-col md="12">
          <vendors-autocomplete-search-field
              id="catalog-item-relation-vendors-dropdown"
              v-model="vendorId"
              label="Vendor"
              @input="onVendorChosen"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6">
          <v-text-field
              v-model="vendorSku"
              id="catalog-item-vendor-relation-vendor-sku"
              label="Vendor SKU"
              placeholder=" "
              persistent-placeholder
              type="string"
          />
        </v-col>
        <v-col md="6">
          <v-text-field
              v-model.number="vendorCost"
              label="* Vendor Cost"
              id="catalog-item-vendor-relation-vendor-cost"
              placeholder="0.00"
              persistent-placeholder
              prefix="$"
              :rules="[rules.required, rules.currencyFormat]"
              type="number"
              min="0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <branches-search-autocomplete
              ref="branchesAutocomplete"
              :label="'* Branches'"
              :placeholder="'Select branch for a chosen Vendor'"
              :select-all-enabled="false"
              :required="true"
              :clearable="true"
              :multiple="true"
              @inputSelected="onBranchSelected"
              :getBranchesOnComponentCreation="true"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";
import VendorsAutocompleteSearchField from "../../components/searchFields/VendorsAutocomplete.vue"
import BranchesSearchAutocomplete from "../../components/searchFields/BranchesAutocomplete.vue"
import Rules from "../../assets/rules";

export default {
  components: {
    VendorsAutocompleteSearchField,
    ModalDialog,
    BranchesSearchAutocomplete
  },

  props: {},

  data() {
    return {
      vendorSku: null,
      vendorCost: null,
      vendorId: null,
      branches: [],
      rules: {
        required: Rules.required,
        currencyFormat: Rules.isCurrencyFormat
      }
    }
  },

  methods: {
    reset() {
      this.vendorSku = null;
      this.vendorCost = null;
      this.vendorId = null;
      this.$refs.branchesAutocomplete.clearSelectedBranch();
      this.resetValidation();
    },
    validate() {
      return this.$refs.addVendorBranchRelation.validate();
    },
    resetFormData() {
      return this.reset();
    },
    resetValidation() {
      return this.$refs.addVendorBranchRelation.resetValidation();
    },
    onVendorChosen(value) {
      this.vendorId = value;
    },
    getFormData() {
      return Object.fromEntries(Object.entries(this.$data).filter(e => e[0] !== 'rules'));
    },
    onBranchSelected(value) {
      this.branches = value;
    }
  }
};
</script>
