import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * SalesPerTierDTO.
 */
export class SalesPerTierDTO {
    id: number;
    name: string;
    percentage: number = 0;

    constructor(id:number, name: string, percentage: number) {
        this.id = id;
        this.name = name;
        this.percentage = percentage;
    }
}

/**
 * REST interface provided for sales representative tiers.
 */
export interface SalesRepTiersRestApi {
    getSalesRepTiers(): AxiosPromise<SalesPerTierDTO[]>;

    updateSalesRepTiers(tiers: SalesPerTierDTO[]): AxiosPromise<SalesPerTierDTO[]>;
}

/**
 * REST implementation provided for sales representative tiers.
 */
class Implementation implements SalesRepTiersRestApi {
    getSalesRepTiers(): AxiosPromise<SalesPerTierDTO[]> {
        return Axios.axiosInstance().get<SalesPerTierDTO[]>("/sales-rep-tiers");
    }

    updateSalesRepTiers(tiers: SalesPerTierDTO[]): AxiosPromise<SalesPerTierDTO[]> {
        return Axios.axiosInstance().post<SalesPerTierDTO[]>("/sales-rep-tiers", tiers);
    }
}

const SalesRepTiers = {
    getRestApi(): SalesRepTiersRestApi {
        return new Implementation();
    }
};

export default SalesRepTiers;
