<template>
  <v-container class="mt-6">
    <v-skeleton-loader
        v-if="loading"
        type="card"
        class="mt-6"
    />
    <v-form v-else ref="form" v-model="isFormValid">
      <v-row v-for="(group, groupName) in groupedItems" :key="groupName">
        <v-col cols="12">
          <v-card flat class="ml-3 mt-3 pa-4">
            <v-card-text>
              <v-row v-if="sortedGroup.some(item => item.isModifiable)">
                <v-col v-for="item in sortedGroup" :key="item.id" cols="12" v-show="item.isModifiable">
                  <v-text-field
                      v-model="item.value"
                      :label="item.description"
                      :type="item.type === 'INTEGER' || item.type === 'DURATION' ? 'number' : 'text'"
                      :rules="[rules.required]"
                      persistent-placeholder
                      @input="markAsModified(item)"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-icon x-large class="mt-3"> mdi-notebook-remove-outline</v-icon>
                  <v-card-text>No configurations available</v-card-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="end" v-if="hasVisibleConfigurations">
        <v-col cols="2">
          <v-btn
              color="primary"
              :disabled="!isFormValid"
              @click="saveConfigurations"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Rules from "@/assets/rules";

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isActionConfirmed: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      isFormValid: false,
      isModified: false,
      loading: false,
      noChangeCounter: 0,
      modifiedItems: [],
      rules: {
        required: Rules.required
      }
    };
  },

  computed: {
    groupedItems() {
      return this.items.reduce((groups, item) => {
        const group = groups[item.groupName] || [];
        group.push(item);
        groups[item.groupName] = group;
        return groups;
      }, {});
    },
    hasVisibleConfigurations() {
      return this.items.some(item => item.isModifiable);
    },
    sortedGroup() {
      return [...this.items].sort((a, b) => a.id - b.id);
    }
  },

  watch: {
    isActionConfirmed: {
      handler(val) {
        if (val) {
          this.saveConfigurations();
        }
      },
      immediate: true
    }
  },

  methods: {
    markAsModified(item) {
      this.isModified = true;
      const existingItem = this.modifiedItems.find(modifiedItem => modifiedItem.id === item.id);
      if (existingItem) {
        existingItem.value = item.value;
      } else {
        this.modifiedItems.push(item);
      }
    },
    saveConfigurations() {
      if (!this.isModified) {
        this.showMessage();
      } else {
        this.$emit('updateConfigurations', this.modifiedItems);
        this.modifiedItems = [];
        this.isModified = false;
      }
    },
    showMessage() {
      this.noChangeCounter++;
      if (this.noChangeCounter === 3) {
        this.$emit('noModificationDetected', 'Still no changes detected, but nice try!');
        this.noChangeCounter = 0;
      } else {
        this.$emit('noModificationDetected', 'No changes detected');
      }
    }
  }
};
</script>
