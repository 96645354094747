<template>
  <v-container fluid>
    <v-row class="mb-0">
      <v-col cols="4" v-if="filterByMarket">
        <market-search-field
            class="marketSearchField"
            :getItemsOnComponentCreation="false"
            :multiple-selection="false"
            :filteredByBranch="true"
            :markets="markets"
            @input="onMarketSelect"
        />
      </v-col>
      <v-col cols="4" v-if="filterByMarket">
        <sales-reps-search-field-by-market
            ref="salesRepsSelection"
            class="marketSearchField"
            :disabled="false"
            :multiple-selection="false"
            :marketId="marketId"
            :is-required="false"
            item-value="id"
            placeholder="Search Sale Representatives"
            :clearable="true"
            :clear-icon-cb="onClearClicked"
            :isRefreshByMarket="true"
            @change="onSelectedSaleRepresentatives"
        />
      </v-col>
    </v-row>
    <FullCalendar
        ref="fullCalendar"
        class="calendar"
        :options="calendarOptions"
    />
  </v-container>
</template>

<script>
import SalesRepsTimeBlock from "@/rest/salesRepsTimeBlock";
import MarketSearchField from "@/components/searchFields/MarketsAutocomplete.vue";
import SalesRepsSearchFieldByMarket from "../searchFields/SalesRepsSearchFieldByMarket";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import DateUtils from "@/assets/utils/dateUtils";
import Roles from "@/assets/roles";
import Constants from "@/assets/constants";

export default {
  components: {
    FullCalendar,
    MarketSearchField,
    SalesRepsSearchFieldByMarket
  },

  props: {
    filterBySalesRep: Boolean,
    filterByMarket: Boolean,
    market: null,
    showDoneButton: Boolean,
    markets: {
      type: Array,
      required: false,
    },
    formType: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      selectedSalesRepId: null,
      marketId: null,
      marketTimezone: null,
      timeBlocks: [],
    };
  },

  watch: {
    selectedSalesRepId() {
      this.getTimeBlocks();
    },
    marketId(value) {
      this.timeBlocks = [];
      this.selectedSalesRepId = null;
      this.$refs.salesRepsSelection.resetMarket();
      this.$emit("marketClicked", value);
    },
    market(value) {
      this.marketId = value;
      this.getTimeBlocks();
    }
  },

  computed: {
    calendarOptions() {
      return {
        initialView: "dayGridMonth",
        headerToolbar: this.header,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        aspectRatio: 2.4,
        height: "auto",
        slotMinTime: "07:00:00",
        slotMaxTime: "22:00:00",
        weekends: true,
        events: this.formattedTimeBlocks,
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick,
        datesSet: this.handleDates,
        customButtons: this.customButtons,
        showNonCurrentDates: false,
        displayEventTime: false,
        eventDisplay: 'block',
      }
    },
    formattedTimeBlocks() {
      return this.timeBlocks.map((item, index) => {
        const startTime = this.convertToDate(item.slotDate, item.startTime);
        const endTime = this.convertToDate(item.slotDate, item.endTime);
        let slots = item.maxSlots - item.usedSlots;
        return {
          id: index,
          start: startTime,
          end: endTime,
          color: item.color,
          title: "(" + slots + ") " + item.name,
          textColor: "black"
        };
      });
    },
    customButtons() {
      if (!this.showDoneButton) {
        return false
      } else {
        return {
          doneBtn: {
            text: "Done",
            click: () => {
              this.closeDialog();
            },
          }
        };
      }
    },
    header() {
      return {
        left: 'prevYear,prev,today,next,nextYear,dayGridMonth,timeGridWeek',
        center: 'title',
        right: this.showDoneButton ? 'doneBtn' : ''
      };
    }
  },

  methods: {
    onMarketSelect(value) {
      this.marketId = value;
      this.marketTimezone = this.markets.find(market => market.id === value).timezone;
      this.getTimeBlocks();
    },
    async getTimeBlocks() {
      if (this.marketId && this.selectedSalesRepId) {
        try {
          const response = await SalesRepsTimeBlock.getRestApi().getByMarketDateAndSalesRep(
              this.marketId,
              this.selectedSalesRepId,
              moment(this.startDate).format("YYYY-MM-DD"),
              moment(this.endDate).format("YYYY-MM-DD"),
          );
          this.timeBlocks = response.data;

        } catch (error) {
          console.log(error);
        }
      } else if (this.marketId) {
        try {
          const response = await SalesRepsTimeBlock.getRestApi().getByMarketAndDate(
              this.marketId,
              moment(this.startDate).format("YYYY-MM-DD"),
              moment(this.endDate).format("YYYY-MM-DD"),
          );
          this.timeBlocks = response.data;
        } catch (error) {
          console.log(error);
        }
      }
    },
    handleDateClick(arg) {
      let theSameDateAsDayOff = this.timeBlocks.some(timeBlock => {
        if (timeBlock.id === null && moment(arg.date).format("YYYY-MM-DD") === moment(timeBlock.slotDate).format("YYYY-MM-DD")) {
          return true;
        }
      });
      if (theSameDateAsDayOff) {
        return false;
      }

      const today = moment(new Date()).format("YYYY-MM-DD");
      const clickedDate = moment(arg.date).format("YYYY-MM-DD");
      if (moment(clickedDate).isSameOrAfter(today)) {
        let object = {
          date: clickedDate,
          weekDay: DateUtils.convertToWeekDateName(arg)
        };
        this.$emit('dateClicked', object);
      }
    },
    handleEventClick(arg) {
      const timeBlockClicked = this.timeBlocks[arg.event.id];
      const timeBlockDate = this.convertToDate(timeBlockClicked.slotDate, timeBlockClicked.startTime);
      let theSameDateAsDayOff = this.timeBlocks.some(timeBlock => {
        return (timeBlockClicked.id === null
            && moment(timeBlockDate).format("YYYY-MM-DD") === moment(timeBlock.slotDate).format("YYYY-MM-DD"))
      });

      if (theSameDateAsDayOff) {
        return false;
      }

      const isSameDay = DateUtils.isSameDayInTimezone(timeBlockClicked.slotDate, this.marketTimezone);
      const isFutureDate = DateUtils.isFutureDateInTimezone(timeBlockClicked.slotDate, timeBlockClicked.startTime, this.marketTimezone);
      const allowSuperAdminToPickAnyDay = Roles.isSuperAdminUser() && this.formType === Constants.leadFormType.UPDATE;

      if (isSameDay || isFutureDate || allowSuperAdminToPickAnyDay) {
        this.$emit("timeblockClicked", timeBlockClicked);
      }
    },
    handleDates() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      const startDate = calendarApi.view.activeStart;
      const endDate = calendarApi.view.activeEnd;
      if (!moment(this.startDate).isSame(startDate) || !moment(this.endDate).isSame(endDate)) {
        this.startDate = startDate;
        this.endDate = endDate;

        if (this.marketId) {
          this.getTimeBlocks();
        }
      }
    },
    convertToDate(date, time) {
      let formattedDate = moment(date).format("YYYY-MM-DD");
      return moment(formattedDate + " " + time).toDate();
    },
    closeDialog() {
      this.$emit('cancelClicked');
    },
    onClearClicked() {
      this.selectedSalesRepId = null;
      this.getTimeBlocks();
    },
    onSelectedSaleRepresentatives(selectedSalesRepId) {
      this.selectedSalesRepId = selectedSalesRepId;
    }
  }
};
</script>

<style lang="scss" scoped>

::v-deep .fc-button-primary {
  color: #fff !important;
  background-color: #6699bb !important;
  border-color: #6699bb !important;
}

::v-deep .fc-toolbar h2 {
  margin: 0;
  color: #78909c !important;
  caret-color: #78909c !important;
}

::v-deep .fc-past {
  opacity: 0.3;
}

.marketSearchField {
  width: 350px;
  margin-left: 20px;
  margin-top: 30px;
}

.calendar {
  padding: 10px 20px 20px;
}
</style>
