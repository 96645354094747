<template>
  <modal-dialog
      ref="dialog"
      title="Payment Received"
      width="500"
      :visible="dialogVisible"
      submitLabel="Yes"
      cancelLabel="No"
      @submitClicked="onConfirmClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
      :loading="loading"
  >
    <v-form ref="form">
      <v-container>
        <div class="modal-message"> You are going to transfer payment from Hold to Received. Are you sure?</div>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../../components/common/ModalDialog.vue";

const formDefaults = {
  dialogVisible: false
};
export default {
  components: {
    ModalDialog
  },

  data() {
    return Object.assign({}, formDefaults, {referenceId: null, loading: false});
  },

  methods: {
    openDialog(referenceId) {
      this.referenceId = referenceId;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.loading = false;
      this.dialogVisible = false
    },
    onCancelClicked() {
      this.closeDialog();
    },
    async onConfirmClicked() {
      this.loading = true;
      try {
        this.$emit('receivedConfirmed');
        this.closeDialog();
      } catch (error) {
        this.loading = false;
        this.emitError(error);
      }
    },
  }
};
</script>
<style>
.modal-message {
  font-size: 14px;
}
</style>
