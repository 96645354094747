<template>
  <v-container>
    <v-row>
      <v-col md="6">
        <v-text-field
            v-model="key"
            label="* Promo Code"
            placeholder=" "
            :rules="[() => !!key || 'Promo Code is required']"
            required
            persistent-placeholder
        />
      </v-col>
      <v-col md="6">
        <status-dropdown
            :value="active"
            @onStatusChange="changeStatus"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <v-textarea
            :rows="2"
            auto-grow
            v-model="description"
            label="Description"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <market-search-field
            ref="marketSearch"
            :marketIds="marketIds"
            :selectAllEnabled="true"
            :getItemsOnComponentCreation="true"
            @input="onUpdateMarketIds"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MarketSearchField from "@/components/searchFields/MarketsAutocomplete.vue";
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";

const formDefaults = {
  key: null,
  active: true,
  description: null,
  marketIds: []
};

export default {
  components: {
    MarketSearchField,
    StatusDropdown
  },

  data() {
    return Object.assign(
        {
          originalState: null,
          defaultState: formDefaults
        },
        formDefaults
    );
  },

  props: {mode: String},

  methods: {
    changeStatus(value) {
      this.active = value;
    },
    resetForm() {
      if (this.$props.mode === "create") {
        this.loadForm(this.defaultState);
      } else {
        this.loadForm(this.originalState);
      }
    },
    buildPayload() {
      const payload = {};
      payload.key = this.key;
      payload.description = this.description;
      payload.active = this.active;
      payload.markets = this.marketIds;
      return payload;
    },
    loadForm(payload) {
      this.key = payload.key;
      this.description = payload.description;
      this.active = payload.active;
      this.marketIds = payload.marketIds;
      this.setDefaultData(payload);
    },
    setDefaultData(data) {
      this.originalState = Object.assign({}, data);
    },
    onUpdateMarketIds(value) {
      this.marketIds = value;
    }
  }
};
</script>
