<template>
  <div class="separator pa-2">
    <table class="line-item-headers caption">
      <tr>
        <td class="column-header" style="width: 15%;">Reference Id</td>
        <td class="column-header" style="width: 45%;">Description</td>
        <td class="column-header" style="width: 15%;">Price</td>
        <td class="column-header" style="width: 15%;">Warranty Cost for Sales Rep</td>
        <td class="column-header" style="width: 15%;">Warranty Cost for 50Floor</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
.help-icon {
  cursor: pointer;
}
.column-header {
  padding-left: 15px;
}
</style>
