export default class ActivityDetector {
    constructor(idleTime, onIdle) {
        this.idleTime = idleTime;
        this.onIdle = onIdle;
        this.timer = null;
        this.events = ['mousemove', 'keydown', 'mousedown', 'touchstart', 'mousewheel', 'DOMMouseScroll', 'MSPointerDown', 'MSPointerMove', 'scroll', 'touchmove', 'touchend', 'click', 'dblclick', 'keyup', 'keypress', 'wheel', 'resize'];
        this.resetTimer = this.resetTimer.bind(this);
        this.start();
    }

    start() {
        this.events.forEach(event => window.addEventListener(event, this.resetTimer));
        this.resetTimer();
    }

    resetTimer() {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.onIdle, this.idleTime);
    }

    stop() {
        clearTimeout(this.timer);
        this.events.forEach(event => window.removeEventListener(event, this.resetTimer));
    }
}
