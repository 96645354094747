<template>
  <dx-data-grid
      ref="activityLogsPageableGrid"
      :data-source="dataSource"
      :show-borders="false"
      :show-pager="true"
      :remote-operations="true"
  >
    <DxPaging
        :enabled="true"
        :page-size="initialPageSize"
        :page-index="currentPage"
    />
    <DxPager
        :visible="true"
        :allowed-page-sizes="pageSizes"
        :show-page-size-selector="true"
        :show-info="true"
        :info-text="computedInfoText"
    />
    <dx-column
        data-field="createdAt"
        width="20%"
        data-type="datetime"
        caption="Date"
        sortOrder="desc"
        format="MM/dd/yyyy, hh:mm a"
    />
    <dx-column
        data-field="changeDescription"
        width="50%"
        caption="Description"
    />
    <dx-column
        data-field="createdUser"
        width="30%"
        caption="Created User"
    />
  </dx-data-grid>
</template>

<script>
import {DxColumn, DxDataGrid, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";
import CustomStore from "devextreme/data/custom_store";

export default {
  components: {
    DxColumn,
    DxPager,
    DxPaging,
    DxDataGrid,
  },

  props: {
    customPagingSize: {
      type: Array,
      required: false,
    },
    fetchLogsFunction: {
      type: Function,
      required: true,
    }
  },

  data() {
    return {
      dataSource: this.createCustomStore(),
      pageSizes: [5, 10, 25, 50],
      initialPageSize: 5,
      currentPage: 0,
      totalElements: 0,
      totalPages: 0,
    };
  },

  watch: {
    customPagingSize: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.pageSizes = newVal;
          this.initialPageSize = this.pageSizes[0];
        }
      }
    }
  },

  computed: {
    computedInfoText() {
      return `Page ${this.currentPage + 1} of ${this.totalPages} (${this.totalElements} items)`;
    }
  },

  methods: {
    createCustomStore() {
      return new CustomStore({
        key: "id",
        load: async (loadOptions) => {
          const pageSize = loadOptions.take || this.initialPageSize;
          const currentPage = loadOptions.skip / pageSize || this.currentPage;
          return this.fetchLogs(pageSize, currentPage);
        }
      });
    },
    async fetchLogs(pageSize, currentPage) {
      try {
        const response = await this.fetchLogsFunction({
          page: currentPage,
          size: pageSize
        });
        this.totalElements = response.data.totalElements;
        this.totalPages = Math.ceil(this.totalElements / pageSize);
        this.$emit('onGetLogsFinished');
        return {
          data: response.data.content,
          totalCount: this.totalElements
        };
      } catch (error) {
        this.onError(error);
      }
    },
    onError(error) {
      this.$emit('onGetLogsFailed', error);
    },
    getGridInstance() {
      return this.$refs.activityLogsPageableGrid.instance;
    },
    refreshGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.refresh();
    },
  }
};
</script>
