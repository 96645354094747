<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0">
    <v-row>
      <v-col md="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            v-model="primaryPhone"
            :disabled="disabled"
            :rules="[rules.required, rules.phoneNumber]"
            label="* Cell Phone"
            data-id="customer-form-primary-phone"
            v-facade="phoneNumberMask"
            ref="primaryPhone"
            autocomplete="new-customer-primary-phone"
            @input="validateIsNewPhone(primaryPhone)"
            @paste="onPaste"
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            v-model="alternatePhone"
            :rules="[rules.phoneNumber]"
            label="Alt Phone"
            ref="alternatePhone"
            :disabled="disabled"
            data-id="customer-form-alternate-phone"
            v-facade="phoneNumberMask"
            autocomplete="new-customer-alt-phone"
        />
      </v-col>
    </v-row>

    <div v-if="showPhoneOwnerBlock">
      <v-row v-if="showNonUniquePhoneAlert" class="ma-1">
        <div v-show="showBlock">
          <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
              prominent
          >
            <v-row class="pa-4">
              {{ ownershipText }}
            </v-row>
          </v-alert>
        </div>
      </v-row>

      <v-row class="mb-3 mt-3 ml-1" v-if="showPhoneOwnerOptions">
        <radio-buttons
            ref="phoneOwnershipRadioButtons"
            :value="isPhoneOwner"
            :selectOptions="phoneOwnerOptions"
            :inline="true"
            :required="true"
            @onValueChanged="phoneOwnershipChanged"
        />
      </v-row>
    </div>

    <v-row>
      <v-col md="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            v-model="firstName"
            :rules="[rules.required, rules.max40]"
            maxlength="40"
            label="* First Name"
            :disabled="disabled"
            data-id="customer-form-first-name"
            autocomplete="new-customer-first-name"
            counter
        />
      </v-col>
      <v-col md="6">
        <v-text-field
            placeholder=" "
            persistent-placeholder
            v-model="lastName"
            :rules="[rules.required, rules.max40]"
            maxlength="40"
            label="* Last Name"
            :disabled="disabled"
            data-id="customer-form-last-name"
            autocomplete="new-customer-last-name"
            counter
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" class="pb-0">
        <v-text-field
            ref="customerEmail"
            placeholder=" "
            persistent-placeholder
            v-model="email"
            :rules="[rules.emailRequired, rules.email]"
            :label="emailLabel"
            :disabled="disabled && validEmailPresent"
            data-id="customer-form-email"
            autocomplete="new-customer-email"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="pb-7 pt-0">
        <h4 class="text-left">Customer Mailing Address</h4>
      </v-col>
    </v-row>

    <v-row class="pb-5 pt-3">
      <address-form-with-autocomplete
          ref="addressForm"
          :addressData="addressData"
          :disabled="disabled"
      />
    </v-row>
  </v-container>
</template>

<script>
import AddressFormWithAutocomplete from "../common/form/AddressFormWithAutocomplete.vue";
import Constants from "../../assets/constants";
import PhoneMask from "../../assets/phoneMask";
import Rules from "../../assets/rules";
import Customers from "../../rest/customers";
import RadioButtons from "@/components/common/form/RadioButtons.vue";
import FormattingHelper from "@/assets/formattingHelper";
import ValidationHelper from "@/assets/validationHelper";

const formDefaults = {
  addressData: null,
  primaryPhone: null,
  alternatePhone: null,
  firstName: null,
  lastName: null,
  email: null,
  id: null,
  phoneNumberMask: Constants.phoneNumberMask,
  showNonUniquePhoneAlert: false,
  isPhoneOwner: null,
  phoneOwnerOptions: Constants.phoneOwnerOptions,
  notOwnerText: "This cell phone number is already associated with another customer in the system. " +
      "Choosing 'Not Owner' for this customer will only affect their phone number status. " +
      "All other customers ownership will remain unchanged.",
  ownerText: "This cell phone number is already associated with another customer in the system. " +
      "If you select 'Owner', the current cell phone owner (if it is present) will be set to 'Not Owner'.",
  noOwnershipSelectedText: "This cell phone number is already associated with another customer in the system. " +
      "Please select the status of cell phone number ownership for new customer.",
  theOnlyOneOwnerText: "This phone number is associated only with this customer in the system. " +
      "Selecting 'Not Owner' will keep the number with this customer but mark them as a non-owner.",
  showBlock: true,
  initialPhoneOwnership: false,
  initialPhoneNumber: null,
  isUniqueOwner: false
};

export default {
  components: {
    RadioButtons,
    AddressFormWithAutocomplete
  },

  props: {
    customer: {
      type: Object,
      required: false
    },
    disabled: Boolean,
    validEmailPresent: Boolean,
    emailRequired: Boolean,
    isCreate: Boolean,
    isPhoneOwnerBlockHidden: {
      type: Boolean,
      required: false
    }
  },

  watch: {
    customer: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.checkPhoneOwnership(newVal);
          this.initialPhoneNumber = newVal.primaryPhone;
        }
        this.loadForm(newVal);
      }
    }
  },

  data() {
    return Object.assign(
        {
          rules: {
            required: Rules.required,
            emailRequired: value => {
              if (this.emailRequired || this.isCreate || (!(this.customer) && this.isCreate)) {
                return !!value || "Required";
              } else {
                return true;
              }
            },
            alphabeticOnly: Rules.alphabeticOnly,
            phoneNumber: Rules.phoneNumberWithDelimiter,
            email: Rules.email,
            max40: value => Rules.maxValue(value, 40)
          }
        },
        formDefaults
    );
  },

  computed: {
    emailLabel() {
      return this.emailRequired || this.isCreate || (!(this.customer) && this.isCreate) ? '* Email' : 'Email';
    },
    showPhoneOwnerOptions() {
      return this.primaryPhone?.length === Constants.validPhoneLengthWithoutHyphens || this.showNonUniquePhoneAlert;
    },
    ownershipText() {
      if (this.isPhoneOwner === null) {
        return this.noOwnershipSelectedText;
      } else if (this.isUniqueOwner) {
        return this.theOnlyOneOwnerText;
      } else return this.isPhoneOwner ? this.ownerText : this.notOwnerText;
    },
    showPhoneOwnerBlock() {
      return !this.isPhoneOwnerBlockHidden && this.showNonUniquePhoneAlert;
    }
  },

  methods: {
    async validateIsNewPhone(val) {
      let digitsOnly = FormattingHelper.formatCellPhoneWithoutHyphens(val);

      // edit form: set default ownership for customer even if the same phone entered or copied in the text field
      if (!this.isCreate && this.isEnteredPrimaryPhoneTheSameAsOriginal(digitsOnly)) {
        this.isPhoneOwner = this.customer.isPrimaryPhoneOwner;
        this.showNonUniquePhoneAlert = true;
        return;
      }

      if (digitsOnly.length === Constants.validPhoneLengthDigitsOnly) {
        const isInUse = await this.checkPhoneUniqueness(digitsOnly);
        this.showNonUniquePhoneAlert = isInUse;
        if (!isInUse) {
          this.phoneOwnershipChanged(true);
        } else {
          this.phoneOwnershipChanged(null);
        }
      } else {
        this.showNonUniquePhoneAlert = false;
        this.phoneOwnershipChanged(false);
      }
    },
    async checkPhoneUniqueness(primaryPhone) {
      try {
        const response = await Customers.getRestApi().checkIfCustomerPhoneNumberIsPresent(primaryPhone);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    buildPayload() {
      const payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      payload.primaryPhone = PhoneMask.unMaskPhone(this.primaryPhone);
      payload.alternatePhone = PhoneMask.unMaskPhone(this.alternatePhone);
      payload.firstName = this.firstName;
      payload.lastName = this.lastName;
      payload.email = this.email;
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      payload.isPhoneOwner = this.isPhoneOwner;
      return payload;
    },
    async loadForm(payload) {
      if (!payload) {
        payload = formDefaults;
      }
      this.addressData = this.setAddressDataObject(payload);
      this.primaryPhone = payload.primaryPhone;
      this.alternatePhone = payload.alternatePhone;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.email = payload.email;
      this.id = payload.id;
      this.initialPhoneOwnership = payload.isPrimaryPhoneOwner;
      this.phoneOwnershipChanged(payload.isPrimaryPhoneOwner);
      await this.setUniquePhoneOwner(payload);
      this.showNonUniquePhoneAlert = payload.showNonUniquePhoneAlert || (!this.isCreate && !payload.isPrimaryPhoneOwner)
          || payload.isPrimaryPhoneOwner;
    },
    setAddressDataObject(payload) {
      return {
        address: payload.address,
        city: payload.city,
        state: payload.state,
        zipCode: payload.zipCode
      }
    },
    setPhone(phone) {
      this.primaryPhone = phone || "";
    },
    phoneOwnershipChanged(value) {
      if (this.initialPhoneOwnership && !this.isCreate) {
        this.checkPhoneOwnership(value);
      }
      this.isPhoneOwner = value;
    },
    setFormBackToDefault() {
      this.loadForm(formDefaults);
    },
    onPaste(event) {
      let clipped = event.clipboardData.getData('text').split("\n");
      clipped.forEach(item => {
        let copyPastedDigitsOnly = FormattingHelper.formatCellPhoneWithoutHyphens(item);
        if (!this.isCreate && this.isEnteredPrimaryPhoneTheSameAsOriginal(copyPastedDigitsOnly)) {
          return;
        }
        let isValidPhone = ValidationHelper.isValidPhoneNumber(copyPastedDigitsOnly);
        if (isValidPhone) {
          this.validateIsNewPhone(copyPastedDigitsOnly);
        }
      })
    },
    isEnteredPrimaryPhoneTheSameAsOriginal(digitsOnly) {
      return this.customer?.primaryPhone === digitsOnly
          && digitsOnly === FormattingHelper.formatCellPhoneWithoutHyphens(this.primaryPhone);
    },
    checkPhoneOwnership(newOwnershipValue) {
      if (!this.isCreate) {
        if (this.primaryPhone) {
          if (this.initialPhoneNumber === FormattingHelper.formatCellPhoneWithoutHyphens(this.primaryPhone)) {
            if (newOwnershipValue === undefined || this.initialPhoneOwnership === newOwnershipValue) {
              this.showBlock = false;
            } else {
              this.showBlock = this.initialPhoneOwnership !== newOwnershipValue;
            }
          } else {
            this.showBlock = true;
          }
        }
      }
    },
    async setUniquePhoneOwner(payload) {
      if (!this.isCreate && this.initialPhoneOwnership) {
        const customers = await this.getAllCustomersByPrimaryPhone(payload.primaryPhone);
        if (customers && customers.length === 1) {
          this.isUniqueOwner = true;
        }
      } else {
        this.isUniqueOwner = false;
      }
    },
    async getAllCustomersByPrimaryPhone(phone) {
      try {
        const response = await Customers.getRestApi().findCustomersByPrimaryPhoneNumber(phone, true);
        return response.data;
      } catch (error) {
        throw new error;
      }
    }
  }
};
</script>
