<template>
  <div class="separator pa-2">
    <table class="line-item-headers caption">
      <tr>
        <td style="width: 10%">Reference Id</td>
        <td style="width: 20%">Type</td>
        <td style="width: 10%"></td>
        <td style="width: 20%">Description</td>
        <td style="width: 10%">Payment ID</td>
        <td style="width: 10%">Plan Code</td>
        <td style="width: 15%">Amount paid by customer</td>
        <td style="width: 15%">Amount received by 50Floor</td>
        <td style="width: 30px"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  components: {},

  props: [],

  data() {
    return {};
  },

  methods: {}
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
</style>
