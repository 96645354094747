import DateFormatter from "@/assets/dateFormatter";
import {DateTime, Duration} from 'luxon';

const YYYY_MM_DD_WITH_SLASHES_REGEX = /^\d{4}\/\d{2}\/\d{2}$/;
const dateStringLength = '2023-08-22'.length;

const DateUtils = {
    /*
    * Compare a passed date with the current date in UTC.
    * To omit timezones offsets, we set the timezone to UTC for the passed date (keepLocalTime "true" flag is required).
    * */
    isPastDate(date) {
        return this.buildDateTime(date).setZone('utc', {keepLocalTime: true}).startOf('day')
            .diff(DateTime.now().setZone('utc').startOf('day')).as('days') < 0;
    },
    getStartOfWeek(date) {
        const dateTime = this.buildDateTime(date);
        return dateTime.startOf('week', {week: 'iso'}).toJSDate();
    },
    getEndOfWeek(date) {
        const dateTime = this.buildDateTime(date);
        return dateTime.endOf('week', {week: 'iso'}).toJSDate();
    },
    isDateBetween(date, start, end) {
        const testDate = this.buildDateTime(date).setZone('utc');
        const startDate = this.buildDateTime(start).setZone('utc');
        const endDate = this.buildDateTime(end).setZone('utc');
        return testDate >= startDate.startOf('day') && testDate <= endDate.endOf('day');
    },
    /*
    * Removes time from Date object and compare just String dates
    * */
    isSameDate(date1, date2) {
        return DateFormatter.formatDateInUtcAndYYYYMMDDFormat(date1) === DateFormatter.formatDateInUtcAndYYYYMMDDFormat(date2);
    },
    isSameOrAfter(date1, date2) {
        return new Date(DateFormatter.formatDateInUtcAndYYYYMMDDFormat(date1)) >= new Date(DateFormatter.formatDateInUtcAndYYYYMMDDFormat(date2));
    },

    getDatesBetween: function (startDate, endDate) {
        const result = [];
        let currentDate = new Date(startDate);
        while (currentDate <= new Date(endDate)) {
            const formattedDate = DateFormatter.formatDateInUtcAndYYYYMMDDFormat(currentDate);
            result.push(formattedDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return result;
    },
    dateToDateTime(date) {
        if (date instanceof Date) {
            return DateTime.fromJSDate(date);
        } else {
            return DateTime.fromISO(date);
        }
    },

    buildDateTime: function (date) {
        if (date instanceof DateTime){
            return date;
        } else if (date instanceof Date) {
            return DateTime.fromJSDate(date);
        } else if (typeof date === 'string') {
            return this.stringToDateTime(date);
        }
    },
    stringToDateTime: function (date, format) {
        let result;
        if (format) {
            result = DateTime.fromFormat(date, format);
        } else {
            if (YYYY_MM_DD_WITH_SLASHES_REGEX.test(date)) {
                result = DateTime.fromFormat(date, 'y/MM/dd');
            } else {
                result = DateTime.fromISO(date);
            }
        }
        return result;
    },
    isFutureDate(date) {
        let installationDate = DateFormatter.getStartOfTheDayFromDate(date);
        let now = DateFormatter.getStartOfTheDayFromDate(new Date());
        return now < installationDate;
    },
    allowedTodayAndFutureDates(value) {
        const inputDate = DateTime.fromISO(value).startOf('day');
        const today = DateTime.now().startOf('day');
        return inputDate >= today;
    },
    allowedTodayAndFutureDatesAndExcludeAlreadySelected(value, alreadySelectedDates) {
        const inputDate = DateTime.fromISO(value).startOf('day');
        const today = DateTime.now().startOf('day');
        return !alreadySelectedDates.includes(value) && inputDate >= today;
    },
    convertToWeekDateName(arg) {
        const clickedDate = DateTime.fromJSDate(new Date(arg.date));
        return clickedDate.toFormat('cccc');
    },
    formatTimeBlockName(startTime, endTime) {
        const start = DateTime.fromFormat(startTime, "HH:mm:ss");
        const end = DateTime.fromFormat(endTime, "HH:mm:ss");
        return start.toFormat("h:mm a") + " - " + end.toFormat("h:mm a");
    },

    //method to check if the date is in the future according to the market timezone and current user date
    isFutureDateInTimezone(date, time, marketZone) {
        const dateTime = this.buildDateTime(date + "T" + time).setZone(marketZone, {keepLocalTime: true});
        const now = DateTime.now().setZone(marketZone);
        return dateTime > now;
    },
    isFutureOrSameDateInTimezone(date, time, marketZone) {
        const dateTime = this.buildDateTime(date + "T" + time).setZone(marketZone, {keepLocalTime: true});
        const now = DateTime.now().setZone(marketZone);
        return dateTime >= now;
    },
    isSameDayInTimezone(date, marketZone) {
        const dateTime = this.buildDateTime(date).setZone(marketZone, {keepLocalTime: true});
        const now = DateTime.now().setZone(marketZone);
        return dateTime.hasSame(now, 'day');
    },
    getDuration(milliseconds) {
        return Duration.fromMillis(milliseconds);
    },
    convertToLocalDateTime(dateTimeIsoString) {
        return DateTime.fromISO(dateTimeIsoString, { zone: "utc" })
            .toLocal();
    }
}

export default DateUtils;
