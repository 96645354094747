<template>
  <div>
    <custom-icon
        :icon="icon"
        :showFullName="false"
    />
  </div>
</template>

<script>
import CustomIcon from "@/components/common/icons/CustomIcon.vue";
import IconDefineHelper from "@/assets/iconDefineHelper";

export default {
  components: {CustomIcon},

  props: {
    cellData: {
      type: Object,
      required: false
    }
  },

  computed: {
    icon() {
      return IconDefineHelper.defineMfaOption(this.cellData);
    }

  }

};
</script>
