<template>
  <grid-view
      ref="nav"
      page-title="List Inventory In Transfer"
      nav-section="warehouse"
      :data-source="dataSource"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      no-data-text="Inventory In Transfer not found"
      @rowSelected="onRowSelected"
  >
    <!--    <dx-column-->
    <!--        data-field="CreatedAt"-->
    <!--        caption="Creation Date"-->
    <!--        data-type="date"-->
    <!--        format="MM/dd/yyyy"-->
    <!--        :allowHeaderFiltering="false"     -->
    <!--        sort-order="desc"-->
    <!--    />-->
    <dx-column
        data-field="TransferFromBranch"
        caption="Transfer From"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="TransferToBranch"
        caption="Transfer To"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ShipmentNumber"
        caption="Shipment Number"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="EstimatedDeliveryDate"
        caption="Estimated Delivery Date"
        format="MM/dd/yyyy"
        data-type="date"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="InternalSku"
        caption="Internal SKU"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Vendor"
        caption="Vendor"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ProductType"
        caption="Product Type"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Style"
        caption="Style"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Color"
        caption="Color"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="VendorSku"
        caption="Vendor Sku"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="RollNumber"
        caption="Roll Number"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="DyeLot"
        caption="Dye Lot"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="UnitOfMeasure"
        caption="Unit of Measure"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="QuantityInTransfer"
        caption="Quantity in Transfer"
        data-type="number"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="VendorCost"
        caption="Vendor Cost"
        data-type="number"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="TotalValue"
        caption="Total Value"
        data-type="number"
        :allowHeaderFiltering="false"
    />

  </grid-view>
</template>

<script>
import GridView from "../../common/GridView.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import OdataContext from "../../../rest/odataContext";

export default {
  components: {
    GridView,
    DxColumn,
  },

  data() {
    return {
      userBranches: [],
      dataSource: {
        store: OdataContext['WebInventoryInTransfers'],
        select: [
          "Id",
          "CreatedAt",
          "TransferFromBranch",
          "TransferToBranch",
          "ShipmentNumber",
          "EstimatedDeliveryDate",
          "InternalSku",
          "Vendor",
          "ProductType",
          "Style",
          "Color",
          "VendorSku",
          "RollNumber",
          "DyeLot",
          "UnitOfMeasure",
          "QuantityInTransfer",
          "VendorCost",
          "TotalValue",
        ],
        sort: [{selector: "CreatedAt", desc: true}]
      }
    };
  },

  computed: {},

  methods: {
    onRowSelected(data) {
      this.$router.push(`/inventory/in-transfer/details/${data.Id}`);
    },
  },
};
</script>
