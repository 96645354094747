<template>
  <div v-if="showActionButtons" class="buttonsContainer">
    <v-btn
        dark
        :class="getPrimaryButtonClass"
        color="primary"
        @click="onButtonClick"
    >
      {{ label }}
    </v-btn>
    <v-btn
        dark
        v-if="secondaryLabel"
        color="primary"
        :class="secondaryLabel
          ? 'multiple-buttons-secondary'
          : 'single-button ma-0 pa-0'"
        @click="onSecondaryButtonClick"
    >
      {{ secondaryLabel }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    secondaryLabel: {
      type: String
    },
    showActionButtons: {
      type: Boolean,
      required: true,
      default: false
    },
  },

  computed: {
    getPrimaryButtonClass() {
      let classes = "";
      if (this.secondaryLabel) {
        classes = "multiple-buttons-primary";
      } else {
        classes = "single-button ma-0 pa-0 detail-action-button";
      }
      return classes;
    }
  },

  methods: {
    onButtonClick() {
      this.$emit('click');
    },
    onSecondaryButtonClick() {
      this.$emit('secondaryButtonClick');
    }
  }
};
</script>

<style scoped>
.buttonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.single-button {
  height: 100% !important;
  font-size: 18px;
  border-radius: 0;
  box-shadow: none !important;
}
.multiple-buttons-primary,
.multiple-buttons-secondary {
  flex-grow: 1;
  flex-basis: 0;
  height: 100% !important;
  font-size: 14px;
  margin: 0 1px;
  border-radius: 0;
  box-shadow: none !important;
}
.multiple-buttons-primary {
  border-right: 1px solid white !important;
  margin-right: 1px;
  margin-left: 1px;
}
.detail-action-button {
  width: 100%;
  height: 48px !important;
}
</style>
