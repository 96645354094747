<template>
  <v-btn
      v-if="showCloseButton"
      class="close-tr-btn"
      text
      color="primary"
      @click="closeTransferRequestItemClicked"
  >
    Close
  </v-btn>
</template>

<script>

import Roles from "@/assets/roles";

export default {
  props: {
    cellData: Object,
    transferRequestStatus: String
  },

  data() {
    return {};
  },

  computed: {
    showCloseButton() {
      return Roles.isSuperAdminOrAdminUser() && this.cellData.data.canBeClosed;
    }
  },

  methods: {
    closeTransferRequestItemClicked() {
      this.$emit('onClickCloseTRItem', this.cellData);
    },
  }
};
</script>

<style scoped>
.close-tr-btn {
  border: 1px rgb(102, 153, 187) solid;
  border-radius: 3px;
  font-size: 14px;
}
</style>
