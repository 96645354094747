import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

export class ProductSubType {
  id: number;
  parentId: number;
  name: string;
  description: string;

  constructor(id: number, parentId: number, name: string, description: string) {
    this.id = id;
    this.parentId = parentId
    this.name = name;
    this.description = description;
  }
}

/**
 * REST interface provided for product types.
 */
export interface ProductSubTypeRestApi {
  createProductSubType(payload: ProductSubType): AxiosPromise<ProductSubType>;
  updateProductSubType(id: number, updated: ProductSubType): AxiosPromise<ProductSubType>;
  getAllProductSubTypes(): AxiosPromise<ProductSubType>;
}

/**
 * REST implementation provided for product types.
 */
class Implementation implements ProductSubTypeRestApi {
  createProductSubType(payload: ProductSubType): AxiosPromise<ProductSubType> {
    return Axios.axiosInstance().post<ProductSubType>('/product_sub_types', payload);
  }
  updateProductSubType(id: number, updated: ProductSubType): AxiosPromise<ProductSubType> {
    return Axios.axiosInstance().put<ProductSubType>(`/product_sub_types/${id}`, updated);
  }
  getAllProductSubTypes(): AxiosPromise<ProductSubType> {
    return Axios.axiosInstance().get<ProductSubType>('/product_sub_types/');
  }
}

const ProductSubTypes = {
  getRestApi(): ProductSubTypeRestApi {
    return new Implementation();
  },
};

export default ProductSubTypes;
