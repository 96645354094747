import Axios from ".././axiosInstance";
import {AxiosPromise} from "axios";
import * as AxiosUtils from "../util/axiosUtils";

export interface SlotCapabilitySummaryDTO {
    id: number;
    slotDate: string;
    installerCrewId: number;
    installerCrewName: string;
    status: string;
}
export interface SlotSummaryDTO {
    slotDate: string;
    totalSlots: number;
    scheduledSlots: number;
}

export interface InstallerCrewSlotDTO {
    id: number;
    installerCrewId: number;
    installerCrewName: string;
    status: string;
}

export interface InstallerCrewExtraSlotRequest {
    branchId: number;
    installerCrewId: number;
    slotDate: string;
}

export interface InstallerSlotApi {
    deleteSlot(id: number, wsSessionId: string): AxiosPromise<void>;

    getSlotsByCapability(branchId: number, from: string, to: string): AxiosPromise<[SlotCapabilitySummaryDTO]>;

    getSlotsSummary(branchId: number, from: string, to: string): AxiosPromise<[SlotSummaryDTO]>;

    getSlotsByDateAndCapabilityId(branchId: number, slotDate: string, capabilityId: number): AxiosPromise<[InstallerCrewSlotDTO]>;

    getAvailableDatesByCapabilityId(branchId: number, capabilityId: number): AxiosPromise<[Date]>;

    createExtraSlot(payload: InstallerCrewExtraSlotRequest, wsSessionId: string): AxiosPromise<void>;
}

class Implementation implements InstallerSlotApi {

    deleteSlot(id: number, wsSessionId: string): AxiosPromise<void> {
        const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
        return Axios.axiosInstance().delete<void>(`/installer-crew/slot?id=${id}`, config);
    }

    getSlotsByCapability(branchId: number, from: string, to: string): AxiosPromise<[SlotCapabilitySummaryDTO]> {
        return Axios.axiosInstance().get<[SlotCapabilitySummaryDTO]>(`/installer-crew/slot/summary/by-capability?branchId=${branchId}&from=${from}&to=${to}`);
    }

    getSlotsSummary(branchId: number, from: string, to: string): AxiosPromise<[SlotSummaryDTO]> {
        return Axios.axiosInstance().get<[SlotSummaryDTO]>(`/installer-crew/slot/summary?branchId=${branchId}&from=${from}&to=${to}`);
    }

    getSlotsByDateAndCapabilityId(branchId: number, slotDate: string, capabilityId: number): AxiosPromise<[InstallerCrewSlotDTO]> {
        return Axios.axiosInstance().get<[InstallerCrewSlotDTO]>(`/installer-crew/slot/details?branchId=${branchId}&slotDate=${slotDate}&capabilityId=${capabilityId}`);
    }

    getAvailableDatesByCapabilityId(branchId: number, capabilityId: number): AxiosPromise<[Date]> {
        return Axios.axiosInstance().get<[Date]>(`/installer-crew/slot/available-dates?branchId=${branchId}&capabilityId=${capabilityId}`);
    }

    createExtraSlot(payload: InstallerCrewExtraSlotRequest, wsSessionId: string): AxiosPromise<void> {
        const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
        return Axios.axiosInstance().post<void>(`/installer-crew/slot/extra`, payload, config);
    }

}

const InstallerSlots = {
    getRestApi(): InstallerSlotApi {
        return new Implementation();
    }
};

export default InstallerSlots;
