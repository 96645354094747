import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "@/rest/interfaces/activityLogs";

/**
 * Market.
 */
export class Market {
  id: number;
  referenceId: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  branchId: string;
  status: boolean;
  isALAEnabled: boolean;
  timezoneName: string;

  constructor(
    id: number,
    referenceId: string,
    name: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
    branchId: string,
    status: boolean,
    isALAEnabled: boolean,
    timezoneName: string
  ) {
    this.id = id;
    this.referenceId = referenceId;
    this.name = name;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.branchId = branchId;
    this.status = status;
    this.isALAEnabled = isALAEnabled;
    this.timezoneName = timezoneName;
  }
}

export class MarketZipCode {
  id: number;
  zipCode: string;
  active: boolean;
  marketId: string;
  marketReferenceId: string;
  automaticLeadAssignmentEnabled: boolean;

  constructor(
      id: number,
      zipCode: string,
      active: boolean,
      marketId: string,
      marketReferenceId: string,
      automaticLeadAssignmentEnabled: boolean,
  ) {
    this.id = id;
    this.zipCode = zipCode;
    this.active = active;
    this.marketId = marketId;
    this.marketReferenceId = marketReferenceId;
    this.automaticLeadAssignmentEnabled = automaticLeadAssignmentEnabled;
  }
}

/**
 * REST interface.
 */
export interface MarketsRestApi {
  getAllMarkets(): AxiosPromise<Market[]>;
  getAllActiveMarkets(): AxiosPromise<Market[]>;
  getAllMarketsFilteredByBranch(): AxiosPromise<Market[]>;

  getMarketByZipCode(zipCode: string): AxiosPromise<MarketZipCode>;
  getMarket(refId: string): AxiosPromise<Market>;
  checkAccessToMarketByZipCode(zipCode: string): AxiosPromise<boolean>;
  createMarket(payload: Market): AxiosPromise<Market>;
  updateMarket(refId: string, updated: Market): AxiosPromise<Market>;
  getMarketLogs(refId: string): AxiosPromise<ActivityLog[]>;
}

/**
 * REST implementation.
 */
class Implementation implements MarketsRestApi {
  getAllMarkets(): AxiosPromise<Market[]> {
    return Axios.axiosInstance().get<Market[]>(`markets/search/all`);
  }
  getAllActiveMarkets(): AxiosPromise<Market[]> {
    return Axios.axiosInstance().get<Market[]>(`markets/search/active/all`);
  }
  getAllMarketsFilteredByBranch(): AxiosPromise<Market[]> {
    return Axios.axiosInstance().get<Market[]>(`markets/search/branch-filtered`);
  }

  getMarketByZipCode(zipCode: string): AxiosPromise<MarketZipCode> {
    return Axios.axiosInstance().get<MarketZipCode>(`/markets/zip_code/${zipCode}`);
  }
  getMarket(refId: string): AxiosPromise<Market> {
    return Axios.axiosInstance().get<Market>(`/markets/${refId}`);
  }
  checkAccessToMarketByZipCode(zipCode: string): AxiosPromise<boolean> {
    return Axios.axiosInstance().get<boolean>(`/markets/check-access/${zipCode}`)
  }
  createMarket(payload: Market): AxiosPromise<Market> {
    return Axios.axiosInstance().post<Market>(`/markets`, payload);
  }
  updateMarket(refId: string, updated: Market): AxiosPromise<Market> {
    return Axios.axiosInstance().put<Market>(`/markets/${refId}`, updated);
  }
  getMarketLogs(refId: string): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/markets/${refId}/logs`);
  }
}

const Markets = {
  getRestApi(): MarketsRestApi {
    return new Implementation();
  }
};

export default Markets;
