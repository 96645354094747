<template>
  <v-card flat>
    <table class="line-items">
      <tr>
        <td width="20%" class="text-truncate">
          {{ purchaseOrderEntry.lineItem.referenceId }}
        </td>
        <td width="50%" class="text-truncate">
          {{ purchaseOrderEntry.lineItem.productCatalogItem.styleName }}
        </td>
        <td width="20%" class="text-truncate">
          {{ purchaseOrderEntry.lineItem.productCatalogItem.color }}
        </td>
        <td width="10%" class="text-truncate">
          {{ purchaseOrderEntry.quantity }}
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
export default {
  components: {},

  props: {
    purchaseOrderEntry: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  }
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
</style>
