<template>
  <div style="padding-left: 10px">
    <v-row>
      <v-col md="6" class="textFields pt-8">
        <v-text-field
            label="Selected Vendor"
            :value="selectedVendor.name"
            readonly
            disabled
        />
      </v-col>

      <v-col md="6" class="textFields  pt-8">
        <v-text-field
            label="Selected Branch"
            :value="selectedBranch.name"
            readonly
            disabled
        />
      </v-col>

      <v-col md="12" class="ma-1">
        <table class="line-item-headers caption">
          <tr>
            <td width="60%" class="text-sm-left">Item Information</td>
            <td width="25%">Ordered</td>
            <td width="30%">Price</td>
            <td width="10px"></td>
          </tr>
        </table>

        <v-divider/>
      </v-col>

      <v-row class="line-items-wrapper ma-2 mb-6">
        <purchase-order-line-items
            class="ma-1"
            v-for="(item, index) in selectedItems"
            :key="index"
            :index="index"
            :line-item="item"
            :readonly="true"
        />
      </v-row>
    </v-row>
  </div>
</template>

<script>
import PurchaseOrderLineItems from "../../../components/purchaseOrders/bulkPurchaseOrder/LineItems.vue";

export default {
  components: {
    PurchaseOrderLineItems
  },

  props: {
    error: {
      type: String | null,
      required: false,
      default: null
    },
    selectedItems: {
      type: Array | null,
      required: false,
      default: () => []
    },
    selectedVendor: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    selectedBranch: {
      type: String | Object,
      required: false,
      default: ""
    }

  },

  watch: {
    selectedItems: {
      handler(value) {
      },
      deep: true
    }
  },

  methods: {
  }
};
</script>

<style scoped>
.stepError {
    color: red;
    text-align: right;
    height: 20px;
    padding-top: 5px;
}

.textFields {
    padding-top: 16px;
}

.line-items-wrapper {
  flex-direction: column;
}
</style>
