<template>
  <drawer-form
      ref="leadScheduleDrawerForm"
      :title="title"
      formId="leadScheduleDrawerForm"
      :submitLabel="submitLabel"
      :inProgress="inProgress"
      :isLoading="isLoading"
      :hideSubmit="nonServiceable"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <v-container grid-list-lg text-xs-center>
      <v-skeleton-loader
          v-if="isLoading"
          :loading="isLoading"
          type="form, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item, list-item,
          list-item, list-item, list-item-two-line"
      />
      <lead-schedule-form-editable
          v-show="!isLoading"
          ref="leadScheduleForm"
          :formType="formType"
          :customer="customer"
          :marketId="marketId"
          :sales-rep-name="salesRepName"
          :promo-code-name="promoCodeName"
          :assignedSalesRep="assignedSalesRep"
          :disabled="nonServiceable"
          :isSameDayAllowed="isSameDayAllowed"
          :marketRefId="marketRefId"
          @formLoaded="onFormLoaded"
          @onError="onError"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm";
import LeadScheduleFormEditable from "../../components/leads/LeadScheduleFormEditable";
import DatePickerHelper from "../../assets/datePickerHelper";

export default {
  components: {
    DrawerForm,
    LeadScheduleFormEditable,
  },

  props: {
    salesRepName: String,
    promoCodeName: String,
    title: String,
    submitLabel: String,
    customer: Object,
    nonServiceable: Boolean,
    marketId: Number,
    marketRefId: String,
    formType: String,
    inProgress: Boolean,
    disabledDrawer: Boolean,
    assignedSalesRep: Object,
    isSameDayAllowed: Boolean
  },

  data() {
    return {
      isLoading: true
    };
  },

  methods: {
    resetForm() {
      this.$refs.leadScheduleDrawerForm.resetValidation();
      this.$refs.leadScheduleForm.reset();
      this.$refs.leadScheduleForm.setFormBackToDefault();
    },
    onCancelClicked() {
      this.$emit('cancelClicked');
    },
    onSubmitClicked() {
      this.$refs.leadScheduleForm.checkIfSalesRepRequired();
      let valid = this.$refs.leadScheduleDrawerForm.validate();
      if (valid) {
        const payload = this.$refs.leadScheduleForm.buildPayload();
        if (payload.newDate) {
          payload.appointmentDate = DatePickerHelper.addUtcOffset12Hours(payload.appointmentDate);
        }
        this.$emit('submitClicked', payload);
      }
    },
    loadDrawerForm(data, leadRooms, leadProducts) {
      this.isLoading = true;
      this.$refs.leadScheduleForm.reset();
      this.$refs.leadScheduleForm.loadForm(data, leadRooms, leadProducts);
    },
    setFormBackToOriginalData(data, leadRooms, leadProducts) {
      this.$refs.leadScheduleForm.setFormBackToOriginalData(data, leadRooms, leadProducts);
    },
    onError(error) {
      this.$emit('onError', error);
    },
    onFormLoaded() {
      this.isLoading = false;
    }
  }
};
</script>
