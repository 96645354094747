<template>
  <modal-dialog
      ref="dialog"
      title="Reset GPL Calculation"
      width="500"
      :visible="dialogVisible"
      :submitEnabled="true"
      submitLabel="Reset"
      cancelLabel="Cancel"
      @submitClicked="onResetClicked"
      @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <slot>
          You are going to reset GPL calculation for {{ salesRepFullName }}. Are you sure?
        </slot>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../components/common/ModalDialog.vue";
import SalesRepresentatives from "@/rest/salesRepresentatives";

export default {
  data: () => ({
    dialogVisible: false,
    salesRepFullName: '',
    error: null
  }),

  components: {
    ModalDialog
  },

  methods: {
    openDialog(salesRep) {
      this.salesRepFullName = this.getFullName(salesRep);
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.salesRepFullName = '';
    },
    showError(error) {
      this.error = error;
    },
    onResetClicked() {
      this.dialogVisible = false;
      this.salesRepFullName = '';
      this.$emit("resetGPL");
    },
    onCancelClicked() {
      this.dialogVisible = false;
      this.cancelClicked = false;
      this.salesRepFullName = '';
    },
    getFullName(salesRep) {
      if (salesRep) {
        return salesRep.firstName + ' ' + salesRep.lastName;
      } else {
        return '';
      }
    }
  }
};
</script>
