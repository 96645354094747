<template>
  <v-col md="12" class="pt-0 mt-0 mb-1">
    <v-select
        @change="onActionSelect"
        placeholder="Select"
        v-model="selected"
        :items="this.$props.dayOff.status === 'WAITING FOR DELETION' ? onDeleteChoices : statusChoices"
        attach
    >
    </v-select>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      statusChoices: [
        {text: "Approve ", value: 'confirm'},
        {text: "Decline ", value: 'decline'}
      ],
      onDeleteChoices: [{text: "Delete ", value: 'decline'}]
    };
  },

  props: {
    dayOff: null
  },

  watch: {
    value: {
      immediate: true,
      handler: function(value) {
        this.$data.selected = value;
      }
    },
    selected: function(value) {
      this.$emit("onStatusChange", value ,this.$props.dayOff.id);
    }
  },
  methods:{
    onActionSelect: function(value) {
       this.$emit("changeRequestStatus", this.$props.dayOff.id, value);
    },
  }
};
</script>
