import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * TransferRequest.
 */
export class TransferRequest {
    transferNumber: string;
    status: string;
    closestInstallationDate: Date;
    pickUpDate: Date;
    estimatedDeliveryDate: Date;
    closedDate: Date;
    transferFrom: number;
    transferFromBranch: string;
    transferTo: number;
    transferToBranch: string;
    isClosed: boolean;
    jobReferenceId: string;

    constructor(transferNumber: string, status: string, closestInstallationDate: Date, pickUpDate: Date,
                estimatedDeliveryDate: Date, closedDate: Date, transferFrom: number, transferFromBranch: string,
                transferTo: number, transferToBranch: string, isClosed: boolean, jobReferenceId: string) {
        this.transferNumber = transferNumber;
        this.status = status;
        this.closestInstallationDate = closestInstallationDate;
        this.pickUpDate = pickUpDate;
        this.estimatedDeliveryDate = estimatedDeliveryDate;
        this.closedDate = closedDate;
        this.transferFrom = transferFrom;
        this.transferFromBranch = transferFromBranch;
        this.transferTo = transferTo;
        this.transferToBranch = transferToBranch;
        this.isClosed = isClosed;
        this.jobReferenceId = jobReferenceId;
    }
}

export class BranchFulfillmentPlanRequest {
    fromBranch: string;
    plannedInventoryItems: [];

    constructor(fromBranch: string, plannedInventoryItems: []) {
        this.fromBranch = fromBranch;
        this.plannedInventoryItems = plannedInventoryItems;
    }
}

export class CreateManualTransferRequest {
    transferFrom: string;
    transferTo: string;
    plan: [];

    constructor(transferFrom: string, transferTo: string, plan: []) {
        this.transferFrom = transferFrom;
        this.transferTo = transferTo;
        this.plan = plan;
    }
}

export class BranchFulfillmentPlanResponse {
    overviewList: [];
    plan: [];

    constructor(overviewList: [], plan: []) {
        this.overviewList = overviewList;
        this.plan = plan;
    }
}

/**
 * Transfer Request Item
 */
export class TransferRequestItem {
    id: number;
    internalSKU: string;
    vendorName: string;
    productType: string;
    style: string;
    color: string;
    rollNumber: string;
    dyeLot: string;
    bin: string;
    quantity: number;
    transferredQuantity: number;
    vendorCost: number;
    totalValue: number;
    status: string;


    constructor(id: number, internalSKU: string, vendorName: string, productType: string, style: string, color: string,
                rollNumber: string, dyeLot: string, bin: string, quantity: number, transferredQuantity: number,
                vendorCost: number, totalValue: number, status: string) {
        this.id = id;
        this.internalSKU = internalSKU;
        this.vendorName = vendorName;
        this.productType = productType;
        this.style = style;
        this.color = color;
        this.rollNumber = rollNumber;
        this.dyeLot = dyeLot;
        this.bin = bin;
        this.quantity = quantity;
        this.transferredQuantity = transferredQuantity;
        this.vendorCost = vendorCost;
        this.totalValue = totalValue;
        this.status = status;
    }
}


/**
 * REST interface provided for Transfer Request interactions
 */
export interface TransferRequestRestApi {
    getTransferRequestByTransferNumber(transferNumber: string): AxiosPromise<TransferRequest>;
    getTransferRequestsActivityLogs(transferNumber: string): AxiosPromise<ActivityLog>;
    getInventoryToTransferItems(transferNumber: string): AxiosPromise<TransferRequestItem[]>;
    closeTransferRequest(transferNumber: string, payload: object): AxiosPromise<TransferRequest>;
    closeTransferRequestItem(transferRequestItemId: number, payload: object): AxiosPromise<TransferRequest>;
    createTransferRequestFulfillmentPlan(payload: BranchFulfillmentPlanRequest): AxiosPromise<BranchFulfillmentPlanResponse>;
    createManualTransferRequest(payload: CreateManualTransferRequest): AxiosPromise<TransferRequest>;
    removeTransferRequestsFromShipment(transferRequestNumber: string): AxiosPromise<any>;
}

/**
 * REST implementation.
 */
class Implementation implements TransferRequestRestApi {

    getTransferRequestByTransferNumber(transferNumber: string): AxiosPromise<TransferRequest> {
        return Axios.axiosInstance().get<TransferRequest>(`/transfer-request/${transferNumber}/details`);
    }
    getInventoryToTransferItems(transferNumber: string): AxiosPromise<TransferRequestItem[]> {
        return Axios.axiosInstance().get<TransferRequestItem[]>(`/transfer-request/${transferNumber}/items`);
    }
    getTransferRequestsActivityLogs(transferNumber: string): AxiosPromise<ActivityLog> {
        return Axios.axiosInstance().get<ActivityLog>(`/transfer-request/${transferNumber}/logs`);
    }
    closeTransferRequest(transferNumber: string, payload: object): AxiosPromise<TransferRequest> {
        return Axios.axiosInstance().post<TransferRequest>(`/transfer-request/${transferNumber}/close/force`, payload);
    }
    closeTransferRequestItem(transferRequestItemId: number, payload: object): AxiosPromise<TransferRequest> {
        return Axios.axiosInstance().post<TransferRequest>(`/transfer-request/item/${transferRequestItemId}/close/force`, payload);
    }
    createTransferRequestFulfillmentPlan(payload: BranchFulfillmentPlanRequest): AxiosPromise<BranchFulfillmentPlanResponse> {
        return Axios.axiosInstance().post<BranchFulfillmentPlanResponse>(`/transfer-request/fulfillment-plan`, payload);
    }
    createManualTransferRequest(payload: CreateManualTransferRequest): AxiosPromise<TransferRequest> {
        return Axios.axiosInstance().post<TransferRequest>(`/transfer-request/manual`, payload);
    }
    removeTransferRequestsFromShipment(transferNumber: string): AxiosPromise<any> {
        return Axios.axiosInstance().delete<TransferRequest>(`/transfer-request/remove-from-shipment/${transferNumber}`);
    }
}

const TransferRequests = {
    getRestApi(): TransferRequestRestApi {
        return new Implementation();
    }
};

export default TransferRequests;
