<template>
  <v-autocomplete
      hide-no-data
      hide-selected
      v-model="selected"
      :items="items"
      :loading="loading"
      item-text="purchaseOrderNumber"
      item-value="purchaseOrderNumber"
      label="Search Purchase Order Number"
      :placeholder="'Start typing to search'"
      persistent-placeholder
      :rules="[rules.required]"
      required
      id="purchase-order-number-search-field"
      @keyup.enter.native="handleEnterKeyPress"
      attach
  />
</template>

<script>
import PurchaseOrders from "@/rest/purchaseOrders";
import Rules from "../../assets/rules";

export default {
  components: {},

  data() {
    return {
      rules: {
        required: Rules.required
      },
      selected: null,
      items: [],
      loading: false,
    };
  },

  props: ["value", "placeholder", "readonly", "excludeClosed"],

  watch: {
    value: {
      immediate: true,
      handler(updated) {
        this.selected = updated;
      },
    },
    selected(value) {
      this.$emit("input", value);
    },
  },

  created() {
    this.getAllPurchaseOrders();
  },

  methods: {
    async getAllPurchaseOrders() {
      try {
        const response = this.excludeClosed
            ? await PurchaseOrders.getRestApi().getOpenPurchaseOrdersViews()
            : await PurchaseOrders.getRestApi().getAllPurchaseOrdersViews();
        let onlyOpenedPOs = [];
        for (let i = 0; i < response.data.length; i++) {
          if (!response.data[i].purchaseOrderClosedDate) {
            onlyOpenedPOs.push(response.data[i]);
          }
        }
        this.items = onlyOpenedPOs;
      } catch (error) {
        throw error;
      }
    },
    handleEnterKeyPress(data) {
      this.$emit("enterKeyPress", data);
    },
  },
};
</script>
