<template>
  <v-list class="select-all-list">
    <v-list-item
        class="selection-section"
        ripple
        @mousedown.prevent
        @click="handleSelectDeselectClick"
    >
      <v-checkbox
          class="checkbox-select-all"
          color="primary"
          v-model="checkBoxSelected"
      />
      <span class="append-item-name"> Select All </span>
    </v-list-item>
    <div class="divider-full-length">
      <v-divider></v-divider>
    </div>
  </v-list>
</template>

<script>
export default {
  data() {
    return {};
  },

  props: {
    items: {type: Array, required: true},
    selected: {type: Array | null, required: true}
  },

  computed: {
    enabledItems() {
      return this.items.filter(item => !item.disabled);
    },
    checkBoxSelected() {
      return (this.selected ? this.selected.length : 0) === this.enabledItems.length;
    },
  },

  methods: {
    handleSelectDeselectClick() {
      if ((this.selected ? this.selected.length : 0) === this.enabledItems.length) {
        this.deselectAll();
      } else this.selectAll();
    },
    selectAll() {
      this.$emit('select');
    },
    deselectAll() {
      this.$emit('deselect');
    },
  }
};
</script>

<style scoped>

.selection-section {
  height: 2.3em;
}
.v-input--selection-controls {
  margin-top: 4px;
}
.append-item-name {
  position: absolute;
  display: flex;
  margin-left: 54px;
  font-size: 13px;
}

</style>
