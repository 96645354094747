<template>
  <v-card light color="white">
    <v-card-text>
      <customer-details-form-read-only
          class="pa-3"
          ref="details"
          :customer="customer"
          :showAddress="true"
          :showReferenceIdBlock="true"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CustomerDetailsFormReadOnly from "../../../components/customers/CustomerDetailsFormReadOnly.vue";
import Customers from "../../../rest/customers";

export default {
  components: {CustomerDetailsFormReadOnly},

  props: {
    customerReferenceId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      customer: null,
      error: null
    };
  },

  watch: {
    customerReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      let component = this;
      Customers.getRestApi()
          .getCustomer(this.customerReferenceId)
          .then((response) => {
            let customer = response.data;
            component.customer = customer;
            component.$refs.details.loadForm(customer);
          })
          .catch((error) => {
            component.$emit("jobCustomerPanelError", error);
          });
    }
  }
};
</script>
