<template>
  <v-container grid-list-lg>
    <v-row v-if="formType === Constants.leadFormType.UPDATE">
      <v-col cols="12">
        <v-col cols="6" offset-md="6">
          <lead-statuses-search-field
              v-model="leadStatusId"
              :disabled="isStatusDisabled"
              :sourceState="initialLeadStatusId"
              :manualAssignmentAllowed="isManualAssignmentAllowed"
              :isTimeBlockInPast="isPastTimeBlockLoaded"
              :isNewStatusAllowed="isNewStatusAllowed"
              :filterOutLeadStatusIds="filterOutLeadStatusIds"
          />
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="10" class="pb-0">
        <lead-appointment-fields
            ref="appointmentFields"
            :formType="formType"
            :disabled="isAppointmentDisabled"
            :required="isAppointmentRequired"
            :appointment-time-place-holder="appointmentInLead || ' '"
            :marketId="marketId"
            :isSameDayAllowed="isSameDayAllowed"
            @changeAppointmentDate="onChangeAppointmentDate"
            @changeAppointmentTime="onChangeAppointmentTime"
        />
      </v-col>
      <v-col cols=2 class="pt-10 pb-0">
        <v-btn
            class="calendar-picker-button"
            dark
            small
            color="primary"
            :disabled="isAppointmentDisabled"
            @click="onShowCalendarClick"
        >
          <v-icon>event_available</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="formType === Constants.leadFormType.UPDATE">
      <v-col cols="12" class="pt-0">
        <simple-check-box-with-label
            label="Appointment Confirmed"
            v-model="isAppointmentConfirmed"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center full-width">

        <v-alert
            v-if="isPastTimeBlockLoaded"
            border="left"
            colored-border
            type="error"
            elevation="2"
            prominent
        >
          <v-row class="pa-1">
            Appointment date and time are in the past. Please select a future date and time to proceed with the
            assignment.
          </v-row>
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="showSalesRepDropdown">
      <v-col cols="6">
        <sales-reps-search-field-by-market
            ref="salesRepsSearchField"
            :selectedSalesReps="salesRepReferenceId"
            :marketId="marketId"
            :placeholder="salesRepName || previousSalesRepName || 'Search Sale Representatives'"
            :disabled="isDisabled || !isReassignAllowed || isPastTimeBlockLoaded"
            :readonly="!isReassignAllowed"
            :isRequired="isSalesRepRequired"
            :timeBlockId="timeBlockId"
            :isRefreshByMarket="false"
            :clearable="false"
            :showTier="true"
            class="mb-1"
            @change="onSaleRepChange"
        />
      </v-col>
      <v-col cols="6">
        <choose-reassign-sales-reason-code-dropdown
            v-if="formType === Constants.leadFormType.UPDATE && isAssignedStatus && isALAEnabled"
            :disabled="!isReassignAllowed || !wasSalesChanged || isPastTimeBlockLoaded"
            :required="isReassignAllowed && wasSalesChanged"
            ref="reasonCodeDropDown"
            @select="optionSelected"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <promo-codes-search-field
            ref="promoCodesSearchField"
            v-model="promoCodeId"
            :filterPromoCodes="true"
            :placeholder="'Search Promo Codes'"
            :marketId="marketId"
            :requiredValue="true"
            :disabled="isDisabled"
            :clearable="false"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <lead-rooms-search-field
            ref="leadRoomsSearchField"
            :value="leadRooms"
            :showRoomCount="true"
            :placeholder="'Search Rooms'"
            :disabled="isDisabled"
            :selectAllEnabled="true"
            class="mb-1"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <lead-product-types
            ref="productTypesSearchField"
            :value="leadProductTypes"
            :disabled="isDisabled"
            :multiple="true"
            :selectAllEnabled="true"
            :max-dropdown-height="190"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="formType === Constants.leadFormType.CREATE">
        <v-text-field
            v-model="note"
            label="Note"
            placeholder=" "
            persistent-placeholder
            data-id="lead-details-form-note"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mt-1 mb-0 pt-0 pb-0">
        <simple-check-box-with-label
            label="Is the customer a home owner?"
            v-model="isHomeOwner"
        />
      </v-col>
    </v-row>

    <v-row v-if="formType === Constants.leadFormType.UPDATE">
      <v-col cols="12" class="mt-6 mb-0 pt-0 pb-0">
        <simple-check-box-with-label
            label="Rehash go back"
            v-model="isRehash"
        />
      </v-col>
    </v-row>

    <time-block-calendar-dialog
        ref="calendarDialog"
        :formType="formType"
        @timeblockClicked="handleTimeBlockClick"
    />
  </v-container>
</template>

<script>
import Constants from "../../assets/constants";
import LeadStatusesSearchField from "../../components/searchFields/LeadStatuses.vue";
import PromoCodesSearchField from "../../components/searchFields/PromoCodes.vue";
import LeadRoomsSearchField from "../../components/searchFields/LeadRooms.vue";
import ProductTypesSearchField from "../../components/searchFields/LeadProductTypes.vue";
import SalesRepsSearchFieldByMarket from "../../components/searchFields/SalesRepsSearchFieldByMarket.vue";
import LeadAppointmentFields from "../../components/leads/LeadAppointmentFields.vue";
import TimeBlockCalendarDialog from "./TimeBlockCalendarDialog.vue";
import LeadProductTypes from "../searchFields/LeadProductTypes";
import SimpleCheckBoxWithLabel from "../common/form/SimpleCheckBoxWithLabel.vue";
import ChooseReassignSalesReasonCodeDropdown from "@/components/leads/ChooseReassignSalesReasonCodeDropdown.vue";
import DateUtils from "@/assets/utils/dateUtils";
import Markets from "@/rest/markets";
import SalesRepsTimeBlocks from "@/rest/salesRepsTimeBlock";

const formDefaults = {
  id: null,
  note: null,
  promoCodeId: null,
  previousSalesRepName: null,
  leadStatusId: 1,
  salesRepReferenceId: null,
  salesRepId: null,
  salesRepName: null,
  timeBlockId: null,
  leadRooms: [],
  leadProductTypes: [],
  appointmentDate: null,
  appointmentTime: null,
  initialLeadStatusId: null,
  oldAppointmentDate: null,
  oldAppointmentTime: null,
  originalSalesRepReferenceId: null,
  appointmentInLead: null,
  selectedReasonCode: null,
  isHomeOwner: false,
  isRehash: false,
  isAppointmentConfirmed: false
};

export default {
  components: {
    ChooseReassignSalesReasonCodeDropdown,
    SimpleCheckBoxWithLabel,
    LeadProductTypes,
    LeadStatusesSearchField,
    PromoCodesSearchField,
    LeadRoomsSearchField,
    ProductTypesSearchField,
    SalesRepsSearchFieldByMarket,
    LeadAppointmentFields,
    TimeBlockCalendarDialog
  },

  props: {
    customer: Object,
    marketId: Number,
    disabled: Boolean,
    details: Boolean,
    formType: String,
    promoCodeEditable: Boolean,
    assignedSalesRep: Object,
    isSameDayAllowed: Boolean,
    marketRefId: String
  },

  data() {
    return Object.assign(
        {},
        formDefaults,
        {
          isHomeOwner: this.formType === Constants.leadFormType.CREATE,
          isAppointmentInPast: false,
          assignmentStatus: null,
          isManualAssignmentAllowed: true,
          isPastTimeBlockLoaded: false,
          isFutureTimeBlockLoaded: false,
          isALAEnabled: false,
          formLoaded: false
        }
    );
  },

  computed: {
    Constants() {
      return Constants
    },
    /**
     * The fields should be disabled if parent component disabled, or we are transitioning to a
     * Dead status (this.leadStatusId === 6).
     */
    isNewStatusAllowed() {
      return this.isManualAssignmentAllowed || this.initialLeadStatusId === Constants.LeadStatuses.Canceled;
    },
    filterOutLeadStatusIds() {
      if (this.leadStatusId !== Constants.LeadStatuses.New && this.initialLeadStatusId !== Constants.LeadStatuses.Canceled) {
        return [Constants.LeadStatuses.New]
      } else {
        return []
      }
    },
    isDisabled() {
      return this.disabled || this.leadStatusId === Constants.LeadStatuses.Dead;
    },
    isAppointmentDisabled() {
      return this.disabled ||
          this.leadStatusId === Constants.LeadStatuses.Dead ||
          this.leadStatusId === Constants.LeadStatuses.Sizing;
    },
    isReassignAllowed() {
      if (this.formType === Constants.leadFormType.CREATE) {
        return this.assignmentStatus === Constants.timeBlockAssignmentStatuses.CAN_BE_REASSIGNED;
      }

      return this.leadStatusId === Constants.LeadStatuses.Assigned || this.leadStatusId === Constants.LeadStatuses.Rehash;
    },
    isAssignedStatus() {
      return this.leadStatusId === Constants.LeadStatuses.Assigned && this.initialLeadStatusId === Constants.LeadStatuses.Assigned;
    },
    wasSalesChanged() {
      return this.salesRepReferenceId !== this.originalSalesRepReferenceId;
    },
    /**
     * Changing the status field is disable if the parent component disable if or
     * if we transition to a Dead status (this.leadStatusId === 6).
     * Now we can go from the Dead status to the New status so (this.leadStatusId === 6) is not valid anymore.
     */
    isStatusDisabled() {
      return this.disabled;
    },
    /**
     * Appointment is not required only when the lead is:
     * - 5: Cancelled.
     * - 6: Dead.
     */
    isAppointmentRequired() {
      let required = false;
      if (this.disabled) {
        required = false;
      } else if (this.leadStatusId !== Constants.LeadStatuses.Canceled
          && this.leadStatusId !== Constants.LeadStatuses.Dead) {
        required = true;
      }
      return required;
    },
    /**
     * Sales Representatives are required when we are
     * in states
     *  - 2: Assigned
     *  - 7: Sold
     *  - 8: Sizing
     */
    isSalesRepRequired() {
      let required = false;
      if (this.leadStatusId === Constants.LeadStatuses.Assigned
          || this.leadStatusId === Constants.LeadStatuses.Sold
          || this.leadStatusId === Constants.LeadStatuses.Sizing) {
        required = true;
      }
      return required;
    },
    showSalesRepDropdown() {
      if (this.formType === Constants.leadFormType.CREATE) {
        return this.isManualAssignmentAllowed;
      }

      const isStatusChangedFromNewToAssignManually = this.isManualAssignmentAllowed
          && this.initialLeadStatusId === Constants.LeadStatuses.New
          && this.leadStatusId === Constants.LeadStatuses.Assigned;

      return (this.leadStatusId === Constants.LeadStatuses.Rehash || !this.isFutureTimeBlockLoaded)
          && (this?.initialLeadStatusId !== Constants.LeadStatuses.New || isStatusChangedFromNewToAssignManually);
    }
  },

  watch: {
    timeBlockId: 'getAssignmentStatusByTimeBlockId',
    salesRepReferenceId: 'validateDateWatcher',
    customer: {
      immediate: true,
      handler(newCustomer, oldCustomer) {
      }
    },
    leadStatusId(newValue, oldValue) {
      if (newValue === Constants.LeadStatuses.New && oldValue === Constants.LeadStatuses.Assigned) {
        this.salesRepReferenceId = null;
      }
      if (newValue === Constants.LeadStatuses.Assigned && oldValue === Constants.LeadStatuses.New) {
        this.salesRepReferenceId = this.originalSalesRepReferenceId;
      }
      this.validateDateWatcher();
    },
    isHomeOwner(newValue) {
      this.isHomeOwner = newValue;
    },
    isRehash(newValue) {
      this.isRehash = newValue;
    },
    isAppointmentConfirmed(newValue) {
      this.isAppointmentConfirmed = newValue;
    }
  },

  methods: {
    async getMarket() {
      return Markets.getRestApi().getMarket(this.marketRefId)
          .then(r => r.data);
    },
    calculateIsAppointmentInPast() {
      let appointmentFields = this.$refs.appointmentFields.getValues();

      this.isAppointmentInPast = this.appointmentDate && appointmentFields && appointmentFields.time !== '' &&
          !DateUtils.isFutureOrSameDateInTimezone(this.appointmentDate, appointmentFields.time + ":00", this.timezone)
    },

    async getAssignmentStatusByTimeBlockId() {
      if (this.timeBlockId === null) {
        return;
      }

      this.assignmentStatus = await SalesRepsTimeBlocks.getRestApi()
          .getAssignmentStatus(this.timeBlockId)
          .then(response => response.data)

      this.isManualAssignmentAllowed = this.assignmentStatus === Constants.timeBlockAssignmentStatuses.CAN_BE_REASSIGNED
      this.isPastTimeBlockLoaded = this.assignmentStatus === Constants.timeBlockAssignmentStatuses.PAST
      this.isFutureTimeBlockLoaded = this.assignmentStatus === Constants.timeBlockAssignmentStatuses.FUTURE
    },

    validateDateWatcher() {
      if (this.initialLeadStatusId === Constants.LeadStatuses.New && this.leadStatusId === Constants.LeadStatuses.Assigned) {
        this.calculateIsAppointmentInPast();
      } else if (this.initialLeadStatusId === Constants.LeadStatuses.Assigned && this.leadStatusId === Constants.LeadStatuses.Assigned &&
          this.salesRepReferenceId !== this.originalSalesRepReferenceId) {
        this.calculateIsAppointmentInPast();
      }

    },

    buildPayload() {
      let payload = {};
      const {name, date, timeBlockId} = this.$refs.appointmentFields.getValues();
      payload.appointmentTime = name;
      payload.appointmentDate = date;
      payload.timeBlockId = timeBlockId;
      payload.id = this.id;
      payload.leadStatusId = this.leadStatusId;
      payload.newDate = this.appointmentDate !== this.oldAppointmentDate;
      payload.promoCodeId = this.$refs.promoCodesSearchField.selected;
      payload.roomTypes = this.$refs.leadRoomsSearchField.getFlatSelected();
      payload.productTypesIds = this.$refs.productTypesSearchField.getFlatSelected();
      payload.salesRepReferenceId = Array.isArray(this.salesRepReferenceId) ? null : this.salesRepReferenceId;
      payload.note = this.createNote();
      payload.isHomeOwner = this.isHomeOwner;
      payload.isRehash = this.isRehash;
      payload.isAppointmentConfirmed = this.isAppointmentConfirmed;
      payload.selectedReasonCode = this.selectedReasonCode;
      return payload;
    },

    setFormBackToDefault() {
      let leadRooms = [];
      let leadProductTypes = [];
      this.loadForm(formDefaults, leadRooms, leadProductTypes);
      this.cleanSalesRepSearchField();
    },
    createNote() {
      if (this.note != null) {
        const user = this.$store.state.loggedInUser;
        const creatorId = user.id;
        return {
          note: this.note,
          creatorId
        };
      }
      return null;
    },
    setFormBackToOriginalData(payload, leadRooms, leadProductTypes) {
      this.loadForm(payload, leadRooms, leadProductTypes);
      this.$refs.leadRoomsSearchField.setSelected(leadRooms);
      this.$refs.productTypesSearchField.setSelected(leadProductTypes);
    },

    async loadForm(payload, leadRooms, leadProductTypes) {
      if (this.$refs.appointmentFields) {
        this.$refs.appointmentFields.reset();
        await this.$refs.appointmentFields.loadForm(payload);
      }

      this.appointmentInLead = payload.appointmentTime;
      this.note = payload.note;
      this.isHomeOwner = payload.isHomeOwner;
      this.isRehash = payload.isRehash;
      this.isAppointmentConfirmed = payload.isAppointmentConfirmed;
      this.salesRepReferenceId = payload.salesRepReferenceId;
      this.originalSalesRepReferenceId = payload.salesRepReferenceId;
      this.leadStatusId = payload.leadStatusId;
      this.promoCodeId = payload.promoCodeId;
      this.leadRooms = leadRooms;
      this.id = payload.id;
      this.salesRepName = this.assignedSalesRep.salesRepName;
      this.previousSalesRepName = payload.previousSalesRepName;
      this.leadProductTypes = leadProductTypes;
      this.initialLeadStatusId = payload.leadStatusId;

      const {name, date, timeBlockId} = this.$refs.appointmentFields.getValues();

      this.$nextTick(() => {
        this.refreshSalesRepSearchField(timeBlockId, this.salesRepReferenceId);
      });

      this.timeBlockId = timeBlockId;
      this.oldAppointmentDate = date;
      this.oldAppointmentTime = name;
      this.market = await this.getMarket();
      this.timezone = this.market.timezoneName;
      this.isALAEnabled = this.market.isALAEnabled;

      this.formLoaded = true;
      this.$emit('formLoaded');
    },
    setLeadStatusToNew() {
      this.leadStatusId = Constants.LeadStatuses.New;
    },
    checkIfSalesRepRequired() {
      return this.isSalesRepRequired;
    },
    onSubmitSuccess(data) {
      this.$refs.appointmentFields.updateTimeSlots(data[0].id);
    },
    onSubmitFailed(error) {
      this.$emit('onError', error);
    },
    onChangeAppointmentDate(appointmentDate) {
      this.isAppointmentConfirmed = false;

      if (appointmentDate !== this.appointmentDate && this.appointmentDate !== null) {
        this.cleanSalesRepSearchField()
      }

      if (this.marketId !== undefined && this.marketId !== null) {
        this.appointmentTime = null;
        this.appointmentDate = appointmentDate;
        this.$refs.appointmentFields.setTimeBlock(appointmentDate, null, this.marketId);
      }
    },
    async onChangeAppointmentTime(slotTime) {
      this.isAppointmentConfirmed = false;
      this.appointmentTime = slotTime;

      if (this.formLoaded || this.formType === Constants.leadFormType.CREATE) {
        await this.refreshSalesRep();
      }
    },
    async refreshSalesRep() {
      this.timeBlockId = this.$refs.appointmentFields.getValues().timeBlockId;
      await this.refreshSalesRepSearchField(this.timeBlockId, this.originalSalesRepReferenceId);

      const isSalesRepNotAvailable = !await this.isSalesRepAvailable(this.salesRepReferenceId);
      const isOriginalSalesRepAvailable = await this.isSalesRepAvailable(this.originalSalesRepReferenceId)
      const appointmentDateTimeHasChanged = (this.oldAppointmentDate !== null && this.oldAppointmentTime !== null)
          && (this.appointmentDate !== this.oldAppointmentDate || this.appointmentTime !== this.oldAppointmentTime);
      const salesRepHasChanged = this.salesRepReferenceId != null && this.salesRepReferenceId !== this.originalSalesRepReferenceId;
      const nothingHasChanged = !salesRepHasChanged && !appointmentDateTimeHasChanged;

      if (isSalesRepNotAvailable && isOriginalSalesRepAvailable || nothingHasChanged) {
        this.salesRepReferenceId = this.originalSalesRepReferenceId;
      } else if ((salesRepHasChanged || appointmentDateTimeHasChanged) && isSalesRepNotAvailable) {
        this.salesRepReferenceId = null;
      }
    },
    reset() {
      Object.assign(this.$data, formDefaults);
      this.formLoaded = false;
      if (this.$refs.appointmentFields) {
        this.$refs.appointmentFields.reset();
      }
      if (this.$refs.reasonCodeDropDown) {
        this.$refs.reasonCodeDropDown.restoreToDefaults();
      }
      this.setLeadStatusToNew();
      this.resetPromoCodes();
      this.appointmentInLead = null;
    },
    optionSelected(val) {
      this.selectedReasonCode = val;
    },
    resetPromoCodes() {
      this.promoCodeId = null;
    },
    onShowCalendarClick() {
      this.$refs.calendarDialog.showDialog(this.marketId);
    },
    async handleTimeBlockClick(timeBlockClicked) {
      let payload = {
        appointmentDate: timeBlockClicked.slotDate,
        timeBlockId: timeBlockClicked.id,
        marketId: timeBlockClicked.marketId
      };
      this.$refs.appointmentFields.reset();
      if (this.$refs.appointmentFields) {
        await this.$refs.appointmentFields.loadForm(payload);
      }
      await this.refreshSalesRep();
    },
    cleanSalesRepSearchField() {
      if (this.$refs.salesRepsSearchField) {
        this.$refs.salesRepsSearchField.cleanList();
      }
    },
    async refreshSalesRepSearchField(timeBlockId, selectedSalesRep) {
      if (this.$refs.salesRepsSearchField) {
        await this.$refs.salesRepsSearchField.refreshByTimeBlock(timeBlockId, selectedSalesRep);
      }
    },
    async isSalesRepAvailable(salesRepReferenceId) {
      if (this.$refs.salesRepsSearchField) {
        return this.$refs.salesRepsSearchField.isSalesRepAvailable(salesRepReferenceId);
      }
      return false;
    },
    onSaleRepChange(selectedSalesRepReferenceId) {
      this.salesRepReferenceId = selectedSalesRepReferenceId;
    }
  }
};
</script>

<style scoped>
.calendar-picker-button {
  width: 72px;
  height: 34px;
  box-shadow: none
}
</style>
