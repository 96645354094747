<template>
  <modal-dialog
      ref="dialog"
      title="Add Day Off"
      width="500"
      :visible="dialogVisible"
      :submitEnabled="true"
      :loading="loading"
      submitLabel="Save"
      cancelLabel="Cancel"
      @submitClicked="onSubmit"
      @cancelClicked="closeDialog"
  >
    <add-crew-day-off-form
        ref="form"
        :crews="crews"
    />

    <error-modal
        ref="errorModal"
        :error="errorMessage"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";
import AddCrewDayOffForm from "@/views/installers/crews/dayoff/CrewDayOffForm.vue";
import InstallerCrewDayOff from "@/rest/installer/installerCrewDayOff";
import ErrorModal from "@/components/navigation/ErrorModal.vue";

export default {
  components: {
    ErrorModal,
    ModalDialog,
    AddCrewDayOffForm
  },

  data() {
    return {
      dialogVisible: false,
      loading: false,
      errorMessage: null,
      isErrorModalOpen: false
    };
  },

  props: {
    crews: {
      type: Array,
      required: true
    }
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
      this.stopLoading();
    },
    closeDialog() {
      this.stopLoading();
      this.dialogVisible = false;
      this.reset();
    },
    reset() {
      this.$refs.form.resetForm();
    },
    showError(error) {
      this.errorMessage = error.response.data.message;
      this.isErrorModalOpen = true;
    },
    async onSubmit() {
      try {
        const valid = this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          const payloadCrew = this.$refs.form.buildPayload();
          InstallerCrewDayOff.getRestApi().createDayOff(payloadCrew)
              .then(() => {
                this.$emit('saveDayOffButtonClicked', payloadCrew);
                this.closeDialog();
              }).catch((error) => {
            this.stopLoading();
            this.showError(error);
          });
        }
      } catch (error) {
        this.$emit('submitFailed', error);
      }
    },
    stopLoading() {
      this.loading = false;
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.errorMessage = null;
    }
  }
};
</script>
