<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Sales Commissions
          </v-expansion-panel-header>
          <v-expansion-panel-content key="salesRepCommissionsOdata">
            <sales-rep-commissions-grid
                :salesRepresentativeId="salesRepresentativeId"
                :salesRepReferenceId="salesRepReferenceId"
                :customPagingSize="[10, 20, 50]"
                @commissionUpdatedSuccessfully="onCommissionUpdatedSuccessfully"
                @commissionUpdateFailed="emitError"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Fees and Adjustments
          </v-expansion-panel-header>
          <v-expansion-panel-content key="salesRepFines">
            <fines
                ref="salesRepFines"
                :salesRepName="salesRepName"
                :salesRepReferenceId="salesRepReferenceId"
                @submitClicked="onSubmitClicked"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Schedule
          </v-expansion-panel-header>
          <v-expansion-panel-content key="salesRepSchedule">
            <schedule
                ref="salesRepSchedule"
                :salesRepReferenceId="salesRepReferenceId"
                :sales-rep-urls="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-card>
        <v-tabs background-color="#69b" slider-color="white" dark>
          <v-tab key="notes">Notes</v-tab>
          <v-tab-item key="notes">
            <notes-grid
                ref="notesGrid"
                :notes="notes"
                @saveNote="onSaveNote"
                @getNotes="onGetNotes"
            />
          </v-tab-item>

          <v-tab key="activityLog">Activity Log</v-tab>
          <v-tab-item key="activityLog">
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-tab-item>

          <v-tab key="files">EDocs</v-tab>
          <v-tab-item key="files">
            <files-grid
                ref="files"
                parent-type="salesRep"
                :showEditingButtons="false"
                :salesRepReferenceId="salesRepReferenceId"
                :documentTypesCategory=Constants.documentTypesCategories.SALES_REPRESENTATIVE
                @filesGridError="emitError"
            />
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Schedule from "../../components/salesRepresentatives/inlineContainers/SalesRepSchedule.vue";
import Fines from "../../components/salesRepresentatives/inlineContainers/SalesRepFines.vue";
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import NotesGrid from "../../components/common/inlineGrids/NotesGrid.vue";
import Constants from "../../assets/constants";
import Notes from "../../rest/notes";
import SalesRepresentative from "../../rest/salesRepresentatives";
import FilesGrid from "@/components/common/inlineGrids/files/FilesGrid.vue";
import SalesRepCommissionsGrid from "@/components/salesRepresentatives/SalesRepCommissionsGrid.vue";

export default {
  computed: {
    Constants() {
      return Constants
    }
  },
  components: {
    SalesRepCommissionsGrid,
    FilesGrid,
    Schedule,
    Fines,
    ActivityLogsGrid,
    NotesGrid
  },

  props: {
    salesRepReferenceId: {
      type: String,
      required: true
    },
    salesRepresentativeId: {
      type: Number,
      required: true
    },
    salesRepName: {
      type: String
    }
  },

  data() {
    return {
      activityLogs: [],
      notes: [],
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    refreshActivityLog() {
      if (this.$refs.activityLog) {
        this.getActivityLogs();
      }
    },
    onCommissionUpdatedSuccessfully(message) {
      this.refreshActivityLog();
      this.$emit('showSuccess', message);
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    emitSuccess(message) {
      this.$emit('showSuccess', message);
    },
    async getNotesData() {
      try {
        const response = await SalesRepresentative.getRestApi().getNotes(this.salesRepReferenceId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async saveSalesRepNote(note) {
      try {
        await Notes.getRestApi().createSalesRepNote(this.salesRepReferenceId, note);
        this.onSaveNoteSuccess();
      } catch (error) {
        this.emitError(error);
      } finally {
        this.$refs.notesGrid.resetCreateNoteForm();
      }
    },
    async onSaveNoteSuccess() {
      try {
        await this.onGetNotes();
        this.emitSuccess("Sales Representative note saved successfully!");
        this.$refs.notesGrid.onSuccessSave();
      } catch (error) {
        throw error;
      }
    },
    onSaveNote(note) {
      this.saveSalesRepNote(note);
    },
    async onGetNotes() {
      try {
        this.notes = await this.getNotesData();
      } catch (error) {
        this.emitError(error);
      }
    },
    async getActivityLogs() {
      try {
        const response = await SalesRepresentative.getRestApi().getSalesRepresentativeLogs(this.salesRepReferenceId);
        this.activityLogs = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    async onSubmitClicked(payload) {
      try {
        this.handleCreateFineSuccess(payload);
      } catch (error) {
        this.emitError(error);
      }
    },
    handleCreateFineSuccess(payload) {
      const feeOrAdjustmentType = payload.type == 1 ? 'adjustment' : 'fee'
      this.emitSuccess("Saved " + feeOrAdjustmentType + " successfully!");
      this.$refs.salesRepFines.resetWizard();
      this.getActivityLogs();
      this.refreshActivityLog();
    }
  }
};
</script>
