<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td class="reference-id-td top-aligned">
          <strong v-if="!isGenerated(lineItem.jobLineItem.referenceId)">{{ lineItem.jobLineItem.referenceId }}</strong>
        </td>
        <td width="20%">
          <v-text-field
              class="short-input left-aligned"
              :value="comment"
              placeholder=" "
              disabled
          />
        </td>
        <td width="40%">
          <v-text-field
              :value="installationInfo"
              class="short-input left-aligned"
              placeholder=" "
              disabled
          />
        </td>
        <td width="10%" class="pt-0">
          <v-text-field
              class="short-input"
              :value="laborAmount"
              prefix="$"
              disabled
              @blur="sanitizeLaborAmount"
          />
        </td>
        <td width="10%" class="pt-0">
          <v-text-field
              class="short-input"
              :value="installerPayable"
              prefix="$"
              disabled
          />
        </td>
        <td width="10%" class="pt-0" v-if="showScgPayableColumn">
          <v-text-field
              class="short-input"
              :value="scgPayable"
              prefix="$"
              disabled
          />
        </td>
        <td width="10%" class="pt-0">
          <v-text-field
              class="short-input"
              :value="materialsAmount"
              prefix="$"
              disabled
              @blur="sanitizeMaterialsAmount"
          />
        </td>
        <td width="10%" class="pt-0">
          <v-text-field
              class="short-input"
              :value="customerSatisfactionAmount"
              prefix="$"
              disabled
              @blur="sanitizeCustomerSatisfactionAmount"
          />
        </td>
        <td width="30px" class="reimbursement-icon-container">
          <v-btn
              small
              dark
              icon
              class="short ma-0 pa-0"
              v-if="canDeleteItems"
              @click="onDeleteItem(lineItem)"
          >
            <v-icon class="short" color="primary">delete</v-icon>
          </v-btn>
        </td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import {isGenerated} from "../../../assets/referenceIds";
import DateFormatter from "../../../assets/dateFormatter";

export default {
  components: {},

  props: {
    job: {
      type: Object,
      required: true,
    },
    lineItem: {
      type: Object,
      required: true,
    },
    showScgPayableColumn: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  data() {
    return {
      referenceId: null,
      type: null,
      quantity: null,
      laborAmount: null,
      materialsAmount: null,
      customerSatisfactionAmount: null,
      comment: null,
      isFulFilled: false,
      installationCapabilityId: null,
      installerReimbursement: false,
      installationInfo: null,
      installerPayable: 0,
      scgPayable: 0
    };
  },

  computed: {
    canDeleteItems() {
      return !this.job.invoiced && this.lineItem.jobLineItem.installation.jobLineItem.earlyPaymentDate === null
          && (this.lineItem.jobLineItem.deletable === true || this.lineItem.jobLineItem.deletable === undefined)
    }
  },

  watch: {
    lineItem: {
      immediate: true,
      handler(updated) {
        let lineItem = updated.jobLineItem;
        this.referenceId = lineItem.referenceId;
        this.type = lineItem.type;
        this.status = null;
        this.comment = lineItem.comment;
        this.quantity = null;
        this.laborAmount = CurrencyFormatter.formatCurrency(lineItem.laborAmount);
        this.materialsAmount = CurrencyFormatter.formatCurrency(lineItem.materialsAmount);
        this.customerSatisfactionAmount = CurrencyFormatter.formatCurrency(lineItem.customerResponsibilityAmount);
        this.isFulFilled = !!lineItem.fulfillmentDate;
        this.installationCapabilityId = lineItem.installationCapabilityId;
        this.installerReimbursement = lineItem.installerReimbursement;
        this.installationInfo = lineItem.installation.installer.companyName
            + " - " + lineItem.installation.jobLineItem.installationCapability.name
            + " - " + DateFormatter.formatDateInMMDDYYYYFormat(lineItem.installation.installationDate);
        this.installerPayable = lineItem.installerPayable
            ? CurrencyFormatter.formatCurrency(lineItem.installerPayable)
            : CurrencyFormatter.zeroCurrency();
        this.scgPayable = lineItem.scgPayable
            ? CurrencyFormatter.formatCurrency(lineItem.scgPayable)
            : CurrencyFormatter.zeroCurrency();
      }
    },
    //TODO: looks like those watches are unused
    laborAmount(value) {
      if (value === this.lineItem.jobLineItem.laborAmount) {
        return;
      }
      this.lineItem.jobLineItem.laborAmount = CurrencyFormatter.asCurrency(value);
      this.$emit('laborAmountUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    materialsAmount(value) {
      if (value === this.lineItem.jobLineItem.materialsAmount) {
        return;
      }
      this.lineItem.jobLineItem.materialsAmount = CurrencyFormatter.asCurrency(value);
      this.$emit('materialsAmountUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
    customerSatisfactionAmount(value) {
      if (value === this.lineItem.jobLineItem.customerSatisfactionAmount) {
        return;
      }
      this.lineItem.jobLineItem.customerSatisfactionAmount = CurrencyFormatter.asCurrency(value);
      this.$emit('customerSatisfactionAmountUpdated', {
        item: this.lineItem,
        updated: value,
      });
    },
  },

  methods: {
    //TODO: move to a helper class
    sanitizeLaborAmount() {
      let parsed = Number.parseFloat(this.laborAmount);
      if (!Number.isNaN(parsed) && parsed < 0) {
        this.laborAmount = 0;
      }
    },
    sanitizeMaterialsAmount() {
      let parsed = Number.parseFloat(this.materialsAmount);
      if (!Number.isNaN(parsed) && parsed < 0) {
        this.materialsAmount = 0;
      }
    },
    sanitizeCustomerSatisfactionAmount() {
      let parsed = Number.parseFloat(this.customerSatisfactionAmount);
      if (!Number.isNaN(parsed) && parsed < 0) {
        this.customerSatisfactionAmount = 0;
      }
    },
    emitError(error) {
      this.$emit('error', error);
    },
    onDeleteItem(lineItem) {
      this.$emit('itemDeleted', lineItem);
    },
    isGenerated
  },
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}

.reimbursement-icon-container {
  text-align: center;
}

.reference-id-td {
  width: 10%;
  padding-top: 7px;
}
</style>

<style>
.item-row td {
  vertical-align: bottom;
}
.item-row td.top-aligned {
  vertical-align: top;
}
</style>
