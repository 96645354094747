<template v-slot:content>
  <div class="pb-3">
    <dx-data-grid
        :data-source="subjobs"
        ref="grid"
        :selection="{ mode: 'single' }"
        :show-borders="false"
        :hover-state-enabled="true"
        noDataText="No Subjobs Found for This Job"
        @selectionChanged="onSubjobSelected"
    >
      <dx-column
          data-field="referenceId"
          caption="Job Reference Id"
      />
      <dx-column
          data-field="createdAt"
          caption="Created Date"
          data-type="date"
          format="MM/dd/yyyy"
          :minWidth="165"
          :allowHeaderFiltering="false"
      />
      <dx-column
          data-field="updatedAt"
          caption="Updated Date"
          data-type="date"
          format="MM/dd/yyyy"
          :minWidth="165"
          :allowHeaderFiltering="false"
      />
    </dx-data-grid>
    <v-row justify="center">
      <v-card-actions class="ma-4">
        <v-card v-if="createSubjobEnabled" flat outlined>
          <inline-grid-button
              :loading="creating"
              label="Create Subjob"
              @click="onCreateSubjob"
          />
        </v-card>
        <v-card flat v-else class="subheading pa-4 blue-grey--text text--lighten-1">
          Subjobs can only be created once a job is invoiced or closed
        </v-card>
      </v-card-actions>
    </v-row>
  </div>
</template>

<script>
import InlineGridButton from "../../../components/common/grid/InlineGridButton.vue";
import Jobs from "../../../rest/jobs";
import {DxColumn, DxDataGrid} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    InlineGridButton,
    DxDataGrid,
    DxColumn,
  },

  props: {
    job: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      subjobs: [],
      creating: false,
    };
  },

  computed: {
    jobReferenceId() {
      return this.job ? this.job.referenceId : null;
    },
    createSubjobEnabled() {
      return this.job.invoiced;
    },
  },

  watch: {
    jobReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      },
    },
  },

  methods: {
    async refresh() {
      try {
        let response = await Jobs.getRestApi().getSubjobs(this.job.referenceId);
        this.subjobs = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async onCreateSubjob() {
      this.creating = true;
      try {
        let response = await Jobs.getRestApi().createSubjob(this.job.referenceId);
        let job = response.data;
        this.$emit("open", job.referenceId);
      } catch (error) {
        console.log(error);
      }
      this.creating = false;
    },
    onSubjobSelected(subjob) {
      let referenceId = subjob.selectedRowsData[0].referenceId;
      this.$emit("open", referenceId);
    },
  },
};
</script>
