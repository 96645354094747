<template>
  <v-row class="ma-0 pa-0 no-gutters">
    <v-col cols="4">
      <v-switch
          v-model="localMfaEnabled"
          :disabled="disabled"
          label="MFA Enabled"
          @change="mfaEnabledValueChanged"
      />
    </v-col>
    <v-col cols="4" offset-md="4" class="mt-2" v-if="localMfaEnabled && mfaSetUp">
      <v-btn
          id="mfa-control-tools-reset-mfa"
          class="full-width white--text"
          color="#59b"
          large
          :disabled="isMfaResetDisabled"
          @click="resetMfaClicked"
      >
        <span class="text-center">Reset MFA</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Roles from "@/assets/roles";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: true
    },
    mfaEnabled: {
      type: Boolean,
      required: true
    } ,
    mfaSetUp: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    isMfaResetDisabled() {
      return !Roles.isSuperAdminUser();
    }
  },

  data() {
    return {
      localMfaEnabled: this.mfaEnabled
    };
  },

  watch: {
    mfaEnabled(newVal) {
      this.localMfaEnabled = newVal;
    }
  },

  methods: {
    resetMfaClicked() {
      this.$emit('resetMfaClicked');
    },
    mfaEnabledValueChanged(value) {
      this.$emit('mfaEnabledValueChanged', value);
    }
  }
};
</script>
<style scoped>
button#mfa-control-tools-reset-mfa {
  width: 150px;
}
</style>
