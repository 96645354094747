<template>
  <v-data-table
      :headers="headers"
      :items="jobItems"
      light
      hide-default-footer
      disable-pagination
      item-key="name"
  >
    <template v-slot:item="{ item }">
      <tr class="rowOfData"
          :key="item.name"
          @click="addToCheckInList(item)"
      >
        <td class="text-lg-left pa-4" width="20%">
          {{ item.jobProductLineItem.referenceId }}
        </td>
        <td class="text-lg-left pa-4" width="20%">
          {{ item.jobProductLineItem.productCatalogItem.styleName }}
        </td>
        <td class="text-lg-left pa-4" width="20%">
          {{ item.jobProductLineItem.productCatalogItem.color }}
        </td>
        <td class="text-lg-left pa-4" width="20%">
          {{ item.jobProductLineItem.quantity }}
        </td>
        <td class="text-xs-left" width="1%">
          <v-tooltip v-if="isNotFulfilled(item)" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs"
                      v-on="on"
                      color="primary"
              >
                error_outline
              </v-icon>
            </template>
            <span>Item has not been submitted for fulfillment.</span>
          </v-tooltip>
          <v-tooltip v-else-if="isPOwithNoInventory(item)" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs"
                      v-on="on"
                      color="primary"
              >
                error_outline
              </v-icon>
            </template>
            <span>Item from purchase order has not been checked in.</span>
          </v-tooltip>
          <v-icon
              v-else-if="item.jobProductLineItem.checkedOut"
              color="primary"
          >
            check_circle_outline
          </v-icon>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      No inventory items have been checked in for this Job
    </template>
  </v-data-table>
</template>

<script>
export default {
  components: {},
  props: {
    jobItems: {type: Array, required: true}
  },
  data() {
    return {
      headers: [
        { text: "Reference Id", value: "referenceId", sortable: false },
        { text: "Style", value: "styleName", sortable: false },
        { text: "Color", value: "color", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Checked Out", value: "checkedOut", sortable: false }
      ]
    };
  },

  methods: {
    addToCheckInList(item) {
      if (!item.jobProductLineItem.checkedOut && !this.isPOwithNoInventory(item) && !this.isNotFulfilled(item)) {
        this.$emit("jobItemClicked", item);
      }
    },
    isNotFulfilled(item) {
      return !item.jobProductLineItem.fulfillmentDate;
    },
    isPOwithNoInventory(item) {
      return (item.purchaseOrder && item.purchaseOrder.inventoryItems.length === 0);
    }
  }
};
</script>

<style>
.rowOfData{
  font-size: 13px;
}
.rowOfData:hover {
  cursor: pointer;
  background: #7A7A7A1F;
}
</style>
