import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "@/rest/interfaces/activityLogs";

/**
 * PurchaseOrders.
 */
export class PurchaseOrder {
    id: number;
    purchaseOrderNumber: string;

    constructor(id: number, purchaseOrderNumber: string) {
        this.id = id;
        this.purchaseOrderNumber = purchaseOrderNumber;
    }
}

/**
 * PurchaseOrders.
 */
export class PurchaseOrderView {
    id: number;
    purchaseOrderNumber: string;

    constructor(id: number, purchaseOrderNumber: string) {
        this.id = id;
        this.purchaseOrderNumber = purchaseOrderNumber;
    }
}

export class ClosePurchaseOrder {
    reasonCodeId: number;
    comment: string;

    constructor(reasonCodeId: number, comment: string) {
        this.reasonCodeId = reasonCodeId;
        this.comment = comment;
    }
}

export class BulkPurchaseOrder {
    vendorId: number;
    branchReferenceId: string;
    purchaseOrderItems: [];

    constructor(vendorId: number, branchReferenceId: string, purchaseOrderItems: []) {
        this.vendorId = vendorId;
        this.branchReferenceId = branchReferenceId;
        this.purchaseOrderItems = purchaseOrderItems;
    }
}

export class EditPOItemQuantity {
    order: number;
    total: number;

    constructor(order: number, total: number) {
        this.order = order;
        this.total = total;
    }
}

/** PO Item interface */
export interface PurchaseOrderItem {
}

/**
 * REST interface provided for purchase_orders.
 */
export interface PurchaseOrdersRestApi {
    getPurchaseOrderSummary(purchaseOrderNumber: string): AxiosPromise<PurchaseOrder>;

    getPurchaseOrderByPurchaseOrderNumber(purchaseOrderNumber: string): AxiosPromise<PurchaseOrder>;

    getAllPurchaseOrders(): AxiosPromise<PurchaseOrder>;

    getOpenPurchaseOrders(): AxiosPromise<PurchaseOrder>;

    getAllPurchaseOrdersViews(): AxiosPromise<PurchaseOrderView>;

    getOpenPurchaseOrdersViews(): AxiosPromise<PurchaseOrderView>;

    createPurchaseOrder(order: PurchaseOrder): AxiosPromise<PurchaseOrder>;

    updatePurchaseOrder(purchaseOrderNumber: string, updated: PurchaseOrder): AxiosPromise<PurchaseOrder>;

    updatePurchaseOrderItemEta(itemId: number, eta: string): AxiosPromise<PurchaseOrderItem>;

    deletePurchaseOrder(purchaseOrderNumber: string): AxiosPromise<PurchaseOrder>;

    closePurchaseOrder(purchaseOrderNumber: string, updated: ClosePurchaseOrder): AxiosPromise<ClosePurchaseOrder>;

    getPurchaseOrderItems(purchaseOrderNumber: string): AxiosPromise<PurchaseOrderItem>;

    closePurchaseOrderItem(purchaseOrderItemId: number, closeItemRequest: ClosePurchaseOrder): AxiosPromise<ClosePurchaseOrder>;

    editQuantityOrdered(purchaseOrderItemId: number, updated: EditPOItemQuantity): AxiosPromise<PurchaseOrder>;

    createBulkPurchaseOrder(bulkPoRequest: BulkPurchaseOrder): AxiosPromise<BulkPurchaseOrder>;
}

/**
 * REST implementation provided for purchase_orders.
 */
class Implementation implements PurchaseOrdersRestApi {

    getAllPurchaseOrders(): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().get<PurchaseOrder>("/purchase_orders");
    }

    getOpenPurchaseOrders(): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().get<PurchaseOrder>("/purchase_orders/open");
    }

    getAllPurchaseOrdersViews(): AxiosPromise<PurchaseOrderView> {
        return Axios.axiosInstance().get<PurchaseOrderView>("/purchase_orders/id");
    }

    getOpenPurchaseOrdersViews(): AxiosPromise<PurchaseOrderView> {
        return Axios.axiosInstance().get<PurchaseOrderView>("/purchase_orders/id/open");
    }

    getPurchaseOrderSummary(purchaseOrderNumber: string): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().get<PurchaseOrder>(`/purchase_orders/${purchaseOrderNumber}/summary`);
    }

    getPurchaseOrderByPurchaseOrderNumber(purchaseOrderNumber: string): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().get<PurchaseOrder>(`/purchase_orders/ordernumber/${purchaseOrderNumber}/summary`);
    }

    createPurchaseOrder(order: PurchaseOrder): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().post<PurchaseOrder>(`/purchase_orders`, order);
    }

    updatePurchaseOrder(purchaseOrderNumber: string, updated: PurchaseOrder): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().put<PurchaseOrder>(`/purchase_orders/${purchaseOrderNumber}`, updated);
    }

    updatePurchaseOrderItemEta(itemId: number, eta: string): AxiosPromise<PurchaseOrderItem> {
        return Axios.axiosInstance().patch<PurchaseOrderItem>(`/purchase_orders/item/${itemId}/eta`, {eta: eta});
    }

    deletePurchaseOrder(purchaseOrderNumber: string): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().delete(`/purchase_orders/${purchaseOrderNumber}`);
    }

    getActivityLogs(purchaseOrderNumber: string): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>(`/purchase_orders/${purchaseOrderNumber}/logs`);
    }

    closePurchaseOrder(purchaseOrderNumber: string, closeRequest: ClosePurchaseOrder): AxiosPromise<ClosePurchaseOrder> {
        return Axios.axiosInstance().post<ClosePurchaseOrder>(`/purchase_orders/close/${purchaseOrderNumber}`, closeRequest);
    }

    getPurchaseOrderItems(purchaseOrderNumber: string): AxiosPromise<PurchaseOrderItem> {
        return Axios.axiosInstance().get<PurchaseOrderItem>(`/purchase_orders/${purchaseOrderNumber}/items`);
    }

    closePurchaseOrderItem(purchaseOrderItemId: number, closeItemRequest: ClosePurchaseOrder): AxiosPromise<ClosePurchaseOrder> {
        return Axios.axiosInstance().patch<ClosePurchaseOrder>(`/purchase_orders/close/${purchaseOrderItemId}`, closeItemRequest);
    }

    editQuantityOrdered(purchaseOrderItemId: number, updated: EditPOItemQuantity): AxiosPromise<PurchaseOrder> {
        return Axios.axiosInstance().put<any>(`/purchase_orders/edit/${purchaseOrderItemId}/quantity`, updated);
    }

    createBulkPurchaseOrder(bulkPoRequest: BulkPurchaseOrder): AxiosPromise<BulkPurchaseOrder> {
        return Axios.axiosInstance().post<BulkPurchaseOrder>(`/purchase_orders/create/bulk`, bulkPoRequest);
    }
}

const PurchaseOrders = {
    getRestApi(): PurchaseOrdersRestApi {
        return new Implementation();
    },
};

export default PurchaseOrders;
