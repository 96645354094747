<template>
  <navigation-view ref="nav" page-title="Market Details" nav-section="admin">
    <template v-slot:content>
      <v-container grid-list-lg fluid>
        <market-read-only :market="market"/>

        <inline-grids
            ref="inlineGrids"
            :marketReferenceId="marketReferenceId"
            :zipCodes="zipCodes"
            @refreshZipCodes="getMarket"
            @showError="showError"
            @showSuccess="showSuccess"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <market-update-drawer
          :market="market"
          @cancelClicked="onCancel"
          @onUpdateSuccess="onUpdateSuccess"
          @onUpdateFail="showError"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Market"
          @click="showDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>
import Markets from "@/rest/markets.ts";
import MarketReadOnly from "@/components/markets/MarketReadOnly.vue";
import MarketUpdateDrawer from "@/components/markets/MarketUpdateDrawer.vue";
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import InlineGrids from "@/views/markets/MarketsInlineGrids.vue";
import Roles from "../../assets/roles";

export default {
  components: {
    MarketReadOnly,
    MarketUpdateDrawer,
    NavigationView,
    NavigationActionButton,
    InlineGrids
  },

  data() {
    return {
      market: {},
      marketId: 0,
      marketReferenceId: null,
      zipCodes: []
    };
  },

  created() {
    this.display(this.$route.params.marketReferenceId);
  },

  // Called when component is reused.
  beforeRouteUpdate(to, from, next) {
    this.display(to.params.marketReferenceId);
    next();
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    display(marketReferenceId) {
      this.marketReferenceId = marketReferenceId;
      this.getMarket();
    },
    async getMarket() {
      try {
        const response = await Markets.getRestApi().getMarket(this.marketReferenceId);
        this.setMarket(response.data);
      } catch (error) {
        this.showError(error);
      }
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    setMarket(data) {
      this.market = data;
      this.zipCodes = data.marketZipCodeValues;
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    onUpdateSuccess(data) {
      const message = "Updated market details successfully!";
      this.showSuccess(message);
      this.$refs.nav.hideDrawer();
      if (data.referenceId !== this.marketReferenceId) {
        this.$router.push(`/markets/details/${data.referenceId}`);
      } else {
        this.getMarket();
        this.refreshActivityLog();
      }
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    showSuccess(message) {
      this.$refs.nav.showSuccess(message);
    }
  }
};
</script>
