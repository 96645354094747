<template>
  <modal-dialog
      ref="dialog"
      title="Add Vendor"
      :width="defaultWidth"
      :minHeight="minHeight"
      :visible="dialogVisible"
      :loading="loading"
      :submitEnabled="true"
      submitLabel="Add Vendor"
      cancelLabel="Cancel"
      @submitClicked="addNewVendorCostClicked"
      @cancelClicked="onCancelClicked"
  >
    <add-new-vendor-cost-form
        ref="addNewVendorAndVendorCost"
        :selectedVendor="null"
        :activeVendors="activeVendors"
        :isAddForm="true"
    />
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../ModalDialog.vue";
import Rules from "../../../../assets/rules";
import AddNewVendorCostForm from "./AddNewVendorCostForm.vue";

const formDefaults = {
  dialogVisible: false,
  loading: false,
  vendorCost: null,
  vendorSku: null,
  error: null,
  minHeight: '320px',
  defaultWidth: '750px',
  datePickerRestrictionType: "day"
};

export default {
  components: {
    AddNewVendorCostForm,
    ModalDialog
  },

  props: {
    activeVendors: {
      type: Array,
      required: true,
    },
  },

  watch: {
    activeVendors: {
      immediate: true,
      handler(value) {
      }
    },
  },

  data() {
    return Object.assign(
      {
        rules: {
          required: Rules.required,
          max255: value => Rules.maxValue(value, 255),
          isCurrency: Rules.isCurrencyFormat
        }
      },
      formDefaults
    );
  },

  // TODO: consider combine it with {AddVendorCostForSelectedVendorDialog}
  methods: {
    resetToDefaults() {
      this.$refs.addNewVendorAndVendorCost.resetValidation();
      this.$refs.addNewVendorAndVendorCost.resetForm();
      Object.assign(this.$data, formDefaults);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.resetToDefaults();
    },
    showError(error) {
      this.error = error;
    },
    setLoadingFalse() {
      this.loading = false;
    },
    addNewVendorCostClicked() {
      const valid = this.$refs.addNewVendorAndVendorCost.validateForm();
      if (valid) {
        let formData = this.$refs.addNewVendorAndVendorCost.getFormData();
        this.loading = true;
        this.$emit('onAddNewVendorCost', formData);
      }
    },
    onCancelClicked() {
      this.resetToDefaults();
      this.closeDialog();
    }
  }
};
</script>
