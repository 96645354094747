<template>
  <div>
    <!-- top section for status -->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs2>
          <v-text-field
            label="Reference Id"
            :readonly="true"
            v-model="jobReferenceId"
            data-id="job-details-form-ref-id"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
        <v-flex />
        <v-flex xs2>
          <v-text-field
            label="Job Status"
            v-model="parentChildJobStatus"
            :readonly="true"
            data-id="job-details-form-status"
            placeholder=" "
            persistent-placeholder
          />
        </v-flex>
      </v-layout>
      <v-divider class="detailsDivider" />
    </v-flex>

    <!-- job and customer info -->
    <v-flex xs12>
      <v-layout row wrap>
        <v-flex xs5>
          <v-layout row wrap>
            <v-flex xs6>
              <v-text-field
                v-model="contractPrice"
                label="Contract Price"
                readonly
                prefix="$"
                id="job-details-read-only-contract-price"
                placeholder=" "
                persistent-placeholder
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="fullName"
                label="Customer Name"
                readonly
                id="customer-details-read-only-full-name"
                placeholder=" "
                persistent-placeholder
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- customer info -->
        <v-flex xs2></v-flex>
        <v-flex xs5>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                v-model="address"
                label="Address"
                readonly
                id="customer-details-read-only-address"
                placeholder=" "
                persistent-placeholder
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="city"
                label="City"
                readonly
                id="customer-details-read-only-city"
                placeholder=" "
                persistent-placeholder
              />
            </v-flex>
            <v-flex xs2>
              <v-text-field
                v-model="state"
                label="State"
                readonly
                id="customer-details-read-only-state"
                placeholder=" "
                persistent-placeholder
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="zipCode"
                label="Zip Code"
                readonly
                id="customer-details-read-only-zip-code"
                placeholder=" "
                persistent-placeholder
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
import Constants from "@/assets/constants";

const formDefaults = {
  jobReferenceId: null,
  jobStatusName: null,
  parentChildJobStatus: null,
  contractPrice: null,
  fullName: null,
  address: null,
  city: null,
  state: null,
  zipCode: null
};

export default {
  data() {
    return Object.assign({}, formDefaults);
  },

  methods: {
    loadForm: function(jobData) {
      const { job, customer, parentChildJobStatus } = jobData;
      this.$data.jobReferenceId = job.referenceId;
      this.$data.parentChildJobStatus = parentChildJobStatus;
      this.$data.contractPrice = job.contractPrice;
      this.$data.fullName = customer.fullName;
      this.$data.address = customer.address;
      this.$data.city = customer.city;
      this.$data.state = customer.state;
      this.$data.zipCode = customer.zipCode;
    }
  }
};
</script>
