<template>
  <v-autocomplete
      v-model="selected"
      :search-input.sync="searchInput"
      @input="searchInput=null"
      item-text="name"
      :item-value="isIdRequired"
      :placeholder="placeholder"
      :rules="[rules.requiredBasedOnProps]"
      :items="dropdownOptions"
      :loading="loading"
      :label="label || 'Branch'"
      :readonly="readonly"
      :multiple="multiple"
      :required="required"
      :disabled="disabled"
      :clearable="clearable || false"
      data-id="branches-autocomplete"
      light
      dense
      attach
      hide-no-data
      validate-on-blur
      persistent-placeholder
      :return-object="isReturnObject"
      :menu-props="menuProps"
  >
    <template v-slot:prepend-item class="select-all" v-if="selectAllEnabled && dropdownOptionsEnabled.length > 0">
      <select-all-autocomplete
          :items="dropdownOptions"
          :selected="selected"
          @select="selectAll"
          @deselect="deselectAll"
      >
      </select-all-autocomplete>
    </template>
  </v-autocomplete>
</template>

<script>
import Branches from "../../rest/branches";
import SelectAllAutocomplete from "../../components/searchFields/selectAllForDropdowns/AutoCompleteSelectAllDropdownTemplate.vue";
import Rules from "../../assets/rules";

export default {
  components: {
    SelectAllAutocomplete
  },

  data() {
    return {
      selected: this.value || null,
      branches: [],
      loading: false,
      rules: {
        requiredBasedOnProps: (value) => {
          if (this.multiple && this.required) {
            return Rules.requiredArray(value);
          } else if (this.multiple && !this.required) {
            return true;
          } else {
            return Rules.required(value);
          }
        },
      },
      searchInput: null,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Array],
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: 'Branch'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    getId: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    selectAllEnabled: {
      type: Boolean,
      default: false,
      required: true
    },
    isBranchesMappedById: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select Branch'
    },
    defaultBranches: {
      type: Array,
      required: false
    },
    getBranchesOnComponentCreation: {
      type: Boolean,
      required: false,
      default: true
    },
    isReturnObject: {
      type: Boolean,
      required: false,
      default: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => {}
    },
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit('inputSelected', value);
    },
  },

  created() {
    if (this.getBranchesOnComponentCreation) {
      this.getBranches();
    }
  },

  computed: {
    isIdRequired() {
      return this.getId ? "id" : "referenceId";
    },
    dropdownOptions() {
      return (!this.getBranchesOnComponentCreation && this.defaultBranches.length > 0)
        ? this.defaultBranches
        : this.branches;
    },
    dropdownOptionsEnabled() {
      return this.dropdownOptions.filter(item => !item.disabled);
    },
  },

  methods: {
    async getBranches() {
      try {
        const response = await Branches.getRestApi().getAllUserBranches();
        this.branches = response.data.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.emitError(error);
      }
    },
    clearSelectedBranch() {
      this.selected = null;
    },
    reset() {
      this.selected = null;
    },
    selectAll() {
      const availableOptions = this.dropdownOptions.filter(item => !item.disabled);
      if (this.$props.isBranchesMappedById) {
        this.selected = availableOptions.map(item => item.id);
      } else {
        this.selected = availableOptions.map(item => item.referenceId);
      }
    },
    emitError(error) {
      this.$emit('showError', error);
    },
    deselectAll() {
      this.selected = [];
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-list-item {
    width: 50%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
</style>
