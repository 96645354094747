<template>
  <v-dialog v-model="wizardVisible" persistent :width="800">
    <v-app-bar dense flat dark class="mb-0" color="primary">
      <v-app-bar-title>Remove Transfer Request from Shipment</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <stepper-headers
          :step="step"
          step-one-name="Password"
          step-two-name=" Confirmation"
          :maxStepNumber=2
          :editable="false"
      />

      <v-stepper-items>
        <v-stepper-content step="1">
          <validate-special-user-password-form
              ref="passwordForm"
              @passwordEmpty="passwordChangeHandler"
          />
          <span class="submitError" v-show="passwordError"> {{ passwordError }}</span>
          <button-container
              backName=Cancel
              nextName=Next
              :disableNextStep="isPasswordFieldEmpty"
              @getPreviousStep="resetWizard"
              @getNextStep="goToStepTwo"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-container class="mt-1 mb-2">
            <v-row>
              <v-col>
                 <span> Are you sure you want to remove transfer request <b>{{ selectedRowData.transferNumber }} </b>
                   from the shipment <b> {{
                       selectedRowData.shipmentNumber
                     }} </b> with <b> {{ selectedRowData.pickUpDate }} </b>
                   pickup date?
                 </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>
                  If you do this, it means that the inventory included in this request will not be transferred to
                  another warehouse unless you add the request to another shipment.
                </p>
              </v-col>
            </v-row>
          </v-container>

          <button-container-with-cancel
              nextName=Confirm
              cancelName=Cancel
              :hidePreviousStep="true"
              @cancel="resetWizard"
              @getNextStep="onSubmitClicked"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "../common/wizard/ButtonContainer.vue";
import ButtonContainerWithCancel from "../common/wizard/ButtonContainerWithCancel.vue";
import ValidateSpecialUserPasswordForm from "../common/form/ValidateSpecialUserPasswordForm.vue";
import StepperHeaders from "../common/stepper/headers/ConfigurableStepperHeaders.vue";

export default {
  components: {
    StepperHeaders,
    ValidateSpecialUserPasswordForm,
    ButtonContainerWithCancel,
    ButtonContainer
  },

  data() {
    return Object.assign(
        {},
        {
          wizardVisible: false,
          selectedRowData: null,
          step: 1,
          passwordError: null,
          submitDisabled: true,
          isPasswordFieldEmpty: true
        }
    );
  },

  methods: {
    passwordChangeHandler(value) {
      this.isPasswordFieldEmpty = value;
    },
    onSubmitClicked() {
      this.$emit('removeTransferRequestSuccess', this.selectedRowData.transferNumber);
    },
    openRemoveTransferRequestFromShipmentDialog(rowData) {
      this.selectedRowData = rowData;
      this.wizardVisible = true;
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    goToStep(step) {
      this.step = step;
    },
    async goToStepTwo() {
      let valid = await this.$refs.passwordForm.validatePasswordForm();
      if (valid) {
        this.passwordError = null;
        this.goToStep(2);
      } else {
        this.passwordError = 'Password is empty or does not match';
      }
    },
    resetWizard() {
      this.step = 1;
      this.isPasswordFieldEmpty = true;
      this.submitDisabled = true;
      this.closeDialog();
      if (this.$refs.passwordForm) {
        this.$refs.passwordForm.loadDefaults();
      }
    },
  }
};
</script>
<style scoped>
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
