<template>
  <div>
    <inline-grid
        ref="gridSummaryContainer"
        id="gridSummaryContainer"
        :data-source="dataSource"
        :show-borders="false"
        :hoverStateEnabled="true"
        :hide-delete="hideDeleteTransferRequests"
        no-data-text="Transfer Requests not found"
        key-expr="transferNumber"
        @deleteClicked="onDeleteRow"
    >
      <dx-editing
          :allow-updating="false"
          :allow-adding="false"
          :allow-deleting="true"
          mode="row"
      />
      <DxGroupPanel :visible="isGroupPanelVisible"/>
      <DxGrouping :auto-expand-all="autoExpandAll"/>
      <DxPaging :page-size="10"/>
      <dx-pager
          :show-page-size-selector="true"
          :show-info="true"
          infoText="Page {0} of {1} - {2} items"
      />
      <dx-column
          data-field="createdAt"
          caption="Creation Date"
          data-type="date"
          format="MM/dd/yyyy"
          :allowHeaderFiltering="false"
          :allowSorting="false"
      />
      <dx-column
          data-field="transferNumber"
          caption="Transfer Request Number"
          data-type="string"
          :allowHeaderFiltering="false"
          :allowSorting="false"
          :cell-template="clickableTransferNumberCellTemplate"
      />
      <dx-column
          data-field="transferFromBranch"
          caption="Transfer From"
          data-type="string"
          :allowHeaderFiltering="false"
          :allowSorting="false"
      />
      <dx-column
          data-field="transferToBranch"
          caption="Transfer To"
          data-type="string"
          :allowHeaderFiltering="false"
          :allowSorting="false"
      />
      <dx-column
          :group-index="0"
          data-field="transferNumber"
          :calculate-cell-value="calculateCellValue"
      />
      <dx-column
          data-field="pickUpDate"
          caption="Pickup Date"
          format="MM/dd/yyyy"
          data-type="date"
          :allowHeaderFiltering="false"
          :allowSorting="false"
      />
      <dx-column
          data-field="estimatedDeliveryDate"
          caption="Estimated Delivery Date"
          format="MM/dd/yyyy"
          data-type="date"
          :allowHeaderFiltering="false"
          :allowSorting="false"
      />
      <dx-column
          v-if="canDeleteTransferRequestItems"
          data-field="id"
          width="5%"
          caption
          cellTemplate="delete"
          :allowSorting="false"
      />
    </inline-grid>

    <add-transfer-requests-container
        ref="addTransferRequest"
        :shipment="shipment"
        @addTransferRequestsClicked="onAddTransferRequestsToShipment"
    />

    <inline-grid-footer v-if="canAddTransferRequest">
      <inline-grid-button
          v-if="canAddTransferRequest"
          :loading="loading"
          label="Add Transfer Request"
          @click="addTransferRequest"
      />
    </inline-grid-footer>

    <div class="options" v-if="shipment.transferRequests.length > 1">
      <div class="option">
        <dx-check-box
            id="autoExpand"
            v-model="autoExpandAll"
            text="Expand All Groups"
        />
      </div>
    </div>

    <remove-transfer-request-from-shipment-wizard
        ref="removeTransferRequestWizard"
        @removeTransferRequestSuccess="onRemoveTransferRequestSuccess"
    />
  </div>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxGrouping,
  DxGroupPanel,
  DxPager,
  DxPaging
} from "devextreme-vue/ui/data-grid";
import DxCheckBox from 'devextreme-vue/check-box';
import InlineGridFooter from "../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../components/common/grid/InlineGridButton.vue";
import AddTransferRequestsContainer from "../../components/transferRequest/AddTransferRequestsContainer.vue";
import Shipments from "../../rest/shipments";
import TransferRequests from "../../rest/transferRequests";
import RemoveTransferRequestFromShipmentWizard
  from "../../components/transferRequest/RemoveTransferRequestFromShipmentWizard.vue";
import InlineGrid from "../../components/common/grid/InlineGrid.vue";
import {ShipmentStatuses} from "@/assets/statusIcons/statusIcons";
import Roles from "../../assets/roles";
import RouterHelper from "@/assets/routerHelper";

export default {
  components: {
    InlineGrid,
    RemoveTransferRequestFromShipmentWizard,
    AddTransferRequestsContainer,
    InlineGridButton,
    InlineGridFooter,
    DxDataGrid,
    DxColumn,
    DxCheckBox,
    DxGrouping,
    DxGroupPanel,
    DxPaging,
    DxPager,
    DxEditing
  },

  props: {
    shipment: Object
  },

  data() {
    return {
      autoExpandAll: true,
      loading: false
    };
  },

  computed: {
    canAddTransferRequest() {
      return (this.shipment.status === ShipmentStatuses.SCHEDULED.name || this.shipment.status === ShipmentStatuses.SCHEDULED.fullName)
          && Roles.isSuperAdminOrAdminUser();
    },
    dataSource() {
      return this.shipment.transferRequests;
    },
    isGroupPanelVisible() {
      return this.shipment.transferRequests.length > 0;
    },
    hideDeleteTransferRequests() {
      return !Roles.isAnyAdminUser() || this.shipment.status !== ShipmentStatuses.SCHEDULED.fullName;
    },
    canDeleteTransferRequestItems() {
      return Roles.isSuperAdminOrAdminUser();
    },
  },

  methods: {
    onDeleteRow(cellData) {
      cellData.cancel = true;
      let rowData = {
        transferNumber: cellData.key.transferNumber,
        shipmentNumber: this.shipment.shipmentNumber,
        pickUpDate: this.shipment.pickUpDate
      }
      this.$refs.removeTransferRequestWizard.openRemoveTransferRequestFromShipmentDialog(rowData);
    },
    addTransferRequest() {
      this.$refs.addTransferRequest.openDialog(this.shipment.shipFrom, this.shipment.shipTo);
    },
    calculateCellValue(rowData) {
      return rowData.transferNumber + ' Status: [' + rowData.status + ']';
    },
    async onAddTransferRequestsToShipment(selected) {
      let payload = this.buildPayload(selected);
      await this.addTransferRequestsToTheShipment(payload);
    },
    async addTransferRequestsToTheShipment(payload) {
      try {
        this.loading = true;
        const response = await Shipments.getRestApi().addTransferRequestsToShipment(this.shipment.shipmentNumber, payload);
        if (response) {
          this.$emit('transferRequestAdded');
          this.$refs.addTransferRequest.clearSelected();
        }
      } catch (error) {
        this.$emit('addTransfersError', error);
      } finally {
        this.loading = false;
      }
    },
    async removeTransferRequest(transferNumber) {
      try {
        await TransferRequests.getRestApi().removeTransferRequestsFromShipment(transferNumber);
        this.$emit('removeTransferRequestSuccess', transferNumber);
        this.$refs.removeTransferRequestWizard.resetWizard();
        this.$refs.addTransferRequest.clearSelected();
      } catch (error) {
        this.$emit('removeTransferRequestFromShipmentError', error);
      }
    },
    buildPayload(selected) {
      return {
        transferFrom: this.shipment.shipFromBranchId,
        transferTo: this.shipment.shipToBranchId,
        transferRequestIds: selected.map(item => item.Id)
      }
    },
    onRemoveTransferRequestSuccess(transferNumber) {
      this.removeTransferRequest(transferNumber);
    },
    clickableTransferNumberCellTemplate(container, options) {
      RouterHelper.createClickableLink(container, options, this.$router, '/inventory/transfer-requests');
    }
  },
};
</script>

<style scoped>
.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.option {
  margin-top: 10px;
}
</style>
