<template>
  <drawer-form
      ref="form"
      title="Edit Ticket"
      submitLabel="Save Ticket"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container grid-list-lg>
      <ticket-edit-form
          ref="ticketEditForm"
      />
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "../../components/common/form/DrawerForm.vue";
import Tickets from "../../rest/tickets";
import TicketEditForm from "../../components/tickets/TicketEditForm.vue";

export default {
  components: {
    DrawerForm,
    TicketEditForm
  },

  props: {
    ticket: Object
  },

  data() {
    return {
      ticketData: null,
      inProgress: false
    };
  },

  watch: {
    ticket(newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      this.ticketData = Object.assign(this.ticket);
      this.$refs.ticketEditForm.loadTicketFrom(this.ticketData);
    },
    onCancel() {
      this.refresh();
      this.$emit("cancelClicked");
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.updateTicket();
      }
    },
    async updateTicket() {
      try {
        this.inProgress = true;
        const payload = this.$refs.ticketEditForm.buildPayload();
        const response = await Tickets.getRestApi().updateTicket(this.ticket.referenceId, payload);
        this.$emit("onUpdateSuccess", response.data);
      } catch (error) {
        this.$emit("onUpdateFail", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
