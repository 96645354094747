<template>
  <modal-dialog
      ref="dialog"
      title="Add Transfer Request(s)"
      width="1100"
      :visible="dialogVisible"
      submitLabel="Add Transfer Request(s)"
      cancelLabel="Cancel"
      @submitClicked="onSaveButtonClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="submitEnabled"
  >
    <v-form ref="form">
      <v-container>
        <select-transfer-request-container
            ref="selectTransferRequestContainer"
            :fromBranchName="from"
            :toBranchName="to"
            @selected="onTransferRequestSelected"
            @enableSubmit="onEnableSubmit"
            @disableSubmit="onDisableSubmit"
        />
      </v-container>
    </v-form>
  </modal-dialog>
</template>
<script>
import ModalDialog from "../common/ModalDialog.vue";
import SelectTransferRequestContainer from "../../views/shipments/SelectTransferRequestContainer.vue";

const formDefaults = {
  dialogVisible: false
};

export default {
  components: {SelectTransferRequestContainer, ModalDialog},

  props: {
    shipment: Object
  },

  data() {
    return {
      dialogVisible: false,
      transferRequestsToBeAdded: [],
      from: null,
      to: null,
      submitEnabled: false
    };
  },

  methods: {
    onEnableSubmit() {
      this.submitEnabled = true;
    },
    onDisableSubmit() {
      this.submitEnabled = false;
    },
    openDialog(shipFrom, shipTo) {
      this.dialogVisible = true;
      this.from = shipFrom;
      this.to = shipTo;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.clearSelected();
    },
    clearSelected() {
      this.submitEnabled = false;
      this.transferRequestsToBeAdded = [];
      if (this.$refs.selectTransferRequestContainer) {
        this.$refs.selectTransferRequestContainer.reset();
      }
      this.from = null;
      this.to = null;
    },
    onTransferRequestSelected(value) {
      this.transferRequestsToBeAdded.push(value);
    },
    onSaveButtonClicked() {
     this.$emit('addTransferRequestsClicked', this.$refs.selectTransferRequestContainer.selected);
      this.closeDialog();
    },
    onCancelClicked() {
      this.dialogVisible = false;
      this.clearSelected();
    }
  }
};
</script>
