<template>
  <modal-dialog
    ref="dialog"
    title="Warning"
    width="550"
    :visible="dialogVisible"
    :submitEnabled="true"
    cancelLabel="OK"
    :hideSubmit="true"
    @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <slot>
          <div>{{ message }}</div>
        </slot>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

export default {
  data: () => ({
    dialogVisible: false,
    selected: null,
    next: null,
    error: null
  }),

  components: {
    ModalDialog
  },

  props: { message: String },

  methods: {
    reset() {
      this.selected = null;
    },
    openDialog() {
      this.reset();
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onSubmitClicked() {
      this.dialogVisible = false;
      this.$emit("accept");
    },
    onCancelClicked() {
      this.dialogVisible = false;
      this.$emit("cancel");
    }
  }
};
</script>
