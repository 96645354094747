<template>
  <inline-searchable-grid
      ref="inlineGrid"
      :data-source="dataSource"
      :pageSize="5"
      :show-borders="false"
      :showPager="true"
      @hideJobRelatedColumns="onHideJobRelatedColumns"
  >
    <dx-column
        data-field="ReservationType"
        caption="Reservation Type"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="TransferNumber"
        caption="Transfer Number"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        v-if="showJobRelatedColumns"
        data-field="JobReferenceId"
        caption="Job Reference Id"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        v-if="showJobRelatedColumns"
        data-field="LineItemReferenceId"
        caption="Line Item Reference Id"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        v-if="showJobRelatedColumns"
        data-field="CustomerName"
        caption="Customer Name"
        data-type="string"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="SupplierCost"
        data-type="number"
        caption="Vendor Cost"
        alignment="left"
        :format="format"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ReservationAmount"
        data-type="number"
        caption="Reserved Count"
        alignment="left"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="FulfillmentDate"
        :caption="caption"
        :header-cell-template="headerCellTemplate"
        data-type="date"
        alignment="center"
        format="MM/dd/yyyy"
        :minWidth="160"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="CheckedOut"
        caption="Checked Out"
        data-type="boolean"
        :allowHeaderFiltering="false"
    />
  </inline-searchable-grid>
</template>

<script>
import InlineSearchableGrid from "../../../components/common/grid/InlineSearchableGrid.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "@/rest/odataContext";

export default {
  components: {
    InlineSearchableGrid,
    DxColumn
  },

  props: {
    inventoryItemId: {
      type: [String, Number],
      required: true
    }
  },

  watch: {
    inventoryItemId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.setFilterAndReloadDataSource(["InventoryItemId", "=", parseInt(value)]);
        }
      }
    }
  },

  data() {
    return {
      caption: "Fulfillment Date /\r\nTransfer Creation Date",
      format: {
        type: "currency",
        precision: 2
      },
      dataSource: {
        store: ODataContext['InventoryReservations'],
        select: [
          "Id",
          "InventoryItemId",
          "JobReferenceId",
          "LineItemReferenceId",
          "CustomerName",
          "FulfillmentDate",
          "CheckedOut",
          "SupplierCost",
          "ReservationAmount",
          "ReservationType",
          "TransferNumber"
        ],
        filter: ["InventoryItemId", "=", parseInt(this.inventoryItemId)]
      },
      showJobRelatedColumns: true
    };
  },

  methods: {
    setFilterAndReloadDataSource(filter) {
      const gridInstance = this.getGridInstance();
      this.reloadDataSourceWithFilter(gridInstance, filter);
    },
    getGridInstance() {
      return this.$refs.inlineGrid.getGridInstance();
    },
    reloadDataSourceWithFilter(gridInstance, filter) {
      gridInstance.filter(filter);
      gridInstance.refresh();
    },
    clearGrid(updatedInventoryItemId) {
      updatedInventoryItemId
          ? this.$refs.inlineGrid.clearSortingAndFiltering(updatedInventoryItemId)
          : this.$refs.inlineGrid.clearSortingAndFiltering(this.inventoryItemId);
    },
    // 2-row column header template
    headerCellTemplate(header, info) {
      const div = document.createElement('div');
      div.innerHTML = info.column.caption.replace(/\r\n/g, "<br/>");
      div.style.textAlign = 'center';
      header.appendChild(div);
    },
    onHideJobRelatedColumns() {
      this.showJobRelatedColumns = false;
    }
  }
};
</script>
