<template>
  <v-stepper-header>
    <v-stepper-step :complete="step > 1" step="1">Enter Note</v-stepper-step>
    <v-divider></v-divider>
    <v-stepper-step :complete="step > 2" step="2">Customer</v-stepper-step>
    <v-divider></v-divider>
    <v-stepper-step :complete="step > 3" step="3">50Floor</v-stepper-step>
    <v-divider></v-divider>
    <v-stepper-step :complete="step > 4" step="4">Third Party</v-stepper-step>
    <v-divider></v-divider>
    <v-stepper-step :complete="step > 5" step="5">Chargeback</v-stepper-step>
  </v-stepper-header>
</template>

<script>
export default {
  props: { step: Number },
};
</script>
