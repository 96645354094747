<template>
  <navigation-view ref="nav" page-title="List TimeBlocks" nav-section="admin">
    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="true"
          label="Create Time Block"
          @click="onShowDrawer"
      />
    </template>

    <template v-slot:drawer>
      <sales-rep-time-block-drawer
          v-if="showTimeBlockDrawer"
          ref="timeBlockDrawer"
          :startDate="date"
          :marketId="marketId"
          :markets="markets"
          :timeBlock="selectedTimeBlock"
          :selectedWeekDay="selectedWeekDay"
          :availableSlots="allSlots"
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>

    <template v-slot:content>
      <sales-rep-time-block-calendar
          ref="timeBlockCalendar"
          :filterBySalesRep="true"
          :filterByMarket="true"
          :show-done-button="false"
          :markets="markets"
          @dateClicked="handleDateClick"
          @timeblockClicked="handleEventClick"
          @marketClicked="handleMarketClick"
      />
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import SalesRepTimeBlockDrawer from "@/components/salesRepTimeblock/SalesRepTimeBlockDrawer.vue";
import SalesRepTimeBlockCalendar from "@/components/salesRepTimeblock/SalesRepTimeBlockCalendar.vue";
import Markets from "@/rest/markets";
import SalesRepsTimeBlocks from "@/rest/salesRepsTimeBlock";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    SalesRepTimeBlockDrawer,
    SalesRepTimeBlockCalendar
  },

  async created() {
    this.markets = await this.getAllMarkets();
    this.allSlots = await this.getPossibleTimeSlots();
  },

  data() {
    return {
      date: null,
      selectedTimeBlock: null,
      marketId: null,
      showTimeBlockDrawer: false,
      markets: [],
      filteredByBranch: true,
      selectedWeekDay: null,
      allSlots: [],
    };
  },

  methods: {
    onSubmitSuccess() {
      this.hideDrawer();
      this.$refs.nav.showSuccess("Created new time block successfully!"); // the same is for update and delete block
      this.setDefaults();
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    onCancel() {
      this.hideDrawer();
      this.resetFrom();
    },
    setDefaults() {
      this.$refs.timeBlockCalendar.getTimeBlocks();
      this.resetFrom();
    },
    resetFrom() {
      this.selectedTimeBlock = null;
      this.showTimeBlockDrawer = false;
      this.selectedWeekDay = null;
    },
    handleDateClick(selectedDate) {
      this.date = selectedDate.date;
      this.selectedWeekDay = selectedDate.weekDay;
      this.onShowDrawer();
    },
    handleEventClick(timeBlockClicked) {
      this.selectedTimeBlock = timeBlockClicked;
      this.onShowDrawer();
    },
    handleMarketClick(marketClicked) {
      this.marketId = marketClicked;
    },
    onShowDrawer() {
      this.showTimeBlockDrawer = true;
      this.$refs.nav.showDrawer();
    },
    hideDrawer() {
      this.date = null;
      this.$refs.nav.hideDrawer();
    },
    async getAllMarkets() {
      let response;
      if (this.filteredByBranch) {
        response = await Markets.getRestApi().getAllMarketsFilteredByBranch();
      } else {
        response = await Markets.getRestApi().getAllActiveMarkets();
      }
      return response.data;
    },
    async getPossibleTimeSlots() {
      try {
        let response = await SalesRepsTimeBlocks.getRestApi().getSalesRepresentativeTimeBlocksPossibleSlots();
        return response.data;
      } catch (error) {
        this.showError(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.headline {
  color: #78909c;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}

.tempSearchContainer {
  padding-top: 10px;
  padding-bottom: 60px;
}

.searchIcon {
  font-size: 150px;
}

.checkInButton {
  width: 100px;
}

.searchError,
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
