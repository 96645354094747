<template>
  <div>
    <inline-grid
        ref="crewsInlineGrid"
        :data-source="crews"
        :pageSize="5"
        :showPager="true"
        :show-borders="false"
        :hoverStateEnabled="true"
        @rowSelected="onRowSelected"
    >
      <dx-column
          data-field="referenceId"
          caption="Crew ID"
          width="auto"
          alignment="left"
      />
      <dx-column
          data-field="name"
          caption="Full name"
          width="auto"
          alignment="left"
      />
      <dx-column
          :calculate-cell-value="calculateMaskedPhoneNumber"
          caption="Phone number"
          width="auto"
          alignment="left"
          :allow-sorting="true"
      />
      <dx-column
          data-field="email"
          caption="Email"
          width="auto"
          alignment="left"
      />
      <dx-column
          :calculate-cell-value="calculateBranchName"
          caption="Primary Branch"
          width="auto"
          alignment="left"
      />
      <dx-column
          data-field="secondaryBranch"
          caption="Secondary Branch"
          width="auto"
          alignment="left"
          :allow-sorting="true"
      />
      <dx-column
          :calculate-cell-value="calculateCapability"
          caption="Capabilities"
          width="15%"
          alignment="left"
          :allow-sorting="true"
      />
      <dx-column
          data-field="workingDaysToShow"
          caption="Working days"
          width="auto"
          alignment="left"
      />
      <dx-column
          :calculate-cell-value="calculateStatus"
          :cell-template="StatusRenderer"
          caption="Status"
          width="auto"
          alignment="left"
          :allow-sorting="true"
      />
      <dx-column
          data-field="mfaEnabled"
          data-type="boolean"
          cell-template="booleanStatusCellTemplate"
          caption="MFA Enabled"
          width="auto"
          alignment="left"
      />
      <dx-column
          data-field="mfaSetUp"
          data-type="boolean"
          cell-template="booleanStatusCellTemplate"
          caption="MFA Set Up"
          width="auto"
          alignment="left"
      />

      <template v-slot:footer>
        <inline-grid-footer>
          <inline-grid-button
              label="Add Crew"
              @click="onAddCrewButtonClick"
          />
        </inline-grid-footer>
      </template>
    </inline-grid>

    <add-crew-dialog
        ref="addCrewDialog"
        :capabilities="capabilities"
        :branch="branch"
        :installer="installer"
        :branches="branches"
        @saveCrewButtonClicked="onEmitSaveCrew"
        @emitError="emitError"
    />
    <update-crew-dialog
        ref="updateCrewDialog"
        :capabilities="capabilities"
        :branches="branches"
        :installer="installer"
        @updateCrewButtonClicked="onEmitUpdateCrew"
        @emitError="emitError"
        @passwordOverriddenSuccessfully="passwordOverriddenSuccessfully"
        @cancelUpdateCrewFormClicked="onCancelUpdateCrew"
        @resetMfaSuccess="onResetMfaSuccess"
    />
  </div>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGrid from "../../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../../components/common/grid/InlineGridButton.vue";
import UpdateCrewDialog from "./UpdateCrewDialog.vue";
import AddCrewDialog from "./AddCrewDialog.vue";
import PhoneMask from "../../../assets/phoneMask";
import StatusRenderer from "../../../components/common/grid/StatusRenderer";

export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    AddCrewDialog,
    UpdateCrewDialog
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    capabilities: {
      type: Array,
      required: true
    },
    branch: {
      type: Object,
      required: true
    },
    branches: {
      type: Array,
      required: true
    },
    installer: {
      type: Object,
      required: true
    }
  },

  created() {
    this.emitGetCrews();
  },

  data() {
    return {
    };
  },

  methods: {
    onRowSelected(data) {
      if (data) {
        data.branch = this.branch;
        this.$refs.updateCrewDialog.loadForm(data);
        this.$refs.updateCrewDialog.openUpdateCrewDialog();
      }
    },
    onAddCrewButtonClick() {
      this.$refs.addCrewDialog.openDialog();
    },
    onEmitSaveCrew(crew) {
      this.$emit('saveCrew', crew);
    },
    onEmitUpdateCrew(crew) {
      this.$emit('updateCrew', crew);
    },
    async emitGetCrews() {
      this.$emit('getCrews');
    },
    emitError(error) {
      this.$emit('emitError', error);
    },
    passwordOverriddenSuccessfully() {
      this.$emit('emitPasswordOverriddenSuccess');
    },
    onSuccessSave() {
      this.closeCreateCrewDialog();
    },
    onUpdateSave() {
      this.resetUpdateCrewForm();
      this.closeUpdateCrewDialog();
    },
    onCreateError() {
      this.$refs.addCrewDialog.stopLoading();
    },
    onUpdateError() {
      this.$refs.updateCrewDialog.stopLoading();
    },
    closeCreateCrewDialog() {
      this.$refs.addCrewDialog.closeDialog();
    },
    resetUpdateCrewForm() {
      this.$refs.updateCrewDialog.reset();
    },
    closeUpdateCrewDialog() {
      this.$refs.updateCrewDialog.closeUpdateCrewDialog();
    },
    calculateStatus(cellInfo) {
      return cellInfo.active ? "Active" : "Inactive";
    },
    calculateCapability(rowInfo) {
      return rowInfo.capabilityIds
          .map(capabilityId => this.capabilities.find(i => i.id === capabilityId).name)
          .join(",");
    },
    calculateBranchName() {
      return this.branch.name;
    },
    calculateMaskedPhoneNumber(rowInfo) {
      return PhoneMask.maskPhone(rowInfo.phoneNumber);
    },
    onCancelUpdateCrew() {
      this.deselectItems();
    },
    deselectItems() {
      return this.$refs.crewsInlineGrid.clearSelection();
    },
    StatusRenderer,
    onResetMfaSuccess() {
      this.$emit('resetMfaSuccess');
    }
  }
};
</script>
