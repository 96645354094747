<template>
  <v-select
      v-model="selectedReasonCodeId"
      :items="reassignSalesReasonCodes"
      label="* Reason Code"
      item-text="name"
      item-value="id"
      placeholder=" "
      :disabled="disabled"
      persistent-placeholder
      :rules="[rules.requiredBasedOnPropsValue]"
      attach
  />
</template>

<script>
import Rules from "../../assets/rules";
import ReassignSalesReasonCodes from "../../rest/reassignSalesReasonCodes";

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  data() {
    return {
      error: null,
      reassignSalesReasonCodes: [],
      selectedReasonCodeId: null,
      rules: {
        requiredBasedOnPropsValue: (value) => Rules.requiredBasedOnPropsValue(value, this.required)
      },
    };
  },

  created() {
    this.getReassignSalesReasonCode();
  },

  watch: {
    selectedReasonCodeId(newVal) {
      this.$emit('select', newVal);
    }
  },

  methods: {
    restoreToDefaults() {
      this.selectedReasonCodeId = null;
      this.error = null;
    },
    async getReassignSalesReasonCode() {
      const response = await ReassignSalesReasonCodes.getRestApi().getReassignSalesReasonCode();
      this.reassignSalesReasonCodes = response.data;
    }
  }
}
</script>
