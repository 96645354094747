<template>
  <div :class="getItemClassNames">
    <div class="crew-name">{{ item.name }}</div>
    <div class="crew-capabilities">{{ formatCapabilities(item.capabilities) }}</div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    crewsAmount: {
      type: Number,
      required: false
    }
  },

  computed: {
    getItemClassNames() {
      return {
        'selected-dropdown-item': this.selected,
        'crews-dropdown-item': !this.selected,
        'item-border-bottom': this.crewsAmount > 1
      };
    }
  },

  methods: {
    formatCapabilities(capabilities) {
      return capabilities.map(capability => capability.name).join('; ');
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-dropdown-item,
.crews-dropdown-item {
  display: flex;
  padding: 5px 0;
  font-size: 14px;

  .crew-name {
    font-weight: bold;
    width: 180px;
    flex-shrink: 0;
  }

  .crew-capabilities {
    padding-left: 8px;
    border-left: 1px solid #E0E0E0;
  }
}

.selected-dropdown-item {
  width: 500px;
}

.crews-dropdown-item {
  width: 520px;
  min-height: 48px;
}

.item-border-bottom {
  border-bottom: 1px solid #E0E0E0;
}
</style>