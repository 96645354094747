<template>
  <v-container fluid class="mb-2" v-if="salesRep">
    <v-row>
      <v-col cols="2">
        <v-text-field
            label="Reference Id"
            placeholder=" "
            :value="salesRep.referenceId"
            readonly
            id="sales-rep-read-only-reference-id"
            persistent-placeholder
        />
      </v-col>
      <v-col cols="2" offset="4">
        <v-btn
            v-if="isSuperAdmin"
            dark
            color="primary"
            class="section-button"
            @click="openResetGplDialog"
        >
          Reset GPL
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-text-field
            id="enableAutomaticLeadAssignment"
            readonly
            :value="isAutomaticLeadAssignmentEnabled"
            label="Enable ALA"
            placeholder=" "
            persistent-placeholder
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
            :value="isMfaEnabled"
            label="MFA Enabled"
            placeholder=" "
            persistent-placeholder
            readonly
            id="is-mfa-enabled"
        />
      </v-col>
      <v-col cols="1">
        <v-text-field
            :value="isMfaSetUp"
            label="MFA Set Up"
            placeholder=" "
            persistent-placeholder
            readonly
            id="is-mfa-set-up"
        />
      </v-col>
      <v-col cols="1">
        <status-dropdown :value="salesRep.active" :readonly="true"/>
      </v-col>
    </v-row>

    <v-divider class="detailsDivider"/>

    <!--    left block -->
    <v-row>
      <v-col cols="5">
        <v-row>
          <v-col cols="12">
            <v-text-field
                id="sales-rep-company-name"
                readonly
                :value="salesRep.companyName"
                label="Company Name"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                id="sales-rep-first-name"
                readonly
                :value="salesRep.firstName"
                label="First Name"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                id="sales-rep-last-name"
                readonly
                :value="salesRep.lastName"
                label="Last Name"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                id="sales-rep-address"
                readonly
                :value="salesRep.address"
                label="Address"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
                id="sales-rep-city"
                readonly
                :value="salesRep.city"
                label="City"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
                id="sales-rep-state"
                readonly
                :value="salesRep.state"
                label="State"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
                id="sales-rep-zip-code"
                readonly
                :value="salesRep.zipCode"
                label="Zip Code"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2"/>

      <!-- right block-->
      <v-col cols="5">
        <v-row>
          <v-col cols="12">
            <v-text-field
                id="sales-rep-email"
                readonly
                :value="salesRep.email"
                label="Email"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                id="sales-rep-phone"
                readonly
                :value="salesRep.phone"
                label="Phone"
                placeholder=" "
                v-facade="phoneNumberMask"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                id="sales-rep-fax"
                readonly
                :value="salesRep.fax"
                label="Fax"
                placeholder=" "
                v-facade="phoneNumberMask"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
                id="sales-rep-markets"
                readonly
                :value="salesRep.marketNames"
                label="Markets"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="12" v-if="salesRep.automaticLeadAssignmentBranchName">
            <v-text-field
                id="sales-rep-branch"
                readonly
                :value="salesRep.automaticLeadAssignmentBranchName"
                label="Branch"
                placeholder=" "
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="salesRep.stateLicenseNumber"
                label="State License Number"
                placeholder=" "
                readonly
                id="sales-rep-state-license-number-readonly"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="licenseExpirationDateFormatted"
                label="License Expiration Date"
                placeholder=" "
                readonly
                id="sales-rep-state-license-expiration-date-readonly"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="salesRepTierName"
                label="Tier"
                placeholder=" "
                readonly
                id="sales-rep-state-tier-readonly"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="salesRep.gpl"
                label="GPL"
                placeholder=" "
                readonly
                id="sales-rep-gpl-readonly"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="salesRep.maxLeadPerDay"
                label="Max Leads Per day"
                placeholder=" "
                readonly
                id="sales-rep-max-lead-per-day-readonly"
                persistent-placeholder
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                :value="salesRep.gplStartDate"
                label="GPL Start Date"
                placeholder=" "
                readonly
                id="sales-gpl-start-date-readonly"
                persistent-placeholder
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <sales-representative-gpl-reset-dialog
        :dialogVisible=true
        @resetGPL=resetGPL
        ref="gplResetDialog"
    />
  </v-container>
</template>

<script>
import StatusDropdown from "@/components/common/form/StatusDropdown.vue";
import moment from "moment";
import Constants from "@/assets/constants";
import SalesRepresentatives from "@/rest/salesRepresentatives";
import SalesRepresentativeGplResetDialog from "@/components/salesRepresentatives/SalesRepresentativeGplResetDialog.vue";
import Roles from "@/assets/roles";

export default {
  components: {
    SalesRepresentativeGplResetDialog,
    StatusDropdown
  },

  props: {
    salesRep: Object,
    referenceId: {
      type: String
    }
  },

  computed: {
    licenseExpirationDateFormatted() {
      return this.$props.salesRep.licenseExpirationDate
          ? moment(this.$props.salesRep.licenseExpirationDate).format('MM/DD/YYYY')
          : ' ';
    },
    salesRepTierName() {
      return this.salesRep.tier ? this.salesRep.tier.name : '';
    },
    isSuperAdmin() {
      return Roles.isSuperAdminUser();
    },
    isMfaEnabled() {
      return this.salesRep.mfaEnabled ? 'Yes' : 'No';
    },
    isMfaSetUp() {
      return this.salesRep.mfaSetUp ? 'Yes' : 'No';
    },
    isAutomaticLeadAssignmentEnabled() {
      return this.salesRep.enableAutomaticLeadAssignment ? 'Yes' : 'No';
    }
  },

  methods: {
    openResetGplDialog() {
      this.$refs.gplResetDialog.openDialog(this.salesRep);
    },
    async resetGPL() {
      try {
        await SalesRepresentatives.getRestApi().resetGPLStartDate(this.salesRep.referenceId);
        this.$emit('onUpdateSuccess');
      } catch (error) {
        this.$emit('onUpdateFail', error);
      }
    }
  },

  data() {
    return {
      displayTaxId: false,
      phoneNumberMask: Constants.phoneNumberMask
    };
  }
}
;
</script>

