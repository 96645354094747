<template>
  <div ref="printable" id="printable" class="printable">
    <div class="printable-container">
      <div class="printable-header" id="print-title">
        <div class="printable-header-title">
          50 Floor - Job
        </div>
        <div v-if="date" class="printable-header-date">
          {{ date }}
        </div>
      </div>
      <div class="printable-grid">
        <div
          v-if="jobReferenceId"
          class="printable-field"
          id="print-job-ref-id"
        >
          <div class="printable-label">
            Job Reference ID:
          </div>
          <div class="printable-value">
            {{ jobReferenceId }}
          </div>
        </div>
        <div v-if="jobStatus" class="printable-field" id="print-job-status">
          <div class="printable-label">
            Job Status:
          </div>
          <div class="printable-value">
            {{ jobStatus }}
          </div>
        </div>
        <div
          v-if="installationDate"
          class="printable-field"
          id="print-install-date"
        >
          <div class="printable-label">
            Installation Date:
          </div>
          <div class="printable-value">
            {{ installationDate }}
          </div>
        </div>
        <div
          v-if="customerName"
          class="printable-field"
          id="print-customer-name"
        >
          <div class="printable-label">
            Customer Name:
          </div>
          <div class="printable-value">
            {{ customerName }}
          </div>
        </div>
        <div
          v-if="customerAddress"
          class="printable-field"
          id="print-customer-address"
        >
          <div class="printable-label">
            Customer Address:
          </div>
          <div class="printable-value">
            {{ customerAddress }}
          </div>
        </div>
        <div v-if="roomTypes" class="printable-field" id="print-room-types">
          <div class="printable-label">
            Room Types:
          </div>
          <div class="printable-value">
            {{ roomTypes }}
          </div>
        </div>
        <div
          v-if="productTypes"
          class="printable-field"
          id="print-product-types"
        >
          <div class="printable-label">
            Product Types:
          </div>
          <div class="printable-value">
            {{ productTypes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      qaDebug: false,
      date: null,
      jobReferenceId: null,
      jobStatus: null,
      installationDate: null,
      customerName: null,
      customerAddress: null,
      roomTypes: null,
      productTypes: null
    };
  },

  mounted() {
    this.initView();
  },

  methods: {
    initView: function() {
      let data = this.$store.getters.getPrintData;
      if (data) {
        this.$data.date = moment(new Date()).format("MM/DD/YYYY");
        this.$data.jobReferenceId = data.jobReferenceId
          ? data.jobReferenceId
          : null;
        this.$data.jobStatus = data.jobStatus ? data.jobStatus : null;
        this.$data.installationDate = data.installationDate
          ? data.installationDate
          : null;
        this.$data.customerName = data.customerName ? data.customerName : null;
        this.$data.customerAddress = data.customerAddress
          ? data.customerAddress
          : null;
        this.$data.roomTypes = data.roomTypes ? data.roomTypes : null;
        this.$data.productTypes = data.productTypes ? data.productTypes : null;
        this.$data.qaDebug = "qaDebug" in this.$route.query ? true : false;
        this.onAfterPrint();
        this.print();
      } else {
        this.$router.push("/sales/jobs");
      }
    },
    print: function() {
      if (!this.$data.qaDebug) {
        window.print();
      }
    },
    onAfterPrint: function() {
      window.onafterprint = this.close;
    },
    close: function() {
      if (!this.$data.qaDebug) {
        setTimeout(window.close, 500);
      }
    }
  }
};
</script>

<style>
.printable-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.printable {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
}

.printable-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5mm;
  padding-bottom: 1mm;
  border-bottom: 1px solid black;
}

.printable-header-title {
  display: flex;
  align-items: flex-end;
  font-size: 24px;
  font-weight: bold;
}

.printable-header-date {
  display: flex;
  align-items: flex-end;
  text-align: center;
  padding-left: 10mm;
}

.printable-grid {
  display: grid;
  grid-row-gap: 20px;
}

.printable-field {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 200px;
  grid-column-gap: 10px;
  font-size: 14px;
}

.printable-label {
  display: flex;
  font-weight: bold;
  align-items: flex-start;
  justify-content: flex-start;
}

.printable-value {
  display: flex;
  align-items: flex-start;
  text-align: left;
}
</style>
