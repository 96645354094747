import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {AuthenticationResponse} from "./users";

/**
 *  User Verification request with One-Time-Password (OPT)
 */
export interface UserVerificationRequest {
    email: string;
    otp: string;
    dontAskMfaAgainEnabled: boolean;
    password: string;
}

/**
 *  User Security Token Verification request (Email Verification) for setting up MFA
 */
export interface EmailTokenVerificationRequest {
    email: string;
    token: string;
}

/**
 * REST interface provided for user verification.
 */
export interface AuthenticationRestApi {
    verifyOTP(verificationRequest: UserVerificationRequest): AxiosPromise<AuthenticationResponse>;
    verifySecurityToken(tokenVerificationRequest: EmailTokenVerificationRequest): AxiosPromise<AuthenticationResponse>;
}

/**
 * REST implementation provided for OTP Verification (MFA implementation).
 */
class Implementation implements AuthenticationRestApi {
    verifyOTP(verificationRequest: UserVerificationRequest): AxiosPromise<AuthenticationResponse> {
        return Axios.axiosInstance().post<AuthenticationResponse>("authentication/otp/verify", verificationRequest);
    }

    verifySecurityToken(tokenVerificationRequest: EmailTokenVerificationRequest): AxiosPromise<AuthenticationResponse> {
        return Axios.axiosInstance().post<AuthenticationResponse>("authentication/security-token/verify", tokenVerificationRequest);
    }
}

const Authentication = {
    getRestApi(): AuthenticationRestApi {
        return new Implementation();
    }
};

export default Authentication;
