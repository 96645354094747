<template>
  <v-row md="12">
    <v-col md="6" v-if="isRolled">
      <v-text-field
          v-model="rollNumber"
          label="* Roll Number"
          placeholder=" "
          persistent-placeholder
          data-id="inventory-item-details-form-roll-number"
          :rules="[rules.required]"
      />
    </v-col>
    <v-col md="6" v-if="hasDyeLot">
      <v-text-field
          v-model="dyeLot"
          label="* Dye Lot"
          placeholder=" "
          persistent-placeholder
          data-id="inventory-item-details-form-dye-lot"
          :rules="[rules.required]"
      />
    </v-col>
  </v-row>
</template>

<script>
import Rules from "../../assets/rules";
import Bins from "../../rest/bins";

const formDefaults = {
  rollNumber: null,
  dyeLot: null,
};

export default {
  components: {},
  props: {
    isRolled: Boolean,
    hasDyeLot: Boolean
  },
  data() {
    return Object.assign({},
        formDefaults,
        {
          rules: {
            required: Rules.required
          }
        }
    );
  },

  methods: {
    buildPayload() {
      let payload = {};
      payload.rollNumber = this.rollNumber;
      payload.dyeLot = this.dyeLot;
      return payload;
    },
    loadForm(payload) {
      this.rollNumber = payload.rollNumber;
      this.dyeLot = payload.dyeLot;
      this.inventoryBinId = payload.inventoryBinId;
      this.getBinsByBranch(payload.inventoryBin.branchReferenceId);
    },
    async getBinsByBranch(branchReferenceId) {
      try {
        const response = await Bins.getRestApi().getBinsByBranchReferenceId(branchReferenceId);
        this.bins = response.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
