<template>
  <v-row class="ma-1">
    <v-col md="6">
      <star-rating-field ref="starRating" :value="rating"/>
    </v-col>
    <v-col md="6">
      <status-dropdown
          :value="active"
          @onStatusChange="changeStatus"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          label="* Company Name"
          v-model="companyName"
          placeholder=" "
          id="installer-form-company-name"
          :rules="[rules.required, rules.nonUniqueCompanyName, rules.max100]"
          maxlength="100"
          counter
          persistent-placeholder
          @onConflict="onNonUniqueNameConflict"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          label="Primary Contact Name"
          v-model="name"
          placeholder=" "
          id="installer-form-name"
          :rules="[rules.max40]"
          maxlength="40"
          counter
          persistent-placeholder
      />
    </v-col>
    <v-col md="12">
      <address-form-with-autocomplete ref="addressForm"/>
    </v-col>
    <v-col md="12">
      <branches-dropdown-autocomplete
          id="installer-branch"
          label="* Branch"
          :value="branchReferenceId"
          :branches="branches"
          :required="true"
          :selectAllEnabled="false"
          @inputSelected="updateBranchReferenceId"
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          :rules="[rules.required, rules.phoneNumber]"
          label="* Phone Number"
          v-model="phoneNumber"
          placeholder=" "
          v-facade="phoneNumberMask"
          id="installer-form-phone-number"
          persistent-placeholder
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          :rules="[rules.phoneNumber]"
          label="Fax Number"
          v-model="fax"
          placeholder=" "
          v-facade="phoneNumberMask"
          id="installer-form-fax-number"
          persistent-placeholder
      />
    </v-col>
    <v-col md="12">
      <v-text-field
          :rules="[rules.email]"
          label="Email"
          v-model="email"
          placeholder=" "
          id="installer-form-email"
          persistent-placeholder
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          :rules="[rules.required, rules.isPositiveNumber, rules.isCurrencyFormat]"
          label="* Retainage Amount"
          v-model="retainageAmount"
          type="number"
          placeholder="0.00"
          prefix="$"
          id="installer-form-retainage-amount"
          persistent-placeholder
      />
    </v-col>
    <v-col md="6">
      <v-text-field
          :rules="[rules.required, rules.isPositiveNumber]"
          label="* Retainage %"
          v-model="retainagePercentage"
          placeholder="0"
          type="number"
          id="installer-form-retainage-precent"
          persistent-placeholder
      />
    </v-col>
    <v-col md="6">
      <date-picker-with-type-in-text-field
          ref="wcExpDateChangePicker"
          label="* WC Exp Date"
          validationName="WC Exp Date"
          :value="wcExpDate"
          @input="onWcExpDateChange"
          id="installer-wc-exp-date"
          :allowedDates="allowedDates"
      />
    </v-col>
    <v-col md="6">
      <date-picker-with-type-in-text-field
          ref="generalLiabilityExpDatePicker"
          label="* General Liability Exp Date"
          validationName="General Liability Exp Date"
          :value="generalLiabilityExpDate"
          @input="onGeneralLiabilityExpDate"
          id="installer-general-liability-exp-date"
          :allowedDates="allowedDates"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import AddressFormWithAutocomplete from "../../components/common/form/AddressFormWithAutocomplete.vue";
import DatePickerWithTypeInTextField from "../../components/common/form/DatePickerWithTypeInTextField.vue";
import PhoneMask from "../../assets/phoneMask";
import BranchesDropdownAutocomplete from "../searchFields/BranchesDropdownAutocomplete.vue";
import Rules from "../../assets/rules";
import Constants from "../../assets/constants";
import StarRatingField from "../common/form/StarRatingField.vue";
import StatusDropdown from "../common/form/StatusDropdown.vue";

const formDefaults = {
  id: null,
  companyName: null,
  name: null,
  phoneNumber: null,
  fax: null,
  email: null,
  taxId: null,
  retainageAmount: "3500",
  retainageBalance: 0,
  lastPaycheckDate: null,
  retainagePercentage: "10",
  active: true,
  rating: 5,
  wcExpDate: null,
  generalLiabilityExpDate: null,
  branchReferenceId: null,
  uniqueNameError: null,
};

export default {
  components: {
    StatusDropdown,
    StarRatingField,
    AddressFormWithAutocomplete,
    DatePickerWithTypeInTextField,
    BranchesDropdownAutocomplete
  },

  props: {
    branches: {
      type: Array,
      required: true
    }
  },

  data() {
    return Object.assign(
        {
          displayTaxId: false,
          rules: {
            nonUniqueCompanyName: value => {
              if (this.uniqueNameError) {
                return !!value || this.uniqueNameError;
              } else return true;
            },
            required: value => {
              if (typeof value === "number") {
                return value >= 0 || "Required";
              } else if (typeof value === "string") {
                value = value.trim();
              }
              return !!value || "Required";
            },
            max40: value => Rules.maxValue(value, 40),
            max100: value => Rules.maxValue(value, 100),
            phoneNumber: Rules.phoneNumberWithDelimiter,
            email: Rules.email,
            zipCode: Rules.zipCode,
            isCurrencyFormat: Rules.isCurrencyFormat,
            isPositiveNumber: Rules.isPositiveOrZero
          },
          phoneNumberMask: Constants.phoneNumberMask
        },
        formDefaults
    );
  },

  watch: {
    uniqueNameError(newVal) {
      if (newVal) {
        this.uniqueNameError = newVal;
      }
    },
    companyName(newVal) {
      if (newVal) {
        this.uniqueNameError = null;
      }
    }
  },
  methods: {
    changeStatus(value) {
      this.active = value;
    },
    updateBranchReferenceId(value) {
      this.branchReferenceId = value;
    },
    emitError(error) {
      this.$emit("emitError", error);
    },
    setState(value) {
      this.state = value;
    },
    setStarRating(value) {
      this.rating = value;
      this.$refs.starRating.rating = value;
    },
    clearPickers() {
      this.$refs.generalLiabilityExpDatePicker.clearSelected();
      this.$refs.wcExpDateChangePicker.clearSelected();
    },
    clearAutocomplete() {
      this.$refs.addressForm.clearAutocomplete();
    },
    onWcExpDateChange(value) {
      this.wcExpDate = value;
    },
    onGeneralLiabilityExpDate(value) {
      this.generalLiabilityExpDate = value;
    },
    buildPayload() {
      let payload = {};
      const addressData = this.$refs.addressForm.getAddressData();
      payload.id = this.id;
      payload.branchReferenceId =  this.branchReferenceId;
      payload.companyName = this.companyName;
      payload.name = this.name;
      payload.phoneNumber = PhoneMask.unMaskPhone(this.phoneNumber);
      payload.fax = this.fax;
      payload.email = this.email;
      payload.taxId = this.taxId;
      payload.active = this.active;
      payload.retainageAmount = Number(this.retainageAmount);
      payload.retainagePercentage = Number(this.retainagePercentage);
      payload.retainageBalance = this.retainageBalance;
      payload.lastPaycheckDate = this.lastPaycheckDate;
      payload.rating = this.$refs.starRating.rating;
      payload.address = addressData.address;
      payload.city = addressData.city;
      payload.state = addressData.state;
      payload.zipCode = addressData.zipCode;
      payload.wcExpDate =this.wcExpDate;
      payload.generalLiabilityExpDate = this.generalLiabilityExpDate;
      return payload;
    },
    loadForm(payload) {
      if (!payload) {
        payload = formDefaults;
      }
      this.id = payload.id;
      this.branchReferenceId = payload.branchReferenceId ? payload.branchReferenceId : "";
      if (payload.branchReferenceId) {
        this.branchReferenceId = payload.branchReferenceId;
      } else if (payload.branchReferenceIds) {
        this.branchReferenceId = payload.branchReferenceIds[0];
      }
      this.companyName = payload.companyName;
      this.name = payload.name;
      this.phoneNumber = payload.phoneNumber;
      this.fax = payload.fax;
      this.email = payload.email;
      this.taxId = payload.taxId;
      this.active = payload.active;
      this.retainageAmount = payload.retainageAmount;
      this.retainagePercentage = payload.retainagePercentage;
      this.retainageBalance = payload.retainageBalance;
      this.lastPaycheckDate = payload.lastPaycheckDate;
      this.wcExpDate = payload.wcExpDate;
      this.generalLiabilityExpDate = payload.generalLiabilityExpDate;
      this.setStarRating(payload.rating);
      this.$refs.addressForm.setAddressData(payload);
    },
    onNonUniqueNameConflict(error) {
      this.uniqueNameError = error;
    },
    allowedDates(value) {
      const currentDate = moment();
      return moment(value).isSameOrAfter(currentDate, "day");
    }
  }
};
</script>
