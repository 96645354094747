<template>
  <drawer-form
      ref="form"
      :title="title"
      :inProgress="creating"
      submitLabel="Save Time Blocks"
      @cancelClicked="onCancelClicked"
      @submitClicked="onSubmitClicked"
  >
    <sales-rep-time-block-form
        ref="timeblocksFormBody"
        :markets="markets"
        :calendarDate="startDate"
        :calendarMarket="marketId"
        :availableSlots="availableSlots"
        :timeBlock="timeBlock"
        :creating="creating"
        :keep-dates-on-hide="keepDatesOnHide"
        :selectedWeekDay="selectedWeekDay"
        @updateStart="onUpdateStart"
        @updateSuccess="onSuccess"
        @updateEnd="onUpdateEnd"
    />
    <v-row class="ml-8">
      <v-btn
          text
          v-if="timeBlock"
          @click="onActivityLogClick"
      >
        Show Activity Log
      </v-btn>
    </v-row>
    <activity-logs-dialog
        ref="activityLogDialog"
        @showError="onError"
    />
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm.vue";
import SalesRepTimeBlockForm from "./SalesRepTimeBlockForm.vue";
import SalesRepsTimeBlocks from "@/rest/salesRepsTimeBlock";
import ActivityLogsDialog from "./ActivityLogsDialog.vue";

export default {
  components: {
    SalesRepTimeBlockForm,
    DrawerForm,
    ActivityLogsDialog
  },

  props: {
    startDate: String,
    marketId: Number,
    timeBlock: Object,
    keepDatesOnHide: Boolean,
    markets: {
      type: Array,
      required: true
    },
    selectedWeekDay: {
      type: String,
      required: false
    },
    availableSlots: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      creating: false
    };
  },

  computed: {
    title() {
      return this.timeBlock
          ? "Edit Sales Rep Time Blocks"
          : "Create Sales Rep Time Blocks";
    }
  },

  methods: {
    onCancelClicked() {
      this.$refs.form.resetValidation();
      this.$refs.timeblocksFormBody.resetFormDefaults();
      this.$emit('cancelClicked');
    },
    onSuccess(data) {
      this.$refs.form.resetValidation();
      this.$refs.timeblocksFormBody.resetFormDefaults();
      this.$emit('submitSuccess', data);
    },
    onUpdateStart() {
      this.creating = true;
    },
    onUpdateEnd() {
      this.creating = false;
    },
    async onSubmitClicked() {
      try {
        let valid = this.$refs.form.validate();
        if (valid) {
          if (this.timeBlock !== null) {
            this.$refs.timeblocksFormBody.openDialog("Update");
          } else {
            this.creating = true;
            const response = await this.createTimeBlocks();
            this.creating = false;
            this.onSuccess(response.data);
          }
        }
      } catch (error) {
        this.creating = false;
        this.$refs.timeblocksFormBody.showError(error);
      }
    },
    onError(error) {
      this.$refs.timeblocksFormBody.showError(error);
    },
    async createTimeBlocks() {
      try {
        const payload = this.$refs.timeblocksFormBody.buildPayload();
        return await SalesRepsTimeBlocks.getRestApi().createTimeBlocks(payload);
      } catch (error) {
        throw error;
      }
    },
    onActivityLogClick() {
      this.$refs.activityLogDialog.showDialog(this.timeBlock);
    }
  }
};
</script>
