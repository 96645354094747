<template>
  <div>
    <v-card flat>
      <section-divider title="PAYMENTS"/>
      <headers/>
      <panel
          :job="job"
          :line-item="item"
          v-for="item in paymentItems"
          :key="item.referenceId"
          :paymentTypes="paymentTypes"
          :planCodes="planCodes"
          :wsSessionId="wsSessionId"
          @priceUpdated="onPriceUpdated"
          @commentUpdated="onCommentUpdated"
          @planCodeIdUpdated="onPlanCodeIdUpdated"
          @paymentIdUpdated="onPaymentIdUpdated"
          @paymentTypeUpdated="onPaymentTypeUpdated"
          @itemDeleted="onItemDeleted"
          @holdReceived="onHoldReceived"
          @onErrorOccurred="paymentReceivedError"
      />
      <v-card-actions class="pr-3">
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            class="section-button"
            @click="onAddPaymentItem"
        >
          {{ paymentButtonText }}
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Constants from "../../../assets/constants.js";
import Headers from "../../../components/jobs/paymentLineItems/PaymentLineItemsHeaders.vue";
import Panel from "../../../components/jobs/paymentLineItems/PaymentLineItemsPanel.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";
import CreateReferenceIds from "../../../assets/referenceIds";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";
import PlanCodes from "@/rest/planCodes";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    initialItems: {
      type: Array,
      required: true
    },
    wsSessionId: {
      type: String,
      required: true
    },
    paymentTypes: Array
  },

  data() {
    return {
      paymentItems: [],
      planCodes: []
    };
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.paymentItems = value;
      }
    }
  },

  computed: {
    paymentButtonText() {
      return (this.paymentItems.length ? 'Add Another Payment' : 'Add Payment');
    }
  },

  mounted() {
    PlanCodes.getRestApi().getAllPlanCodes()
        .then(response => {
          this.planCodes = response.data;
        });
  },

  methods: {
    onAddPaymentItem() {
      let item = {};
      let component = this;
      let refid = CreateReferenceIds.getTempReferenceId();
      item.jobLineItem = {
        referenceId: refid,
        type: Constants.jobType.payment,
        quantity: 1,
        price: CurrencyFormatter.zeroCurrency(),
        customerPaidAmount: CurrencyFormatter.zeroCurrency(),
        paymentTypeId: null,
        paymentType: null,
        status: null,
        comment: null
      };
      component.paymentItems.push(item);
      this.$emit('itemsUpdated', this.paymentItems);
      this.$emit('amountsUpdated');
    },
    onItemDeleted(referenceId) {
      this.paymentItems = this.paymentItems.filter(
          current => current.jobLineItem.referenceId !== referenceId
      );
      this.$emit('itemsUpdated', this.paymentItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    onHoldReceived() {
      this.$emit('holdReceived');
    },
    onCommentUpdated() {
      this.markDirty();
    },
    onPlanCodeIdUpdated() {
      this.markDirty();
    },
    onPaymentIdUpdated() {
      this.markDirty();
    },
    onPriceUpdated() {
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    onPaymentTypeUpdated() {
      this.markDirty();
    },
    markDirty() {
      this.$emit('dataUpdated');
    },
    paymentReceivedError(error) {
      this.$emit('onPaymentErrorOccurred', error);
    },
  }
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
