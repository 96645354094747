<template>
  <div>
    <div id="grid-toolbar">
      <grid-toolbar
          @onToggleResetGridDialog="clearGridState"
      />
    </div>
    <dx-data-grid
        ref="commissions-grid"
        :data-source="dataSource"
        :show-borders="false"
        :loadPanel="{ enabled: true }"
        :columnAutoWidth="true"
        @editValue="onEditCommissionPercentage"
        @selectionChanged="onSelectionChanged"
    >
      <dx-paging :page-size="10"/>
      <dx-pager
          :show-page-size-selector="true"
          :allowed-page-sizes="customPagingSize"
          :show-info="true"
          infoText="Page {0} of {1} - {2} items"
      />
      <dx-selection mode="single"/>
      <dx-filter-row :visible="true"/>
      <slot/>
      <dx-column
          data-field="SaleDate"
          data-type="date"
          caption="Sale Date"
          format="MM/dd/yyyy"
          sortOrder="desc"
      />
      <dx-column
          data-field="CustomerFullName"
          caption="Customer"
          data-type="string"
      />
      <dx-column
          data-field="CustomerFullAddress"
          caption="Customer Address"
          data-type="string"
      />
      <dx-column
          data-field="LeadReferenceId"
          caption="Lead Reference ID"
          data-type="string"
      />
      <dx-column
          data-field="JobReferenceId"
          caption="Job Reference ID"
          data-type="string"
      />
      <dx-column
          data-field="CommissionableJobAmount"
          caption="Commissionable Job Amount"
          :format="format"
          data-type="number"
      />
      <dx-column
          v-if="isAdminUser"
          data-field="CommissionPercentage"
          cellTemplate="editCommissionPercentage"
          caption="Commission %"
      />
      <template #editCommissionPercentage="cellData">
        <div>
          <edit-commission-percentage-cell-template
              :cell-data="cellData"
              @editValue="onEditCommissionPercentage"
          />
        </div>
      </template>
      <dx-column
          data-field="Commission"
          caption="Commission"
          :format="format"
          data-type="number"
      />
      <dx-column
          data-field="SageCommissionType"
          caption="Sage Type"
          data-type="String"
      />
    </dx-data-grid>
    <edit-commission-percentage-dialog
        v-if="isAdminUser"
        ref="editCommissionPercentageDialog"
        @saveCommissionClicked="onSaveCommission"
    />
  </div>
</template>

<script>
import {
  DxColumn,
  DxFilterRow,
  DxPaging,
  DxDataGrid,
  DxSelection,
  DxPager
} from "devextreme-vue/ui/data-grid";
import {roles} from "@/rest/constants.ts";
import SalesRepresentatives from "@/rest/salesRepresentatives.ts";
import EditCommissionPercentageDialog from "@/components/salesRepresentatives/EditCommissionPercentageDialog.vue";
import Roles from "@/assets/roles.js";
import GridToolbar from "@/components/common/grid/GridToolbar.vue";
import ODataContext from "@/rest/odataContext";
import FormattingHelper from "@/assets/formattingHelper";
import EditCommissionPercentageCellTemplate
  from "@/components/common/grid/cellTemplates/EditCommissionPercentageCellTemplate.vue";

export default {
  components: {
    EditCommissionPercentageCellTemplate,
    DxPager, DxSelection, DxPaging, DxDataGrid, DxFilterRow, DxColumn,
    EditCommissionPercentageDialog,
    GridToolbar
  },

  props: {
    salesRepresentativeId: {
      type: Number,
      required: true
    },
    salesRepReferenceId: {
      type: String,
      required: true
    },
    customPagingSize: {
      type: Array,
      required: false,
    }
  },

  data() {
    return {
      format: {
        type: "currency",
        precision: 2
      },
      roles,
      selectedCommission: {},
      dataSource: {
        store: ODataContext['WebSalesRepresentativeCommissions'],
        filter: this.getGridFilter()
      },
      commissionUpdatedSuccessfullyMessage: "Commission updated successfully!"
    }
  },

  computed: {
    isAdminUser() {
      return Roles.isAnyAdminUser();
    }
  },

  methods: {
    onSaveCommission(commission) {
      let payload = this.buildPayload(commission);
      SalesRepresentatives.getRestApi()
          .updateSalesCommission(this.salesRepReferenceId, payload.id, payload)
          .then(() => {
            this.onCommissionUpdateSuccess();
          })
          .catch((error) => {
            this.$emit('commissionUpdateFailed', error);
          })
          .finally(() => {
            this.$refs.editCommissionPercentageDialog.closeDialog();
            this.$emit('commissionUpdatedSuccessfully', this.commissionUpdatedSuccessfullyMessage);
          });
    },
    onEditCommissionPercentage(rowInfo) {
      this.$refs.editCommissionPercentageDialog.openDialog(rowInfo.data);
    },
    clearSortingAndFiltering() {
      const gridInstance = this.getGridInstance();
      gridInstance.clearFilter();
      gridInstance.clearSorting();
      gridInstance.columnOption("SaleDate", "sortOrder", "desc");
      gridInstance.filter(this.getGridFilter());
    },
    getGridInstance() {
      return this.$refs["commissions-grid"].instance;
    },
    clearGridState() {
      this.clearSortingAndFiltering();
    },
    refreshGrid() {
      const gridInstance = this.getGridInstance();
      gridInstance.refresh();
    },
    onCommissionUpdateSuccess() {
      this.refreshGrid();
    },
    getGridFilter() {
      const gridFilter = [];
      gridFilter.push(["SalesRepresentativeId", "=", this.salesRepresentativeId]);
      return gridFilter;
    },
    buildPayload(commission) {
      let payload = FormattingHelper.convertFirstLetterToLowerCase(commission);
      payload.commission = (payload.commissionableJobAmount * payload.commissionPercentage) / 100.0;
      return payload;
    },
    onSelectionChanged(e) {
      this.$emit('onRowSelected', e.selectedRowsData[0]);
    }
  }
};
</script>

<style scoped>
.toolbar {
  margin: 0 1px;
}
</style>
