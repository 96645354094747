<template>
  <v-dialog v-model="wizardVisible" persistent :width="700">
    <v-app-bar dense flat dark color="primary">
      <v-app-bar-title>Refund</v-app-bar-title>
    </v-app-bar>
    <v-stepper v-model="step">
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="pl-2 pr-2 pb-2">
            <refund-confirmation-form
                ref="confirmationForm"
                :job="job"
                :remainingBalance="remainingBalance"
            />
            <button-container
                backName="Cancel"
                nextName="Submit"
                :hidePreviousStep="true"
                @cancel="closeDialog"
                @getNextStep="onSubmitClicked"
            />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import ButtonContainer from "@/components/common/wizard/ButtonContainerWithCancel.vue";
import StepperHeader from "./RefundStepperHeader.vue";
import ConfirmationForm from "./RefundConfirmationForm.vue";
import RefundConfirmationForm from "./RefundConfirmationForm";
import Rules from "../../../assets/rules";

export default {
  components: {
    RefundConfirmationForm,
    StepperHeader,
    ButtonContainer,
    ConfirmationForm,
  },

  props: {
    job: Object,
    remainingBalance: Number,
  },

  data() {
    return Object.assign(
        {},
        {
          refundAmount: 0,
          balanceTakenAmount: 0,
          wizardVisible: false,
          error: null,
          step: 1,
          rules: {
            required: Rules.required,
          },
        }
    );
  },

  methods: {
    openDialog() {
      this.resetWizard();
      this.wizardVisible = true;
      this.$refs.confirmationForm.loadRefundForm();
    },
    closeDialog() {
      this.wizardVisible = false;
    },
    resetWizard() {
      this.step = 1;
      this.closeDialog();
    },
    async onSubmitClicked() {
      let valid = this.$refs.confirmationForm.validate();
      if (valid) {
        const payload = this.$refs.confirmationForm.getPayload();
        let request = {
          refundAmount: payload.refundAmount,
          balanceTakenAmount: payload.balanceTakenAmount
        };
        this.$emit("submitRefund", request);
        this.resetWizard();
      }
    },
  },
};
</script>
