<template>
  <grid-view
      ref="nav"
      page-title="List Product Catalog Items"
      nav-section="admin"
      :action-button-label="isActionButtonDisplayed ? 'Create Catalog Item' : null"
      :data-source="dataSource"
      :allowFiltering="false"
      :columnMinWidth="130"
      :allowColumnResizing="true"
      columnResizingMode="widget"
      @rowSelected="onRowSelected"
  >
    <dx-column
        data-field="Status"
        width="10%"
        caption="Status"
        :cell-template="StatusRenderer"
        data-type="string"
    />
    <dx-column
        data-field="AvailableInSalesApp"
        width="10%"
        caption="Available In Sales App"
        data-type="string"
    />
    <dx-column
        data-field="InternalSku"
        caption="Internal SKU"
        :allowHeaderFiltering="false"
        data-type="string"
    />
    <dx-column
        data-field="ProductSubtype"
        width="10%"
        caption="Product Subtype"
        data-type="string"
    />
    <dx-column
        data-field="ProductType"
        caption="Product Type"
        data-type="string"
    />
    <dx-column
        data-field="StyleName"
        caption="Manufacturer Style"
        data-type="string"
    />
    <dx-column
        data-field="Color"
        :minWidth="175"
        caption="Manufacturer Color"
        data-type="string"
    />
    <dx-column
        data-field="PrivateLabelStyleName"
        caption="Private Label Style"
        data-type="string"
    />
    <dx-column
        data-field="PrivateLabelColor"
        :minWidth="175"
        caption="Private Label Color"
        data-type="string"
    />
    <dx-column
        data-field="ProductClass"
        caption="Product Class"
        data-type="string"
    />
    <dx-column
        data-field="Description"
        caption="Description"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="BulkItem"
        width="20%" caption="Bulk Item"
        data-type="string"
    />
    <dx-column
        data-field="UnitOfMeasure"
        :minWidth="150"
        caption="Unit of Measure"
    />
    <dx-column
        data-field="BoxQuantity"
        caption="Box Quantity"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="ConversionMultiplier"
        caption="Conversion Multiplier"
        :allowHeaderFiltering="true"
    />
    <dx-column
        data-field="RepPrice"
        caption="Rep Price"
        :format="format"
        :allowHeaderFiltering="false"
    />
    <dx-column
        data-field="Manufacturer"
        caption="Manufacturer"
        data-type="string"
    />
    <template v-slot:drawerContent>
      <create-drawer
          ref="createForm"
          :productClassOptions="productClassOptions"
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
          @showSuccess="showSuccess"
          @showWarning="showWarningDialog"
      />
      <generic-warning-dialog
          ref="warningDialog"
          title="Warning"
          :message="warningMessage"
          :multiLineError="true"
          confirmationLabel="Confirm"
          cancelLabel="Cancel"
          @confirmed="onConfirmAction"
      />
    </template>
  </grid-view>
</template>

<script>
import Roles from "../../assets/roles";
import StatusRenderer from "../../components/common/grid/CatalogItemStatusRenderer.js";
import GridView from "../../views/common/GridView.vue";
import CreateDrawer from "../../components/catalogItems/drawers/CreateDrawer.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import ODataContext from "../../rest/odataContext";
import CatalogItems from "../../rest/catalogItems";
import GenericWarningDialog from "../../components/common/dialogContainers/GenericWarningDialog.vue";

export default {
  components: {
    GenericWarningDialog,
    GridView,
    CreateDrawer,
    DxColumn
  },
  data() {
    return {
      format: {
        type: "currency",
        precision: 2
      },
      dataSource: {
        store: ODataContext['ProductCatalogItems'],
        select: [
          "Id",
          "Status",
          "AvailableInSalesApp",
          "InternalSku",
          "ProductType",
          "ProductSubtype",
          "StyleName",
          "Color",
          "Description",
          "BulkItem",
          "UnitOfMeasure",
          "BoxQuantity",
          "ConversionMultiplier",
          "Manufacturer",
          "RepPrice",
          "PrivateLabelStyleName",
          "PrivateLabelColor",
          "ProductClass"
        ]
      },
      productClassOptions: null,
      warningMessage: null
    };
  },

  async created() {
    if (!this.productClassOptions) {
      this.productClassOptions = await this.getProductClassOptions();
    }
  },

  computed: {
    isActionButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  methods: {
    onRowSelected(data) {
      this.$router.push(`/orders/catalog/${data.Id}`);
    },
    onSubmitSuccess() {
      this.showSuccess();
      this.$refs.nav.refreshGrid();
      this.$refs.nav.hideDrawer();
    },
    onSubmitFailed(error) {
      this.$refs.nav.showError(error);
    },
    showSuccess() {
      this.$refs.nav.showSuccess("Created product catalog item successfully!");
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    StatusRenderer,
    async getProductClassOptions() {
      try {
        const response = await CatalogItems.getRestApi().getCatalogItemsProductClasses();
        return response.data;
      } catch (error) {
        this.emitFail(error);
      }
    },
    showWarningDialog(message) {
      this.warningMessage = message;
      this.$refs.warningDialog.openDialog();
    },
    async onConfirmAction() {
      try {
        await this.$refs.createForm.performUpdate();
      } catch (error) {
        this.onSubmitFailed(error);
      }
    }
  }
};
</script>
