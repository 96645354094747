<template>
  <drawer-form
      ref="drawerForm"
      :title=title
      :submit-label="submitLabel"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <choose-dates-step
        ref="datesChooser"
        v-if="shipment"
        :shipment="shipment"
        @datesSelected="allDatesSelected"
        @estimatedDeliveryIsEarlierThanPickUpDate="onEstimatedDeliveryIsEarlierThanPickUpDate"
    />
    <v-row class="justify-center align-center">
      <div class="text-center">
        <span v-show="error" class="edit-dates-error-message">{{ error }}</span>
      </div>
    </v-row>

  </drawer-form>
</template>

<script>
import DrawerForm from "../common/form/DrawerForm.vue";
import ChooseDatesStep from "./steps/ChooseDatesStep.vue";

export default {
  components: {
    ChooseDatesStep,
    DrawerForm,
  },

  props: {
    title: String,
    submitLabel: String
  },

  data() {
    return {
      error: null,
      inProgress: false,
      shipment: null
    };
  },

  methods: {
    validate() {
      return this.$refs.drawerForm.validate();
    },
    resetValidation() {
      this.inProgress = false;
      this.error = null;
      this.$refs.drawerForm.resetValidation();
    },
    onCancel() {
      this.inProgress = false;
      this.error = null;
      this.$emit('cancel');
    },
    allDatesSelected() {
      this.error = null
    },
    onEstimatedDeliveryIsEarlierThanPickUpDate() {
      this.error = "Estimated delivery date must not be earlier than the pickup date.";
    },
    onSubmit() {
      let validated = !this.$refs.datesChooser.hasDateError();
      if (!this.error && validated) {
        let payload = {
          pickUpDate: this.$refs.datesChooser.pickupDate,
          estimatedDeliveryDate: this.$refs.datesChooser.estimatedDeliveryDate
        }
        this.$emit('submit', payload);
      }
    },
    resetForm() {
      this.inProgress = false;
      this.error = null;
      this.shipment = null;
      this.resetValidation();
    },
    loadDrawerForm(shipment) {
      this.resetForm();
      this.shipment = shipment;
    },
  },
};
</script>

<style scoped>
.edit-dates-error-message {
  padding: 10px;
  font-size: 15px;
  color: #FF0000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
