<template>
  <div>
    <inline-grid
        :data-source="costs"
        :show-borders="false"
        @deleteClicked="onDeleteClicked"
    >
      <dx-column
          data-field="effectiveOn"
          caption="Status"
          :cell-template="renderActiveInactive"
      />
      <dx-column
          data-field="effectiveOn"
          :cell-template="renderEffectiveDate"
          caption="Price Effective Date"
      />
      <dx-column
          data-field="cost"
          :caption="costFieldName"
          :format="format"
      />
      <dx-column
          v-if="isMigratedItem"
          data-field="id"
          width="5%"
          caption
          cellTemplate="delete"
      />
      <template v-slot:footer>
        <inline-grid-footer v-if="isMigratedItem">
          <inline-grid-button
              v-if="isAddButtonDisplayed"
              :label="costsButtonAndModalText"
              @click="onAddVendorCost"
          />
        </inline-grid-footer>
      </template>

      <template v-slot:customRenderer>
        <v-card color="red"></v-card>
      </template>
    </inline-grid>

    <add-item-cost-dialog
        ref="dialog"
        :catalog-item-id="catalogItemId"
        :internalSku="internalSku"
        :costs="costs"
        :isMigratedItem="isMigratedItem"
        :submitLabel="submitButtonText"
        :fieldLabel="costFieldName"
        :modalTitle="costsButtonAndModalText"
        @costAdded="costAdded"
    />
  </div>
</template>

<script>
import moment from "moment";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGrid from "../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../components/common/grid/InlineGridButton.vue";
import AddItemCostDialog from "../../components/catalogItems/AddItemCostDialog.vue";


export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    AddItemCostDialog
  },

  props: {
    catalogItemId: Number,
    costs: {
      type: Array,
      required: false,
      default: () => []
    },
    isAddButtonDisplayed: {
      type: Boolean,
      default: true
    },
    internalSku: {
      type: String,
      required: true,
    },
    isMigratedItem: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      format: {
        type: "currency",
        precision: 2
      }
    };
  },

  computed: {
    costsButtonAndModalText() {
      return this.isMigratedItem ? 'Add Rep Price' : 'Add Vendor Cost';
    },
    submitButtonText() {
      return this.isMigratedItem ? 'Add Price' : 'Add Cost';
    },
    costFieldName() {
      return this.isMigratedItem ? 'Rep Price' : 'Vendor Cost';
    }
  },

  methods: {
    renderEffectiveDate(element, column) {
      let date = this.isMigratedItem ? moment(column.data.effectiveOn).format("MM/DD/YYYY") : moment(column.data.effectiveOn).utc().format("MM/DD/YYYY");
      element.insertAdjacentHTML("beforeend", date);
    },
    renderActiveInactive(element, column) {
      let active = column.data.effective || column.data.active;
      let content = active
        ? "<span style='color: #090;'>Active</span>"
        : "<span style='color: #900;'>Inactive</span>";
      element.insertAdjacentHTML("beforeend", content);
    },
    onDeleteClicked(data) {
      this.$emit('deleteClicked', data);
    },
    addVendorCostButtonClicked() {
      this.$emit('addVendorCost');
    },
    onAddVendorCost() {
      this.$refs.dialog.openDialog();
    },
    costAdded() {
      this.$emit('costAdded');
    }
  }
};
</script>

<style></style>
