<template>
  <modal-dialog
      ref="dialog"
      title="Remove Item"
      width="500"
      :visible="dialogVisible"
      submitLabel="Confirm"
      cancelLabel="Cancel"
      @submitClicked="onDeleteClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <v-form ref="form">
      <v-container>
        <div id="delete-file-message"> The item is necessary for
          the job, but it was not found in the catalog during job sync. Make sure that you added the item to Products
          section before deleting it?
        </div>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

const formDefaults = {
  dialogVisible: false
};

export default {
  components: {
    ModalDialog
  },

  data() {
    return Object.assign({}, formDefaults);
  },

  props: {
    miscItemReferenceId: null
  },

  watch: {
    miscItemReferenceId(value) {
      if (value) this.dialogVisible = true;
    }
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.closeDialog();
      this.$emit("deleteCancelled");
    },
    onDeleteClicked() {
      this.deleteConfirmed();
    },
    deleteConfirmed() {
      this.$emit('deleteConfirmed', this.miscItemReferenceId);
      this.closeDialog();
    }
  }
};
</script>
<style>
</style>
