<template>
  <div>
    <inline-grid
        :data-source="adjustments"
        :show-borders="false"
        :columnAutoWidth="true"
    >
      <dx-column
          data-field="whenCreated"
          caption="Date"
          data-type="datetime"
          format="MM/dd/yyyy, hh:mm a"
      />
      <dx-column
          data-field="entryDescription"
          caption="Description"
      />
      <dx-column
          data-field="amount"
          caption="Amount"
          :format="format"
      />
      <template v-slot:footer>
        <inline-grid-footer>
          <inline-grid-button
              v-if="isAddButtonDisplayed"
              label="Add"
              @click="onAddButtonClick"
          />
        </inline-grid-footer>
      </template>

    </inline-grid>
    <fine-wizard
        ref="createFineWizard"
        :salesRepReferenceId="salesRepReferenceId"
        :salesRepName="salesRepName"
        @submitClicked="onSubmitClicked"
    />
  </div>
</template>

<script>
import SalesRepresentatives from "@/rest/salesRepresentatives.ts";
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";
import FineWizard from "@/components/salesRepresentatives/fineWizard/WizardContainer.vue";
import WebSockets from "../../../websocket/webSockets";


export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    FineWizard,
    DxColumn
  },

  props: {
    salesRepReferenceId: String,
    salesRepName: String,
    isAddButtonDisplayed: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      stompJsClient: null,
      format: {
        type: "currency",
        precision: 2
      },
      adjustments: []
    };
  },

  watch: {
    salesRepReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  created() {
    this.connectWebSocket();
  },

  destroyed() {
    this.closeWebSocket();
  },

  methods: {
    // Connect websocket and subscribe to adjustment updates.
    connectWebSocket() {
      this.stompJsClient = WebSockets.createStompJsClient(this.onConnectFunction);
    },
    closeWebSocket() {
      this.stompJsClient.deactivate();
    },
    onConnectFunction() {
      let topic =
          WebSockets.getWebSocketSalesRepTopic() + "/" +
          this.salesRepReferenceId +
          "/adjustment";

      this.stompJsClient.subscribe(topic, response => {
        this.refresh();
      });
    },
    resetWizard() {
      this.$refs.createFineWizard.resetWizard();
    },
    onAddButtonClick() {
      this.$refs.createFineWizard.openDialog();
    },
    closeDialog() {
      this.$refs.createFineWizard.closeDialog();
    },
    onSubmitClicked(payload) {
      let component = this;
      let amount = (payload.type == 2 ? -1 : 1) * payload.amount;
      let adjustment = {
        leadReferenceId: payload.leadReferenceId,
        amount: amount,
        memo: payload.note
      };
      SalesRepresentatives.getRestApi()
          .createSalesRepAdjustment(this.salesRepReferenceId, adjustment)
          .catch(() => {
            component.snackbar = true;
          })
          .finally(() => {
            component.$refs.createFineWizard.closeDialog();
            this.$emit("submitClicked", payload);
          });
    },
    refresh() {
      let component = this;
      const salesRepReferenceId = this.salesRepReferenceId;
      if (!salesRepReferenceId) {
        return;
      }
      SalesRepresentatives.getRestApi().getSalesRepAdjustments(salesRepReferenceId)
          .then((response) => {
            component.adjustments = response.data;
          })
          .catch(() => {
            component.snackbar = true;
          });
    }
  }
};
</script>
