import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {SupplierCost} from "./supplierCosts";
import {ActivityLog} from "./interfaces/activityLogs";
import {ProductType} from "./productTypes"

/**
 * Product catalog item.
 */
export class CatalogItem {
  id: number = 0;
  vendorSku: string = "";
  productType: ProductType;
  styleName: string = "";
  color: string = "";
  description: string = "";
  boxQuantity: number = 0;
  unitOfMeasure: string = "";
  manufacturer: string = "";
  status: string = "";
  internalSku: string = "";
  vendorId: number = 0;

  constructor(id: number, vendorSku: string, productType: ProductType, styleName: string, color: string,
              description: string, boxQuantity: number, unitOfMeasure: string, manufacturer: string, status: string,
              internalSku: string, vendorId: number) {
    this.id = id;
    this.vendorSku = vendorSku;
    this.productType = productType;
    this.styleName = styleName;
    this.color = color;
    this.description = description;
    this.boxQuantity = boxQuantity;
    this.unitOfMeasure = unitOfMeasure;
    this.manufacturer = manufacturer;
    this.status = status;
    this.internalSku = internalSku;
    this.vendorId = vendorId;
  }
}

/**
 * Catalog item overbill.
 */
export class CatalogItemOverbill {
  id: number;
  overbill: number = 0.0;
  effectiveOn: Date = new Date();
  expiresOn: Date = new Date();

  constructor(id: number) {
    this.id = id;
  }
}

export class CatalogItemReference {
  internalSku: string = "";
  styleName: string = "";
  color: string = "";

  constructor() { }
}

export class AssociatedProductCatalogItemDTO {
  id: number;
  internalSku: string = "";
  styleName: string = "";
  color: string = "";

  constructor(id: number, styleName: string, color: string, internalSku: string) {
    this.id = id;
    this.styleName = styleName;
    this.color = color;
    this.internalSku = internalSku;
  }
}

export class CatalogAssociatedItemWithReferences {
  associatedItem: CatalogItem;
  references: CatalogItemReference[];

  constructor(associatedItem: CatalogItem, references: CatalogItemReference[]) {
    this.associatedItem = associatedItem;
    this.references = references;
  }
}

/**
 * Summary information for a catalog item.
 */
export class CatalogItemSummary {
  productCatalogItem: CatalogItem;
  supplierCost: SupplierCost;

  constructor(
    productCatalogItem: CatalogItem,
    supplierCost: SupplierCost
  ) {
    this.productCatalogItem = productCatalogItem;
    this.supplierCost = supplierCost;
  }
}

export class ProductCatalogItemProductClass {
  id: number = 0;
  name: string

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export interface ProductUniquenessValidationResponse {
  anotherProductClassItemsCount: number;
  anotherPrivateLabelStyleCount: number;
}

/**
 * REST interface provided for catalog items.
 */
export interface CatalogItemsRestApi {
  createCatalogItem(item: CatalogItem): AxiosPromise<CatalogItem>;
  getCatalogItemSummary(id: number): AxiosPromise<CatalogItemSummary>;
  getCatalogItemSummaryByItemIdAndBranchId(itemId: number, jobBranchId: number): AxiosPromise<CatalogItemSummary>;
  getCatalogItemsByVendorSku(sku: string): AxiosPromise<CatalogItem[]>;
  getCatalogItemAssociatedItemsByCatalogItemId(id: number): AxiosPromise<AssociatedProductCatalogItemDTO[]>;
  getCatalogAssociatedItemsInCommon(itemIds: number[]): AxiosPromise<CatalogAssociatedItemWithReferences[]>;
  getCatalogItemCosts(id: number): AxiosPromise<SupplierCost[]>;
  getCatalogItemOverbills(id: number): AxiosPromise<CatalogItemOverbill[]>;
  getCatalogItemLogs(id: number): AxiosPromise<ActivityLog[]>;
  getCatalogItemStyles(): AxiosPromise<String[]>;
  getCatalogItemPrivateLabelStyles(): AxiosPromise<String[]>;
  getCatalogItemColors(): AxiosPromise<String[]>;
  addCatalogItemAssociatedItems(id: number, associatedIds: number[]): AxiosPromise<CatalogItem[]>;
  addCatalogItemCost(id: number, cost: SupplierCost): AxiosPromise<CatalogItem>;
  addCatalogItemOverbill(id: number, overbill: CatalogItemOverbill): AxiosPromise<CatalogItem>;
  updateCatalogItem(id: number, updated: CatalogItem): AxiosPromise<CatalogItem>;
  updateCatalogItemOverbill(id: number, params: object): AxiosPromise<CatalogItem>;
  removeCatalogItemAssociatedItem(id: number, associatedId: number): AxiosPromise<CatalogItem>;
  removeCatalogItemOverbill(id: number, overbillId: number): AxiosPromise<CatalogItemOverbill>;
  getCatalogItemsProductClasses(): AxiosPromise<ProductCatalogItemProductClass[]>;
  validateProductUniqueness(params : Object): AxiosPromise<ProductUniquenessValidationResponse>;
}

/**
 * REST implementation provided for product catalog items.
 */
class Implementation implements CatalogItemsRestApi {
  createCatalogItem(item: CatalogItem): AxiosPromise<CatalogItem> {
    return Axios.axiosInstance().post<CatalogItem>("/catalog/items", item);
  }
  getCatalogItemSummary(id: number): AxiosPromise<CatalogItemSummary> {
    return Axios.axiosInstance().get<CatalogItemSummary>(`/catalog/items/${id}/summary`);
  }
  getCatalogItemSummaryByItemIdAndBranchId(itemId: number, jobBranchId: number): AxiosPromise<CatalogItemSummary> {
    return Axios.axiosInstance().get<CatalogItemSummary>(`/catalog/items/${itemId}/${jobBranchId}/summary`);
  }
  getCatalogItemsByVendorSku(sku: string): AxiosPromise<CatalogItem[]> {
    return Axios.axiosInstance().get<CatalogItem[]>(`/catalog/items/search/vendor_sku/${sku}`);
  }
  getCatalogItemAssociatedItemsByCatalogItemId(id: number): AxiosPromise<AssociatedProductCatalogItemDTO[]> {
    return Axios.axiosInstance().get<CatalogItem[]>(`/catalog/items/${id}/associated`);
  }
  getCatalogAssociatedItemsInCommon(itemIds: number[]): AxiosPromise<CatalogAssociatedItemWithReferences[]> {
    let params = "?nocache=true";
    itemIds.forEach(itemId => (params += "&itemId=" + itemId));
    return Axios.axiosInstance().get<CatalogAssociatedItemWithReferences[]>(`/catalog/items/associated${params}`);
  }
  getCatalogItemCosts(id: number): AxiosPromise<SupplierCost[]> {
    return Axios.axiosInstance().get<SupplierCost[]>(`/catalog/items/${id}/costs`);
  }
  getCatalogItemOverbills(id: number): AxiosPromise<CatalogItemOverbill[]> {
    return Axios.axiosInstance().get<CatalogItemOverbill[]>(`/catalog/items/${id}/overbill`);
  }
  getCatalogItemLogs(id: number): AxiosPromise<ActivityLog[]> {
    return Axios.axiosInstance().get<ActivityLog[]>(`/catalog/items/${id}/logs`);
  }
  getCatalogItemStyles(): AxiosPromise<String[]> {
    return Axios.axiosInstance().get<String[]>("/catalog/items/styles");
  }
  getCatalogItemPrivateLabelStyles(): AxiosPromise<String[]> {
    return Axios.axiosInstance().get<String[]>("/catalog/items/private-label/styles");
  }
  getCatalogItemColors(): AxiosPromise<String[]> {
    return Axios.axiosInstance().get<String[]>("/catalog/items/colors");
  }
  addCatalogItemAssociatedItems(id: number, associatedIds: number[]): AxiosPromise<CatalogItem[]> {
    return Axios.axiosInstance().post<CatalogItem[]>(
        `/catalog/items/${id}/associated/multiple`, JSON.stringify(associatedIds)
    );
  }
  addCatalogItemCost(id: number, cost: SupplierCost): AxiosPromise<CatalogItem> {
    return Axios.axiosInstance().post<CatalogItem>(`/catalog/items/${id}/costs`, cost);
  }
  addCatalogItemOverbill(id: number, overbill: CatalogItemOverbill): AxiosPromise<CatalogItem> {
    return Axios.axiosInstance().post<CatalogItem>(`/catalog/items/${id}/overbill`, overbill);
  }
  updateCatalogItem(id: number, updated: CatalogItem): AxiosPromise<CatalogItem> {
    return Axios.axiosInstance().put<CatalogItem>(`/catalog/items/${id}`, updated);
  }
  updateCatalogItemOverbill(id: number, params: CatalogItem): AxiosPromise<CatalogItem> {
    return Axios.axiosInstance().put<CatalogItem>(`/catalog/items/${id}/overbill`, params);
  }
  removeCatalogItemAssociatedItem(id: number, associatedId: number): AxiosPromise<CatalogItem> {
    return Axios.axiosInstance().delete(`/catalog/items/${id}/associated/${associatedId}`);
  }
  removeCatalogItemOverbill(id: number, overbillId: number): AxiosPromise<CatalogItemOverbill> {
    return Axios.axiosInstance().delete(`/catalog/items/${id}/overbill/${overbillId}`);
  }
  getCatalogItemsProductClasses(): AxiosPromise<ProductCatalogItemProductClass[]> {
    return Axios.axiosInstance().get<ProductCatalogItemProductClass[]>(`/catalog/items/classes`);
  }
  validateProductUniqueness(params: Object): AxiosPromise<ProductUniquenessValidationResponse> {
    return Axios.axiosInstance().get<ProductUniquenessValidationResponse>(`/catalog/items/validate-product-uniqueness`, {
      params: params
    });
  }
}

const CatalogItems = {
  getRestApi(): CatalogItemsRestApi {
    return new Implementation();
  }
};

export default CatalogItems;
