<template>
  <div>
    <dx-data-grid
        :data-source="files"
        :show-borders="false"
        :paging="{ pageSize: 10 }"
        @rowRemoving="onDeleteItem"
        @rowUpdated="onValueChanged($event)"
        @editorPrepared="onEditorPrepared($event)"
        @cell-prepared="onCellPrepared"
    >
      <dx-editing
          :allow-updating="showEditingButtons && allowModifying"
          :allow-deleting="allowModifying"
          mode="row"
      >
        <dx-texts
            :confirmDeleteMessage="''"
        />
      </dx-editing>
      <dx-column
          data-field="createdAt"
          width="15%"
          data-type="date"
          caption="Date"
          format="MM/dd/yyyy, hh:mm a"
          :allow-editing="false"
      />
      <dx-column
          data-field="userName"
          width="15%"
          caption="User"
          :allow-editing="false"
      />
      <dx-column
          data-field="url"
          caption="File name"
          cellTemplate="link"
          :allow-editing="false"
      />
      <dx-column
          data-field="documentTypeId"
          :width="showInstallTypes? '15%' : '30%'"
          caption="Document Type"
          :visible="true"
          :set-cell-value="setCellValue"
      >
        <dx-lookup
            :data-source="documentTypesToDisplay"
            display-expr="name"
            value-expr="id"
        />
      </dx-column>
      <dx-column
          v-if="showInstallTypes"
          data-field="installTypeId"
          width="15%"
          caption="Install Type"
          :visible="true"
          edit-cell-template="installTypeDropdown"
      >
        <dx-lookup
            :data-source="getInstallTypeDataSource"
            display-expr="name"
            value-expr="id"
        />
        <dx-custom-rule
            :validation-callback="isInstallTypeValid"
            message="Install type is required"
            :reevaluate="true"
        />
      </dx-column>
      <div slot="installTypeDropdown" slot-scope="cellData">
        <div v-if="installTypesPerDocumentType[cellData.data.documentTypeId]">
          <dx-select-box
              placeholder="Install Type"
              :items="getInstallTypeDataSource(cellData)"
              :value="cellData.data.installTypeId"
              display-expr="name"
              value-expr="id"
              @value-changed="(e) => onSelectBoxValueChanged(e, cellData)"
          />
        </div>
      </div>
      <div slot="link" slot-scope="cellData">
        <link-row-cell-template
            :cell-data="cellData"
            file-id-property="id"
            file-name-property="fileName"
        />
      </div>
    </dx-data-grid>
    <inline-grid-footer>
      <inline-grid-button
          v-if="isActionButtonDisplayed"
          label="Add File(s)"
          @click="onAddFilesButtonClick"
      />
    </inline-grid-footer>

    <add-file-dialog
        ref="addFilesDialog"
        :leadReferenceId="leadReferenceId"
        :jobId="jobId"
        :installerReferenceId="installerReferenceId"
        :purchaseOrderNumber="purchaseOrderNumber"
        :transferRequestNumber="transferRequestNumber"
        :shipmentNumber="shipmentNumber"
        :salesRepReferenceId="salesRepReferenceId"
        :parentType="parentType"
        :documentTypesCategory="documentTypesCategory"
        :documentTypes="documentTypesToDisplay"
        :showInstallTypes="showInstallTypes"
        :installTypesPerDocumentType="installTypesPerDocumentType"
        :availableLabors="availableLabors"
        :wsSessionId="wsSessionId"
        @uploadComplete="refresh"
        @addFilesGridError="emitError"
    />

    <remove-file-dialog
        ref="removeFileDialog"
        @deleteConfirmed="refresh"
    >
    </remove-file-dialog>
  </div>
</template>

<script>
import InlineGrid from "../../../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../../../components/common/grid/InlineGridButton.vue";
import AddFileDialog from "../../../../components/common/inlineGrids/files/AddFileDialog.vue";
import Files from "../../../../rest/files";
import RemoveFileDialog from "./RemoveFileDialog";
import LinkRowCellTemplate from "../../grid/cellTemplates/LinkRowCellTemplate";
import Constants from "../../../../assets/constants";
import {DxColumn, DxCustomRule, DxDataGrid, DxEditing, DxLookup, DxTexts} from "devextreme-vue/ui/data-grid";
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    AddFileDialog,
    RemoveFileDialog,
    LinkRowCellTemplate,
    DxColumn,
    DxLookup,
    DxSelectBox,
    DxCustomRule,
    DxEditing,
    DxDataGrid,
    DxTexts
  },

  props: {
    leadReferenceId: String,
    jobReferenceId: String,
    installerReferenceId: String,
    purchaseOrderNumber: String,
    transferRequestNumber: String,
    shipmentNumber: String,
    salesRepReferenceId: String,
    jobId: {},
    parentType: String,
    isActionButtonDisplayed: {
      type: Boolean,
      default: true
    },
    documentTypesCategory: String,
    availableLabors: Array,
    wsSessionId: String,
    showEditingButtons: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      files: [],
      documentTypes: [],
      documentTypesToDisplay: [],
      installTypesPerDocumentType: {},
      installTypes: [],

      isInstallTypeValid: (params) => {
        const documentTypeId = params.data.documentTypeId;
        const installTypeId = params.value;
        if (this.installTypesPerDocumentType[documentTypeId] === undefined) {
          return true;
        } else if (!this.installTypesPerDocumentType[documentTypeId].some(installType => installType.id === installTypeId)) {
          return false;
        }
        return true;
      }
    }
  },

  created() {
    this.refresh();
    this.getDocumentTypes();
  },

  computed: {
    showInstallTypes() {
      return Constants.documentCategoriesWithInstallTypes.includes(this.documentTypesCategory);
    }
  },

  methods: {
    async refresh(hasCOCorVDAFileTypes) {
      try {
        let referenceIdBasedOnParentType = this.getProperReferenceIdBasedOnParentType(hasCOCorVDAFileTypes);
        const response = await Files.getRestApi().getAllSpecificFilesByProvidedReferenceId(referenceIdBasedOnParentType);
        this.files = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    // Delete an associated item.
    async onDeleteItem(cellData) {
      cellData.cancel = true;
      this.$refs.removeFileDialog.openDialog(cellData);
    },
    async onValueChanged(cellData) {
      await Files.getRestApi().updateFileDocumentType(cellData.data.id, cellData.data.documentTypeId,
          cellData.data.installTypeId, this.wsSessionId);
      await this.refresh();
    },
    onEditorPrepared(e) {
      let installTypeId = e.row.data.installTypeId;
      let documentTypeId = e.row.data.documentTypeId;
      if (this.installTypesPerDocumentType[documentTypeId] !== undefined && installTypeId === null) {
        const instance = e.component.instance();
        const cellElement = instance.getCellElement(e.row.rowIndex, 'installTypeId');
        instance.focus(cellElement);
      }
    },
    onAddFilesButtonClick() {
      this.$refs.addFilesDialog.openDialog();
    },
    emitError(error) {
      this.$emit("filesGridError", error);
    },
    allowModifying(event) {
      return this.allowModifyingRow(event.row.data);
    },
    allowModifyingRow(rowData) {
      return rowData.documentTypeId === null ||
          this.documentTypes.some(documentType => documentType.id === rowData.documentTypeId
              && this.isMutableDocument(documentType, rowData));
    },
    isMutableDocument(documentType, rowData) {
      const isFromSalesOrInstallerApp = rowData.source === Constants.fileSources.SALES_APP
          || rowData.source === Constants.fileSources.INSTALLER_APP;
      return documentType.isDeletable && (!documentType.isImmutableWhenIosSource || !isFromSalesOrInstallerApp);
    },
    onCellPrepared(element) {
      this.disableEditForCOCDocument(element)
    },
    disableEditForCOCDocument(element) {
      if (element.rowType === "data" && element.column.command === "edit" && element.data.documentTypeId === Constants.COC_DOCUMENT_TYPE_ID) {
        element.cellElement.id = 'no-edit-allowed-' + element.rowIndex;
        document.getElementById("no-edit-allowed-" + element.rowIndex).firstChild.style.visibility = "hidden";
      }
    },
    getProperReferenceIdBasedOnParentType(hasCOCorVDAFileTypes) {
      let genericId;
      if (this.parentType === "lead" && this.leadReferenceId) {
        genericId = this.leadReferenceId;
      } else if (this.parentType === "job" && this.jobReferenceId) {
        genericId = this.jobReferenceId;
        if (hasCOCorVDAFileTypes) {
          this.$emit('refreshPageRequired');
        }
      } else if (this.parentType === "installer" && this.installerReferenceId) {
        genericId = this.installerReferenceId;
      } else if (this.parentType === "purchaseorder" && this.purchaseOrderNumber) {
        genericId = this.purchaseOrderNumber;
      } else if (this.parentType === "transferRequest" && this.transferRequestNumber) {
        genericId = this.transferRequestNumber;
      } else if (this.parentType === "shipment" && this.shipmentNumber) {
        genericId = this.shipmentNumber;
      } else if (this.parentType === "salesRep" && this.salesRepReferenceId) {
        genericId = this.salesRepReferenceId;
      }
      return genericId;
    },
    async getDocumentTypes() {
      try {
        const response = await Files.getRestApi().getAllDocumentTypes(this.documentTypesCategory);
        this.documentTypes = response.data;
        this.documentTypesToDisplay = response.data.filter(documentType => !documentType.isHidden);

        this.installTypesPerDocumentType = {};
        this.installTypes = [];

        this.documentTypes.forEach(documentType => {
          const installTypes = documentType.installTypes;
          if (installTypes && installTypes.length) {
            this.installTypesPerDocumentType[documentType.id] = installTypes;
            this.installTypes.push(...installTypes);
          }
        });

        this.installTypes = this.installTypes.filter((installType, index, self) => {
          return self.findIndex(i => i.id === installType.id) === index;
        });
      } catch (error) {
        this.emitError(error);
      }
    },
    getInstallTypeDataSource(e) {
      if (Object.keys(e).length !== 0) {
        return this.installTypesPerDocumentType[e.data.documentTypeId];
      } else {
        return this.installTypes;
      }
    },
    setCellValue(newData, value) {
      newData.installTypeId = null;
      newData.documentTypeId = value;
    },
    onSelectBoxValueChanged(e, cellData) {
      cellData.setValue(e.value);
    }
  }
}
</script>

<style>
.dx-datagrid-rowsview .dx-data-row .dx-validator.dx-datagrid-invalid {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 3px rgba(244, 67, 54, 0.32) !important;
}
</style>
