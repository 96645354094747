<template>
  <div class="separator pa-2" style="margin-left: 22px; margin-right: 10px">
    <table class="line-item-headers caption">
      <tr>
        <td width="6.5%">Created Date</td>
        <td width="6%">Start Date</td>
        <td width="5%">End Date</td>
        <td width="15%">Repeatable</td>
        <td width="19.5%">Description</td>
        <td width="12.5%">Sales Representative</td>
        <td width="9.5%">Market</td>
        <td width="16.5%">Request Status</td>
        <td class="text-sm-left">Action</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
</style>
