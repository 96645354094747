<template>
  <div class="itemPickerArea">
    <grid-toolbar
        :displayColumnChooser="displayColumnChooser"
        @onToggleResetGridDialog="clearGridState"
    />
    <v-card flat class="mr-3 ml-3">
      <return-from-job-items-grid
          ref="itemsGrid"
          :gridLineItems="dataSource"
          @rowSelected="onAddSelected"
      />
    </v-card>
    <div class="chipContainer">
      <template>
        <v-chip
            outlined
            close
            text-color="#333"
            v-for="item in selected"
            :key="item.referenceId"
            @click:close="onRemoveSelected(item)"
        >
          <span>[{{ item.ReferenceId }}] </span> <strong>{{ item.InternalSku }}</strong>&nbsp;
          <span>
          {{ item.StyleName ? ": " + item.StyleName.substring(0, 15) : "" }}
          </span>
        </v-chip>
      </template>
    </div>
  </div>
</template>

<script>
import ReturnFromJobItemsGrid from "./ReturnFromJobItemsGrid.vue";
import GridToolbar from "../../common/grid/GridToolbar";

export default {

  components: {
    GridToolbar,
    ReturnFromJobItemsGrid
  },

  props: {
    displayColumnChooser: {
      type: Boolean,
      default: false
    },
    branchId: Number,
    dataSource: {
      type: Array | null,
      required: false
    },
    selectedVendor: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      selected: [],
      error: null
    };
  },

  methods: {
    reset() {
      this.selected = [];
      this.removeSelection();
    },
    showError(error) {
      this.error = error;
    },
    onAddSelected(event) {
      if (event.selectedRowsData.length) {
        const newSelection = {...event.selectedRowsData[0]};
        let itemIsAlreadySelected = this.checkIfDuplicateExists(newSelection)
        if (itemIsAlreadySelected) {
          return;
        }
        this.selected.push(newSelection);
      }
    },
    checkIfDuplicateExists(newSelection) {
      return this.selected.some(item => item.ReferenceId === newSelection.ReferenceId);
    },
    onRemoveSelected(item) {
      let selectedRows = this.$refs.itemsGrid.getSelectedRows();
      selectedRows.forEach(itemToRemove => {
        if (itemToRemove.ReferenceId === item.ReferenceId) {
          this.removeSelection();
        }
      });
      this.selected = this.selected.filter(current => current.ReferenceId !== item.ReferenceId);
    },
    getSelectedItems() {
      return this.selected;
    },
    clearGridState() {
      this.$refs.itemsGrid.clearGrid();
    },
    removeSelection() {
      this.$refs.itemsGrid.deselectItems();
    }
  }
};
</script>

<style scoped>
.chipContainer {
  min-height: 75px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.itemPickerArea {
  padding-bottom: 20px;
}
</style>
