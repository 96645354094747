<template>
  <drawer-form
      ref="form"
      title="Create User"
      submitLabel="Save User"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <v-container>
      <user-edit-form
          ref="userEditForm"
          :isVisible="isVisible"
          :editing="false"
          :initialPasswordEnabled="true"
          :formData="null"
      >
        <template v-slot:mfa-control-tools>
          <user-mfa-control-tools
              :disabled="true"
              :mfaEnabled="true"
              :mfaSetUp="false"
          />
        </template>
      </user-edit-form>
    </v-container>
  </drawer-form>
</template>

<script>
import DrawerForm from "../../components/common/form/DrawerForm.vue";
import Users, {CreateAdminUserRequest, CreateSuperAdminUserRequest} from "../../rest/users";
import UserEditForm from "../../components/users/UserEditForm.vue";
import Constants from "../../assets/constants";
import UserMfaControlTools from "@/components/users/mfa/UserMfaControlTools.vue";

export default {
  components: {
    UserMfaControlTools,
    DrawerForm,
    UserEditForm
  },

  data() {
    return {
      inProgress: false
    };
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    onCancel() {
      this.$emit('cancelClicked');
      this.resetForm();
    },
    async onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        await this.createUser();
        this.$refs.userEditForm.resetPassword();
        this.$refs.userEditForm.resetDefaultRole();
      }
    },
    async createUser() {
      const role = this.$refs.userEditForm.userRole();
      switch (role) {
        case Constants.userRoles.admin:
          this.createAdminUser();
          break;
        case Constants.userRoles.marketingDataUploaderUser:
          this.createMarketingDataUploaderUser();
          break;
        case Constants.userRoles.regular:
          this.createRegularUser();
          break;
        case Constants.userRoles.rehashRepUser:
          this.createRehashUser();
          break;
        case Constants.userRoles.branchUser:
          this.createBranchUser();
          break;
        case Constants.userRoles.branchAdminUser:
          this.createBranchAdminUser();
          break;
        case Constants.userRoles.superAdmin:
          this.createSuperAdminUser();
          break;
        case Constants.userRoles.scgApiUser:
          this.createScgApiUser();
          break;
        case Constants.userRoles.generalManager:
          this.createGeneralManager();
          break;
        case Constants.userRoles.salesLeadOptimizerApiUser:
          this.createSalesLeadOptimizerApiUser();
          break;
        default:
          this.$emit("onCreateFail", "Invalid Role: " + role);
      }
    },
    async createAdminUser() {
      try {
        this.inProgress = true;
        const payload = new CreateAdminUserRequest(this.$refs.userEditForm.buildAdminUserPayload());
        const response = await Users.getRestApi().createAdminUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createSuperAdminUser() {
      try {
        this.inProgress = true;
        const payload = new CreateSuperAdminUserRequest(this.$refs.userEditForm.buildSuperAdminUserPayload());
        const response = await Users.getRestApi().createSuperAdminUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createRegularUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildPayload();
        const response = await Users.getRestApi().createUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createMarketingDataUploaderUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildPayload();
        const response = await Users.getRestApi().createMarketingDataUploaderUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createScgApiUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildPayload();
        const response = await Users.getRestApi().createScgApiUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },

    async createSalesLeadOptimizerApiUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildPayload();
        const response = await Users.getRestApi().createSalesLeadOptimizerApiUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },

    async createRehashUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildPayload();
        const response = await Users.getRestApi().createRehashUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createBranchUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildBranchUserPayload();
        const response = await Users.getRestApi().createBranchUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createBranchAdminUser() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildBranchUserPayload();
        const response = await Users.getRestApi().createBranchAdminUser(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    async createGeneralManager() {
      try {
        this.inProgress = true;
        const payload = this.$refs.userEditForm.buildBranchUserPayload();
        const response = await Users.getRestApi().createGeneralManager(payload);
        this.loadDefaults();
        this.$emit("onCreateSuccess", response.data);
      } catch (error) {
        this.$emit("onCreateFail", error);
      }
      this.inProgress = false;
    },
    loadDefaults() {
      this.$refs.form.resetValidation();
      this.$refs.userEditForm.loadForm();
    },
    resetForm() {
      this.$refs.userEditForm.resetDefaultRole();
      this.$refs.userEditForm.resetPassword();
      this.loadDefaults();
    }
  }
};
</script>
