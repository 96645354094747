import moment from "moment";

/**
 * @deprecated This module is deprecated and will be removed in future versions.
 * Please use the new DateUtils module instead.
 */
const DatePickerHelper = {
    addUtcOffset12Hours(date) {
        return moment.utc(date).add(-1 * moment().utcOffset() + 720, "m");
    },
    //instead of using moment(val, "YYYY-MM-DD") >= moment().subtract(1, "day");
    allowedDates(val) {
        const testDate = new Date(val);
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return testDate >= yesterday;
    }
}

export default DatePickerHelper;
