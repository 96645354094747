<template>
  <modal-dialog
      ref="dialog"
      title="Confirm overreceived"
      width="auto"
      :visible="dialogVisible"
      submitLabel="Confirm"
      cancelLabel="Cancel"
      @submitClicked="onOverreceivingApproved"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
  >
    <v-form ref="form">
      <v-container>
        <p>Please confirm that the following products were overreceived: </p>
        <pre class="overreceived"> {{ productsOverreceived }}</pre>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog.vue";

const formDefaults = {
  dialogVisible: false
};

export default {
  components: {ModalDialog},

  props: {
    productsOverreceived: String
  },

  data() {
    return {
      dialogVisible: false
    };
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onOverreceivingApproved() {
      this.closeDialog();
      this.$emit("onOverreceivingApproved");
    },
    onCancelClicked() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style>
.overreceived {
  font: inherit;
}
</style>
