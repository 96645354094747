import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Inventory In Transfer
 */

export class InventoryInTransfer {
    id: number;
    transferFrom: string;
    transferTo: string;
    internalSku: string;
    vendorSku: string;
    vendorName: string;
    productType: string;
    style: string;
    color: string;
    rollNumber: string;
    dyeLot: string;
    unitOfMeasure: string;
    transferRequestNumber: string;
    shipmentNumber: string;
    estimatedDeliveryDate: Date;
    vendorCost: number;
    totalValue: number;
    quantityInTransfer: number;
    boxQuantity: number;


    constructor(id: number, transferFrom: string, transferTo: string, internalSku: string, vendorSku: string,
                vendorName: string, productType: string, style: string, color: string, rollNumber: string, dyeLot: string,
                unitOfMeasure: string, transferRequestNumber: string, shipmentNumber: string, estimatedDeliveryDate: Date,
                vendorCost: number, totalValue: number, quantityInTransfer: number, boxQuantity: number) {
        this.id = id;
        this.transferFrom = transferFrom;
        this.transferTo = transferTo;
        this.internalSku = internalSku;
        this.vendorSku = vendorSku;
        this.vendorName = vendorName;
        this.productType = productType;
        this.style = style;
        this.color = color;
        this.rollNumber = rollNumber;
        this.dyeLot = dyeLot;
        this.unitOfMeasure = unitOfMeasure;
        this.transferRequestNumber = transferRequestNumber;
        this.shipmentNumber = shipmentNumber;
        this.estimatedDeliveryDate = estimatedDeliveryDate;
        this.vendorCost = vendorCost;
        this.totalValue = totalValue;
        this.quantityInTransfer = quantityInTransfer;
        this.boxQuantity = boxQuantity;
    }
}

/**
 * REST interface provided for Inventory In Transfer interactions
 */
export interface InventoryInTransferRestApi {
    getDetails(inventoryInTransferId: number): AxiosPromise<InventoryInTransfer>;
}

/**
 * REST implementation.
 */
class Implementation implements InventoryInTransferRestApi {
    getDetails(inventoryInTransferId: number): AxiosPromise<InventoryInTransfer> {
        return Axios.axiosInstance().get<InventoryInTransfer>(`/inventory-items-in-transfer/details/${inventoryInTransferId}`);
    }
}

const InventoryItemsInTransfer = {
    getRestApi(): InventoryInTransferRestApi {
        return new Implementation();
    }
};

export default InventoryItemsInTransfer;
