<template>
  <div>
    <section-divider title="PRODUCTS"/>
    <headers/>
    <v-card flat>
      <v-card-text class="pt-3 pb-3" v-if="productItems.length === 0 && canAddItems">
        <div class="title mb-3">Job does not currently contain any products.</div>
        <div class="subheading">
          Click <strong>Add Products</strong> to begin adding items from the product catalog.
        </div>
      </v-card-text>
      <panel
          :job="job"
          :line-item="item"
          v-for="item in productItems"
          :key="item.referenceId"
          @statusUpdated="onStatusUpdated"
          @commentUpdated="onCommentUpdated"
          @etaUpdated="onETAUpdated"
          @quantityUpdated="onQuantityUpdated"
          @priceUpdated="onPriceUpdated"
          @itemDeleted="onItemDeleted"
          @showDialog="onShowDialog"
      />
      <v-card-actions class="pr-3">
        <v-spacer></v-spacer>
        <text-button-with-tooltip
            :showButton="isReturnFromJobAvailable"
            :isDisabled="isDisabled"
            :toolTipMessage="toolTipMessage"
            :buttonText="'Return from Job'"
            @buttonClicked="onReturnFromJobClick"
        />
        <v-btn
            v-if="productItems.length > 0 && checkoutProductStatuses"
            color="primary"
            class="section-button"
            @click="navigateToCheckOutJobInventory"
        >
          Check Out Job
        </v-btn>
        <v-btn
            v-if="productItems.length > 0 && canAddItems"
            color="primary"
            class="section-button"
            @click="onAddAssociatedItems"
        >
          Add Related Products
        </v-btn>
        <v-btn
            v-if="canAddItems"
            color="primary"
            class="section-button"
            @click="onAddProductItems"
        >
          Add Products
        </v-btn>
      </v-card-actions>
    </v-card>
    <catalog-item-chooser
        ref="chooser"
        :branchId="jobBranchId"
        :productItems="initialItems"
        @itemsSelected="onCatalogItemsChosen"
    />
    <add-associated-items-dialog
        ref="associated"
        :items="productItems"
        @itemsSelected="onAssociatedItemsChosen"
    />
    <product-item-delete-dialog
        ref="deleteDialog"
        :message="deleteMessage"
    />

    <v-dialog v-model="isReturnFromJobModalVisible" persistent :width="1100">
      <return-from-job-dialog
          ref="returnFromJob"
          :productItems="initialItems"
          :hasVendorChargebacks="hasVendorChargebacks"
          :wsSessionId="wsSessionId"
          @submitReturnFromJob="onReturnFromJobSubmit"
          @closeDialog="closeReturnFromJobDialog"
          @onErrorOccurred="errorOccurred"
      />
    </v-dialog>
  </div>
</template>

<script>
import Constants from "../../../assets/constants";
import CreateReferenceIds from "../../../assets/referenceIds";
import CatalogItems from "../../../rest/catalogItems.ts";
import Headers from "../../../components/jobs/productLineItems/ProductLineItemsHeaders.vue";
import Panel from "../../../components/jobs/productLineItems/ProductLineItemsPanel.vue";
import CatalogItemChooser from "../../../components/jobs/productLineItems/CatalogItemChooser.vue";
import ReturnFromJobDialog from "../../../components/jobs/productLineItems/ReturnFromJobDialog.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";
import AddAssociatedItemsDialog from "../../../components/jobs/productLineItems/AddAssociatedItemsDialog.vue";
import ProductItemDeleteDialog from "../../../components/jobs/productLineItems/ProductItemDeleteDialog.vue";
import TextButtonWithTooltip from "../../common/templates/TextButtonWithTooltip.vue";

export default {
  components: {
    Headers,
    Panel,
    CatalogItemChooser,
    SectionDivider,
    AddAssociatedItemsDialog,
    ProductItemDeleteDialog,
    ReturnFromJobDialog,
    TextButtonWithTooltip
  },

  props: {
    job: {
      type: Object,
      required: true
    },
    initialItems: {
      type: Array,
      required: true
    },
    hasVendorChargebacks: {
      type: Boolean,
      required: true,
      default: false
    },
    wsSessionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      productItems: [],
      deleteMessage: null,
      isReturnFromJobModalVisible: false,
      toolTipMessage: "Job is invoiced. Return from job is not available for invoiced jobs."
    };
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.productItems = value;
      }
    }
  },

  computed: {
    jobBranchId() {
      return this.job.branchId;
    },
    canAddItems() {
      return !this.job.invoiced;
    },
    checkoutProductStatuses() {
      let allJobLineItemStatuses = [];
      for (let item in this.productItems) {
        allJobLineItemStatuses.push(this.productItems[item].jobLineItem.status);
      }
      return this.showCheckedOutButton(allJobLineItemStatuses);
    },
    isReturnFromJobAvailable() {
      return this.productItems.some(product => product.jobLineItem.status === 'CheckedOut');
    },
    isDisabled() {
      return this.job.invoiced;
    },
  },

  methods: {
    onAddProductItems() {
      this.$refs.chooser.openDialog();
    },
    onAddAssociatedItems() {
      this.$refs.associated.openDialog();
    },
    onReturnFromJobClick() {
      this.isReturnFromJobModalVisible = true;
    },
    // Load summary information for catalog items.
    onCatalogItemsChosen(items) {
      const results = items.map(item => {
        return CatalogItems.getRestApi().getCatalogItemSummaryByItemIdAndBranchId(item.Id, this.jobBranchId);
      });
      Promise.all(results).then(completed =>
          this.addLineItemsFor(completed.map(result => result.data))
      );
    },
    closeReturnFromJobDialog() {
      this.isReturnFromJobModalVisible = false;
    },
    onReturnFromJobSubmit() {
      const message = 'Return from job operation was successful';
      this.closeReturnFromJobDialog();
      this.$emit('refresh');
      this.$emit('onReturnFromJobSuccess', message);
    },
    // Load summary information for associated items.
    onAssociatedItemsChosen(items) {
      const results = items.map(item => {
        return CatalogItems.getRestApi().getCatalogItemSummary(item.associatedItem.id);
      });
      Promise.all(results).then(completed =>
          this.addLineItemsFor(completed.map(result => result.data))
      );
    },
    // Add new line items for each catalog summary item.
    addLineItemsFor(summaryItems) {
      summaryItems.forEach(item => {
        item.jobLineItem = {
          referenceId: CreateReferenceIds.getTempReferenceId(),
          type: Constants.jobType.product,
          quantity: 0,
          price: 0,
          status: "New",
          comment: "",
          eta: null
        };
        this.productItems.push(item);
      });
      this.$emit('itemsUpdated', this.productItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    async navigateToCheckOutJobInventory() {
      this.$router.push({path: `/inventory/management/checkout/job/`, query: {referenceId: this.job.referenceId}});
    },
    showCheckedOutButton(allJobLineItemStatuses) {
      const hideButtonIfAllItemsHaveStatuses = ['CheckedOut', 'New'];
      return !allJobLineItemStatuses.every(elem => hideButtonIfAllItemsHaveStatuses.includes(elem));
    },
    onItemDeleted(referenceId) {
      this.productItems = this.productItems.filter(
          current => current.jobLineItem.referenceId !== referenceId
      );
      this.$emit('itemsUpdated', this.productItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    onShowDialog(message) {
      this.deleteMessage = message;
      this.$refs.deleteDialog.openDialog();
    },
    onStatusUpdated() {
      this.markDirty();
    },
    onCommentUpdated() {
      this.markDirty();
    },
    onETAUpdated() {
      this.markDirty();
    },
    onQuantityUpdated() {
      this.markDirty();
    },
    onPriceUpdated() {
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    markDirty() {
      this.$emit('dataUpdated');
    },
    errorOccurred(error) {
      this.$emit('onErrorOccurred', error);
    }
  }
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
