<template>
  <v-layout class="nav-bar">
    <v-btn
        v-if="$route.meta.navBackButton"
        dark
        class="back-button"
        color="primary"
        title="Back to List"
        @click="onGoBack"
    >
      <v-icon>chevron_left</v-icon>
    </v-btn>
    <v-app-bar dense flat>
      <v-row>
        <v-col>
          <navigation-menu-item
              label="Sales"
              section="sales"
              :currentSection="navSection"
              :submenus="salesSubmenus"
              :primaryUrl="'/leads'"
          />
        </v-col>
        <v-col>
          <navigation-menu-item
              label="Order Management"
              section="orders"
              :primaryUrl="'/orders/purchases'"
              :currentSection="navSection"
              :submenus="ordersSubmenus"
          />
        </v-col>
        <v-col>
          <navigation-menu-item
              label="Warehouse"
              section="warehouse"
              :primaryUrl="'/inventory/items'"
              :currentSection="navSection"
              :submenus="warehouseSubmenus"
          />
        </v-col>
        <v-col>
          <navigation-menu-item
              label="Customer Service"
              section="customer"
              :primaryUrl="'/customers'"
              :currentSection="navSection"
              :submenus="customerSubmenus"
          />
        </v-col>
        <v-col>
          <navigation-menu-item
              label="Reporting"
              section="reporting"
              :currentSection="navSection"
              :primaryUrl="'/reporting-metabase'"
          />
        </v-col>
        <v-col>
          <navigation-menu-item
              v-if="isNotRehashUser"
              label="Installer Calendar"
              section="installer"
              :primaryUrl="'/installers/calendar'"
              :currentSection="navSection"
              :submenus="installerSubmenus"
          />
        </v-col>
        <v-col>
          <navigation-menu-item
              v-if="isAdminUser"
              label="Admin"
              section="admin"
              :primaryUrl="'/vendors'"
              :submenus="filteredAdminSubmenus"
              :currentSection="navSection"
          />
        </v-col>
      </v-row>
    </v-app-bar>
  </v-layout>
</template>

<script>
import NavigationMenuItem from "../../components/navigation/NavigationMenuItem.vue";
import Roles from "../../assets/roles.js";
import DaysOff from "../../rest/daysOff";

export default {
  components: {NavigationMenuItem},

  props: {
    navSection: String
  },

  data() {
    return {
      reviewsCount: null,
      salesSubmenus: [
        {label: "Leads", to: "/leads"},
        {label: "Jobs", to: "/jobs"}
      ],
      ordersSubmenus: [
        {label: "Purchase Orders", to: "/orders/purchases"},
        {label: "Check In - PO Inventory", to: "/inventory/management/checkin/purchase_order"},
        {label: "Check Out - Job Inventory", to: "/inventory/management/checkout/job"},
        {label: "Check In - Inventory Adjustments", to: "/inventory/management/checkin/general"},
        {label: "Check Out - Inventory Adjustments", to: "/inventory/management/checkout/general"}
      ],
      adminSubmenus: [
        {label: "Vendors", to: "/vendors", branchAdminAllowed: true},
        {label: "Installers", to: "/installers", branchAdminAllowed: true},
        {label: "Sales Representatives", to: "/sales-representatives", branchAdminAllowed: true},
        {label: "Markets", to: "/markets", branchAdminAllowed: true},
        {label: "Branches", to: "/branches", branchAdminAllowed: true},
        {label: "Bins", to: "/inventory/bins", branchAdminAllowed: true},
        {label: "Promo Codes", to: "/promos", branchAdminAllowed: true},
        {label: "Product Types", to: "/product/types", branchAdminAllowed: true},
        {label: "Product Catalog Items", to: "/orders/catalog", branchAdminAllowed: true},
        {label: "Users", to: "/users", branchAdminAllowed: true},
        {label: "Time Block Sales Reps", to: "/time-blocks-sales-rep", branchAdminAllowed: true},
        {label: "Broadcast Message", to: "/broadcast", branchAdminAllowed: false},
        {label: "Sales Reps Days Off", to: "/days-off", branchAdminAllowed: true, daysOffCounter: true},
        {label: "Super Admin Tools", to: "/super-admin-tools", branchAdminAllowed: false, isSuperAdminSubmenu: true},
      ],
      warehouseSubmenus: [
        {label: "Inventory", to: "/inventory/items"},
        {label: "Transfer Request", to: "/inventory/transfer-requests"},
        {label: "Shipments", to: "/inventory/shipments"},
        {label: "Check In - Branch Transfer", to: "/inventory/check-in/branch-transfer"},
        {label: "Check Out - Branch Transfer", to: "/inventory/check-out/branch-transfer"},
        {label: "Inventory in Transfer", to: "/inventory/in-transfer"},
      ],
      customerSubmenus: [
        {label: "Customers", to: "/customers"},
        {label: "Tickets", to: "/tickets"}
      ],
      installerSubmenus: [
        {label: "Slots management", to: "/installers/calendar"},
        {label: "Installations", to: "/installers/installations"}
      ]
    };
  },

  computed: {
    isAdminUser() {
      return Roles.isAnyAdminUser();
    },
    isNotRehashUser() {
      return !Roles.isRehashUser();
    },
    filteredAdminSubmenus() {
      if (Roles.isBranchAdmin()) {
        return this.adminSubmenus.filter(submenu => submenu.branchAdminAllowed && !submenu.isSuperAdminSubmenu);
      }
      if (Roles.isSuperAdminUser()) {
        return this.adminSubmenus;
      }
      return this.adminSubmenus.filter(submenu => !submenu.isSuperAdminSubmenu);
    }
  },

  created() {
    this.setDayOffCounter();
  },

  methods: {
    onGoBack() {
      this.$router.go(-1);
      this.$emit("goBackButtonClicked");
    },
    setDayOffCounter() {
      let component = this;
      DaysOff.getRestApi()
          .getUnreviewedDaysOffCount()
          .then((response) => {
            let result = component.adminSubmenus.find(e => e.daysOffCounter);
            result.label = "Sales Reps Days Off (" + response.data + ")";
          });
    }
  }
};
</script>

<style scoped>
.nav-bar {
  overflow-x: hidden;
  background-color: #f5f8fa;
  border-top: 1px solid #d1dbe3;
  border-bottom: 1px solid #d1dbe3;
}

.back-button {
  padding: 0;
  border-radius: 0;
  height: 48px !important;
}

</style>
