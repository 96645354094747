<template>
  <v-card flat class="pa-2 mb-1">
    <table class="line-items body-2">
      <tr class="item-row">
        <td width="60%" class="text-truncate">
          <div class="text-truncate"><strong>Product Type: {{ inventoryItem.ProductType }}</strong></div>
          <div class="text-truncate">Internal SKU: {{ inventoryItem.InternalSku }}</div>
          <div class="text-truncate" v-if="inventoryItem.ProductColor">Color: {{ inventoryItem.ProductColor }}</div>
          <div class="text-truncate" v-if="inventoryItem.ProductStyle">Style: {{ inventoryItem.ProductStyle }}</div>
          <div :class="{ 'highlight': highlight }" class="text-truncate" v-if="inventoryItem.BoxQuantity">Box Quantity: {{ inventoryItem.BoxQuantity }}</div>
          <div class="text-truncate" v-if="inventoryItem.RollNumber">RollNumber: {{ inventoryItem.RollNumber }}</div>
          <div class="text-truncate" v-if="inventoryItem.DyeLot ">Dye Lot: {{ inventoryItem.DyeLot }}</div>
          <div :class="{ 'highlight': highlight }" class="text-truncate" v-if="step === 3">Quantity Available: {{ inventoryItem.QuantityAvailable }} </div>
        </td>

        <td width="20%" class="pt-0" v-if="step===3">
          <v-text-field
              id="qtyToTransfer"
              v-if="!readonly"
              type="number"
              v-model="quantityToTransfer"
              placeholder="0"
              class="short-input"
              :readonly="readonly"
              :rules="[rules.validQuantity, rules.required,  rules.validQuantityCompareToMaxValue]"
              hide-details
          />
        </td>
        <td width="20%" class="pt-0">
          <v-text-field
              id="qtyRoundedToBoxQty"
              type="number"
              v-model="computedQuantity"
              placeholder="0"
              class="short-input"
              disabled
          />
        </td>

        <td width="20%" class="pt-0" v-if="step===4">
          <v-text-field
              id="vendorCost"
              prefix="$"
              type="number"
              v-model="inventoryItem.VendorCost"
              placeholder="0"
              class="short-input"
              disabled
          />
        </td>

        <td width="20%" class="pt-0" v-if="step===4">
          <v-text-field
              id="totalValue"
              prefix="$"
              type="number"
              :value="inventoryItem.TotalValue"
              placeholder="0"
              class="short-input"
              disabled
          />
        </td>
      </tr>
    </table>
    <p class="submitError" v-show="itemError">{{ itemError }}</p>
  </v-card>
</template>

<script>
import Rules from "../../assets/rules";
import BoxQuantity from "../../assets/boxQuantity";

export default {
  components: {},

  props: {
    inventoryItem: {
      type: Object,
      required: true
    },
    readonly: Boolean,
    index: Number,
    step: Number
  },

  data() {
    return {
      quantityToTransfer: null,
      quantity: null,
      itemError: null,
      rules: {
        required: Rules.required,
        validQuantity: Rules.isPositiveNumericQuantityValue,
        validQuantityCompareToMaxValue: (value) => {
          return Rules.validQuantityCompareToMaxValue(value, this.inventoryItem.QuantityAvailable);
        }
      }
    };
  },

  computed: {
    highlight() {
      return this.detectBoxQuantityLessThanAvailableQuantity();
    },
    computedQuantity() {
      return this.quantity || this.inventoryItem.QuantityToTransfer;
    }
  },

  watch: {
    quantityToTransfer(value) {
      if (value) {
        this.itemError = null;
        let computedQuantity = BoxQuantity.roundValueToBoxQuantity(value, this.inventoryItem.BoxQuantity);
        this.quantity = computedQuantity;
        this.validateEnteredQuantity(value, computedQuantity);
      } else {
        this.$emit('valueCleared');
      }
    }
  },

  methods: {
    validateEnteredQuantity(value, computedQuantity) {
      let floatAndExceeds = this.checkIfEnteredValueIsFloatForNonBoxQtyItemAndNotExceedsMaxValue(value);
      let isFloatingPointNumber = this.canHaveFloatingPointNumberEntered(this.inventoryItem);

      if (floatAndExceeds && !isFloatingPointNumber) {
        this.itemError = `Entered Quantity to Transfer ${value} must be an integer`;
        this.$emit('enteredValueIsFloatForNonBoxQtyItem', value);
        return;
      }

      let checkIfEnteredQtyIsMoreThanAvailable = this.checkIfEnteredQtyIsMoreThanAvailable(value, floatAndExceeds);

      if (checkIfEnteredQtyIsMoreThanAvailable) {
        if (value) {
          this.itemError = value <= 0
              ? 'Quantity to transfer must be greater than 0'
              : `Quantity to transfer cannot exceed available quantity ${this.inventoryItem.QuantityAvailable}`;
        }
        this.$emit('enteredQtyIsMoreThanAvailable', value, this.inventoryItem.QuantityAvailable);
      } else {
        this.$emit('computeQuantity', computedQuantity, this.index);
      }
    },
    checkIfEnteredValueIsFloatForNonBoxQtyItemAndNotExceedsMaxValue(value) {
      return Rules.isFloat(value) && !this.inventoryItem.BoxQuantity && Rules.validQuantityCompareToMaxValue(value, this.inventoryItem.QuantityAvailable);
    },
    checkIfEnteredQtyIsMoreThanAvailable(value, floatAndExceeds) {
      return !Rules.validQuantityCompareToMaxValue(value, this.inventoryItem.QuantityAvailable) && !floatAndExceeds;
    },
    clearPreviouslyEnteredQuantity() {
      this.quantityToTransfer = null;
      this.quantity = null;
      this.itemError = null;
    },
    canHaveFloatingPointNumberEntered(inventoryItem) {
      return inventoryItem?.UnitOfMeasure === "Square Feet"
          || inventoryItem?.UnitOfMeasure === "Square Yards"
          || inventoryItem?.UnitOfMeasure === "Linear Feet";
    },
    detectBoxQuantityLessThanAvailableQuantity() {
      return this.inventoryItem.BoxQuantity && this.inventoryItem.QuantityAvailable < this.inventoryItem.BoxQuantity;
    }
  }
};
</script>

<style scoped>
.line-items {
  text-align: left;
  color: #666;
  width: 100%;
  table-layout: fixed;
  padding-bottom: 10px;
}
.highlight {
  color: red;
}
.submitError {
  color: red;
  padding-right: 15px;
  height: 25px;
}
</style>
