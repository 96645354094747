import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import * as AxiosUtils from "./util/axiosUtils";

/**
 * Installation.
 */
export class Installation {
  id: number;
  installerId: number;
  jobId: number;
  jobLineItemId: number;
  installationDate: string;
  installationTime: string;
  comment: string;

  constructor(
    id: number,
    installerId: number,
    jobId: number,
    jobLineItemId: number,
    installationDate: string,
    installationTime: string,
    comment: string
  ) {
    this.id = id;
    this.installerId = installerId;
    this.jobId = jobId;
    this.jobLineItemId = jobLineItemId;
    this.installationDate = installationDate;
    this.installationTime = installationTime;
    this.comment = comment;
  }
}

/**
 * REST interface provided for installations.
 */
export interface InstallationsRestApi {
  createInstallation(installation: Installation, wsSessionId: string): AxiosPromise<Installation>;
  getInstallationById(id: number): AxiosPromise<Installation>;
  deleteInstallation(installationId: number, wsSessionId: string): AxiosPromise<Installation>;
  createInstallationEarlyPayment(refId: string, payload: object): AxiosPromise<Installation>;
}

/**
 * REST implementation provided for installations.
 */
class Implementation implements InstallationsRestApi {
  createInstallation(installation: Installation, wsSessionId: string): AxiosPromise<Installation> {
    const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
    return Axios.axiosInstance().post<Installation>(`/installations`, installation, config);
  }

  getInstallationById(id: number): AxiosPromise<Installation> {
    return Axios.axiosInstance().get<Installation>(`/installations/${id}`);
  }

  deleteInstallation(installationId: number, wsSessionId: string): AxiosPromise<Installation> {
    const config = AxiosUtils.generateWsSessionIdHeaderConfig(wsSessionId);
    return Axios.axiosInstance().delete(`/installations/${installationId}`, config);
  }

  /** Create an early payment for an installer */
  createInstallationEarlyPayment(refId: string, payload: object) {
    return Axios.axiosInstance().post<Installation>(`/installations/${refId}/retainage`, payload);
  }
}

const Installers = {
  getRestApi(): InstallationsRestApi {
    return new Implementation();
  }
};

export default Installers;
