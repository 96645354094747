<template>
  <landing-page>
    <v-form
        ref="form"
        v-show="!showOtpForm && !showAuthAppSetUpForm && !showEmailTokenForm"
        class="landing-form"
        v-model="valid"
        lazy-validation
    >
      <v-text-field
          id="login-field-email"
          class="email-field"
          v-model="email"
          label="Email Address"
          required
          placeholder=" "
          persistent-placeholder
          @keyup.enter.native="onLoginClick"
      />
      <v-text-field
          id="login-field-password"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'visibility_off' : 'visibility'"
          label="Password"
          required
          placeholder=" "
          persistent-placeholder
          @keyup.enter.native="onLoginClick"
          @click:append="showPassword = !showPassword"
      />

      <v-row class="pa-1">
        <div class="forgot-password" id="login-forgot-password-button">
          <router-link to="/request">Forgot Password</router-link>
        </div>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn
              id="login-log-in-button"
              class="ma-0 mt-3 login-button white--text"
              color="#59b"
              large
              :disabled="!fieldsEntered"
              append-icon="search"
              @click="onLoginClick"
          >
            <span class="login-text">Log In</span>
            <v-spacer></v-spacer>
            <v-icon dark>arrow_forward</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-form v-show="showOtpForm" ref="otp-form" class="landing-form">
      <otp-form
          ref="otpForm"
          :error="hasOTPVerificationError && showOtpForm"
          :userMfaSettings="authSetUpResponse?.userMfaSettings"
          @otpEntered="onOtpEntered"
          @backToLogin="onBackToLoginClicked"
      />
    </v-form>

    <v-form v-show="showQrCodeSetupForm" ref="auth-app-set-up-form" class="landing-form">
      <auth-app-set-up-form
          :authResponse="authSetUpResponse"
          @otpAppSetupFinished="onOtpAppSetupFinished"
      />
    </v-form>

    <v-form v-show="showEmailTokenForm" ref="email-token-form" class="landing-form">
      <token-confirmation-form
          :email="email"
          :authResponse="authSetUpResponse"
          @backToCredentialsForm="onBackToCredentialsForm"
          @isTokenVerified="onEmailTokenVerified"
          @tokenVerificationError="displayFormMessage"
      />
    </v-form>

    <v-row>
      <v-col cols="12">
        <div v-if="formMessage" class="form-message">
          {{ loginFormMessage }}
        </div>
      </v-col>
    </v-row>

  </landing-page>
</template>

<script>
import LandingPage from "../LandingPage.vue";
import Users, {User} from "../../rest/users";
import {checkVersion} from "@/rest/version";
import Authentication from "@/rest/authentication";
import OtpForm from "@/views/users/OtpForm.vue";
import AuthAppSetUpForm from "@/views/users/AuthAppSetUpForm.vue";
import SectionDivider from "@/components/jobs/SectionDivider.vue";
import {AuthHelper} from "@/assets/authHelper";
import TokenConfirmationForm from "@/views/users/TokenConfirmationForm.vue";
import {LoginErrorMessages} from "@/assets/errorMessages";
import Constants from "@/assets/constants";

export default {
  components: {
    TokenConfirmationForm,
    SectionDivider,
    OtpForm,
    AuthAppSetUpForm,
    LandingPage
  },

  data: () => ({
    valid: true,
    email: "",
    password: "",
    showPassword: false,
    autoUpdateTimer: 0,
    snackbar: false,
    formMessage: false,
    loginFormMessage: "",
    showOtpForm: false,
    showEmailTokenForm: false,
    showAuthAppSetUpForm: false,
    hasOTPVerificationError: false,
    authSetUpResponse: null
  }),

  mounted() {
    this.checkInactiveLogout();
    this.checkTokenExpireLogout();
    this.checkVersionLogout();
    this.checkPaginationPageSize();
    checkVersion();
  },

  computed: {
    fieldsEntered() {
      return this.email && this.password;
    },
    showQrCodeSetupForm() {
      return this.authSetUpResponse?.userMfaSettings?.securityTokenVerified && this.showAuthAppSetUpForm;
    }
  },

  methods: {
    onLoginClick() {
      this.attemptLogin();
    },
    attemptLogin() {
      this.hideFormMessage();
      this.resetLogoutType();
      this.authenticationStep();
    },
    async authenticationStep() {
      try {
        let response = await Users.getRestApi().authenticate(this.email, this.password);
        let authResponse = response.data;

        if (this.isAuthResponseSuccess(authResponse)) {
          this.setUserDataOnSuccessfulLogin(authResponse);
        } else {
          if (this.isSecurityTokenFormShouldBeDisplayed(authResponse)) {
            this.showEmailTokenForm = true;
          } else if (this.isSetUpFormShouldBeDisplayed(authResponse)) {
            this.authSetUpResponse = authResponse;
            this.showAuthAppSetUpForm = true;
          } else if (this.isVerifyStepShouldBeDisplayed(authResponse)) {
            this.authSetUpResponse = authResponse;
            this.showOtpForm = true;
          }
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.displayFormMessage(LoginErrorMessages.unsuccessfulLogin);
        } else if (error.status === 403) {
          this.displayFormMessage(LoginErrorMessages.webAccessForbidden);
        } else {
          this.displayFormMessage(LoginErrorMessages.defaultError);
        }
      }
    },
    async verificationStep(email, verificationObject) {
      try {
        const verificationRequest = {
          email: email,
          otp: verificationObject.otp,
          dontAskMfaAgainEnabled: verificationObject.dontAskMfaAgainEnabled,
          password: this.password
        };
        const response = await Authentication.getRestApi().verifyOTP(verificationRequest);
        let verificationResponse = response.data;
        this.proceedWithVerificationResponse(verificationResponse);
      } catch (error) {
        if (error.status === 403) {
          this.hasOTPVerificationError = true;
          this.$refs.otpForm.clear();
          this.displayFormMessage(LoginErrorMessages.otpVerificationFailed);
        } else {
          this.hasOTPVerificationError = true;
          this.$refs.otpForm.clear();
          this.displayFormMessage(error.message);
        }
      }
    },
    setUserDataOnSuccessfulLogin(authResponse) {
      let user = new User(authResponse.user);
      let accessToken = authResponse.accessToken;
      let refreshToken = authResponse.refreshToken;
      let deviceId = authResponse?.userMfaSettings?.deviceId;

      this.updateLocalStorage(user, deviceId);

      if (AuthHelper.isSafariBrowser()) {
        AuthHelper.updateSessionStorage(user, accessToken, refreshToken);
      }

      this.$router.push("/customers");
      this.clearCredentials();
    },
    proceedWithVerificationResponse(verificationResponse) {
      if (verificationResponse.accessToken) {
        this.setUserDataOnSuccessfulLogin(verificationResponse);
      } else {
        this.displayFormMessage(LoginErrorMessages.unsuccessfulLogin);
      }
    },
    checkInactiveLogout() {
      if (this.isInactiveLogout()) {
        this.displayFormMessage(LoginErrorMessages.inactiveLogout);
      }
    },
    checkTokenExpireLogout() {
      if (this.$store.state.loggedInUser === "tokenExpireLogout") {
        this.displayFormMessage(LoginErrorMessages.tokenExpired);
      }
    },
    checkVersionLogout() {
      if (this.$store.state.loggedInUser === "versionLogout") {
        this.displayFormMessage(LoginErrorMessages.versionLogout);
      }
    },
    checkPaginationPageSize() {
      Object.entries(this.$store.state.gridStore).forEach(gridStore => {
        if (gridStore[1].pageSize.length <= 0 || gridStore[1].pageSize.length === undefined) {
          this.setDefaultPageSize(this.$store.state.gridStore, gridStore[0]);
        }
      });
    },
    resetLogoutType() {
      if (this.$store.state.loggedInUser || this.$store.state.jwt) {
        this.$store.commit("logout", null);
      }
    },
    displayFormMessage(message) {
      this.loginFormMessage = message;
      this.formMessage = true;
    },
    hideFormMessage() {
      this.formMessage = false;
    },
    setDefaultPageSize(state, gridProperty) {
      this.$store.commit('setDefaultPageSize', state, gridProperty);
    },
    clearCredentials() {
      this.email = "";
      this.password = "";
    },
    onOtpEntered(otp) {
      this.verificationStep(this.email, otp);
    },
    onOtpAppSetupFinished(buildVerificationObject) {
      this.verificationStep(this.email, buildVerificationObject);
    },
    onBackToLoginClicked() {
      this.hideFormMessage();
      this.hasOTPVerificationError = false;
      this.showOtpForm = false;
    },
    updateLocalStorage(user, deviceId) {
      this.$store.commit("loginSuccess", user);
      this.$store.commit("setDeviceId", deviceId);
    },
    isVerifyStepShouldBeDisplayed(authResponse) {
      return !authResponse?.accessToken && authResponse?.userMfaSettings?.mfaAppSetUp && authResponse?.userMfaSettings?.mfaEnabled
          && (authResponse?.userMfaSettings && !authResponse?.userMfaSettings?.isDontAskMfaActive);
    },
    isSetUpFormShouldBeDisplayed(authResponse) {
      return !authResponse.accessToken && !authResponse?.userMfaSettings?.mfaAppSetUp && authResponse?.userMfaSettings?.mfaEnabled;
    },
    isSecurityTokenFormShouldBeDisplayed(response) {
      return response?.userMfaSettings?.mfaEnabled && !response?.userMfaSettings?.mfaAppSetUp;
    },
    onEmailTokenVerified(verificationResponse) {
      this.clearFormErrors();
      this.showEmailTokenForm = false;
      this.authSetUpResponse = verificationResponse;
      this.showAuthAppSetUpForm = true;
    },
    onBackToCredentialsForm() {
      this.clearFormErrors();
      this.showEmailTokenForm = false;
      this.showAuthAppSetUpForm = false;
    },
    clearFormErrors() {
      this.loginFormMessage = null;
      this.formMessage = false;
    },
    isAuthResponseSuccess(authResponse) {
      return authResponse.accessToken && authResponse.refreshToken && authResponse.user;
    },
    isInactiveLogout() {
      return this.$store.state.loggedInUser === Constants.logoutTypes.INACTIVE
          || this.$store.state.loginError === Constants.logoutTypes.INACTIVE;
    }
  }
};
</script>

<style scoped>
.email-field {
  padding-bottom: 20px;
}
.login-button {
  width: 100%;
  z-index: 1;
}
.forgot-password {
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding-right: 5px;
  color: #309;
}
.form-message {
  color: red;
}
.login-text {
  width: 100%;
  padding-left: 24px;
}
.v-text-field >>> .v-input__append-inner {
  margin-right: 10px;
}
</style>
