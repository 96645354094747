<template>
  <v-form ref="form" v-model="valid">
    <v-row class="ma-1">
      <v-col class="form-message" data-id="password-change-message">
        Password must consist of a minimum of 6 characters and at least one number.
      </v-col>
    </v-row>

    <v-row class="ma-3">
      <!-- if user changing own password, prompt for existing password -->
      <v-text-field
          v-if="changePassword"
          class="form-input"
          data-id="existing-password"
          placeholder=" "
          required
          autocomplete
          :outline="outline"
          @keyup.enter.native="submitClick"
          v-model="currentPassword.value"
          @click:append="currentPassword.visible = !currentPassword.visible"
          :append-icon="currentPassword.visible ? 'visibility_off' : 'visibility'"
          :type="currentPassword.visible ? 'text' : 'password'"
          :rules="[rules.required, rules.min]"
          label="* Current Password"
          persistent-placeholder
      />
      <!-- if user resetting own password, prompt for temp password -->
      <v-text-field
          v-if="resetPassword"
          class="form-input"
          data-id="reset-password"
          required
          placeholder=" "
          autocomplete
          @keyup.enter.native="submitClick"
          v-model="temporaryPassword.value"
          @click:append="temporaryPassword.visible = !temporaryPassword.visible"
          :append-icon=" temporaryPassword.visible ? 'visibility_off' : 'visibility'"
          :type="temporaryPassword.visible ? 'text' : 'password'"
          :rules="[rules.required]"
          label="Temporary Password (check your email)"
          persistent-placeholder
      />
      <v-text-field
          class="form-input"
          data-id="new-password"
          required
          autocomplete
          placeholder=" "
          v-bind="progressVisible"
          @keyup.enter.native="submitClick"
          v-model="newPassword.value"
          @click:append="newPassword.visible = !newPassword.visible"
          :append-icon="newPassword.visible ? 'visibility_off' : 'visibility'"
          :type="newPassword.visible ? 'text' : 'password'"
          :rules="[rules.required, rules.min, rules.validate, rules.denyMatch]"
          label="* New Password"
          persistent-placeholder
      >
        <template v-slot:progress>
          <v-progress-linear
              data-id="password-change-strength"
              :value="progress"
              :color="color"
              height="2"
          />
        </template>

      </v-text-field>

      <br/>
      <v-text-field
          class="form-input"
          data-id="confirm-password"
          placeholder=" "
          required
          autocomplete
          @keyup.enter.native="submitClick"
          v-model="verifyPassword.value"
          @click:append="verifyPassword.visible = !verifyPassword.visible"
          :append-icon="verifyPassword.visible ? 'visibility_off' : 'visibility'"
          :type="verifyPassword.visible ? 'text' : 'password'"
          :rules="[rules.required, rules.min, rules.validate, rules.confirmMatch]"
          label="* Re-type Password"
          persistent-placeholder
      />
    </v-row>
    <div class="password-buttons">
      <v-btn
          data-id="cancel-change-password-button"
          large
          v-if="cancelButton"
          color="#59b"
          class="password-button white--text"
          @click="cancelClick"
      >
        {{ cancelLabel }}
      </v-btn>
      <v-btn
          data-id="submit-change-password-button"
          large
          color="#59b"
          class="password-button white--text"
          :disabled="!valid"
          @click="submitClick"
      >
        {{ submitLabel }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
const queryString = require("query-string");

export default {
  components: {},
  props: {
    submitLabel: {
      type: String,
      default: "Save"
    },
    cancelLabel: {
      type: String,
      default: "Cancel"
    },
    cancelButton: {
      type: Boolean,
      default: false
    },
    resetPassword: {
      type: Boolean,
      default: false
    },
    changePassword: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean
    },
    email: {
      type: String,
      default: null
    },
    overridePassword: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      valid: false,
      temporaryPassword: {
        value: "",
        visible: false
      },
      currentPassword: {
        value: "",
        visible: false
      },
      newPassword: {
        value: "",
        visible: false
      },
      verifyPassword: {
        value: "",
        visible: false
      },
      rules: {
        required: value => (!value ? "Required." : true),
        min: value => (!value || value.length < 6 ? "Min 6 characters" : true),
        validate: value =>
            !value ||
            !value.match(/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).+\S$/gi)
                ? "Password must contain at least one letter and one number."
                : true,
        confirmMatch: () =>
            this.newPassword.value !== this.verifyPassword.value
                ? "Passwords must match"
                : true,
        denyMatch: () =>
            this.newPassword.value === this.currentPassword.value
                ? "New password cannot be the same as the old password."
                : true
      }
    };
  },

  computed: {
    progress() {
      // get input validated progress bar length
      if (this.newPassword.value) {
        return Math.min(100, this.newPassword.value.length * 17);
      }
      return 0;
    },
    color() {
      // get validated progress bar color
      let validation = 0;
      const value = this.newPassword.value;
      if (value) {
        if (value.length >= 6) {
          if (value.match(/\d/i)) {
            validation++;
          }
          if (value.match(/[a-z]/i)) {
            validation++;
          }
        }
      }
      return ["error", "warning", "success"][validation];
    },
    progressVisible() {
      let result = false;
      if (this.progress > 0) {
        result = true;
      }
      return {loading: result};
    }
  },

  methods: {
    getEmail() {
      const query = queryString.parse(location.search);
      if (query.email) {
        return query.email;
      } else if (this.email) {
        return this.email;
      } else if (
          this.$store &&
          this.$store.state &&
          this.$store.state.loggedInUser
      ) {
        return this.$store.state.loggedInUser.email;
      } else {
        return null;
      }
    },
    buildPayload() {
      const payload = {};
      payload.id = this.$data.id;
      payload.email = this.getEmail();
      payload.temporaryPassword = this.temporaryPassword.value;
      payload.currentPassword = this.currentPassword.value;
      payload.newPassword = this.newPassword.value;
      return payload;
    },
    submitClick() {
      this.valid = this.$refs.form.validate();
      if (this.valid === true) {
        this.$emit("submitClick");
      }
      this.resetPasswordForm();
    },
    cancelClick() {
      this.resetPasswordForm();
      this.$emit("cancelClick");
    },
    resetPasswordForm() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style scoped>
.form-message {
  width: fit-content;
  font-size: 13px;
}
.form-input {
  margin: 15px 0;
  width: 400px;
  align-self: center;
  justify-self: center;
}
.password-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.password-button {
  margin: 0 15px;
}
.v-progress-linear {
  position: absolute !important;
}
</style>
