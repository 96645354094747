<template>
  <div>
    <v-icon
        v-if="cellData.data.checkedIn || cellData.data.purchaseOrderClosedDate"
        color="primary"
    >
      check_circle_outline
    </v-icon
    >
    <v-btn
        v-else
        small color="primary"
        @click.stop="onCheckIn"
    >
      Check In
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    cellData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  watch: {
    cellData: {
      immediate: true,
      handler(newVal) {
      }
    }
  },

  methods: {
    onCheckIn() {
      this.$emit("checkIn", this.cellData.data);
    }
  }
};
</script>
