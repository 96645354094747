<template>
  <div class="separator">
    <table class="line-item-headers caption">
      <tr>
        <td class="labor-panel-reference-id-width">Reference Id</td>
        <td class="labor-panel-install-type-width left-padding">Install Type</td>
        <td class="labor-panel-description-width left-padding">Description (optional)</td>
        <td class="labor-panel-installer-payable-width left-padding">Installer Payable</td>
        <td class="labor-panel-installation-date-width left-padding">Install Date</td>
        <td class="labor-panel-etc-date-width left-padding">ETC date</td>
        <td class="labor-panel-installer-name-width left-padding">Installer</td>
        <td class="labor-panel-installer-crew-width left-padding">Crew</td>
        <td class="labor-panel-stop-width left-padding">Stop</td>
        <td class="labor-panel-early-payment-width">Payment</td>
        <td class="labor-panel-work-order-width">WO</td>
        <td class="labor-panel-installation-status-width">Status</td>
        <td class="labor-panel-delete-icon-width"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
td {
  margin-right: 10px;
}
.separator {
  width: 100%;
  margin-bottom: 10px;
}
.line-item-headers {
  width: 100%;
  table-layout: fixed;
  font-weight: bold;
  text-align: left;
  color: #666;
}
.left-padding {
  padding-left: 15px;
}
</style>
