<template>
  <navigation-view
      ref="nav"
      page-title="Shipment Details"
      nav-section="warehouse"
  >
    <template v-slot:content>
      <v-skeleton-loader
          :loading="isLoading"
          type="subtitle, article, list-item-two-line, subtitle, list-item-three-line, divider,
          table-heading, table-thead, table-tbody, table-row-divider, table-row, table-tfoot"
      >
        <v-container fluid id="shipment-details">
          <v-row class="mt-1" no-gutters>
            <shipment-details-read-only-form
                ref="shipmentDetails"
                :shipment="shipment"
            />
          </v-row>

          <shipment-inline-grids
              v-if="shipment"
              ref="shipmentInlineGrids"
              :shipment="shipment"
              @transferRequestAdded="onTransferRequestAdded"
              @addTransfersError="showError"
              @removeTransferRequestSuccess="onRemoveTransferRequestSuccess"
              @removeTransferRequestFromShipmentError="showError"
          />
        </v-container>
      </v-skeleton-loader>
    </template>

    <template v-slot:nav-action>
      <v-skeleton-loader
          :loading="isLoading"
      >
        <v-row id="buttons-row" class="d-flex justify-end ma-0 pb-0 pt-0">
          <v-col class="pb-0 bt-0 pr-1 pl-0 pt-0" v-if="shipment.status === 'Scheduled'">
            <navigation-action-button
                :showActionButtons="canEditShipment"
                label="Edit Dates"
                @click="showDrawer"
            />
          </v-col>

          <v-col class="pb-0 bt-0 pr-1 pl-0 pt-0">
            <navigation-action-button
                :showActionButtons="canEditShipment"
                label="Cancel Shipment"
                @click="showCancelShipmentModal"
            />
          </v-col>
        </v-row>

        <cancel-shipment-wizard
            ref="cancelShipmentWizard"
            :shipment="shipment"
            @submitCancelShipmentSuccess="onCancelShipmentSuccess"
            @submitCancelShipmentFailed="onSubmitFailed"
        />
      </v-skeleton-loader>
    </template>

    <template v-slot:drawer>
      <shipment-edit-dates-drawer
          v-if="shipment"
          ref="editDatesDrawer"
          :shipment="shipment"
          submit-label="Save Shipment"
          title="Update Shipment"
          @cancel="onCancelEditDatesClicked"
          @submit="onSubmitEditDatesClicked"
      />
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "../../views/navigation/NavigationView.vue";
import ShipmentDetailsReadOnlyForm from "./ShipmentDetailsReadOnlyForm.vue";
import NavigationActionButton from "../navigation/NavigationActionButton.vue";
import ShipmentInlineGrids from "./ShipmentInlineGrids.vue";
import Roles from "../../assets/roles";
import Shipments from "../../rest/shipments";
import CancelShipmentWizard from "../../components/shipment/CancelShipmentWizard.vue";
import {ShipmentStatuses} from "@/assets/statusIcons/statusIcons";
import ShipmentEditDatesDrawer from "../../components/shipment/ShipmentEditDatesDrawer.vue";

export default {
  components: {
    ShipmentEditDatesDrawer,
    CancelShipmentWizard,
    ShipmentInlineGrids,
    ShipmentDetailsReadOnlyForm,
    NavigationActionButton,
    NavigationView
  },

  data() {
    return {
      shipment: null,
      shipmentNumber: null,
      isLoading: true,
    };
  },
  created() {
    this.display(this.$route.params.shipmentNumber);
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },

  computed: {
    canEditShipment() {
      return Roles.isSuperAdminOrAdminUser() && this.shipment.status === ShipmentStatuses.SCHEDULED.fullName;
    },
  },

  methods: {
    async display(shipmentNumber) {
      this.shipmentNumber = shipmentNumber;
      await this.refresh();
    },
    async refresh() {
      try {
        this.shipment = await this.getShipmentDetails();
      } catch (error) {
        this.showError(error);
      }
    },
    showError(error) {
      this.$refs.nav.showError(error);
    },
    async getShipmentDetails() {
      try {
        const response = await Shipments.getRestApi().getShipmentByShipmentNumber(this.shipmentNumber);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async updateShipmentDates(payload) {
      try {
        const response = await Shipments.getRestApi().updateShipmentDates(this.shipmentNumber, payload);
        return response.data;
      } catch (error) {
        this.onSubmitFailed(error);
        throw error;
      }
    },
    showCancelShipmentModal() {
      this.$refs.cancelShipmentWizard.openCancelShipmentDialog();
    },
    onSubmitFailed(error) {
      this.showError(error);
    },
    onCancelShipmentSuccess() {
      this.$refs.cancelShipmentWizard.resetWizard();
      this.$refs.nav.showSuccess("Shipment has been closed successfully!");
      this.refresh();
      this.$refs.shipmentInlineGrids.refreshActivityLog();
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
      this.$refs.editDatesDrawer.loadDrawerForm(this.shipment);
    },
    async onSubmitEditDatesClicked(payload) {
      const updated = await this.updateShipmentDates(payload);
      this.$refs.nav.showSuccess("Shipment Dates have been updated successfully!");
      this.shipment = updated;
      this.$refs.nav.hideDrawer();
      this.$refs.editDatesDrawer.loadDrawerForm(updated);
      this.$refs.shipmentInlineGrids.refreshActivityLog();
    },
    onCancelEditDatesClicked() {
      this.$refs.nav.hideDrawer();
      this.$refs.editDatesDrawer.$refs.datesChooser.resetForm(this.shipment);
    },
    onTransferRequestAdded() {
      this.$refs.nav.showSuccess("Transfer Request(s) have been added successfully!");
      this.refresh();
    },
    onRemoveTransferRequestSuccess(transferNumber) {
      this.$refs.nav.showSuccess(`Transfer Request ${transferNumber} has been removed from Shipment successfully!`);
      this.shipment = null;
      this.refresh();
      this.$refs.shipmentInlineGrids.refreshActivityLog();
    }
  }
};
</script>
