<template>
  <div>
    <inline-grid
        ref="distributionVendors"
        :data-source="distributionVendors"
        :show-borders="false"
        :hoverStateEnabled="true"
        :showPager="true"
        :pageSize="defaultInitialPageSize"
        key-expr="id"
        @rowSelected="onRowSelected"
        @editingStarted="editRowClicked"
        @deleteClicked="deleteRowClicked"
        no-data-text="No Distribution Vendors added yet"
    >
      <dx-column
          :width="'25%'"
          data-field="vendor.name"
          data-type="string"
          caption="Vendor"
          :allow-editing="false"
          sort-order="asc"
      />
      <dx-column
          data-field="branches"
          caption="Branches"
          data-type="string"
          :allow-editing="false"
          :customize-text="branchNamesAsString"
      />
      <dx-column
          data-field="id"
          width="5%"
          caption
          cellTemplate="delete"
          :allowSorting="false"
      />
    </inline-grid>

    <inline-grid-footer slot="footer">
      <inline-grid-button
          v-if="isAddDistributionVendorButtonVisible"
          label="Add Distribution Vendor"
          @click="onAddDistributionVendorButtonClick"
      />
      <inline-grid-button
          :loading="!defaultBranches && !defaultActiveVendors"
          v-if="selectedVendor"
          :label="'Edit Vendor: ' +  selectedVendor.name"
          :width="'auto'"
          @click="editRowClicked"
      />
    </inline-grid-footer>

    <distribution-vendor-dialog
        ref="distributionVendorDialog"
        :isAddForm="isAddForm"
        :vendorSelected="selectedVendor"
        :selectedBranchesFromGrid="selectedBranchesFromGrid"
        :selectedVendorsFromGrid="selectedVendorsFromGrid"
        :defaultActiveVendors="defaultActiveVendors"
        :defaultBranches="defaultBranches"
        :selectedBranches="selectedBranches"
        :vendorManagementVendors="vendorManagementVendors"
        @onSaveDistributionVendor="saveDistributionVendor"
        @onUpdateDistributionVendor="performDistributionVendorUpdate"
        @onDialogClosed="clearRowSelection"
        @onEditFormClosed="hideSelectedVendorButton"
    />
    <delete-item-default-dialog
        ref="removeDistributionVendorDialog"
        title="Distribution Vendor Deletion"
        itemName="Test ADasdsad sasd adas dsa"
        @onDeleteItemConfirmed="deleteDistributionVendor"
    />
    <snackbar ref="snackbar" error-color="white" default-timeout="4000"/>
  </div>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGridFooter from "../../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../../components/common/grid/InlineGridButton.vue";
import Snackbar from "../../../components/common/Snackbar.vue";
import DistributionVendorDialog from "../dialogContainers/catalogItemManagement/DistributionVendorDialog.vue";
import DeleteRowCellTemplate from "../grid/cellTemplates/DeleteRowCellTemplate.vue";
import DistributionVendors from "../../../rest/distributionVendors";
import Branches from "../../../rest/branches";
import formattingHelper from "../../../assets/formattingHelper";
import InlineGrid from "../grid/InlineGrid.vue";
import DeleteItemDefaultDialog from "../../catalogItems/DeleteItemDefaultDialog.vue";

export default {
  components: {
    DeleteRowCellTemplate,
    DistributionVendorDialog,
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    InlineGrid,
    Snackbar,
    DeleteItemDefaultDialog
  },

  props: {
    defaultActiveVendors: {
      type: Array,
      required: true
    },
    distributionVendors: {
      type: Array,
      required: false,
      default: () => []
    },
    catalogItemId: {
      type: Number,
      required: true
    },
    internalSku: {
      type: String,
      required: true
    },
    vendorManagementVendors: {
      type: Array,
      required: true
    },
    isAddDistributionVendorButtonVisible: {
      type: Boolean,
      required: true
    }
  },

  mounted() {
    this.getBranches();
  },

  watch: {
    distributionVendors(value) {
      this.selectedVendorsFromGrid = value;
      this.selectedBranchesFromGrid = value.map(i => i.branches.map(b => b.referenceId)).flat();
    }
  },

  data() {
    return {
      allowedPageSizes: [10, 25, 50],
      defaultInitialPageSize: 10,
      snackbar: false,
      selectedVendor: null,
      selectedBranches: [],
      defaultBranches: [],
      isAddForm: true,
      selectedDistributionVendor: null,
      selectedBranchesFromGrid: [],
      selectedVendorsFromGrid: [],
    }
  },

  methods: {
    onRowSelected(data) {
      this.selectedBranches = [];
      this.selectedDistributionVendor = data;
      this.selectedVendor = data.vendor;
      data.branches.forEach(branch => {
        this.selectedBranches.push(branch.referenceId);
      });
    },
    onAddDistributionVendorButtonClick() {
      this.selectedVendor = null;
      this.selectedBranches = []
      this.$refs.distributionVendorDialog.openDialog( this.selectedVendor, this.selectedBranches, true);
    },
    setDialogLoadingFalse() {
      this.$refs.distributionVendorDialog.setLoading(false);
    },
    showSnackbarSuccess(message) {
      this.$refs.snackbar.addMessage(message);
    },
    editRowClicked() {
      this.isAddForm = false;
      this.$refs.distributionVendorDialog.openDialog(this.selectedVendor, this.selectedBranches, this.isAddForm);
    },
    async saveDistributionVendor(data) {
      await this.performDistributionVendorSave(data);
    },
    async performDistributionVendorSave(data) {
      DistributionVendors.getRestApi().createDistributionVendor(this.internalSku, data)
          .then(() => {
            this.$emit('onDistributionVendorAdded');
            this.closeModal();
          })
          .catch((error) => {
            this.$emit('vendorError', error);
          }).finally(() => {
        this.$refs.distributionVendorDialog.setLoading(false);
        this.$refs.distributionVendorDialog.resetDistributionVendorForm();
      });
    },
    async performDistributionVendorUpdate(data) {
      DistributionVendors.getRestApi().updateDistributionVendor(this.internalSku, this.selectedDistributionVendor.id, data)
          .then((response) => {
            this.setPreviouslySelectedData(data.vendor, data.branches);
            this.closeModal();
            this.$emit('onDistributionVendorUpdated');
          })
          .catch((error) => {
            this.$emit('vendorError', error);
          }).finally(() => {
        this.$refs.distributionVendorDialog.setLoading(false);
      });
    },
    deleteRowClicked(data) {
      this.$refs.removeDistributionVendorDialog.openDialog(data, 'distributionVendor');
    },
    async performDistributionVendorDeleteRequest(data) {
      let removed = data.vendor;
      DistributionVendors.getRestApi().deleteDistributionVendor(this.internalSku, data.vendor.id)
          .then((response) => {
            this.$emit('onDistributionVendorDeleted');
            this.$refs.removeDistributionVendorDialog.closeDialog();
            this.$refs.distributionVendorDialog.$refs.distributionVendorForm.filterOutAvailableVendors(removed, false);
          })
          .catch((error) => {
            this.$emit('vendorError', error);
          }).finally(() => {
        this.$refs.removeDistributionVendorDialog.setLoading(false);
        this.clearRowSelection();
      });
    },
    async getBranches() {
      try {
        const response = await Branches.getRestApi().getAllUserBranches();
        this.defaultBranches = response.data;
      } catch (error) {
        this.emitError(error);
      }
    },
    closeModal() {
      this.$refs.distributionVendorDialog.closeDialog();
      this.$refs.distributionVendorDialog.resetForm();
    },
    setPreviouslySelectedData(vendor, branches) {
      this.selectedVendor = vendor;
      this.selectedBranches = branches;
    },
    branchNamesAsString(element) {
      return formattingHelper.asCommaDelimitedString(element.value);
    },
    clearRowSelection() {
      this.$refs.distributionVendors.$refs.grid.instance.clearSelection();
    },
    deleteDistributionVendor(cellData) {
      this.performDistributionVendorDeleteRequest(cellData.data);
    },
    hideSelectedVendorButton() {
      this.selectedVendor = null;
      this.clearRowSelection();
    }
  },

};
</script>

<style scoped>
::v-deep .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed) > td {
  background-color: #e5eef5;
  color: unset;
}
</style>
