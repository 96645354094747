<template>
  <v-btn
      class="close-po-btn"
      v-if="editDisabled"
      text
      @click="clickClosePoItem"
      color="primary"
      :ripple="false"
  >
    Close
  </v-btn>
</template>

<script>
import Constants from "../../../../assets/constants";

export default {
  props: {
    cellData: Object
  },

  data() {
    return {};
  },

  computed: {
    editDisabled() {
      return this.hideClosePOButton(this.cellData);
    }
  },

  methods: {
    clickClosePoItem() {
      this.$emit('onClickClosePoItem', this.cellData);
    },
    hideClosePOButton(element) {
      return (!element.data || element.data.status !== Constants.closedStatus);
    },
  }
};
</script>

<style scoped>
.close-po-btn {
  border: 1px rgb(102, 153, 187) solid;
  border-radius: 3px;
  font-size: 14px;
}
</style>
