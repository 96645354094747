<template>
  <v-radio-group
      :row="inline"
      v-model="selectedOption"
      :label="label"
      :disabled="disabled"
      :rules="[rules.requiredBasedOnPropsValue]"
      @change=onRadioChanged($event)
  >
    <v-radio
        v-for="option in selectOptions"
        :key="option.key"
        :label="option.label"
        :value="option.value"
        color="primary"
    />
  </v-radio-group>
</template>

<script>

export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    selectOptions: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  data() {
    return {
      selectedOption: false,
      rules: {
        requiredBasedOnPropsValue: () => {
          return this.required ? this.selectedOption != null : true;
        }
      },
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.selectedOption = val;
      }
    }
  },

  methods: {
    onRadioChanged(event) {
      this.$emit('onValueChanged', event);
    }
  }

};
</script>

<style scoped>
</style>
