import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";
import {ActivityLog} from "./interfaces/activityLogs";

/**
 * InventoryItems.
 */
export class InventoryItem {
    id: number;
    agingStartDateTime: Date;

    constructor(id: number, agingStartDateTime: Date) {
        this.id = id;
        this.agingStartDateTime = agingStartDateTime;
    }
}

/**
 * Inventory Item Generic Check-In
 */
export class InventoryGenericCheckIn {
    /** Bin id for inventory */
    binId: number;
    /** id for product */
    productCatalogItemId: number;
    /** Quantity added to bin */
    quantity: number;
    /** Dye lot */
    dyeLot: string;
    /** Roll number */
    rollNumber: string;

    constructor(
        binId: number,
        productCatalogItemId: number,
        quantity: number,
        dyeLot: string,
        rollNumber: string
    ) {
        this.binId = binId;
        this.productCatalogItemId = productCatalogItemId;
        this.quantity = quantity;
        this.dyeLot = dyeLot;
        this.rollNumber = rollNumber;
    }
}

/**
 * Inventory Item Generic Check-out
 */
export class InventoryGenericCheckOut {
    /** Id of item to check out */
    inventoryItemId: number;
    /** Quantity checked out */
    quantity: number;

    constructor(inventoryItemId: number, quantity: number) {
        this.inventoryItemId = inventoryItemId;
        this.quantity = quantity;
    }
}

/**
 * Request for Generic Inventory Check-In
 */
export class CheckInGenericInventoryRequest {
    reasonCodeId: number;
    branchReferenceId: string;
    checkIns: InventoryGenericCheckIn[];

    constructor(
        reasonCodeId: number,
        branchReferenceId: string,
        checkIns: InventoryGenericCheckIn[]
    ) {
        this.reasonCodeId = reasonCodeId;
        this.branchReferenceId = branchReferenceId;
        this.checkIns = checkIns;
    }
}

export class SplitInventoryItemRequest {
    binId: number;
    reservationId: number;
    inventoryItemId: number;
    quantity: number;

    constructor(
        binId: number,
        reservationId: number,
        inventoryItemId: number,
        quantity: number,
    ) {
        this.binId = binId;
        this.reservationId = reservationId;
        this.inventoryItemId = inventoryItemId;
        this.quantity = quantity;
    }
}

/**
 * REST interface.
 */
export interface InventoryItemRestApi {
    getInventoryItemById(id: number): AxiosPromise<InventoryItem>;
    updateInventoryItem(id: number, updated: InventoryItem): AxiosPromise<InventoryItem>;
    getInventoryItemAuditLogs(id: number): AxiosPromise<InventoryItem[]>;
    getInventoryItemActivityLogs(id: number): AxiosPromise<ActivityLog[]>;
    inventoryItemsCheckIn(items: InventoryItem): AxiosPromise<InventoryItem>;
    inventoryItemsCheckInGeneric(request: CheckInGenericInventoryRequest): AxiosPromise<InventoryItem>;
    inventoryItemsCheckOut(items: InventoryItem): AxiosPromise<InventoryItem>;
    inventoryItemsCheckOutGeneric(request: InventoryGenericCheckOut): AxiosPromise<InventoryItem>;
    inventoryItemsCheckOutGeneric(request: InventoryGenericCheckOut): AxiosPromise<InventoryItem>;
    moveInventoryItems(request: SplitInventoryItemRequest): AxiosPromise<SplitInventoryItemRequest>;
    updateBin(itemId: number, binId: number): AxiosPromise<VoidFunction>;

    /** @deprecated*/
    getInventoryJobItems(jobRefId: number): AxiosPromise<InventoryItem[]>;
}

/**
 * REST implementation.
 */
class Implementation implements InventoryItemRestApi {
    getInventoryItemById(id: number): AxiosPromise<InventoryItem> {
        return Axios.axiosInstance().get<InventoryItem>(`/inventory/items/${id}`);
    }
    updateInventoryItem(id: number, updated: InventoryItem): AxiosPromise<InventoryItem> {
        return Axios.axiosInstance().patch<InventoryItem>(`/inventory/items/${id}`, updated);
    }
    getInventoryItemAuditLogs(id: number): AxiosPromise<InventoryItem[]> {
        return Axios.axiosInstance().get<InventoryItem[]>(`/inventory/items/${id}/logs`);
    }
    getInventoryItemActivityLogs(id: number): AxiosPromise<ActivityLog[]> {
        return Axios.axiosInstance().get<ActivityLog[]>(`/inventory/items/${id}/activitylogs`);
    }
    inventoryItemsCheckIn(items: InventoryItem): AxiosPromise<InventoryItem> {
        return Axios.axiosInstance().post<InventoryItem>("/inventory/items/checkin", items);
    }
    inventoryItemsCheckInGeneric(request: CheckInGenericInventoryRequest): AxiosPromise<InventoryItem> {
        return Axios.axiosInstance().post<InventoryItem>("/inventory/items/checkin/generic", request);
    }
    inventoryItemsCheckOut(items: InventoryItem): AxiosPromise<InventoryItem> {
        return Axios.axiosInstance().post<InventoryItem>("/inventory/items/checkout", items);
    }
    inventoryItemsCheckOutGeneric(request: InventoryGenericCheckOut): AxiosPromise<InventoryItem> {
        return Axios.axiosInstance().post<InventoryItem>("/inventory/items/checkout/generic", request);
    }
    moveInventoryItems(request: SplitInventoryItemRequest): AxiosPromise<SplitInventoryItemRequest> {
        return Axios.axiosInstance().post<SplitInventoryItemRequest>("/inventory/items/movement", request);
    }
    updateBin(itemId: number, binId: number): AxiosPromise<VoidFunction> {
        return Axios.axiosInstance().patch<VoidFunction>(`/inventory/items/${itemId}/${binId}`);
    }

    /** @deprecated*/
    getInventoryJobItems(jobRefId: number): AxiosPromise<InventoryItem[]> {
        return Axios.axiosInstance().get<InventoryItem[]>(`/inventory/items/job/${jobRefId}`);
    }
}

const InventoryItems = {
    getRestApi(): InventoryItemRestApi {
        return new Implementation();
    }
};

export default InventoryItems;
