<template>
  <drawer-form
      ref="form"
      title="Update Product Catalog Item"
      submit-label="Save Item"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <catalog-item-form-body
        ref="formBody"
        type="update"
        :subtype="subtype"
        :productClassOptions="productClassOptions"
        @emitWarning="emitWarning"
        @emitFail="emitFail"
    />
    <duplicate-vendor-and-sku-dialog
        ref="duplicate"
        @confirmed="completeUpdate"
    />
  </drawer-form>
</template>

<script>
import CatalogItemFormBody from "../CatalogItemFormBody.vue";
import DuplicateVendorAndSkuDialog from "../DuplicateVendorAndSkuDialog.vue";
import CatalogItems from "../../../rest/catalogItems";
import DrawerForm from "../../common/form/DrawerForm.vue";
import BoxQuantity from "@/assets/boxQuantity";

export default {
  components: {
    CatalogItemFormBody,
    DuplicateVendorAndSkuDialog,
    DrawerForm,
  },

  props: {
    catalogItemId: {
      type: Number,
      required: true
    },
    productSubtype: {
      type: Object | null,
      required: true
    },
    productClassOptions: Array
  },

  data() {
    return {
      originalSku: null,
      inProgress: false,
      subtype: null
    };
  },

  watch: {
    productSubtype(value) {
      this.subtype = value;
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.inProgress = false;
      this.$refs.form.resetValidation();
    },
    onCancel() {
      this.inProgress = false;
      this.$emit('cancelClicked');
    },
    async getMatchesForSku(vendorSku) {
      if (vendorSku) {
        try {
          let matches = await CatalogItems.getRestApi().getCatalogItemsByVendorSku(vendorSku);
          return matches.data;
        } catch (error) {
          this.emitFail(error);
          return [];
        }
      }
    },
    async onSubmit() {
      this.completeUpdate();
    },
    async completeUpdate() {
        let valid = this.validate();
        if (valid) {
          this.inProgress = true;
          let params = this.buildParams();
          const validOnUniqueness = await this.$refs.formBody.validateUniqueness(params);
          if (validOnUniqueness) {
            try {
              await this.performUpdate();
            } catch (error) {
              this.emitFail(error);
            }
          }
          this.inProgress = false;
        }
    },
    async performUpdate() {
      const payload = this.$refs.formBody.buildPayload();
      const data = await this.updateCatalogItem(payload);
      this.$emit('submitClicked', data);
    },
    async updateCatalogItem(payload) {
      try {
        this.inProgress = true;
        const response = await CatalogItems.getRestApi().updateCatalogItem(this.$props.catalogItemId, payload);
        return response.data;
      } catch (error) {
        this.emitFail(error);
      } finally {
        this.inProgress = false;
      }
    },
    emitFail(error) {
      this.$emit('submitFailed', error);
    },
    emitWarning(warning) {
      this.inProgress = false;
      this.$emit('showWarning', warning);
    },
    resetForm(payload) {
      this.inProgress = false;
      this.originalSku = payload.vendorSku;
      this.$refs.formBody.loadForm(payload);
      this.resetValidation();
    },
    loadDrawerForm(payload) {
      this.inProgress = false;
      this.originalSku = payload.vendorSku;
      this.$refs.formBody.loadForm(payload);
    },
    buildParams() {
      return {
        productTypeId: this.$refs.formBody.productTypeId,
        styleName: this.$refs.formBody.styleName,
        color: this.$refs.formBody.color,
        boxQuantity: BoxQuantity.formatBoxQuantity(this.$refs.formBody.boxQuantity),
        productClassId: this.$refs.formBody.productClass ? this.$refs.formBody.productClass.id : null,
        productCatalogItemId: this.catalogItemId,
        privateLabelStyleName: this.$refs.formBody.privateLabelStyleName,
        isUpdateRequest: true
      }
    }
  },
};
</script>
