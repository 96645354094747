import Axios from ".././axiosInstance";
import {AxiosPromise} from "axios";

export interface CreateInstallerCrewDayOff {
    installerCrewId: number;
    startDate: Date;
    endDate: Date;
    comment: String;
}

export interface UpdateInstallerCrewDayOff extends CreateInstallerCrewDayOff {
    id: number;
}

export interface InstallerCrewDayOffDTO {
    id: number;
    installerCrewId: number;
    installerCrewName: String;
    startDate: Date;
    endDate: Date;
    comment: String;
}

export interface InstallerCrewDayOffApi {
    createDayOff(payload: CreateInstallerCrewDayOff): AxiosPromise<InstallerCrewDayOffDTO>;

    updateDayOff(payload: UpdateInstallerCrewDayOff): AxiosPromise<InstallerCrewDayOffDTO>;

    deleteDayOff(id: number): AxiosPromise<void>;

    getCrewsDaysOff(installerRefId: String): AxiosPromise<InstallerCrewDayOffDTO[]>;
}

class Implementation implements InstallerCrewDayOffApi {

    createDayOff(payload: CreateInstallerCrewDayOff): AxiosPromise<InstallerCrewDayOffDTO> {
        return Axios.axiosInstance().post<InstallerCrewDayOffDTO>(`/installer-crew/day-off`, payload);
    }

    updateDayOff(payload: UpdateInstallerCrewDayOff): AxiosPromise<InstallerCrewDayOffDTO> {
        return Axios.axiosInstance().put<InstallerCrewDayOffDTO>(`/installer-crew/day-off/${payload.id}`, payload);
    }

    deleteDayOff(id: number): AxiosPromise<void> {
        return Axios.axiosInstance().delete<void>(`/installer-crew/day-off/${id}`);
    }

    getCrewsDaysOff(installerRefId: String): AxiosPromise<InstallerCrewDayOffDTO[]> {
        return Axios.axiosInstance().get<InstallerCrewDayOffDTO[]>(`/installer-crew/day-off/installer/${installerRefId}/all`);
    }
}

const InstallerCrewDayOff = {
    getRestApi(): InstallerCrewDayOffApi {
        return new Implementation();
    }
};

export default InstallerCrewDayOff;
