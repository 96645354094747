<template>
  <modal-dialog
    ref="dialog"
    title="Job Update Conflict"
    width="480"
    :visible="dialogVisible"
    :hideCancel="true"
    :submitEnabled="true"
    submitLabel="Refresh"
    cancelLabel="Cancel"
    @submitClicked="onRefreshClicked"
    @cancelClicked="onCancelClicked"
  >
    <v-card flat>
      <v-card-text>
        <slot>
          <div>
            This job has been updated by another user. Job
            information will be refreshed to show the updates.
          </div>
        </slot>
      </v-card-text>
    </v-card>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../common/ModalDialog.vue";

export default {
  data: () => ({
    dialogVisible: false,
    error: null
  }),

  components: {
    ModalDialog
  },

  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    showError(error) {
      this.error = error;
    },
    onRefreshClicked() {
      this.dialogVisible = false;
      this.$emit('refresh');
    },
    onCancelClicked() {
      this.dialogVisible = false;
    }
  }
};
</script>
