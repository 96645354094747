<template>
  <navigation-view ref="nav" page-title="Vendor Details" nav-section="admin">
    <template v-slot:content>
      <v-container grid-list-lg fluid id="vendor-details">
        <vendor-details-form ref="vendorDetailsForm"/>
        <inline-grids
            ref="inlineGrids"
            :vendorReferenceId="vendorReferenceId"
            @showError="showError"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <vendor-drawer-form
          :create="false"
          ref="vendorDrawerForm"
          title="Edit Vendor"
          submitLabel="Save Vendor"
          @refreshVendorDrawerForm="refresh"
          @cancelClicked="onCancel"
          @submitClicked="submitUpdateVendor"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="isEditButtonDisplayed"
          label="Edit Vendor"
          @click="showDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import Vendors from "@/rest/vendors.ts";
import VendorDetailsForm from "@/components/vendors/VendorDetailsForm.vue";
import VendorDrawerForm from "@/components/vendors/VendorDrawerForm.vue";
import InlineGrids from "@/views/vendors/VendorsInlineGrids.vue";
import Roles from "@/assets/roles";
import PhoneMask from "../../assets/phoneMask";

export default {
  components: {
    NavigationView,
    NavigationActionButton,
    VendorDetailsForm,
    VendorDrawerForm,
    InlineGrids
  },

  data() {
    return {
      vendorReferenceId: null,
      vendor: null,
      snackbar: false,
      errorMessage: null,
      successMessage: null,
      activityLogs: []
    };
  },

  computed: {
    isEditButtonDisplayed() {
      return !Roles.isBranchAdmin();
    }
  },

  created() {
    this.display(this.$route.params.vendorReferenceId);
  },

  beforeRouteUpdate(to, from, next) {
    this.display(to.params.vendorReferenceId);
    next();
  },

  methods: {
    display(vendorReferenceId) {
      this.vendorReferenceId = vendorReferenceId;
      this.refresh();
    },
    async refresh() {
      try {
        const response = await Vendors.getRestApi().getVendor(this.vendorReferenceId);
        this.vendor = response.data;
        this.$refs.vendorDetailsForm.loadForm(response.data);
        this.$refs.vendorDrawerForm.loadDrawerForm(response.data);
      } catch (error) {
        this.showError(error);
      }
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    async submitUpdateVendor(payload) {
      payload.phoneNumber = PhoneMask.unMaskPhone(payload.phoneNumber);
      try {
        payload.status = this.getStatusNumericValue(payload.statusValue);
        await Vendors.getRestApi().updateVendor(this.vendorReferenceId, payload);
        this.submitUpdateSuccess();
        await this.refresh();
      } catch (error) {
        this.showError(error);
      }
    },
    refreshActivityLog() {
      this.$refs.inlineGrids.refreshActivityLog();
    },
    submitUpdateSuccess() {
      this.$refs.vendorDrawerForm.reset();
      const message = "Updated vendor details successfully!";
      this.$refs.nav.showSuccess(message);
      this.$refs.nav.hideDrawer();
      this.refreshActivityLog();
    },
    getStatusNumericValue(value) {
      return value === "active" ? 1 : 0;
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
    },
    showError(error) {
      this.$refs.nav.showError(error);
    }
  }
};
</script>
