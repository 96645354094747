<template>
  <v-container grid-list-lg fluid class="ma-0 pa-0 mb-2" v-if="branch">
    <v-row>
      <v-col md="2">
        <v-text-field
            :value="branch.referenceId"
            label="Reference Id"
            placeholder=" "
            persistent-placeholder
            readonly
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="2">
        <status-icon-cell-template
            :statusName="automaticLeadAssignmentMode"
            template-name="branchDetails"
        />
      </v-col>
      <v-col md="2">
        <status-dropdown
            :value="branch.active"
            :readonly="true"
        />
      </v-col>
    </v-row>

    <v-divider class="detailsDivider"/>

    <v-row>
      <v-col md="5">
        <v-row>
          <v-col>
            <v-text-field
                :value="branch.name"
                label="Name"
                placeholder=" "
                persistent-placeholder
                readonly
                id="branch-details-name"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0">
            <v-text-field
                :value="branch.marketNames"
                placeholder=" "
                persistent-placeholder
                label="Associated Markets"
                readonly
                id="branch-details-markets"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col md="6">
            <v-text-field
                :value="branch.phone"
                placeholder=" "
                persistent-placeholder
                label="Phone Number"
                v-facade="phoneNumberMask"
                readonly
                id="branch-details-phone"
            />
          </v-col>
          <v-col md="6">
            <v-text-field
                :value="branch.fax"
                placeholder=" "
                persistent-placeholder
                label="Fax Number"
                v-facade="phoneNumberMask"
                readonly
                id="branch-details-fax"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0">
            <v-text-field
                :value="branch.email"
                placeholder=" "
                persistent-placeholder
                label="Email"
                readonly
                id="branch-details-email"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-spacer></v-spacer>

      <v-col md="5" class="mt-6">
        <v-row>
          <address-form-with-autocomplete
              :addressLabel="branchOfficeAddress"
              ref="addressForm"
              :readonly="true"
          />
          <address-form-with-autocomplete
              :addressLabel="shippingAddress"
              ref="shipmentAddressForm"
              :readonly="true"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddressFormWithAutocomplete from "../common/form/AddressFormWithAutocomplete.vue";
import StatusDropdown from "../common/form/StatusDropdown.vue";
import Constants from "../../assets/constants";
import StatusIconCellTemplate from "@/components/common/grid/cellTemplates/StatusIconCellTemplate.vue";

export default {
  components: {
    StatusIconCellTemplate,
    StatusDropdown,
    AddressFormWithAutocomplete,
  },

  computed: {
    automaticLeadAssignmentMode() {
      return this.branch.isAutomaticLeadAssignmentEnabled ? 'Automatic' : 'Manual';
    }
  },

  data() {
    return {
      branchOfficeAddress: "Branch Office Address",
      shippingAddress: "Shipping Address",
      phoneNumberMask: Constants.phoneNumberMask
    };
  },

  props: {
    branch: {
      type: Object,
      required: true
    }
  },

  watch: {
    branch: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.address) {
          this.$refs.addressForm.setAddressData(newVal);
          this.$refs.shipmentAddressForm.setShipmentAddressData(newVal);
        }
      }
    }
  }
};
</script>

<style scoped>
.divider {
  padding-bottom: 20px;
}
</style>
