<template>
  <v-simple-table class="full-width">
    <thead class="mb-1 mt-5" v-show="showHeaders">
    <tr>
      <th class="text-center">
        State
      </th>
      <th class="text-center">
        Event
      </th>
      <th class="text-center">
        Start
      </th>
      <th class="text-center">
        End
      </th>
      <th class="text-center" width="50%">
        Message
      </th>
      <th class="text-center">
        Sender
      </th>
      <th class="text-center">
        Mark As Read
      </th>
    </tr>
    </thead>

    <tbody class="table-body">
    <tr
        v-for="(message, index) in ongoingNotifications"
        :key="index"
    >
      <td>
        <div class="mt-2">
          <v-progress-circular
              :width="3"
              size="20"
              color="#6699bb"
              indeterminate
          >
            <v-icon color="#6699bb">
              mdi-progress-wrench
            </v-icon>
          </v-progress-circular>
        </div>

        <span class="text-center" style="font-size: 9px"> {{ message.eventState.toLowerCase() }} </span>
      </td>
      <td>
        {{ message.eventName }}
      </td>
      <td>
        {{ message.startTime }}
      </td>
      <td>
        {{ message.endTime }}
      </td>
      <td class="td-message-text-field mt-2">
        {{ message.message }}
      </td>
      <td>
        {{ message.sender }}
      </td>
    </tr>
    </tbody>
  </v-simple-table>
</template>
<script>

export default {
  props: {
    showHeaders: {
      type: Boolean,
      default: true,
    },
    ongoingNotifications: {
      type: Array | null
    },
  },

}
</script>

<style scoped>
.full-width {
  width: 100%;
  background-color: rgba(149, 223, 227, 0.61);
}

.table-body {
  pointer-events: none;
}

.td-message-text-field{
  width: 50%;
}

</style>
