<template>
  <v-autocomplete
      item-text="description"
      item-value="id"
      :items="availableRoles"
      v-model="role"
      label="* Role"
      :readonly="editing"
      @change="onRoleSelect"
      persistent-placeholder
      :rules="[value => !!value || 'Required.']"
      attach
  />
</template>

<script>

export default {
  props: {
    selected: Number,
    editing: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    selected: {
      immediate: true,
      handler(value) {
        this.setRole(value);
      }
    }
  },

  data() {
    return {
      role: null
    };
  },

  computed: {
    availableRoles() {
      return this.items.filter((role) => !role.isInternal);
    }
  },

  methods: {
    onRoleSelect(value) {
      this.$emit("onRoleSelect", value);
    },
    setRole(value) {
      this.role = value ? value : {id: 4, description: "Branch User"};
    }
  }
};
</script>
