<template>
  <navigation-view
      ref="nav"
      page-title="Inventory Bin Details"
      nav-section="admin"
  >
    <template v-slot:content>
      <v-container grid-list-lg fluid>
        <bin-read-only :bin="bin"/>
        <bins-inline-grids
            ref="inlineGrids"
            :binId="binId"
            :showError="onSubmitFailed"
        />
      </v-container>
    </template>

    <template v-slot:drawer>
      <bin-update-drawer
          ref="updateDrawer"
          :binId="binId"
          @cancelClicked="onCancel"
          @submitSuccess="onSubmitSuccess"
          @submitFailed="onSubmitFailed"
      />
    </template>

    <template v-slot:nav-action>
      <navigation-action-button
          :showActionButtons="!bin.isSystem"
          label="Edit Bin"
          @click="showDrawer"
      />
    </template>
  </navigation-view>
</template>

<script>
import NavigationView from "@/views/navigation/NavigationView.vue";
import NavigationActionButton from "@/views/navigation/NavigationActionButton.vue";
import Bins from "@/rest/bins";
import BinReadOnly from "@/components/bins/BinReadOnly.vue";
import BinUpdateDrawer from "@/components/bins/BinUpdateDrawer.vue";
import InlineGrids from "@/views/bins/BinsInlineGrids.vue";
import BinsInlineGrids from "./BinsInlineGrids";

export default {
  components: {
    BinsInlineGrids,
    NavigationView,
    NavigationActionButton,
    BinReadOnly,
    BinUpdateDrawer,
    InlineGrids
  },
  data() {
    return {
      binId: null,
      bin: {}
    };
  },

  created() {
    this.display(this.$route.params.binId);
  },

  // Called when component is reused.
  beforeRouteUpdate(to, from, next) {
    this.display(to.params.binId);
    next();
  },

  methods: {
    display(binId) {
      this.binId = Number(binId);
      this.refresh();
    },
    async refresh() {
      try {
        const data = await this.getBinData();
        this.bin = data;
        this.$refs.updateDrawer.loadForm(data);
      } catch (error) {
        this.showError(error);
      }
    },
    async getBinData() {
      try {
        const response = await Bins.getRestApi().getBinById(this.binId);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    showDrawer() {
      this.$refs.nav.showDrawer();
    },
    onSubmitSuccess() {
      this.refresh();
      this.$refs.nav.hideDrawer();
      this.$refs.nav.showSuccess("Update bin details successfully!");
      this.$refs.inlineGrids.refreshActivityLog();
    },
    onSubmitFailed(error) {
      this.showError(error);
    },
    onCancel() {
      this.$refs.nav.hideDrawer();
      this.$refs.updateDrawer.loadForm(this.bin);
    },
    showError(error) {
      this.$refs.nav.showError(error);
    }
  }
};
</script>
