<template>
  <div>
    <inline-grid
        ref="notesGrid"
        :data-source="notes"
        :show-borders="false"
        :hoverStateEnabled="true"
        @rowSelected="onRowSelected"
    >
      <DxPaging :page-size="10"/>
      <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
      />
      <dx-column
          :width="220"
          data-field="createdAt"
          data-type="date"
          caption="Date"
          sortOrder="desc"
          format="MM/dd/yyyy, hh:mm a"
      />
      <dx-column
          data-field="userName"
          caption="User"
          :width="220"
      />
      <dx-column
          v-if="showTheTicketOrigin"
          data-field="origin"
          caption="Origin"
          id="origin"
          :width="150"
      />
      <dx-column
          data-field="note"
          caption="Note"
      />
      <inline-grid-footer slot="footer">
        <inline-grid-button
            v-if="isAddNoteButtonDisplayed"
            label="Add Note"
            @click="onAddNoteButtonClick"
        />
      </inline-grid-footer>
    </inline-grid>

    <show-note-dialog
        ref="showNoteDialog"
        @onCancelClicked="onCancelNoteDialog"
    />
    <add-note-dialog
        ref="addNoteDialog"
        :userName="userName"
        @saveNoteButtonClicked="onEmitSaveNote"
    />
  </div>
</template>

<script>
import {DxColumn, DxPager, DxPaging} from "devextreme-vue/ui/data-grid";
import InlineGrid from "../grid/InlineGrid.vue";
import InlineGridFooter from "../grid/InlineGridFooter.vue";
import InlineGridButton from "../grid/InlineGridButton.vue";
import ShowNoteDialog from "../dialogContainers/ShowNoteDialog.vue";
import AddNoteDialog from "../dialogContainers/AddNoteDialog.vue";
import RouterHelper from "../../../assets/routerHelper";

export default {
  components: {
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn,
    ShowNoteDialog,
    AddNoteDialog,
    DxPager,
    DxPaging
  },

  props: {
    notes: {
      type: Array,
      required: true
    },
    isAddNoteButtonDisplayed: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    showTheTicketOrigin() {
      return RouterHelper.isLeadOrJobDetailsPage(this.$route);
    }
  },

  created() {
    this.emitGetNotes();
    this.getLoggedInUser();
  },

  data() {
    return {
      userName: "",
      creatorId: 0,
      pageSizes: [10, 25, 50]
    };
  },

  methods: {
    onSuccessSave() {
      this.resetCreateNoteForm();
      this.closeCreateNoteDialog();
    },
    onRowSelected(data) {
      this.$refs.showNoteDialog.openDialog(data);
    },
    onAddNoteButtonClick() {
      this.$refs.addNoteDialog.openDialog();
    },
    resetCreateNoteForm() {
      this.$refs.addNoteDialog.reset();
    },
    closeCreateNoteDialog() {
      this.$refs.addNoteDialog.closeDialog();
    },
    getLoggedInUser() {
      const user = this.$store.state.loggedInUser;
      this.userName = `${user.firstName} ${user.lastName}`;
      this.creatorId = user.id;
    },
    onEmitSaveNote(note) {
      const data = {
        note,
        creatorId: this.creatorId
      };
      this.$emit('saveNote', data);
    },
    async emitGetNotes() {
      this.$emit('getNotes');
    },
    onCancelNoteDialog() {
      this.$refs.notesGrid.clearSelection();
    }
  }
};
</script>
