<template>
  <div id="check-in-table">
    <v-data-table
      :items="tableItems"
      light
      hide-default-footer
      disable-pagination
      hide-default-header
      dense
    >
      <template v-slot:item="{ item }">
        <tr class="rowOfData">
          <td class="text-xs-left" width="20%">
            {{ item.jobProductLineItem.referenceId }}
          </td>
          <td class="text-xs-left" width="20%">
            {{ item.vendorDTO ? item.vendorDTO.name : 'N/A'}}
          </td>
          <td class="text-xs-left" width="20%">
            {{ item.jobProductLineItem.productCatalogItem.styleName }}
          </td>
          <td class="text-xs-left" width="20%">
            {{ item.jobProductLineItem.productCatalogItem.color }}
          </td>
          <td class="text-xs-left" width="20%">
            {{ item.jobProductLineItem.quantity }}
          </td>
          <td class="text-xs-left" width="1%">
            <v-btn small dark icon @click="onDeleteItem(item)">
              <v-icon class="short" color="primary">delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    itemSummary: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  computed: {
    tableItems() {
      return [this.itemSummary];
    }
  },

  methods: {
    onDeleteItem(item) {
      this.$emit("deleteItem", item.jobProductLineItem.referenceId);
    }
  }
};
</script>

<style scoped>
#check-in-table table thead {
  display: none;
}

.rowOfData {
  height: 48px;
}
</style>
