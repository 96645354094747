import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * Transfer Request Reason code
 */
export class TransferRequestReasonCode {
    id: number = 0;
    name: string = "";
    active: boolean;
    isItemApplied: boolean;
    isTransferRequestApplied: boolean;

    constructor(id: number, name: string, active: boolean, isItemApplied: boolean, isTransferRequestApplied: boolean) {
        this.id = id;
        this.name = name;
        this.active = active;
        this.isItemApplied = isItemApplied;
        this.isTransferRequestApplied = isTransferRequestApplied;
    }
}

export interface TransferRequestReasonCodesRestApi {
    getAllReasonCodesRelatedToTransferRequest(): AxiosPromise<TransferRequestReasonCode[]>;
}

class Implementation implements TransferRequestReasonCodesRestApi {
    getAllReasonCodesRelatedToTransferRequest(): AxiosPromise<TransferRequestReasonCode[]> {
        return Axios.axiosInstance().get<TransferRequestReasonCode[]>("close-transfer-request-reason-codes/all");
    }
}

const TransferRequestReasonCodes = {
    getRestApi(): TransferRequestReasonCodesRestApi {
        return new Implementation();
    }
};

export default TransferRequestReasonCodes;
