<template>
  <v-row>
    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Inventory Reservations
          </v-expansion-panel-header>
          <v-expansion-panel-content key="reservations">
            <grid-toolbar
                @onToggleResetGridDialog="clearGridState">
            </grid-toolbar>
            <inventory-reservations-grid
                ref="reservations"
                :inventoryItemId="inventoryItemId"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Audit Logs
          </v-expansion-panel-header>
          <v-expansion-panel-content key="audit-logs">
            <template v-slot:header>Audit Logs</template>
            <inventory-audit-logs
                ref="auditLog"
                :auditLogs="auditLogs"
                @onGetLogs="getAuditLogs"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col md="12">
      <v-expansion-panels multiple accordion :value="expanded">
        <v-expansion-panel>
          <v-expansion-panel-header color="#69b">
            Activity Logs
          </v-expansion-panel-header>
          <v-expansion-panel-content key="activityLog">
            <template v-slot:header>Activity Logs</template>
            <activity-logs-grid
                ref="activityLog"
                :activityLogs="activityLogs"
                @onGetLogs="getActivityLogs"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import ActivityLogsGrid from "../../components/common/inlineGrids/ActivityLogsGrid.vue";
import InventoryAuditLogs from "../../components/inventoryItems/InventoryAuditLogs.vue";
import InventoryReservationsGrid from "../../components/inventoryItems/reservations/InventoryReservationsGrid.vue";
import GridToolbar from "../../components/common/grid/GridToolbar";
import Constants from "../../assets/constants";
import InventoryItems from "../../rest/inventoryItems";

export default {
  components: {
    ActivityLogsGrid,
    InventoryAuditLogs,
    InventoryReservationsGrid,
    GridToolbar
  },

  props: {
    inventoryItemId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      activityLogs: [],
      auditLogs: [],
      expanded: Constants.expandedByDefault
    };
  },

  methods: {
    display() {
      try {
        this.getActivityLogs();
        this.getAuditLogs();
      } catch (error) {
        this.showError(error);
      }
    },
    async refreshActivityLog() {
      if (this.$refs.activityLog) {
        await this.getActivityLogs();
      }
    },
    async refreshAuditLog() {
      if (this.$refs.auditLog) {
        await this.getAuditLogs();
      }
    },
    refreshInventoryReservationsGrid() {
      if (this.$refs.reservations) {
        this.clearGridState();
      }
    },
    getInventoryIdFromUrl() {
      return this.$route.params.inventoryItemId;
    },
    showError(error) {
      this.$emit('showError', error);
    },
    async getActivityLogs() {
      try {
        const response = await InventoryItems.getRestApi().getInventoryItemActivityLogs(this.getInventoryIdFromUrl());
        this.activityLogs = response.data;
      } catch (error) {
        throw error;
      }
    },
    async getAuditLogs() {
      try {
        const response = await InventoryItems.getRestApi().getInventoryItemAuditLogs(this.getInventoryIdFromUrl());
        this.auditLogs = response.data;
      } catch (error) {
        throw error;
      }
    },
    clearGridState() {
      this.$refs.reservations.clearGrid(this.getInventoryIdFromUrl());
    }
  }
};
</script>

<style scoped>
.toolbar {
  margin: 0 0 0 0;
}
</style>
