<template>
  <drawer-form
      ref="form"
      title="Edit Market"
      submitLabel="Save Market"
      :inProgress="inProgress"
      @cancelClicked="onCancel"
      @submitClicked="onSubmit"
  >
    <market-edit-form ref="marketEditForm"/>
  </drawer-form>
</template>

<script>
import DrawerForm from "@/components/common/form/DrawerForm.vue";
import Markets from "@/rest/markets";
import MarketEditForm from "@/components/markets/MarketEditForm.vue";

export default {
  components: {
    DrawerForm,
    MarketEditForm
  },

  props: {market: Object},

  data() {
    return {
      marketData: null,
      inProgress: false
    };
  },

  watch: {
    market: function (newVal) {
      if (newVal) {
        this.refresh();
      }
    }
  },

  methods: {
    refresh() {
      this.marketData = Object.assign(this.market);
      this.$refs.marketEditForm.loadForm(this.marketData);
    },
    onCancel() {
      this.refresh();
      this.$emit("cancelClicked");
    },
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.updateMarket();
      }
    },
    async updateMarket() {
      try {
        this.inProgress = true;
        const payload = this.$refs.marketEditForm.buildPayload();
        const response = await Markets.getRestApi().updateMarket(this.market.referenceId, payload);
        this.$emit("onUpdateSuccess", response.data);
      } catch (error) {
        this.$emit("onUpdateFail", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
