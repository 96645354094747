<template>
  <div>
    <inline-grid
        :data-source="installations"
        :show-borders="false"
        :noDataText="noDataText"
        :hoverStateEnabled="true"
        :hideDelete="!canEditInstallations"
        @deleteClicked="onDeleteInstallation"
        @rowSelected="onRowSelected"
    >
      <DxPaging :page-size="10"/>
      <dx-column
          data-field="jobLineItemDescription"
          width="25%"
          caption="Line Item"
      />
      <dx-column
          data-field="installerName"
          width="20%"
          caption="Installer"
      />
      <dx-column
          data-field="crewName"
          width="20%"
          caption="Crew Name"
      />
      <dx-column
          data-field="installationDate"
          width="15%"
          data-type="date"
          caption="Install Date"
          format="MM/dd/yyyy"
      />
      <dx-column
          data-field="etc"
          width="20%"
          data-type="date"
          caption="ETC"
          format="MM/dd/yyyy"
      />
      <dx-column
          data-field="comment"
          width="20%"
          caption="Comment"
      />
      <dx-column
          :visible="!readOnly && !isSalesApp"
          data-field="id"
          width="5%"
          caption
          cellTemplate="delete"
      />
      <inline-grid-footer
          v-show="ifHaveLaborLineItems && !isSalesApp"
          slot="footer"
      >
        <inline-grid-button
            v-if="!readOnly"
            label="Schedule Installation"
            @click="onAddInstallation"
        />
        <div v-else>
          Save changes to job line items before editing installations
        </div>
      </inline-grid-footer>
    </inline-grid>
    <create-dialog
        ref="createDialog"
        :jobId="job.id"
        :branchId="job.branchId"
        :wsSessionId="wsSessionId"
        @hasLaborLineItems="ifHaveLaborLineItems"
        @installationAdded="onInstallationAdded"
        @installationAddFailed="emitError"
    />
    <read-only-dialog ref="readOnlyDialog"/>
    <error-message-dialog ref="errorDialog" :message="errorMessage"/>
  </div>
</template>

<script>
import InlineGrid from "../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../components/common/grid/InlineGridButton.vue";
import CreateDialog from "../../components/installations/CreateDialog.vue";
import ReadOnlyDialog from "../../components/installations/ReadOnlyDialog.vue";
import ErrorMessageDialog from "../../components/common/ErrorMessageDialog.vue";
import Installations from "../../rest/installations";
import Jobs from "../../rest/jobs";
import {DxColumn, DxPaging} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    DxPaging,
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    CreateDialog,
    ReadOnlyDialog,
    ErrorMessageDialog,
    DxColumn,
  },

  props: {
    jobReferenceId: {},
    isSalesApp: {},
    readOnly: {},
    job: {},
    wsSessionId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      installations: null,
      hasLaborLineItems: false,
      noDataText: "Labor Line Items must be added before scheduling an Installation",
      errorMessage: null
    };
  },

  watch: {
    jobReferenceId: {
      immediate: true,
      handler() {
        this.refresh();
      }
    }
  },

  computed: {
    canEditInstallations() {
      if (this.job) {
        return !this.job.invoiced;
      }
      return true; // true by default
    }
  },

  methods: {
    refresh() {
      let component = this;
      Jobs.getRestApi()
          .getJobInstallationsWithCrews(this.jobReferenceId)
          .then((response) => {
            component.installations = response.data;
            component.installations.forEach((i) => {
              i.crewName = i.crewName == null ? 'TBD' : i.crewName
              i.etc = i.etc == null ? 'TBD' : i.etc
            });
          })
          .catch(() => {
            component.snackbar = true;
          });
      this.$store.commit("setIsJobSaving", false);
    },
    ifHaveLaborLineItems() {
      this.noDataText = "No data";
      this.hasLaborLineItems = true;
    },
    refreshFormLaborLineItems() {
      this.$refs.createDialog.refreshFormLaborLineItems();
    },
    onAddInstallation() {
      this.$refs.createDialog.openDialog();
    },
    onInstallationAdded() {
      this.refresh();
      this.$emit('added');
    },
    onRowSelected(data) {
      if (data) {
        this.$refs.readOnlyDialog.openDialog(data);
      }
    },
    onDeleteInstallation(cellData) {
      let component = this;
      let itemToRemoveHasEarlyPayment = this.installations.some(i => {
        if (i.earlyPaymentDate && (i.jobLineItemReferenceId === cellData.data.jobLineItemReferenceId)) {
          return i;
        }
      });
      if (itemToRemoveHasEarlyPayment) {
        component.emitError("Cannot delete an installation that has already been paid");
      } else {
        component.$store.commit("setIsJobSaving", true);
        Installations.getRestApi()
            .deleteInstallation(cellData.data.id, this.wsSessionId)
            .then(() => {
              component.$emit("deleted");
              component.refresh();
            })
            .catch((error) => {
              component.emitError(error);
              component.$store.commit("setIsJobSaving", false);
            });
      }
    },
    emitError(error) {
      this.$emit('jobInstallationError', error);
    }
  }
};
</script>
