<template>
  <v-form ref="form">
    <v-container grid-list-lg>
      <v-layout row wrap class="mt-1">
        <v-flex xs12>
          <v-textarea
            v-model="note"
            label="Note"
            placeholder=" "
            :disabled="true"
            rows="3"
          />
        </v-flex>
        <v-flex xs12>
          <v-divider class="mb-4" />
        </v-flex>
        <v-flex xs10>
          <v-select
              label="Select Product Items"
              placeholder=" "
              persistent-placeholder
              v-model="productItem"
              :items="productItems"
              item-text="displayName"
              dense
              attach
              multiple
              small-chips
              return-object
              clearable
              @change="onProductItemsChanged"
          >
            <template v-slot:prepend-item class="select-all" v-if="!emptyProductSelection">
              <select-all
                  :items="productItems"
                  :selected="productItem"
                  @select="selectAllProductItems"
                  @deselect="deselectAllProductItems"
              >
              </select-all>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="materialCost"
            label="Material Cost"
            placeholder="0.00"
            prefix="$"
            :rules="[rules.isCurrencyFormat]"
            :disabled="true"
          />
        </v-flex>
        <v-flex xs10>
          <v-select
              label="Select Labor Items"
              placeholder=" "
              persistent-placeholder
              v-model="laborItem"
              :items="laborItems"
              item-text="displayName"
              attach
              multiple
              small-chips
              return-object
              clearable
              @change="onLaborItemsChanged"
          >
            <template v-slot:prepend-item class="select-all" v-if="!emptyLaborSelection">
              <select-all
                  :items="laborItems"
                  :selected="laborItem"
                  @select="selectAllLaborItems"
                  @deselect="deselectAllLaborItems"
              >
              </select-all>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            v-model="laborCost"
            label="Labor Cost"
            placeholder="0.00"
            prefix="$"
            :rules="[rules.isCurrencyFormat]"
            :disabled="true"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model="customerAmount"
            label="Customer Discount"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model="fiftyFloorAmount"
            label="50Floor Responsibility"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model="thirdPartyAmount"
            label="Third Party Amount"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-flex>
        <v-flex xs12>
          <v-divider class="mb-4" />
        </v-flex>
        <v-flex xs8>
          <installer-search-field
            ref="installers"
            v-model="installerId"
            label="* Installer"
            :availableInstallers="availableInstallers"
            :rules="[rules.required]"
          />
        </v-flex>
        <v-flex xs4>
          <v-text-field
            v-model="totalChargeback"
            label="Total Chargeback"
            placeholder="0.00"
            prefix="$"
            :disabled="true"
            :rules="[rules.isCurrencyFormat]"
          />
        </v-flex>
        <v-flex xs12>
          <v-spacer class="mb-5" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import Jobs from "@/rest/jobs";
import Installers from "@/rest/installers";
import Constants from "@/assets/constants.js";
import InstallerSearchField from "@/components/searchFields/InstallersJobAndParentJob.vue";
import SelectAll from "@/components/searchFields/selectAllForDropdowns/SelectAllDropdownTemplate.vue";
import Rules from "@/assets/rules";
import CurrencyFormatter from "@/assets/jobBuilder/currencyFormatter";

const formDefaults = {};

export default {
  components: {
    InstallerSearchField,
    SelectAll
  },

  props: {
    job: Object,
    laborItems: Array
  },

  data() {
    return Object.assign(
      {},
      {
        error: null,
        note: null,
        productItems: [],
        productItem: null,
        laborItems: [],
        laborItem: null,
        materialCost: 0,
        laborCost: 0,
        customerAmount: 0,
        thirdPartyAmount: 0,
        fiftyFloorAmount: 0,
        totalChargeback: 0,
        installerId: null,
        installer: null,
        installerLabel: null,
        availableInstallers: [],
        overbill: 0,
        rules: {
          required: Rules.required,
          isCurrencyFormat: Rules.isCurrencyFormat,
        },
      },
      formDefaults
    );
  },

  computed: {
    jobReferenceId() {
      return this.job.referenceId;
    },
    emptyProductSelection() {
      return this.productItems.length === 0;
    },
    emptyLaborSelection() {
      return this.laborItems.length === 0;
    }
  },

  watch: {
    materialCost() {
      this.updateChargebackTotal();
    },
    laborCost() {
      this.updateChargebackTotal();
    },
    installerId(val) {
      this.installerId = val;
      this.loadInstaller(val);
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    load(payload) {
      this.productItems = [];
      this.productItem = [];
      this.loadProductItems();
      this.$data.laborItems = [];
      this.$data.laborItem = [];
      this.note = payload.note;
      this.materialCost = CurrencyFormatter.zeroCurrency();
      this.laborCost = CurrencyFormatter.zeroCurrency();
      this.customerAmount = CurrencyFormatter.asCurrency(payload.customerAmount);
      this.thirdPartyAmount = CurrencyFormatter.asCurrency(payload.thirdPartyAmount);
      this.fiftyFloorAmount = CurrencyFormatter.asCurrency(payload.fiftyFloorAmount);
      this.updateChargebackTotal();
      this.availableInstallers = this.filterInstallers();
      this.overbill = CurrencyFormatter.asCurrency(payload.overbill);
    },
    updateChargebackTotal() {
      this.totalChargeback = CurrencyFormatter.asCurrency(
          this.materialCost
              .add(this.laborCost)
              .add(this.thirdPartyAmount)
              .add(this.customerAmount)
              .subtract(this.fiftyFloorAmount));
    },
    getPayload() {
      const payload = {};
      payload.materialCost = this.materialCost;
      payload.laborCost = this.laborCost;
      payload.installerChargeAmount = this.totalChargeback;
      payload.installerId = this.installerId;
      payload.installerLabel = this.installerLabel;
      return payload;
    },
    onProductItemsChanged(items) {
      let total = CurrencyFormatter.zeroCurrency();
      this.productItem = items;
      items.forEach((item) => {
        if (item.supplierCost) {
          let itemTotal = CurrencyFormatter.asCurrency(item.supplierCost.cost).multiply(item.quantity);
          total = total.add(itemTotal);
        }
      });
      this.materialCost = total;
    },
    onLaborItemsChanged(items) {
      let total = CurrencyFormatter.zeroCurrency();
      this.laborItem = items;
      items.forEach((item) => {
        let itemTotal = CurrencyFormatter.asCurrency(item.jobLineItem.price);
        total = total.add(itemTotal);
      });
      this.laborCost = total;
    },
    async loadInstaller(installerId) {
      if (!installerId) {
        this.installer = null;
        this.installerLabel = null;
        return;
      }
      try {
        const response = await Installers.getRestApi().getInstaller(installerId);
        this.installer = response.data;
        if (this.installer) {
          this.installerLabel = this.installer.companyName;
        } else {
          this.installerLabel = "No installer";
        }
      } catch (error) {
        throw error;
      }
    },
    loadProductItems: async function () {
      try {
        const response = await Jobs.getRestApi().getJobProductLineItems(this.jobReferenceId);
        let items = response.data;
        items.forEach((product) => {
          product.displayName = product.referenceId + " - " + product.productCatalogItem.styleName
              + (product.productCatalogItem.color
                  ? " (" + product.productCatalogItem.color + ")" + " - [Quantity: " + product.quantity + "]"
                  : " - [Quantity: " + product.quantity + "]");
          this.$data.productItems = items;
        });
      } catch (error) {
        throw error;
      }
    },
    reset: function () {
      this.$refs.form.reset();
    },
    filterInstallers() {
      if (this.$props.job.parentJobId) {
        this.getInstallersByParentJobId(this.$props.job.id);
      } else {
        return this.filterInstallersWithoutTBDInstaller(this.$props.laborItems, false);
      }
    },
    clearSelectedInstaller() {
      this.$refs.installers.clearSelected();
    },
    async getInstallersByParentJobId(jobId) {
      try {
        const response = await Installers.getRestApi().getInstallersForJobAndParentJob(jobId);
        this.availableInstallers = this.filterInstallersWithoutTBDInstaller(response.data, true);
      } catch (error) {
        throw error;
      }
    },
    filterInstallersWithoutTBDInstaller(installers, isFromRequest) {
      let filtered = [];
      installers.forEach(item => {
        if (isFromRequest) {
          if (item.id !== Constants.TBDInstallerId) {
            filtered.push(item);
          }
        } else {
          if (item.installation && item.installation.installer.id !== Constants.TBDInstallerId) {
            filtered.push(item.installation.installer);
          }
        }
      });
      return filtered;
    },
    selectAllProductItems() {
      this.productItem = this.productItems;
      this.onProductItemsChanged(this.productItem)
    },
    deselectAllProductItems() {
      this.productItem = [];
      this.onProductItemsChanged([]);
    },
    selectAllLaborItems() {
      this.laborItem = this.laborItems;
      this.onLaborItemsChanged(this.laborItems);
    },
    deselectAllLaborItems() {
      this.laborItem = [];
      this.onLaborItemsChanged([]);
    }
  },
};
</script>
