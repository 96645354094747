<template>
  <product-type-form
      ref="form"
      title="Edit Product Type"
      submit-label="Save Product Type"
      :inProgress="inProgress"
      @cancelClicked="onCancelClicked"
      @submitClicked="onUpdateProductType"
  />
</template>

<script>
import ProductTypeForm from "@/components/productTypes/ProductTypeForm.vue";
import ProductTypes from "@/rest/productTypes";

export default {
  components: {
    ProductTypeForm
  },

  data() {
    return {
      productTypeId: null,
      inProgress: false
    };
  },

  methods: {
    refresh(data) {
      let productType = Object.assign({}, data);
      this.productTypeId = productType.id;
      this.$refs.form.loadForm(productType);
    },
    onCancelClicked() {
      this.$emit("cancelClicked");
    },
    async onUpdateProductType(payload) {
      try {
        this.inProgress = true;
        const id = this.productTypeId;
        const response = await ProductTypes.getRestApi().updateProductType(id, payload);
        this.$emit("submitSuccess", response.data);
      } catch (error) {
        this.$emit("submitFailed", error);
      }
      this.inProgress = false;
    }
  }
};
</script>
