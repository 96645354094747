import Axios from "./axiosInstance";
import { AxiosPromise } from "axios";

/**
 * Job status.
 */
export class JobStatus {
  id: number;
  name: string = "";

  constructor(id: number) {
    this.id = id;
  }
}

/**
 * REST interface provided for job statuses.
 */
export interface JobStatusRestApi {
  getAllJobStatuses(): AxiosPromise<JobStatus[]>;
}

/**
 * REST implementation provided for product types.
 */
class Implementation implements JobStatusRestApi {
  getAllJobStatuses(): AxiosPromise<JobStatus[]> {
    return Axios.axiosInstance().get<JobStatus[]>("/job_statuses");
  }
}

const JobStatuses = {
  getRestApi(): JobStatusRestApi {
    const api = new Implementation();
    return api;
  }
};

export default JobStatuses;
