<template>
  <inline-grid
      :data-source="branchIntegrations"
      :show-borders="false"
      @deleteClicked="onDeleteIntegration"
  >
    <dx-column
        data-field="name"
        width="25%"
        caption="Integration Name"
    />
    <dx-column
        data-field="description"
        width="70%"
        caption="Description"
    />
<!--    commented due to risk of potential issues-->
<!--    <dx-column-->
<!--        data-field="id"-->
<!--        width="5%"-->
<!--        :allow-sorting="false"-->
<!--        caption-->
<!--        cellTemplate="delete"-->
<!--    />-->
<!--    <template v-slot:footer>-->
<!--      <inline-grid-footer>-->
<!--        <inline-grid-button-->
<!--            label="Add Integration"-->
<!--            @click="onAddIntegration"-->
<!--        />-->
<!--      </inline-grid-footer>-->
<!--    </template>-->

    <add-branch-integration-dialog
        ref="dialog"
        :branch-reference-id="branchReferenceId"
        @integrationAdded="onIntegrationAdded"
    />
  </inline-grid>
</template>

<script>
import InlineGrid from "@/components/common/grid/InlineGrid.vue";
import InlineGridButton from "@/components/common/grid/InlineGridButton.vue";
import InlineGridFooter from "@/components/common/grid/InlineGridFooter.vue";
import AddBranchIntegrationDialog from "@/components/branches/AddBranchIntegrationDialog.vue";
import {DxColumn} from "devextreme-vue/ui/data-grid";

export default {
  components: {
    InlineGrid,
    InlineGridButton,
    InlineGridFooter,
    AddBranchIntegrationDialog,
    DxColumn
  },

  props: {
    branchReferenceId: {
      type: String,
      required: true
    },
    branchIntegrations: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      logsArray: [],
      pageSizes: [10, 25, 50]
    };
  },

  created() {
    this.emitRefreshIntegrations();
  },

  methods: {
    emitRefreshIntegrations() {
      this.$emit("refreshIntegrations");
    },
    onAddIntegration() {
      this.$refs.dialog.openDialog();
    },
    onIntegrationAdded(id) {
      this.$emit("addIntegration", id);
    },
    onDeleteIntegration(context) {
      this.$emit("deleteIntegration", context.data.id);
    }
  }
};
</script>
