<template>
  <div>
    <v-card flat>
      <section-divider title="MISCELLANEOUS"/>
      <headers/>
      <panel
          :job="job"
          :line-item="item"
          v-for="item in miscItems"
          :key="item.referenceId"
          :miscItemTypes="miscItemTypes"
          @priceUpdated="onPriceUpdated"
          @commentUpdated="onCommentUpdated"
          @itemDeleted="onItemDeleted"
      />
      <v-card-actions class="pr-3">
        <v-spacer></v-spacer>
        <v-btn
            v-if="canAddMisc"
            color="primary"
            class="section-button"
            @click="onAddMiscItem"
        >
          Add Misc
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Constants from "../../../assets/constants.js";
import Headers from "../../../components/jobs/miscLineItems/MiscLineItemsHeaders.vue";
import Panel from "@/components/jobs/miscLineItems/MiscLineItemsPanel.vue";
import SectionDivider from "../../../components/jobs/SectionDivider.vue";
import CreateReferenceIds from "../../../assets/referenceIds";
import CurrencyFormatter from "../../../assets/jobBuilder/currencyFormatter";

export default {
  components: {
    Headers,
    Panel,
    SectionDivider,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    initialItems: {
      type: Array,
      required: true,
    },
    miscItemTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      miscItems: []
    };
  },

  watch: {
    initialItems: {
      immediate: true,
      handler(value) {
        this.miscItems = value;
      },
    },
  },

  computed: {
    canAddMisc() {
      return !this.job.invoiced;
    },
  },

  methods: {
    onAddMiscItem() {
      let item = {};
      let refId = CreateReferenceIds.getTempReferenceId();
      item.jobLineItem = {
        referenceId: refId,
        type: Constants.jobType.misc,
        quantity: 1,
        price: CurrencyFormatter.zeroCurrency(),
        status: null,
        comment: "",
        newLineItem: true,
        itemTypeId: null,
      };
      this.miscItems.push(item);
      this.$emit('itemsUpdated', this.miscItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    onItemDeleted(referenceId) {
      this.miscItems = this.miscItems.filter(
          (current) => current.jobLineItem.referenceId !== referenceId
      );
      this.$emit('itemsUpdated', this.miscItems);
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    onCommentUpdated() {
      this.markDirty();
    },
    onPriceUpdated() {
      this.$emit('amountsUpdated');
      this.markDirty();
    },
    markDirty() {
      this.$emit('dataUpdated');
    }
  },
};
</script>

<style scoped>
.section-button {
  width: 170px;
}
</style>
