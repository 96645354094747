<template>
  <v-combobox
      v-model="selected"
      :items="styles"
      :label="label"
      placeholder="Search or enter new style"
      :disabled="disabled"
      :maxlength="maxLength"
      :counter="counterValue"
      :rules="[rules.required, rules.max100]"
      :search-input.sync="styleInputText"
      dense
      clearable
      attach
      persistent-placeholder
  />
</template>

<script>
import CatalogItems from "../../../rest/catalogItems";
import Rules from "../../../assets/rules";

export default {
  components: {},

  data() {
    return {
      selected: null,
      styles: [],
      loading: false,
      defaultMaxLength: 100,
      defaultCounterValue: 100,
      styleInputText: null,
      maxLength: null,
      counterValue: null,
      rules: {
        max100: value => {
          if (value) {
            return Rules.maxValue(value, this.defaultMaxLength);
          } else return true;
        },
        required: Rules.required
      },
    };
  },

  props: {
    value: {
      type: String | null,
      required: true,
      default: null
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false
    },
    required: {
      type: Boolean,
      required: true,
      default: false
    },
    isPrivateLabel: {
      type: Boolean,
      required: true,
      default: false
    },
    labelName: {
      type: String | null,
      required: false,
      default: null
    }
  },

  computed: {
    label() {
      return this.labelName != null ? this.labelName : (this.required ? '* Style' : 'Style');
    }
  },

  watch: {
    value(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit('input', value);
    },
    styleInputText(newVal) {
      if (this.isSelectedValueWasSavedBefore(newVal)) {
        this.clearValues();
      } else {
        this.styles.length ? this.setDefaultValues() : this.clearValues();
      }
    }
  },

  mounted() {
    this.getCatalogItemStyles();
  },

  methods: {
    async getCatalogItemStyles() {
      this.selected = this.value;
      try {
        const response = this.isPrivateLabel
            ? await CatalogItems.getRestApi().getCatalogItemPrivateLabelStyles()
            : await CatalogItems.getRestApi().getCatalogItemStyles();
        this.styles = response.data;
      } catch (error) {
        throw error;
      }
    },
    setDefaultValues() {
      this.maxLength = this.defaultMaxLength;
      this.counterValue = this.defaultCounterValue;
    },
    clearValues() {
      this.maxLength = null;
      this.counterValue = null;
    },
    isSelectedValueWasSavedBefore(val) {
      return this.styles.includes(val)
    }
  }
};
</script>
