<template>
  <modal-dialog
      ref="dialog"
      title="Delete File"
      width="500"
      :visible="dialogVisible"
      submitLabel="Delete"
      cancelLabel="Cancel"
      @submitClicked="onDeleteClicked"
      @cancelClicked="onCancelClicked"
      :submitEnabled="true"
      :loading="loading"
  >
    <v-form ref="form">
      <v-container>
        <div id="delete-file-message">
          Do you really want to delete <b>{{ this.cellData.key.fileName }}</b>?
        </div>
        <div v-if="hasSpecificFileTypes(this.cellData)" class="warning-message">
          {{ warningMessage }}
        </div>
      </v-container>
    </v-form>
  </modal-dialog>
</template>

<script>
import ModalDialog from "../../ModalDialog.vue";
import Files from "../../../../rest/files";
import Constants from "../../../../assets/constants";

const formDefaults = {
  dialogVisible: false
};
export default {
  components: {
    ModalDialog
  },

  data() {
    return Object.assign(
        {},
        formDefaults, {
          cellData: null,
          loading: false,
          warningMessage: "Warning: All unsaved changes in job builder will be lost!"
        });
  },

  methods: {
    openDialog(cellData) {
      this.cellData = cellData;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.loading = false;
      this.dialogVisible = false;
    },
    onCancelClicked() {
      this.closeDialog();
    },
    onDeleteClicked() {
      this.deleteConfirmed(this.cellData);
    },
    async deleteConfirmed(cellData) {
      let hasCOCorVDAFileTypes = this.hasSpecificFileTypes(cellData);
      this.loading = true;
      try {
        await Files.getRestApi().deleteFile(cellData.data.id);
        this.$emit('deleteConfirmed', hasCOCorVDAFileTypes);
        this.closeDialog();
      } catch (error) {
        this.loading = false;
        this.emitError(error);
      }
    },
    hasSpecificFileTypes(cellData) {
      return cellData.data.documentTypeId === Constants.COC_DOCUMENT_TYPE_ID
          || cellData.data.documentTypeId === Constants.VDA_DOCUMENT_TYPE_ID;
    }
  }
};
</script>
<style>
#delete-file-message {
  font-size: 14px;
}
</style>
