import Axios from "./axiosInstance";
import {AxiosPromise} from "axios";

/**
 * days off rest implementation.
 */
export class DayOff {
  id: number;
  salesRepReferenceId: string;
  description: string;
  status: DayOffStatus;
  repeat: boolean;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;

  constructor(
    id: number,
    salesRepReferenceId: string,
    description: string,
    status: DayOffStatus,
    repeat: boolean,
    startDate: string,
    endDate: string,
    createdAt: string,
    updatedAt: string,
  ) {
    this.id = id;
    this.salesRepReferenceId = salesRepReferenceId;
    this.status = status;
    this.description = description;
    this.repeat = repeat;
    this.startDate = startDate;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.endDate = endDate;
  }
}

export enum DayOffStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  WAITING_FOR_DELETION = "WAITING_FOR_DELETION",
  DELETED = "DELETED"
}

/**
 * REST interface provided for leads.
 */
export interface DaysOffRestApi {
  createDayOff(lead: DayOff): AxiosPromise<DayOff>;
  getAllDaysOff(): AxiosPromise<DayOff>;
  patchDayOff(salesRepDayOffId: string, updated: DayOff): AxiosPromise<DayOff>;
  getUnreviewedDaysOffCount(): AxiosPromise<Number>;
}

/**
 * REST implementation provided for days off.
 */
class Implementation implements DaysOffRestApi {
  //Unused. Reason: not needed on the FE (for now)
  createDayOff(lead: DayOff): AxiosPromise<DayOff> {
    return Axios.axiosInstance().post<DayOff>(`/sales-rep-days-off`);
  }

  getAllDaysOff(): AxiosPromise<DayOff> {
    return Axios.axiosInstance().get<DayOff>(`/sales-rep-days-off`);
  }

  patchDayOff(salesRepDayOffId: string, updated: DayOff): AxiosPromise<DayOff> {
    return Axios.axiosInstance().patch<DayOff>(`/sales-rep-days-off/${salesRepDayOffId}`, updated);
  }

  getUnreviewedDaysOffCount(): AxiosPromise<Number> {
    return Axios.axiosInstance().get<Number>(`/sales-rep-days-off/count`);
  }
}

const DaysOff = {
  getRestApi(): DaysOffRestApi {
    return new Implementation();
  }
};

export default DaysOff;
