<template>
  <div>
    <inline-grid
        ref="grid"
        :data-source="timesInSecondaryBranch"
        :pageSize="5"
        :showPager="true"
        :show-borders="false"
        :hoverStateEnabled="true"
        :editingAllowDeleting="true"
        :editingAllowPerRow="(row) => isTimeInSecondaryBranchDeletable(row)"
        @rowSelected="onRowSelected"
        @deleteClicked="onDeleteClicked"
    >
      <dx-column
          data-field="installerCrewName"
          width="20%"
          caption="Crew"
      />
      <dx-column
          data-field="fromDate"
          width="20%"
          data-type="date"
          caption="Start Date"
      />
      <dx-column
          data-field="toDate"
          width="20%"
          data-type="date"
          caption="End Date"
      />
      <dx-column
          data-field="comment"
          caption="Comments"
      />
      <template v-slot:footer>
        <inline-grid-footer>
          <inline-grid-button label="Add Time Period" @click="onAddClick"/>
        </inline-grid-footer>
      </template>
    </inline-grid>
    <remove-time-in-secondary-branch-dialog
        ref="removeTimeDialog"
        @deleteConfirmed="getData"
        @emitError="showError"
    />
    <add-crew-time-in-secondary-branch-dialog
        ref="addTimeInSecondaryBranchDialog"
        :crews="crews"
        :installerRefId="installerRefId"
        @saveButtonClicked="getData"
    />
    <update-time-in-secondary-branch-dialog
        ref="updateTimeInSecondaryBranchDialog"
        :crews="crews"
        :installerRefId="installerRefId"
        @updateButtonClicked="onUpdateSubmitted"
        @cancelClicked="onUpdateCancel"
    />
    <error-modal
        ref="errorModal"
        :error="error"
        :title="'Error'"
        :visible="isErrorModalOpen"
        @closeErrorModal="closeErrorModal"
    />
  </div>
</template>

<script>
import {DxColumn} from "devextreme-vue/ui/data-grid";
import InlineGrid from "../../../../components/common/grid/InlineGrid.vue";
import InlineGridFooter from "../../../../components/common/grid/InlineGridFooter.vue";
import InlineGridButton from "../../../../components/common/grid/InlineGridButton.vue";
import InstallerCrewSecondaryBranchTimes from "../../../../rest/installer/installerCrewSecondaryBranchTimes";
import AddCrewTimeInSecondaryBranchDialog from "./AddCrewTimeInSecondaryBranchDialog.vue";
import UpdateTimeInSecondaryBranchDialog from "./UpdateTimeInSecondaryBranchDialog.vue";
import RemoveSlotDialog from "../../calendar/RemoveSlotDialog.vue";
import RemoveTimeInSecondaryBranchDialog from "./RemoveTimeInSecondaryBranchDialog.vue";
import ErrorModal from "../../../../components/navigation/ErrorModal.vue";
import UpdateDayOffDialog from "../dayoff/UpdateDayOffDialog.vue";
import DateUtils from "@/assets/utils/dateUtils";

export default {
  components: {
    UpdateDayOffDialog,
    ErrorModal,
    RemoveTimeInSecondaryBranchDialog,
    RemoveSlotDialog,
    UpdateTimeInSecondaryBranchDialog,
    AddCrewTimeInSecondaryBranchDialog,
    InlineGrid,
    InlineGridFooter,
    InlineGridButton,
    DxColumn
  },

  props: {
    crews: {
      type: Array,
      required: true
    },
    installerRefId: String
  },

  created() {
    this.getData();
  },

  data() {
    return {
      timesInSecondaryBranch: [],
      isErrorModalOpen: false,
      error: null
    };
  },

  methods: {
    onRowSelected(data) {
      if (data) {
        this.$refs.updateTimeInSecondaryBranchDialog.openDialog(data);
      }
    },
    onDeleteClicked(row) {
      this.$refs.removeTimeDialog.openDialog(row);
    },
    onAddClick() {
      this.$refs.addTimeInSecondaryBranchDialog.openDialog();
    },
    getData() {
      InstallerCrewSecondaryBranchTimes.getRestApi().getTimesInSecondaryBranch(this.installerRefId)
          .then(response => {
            this.timesInSecondaryBranch = response.data;
          })
    },
    showError(error) {
      this.isErrorModalOpen = true;
      this.error = error;
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
      this.error = null;
    },
    onUpdateCancel() {
      this.$refs.grid.clearSelection();
    },
    isTimeInSecondaryBranchDeletable(row) {
      return !DateUtils.isPastDate(row.toDate);
    },
    onUpdateSubmitted() {
      this.getData();
      this.$refs.grid.clearSelection();
    }
  }
};
</script>
