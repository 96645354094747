import Axios from "../axiosInstance";
import {AxiosPromise} from "axios";

export interface JobWarrantyConfigDTO {
  warrantyPercentage: number;
  salesRepWarrantyCostPercentage: number;
  fiftyFloorWarrantyCostPercentage: number;
}

export interface JobWarrantyConfigRestApi {
  getJobWarrantyConfig(): AxiosPromise<JobWarrantyConfigDTO>;
}

class Implementation implements JobWarrantyConfigRestApi {
  getJobWarrantyConfig(): AxiosPromise<JobWarrantyConfigDTO> {
    return Axios.axiosInstance().get<JobWarrantyConfigDTO>("/jobs/config/warranty");
  }
}

const JobWarrantyConfigs = {
  getRestApi(): JobWarrantyConfigRestApi {
    return new Implementation();
  }
};

export default JobWarrantyConfigs;