<template>
  <v-stepper-header>
    <v-stepper-step :complete="step > 1" step="1" v-show="maxStepNumber >= 1" :editable="editable">
      {{ stepOneName }}
    </v-stepper-step>

    <v-divider v-show="maxStepNumber >= 1"/>

    <v-stepper-step :complete="step > 2" step="2" v-show="maxStepNumber >= 2" :editable="editable">
      {{ stepTwoName }}
    </v-stepper-step>

    <v-divider v-show="maxStepNumber >= 3"/>

    <v-stepper-step :complete="step > 3" step="3" v-show="maxStepNumber >= 3" :editable="editable">
      {{ stepThreeName }}
    </v-stepper-step>

    <v-divider v-show="maxStepNumber >=4"/>

    <v-stepper-step :complete="step > 4" step="4" v-show="maxStepNumber >= 4" :editable="editable">
      {{ stepFourName }}
    </v-stepper-step>
  </v-stepper-header>
</template>

<script>
export default {
  props: {
    step: Number,
    stepOneName: String,
    stepTwoName: String,
    stepThreeName: String,
    stepFourName: String,
    maxStepNumber: Number,
    editable: Boolean
  }
};
</script>
