<template>
  <v-flex xs12 class="mb-3 ml-3">
    <span class="headline float-left">{{ title }}</span>
  </v-flex>
</template>

<script>
export default {
  props: ["title"]
};
</script>

<style></style>
