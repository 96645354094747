<template>
  <v-form ref="EditPOItemForm" v-model="valid" lazy-validation>
    <v-card flat>
      <v-container class="pa-4 pb-4">
        <v-row>
          <v-col md="12" class=" mt-2 pt-2 pr-6 pl-6">
            <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show ? 'text' : 'password'"
                name="input-po-edit-password"
                label="* Password"
                hint="Enter special password (not a user's password)"
                persistent-placeholder
                v-model="password"
                class="input-group--focused"
                @click:append="show = !show"
                autofocus
                @input="handleInput"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>

import Rules from "../../../assets/rules";
import SpecialUserPasswordValidation from "../../../rest/passwordValidator";

const formDefaults = {};

export default {
  components: {},

  data() {
    return {
      valid: false,
      password: null,
      show: false,
      rules: {
        required: Rules.required
      }
    }
  },

  watch: {
    password(val) {
      this.password = val;
    }
  },

  methods: {
    handleInput() {
      this.$emit("passwordEmpty", this.password === null || this.password === "");
    },
    async validatePasswordForm() {
      return this.password ? await this.validatePassword(this.password) : false;
    },
    async validatePassword(password) {
      try {
        const response = await SpecialUserPasswordValidation.getRestApi().validateSpecialUserPassword(password);
        return response.data;
      } catch (error) {
        throw Error;
      }
    },
    loadDefaults() {
      this.password = null;
      this.show = false;
    }
  }
};
</script>
