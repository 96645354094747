<template>
  <v-stepper-header>
    <v-stepper-step :complete="true" step="1">Refund</v-stepper-step>
  </v-stepper-header>
</template>

<script>
export default {
  props: { step: Number },
};
</script>
