<template>
  <v-card>
    <v-card-title class="mt-4">
      <h1>Leads not assigned to timeblock</h1>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          placeholder="Search"
          persistent-placeholder
      />
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="listLeads"
        :rows-per-page-items="[5, 10, 25, 50, 100]"
        :options.sync="pagination"
        :search="search"
    >
      <template v-slot:item="{ item }">
        <tr
            @click="redirectLeadDetail(item.referenceId)"
            style="cursor: pointer"
        >
          <td class="text-xs-left">{{ item.marketName }}</td>
          <td class="text-xs-left">{{ item.referenceId }}</td>
          <td class="text-xs-left">{{ item.salesRepName }}</td>
          <td class="text-xs-left">{{ item.leadStatusName }}</td>
          <td class="text-xs-left">
            {{ new Date(item.appointmentDate).toLocaleDateString() }}
          </td>
          <td class="text-xs-left">{{ item.appointmentTime }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Leads from "@/rest/leads";

export default {
  components: {},
  props: {},
  data() {
    return {
      search: "",
      rowsPerPageItems: [10, 25, 50],
      pagination: {
        rowsPerPage: 25
      },
      headers: [
        {text: "Market Name", value: "marketName", sortable: true},
        {
          text: "Reference Id",
          value: "referenceId",
          sortable: true,
          filterable: true,
          search: true
        },
        {text: "Sales Representative", value: "salesRepName", sortable: true},
        {text: "Status", value: "leadStatusName", sortable: true},
        {text: "Appointment Date", value: "appointmentDate", sortable: true},
        {text: "Appointment Time", value: "appointmentTime", sortable: true}
      ],
      listLeads: []
    };
  },

  methods: {
    async getLeadMigration() {
      const response = await Leads.getRestApi().getLeadMigration();
      this.listLeads = response.data;
    },
    redirectLeadDetail(leadReferenceId) {
      this.$router.push(`/leads/details/` + leadReferenceId);
    }
  },

  beforeMount() {
    this.getLeadMigration();
  }
};
</script>
