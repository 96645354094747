import {v4 as uuid} from 'uuid';
import cryptoRandomString from 'crypto-random-string';

const defaultReferenceIdStringLength = 8;
const generatedReferenceIdPrefix = "generated-";

const create = {
  getRandomString(length) {
    return cryptoRandomString({length: length, type: 'alphanumeric'});
  },
  getTempReferenceId() {
    return generatedReferenceIdPrefix + uuid();
  },
  referenceId(prefix) {
    return `${prefix}-${this.getRandomString(defaultReferenceIdStringLength)}`;
  },
};

export default create;

export function isGenerated(referenceId) {
  return referenceId.startsWith(generatedReferenceIdPrefix);
}
